import ResizeSettingsModal from "@/components/ResizeSettingsModal";
import { EditableEvent, EditableType } from "@/enums/editables";
import editableItemMixin from "@/mixins/editableItemMixin";
import editableMethodsMixin from "@/mixins/editableMethodsMixin";
import { MediaLibraryAction } from "@/store/modules/mediaLibrary";

export default {
    components: {
        ResizeSettingsModal
    },
    mixins: [editableItemMixin, editableMethodsMixin],
    props: {
        type: {
            default: EditableType.Image
        },
        isOpened: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        fileSelectAction() {
            switch (this.type) {
                case EditableType.BackgroundImage:
                case EditableType.Image:
                    return MediaLibraryAction.SelectFile;

                case EditableType.Folder:
                    return MediaLibraryAction.SelectFolder;

                case EditableType.Video:
                    return MediaLibraryAction.SelectFile;

                default:
                    return MediaLibraryAction.SelectFile;
            }
        },

        templatesUsingGroupMediaItem() {
            return this.editableTemplates.filter(
                templateId => !this.isTemplateOverwrite(this.editable, this.groupsAffectingEditable, templateId)
            );
        }
    },
    data() {
        return {
            resizeSettingsModalIsVisible: false,
            imageToResize: null,
            isEditingGroupResizeSettings: false,
            isEditingSingleTemplateResizeSettings: false,
            resizeSettingsToEdit: {},
            templateIdsToEditResizeSettingsOf: []
        };
    },
    methods: {
        /* eslint-disable complexity */
        async fileSelected(editable, groupsAffectingEditable, templateId) {
            // TODO reduce complexity of function
            const mediaItem = this.getOverwriteMediaItem(editable, groupsAffectingEditable, templateId);
            const mediaLibraryConfig = {
                instance: {
                    multiple: false,
                    type: this.type
                }
            };

            if (mediaItem) {
                mediaLibraryConfig.folderId = mediaItem.folderId;
                mediaLibraryConfig.fileId = mediaItem.id;
            }

            let selectedFile = await this.$store.dispatch(this.fileSelectAction, mediaLibraryConfig);
            if (selectedFile) {
                selectedFile = Object.values(selectedFile);

                if (!selectedFile.length) {
                    return;
                }

                selectedFile = selectedFile.pop();

                if (
                    mediaItem &&
                    mediaItem.id === selectedFile.id &&
                    mediaItem.resizeSettings &&
                    selectedFile.resizeSettings
                ) {
                    /*
                        If we're selecting the same image, we do not want to overwrite
                        previously customised resize settings with default resize settings.
                    */
                    if (templateId && mediaItem.resizeSettings.resizeSettingsByTemplateId) {
                        if (mediaItem.resizeSettings.resizeSettingsByTemplateId[templateId]) {
                            selectedFile.resizeSettings = {
                                ...mediaItem.resizeSettings.resizeSettingsByTemplateId[templateId]
                            };
                        } else {
                            selectedFile.resizeSettings = { ...mediaItem.resizeSettings };
                            delete selectedFile.resizeSettings.resizeSettingsByTemplateId;
                        }
                    } else {
                        selectedFile.resizeSettings = { ...mediaItem.resizeSettings };
                    }
                }

                const eventPayload = {
                    mediaItem: selectedFile
                };

                if (templateId) {
                    eventPayload.templateId = templateId;
                }

                this.$emit(EditableEvent.Update, eventPayload);
            }
        },
        setResizeSettings(resizeSettings) {
            if (this.isEditingGroupResizeSettings) {
                this.$emit(EditableEvent.Update, {
                    mediaItem: {
                        ...this.getOverwriteMediaItem(this.editable, this.groupsAffectingEditable, null),
                        resizeSettings
                    }
                });
            } else {
                this.$emit(EditableEvent.Update, {
                    mediaItem: {
                        ...this.getOverwriteMediaItem(
                            this.editable,
                            this.groupsAffectingEditable,
                            this.templateIdsToEditResizeSettingsOf[0]
                        ),
                        resizeSettings
                    },
                    templateId: this.templateIdsToEditResizeSettingsOf[0]
                });
            }
        },
        showResizeSettingsModal(templateIds, isEditingGroupResizeSettings, isEditingSingleTemplateResizeSettings) {
            const mediaItem = isEditingGroupResizeSettings
                ? this.getOverwriteMediaItem(this.editable, this.groupsAffectingEditable, null)
                : this.getOverwriteMediaItem(this.editable, this.groupsAffectingEditable, templateIds[0]);
            this.imageToResize = mediaItem.url;
            this.resizeSettingsToEdit = mediaItem.resizeSettings || {};
            this.isEditingGroupResizeSettings = isEditingGroupResizeSettings;
            this.isEditingSingleTemplateResizeSettings = isEditingSingleTemplateResizeSettings;
            this.templateIdsToEditResizeSettingsOf = templateIds;
            this.toggleResizeSettingsModal();
        },
        toggleResizeSettingsModal() {
            this.resizeSettingsModalIsVisible = !this.resizeSettingsModalIsVisible;
        }
    }
};
