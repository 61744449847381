<template>
    <collapsible-widget>
        <template slot="name">Master Template Resize</template>
        <template slot="controls"></template>
        <Checkbox v-model="hideResized">Hide resized templates</Checkbox>
    </collapsible-widget>
</template>
<script>
import { FilterType, MasterTemplateLibraryAction } from "@/store/modules/masterTemplateLibrary";
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";
import { deepClone } from "@/utils";

export default {
    name: "MasterTemplateResizedFilter",
    components: { CollapsibleWidget },

    props: {
        campaignMasterTemplateIds: {
            type: Array,
            default() {
                return [];
            }
        }
    },

    computed: {
        filterValues() {
            return this.$store.state.masterTemplateLibrary.filters.filter(filter => filter.type === FilterType.Resized);
        },

        hideResized: {
            get() {
                if (this.filterValues.length) {
                    const fval = deepClone(this.filterValues);
                    const val = fval.pop().values.pop();

                    return val === "hide";
                }

                return false;
            },
            set(newValue) {
                // we cannot use booleans as the cloning that take places when updating filters does not handle them nicely
                const value = newValue ? "hide" : "show";
                this.$store.dispatch(MasterTemplateLibraryAction.UpdateResizedFilter, {
                    values: [value]
                });
            }
        }
    },

    beforeDestroy() {
        this.selectedValues = [];
    }
};
</script>

<style lang="scss">
.editable-group {
    &__add {
        width: 100%;
    }

    &__values {
        width: 100%;
    }

    .show-form {
        &--showing {
            display: none;
        }
    }
}
</style>
