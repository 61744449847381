<template>
    <span class="card-footer-button">
        <slot />
    </span>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "../../../sass/_variables.scss";

.card-footer-button {
    align-items: center;
    color: $grey5;
    cursor: pointer;
    display: inline-flex;
    height: 39px;
    justify-content: center;
    width: 40px;
    &:hover {
        background: $grey1;
    }
}
</style>
