<template>
    <ul v-if="kpiStat !== null" class="kpi-stats">
        <li class="kpi-stats__item" :class="classKpi">
            <span></span>
            {{ kpiStatDisplay }}
        </li>
        <li class="kpi-stats__item" :class="classConfidence">
            <span></span>
            {{ confidenceStatDisplay }}
        </li>
        <li class="kpi-stats__item" :class="classUtilisation">
            {{ utilisationDisplay }}
        </li>
    </ul>
</template>
<script>
import {
    confidenceDisplayFormatter,
    getMetricStat,
    kpiDisplayFormatter,
    utilisationDisplayFormatter
} from "@/utils/creativeInteligence";
import { CreativeInsightsGetters } from "@/store/modules/creativeInsights";

export default {
    name: "KpiStats",
    props: {
        stats: {
            type: Object,
            required: true
        }
    },

    computed: {
        classConfidence() {
            const dir = this.confidenceMetricValue > this.confidenceStat ? "down" : "up";

            return `kpi-stats--confidence kpi-stats--${dir}`;
        },

        classKpi() {
            const dir = this.kpiMetricValue > this.kpiStat ? "down" : "up";

            return `kpi-stats--kpi kpi-stats--${dir}`;
        },

        classUtilisation() {
            return "kpi-stats--utilisation";
        },

        confidenceMetricName() {
            return this.$store.getters[CreativeInsightsGetters.ConfidenceMetricName];
        },

        confidenceMetricValue() {
            return this.$store.getters[CreativeInsightsGetters.ConfidenceMetricValue];
        },

        confidenceStat() {
            const stat = getMetricStat(this.confidenceMetricName);
            return this.stats.confidenceMetricStats[stat];
        },

        confidenceStatDisplay() {
            const stat = getMetricStat(this.confidenceMetricName);
            return confidenceDisplayFormatter(this.stats.confidenceMetricStats, stat);
        },

        kpiMetricName() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricName];
        },

        kpiMetricValue() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricValue];
        },

        kpiStat() {
            const stat = getMetricStat(this.kpiMetricName);
            return this.stats.kpiMetricStats[stat];
        },

        kpiStatDisplay() {
            return kpiDisplayFormatter(this.kpiStat);
        },

        utilisationDisplay() {
            return utilisationDisplayFormatter(this.stats.utilisation);
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";
.kpi-stats {
    display: inline-block;

    span {
        width: 6px;
        height: 6px;
        margin-bottom: 2px;
        border-radius: 50%;
        background-color: #00000054;
        display: inline-block;
    }

    &--up span {
        background-color: $ci-quadrant-green;
    }

    &--down span {
        background-color: $ci-quadrant-red;
    }

    &--utilisation {
        color: #00000054;
    }

    &__item {
        display: inline-block;
        padding: 4px;
    }

    &--confidence {
        display: none;
    }
}
</style>
