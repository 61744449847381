<template>
    <div class="qa-drawer-select-templates">
        <div class="qa-drawer-select-templates__container">
            <div class="qa-drawer-select-templates__body">
                <CheckboxGroup :value="visibleMasterTemplateIds" @on-change="emitUpdatedVisibleMasterTemplateIds">
                    <Checkbox v-for="{ label, value } in options" :key="value" :label="value">
                        {{ label }}
                    </Checkbox>
                </CheckboxGroup>
            </div>
        </div>
    </div>
</template>

<script>
import { CampaignGetters } from "@/store/modules/campaign";

export default {
    props: {
        visibleMasterTemplateIds: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            dropdownIsVisible: false
        };
    },
    computed: {
        options() {
            const masterTemplateLabels = this.$store.getters[CampaignGetters.masterTemplateLabels];
            return Object.keys(masterTemplateLabels).map(masterTemplateId => ({
                label: masterTemplateLabels[masterTemplateId],
                value: masterTemplateId
            }));
        }
    },
    methods: {
        emitUpdatedVisibleMasterTemplateIds(visibileMasterTemplateIds) {
            this.$emit("select", visibileMasterTemplateIds);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.qa-drawer-select-templates {
    display: inline-block;
    margin-left: $spacing-smaller;

    .ivu-checkbox-wrapper {
        display: block;
        margin: 0;
        padding: $spacing-small $spacing $spacing-small $spacing-smallest;
    }

    .ivu-dropdown-item {
        padding: 0;
    }
}

.qa-drawer-select-templates__container {
    display: flex;
    flex-direction: column;
}

.qa-drawer-select-templates__body {
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
}
</style>
