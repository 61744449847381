<template>
    <div class="master-templates-sort-by">
        <div class="master-templates-sort-by__label">Sort by:</div>
        <Select :value="sortBy" @input="setValue">
            <Option v-for="option in options" :key="option.value" :label="option.label" :value="option.value">
                <span class="capitalize-text">{{ option.label }}</span>
                <Icon :type="arrowType(option.value)" class="data-sort__direction reporting__sortby-direction" />
            </Option>
        </Select>
    </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { TemplatesSortBy } from "shared-utils/enums/masterTemplate";
import { camelToUpperCase } from "@/utils";

export default {
    name: "MasterTemplatesSortBy",
    data() {
        return {
            sortBy: "WIDTH_ASC"
        };
    },

    created() {
        this.options = Object.entries(TemplatesSortBy).map(([k, v]) => ({
            label: `${camelToUpperCase(v.field)}`,
            value: k
        }));
        const storageValue = window.localStorage.getItem("hox-master-sortby");
        if (storageValue) {
            this.setValue(storageValue);
        }
    },

    methods: {
        arrowType(value) {
            const { dir } = TemplatesSortBy[value];
            return dir === "asc" ? "md-arrow-round-up" : "md-arrow-round-down";
        },

        setValue(val) {
            this.sortBy = val;
            window.localStorage.setItem("hox-master-sortby", val);
            this.$emit("input", TemplatesSortBy[val]);
        }
    }
};
</script>
<style lang="scss">
@import "@/../sass/_variables.scss";

.master-templates-sort-by {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 155px;
    flex: 0 0 155px;
    margin-right: $spacing;

    &__label {
        white-space: nowrap;
        display: flex;
        margin-right: $spacing-smaller;
    }
}
</style>
