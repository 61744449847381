import { ApolloLink, Observable } from "apollo-link";
import { handlers } from "./handlers";
import { GraphQLError } from "./GraphQLError";

export class LocalDevLink extends ApolloLink {
    uri;

    constructor({ uri }) {
        super();
        this.uri = uri;
    }

    request(operation) {
        const { operationName } = operation;
        return new Observable(async observer => {
            try {
                const handler = handlers[operationName];
                if (!handler || typeof handler !== "function") {
                    throw new Error(`No local dev handler for: ${operationName}`);
                }
                // Fetch data from the Hoxton CLI
                const data = await handler(operation, this.uri);
                // Pass it down the link stack
                observer.next({ data });
            } catch (err) {
                // If the error is throw as a GraphQLError then we create a gql
                //  error response and pass it back down the stack
                if (err instanceof GraphQLError) {
                    observer.next({ errors: [err] });
                } else {
                    console.error(err);
                    observer.error(err);
                    return;
                }
            } finally {
                observer.complete();
            }
        });
    }
}
