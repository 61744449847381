<template>
    <div :class="classes">
        <div v-if="showLeftArrow" class="imagine-carousel-arrow left" @click="switchPage(-1)">
            <Icon type="ios-arrow-back" size="24"></Icon>
        </div>
        <campaign-data-provider
            v-if="startSearch"
            :key="searchOptions.searchId + '-' + refreshCounter"
            :refresh-counter="refreshCounter"
            :options="requestOptions"
            @data="onData"
            @loading="onLoading"
            @error="onError"
        />

        <hox-alert v-if="errorMessage" type="danger" size="small" margin-top="none" margin-bottom="none">
            <template #title>
                <span :title="errorMessage">
                    Sorry, the generated content was rejected by our automated content moderation system.
                </span>
            </template>
            <template #content></template>
            <template #actionItems>
                <Button type="primary" @click="forceRefetch">Try again</Button>
            </template>
        </hox-alert>

        <div v-else-if="showImages" class="search-preview__images">
            <div v-for="outputId in outputImgCount" :key="outputId" class="search-preview__image">
                <hox-loading-spinner
                    v-if="isLoading"
                    :size="70"
                    class="search-preview__image-loader"
                    icon="wish-icon"
                />
                <template v-else>
                    <div v-if="hasHoverOptions" class="search-preview__image-hover">
                        <slot name="hoverOptions">
                            <Checkbox
                                v-if="hoverOptions.showAddToLibraryTop"
                                class="search-preview__add-library"
                                @on-change="onAddToCreativeLirbary(getImageUrl(outputId))"
                            >
                                Add to library
                            </Checkbox>
                            <a
                                v-if="hoverOptions.newWindow"
                                class="ivu-btn search-preview__button"
                                :href="getImageUrl(outputId)"
                                target="_blank"
                            >
                                Open in new window
                            </a>
                            <Button
                                v-if="showSetAsBase"
                                class="search-preview__button"
                                @click="setBaseImage(getImageUrl(outputId))"
                            >
                                Set as Base Image
                            </Button>
                            <Button v-if="hoverOptions.showEdit" @click="onEditImage(getImageUrl(outputId))">
                                Edit Image
                            </Button>
                            <Button v-if="hoverOptions.showAddToLibrary" @click="onEditImage(getImageUrl(outputId))">
                                Add to Library
                            </Button>
                        </slot>
                    </div>
                    <img class="search-preview__image-img" :src="getImageUrl(outputId)" />
                </template>
            </div>
        </div>
        <div v-else-if="showAudio" class="search-preview__audio">
            <template>
                <div class="search-preview__audio-icon">
                    <hox-loading-spinner
                        v-if="isLoading"
                        :size="70"
                        class="search-preview__image-loader"
                        icon="wish-icon"
                    />
                    <Icon v-else type="ios-mic" size="80" />
                </div>
                <div class="search-preview__audio-controls">
                    <audio v-if="isAudioReady" controls :src="audioUrl" class="search-preview__audio-controls-el">
                        <a :href="audioUrl">Download audio</a>
                    </audio>
                </div>
            </template>
        </div>
        <div v-if="showRightArrow" class="imagine-carousel-arrow right" @click="switchPage(1)">
            <Icon type="ios-arrow-forward" size="24"></Icon>
        </div>
    </div>
</template>
<script>
import { ModelConfig, ModelOutput, ImagineModel, ResourceTypeByModel, getPlayHTRandomVoice } from "@/enums/imagine";
import CampaignDataProvider from "@/components/Campaign/CampaignDataProvider";

export default {
    name: "SearchPreview",
    components: { CampaignDataProvider },
    props: {
        hoverOptions: {
            type: Object
        },

        searchOptions: {
            type: Object
        },

        startSearch: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            audioUrl: null,
            isAudioReady: false,
            isLoading: true,
            data: null,
            searchStart: null,
            searchEnd: null,
            searchDuration: 0,
            errorMessage: "",
            refreshCounter: 0,
            imageCarouselItems: [],
            useCustomOptions: false,
            savedCustomOptions: {},
            page: 1
        };
    },

    computed: {
        classes() {
            const classes = ["search-preview"];

            if (this.isLoading) {
                classes.push("search-preview--loading");
            }
            return classes;
        },

        hasHoverOptions() {
            return Object.keys(this.hoverOptions).length;
        },

        outputImgCount() {
            if (this.searchOptions.resultsNumber) {
                return this.searchOptions.resultsNumber;
            }

            // todo: clean this up.
            if (ResourceTypeByModel[this.searchOptions.model] === "COG") {
                return this.searchOptions.num_outputs;
            }

            return this.searchOptions.num_images;
        },

        requestOptions() {
            const requestOptions = ModelConfig[this.searchOptions.model].fieldsToRequest(
                this.searchOptions,
                this.refreshCounter
            );

            if (
                this.searchOptions.model === ImagineModel.PlayHT &&
                (!this.searchOptions.voice || this.searchOptions.voice === "random")
            ) {
                requestOptions.input.voice = getPlayHTRandomVoice();
            }

            if (!this.useCustomOptions) {
                return requestOptions;
            }

            if (this.searchOptions.model === ImagineModel.StableDiffusion) {
                requestOptions.input.predict = { ...requestOptions.input.predict, ...this.savedCustomOptions };
            } else {
                const { voice, ...voiceRequestOptions } = this.savedCustomOptions;
                requestOptions.input = { ...requestOptions.input, ...voiceRequestOptions };
            }

            return requestOptions;
        },

        showImages() {
            return ModelOutput[this.searchOptions.model] === "images";
        },

        showAudio() {
            return ModelOutput[this.searchOptions.model] === "audio";
        },

        showSetAsBase() {
            return this.searchOptions.model !== ImagineModel.Dalle2 && this.hoverOptions.setAsBase;
        },

        showLeftArrow() {
            if (this.isLoading) {
                return false;
            }

            if (this.errorMessage !== "") {
                return false;
            }

            return this.hoverOptions.showArrows && this.page > 1;
        },

        showRightArrow() {
            if (this.isLoading) {
                return false;
            }

            if (this.errorMessage !== "") {
                return false;
            }

            return this.hoverOptions.showArrows;
        }
    },

    created() {
        this.savedCustomOptions = {
            ...this.$store.state.imagine.advancedOptions[this.searchOptions.model]
        };
        this.useCustomOptions = this.$store.state.imagine.useAdvancedOptions;
    },

    methods: {
        onAddToCreativeLirbary(image) {
            this.$emit("addImageToLibrary", image);
        },

        forceRefetch() {
            this.errorMessage = "";
            this.isLoading = true;
            this.refreshCounter += 1;
        },

        onData(search) {
            const initAudio = ModelOutput[this.searchOptions.model] === "audio" && !this.data;
            if (!this.imageCarouselItems[this.refreshCounter]) {
                this.imageCarouselItems[this.refreshCounter] = search;
            }
            this.data = search;
            this.$emit("imageInfo", search.output.success[0]);

            if (initAudio) {
                this.startAudio();
            }

            if (search.output.fail) {
                this.errorMessage = search.output.fail;
                this.isLoading = false;
            }

            this.$emit("complete");
        },

        onEditImage(url) {
            this.$emit("editImage", { url, searchOptions: this.searchOptions });
        },

        onError({ message }) {
            if (typeof message === "string") {
                this.errorMessage = message;
            }

            if (message.errorMessage) {
                this.errorMessage = message.errorMessage;
            }

            this.isLoading = false;
            this.$emit("complete");
        },

        onLoading(loading) {
            this.isLoading = loading;
        },

        setBaseImage(url) {
            this.$emit("baseImage", url);
        },

        async startAudio() {
            this.audioUrl = this.data.output.success[0];
            this.isAudioReady = true;
        },

        switchPage(dir) {
            if (this.page + dir < 1) {
                return;
            }
            this.page = this.page + dir;
            if (dir === 1) {
                if (this.imageCarouselItems[this.page - 1]) {
                    this.data = this.imageCarouselItems[this.page - 1];
                    return;
                }

                this.refreshCounter += 1;
                this.requestOptions.input.searchId = `${this.searchOptions.searchId}-${this.refreshCounter}`;
                return;
            }

            this.data = this.imageCarouselItems[this.page - 1];
        },

        getImageUrl(id) {
            if (this.searchOptions.cachedImage !== undefined) {
                return this.searchOptions.cachedImage;
            }

            return this.data.output.success[id - 1];
        }
    }
};
</script>
<style lang="scss">
@import "../../../../sass/_variables.scss";

.search-preview {
    flex: 1 1 24%;
    margin: auto 0;
    max-height: 100%;
    &__images,
    &__audio {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &__audio {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        border: 1px solid #e8eaec;
        border-radius: 4px;
        height: 100%;

        &-icon {
            width: 100%;
            aspect-ratio: 1 / 1;
            height: calc(100% - 54px);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-controls {
            width: calc(100% - 20px);
            display: flex;
            justify-content: flex-start;
            position: relative;
            bottom: 5px;
            left: 10px;

            &-el {
                width: 100%;
            }
        }
    }

    &__button {
        display: flex;
        align-items: center;
        margin-bottom: $spacing-small;
    }
    &__add-library {
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px;
        width: 100%;
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
    }

    &--loading {
        .search-preview__image {
            border: 1px solid #e8eaec;
            border-radius: 4px;
        }
    }

    &:hover {
        .imagine-carousel-arrow {
            visibility: visible;
        }
    }
}
.search-preview__image {
    flex: 1 1 24%;
    object-fit: contain;
    aspect-ratio: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &-img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        max-height: 500px;
    }

    &:hover .search-preview__image-hover {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        a,
        button {
            @media (max-width: 1200px) {
                font-size: 1vw;
            }
            @media (max-width: 800px) {
                font-size: 10px;
            }
        }
    }
}

.search-preview__image-hover {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffffff66;
    width: 100%;
    height: 100%;
}
</style>
