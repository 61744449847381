<template>
    <div :class="classes">
        <label :class="labelClasses" @click="onChange(!value)">
            <slot />
        </label>
        <i-switch :loading="loading" :size="size" :value="value" @on-change="onChange" />
    </div>
</template>
<script>
export default {
    name: "LabelledSwitch",
    props: {
        loading: {
            type: Boolean
        },
        size: {
            validator(value) {
                return ["small", "large"].includes(value);
            }
        },
        type: {
            validator(value) {
                return ["default", "success", "info"].includes(value);
            }
        },
        value: {
            required: true,
            type: Boolean
        }
    },
    computed: {
        classes() {
            const classes = ["labelled-switch"];

            if (this.type) {
                classes.push(`labelled-switch--${this.type}`);
            }

            return classes;
        },
        labelClasses() {
            const classes = ["labelled-switch__label"];
            if (!this.value) {
                classes.push("labelled-switch__label--off");
            }
            return classes;
        }
    },
    methods: {
        onChange(value) {
            if (!this.isLoading) {
                this.$emit("change", value);
                this.$emit("input", value);
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.labelled-switch {
    align-items: center;
    display: inline-flex;
    flex: 1 0;
}

.labelled-switch--success {
    align-items: center;
    display: inline-flex;
    flex: 1 0;

    .ivu-switch-checked {
        background: $success-color;
        border-color: $success-color;

        &:focus {
            box-shadow: 0 0 0 2px rgba($success-color, 0.2);
        }
    }
}

.labelled-switch--info {
    align-items: center;
    display: inline-flex;
    flex: 1 0;

    .ivu-switch-checked {
        background: $info-color;
        border-color: $info-color;

        &:focus {
            box-shadow: 0 0 0 2px rgba($info-color, 0.2);
        }
    }
}

.labelled-switch__label {
    cursor: pointer;
    padding: 0 $spacing-smaller 0 0;
    transition: color 0.2s linear;
}

.labelled-switch__label--off {
    color: $grey5;
}
</style>
