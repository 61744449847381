<template>
    <video ref="video" class="ae-banner__preview-video" muted>
        <source :src="src" type="video/mp4" />
    </video>
</template>

<script>
import bus from "@/bus";
import { BannerVideoActions, BannerVideoEvents, BannerScrubbarEvents, BannerEvents } from "@/enums/banners";

export default {
    name: "AEVideo",

    props: {
        src: {
            required: true,
            type: String
        },
        id: {
            required: true,
            type: String
        }
    },

    data() {
        return {
            currentIntervalId: null,
            currentTime: null
        };
    },

    mounted() {
        bus.$on(BannerVideoActions.Play, this.play);
        bus.$on(BannerVideoActions.Pause, this.pause);
        bus.$on(BannerVideoActions.Reset, this.reset);

        bus.$on(BannerScrubbarEvents.Scrubbing, this.onScrub);
        bus.$on(BannerScrubbarEvents.ScrubUpdateValue, this.seek);

        this.$refs.video.addEventListener("loadeddata", () => {
            bus.$emit(BannerEvents.AEVideoReady, { id: this.id, duration: this.$refs.video.duration });
        });

        this.$refs.video.addEventListener("ended", () => {
            this.stopMonitoringTime();
            bus.$emit(BannerVideoEvents.Ended, this.id);
        });
    },

    beforeDestroy() {
        this.stopMonitoringTime();

        bus.$off(BannerVideoActions.Play, this.play);
        bus.$off(BannerVideoActions.Pause, this.pause);
        bus.$off(BannerVideoActions.Reset, this.reset);

        bus.$off(BannerScrubbarEvents.Scrubbing, this.onScrub);
        bus.$off(BannerScrubbarEvents.ScrubUpdateValue, this.seek);
    },

    methods: {
        onScrub() {
            this.pause();
        },

        seek(time) {
            this.$refs.video.currentTime = time;
        },

        reset(id) {
            if (id !== this.id) {
                return;
            }

            this.seek(0);
            this.play(id);
        },

        play(id) {
            if (id !== this.id) {
                return;
            }

            this.startMonitoringTime();

            this.$refs.video.play();
        },

        pause(id) {
            if (id !== this.id) {
                return;
            }

            this.stopMonitoringTime();

            this.$refs.video.pause();
        },

        startMonitoringTime() {
            this.stopMonitoringTime();

            this.currentIntervalId = setInterval(() => this.updateCurrentTime(), 200);
        },

        stopMonitoringTime() {
            if (this.currentIntervalId) {
                clearInterval(this.currentIntervalId);
            }
        },

        updateCurrentTime() {
            if (this.$refs.video.currentTime === this.currentTime) {
                return;
            }

            this.currentTime = this.$refs.video.currentTime;

            bus.$emit(BannerVideoEvents.TimeUpdate, {
                id: this.id,
                currentTime: this.currentTime
            });
        }
    }
};
</script>

<style>
.ae-banner__preview-video {
    width: 100%;
}
</style>
