const providers = {
    Hogarth: ["hogarthww.com", "hogarth.com"],
    Mindshare: ["mindshareworld.com"],
    WPP: ["wpp.com"],
    WPPCoretech: ["wppcoretech.com"],
    Wunderman: ["jwt.com"],
    ACME: ["mailinator.com"],
    OMG: ["phdmedia.com", "annalect.com"],
    GTB: ["gtb.com"]
};

const providerKeys = Object.keys(providers);

export const getProvider = email => {
    // we want to disable standard SSO behaviour for those stacks
    // nosso stack should be used for testing any changes to SSO configs
    if (["analytics", "imagine", "nosso"].includes(process?.env?.CLIENT_NAME)) {
        return undefined;
    }

    const domain = email.split("@").pop();
    return providerKeys.find(p => providers[p].includes(domain));
};

export default providers;
