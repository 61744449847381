<template>
    <data-filter
        class="client-sort data-sort"
        :size="size"
        :label="label"
        :label-width="100"
        :show-reset-filter="false"
        :options="options"
        :value="defaultValue"
        v-on="$listeners"
    >
        <template #options="slotProps">
            <OptionGroup v-for="option in slotProps.options" :key="option.field">
                <Option
                    class="data-filter__option"
                    :value="option.ascValue"
                    :label="`${option.field} ${option.ascOptionDirection}`"
                >
                    {{ option.field }}
                    <span class="data-sort__direction">
                        {{ option.ascOptionDirection }}
                    </span>
                </Option>
                <Option
                    class="data-filter__option"
                    :value="option.descValue"
                    :label="`${option.field} ${option.descOptionDirection}`"
                >
                    {{ option.field }}
                    <span class="data-sort__direction">
                        {{ option.descOptionDirection }}
                    </span>
                </Option>
            </OptionGroup>
        </template>
    </data-filter>
</template>
<script>
import DataFilter from "@/components/DataFilter";

export default {
    name: "DataSortFilter",
    components: { DataFilter },
    props: {
        defaultValue: {
            type: String,
            required: true
        },
        label: {
            type: String
        },
        options: {
            type: Array
        },
        size: {
            type: String,
            default: "large"
        }
    }
};
</script>
