<template>
    <div />
</template>

<script>
import { fetcher } from "@/components/Reporting/data/utils";
import { Bucket } from "@/components/Reporting/data/constants";
import { CreativeInsightsGetters } from "@/store/modules/creativeInsights";

const queryEndpoint = "reports-with-deliverable-info";

export default {
    name: "CreativeInsightsDataProvider",

    props: {
        filterQuery: {
            type: String,
            default: ""
        },

        kpiOrder: {
            type: String,
            default: "asc"
        },

        nextPageToken: {
            type: String,
            default: ""
        },

        reportId: {
            type: String,
            default: ""
        }
    },

    data() {
        return {
            isLoading: false
        };
    },

    computed: {
        baseOptions() {
            return {
                workerId: this.workerId
            };
        },

        staticBreakdown() {
            return this.$store.state.creativeInsights.selectedBreakdown;
        },

        fetcherOptions() {
            return {
                ...this.baseOptions,
                ...(this.staticBreakdown && {
                    staticBreakdown: encodeURIComponent(this.staticBreakdown)
                }),
                ...(this.filterQuery && { filterQuery: encodeURIComponent(this.filterQuery) }),
                kpi: this.kpi,
                kpiOrder: this.kpiOrder,
                pageSize: 20,
                ...(this.reportId && {
                    reportId: this.reportId
                }),
                storageBucket: Bucket
            };
        },

        fetchNextOptions() {
            return {
                ...this.baseOptions,
                ...(this.staticBreakdown && {
                    staticBreakdown: encodeURIComponent(this.staticBreakdown)
                }),
                filterQuery: encodeURIComponent(this.filterQuery),
                kpi: this.kpi,
                kpiOrder: this.kpiOrder,
                nextPageToken: this.nextPageToken,
                pageSize: 20,
                ...(this.reportId && {
                    reportId: this.reportId
                }),
                storageBucket: Bucket
            };
        },

        kpi() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricName];
        },

        workerId() {
            return this.$store.state.creativeInsights.workerId;
        }
    },

    watch: {
        filterQuery() {
            this.fetch();
        },

        kpi() {
            this.fetch();
        },

        kpiOrder() {
            this.fetch();
        },

        nextPageToken() {
            this.fetchNext();
        },

        staticBreakdown() {
            this.fetch();
        }
    },

    mounted() {
        this.fetch();
    },

    methods: {
        async fetch() {
            try {
                this.isLoading = true;
                this.$emit("loading", this.isLoading);
                const response = await fetcher(queryEndpoint, this.fetcherOptions);
                const responseJson = await response.json();
                this.$emit("data", responseJson);
            } catch (e) {
                this.$emit("error", e);
            } finally {
                this.isLoading = false;
                this.$emit("loading", this.isLoading);
            }
        },

        async fetchNext() {
            try {
                this.isLoading = true;
                this.$emit("loadingNext", this.isLoading);
                const response = await fetcher(queryEndpoint, this.fetchNextOptions);
                const responseJson = await response.json();
                this.$emit("next", responseJson);
            } catch (e) {
                this.$emit("error", e);
            } finally {
                this.isLoading = false;
                this.$emit("loadingNext", this.isLoading);
            }
        }
    }
};
</script>

<style scoped></style>
