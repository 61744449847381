<template>
    <div class="sidebar">
        <portal to="main-sidebar">
            <div v-if="scroll" :key="scroll" v-bar class="sidebar-body" :class="{ 'sidebar-body--scroll': scroll }">
                <!-- el1 -->
                <div class="sidebar-innerbody">
                    <!-- el2 -->
                    <slot></slot>
                </div>
                <!-- dragger will be automatically added here -->
            </div>
            <div v-else class="sidebar-body">
                <slot></slot>
            </div>
        </portal>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Sidebar",
    props: {
        scroll: {
            type: Boolean,
            default: true
        }
    }
};
</script>

<style lang="scss">
@import "../../sass/variables";

.sidebar-body {
    height: calc(100vh);
    width: 100%;

    &--scroll {
        position: sticky !important;
        top: 0;
    }
}
</style>
