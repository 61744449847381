<template>
    <div class="imagine-preview">
        <imagine-data-provider
            v-if="startSearch"
            :key="searchOptions.searchId + '-' + refreshCounter"
            :options="requestOptions"
            :refresh-counter="refreshCounter"
            @data="onData"
            @loading="onLoading"
            @error="onError"
        />

        <hox-alert v-if="errorMessage" type="danger" size="small" margin-top="none" margin-bottom="none">
            <template #title>
                <span :title="errorMessage">
                    Sorry, the generated content was rejected by our automated content moderation system.
                </span>
            </template>
            <template #content></template>
            <template #actionItems>
                <Button type="primary" @click="forceRefetch">Try again</Button>
            </template>
        </hox-alert>
        <div v-else>
            <div v-if="showLeftArrow" class="imagine-carousel-arrow left" @click="switchPage(-1)">
                <Icon type="ios-arrow-back" size="24"></Icon>
            </div>
            <div v-if="isLoading" class="imagine-preview__result-loading" @click="onResultClick">
                <hox-loading-spinner :size="70" icon="wish-icon" />
            </div>
            <div v-else class="imagine-preview__result" @click="onResultClick">
                <div class="imagine-preview__overlay">
                    <div v-show="showHeadline" class="imagine-preview__headline imagine-preview__result-element">
                        <h3>Headline</h3>
                        <p>
                            {{ headline }}
                        </p>
                        <read-text :text="headline" />
                    </div>
                    <div v-show="showCTA" class="imagine-preview__cta imagine-preview__result-element">
                        <h3>Call To Action</h3>
                        <p>
                            {{ cta }}
                        </p>
                        <read-text :text="cta" />
                    </div>
                    <div v-show="showImagePrompt" class="gpt3-preview__image-prompt imagine-preview__result-element">
                        <h3>Image</h3>
                        <p>{{ imagePrompt }}</p>
                    </div>
                </div>
                <search-preview
                    v-show="showImage"
                    v-if="imageSearchOptions"
                    :hover-options="hoverOptions"
                    :search-options="imageFields"
                    :start-search="startImageSearch"
                    class="gpt3-preview__image imagine-preview__result-element"
                    @imageInfo="onImageInfo"
                />
            </div>
            <div v-if="showRightArrow" class="imagine-carousel-arrow right" @click="switchPage(1)">
                <Icon type="ios-arrow-forward" size="24"></Icon>
            </div>
        </div>
    </div>
</template>
<script>
import { GPT3FieldsSequence, ModelConfig, ImagineModel, ImagineModelToLabel, sanitizeGPTParams } from "@/enums/imagine";
import ImagineDataProvider from "@/components/Imagine/ImagineDataProvider";
import SearchPreview from "@/components/Imagine/ModelPreview/SearchPreview";
import ReadText from "@/components/Imagine/ReadText";
import { v4 as uuidv4 } from "uuid";
import { matchLine } from "@/utils";

export default {
    name: "Gpt3Preview",
    components: { ReadText, SearchPreview, ImagineDataProvider },
    props: {
        fieldIndex: {
            type: Number
        },

        searchOptions: {
            type: Object
        },

        startSearch: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            blockedHeadlines: ['"New Headline here".', '"This is not a valid input. Please enter a headline"'],
            carouselImages: [],
            clicker: 0,
            cta: undefined,
            data: null,
            errorMessage: "",
            headline: undefined,
            hoverOptions: {
                newWindow: true,
                setAsBase: false
            },
            imageCarouselItems: [],
            imagePrompt: undefined,
            isLoading: true,
            page: 1,
            refreshCounter: 0,
            renderModel: ImagineModel.StableDiffusion,
            savedCreativeAiOptions: {},
            searchDuration: 0,
            searchEnd: null,
            searchStart: null,
            startImageSearch: true,
            useAdvancedOptions: true
        };
    },
    computed: {
        imageFields() {
            return {
                prompt: this.imagePrompt,
                model: this.renderModel,
                resultsNumber: 1,
                searchId: this.searchOptions.searchId,
                cachedImage: this.carouselImages[this.page - 1]
            };
        },

        imageSearchOptions() {
            if (!this.imagePrompt) {
                return null;
            }

            return ModelConfig[this.renderModel].fieldsToRequest(this.imageFields);
        },

        requestOptions() {
            const requestOptions = ModelConfig[this.searchOptions.model].fieldsToRequest(
                this.searchOptions,
                this.refreshCounter
            );
            if (this.useAdvancedOptions && this.savedCreativeAiOptions) {
                requestOptions.input = { ...requestOptions.input, ...this.savedCreativeAiOptions };
            }
            if (this.promptText && this.promptText !== this.searchOptions.prompt) {
                requestOptions.input.prompt = this.creativeAiParsedText;
            }
            requestOptions.input.prompt = requestOptions.input.prompt
                .replace("$input1$", "")
                .replace("$input2$", "")
                .replace("$input3$", "");

            requestOptions.input = sanitizeGPTParams(requestOptions.input);

            return requestOptions;
        },

        promptText() {
            if (this.useAdvancedOptions && this.savedCreativeAiOptions) {
                return this.savedCreativeAiOptions.text;
            }
            return this.searchOptions.text;
        },

        showHeadline() {
            return this.clicker % GPT3FieldsSequence.length === GPT3FieldsSequence.indexOf("Headline");
        },

        showCTA() {
            return this.clicker % GPT3FieldsSequence.length === GPT3FieldsSequence.indexOf("CTA");
        },

        showImagePrompt() {
            return this.clicker % GPT3FieldsSequence.length === GPT3FieldsSequence.indexOf("Image");
        },

        showImage() {
            return this.clicker % GPT3FieldsSequence.length === GPT3FieldsSequence.indexOf("Image");
        },

        showLeftArrow() {
            if (this.isLoading) {
                return false;
            }

            if (this.errorMessage !== "") {
                return false;
            }

            return this.page > 1;
        },

        showRightArrow() {
            if (this.isLoading) {
                return false;
            }

            if (this.errorMessage !== "") {
                return false;
            }

            return true;
        },

        creativeAiParsedText() {
            let editedText = this.promptText;

            if (this.searchOptions.prompt) {
                editedText = editedText.replace("$input1$", this.searchOptions.prompt);
            }

            if (this.searchOptions.row) {
                editedText = editedText.replace("$input2$", this.searchOptions.row);
            }

            if (this.searchOptions.column) {
                editedText = editedText.replace("$input3$", this.searchOptions.column);
            }

            return editedText;
        }
    },

    watch: {
        fieldIndex: {
            handler() {
                this.clicker = this.fieldIndex || 0;
            },
            immediate: true
        }
    },

    created() {
        this.modelToLabel = ImagineModelToLabel;
        this.savedCreativeAiOptions = { ...this.$store.state.imagine.advancedOptions[ImagineModel.GPT3] };
        this.renderModel =
            this.$store.state.imagine.advancedOptions[ImagineModel.GPT3]?.renderModel || ImagineModel.StableDiffusion;
        this.useAdvancedOptions = this.$store.state.imagine.useAdvancedOptions;
    },

    methods: {
        onData(search) {
            if (!this.imageCarouselItems[this.refreshCounter]) {
                this.imageCarouselItems[this.refreshCounter] = search;
            }
            this.data = search;

            this.parseResponseText();
            if (this.blockedHeadlines.includes(this.headline)) {
                this.errorMessage = "This headline is on the blocked headlines list.";
            }
            if (this.headline.length === 0) {
                this.errorMessage = "This response was not correctly generated.";
            }
            this.$emit("complete");
        },

        onError({ message }) {
            this.errorMessage = message.errorMessage;
            if (typeof message === "string") {
                this.errorMessage = message;
            }
            this.isLoading = false;
            this.$emit("complete");
        },

        onLoading(loading) {
            this.isLoading = loading;
        },

        onResultClick() {
            this.clicker += 1;
        },

        parseResponseText() {
            const response = this.data.output.success.choices[0].text.trim();
            try {
                const parsed = JSON.parse(response);
                this.headline = parsed.headline;
                this.cta = parsed.callToAction;
                this.imagePrompt = parsed.suggestedImage;
            } catch (e) {
                const lines = this.data.output.success.choices[0].text.split("\n");

                this.headline = matchLine(lines, '"headline": ').trim();
                this.cta = matchLine(lines, '"callToAction": ');
                this.imagePrompt = matchLine(lines, '"suggestedImage": ');
            }
        },

        forceRefetch() {
            this.errorMessage = "";
            this.refreshCounter += 1;
        },

        switchPage(dir) {
            if (this.page + dir < 1) {
                return;
            }
            this.page = this.page + dir;
            this.startImageSearch = true;
            if (this.carouselImages[this.page - 1]) {
                this.startImageSearch = false;
            }

            if (dir === 1) {
                if (this.imageCarouselItems[this.page - 1]) {
                    this.data = this.imageCarouselItems[this.page - 1];
                    this.parseResponseText();
                    return;
                }

                this.refreshCounter += 1;
                this.startImageSearch = true;
                this.requestOptions.input.uuid = uuidv4();
                return;
            }
            this.data = this.imageCarouselItems[this.page - 1];
            this.parseResponseText();
        },

        onImageInfo(url) {
            if (!this.carouselImages[this.page - 1]) {
                this.carouselImages[this.page - 1] = url;
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.gpt3-preview {
    &__image-prompt {
        background: rgba($color: #000000, $alpha: 0.5);
        color: $white;
        opacity: 0;

        &:hover {
            opacity: 1;
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
}
</style>
