export default (r, g, b, in16BitMode = false) => {
    return `#${[r, g, b]
        .map(x => {
            if (!in16BitMode) {
                return x.toString(16).padStart(2, "0");
            }
            // If using 16 bit mode we need to double the hex from an 8 bit channel
            // If the value are doubling is 2^16 we minus 1 as 0 is a possible value
            const value = x ? x * 2 : x;
            return (value === 2 ** 16 ? value - 1 : value)
                .toString(16)
                .padStart(4, "0")
                .match(/.{2}/g)
                .pop();
        })
        .join("")}`;
};
