// eslint-disable-next-line import/no-extraneous-dependencies
import { SuperAdminRole } from "shared-utils/enums/user";

const storeNamespace = "auth";

const InternalAuthMutation = {
    SetAuthConfig: "setAuthConfig",
    SetCreated: "setCreated",
    SetIsAuthorized: "setIsAuthorized",
    SetMe: "setMe",
    SetUser: "setUser",
    SetContentGenerationApiInfo: "setContentGenerationApiInfo"
};

export const AuthMutation = {
    SetAuthConfig: `${storeNamespace}/${InternalAuthMutation.SetAuthConfig}`,
    SetCreated: `${storeNamespace}/${InternalAuthMutation.SetCreated}`,
    SetIsAuthorized: `${storeNamespace}/${InternalAuthMutation.SetIsAuthorized}`,
    SetMe: `${storeNamespace}/${InternalAuthMutation.SetMe}`,
    SetUser: `${storeNamespace}/${InternalAuthMutation.SetUser}`,
    SetContentGenerationApiInfo: `${storeNamespace}/${InternalAuthMutation.SetContentGenerationApiInfo}`
};

export const AuthGetters = {
    hasScope: `${storeNamespace}/hasScope`,
    isSuperAdmin: `${storeNamespace}/isSuperAdmin`,
    userCan: `${storeNamespace}/userCan`,
    userHasAction: `${storeNamespace}/userHasAction`,
    contentGenerationApiInfo: `${storeNamespace}/contentGenerationApiInfo`
};

const isSuperAdmin = state => state.me && state.me.role && state.me.role.name === SuperAdminRole;

export default {
    namespaced: true,
    state: {
        isAuthorized: false,
        me: null,
        user: null,
        contentGenerationApiInfo: "",
        authConfig: {}
    },

    getters: {
        // eslint-disable-next-line complexity
        hasScope:
            state =>
            (scope, exact = true) => {
                // If the user is a Super Admin grant full access
                if (isSuperAdmin(state)) {
                    return true;
                }
                // If actions on the is an array then look for the passed action otherwise permit no access
                const hasClientScope = !!(state.me.scope.client && state.me.scope.client.includes(scope.clientId));

                if (typeof scope.campaignId === "undefined" && exact) {
                    return hasClientScope;
                }

                let hasCampaignScope = !!(
                    state.me.scope.campaign && state.me.scope.campaign.includes(scope.campaignId)
                );

                if (!exact && !hasCampaignScope) {
                    hasCampaignScope = !!(
                        state.me.scope.campaignOnlyClient && state.me.scope.campaignOnlyClient.includes(scope.clientId)
                    );
                }

                return hasClientScope || hasCampaignScope;
            },

        isSuperAdmin,
        userCan: (state, getters) => action => {
            // If the user is a Super Admin grant full access
            if (isSuperAdmin(state)) {
                return true;
            }
            return getters.userHasAction(action);
        },

        contentGenerationApiInfo(state) {
            return state.contentGenerationApiInfo;
        },

        userHasAction: state => action =>
            state.me && state.me.role && Array.isArray(state.me.role.actions) && state.me.role.actions.includes(action)
    },

    mutations: {
        setAuthConfig(state, payload) {
            state.authConfig = payload.authConfig;
        },

        setIsAuthorized(state, payload) {
            state.isAuthorized = payload.authorized;
        },

        setMe(state, payload) {
            state.me = payload.me;
        },

        setUser(state, payload) {
            state.user = payload.user;
        },

        setContentGenerationApiInfo(state, payload) {
            state.contentGenerationApiInfo = payload.contentGenerationApiInfo;
        }
    }
};
