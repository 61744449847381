import jss from "json-stable-stringify";
import md5 from "md5";

export default {
    /**
     * Generate an unique hash identifying the deliverableIdentity
     * @param deliverableIdentity
     * @throws Error - when the required fields are not present
     * @returns {*}
     */
    getIdHash(deliverableIdentity) {
        return md5(this.getIdHashOpen(deliverableIdentity));
    },
    getIdHashOpen(deliverableIdentity) {
        const sortedIdentity = ({ editableGroupValueIds, ...identity }) => ({
            editableGroupValueIds: editableGroupValueIds.sort(),
            ...identity
        });
        return jss(sortedIdentity(deliverableIdentity));
    },
    getIdHashFromObject(object) {
        const deliverableIdentity = this.getDeliverableIdentityFromObject(object);
        return this.getIdHash(deliverableIdentity);
    },
    getIdHashOpenFromObject(object) {
        const deliverableIdentity = this.getDeliverableIdentityFromObject(object);
        return this.getIdHashOpen(deliverableIdentity);
    },
    getDeliverableIdentityFromObject(object) {
        return (({ language, masterTemplateId, editableGroupValueIds }) => ({
            language,
            masterTemplateId,
            editableGroupValueIds
        }))(object);
    }
};
