<template>
    <Modal v-model="modal" :class="{ 'alert-modal': true, warning: warning }" :width="width" @on-cancel="cancel">
        <div v-if="header" slot="header">
            <h3>{{ header }}</h3>
        </div>
        <div class="alert-modal__icon-wrapper">
            <Icon type="md-alert" class="alert-modal__alert-icon"></Icon>
        </div>
        <div class="alert-modal__content-wrapper">
            <h4 v-if="title" class="alert-modal__title">{{ title }}</h4>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p class="alert-modal__content" v-html="content"></p>
        </div>
        <div slot="footer">
            <slot name="footer">
                <Button v-if="showCancel" @click="cancel">{{ cancelText }}</Button>
                <Button :type="warning ? 'warning' : 'error'" @click="ok">{{ okText }}</Button>
            </slot>
        </div>
    </Modal>
</template>

<script>
// todo rewrite using passThrough mixin and ModalVisibleMixin
export default {
    name: "AlertModal",
    props: {
        value: {
            type: Boolean,
            required: true
        },
        header: {
            type: String
        },
        title: {
            type: String
        },
        content: {
            type: String,
            required: true
        },
        okText: {
            type: String,
            default: "Ok"
        },
        cancelText: {
            type: String,
            default: "Cancel"
        },
        onOk: {
            type: Function
        },
        onCancel: {
            type: Function
        },
        width: {
            type: Number,
            default: 360
        },
        showCancel: {
            type: Boolean,
            default: true
        },
        warning: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            modal: this.value
        };
    },
    watch: {
        value() {
            this.modal = this.value;
        }
    },
    methods: {
        close() {
            this.modal = false;
            this.$emit("input", this.modal);
        },
        cancel() {
            if (this.onCancel) {
                this.onCancel();
            }

            this.close();
        },
        ok() {
            if (this.onOk) {
                this.onOk();
            }

            this.close();
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.alert-modal {
    color: $cmp-light-secondary-font-color;

    .ivu-modal-content {
        border-radius: 0;
        padding: 10px 5px;
    }

    .ivu-modal-header,
    .ivu-modal-footer {
        border: none;
    }

    .ivu-modal-header h2 {
        padding-left: 5px;
    }

    .ivu-modal-body {
        display: flex;
    }

    .ivu-modal-footer {
        .ivu-btn {
            padding: 5px 10px;
            margin-left: 5px;
        }
    }

    &__alert-icon {
        color: $error-color;
        font-size: 64px;
    }

    &__content-wrapper {
        padding: 3px 0 0 20px;
    }

    &.warning {
        .alert-modal__alert-icon {
            color: $warning-color;
        }
    }
}
</style>
