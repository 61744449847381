<template>
    <div class="message-content">
        <h3>Allowing content changes in the editor</h3>
        <p>
            As a user with the role "{{ roleName }}", you can decide what content can be edited by Editors in this
            campaign team.
        </p>
    </div>
</template>

<script>
export default {
    name: "EditableRestrictionMessageContent",
    props: {
        roleName: {
            type: String
        }
    }
};
</script>
