<template>
    <hox-modal class="a-class-test new-size-modal" @close="cancel">
        <template #header>Are you sure?</template>
        <template #footer>
            <div>
                <Button class="new-size-modal__footer-button" @click="cancel">Cancel</Button>
                <Button class="new-size-modal__footer-button" type="primary" :loading="isDeleting" @click="confirm">
                    {{ isDeleting ? "Deleting..." : "Delete" }}
                </Button>
            </div>
        </template>
    </hox-modal>
</template>
<script>
export default {
    name: "DeleteTagConfirmationModal",

    computed: {
        isDeleting() {
            return this.$store.state.ciTags.isDeleting;
        }
    },

    methods: {
        confirm() {
            this.$emit("confirm");
        },
        cancel() {
            this.$emit("cancel");
        }
    }
};
</script>
