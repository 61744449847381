<template>
    <div class="bar-chart creative-insights-chart">
        <div ref="boundaryElement" class="creative-insights-chart__chart-wrapper">
            <canvas ref="chart" class="horizontal-bar-chart__chart" :style="canvasStyle" />
            <div ref="popperAnchor" class="creative-insights-chart__tooltip" :style="tooltipStyle"></div>
        </div>
        <chart-tooltip ref="tooltip" :point-labels="tooltipLines" :image-urls="tooltipImageUrls" />
    </div>
</template>

<script>
import {
    Chart,
    BarElement,
    BarController,
    CategoryScale,
    LinearScale,
    RadialLinearScale,
    Title,
    Tooltip,
    PolarAreaController,
    ArcElement,
    LineElement,
    BubbleController,
    LineController,
    PieController
} from "chart.js";
import { WordCloudController, WordElement } from "chartjs-chart-wordcloud";
import { TreemapController, TreemapElement } from "chartjs-chart-treemap";
import { CreativeInsightsGetters } from "@/store/modules/creativeInsights";
import { getMetricStat, utilisationToValue } from "@/utils/creativeInteligence";
import { numberToShortKM } from "@/utils";
import { getMetricsLabel } from "@/enums/creativeInteligence";
import { ChartDisplayValue, ChartType, getPointColors } from "@/utils/chart";
import chartTooltipMixin from "@/mixins/chartTooltipMixin";
import ChartTooltip from "@/components/CreativeIntelligence/ChartTooltip";

Chart.register(
    ArcElement,
    BarController,
    BarElement,
    BarElement,
    BubbleController,
    CategoryScale,
    LinearScale,
    LineController,
    LineElement,
    PieController,
    PolarAreaController,
    RadialLinearScale,
    Title,
    Tooltip,
    TreemapController,
    TreemapElement,
    WordCloudController,
    WordElement
);

const typeToChartType = {
    [ChartType.BarChart]: "bar",
    [ChartType.DoughnutChart]: "doughnut",
    [ChartType.HorizontalBarChart]: "bar",
    [ChartType.PolarChart]: "polarArea",
    [ChartType.TreemapChart]: "treemap",
    [ChartType.WordCloudChart]: "wordCloud"
};

const ChartsWithLegend = [ChartType.PolarChart, ChartType.DoughnutChart];

const baseScaleConfig = {
    grid: {
        drawOnChartArea: false, // only want the grid lines for one axis to show up
        drawBorder: false,
        drawTicks: true
    },
    ticks: {
        display: true
    },
    title: {
        display: true
    }
};

const utlisationScaleConfig = {
    ...baseScaleConfig,
    title: {
        display: true,
        text: "% of Ads"
    },

    ticks: {
        display: true,
        callback: value => {
            return value > 100 ? "" : `${value}%`;
        }
    }
};

export default {
    name: "ChartPreview",
    components: { ChartTooltip },
    mixins: [chartTooltipMixin],
    props: {
        data: {
            type: Object
        },

        displayValue: {
            type: String,
            default: ChartDisplayValue.KpiStat
        },

        labelMap: {
            type: Object,
            default() {
                return {};
            }
        },

        maxHeight: {
            type: Number,
            default: 380
        },

        tag: {
            type: Object
        },

        type: {
            type: String
        }
    },

    data() {
        return {
            chart: undefined
        };
    },

    computed: {
        canvasStyle() {
            return {
                maxHeight: `${this.maxHeight}px`
            };
        },

        chartData() {
            return Object.keys(this.data).reduce((acc, group) => {
                if (this.data[group].summaryStats) {
                    acc[group] = this.data[group];
                }

                return acc;
            }, {});
        },

        chartOptionsByType() {
            if (this.type === "horizontalBarChart") {
                return {
                    indexAxis: "y"
                };
            }
            return {};
        },

        chartType() {
            if (typeToChartType[this.type]) {
                return typeToChartType[this.type];
            }

            return "bar";
        },

        datasets() {
            return ["kpiMetric"].map(metric => {
                let chartDataGenerator = this.generateBarChartData;

                if (this.chartType === "wordCloud") {
                    chartDataGenerator = this.generateWordCloudChartData;
                }

                const { backgroundColors, hoverBackgroundColors, values, creatives } = chartDataGenerator(metric);

                return {
                    label: getMetricsLabel(this[`${metric}sName`]),
                    creatives,
                    data: values,
                    key: "value",
                    fit: false,
                    backgroundColor: backgroundColors,
                    hoverBackgroundColor: hoverBackgroundColors,
                    borderWidth: 0
                };
            });
        },

        kpiMetricMax() {
            // 5% more than max or threshold
            return (
                (this.kpiMetrixOverallMax < this.kpiMetricValue ? this.kpiMetricValue : this.kpiMetrixOverallMax) * 1.05
            );
        },

        kpiMetricMin() {
            return 0;
        },

        kpiMetricsName() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricName];
        },

        kpiMetricsValue() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricValue];
        },

        kpiMetrixOverallMax() {
            const stat = getMetricStat(this.kpiMetricsName);

            return Math.max(...this.labels.map(label => this.chartData[label].summaryStats.kpiMetric[stat]));
        },

        labels() {
            return Object.keys(this.chartData);
        },

        displayLabels() {
            return this.labels.map(l => this.labelMap[l] || l).sort();
        },

        parsing() {
            // todo add enums
            // we need to swap the axis values
            if (this.type === "polarChart") {
                return {
                    key: "value"
                };
            }
            if (this.type === "horizontalBarChart") {
                return {
                    xAxisKey: "value",
                    yAxisKey: "label"
                };
            }
            return {
                xAxisKey: "label",
                yAxisKey: "value"
            };
        },

        scales() {
            const scales = {};

            const scaleConfig =
                this.displayValue === ChartDisplayValue.Utilisation
                    ? utlisationScaleConfig
                    : {
                          ...baseScaleConfig,
                          title: {
                              display: true,
                              text: getMetricsLabel(this.kpiMetricsName)
                          }
                      };

            if (this.type === "horizontalBarChart") {
                scales.x = scaleConfig;
            }
            if (this.type === "barChart") {
                scales.y = scaleConfig;
            }

            return scales;
        }
    },

    watch: {
        data() {
            this.chart.data.datasets = this.datasets;
            this.chart.data.labels = this.displayLabels;
            this.chart.options.scales = this.scales;
            this.chart.update();
        },

        type() {
            this.chart.data.datasets = this.datasets;
            this.chart.data.labels = this.displayLabels;
            this.chart.options.scales = this.scales;
            this.chart.update();
        }
    },

    mounted() {
        this.initChart();
    },

    methods: {
        generateBarChartData(metric = "kpiMetric") {
            return this.displayLabels.reduce(
                (acc, label, currentIndex) => {
                    const { backgroundColor, hoverBackgroundColor } = this.getPointColors(label, currentIndex);
                    const { deliverables, summaryStats } = this.chartData[label];

                    const value = {
                        value: this.getPointValue(metric, summaryStats),
                        label
                    };

                    return {
                        backgroundColors: [...acc.backgroundColors, backgroundColor],
                        hoverBackgroundColors: [...acc.hoverBackgroundColors, hoverBackgroundColor],
                        values: [...acc.values, value],
                        creatives: [...acc.creatives, deliverables]
                    };
                },
                {
                    backgroundColors: [],
                    hoverBackgroundColors: [],
                    values: [],
                    creatives: []
                }
            );
        },

        generateWordCloudChartData(metric = "kpiMetric") {
            const items = Object.keys(this.chartData);
            const values = items.map(label => {
                const { summaryStats } = this.chartData[label];
                const stat = getMetricStat(this[`${metric}sName`]);

                return summaryStats[metric][stat];
            });

            const maxValue = Math.max(...values);

            return this.displayLabels.reduce(
                (acc, label, currentIndex) => {
                    const { backgroundColor, hoverBackgroundColor } = this.getPointColors(label, currentIndex);
                    const { deliverables, summaryStats } = this.chartData[label];

                    const value = {
                        value: this.getPointValue(metric, summaryStats, maxValue),
                        label
                    };

                    return {
                        backgroundColors: [...acc.backgroundColors, backgroundColor],
                        hoverBackgroundColors: [...acc.hoverBackgroundColors, hoverBackgroundColor],
                        values: [...acc.values, value],
                        creatives: [...acc.creatives, deliverables]
                    };
                },
                {
                    backgroundColors: [],
                    hoverBackgroundColors: [],
                    values: [],
                    creatives: [],
                    fit: false
                }
            );
        },

        getPointColors(label, index) {
            return getPointColors(label, index);
        },

        getPointValue(metric, summaryStats, maxValue) {
            if (this.displayValue === ChartDisplayValue.Utilisation) {
                if (this.chartType === "wordCloud") {
                    return Math.round(summaryStats.utilisation * 50) + 10;
                }

                return utilisationToValue(summaryStats.utilisation);
            }

            const stat = getMetricStat(this[`${metric}sName`]);
            if (this.chartType === "wordCloud") {
                return Math.round((summaryStats[metric][stat] / (maxValue || 1)) * 10) + 10;
            }

            return summaryStats[metric][stat];
        },

        getTickString(v, mprecision = 0, kprecision = 0) {
            return numberToShortKM(v, mprecision, kprecision);
        },

        initChart() {
            this.chart = new Chart(this.$refs.chart, {
                data: {
                    labels: this.displayLabels,
                    datasets: this.datasets
                },
                options: {
                    parsing: this.parsing,
                    scales: this.scales,
                    plugins: {
                        legend: {
                            display: ChartsWithLegend.includes(this.type),
                            align: "center",
                            position: "right",
                            labels: {
                                usePointStyle: true,
                                boxWidth: 12
                            }
                        },
                        title: {
                            display: false,
                            align: "start",
                            text: this.title
                        },
                        tooltip: {
                            // Disable the on-canvas tooltip
                            enabled: false,
                            // todo uncomment to add custom tooltips
                            external: this.customTooltip.bind(this),
                            callbacks: {
                                label: ({ label }) => label
                            }
                        }
                    },
                    elements: {
                        word: {
                            maxRotation: 0,
                            minRotation: 0
                        }
                    },

                    maintainAspectRatio: false,
                    responsive: true,
                    ...this.chartOptionsByType
                },
                plugins: this.plugins,
                type: this.chartType
            });
        },

        setTooltipText(tooltipModel) {
            let textLines = [];

            if (tooltipModel.dataPoints) {
                const bodyLines = tooltipModel.dataPoints.reduce((acc, { raw }) => {
                    acc.add(raw.label);
                    return acc;
                }, new Set());

                textLines = [...bodyLines].sort().map(line => {
                    const stats = this.data[line].summaryStats;
                    const deliverableAttributes = this.data[line].deliverables[0].attributes;
                    const attr = deliverableAttributes.find(({ attribute }) => attribute === line);

                    let attributeConfidence;
                    if (attr) {
                        attributeConfidence = attr.metadata[0].confidence;
                    }

                    return {
                        label: line,
                        stats,
                        attributeConfidence
                    };
                });
            }
            this.tooltipLines = textLines;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.bar-chart {
    width: 100%;
    height: 100%;
}

.creative-insights-chart {
    &__chart-wrapper {
        height: 380px;
    }
}

.horizontal-bar-chart__chart {
    height: 380px;
}
</style>
