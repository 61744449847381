<template>
    <collapsible-widget :is-opened="true">
        <template #name>Fonts</template>
        <template #controls>
            <Icon type="md-trash" @click.exact.stop="clearFontStyles" />
        </template>
        <div class="font-style">
            <editable-settings-control
                v-if="fontFamilyOptions.length"
                :title="camelToUpperCase(EditableSettingProperty.FontFamily)"
            >
                <template #button>
                    <div class="editable-settings-item">
                        <font-type width="16" height="16"></font-type>
                    </div>
                </template>
                <template #value>
                    <Select
                        size="small"
                        :placeholder="getPlaceholderValue(EditableSettingProperty.FontFamily)"
                        :value="fontFamily._id"
                        class="font-family font-style__control hox-select-dark"
                        @on-change="setFontFamily"
                    >
                        <Option
                            v-for="option in fontFamilyOptions"
                            :key="option.value"
                            class="font-family__option"
                            :value="option.value"
                        >
                            {{ option.label }}
                            <span class="font-family__icon-content">
                                <span class="font-family__icon-wrapper">
                                    <span class="pretty-file-icon-html"></span>
                                </span>
                                <span v-if="option.postScriptName" class="font-family__icon-wrapper">
                                    <span class="pretty-file-icon-psd"></span>
                                </span>
                            </span>
                        </Option>
                    </Select>
                </template>
            </editable-settings-control>

            <editable-settings-control :title="camelToUpperCase(EditableSettingProperty.FontSize)">
                <template #button>
                    <div class="editable-settings-item">
                        <letter-size width="16" height="16"></letter-size>
                    </div>
                </template>
                <template #value>
                    <editable-settings-number-input
                        ref="fontSize"
                        :key="getElKey(EditableSettingProperty.FontSize)"
                        :value="fontSize"
                        prop-name="fontSize"
                        :show-suffix="isSuffixVisble(EditableSettingProperty.FontSize)"
                        :placeholder="getPlaceholderValue(EditableSettingProperty.FontSize)"
                        @wrapperKeyDown="onKeyboardUpdate(EditableSettingProperty.FontSize, $event)"
                        @input="onNumericInput(EditableSettingProperty.FontSize, $event)"
                    />
                </template>
            </editable-settings-control>

            <editable-settings-control v-if="!isPsd" :title="camelToUpperCase(EditableSettingProperty.LetterSpacing)">
                <template #button>
                    <div class="editable-settings-item">
                        <letter-spacing width="20" height="20"></letter-spacing>
                    </div>
                </template>
                <template #value>
                    <editable-settings-number-input
                        ref="letterSpacing"
                        :key="getElKey(EditableSettingProperty.LetterSpacing)"
                        :value="letterSpacing"
                        prop-name="letterSpacing"
                        :show-suffix="isSuffixVisble(EditableSettingProperty.LetterSpacing)"
                        :placeholder="getPlaceholderValue(EditableSettingProperty.LetterSpacing)"
                        @wrapperKeyDown="onKeyboardUpdate(EditableSettingProperty.LetterSpacing, $event)"
                        @input="onNumericInput(EditableSettingProperty.LetterSpacing, $event)"
                    />
                </template>
            </editable-settings-control>

            <editable-settings-control v-if="!isPsd" :title="camelToUpperCase(EditableSettingProperty.LineHeight)">
                <template #button>
                    <div class="editable-settings-item">
                        <line-height width="20" height="20"></line-height>
                    </div>
                </template>
                <template #value>
                    <editable-settings-number-input
                        ref="lineHeight"
                        :key="getElKey(EditableSettingProperty.LineHeight)"
                        :value="lineHeight"
                        prop-name="lineHeight"
                        :show-suffix="isSuffixVisble(EditableSettingProperty.LineHeight)"
                        :placeholder="getPlaceholderValue(EditableSettingProperty.LineHeight)"
                        @wrapperKeyDown="onKeyboardUpdate(EditableSettingProperty.LineHeight, $event)"
                        @input="onNumericInput(EditableSettingProperty.LineHeight, $event)"
                    />
                </template>
            </editable-settings-control>

            <editable-settings-control v-if="!isPsd" :title="camelToUpperCase(EditableSettingProperty.FontWeight)">
                <template #button>
                    <div size="small" class="editable-settings-item font-style__control">
                        <font-weight width="16" height="16"></font-weight>
                    </div>
                </template>
                <template #value>
                    <Select
                        size="small"
                        class="hox-select-dark"
                        :placeholder="fontWeightPlaceholderValue"
                        :value="fontWeight"
                        @input="onSelectInput(EditableSettingProperty.FontWeight, $event)"
                    >
                        <Option v-for="option in fontWeightOptions" :key="option" :value="option">
                            {{ option }}
                        </Option>
                    </Select>
                </template>
            </editable-settings-control>

            <editable-settings-control v-if="!isPsd" :title="camelToUpperCase(EditableSettingProperty.FontStyle)">
                <template #button>
                    <div size="small" class="editable-settings-item font-style__control">
                        <font-italic width="16" height="16"></font-italic>
                    </div>
                </template>

                <template #value>
                    <Select
                        size="small"
                        class="hox-select-dark"
                        :placeholder="fontStylePlaceholderValue"
                        :value="fontStyle"
                        @input="onSelectInput(EditableSettingProperty.FontStyle, $event)"
                    >
                        <Option v-for="option in fontStyleOptions" :key="option" :value="option">
                            {{ option }}
                        </Option>
                    </Select>
                </template>
            </editable-settings-control>

            <editable-settings-control :title="camelToUpperCase(EditableSettingProperty.FontColor)">
                <template #row>
                    <div class="font-style__control--color-row">
                        <Color-picker
                            ref="picker"
                            class="font-style__control--color-picker"
                            size="small"
                            transfer
                            :value="fontColor"
                            @on-active-change="setFontColor"
                        />
                        <div class="font-style__control--color-value">
                            {{ fontColorDisplay }}
                        </div>
                    </div>
                </template>
            </editable-settings-control>
        </div>
    </collapsible-widget>
</template>
<script>
import LetterSize from "@/assets/text-editor/letter-size.svg";
import LetterSpacing from "@/assets/text-editor/spacing.svg";
import LineHeight from "@/assets/text-editor/leading.svg";
import FontWeight from "@/assets/text-editor/bold-text.svg";
import FontItalic from "@/assets/text-editor/italics.svg";
import FontType from "@/assets/text-editor/type.svg";
import EditableSettingsControl from "@/components/Campaign/EditableSettingsControl";
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";
import { camelToUpperCase, debounce } from "@/utils";
import { EditableSettingProperty } from "@/enums/editables";
import editableSettingsMixin from "@/mixins/editableSettingsMixin";
import EditableSettingsNumberInput from "@/components/Campaign/EditableSettingsNumberInput";
import { EditorGetters } from "@/store/modules/editor";

export default {
    name: "FontStyle",
    components: {
        EditableSettingsNumberInput,
        CollapsibleWidget,
        EditableSettingsControl,
        FontItalic,
        FontWeight,
        FontType,
        LetterSize,
        LetterSpacing,
        LineHeight
    },

    mixins: [editableSettingsMixin],

    data() {
        return {
            relevantProps: [
                EditableSettingProperty.FontColor,
                EditableSettingProperty.FontFamily,
                EditableSettingProperty.FontSize,
                EditableSettingProperty.FontWeight,
                EditableSettingProperty.FontStyle,
                EditableSettingProperty.LetterSpacing,
                EditableSettingProperty.LineHeight
            ],
            css: {},
            fontColor: "",
            fontFamily: {},
            fontSize: undefined,
            fontStyle: undefined,
            fontWeight: undefined,
            letterSpacing: undefined,
            lineHeight: undefined,
            emptyPropertyText: "Not set",
            fontWeightOptions: ["normal", "bold"],
            fontStyleOptions: ["normal", "italic"],
            updateFontColor: debounce(function handler(v) {
                this.fontColor = v;
                this.onStyleChange("fontColor");
            }, 16),
            camelToUpperCase,
            EditableSettingProperty
        };
    },

    computed: {
        clientId() {
            return this.$store.state.route.params.clientId;
        },

        fontColorDisplay() {
            if (this.css.fontColor === undefined) {
                return this.getPlaceholderValue("fontColor") || this.emptyPropertyText;
            }

            return this.css.fontColor;
        },

        fontFamilyOptions() {
            return this.fontIds.map(fontId => {
                const { _id: value, name: label, postScriptName } = this.fonts[fontId];

                return {
                    label,
                    value,
                    postScriptName
                };
            });
        },

        fontIds() {
            return this.$store.getters[EditorGetters.fontIds];
        },

        fonts() {
            return this.$store.state.editor.fonts;
        },

        fontStylePlaceholderValue() {
            const value = this.getPlaceholderValue("fontStyle");

            return value || "Select";
        },

        fontWeightPlaceholderValue() {
            const value = this.getPlaceholderValue("fontWeight");
            if (value === "400") {
                return "normal";
            }
            if (value === "700") {
                return "bold";
            }

            return value || "Select";
        }
    },

    methods: {
        clearFontStyles() {
            this.clearStyles();
            this.relevantProps.forEach(prop => {
                this[prop] = undefined;
            });
            this.fontFamily = { id: "" };
            this.fontColor = "";
        },

        onSelectInput(prop, val) {
            if (val) {
                this.onInput(prop, val.toLowerCase());
            }
        },

        setFontColor(v) {
            this.updateFontColor(v);
        },

        setFontFamily(v) {
            if (!v) {
                return;
            }

            // Here which need to spread the found font so that the assignment to this.fontFamily
            // Is not a pass by reference, otherwise when you change the dropdown the _id of the source array will be updated
            this.fontFamily = { ...this.fonts[v] };
            this.onStyleChange("fontFamily");
        }
    }
};
</script>
<style lang="scss">
@import "../../../sass/variables";

.font-style {
    &__control {
        &--color-value {
            position: absolute;
            width: calc(100% - 40px);
            z-index: 1;
            top: 0;
            margin-left: 40px;
            padding: 0 10px;
            border-left: 1px solid $cmp-dark-bg-color;
        }

        &--color-row {
            position: relative;
            width: 100%;
        }

        &--color-picker {
            cursor: pointer;
            position: relative;
            width: 100%;
            z-index: 2;

            .ivu-input-icon {
                display: none;
            }

            .ivu-input {
                border: transparent 0;
                background-color: inherit;
                width: 140px;

                &:hover,
                &:active {
                    border: transparent 0;
                    cursor: pointer;
                }
            }

            .ivu-input-icon-normal + .ivu-input {
                padding: 2px 6px;
                width: 40px;
                height: 33px;
            }

            .ivu-color-picker-small .ivu-color-picker-color {
                margin: 4px 6px;
            }
        }
    }

    &__color-value {
        width: 100%;
        height: 100%;
        cursor: pointer;
        background-color: green;
    }
}

.font-family {
    max-width: 184px;
    &__option {
        padding-right: 100px;
        position: relative;
    }

    &__icon-content {
        position: absolute;
        top: 5px;
        right: 15px;
    }

    &__icon-wrapper {
        display: inline-block;
        width: 24px;
        height: 24px;
        filter: grayscale(100%);
    }
}
</style>
