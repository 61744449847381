<template>
    <div :class="classes">
        <Tag :color="tagColor" @click.native="$emit('click')">
            {{ statusText }}
        </Tag>
    </div>
</template>

<script>
import { BannerQaStatus, BannerQaStatusLabel } from "@/enums/banners";

export default {
    props: {
        display: {
            default: "inline-block",
            validator(value) {
                return ["block", "inline-block"].includes(value);
            }
        },
        isClickable: {
            default: false,
            type: Boolean
        },
        status: {
            default: BannerQaStatus.Draft,
            required: true,
            validator(value) {
                return Object.values(BannerQaStatus).includes(value);
            }
        }
    },
    computed: {
        classes() {
            const classes = ["qa-status-label"];
            if (this.display === "block") {
                classes.push("qa-status-label--display-block");
            }
            if (!this.isClickable) {
                classes.push("qa-status-label--pointer-events-none");
            }
            return classes;
        },
        statusText() {
            return BannerQaStatusLabel[this.status];
        },
        tagColor() {
            switch (this.status) {
                case BannerQaStatus.Approved: {
                    return "success";
                }
                case BannerQaStatus.InReview: {
                    return "warning";
                }
                case BannerQaStatus.Rejected: {
                    return "error";
                }
                default:
                    return "default";
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.qa-status-label {
    display: inline-block;
}

.qa-status-label--display-block {
    display: block;

    .ivu-tag {
        text-align: center;
        width: 100%;
    }
}

.qa-status-label--pointer-events-none {
    pointer-events: none;
}
</style>
