<template>
    <hox-notification-item
        :progress-percentage="progressPercentage"
        :show-clear-button="hasFailed || hasCompleted"
        :show-error-message="hasFailed"
        :status="notification.status"
        @clear="removeNotification"
    >
        <template #title>
            {{ title }}
        </template>
        <template #subtitle>
            {{ statusText }}
        </template>
        <template #errorMessage>
            <p>{{ notification.job.message }}</p>
            <p>Please try again in a few moments.</p>
        </template>
    </hox-notification-item>
</template>

<script>
import { NotificationStatus } from "@/enums/notifications";
import { NotificationsAction } from "@/store/modules/notifications";

export default {
    name: "BackgroundUpdateNotificationItem",
    props: {
        notification: {
            required: true,
            type: Object
        }
    },
    computed: {
        adOrAds() {
            return this.numberOfAds === 1 ? "ad" : "ads";
        },

        numberOfAds() {
            // get if from deliverablesCount (if provided), or from the job payload (if provided)
            return this.notification.deliverablesCount || this.notification.job?.payload?.processingAdsCount || "Many";
        },

        completedStatusText() {
            return `${this.numberOfAds} ${this.adOrAds} has been updated`;
        },

        hasCompleted() {
            return this.notification.status === NotificationStatus.Completed;
        },

        hasFailed() {
            return this.notification.status === NotificationStatus.Failed;
        },

        statusText() {
            if (this.notification.status === NotificationStatus.InProgress) {
                return `Updating ${this.numberOfAds} ${this.adOrAds}`;
            }
            if (this.hasCompleted) {
                return this.completedStatusText;
            }
            if (this.hasFailed) {
                return "Failed";
            }
            return "Set metadata values";
        },

        title() {
            return "Background Update";
        },

        progressPercentage() {
            return this.notification.job?.progress;
        }
    },

    methods: {
        removeNotification() {
            this.$store.dispatch(NotificationsAction.RemoveItem, this.notification.id);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.publish-notification-item__list-item {
    list-style-position: inside;
    margin-left: 0 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
