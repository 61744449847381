import { Element } from "chart.js";

import { _isBetween } from "chart.js/helpers";

/**
 * Helper function to get the bounds of the bar regardless of the orientation
 * @param {BarElement} bar the bar
 * @param {boolean} [useFinalPosition]
 * @return {object} bounds of the bar
 * @private
 */
function getBarBounds(bar, useFinalPosition) {
    const { x, x2, y, height } = bar.getProps(["x", "x2", "y", "width", "height"], useFinalPosition);
    const {
        options: { height: optHeight }
    } = bar;

    const h = height || optHeight;
    const half = h / 2;
    const left = x;
    const right = x2;
    const top = y - half;
    const bottom = y + half;

    return { left, top, right, bottom };
}

function inRange(bar, x, y, useFinalPosition) {
    const skipX = x === null;
    const skipY = y === null;
    const skipBoth = skipX && skipY;
    const bounds = bar && !skipBoth && getBarBounds(bar, useFinalPosition);

    return (
        bounds &&
        (skipX || _isBetween(x, bounds.left, bounds.right)) &&
        (skipY || _isBetween(y, bounds.top, bounds.bottom))
    );
}

const roundRect = (ctx, x, y, width, height, radius = 8, fillStyle = "", strokeStyle = "") => {
    ctx.save();
    const radiusStyle = { tl: radius, tr: radius, br: radius, bl: radius };
    if (typeof radius !== "number") {
        const defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
        Object.keys(defaultRadius).forEach(side => {
            radiusStyle[side] = radius[side] || defaultRadius[side];
        });
    }
    ctx.beginPath();
    ctx.moveTo(x + radiusStyle.tl, y);
    ctx.lineTo(x + width - radiusStyle.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radiusStyle.tr);
    ctx.lineTo(x + width, y + height - radiusStyle.br);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radiusStyle.br, y + height);
    ctx.lineTo(x + radiusStyle.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radiusStyle.bl);
    ctx.lineTo(x, y + radiusStyle.tl);
    ctx.quadraticCurveTo(x, y, x + radiusStyle.tl, y);
    ctx.closePath();
    if (fillStyle) {
        ctx.fillStyle = fillStyle;
        ctx.fill();
    }
    if (strokeStyle) {
        ctx.strokeStyle = strokeStyle;
        ctx.stroke();
    }
    ctx.restore();
};

export class RoundedRect extends Element {
    constructor(cfg) {
        super();

        this.options = undefined;
        this.width = undefined;

        if (cfg) {
            Object.assign(this, cfg);
        }
    }

    size(useFinalPosition) {
        const { width } = this.getProps(["width"], useFinalPosition);

        return width;
    }

    inRange(mouseX, mouseY, useFinalPosition) {
        return inRange(this, mouseX, mouseY, useFinalPosition);
    }

    inXRange(mouseX, useFinalPosition) {
        return inRange(this, mouseX, null, useFinalPosition);
    }

    inYRange(mouseY, useFinalPosition) {
        return inRange(this, null, mouseY, useFinalPosition);
    }

    getCenterPoint(useFinalPosition) {
        const { x, x2, y } = this.getProps(["x", "x2", "y"], useFinalPosition);

        return {
            x: (x + x2) / 2,
            y
        };
    }

    tooltipPosition(useFinalPosition) {
        const { x, x2, y } = this.getProps(["x", "x2", "y"], useFinalPosition);

        return {
            x: (x + x2) / 2,
            y
        };
    }

    draw(ctx) {
        const me = this;

        const { x, y, width, height } = me;
        const {
            options: { backgroundColor, strokeStyle, radius, height: optHeight }
        } = this;

        const h = height || optHeight;
        if (!width) {
            // draw single point based on height
            roundRect(ctx, x - h / 2, y - h / 2, h, h, h / 2, backgroundColor, strokeStyle);
            return;
        }

        roundRect(ctx, x, y - h / 2, width, h, radius, backgroundColor, strokeStyle);
    }
}
RoundedRect.id = "RoundedRect";

RoundedRect.defaults = {
    height: 16,
    backgroundColor: "#FF0000",
    borderStyle: "",
    radius: 8
};
