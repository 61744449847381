<template>
    <div class="editable-group-batch-upload">
        <Upload
            action=""
            :show-upload-list="false"
            :multiple="false"
            :on-progress="showUploadPercentage"
            :on-error="onError"
            v-bind="attrs"
            v-on="listeners"
        >
            <div class="editable-group-batch-upload__button hox-upload__button">
                <loading-spinner v-if="loading"></loading-spinner>
                <template v-else>
                    <slot name="button">
                        <Button>Upload file</Button>
                    </slot>
                </template>
            </div>
        </Upload>
    </div>
</template>
<script>
import bus from "@/bus";
import passThroughMixin from "@/mixins/passThroughMixin";
import LoadingSpinner from "@/components/common/LoadingSpinner";

export default {
    name: "UploadButton",
    components: { LoadingSpinner },
    mixins: [passThroughMixin],
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        showUploadPercentage(e) {
            bus.$emit("percentLoadEvent", e.percent);
        },

        onError(e) {
            this.$emit("error", {
                type: "upload",
                message: e.message || ""
            });
        }
    }
};
</script>
