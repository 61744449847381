<template>
    <div class="qa-banner-add-annotation__new-annotation-dot" :style="style" @click.stop>
        <Poptip placement="bottom-start" trigger="custom" :value="true" width="440" :transfer="true">
            <template #content>
                <qa-annotation-input-form
                    ref="inputForm"
                    :is-saving="isAddingAnnotation"
                    margin-bottom="none"
                    poptip-width="wide"
                    @cancel="close"
                    @save="saveAnnotation"
                />
            </template>
        </Poptip>
    </div>
</template>

<script>
import QaAnnotationInputForm from "@/components/Qa/QaAnnotationInputForm";
import { addAnnotationToDeliverable } from "@/services/Qa";

export default {
    components: {
        QaAnnotationInputForm
    },
    props: {
        deliverable: {
            type: Object,
            required: true
        },
        position: {
            type: Object,
            required: true,
            validator(value) {
                return value.x !== undefined && value.y !== undefined;
            }
        },
        scalingFactor: {
            type: Number,
            default: 1
        },
        timeline: {
            required: true,
            type: Number
        }
    },
    data() {
        return {
            isAddingAnnotation: false
        };
    },
    computed: {
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },
        style() {
            return {
                left: `${this.position.x * this.scalingFactor}px`,
                top: `${this.position.y * this.scalingFactor}px`
            };
        }
    },
    watch: {
        position: {
            deep: true,
            handler() {
                this.$refs.inputForm.focus();
            }
        }
    },
    mounted() {
        this.$refs.inputForm.focus();
    },
    methods: {
        close() {
            this.$emit("close");
        },
        async saveAnnotation({ content, mentions }) {
            this.isAddingAnnotation = true;
            try {
                const annotation = await addAnnotationToDeliverable(
                    this.campaignId,
                    this.deliverable,
                    content,
                    this.timeline,
                    this.position.x,
                    this.position.y,
                    mentions
                );
                this.$emit("annotationAdded", annotation);
                this.close();
                this.$snackbar.success("Annotation added.");
            } catch (err) {
                this.$snackbar.error(
                    "There was an unexpected error and the annotation was unable to be saved. Please try again in a few moments."
                );
            }
            this.isAddingAnnotation = false;
        },
        setHasValidationErrors(hasValidationErrors) {
            this.hasValidationErrors = hasValidationErrors;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

$new-annotation-dot-size: 30px;

.qa-banner-add-annotation__new-annotation-dot {
    align-items: flex-end;
    background-color: rgba(127, 127, 127, 0.75);
    border-radius: 100%;
    border: 1px dashed $white;
    cursor: default;
    display: flex;
    height: $new-annotation-dot-size;
    margin: (0 - calc($new-annotation-dot-size / 2)) 0 0 (0 - calc($new-annotation-dot-size / 2));
    padding: 0 calc($new-annotation-dot-size / 2) 0 0;
    position: relative;
    width: $new-annotation-dot-size;
}
</style>
