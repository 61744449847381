<template>
    <collapsible-widget :is-opened="true">
        <template slot="name">Languages</template>
        <template slot="controls">
            <Badge v-if="selectedValues.length" :count="selectedValues.length" class="filter-badge-count"></Badge>
        </template>
        <library-checkbox-group v-model="selectedValues" :options="options" />
    </collapsible-widget>
</template>

<script>
import LibraryCheckboxGroup from "@/components/Campaign/LibraryCheckboxGroup";
import { DeliverableLibraryAction, DeliverableLibraryGetters } from "@/store/modules/deliverableLibrary";
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";

export default {
    name: "LibraryLanguageFilter",
    components: {
        CollapsibleWidget,
        LibraryCheckboxGroup
    },

    computed: {
        selectedValues: {
            get() {
                return this.$store.getters[DeliverableLibraryGetters.languageFilters];
            },
            set(newValue) {
                this.$store.dispatch(DeliverableLibraryAction.UpdateLanguageFilter, {
                    values: newValue
                });
            }
        },

        options() {
            return this.$store.state.deliverableLibrary.filterOptions.languages;
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";
</style>
