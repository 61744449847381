<template>
    <data-sort-filter
        size="default"
        class="sort-media-library"
        :default-value="defaultValue"
        :label="label"
        :options="options"
        v-on="$listeners"
    />
</template>
<script>
import DataSortFilter from "@/components/DataSortFilter";

export const defaultMediaLibrarySortBy = "name_ASC";

export default {
    name: "SortMediaLibrary",
    components: { DataSortFilter },
    props: {
        value: {
            type: String,
            default: defaultMediaLibrarySortBy
        }
    },
    data() {
        return {
            options: [
                {
                    field: "Name",
                    ascValue: "name_ASC",
                    ascOptionDirection: "A to Z",
                    descValue: "name_DESC",
                    descOptionDirection: "Z to A"
                },

                {
                    field: "Updated",
                    descValue: "modified_ASC",
                    descOptionDirection: "Old to New",
                    ascValue: "modified_DESC",
                    ascOptionDirection: "New to Old"
                },

                {
                    field: "Created",
                    descValue: "created_ASC",
                    descOptionDirection: "Old to New",
                    ascValue: "created_DESC",
                    ascOptionDirection: "New to Old"
                },

                {
                    field: "Type",
                    ascValue: "type_ASC",
                    ascOptionDirection: "File first",
                    descValue: "type_DESC",
                    descOptionDirection: "Folder first"
                }
            ],
            label: "Sort by:",
            defaultValue: defaultMediaLibrarySortBy
        };
    }
};
</script>

<style lang="scss">
.sort-media-library {
    &.data-sort .ivu-form-item-label {
        height: 34px;
    }
}
</style>
