<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div>
        <label class="resize-settings__select-label">
            {{ label }}
        </label>
        <div
            class="resize-settings__radio-wrapper"
            :class="{ 'resize-settings__radio-wrapper--manual': showManualResizeInputs }"
        >
            <RadioGroup :value="resizeType" type="button">
                <Radio :label="ResizeType.Auto" @click.native="setResizeType(ResizeType.Auto)">Auto</Radio>
                <Radio
                    v-if="showManualResizeOption"
                    :label="ResizeType.Manual"
                    @click.native="setResizeType(ResizeType.Manual)"
                >
                    Manual
                </Radio>
                <Radio :label="ResizeType.Off" @click.native="setResizeType(ResizeType.Off)">Off</Radio>
            </RadioGroup>
            <div v-if="showManualResizeInputs" class="new-size-modal__form">
                <span class="new-size-modal__form-input-wrapper new-size-modal__form-item">
                    <Input
                        type="number"
                        min="1"
                        class="new-size-modal__form-input"
                        :value="resizeDimensions.width"
                        @input="onDimensionChange('width', $event)"
                    >
                        <template slot="append">px</template>
                    </Input>
                </span>
                <span class="new-size-modal__form-item">x</span>
                <span class="new-size-modal__form-input-wrapper new-size-modal__form-item">
                    <Input
                        type="number"
                        min="1"
                        class="new-size-modal__form-input"
                        :value="resizeDimensions.height"
                        @input="onDimensionChange('height', $event)"
                    >
                        <template slot="append">px</template>
                    </Input>
                </span>
            </div>
        </div>
        <template v-if="isAutoResize">
            <div class="resize-settings__radio-wrapper">
                <label class="resize-settings__select-label">Algorithm</label>
                <RadioGroup v-model="cover" type="button">
                    <Radio :label="CoverType.Smart" @click.native="setCover(CoverType.Smart)">Smart</Radio>
                    <Radio :label="CoverType.Attention" @click.native="setCover(CoverType.Attention)">
                        Attention
                    </Radio>
                    <Radio :label="CoverType.Entropy" @click.native="setCover(CoverType.Entropy)">Entropy</Radio>
                    <Radio :label="CoverType.Face" @click.native="setCover(CoverType.Face)">Face</Radio>
                    <Radio :label="CoverType.Directional" @click.native="setCover(CoverType.Directional)">
                        Directional
                    </Radio>
                </RadioGroup>
                <p v-if="algorithmDescription" class="resize-settings__algorithm-description">
                    {{ algorithmDescription }}
                </p>
            </div>
            <template v-if="cover === CoverType.Directional">
                <div class="resize-settings__radio-wrapper">
                    <label class="resize-settings__select-label">Vertical Alignment</label>
                    <RadioGroup v-model="verticalAlign" type="button">
                        <Radio :label="VerticalAlign.Center" @click.native="setVerticalAlign(VerticalAlign.Center)">
                            Center
                        </Radio>
                        <Radio :label="VerticalAlign.North" @click.native="setVerticalAlign(VerticalAlign.North)">
                            Top
                        </Radio>
                        <Radio :label="VerticalAlign.South" @click.native="setVerticalAlign(VerticalAlign.South)">
                            Bottom
                        </Radio>
                    </RadioGroup>
                </div>
                <div class="resize-settings__radio-wrapper">
                    <label class="resize-settings__select-label">Horizontal Alignment</label>
                    <RadioGroup v-model="horizontalAlign" type="button">
                        <Radio
                            :label="HorizontalAlign.Center"
                            @click.native="setHorizontalAlign(HorizontalAlign.Center)"
                        >
                            Center
                        </Radio>
                        <Radio :label="HorizontalAlign.West" @click.native="setHorizontalAlign(HorizontalAlign.West)">
                            Left
                        </Radio>
                        <Radio :label="HorizontalAlign.East" @click.native="setHorizontalAlign(HorizontalAlign.East)">
                            Right
                        </Radio>
                    </RadioGroup>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { CoverType, HorizontalAlign, ResizeType, VerticalAlign } from "shared-utils/imageBoss";

export default {
    props: {
        cover: {
            type: String
        },
        resizeType: {
            type: String,
            validator(value) {
                return Object.values(ResizeType).includes(value);
            }
        },
        horizontalAlign: {
            type: String
        },
        showManualResizeOption: {
            type: Boolean,
            default: true
        },
        verticalAlign: {
            type: String
        },
        label: {
            type: String,
            default: "Resize"
        },

        resizeDimensions: {
            type: Object
        }
    },

    data() {
        return {
            manualDimensions: { ...this.resizeDimensions }
        };
    },
    computed: {
        algorithmDescription() {
            switch (this.cover) {
                case CoverType.Smart: {
                    return "The Smart algorithm uses content aware cropping while resizing images to keep what's important in frame";
                }

                case CoverType.Attention: {
                    return "The Attention algorithm will resize images while keeping areas with high luminance frequency, high colour saturation and skin tones in frame";
                }

                case CoverType.Entropy: {
                    return "The Entropy algorithm uses the Shannon Entropy algorithm to decide which area to keep in frame when resizing images";
                }

                case CoverType.Face: {
                    return "The Face algorithm will resize images while keeping areas where faces are detected in frame";
                }
                default:
                    return null;
            }
        },
        isAutoResize() {
            return this.resizeType === ResizeType.Auto;
        },
        showManualResizeInputs() {
            return this.resizeType === ResizeType.Manual && this.resizeDimensions;
        }
    },
    created() {
        this.CoverType = CoverType;
        this.HorizontalAlign = HorizontalAlign;
        this.ResizeType = ResizeType;
        this.VerticalAlign = VerticalAlign;
    },
    methods: {
        async close() {
            this.$emit("close");
        },

        onDimensionChange(prop, val) {
            this.manualDimensions = { ...this.manualDimensions, [prop]: +val };
            this.$emit("dimensionChange", this.manualDimensions);
        },
        setCover(cover) {
            this.$emit("update:cover", cover);
            if (cover === CoverType.Directional) {
                /*
                    Because we may not have horizontalAlign or verticalAlign values
                    when toggling cover to directional, which could cause errors,
                    we'll be extra careful and ensure that we do have values.
                */
                if (!this.horizontalAlign) {
                    this.setHorizontalAlign(HorizontalAlign.Center);
                }
                if (!this.verticalAlign) {
                    this.setVerticalAlign(VerticalAlign.Center);
                }
            }
        },
        setHorizontalAlign(horizontalAlign) {
            this.$emit("update:horizontal-align", horizontalAlign);
        },
        setResizeType(resizeType) {
            this.$emit("update:resize-type", resizeType);
        },
        setVerticalAlign(verticalAlign) {
            this.$emit("update:vertical-align", verticalAlign);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.resize-settings__algorithm-description {
    color: $grey5;
    font-size: $font-size-small;
    padding: $spacing-smaller 0 0 0;
}

.resize-settings__radio-wrapper {
    margin: 0 0 $spacing-small;

    &--manual {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .ivu-radio {
        display: none;
    }
}

.resize-settings__select-label {
    display: block;
    font-size: $font-size-base;
    font-weight: bold;
    margin: 0 0 $spacing-smaller;
}
</style>
