var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notifications-drawer",attrs:{"data-testid":"notifications-drawer"}},[_c('Drawer',{attrs:{"placement":"left","transfer":false,"value":_vm.isVisible,"width":"400px"},on:{"on-close":_vm.close}},[_c('div',{staticClass:"notifications-drawer__header"},[_c('hox-tabs',{attrs:{"color":"tertiary","size":"small"}},[_c('hox-tabs-item',{attrs:{"is-active":""}},[_vm._v("Notifications")])],1)],1),_c('div',{staticClass:"notifications-drawer__content"},[(_vm.hasErrorLoadingMyJobsByStatus)?_c('hox-alert',{attrs:{"margin-bottom":"none","size":"small","type":"danger"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" There was an unexpected error while attempting to get your background jobs. ")]},proxy:true},{key:"content",fn:function(){return [_c('p',[_vm._v("Hopefully it is just a temporary issue and it should work if you retry in a few moments.")])]},proxy:true},{key:"actionItems",fn:function(){return [_c('Button',{attrs:{"loading":_vm.isLoadingMyJobsByStatus > 0,"type":"primary"},on:{"click":_vm.runMyJobsByStatusQuery}},[_vm._v(" Retry ")])]},proxy:true}],null,false,2184656668)}):(_vm.notifications.length === 0)?_c('div',{staticClass:"notifications-drawer__empty-message-wrapper"},[_c('hox-empty-message',{attrs:{"type":"all-clear"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("There are no notifications")]},proxy:true}])})],1):_vm._e(),_vm._l((_vm.notifications),function(notification){return _c('div',{key:notification.id},[(
                        notification.type === _vm.NotificationTypes.FlashtalkingDownloadExcel ||
                        notification.type === _vm.NotificationTypes.FlashtalkingDownloadAssets
                    )?_c('flashtalking-download-job-notification-item',{attrs:{"notification":notification}}):_vm._e(),(notification.type === _vm.NotificationTypes.Download)?_c('download-notification-item',{attrs:{"notification":notification}}):(notification.type === _vm.NotificationTypes.DownloadJob)?_c('download-job-notification-item',{attrs:{"notification":notification}}):(
                        notification.type === _vm.NotificationTypes.Publish ||
                        notification.type === _vm.NotificationTypes.Unpublish
                    )?_c('publish-notification-item',{attrs:{"notification":notification}}):(notification.type === _vm.NotificationTypes.GenerateReport)?_c('generate-report-notification-item',{attrs:{"notification":notification}}):(notification.type === _vm.NotificationTypes.BackgroundUpdate)?_c('background-update-notification-item',{attrs:{"notification":notification}}):_vm._e()],1)})],2),(_vm.notifications.length > 0)?_c('div',{staticClass:"notifications-drawer__footer",on:{"click":_vm.clearItemsNotPendingOrInProgress}},[_vm._v(" Clear Completed ")]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }