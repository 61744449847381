<template>
    <collapsible-widget :is-opened="true" size="small">
        <template slot="name">
            <span class="capitalize-text">{{ label }}</span>
        </template>
        <template slot="controls">
            <Badge
                v-if="selectedValues.length"
                :count="selectedValues.length"
                class="editable-group-checkbox__count-badge filter-badge-count"
            ></Badge>
        </template>
        <library-checkbox-group
            v-model="selectedGroupValues"
            :options="options"
            :all-selected-groups="selectedGroups"
            @input="updateSelectedGroups"
        />
    </collapsible-widget>
</template>
<script>
import LibraryCheckboxGroup from "@/components/IntelligentDelivery/LibraryCheckboxGroup";
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";

export default {
    name: "EditableGroupCheckbox",
    components: {
        CollapsibleWidget,
        LibraryCheckboxGroup
    },

    props: {
        groupName: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        options: {
            type: Array,
            required: true
        },
        selectedGroups: {
            type: Array
        }
    },

    data() {
        return {
            selectedGroupValues: []
        };
    },
    computed: {
        selectedValues() {
            const allValues = this.options.map(option => option.value);
            const filteredValues = allValues.filter(value => this.selectedGroupValues.includes(value));
            return filteredValues;
        }
    },
    watch: {
        selectedGroups: {
            handler(newVal) {
                this.selectedGroupValues = newVal;
            },
            immediate: true
        }
    },
    methods: {
        updateSelectedGroups() {
            this.$emit("updateSelectedGroupValueFilters", this.selectedGroupValues);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
</style>
