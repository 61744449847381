import campaignEditorQuery from "./campaignEditorQuery";
import {
    addEditableGroupValue,
    updateEditableGroupValue,
    removeEditableGroupValue,
    addEditableGroupValues
} from "./groupValueMutations";
import {
    overwriteEditablesEditableGroup,
    removeOverwrites,
    overwriteEditablesEditableGroupAndMasterTemplate
} from "./overwriteEditablesMutations";
import { resizeMasterTemplate } from "./masterTemplates";

// Define the list of handlers that are used to respond to specific GQL requests
export const handlers = {
    CampaignEditor: campaignEditorQuery,
    AddEditableGroupValue: addEditableGroupValue,
    AddEditableGroupValues: addEditableGroupValues,
    UpdateEditableGroupValues: updateEditableGroupValue,
    RemoveEditableGroupValues: removeEditableGroupValue,
    RemoveOverwrites: removeOverwrites,
    OverwriteEditablesWithEditableScope: overwriteEditablesEditableGroup,
    OverwriteEditablesWithEditableGroupAndMasterTemplateScope: overwriteEditablesEditableGroupAndMasterTemplate,
    ResizeMasterTemplate: resizeMasterTemplate,
    // These are not currently in use
    OverwriteEditablesWithMasterTemplateScope: undefined,
    OverwriteEditablesWithCampaignScope: undefined
};

export default handlers;
