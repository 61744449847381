<template>
    <div class="sandbox-no-templates">
        <div class="sandbox-no-templates__content">
            <h2>There are no usable templates being served.</h2>
            <div class="sandbox-no-templates__content-instructions">
                <p>Please check the following:</p>
                <ol>
                    <li>Are you running the CLI in the right folder?</li>
                    <li>Do your index.html files have a valid manifest?</li>
                    <li>Have you checked the console for errors?</li>
                </ol>
                <p>
                    If you think that the issue is now resolved please click the link to try again:
                    <a @click="retry">Reload</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SandboxNoTemplates",

    methods: {
        retry() {
            this.$emit("retry");
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../../sass/variables";

.sandbox-no-templates {
    background: $campaign-nav-bg;
    color: $cmp-dark-font-color;
    width: calc(100% - #{$campaign-vertical-nav-width});
    height: calc(100vh - #{$campaign-nav-height} - #{$campaign-main-content-padding-top});
    display: flex;
    align-items: flex-start;
    justify-content: center;

    &__content {
        padding: $spacing-large;

        h2 {
            flex: 0;
            font-weight: normal;
            font-size: 28px;
            text-align: center;
            max-width: 400px;
            margin: 0 auto 50px;
        }

        p {
            margin-bottom: $spacing-base;
        }

        ol {
            padding: $spacing-small $spacing-large;
            margin-bottom: $spacing-base;
        }

        &-instructions {
            padding: $spacing-large;
            background: $cmp-dark-bg-color;
        }
    }
}
</style>
