<template>
    <div class="library-deliverable-table-action-items-cell" @click="showPreview">
        <slot name="tooltip">
            <Tooltip :offset="7" :transfer="true" placement="top-end">
                <Icon size="26" type="md-log-in" />
                <template #content>
                    <slot name="content">Single QA & Preview</slot>
                </template>
            </Tooltip>
        </slot>
    </div>
</template>

<script>
export default {
    methods: {
        showPreview() {
            this.$emit("showPreview");
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.library-deliverable-table-action-items-cell {
    bottom: 0;
    color: $link-color;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition:
        background 0.2s linear,
        color 0.2s linear;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid $grey3;

    &:hover {
        background: $grey1;
        color: $link-hover-color;
    }
}
</style>
