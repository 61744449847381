<template>
    <hox-alert type="info" class="importExportModal__upload-result">
        <template #content>
            <p>Please select a primary key. This will be used as the variant name within the group.</p>
            <div>
                <RadioGroup class="editable-group__values" :vertical="true" :value="value" @input="input">
                    <Radio v-for="candidate in candidates" :key="candidate" :label="candidate">
                        {{ candidate }}
                    </Radio>
                </RadioGroup>
            </div>
        </template>
        <template #actionItems>
            <Button type="primary" @click="select">Save</Button>
        </template>
    </hox-alert>
</template>
<script>
export default {
    name: "FeedPrimaryKeySelector",
    props: {
        value: {
            type: String,
            required: true
        },
        candidates: {
            type: Array,
            required: true,
            default() {
                return [];
            }
        }
    },

    methods: {
        input(value) {
            this.$emit("input", value);
        },

        select() {
            this.$emit("select");
        }
    }
};
</script>
