<template>
    <div class="breakdown-context">
        <insights-data-provider
            :key="advancedSearch"
            :insights-type="InsightType.Matrix"
            :advanced-search="advancedSearch"
            @loading="onLoading"
            @data="onData"
            @error="onError"
        />
        <template v-if="showContent">
            <insights-chart-preview
                v-for="breakdownGroupName in breakdownGroupNames"
                :key="breakdownGroupName"
                :data="chartDataByBreakdownGroup[breakdownGroupName]"
                :title="breakdownGroupName"
                :label-map="labelMap"
            />
        </template>
    </div>
</template>
<script>
import InsightsDataProvider from "@/components/CreativeIntelligence/InsightsDataProvider";
import { BreakdownGroup, BreakdownsByGroups, InsightType } from "@/components/CreativeIntelligence/constants";
import { CreativeInsightsGetters } from "@/store/modules/creativeInsights";
import {
    Breakdown,
    BreakdownFriendlyNames,
    CreativeInsightsContentFilter,
    CreativeInsightsMode,
    getBreakdownLabel,
    getMetricsLabel
} from "@/enums/creativeInteligence";
import InsightsChartPreview from "@/components/CreativeIntelligence/InsightPreviews/InsightsChartPreview";

export default {
    name: "BreakdownContext",
    components: { InsightsChartPreview, InsightsDataProvider },

    data() {
        return {
            data: {},
            isLoading: false
        };
    },

    computed: {
        advancedSearch() {
            return this.$store.getters[CreativeInsightsGetters.AdvancedSearchValue];
        },

        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        breakdownNames() {
            return Object.keys(this.breakdownsByName);
        },

        breakdownsByName() {
            if (!this.data || !this.data.length) {
                return {};
            }

            return this.data.reduce((acc, curr) => {
                acc[curr.name] = curr;

                return acc;
            }, {});
        },

        breakdownGroupNames() {
            return Object.keys(this.dynamicBreakdownValuesByBreakdown);
        },

        dynamicBreakdownValuesByBreakdown() {
            return this.$store.getters[CreativeInsightsGetters.DynamicBreakdownValuesByBreakdown];
        },

        chartDataByBreakdownGroup() {
            return this.breakdownGroupNames.reduce((acc, groupName) => {
                const groupData = this.dynamicBreakdownValuesByBreakdown[groupName].reduce(
                    (breakdownsWithData, breakdownName) => {
                        if (this.breakdownsByName[breakdownName]) {
                            // eslint-disable-next-line no-param-reassign
                            breakdownsWithData[breakdownName] = {
                                summaryStats: {
                                    ...this.breakdownsByName[breakdownName],
                                    kpiMetric: this.breakdownsByName[breakdownName].kpiMetricStats,
                                    confidenceMetric: this.breakdownsByName[breakdownName].confidenceMetricStats
                                },
                                deliverables: []
                            };
                        }

                        return breakdownsWithData;
                    },
                    {}
                );

                if (!Object.keys(groupData).length) {
                    return acc;
                }

                acc[groupName] = acc[groupName] || {};
                acc[groupName] = groupData;

                return acc;
            }, {});
        },

        confidenceMetricName() {
            return getMetricsLabel(this.$store.getters[CreativeInsightsGetters.ConfidenceMetricName]);
        },

        customBreakdownNames() {
            if (this.campaignId === "62692e9f4228c400092b11aa") {
                return BreakdownsByGroups[this.groupFilter].filter(nameInAGroup =>
                    this.breakdownNames.includes(nameInAGroup)
                );
            }

            return this.breakdownNames.filter(name => name !== Breakdown.NoBreakdowns);
        },

        displayMode() {
            return this.$store.state.creativeInsights.displayMode;
        },

        kpiMetricName() {
            return getMetricsLabel(this.$store.getters[CreativeInsightsGetters.KpiMetricName]);
        },

        contentFilter() {
            return this.$store.state.creativeInsights.contentFilter;
        },

        labelMap() {
            return BreakdownsByGroups[BreakdownGroup.All].reduce((acc, curr) => {
                acc[curr] = getBreakdownLabel(curr);

                return acc;
            }, {});
        },

        showContent() {
            return (
                this.breakdownNames.length &&
                (this.displayMode === CreativeInsightsMode.BarReport ||
                    this.displayMode === CreativeInsightsMode.QuadrantReport) &&
                (this.contentFilter === CreativeInsightsContentFilter.Everything ||
                    this.contentFilter === CreativeInsightsContentFilter.Context)
            );
            //
        }
    },

    created() {
        this.InsightType = InsightType;
        this.BreakdownFriendlyNames = BreakdownFriendlyNames;
        this.Breakdown = Breakdown;
        this.BreakdownGroup = BreakdownGroup;
    },

    methods: {
        getBreakdownLabel(label) {
            return getBreakdownLabel(label);
        },

        getMetricLabel(label) {
            return getMetricsLabel(label);
        },

        onLoading(loading) {
            this.isLoading = loading;
            this.$emit("loading", this.isLoading);
        },

        onData(response) {
            if (response.requestType === InsightType.Matrix) {
                this.data = response.data;
                this.hasData = !!response.data.length;
                this.$emit("hasData", this.hasData);
            }
        },

        onError(e) {
            console.error(`Error getting data for (${this.advancedSearch})`);
            console.error(e);

            this.hasData = false;
            this.$emit("hasData", this.hasData);
        }
    }
};
</script>
