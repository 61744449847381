<template>
    <div class="manage-users-modal">
        <hox-modal fullscreen :max-width-px="1400" @close="close">
            <template #header>Workspace Settings</template>

            <template #tabs>
                <navigation-tabs color="tertiary">
                    <navigation-tab
                        v-if="canManageUsers"
                        data-testid="workspace-settings__tabs-people"
                        :is-active="isTabActive(Tabs.People)"
                        @click="setActive(Tabs.People)"
                    >
                        People
                        <span>({{ users.length }})</span>
                    </navigation-tab>
                    <navigation-tab
                        data-testid="workspace-settings__tabs-notifications"
                        :is-active="isTabActive(Tabs.Notifications)"
                        @click="setActive(Tabs.Notifications)"
                    >
                        Notifications
                    </navigation-tab>
                </navigation-tabs>
            </template>

            <div class="manage-users-modal__tab-content">
                <div v-if="isTabActive(Tabs.Notifications)" class="manage-users-modal__notification-tab">
                    <Spin v-if="!userNotificationSettings"></Spin>
                    <div v-else class="notification-settings">
                        <div class="notification-settings-group">
                            <h3>Annotations</h3>
                            <labelled-switch
                                type="info"
                                :value="getSwitchValue('annotationSettings', 'mentions')"
                                @change="onSettingsChange($event, 'annotationSettings', 'mentions')"
                            >
                                <div class="notification-label">
                                    You will always be notified when someone @mentions you, or comments on a discussion
                                    that you are involved with.
                                </div>
                            </labelled-switch>
                            <labelled-switch
                                type="info"
                                :value="getSwitchValue('annotationSettings', 'all')"
                                @change="onSettingsChange($event, 'annotationSettings', 'all')"
                            >
                                <div class="notification-label">
                                    You will always be notified when external reviewers annotate any of the banners in
                                    your campaign
                                </div>
                            </labelled-switch>
                        </div>

                        <div class="notification-settings-group">
                            <h3>Status Changes and Approvals</h3>
                            <div class="notification-label notification-label--shared">
                                You will always be notified when any of the banners in your campaigns are set to:
                            </div>
                            <labelled-switch
                                type="info"
                                :value="getSwitchValue('statusChangesSettings', 'approved')"
                                @change="onSettingsChange($event, 'statusChangesSettings', 'approved')"
                            >
                                <div class="notification-label"><strong>Status: approved</strong></div>
                            </labelled-switch>
                            <labelled-switch
                                type="info"
                                :value="getSwitchValue('statusChangesSettings', 'inReview')"
                                @change="onSettingsChange($event, 'statusChangesSettings', 'inReview')"
                            >
                                <div class="notification-label"><strong>Status: in review</strong></div>
                            </labelled-switch>

                            <labelled-switch
                                type="info"
                                :value="getSwitchValue('statusChangesSettings', 'rejected')"
                                @change="onSettingsChange($event, 'statusChangesSettings', 'rejected')"
                            >
                                <div class="notification-label"><strong>Status: rejected</strong></div>
                            </labelled-switch>
                        </div>

                        <div class="notification-settings-group">
                            <h3>New users</h3>
                            <labelled-switch
                                type="info"
                                :value="getSwitchValue('newUserJoins')"
                                @change="onSettingsChange($event, 'newUserJoins')"
                            >
                                <div class="notification-label">
                                    You will always be notified when a new user joins one of your brands and campaigns
                                </div>
                            </labelled-switch>
                        </div>

                        <div class="notification-settings-group">
                            <h3>Email frequency</h3>

                            <RadioGroup
                                class="notification-radio-group"
                                vertical
                                :value="frequencySwitchValue"
                                @input="onNotificationFrequencyChange"
                            >
                                <Radio class="notification-radio" label="everytime">
                                    <span class="notification-label">Send me an email for any of the cases above</span>
                                </Radio>
                                <Radio class="notification-radio" label="daily">
                                    <span class="notification-label">
                                        Send me an email every 24 hours, including all updates
                                    </span>
                                </Radio>
                            </RadioGroup>

                            <Button
                                v-if="frequencySwitchValue === 'daily'"
                                type="text"
                                class="notification-email-digest"
                                @click="sendEmailDigest"
                            >
                                Send me the email digest
                            </Button>
                        </div>
                    </div>
                </div>
                <template v-if="isTabActive(Tabs.People)">
                    <div class="manage-users-modal__add-client-wrapper">
                        <Button
                            class="pull-right"
                            type="text"
                            icon="md-person-add"
                            data-testid="add-user__button"
                            @click="onAddUser"
                        >
                            Add user
                        </Button>
                    </div>

                    <div class="manage-users-modal-content">
                        <Spin v-if="$apollo.queries.users.loading" fixed></Spin>
                        <user-roles-list
                            v-else
                            :users="users"
                            @roleChanged="onUserRoleChanged"
                            @userDeleted="refreshUserData"
                        />
                    </div>

                    <user-access-scope-modal
                        v-if="showNewUserModal"
                        new-user
                        @userCreated="refreshUserData"
                        @close="showNewUserModal = false"
                    ></user-access-scope-modal>
                </template>
            </div>

            <template #footer>
                <Button type="text" data-testid="workspace-settings-modal__cancel_button" @click="close">
                    Cancel
                </Button>
                <Button type="primary" data-testid="workspace-settings-modal__save_button" @click="save">
                    Save All
                </Button>
            </template>
        </hox-modal>
    </div>
</template>
<script>
import bus from "@/bus";

import UsersQuery from "@/apollo/queries/UsersWithScopeEntities.gql";
import UsersNotificationSettingsQuery from "@/apollo/queries/UsersNotificationSettings.gql";
import SendMeEmailDigestQuery from "@/apollo/queries/v2/SendMeEmailDigest.gql";

import UpdateUserMutation from "@/apollo/mutations/UpdateUser.gql";
import SetUserNotificationSettings from "@/apollo/mutations/SetUserNotificationSettings.gql";

import HoxModal from "@/components/Modal/Modal/Modal";
import NavigationTab from "@/components/common/NavigationTabs/Tab";
import NavigationTabs from "@/components/common/NavigationTabs/Container";
import UserRolesList from "@/components/ManageUsers/UserRolesList";
import UserAccessScopeModal from "@/components/ManageUsers/UserAccessScopeModal";
import LabelledSwitch from "@/components/Campaign/LabelledSwitch";

const Tabs = {
    People: "people",
    Notifications: "notification"
};

const removeTypename = ({ __typename, ...rest }) => rest;

export default {
    name: "ManageUserModal",
    components: {
        LabelledSwitch,
        UserAccessScopeModal,
        HoxModal,
        NavigationTab,
        NavigationTabs,
        UserRolesList
    },

    data() {
        return {
            activeTab: Tabs.Notifications,
            showNewUserModal: false,
            userNotificationSettings: null,
            users: [],
            updatedRoles: {},
            updatedSettings: {}
        };
    },

    computed: {
        canManageUsers() {
            return this.$auth.userCan(this.$auth.Actions.CanManageUsers);
        },

        frequencySwitchValue() {
            return this.getSwitchValue("notificationFrequency", "everytime") ? "everytime" : "daily";
        }
    },

    watch: {
        canManageUsers() {
            if (!this.canManageUsers) {
                this.$snackbar.warning("You are not authorized to manage users");
                this.activeTab = Tabs.Notifications;
            }
        }
    },

    created() {
        this.Tabs = Tabs;

        if (this.canManageUsers) {
            this.activeTab = Tabs.People;
        }
    },

    methods: {
        close() {
            this.$emit("close");
        },

        getSwitchValue() {
            // eslint-disable-next-line prefer-rest-params
            const pathParts = Array.from(arguments);
            if (this.updatedSettings && this.updatedSettings[pathParts[0]]) {
                return typeof pathParts[1] !== "undefined"
                    ? this.updatedSettings[pathParts[0]][pathParts[1]]
                    : this.updatedSettings[pathParts[0]];
            }

            return typeof pathParts[1] !== "undefined"
                ? this.userNotificationSettings[pathParts[0]][pathParts[1]]
                : this.userNotificationSettings[pathParts[0]];
        },

        isTabActive(tab) {
            return this.activeTab === tab;
        },

        onAddUser() {
            this.showNewUserModal = true;
        },

        onNotificationFrequencyChange(value) {
            const everytime = value === "everytime";
            const settingObject = {
                everytime,
                daily: !everytime
            };

            this.updatedSettings = {
                ...this.updatedSettings,
                notificationFrequency: {
                    ...removeTypename(this.userNotificationSettings.notificationFrequency),
                    ...this.updatedSettings.notificationFrequency,
                    ...settingObject
                }
            };
        },

        onSettingsChange() {
            // eslint-disable-next-line prefer-rest-params
            const [value, ...pathParts] = Array.from(arguments);
            let settingObject = value;

            if (typeof pathParts[1] === "undefined") {
                this.updatedSettings = {
                    ...this.updatedSettings,
                    [pathParts[0]]: settingObject
                };
            } else {
                settingObject = {
                    [pathParts[1]]: value
                };

                this.updatedSettings = {
                    ...this.updatedSettings,
                    [pathParts[0]]: {
                        ...removeTypename(this.userNotificationSettings[pathParts[0]]),
                        ...this.updatedSettings[pathParts[0]],
                        ...settingObject
                    }
                };
            }
        },

        onUserRoleChanged(newRoleData) {
            this.updatedRoles[newRoleData.userId] = newRoleData;
        },

        refreshUserData() {
            this.$apollo.queries.users.refetch();
        },

        // eslint-disable-next-line complexity
        async save() {
            this.close();

            if (this.updatedSettings && this.activeTab === Tabs.Notifications) {
                try {
                    await this.$apollo.mutate({
                        mutation: SetUserNotificationSettings,
                        variables: {
                            notificationSettings: this.updatedSettings
                        },
                        refetchQueries: [
                            {
                                query: UsersNotificationSettingsQuery
                            }
                        ]
                    });
                    this.$snackbar.success("Notification settings updated successfully");
                } catch (e) {
                    this.$snackbar.error("Failed to updated the notification settings", e.message);
                }
            }

            const userIds = Object.keys(this.updatedRoles);
            if (!userIds.length) {
                return;
            }

            try {
                await Promise.all(
                    userIds.map(userId =>
                        this.$apollo.mutate({
                            mutation: UpdateUserMutation,
                            variables: {
                                id: this.updatedRoles[userId].userId,
                                roleId: this.updatedRoles[userId].roleId
                            },
                            refetchQueries: [{ query: UsersQuery }]
                        })
                    )
                );

                this.$snackbar.success(`${userIds.length} user roles updated successfully`);
            } catch (e) {
                this.$snackbar.error("Failed to updated user roles", e.message);
            }
        },

        setActive(tab) {
            this.activeTab = tab;
        },

        async sendEmailDigest() {
            try {
                await this.$apollo.query({
                    fetchPolicy: "no-cache",
                    query: SendMeEmailDigestQuery
                });

                this.$snackbar.success("Email digest was sent succesfuly to your email");
            } catch (e) {
                this.$snackbar.error("Failed to send the email digest", e.message);
            }
        }
    },

    apollo: {
        users: {
            query: UsersQuery,
            error(e) {
                bus.$emit("apolloErrorEvent", e);
            }
        },
        userNotificationSettings: {
            query: UsersNotificationSettingsQuery,
            error(e) {
                bus.$emit("apolloErrorEvent", e);
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.manage-users-modal {
    &-alert {
        .alert__icon-container {
            padding-bottom: 0;
        }
    }

    &__tab-content {
        width: 100%;
    }

    &__notification-tab {
        display: flex;
    }

    &__add-client-wrapper {
        float: right;
        width: 100%;
    }
}

.notification-label {
    width: 650px;
    color: $darktheme20;
    padding-right: $spacing-large;

    &--shared {
        padding-top: $spacing-smaller;
    }
}

.labelled-switch {
    padding: $spacing-small 0;
}

.notification-settings-group {
    margin-top: $spacing-large;
    padding-bottom: $spacing-large;
    border-bottom: 1px solid $grey3;
}

.notification-radio-group {
    padding-top: $spacing-small;
}

.notification-settings {
    padding-top: $spacing-large;
    margin: 0 $spacing-large;
    width: 750px;
}

.notification-email-digest {
    margin-top: 20px;
}
</style>
