<template>
    <div>
        <add-metadata-field-modal
            v-if="addMetadataModalIsVisible"
            @close="toggleAddMetadataModal"
            @columnVisibilityUpdated="reset"
        />
        <Dropdown
            placement="bottom-start"
            trigger="custom"
            :visible="dropdownIsVisible"
            @on-clickoutside="dropdownClickOutside"
        >
            <hox-dropdown-button
                :is-active="dropdownIsVisible"
                :is-disabled="!isEnabled"
                @click="toggleTableColumnsDropdown"
            >
                Customise table
            </hox-dropdown-button>
            <template #list>
                <div class="library-deliverable-table-customise-columns__dropdown-container">
                    <div
                        v-if="userCanManageMetadata"
                        class="library-deliverable-table-customise-columns__add-metadata-wrapper"
                    >
                        <Button type="primary" @click="toggleAddMetadataModal">Add column</Button>
                    </div>
                    <div class="library-deliverable-table-customise-columns__single-checkbox-wrapper">
                        <Checkbox
                            :indeterminate="someButNotAllColumnsAreVisible"
                            :value="allColumnsAreVisible"
                            @click.prevent.native="toggleBulkVisibility"
                        >
                            Show all
                        </Checkbox>
                    </div>
                    <div ref="sortOptionsContainer" class="library-deliverable-table-customise-columns__dropdown-body">
                        <CheckboxGroup v-model="tempVisibleColumnKeys">
                            <div
                                v-for="(id, index) of tempSortedColumnKeys"
                                :key="id"
                                :class="[
                                    'library-deliverable-table-customise-columns__option',
                                    index === currentDraggedIndex
                                        ? 'library-deliverable-table-customise-columns__option--drop-position'
                                        : ''
                                ]"
                                @mouseup.prevent="endDrag"
                                @mouseenter.prevent="moveDraggedOptionToIndexInTempSortedColumnKeys(index)"
                            >
                                <div
                                    class="library-deliverable-table-customise-columns__drag-icon"
                                    @mousedown.prevent="startDrag($event, index, columnLabelsByKey[id])"
                                >
                                    <Icon type="md-reorder" />
                                </div>
                                <div class="library-deliverable-table-customise-columns__checkbox-container">
                                    <Checkbox :label="id">
                                        {{ columnLabelsByKey[id] }}
                                    </Checkbox>
                                </div>
                            </div>
                        </CheckboxGroup>
                    </div>
                    <div>
                        <div class="library-deliverable-table-customise-columns__single-checkbox-wrapper">
                            <Checkbox
                                :value="tempCreativeValuesAreVisible"
                                @click.prevent.native="toggleCreativeValuesAreVisible"
                            >
                                Show creative values
                            </Checkbox>
                        </div>
                        <div class="library-deliverable-table-customise-columns__dropdown-footer">
                            <Button
                                class="library-deliverable-table-customise-columns__dropdown-button"
                                @click="toggleTableColumnsDropdown"
                            >
                                Cancel
                            </Button>
                            <Button
                                class="library-deliverable-table-customise-columns__dropdown-button"
                                type="primary"
                                @click="saveTempColumnKeys"
                            >
                                Apply
                            </Button>
                        </div>
                    </div>
                </div>
            </template>
        </Dropdown>
        <div
            v-if="isDragging"
            class="library-deliverable-table-customise-columns__dragged-label"
            :style="draggedLabelStyle"
        >
            {{ draggingLabel }}
        </div>
    </div>
</template>

<script>
import AddMetadataFieldModal from "@/components/Metadata/AddMetadataFieldModal";
import { DeliverableLibraryAction, DeliverableLibraryGetters } from "@/store/modules/deliverableLibrary";

export default {
    components: {
        AddMetadataFieldModal
    },
    data() {
        return {
            addMetadataModalIsVisible: false,
            currentDraggedIndex: null,
            draggingLabel: null,
            dropdownIsVisible: false,
            isDragging: false,
            isScrollingOptions: false,
            mousePosition: {
                x: 0,
                y: 0
            },
            tempCreativeValuesAreVisible: false,
            tempSortedColumnKeys: [],
            tempVisibleColumnKeys: []
        };
    },
    computed: {
        allColumnsAreVisible() {
            return this.dropdownIsVisible && this.tempVisibleColumnKeys.length === this.sortedColumnKeys.length;
        },

        campaignId() {
            return this.$route.params.campaignId;
        },

        clientId() {
            return this.$route.params.clientId;
        },

        columnLabelsByKey() {
            return this.$store.getters[DeliverableLibraryGetters.tableColumnLabelsByColumnKey];
        },
        creativeValuesAreVisible: {
            get() {
                return this.$store.state.deliverableLibrary.table.creativeValuesAreVisible;
            },
            set(value) {
                this.$store.dispatch(DeliverableLibraryAction.SetCreativeValuesAreVisible, value);
            }
        },
        draggedLabelStyle() {
            /*
                The reason we add to the positions is so that the cursor is
                not on the element so that mouseenter events are not blocked
                by it, which would break the drag and drop functionality for
                sorting columns.
            */
            return {
                top: `${this.mousePosition.y + 4}px`,
                left: `${this.mousePosition.x + 4}px`
            };
        },
        isEnabled() {
            return this.sortedColumnKeys !== null && this.visibleColumnKeys !== null;
        },
        someButNotAllColumnsAreVisible() {
            return (
                this.dropdownIsVisible &&
                this.tempVisibleColumnKeys.length !== this.sortedColumnKeys.length &&
                this.tempVisibleColumnKeys.length > 0
            );
        },
        sortedColumnKeys: {
            get() {
                return this.$store.state.deliverableLibrary.table.sortedColumnKeys;
            },
            set(sortedColumnKeys) {
                this.$store.dispatch(DeliverableLibraryAction.SetTableSortedColumnKeys, sortedColumnKeys);
            }
        },

        userCanManageMetadata() {
            return this.$auth.userCan(this.$auth.Actions.CanManageMetadata, {
                clientId: this.clientId,
                campaignId: this.campaignId
            });
        },

        visibleColumnKeys: {
            get() {
                return this.$store.state.deliverableLibrary.table.visibleColumnKeys;
            },
            set(visibleColumnKeys) {
                this.$store.dispatch(DeliverableLibraryAction.SetTableVisibleColumnKeys, visibleColumnKeys);
            }
        }
    },
    watch: {
        async mousePosition() {
            /*
                TODO:
                We could add an acceleration factor depending on the delta between
                mouse Y and the bottom/top.
            */
            if (!this.isScrollingOptions) {
                /*
                    There is currently undesired behaviour when at the top of the element we are scrolling
                    in that it will very quickly scroll. This is unrelated to anything we are doing here
                    and most likely related to the items inside the scrollable element being re-rendered.

                    We work around this by using boundaryOffsetPixels which causes the scroll to kick
                    in well before we get to the first item, hopefully avoiding the issue.

                    It's imperfect, for example if you start dragging an item that is within the
                    scrolling trigger zone it will begin to drag immediatately, but it's better than
                    not doing anything (and we could possibly fix this by doing something along the lines
                    of not triggering scroll if the item has not been dragged out of the item we start
                    dragging).
                */
                const boundaryOffsetPixels = 45;
                const pixelsScrolledPerLoop = 5;
                this.isScrollingOptions = true;
                while (this.isDragging) {
                    const { bottom, top } = this.$refs.sortOptionsContainer.getBoundingClientRect();
                    if (this.mousePosition.y > bottom - boundaryOffsetPixels) {
                        this.$refs.sortOptionsContainer.scrollTop =
                            this.$refs.sortOptionsContainer.scrollTop + pixelsScrolledPerLoop;
                    } else if (this.mousePosition.y < top + boundaryOffsetPixels) {
                        this.$refs.sortOptionsContainer.scrollTop =
                            this.$refs.sortOptionsContainer.scrollTop - pixelsScrolledPerLoop;
                    } else {
                        break;
                    }
                    /* eslint-disable-next-line */
                    await new Promise(resolve => setTimeout(resolve, 10));
                }
                this.isScrollingOptions = false;
            }
        }
    },
    beforeDestroy() {
        /*
            While the following events should have already been cleaned up we're
            going to be paranoid and make sure that they absolutely are cleaned
            up.
        */
        window.removeEventListener("mousemove", this.setMouseCoordinates, true);
        window.removeEventListener("mouseup", this.endDrag, true);
    },
    methods: {
        dropdownClickOutside() {
            // don't cose the menu when the add metadata modal is open
            if (this.addMetadataModalIsVisible || this.metadataModalClosed) {
                this.metadataModalClosed = false;
                return;
            }

            this.toggleTableColumnsDropdown();
        },
        endDrag() {
            this.isDragging = false;
            this.currentDraggedIndex = null;
            window.removeEventListener("mousemove", this.setMouseCoordinates, true);
            window.removeEventListener("mouseup", this.endDrag, true);
        },
        moveDraggedOptionToIndexInTempSortedColumnKeys(index) {
            if (this.isDragging) {
                /*
                    Make a local reference to the item so we can insert it into the
                    array later.
                */
                const item = this.tempSortedColumnKeys[this.currentDraggedIndex];
                /*
                    Remove the item from the array.
                */
                this.tempSortedColumnKeys.splice(this.currentDraggedIndex, 1);
                /*
                    Insert the item back into the array at the new index.
                */
                this.tempSortedColumnKeys.splice(index, 0, item);
                this.currentDraggedIndex = index;
            }
        },
        resetTempSortedColumnKeys() {
            this.tempSortedColumnKeys = [...this.sortedColumnKeys];
        },
        resetTempVisibleColumnKeys() {
            this.tempVisibleColumnKeys = [...this.visibleColumnKeys];
        },
        reset() {
            this.resetTempSortedColumnKeys();
            this.resetTempVisibleColumnKeys();
            this.tempCreativeValuesAreVisible = this.creativeValuesAreVisible;
        },
        saveTempColumnKeys() {
            this.creativeValuesAreVisible = this.tempCreativeValuesAreVisible;
            this.sortedColumnKeys = [...this.tempSortedColumnKeys];
            this.visibleColumnKeys = [...this.tempVisibleColumnKeys];
            this.toggleTableColumnsDropdown();
        },
        setMouseCoordinates(evt) {
            if (this.isDragging) {
                this.mousePosition = {
                    x: evt.clientX,
                    y: evt.clientY
                };
            }
        },
        startDrag(evt, index, label) {
            this.draggingLabel = label;
            this.isDragging = true;
            this.currentDraggedIndex = index;
            this.setMouseCoordinates(evt);
            window.addEventListener("mousemove", this.setMouseCoordinates, true);
            window.addEventListener("mouseup", this.endDrag, true);
        },
        toggleAddMetadataModal() {
            this.metadataModalClosed = true;
            this.addMetadataModalIsVisible = !this.addMetadataModalIsVisible;
        },
        toggleBulkVisibility() {
            if (this.allColumnsAreVisible) {
                this.tempVisibleColumnKeys = [];
            } else {
                this.tempVisibleColumnKeys = [...this.sortedColumnKeys];
            }
        },
        toggleCreativeValuesAreVisible() {
            this.tempCreativeValuesAreVisible = !this.tempCreativeValuesAreVisible;
        },
        toggleTableColumnsDropdown() {
            this.dropdownIsVisible = !this.dropdownIsVisible;
            if (this.dropdownIsVisible) {
                this.reset();
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

$dragged-dropzone-border-width: 1px;

.library-deliverable-table-customise-columns__add-metadata-wrapper {
    border-bottom: 1px solid $grey3;
    padding: $spacing-smaller $spacing-small $spacing-small;

    .ivu-btn {
        width: 100%;
    }
}

.library-deliverable-table-customise-columns__checkbox-container {
    flex: 1 0 auto;
}

.library-deliverable-table-customise-columns__drag-icon {
    align-items: center;
    align-self: stretch;
    display: flex;
    margin: (0 - $dragged-dropzone-border-width);
    padding: 0 $spacing-smaller;
    transition:
        background 0.2s linear,
        color 0.2s linear;

    &:hover {
        background: $primary;
        color: $white;
        cursor: grab;
    }
}

.library-deliverable-table-customise-columns__dragged-label {
    background: $primary;
    border-radius: $btn-border-radius;
    box-shadow: $box-shadow-base;
    color: $white;
    max-width: 300px;
    overflow: hidden;
    padding: $spacing-small $spacing;
    position: fixed;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 99999;
}

.library-deliverable-table-customise-columns__dropdown-container {
    display: flex;
    flex-direction: column;
    max-height: 330px;

    .ivu-checkbox-group-item {
        display: block;
        /*
      To make sure we don't have a 1px gap where the row has the hover state
      but the checkbox is not clickable we apply minus margin to the checkbox
      item.
    */
        margin-bottom: (0 - $dragged-dropzone-border-width);
        margin-top: (0 - $dragged-dropzone-border-width);
        padding: $spacing-smaller;
    }
}

.library-deliverable-table-customise-columns__dropdown-body {
    border-bottom: 1px solid $grey3;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    width: 220px;
}

.library-deliverable-table-customise-columns__dropdown-button {
    margin: 0 0 0 $spacing-small;
}

.library-deliverable-table-customise-columns__dropdown-footer {
    display: flex;
    justify-content: flex-end;
    padding: $spacing $spacing-small ($spacing-small - 5px) $spacing-large;
}

.library-deliverable-table-customise-columns__option {
    align-items: center;
    border: $dragged-dropzone-border-width solid transparent;
    display: flex;
    justify-content: space-between;
    transition: background 0.2s linear;

    &:hover {
        background: $grey1;
    }
}

.library-deliverable-table-customise-columns__option--drop-position {
    border: $dragged-dropzone-border-width dashed $primary;

    &:hover {
        background: $white;
    }

    .library-deliverable-table-customise-columns__checkbox-container,
    .library-deliverable-table-customise-columns__drag-icon {
        opacity: 0;
        pointer-events: none;
    }
}

.library-deliverable-table-customise-columns__single-checkbox-wrapper {
    border-bottom: 1px solid $grey3;
    transition: background 0.2s linear;

    &:hover {
        background: $grey1;
    }

    .ivu-checkbox-wrapper {
        display: block;
        margin: 0;
        padding: $spacing-small $spacing-small;
    }
}
</style>
