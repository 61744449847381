<template>
    <div class="tag-list">
        <Tag v-for="tag in tags" :key="tag" closable :name="tag" @on-close="onTagClose">{{ tag }}</Tag>
    </div>
</template>

<script>
export default {
    name: "TagList",
    props: {
        tags: {
            type: Array,
            required: true
        }
    },

    watch: {
        tags(value) {
            // eslint-disable-next-line vue/no-mutating-props
            this.tags = value;
        }
    },

    methods: {
        onTagClose(event, tagName) {
            this.$emit("tagClose", tagName);
        }
    }
};
</script>

<style lang="scss">
.tag-list {
    .ivu-tag {
        font-size: 10px;
        height: auto;
        text-transform: uppercase;

        .ivu-icon-ios-close {
            margin-top: 1px;
        }
    }
}
</style>
