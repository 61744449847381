export const parseTemperatureValueString = inputStr => {
    let str = inputStr;
    if (str.startsWith("X")) {
        str = str.substring(1);
    }
    const regex = /^(>=|<=|>|<|===)?(\d+)$/;
    const match = str.match(regex);

    if (match) {
        return {
            operator: match[1] || "",
            value: parseInt(match[2], 10)
        };
    }
    return {
        operator: "",
        value: null
    };
};
