<template>
    <insight-preview-wrapper :filters="matchingContentFilters" :check-for-data="false">
        <template #name>
            {{ title }}
        </template>

        <template #headline>
            {{ headline }}
        </template>

        <key-takeaway-panel :matching="true" @parentTitle="headline = $event" />
        <key-takeaway-panel :matching="false" />

        <!--  TODO:       <template #chartPreview>
            <insights-chart-preview :data="chartData" :title="title"></insights-chart-preview>
        </template>-->
    </insight-preview-wrapper>
</template>

<script>
import InsightPreviewWrapper from "@/components/CreativeIntelligence/InsightPreviews/InsightPreviewWrapper";

import { CreativeInsightsGetters } from "@/store/modules/creativeInsights";
import { CreativeInsightsContentFilter } from "@/enums/creativeInteligence";
import { InsightType } from "@/components/CreativeIntelligence/constants";
import KeyTakeawayPanel from "@/components/CreativeIntelligence/InsightPreviews/KeyTakeawayPanel";

export default {
    name: "KeyTakeaway",
    components: { KeyTakeawayPanel, InsightPreviewWrapper },

    data() {
        return {
            title: "Key Takeaway",
            headline: ""
        };
    },

    computed: {
        chartData() {
            return this.matchingDeliverables.reduce((acc, deliverable) => {
                // this code assumes that we have unique reporting labels
                acc[deliverable.reportingLabel] = {
                    deliverables: [deliverable],
                    summaryStats: {
                        kpiMetric: {
                            count: 1,
                            [this.kpiSettingsStat]: deliverable.metrics[this.kpiSettingsName]
                        },
                        confidenceMetric: {
                            count: 1,
                            [this.confidenceMetricStat]: deliverable.metrics[this.confidenceSettingsName]
                        }
                    }
                };

                return acc;
            }, {});
        },

        confidenceSettingsName() {
            return this.$store.getters[CreativeInsightsGetters.ConfidenceMetricName];
        },

        confidenceMetricStat() {
            return this.$store.getters[CreativeInsightsGetters.ConfidenceMetricStat];
        },

        confidenceValue() {
            return this.creative.metrics[this.confidenceSettingsName];
        },

        kpiSettingsName() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricName];
        },

        kpiSettingsStat() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricStat];
        },

        kpiSettingsValue() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricValue];
        },

        displayTemplates() {
            if (!this.selectedTagIds.length) {
                return null;
            }

            return this.tagsById[this.selectedTagIds[0]].displayTemplates;
        },

        showsDiversityTemplate() {
            return this.displayTemplates && this.displayTemplates.includes("diversity");
        },

        selectedTagIds() {
            return this.$store.state.ciTags.selectedTagIds;
        },

        tagsById() {
            return this.$store.state.ciTags.tagsById;
        }
    },

    created() {
        this.matchingContentFilters = [CreativeInsightsContentFilter.Content];
        this.InsightType = InsightType;
    },

    methods: {
        onError() {
            this.resetData();
        },

        resetData() {
            this.matchingDeliverables = [];
            this.matchingStats = {};
        }
    }
};
</script>

<style lang="scss">
@import "../../../../sass/_variables.scss";
$bgColor: transparent !important;

.key-takeaway {
    width: 100%;
    &.ivu-collapse > .ivu-collapse-item {
        border: 1px solid transparent;

        .ivu-collapse-header {
            border: none;
            background: $bgColor;
            box-shadow: none !important;
        }

        &.ivu-collapse-item-active {
            border-bottom: 0px solid transparent !important;

            .ivu-collapse-header {
                background: $bgColor !important;
            }
        }
    }

    .ivu-collapse-content {
        background: $bgColor !important;
    }

    &.ivu-collapse {
        border: none;
        border-radius: 0;
        background-color: $bgColor;

        .ivu-collapse-content {
            background-color: $bgColor;
        }

        .ivu-collapse-content > .ivu-collapse-content-box {
            padding: 6px 0;
        }
    }

    .ivu-checkbox-wrapper {
        margin: 0;
        padding: 0 0 0 50px;
        display: block;
    }

    &__panel {
        .ivu-collapse-header {
            height: 24px !important;
            line-height: 24px !important;
            padding: 0 !important;
            display: flex;
            width: 100%;
            flex: 1 0 100%;
            justify-content: flex-start;
            align-items: flex-start;
            background-color: transparent;
            box-shadow: none;
            font-weight: normal;

            & > .ivu-icon {
                display: flex;
                margin-top: 0;
            }
        }
    }
}
</style>
