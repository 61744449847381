import { mapActions, mapState } from "vuex";
import { UiAction } from "@/store/modules/ui";

export default {
    created() {
        this.$store.dispatch(UiAction.SetSidebarWidthOpen, this.sidebarWidth);
        this.$store.dispatch(UiAction.SetSidebarWidthCollapsed, this.sidebarCollapsedWidth || 0);
        // To make sure that if we navigate away from the view with sidebar, the sidebar gets closed
        // Only if the new route is a different one i.e. not the same route with a different query.
        this.$router.beforeEach((from, to, next) => {
            if (from.name !== to.name) {
                this.closeSidebar();
            }

            next();
        });
    },

    computed: {
        ...mapState("auth", ["isAuthorized", "authConfig"]),
        ...mapState("ui", ["isSidebarOpen", "sidebarWidthOpen", "sidebarWidthCollapsed", "theme"]),

        isAuthorizedContentVisible() {
            return this.isAuthorized;
        },

        themeClassName() {
            return `theme theme-${this.theme}`;
        }
    },

    methods: {
        ...mapActions("ui", ["openSidebar", "closeSidebar"])
    }
};
