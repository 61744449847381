// eslint-disable-next-line import/no-extraneous-dependencies
import nl2br from "shared-utils/nl2br";

export default {
    defaultMessage: "Unable to display the error here. Please check your console for error details",
    set(notification) {
        this.notification = notification;
        this.notification.config({ duration: 4 });
    },
    notify(type, message, description = "", params = {}) {
        const content = nl2br(description ? `${message}: ${description}` : message);
        this.notification[type]({ content, ...params });
    },
    error(message, description, params = { closable: true }) {
        this.notify("error", message, description, params);
    },
    info(message, description, params = { closable: true }) {
        this.notify("info", message, description, params);
    },
    success(message, description, params = { closable: true }) {
        this.notify("success", message, description, params);
    },
    warning(message, description, params = { closable: true }) {
        this.notify("warning", message, description, params);
    },
    render(type, params = { closable: true }) {
        this.notification.message(type, { ...params });
    },
    fieldRequired(message) {
        this.error("Field Required", message);
    },
    unauthorized(err) {
        this.error("Unauthorized", err.message || this.defaultMessage);
    },
    templateAdded() {
        this.success("Template added to set", "The template will now appear");
    },
    authentication(err, type = "error") {
        const errorMessage = typeof err === "string" ? err : err.message || this.defaultMessage;
        this[type]("Authentication Error", errorMessage);
    },
    image(err, type = "error") {
        this[type]("Image Error", err.message || this.defaultMessage);
    },

    graphQLErrors(errors) {
        const errorArray = Array.isArray(errors) ? errors : [errors];

        errorArray.forEach(err => {
            if (err.message === "Unauthorized") {
                this.error("Unauthorized");
            } else {
                this.error("GraphQL Error", err.message || this.defaultMessage);
            }
        });
    },
    graphQLError(err) {
        if ([401, 403].includes(err.statusCode)) {
            this.unauthorized(err);
        } else if (Array.isArray(err.graphQLErrors)) {
            this.graphQLErrors(err.graphQLErrors);
        }
    }
};
