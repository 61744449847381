<template>
    <Dropdown
        placement="bottom-start"
        trigger="custom"
        :visible="dropdownIsVisible"
        @on-clickoutside="toggleDropdown"
    >
        <hox-dropdown-button
            :is-active="dropdownIsVisible"
            :is-disabled="deliverables.length === 0 || isDisabled"
            :is-loading="isSettingDeliverableStatus"
            button-type="primary"
            data-testid="library__production-status-select"
            @click="toggleDropdown"
        >
            <slot>Production status</slot>
        </hox-dropdown-button>
        <template #list>
            <qa-status-dropdown-items @statusSelected="setDeliverableStatus" />
        </template>
    </Dropdown>
</template>

<script>
import QaStatusDropdownItems from "@/components/Campaign/QaStatusDropdownItems";
import HoxDropdownButton from "@/components/common/DropdownButton";
import { BannerQaStatusLabel } from "@/enums/banners";
import { setDeliverablesGlobalStatus } from "@/services/Qa";
import { DeliverableLibraryAction } from "@/store/modules/deliverableLibrary";

export default {
    components: {
        HoxDropdownButton,
        QaStatusDropdownItems
    },
    props: {
        deliverables: {
            required: true,
            type: Array
        },
        isDisabled: {
            type: Boolean
        },
        libraryFiltersForQuery: {
            required: false,
            type: Object
        },
        itemToChangeNumber: {
            required: false,
            type: Number
        }
    },
    data() {
        return {
            dropdownIsVisible: false,
            isSettingDeliverableStatus: false
        };
    },
    computed: {
        campaignId() {
            return this.$store.state.route.params.campaignId;
        }
    },
    methods: {
        toggleDropdown() {
            this.dropdownIsVisible = !this.dropdownIsVisible;
        },
        async setDeliverableStatus(status) {
            this.toggleDropdown();
            this.isSettingDeliverableStatus = true;
            const deliverablesCount = this.itemToChangeNumber || this.deliverables.length;
            try {
                const deliverables = await setDeliverablesGlobalStatus(
                    this.campaignId,
                    this.deliverables,
                    status,
                    this.libraryFiltersForQuery
                );
                this.$store.dispatch(DeliverableLibraryAction.SetDeliverableStatusByIdHash, deliverables);
                if (deliverablesCount === 1) {
                    this.$snackbar.success(`The deliverable is now "${BannerQaStatusLabel[status]}"`);
                } else {
                    this.$snackbar.success(
                        `${deliverablesCount} deliverables are now "${BannerQaStatusLabel[status]}"`
                    );
                }
                this.$emit("statusHasBeenSet");
            } catch (err) {
                this.$snackbar.error(
                    "There was an unexpected error and the deliverable status was unable to be set. Please try again in a few moments."
                );
            }
            this.isSettingDeliverableStatus = false;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
</style>
