import Vue from "vue";
import Vuex from "vuex";

import auth from "@/store/modules/auth";
import mediaLibrary from "@/store/modules/mediaLibrary";
import ui from "@/store/modules/ui";
import editor from "@/store/modules/editor";
import campaign from "@/store/modules/campaign";
import deliverableLibrary from "@/store/modules/deliverableLibrary";
import jobs from "@/store/modules/jobs";
import masterTemplateLibrary from "@/store/modules/masterTemplateLibrary";
import notifications from "@/store/modules/notifications";
import roles from "@/store/modules/roles";
import banners from "@/store/modules/banners.store";
import ciTags from "@/store/modules/ciTags";
import creativeInsights from "@/store/modules/creativeInsights";
import imagine from "@/store/modules/imagine";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isMaintenanceMode: false
    },

    modules: {
        auth,
        banners,
        campaign,
        ciTags,
        creativeInsights,
        deliverableLibrary,
        editor,
        jobs,
        masterTemplateLibrary,
        mediaLibrary,
        notifications,
        roles,
        ui,
        imagine
    }
});
