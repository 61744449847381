export const MEDIA_LIBRARY_ROOT = "media-library";
export const PATH_SEPARATOR = "/";
export const EXTENSION_SEPARATOR = ".";
export const MEDIA_TYPES = {
    FOLDER: "FOLDER",
    FILE: "FILE"
};
export const IMAGE_PROCESSING_MIMES = ["image/jpeg", "image/png"];
export const IMAGE_PROCESSING_EXTS = ["png", "jpg", "jpeg"];
export const IMAGE_TAG_TYPES = {
    AGE: "Age",
    COLOR_THEORY: "ColorTheory",
    DOMINANT_COLOUR: "DominantColour",
    GENDER: "Gender",
    LABEL: "Label",
    OBJECT: "Object",
    LANDMARK: "Landmark",
    LOGO: "Logo",
    EMOTION: "Emotion",
    FACIAL_FEATURE: "FacialFeature",
    TEXT: "Text",
    TEXT_WORD: "TextWord",
    TEXT_LINE: "TextLine",
    TEXT_BLOCK: "TextBlock"
};
