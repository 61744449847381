<template>
    <div class="ivu-tooltip-dark ivu-tooltip-popper qa-banner-cursor-info" x-placement="top" :style="style">
        <div class="ivu-tooltip-content">
            <div class="ivu-tooltip-arrow"></div>
            <div class="ivu-tooltip-inner">Click to annotate</div>
        </div>
    </div>
</template>

<script>
const manualOffset = {
    x: 69,
    y: 52
};

const withHeaderOffset = {
    x: 69,
    y: 93
};

export default {
    name: "QaBannerCursorInfo",

    props: {
        hasHeader: {
            type: Boolean,
            default: false
        },

        initMouseEvent: {
            type: MouseEvent,
            default: null
        }
    },

    data() {
        return {
            parentBoundingRect: {
                top: 0,
                left: 0
            },
            style: {
                top: 0,
                left: 0
            }
        };
    },

    computed: {
        offset() {
            if (this.hasHeader) {
                return withHeaderOffset;
            }

            return manualOffset;
        }
    },
    created() {
        document.addEventListener("mousemove", this.updatePosition, false);
    },

    mounted() {
        this.parentBoundingRect = this.$parent.$el.getBoundingClientRect();

        if (this.initMouseEvent) {
            this.updatePosition(this.initMouseEvent);
        }
    },

    beforeDestroy() {
        document.removeEventListener("mousemove", this.updatePosition, false);
    },

    methods: {
        updatePosition(event) {
            this.style = {
                "will-change": "top, left",
                left: `${event.pageX - this.parentBoundingRect.left - this.offset.x}px`,
                top: `${event.pageY - this.parentBoundingRect.top - this.offset.y}px`
            };
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.qa-banner-cursor-info {
    display: none;
    z-index: $zindex-qa-cursor-info;
}
</style>
