const AdTypes = {
    HTML5: "HTML5",
    HTMLRM: "HTMLRM"
};
const empty = {};

const EditableType = {
    Array: "array",
    BackgroundImage: "backgroundImage",
    Boolean: "boolean",
    Css: "css",
    File: "file",
    Folder: "folder",
    Image: "image",
    Textarea: "textarea",
    Subtitles: "subtitles",
    Text: "text",
    Url: "url",
    Video: "video"
};

const EditableImageTypes = [EditableType.Image, EditableType.BackgroundImage];
const EditableFileTypes = [EditableType.File, EditableType.Video, ...EditableImageTypes];
const EditableMediaTypes = [EditableType.Folder, ...EditableFileTypes];

export { AdTypes, empty, EditableMediaTypes };
