<template>
    <div class="ci-tag" :class="{ 'ci-tag--selected': isSelected }" @click="toggle">
        <manage-tag-modal v-if="isEditModalShown" :is-modal-visible.sync="isEditModalShown" :tag="tag" />

        <div class="ci-tag__name">
            {{ tag.name }}
        </div>
        <kpi-stats v-if="hasStats" :stats="stats" />
    </div>
</template>

<script>
import { TagsAction } from "@/store/modules/ciTags";
import ManageTagModal from "@/components/CreativeIntelligence/ManageTagModal";
import KpiStats from "@/components/CreativeIntelligence/KpiStats";

export default {
    name: "ReportingTag",
    components: { KpiStats, ManageTagModal },
    props: {
        tag: {
            type: Object
        },

        stats: {
            type: Object
        }
    },

    data() {
        return {
            isEditModalShown: false
        };
    },

    computed: {
        hasStats() {
            return !!Object.keys(this.stats).length;
        },

        isSelected() {
            return this.selectedTagIds.includes(this.tag.tagId);
        },

        selectedTagIds() {
            return this.$store.state.ciTags.selectedTagIds;
        }
    },

    methods: {
        showEditModal() {
            this.isEditModalShown = true;
        },

        toggle() {
            if (!this.isSelected) {
                this.$store.dispatch(TagsAction.ToggleTagSelection, this.tag.tagId);
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.ci-tag {
    width: 100%;
    padding: 0 $spacing-small 0 $spacing-large;
    line-height: 40px;
    height: 40px;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &__name {
        flex: 1 1;
        text-overflow: ellipsis;
        overflow: hidden;
        color: currentColor;
        white-space: nowrap;
    }

    &--selected {
        color: $ci-blue;
        background-color: $ci-blue-bg;
        box-shadow: inset -3px 0px 0px #1890ff;
    }
}
</style>
