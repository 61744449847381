const EditableTypes = {
    array: "array",
    backgroundImage: "backgroundImage",
    boolean: "boolean",
    css: "css",
    folder: "folder",
    iframe: "iframe",
    image: "image",
    number: "number",
    subtitles: "subtitles",
    text: "text",
    textarea: "textarea",
    url: "url",
    video: "video"
};
const empty = {};

export { EditableTypes, empty };
