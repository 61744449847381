import bus from "@/bus";
import { BannerActionEvents, BannerState } from "@/enums/banners";
import deliverableMethodsMixin from "@/mixins/deliverableMethodsMixin";

export default {
    computed: {
        bannerIframeId() {
            const deliverable = this.selectedDeliverable || this.deliverable;
            if (deliverable) {
                return this.iframeId(deliverable, this.bannerGroup);
            }
            return null;
        },
        bannerIsPlaying() {
            return (
                this.bannerState &&
                (this.bannerState.state === BannerState.Play || this.bannerState.state === BannerState.Loaded)
            );
        },
        bannerIsCompleted() {
            return this.bannerState && this.bannerState.state === BannerState.Completed;
        },
        bannerState() {
            return this.$store.state.banners.instanceStates[this.bannerIframeId];
        },
        playPauseReplayIcon() {
            if (this.bannerShouldReplay || this.bannerIsCompleted) {
                return "md-refresh";
            }
            if (this.bannerIsPlaying) {
                return "md-pause";
            }
            return "md-play";
        }
    },
    data() {
        return {
            bannerPlaybackTime: 0,
            bannerPlaybackTimeSetByUser: false,
            bannerPlaybackTimeSetFromBanner: false,
            bannerShouldReplay: false
        };
    },
    methods: {
        playPauseReplayBanners() {
            if (this.bannerIsPlaying) {
                bus.$emit(BannerActionEvents.AllBannersPause, this.bannerGroup);
            } else if (this.bannerIsCompleted || this.bannerShouldReplay) {
                this.bannerShouldReplay = false;
                bus.$emit(BannerActionEvents.AllBannersReplay, this.bannerGroup);
            } else {
                bus.$emit(BannerActionEvents.AllBannersPlay, this.bannerGroup);
            }
        },

        setBannerPlaybackTimeFromBanner(time) {
            if (time < this.bannerPlaybackTime && this.bannerPlaybackTimeSetByUser) {
                /*
                    We get to this point when the user attempts to seek beyond the
                    duration of the banner. We want to have the same behaviour
                    as to when the banner naturally completed, so we manually set
                    the bannerShouldReplay flag.
                */
                this.bannerShouldReplay = true;
            } else {
                this.bannerShouldReplay = false;
            }
            this.bannerPlaybackTimeSetFromBanner = true;
            this.bannerPlaybackTimeSetByUser = false;
            this.bannerPlaybackTime = time;
        },
        // eslint-disable-next-line complexity
        setBannerPlaybackTime(time) {
            /*
                Because setting the time from the banner triggers the scrub bar
                to emit an updated time we need to break the loop so it doesn't
                get stuck.

                We do this with the flag bannerPlaybackTimeSetFromBanner.

                If the banner is not playing then none of this matters.
            */
            if (!this.bannerPlaybackTimeSetFromBanner || !this.bannerIsPlaying) {
                if (this.bannerIsCompleted) {
                    this.bannerShouldReplay = true;
                }
                if (time < this.bannerPlaybackTime || !this.bannerIsCompleted) {
                    bus.$emit(BannerActionEvents.AllBannersSeek, time, this.bannerGroup);
                }
                this.bannerPlaybackTimeSetByUser = true;
                this.bannerPlaybackTime = time;
            }
            this.bannerPlaybackTimeSetFromBanner = false;
        }
    },
    mixins: [deliverableMethodsMixin]
};
