<template>
    <Table
        :ref="ref"
        v-bind="attrs"
        :class="className"
        :columns="columns"
        v-on="listeners"
        @on-selection-change="onSelect"
        @on-row-click="onRowClick"
    ></Table>
</template>
<script>
import passThroughMixin from "@/mixins/passThroughMixin";

export default {
    name: "TableCheckboxGroup",
    mixins: [passThroughMixin],
    props: {
        value: {
            type: Array,
            default() {
                return [];
            }
        },
        type: {
            type: String,
            default: "table"
        },
        checkboxWidth: {
            type: Number,
            default: 60
        },
        disableRowClick: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            selected: []
        };
    },

    computed: {
        attrs() {
            const { value, columns, ref, ...attrs } = this.$attrs;
            return attrs;
        },

        className() {
            return `table-checkbox-group table-checkbox-group--${this.type}`;
        },

        columns() {
            if (!this.$attrs.columns || !this.$attrs.columns.length) {
                return [this.selectionColumn, this.labelColumn];
            }

            if (this.$attrs.columns[0].type !== "selection") {
                return [this.selectionColumn, ...this.$attrs.columns];
            }

            return this.$attrs.columns;
        },

        listeners() {
            const { input, ...listeners } = this.$listeners;
            delete listeners["on-selection-change"];
            delete listeners["on-row-click"];

            return listeners || [];
        },

        ref() {
            return this.$attrs.ref || "tableCheckboxGroup";
        }
    },

    created() {
        this.selectionColumn = {
            type: "selection",
            width: this.checkboxWidth,
            align: "center"
        };

        this.labelColumn = {
            title: "Label",
            key: "label"
        };
    },

    methods: {
        onRowClick(row, index) {
            if (this.disableRowClick) {
                return;
            }

            if (!row._disabled) {
                this.$refs[this.ref].toggleSelect(index);
            }

            this.$emit("on-row-click", row, index);
        },

        onSelect(value) {
            this.$emit("input", value);
            this.$emit("on-selection-change", value);
        }
    }
};
</script>

<style lang="scss">
.table-checkbox-group {
    &--ghost {
        border-color: transparent;

        .ivu-table {
            &::before,
            &::after {
                background-color: inherit;
            }

            th,
            td {
                background-color: inherit;
                border-color: transparent;
                height: 25px;
            }

            table {
                border-color: transparent;
                background-color: inherit;
            }
        }
    }
}
</style>
