<template>
    <hox-modal @close="close">
        <hox-loading-layer v-if="isAddingFont" />
        <template #header>Add custom font</template>
        <font-form
            :upload-config="uploadConfig"
            @validationErrorsUpdate="setValidationErrors"
            @formDataUpdate="setFormData"
            @formTabSwitched="onTabChange"
            @submit="addFont"
        />
        <Alert banner type="warning">
            Licensing
            <template #desc>Only upload fonts if the appropriate font license agreement has been obtained</template>
        </Alert>
        <div v-if="error" class="add-font-modal__unexpected-error-wrapper">
            <Alert banner type="error">
                <template #desc>{{ error }}</template>
            </Alert>
        </div>
        <template #footer>
            <Button :disabled="isAddingFont" type="primary" data-testid="add-font-modal__add-button" @click="addFont">
                Add
            </Button>
        </template>
    </hox-modal>
</template>

<script>
import addFontMutation from "@/apollo/mutations/AddFont.gql";
import FontForm from "@/components/FontForm";

export default {
    name: "AddFontModal",

    components: {
        FontForm
    },

    props: {
        // sections: {
        //     required: true,
        //     type: Array
        // },

        uploadConfig: {
            required: true,
            type: Object
        }
    },

    data() {
        return {
            error: "",
            isAddingFont: false,
            formData: {},
            validationErrors: []
        };
    },

    computed: {
        clientId() {
            return this.$store.state.route.params.clientId;
        }
    },

    methods: {
        async addFont() {
            if (!this.isAddingFont) {
                // Return if there are validation errors
                if (this.validationErrors.length) {
                    const [error] = this.validationErrors;
                    this.error = error;
                    return;
                }
                // Lock and reset errors
                this.isAddingFont = true;
                this.error = "";

                try {
                    await this.$apollo.mutate({
                        mutation: addFontMutation,
                        variables: {
                            clientId: this.clientId,
                            font: {
                                name: this.formData.name,
                                ...(this.formData.key && { key: this.formData.key }),
                                ...(this.formData.url && { url: this.formData.url })
                                // sectionId: this.formData.sectionId === "none" ? null : this.formData.sectionId
                            }
                        }
                    });

                    this.$snackbar.success("Font added");
                    this.$emit("fontAdded");
                    this.close();
                } catch ({ graphQLErrors }) {
                    const duplicateKey = graphQLErrors.find(
                        ({ extensions }) => extensions && extensions.code === "DUPLICATE_KEY"
                    );

                    this.error = duplicateKey
                        ? duplicateKey.message
                        : "There was an unexpected error and the font could not be added. Please try again in a few moments.";
                } finally {
                    this.isAddingFont = false;
                }
            }
        },

        close() {
            this.$emit("close");
        },

        onTabChange() {
            this.error = "";
        },

        setFormData(formData) {
            this.formData = formData;
        },

        setValidationErrors(validationErrors) {
            this.validationErrors = validationErrors;

            if (!this.validationErrors.length) {
                this.error = "";
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.add-font-modal__unexpected-error-wrapper {
    padding: $spacing 0 0 0;
}
</style>
