<template>
    <div class="library-deliverable-table-metadata-cell">
        <div :class="contentClasses">
            <span :class="valueClasses" :title="displayValue" @dblclick.self="toggleInputIsVisible">
                {{ displayValue }}
            </span>
            <div v-if="userCanManageMetadata" class="library-deliverable-table-metadata-cell__edit-button-wrapper">
                <hox-square-button
                    has-inverse-hover
                    :is-active="inputIsVisible"
                    size="small"
                    @click="toggleInputIsVisible"
                >
                    <Icon type="md-create" />
                </hox-square-button>
            </div>
        </div>
        <transition name="fade">
            <div v-if="inputIsVisible" class="library-deliverable-table-metadata-cell__input-container">
                <form class="library-deliverable-table-metadata-cell__form" @submit.prevent="setMetadataValue">
                    <Input ref="input" v-model="internalValue" />
                </form>
                <div>
                    <hox-square-button has-inverse-hover size="small" @click="setMetadataValue">
                        <Icon type="md-checkmark" />
                    </hox-square-button>
                    <hox-square-button has-inverse-hover size="small" @click="toggleInputIsVisible">
                        <Icon type="md-close" />
                    </hox-square-button>
                </div>
            </div>
        </transition>
        <hox-loading-layer v-if="isSettingMetadataValue" :is-full-screen="false" is-transparent size="smaller" />
    </div>
</template>

<script>
import Deliverables from "@/services/Deliverables";
import {
    addComputedDeliverablesMetadataValue,
    removeComputedDeliverablesMetadataValueByFieldId
} from "@/services/Metadata";

export default {
    props: {
        deliverable: {
            required: true,
            type: Object
        },

        fieldId: {
            type: String,
            required: true
        },

        inputIsVisible: {
            default: false,
            type: Boolean
        },

        metadataValue: {
            default: () => ({
                _id: null,
                value: ""
            }),
            type: Object
        }
    },

    data() {
        return {
            isSettingMetadataValue: false,
            internalValue: this.metadataValue.value || null
        };
    },
    computed: {
        campaignId() {
            return this.$route.params.campaignId;
        },

        clientId() {
            return this.$route.params.clientId;
        },

        contentClasses() {
            const classes = ["library-deliverable-table-metadata-cell__content"];
            if (this.isSettingMetadataValue) {
                classes.push("library-deliverable-table-metadata-cell__content--faded");
            }
            return classes;
        },

        displayValue() {
            if (this.hasValue) {
                return this.metadataValue.value;
            }
            return "No value";
        },

        hasValue() {
            return this.metadataValue.value;
        },

        userCanManageMetadata() {
            return this.$auth.userCan(this.$auth.Actions.CanManageMetadata, {
                clientId: this.clientId,
                campaignId: this.campaignId
            });
        },

        valueClasses() {
            const classes = ["library-deliverable-table-metadata-cell__value"];
            if (!this.hasValue) {
                classes.push("library-deliverable-table-metadata-cell__value--muted");
            }
            return classes;
        }
    },

    watch: {
        async inputIsVisible() {
            if (this.inputIsVisible) {
                this.internalValue = this.metadataValue.value;
                await this.$nextTick();
                this.$refs.input.focus();
            }
        }
    },

    methods: {
        async setMetadataValue() {
            this.toggleInputIsVisible();
            this.isSettingMetadataValue = true;
            const deliverableIdentifiers = Deliverables.getComputedDeliverableIdentifiers([this.deliverable]);
            try {
                if (this.internalValue) {
                    const metadataValue = await addComputedDeliverablesMetadataValue(
                        deliverableIdentifiers,
                        this.fieldId,
                        this.internalValue
                    );
                    this.$emit("metadataValueUpdated", metadataValue);
                } else if (this.metadataValue._id) {
                    await removeComputedDeliverablesMetadataValueByFieldId(deliverableIdentifiers, this.fieldId);
                    this.$emit("metadataValueUpdated", { ...this.metadataValue, value: undefined });
                }
            } catch (err) {
                this.$snackbar.error(
                    "The column value was not set because there was an unexpected error. Please try again in a few moments."
                );
            }
            this.isSettingMetadataValue = false;
        },

        toggleInputIsVisible() {
            /*
                The reason that the visibility of the input is handled via an external prop
                (and event), as opposed to internal data, is that we do not want to show multiple
                inputs in a single table at the same time and this is only possible when an
                external entity controls things.
            */
            if (this.userCanManageMetadata) {
                this.$emit("toggleInputIsVisible");
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.library-deliverable-table-metadata-cell {
    position: relative;
    max-width: 100%;

    &:hover {
        .library-deliverable-table-metadata-cell__edit-button-wrapper {
            opacity: 1;
        }
    }
}

.library-deliverable-table-metadata-cell__content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    transition:
        filter 0.2s,
        opacity 0.2s;

    &--faded {
        filter: blur(2px);
        opacity: 0.5;
    }
}

.library-deliverable-table-metadata-cell__form {
    flex: 1;
    margin: 0 $spacing-smaller 0 0;
}

.library-deliverable-table-metadata-cell__edit-button-wrapper {
    opacity: 0;
    transition: opacity 0.2s;
}

.library-deliverable-table-metadata-cell__input-container {
    align-items: center;
    background: $white;
    box-shadow: $box-shadow-base;
    display: flex;
    left: 100%;
    min-width: 240px;
    padding: $spacing-small;
    position: absolute;
    top: 50%;
    transform: translate(-100%, -50%);
    width: 100%;
    z-index: 4;

    .ivu-input {
        height: 28px;
    }
}

.library-deliverable-table-metadata-cell__value {
    cursor: text;
    flex: 1;
    margin: 0 $spacing-small 0 0;
    overflow: hidden;
    text-overflow: ellipsis;

    &--muted {
        color: $grey5;
    }
}
</style>
