/*
    The purpose of this file is to provide a single import for to use the components
    of the library so that we don't have to import and specify every component each
    time we want to use it.
*/
import Vue from "vue";
import Alert from "@/components/common/Alert";
import DropdownButton from "@/components/common/DropdownButton";
import EmptyMessage from "@/components/common/EmptyMessage";
import Input from "@/components/common/Input";
import ImagePreview from "@/components/common/AssetPreview";
import ImageCropper from "@/components/common/ImageCropper";
import LoadingLayer from "@/components/common/LoadingLayer";
import LoadingSpinner from "@/components/common/LoadingSpinner";
import MainNavButton from "@/components/common/MainNavButton";
import Modal from "@/components/Modal/Modal/Modal";
import ModalPortal from "@/components/Modal/Modal/ModalPortal";
import MultiInput from "@/components/common/MultiInput";
import NavigationTabsContainer from "@/components/common/NavigationTabs/Container";
import NavigationTabsItem from "@/components/common/NavigationTabs/Tab";
import NotificationItem from "@/components/common/NotificationItem";
import ProgressBar from "@/components/common/ProgressBar";
import SquareButton from "@/components/common/SquareButton";
import Table from "@/components/common/Table/Table";
import TableHeader from "@/components/common/Table/TableHeader";
import TableHeaderColumn from "@/components/common/Table/TableHeaderColumn";
import TableRow from "@/components/common/Table/TableRow";
import TableRowColumn from "@/components/common/Table/TableRowColumn";

const libraryPrefix = "hox";

/*
    Inputs
*/
Vue.component(`${libraryPrefix}-dropdown-button`, DropdownButton);
Vue.component(`${libraryPrefix}-input`, Input);
Vue.component(`${libraryPrefix}-multi-input`, MultiInput);
Vue.component(`${libraryPrefix}-square-button`, SquareButton);

/*
    Layout
*/
Vue.component(`${libraryPrefix}-alert`, Alert);
Vue.component(`${libraryPrefix}-empty-message`, EmptyMessage);
Vue.component(`${libraryPrefix}-loading-layer`, LoadingLayer);
Vue.component(`${libraryPrefix}-modal`, Modal);
Vue.component(`${libraryPrefix}-modal-portal`, ModalPortal);
Vue.component(`${libraryPrefix}-tabs`, NavigationTabsContainer);
Vue.component(`${libraryPrefix}-tabs-item`, NavigationTabsItem);
// Table
Vue.component(`${libraryPrefix}-table`, Table);
Vue.component(`${libraryPrefix}-table-header`, TableHeader);
Vue.component(`${libraryPrefix}-table-header-column`, TableHeaderColumn);
Vue.component(`${libraryPrefix}-table-row`, TableRow);
Vue.component(`${libraryPrefix}-table-row-column`, TableRowColumn);

/*
    Misc.
*/
Vue.component(`${libraryPrefix}-image-cropper`, ImageCropper);
Vue.component(`${libraryPrefix}-image-preview`, ImagePreview);
Vue.component(`${libraryPrefix}-loading-spinner`, LoadingSpinner);
Vue.component(`${libraryPrefix}-main-nav-button`, MainNavButton);
Vue.component(`${libraryPrefix}-notification-item`, NotificationItem);
Vue.component(`${libraryPrefix}-progress-bar`, ProgressBar);
