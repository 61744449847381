<template>
    <th :class="classes" :style="style">
        <div class="table-header-column__content">
            <slot />
        </div>
    </th>
</template>

<script>
export default {
    props: {
        hasStrongBorderLeft: {
            type: Boolean
        },
        isGroupHeader: {
            type: Boolean
        },
        isStickyLeft: {
            type: Boolean
        },
        isStickyRight: {
            type: Boolean
        },
        maxWidthPixels: {
            type: Number
        },
        minWidthPixels: {
            type: Number
        },
        stickyOffsetPixels: {
            type: Number
        },
        widthPixels: {
            type: Number
        }
    },
    data() {
        return {
            autoStickyOffsetPixels: undefined
        };
    },
    computed: {
        // eslint-disable-next-line complexity
        classes() {
            const classes = ["table-header-column"];
            if (this.isStickyTop()) {
                classes.push("table-header-column--sticky-top");
            }
            if (this.isStickyLeft) {
                classes.push("table-header-column--sticky-left");
            } else if (this.isStickyRight) {
                classes.push("table-header-column--sticky-right");
            }
            if (this.isGroupHeader) {
                classes.push("table-header-column--group-header");
            } else if (this.isSubHeader()) {
                classes.push("table-header-column--sub-header");
            }
            if (this.hasStrongBorderLeft) {
                classes.push("table-header-column--strong-border-left");
            }
            return classes;
        },
        // eslint-disable-next-line complexity
        style() {
            const style = {};
            if (this.widthPixels) {
                style.maxWidth = `${this.widthPixels}px`;
                style.minWidth = `${this.widthPixels}px`;
                style.width = `${this.widthPixels}px`;
            } else {
                if (this.maxWidthPixels) {
                    style.maxWidth = `${this.maxWidthPixels}px`;
                }
                if (this.minWidthPixels) {
                    style.minWidth = `${this.minWidthPixels}px`;
                }
            }
            if (this.stickyOffsetPixels) {
                if (this.isStickyLeft) {
                    style.left = `${this.stickyOffsetPixels}px`;
                } else if (this.isStickyRight) {
                    style.right = `${this.stickyOffsetPixels}px`;
                }
            } else if (this.autoStickyOffsetPixels && this.isStickyLeft) {
                style.left = `${this.autoStickyOffsetPixels}px`;
            }
            if (this.isStickyTop() && this.stickyTopOffsetPixels()) {
                style.top = `${this.stickyTopOffsetPixels()}px`;
            }
            return style;
        }
    },
    inject: ["isStickyTop", "isSubHeader", "stickyTopOffsetPixels"],
    watch: {
        isStickyLeft() {
            if (this.isStickyLeft && !this.stickyOffsetPixels) {
                this.setAutoStickyOffsetPixels();
            }
        }
    },
    mounted() {
        if (this.isStickyLeft && !this.stickyOffsetPixels) {
            this.setAutoStickyOffsetPixels();
        }
    },
    methods: {
        setAutoStickyOffsetPixels() {
            this.autoStickyOffsetPixels = this.$el.offsetLeft;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.table-header-column {
    background: $grey1;
    font-weight: bold;
    position: relative;
    text-align: left;
    vertical-align: top;
    white-space: nowrap;
    width: auto;

    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: 1px solid $grey3;
    }

    &--group-header {
        font-weight: normal;

        &:after {
            border-bottom: none;
        }
    }

    &--sticky-top {
        position: sticky;
        top: 0;
        z-index: 3;
    }

    &--sticky-left {
        position: sticky;
        left: 0;
        z-index: 5;

        &:after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            height: 100%;
            border-right: 1px solid $grey3;
        }
    }

    &--sticky-right {
        position: sticky;
        right: 0;
        z-index: 5;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            height: 100%;
            border-left: 1px solid $grey3;
            z-index: -1;
        }
    }

    &--strong-border-left {
        z-index: 4;

        &:after {
            content: "";
            position: absolute;
            left: -1px;
            bottom: 0;
            height: 100%;
            border-left: 3px double $grey3;
        }
    }

    &--sub-header {
        background: $white;
        font-weight: normal;
    }
}

.table-header-column__content {
    align-items: center;
    display: flex;
    max-width: 100%;
    overflow: hidden;
    padding: $spacing-small $spacing-semi-small;
    text-overflow: ellipsis;
}
</style>
