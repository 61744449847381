const OverwriteScope = {
    Campaign: "CAMPAIGN",
    Template: "MASTER_TEMPLATE",
    EditableGroup: "EDITABLE_GROUP",
    EditableGroupTemplate: "EDITABLE_GROUP_AND_MASTER_TEMPLATE"
};

const OverwriteScopeOrder = {
    [OverwriteScope.Campaign]: 1,
    [OverwriteScope.Template]: 2,
    [OverwriteScope.EditableGroup]: 3,
    [OverwriteScope.EditableGroupTemplate]: 4
};

const EditableGroupScopeLabel = "";
const ExportGroupScopeLabel = "All Sizes";

const TemplateOverwriteScopes = [OverwriteScope.Template, OverwriteScope.EditableGroupTemplate];

export { ExportGroupScopeLabel, EditableGroupScopeLabel, OverwriteScope, OverwriteScopeOrder, TemplateOverwriteScopes };
