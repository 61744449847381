<template>
    <div
        class="editable-image-thumbnail"
        :class="{ 'editable-image-thumbnail--locked': !canEdit, 'editable-image-thumbnail--fallback': fallback }"
    >
        <deliverable-label
            v-if="label"
            class="editable-image-thumbnail__label"
            :color="labelColor"
            :label="label"
        ></deliverable-label>
        <div
            v-if="computedValue || isFolder"
            class="editable-image-thumbnail__img editable-image-thumbnail__thumbnail"
            :style="imageTagStyle"
            @click="onClick('')"
        ></div>
        <div v-else class="editable-image-thumbnail__icon editable-image-thumbnail__thumbnail" @click="onClick('')">
            <Icon type="md-image"></Icon>
        </div>
        <div class="editable-image-thumbnail__footer">
            <div class="editable-image-thumbnail__footer--name" :title="fileName">
                {{ fileName }}
            </div>
            <div class="editable-image-thumbnail__footer--icon">
                <Icon type="md-settings" @click="openEditableSettings" />
                <Icon v-if="!isDefault && isImage" type="md-crop" @click="openResizeSettings" />
                <Icon type="ios-search" @click="onClick('icon')" />
            </div>
        </div>
    </div>
</template>
<script>
import DeliverableLabel from "@/components/Campaign/DeliverableLabel";
import { EditableImageTypes, EditableType } from "@/enums/editables";

export default {
    name: "EditableImageThumbnail",
    components: { DeliverableLabel },
    props: {
        value: {
            type: String,
            default: ""
        },

        mediaItem: {
            type: Object,
            default: null
        },

        label: {
            type: String
        },

        labelColor: {
            type: String,
            default: "default"
        },

        canEdit: {
            type: Boolean,
            default: true
        },

        filetype: {
            type: String,
            default: EditableType.Image
        }
    },
    data() {
        return {
            computedValue: "",
            fallback: false
        };
    },

    computed: {
        fileName() {
            // todo update default value
            return this.mediaItem && this.mediaItem.name ? this.mediaItem.name : "Default";
        },

        imageTagStyle() {
            return {
                backgroundImage: `url(${this.computedValue})`
            };
        },

        isDefault() {
            return !this.mediaItem;
        },

        isFolder() {
            return this.filetype === EditableType.Folder;
        },

        isImage() {
            return EditableImageTypes.includes(this.filetype);
        }
    },

    watch: {
        value: {
            immediate: true,
            handler() {
                this.computeValue();
            }
        }
    },

    methods: {
        computeValue() {
            this.computedValue = this.value;
            if (this.computedValue) {
                // Fallback to icon if folder preview is not found
                if (this.filetype === EditableType.Folder) {
                    this.folderPreviewFallback();
                } else {
                    this.imagePreviewFallback();
                }
            }
        },
        folderPreviewFallback() {
            const image = new Image();
            image.onerror = () => {
                this.computedValue = "/img/open-folder-outline.svg";
                this.fallback = true;
            };
            image.src = this.computedValue;
        },
        imagePreviewFallback() {
            const image = new Image();
            image.onerror = () => {
                this.computedValue = null;
                this.fallback = true;
            };
            image.src = this.computedValue;
        },

        onClick(target) {
            this.$emit(target ? `${target}-click` : "click");
        },

        openEditableSettings() {
            this.$emit("openEditableSettings");
        },

        openResizeSettings() {
            this.$emit("openResizeSettings");
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_mixins.scss";
@import "../../../sass/variables";

$preview-width: 100px;

.editable-image-thumbnail {
    @include make-checked-background(#808080, 5px);
    display: flex;
    flex: 0 0 $preview-width;
    width: $preview-width;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid $cmp-dark-bg-color;
    border-radius: 0 0 2px 2px;
    position: relative;

    &__label {
        position: absolute;
        top: 5px;
        left: 0;
        z-index: 2;
    }

    &__img {
        width: 100%;
        height: 82px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &__thumbnail {
        cursor: pointer;
    }

    &__icon .ivu-icon {
        font-size: 84px;
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 31px;
        line-height: 31px;
        overflow: hidden;
        padding: 0 5px;
        background-color: $cmp-dark-secondary-sidebar-color;

        &--icon {
            display: none;
            cursor: pointer;
        }

        &--name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &:hover {
        .editable-image-thumbnail__footer--icon {
            display: flex;
        }
    }

    &--locked:hover {
        .editable-image-thumbnail__footer--icon {
            display: none;
        }

        .editable-image-thumbnail__thumbnail {
            cursor: default;
        }
    }

    &--fallback {
        .editable-image-thumbnail__thumbnail {
            background-position-y: bottom;
            background-size: 55%;
        }
    }
}
</style>
