<template>
    <div id="app" :class="themeClassName">
        <hox-loading-bar />
        <Layout class="campaign-layout">
            <campaign-header :is-authorized-content-visible="isAuthorizedContentVisible" :has-campaign="false" />
            <Sider
                :collapsible="true"
                :value="!isSidebarOpen"
                :collapsed-width="sidebarWidthCollapsed"
                :width="sidebarWidthOpen"
                :hide-trigger="true"
            >
                <portal-target name="main-sidebar" slim></portal-target>
            </Sider>
            <Layout class="campaign-content">
                <Content class="main-content">
                    <router-view :key="$route.fullPath" class="hox-full-height hox-full-width router-view" />
                </Content>
            </Layout>
        </Layout>
    </div>
</template>

<script>
import HoxLoadingBar from "@/HoxLoadingBar";
import CampaignHeader from "@/CampaignHeader";
import themeMixin from "@/mixins/themeMixin";

export default {
    name: "ThemeTemplatesLight",
    components: {
        CampaignHeader,
        HoxLoadingBar
    },
    mixins: [themeMixin],

    data() {
        return {
            sidebarWidth: 300
        };
    },

    computed: {
        themeClassName() {
            return `theme theme-${this.theme} theme-campaign-light`;
        }
    }
};
</script>

<style lang="scss">
@import "../sass/variables";

.theme-campaign-light {
    .campaign {
        &-header {
            background-color: $cmp-light-bg-color;
            border-bottom: 1px solid $cmp-light-border-color;

            &__breadcrumb {
                span.inactive-link {
                    color: $cmp-light-secondary-font-color;
                }

                a {
                    color: $cmp-light-secondary-font-color;

                    &:hover {
                        color: $cmp-light-secondary-font-color;
                    }
                }
            }
        }

        &-banner {
            border: 1px solid $cmp-light-border-color;
            box-sizing: content-box;

            &-footer {
                color: $cmp-light-font-color;
            }
        }
    }

    .ivu-layout-sider {
        background-color: $white;

        .sidebar-body {
            width: $campaign-library-pane-width;
            top: 0;
            position: fixed !important;
            overflow: auto !important;
            height: 100vh;
        }
    }

    .main-content {
        background-color: $cmp-light-bg-color;
    }
}
</style>
