import { v4 as uuidv4 } from "uuid";

const ImagineModel = {
    Dalle2: "dalle2",
    GPT3: "GPT3",
    GPT3CTA: "GPT3CTA",
    ChatGPT: "ChatGPT",
    GPTDimensions: "GPTDimensions",
    MaterialStableDiffusion: "tommoore515/material_stable_diffusion",
    StableDiffusion: "stability-ai/stable-diffusion",
    StableDiffusionImage2Image: "stability-ai/stable-diffusion-img2img",
    PlayHT: "PlayHT",
    Sdxl: "stability-ai/sdxl"
};

const ImagineModelToLabel = {
    [ImagineModel.StableDiffusion]: "Stable Diffusion",
    [ImagineModel.MaterialStableDiffusion]: "3D Material",
    [ImagineModel.PlayHT]: "Text to voice",
    [ImagineModel.Dalle2]: "DALL·E 2",
    [ImagineModel.GPT3]: "AI Creative Brief",
    [ImagineModel.ChatGPT]: "ChatGPT",
    [ImagineModel.GPTDimensions]: "GPT Matrix dimensions",
    [ImagineModel.Sdxl]: "SDXL"
};

const GPT3FieldsSequence = ["Headline", "CTA", "Image"];

const StylesByGroup = {
    Color: [
        { name: "red", thumbnail: "/imagine-thumbs/color-red.png" },
        { name: "yellow", thumbnail: "/imagine-thumbs/color-yellow.png" },
        { name: "blue", thumbnail: "/imagine-thumbs/color-blue.png" },
        { name: "black and white", thumbnail: "/imagine-thumbs/color-bw.png" },
        { name: "psychedelic", thumbnail: "/imagine-thumbs/color-psychedelic.png" }
    ],
    Style: [
        { name: "drawing", thumbnail: "/imagine-thumbs/style-drawing.png" },
        { name: "photo-realistic", thumbnail: "/imagine-thumbs/style-photo.png" },
        { name: "3d render", thumbnail: "/imagine-thumbs/style-3d.png" },
        { name: "bokeh", thumbnail: "/imagine-thumbs/style-bokeh.png" }
    ],
    // Texture: ["Zebra", "Chessboard", "Tongue", "Leopard"],
    Environment: [
        { name: "rural", thumbnail: "/imagine-thumbs/env-country.png" },
        { name: "city", thumbnail: "/imagine-thumbs/env-city.png" }
    ],
    Artists: [
        { name: "hogarth", thumbnail: "/imagine-thumbs/artist-hogarth.png" },
        { name: "picasso", thumbnail: "/imagine-thumbs/artist-picasso.png" },
        { name: "dali", thumbnail: "/imagine-thumbs/artist-dali.png" },
        { name: "matisse", thumbnail: "/imagine-thumbs/artist-matisse.png" }
    ]
};

const ModelParams = {
    [ImagineModel.PlayHT]: {
        voice: {
            options: [
                { value: "random", label: "Random" },
                { value: "Emilia", label: "Emilia - English (UK)" },
                { value: "en-GB-Standard-B", label: "Jack - English (UK)" },
                { value: "en-GB-Standard-C", label: "Mia - English (UK)" },
                { value: "en-GB-Wavenet-A", label: "Isla - English (UK)" },
                { value: "en-GB-Wavenet-B", label: "Harry - English (UK)" },
                { value: "en-GB_KateV3Voice", label: "Lily - English (UK)" },
                { value: "en-GB_JamesV3Voice", label: "James - English (UK)" },
                { value: "en-GB-LibbyNeural", label: "Libby - English (UK)" },
                { value: "Noah", label: "Noah - English (US)" },
                { value: "en-US-DavisNeural", label: "Davis - English (US)" },
                { value: "Scarlett", label: "Scarlett - English (US)" },
                { value: "Jessica", label: "Jessica - English (US)" },
                { value: "en-US-Wavenet-B", label: "Robert - English (US)" },
                { value: "en-US-Wavenet-C", label: "Patricia - English (US)" },
                { value: "en-US_AllisonV3Voice", label: "Grace - English (US)" },
                { value: "en-US_HenryV3Voice", label: "Henry - English (US)" },
                { value: "en-US-AriaNeural", label: "Aria - English (US)" },
                { value: "en-US-GuyNeural", label: "Guy - English (US)" },
                { value: "en-US-JennyNeural", label: "Jenny - English (US)" },
                { value: "en-US-AmberNeural", label: "Amber - English (US)" },
                { value: "en-US-AshleyNeural", label: "Ashley - English (US)" },
                { value: "en-US-CoraNeural", label: "Cora - English (US)" },
                { value: "en-US-ElizabethNeural", label: "Beth - English (US)" },
                { value: "en-US-MichelleNeural", label: "Michelle - English (US)" },
                { value: "en-US-MonicaNeural", label: "Monica - English (US)" },
                { value: "Geraint", label: "Geraint - English (Welsh)" }
            ]
        },
        narrationStyles: {
            Emilia: ["news"],
            Noah: ["news"],
            Jessica: ["news"],
            "en-US-AriaNeural": [
                "newscast-formal",
                "newscast-casual",
                "customerservice",
                "chat",
                "cheerful",
                "empathetic"
            ],
            "en-US-GuyNeural": ["newscast"],
            "en-US-JennyNeural": ["newscast", "customerservice", "chat", "assistant"],
            "en-US-DavisNeural": [
                "angry",
                "chat",
                "cheerful",
                "excited",
                "friendly",
                "hopeful",
                "sad",
                "shouting",
                "terrified",
                "unfriendly",
                "whispering"
            ]
        }
    }
};

const getPlayHTRandomVoice = () => {
    return ModelParams[ImagineModel.PlayHT].voice.options[
        Math.floor(Math.random() * (ModelParams[ImagineModel.PlayHT].voice.options.length - 2)) + 1
    ].value;
};

const ModelDefaultValues = {
    [ImagineModel.StableDiffusion]: {
        prompt: "",
        num_outputs: 1,
        width: 768,
        height: 768,
        renderModel: ImagineModel.StableDiffusion
    },
    [ImagineModel.MaterialStableDiffusion]: {
        prompt: "",
        num_outputs: 1,
        width: 768,
        height: 768
    },
    [ImagineModel.PlayHT]: {
        text: "",
        voice: "random"
    },
    [ImagineModel.Dalle2]: {
        prompt: "",
        size: "1024x1024",
        num_images: 1
    },
    [ImagineModel.GPT3]: {
        model: "text-davinci-003",
        prompt: "",
        temperature: 0.7,
        max_tokens: 256,
        top_p: 1,
        frequency_penalty: 0.2,
        presence_penalty: 0.2
    },
    [ImagineModel.GPT3CTA]: {
        model: "gpt-4",
        messages: [
            {
                role: "system",
                content: ""
            }
        ],
        temperature: 0.7,
        max_tokens: 256,
        top_p: 1,
        frequency_penalty: 0.2,
        presence_penalty: 0.2
    },
    [ImagineModel.ChatGPT]: {
        model: "text-davinci-003",
        prompt: "",
        temperature: 0.7,
        max_tokens: 256,
        top_p: 1,
        frequency_penalty: 0.2,
        presence_penalty: 0.2
    },
    [ImagineModel.GPTDimensions]: {
        model: "text-davinci-003",
        prompt: "",
        temperature: 0.7,
        max_tokens: 256,
        top_p: 1,
        frequency_penalty: 0.2,
        presence_penalty: 0.2
    },
    [ImagineModel.Sdxl]: {
        prompt: "",
        num_outputs: 1,
        width: 1024,
        height: 1024,
        model: ImagineModel.Sdxl
    }
};

const ModelOutput = {
    [ImagineModel.StableDiffusion]: "images",
    [ImagineModel.MaterialStableDiffusion]: "images",
    [ImagineModel.Dalle2]: "images",
    [ImagineModel.PlayHT]: "audio",
    [ImagineModel.GPT3]: "mixed",
    [ImagineModel.GPT3CTA]: "mixed",
    [ImagineModel.ChatGPT]: "mixed",
    [ImagineModel.Sdxl]: "images"
};

const ResourceTypeByModel = {
    [ImagineModel.StableDiffusion]: "COG",
    [ImagineModel.MaterialStableDiffusion]: "COG",
    [ImagineModel.Dalle2]: "DALLE2",
    [ImagineModel.PlayHT]: "PLAYHT",
    [ImagineModel.GPT3]: "GPT3_COMPLETION",
    [ImagineModel.GPT3CTA]: "OPENAI_AZURE_GPT_CHAT_COMPLETION",
    [ImagineModel.ChatGPT]: "GPT3_COMPLETION",
    [ImagineModel.GPTDimensions]: "GPT3_COMPLETION",
    [ImagineModel.Sdxl]: "COG"
};

// eslint-disable-next-line complexity
const promptToStyledPrompt = searchOptions => {
    if (!searchOptions.style) {
        return searchOptions.prompt;
    }

    let color = "";
    let style = "";
    let artist = "";
    let environment = "";

    if (searchOptions.style.Color && searchOptions.style.Color.length) {
        color = `${searchOptions.style.Color[0]} `;
    }

    if (searchOptions.style.Style && searchOptions.style.Style.length) {
        style = `${searchOptions.style.Style[0]} of `;
    }

    if (searchOptions.style.Environment && searchOptions.style.Environment.length) {
        environment = ` in ${searchOptions.style.Environment[0]} environment`;
    }

    if (searchOptions.style.Artists && searchOptions.style.Artists.length) {
        artist = ` by ${searchOptions.style.Artists[0]}`;
    }

    return `${color}${style}${searchOptions.prompt}${artist}${environment}`;
};

const getGPT3Prompt = searchOptions => {
    const template = `You are a marketing specialist who performs deep marketing research and writes award winning ideas. Consider this sentence:
    ${searchOptions.prompt || "$input1$"}
    ${searchOptions.row || "$input2$"}
    ${searchOptions.column || "$input3$"}
What is the product? Who is the audience? Where is the moment happening?

Pick your most unique and clever idea that would be 'award winning' from a marketing perspective.

Generate a HEADLINE, CALL TO ACTION, and IMAGE SUGGESTION for this idea.

A HEADLINE is a clever, witty headline that communicates the idea but avoids cliche marketing language
A SUGGESTED IMAGE is a highly detailed three sentence that describes an image and the lighting in a lot of detail.
A CALL TO ACTION is a short, three word sentence that excites the audience to take action.

Output in the following JavaScript object format
{
    "headline": "",
    "callToAction": "",
    "suggestedImage": ""
}`;

    return template;
};

const getGPT3CTA = searchOptions => {
    const template = `Consider this sentence:
        ${searchOptions.prompt || "$input1$"}
        Detect the language of the text and return the results ONLY in that language.
        Pick your most unique and clever idea that would be 'award winning' from a marketing perspective.
        
        A CALL TO ACTION is a short, three to five word sentence that excites the audience to take action.
        Generate four distinct CALL TO ACTIONS for this idea.
        
        Return ONLY the javascript object as a response with the callToAction property and nothing else in the EXACT following JavaScript object format
        {"callToAction": "", "callToAction": "", "callToAction": "", "callToAction": ""}`;
    return template;
};

const getGPTDimensionsPrompt = searchOptions => {
    const template = `Create a content strategy for a product.
    Output into two lists so a content matrix can be generated.
    The product is: ${searchOptions.prompt || "$input1$"}

    Return the output in the following javascript object format. 

    Each array should have 10 items.  
    List 1 should be audience segments that would purchase the product. 
    List 2 should be an occasion or moment where the product will be used by the audience.

    {
       "list1": [],   
       "list2": []
   }`;

    return template;
};

// eslint-disable-next-line camelcase
const sanitizeDalleParams = ({ resultsNumber, init_image, cachedImage, renderModel, column, row, ...rest }) => rest;
export const sanitizeGPTParams = ({ text, renderModel, ...rest }) => rest;

const numberOfResults = +localStorage.getItem("imagineNumberOfResults");

const ModelConfig = {
    [ImagineModel.StableDiffusion]: {
        resourceType: ResourceTypeByModel[ImagineModel.StableDiffusion],
        modelOutput: "images",
        fieldsToRequest: (fields, refreshCounter = 0) => {
            // because stable diffusion stopped supporting base image we choose another model that supports it
            let version = null;
            if (fields.init_image) {
                version = "27b93a2413e7f36cd83da926f3656280b2931564ff050bf9575f1fdf9bcd7478";
            }

            return {
                type: ResourceTypeByModel[ImagineModel.StableDiffusion],
                input: {
                    model: ImagineModel.StableDiffusion,
                    ...(version && { version }),
                    predict: {
                        ...ModelDefaultValues[ImagineModel.StableDiffusion],
                        ...fields,
                        prompt: promptToStyledPrompt(fields),
                        uuid: `${uuidv4()}-${refreshCounter}`
                    }
                }
            };
        },
        fields: [],
        numberOfResults: numberOfResults || 8,
        numberOfReRolls: 4
    },
    [ImagineModel.MaterialStableDiffusion]: {
        resourceType: ResourceTypeByModel[ImagineModel.MaterialStableDiffusion],
        modelOutput: "images",
        fieldsToRequest: (fields, refreshCounter = 0) => {
            return {
                type: ResourceTypeByModel[ImagineModel.MaterialStableDiffusion],
                input: {
                    ...ModelDefaultValues[ImagineModel.MaterialStableDiffusion],
                    ...fields,
                    prompt: promptToStyledPrompt(fields),
                    uuid: `${uuidv4()}-${refreshCounter}`
                }
            };
        },
        fields: [],
        numberOfResults: numberOfResults || 8,
        numberOfReRolls: 4
    },
    [ImagineModel.Dalle2]: {
        resourceType: ResourceTypeByModel[ImagineModel.Dalle2],
        modelOutput: "images",
        fieldsToRequest: (fields, refreshCounter = 0) => {
            const sanitizedFields = sanitizeDalleParams(fields);
            return {
                type: ResourceTypeByModel[ImagineModel.Dalle2],
                input: {
                    ...ModelDefaultValues[ImagineModel.Dalle2],
                    ...sanitizedFields,
                    prompt: promptToStyledPrompt(fields),
                    uuid: `${uuidv4()}-${refreshCounter}`
                }
            };
        },
        fields: [],
        numberOfResults: numberOfResults || 8,
        numberOfReRolls: 4
    },
    [ImagineModel.Sdxl]: {
        resourceType: ResourceTypeByModel[ImagineModel.Sdxl],
        modelOutput: "images",
        fieldsToRequest: (fields, refreshCounter = 0) => {
            // because stable diffusion stopped supporting base image we choose another model that supports it
            let version = null;
            if (fields.init_image) {
                version = "27b93a2413e7f36cd83da926f3656280b2931564ff050bf9575f1fdf9bcd7478";
            }

            return {
                type: ResourceTypeByModel[ImagineModel.Sdxl],
                input: {
                    model: ImagineModel.Sdxl,
                    ...(version && { version }),
                    predict: {
                        ...ModelDefaultValues[ImagineModel.Sdxl],
                        ...fields,
                        prompt: promptToStyledPrompt(fields),
                        uuid: `${uuidv4()}-${refreshCounter}`
                    }
                }
            };
        },
        fields: [],
        numberOfResults: numberOfResults || 8,
        numberOfReRolls: 4
    },
    [ImagineModel.PlayHT]: {
        resourceType: ResourceTypeByModel[ImagineModel.PlayHT],
        modelOutput: "audio",

        fieldsToRequest: (fields, refreshCounter = 0) => {
            return {
                type: ResourceTypeByModel[ImagineModel.PlayHT],
                input: {
                    ...ModelDefaultValues[ImagineModel.PlayHT],
                    ...fields,
                    uuid: `${uuidv4()}-${refreshCounter}`
                }
            };
        },
        fields: [],
        numberOfResults: numberOfResults || 8,
        numberOfReRolls: 4
    },
    [ImagineModel.GPT3]: {
        resourceType: ResourceTypeByModel[ImagineModel.GPT3],
        modelOutput: "mixed",
        fieldsToRequest: (fields, refreshCounter = 0) => {
            const sanitizedFields = sanitizeGPTParams(fields);

            return {
                type: ResourceTypeByModel[ImagineModel.GPT3],
                input: {
                    ...ModelDefaultValues[ImagineModel.GPT3],
                    prompt: getGPT3Prompt(sanitizedFields),
                    uuid: `${uuidv4()}-${refreshCounter}`
                }
            };
        },
        fields: [],
        numberOfResults: numberOfResults || 8,
        numberOfReRolls: 4
    },
    [ImagineModel.GPT3CTA]: {
        resourceType: ResourceTypeByModel[ImagineModel.GPT3CTA],
        modelOutput: "mixed",
        fieldsToRequest: (fields, refreshCounter = 0) => {
            const sanitizedFields = sanitizeGPTParams(fields);

            return {
                type: ResourceTypeByModel[ImagineModel.GPT3CTA],
                input: {
                    ...ModelDefaultValues[ImagineModel.GPT3CTA],
                    messages: [
                        {
                            role: "system",
                            content: getGPT3CTA(sanitizedFields)
                        }
                    ],
                    uuid: `${uuidv4()}-${refreshCounter}`
                }
            };
        },
        fields: [],
        numberOfResults: numberOfResults || 1,
        numberOfReRolls: 1
    },
    [ImagineModel.ChatGPT]: {
        resourceType: ResourceTypeByModel[ImagineModel.ChatGPT],
        modelOutput: "mixed",
        fieldsToRequest: (fields, refreshCounter = 0) => {
            const sanitizedFields = sanitizeGPTParams(fields);

            return {
                type: ResourceTypeByModel[ImagineModel.ChatGPT],
                input: {
                    ...ModelDefaultValues[ImagineModel.ChatGPT],
                    prompt: sanitizedFields.prompt,
                    uuid: `${uuidv4()}-${refreshCounter}`
                }
            };
        },
        fields: [],
        numberOfResults: numberOfResults || 8,
        numberOfReRolls: 4
    },
    [ImagineModel.GPTDimensions]: {
        resourceType: ResourceTypeByModel[ImagineModel.GPT3],
        modelOutput: "mixed",
        fieldsToRequest: (fields, refreshCounter = 0) => {
            const sanitizedFields = sanitizeGPTParams(fields);

            return {
                type: ResourceTypeByModel[ImagineModel.GPTDimensions],
                input: {
                    ...ModelDefaultValues[ImagineModel.GPTDimensions],
                    prompt: getGPTDimensionsPrompt(sanitizedFields),
                    uuid: `${uuidv4()}-${refreshCounter}`
                }
            };
        },
        fields: [],
        numberOfResults: numberOfResults || 8,
        numberOfReRolls: 4
    }
};

export {
    ImagineModelToLabel,
    ImagineModel,
    GPT3FieldsSequence,
    ModelParams,
    ModelDefaultValues,
    ModelConfig,
    StylesByGroup,
    ModelOutput,
    ResourceTypeByModel,
    promptToStyledPrompt,
    getPlayHTRandomVoice
};
