<template>
    <hox-modal data-testid="manage-team__modal" @close="close">
        <hox-loading-layer v-if="showLoadingLayer" />
        <template #header>
            {{ headerText }}
            <span class="subtitle"></span>
        </template>
        <div v-if="isLoadingUsersByScope && !hasLoadedUsersByScope" class="team__loading-container">
            <hox-loading-layer :is-full-screen="false" />
        </div>
        <div v-else-if="hasErrorLoadingUsersByScope">
            <hox-alert margin-top="base" type="danger">
                <template #title>Uh oh!</template>
                <template #content>
                    <p>Something went wrong while getting some data. Try again in a few moments.</p>
                </template>
                <template #actionItems>
                    <Button type="primary" @click="refetchUsers">Retry</Button>
                </template>
            </hox-alert>
        </div>
        <template v-else>
            <form class="team__invite-form" @submit.prevent="onInvite">
                <p>Invite user to team by email</p>
                <div class="team_invite-input-wrapper">
                    <div class="team_invite-input">
                        <hox-input
                            v-model="teamInvitee"
                            placeholder="E.g name@example.com"
                            data-testid="invite-user__input"
                            :show-error="inviteUserValidationErrors.length > 0 && showInviteUserValidationErrors"
                        >
                            <template #error>
                                <p v-for="error in inviteUserValidationErrors" :key="error">
                                    {{ error }}
                                </p>
                            </template>
                        </hox-input>
                    </div>
                    <Button
                        :disabled="inviteUserValidationErrors.length > 0 && showInviteUserValidationErrors"
                        type="primary"
                        data-testid="invite-user__button"
                        @click="onInvite"
                    >
                        Invite
                    </Button>
                </div>
                <Alert v-if="hasErrorInvitingUser" banner type="error">
                    <template #desc>
                        Something went wrong while inviting the user. Please try again in a few moments. If the issue
                        persists then try reloading the page.
                    </template>
                </Alert>
            </form>
            <team-member
                v-for="user in users"
                :key="user.id"
                :member="user"
                :entity-type="entityType"
                @remove="onRemove(user.id)"
            />
            <hox-alert v-if="users.length === 0" margin-top="base" type="info">
                <template #title>There are no {{ entityType }} team members</template>
                <template #content>
                    <p>At the moment only Super Admins can access this {{ entityType }}.</p>
                    <p>You can give other users access to this {{ entityType }} by using the form above.</p>
                </template>
            </hox-alert>
        </template>
    </hox-modal>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import validateEmail from "shared-utils/validateEmail";
import UsersByScopeQuery from "@/apollo/queries/UsersByScope.gql";
import AddUserScopes from "@/apollo/mutations/AddUserScopes.gql";
import RemoveUserScopes from "@/apollo/mutations/RemoveUserScopes.gql";
import HoxModal from "@/components/Modal/Modal/Modal";
import TeamMember from "@/components/TeamMember";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Team",

    components: {
        TeamMember,
        HoxModal
    },

    props: {
        campaignId: {
            type: String
        },

        clientId: {
            required: true,
            type: String
        },

        entityType: {
            validator(value) {
                return ["campaign", "client"].includes(value);
            }
        }
    },

    data() {
        return {
            users: [],
            teamInvitee: "",
            hasErrorInvitingUser: false,
            hasErrorLoadingUsersByScope: false,
            hasLoadedUsersByScope: false,
            isLoadingUsersByScope: 0,
            isInvitingUser: false,
            isRemovingUser: false,
            showInviteUserValidationErrors: false
        };
    },

    computed: {
        headerText() {
            return this.entityType === "client" ? "Client Team" : "Campaign Team";
        },

        inviteUserValidationErrors() {
            const errors = [];
            if (this.teamInvitee.length === 0) {
                errors.push("An email address is required.");
            } else if (this.userExists(this.teamInvitee)) {
                errors.push(`${this.teamInvitee} is already a member of this team.`);
            } else if (this.teamInvitee.match(/@hogarthww.com$/)) {
                errors.push("Please use the @hogarth.com domain");
            } else {
                const isValidEmail = validateEmail(this.teamInvitee);
                if (!isValidEmail) {
                    errors.push(
                        `"${this.teamInvitee}" does not appear to be a valid email address.`,
                        "Hint: check for spaces and/or other special characters."
                    );
                }
            }
            return errors;
        },

        scope() {
            return {
                clientId: this.clientId,
                campaignId: this.entityType !== "client" ? this.campaignId : undefined
            };
        },

        showLoadingLayer() {
            return (
                (this.isInvitingUser || this.isLoadingUsersByScope || this.isRemovingUser) && this.hasLoadedUsersByScope
            );
        }
    },

    methods: {
        async onInvite() {
            if (!this.isInvitingUser) {
                /*
                    As it's possible for the user to change the teamInvitee value
                    we make a local copy to ensure that nothing unexpected happens.
                */
                const userToInvite = this.teamInvitee;
                this.hasErrorInvitingUser = false;
                if (this.inviteUserValidationErrors.length > 0) {
                    this.showInviteUserValidationErrors = true;
                    return;
                }
                this.isInvitingUser = true;
                try {
                    await this.$apollo.mutate({
                        mutation: AddUserScopes,
                        variables: {
                            id: userToInvite,
                            scopes: [this.scope]
                        }
                    });
                } catch (e) {
                    this.hasErrorInvitingUser = true;
                    return;
                } finally {
                    this.isInvitingUser = false;
                }
                this.$snackbar.success(`${userToInvite} has been invited to this ${this.entityType}`);
                this.refetchUsers();
                this.teamInvitee = "";
                this.showInviteUserValidationErrors = false;
            }
        },

        async onRemove(userId) {
            this.isRemovingUser = true;
            try {
                await this.$apollo.mutate({
                    mutation: RemoveUserScopes,
                    variables: {
                        id: userId,
                        scopes: [this.scope]
                    }
                });
                this.$snackbar.success(`${userId} has been removed from this ${this.entityType}`);
            } catch (e) {
                this.$snackbar.error(
                    `Could not remove ${userId} from this ${this.entityType}. Try again in a few moments.`
                );
                return;
            } finally {
                this.isRemovingUser = false;
            }
            this.refetchUsers();
        },

        refetchUsers() {
            this.$apollo.queries.usersByScope.refetch();
        },

        userExists(userId) {
            return this.users.some(m => m.id.toLowerCase() === userId.toLowerCase());
        },

        close() {
            if (this.teamInvitee !== "") {
                this.$Modal.confirm({
                    title: "Are you sure you want to close?",
                    content: `You haven't invited ${this.teamInvitee} yet!`,
                    okText: "Yes",
                    cancelText: "No",
                    onOk: () => {
                        this.$emit("close");
                    }
                });
            } else {
                this.$emit("close");
            }
        }
    },

    apollo: {
        usersByScope: {
            query: UsersByScopeQuery,
            fetchPolicy: "network-only",
            loadingKey: "isLoadingUsersByScope",
            variables() {
                return {
                    scope: this.scope
                };
            },

            result({ data, error }) {
                this.hasErrorLoadingUsersByScope = false;
                if (error) {
                    this.hasErrorLoadingUsersByScope = true;
                    return;
                }
                /*
                    `hasLoadedUsersByScope` is used to dictate which loading spinner we show: if it's
                    false then we show the loading spinner inside the modal; if it's true then we show
                    a loading spinner layer on top of the modal.
                */
                this.hasLoadedUsersByScope = true;
                /*
                    We compute `accessViaScope` because we want to handle users that have the client
                    scope differently to how we handle users who only have the campaigns scope when
                    listing a campaign team.
                */
                this.users = data.usersByScope.map(user => ({
                    ...user,
                    accessViaScope:
                        this.entityType !== "client" &&
                        user.scope.campaign &&
                        user.scope.campaign.includes(this.campaignId)
                            ? "campaign"
                            : "client"
                }));
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.team__invite-form {
    margin: 0 0 $spacing-large;
}

.team_invite-input-wrapper {
    display: flex;
    margin: $spacing-small 0 0;
}

.team_invite-input {
    flex: 1;
    margin: 0 $spacing-small 0 0;
}

.team__loading-container {
    position: relative;
    height: 300px;
}
</style>
