<template>
    <collapsible-widget class="editable-file-item" :is-opened="opened" collapsed-height="47" @input="toggleOpen">
        <template #name>{{ editable.label }}</template>
        <template #controls>
            <editable-restriction-control v-if="showRestrictionControl" :editable="editable" />
        </template>

        <div class="editable-widget__body">
            <div v-if="readOnly" class="editable-widget__body--readonly" title="Read Only" @click.stop.prevent></div>
            <editable-file-list-view
                :editable="editable"
                :groups-affecting-editable="groupsAffectingEditable"
                :is-opened="opened"
                @update="updateFileEditable"
            ></editable-file-list-view>
        </div>
    </collapsible-widget>
</template>

<script>
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";
import EditableFileListView from "@/components/Campaign/EditableFileListView";

import editableItemMixin from "@/mixins/editableItemMixin";
import editableMethodsMixin from "@/mixins/editableMethodsMixin";
import EditableRestrictionControl from "@/components/Campaign/EditableRestrictionControl";

export default {
    name: "EditableFileWidget",
    components: {
        EditableRestrictionControl,
        EditableFileListView,
        CollapsibleWidget
    },

    mixins: [editableItemMixin, editableMethodsMixin]
};
</script>

<style lang="scss">
@import "../../../../sass/variables";

.editable-file-item .widget-mode {
    color: $cmp-dark-disabled-color;

    &--active {
        color: $cmp-dark-font-color;
    }
}
</style>
