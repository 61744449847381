<template>
    <div class="editables-wrapper">
        <div
            v-if="scrollable"
            v-bar="{ el1ScrollVisibleClass: 'editable-widgets--with-scrollbar' }"
            class="editable-widgets"
        >
            <div class="editable-widgets__inner">
                <div class="editable-widgets__inner-content">
                    <template v-for="editable in editablesWithFilteredGroups">
                        <component
                            :is="getComponentName(editable)"
                            v-if="canSeeEditable(editable.restricted)"
                            :key="componentKey(editable._id)"
                            :is-opened="allExpanded"
                            :editable="editable"
                            :groups-affecting-editable="groupsAffectingEditable"
                            :read-only="isReadOnly(editable)"
                            :class="{ 'last-interaction': isLastInteracted(editable._id) }"
                            :show-restriction-control="!sandboxMode"
                            @click.native="onClick(editable._id)"
                            @remove="onRemove"
                            @update="onUpdate"
                        ></component>
                    </template>

                    <hox-alert
                        v-if="!editablesWithFilteredGroups.length"
                        class="editable-widgets__no-matches"
                        type="warning"
                        size="small"
                        align-text="center"
                        theme="dark"
                    >
                        <template #content>
                            <p>There are no fields matching the filter</p>
                        </template>
                    </hox-alert>
                </div>
            </div>
        </div>
        <div v-else class="editable-widgets">
            <div class="editable-widgets__inner">
                <div class="editable-widgets__inner-content">
                    <template v-for="editable in editablesWithFilteredGroups">
                        <component
                            :is="getComponentName(editable)"
                            v-if="canSeeEditable(editable.restricted)"
                            :key="componentKey(editable._id)"
                            :is-opened="allExpanded"
                            :editable="editable"
                            :groups-affecting-editable="groupsAffectingEditable"
                            :read-only="isReadOnly(editable)"
                            :class="{ 'last-interaction': isLastInteracted(editable._id) }"
                            :show-restriction-control="!sandboxMode"
                            @click.native="onClick(editable._id)"
                            @remove="onRemove"
                            @update="onUpdate"
                        ></component>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { EditorAction, EditorGetters } from "@/store/modules/editor";
import { EditableEvent, EditableType, EditableTypeWidgetOverrides } from "@/enums/editables";

import EditableTextWidget from "@/components/Campaign/widgets/EditableTextWidget";
import EditableImageWidget from "@/components/Campaign/widgets/EditableImageWidget";
import EditableFileWidget from "@/components/Campaign/widgets/EditableFileWidget";
import EditableArrayWidget from "@/components/Campaign/widgets/EditableArrayWidget";
import EditableBooleanWidget from "@/components/Campaign/widgets/EditableBooleanWidget";
import EditableFolderWidget from "@/components/Campaign/widgets/EditableFolderWidget";
import EditableUrlWidget from "@/components/Campaign/widgets/EditableUrlWidget";
import EditableVideoWidget from "@/components/Campaign/widgets/EditableVideoWidget";
import EditableCssWidget from "@/components/Campaign/widgets/EditableCssWidget";

import editableMethodsMixin from "@/mixins/editableMethodsMixin";
import editableUtils from "@/utils/editables";

export default {
    name: "EditablesList",
    components: {
        EditableVideoWidget,
        EditableFolderWidget,
        EditableBooleanWidget,
        EditableArrayWidget,
        EditableImageWidget,
        EditableTextWidget,
        EditableFileWidget,
        EditableUrlWidget,
        EditableCssWidget
    },

    mixins: [editableMethodsMixin],

    props: {
        allExpanded: {
            type: Boolean,
            default: true
        },
        nameFilter: {
            type: String,
            default: ""
        },
        sandboxMode: {
            type: Boolean,
            default: false
        },
        scrollable: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            lastInteracted: null
        };
    },

    computed: {
        availableFontNames() {
            if (!this.fontsByName) {
                return [];
            }

            return Object.keys(this.fontsByName);
        },

        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        editables() {
            return this.selectedEditables.filter(e => e.label.toLowerCase().includes(this.nameFilter.toLowerCase()));
        },

        editablesWithFilteredGroups() {
            const selectedGroup = this.$store.state.editor.editingGroupName;
            return this.editables.reduce((acc, editable) => {
                const defaultValues = editable.defaultValues.filter(({ editableGroups }) =>
                    editableGroups.includes(selectedGroup)
                );
                if (defaultValues.length) {
                    acc.push({
                        ...editable,
                        defaultValues
                    });
                }

                return acc;
            }, []);
        },

        editingGroupValueId() {
            return this.$store.getters[EditorGetters.editingGroupValue] &&
                this.$store.getters[EditorGetters.editingGroupValue].groupValueId
                ? this.$store.getters[EditorGetters.editingGroupValue].groupValueId
                : "";
        },

        fontsByName() {
            return this.$store.getters[EditorGetters.fontsByName];
        },

        groupsAffectingEditable() {
            return this.editablesWithFilteredGroups.length
                ? this.editablesWithFilteredGroups[0].defaultValues[0].editableGroups
                : [];
        },

        masterTemplates() {
            return this.$store.state.campaign.normalized.masterTemplates;
        },

        selectedEditables() {
            return this.$store.getters[EditorGetters.selectedEditables];
        }
    },

    watch: {
        editingGroupValueId() {
            this.$store.dispatch(EditorAction.SetEditedEditable, null);
        },

        selectedEditables() {
            this.$store.dispatch(EditorAction.SetEditedEditable, null);
        }
    },

    created() {
        if (this.editables.length) {
            this.lastInteracted = this.editables[0]._id;
        }
    },

    methods: {
        canSeeEditable(isRestricted) {
            return (
                !isRestricted ||
                this.$auth.userCan(this.$auth.Actions.CanManageRestrictedEditables, {
                    campaignId: this.campaignId,
                    clientId: this.clientId
                })
            );
        },

        detach() {
            this.$emit("detach");
        },

        editablesMissingFonts(editable) {
            return editableUtils.findMissingFonts(editable, this.availableFontNames);
        },

        getComponentName(editable) {
            const editableType = editable.type.toLowerCase();

            if (
                Object.values(EditableType)
                    .map(type => type.toLowerCase())
                    .includes(editableType)
            ) {
                return `editable-${
                    EditableTypeWidgetOverrides[editableType] ? EditableTypeWidgetOverrides[editableType] : editableType
                }-widget`;
            }

            return "editable-text-widget";
        },

        hasPsd(editable) {
            return editableUtils.hasPsdTemplate(editable, this.masterTemplates);
        },

        hasPsdWithMissingFont(editable) {
            return !!this.editablesMissingFonts(editable).length;
        },

        isLastInteracted(editableId) {
            return this.lastInteracted === editableId;
        },

        isReadOnly(editable) {
            return this.hasPsd(editable) && this.hasPsdWithMissingFont(editable);
        },

        onClick(editableId) {
            if (this.lastInteracted !== editableId) {
                this.lastInteracted = editableId;
            }
        },

        onRemove(overwriteToRemove) {
            this.$emit(EditableEvent.Remove, overwriteToRemove);
        },

        onUpdate(editableUpdate) {
            this.$emit(EditableEvent.Update, editableUpdate);
        },

        componentKey(editableId) {
            if (!this.editingGroupValueId) {
                return editableId;
            }

            return `${this.editingGroupValueId}-${editableId}`;
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.attached-panel {
    .editable-widgets {
        height: 100vh;
    }
}

.detached-panel {
    .editable-widgets {
        min-height: 80px;
    }

    &.editables-wrapper {
        height: 100%;
        overflow: hidden auto;
    }
}

.editable-widgets {
    height: 100%;
    /*overflow: initial !important; when present allows the content to overflow parent and reveals the native scrollbarq*/

    .expand-editables {
        font-size: 12px;
        position: absolute;
        top: -30px;
        left: 8px;
    }

    &__no-matches {
        margin: $spacing-large;
        color: $grey5;
    }

    .last-interaction.ivu-collapse > .ivu-collapse-item {
        &.ivu-collapse-item-active {
            .ivu-collapse-header {
                background: $cmp-dark-active;
            }
        }
    }

    &--with-scrollbar {
        .editable-widgets__inner-content {
            width: calc(100% - 15px);
            margin-bottom: 75px;
        }
    }

    .editable-widget__body {
        position: relative;

        &--readonly {
            background: $cmp-dark-bg-color;
            opacity: 0.6;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 5;
        }
    }
}
</style>
