<template>
    <div>
        <portal-target v-for="name of modalPortalNames" :key="name" :name="name" slim />
    </div>
</template>

<script>
import bus from "@/bus";

export default {
    data() {
        return {
            modalPortalNames: []
        };
    },
    beforeDestroy() {
        bus.$off("addModalPortal", this.addModalPortal);
        bus.$off("removeModalPortal", this.removeModalPortal);
    },
    created() {
        bus.$on("addModalPortal", this.addModalPortal);
        bus.$on("removeModalPortal", this.removeModalPortal);
    },
    methods: {
        addModalPortal(newModalPortalName) {
            this.modalPortalNames = [...this.modalPortalNames, newModalPortalName];
        },
        removeModalPortal(modalPortalName) {
            this.modalPortalNames = this.modalPortalNames.filter(name => name !== modalPortalName);
        }
    }
};
</script>

<style module lang="scss"></style>
