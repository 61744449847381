import Vue from "vue";
import { dedup, deepClone, formatEditableName, formatGroupName, generateTemplateLabel } from "@/utils";
import editableUtils from "@/utils/editables";
import { EditableScope } from "@/enums/editables";
import { OverwriteScope, OverwriteScopeOrder } from "@/enums/overwrites";
import { BannerDefaultDuration } from "@/enums/banners";

export const storeNamespace = "campaign";

export const CampaignAction = {
    AddEditableGroupValue: `${storeNamespace}/addEditableGroupValue`,
    DisassociateMasterTemplate: `${storeNamespace}/disassociateMasterTemplate`,
    RemoveEditableGroupValue: `${storeNamespace}/removeEditableGroupValue`,
    SetCampaign: `${storeNamespace}/setCampaign`,
    SetDcProfileId: `${storeNamespace}/setDcProfileId`,
    SetEditableOverwrites: `${storeNamespace}/setEditableOverwrites`,
    SetGroups: `${storeNamespace}/setGroups`,
    SetInitialState: `${storeNamespace}/setInitialState`,
    SetIsLocked: `${storeNamespace}/setIsLocked`,
    SetMasterTemplates: `${storeNamespace}/setMasterTemplates`,
    UpdateEditableGroupValue: `${storeNamespace}/updateEditableGroupValue`,
    SetEditableRestriction: `${storeNamespace}/setEditableRestriction`,
    SetCampaignUsers: `${storeNamespace}/setCampaignUsers`
};

export const CampaignGetters = {
    masterTemplateLabels: `${storeNamespace}/masterTemplateLabels`,
    editableOverwriteValue: `${storeNamespace}/editableOverwriteValue`,
    editableValue: `${storeNamespace}/editableValue`,
    groupByGroupValue: `${storeNamespace}/groupByGroupValue`,
    groupByGroupValueId: `${storeNamespace}/groupByGroupValueId`,
    groupValueNamesByGroup: `${storeNamespace}/groupValueNamesByGroup`,
    groupEditables: `${storeNamespace}/groupEditables`,
    masterTemplatesBySize: `${storeNamespace}/masterTemplatesBySize`,
    templateEditables: `${storeNamespace}/templateEditables`,
    templateById: `${storeNamespace}/templateById`
};

export const CampaignMutation = {
    AddEditableGroupValueToGroup: "addEditableGroupValueToGroup",
    ClearGroups: "clearGroups",
    RemoveEditableGroupValue: "removeEditableGroupValue",
    RemoveGroupValueFromEditableGroup: "removeGroupValueFromEditableGroup",
    RemoveMasterTemplate: "removeMasterTemplate",
    SetCampaign: "setCampaign",
    SetDcProfileId: "setDcProfileId",
    SetEditable: "setEditable",
    SetEditableGroup: "setEditableGroup",
    SetEditableGroupValue: "setEditableGroupValue",
    SetEditableOverwrite: "setEditableOverwrite",
    SetEditableOverwrites: "setEditableOverwrites",
    SetGroups: "setGroups",
    SetInitialState: "setInitialState",
    SetIsLocked: "setIsLocked",
    SetMasterTemplate: "setMasterTemplate",
    SetMasterTemplates: "setMasterTemplates",
    SetEditableRestriction: "setEditableRestriction",
    SetCampaignUsers: "setCampaignUsers",
    SortEditableGroupValuesInGroup: "sortEditableGroupValuesInGroup"
};

const getEditableLabel = formatEditableName;

const getGroupLabel = formatGroupName;

export const campaignDefaultLanguage = "en";

export const sortEditableGroupValueIdsByValue = (state, groupValuesIds) =>
    groupValuesIds.sort((a, b) => {
        const valueA = state.editableGroupValues[a].value.toUpperCase();
        const valueB = state.editableGroupValues[b].value.toUpperCase();

        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
    });

const sortByValue = (a, b) => {
    const valueA = a.value.toUpperCase();
    const valueB = b.value.toUpperCase();

    return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
};

export default {
    namespaced: true,
    state: {
        client: {},
        editableGroupValues: {
            /* [editableGroupValueId: string]: EditableGroupValue */
        },
        editableGroupValueIds: [
            /* string */
        ],
        editableOverwrites: [],
        groups: [],
        id: null,
        _id: null,
        isLocked: false,
        languages: [campaignDefaultLanguage],
        masterTemplates: [],
        mastheadImageUrl: null,
        mastheadUploadConfig: null,
        name: null,
        jiraTicketUrl: null,
        resourceGroupIds: null,
        dcProfileId: null,
        toneOfVoice: null,
        defaultTimelineLength: BannerDefaultDuration,
        groupFeedMappings: [],
        normalized: {
            editables: {
                /* [editableId: string]: Editables */
            },
            editableIds: [
                /* string */
            ],
            editableGroups: {
                /* [groupName: string]: EditableGroup */
            },
            editableGroupIds: [
                /* string */
            ],
            editableOverwrites: {
                /* [editableId: string]: {editableId: string, overwrites: [Overwrite]} */
            },
            masterTemplates: {
                /* [masterTemplateId: string]: MasterTemplate */
            },
            masterTemplateIds: [
                /* string */
            ],
            overwrites: [
                /* {editableId: string, overwrites: [Overwrite]} */
            ],
            users: {
                /* [userId: string]: User */
            },
            userIds: [
                /* string */
            ]
        }
    },

    getters: {
        editableOverwriteValue:
            state =>
            (
                editableId,
                masterTemplateId = undefined,
                language = campaignDefaultLanguage,
                groupValuesIds = [],
                editableOverwrites = state.editableOverwrites
            ) => {
                // 1. filter editables
                const filteredEditables = editableOverwrites.filter(
                    editableOverwrite => editableOverwrite.editable._id === editableId
                );

                if (!filteredEditables.length) {
                    return {
                        value: undefined,
                        mediaItem: null,
                        scope: null
                    };
                }

                // const permittedLanguages = [ "en" ].concat( language );
                // the line above will allow the copy for non english translation to be pre-populated with english values
                // until overwritten with proper translations - was meant to make translations easier
                const permittedLanguages = [campaignDefaultLanguage].concat(language);

                // 2. filter overrides inside editables
                const overwrites = filteredEditables.reduce((acc, editable) => {
                    const getGroups = override =>
                        override.editableGroupValueIds.every(groupValueId => groupValuesIds.includes(groupValueId));

                    const filteredOverwrites = editable.overwrites.filter(override => {
                        // 3. Filter the editables with the languages that we can use => ["en", selectedLanguage]
                        if (!permittedLanguages.includes(override.language)) {
                            return false;
                        }

                        // 4. filter out only the scopes that have all the required values. I.e. all editableGroupValueId has been selected
                        switch (override.scope) {
                            case OverwriteScope.Campaign:
                                return true;

                            case OverwriteScope.Template:
                                return override.masterTemplateId === masterTemplateId;

                            case OverwriteScope.EditableGroup:
                                return getGroups(override);

                            case OverwriteScope.EditableGroupTemplate:
                                return override.masterTemplateId === masterTemplateId && getGroups(override);

                            default:
                                return false;
                        }
                    });

                    if (filteredOverwrites.length) {
                        acc.push(...filteredOverwrites);
                    }

                    return acc;
                }, []);

                if (!overwrites.length) {
                    return {
                        value: undefined,
                        mediaItem: null,
                        _id: null,
                        scope: null
                    };
                }

                const getScopesByOrder = () => ({ ...OverwriteScopeOrder });
                const scopesByLanguage = { [language]: getScopesByOrder() };
                const getOverwriteValue = (computedOverwrite, overwrite) =>
                    computedOverwrite.value || computedOverwrite.value === ""
                        ? computedOverwrite.value
                        : overwrite.value;
                const assignValueFromScope = computedOverwrite =>
                    !computedOverwrite.valueFromScope &&
                    (computedOverwrite.value || computedOverwrite.value === "" || computedOverwrite.mediaItem);

                /*
                 * If the selected language is not the default lanuage then add it to be process as for a fallback / prepopulation
                 */
                if (language !== campaignDefaultLanguage) {
                    scopesByLanguage[campaignDefaultLanguage] = getScopesByOrder();
                }

                /*
                 * For each language and scope within the language find an overwrite
                 */
                Object.keys(scopesByLanguage).forEach(lang => {
                    Object.keys(scopesByLanguage[lang]).forEach(scope => {
                        scopesByLanguage[lang][scope] = overwrites
                            .filter(overwrite => overwrite.scope === scope && overwrite.language === lang)
                            .pop();
                    });
                });

                const assignOverwriteProps = (acc, overwrite) => {
                    acc.value = getOverwriteValue(acc, overwrite);
                    acc.mediaItem = acc.mediaItem || overwrite.mediaItem;
                    acc.settings = acc.settings || overwrite.settings;
                    acc._id = acc._id || overwrite._id;
                    acc.scope = acc.scope || overwrite.scope;
                    acc.modified = acc.modified || overwrite.modified;
                };

                /*
                 * Starting with the selected language (highest priority) and looping the scopes backwards so they are in priority order
                 * Set properties onto the computed overwrite, by the end each property set should come from the more prioritised overwrite for that property
                 */
                const computedOverwrite = Object.values(scopesByLanguage).reduce((acc, lang) => {
                    Object.values(lang)
                        .filter(Boolean)
                        .reverse()
                        .forEach(overwrite => {
                            assignOverwriteProps(acc, overwrite);

                            if (assignValueFromScope(acc)) {
                                acc.valueFromScope = overwrite.scope;
                            }

                            acc.settingsFromScope = acc.settingsFromScope || {};

                            if (!acc.settingsFromScope[overwrite.scope]) {
                                acc.settingsFromScope[overwrite.scope] = overwrite.settings || null;
                            }
                        });

                    return acc;
                }, {});

                return computedOverwrite;
            },

        editableValue:
            (state, getters) =>
            (
                editableId,
                // eslint-disable-next-line default-param-last
                masterTemplateId = undefined,
                language,
                groupValuesIds = [],
                editableOverwrites = state.editableOverwrites
            ) =>
                editableUtils.extractOverwriteValue(
                    getters.editableOverwriteValue(
                        editableId,
                        masterTemplateId,
                        language,
                        groupValuesIds,
                        editableOverwrites
                    )
                ) ||
                editableUtils.extractOverwriteValue(
                    getters.editableOverwriteValue(editableId, undefined, language, groupValuesIds, editableOverwrites)
                ),

        groupByGroupValue: state => groupValueId =>
            state.groups.find(group => group.editableGroupValues.map(egv => egv._id).includes(groupValueId)),

        groupByGroupValueId(state) {
            return Object.keys(state.normalized.editableGroups).reduce((acc, group) => {
                const { editableGroupValues } = state.normalized.editableGroups[group];
                editableGroupValues.forEach(groupValueId => {
                    acc[groupValueId] = group;
                });
                return acc;
            }, {});
        },

        /**
         * Get editables controlled by the groups
         * @deprecated since "other" fields had been transformed into group all editables are groupEditables
         * @param state
         * @returns {*}
         */
        groupEditables: state =>
            dedup(
                state.groups.map(group => group.editables).reduce((acc, val) => acc.concat(val), []),
                "name"
            ).map(grp => {
                const grpCopy = deepClone(grp);
                grpCopy.scope = EditableScope.Group;

                return grpCopy;
            }),

        groupValueNamesByGroup: state => {
            const populatedGroups = state.normalized.editableGroupIds.map(editableGroupId => {
                const editableGroupValuesNamesMap = {};
                const editableGroupValuesNames = [];
                state.normalized.editableGroups[editableGroupId].editableGroupValues.forEach(egvId => {
                    editableGroupValuesNamesMap[state.editableGroupValues[egvId].value] = egvId;
                    editableGroupValuesNames.push(state.editableGroupValues[egvId].value);
                });

                return {
                    ...state.normalized.editableGroups[editableGroupId],
                    editableGroupValuesNamesMap,
                    editableGroupValuesNames
                };
            });

            return populatedGroups.reduce((acc, curr) => Object.assign(acc, { [curr.name]: curr }), {});
        },

        masterTemplates: state => {
            return state.normalized.masterTemplates;
        },

        masterTemplateLabels: state =>
            state.normalized.masterTemplateIds.reduce((acc, masterTemplateId) => {
                const usedLabels = Object.values(acc);

                return Object.assign(acc, {
                    [masterTemplateId]: generateTemplateLabel(
                        state.normalized.masterTemplates[masterTemplateId],
                        usedLabels
                    )
                });
            }, {}),

        masterTemplatesBySize: state => {
            return state.masterTemplates.reduce((acc, masterTemplate) => {
                const size = `${masterTemplate.width}x${masterTemplate.height}`;
                if (!acc[size]) {
                    acc[size] = [];
                }

                acc[size].push(masterTemplate._id);

                return acc;
            }, {});
        },

        templateEditables: state => masterTemplateId => {
            if (!masterTemplateId) {
                return [];
            }

            return state.editableOverwrites.reduce((acc, { editable }) => {
                const defaultValues = editable.defaultValues.filter(dv => dv.masterTemplateId === masterTemplateId);
                if (defaultValues.length) {
                    acc.push({
                        ...editable,
                        defaultValues
                    });
                }
                return acc;
            }, []);
        },

        // todo: remove masterTemplate.id option when removing old code
        /**
         * @deprecated
         * @param state
         * @returns {function(*): *}
         */
        templateById: state => masterTemplateId =>
            state.masterTemplates.find(
                masterTemplate => masterTemplate.id === masterTemplateId || masterTemplate._id === masterTemplateId
            )
    },

    mutations: {
        addEditableGroupValueToGroup(state, payload) {
            const group = {
                ...state.normalized.editableGroups[payload.groupName],
                editableGroupValues: state.normalized.editableGroups[payload.groupName].editableGroupValues.concat(
                    payload.editableGroupValue._id
                )
            };

            Vue.set(state.normalized.editableGroups, group.name, group);
        },

        clearGroups(state) {
            state.groups = [];
            state.normalized.editableGroups = {};
            state.normalized.editableGroupIds = [];
            state.normalized.editables = {};
            state.normalized.editableIds = [];
        },

        removeEditableGroupValue(state, editableGroupValueId) {
            Vue.delete(state.editableGroupValueIds, state.editableGroupValueIds.indexOf(editableGroupValueId));
            Vue.delete(state.editableGroupValues, editableGroupValueId);
        },

        removeGroupValueFromEditableGroup(state, payload) {
            const group = {
                ...state.normalized.editableGroups[payload.groupName],
                editableGroupValues: state.normalized.editableGroups[payload.groupName].editableGroupValues.filter(
                    egvId => egvId !== payload.editableGroupValueId
                )
            };

            Vue.set(state.normalized.editableGroups, group.name, group);
        },

        removeMasterTemplate(state, masterTemplateId) {
            const index = state.normalized.masterTemplateIds.indexOf(masterTemplateId);

            Vue.delete(state.normalized.masterTemplateIds, index);
            Vue.delete(state.normalized.masterTemplates, masterTemplateId);
        },

        setCampaign(state, payload) {
            //  todo: extend to hold all the required values;
            const campaign = deepClone(payload);
            state.id = campaign.id;
            state._id = campaign._id;
            state.languages = campaign.languages.length ? campaign.languages : [campaignDefaultLanguage];
            state.mastheadImageUrl = campaign.mastheadImageUrl;
            state.mastheadUploadConfig = campaign.mastheadUploadConfig;
            state.name = campaign.name;
            state.jiraTicketUrl = campaign.jiraTicketUrl;
            state.resourceGroupIds = campaign.resourceGroupIds;
            state.client = campaign.client;
            state.dcProfileId = campaign.dcProfileId;
            state.isLocked = campaign.isLocked;
            state.toneOfVoice = campaign.toneOfVoice;
            state.defaultTimelineLength = campaign.defaultTimelineLength;
            state.groupFeedMappings = campaign.groupFeedMappings;
        },

        setCampaignUsers(state, users) {
            users.forEach(user => {
                Vue.set(state.normalized.users, user.id, user);

                if (!state.normalized.userIds.includes(user.id)) {
                    state.normalized.userIds.push(user.id);
                }
            });
        },

        setDcProfileId(state, dcProfileId) {
            state.dcProfileId = dcProfileId;
        },

        setEditable(state, editable) {
            Vue.set(state.normalized.editables, editable._id, editable);

            if (!state.normalized.editableIds.includes(editable._id)) {
                state.normalized.editableIds.push(editable._id);
            }
        },

        setEditableGroup(state, editableGroup) {
            const editableGroupValues = editableGroup.editableGroupValues
                .sort(sortByValue)
                .map(editableGroupValue => editableGroupValue._id);

            const group = {
                ...editableGroup,
                editableGroupValues,
                editables: editableGroup.editables.map(editable => editable._id)
            };

            Vue.set(state.normalized.editableGroups, group.name, group);

            if (!state.normalized.editableGroupIds.includes(group.name)) {
                state.normalized.editableGroupIds.push(group.name);
            }
        },

        setEditableGroupValue(state, editableGroupValue) {
            Vue.set(state.editableGroupValues, editableGroupValue._id, editableGroupValue);

            if (!state.editableGroupValueIds.includes(editableGroupValue._id)) {
                state.editableGroupValueIds.push(editableGroupValue._id);
            }
        },

        setEditableOverwrite(state, editableOverwrite) {
            const editableId = editableOverwrite.editable._id;

            if (!(editableId in state.normalized.editableOverwrites)) {
                Vue.set(state.normalized.editableOverwrites, editableId, {
                    editableId,
                    overwrites: []
                });
            }
            const overwrites = editableOverwrite.overwrites.map(overwrite => ({
                ...overwrite,
                editableId
            }));

            Vue.set(
                state.normalized.editableOverwrites[editableId],
                "overwrites",
                state.normalized.editableOverwrites[editableId].overwrites.concat(overwrites)
            );

            state.normalized.overwrites = state.normalized.overwrites.concat(overwrites);
        },

        setEditableOverwrites(state, editableOverwrites) {
            state.editableOverwrites = editableOverwrites.map(editableOverwrite => {
                const editOver = deepClone(editableOverwrite);
                editOver.editable.label = getEditableLabel(editOver.editable.name);

                return editOver;
            });
        },

        setEditableRestriction(state, { editableId, isRestricted }) {
            Vue.set(state.normalized.editables, editableId, {
                ...state.normalized.editables[editableId],
                restricted: isRestricted
            });
        },

        setGroups(state, groups) {
            const processed = groups.map(group => {
                const grp = deepClone(group);

                grp.label = getGroupLabel(group.name);

                grp.editables = grp.editables.map(editable => ({
                    ...editable,
                    label: getEditableLabel(editable.name)
                }));

                return grp;
            });

            state.groups = processed;
        },

        setInitialState(state) {
            state.client = {};
            state.editableGroupValues = {};
            state.editableGroupValueIds = [];
            state.editableOverwrites = [];
            state.groups = [];
            state.id = null;
            state._id = null;
            state.languages = [campaignDefaultLanguage];
            state.masterTemplates = [];
            state.mastheadImageUrl = null;
            state.mastheadUploadConfig = null;
            state.name = null;
            state.jiraTicketUrl = null;
            state.resourceGroupIds = null;
            state.dcProfileId = null;

            state.normalized.editables = {};
            state.normalized.editableIds = [];
            state.normalized.editableGroups = {};
            state.normalized.editableGroupIds = [];
            state.normalized.editableOverwrites = {};
            state.normalized.masterTemplates = {};
            state.normalized.masterTemplateIds = [];
            state.normalized.overwrites = [];
            state.normalized.users = {};
            state.normalized.userIds = [];
        },

        setIsLocked(state, isLocked) {
            state.isLocked = isLocked;
        },

        setMasterTemplate(state, masterTemplate) {
            Vue.set(state.normalized.masterTemplates, masterTemplate._id, masterTemplate);

            if (!state.normalized.masterTemplateIds.includes(masterTemplate._id)) {
                state.normalized.masterTemplateIds.push(masterTemplate._id);
            }
        },

        setMasterTemplates(state, masterTemplates) {
            state.masterTemplates = masterTemplates;
        },

        sortEditableGroupValuesInGroup(state, groupName) {
            const groupValueIds = state.normalized.editableGroups[groupName].editableGroupValues;
            const editableGroupValues = sortEditableGroupValueIdsByValue(state, groupValueIds);

            const group = {
                ...state.normalized.editableGroups[groupName],
                editableGroupValues
            };

            Vue.set(state.normalized.editableGroups, group.name, group);
        }
    },

    actions: {
        addEditableGroupValue({ commit }, payload) {
            commit(CampaignMutation.SetEditableGroupValue, payload.editableGroupValue);
            commit(CampaignMutation.AddEditableGroupValueToGroup, payload);
            commit(CampaignMutation.SortEditableGroupValuesInGroup, payload.groupName);
        },

        disassociateMasterTemplate({ commit }, masterTemplateId) {
            commit(CampaignMutation.RemoveMasterTemplate, masterTemplateId);
        },

        removeEditableGroupValue({ commit }, payload) {
            commit(CampaignMutation.RemoveGroupValueFromEditableGroup, payload);
            commit(CampaignMutation.RemoveEditableGroupValue, payload.editableGroupValueId);
        },

        setCampaign({ commit }, campaign) {
            commit(CampaignMutation.SetCampaign, campaign);
        },

        setCampaignUsers({ commit }, users) {
            commit(CampaignMutation.SetCampaignUsers, users);
        },

        setDcProfileId({ commit }, dcProfileId) {
            commit(CampaignMutation.SetDcProfileId, dcProfileId);
        },

        setEditableOverwrites({ commit }, editableOverwrites) {
            commit(CampaignMutation.SetEditableOverwrites, editableOverwrites);

            editableOverwrites.forEach(editableOverwrite =>
                commit(CampaignMutation.SetEditableOverwrite, editableOverwrite)
            );
        },

        setEditableRestriction({ commit }, payload) {
            commit(CampaignMutation.SetEditableRestriction, payload);
        },

        setGroups({ commit }, groups) {
            commit(CampaignMutation.ClearGroups);
            commit(CampaignMutation.SetGroups, groups);

            // A single editable id can be in multiple groups so we need to collect them all
            // here and merge their defaultValues before using the mutation
            const editables = groups.reduce((acc, group) => {
                group.editables.forEach(editable => {
                    if (acc[editable._id]) {
                        acc[editable._id].defaultValues.push(...editable.defaultValues);
                    } else {
                        // Create a new editable object - this protects the reactive object in the apollo cache from being modified
                        //  modifications to that object trigger the apollo result handler again in an infinite loop and the library crashes
                        acc[editable._id] = { ...editable, defaultValues: [...editable.defaultValues] };
                    }
                });

                return acc;
            }, {});
            Object.values(editables).forEach(editable => commit(CampaignMutation.SetEditable, editable));

            groups.forEach(group =>
                group.editableGroupValues.forEach(editableGroupValue =>
                    commit(CampaignMutation.SetEditableGroupValue, editableGroupValue)
                )
            );

            groups.forEach(group => commit(CampaignMutation.SetEditableGroup, group));
        },

        setInitialState({ commit }) {
            commit(CampaignMutation.SetInitialState);
        },

        setIsLocked({ commit }, isLocked) {
            commit(CampaignMutation.SetIsLocked, isLocked);
        },

        setMasterTemplates({ commit, getters }, masterTemplates) {
            // Find and remove any templates which are no longer in the list
            const currentMasterTemplates = getters.masterTemplates;
            const templatesToRemove = Object.keys(currentMasterTemplates).filter(
                id => !masterTemplates.find(mt => mt._id === id)
            );
            templatesToRemove.forEach(masterTemplateId =>
                commit(CampaignMutation.RemoveMasterTemplate, masterTemplateId)
            );

            commit(CampaignMutation.SetMasterTemplates, masterTemplates);
            masterTemplates.forEach(masterTemplate => commit(CampaignMutation.SetMasterTemplate, masterTemplate));
        },

        updateEditableGroupValue({ commit }, payload) {
            commit(CampaignMutation.SetEditableGroupValue, payload.editableGroupValue);
            commit(CampaignMutation.SortEditableGroupValuesInGroup, payload.groupName);
        }
    }
};
