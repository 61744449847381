<template>
    <div v-if="data" class="insights-chart-preview">
        <h2>{{ title }}</h2>
        <bar-chart v-if="displayMode === CreativeInsightsMode.BarReport" :data="data" :label-map="labelMap"></bar-chart>
        <scatter-chart
            v-if="displayMode === CreativeInsightsMode.QuadrantReport"
            :data="data"
            :label-map="labelMap"
        ></scatter-chart>
    </div>
</template>
<script>
import BarChart from "@/components/CreativeIntelligence/BarChart";
import ScatterChart from "@/components/CreativeIntelligence/ScatterChart";
import { CreativeInsightsMode } from "@/enums/creativeInteligence";

export default {
    name: "InsightsChartPreview",
    components: { ScatterChart, BarChart },
    props: {
        data: {
            type: Object
        },
        labelMap: {
            type: Object,
            default() {
                return {};
            }
        },
        title: {
            type: String
        }
    },

    computed: {
        displayMode() {
            return this.$store.state.creativeInsights.displayMode;
        }
    },

    created() {
        this.CreativeInsightsMode = CreativeInsightsMode;
    }
};
</script>

<style lang="scss">
@import "../../../../sass/_variables.scss";

.insights-chart-preview {
    max-width: 100%;
    background-color: white;
    padding: $spacing-large;
    margin: 0 auto $spacing-larger auto;

    h2 {
        text-transform: capitalize;
        color: $ci-font-color-secondary;
    }
}
</style>
