<template>
    <hox-modal @close="close">
        <hox-loading-layer v-if="isUpdatingMetadataField" />
        <delete-metadata-field-modal
            v-if="deleteMetadataFieldModalIsVisible"
            :field-id="fieldId"
            :column-name="columnName"
            @close="toggleDeleteMetadataFieldModalIsVisible"
            @metadataFieldDeleted="close"
        />
        <template #header>
            Edit the "
            <em>{{ columnName }}</em>
            " column
        </template>
        <metadata-field-form
            :has-error-submitting-form="hasErrorUpdatingMetadataField"
            :initial-form-data="{ columnName }"
            :show-validation-errors="true"
            :error="formError"
            @formDataUpdate="setFormData"
            @hasValidationErrorsUpdate="setHasValidationErrors"
            @submit="updateMetadataField"
        />
        <template #footer>
            <div class="edit-metadata-field-modal__buttons-container">
                <Button ghost type="error" @click="toggleDeleteMetadataFieldModalIsVisible">Delete</Button>
                <Button :disabled="hasValidationErrors" type="primary" @click="updateMetadataField">Save</Button>
            </div>
        </template>
    </hox-modal>
</template>

<script>
import DeleteMetadataFieldModal from "@/components/Metadata/DeleteMetadataFieldModal";
import MetadataFieldForm from "@/components/Metadata/MetadataFieldForm";
import { updateMetadataField } from "@/services/Metadata";
import { DeliverableLibraryAction } from "@/store/modules/deliverableLibrary";

export default {
    components: {
        DeleteMetadataFieldModal,
        MetadataFieldForm
    },

    props: {
        columnName: {
            required: true,
            type: String
        },

        fieldId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            deleteMetadataFieldModalIsVisible: false,
            formData: {},
            hasErrorUpdatingMetadataField: false,
            hasValidationErrors: false,
            isUpdatingMetadataField: false,
            formError: null
        };
    },

    methods: {
        close() {
            this.$emit("close");
        },

        setFormData(formData) {
            this.formData = formData;
            this.formError = null;
            this.hasErrorUpdatingMetadataField = false;
        },

        setHasValidationErrors(hasValidationErrors) {
            this.hasValidationErrors = hasValidationErrors;
        },

        toggleDeleteMetadataFieldModalIsVisible() {
            this.deleteMetadataFieldModalIsVisible = !this.deleteMetadataFieldModalIsVisible;
        },

        async updateMetadataField() {
            if (!this.hasValidationErrors) {
                this.hasErrorUpdatingMetadataField = false;
                this.isUpdatingMetadataField = true;
                try {
                    const updatedMetadataField = await updateMetadataField(this.fieldId, this.formData.columnName);
                    this.$store.dispatch(DeliverableLibraryAction.SetMetadataFieldsByFieldId, updatedMetadataField);
                    if (this.columnName !== updatedMetadataField.name) {
                        this.$snackbar.success(
                            `The column "${this.columnName}" has been renamed to "${updatedMetadataField.name}"`
                        );
                    }
                } catch (err) {
                    this.formError = err;
                    this.hasErrorUpdatingMetadataField = true;
                    return;
                } finally {
                    this.isUpdatingMetadataField = false;
                }
                this.close();
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.edit-metadata-field-modal__buttons-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
</style>
