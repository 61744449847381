export default text => {
    const el = document.createElement("textarea");
    const lines = Array.isArray(text) ? text : [text];
    el.value = lines.join("\n");
    el.setAttribute("readonly", "");
    el.style = { display: "none" };
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
};
