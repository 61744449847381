<template>
    <hox-modal v-if="isOpen" class="ci-settings-modal" @close="close">
        <template #header>Set Benchmarks & Confidence Thresholds</template>

        <report-metrics-data-provider
            v-if="!metricNames.length"
            @data="setMetricNames"
            @error="setError"
            @loading="setLoading"
        />
        <template>
            <div class="ci-settings-modal__section">
                <div class="ci-settings-modal__section-header">
                    <h3>Kpi Metric</h3>
                    <metric-selector v-model="kpiMetricsName" :options="metricNames" :loading="isLoading" />
                </div>
                <p>
                    Select which KPI is most important to you, and provide a benchmark where an Ad that has performed
                    above this number is considered “good”.
                </p>
                <div class="ci-settings-modal__section-inputs">
                    <Select
                        :value="toSave.kpiMetricsMode"
                        class="ci-settings-modal__metric-mode"
                        size="small"
                        transfer
                        @input="kpiMetricsMode = $event"
                    >
                        <Option value="avg">Use Average</Option>
                        <Option value="value">Use Value</Option>
                    </Select>
                    <Input
                        :value="toSave.kpiMetricsValue"
                        :disabled="isMetricInputDisabled('kpi')"
                        size="small"
                        class="ci-settings-modal__section-number"
                        @input="kpiMetricsValue = $event"
                    />
                </div>
            </div>
            <div class="ci-settings-modal__section">
                <div class="ci-settings-modal__section-header">
                    <h3>Confidence Metric</h3>
                    <metric-selector v-model="confidenceMetricsName" :options="metricNames" :loading="isLoading" />
                </div>
                <p>
                    Select a metric which represents statistical significance, and provide a threshold where an Ad that
                    has reached beyond this number is considered “well tested”.
                </p>
                <div class="ci-settings-modal__section-inputs">
                    <Select
                        :value="toSave.confidenceMetricsMode"
                        class="ci-settings-modal__metric-mode"
                        size="small"
                        transfer
                        @input="confidenceMetricsMode = $event"
                    >
                        <Option value="avg">Use Average</Option>
                        <Option value="value">Use Value</Option>
                    </Select>
                    <Input
                        :value="toSave.confidenceMetricsValue"
                        :disabled="isMetricInputDisabled('confidence')"
                        class="ci-settings-modal__section-number"
                        size="small"
                        @input="confidenceMetricsValue = $event"
                    ></Input>
                </div>
            </div>

            <div class="ci-settings-modal__section">
                <div class="discard_header">
                    <h3>Discard Metric</h3>
                    <div class="discard_header__add-btn white-space-nowrap" @click="addNewDiscard">
                        <Icon type="md-add"></Icon>
                        Add
                    </div>
                </div>
                <p>
                    Select a metric which where any Ads which have not yet reached this number should be ignored and not
                    included in any computations and results.
                </p>
                <div
                    v-for="discardThresholdName in metricNamesWithDiscardValue"
                    :key="discardThresholdName"
                    class="ci-settings-modal__dynamic-input ci-settings-modal__section-inputs"
                >
                    <Icon type="md-trash" @click="removeDiscard(discardThresholdName)" />
                    <div class="ci-settings-modal__discard-name">{{ getMetricLabel(discardThresholdName) }}</div>
                    <Select
                        v-if="discardThresholdName === 'aiService'"
                        v-model="localThresholds[discardThresholdName]"
                        size="small"
                    >
                        <Option v-for="item in aiServiceOptions" :key="item.value" :value="item.value">
                            {{ item.label }}
                        </Option>
                    </Select>
                    <Input
                        v-else
                        :value="localThresholds[discardThresholdName]"
                        size="small"
                        class="ci-settings-modal__section-number"
                        @input="setDiscardValues(discardThresholdName, $event)"
                    ></Input>
                </div>
                <div
                    v-for="(discard, i) in newDiscardValues"
                    :key="i"
                    class="ci-settings-modal__dynamic-input ci-settings-modal__section-inputs"
                >
                    <Icon type="md-trash" @click="removeNewDiscard(i)" />
                    <metric-selector
                        v-model="newDiscardValues[i].metric"
                        :options="newDiscardOptions"
                    ></metric-selector>
                    <Select
                        v-if="newDiscardValues[i].metric === 'aiService'"
                        v-model="newDiscardValues[i].value"
                        size="small"
                    >
                        <Option v-for="item in aiServiceOptions" :key="item.value" :value="item.value">
                            {{ item.label }}
                        </Option>
                    </Select>
                    <Input
                        v-else
                        v-model="newDiscardValues[i].value"
                        class="ci-settings-modal__section-number"
                        size="small"
                    ></Input>
                </div>
            </div>
        </template>

        <template #footer>
            <Button type="primary" :disabled="waitingForValidData" @click="save">Save</Button>
        </template>
    </hox-modal>
</template>

<script>
import { CreativeInsightsAction, CreativeInsightsGetters } from "@/store/modules/creativeInsights";
import MetricSelector from "@/components/CreativeIntelligence/MetricSelector";
import ReportMetricsDataProvider from "@/components/Reporting/data/ReportMetricsDataProvider";
import Vue from "vue";
import { metricValueFormatter } from "@/utils/creativeInteligence";
import { getMetricsLabel } from "@/enums/creativeInteligence";
import { ReportKPI } from "@/enums/reporting";

const aiServiceOptions = [
    { label: "AWS Rekognition", value: "AWS" },
    { label: "Google Cloud Vision", value: "GCV" },
    { label: "GVI", value: "GVI" },
    { label: "Brand Guardia", value: "WT-BG" },
    { label: "Image to Prompt", value: "replicate/img2prompt" }
];

export default {
    name: "CreativeInsightsSettingsModal",
    components: { ReportMetricsDataProvider, MetricSelector },

    data() {
        return {
            toSave: {},
            isLoading: false,
            localThresholds: [],
            newDiscardValues: []
        };
    },

    computed: {
        clientId() {
            return this.$store.state.route.params.clientId;
        },

        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        confidenceMetricsMode: {
            get() {
                if (this.$store.getters[CreativeInsightsGetters.ConfidenceMetricMode] === undefined) {
                    return this.toSave.confidenceMetricsMode || "avg";
                }

                return this.$store.getters[CreativeInsightsGetters.ConfidenceMetricMode];
            },
            set(val) {
                this.toSave.confidenceMetricsMode = val;
            }
        },

        confidenceMetricsName: {
            get() {
                if (this.$store.getters[CreativeInsightsGetters.ConfidenceMetricName] === undefined) {
                    if (!this.metricNames.length) {
                        return "";
                    }

                    return this.metricNames.includes("impressions") ? "impressions" : this.metricNames[1];
                }

                return this.$store.getters[CreativeInsightsGetters.ConfidenceMetricName];
            },
            set(val) {
                this.toSave.confidenceMetricsName = val;
            }
        },

        confidenceMetricsValue: {
            get() {
                if (this.$store.getters[CreativeInsightsGetters.ConfidenceMetricValue] === undefined) {
                    if (
                        !this.metricNames.length ||
                        !this.confidenceMetricsName ||
                        !this.metricStats[this.confidenceMetricsName]
                    ) {
                        return "";
                    }

                    return metricValueFormatter(this.metricStats[this.confidenceMetricsName].avg);
                }

                return this.$store.getters[CreativeInsightsGetters.ConfidenceMetricValue];
            },
            set(val) {
                this.toSave.confidenceMetricsValue = val;
            }
        },

        discardThresholds: {
            get() {
                return this.$store.state.creativeInsights.discardThresholds;
            },
            set(val) {
                this.toSave.discardThresholds = val;
            }
        },

        isOpen() {
            return this.$store.state.creativeInsights.isSettingsModalOpen;
        },

        kpiMetricsMode: {
            get() {
                if (this.$store.getters[CreativeInsightsGetters.KpiMetricMode] === undefined) {
                    return this.toSave.kpiMetricsMode || "avg";
                }

                return this.$store.getters[CreativeInsightsGetters.KpiMetricMode];
            },

            set(val) {
                this.toSave.kpiMetricsMode = val;
            }
        },

        kpiMetricsName: {
            get() {
                if (this.$store.getters[CreativeInsightsGetters.KpiMetricName] === undefined) {
                    if (!this.metricNames.length) {
                        return "";
                    }

                    if (this.metricNames.includes(ReportKPI.ClickRate)) {
                        return ReportKPI.ClickRate;
                    }

                    if (this.metricNames.includes(ReportKPI.ReactionsTotalRate)) {
                        return ReportKPI.ReactionsTotalRate;
                    }

                    return this.metricNames[0];
                }

                return this.$store.getters[CreativeInsightsGetters.KpiMetricName];
            },
            set(val) {
                this.toSave.kpiMetricsName = val;
            }
        },

        kpiMetricsValue: {
            get() {
                if (this.$store.getters[CreativeInsightsGetters.KpiMetricValue] === undefined) {
                    if (!this.metricNames.length || !this.kpiMetricsName || !this.metricStats[this.kpiMetricsName]) {
                        return "";
                    }

                    return metricValueFormatter(this.metricStats[this.kpiMetricsName].avg);
                }

                return this.$store.getters[CreativeInsightsGetters.KpiMetricValue];
            },
            set(val) {
                this.toSave.kpiMetricsValue = val;
            }
        },

        metricNames() {
            return this.$store.state.creativeInsights.reportMetricNames;
        },

        metricNamesWithDiscardValue() {
            return Object.keys(this.localThresholds);
        },

        metricStats() {
            return this.$store.state.creativeInsights.reportMetricStats;
        },

        newDiscardOptions() {
            let extraThresholds = ["confidence", "aiService"];

            const newThresholdsNames = this.newDiscardValues.map(({ metric }) => metric);

            if (
                this.metricNamesWithDiscardValue.includes("confidence") ||
                this.metricNamesWithDiscardValue.includes("aiService")
            ) {
                extraThresholds = [];
            }

            if (newThresholdsNames.includes("aiService")) {
                extraThresholds = ["aiService"];
            } else if (newThresholdsNames.includes("confidence")) {
                extraThresholds = ["confidence"];
            }

            return [
                ...extraThresholds,
                ...this.metricNames.filter(name => !this.metricNamesWithDiscardValue.includes(name))
            ];
        },

        sortBy: {
            get() {
                return {
                    ...this.$store.state.creativeInsights.sortBy,
                    field: this.kpiMetricsName
                };
            },
            set(val) {
                this.toSave.sortBy = {
                    ...val,
                    field: this.kpiMetricsName
                };
            }
        },

        waitingForValidData() {
            if (
                (this.$store.getters[CreativeInsightsGetters.KpiMetricName] === undefined ||
                    this.$store.getters[CreativeInsightsGetters.ConfidenceMetricName] === undefined) &&
                this.isLoading
            ) {
                return true;
            }

            return false;
        }
    },

    watch: {
        kpiMetricsMode: {
            immediate: true,
            handler(v) {
                this.toSave = { ...this.toSave, kpiMetricsMode: v };
                // for now we only allow the kpi to be used as a sort field
                this.toSave = { ...this.toSave, sortBy: { ...this.sortBy, field: v } };
            }
        },

        kpiMetricsName: {
            immediate: true,
            handler(v) {
                this.toSave = { ...this.toSave, kpiMetricsName: v };
            }
        },

        kpiMetricsValue: {
            immediate: true,
            handler(v) {
                this.toSave = { ...this.toSave, kpiMetricsValue: v };
            }
        },

        confidenceMetricsMode: {
            immediate: true,
            handler(v) {
                this.toSave = { ...this.toSave, confidenceMetricsMode: v };
            }
        },

        confidenceMetricsName: {
            immediate: true,
            handler(v) {
                this.toSave = { ...this.toSave, confidenceMetricsName: v };
            }
        },

        confidenceMetricsValue: {
            immediate: true,
            handler(v) {
                this.toSave = { ...this.toSave, confidenceMetricsValue: v };
            }
        },

        discardThresholds: {
            immediate: true,
            handler(v) {
                this.localThresholds = { ...v };
            }
        },

        sortBy: {
            immediate: true,
            handler(v) {
                // for now we only allow the kpi to be used as a sort field
                this.toSave = { ...this.toSave, sortBy: { ...this.sortBy, field: v } };
                this.toSave = { ...this.toSave, sortBy: v };
            }
        },

        // eslint-disable-next-line func-names
        "toSave.kpiMetricsName": function (val) {
            this.updateAvgValue("kpi");
            this.setSortBy("field", val);
        },

        // eslint-disable-next-line func-names
        "toSave.kpiMetricsMode": function () {
            this.updateAvgValue("kpi");
        },

        // eslint-disable-next-line func-names
        "toSave.confidenceMetricsName": function () {
            this.updateAvgValue("confidence");
        },

        // eslint-disable-next-line func-names
        "toSave.confidenceMetricsMode": function () {
            this.updateAvgValue("confidence");
        }
    },

    created() {
        this.aiServiceOptions = aiServiceOptions;
    },

    methods: {
        addNewDiscard() {
            this.newDiscardValues.push({ metric: "", value: 0 });
        },

        close() {
            this.$store.dispatch(CreativeInsightsAction.ToggleSettingsModal);
            this.$emit("close");
        },

        getMetricLabel(metric) {
            return getMetricsLabel(metric);
        },

        isMetricInputDisabled(metric) {
            return this.toSave[`${metric}MetricsMode`] === "avg" || this.toSave[`${metric}MetricsMode`] === undefined;
        },

        removeDiscard(discardName) {
            Vue.delete(this.localThresholds, discardName);
        },

        removeNewDiscard(index) {
            Vue.delete(this.newDiscardValues, index);
        },

        setDiscardValues(discardName, val) {
            this.localThresholds[discardName] = val;
        },

        save() {
            const newThresholds = this.newDiscardValues.reduce((acc, { metric, value }) => {
                if (metric && value !== "") {
                    acc[metric] = value;
                }

                return acc;
            }, {});
            const discardThresholds = { ...this.localThresholds, ...newThresholds };
            this.$store.dispatch(CreativeInsightsAction.SaveSettings, {
                scopeId: this.campaignId,
                ...this.toSave,
                discardThresholds
            });
            this.close();
            this.newDiscardValues = [];
        },

        setLoading(isLoading) {
            this.isLoading = isLoading;
        },

        setMetricNames({ percentage, metrics, metricStats }) {
            if (percentage !== undefined) {
                this.$store.dispatch(CreativeInsightsAction.SetIsIndexed, false);
                this.$store.dispatch(CreativeInsightsAction.SetIndexedPercentage, percentage);
                this.close();
                return;
            }

            this.$store.dispatch(CreativeInsightsAction.SetReportMetricNames, metrics);
            this.$store.dispatch(CreativeInsightsAction.SetReportMetricStats, metricStats);
        },

        setSortBy(prop, value) {
            this.toSave.sortBy = { ...this.toSave.sortBy, [prop]: value };
        },

        setError(error) {
            this.$store.dispatch(CreativeInsightsAction.SetIsIndexed, false);
            this.$store.dispatch(CreativeInsightsAction.SetIndexingError, error);
            this.close();
        },

        updateAvgValue(metric) {
            if (
                this.toSave[`${metric}MetricsName`] &&
                this.toSave[`${metric}MetricsMode`] === "avg" &&
                this.metricStats &&
                this.metricStats[this.toSave[`${metric}MetricsName`]] &&
                this.metricStats[this.toSave[`${metric}MetricsName`]].avg
            ) {
                this.toSave[`${metric}MetricsValue`] = metricValueFormatter(
                    this.metricStats[this.toSave[`${metric}MetricsName`]].avg
                );
            }
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/../sass/_variables.scss";

.ci-settings-modal {
    .hox-modal__header {
        font-size: 16px;
    }

    &__dynamic-input {
        margin-bottom: $spacing-semi-small;
    }
}

.ci-settings-modal__section {
    padding: $spacing-semi-large $spacing-larger;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
            font-weight: normal;
            white-space: nowrap;
        }

        .report-kpi-selector {
            width: 210px;
            flex: 0 0 210px;
        }
    }

    p {
        padding: $spacing 0;
    }

    &-inputs {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .ci-settings-modal__metric-mode,
        .ci-settings-modal__discard-name {
            width: 180px;
            flex: 0 0 180px;
            margin-right: auto;
        }

        .ivu-icon {
            margin-right: $spacing-small;
        }
    }

    &-number {
        width: 80px;
    }
}

.discard_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__add-btn {
        color: $ci-blue;
        display: flex;
        align-items: center;
        line-height: 20px;
        cursor: pointer;
    }
}
</style>
