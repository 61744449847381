// @ts-check
import { getDefaultHeaders, resourceManagerRequest, RESOURCE_MANAGER_TYPES, request } from "./index";

/**
 * CREATE PLAINLY PROJECT
 */

/**
 * @typedef {Object} BaseLayer
 * @property {number} duration
 * @property {string} internalId
 * @property {boolean} guideLayer
 * @property {number} workAreaStart
 * @property {string} name
 * @property {number} width
 * @property {number} id
 * @property {boolean} enabled
 * @property {number} height
 */

/**
 * @typedef {Object} ChildBaseLayerProps
 * @property {number} outPoint
 * @property {number} inPoint
 * @property {number} startTime
 * @typedef {BaseLayer & ChildBaseLayerProps} ChildBaseLayer
 */

/**
 * @typedef {Object} CompositionLayerProps
 * @property {"COMPOSITION"} type
 * @property {(ChildLayer)[] | []} children
 * @typedef {BaseLayer & CompositionLayerProps} CompositionLayer
 */

/**
 * @typedef {Object} ChildCompositionLayerProps
 * @property {"COMPOSITION"} type
 * @property {string} layerName
 * @property {(ChildLayer)[] | []} children
 * @typedef {ChildBaseLayer & ChildCompositionLayerProps} ChildCompositionLayer
 */

/**
 * @typedef {Object} ChildMediaLayerProps
 * @property {Object} preview
 * @property {string} preview.link
 * @property {string} preview.expiresAt
 * @property {boolean} preview.internal
 * @property {"IMAGE"|"VIDEO"|"AUDIO"} mediaType
 * @property {"MEDIA"} type
 * @property {string} value
 * @typedef {ChildBaseLayer & ChildMediaLayerProps } ChildMediaLayer
 */

/**
 * @typedef {Object} ChildTextLayerProps
 * @property {string} fontLocation
 * @property {"TEXT"} type
 * @property {string} fontStyle
 * @property {string} fontFamily
 * @property {number} fontSize
 * @property {string} value
 * @property {string} font
 * @typedef {ChildBaseLayer & ChildTextLayerProps} ChildTextLayer
 */

/**
 * @typedef {Object} ChildSolidLayerProps
 * @property {"SOLID"} mediaType
 * @property {"MEDIA"} type
 * @property {string} value
 * @typedef {ChildBaseLayer & ChildSolidLayerProps} ChildSolidLayer
 */

/**
 * @typedef {Object} HackComposedLayerType
 * @property {ChildLayer[]} [children]
 * @property {"IMAGE"|"VIDEO"|"AUDIO"|"SOLID"} [mediaType]
 * @property {string} [value]
 */

/**
 * @typedef {(ChildMediaLayer | ChildCompositionLayer | ChildTextLayer | ChildSolidLayer) & HackComposedLayerType } ChildLayer
 */

/**
 * @typedef {Object} PlainlyProjectSuccessOutput
 * @property {string} plainlyProjectId
 * @property {CompositionLayer[]} layers
 */

/**
 * @typedef {import("./").ResourceManagerResponse<PlainlyProjectSuccessOutput>} CreatePlainlyProjectResponse
 * @typedef {import("./").ResourceManagerSuccesResponse<PlainlyProjectSuccessOutput>} CreatePlainlyProjectSuccessResponse
 */

/**
 * @typedef {Object} CreatePlainlyProjectArgs
 * @property {string} baseUrl
 * @property {string} apiKey
 * @property {string} id
 * @property {string} bucket
 * @property {string} key
 */

/**
 * @param {CreatePlainlyProjectArgs} args
 * @returns {Promise<{ data: CreatePlainlyProjectResponse }>}
 */
export async function createPlainlyProject({ baseUrl, apiKey, id, bucket, key }) {
    return resourceManagerRequest({
        baseUrl,
        apiKey,
        id,
        body: {
            type: RESOURCE_MANAGER_TYPES.PLAINLY_CREATE_PROJECT,
            input: {
                storageInfo: {
                    bucket,
                    key
                }
            }
        }
    });
}

/**
 * CREATE PLAINLY RENDER
 */

/**
 * @typedef {Object} PlainlyComputedTextLayer
 * @property {string} name
 * @property {string} value
 */

/**
 * @typedef {Object} PlainlyComputedMediaLayer
 * @property {string} name
 * @property {Object} fileLocation
 * @property {string} fileLocation.bucket
 * @property {string} fileLocation.key
 */

/**
 * @typedef {PlainlyComputedTextLayer | PlainlyComputedMediaLayer} PlainlyComputedLayer
 */

/**
 * @typedef {Object} RequestPlainlyRenderInput
 * @property {string} reportingLabel
 * @property {string} plainlyProjectId
 * @property {Object} plainlyTemplateId
 * @property {Object} outputOptions
 * @property {boolean} outputOptions.preview
 * @property {PlainlyComputedLayer[]} computedLayers
 */

/**
 * @typedef {Object} RequestPlainlyRenderArgs
 * @property {string} baseUrl
 * @property {string} apiKey
 * @property {string} id
 * @property {RequestPlainlyRenderInput} input
 */

/**
 * @typedef {Object} PlainlyRenderSuccessOutput
 * @property {Object} fileLocation
 * @property {string} fileLocation.bucket
 * @property {string} fileLocation.key
 * @property {string} fileLocation.signedUrl
 */

/**
 * @typedef {import("./").ResourceManagerResponse<PlainlyRenderSuccessOutput>} PlainlyRenderResponse
 */

/**
 * @param {RequestPlainlyRenderArgs} args
 * @returns {Promise<{ data: PlainlyRenderResponse }>}
 */
export function createPlainlyRender({ baseUrl, apiKey, id, input }) {
    return resourceManagerRequest({
        baseUrl,
        apiKey,
        id,
        body: {
            type: RESOURCE_MANAGER_TYPES.PLAINLY_RENDER,
            input
        }
    });
}

/**
 * CREATE PLAINLY BATCH RENDER
 */

/**
 * @typedef {Object} RequestPlainlyBatchRenderArgs
 * @property {string} baseUrl
 * @property {string} apiKey
 * @property {string} id
 * @property {RequestPlainlyRenderInput[]} inputs
 */

/**
 * @typedef {Object} PlainlyBatchRenderSuccessOutput
 * @property {Object} zipFileLocation
 * @property {string} zipFileLocation.bucket
 * @property {string} zipFileLocation.key
 * @property {string} zipFileLocation.signedUrl
 */

/**
 * @typedef {import("./").ResourceManagerResponse<PlainlyBatchRenderSuccessOutput>} PlainlyBatchRenderResponse
 */

/**
 * @param {RequestPlainlyBatchRenderArgs} args
 * @returns {Promise<{ data: PlainlyBatchRenderResponse }>}
 */
export function createPlainlyBatchRender({ baseUrl, apiKey, id, inputs }) {
    return resourceManagerRequest({
        baseUrl,
        apiKey,
        id,
        body: {
            type: RESOURCE_MANAGER_TYPES.PLAINLY_BATCH_RENDER,
            input: {
                recipients: [],
                itemsToRender: inputs
            }
        }
    });
}

/**
 * CREATE PLAINLY TEMPLATES
 */

/**
 * @typedef {Object} PlainlyLayer
 * @property {string} id
 * @property {string=} label
 */

/**
 * @typedef {Object} PlainlyTemplate
 * @property {string} name
 * @property {number} renderingCompositionId
 * @property {PlainlyLayer[]} layers
 */

/**
 * @param {Object} params
 * @param {string} params.baseUrl
 * @param {string} params.apiKey
 * @param {string} params.resourceId
 * @param {PlainlyTemplate[]} params.templates
 * @returns {Promise<{ data: string[] }>}
 */
export function createPlainlyTemplates({ baseUrl, apiKey, resourceId, templates }) {
    const headers = getDefaultHeaders(apiKey);
    const url = `${baseUrl}/v1/plainly/add-templates`;

    return request(url, headers, "POST", { resourceId, templates });
}
