<template>
    <div v-if="matchesContentFilters" :class="containerClasses">
        <insights-data-provider
            v-if="type"
            :advanced-search="advancedSearch"
            :fetch-all="fetchAll"
            :insights-type="type"
            :next-page-token="nextPageToken"
            :refresh-counter="refreshCounter"
            :request-options="requestOptions"
            :sort-by="sortBy"
            @data="onData"
            @next="onNext"
            @error="onError"
            @loading="onLoading"
            @loadingNext="onLoadingNext"
        />
        <div v-if="checkForData && isLoading" class="insight-preview-body">
            <hox-loading-spinner v-if="isLoading" :size="40" />
        </div>
        <template v-else-if="hasData || !checkForData">
            <div v-if="displayMode === CreativeInsightsMode.InsightsReport" class="insight-preview-body">
                <Collapse v-model="opened" :class="classes" @input="onOpenedChange">
                    <Panel name="1" class="insight-preview__panel">
                        <span class="insight-preview__panel-name">
                            <h4><slot name="name"></slot></h4>
                            <span class="insight-preview__panel-name--inner">
                                <h2><slot name="headline"></slot></h2>
                            </span>
                        </span>
                        <span class="insight-preview__panel-controls">
                            <slot name="controls"></slot>
                        </span>

                        <div slot="content" class="insight-preview__body">
                            <slot></slot>
                            <slot name="errorMessage">
                                <hox-alert v-if="hasError" margin-top="base" type="danger" @close="forceRefetch">
                                    <template #title>Uh oh!</template>
                                    <template #content>
                                        <p>
                                            There was an unexpected issue while loading the data. Hopefully it was a
                                            temporary issue and should be fixed if you try again in a few moments.
                                        </p>
                                    </template>
                                    <template #actionItems>
                                        <Button type="primary" @click="forceRefetch">Try again</Button>
                                    </template>
                                </hox-alert>
                            </slot>
                        </div>
                    </Panel>
                </Collapse>
            </div>
            <div v-if="canDisplayChart" class="insight-preview-body">
                <slot name="chartPreview"></slot>
            </div>
        </template>
    </div>
</template>

<script>
import InsightsDataProvider from "@/components/CreativeIntelligence/InsightsDataProvider";
import { CreativeInsightsGetters } from "@/store/modules/creativeInsights";
import { CreativeInsightsContentFilter, CreativeInsightsMode } from "@/enums/creativeInteligence";
import { deliverablesFromVideoGroups } from "@/utils/creativeInteligence";

export default {
    name: "InsightPreviewWrapper",
    components: { InsightsDataProvider },
    props: {
        checkForData: {
            type: Boolean,
            default: true
        },

        fetchAll: {
            type: Boolean,
            default: false
        },

        filters: {
            type: [Array, String],
            default: ""
        },

        nextPageToken: {
            type: String,
            default: ""
        },

        requestOptions: {
            type: Object,
            default() {
                return {};
            }
        },

        responsePath: {
            type: String
        },

        type: {
            type: String
        }
    },

    data() {
        return {
            data: null,
            hasError: false,
            isLoading: false,
            opened: "",
            refreshCounter: 0
        };
    },

    computed: {
        advancedSearch() {
            return this.$store.getters[CreativeInsightsGetters.AdvancedSearchValue];
        },

        canDisplayChart() {
            return (
                [CreativeInsightsMode.QuadrantReport, CreativeInsightsMode.BarReport].includes(this.displayMode) &&
                this.hasChartView &&
                this.hasData
            );
        },

        classes() {
            return [];
        },

        containerClasses() {
            const classes = ["insight-preview"];

            if (!this.isSidebarOpen) {
                classes.push("insight-preview--collapsed");
            }
            return classes;
        },

        contentFilter() {
            return this.$store.state.creativeInsights.contentFilter;
        },

        creativeInsightsSearch() {
            return this.$store.state.creativeInsights.advancedSearch;
        },

        displayMode() {
            return this.$store.state.creativeInsights.displayMode;
        },

        hasChartView() {
            return !!this.$slots.chartPreview;
        },

        hasData() {
            if (!this.data) {
                return false;
            }

            if (this.data && this.data.summaryStats) {
                return true;
            }

            const chartData = Object.keys(this.data).reduce((acc, group) => {
                if (this.data[group].summaryStats && this.data[group].deliverables.length) {
                    acc[group] = this.data[group];
                }

                return acc;
            }, {});

            return !!Object.keys(chartData).length;
        },

        isSidebarOpen() {
            return this.$store.state.ui.isSidebarOpen;
        },

        matchesContentFilters() {
            return this.matchingFilters.includes(this.contentFilter);
        },

        matchingFilters() {
            if (Array.isArray(this.filters)) {
                return [CreativeInsightsContentFilter.Everything].concat(...this.filters);
            }
            return [CreativeInsightsContentFilter.Everything, this.filters];
        },

        selectedTagIds() {
            return this.$store.state.ciTags.selectedTagIds;
        },

        sortBy() {
            return this.$store.state.creativeInsights.sortBy;
        },

        tagMode() {
            return this.selectedTagIds.length;
        },

        tagsById() {
            return this.$store.state.ciTags.tagsById;
        }
    },

    watch: {
        advancedSearch() {
            this.hasError = false;
        }
    },

    created() {
        this.CreativeInsightsMode = CreativeInsightsMode;
    },

    methods: {
        forceRefetch() {
            this.hasError = false;
            this.refreshCounter += 1;
        },

        onData(response) {
            this.$emit("response", response);
            if (response.requestType === this.type) {
                let responseData = this.responsePath ? response.data[this.responsePath] : response.data;
                responseData = responseData || {};
                const data = Object.keys(responseData).reduce((acc, group) => {
                    acc[group] = {
                        ...responseData[group]
                    };
                    if (!responseData[group].deliverables && responseData[group].uniqueCreativesGroups) {
                        acc[group].deliverables = deliverablesFromVideoGroups(
                            responseData[group].uniqueCreativesGroups
                        );
                    }

                    return acc;
                }, {});

                this.data = data;
                this.$emit("data", this.data);
            }
        },

        onError(error) {
            this.hasError = true;
            this.$emit("error", error);
            // TODO: if there is an error we want to hide the component
            // this.data = null;
        },

        onLoading(loading) {
            this.isLoading = loading;
            this.$emit("loading", loading);
        },

        onLoadingNext(loadingNext) {
            this.isLoadingNext = loadingNext;
            this.$emit("loadingNext", loadingNext);
        },

        onNext(response) {
            this.$emit("next", response);
        },

        onOpenedChange(opened) {
            this.$emit("opened", opened.length);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.insight-preview {
    width: calc(100vw - 410px);

    &--collapsed {
        width: calc(100vw - 150px);
    }

    &-body {
        min-height: 100px;
        margin: 0 0 $spacing 0;
        width: 100%;
    }

    &.ivu-collapse {
        border: none;
        border-radius: 0;

        .ivu-collapse-content > .ivu-collapse-content-box {
            padding: 6px 0;
        }
    }

    .ivu-checkbox-wrapper {
        margin: 0;
        padding: 0 0 0 50px;
        display: block;
    }

    &__panel {
        .ivu-collapse-header {
            height: 100px !important;
            display: flex;
            width: 100%;
            flex: 1 0 100%;
            justify-content: flex-start;
            align-items: flex-start;

            & > .ivu-icon {
                display: flex;
                margin-top: $spacing-larger;
            }
        }

        &-name {
            flex: 1 0;
            overflow: hidden;

            h4 {
                padding-top: 28px;
                color: $ci-font-color-secondary;
                font-size: 12px;
                line-height: 14px;
                text-transform: uppercase;
            }

            h2 {
                line-height: 32px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &--inner {
            }
        }

        &-controls {
            display: flex;
            flex: 0 0;
            align-items: center;
            align-self: flex-end;
            margin: 12px 5px;
        }
    }

    &__body {
        padding: 0 $spacing-large;
    }

    span.tagname {
        color: $ci-font-color;
    }
}
</style>
