<template>
    <div class="master-template-library">
        <Spin v-if="isLoading && inModal" fix></Spin>
        <template v-else>
            <div class="master-template-library__header" :class="{ 'no-templates': !hasMasterTemplates }">
                <section-nav />

                <master-templates-sort-by @input="setSort" />
                <master-template-text-filter />
                <master-template-select-view-control />
                <multiview-selectall-control
                    v-if="isSelectAllVisible"
                    class="master-template-library__select-all"
                    :show-empty="false"
                    instance-name="masterTemplates"
                ></multiview-selectall-control>
                <master-template-upload-button
                    v-if="canManageMasterTemplates && !selected.length"
                    class="master-template-library__upload"
                ></master-template-upload-button>
                <Button
                    v-if="canManageMasterTemplates && selected.length"
                    type="primary"
                    class="master-template-library__upload"
                    @click.native="$emit('add')"
                >
                    Add to campaign
                </Button>
            </div>

            <multiview-selectall-notice instance-name="masterTemplates" />

            <hox-empty-message
                v-if="masterTemplateLibraryFilters.length && !hasMasterTemplates"
                class="master-template-library__no-matches"
            >
                <template #title>No templates to show</template>
                <template #content>
                    <p>Please check your filter selection or upload a new template by clicking "Add template"</p>
                </template>
            </hox-empty-message>

            <master-template-select
                v-if="hasMasterTemplates || !masterTemplateLibraryFilters.length"
                :items="masterTemplateIds"
                :campaign-template-ids="campaignMasterTemplateIds"
                :sort-by="sortBy"
                instance-name="masterTemplates"
                @select="onSelect"
            ></master-template-select>
        </template>
    </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { TemplatesSortBy } from "shared-utils/enums/masterTemplate";
import bus from "@/bus";
import clientMasterTemplatesQuery from "@/apollo/queries/v2/ClientMasterTemplates.gql";
import { MasterTemplateLibraryAction, MasterTemplateLibraryGetters } from "@/store/modules/masterTemplateLibrary";
import MasterTemplateUploadButton from "@/components/Campaign/MasterTemplates/MasterTemplateUploadButton";
import MasterTemplateSelect from "@/components/Campaign/MasterTemplateSelect";
import MultiviewSelectallControl from "@/components/Campaign/MultiviewSelectallControl";

import { UiAction } from "@/store/modules/ui";
import multiviewPaginationMixin from "@/mixins/multiviewPaginationMixin";
import MultiviewSelectallNotice from "@/components/Campaign/MultiviewSelectallNotice";
import MasterTemplatesSortBy from "@/components/Campaign/MasterTemplatesSortBy";
import MasterTemplateTextFilter from "@/components/Campaign/MasterTemplates/MasterTemplateTextFilter";
import MasterTemplateSelectViewControl from "@/components/Campaign/MasterTemplateSelectViewControl";
import SectionNav from "@/components/SectionNav";

export default {
    name: "MasterTemplateLibrary",

    components: {
        MasterTemplateSelectViewControl,
        MasterTemplateTextFilter,
        MasterTemplatesSortBy,
        MultiviewSelectallNotice,
        MultiviewSelectallControl,
        MasterTemplateSelect,
        MasterTemplateUploadButton,
        SectionNav
    },

    mixins: [multiviewPaginationMixin],

    props: {
        campaignMasterTemplateIds: {
            type: Array,
            default() {
                return null;
            }
        },
        inModal: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            isLoading: true,
            selected: [],
            sortBy: TemplatesSortBy.WIDTH_ASC,
            templateNameFilter: ""
        };
    },

    computed: {
        canListMasterTemplates() {
            return this.$auth.userCan(this.$auth.Actions.CanListMasterTemplates, { clientId: this.clientId }, false);
        },

        canManageMasterTemplates() {
            return this.$auth.userCan(this.$auth.Actions.CanManageMasterTemplates, { clientId: this.clientId }, false);
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        hasMasterTemplates() {
            return !!this.masterTemplateIds.length;
        },

        isPermissionGranted() {
            return this.whoAmI && this.canListMasterTemplates;
        },

        isSelectAllVisible() {
            return this.campaignMasterTemplateIds !== null;
        },

        masterTemplateIds() {
            return this.$store.getters[MasterTemplateLibraryGetters.filteredMasterTemplateIds];
        },

        masterTemplateLibraryFilters() {
            return this.$store.state.masterTemplateLibrary.filters;
        },

        showSidebar() {
            return this.hasMasterTemplates || !!this.masterTemplateLibraryFilters.length;
        },

        userHasScope() {
            return this.$auth.hasScope({ clientId: this.clientId }, false);
        },

        whoAmI() {
            return this.$store.state.auth.me;
        }
    },

    watch: {
        showSidebar: {
            immediate: true,
            handler(val) {
                if (!this.inModal) {
                    this.$store.dispatch(val ? UiAction.OpenSidebar : UiAction.CloseSidebar);
                }
            }
        }
    },

    methods: {
        onSelect(selected) {
            this.selected = selected;
            this.$emit("select", selected);
        },

        setSort(sortBy) {
            this.sortBy = sortBy;
        }
    },

    apollo: {
        masterTemplates: {
            query: clientMasterTemplatesQuery,
            variables() {
                return {
                    clientId: this.clientId
                };
            },
            manual: true,
            skip() {
                return !this.isPermissionGranted;
            },
            result({ data }) {
                this.$store.dispatch(MasterTemplateLibraryAction.SetMasterTemplates, data.masterTemplatesByClient);
                this.$store.dispatch(MasterTemplateLibraryAction.SyncFilters);

                this.isLoading = false;
            },

            error(e) {
                bus.$emit("apolloErrorEvent", e);
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.master-template-library {
    &__filter-tags {
        flex: 0 0 auto;
        margin: 22px 0;
        padding: 0 20px;

        &.filterTags--empty {
            margin: 0;
            padding: 0;
        }
    }

    &__no-matches {
        margin-top: $spacing;
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 0 0 auto;
        padding: 0 20px;
        border-bottom: 1px solid $grey3;
        height: 60px;
        margin: -30px 0 0;

        &.no-templates {
            .multiview-select-all {
                visibility: hidden;
            }
        }
    }

    &__select-all {
        margin-right: $spacing-semi-small;
    }
}

.templates-modal .master-template-library {
    &__header {
        margin-top: 0;
        border-bottom: 0;
    }
}
</style>
