<template>
    <div class="creative-insights-data-explorer">
        <insights-data-provider
            v-if="currentTag"
            :key="currentTag.tagId"
            :insights-type="currentEndpoint"
            :advanced-search="currentTag.advancedSearch"
            :request-options="currentEndpointOptions"
            :sort-by="sortBy"
            :fetch-all="true"
            @data="onNextData"
            @next="onNextData"
            @fetchAllComplete="onFetchAllComplete"
            @error="onError"
        />
        <Card class="creative-insights-data-explorer__card">
            <hox-alert v-if="isLoading" type="info" size="small" margin-top="none" margin-bottom="none">
                <template #title>
                    <span>
                        Loading resources for "{{ currentTag.name }}" ({{ requestIndex + 1 }} / {{ tags.length }})
                    </span>
                </template>
                <template #content>{{ loadingText }}</template>
            </hox-alert>
            <data-explorer-scatter-chart
                v-if="Object.keys(requestResults).length > 0"
                :data="requestResults"
                class="creative-insights-data-explorer__chart"
            ></data-explorer-scatter-chart>
        </Card>
    </div>
</template>
<script>
import { InsightType } from "@/components/CreativeIntelligence/constants";
import InsightsDataProvider from "@/components/CreativeIntelligence/InsightsDataProvider";
import { deliverablesFromVideoGroups } from "@/utils/creativeInteligence";
import DataExplorerScatterChart from "@/components/CreativeIntelligence/DataExplorerScatterChart";
import { getResourcePath } from "@/enums/creativeInteligence";
import { CreativeInsightsGetters } from "@/store/modules/creativeInsights";

export default {
    name: "CreativeInsightsDataExplorer",
    components: { DataExplorerScatterChart, InsightsDataProvider },
    data() {
        return {
            isLoading: true,
            loadingText: "",
            partialRequestResults: {},
            requestIndex: 0,
            requestResults: {}
        };
    },

    computed: {
        advancedSearch() {
            return this.$store.state.creativeInsights.advancedSearch;
        },

        confidenceMetricsName() {
            return this.$store.getters[CreativeInsightsGetters.ConfidenceMetricName];
        },

        currentEndpoint() {
            if (this.currentTag?.tagOptions?.endpoint) {
                return this.currentTag?.tagOptions?.endpoint;
            }

            return InsightType.AttributeValuesAnalysis;
        },

        currentEndpointOptions() {
            if (this.currentTag?.tagOptions?.endpointOptions) {
                return this.currentTag?.tagOptions?.endpointOptions;
            }

            return {};
        },

        currentTag() {
            return this.tags[this.requestIndex];
        },

        kpiMetricsName() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricName];
        },

        sortBy() {
            return this.$store.state.creativeInsights.sortBy;
        },

        tags() {
            if (this.advancedSearch) {
                return [
                    {
                        advancedSearch: this.advancedSearch,
                        tagId: "advancedSearch",
                        name: "Search Query"
                    },
                    ...this.$store.state.ciTags.tags
                ];
            }
            return this.$store.state.ciTags.tags;
        }
    },

    watch: {
        tags: {
            handler() {
                this.isLoading = true;
                this.partialRequestResults = {};
                this.requestIndex = 0;
                this.requestResults = {};
            },
            deep: true
        },

        kpiMetricsName() {
            this.setInitLoadState();
        },

        confidenceMetricsName() {
            this.setInitLoadState();
        }
    },

    created() {
        this.InsightType = InsightType;
        this.requestResults = this.tags.reduce((acc, tag) => {
            acc[tag.tagId] = {
                tag,
                data: [],
                initial: true
            };
            return acc;
        }, {});
    },

    methods: {
        getAttributesFromData(attributes) {
            return Object.keys(attributes).reduce((acc, group) => {
                acc[group] = {
                    ...attributes[group]
                };
                if (!attributes[group].deliverables && attributes[group].uniqueCreativesGroups) {
                    acc[group].deliverables = deliverablesFromVideoGroups(attributes[group].uniqueCreativesGroups);
                }

                return acc;
            }, {});
        },

        moveToNextRequest() {
            if (this.tags.length - 1 > this.requestIndex) {
                this.requestIndex += 1;
                this.requestResults = {
                    ...this.requestResults,
                    ...this.partialRequestResults
                };
            } else {
                this.requestResults = {
                    ...this.requestResults,
                    ...this.partialRequestResults
                };
                this.isLoading = false;
            }
        },

        onData({ data }) {
            if (!data) {
                return;
            }

            const normalisedData = this.getAttributesFromData(data[getResourcePath(this.currentEndpoint)]);
            this.partialRequestResults = {
                ...this.partialRequestResults,
                [this.currentTag.tagId]: {
                    tag: this.currentTag,
                    data: normalisedData
                }
            };
        },

        onError() {
            console.log("Request error, skipping tag");
            this.moveToNextRequest();
        },

        onFetchAllComplete() {
            this.partialRequestResults[this.currentTag.tagId].data = this.getAttributesFromData(
                this.partialRequestResults[this.currentTag.tagId].data
            );
            this.moveToNextRequest();
        },

        onNextData(response) {
            if (response) {
                if (!this.partialRequestResults[this.currentTag.tagId]) {
                    this.partialRequestResults[this.currentTag.tagId] = { tag: this.currentTag, data: {} };
                    console.log("init", this.currentTag.tagId);
                }

                this.partialRequestResults = {
                    ...this.partialRequestResults,
                    [this.currentTag.tagId]: {
                        ...this.partialRequestResults[this.currentTag.tagId],
                        data: {
                            ...this.partialRequestResults[this.currentTag.tagId].data,
                            ...response.data[getResourcePath(this.currentEndpoint)]
                        }
                    }
                };

                this.loadingText = `Fetched ${
                    Object.keys(this.partialRequestResults[this.currentTag.tagId].data).length
                } our of ${response.pagination.total} "${this.currentTag.name}" resource elements`;
            }
        },

        setInitLoadState() {
            this.isLoading = true;
            this.partialRequestResults = {};
            this.requestIndex = 0;
            this.requestResults = this.tags.reduce((acc, tag) => {
                acc[tag.tagId] = {
                    tag,
                    data: [],
                    initial: true
                };
                return acc;
            }, {});
        },

        setLoading(val) {
            this.isLoading = val;
        }
    }
};
</script>
<style lang="scss">
.creative-insights-data-explorer {
    width: 100%;

    &__card {
        width: 100%;
        min-height: 200px;
    }

    &__chart {
        width: 100%;
    }
}
</style>
