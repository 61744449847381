<template>
    <div class="library-checkbox-group">
        <div v-if="showAllOption" class="library-checkbox-group__all-checkbox-wrapper">
            <Checkbox
                :indeterminate="someButNotAllOptionsAreSelected"
                :value="allOptionsAreSelected"
                @on-change="onSelectAll"
            >
                All
            </Checkbox>
        </div>
        <CheckboxGroup :value="value" :vertical="true" @on-change="emitValues">
            <Checkbox v-for="option in options" :key="option.value" :label="option.value">
                {{ option.label }}
            </Checkbox>
        </CheckboxGroup>
    </div>
</template>
<script>
export default {
    props: {
        options: {
            required: true,
            type: Array
        },
        value: {
            required: true,
            type: Array
        }
    },
    computed: {
        allOptionsAreSelected() {
            return this.options.length === this.value.length;
        },
        showAllOption() {
            return this.options.length > 1;
        },
        someButNotAllOptionsAreSelected() {
            return this.value.length > 0 && this.options.length !== this.value.length;
        }
    },

    methods: {
        emitValues(values) {
            this.$emit("input", values);
        },
        onSelectAll() {
            if (this.allOptionsAreSelected) {
                this.emitValues([]);
            } else {
                this.emitValues(this.options.map(({ value }) => value));
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.library-checkbox-group__all-checkbox-wrapper {
    margin: 0 0 $spacing-small 0;
}
</style>
