<template>
    <collapsible-widget v-if="options.length > 0">
        <template slot="name">Master Template Size</template>
        <template slot="controls"></template>
        <CheckboxGroup v-model="selectedValues" class="editable-group__values" :vertical="true">
            <Checkbox v-for="option in options" :key="option" :label="option"></Checkbox>
        </CheckboxGroup>
    </collapsible-widget>
</template>
<script>
import { FilterType, MasterTemplateLibraryAction } from "@/store/modules/masterTemplateLibrary";
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";
import { deepClone } from "@/utils";

export default {
    name: "MasterTemplateSizeFilter",
    components: { CollapsibleWidget },

    props: {
        campaignMasterTemplateIds: {
            type: Array,
            default() {
                return [];
            }
        }
    },

    computed: {
        filterValues() {
            return this.$store.state.masterTemplateLibrary.filters.filter(filter => filter.type === FilterType.Size);
        },

        masterTemplates() {
            return this.$store.state.masterTemplateLibrary.masterTemplates;
        },

        // Templates that belong to the client but has not been added to the campaign yet.
        masterTemplateIds() {
            return this.$store.state.masterTemplateLibrary.masterTemplateIds.filter(
                templateId => !this.campaignMasterTemplateIds.includes(templateId)
            );
        },

        options() {
            return Object.keys(
                this.masterTemplateIds.reduce(
                    (acc, cur) =>
                        Object.assign(acc, {
                            [`${this.masterTemplates[cur].width}x${this.masterTemplates[cur].height}`]: true
                        }),
                    []
                )
            );
        },

        selectedValues: {
            get() {
                if (this.filterValues.length) {
                    const fval = deepClone(this.filterValues);

                    return fval.pop().values;
                }

                return [];
            },
            set(newValue) {
                this.$store.dispatch(MasterTemplateLibraryAction.UpdateSizeFilter, {
                    values: newValue
                });
            }
        }
    },

    beforeDestroy() {
        this.selectedValues = [];
    }
};
</script>

<style lang="scss">
.editable-group {
    &__add {
        width: 100%;
    }

    &__values {
        width: 100%;
    }

    .show-form {
        &--showing {
            display: none;
        }
    }
}
</style>
