<template>
    <div class="user-list">
        <div class="user-list-table">
            <Table
                :columns="columns"
                :data="tableData"
                :row-class-name="rowClassName"
                no-data-text="No results. Please check your filters."
            >
                <template #username="{ row }">
                    <template v-if="row.search">
                        <Input
                            :value="userFilter"
                            size="small"
                            placeholder="search"
                            search
                            clearable
                            data-testid="users-list__username"
                            @input="onUserFilterChange"
                        ></Input>
                    </template>
                    <user-role-list-name v-else :user="row"></user-role-list-name>
                </template>

                <template #role="{ row }">
                    <template v-if="row.search">
                        <Input
                            :value="roleFilter"
                            size="small"
                            placeholder="search"
                            search
                            clearable
                            data-testid="users-list__role"
                            @input="onRoleFilterChange"
                        ></Input>
                    </template>
                    <user-role-select
                        v-else
                        :disabled="isRoleDisabled(row)"
                        transfer
                        :title="row.id"
                        :value="row.role._id"
                        @input="onRoleChange(row.id, $event)"
                    ></user-role-select>
                </template>

                <template #brand="{ row }">
                    <template v-if="row.search">
                        <Input
                            :value="brandFilter"
                            size="small"
                            placeholder="search"
                            search
                            clearable
                            data-testid="users-list__brand"
                            @input="onBrandFilterChange"
                        ></Input>
                    </template>
                    <template v-else>
                        <template v-if="getUserTableData(row).length">{{ getClientsString(row) }}</template>

                        <div v-else class="user-list-table_empty-wrapper" @click.capture.stop="showModal(row)">
                            None
                        </div>
                    </template>
                </template>

                <template #action="{ row }">
                    <Button
                        v-if="!row.search"
                        class="user-list-table_action-btn"
                        size="small"
                        data-testid="users-list__edit-button"
                        @click="showModal(row)"
                    >
                        Edit
                    </Button>
                </template>
            </Table>
            <Page
                v-if="itemsToPage.length"
                class="paging-control paging-control-pages user-list-table__pages"
                :current="page"
                :total="itemsToPage.length"
                :page-size="pageSize"
                @on-change="updatePageNumber"
            ></Page>
        </div>
        <user-access-scope-modal
            v-if="isModalVisible"
            :user="modalUserData"
            @userDeleted="onUserDeleted"
            @close="hideModal"
        />
    </div>
</template>
<script>
import UserRoleSelect from "@/components/ManageUsers/UserRoleSelect";
import UserAccessScopeModal from "@/components/ManageUsers/UserAccessScopeModal";
import UserRoleListName from "@/components/ManageUsers/UserRoleListName";
import { AuthGetters } from "@/store/modules/auth";
import paginationMixin from "@/mixins/paginationMixin";

export default {
    name: "UserRolesList",
    components: {
        UserRoleSelect,
        UserRoleListName,
        UserAccessScopeModal
    },

    mixins: [paginationMixin],

    props: {
        users: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            columns: [
                {
                    title: "User",
                    slot: "username"
                },

                {
                    title: "Role",
                    width: 200,
                    slot: "role"
                },

                {
                    title: "Brand",
                    width: 500,
                    slot: "brand"
                },

                {
                    title: "Action",
                    slot: "action",
                    key: "action",
                    width: 85,
                    align: "center"
                }
            ],

            isModalVisible: false,
            modalUserData: null,
            userFilter: "",
            roleFilter: "",
            brandFilter: ""
        };
    },

    computed: {
        itemsToPage() {
            return this.filteredUsers;
        },

        sortedUsers() {
            return [...this.users].sort((a, b) => {
                const idA = a.id.toLowerCase();
                const idB = b.id.toLowerCase();
                if (idA === idB) {
                    return 0;
                }

                return idA < idB ? -1 : 1;
            });
        },

        filteredUsers() {
            let filtered = this.sortedUsers;
            if (this.userFilter) {
                filtered = filtered.filter(u => u.id.indexOf(this.userFilter) > -1);
            }

            if (this.roleFilter) {
                filtered = filtered.filter(u => u.role.name && u.role.name.toLowerCase().indexOf(this.roleFilter) > -1);
            }

            if (this.brandFilter) {
                filtered = filtered.filter(u => {
                    const matchedClient =
                        u.scope.client && u.scope.client.some(c => c.name.toLowerCase().indexOf(this.brandFilter) > -1);

                    if (matchedClient) {
                        return true;
                    }

                    const matchedCampaign =
                        u.scope.campaign &&
                        u.scope.campaign.some(c => c.name.toLowerCase().indexOf(this.brandFilter) > -1);

                    if (matchedCampaign) {
                        return true;
                    }

                    const matchedCampaignOnlyClient =
                        u.scope.campaignOnlyClient &&
                        u.scope.campaignOnlyClient.some(c => c.name.toLowerCase().indexOf(this.brandFilter) > -1);

                    if (matchedCampaignOnlyClient) {
                        return true;
                    }

                    return false;
                });
            }

            return filtered;
        },

        tableData() {
            return [{ search: true }].concat(this.pagedItems);
        },

        userId() {
            return this.$store.state.auth.me && this.$store.state.auth.me.id;
        }
    },

    created() {
        this.pageSize = 6;
    },

    methods: {
        getClientsString(user) {
            return Object.values(this.getScopeClients(user))
                .map(c => c.name)
                .join(", ");
        },

        getScopeClients(user) {
            let scopeClients = {};
            let scopeCampaign = {};

            if (user.scope.client) {
                scopeClients = user.scope.client.reduce((acc, cur) => {
                    if (this.$auth.hasScope({ clientId: cur._id })) {
                        return Object.assign(acc, { [cur._id]: cur });
                    }

                    return acc;
                }, {});
            }

            if (user.scope.campaign) {
                scopeCampaign = user.scope.campaign.reduce((acc, cur) => {
                    const clnt = user.scope.campaignOnlyClient.find(cl => cl.id === cur.clientId);

                    if (
                        clnt !== undefined &&
                        (this.$auth.hasScope({ clientId: clnt._id }) ||
                            this.$auth.hasScope({
                                clientId: clnt._id,
                                campaignId: cur._id
                            }))
                    ) {
                        return Object.assign(acc, { [clnt._id]: clnt });
                    }

                    return acc;
                }, {});
            }

            return {
                ...scopeClients,
                ...scopeCampaign
            };
        },

        getUserTableData(user) {
            const scopedClients = this.getScopeClients(user);
            return Object.keys(scopedClients).map(clientId => scopedClients[clientId]);
        },

        hideModal() {
            this.isModalVisible = false;
        },

        isRoleDisabled({ id }) {
            if (this.isSuperAdmin()) {
                return false;
            }

            return this.userId === id;
        },

        isSuperAdmin() {
            return this.$store.getters[AuthGetters.isSuperAdmin];
        },

        onBrandFilterChange(val) {
            this.brandFilter = val.toLowerCase();
            this.updatePageNumber(1);
        },

        onRoleFilterChange(val) {
            this.roleFilter = val.toLowerCase();
            this.updatePageNumber(1);
        },

        onUserFilterChange(val) {
            this.userFilter = val.toLowerCase();
            this.updatePageNumber(1);
        },

        onUserDeleted() {
            this.$emit("userDeleted");
        },

        onRoleChange(userId, roleId) {
            this.$emit("roleChanged", {
                roleId,
                userId
            });
        },

        rowClassName(row) {
            return row.search ? "search-row" : "";
        },

        showModal(userData) {
            this.modalUserData = userData;
            this.isModalVisible = true;
        },

        userExists(userId) {
            return !!this.users.find(m => m.id === userId);
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.user-list {
    margin-top: 28px;

    &-table {
        .ivu-table th {
        }

        .ivu-table td {
            vertical-align: top;
            padding-top: 16px;
            padding-bottom: 12px;

            .ivu-table {
                background-color: inherit;

                td.bordered-column > .ivu-table-cell {
                    background-color: $white;
                }

                td {
                    vertical-align: middle;
                    padding-top: 0;
                    padding-bottom: 0;
                    background-color: inherit;
                }

                .ivu-icon.ivu-select-arrow {
                    top: 12px;
                }
            }
        }

        .ivu-table .search-row td {
            padding-top: 6px;
            padding-bottom: 6px;
            height: 36px;
        }

        .ivu-select-single .ivu-select-selection {
            height: 34px;
        }

        .add-user-name {
            width: 300px;
            padding: 17px 0;
        }

        .new-user-row {
            td {
                background-color: $grey1;
            }
        }

        &-more {
            color: $blue;

            &:hover {
                background-color: inherit;
            }
        }

        &-confirm {
            margin-left: 10px;
        }

        &_empty-wrapper {
            .user-permission-modal-table-wrapper {
                pointer-events: none;
            }
        }

        &_action-btn {
            display: none;
        }

        .ivu-table-row:hover &_action-btn {
            display: block;
        }

        &__pages {
            float: right;
            margin-top: 16px;
        }
    }

    &-add {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 16px;
    }
}
</style>
