<template>
    <div class="tag-component-filter-select">
        <div class="tag-component-filter-select__nav tag-component-filter-select__prev" @click="move(-1)">&lt;</div>
        <div class="tag-component-filter-select__value">
            {{ displayName }}
        </div>
        <div class="tag-component-filter-select__nav tag-component-filter-select__next" @click="move(1)">&gt;</div>
    </div>
</template>
<script>
export default {
    name: "TagComponentFilterSelect",
    props: {
        value: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            currentIndex: 0
        };
    },

    computed: {
        displayName() {
            return this.tagFilters[this.currentIndex].name;
        },

        selectedDynamicBreakdowns() {
            return this.$store.state.creativeInsights.selectedDynamicBreakdowns;
        },

        tagFilters() {
            const filters = Object.keys(this.selectedDynamicBreakdowns).reduce((acc, filterGroup) => {
                this.selectedDynamicBreakdowns[filterGroup].forEach(filterName => {
                    acc.push({ name: `${filterName}`, filter: { [filterGroup]: [filterName] } });
                });

                return acc;
            }, []);

            return [{ name: "All", filter: this.selectedDynamicBreakdowns }, ...filters];
        }
    },

    watch: {
        selectedDynamicBreakdowns() {
            this.currentIndex = 0;
        },

        currentIndex(v) {
            this.$emit("input", this.tagFilters[v]);
        }
    },

    mounted() {
        // force emitting the initial state
        this.currentIndex = 0;
    },

    methods: {
        move(dir) {
            if (this.currentIndex + dir < 0) {
                this.currentIndex = this.tagFilters.length - 1;
                return;
            }

            if (this.currentIndex + dir === this.tagFilters.length) {
                this.currentIndex = 0;
                return;
            }
            this.currentIndex += dir;
        }
    }
};
</script>
<style lang="scss">
@import "@/../sass/_variables.scss";

.tag-component-filter-select {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__nav {
        cursor: pointer;
        margin-left: $spacing-small;
        margin-right: $spacing-small;
    }
}
</style>
