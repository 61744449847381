<template>
    <div />
</template>

<script>
import { postFetcher } from "@/components/Reporting/data/utils";
import { Bucket } from "@/components/Reporting/data/constants";
import { CreativeInsightsGetters } from "@/store/modules/creativeInsights";

export default {
    name: "TagsStatsDataProvider",

    props: {
        tags: {
            type: Array,
            default() {
                return [];
            }
        }
    },

    data() {
        return {
            isLoading: false
        };
    },

    computed: {
        apiDiscardThresholds() {
            return this.$store.getters[CreativeInsightsGetters.ApiDiscardThresholds];
        },

        baseOptions() {
            return {
                workerIds: this.workerId.split(",")
            };
        },

        body() {
            return {
                kpiMetric: this.kpi,
                confidenceMetric: this.confidenceMetric,
                storageBucket: Bucket,
                dynamicBreakdowns: this.dynamicBreakdownsFilter,
                ...(this.staticBreakdown && {
                    staticBreakdown: this.staticBreakdown
                }),
                ...this.baseOptions,
                ...(this.apiDiscardThresholds.length && {
                    discardThresholds: this.apiDiscardThresholds
                }),
                tags: this.tags.map(({ tagId, advancedSearch }) => ({ tagId, advancedSearch }))
            };
        },

        confidenceMetric() {
            return this.$store.getters[CreativeInsightsGetters.ConfidenceMetricName];
        },

        dynamicBreakdownsFilter() {
            return this.$store.getters[CreativeInsightsGetters.DynamicBreakdownsFilter];
        },

        kpi() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricName];
        },

        queryEndpoint() {
            return "insights/compute-tags-metric-stats";
        },

        staticBreakdown() {
            return null;
        },

        workerId() {
            return this.$store.state.creativeInsights.workerId;
        }
    },

    watch: {
        apiDiscardThresholds() {
            this.fetch();
        },

        confidenceMetric() {
            this.fetch();
        },

        dynamicBreakdownsFilter() {
            this.fetch();
        },

        kpi() {
            this.fetch();
        },

        staticBreakdown() {
            this.fetch();
        },

        tags() {
            this.fetch();
        },

        workerId() {
            this.fetch();
        }
    },

    mounted() {
        this.fetch();
    },

    methods: {
        async fetch() {
            if (!this.tags.length || !this.workerId) {
                return;
            }
            try {
                this.isLoading = true;
                this.$emit("loading", this.isLoading);
                const response = await postFetcher(this.queryEndpoint, this.body);
                if (response.ok) {
                    const responseJson = await response.json();
                    this.$emit("data", { ...responseJson, requestType: this.insightsType });
                } else {
                    this.$emit("error", { response });
                }
            } catch (e) {
                this.$emit("error", e);
            } finally {
                this.isLoading = false;
                this.$emit("loading", this.isLoading);
            }
        }
    }
};
</script>
