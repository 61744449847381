<template>
    <div class="kpi-matrix" :class="{ 'kpi-matrix--has-data': hasData && !isLoading }">
        <insights-data-provider
            :insights-type="InsightType.Matrix"
            :advanced-search="advancedSearch"
            :sort-by="sortBy"
            @loading="onLoading"
            @data="onData"
            @error="onError"
            @aborted="onAbort"
        />
        <hox-loading-spinner v-if="isLoading" :size="48" />
        <table v-else-if="hasData" :key="advancedSearch" class="kpi-matrix__table">
            <thead>
                <tr>
                    <th class="kpi-matrix__platform-select kpi-matrix__sticky kpi-matrix__sticky--first">
                        <tree-select-breakdown-filter @selected="onBreakdownSelect">
                            <template #dropdown-trigger>
                                <span class="link-like">Select comparisons</span>
                            </template>
                        </tree-select-breakdown-filter>
                    </th>
                    <th
                        v-if="isNoBreakdownsAvailable"
                        :class="getClasses(Breakdown.NoBreakdowns)"
                        class="kpi-matrix__sticky kpi-matrix__sticky--second"
                    >
                        {{ getBreakdownLabel(Breakdown.NoBreakdowns) }}
                    </th>
                    <th v-for="column in selectedBreakdownNames" :key="column" :class="getClasses(column)">
                        <div class="kpi-matrix__header-item-clip" :title="getBreakdownLabel(column)">
                            {{ getBreakdownLabel(column) }}
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody v-if="selectedBreakdownNames.length">
                <tr>
                    <td class="kpi-matrix__sticky kpi-matrix__sticky--first metric-name">{{ kpiMetricName }}</td>
                    <td
                        v-if="isNoBreakdownsAvailable"
                        :class="getClasses(Breakdown.NoBreakdowns)"
                        class="kpi-matrix__sticky kpi-matrix__sticky--second"
                    >
                        {{ getDisplayKpi(Breakdown.NoBreakdowns) }}
                    </td>
                    <td
                        v-for="breakdownName in selectedBreakdownNames"
                        :key="breakdownName + 'kpi'"
                        :class="getClasses(breakdownName)"
                        @click="selectBreakdown(breakdownName)"
                    >
                        {{ getDisplayKpi(breakdownName) }}
                    </td>
                </tr>
                <tr>
                    <td
                        class="capitalize-text kpi-matrix__sticky kpi-matrix__sticky--bottom kpi-matrix__sticky--first metric-name"
                    >
                        {{ confidenceMetricName }}
                    </td>
                    <td
                        v-if="isNoBreakdownsAvailable"
                        :class="getClasses(Breakdown.NoBreakdowns)"
                        class="kpi-matrix__sticky kpi-matrix__sticky--bottom kpi-matrix__sticky--second"
                    >
                        {{ getDisplayConfidence(Breakdown.NoBreakdowns) }}
                    </td>
                    <td
                        v-for="breakdownName in selectedBreakdownNames"
                        :key="breakdownName + 'confidence'"
                        :class="getClasses(breakdownName)"
                    >
                        {{ getDisplayConfidence(breakdownName) }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import InsightsDataProvider from "@/components/CreativeIntelligence/InsightsDataProvider";
import { InsightType } from "@/components/CreativeIntelligence/constants";
import { CreativeInsightsGetters } from "@/store/modules/creativeInsights";
import { numberToShortKM } from "@/utils";
import {
    Breakdown,
    BreakdownBreakdownValueSeparator,
    BreakdownFriendlyNames,
    getBreakdownLabel,
    getMetricsLabel
} from "@/enums/creativeInteligence";
import TreeSelectBreakdownFilter from "@/views/CreativeInteligence/TreeSelectBreakdownFilter";

export default {
    name: "KpiMatrix",
    components: { TreeSelectBreakdownFilter, InsightsDataProvider },

    data() {
        return {
            isLoading: true,
            selectedBreakdowns: {},
            dynamicBreakdowns: []
        };
    },

    computed: {
        advancedSearch() {
            return this.$store.getters[CreativeInsightsGetters.AdvancedSearchValue];
        },

        breakdownsByName() {
            return this.dynamicBreakdowns.reduce((acc, curr) => {
                acc[curr.name] = curr;

                return acc;
            }, {});
        },

        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        confidenceMetricName() {
            return getMetricsLabel(this.$store.getters[CreativeInsightsGetters.ConfidenceMetricName]);
        },

        hasData() {
            return this.dynamicBreakdowns.length;
        },

        isNoBreakdownsAvailable() {
            return false; // todo: get rid of that concept completely
        },

        kpiMetricName() {
            return getMetricsLabel(this.$store.getters[CreativeInsightsGetters.KpiMetricName]);
        },

        selectedBreakdownNames() {
            return Object.keys(this.selectedBreakdowns).reduce((acc, breakdown) => {
                const selectedValues = Object.keys(this.selectedBreakdowns[breakdown]).reduce((accumulator, value) => {
                    if (this.selectedBreakdowns[breakdown][value]) {
                        accumulator.push(`${breakdown}${BreakdownBreakdownValueSeparator}${value}`);
                    }

                    return accumulator;
                }, []);

                acc.push(...selectedValues);

                return acc;
            }, []);
        },

        sortBy() {
            return this.$store.state.creativeInsights.sortBy;
        }
    },

    created() {
        this.InsightType = InsightType;
        this.BreakdownFriendlyNames = BreakdownFriendlyNames;
        this.Breakdown = Breakdown;

        this.initSelectedDynamicBreakdowns();
    },

    methods: {
        getClasses(breakdownName) {
            if (this.breakdown === breakdownName) {
                if (this.isNoBreakdownsAvailable) {
                    return "kpi-matrix__table-item selected kpi-matrix__sticky kpi-matrix__sticky--third";
                }

                return "kpi-matrix__table-item selected kpi-matrix__sticky kpi-matrix__sticky--second";
            }

            return "kpi-matrix__table-item";
        },

        getDisplayKpi(breakdown) {
            if (!this.breakdownsByName[breakdown]) {
                console.log("breakdown not found", breakdown);

                return "0";
            }
            const { avg } = this.breakdownsByName[breakdown].kpiMetricStats;

            return avg.toFixed(3);
        },

        getDisplayConfidence(breakdown) {
            if (!this.breakdownsByName[breakdown]) {
                console.log("breakdown not found", breakdown);

                return "0";
            }
            const { sum } = this.breakdownsByName[breakdown].confidenceMetricStats;
            if (sum < 1000) {
                return sum.toFixed(2);
            }
            if (sum < 1) {
                return sum.toFixed(3);
            }
            return numberToShortKM(sum);
        },

        getBreakdownLabel(label) {
            return getBreakdownLabel(label);
        },

        getMetricLabel(label) {
            return getMetricsLabel(label);
        },

        initSelectedDynamicBreakdowns() {
            const selectedBreakdowns = this.$store.state.creativeInsights.selectedDynamicBreakdowns;
            this.selectedBreakdowns = Object.keys(selectedBreakdowns).reduce((acc, curr) => {
                acc[curr] = selectedBreakdowns[curr].reduce((accumulator, filter) => {
                    accumulator[filter] = true;

                    return accumulator;
                }, {});

                return acc;
            }, {});
        },

        onLoading(loading) {
            this.isLoading = loading;
            this.$emit("loading", this.isLoading);
        },

        onAbort() {
            this.$emit("data", null);
        },

        onData(response) {
            this.dynamicBreakdowns = response.data;
        },

        onError(e) {
            console.error(`Error getting data for (${this.advancedSearch})`);
            console.error(e);

            this.$emit("data", null);
            this.$emit("error");
        },

        onBreakdownSelect(selected) {
            this.selectedBreakdowns = selected;
        }
    }
};
</script>
<style lang="scss">
@import "../../../../sass/_variables.scss";

$selected-bg: #f5f7f9;
$border-color: #ced4d9;
$selected-border-color: #0f8ee9;
$border-style: 1px $border-color solid;
$box-shadow:
    inset 1px 1px 0 $border-color,
    inset -1px 0px 0 $border-color;
$box-shadow-td:
    inset 0px 1px 0 $border-color,
    inset 1px 0px 0 $border-color;
$box-shadow-selected:
    inset 0px 0px 0 transparent,
    inset 0px 0px 0 $border-color;

.kpi-matrix {
    margin: $spacing 0;
    max-width: calc(100vw - 396px);
    overflow: auto;
    position: relative;

    &--has-data {
        overflow: scroll hidden;
    }

    &__sticky {
        position: sticky;
        left: 0;
        background-color: #f5f7f9;
        border: 0 none;

        &--first {
            left: 1px;
            text-overflow: ellipsis;
            overflow: hidden;
            &:after {
                content: "";
                position: absolute;
                left: -1px;
                bottom: 0;
                height: 100%;
                border-left: 1px solid #f5f7f9;
            }
        }

        &--second,
        &--second.kpi-matrix__sticky--third {
            left: 137px;
        }

        &--third {
            left: 274px;
        }
    }

    &__table {
        width: 100%;
        border-spacing: 0;
        margin: 0 0 $spacing 0;
        border: none;
        border-collapse: collapse;
        table-layout: fixed;

        td,
        th {
            padding: $spacing-smaller $spacing;
            text-align: center;
            height: 64px;
            width: 137px;

            &.selected {
                color: $selected-border-color;
            }
        }
        thead {
            th {
                height: 44px;
                font-weight: normal;
                font-size: 12px;

                .kpi-matrix__header-item-clip {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; /* number of lines to show */
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                &.kpi-matrix__platform-select {
                    text-align: left;
                }

                &.selected {
                    box-shadow:
                        inset 0px -2px 0 #0f8ee9,
                        inset 0px 0px 0 #0f8ee9;
                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        height: 2px;
                        border-left: 2px solid $selected-border-color;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        right: -1px;
                        bottom: 0;
                        height: 2px;
                        border-right: 2px solid $selected-border-color;
                    }
                }
            }
        }

        tbody {
            background-color: $white;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);

            td {
                box-shadow: $box-shadow-td;
                background-color: $white;

                &.selected {
                    background-color: #fafbfc;
                }

                &.kpi-matrix__sticky {
                    border: 0 none !important;

                    &--first {
                        box-shadow: $box-shadow;
                    }

                    &--second {
                        &:after {
                            content: "";
                            position: absolute;
                            right: -1px;
                            bottom: 0;
                            height: 100%;
                            border-right: $border-style;
                        }
                    }

                    &.selected {
                        box-shadow: none;
                        &:before {
                            content: "";
                            position: absolute;
                            left: 0px;
                            bottom: 0;
                            height: 100%;
                            border-left: $border-style;
                        }
                        &:after {
                            content: "";
                            position: absolute;
                            right: -1px;
                            bottom: 0;
                            height: 100%;
                            border-right: $border-style;
                        }
                    }

                    &.selected {
                        box-shadow: $box-shadow-selected;

                        &.kpi-matrix__sticky--bottom {
                            box-shadow:
                                inset 0 0 0 $selected-bg,
                                inset 0 0 0 $border-color;
                        }
                    }
                }
            }
        }

        .metric-name {
            font-weight: bold;
            background-color: #fafbfc;
        }
    }
}
</style>
