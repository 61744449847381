import assert from "assert";
import HoxtonError from "./HoxtonError";
import generateKeyValueObject from "../utils/generateKeyValueObject";
import InvalidArgumentError from "./InvalidArgumentError";

const types = {
    INVALID_TOKEN: { code: "INVALID_TOKEN", message: "Invalid Token" },
    INVALID_USER: { code: "INVALID_USER", message: "Invalid User" },
    FORBIDDEN: { code: 403, message: "Forbidden" },
    UNAUTHORIZED: { code: 401, message: "Unauthorized" }
};

// Build an array of constant strings that we can use to indicate different types when creating the error
export const AuthenticationErrorTypes = generateKeyValueObject(types);

export default class AuthenticationError extends HoxtonError {
    constructor(errorId) {
        const type = types[errorId];
        assert(type, new InvalidArgumentError(`${errorId} is not a valid AuthenticationError`));
        super(type.message, type.code);
    }
}
