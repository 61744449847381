<template>
    <div
        class="expandable-text-input"
        :class="{
            'expandable-text-input__expanded': isExpanded,
            'expandable-text-input--locked': isLocked
        }"
    >
        <template v-if="isExpanded">
            <div class="expandable-text-input__expanded-header">
                <span>{{ label }}</span>
                <div>
                    <Icon type="md-checkmark" @click="collapse" />
                    <Icon type="md-close" @click="undoExpandable" />
                </div>
            </div>
            <slot
                name="editor"
                :value="updatedTextValue"
                :placeholder="placeholder"
                :on-change="onExpandedChange"
                :on-enter="onEnter"
            >
                <div class="expandable-text-input__expanded_text_edit">
                    <Input
                        ref="expandableTextInput"
                        :value="updatedTextValue"
                        :type="type"
                        :autosize="{ minRows: 1, maxRows: 10 }"
                        :placeholder="placeholder"
                        @on-change="onExpandedChange"
                        @on-keydown.enter.exact="onEnter"
                    />
                    <Icon
                        v-if="clientHasGenerativeAiFeaturesEnabled"
                        type="md-color-wand"
                        @click.prevent="onShowGenerateCtaModal"
                    ></Icon>
                </div>
            </slot>
        </template>
        <template v-else>
            <template v-if="lockable">
                <visible-row
                    :can-change-visibility="canChangeVisibility"
                    :visible="isVisible"
                    @updateSettings="onUpdateSettings"
                >
                    <lockable-row :locked="isLocked" @unlock="onUnlock" />
                    <labelled-value
                        :value="showPlaceholder ? placeholder : value"
                        :label="label"
                        :label-color="labelColor"
                        :class="{ 'is-placeholder': showPlaceholder }"
                        @click="onExpand"
                    >
                        <template #icons>
                            <Icon type="md-create" @click.prevent="onExpand" />
                            <slot name="icons" />
                        </template>
                    </labelled-value>
                </visible-row>
            </template>
            <template v-else>
                <visible-row
                    :can-change-visibility="canChangeVisibility"
                    :visible="isVisible"
                    @updateSettings="onUpdateSettings"
                >
                    <labelled-value
                        :value="showPlaceholder ? placeholder : value"
                        :label="label"
                        :label-color="labelColor"
                        :class="{ 'is-placeholder': showPlaceholder }"
                        @click="onExpand"
                    >
                        <template #icons>
                            <Icon type="md-create" @click.prevent="onExpand" />
                            <Icon
                                v-if="clientHasGenerativeAiFeaturesEnabled"
                                type="md-color-wand"
                                @click.stop="onShowGenerateCtaModal"
                            />
                            <slot name="icons" />
                        </template>
                    </labelled-value>
                </visible-row>
            </template>
        </template>

        <hox-modal v-if="generateCtaModalIsVisible" class="generate-cta-modal" @close="onShowGenerateCtaModal">
            <template #header>Generate copy with AI</template>
            <div class="generate-cta-modal__wrap">
                <div class="generate-cta-modal__content">
                    <div class="generate-cta-modal__content-head">
                        <h4>Original copy</h4>
                        <span>{{ prompt }}</span>
                    </div>
                    <alternative-headlines-results
                        v-for="searchOptions in searches"
                        :key="searchOptions.searchId"
                        :search-options="searchOptions"
                        @selected="onSelect"
                    />
                </div>
            </div>
        </hox-modal>
    </div>
</template>

<script>
import LabelledValue from "@/components/Campaign/LabelledValue";
import LockableRow from "@/components/Campaign/LockableRow";
import VisibleRow from "@/components/Campaign/VisibleRow";
import { OverwriteScope } from "@/enums/overwrites";
import { deepClone } from "@/utils";
import { EditableType } from "@/enums/editables";
import { ModelDefaultValues } from "@/enums/imagine";
import AlternativeHeadlinesResults from "@/components/Campaign/AlternativeHeadlinesResults";

const model = "GPT3CTA";

export default {
    name: "ExpandableTextInput",
    components: {
        LockableRow,
        VisibleRow,
        LabelledValue,
        AlternativeHeadlinesResults
    },

    props: {
        canChangeVisibility: {
            type: Boolean,
            default: true
        },

        label: {
            type: String,
            default: ""
        },

        labelColor: {
            type: String,
            default: "default"
        },

        isExpanded: {
            type: Boolean,
            default: false
        },

        value: {
            type: [String, Number]
        },

        placeholder: {
            type: String
        },

        lockable: {
            type: Boolean,
            default: true
        },

        overwrite: {
            type: Object
        },

        scope: {
            type: String,
            validator(value) {
                return [OverwriteScope.EditableGroup, OverwriteScope.EditableGroupTemplate].includes(value);
            }
        },

        settings: {
            type: Object
        },

        type: {
            type: String,
            default: "text"
        }
    },

    data() {
        return {
            baseOverwrite: null,
            expandedValue: "",
            generateCtaModalIsVisible: false,
            prompt: "",
            query: "",
            searchCount: 0,
            searches: [],
            updatedTextValue: ""
        };
    },

    computed: {
        clientHasGenerativeAiFeaturesEnabled() {
            if (this.$store.state.ui.currentClient) {
                return this.$store.state.ui.currentClient.imageProcessingEnabled;
            }

            return false;
        },

        isLocked() {
            return !!(
                this.overwrite &&
                this.overwrite.valueFromScope === OverwriteScope.EditableGroupTemplate &&
                (this.overwrite.mediaItem || this.overwrite.value || this.overwrite.value === "")
            );
        },

        isVisible() {
            if (!this.settings) {
                return true;
            }

            return this.settings.visible !== false;
        },

        showPlaceholder() {
            return !this.value && !this.lockable && this.placeholder;
        }
    },

    watch: {
        value: {
            handler(val) {
                this.updatedTextValue = val;
            },
            immediate: true
        }
    },

    methods: {
        onUnlock() {
            this.$emit("removeOverwrite", this.overwrite._id);
        },

        onUpdateSettings(payload) {
            let currentLevelSettings = {};

            if (this.overwrite.settingsFromScope && this.overwrite.settingsFromScope[this.scope]) {
                currentLevelSettings = this.overwrite.settingsFromScope[this.scope];
            }

            this.$emit("updateSettings", currentLevelSettings, payload);
        },

        undoExpandable() {
            if (
                this.baseOverwrite &&
                this.baseOverwrite.value !== null &&
                this.baseOverwrite.scope === this.overwrite.scope
            ) {
                if (this.baseOverwrite.value !== this.overwrite.value) {
                    // the change has been made on same overwrite level so we can simply update value
                    this.expandedValue = this.baseOverwrite.value;
                    this.$emit("input", this.expandedValue);
                }
            } else {
                // we need to delete the template level overwrite in order to restore the value
                this.$emit("restore");
            }

            this.collapse();
        },

        collapse() {
            this.$emit("collapse");
        },

        async onExpand() {
            this.baseOverwrite = deepClone(this.overwrite);
            this.$emit("expand");
            /*
                The iView input component `autofocus` prop only seems to work on
                first show of the component so we manually focus it when we
                toggle it.
            */
            await this.$nextTick();
            if (this.$refs.expandableTextInput) {
                this.$refs.expandableTextInput.focus();
            }
        },

        onEnter(evt) {
            if (this.type !== EditableType.Textarea) {
                evt.preventDefault();
                this.collapse();
            }
        },

        onExpandedChange(value) {
            this.expandedValue = value;
            if (typeof value === "object") {
                this.expandedValue = value.target.value;
            }

            this.$emit("input", this.expandedValue);
        },

        onShowGenerateCtaModal() {
            this.searches = [];
            this.prompt = this.value.trim();
            if (!this.prompt) {
                this.$snackbar.error("Please enter an initial CTA");
                return;
            }
            this.generateCtaModalIsVisible = !this.generateCtaModalIsVisible;
            if (this.generateCtaModalIsVisible) {
                this.onSearch();
            }
        },

        onSearch(text = this.prompt) {
            this.searchCount += 1;
            // todo mak filling in the correct fields more intelligent
            const { prompt } = this;
            const newSearch = {
                searchId: this.searchCount,
                ...ModelDefaultValues[model],
                text,
                prompt,
                messages: [
                    {
                        role: "system",
                        content: text
                    }
                ],
                model
            };

            this.searches.unshift(newSearch);
        },

        onSelect(cta) {
            this.onExpandedChange(cta);
            this.updatedTextValue = cta;
            this.generateCtaModalIsVisible = false;
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.expandable-text-input {
    &--locked {
        color: $cmp-dark-disabled-color;
    }

    &__expanded {
        padding: 0 10px 8px;
        border-left: 4px solid $cmp-dark-tag-primary-border;

        &-header {
            align-items: flex-end;
            color: $cmp-dark-font-color;
            display: flex;
            font-weight: bold;
            justify-content: space-between;
            padding: 4px 0;
            width: 100%;

            i {
                cursor: pointer;

                &:hover {
                    color: $cmp-dark-active;
                }
            }
        }
    }
    &__expanded_text_edit {
        display: flex;
        align-items: center;
        i {
            margin-left: 10px;
        }
    }

    .is-placeholder .labelled-value__text--value {
        opacity: 0.5;
    }
}
.generate-cta-modal {
    &__wrap {
        display: flex;
    }
    &__sidebar {
        width: 30%;
    }

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        &-head {
            margin-bottom: 40px;
            span {
                font-size: 18px;
            }
        }
    }
    &__prompt {
        display: flex;
        margin: 20px 0;
        .ivu-input {
            padding: 8px 10px;
        }
        .ivu-btn {
            margin-left: 10px;
        }
    }
}
</style>
