<template>
    <div class="resource-group-input">
        <hox-input
            :value="formResourceGroupIds"
            data-testid="create-campaign__resource-id"
            @input="onChangeFormResourceGroupIds"
        >
            <template #label>Resource Group ID</template>
            <template #error>
                <p v-for="error in validationErrors" :key="error">
                    {{ error }}
                </p>
            </template>
        </hox-input>
        <div>Enriched Resources</div>
        <div v-if="showActions" class="resource-group-input__enriched-resources">
            <Button
                :disabled="loading"
                class="resource-group-input__enriched-resources-btn"
                data-testid="enriched-resources__copy-button"
                @click="copyResources"
            >
                Copy
            </Button>
            <Button
                v-if="hasNavigatorClipboard"
                :disabled="loading"
                class="resource-group-input__enriched-resources-btn"
                data-testid="enriched-resources__paste-button"
                @click="pasteResources"
            >
                Paste
            </Button>
            <div v-else>
                <span>Paste (Ctrl+V) the data into the below textarea</span>
                <Input
                    ref="pasteTextarea"
                    type="textarea"
                    class="resource-group-textarea"
                    data-testid="enriched-resources__textarea"
                    @on-change="pasteTextareaResources"
                ></Input>
            </div>
        </div>
        <div class="resource-group-input__enriched-resources">
            {{ enrichedStatus }}
        </div>
    </div>
</template>

<script>
import { getResourcesByGroupIds, addResources } from "@/components/Reporting/data/resources";
import copyToClipboard from "@/utils/copyToClipboard";

const clipboardMarker = "##enriched-resources##";
export default {
    props: {
        value: { type: String }
    },
    data() {
        return {
            formResourceGroupIds: "",
            validationErrors: [],
            showActions: true,
            enrichedStatus: "",
            loading: false
        };
    },

    computed: {
        hasNavigatorClipboard() {
            return navigator.clipboard;
        }
    },

    watch: {
        value: {
            handler(v) {
                this.formResourceGroupIds = v;
            },
            immediate: true
        }
    },

    methods: {
        onChangeFormResourceGroupIds(value) {
            this.$emit("input", value);
        },

        async copyResources() {
            this.validationErrors = [];
            this.loading = true;
            const response = await getResourcesByGroupIds(this.value);
            this.loading = false;
            if (response.ok) {
                const jsonResp = await response.json();
                const { resources } = jsonResp.data;
                const resourcesText = `${clipboardMarker}${JSON.stringify(resources)}`;
                if (!this.hasNavigatorClipboard) {
                    copyToClipboard(resourcesText);
                    this.showActions = false;
                    this.enrichedStatus = "Copied. Go and paste it to the destination campaign";
                    return;
                }
                await navigator.clipboard.writeText(resourcesText);
                this.showActions = false;
                this.enrichedStatus = "Copied. Go and paste it to the destination campaign";
            } else {
                const { message = "unknown error" } = (await response.json()) || {};
                this.validationErrors.push(message);
            }
        },

        pasteTextareaResources() {
            const fromClipboard = this.$refs.pasteTextarea.currentValue;
            this.parsePastedResources(fromClipboard);
        },

        async pasteResources() {
            if (!this.hasNavigatorClipboard) {
                console.error("Clipboard is not available");
                return;
            }
            const fromClipboard = await navigator.clipboard.readText();
            this.parsePastedResources(fromClipboard);
        },

        async parsePastedResources(fromClipboard) {
            const marker = clipboardMarker;
            if (!fromClipboard.startsWith(marker)) {
                this.enrichedStatus =
                    "Not copied. Go to the source campaign and press Copy. Then, come back here and press Paste";
                return;
            }

            const resources = JSON.parse(fromClipboard.replace(marker, ""));
            if (resources.length > 0) {
                const resourceGroupIds = resources.map(({ resourceGroupId }) => resourceGroupId);
                this.formResourceGroupIds = resourceGroupIds.join(",");
                this.$emit("input", this.formResourceGroupIds);
                this.loading = true;
                const response = await addResources(resources);
                this.loading = false;
                if (response.ok) {
                    this.showActions = false;
                    this.enrichedStatus = "Enriched resources added. Just press save and you're ready!";
                } else {
                    const { message = "unknown error" } = (await response.json()) || {};
                    this.validationErrors.push(message);
                }
            }
        }
    }
};
</script>
<style lang="scss">
@import "@/../sass/_variables.scss";
.resource-group {
    &-input__enriched-resources {
        margin: $spacing-small;
    }

    &-input__enriched-resources-btn {
        margin-right: $spacing-small;
    }

    &-textarea {
        width: 100%;
    }
}
</style>
