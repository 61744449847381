<template>
    <div class="editable-group-batch-upload">
        <upload-button :accept="acceptedMimetype" :before-upload="onBeforeUpload"></upload-button>
    </div>
</template>
<script>
import ExcelJS from "exceljs";
import bus from "@/bus";
import mimetype from "@/enums/mimetype";
import UploadButton from "@/components/Campaign/UploadButton";

export default {
    name: "XlsUploadButton",
    components: { UploadButton },
    created() {
        this.acceptedMimetype = mimetype.XSLX;
    },

    methods: {
        onBeforeUpload(file) {
            if (file.type === mimetype.XSLX) {
                this.readExcel(file);
                return false;
            }

            this.$emit("error", {
                type: "filetype",
                message: "Excel(.xlsx) file"
            });
            return false;
        },

        showUploadPercentage(e) {
            bus.$emit("percentLoadEvent", e.percent);
        },

        onError(e) {
            this.$emit("error", {
                type: "upload",
                message: e.message || ""
            });
        },

        readExcel(file) {
            const wb = new ExcelJS.Workbook();
            const excelReader = new FileReader();
            excelReader.readAsArrayBuffer(file);

            excelReader.onload = async () => {
                const buffer = excelReader.result;
                const importData = { sheets: [] };
                const workbook = await wb.xlsx.load(buffer);
                workbook.eachSheet(sheet => {
                    const sheetData = [];
                    const sheetName = sheet.name;
                    sheet.eachRow((row, rowNumber) => {
                        // ignore info row
                        if (rowNumber === 1) {
                            return;
                        }

                        // Iterate over all cells in a row (including empty cells).
                        const values = [];
                        row.eachCell({ includeEmpty: true }, cell => {
                            values.push(cell.value !== null ? cell.value.toString() : null);
                        });

                        sheetData.push(values);
                    });

                    importData.sheets.push(sheetName);
                    importData[sheetName] = {
                        sheetData
                    };
                });

                this.$emit("load", importData);
            };
        }
    }
};
</script>
