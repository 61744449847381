<template>
    <div :class="classes">
        <Drawer :transfer="false" :value="!!isOpen" width="90" @on-close="close">
            <template #header>
                <!--
                  This empty div is used to trick the drawer component into not hiding the
                  header slot if we are loading a deliverable.

                  If we didn't do this then the header would not be shown when isLoadingDeliverable
                  is switched back to false.
                -->
                <div>
                    <div v-if="drawerHeaderIsVisible" class="hox-drawer-header reporting-drawer-header">
                        <div v-if="currentCreative" class="reporting-drawer-header__label">
                            {{ currentCreative.reportingLabel }}
                        </div>
                    </div>
                </div>
            </template>
            <template #close>
                <div class="hox-drawer__close-icon-container">
                    <Icon size="30" type="ios-close" @click="close" />
                </div>
            </template>
            <div v-if="creatives.length" class="hox-drawer__container">
                <div class="hox-drawer__content">
                    <div class="hox-drawer__content-container">
                        <div class="hox-drawer__header reporting-drawer__header">
                            <div class="reporting-drawer__header-select"></div>
                            <Page
                                :current="pageNumber"
                                :page-size="1"
                                :total="totalItems"
                                @on-change="setPageNumber"
                            ></Page>
                        </div>

                        <tab-carousel
                            v-model="pageNumber"
                            class="hox-drawer__body reporting-drawer__container"
                            :show-arrows="isBulk"
                            :total-pages="totalItems"
                            padding="base"
                        >
                            <timeline-analysis v-if="currentCreative" :key="pageNumber" :creative="currentCreative" />
                        </tab-carousel>
                    </div>
                </div>
            </div>
            <hox-loading-spinner v-else></hox-loading-spinner>
        </Drawer>
    </div>
</template>

<script>
import TabCarousel from "@/components/TabCarousel";
import TimelineAnalysis from "@/components/CreativeIntelligence/TimelineAnalisys";

export default {
    name: "CreativeIntelligenceDeliverableDrawer",
    components: {
        TimelineAnalysis,
        TabCarousel
    },

    props: {
        creatives: {
            type: Array
        },

        isOpen: {
            type: [Boolean, Number],
            default: false
        }
    },

    data() {
        return {
            pageNumber: 1
        };
    },

    computed: {
        breakdown() {
            return this.$store.state.creativeInsights.selectedBreakdown;
        },

        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        classes() {
            const classes = ["hox-drawer", "reporting-drawer"];
            /*
             As we can't simply hide/show the header slot, we are going to
             control the headers visibility using CSS.
            */
            if (!this.drawerHeaderIsVisible) {
                classes.push("hox-drawer--hide-header");
            }
            return classes;
        },

        currentCreative() {
            if (!this.creatives.length) {
                return null;
            }

            return this.creatives[this.pageNumber - 1];
        },

        drawerHeaderIsVisible() {
            return true;
        },

        isBulk() {
            return this.creatives.length > 1;
        },

        totalItems() {
            return this.creatives.length;
        }
    },

    methods: {
        close() {
            this.pageNumber = 1;
            this.$emit("closed");
        },

        setPageNumber(number) {
            this.pageNumber = number;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
.reporting-drawer {
    &__body {
        height: 100%;
        flex: 1;
        border: 1px solid #0d3349;
    }

    &__container {
        transform: translateZ(0);
        overflow: hidden;
    }

    &__header {
        padding: $spacing $spacing-large;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-select {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 300px;
        }

        &-breakdown {
            margin-right: $spacing;
        }
    }
}
</style>
