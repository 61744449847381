<template>
    <insight-preview-wrapper
        :type="InsightType.SizeAnalysis"
        :filters="matchingContentFilters"
        :response-path="responsePath"
        :request-options="requestOptions"
        @data="onData"
    >
        <template #name>{{ title }}</template>
        <template v-if="best" #headline>{{ headerText }}</template>

        <div v-if="data" class="size-analysis">
            <div v-for="bucket in buckets" :key="bucket" class="size-analysis__item">
                <insights-creative-preview-carousel
                    v-if="hasBucketData(bucket)"
                    class="creative-preview-insights"
                    :bounding-boxes="true"
                    :bucket-config="getBucketConfig(bucket)"
                    :init-creatives="getDeliverables(bucket)"
                    :request-options="requestOptions"
                    :seek-to-attribute="true"
                    :stats="getStats(bucket)"
                >
                    <template #name>{{ bucket }}</template>
                </insights-creative-preview-carousel>
                <insights-creative-no-data-preview v-else>
                    <template #name>{{ bucket }}</template>
                </insights-creative-no-data-preview>
            </div>
        </div>
        <template #chartPreview>
            <insights-chart-preview :data="data" :title="title" />
        </template>
    </insight-preview-wrapper>
</template>
<script>
import { InsightType, InsightTypeToServiceName } from "@/components/CreativeIntelligence/constants";
import InsightPreviewWrapper from "@/components/CreativeIntelligence/InsightPreviews/InsightPreviewWrapper";
import InsightsCreativeNoDataPreview from "@/components/CreativeIntelligence/InsightPreviews/InsightsCreativeNoDataPreview";
import InsightsChartPreview from "@/components/CreativeIntelligence/InsightPreviews/InsightsChartPreview";
import { CreativeInsightsContentFilter } from "@/enums/creativeInteligence";
import InsightsCreativePreviewCarousel from "@/components/CreativeIntelligence/InsightPreviews/InsightsCreativePreviewCarousel";
import { CreativeInsightsGetters } from "@/store/modules/creativeInsights";
import { getMetricStat } from "@/utils/creativeInteligence";

const groups = [
    { name: "Small", maxValue: "0.1" },
    { name: "Medium", maxValue: "0.4" },
    { name: "Large", maxValue: "0.6" }
];

export default {
    name: "SizeAnalysis",
    components: {
        InsightsCreativePreviewCarousel,
        InsightsChartPreview,
        InsightsCreativeNoDataPreview,
        InsightPreviewWrapper
    },

    data() {
        return {
            data: null,
            responsePath: "groups",
            title: "Size Analysis"
        };
    },

    computed: {
        best() {
            if (!this.data || !Object.keys(this.data).length) {
                return null;
            }

            const labelsSortedByKpi = Object.keys(this.data).sort((labelA, labelB) => {
                const objA = this.data[labelA].summaryStats;
                const objB = this.data[labelB].summaryStats;
                const kpiA = objA ? objA.kpiMetric[this.kpiMetricStatName] : 0;
                const kpiB = objB ? objB.kpiMetric[this.kpiMetricStatName] : 0;

                return kpiB - kpiA;
            });

            return labelsSortedByKpi[0];
        },

        kpiMetricName() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricName];
        },

        kpiMetricStatName() {
            return getMetricStat(this.kpiMetricName);
        },

        buckets() {
            return groups.map(({ name }) => name);
        },

        displayTemplates() {
            if (!this.selectedTagIds.length) {
                return null;
            }

            return this.tagsById[this.selectedTagIds[0]].displayTemplates;
        },

        showsDiversityTemplate() {
            return this.displayTemplates && this.displayTemplates.includes("diversity");
        },

        headerText() {
            if (this.showsDiversityTemplate) {
                return `When People are shown at a ${this.best} size, it performs better.`;
            }

            return `When ${this.resultSource} shown at a ${this.best} size, it performs better.`;
        },

        requestOptions() {
            return {
                groups
            };
        },

        resultSource() {
            return this.selectedTagIds.length
                ? `${this.tagsById[this.selectedTagIds[0]].name} is`
                : "ads matching the query are";
        },

        selectedTagIds() {
            return this.$store.state.ciTags.selectedTagIds;
        },

        tagsById() {
            return this.$store.state.ciTags.tagsById;
        }
    },

    created() {
        this.matchingContentFilters = [CreativeInsightsContentFilter.Position];
        this.InsightType = InsightType;
    },

    methods: {
        getBucketConfig(bucket) {
            return {
                ...this.data[bucket].pagination,
                bucketFilters: {
                    ...this.getBucketFilterForBucket(bucket),
                    type: InsightTypeToServiceName[InsightType.SizeAnalysis]
                }
            };
        },

        getBucketFilterForBucket(bucket) {
            const bucketIndex = this.buckets.indexOf(bucket);
            if (bucketIndex === -1) {
                // not found === Other
                return {
                    minValue: groups[groups.length - 1].maxValue
                };
            }

            let minValue = 0;
            const { maxValue } = groups[bucketIndex];
            if (bucketIndex > 0) {
                minValue = groups[bucketIndex - 1].maxValue;
            }

            return {
                minValue,
                maxValue
            };
        },

        getDeliverables(bucket) {
            if (!this.data || !this.data[bucket]) {
                return [];
            }

            return this.data[bucket].deliverables || [];
        },

        getStats(bucket) {
            return this.data[bucket].summaryStats;
        },

        hasBucketData(bucket) {
            return this.data[bucket] && this.data[bucket].deliverables.length;
        },

        onData(response) {
            this.data = response;
        }
    }
};
</script>
<style lang="scss">
@import "../../../../sass/_variables.scss";

.size-analysis {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
</style>
