import { BarController } from "chart.js";

import { OffsetBar } from "@/components/CreativeIntelligence/SegmentBarChart/element.offsetBar";

export class SegmentBarController extends BarController {}

SegmentBarController.id = "SegmentBar";
SegmentBarController.defaults = {
    ...BarController.defaults,
    datasetElementType: false,
    dataElementType: OffsetBar.id
};
