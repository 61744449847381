import { MultiviewEvent } from "@/enums/multiview";
import bus from "@/bus";

export default {
    created() {
        this.initMultiviewPagination();
    },

    methods: {
        initMultiviewPagination() {
            bus.$on(MultiviewEvent.SelectAllOnPage, this.selectAllOnPage.bind(this));
            bus.$on(MultiviewEvent.SelectAll, this.selectAll.bind(this));
        },

        selectAll(data) {
            if (data.instance !== this.instanceName) {
                return;
            }

            this.selectedItems = this.itemsToPage;
        },

        selectAllOnPage(data) {
            if (data.instance !== this.instanceName) {
                return;
            }

            this.selectedItems = data.value ? this.pagedItems : [];
        }
    },

    watch: {
        pagedItems() {
            // reset selection on page change
            this.selectedItems = [];
        }
    }
};
