<template>
    <div class="add-template-to-campaign">
        <div class="add-template-to-campaign__content">
            <h2>Pick your campaign templates to get started</h2>
            <div class="add-template-to-campaign__actions">
                <div
                    class="add-template-to-campaign__action add-template-to-campaign__actions--browse"
                    @click="addTemplate"
                >
                    <browser-icon></browser-icon>
                    <Button data-testid="add-template__browse" @click="addTemplate">Browse</Button>
                    <p>Select templates from your Template Library</p>
                </div>
                <div
                    class="add-template-to-campaign__action add-template-to-campaign__actions--upload"
                    @click="addTemplate"
                >
                    <upload-icon></upload-icon>
                    <Button data-testid="add-template__upload" @click="addTemplate">Upload</Button>
                    <p>Upload a new template to your Template Library</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BrowserIcon from "@/assets/Complex_browse.svg";
import UploadIcon from "@/assets/Complex_upload.svg";

export default {
    name: "AddMasterTemplateToCampaign",
    components: {
        BrowserIcon,
        UploadIcon
    },
    methods: {
        addTemplate() {
            this.$emit("addTemplate");
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.add-template-to-campaign {
    background: $campaign-nav-bg;
    color: $cmp-dark-font-color;
    /*position: fixed;*/
    /*z-index: 10;*/
    width: calc(100% - #{$campaign-vertical-nav-width});
    height: calc(100vh - #{$campaign-nav-height} - #{$campaign-main-content-padding-top});
    display: flex;
    align-items: flex-start;
    justify-content: center;

    &__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 600px;
        margin-top: 10vh;
    }

    h2 {
        flex: 0;
        font-weight: normal;
        font-size: 28px;
        text-align: center;
        max-width: 400px;
        margin: 0 auto 50px;
    }

    &__action {
        cursor: pointer;
    }

    &__actions {
        flex: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &--browse,
        &--upload {
            background: $cmp-dark-bg-color;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 10px;
            width: 280px;
            height: 280px;

            button,
            svg {
                display: block;
            }

            button {
                background: $primary-color;
                color: $white;
                border: none;
                width: 180px;

                &:hover {
                    background: lighten($primary-color, 10);
                    color: $white;
                    border: none;
                }
            }

            p {
                text-align: center;
                font-size: 12px;
                opacity: 0.5;
                width: 180px;
                margin-top: 10px;
            }
        }
    }
}
</style>
