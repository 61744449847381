<template>
    <div class="play-pause-all">
        <Icon type="md-refresh" @click="replay" />
    </div>
</template>
<script>
import bus from "@/bus";
import { BannerActionEvents } from "@/enums/banners";

export default {
    name: "CampaignBannerPlayAll",

    props: {
        bannerGroup: {
            type: String
        }
    },

    methods: {
        replay() {
            bus.$emit(BannerActionEvents.AllBannersReplay, this.bannerGroup);
        }
    }
};
</script>

<style lang="scss">
.play-pause-all {
    cursor: pointer;
    margin-right: 20px;
}
</style>
