<template>
    <hox-modal @close="close">
        <hox-loading-layer v-if="isDeletingMetadataField" />
        <div class="delete-metadata-field-modal__alert-wrapper">
            <hox-alert type="danger" :margin-bottom="hasErrorDeletingMetadataField ? 'base' : 'none'">
                <template #title>
                    Are you sure you want to delete the "
                    <em>{{ columnName }}</em>
                    " column?
                </template>
                <template #content>
                    <p>
                        Deleting a column is permanent: it can not be recovered. All values of this column will also be
                        removed.
                    </p>
                </template>
            </hox-alert>
        </div>
        <Alert v-if="hasErrorDeletingMetadataField" banner type="error">
            <template #desc>
                There was an unexpected error and the column was not deleted. Hopefully it was a temporary issue and
                should work if you try again in a few moments.
            </template>
        </Alert>
        <template #footer>
            <Button class="delete-metadata-field-modal__button" @click="close">Cancel</Button>
            <Button class="delete-metadata-field-modal__button" type="error" @click="deleteMetadataField">
                Delete
            </Button>
        </template>
    </hox-modal>
</template>

<script>
import { removeMetadataField } from "@/services/Metadata";
import { DeliverableLibraryAction } from "@/store/modules/deliverableLibrary";

export default {
    props: {
        columnName: {
            required: true,
            type: String
        },

        fieldId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            hasErrorDeletingMetadataField: false,
            isDeletingMetadataField: false
        };
    },

    methods: {
        close() {
            this.$emit("close");
        },

        async deleteMetadataField() {
            this.hasErrorDeletingMetadataField = false;
            this.isDeletingMetadataField = true;
            try {
                await removeMetadataField(this.fieldId);
                this.$store.dispatch(DeliverableLibraryAction.UnsetMetadataFieldsByFieldId, this.fieldId);
                this.$store.dispatch(
                    DeliverableLibraryAction.SetTableVisibleColumnKeys,
                    this.$store.state.deliverableLibrary.table.visibleColumnKeys.filter(key => key !== this.fieldId)
                );
                this.$store.dispatch(
                    DeliverableLibraryAction.SetTableSortedColumnKeys,
                    this.$store.state.deliverableLibrary.table.sortedColumnKeys.filter(key => key !== this.fieldId)
                );
                this.$snackbar.success(`The column "${this.columnName}" has been removed from the table`);
            } catch (err) {
                this.hasErrorDeletingMetadataField = true;
                return;
            } finally {
                this.isDeletingMetadataField = false;
            }
            this.$emit("metadataFieldDeleted");
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.delete-metadata-field-modal__alert-wrapper {
    padding: $spacing 0 0;
}

.delete-metadata-field-modal__button {
    margin: 0 0 0 $spacing-small;
}
</style>
