export const MultiviewEvent = {
    SelectAll: "multiview:selectAll",
    SelectAllOnPage: "multiview:selectAllOnPage"
};

export const MultiviewMode = {
    Grid: "grid",
    List: "list"
};

export default {
    MultiviewEvent,
    MultiviewMode
};
