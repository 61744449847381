<template>
    <div class="play-pause">
        <Icon type="md-refresh" @click="replay" />
    </div>
</template>
<script>
import bus from "@/bus";
import { BannerActionEvents } from "@/enums/banners";

export default {
    name: "CampaignBannerPlay",

    props: {
        bannerId: {
            type: String,
            required: true
        }
    },

    methods: {
        replay() {
            bus.$emit(BannerActionEvents.BannerReplay, this.bannerId);
        }
    }
};
</script>
