export const OverwritingScope = {
    CAMPAIGN: "CAMPAIGN",
    MASTER_TEMPLATE: "MASTER_TEMPLATE",
    EDITABLE_GROUP: "EDITABLE_GROUP",
    EDITABLE_GROUP_AND_MASTER_TEMPLATE: "EDITABLE_GROUP_AND_MASTER_TEMPLATE"
};

export const OverwritingScopeOrder = {
    [OverwritingScope.CAMPAIGN]: 1,
    [OverwritingScope.MASTER_TEMPLATE]: 2,
    [OverwritingScope.EDITABLE_GROUP]: 3,
    [OverwritingScope.EDITABLE_GROUP_AND_MASTER_TEMPLATE]: 4
};

export const OverwritingLanguageOrder = (overwriteLang, selectedLang, campaignLang) => {
    if (overwriteLang === selectedLang) {
        return 2;
    }

    return overwriteLang === campaignLang ? 1 : 0;
};

export default {
    OverwritingScope,
    OverwritingScopeOrder,
    OverwritingLanguageOrder
};
