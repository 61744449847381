<template>
    <div class="qa-drawer">
        <div class="hox-drawer__container qa-drawer__container">
            <div class="hox-drawer__content qa-drawer__content">
                <div class="hox-drawer__content-container qa-drawer__content-container">
                    <div v-if="awaitingReviewCount !== null" class="qa-drawer__bulk-header">
                        {{ awaitingReviewCount }} to review
                    </div>
                    <div class="hox-drawer__header qa-drawer__header">
                        <qa-drawer-header
                            :deliverables="creativeDeliverables"
                            :buttons-disabled="headerButtonsDisabled"
                            :is-bulk="isBulk"
                            :bulk-current="pageNumber"
                            :bulk-total="totalItems"
                            :visible-master-template-ids="visibleMasterTemplateIds"
                            @bulkIndexChange="setPageNumber"
                            @show-resolved="onShowResolvedChange"
                            @statusHasBeenUpdated="onDeliverableStatusUpdate"
                            @userQaStatusHasBeenUpdated="onUserQaStatusUpdate"
                        />
                    </div>

                    <tab-carousel
                        v-model="pageNumber"
                        class="hox-drawer__body qa-drawer__body"
                        :total-pages="totalItems"
                        :show-arrows="hasDeliverables && isBulk"
                    >
                        <hox-loading-layer
                            v-if="isCreativeLoading"
                            class="qa-drawer__loading-layer"
                            :is-full-screen="false"
                            :left-offset-pixels="loaderOffset"
                            fix
                        />
                        <qa-creative-preview
                            v-if="!(isFilterBulk && !internalDeliverable)"
                            :deliverable="internalDeliverable"
                            :selected-annotation="selectedAnnotation"
                            :external-annotations="selectedDeliverableAnnotationsByType[AnnotationType.External]"
                            :has-error-loading-selected-deliverable-data="hasErrorLoadingSelectedDeliverableData"
                            :internal-annotations="selectedDeliverableAnnotationsByType[AnnotationType.Internal]"
                            :is-loading-selected-deliverable-data="isLoadingSelectedDeliverableData"
                            :show-full-creative="false"
                            :show-master-template-ids="currentCreativeMasterTemplateIds"
                            @annotationSelected="setSelectedAnnotation"
                            @annotationAdded="addAnnotation"
                            @select="setSelectedDeliverable"
                            @selectedBannerTimeUpdated="setBannerPlaybackTimeFromBanner"
                            @statusHasBeenUpdated="onDeliverableStatusUpdate"
                            @userQaStatusHasBeenUpdated="onUserQaStatusUpdate"
                            @loadingStarted="isCreativeLoading = true"
                            @loadingFinished="isCreativeLoading = false"
                            @visibleMasterTemplateIds="setVisibleMasterTemplateIds"
                            @load="onLoad"
                        ></qa-creative-preview>
                    </tab-carousel>

                    <div v-if="hasDeliverables" class="hox-drawer__footer qa-drawer__footer">
                        <div class="qa-drawer__scrubbar-container">
                            <annotation-scrubbar
                                v-if="showAnnotationsOnTimeline"
                                :key="selectedDeliverable && selectedDeliverable.hashId"
                                :show-resolved="areResolvedShown"
                                :annotations="selectedDeliverableAnnotations"
                                :duration="maxDuration"
                                :selected-annotation="selectedAnnotation"
                                @annotationSelected="setSelectedAnnotation"
                            />
                            <banner-scrubbar :value="bannerPlaybackTime" @change="setBannerPlaybackTime" />
                        </div>
                        <square-button has-inverse-hover @click="playPauseReplayBanners">
                            <Icon :type="playPauseReplayIcon" />
                        </square-button>
                    </div>
                </div>

                <div v-if="hasDeliverables" class="qa-drawer__sidebar">
                    <qa-drawer-sidebar
                        :show-resolved="areResolvedShown"
                        :deliverable="selectedDeliverable"
                        :external-annotations="selectedDeliverableAnnotationsByType[AnnotationType.External]"
                        :has-error-loading-selected-deliverable-data="hasErrorLoadingSelectedDeliverableData"
                        :internal-annotations="selectedDeliverableAnnotationsByType[AnnotationType.Internal]"
                        :is-loading-selected-deliverable-data="isLoadingSelectedDeliverableData"
                        :selected-annotation="selectedAnnotation"
                        @annotationDeleted="removeAnnotation"
                        @annotationSelected="setSelectedAnnotation"
                        @annotationUpdated="replaceAnnotation"
                        @loadSelectedDeliverableData="getSelectedDeliverableData"
                        @resolvedShown="onShowResolvedChange"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UsersByScopeQuery from "@/apollo/queries/UsersByScope.gql";
import BulkAwaitingReviewCountQuery from "@/apollo/queries/v2/BulkAwaitingReviewCount.gql";
import HashUtils from "@/utils/generateHash";

import BannerScrubbar from "@/components/Previews/BannerScrubbar";
import SquareButton from "@/components/common/SquareButton";
import QaDrawerHeader from "@/components/Qa/DrawerTab/QaDrawerTabHeader";
import QaDrawerSidebar from "@/components/Qa/DrawerTab/QaDrawerTabSidebar";
import AnnotationScrubbar from "@/components/Qa/AnnotationScrubbar";
import { AnnotationType } from "@/enums/annotations";
import {
    BannerDefaultDuration,
    BannerGroup,
    BannerQaUserStatus,
    BannerQaUserStatusLabelFilterMap,
    BannerState
} from "@/enums/banners";
import DeliverablesService from "@/services/Deliverables";
import { getAnnotationsOfDeliverable, getDeliverables } from "@/services/Qa";
import bannerTimelineMixin from "@/mixins/bannerTimelineMixin";
import deliverableMethodsMixin from "@/mixins/deliverableMethodsMixin";
import URLManager from "@/services/URLManager";
import { DeliverableLibraryAction } from "@/store/modules/deliverableLibrary";
import QaCreativePreview from "@/components/Qa/QaCreativePreview";
import { CampaignAction } from "@/store/modules/campaign";
import { constructLibraryFiltersQueryVariables } from "@/utils";
import TabCarousel from "@/components/TabCarousel";

const QueryParams = {
    SelectedDeliverableId: "qad-sd",
    ShowResolvedAnnotations: "qad-sra"
};

export default {
    name: "QaDrawerTab",

    components: {
        TabCarousel,
        QaCreativePreview,
        AnnotationScrubbar,
        BannerScrubbar,
        QaDrawerHeader,
        QaDrawerSidebar,
        SquareButton
    },
    mixins: [bannerTimelineMixin, deliverableMethodsMixin],
    props: {
        allSelectedMode: {
            type: Boolean,
            default: false
        },

        deliverables: {
            type: Array,
            required: true
        },

        libraryFiltersForQuery: {
            type: Object,
            required: false
        }
    },

    data() {
        return {
            areResolvedShown: false,
            awaitingReviewCount: null,
            bannerGroup: BannerGroup.Qa,
            creativeDeliverables: [],
            existingDeliverableStatusIdHashes: new Set(),
            fetchedDeliverable: null,
            fetchedTotal: 0,
            isCreativeLoading: false,
            hasErrorLoadingSelectedDeliverableAnnotations: false,
            hasErrorLoadingSelectedDeliverableStatus: false,
            isLoadingSelectedDeliverableAnnotations: false,
            isLoadingSelectedDeliverableStatus: false,
            pageNumber: 1,
            selectedAnnotation: null,
            selectedDeliverable: null,
            selectedDeliverableAnnotations: [],
            visibleMasterTemplateIds: []
        };
    },

    computed: {
        // override mixin
        bannerIframeId() {
            const deliverable = this.internalDeliverable;

            if (deliverable) {
                return this.iframeId(deliverable, BannerGroup.Qa);
            }
            return null;
        },

        campaignId() {
            return this.$store.state.route.params.campaignId;
        },
        clientId() {
            return this.$store.state.route.params.clientId;
        },

        maxDuration() {
            return this.$store.state.campaign.defaultTimelineLength || BannerDefaultDuration;
        },

        creatives() {
            if (this.isArrayBulk) {
                const result = this.deliverables.reduce((acc, deliverable) => {
                    let creative = {};
                    const deliverableIdentity = HashUtils.getDeliverableIdentityFromObject(deliverable);
                    const creativeIdentity = {
                        ...deliverableIdentity,
                        masterTemplateId: null
                    };

                    const creativeId = HashUtils.getIdHash(creativeIdentity);

                    if (creativeId in acc) {
                        creative = {
                            creativeId,
                            editableGroupIds: creativeIdentity.editableGroupValueIds,
                            language: creativeIdentity.language,
                            deliverableIds: [...acc[creativeId].deliverableIds, deliverable._id],
                            deliverables: {
                                ...acc[creativeId].deliverables,
                                [deliverable._id]: deliverable
                            }
                        };
                    } else {
                        creative = {
                            creativeId,
                            editableGroupIds: creativeIdentity.editableGroupValueIds,
                            language: creativeIdentity.language,
                            deliverableIds: [deliverable._id],
                            deliverables: {
                                [deliverable._id]: deliverable
                            }
                        };
                    }

                    return Object.assign(acc, { [creativeId]: creative });
                }, {});

                return result;
            }

            return [];
        },

        creativeIds() {
            if (!this.isArrayBulk) {
                return [];
            }

            return Object.keys(this.creatives);
        },

        currentCreative() {
            return this.creatives[this.creativeIds[this.pageNumber - 1]];
        },

        currentCreativeMasterTemplateIds() {
            if (this.isArrayBulk) {
                const { deliverables, deliverableIds } = this.currentCreative;

                return deliverableIds.map(deliverableId => {
                    return deliverables[deliverableId].masterTemplate._id;
                });
            }

            if (this.isFilterBulk) {
                return this.libraryFiltersForQuery.sizeFilters || [];
            }

            return [];
        },

        hasDeliverables() {
            return this.creativeDeliverables.length !== 0;
        },
        hasErrorLoadingSelectedDeliverableData() {
            return this.hasErrorLoadingSelectedDeliverableAnnotations || this.hasErrorLoadingSelectedDeliverableStatus;
        },

        headerButtonsDisabled() {
            return this.creativeDeliverables.length === 0;
        },

        internalDeliverable() {
            if (this.isArrayBulk && this.currentCreative) {
                const { deliverables, deliverableIds } = this.currentCreative;
                return deliverables[deliverableIds[0]];
            }

            if (this.isFilterBulk) {
                return this.fetchedDeliverable;
            }

            return this.deliverables[0];
        },

        isArrayBulk() {
            return Array.isArray(this.deliverables) && this.deliverables.length > 1;
        },

        isFilterBulk() {
            return this.allSelectedMode;
        },

        isBulk() {
            return this.isArrayBulk || this.isFilterBulk;
        },

        isLoadingSelectedDeliverableData() {
            return this.isLoadingSelectedDeliverableAnnotations || this.isLoadingSelectedDeliverableStatus;
        },

        loaderOffset() {
            return this.hasDeliverables ? -300 : 0;
        },

        selectedDeliverableAnnotationsByType() {
            if (this.hasErrorLoadingSelectedDeliverableData) {
                return {
                    [AnnotationType.Internal]: [],
                    [AnnotationType.External]: []
                };
            }
            const annotationsByType = this.selectedDeliverableAnnotations.reduce(
                (acc, annotation) => {
                    acc[annotation.type].push({
                        ...annotation,
                        displayIndex: acc[annotation.type].length + 1
                    });
                    return acc;
                },
                {
                    [AnnotationType.Internal]: [],
                    [AnnotationType.External]: []
                }
            );
            /*
                While these should be prefiltered by the backend we're going to be
                extra careful and ensure that they user does not see annotations that
                they do not have access to on the frontend too.
            */
            if (!this.userCanListExternalAnnotations) {
                annotationsByType[AnnotationType.External] = [];
            }
            if (!this.userCanListInternalAnnotations) {
                annotationsByType[AnnotationType.Internal] = [];
            }
            /*
                We reverse the arrays to show the most recent annotation first.

                We could have done this by using Array.unshift to put items onto
                the array, but then we would not have been able to index the annotations
                with the most recent annotation having the highest value.
            */
            return {
                [AnnotationType.Internal]: annotationsByType[AnnotationType.Internal].reverse(),
                [AnnotationType.External]: annotationsByType[AnnotationType.External].reverse()
            };
        },
        showAnnotationsOnTimeline() {
            return this.selectedDeliverableAnnotations.length > 0 && !this.hasErrorLoadingSelectedDeliverableData;
        },

        totalItems() {
            if (this.isFilterBulk) {
                return this.fetchedTotal;
            }

            if (this.isArrayBulk) {
                return this.creativeIds.length;
            }

            return 1;
        },

        userCanListExternalAnnotations() {
            return this.$auth.userCan(this.$auth.Actions.CanListExternalAnnotations);
        },
        userCanListInternalAnnotations() {
            return this.$auth.userCan(this.$auth.Actions.CanListInternalAnnotations);
        },
        userCanOnlyListExternalAnnotations() {
            return !this.userCanListInternalAnnotations && this.userCanListExternalAnnotations;
        },
        userCanOnlyListInternalAnnotations() {
            return this.userCanListInternalAnnotations && !this.userCanListExternalAnnotations;
        },
        userHasAccessToAnnotationType() {
            if (this.userCanOnlyListInternalAnnotations) {
                return AnnotationType.Internal;
            }
            if (this.userCanOnlyListExternalAnnotations) {
                return AnnotationType.External;
            }
            return undefined;
        }
    },
    watch: {
        areResolvedShown() {
            this.setShowResolvedAnnotationsQueryParams();
        },

        selectedDeliverable() {
            /*
                We're clearing the current annotations to prevent any odd behaviour when
                switching between selected deliverables, such as showing the annotations
                from the previous deliverable on the deliverable that was just selected.
            */
            if (this.bannerState && this.bannerState.state !== BannerState.Loading) {
                this.setBannerPlaybackTimeFromBanner(this.bannerState.currentTime);
            }
            this.selectedDeliverableAnnotations = [];
            this.selectedAnnotation = null;
            this.getSelectedDeliverableData();
            if (!this.isBulk) {
                this.setSelectedDeliverableIdQueryParams();
            }
        },

        pageNumber: {
            handler(page, oldPage) {
                if (this.isFilterBulk) {
                    this.fetchDeliverable(page);
                }

                if (page !== oldPage) {
                    // clear selection on page change
                    this.setSelectedDeliverable(null);
                }
            }
        },

        visibleMasterTemplateIds() {
            if (this.isFilterBulk) {
                this.getAwaitingReviewCount();
            }
        }
    },

    beforeDestroy() {
        this.cleanDeliverableStatusByIdHash();
        this.unsetAllQueryParams();
    },

    created() {
        this.urlManager = new URLManager(this.$router, {
            tracked: Object.values(QueryParams)
        });
        /*
            We store a set of existing deliverable ID hashes so that we can
            clear out data created by this component without removing data that
            is being used in elsewhere.
        */

        this.existingDeliverableStatusIdHashes = new Set(
            Object.keys(this.$store.state.deliverableLibrary.deliverableStatusByIdHash)
        );
        this.AnnotationType = AnnotationType;
        this.deliverablesService = new DeliverablesService(this.$apollo, this.$store);

        this.getSelectedDeliverableData();

        this.areResolvedShown = !!this.$route.query[QueryParams.ShowResolvedAnnotations];
        this.setShowResolvedAnnotationsQueryParams();

        if (this.isFilterBulk) {
            this.fetchDeliverable(this.pageNumber);
            this.getAwaitingReviewCount();
        }
    },

    methods: {
        addAnnotation(annotation) {
            this.selectedDeliverableAnnotations.push(annotation);
            this.getSelectedDeliverableData();
            this.$emit("annotationsHasBeenUpdated");
        },

        close() {
            this.$emit("closed");
        },

        cleanDeliverableStatusByIdHash() {
            /*
                To ensure that `deliverableStatusByIdHash` in the store does not
                infinitely grow we need to clear out data that is no longer being used.

                To make sure that we do not clear out data that is being used elsewhere
                we filter out any deliverables that existed in `deliverableStatusByIdHash`
                before this component was created.
            */
            const deliverablesToUnset = this.creativeDeliverables.filter(
                deliverable => !this.existingDeliverableStatusIdHashes.has(deliverable.idHash)
            );
            this.$store.dispatch(DeliverableLibraryAction.UnsetDeliverableStatusByIdHash, deliverablesToUnset);
        },

        async fetchDeliverable(page) {
            // Let's get just a single size as this is enough to determine the creative
            const libraryFiltersForQuery = {
                ...this.libraryFiltersForQuery,
                sizeFilters: [this.libraryFiltersForQuery.sizeFilters[0]]
            };

            const { deliverables, pagination } = await this.deliverablesService.getPaginatedDeliverables(
                this.campaignId,
                libraryFiltersForQuery,
                page,
                1
            );

            this.fetchedDeliverable = deliverables.pop();
            this.fetchedTotal = pagination.totalCount;
        },

        async getAnnotationsForSelectedDeliverable() {
            this.hasErrorLoadingSelectedDeliverableAnnotations = false;
            this.isLoadingSelectedDeliverableAnnotations = true;
            if (
                !this.selectedDeliverable ||
                (!this.userCanListInternalAnnotations && !this.userCanListExternalAnnotations)
            ) {
                /*
                    The user has no permissions to see any annotations or there is no
                    selected annotation: don't bother with the query.
                */
                this.selectedDeliverableAnnotations = [];
                return;
            }
            try {
                this.selectedDeliverableAnnotations = await getAnnotationsOfDeliverable(
                    this.campaignId,
                    this.selectedDeliverable,
                    this.userHasAccessToAnnotationType
                );
            } catch (err) {
                this.selectedDeliverableAnnotations = [];
                this.hasErrorLoadingSelectedDeliverableAnnotations = true;
            }
            this.isLoadingSelectedDeliverableAnnotations = false;
        },

        async getAwaitingReviewCount() {
            if (!this.visibleMasterTemplateIds.length || !this.isFilterBulk) {
                return;
            }

            let libraryFiltersVars = constructLibraryFiltersQueryVariables(this.libraryFiltersForQuery);

            libraryFiltersVars = {
                ...libraryFiltersVars,
                filters: {
                    ...libraryFiltersVars.filters,
                    masterTemplateIds: this.visibleMasterTemplateIds
                },
                userStatus: BannerQaUserStatusLabelFilterMap[BannerQaUserStatus.None]
            };

            const { data } = await this.$apollo.query({
                fetchPolicy: "no-cache",
                query: BulkAwaitingReviewCountQuery,
                variables: {
                    campaignId: this.campaignId,
                    libraryFilters: libraryFiltersVars
                }
            });
            this.awaitingReviewCount = data.computeDeliverables.pagination.totalCount;
        },

        getSelectedDeliverableData() {
            this.getAnnotationsForSelectedDeliverable();
            this.getStatusForSelectedDeliverable();
        },

        async getStatusForSelectedDeliverable() {
            this.hasErrorLoadingSelectedDeliverableStatus = false;
            this.isLoadingSelectedDeliverableStatus = true;
            if (this.selectedDeliverable) {
                try {
                    const deliverablesStatus = await getDeliverables(this.campaignId, [this.selectedDeliverable]);
                    this.$store.dispatch(DeliverableLibraryAction.SetDeliverableStatusByIdHash, deliverablesStatus);
                } catch (err) {
                    this.hasErrorLoadingSelectedDeliverableStatus = true;
                }
            }
            this.isLoadingSelectedDeliverableStatus = false;
        },

        onUserQaStatusUpdate() {
            this.getAwaitingReviewCount();
            this.$emit("userStatusHasBeenUpdated");
        },

        onDeliverableStatusUpdate() {
            this.$emit("statusHasBeenUpdated");
        },

        onLoad(deliverables) {
            this.creativeDeliverables = deliverables;
        },

        onShowResolvedChange(val) {
            this.areResolvedShown = val;
        },

        removeAnnotation(annotationToRemove) {
            this.selectedDeliverableAnnotations = this.selectedDeliverableAnnotations.filter(
                annotation => annotation._id !== annotationToRemove._id
            );
            this.getSelectedDeliverableData();
            this.$emit("annotationsHasBeenUpdated");
        },
        replaceAnnotation(annotationToReplace) {
            this.selectedDeliverableAnnotations = this.selectedDeliverableAnnotations.map(annotation => {
                if (annotation._id === annotationToReplace._id) {
                    return annotationToReplace;
                }
                return annotation;
            });
            this.$emit("annotationsHasBeenUpdated");
            this.getSelectedDeliverableData();
        },

        setPageNumber(number) {
            this.pageNumber = number;
        },

        setShowResolvedAnnotationsQueryParams() {
            this.urlManager.setURLParams(
                [
                    {
                        key: QueryParams.ShowResolvedAnnotations,
                        value: this.areResolvedShown ? [1] : []
                    }
                ],
                true
            );
        },
        setSelectedAnnotation(annotation) {
            this.selectedAnnotation = annotation;
            this.setBannerPlaybackTime(annotation.timeline);
        },
        setSelectedDeliverable(deliverable) {
            this.selectedDeliverable = deliverable;
            this.$emit("selectedDeliverableUpdate", deliverable);
        },
        setSelectedDeliverableIdQueryParams() {
            this.urlManager.setURLParams(
                [
                    {
                        key: QueryParams.SelectedDeliverableId,
                        value: this.selectedDeliverable ? [this.selectedDeliverable.idHash] : []
                    }
                ],
                true
            );
        },

        setVisibleMasterTemplateIds(visibleMasterTemplateIds) {
            this.visibleMasterTemplateIds = visibleMasterTemplateIds;
        },

        unsetAllQueryParams() {
            this.urlManager.setURLParams(
                Object.values(QueryParams).map(key => ({
                    key,
                    value: []
                })),
                true
            );
        }
    },
    apollo: {
        usersByScope: {
            query: UsersByScopeQuery,
            variables() {
                return {
                    scope: {
                        clientId: this.clientId,
                        campaignId: this.campaignId
                    }
                };
            },

            result({ data, error }) {
                if (!error) {
                    this.$store.dispatch(CampaignAction.SetCampaignUsers, data.usersByScope);
                }
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

$qa-sidebar-width: 300px;

.qa-drawer {
    .timeline-slider {
        align-items: center;
        display: flex;
        flex: 1 0;
        width: 100%;
    }

    .ivu-slider-wrap {
        margin: 9px ($spacing + 9) 9px 9px;
    }

    .tab-carousel-arrow.right {
        right: $spacing-large + $qa-sidebar-width;
    }
}

.qa-drawer__bulk-header {
    width: 100%;
    padding: $spacing-small $spacing-large $spacing-smaller $spacing-large;
    text-align: right;
    font-weight: bold;
    background-color: $white;
}

.qa-drawer__loading-layer {
    position: fixed;
}

.qa-drawer__scrubbar-container {
    flex: 1;
    position: relative;
}

.qa-drawer__sidebar {
    border-left: 1px solid $grey3;
    flex: 0 1;
    min-width: $qa-sidebar-width;
    position: relative;
    width: $qa-sidebar-width;
}
</style>
