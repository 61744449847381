<template>
    <collapsible-widget :is-opened="isOpened" :collapsed-height="collapsedHeight" @input="onInput">
        <template #name>
            <slot name="name"></slot>
        </template>
        <template #controls>
            <slot name="controls-prefix"></slot>
            <Icon
                type="md-apps"
                size="18"
                class="widget-mode"
                :class="{ 'widget-mode--active': !isListMode }"
                @click.stop="toggleMode(false)"
            ></Icon>
            <Icon
                type="md-list"
                size="18"
                class="widget-mode"
                :class="{ 'widget-mode--active': isListMode }"
                @click.stop="toggleMode(true)"
            ></Icon>
            <slot name="controls"></slot>
        </template>

        <div class="editable-widget__body">
            <div v-if="readOnly" class="editable-widget__body--readonly" title="Read Only" @click.stop.prevent></div>
            <template v-if="isListMode">
                <slot name="list-view"></slot>
            </template>
            <template v-else>
                <slot name="grid-view"></slot>
            </template>
        </div>
    </collapsible-widget>
</template>

<script>
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";

export default {
    name: "MultiviewEditableWidget",
    components: { CollapsibleWidget },
    props: {
        isOpened: {
            type: Boolean,
            default: true
        },
        readOnly: {
            type: Boolean
        }
    },

    data() {
        return {
            isListMode: false
        };
    },

    computed: {
        collapsedHeight() {
            return this.isListMode ? 47 : 139;
        }
    },

    methods: {
        toggleMode(isList) {
            this.isListMode = isList;
        },

        onInput(val) {
            this.$emit("input", val);
        }
    }
};
</script>
