import Vue from "vue";
import { baseRequestOptions } from "@/components/Reporting/data/utils";
import { TagsBaseUrl } from "@/components/Reporting/data/constants";

const storeNamespace = "ciTags";

const buildUrl = endpoint => `${TagsBaseUrl}${endpoint}`;
const buildRequestBody = (method, body = null) => ({
    ...baseRequestOptions,
    method,
    headers: {
        ...baseRequestOptions.headers,
        ...(body && { "Content-Type": "application/json" })
    },
    ...(body && { body: JSON.stringify(body) })
});

export const TagsAction = {
    CreateTag: `${storeNamespace}/createTag`,
    DeleteTag: `${storeNamespace}/deleteTag`,
    GetTags: `${storeNamespace}/getTags`,
    SelectTags: `${storeNamespace}/selectTags`,
    ToggleTagSelection: `${storeNamespace}/toggleTagSelection`,
    UpdateTag: `${storeNamespace}/updateTag`
};

export const TagsMutation = {
    AddTag: "addTag",
    DeleteTag: "deleteTag",
    DeselectTag: "deselectTag",
    SelectTag: "selectTag",
    SelectTags: "selectTags",
    SetCreating: "setCreating",
    SetDeleting: "setDeleting",
    SetLoading: "setLoading",
    SetTags: "setTags",
    SetUpdating: "setUpdating",
    UpdateTag: "updateTag"
};

const refreshTagCategories = state => {
    const tagsByCategory = state.tags.reduce((acc, curr) => {
        const category = curr.category || "Other";
        if (!acc[category]) {
            acc[category] = [];
        }

        acc[category].push(curr.tagId);

        return acc;
    }, {});
    const tagCategories = Object.keys(state.tagsByCategory).sort();

    return { tagsByCategory, tagCategories };
};

export default {
    namespaced: true,

    state: {
        isCreating: false,
        isDeleting: false,
        isLoading: false,
        isUpdating: false,
        selectedTagIds: [],
        tagCategories: [],
        tags: [],
        tagsById: {},
        tagsByCategory: {}
    },

    getters: {},

    mutations: {
        addTag(state, tag) {
            state.tags = [...state.tags, tag];
            Vue.set(state.tagsById, tag.tagId, tag);
            const { tagsByCategory, tagCategories } = refreshTagCategories(state);
            state.tagsByCategory = tagsByCategory;
            state.tagCategories = tagCategories;
        },

        deleteTag(state, tagId) {
            state.tags = state.tags.filter(tag => tag.tagId !== tagId);
            Vue.delete(state.tagsById, tagId);
            const { tagsByCategory, tagCategories } = refreshTagCategories(state);
            state.tagsByCategory = tagsByCategory;
            state.tagCategories = tagCategories;
        },

        deselectTag(state, tagId) {
            state.selectedTagIds = state.selectedTagIds.filter(tag => tag !== tagId);
        },

        selectTag(state, tagId) {
            state.selectedTagIds = [tagId];
        },

        selectTags(state, tagIds) {
            state.selectedTagIds = tagIds;
        },

        setCreating(state, isCreating) {
            state.isCreating = isCreating;
        },

        setDeleting(state, isDeleting) {
            state.isDeleting = isDeleting;
        },

        setLoading(state, isLoading) {
            state.isLoading = isLoading;
        },

        setTags(state, tags) {
            state.tags = tags;
            state.tagsById = tags.reduce((acc, curr) => {
                acc[curr.tagId] = curr;

                return acc;
            }, {});

            const { tagsByCategory, tagCategories } = refreshTagCategories(state);
            state.tagsByCategory = tagsByCategory;
            state.tagCategories = tagCategories;
        },

        setUpdating(state, isUpdating) {
            state.isUpdating = isUpdating;
        },

        updateTag(state, tag) {
            const index = state.tags.findIndex(({ tagId }) => tagId === tag.tagId);
            Vue.set(state.tags, index, tag);
            Vue.set(state.tagsById, tag.tagId, tag);
        }
    },

    actions: {
        async getTags({ commit, rootState }, tagGroup) {
            commit(TagsMutation.SetLoading, true);
            try {
                const response = await fetch(buildUrl(`/${tagGroup}`), buildRequestBody("GET"));
                const { data } = await response.json();
                commit(TagsMutation.SetTags, data);

                // set the tagId from the url
                if (rootState.route.params.tagId) {
                    commit(TagsMutation.SelectTag, rootState.route.params.tagId);
                }

                commit(TagsMutation.SetLoading, false);
            } catch (e) {
                commit(TagsMutation.SetLoading, false);
                console.error(e);
            }
        },

        async createTag({ commit }, { clientId, name, query, sourceTag, displayTemplates, category, tagOptions }) {
            commit(TagsMutation.SetCreating, true);
            const tagGroup = clientId;
            const body = {
                name,
                advancedSearch: query,
                ...(sourceTag && { sourceTag }),
                ...(displayTemplates && { displayTemplates }),
                ...(category && { category }),
                ...(tagOptions && { tagOptions })
            };
            try {
                const response = await fetch(buildUrl(`/${tagGroup}`), buildRequestBody("POST", body));
                const { data } = await response.json();
                commit(TagsMutation.AddTag, { ...data.data, ...data.data.data });
                commit(TagsMutation.SetCreating, false);
                return data.data;
            } catch (e) {
                commit(TagsMutation.SetCreating, false);
                console.error(e);
                return null;
            }
        },

        async deleteTag({ commit }, { tagGroup, tagId }) {
            commit(TagsMutation.SetDeleting, true);
            commit(TagsMutation.DeselectTag, tagId);

            try {
                const response = await fetch(buildUrl(`/${tagGroup}/${tagId}`), buildRequestBody("DELETE"));
                const { data } = await response.json();
                commit(TagsMutation.DeleteTag, tagId);
                commit(TagsMutation.SetDeleting, false);
                return data.data;
            } catch (e) {
                commit(TagsMutation.SetDeleting, false);
                console.error(e);
                return null;
            }
        },

        selectTags({ commit }, tagIds) {
            commit(TagsMutation.SelectTags, tagIds);
        },

        toggleTagSelection({ commit, state }, tagId) {
            const mutation = state.selectedTagIds.includes(tagId) ? TagsMutation.DeselectTag : TagsMutation.SelectTag;
            commit(mutation, tagId);
        },

        async updateTag({ commit }, tag) {
            commit(TagsMutation.SetUpdating, true);
            const { tagId, tagGroup } = tag;

            try {
                const response = await fetch(buildUrl(`/${tagGroup}/${tagId}`), buildRequestBody("POST", tag));
                const { data } = await response.json();
                commit(TagsMutation.UpdateTag, { ...data.data, ...data.data.data });
                commit(TagsMutation.SetUpdating, false);
                return data.data;
            } catch (e) {
                commit(TagsMutation.SetUpdating, false);
                console.error(e);
                return null;
            }
        }
    }
};
