import { languagesList } from "../assets/languages";

export default {
    friendlyNameToLocale(locale) {
        if (locale === "all") {
            return "all";
        }

        const friendlyName = languagesList.filter(language => language.label === locale);

        return friendlyName.length ? friendlyName[0].value : null;
    },

    localeToFriendlyName(locale) {
        if (locale === "all") {
            return "All";
        }

        if (!languagesList) {
            return "";
        }

        const friendlyName = languagesList.filter(language => language.value === locale);

        return friendlyName.length ? friendlyName[0].label : null;
    }
};
