<template>
    <Form
        ref="addAccountForm"
        class="add-platform-account"
        :rules="baseValidationRules"
        :model="formData"
        @submit.native.prevent="onSubmitFormAccount"
    >
        <FormItem ref="accountName" label="Account name" prop="accountName">
            <Input
                v-model="formData.accountName"
                auto-complete="off"
                data-testid="add-platform-account__name"
            ></Input>
        </FormItem>
        <account-type-select :account-type="formData.accountType" @changeAccountType="onChangeAccountType" />
        <Button type="primary" data-testid="new-platform-account__modal-button" @click="onSubmitFormAccount">
            {{ actionLabel }}
        </Button>
    </Form>
</template>
<script>
import activateAccountFormsMixin from "@/mixins/activateAccountFormsMixin";
import AccountTypeSelect from "@/components/IntelligentDelivery/AccountTypeSelect";

export default {
    name: "BasicAccountForm",
    components: {
        AccountTypeSelect
    },
    mixins: [activateAccountFormsMixin]
};
</script>
