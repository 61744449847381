<template>
    <collapsible-widget :is-opened="true">
        <template #name>Position</template>
        <template #controls>
            <Icon type="md-trash" @click.exact.stop="clearStyles" />
        </template>
        <div class="element-position">
            <editable-settings-control v-if="isTranslationMethodVisible" title="Translation Mode">
                <template #button>
                    <div class="editable-settings-item">
                        <Icon type="ios-browsers-outline" />
                    </div>
                </template>

                <template #value>
                    <Select
                        size="small"
                        class="hox-select-dark"
                        :placeholder="methodPlaceholder"
                        :value="translationMethod"
                        @input="onTranslationMethodInput(EditableSettingProperty.TranslationMethod, $event)"
                    >
                        <Option v-for="method in translationMethods" :key="method" :value="method">
                            {{ method }}
                        </Option>
                    </Select>
                </template>
            </editable-settings-control>
            <div class="keyboard-wrapper" @keydown.capture="onMoveDimensions">
                <editable-settings-control title="Dimensions">
                    <template #button>
                        <div class="editable-settings-item">
                            <ruler-icon width="20" height="20"></ruler-icon>
                        </div>
                    </template>
                    <template #value>
                        <div class="element-position__position">
                            <editable-settings-number-input
                                ref="width"
                                :key="getElKey(EditableSettingProperty.Width)"
                                :value="width"
                                prop-name="width"
                                :show-suffix="isSuffixVisble(EditableSettingProperty.Width)"
                                :placeholder="getPlaceholderValue(EditableSettingProperty.Width)"
                                @wrapperKeyDown="onKeyboardUpdate(EditableSettingProperty.Width, $event)"
                                @input="onDimensionInput(EditableSettingProperty.Width, $event)"
                            >
                                <template #label>
                                    <span class="editable-settings__input-label">W:</span>
                                </template>
                            </editable-settings-number-input>

                            <editable-settings-number-input
                                ref="height"
                                :key="getElKey(EditableSettingProperty.Height)"
                                :value="height"
                                prop-name="height"
                                :show-suffix="isSuffixVisble(EditableSettingProperty.Height)"
                                :placeholder="getPlaceholderValue(EditableSettingProperty.Height)"
                                @wrapperKeyDown="onKeyboardUpdate(EditableSettingProperty.Height, $event)"
                                @input="onDimensionInput(EditableSettingProperty.Height, $event)"
                            >
                                <template #label>
                                    <span class="editable-settings__input-label editable-settings__input-label--second">
                                        H:
                                    </span>
                                </template>
                            </editable-settings-number-input>
                        </div>
                    </template>
                </editable-settings-control>
            </div>

            <div class="keyboard-wrapper" @keydown.capture="onMovePosition">
                <editable-settings-control title="Position">
                    <template #button>
                        <div class="editable-settings-item">
                            <Icon type="md-move" />
                        </div>
                    </template>
                    <template #value>
                        <div class="element-position__position">
                            <editable-settings-number-input
                                ref="left"
                                :key="getElKey(EditableSettingProperty.Left)"
                                :value="left"
                                prop-name="left"
                                :show-suffix="isSuffixVisble(EditableSettingProperty.Left)"
                                :placeholder="leftPlaceholder"
                                @wrapperKeyDown="onPositionKeyboardUpdate(EditableSettingProperty.Left, $event)"
                                @input="onNumericInput(EditableSettingProperty.Left, $event)"
                            >
                                <template #label>
                                    <span class="editable-settings__input-label">X:</span>
                                </template>
                            </editable-settings-number-input>

                            <editable-settings-number-input
                                ref="top"
                                :key="getElKey(EditableSettingProperty.Top)"
                                :value="top"
                                prop-name="top"
                                :show-suffix="isSuffixVisble(EditableSettingProperty.Top)"
                                :placeholder="topPlaceholder"
                                @wrapperKeyDown="onPositionKeyboardUpdate(EditableSettingProperty.Top, $event)"
                                @input="onNumericInput(EditableSettingProperty.Top, $event)"
                            >
                                <template #label>
                                    <span class="editable-settings__input-label editable-settings__input-label--second">
                                        Y:
                                    </span>
                                </template>
                            </editable-settings-number-input>
                        </div>
                    </template>
                </editable-settings-control>
            </div>

            <editable-settings-control v-if="!isPsd" :title="camelToUpperCase(EditableSettingProperty.Scale)">
                <template #button>
                    <div class="editable-settings-item">
                        <Icon type="md-expand" />
                    </div>
                </template>
                <template #value>
                    <editable-settings-number-input
                        ref="letterSpacing"
                        :key="getElKey(EditableSettingProperty.Scale)"
                        :value="scale"
                        prop-name="letterSpacing"
                        :show-suffix="true"
                        suffix="%"
                        :placeholder="getPlaceholderValue(EditableSettingProperty.Scale)"
                        @wrapperKeyDown="onKeyboardUpdate(EditableSettingProperty.Scale, $event)"
                        @input="onNumericInput(EditableSettingProperty.Scale, $event)"
                    />
                </template>
            </editable-settings-control>
        </div>
    </collapsible-widget>
</template>
<script>
import EditableSettingsControl from "@/components/Campaign/EditableSettingsControl";
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";

import RulerIcon from "@/assets/text-editor/ruler.svg";

import { EditableSettingProperty, EditableTranslateMethod, EditableType } from "@/enums/editables";
import { camelToUpperCase } from "@/utils";
import editableSettingsMixin from "@/mixins/editableSettingsMixin";
import EditableSettingsNumberInput from "@/components/Campaign/EditableSettingsNumberInput";

export default {
    name: "ElementPosition",
    components: {
        EditableSettingsNumberInput,
        CollapsibleWidget,
        EditableSettingsControl,
        RulerIcon
    },

    mixins: [editableSettingsMixin],

    props: {
        // eslint-disable-next-line vue/require-prop-types
        masterTemplate: {
            default: null
        },

        // eslint-disable-next-line vue/require-prop-types
        boundingClientRect: {
            default: null
        },

        editableType: {
            type: String,
            default: EditableType.Text
        }
    },

    data() {
        return {
            relevantProps: [
                EditableSettingProperty.Top,
                EditableSettingProperty.Left,
                EditableSettingProperty.Width,
                EditableSettingProperty.Height,
                EditableSettingProperty.Scale,
                EditableSettingProperty.TranslationMethod
            ],
            css: {},
            emptyPropertyText: "Not set",
            handledKeys: ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"],
            left: undefined,
            scale: undefined,
            top: undefined,
            translationMethod: undefined,
            translationMethods: [
                EditableTranslateMethod.Relative,
                EditableTranslateMethod.Transform,
                EditableTranslateMethod.Margin
            ],
            width: undefined,
            height: undefined,
            camelToUpperCase,
            EditableSettingProperty
        };
    },

    computed: {
        isTranslationMethodVisible() {
            return !this.isPsd && this.editableType !== EditableType.BackgroundImage;
        },

        leftPlaceholder() {
            return this.getPositionPlaceholderValue(EditableSettingProperty.Left);
        },

        topPlaceholder() {
            return this.getPositionPlaceholderValue(EditableSettingProperty.Top);
        },

        methodPlaceholder() {
            return this.parentSettings.translationMethod !== undefined
                ? this.parentSettings.translationMethod
                : EditableTranslateMethod.Relative;
        }
    },

    methods: {
        clearStyles() {
            this.resetStyles();
            this.$emit("clear", this.css);
            this.relevantProps.forEach(prop => {
                this[prop] = undefined;
            });
        },

        getDomValue(prop) {
            if (this.isPsd) {
                return undefined;
            }

            try {
                const value = this.computedStyle.getPropertyValue(prop);
                if (prop === EditableSettingProperty.Height || prop === EditableSettingProperty.Width) {
                    const numericValue = Number.parseFloat(value);
                    if (Number.isNaN(numericValue) && this.boundingClientRect && this.boundingClientRect[prop]) {
                        return `${this.boundingClientRect[prop]}px`;
                    }
                }

                return value;
            } catch (e) {
                return undefined;
            }
        },

        getPositionPlaceholderValue(prop) {
            if (this.translationMethod === EditableTranslateMethod.Transform) {
                const transformStyle = this.getDomValue("transform");
                if (transformStyle === "none") {
                    return "0";
                }
                if (transformStyle.startsWith("matrix")) {
                    const [, , , , translateX, translateY] = transformStyle.split(",");
                    if (prop === EditableSettingProperty.Left) {
                        return translateX;
                    }

                    if (prop === EditableSettingProperty.Top) {
                        return translateY.replace(")", "");
                    }
                }
            }
            return this.getPlaceholderValue(prop);
        },

        onPositionKeyboardUpdate(prop, event) {
            if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                event.preventDefault();
                event.stopImmediatePropagation();

                let moveBy = this.getMoveByValue(event);
                if (this.steps[prop]) {
                    moveBy *= this.steps[prop];
                }

                let currentValue = parseFloat(this[prop]);
                if (Number.isNaN(currentValue)) {
                    if ([EditableSettingProperty.Left, EditableSettingProperty.Top].includes(prop)) {
                        currentValue = Number.parseFloat(this.getPositionPlaceholderValue(prop)) || 0;
                    } else {
                        currentValue = Number.parseFloat(this.getPlaceholderValue(prop)) || 0;
                    }
                }
                this.onNumericInput(prop, currentValue + moveBy);
            }
        },

        // eslint-disable-next-line complexity
        onMovePosition(event) {
            if (!event.ctrlKey || !this.handledKeys.includes(event.key)) {
                return;
            }

            if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
                this.$refs.left.focus();
                const moveBy = -this.getMoveByValue(event);
                let currentLeft = this.getCurrentValue(EditableSettingProperty.Left);

                if (Number.isNaN(currentLeft)) {
                    currentLeft =
                        Number.parseFloat(this.getPositionPlaceholderValue(EditableSettingProperty.Left)) || 0;
                }

                this.onNumericInput(EditableSettingProperty.Left, currentLeft + moveBy);
            } else if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                this.$refs.top.focus();
                const moveBy = -this.getMoveByValue(event);
                let currentTop = this.getCurrentValue(EditableSettingProperty.Top);

                if (Number.isNaN(currentTop)) {
                    currentTop = Number.parseFloat(this.getPositionPlaceholderValue(EditableSettingProperty.Top)) || 0;
                }

                this.onNumericInput(EditableSettingProperty.Top, currentTop + moveBy);
                event.stopImmediatePropagation();
            }

            event.preventDefault();
        },

        onMoveDimensions(event) {
            if (!event.ctrlKey || !this.handledKeys.includes(event.key)) {
                return;
            }

            if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
                this.$refs.width.focus();
                const moveBy = -this.getMoveByValue(event);
                const currentLeft = this.getCurrentValue(EditableSettingProperty.Width);

                this.onNumericInput(EditableSettingProperty.Width, currentLeft + moveBy);
            } else if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                this.$refs.height.focus();
                const moveBy = this.getMoveByValue(event);
                const currentTop = this.getCurrentValue(EditableSettingProperty.Height);

                this.onNumericInput(EditableSettingProperty.Height, currentTop + moveBy);
                event.stopImmediatePropagation();
            }

            event.preventDefault();
        },

        async onDimensionInput(prop, value) {
            const intValue = parseInt(value, 10);

            // PSD API does not allow object dimensions to be smaller then 4x4.
            if (this.isPsd && (Number.isNaN(intValue) || intValue < 4)) {
                this.onInput(prop, 4);
                return;
            }

            if (!this.isPsd && Number.isNaN(intValue) && this[prop]) {
                this.css[prop] = undefined;
                this[prop] = undefined;
                this.onStyleChange();
                this.elKeys[prop] += 1;

                await this.$nextTick();

                if (this.$refs[prop]) {
                    this.$refs[prop].focus();
                }
                return;
            }

            this.onInput(prop, intValue);
        },

        async onTranslationMethodInput(prop, value) {
            await this.onDimensionInput(EditableSettingProperty.Left, "");
            await this.onDimensionInput(EditableSettingProperty.Top, "");
            this.onStyleChange();
            this.onInput(prop, value);
        }
    }
};
</script>
<style lang="scss">
@import "../../../sass/variables";
.element-position {
    &__position {
        flex: 1 1 138px;
        width: 138px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}
</style>
