<template>
    <router-link class="campaign-card" :to="link">
        <card is-clickable>
            <div class="campaign-card__logo" :style="logoStyle">
                <Icon v-if="!logo" type="ios-image-outline" class="campaign-card__missing-logo" />
            </div>
            <template #title>
                {{ name }}
            </template>
            <template #actionItems>
                <Dropdown transfer trigger="click">
                    <card-footer-button data-testid="campaign-card__more-button">
                        <Icon type="ios-more" class="campaign-card-option--dots" />
                    </card-footer-button>
                    <DropdownMenu slot="list">
                        <DropdownItem
                            v-if="userCanEditCampaign"
                            data-testid="campaign-card__edit-button"
                            @click.native="edit"
                        >
                            Edit
                        </DropdownItem>
                        <DropdownItem data-testid="campaign-card__manage-team-button" @click.native="manageTeam">
                            Manage Team
                        </DropdownItem>
                        <DropdownItem
                            v-if="userCanEditCampaign"
                            data-testid="campaign-card__move-section-button"
                            @click.native="setSection"
                        >
                            Move to Section
                        </DropdownItem>
                        <DropdownItem
                            v-if="userCanAddCampaign"
                            data-testid="campaign-card__duplicate-button"
                            @click.native="duplicateCampaign"
                        >
                            Duplicate
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </template>
        </card>
    </router-link>
</template>

<script>
import Card from "@/components/Card/Card";
import CardFooterButton from "@/components/Card/CardFooterButton";

export default {
    components: {
        Card,
        CardFooterButton
    },
    props: {
        clientId: {
            required: true,
            type: String
        },
        id: {
            required: true,
            type: String
        },
        logo: {
            type: String
        },
        name: {
            required: true,
            type: String
        },
        hasJira: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        link() {
            if (this.$store.state.ui.domain === "analytics") {
                return {
                    name: "CIInsightsDashboard",
                    params: {
                        campaignId: this.id,
                        clientId: this.clientId
                    }
                };
            }
            return {
                name: this.$auth.userCan(this.$auth.Actions.CanManageOverwrites, {
                    clientId: this.clientId,
                    campaignId: this.id
                })
                    ? "CampaignEditor"
                    : "CampaignLibrary",
                params: {
                    campaignId: this.id,
                    clientId: this.clientId
                },
                query: { j: this.hasJira }
            };
        },
        logoStyle() {
            if (this.logo) {
                return {
                    backgroundImage: `url(${this.logo})`
                };
            }
            return {};
        },
        userCanAddCampaign() {
            return this.$auth.userCan(this.$auth.Actions.CanAddCampaign, {
                clientId: this.clientId
            });
        },
        userCanEditCampaign() {
            return this.$auth.userCan(this.$auth.Actions.CanEditCampaign, {
                clientId: this.clientId,
                campaignId: this.id
            });
        }
    },
    methods: {
        duplicateCampaign() {
            this.$emit("duplicate");
        },
        edit() {
            this.$emit("edit");
        },
        manageTeam() {
            this.$emit("manage-team");
        },
        setSection() {
            this.$emit("setSection");
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/_variables.scss";

$logo-height: 200px;

.campaign-card__logo {
    align-items: center;
    background: no-repeat center;
    background-size: cover;
    color: $grey5;
    display: flex;
    height: $logo-height;
    justify-content: center;
    width: 100%;
}

.campaign-card__logo--ci-demo {
    background-size: contain;
    max-height: 75%;
    max-width: 75%;
    flex: 0 0 auto;
    margin: 0 auto;
}

.campaign-card__missing-logo {
    align-items: center;
    display: flex;
    height: $logo-height;
    font-size: 80px;
    justify-content: center;
}
</style>
