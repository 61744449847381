import store from "@/store";
import { setOverwrite, removeOverwrite, persistData } from "./state";

const overwriteEditables = (overwrites, scope) => {
    overwrites.forEach(overwrite => {
        // Add the scope to the overwrite. This data is not sent in the request but is created when different mutations are used
        const set = { ...overwrite, scope };
        // If the overwrite has a mediaId then we need to get that specific mediaItem object and store it
        if (overwrite.mediaId) {
            // Find the overwrite in the vuex store as the mediaItem is set on it but not passed in the request
            const local = store.state.editor.localEditableOverwrites.find(
                ({ editable }) => editable._id === overwrite.editableId
            );
            const { mediaItem } = local.overwrites.find(lo => lo.mediaItem.id === overwrite.mediaId) || {};
            // Set the reference
            set.mediaItem = mediaItem;
        }
        // Store the overwrite in the local state
        setOverwrite(set);
    });
    // This is the same as the server response for this request
    return [];
};

export const overwriteEditablesEditableGroup = async (operation, serverUri) => {
    // Create the overwrites with the EDITABLE_GROUP scope
    const overwriteEditablesWithEditableGroupScope = overwriteEditables(
        operation.variables.overwrites,
        "EDITABLE_GROUP"
    );

    // Persist the data to the working directory as this mutation is called on the Save action
    // We pass true here as we want the overwrites to be persisted
    await persistData(serverUri, true);

    return {
        overwriteEditablesWithEditableGroupScope
    };
};

export const overwriteEditablesEditableGroupAndMasterTemplate = async (operation, serverUri) => {
    // Create the overwrites with the EDITABLE_GROUP_AND_MASTER_TEMPLATE scope
    const overwriteEditablesWithEditableGroupAndMasterTemplateScope = overwriteEditables(
        operation.variables.overwrites,
        "EDITABLE_GROUP_AND_MASTER_TEMPLATE"
    );

    // Persist the data to the working directory as this mutation is called on the Save action
    // We pass true here as we want the overwrites to be persisted
    await persistData(serverUri, true);

    return {
        overwriteEditablesWithEditableGroupAndMasterTemplateScope
    };
};

export const removeOverwrites = async (operation, serverUri) => {
    const { overwriteIds } = operation.variables;
    // Remove the overwrites from the local state
    overwriteIds.forEach(overwriteId => removeOverwrite(overwriteId));

    // Persist the changes, including changes to the overwrites
    await persistData(serverUri, true);

    return { removeOverwrites: overwriteIds.length };
};
