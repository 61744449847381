<template>
    <hox-modal resizable class="new-size-modal" @close="close">
        <template #header>New size</template>
        <div class="new-size-modal__body">
            <Select class="new-size-modal__predefined-values" @input="onDefaultSelected">
                <OptionGroup label="Facebook">
                    <Option
                        v-for="(item, dcIndex) in platforms.facebook"
                        :key="getKey(item)"
                        :value="`facebook-${dcIndex}`"
                        :label="item.name"
                        :disabled="sizeAlreadyExists(item.width, item.height)"
                    >
                        <span>{{ item.name }}</span>
                        <span class="new-size-modal__predefined-values-right">
                            {{ item.width }} x {{ item.height }}
                        </span>
                    </Option>
                </OptionGroup>

                <OptionGroup label="Instagram">
                    <Option
                        v-for="(item, dcIndex) in platforms.instagram"
                        :key="getKey(item)"
                        :value="`instagram-${dcIndex}`"
                        :label="item.name"
                        :disabled="sizeAlreadyExists(item.width, item.height)"
                    >
                        <span>{{ item.name }}</span>
                        <span class="new-size-modal__predefined-values-right">
                            {{ item.width }} x {{ item.height }}
                        </span>
                    </Option>
                </OptionGroup>

                <OptionGroup label="TikTok">
                    <Option
                        v-for="(item, dcIndex) in platforms.tiktok"
                        :key="getKey(item)"
                        :value="`tiktok-${dcIndex}`"
                        :disabled="sizeAlreadyExists(item.width, item.height)"
                    >
                        <span>{{ item.name }}</span>
                        <span class="new-size-modal__predefined-values-right">
                            {{ item.width }} x {{ item.height }}
                        </span>
                    </Option>
                </OptionGroup>

                <OptionGroup label="YouTube">
                    <Option
                        v-for="(item, dcIndex) in platforms.youtube"
                        :key="getKey(item)"
                        :value="`youtube-${dcIndex}`"
                        :disabled="sizeAlreadyExists(item.width, item.height)"
                    >
                        <span>{{ item.name }}</span>
                        <span class="new-size-modal__predefined-values-right">
                            {{ item.width }} x {{ item.height }}
                        </span>
                    </Option>
                </OptionGroup>

                <OptionGroup label="Google Display">
                    <Option
                        v-for="(item, dcIndex) in platforms.doubleclick"
                        :key="getKey(item)"
                        :value="`doubleclick-${dcIndex}`"
                        :disabled="sizeAlreadyExists(item.width, item.height)"
                    >
                        <span>{{ item.name }}</span>
                        <span class="new-size-modal__predefined-values-right">
                            {{ item.width }} x {{ item.height }}
                        </span>
                    </Option>
                </OptionGroup>
            </Select>
            <div class="new-size-modal__form">
                <span class="new-size-modal__form-item">size</span>
                <span class="new-size-modal__form-input-wrapper new-size-modal__form-item">
                    <Input
                        type="number"
                        class="new-size-modal__form-input"
                        :value="inputWidth"
                        @input="onInputChange('width', $event)"
                    >
                        <template slot="append">px</template>
                    </Input>
                </span>
                <span class="new-size-modal__form-item">x</span>
                <span class="new-size-modal__form-input-wrapper new-size-modal__form-item">
                    <Input
                        type="number"
                        class="new-size-modal__form-input"
                        :value="inputHeight"
                        @input="onInputChange('height', $event)"
                    >
                        <template slot="append">px</template>
                    </Input>
                </span>
            </div>
            <div class="new-size-modal__form-errors">
                <ul>
                    <li v-for="error in validationErrors" :key="error">{{ error }}</li>
                </ul>
            </div>
            <div class="new-size-modal__banner" test-id="new-size__banner">
                <master-template-preview
                    :style="internalBannerStyle"
                    resizable
                    :max-height="masterTemplate.height"
                    :max-width="masterTemplate.width"
                    :scaling-factor="scalingFactor"
                    :show-resize-header="showResizeHeader"
                    :master-template="masterTemplate"
                    :input-width="inputWidth"
                    :input-height="height"
                    @resized="onResize"
                />
            </div>
            <div v-if="hasError && !isCreating" class="new-size-modal__error">
                <hox-alert type="danger" size="small" align-text="center">
                    <template #content>
                        <p>There was an error when trying to create new size, please try again.</p>
                    </template>
                </hox-alert>
            </div>
        </div>
        <template #footer>
            <div>
                <Button class="new-size-modal__footer-button" @click="close">Close</Button>
                <Button
                    class="new-size-modal__footer-button"
                    type="primary"
                    :loading="isCreating"
                    :disabled="isCreating || !!validationErrors.length"
                    @click="create"
                >
                    {{ isCreating ? "Creating..." : "Create" }}
                </Button>
            </div>
        </template>
    </hox-modal>
</template>

<script>
import HoxModal from "@/components/Modal/Modal/Modal";
import MasterTemplatePreview from "@/components/Campaign/MasterTemplatePreview";

export default {
    components: {
        MasterTemplatePreview,
        HoxModal
    },
    props: {
        hasError: {
            type: Boolean,
            default: false
        },
        masterTemplate: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isCreating: false,
            scalingFactor: 1,
            showResizeHeader: false,
            internalBannerStyle: null,
            width: 100,
            height: 100,
            validationErrors: [],

            platforms: {
                facebook: [
                    { width: 1080, height: 1080, name: "Feed (1:1)" },
                    { width: 1080, height: 1285, name: "Portrait Feed (1.19:1)" },
                    { width: 1080, height: 1920, name: "Story (9:16)" }
                ],

                instagram: [
                    { width: 1080, height: 1080, name: "Feed (1:1)" },
                    { width: 1080, height: 1920, name: "Story (9:16)" }
                ],

                tiktok: [
                    { width: 1080, height: 1080, name: "In-Feed Square (1:1)" },
                    { width: 1080, height: 1920, name: "In-Feed Story (9:16)" },
                    { width: 1920, height: 1080, name: "In-Feed Story (16:9)" }
                ],

                youtube: [
                    { width: 169, height: 1080, name: "Story (9:16)" },
                    { width: 1920, height: 1080, name: "In-stream Video (16:9)" }
                ],

                doubleclick: [
                    { width: 200, height: 200, name: "Small square" },
                    { width: 240, height: 400, name: "Vertical rectangle" },
                    { width: 250, height: 250, name: "Square" },
                    { width: 250, height: 360, name: "Triple widescreen" },
                    { width: 300, height: 250, name: "Inline rectangle" },
                    { width: 336, height: 280, name: "Large rectangle" },
                    { width: 580, height: 400, name: "Netboard" },

                    { width: 120, height: 600, name: "Skyscraper" },
                    { width: 160, height: 600, name: "Wide skyscraper" },
                    { width: 300, height: 600, name: "Half-page ad" },
                    { width: 300, height: 1050, name: "Portrait" },

                    { width: 468, height: 60, name: "Banner" },
                    { width: 728, height: 90, name: "Leaderboard" },
                    { width: 930, height: 180, name: "Top banner" },
                    { width: 970, height: 90, name: "Large leaderboard" },
                    { width: 970, height: 250, name: "Billboard" },
                    { width: 980, height: 120, name: "Panorama" },

                    { width: 300, height: 50, name: "Mobile banner" },
                    { width: 320, height: 50, name: "Mobile banner" },
                    { width: 320, height: 100, name: "Large mobile banner" }
                ]
            }
        };
    },

    computed: {
        campaignMasterTemplateIds() {
            return this.$store.state.campaign.normalized.masterTemplateIds;
        },

        campaignMasterTemplates() {
            return this.$store.state.campaign.normalized.masterTemplates;
        },

        familyMembers() {
            return this.campaignMasterTemplateIds.reduce((acc, templateId) => {
                const { _id, parentId } = this.campaignMasterTemplates[templateId];
                if ([_id, parentId].includes(this.masterTemplate._id)) {
                    acc.push(this.campaignMasterTemplates[templateId]);
                }
                return acc;
            }, []);
        },

        inputHeight() {
            return this.height - 40;
        },

        inputWidth() {
            return this.width;
        }
    },

    watch: {
        hasError(v) {
            if (v) {
                this.isCreating = false;
            }
        }
    },

    mounted() {
        this.setInitialSize();
    },

    methods: {
        close() {
            this.$emit("close");
        },

        create() {
            this.isCreating = true;
            this.$emit("create", {
                width: this.inputWidth,
                height: this.inputHeight,
                masterTemplateId: this.masterTemplate._id
            });
        },

        getKey(item) {
            return `${item.width}${item.height}${item.name}`;
        },

        onDefaultSelected(item) {
            const [platform, index] = item.split("-");
            const size = this.platforms[platform][index];
            this.onInputChange("width", size.width);
            this.onInputChange("height", size.height);
        },

        onInputChange(prop, inputVal) {
            if (inputVal === "") {
                return;
            }

            const val = parseInt(inputVal, 10);

            if (val === this[prop]) {
                return;
            }

            this[prop] = val;

            this.validate();

            if (this.width > 4096) {
                this.width = 4096;
                return;
            }

            if (prop === "height") {
                /* when setting the container height manually we need to take into account
                the height of banner footer which is 40px high */
                // this.internalBannerStyle.height = `${val + 40}px`;
                this.height = val + 40;
            }
            // this.internalBannerStyle[prop] = `${val}px`;
        },

        onResize(sizes) {
            this.width = sizes.width;
            if (sizes.height) {
                this.height = sizes.height + 40;
            }

            if (sizes.height !== 40 && this.internalBannerStyle) {
                this.internalBannerStyle = null;
            }
        },

        setInitialSize() {
            this.height = this.masterTemplate.height;
            this.width = this.masterTemplate.width;

            this.internalBannerStyle = {
                height: `${this.masterTemplate.height + 40}px`,
                width: `${this.masterTemplate.width}px`,
                minHeight: `${this.masterTemplate.height + 40}px`,
                minWidth: `${this.masterTemplate.width}px`
            };
        },

        validate() {
            this.validationErrors = [
                ...this.validateSizeValue("width", this.width),
                ...this.validateSizeValue("height", this.height),
                ...this.validateFamilySizePresent(this.width, this.height)
            ];
        },

        validateSizeValue(prop, value) {
            const errors = [];
            if (Number.isNaN(value)) {
                errors.push(`${prop} value is not a valid number`);
                return errors;
            }

            if (value > 4096) {
                errors.push(`${prop} can't be larger then 4096px`);
            }

            if (value < 0) {
                errors.push(`${prop} can't be a negative number`);
            }

            return errors;
        },

        sizeAlreadyExists(width, height) {
            if (this.familyMembers.some(member => member.width === width && member.height === height)) {
                return true;
            }

            return false;
        },

        validateFamilySizePresent(width, height) {
            if (this.sizeAlreadyExists(width, height)) {
                return [`Template family already contains a template with size ${width} x ${height}`];
            }
            return [];
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.new-size-modal {
    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__predefined-values {
        width: 100%;
        margin-bottom: $spacing;

        &-right {
            float: right;
            color: #cccccc;
        }
    }

    &__form {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: $spacing-smaller;

        &-item {
            flex: 0 0;
            margin: 0 $spacing-small;
        }

        &-input-wrapper {
            display: block;
            white-space: nowrap;
            margin: 0 $spacing-smaller;
        }

        &-input {
            width: 75px;

            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            input[type="number"] {
                -moz-appearance: textfield;
            }
        }
    }

    &__form-errors {
        margin-bottom: $spacing;
        color: $red;
    }

    &__banner .campaign-banner-footer__options,
    &__banner .campaign-banner-footer__controls {
        display: none;
    }

    &__footer-button {
        margin: 0 0 0 $spacing-small;
    }
    &__error {
        width: 100%;
        margin-top: $spacing-large;
    }
}
</style>
