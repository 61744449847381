import { RoundedRect } from "@/components/CreativeIntelligence/GanttChart/element.roundedRect";
import { BubbleController, CategoryScale, Chart, LinearScale } from "chart.js";
import { valueOrDefault } from "chart.js/helpers";

const barHoverRadius = 8;

export class GanttController extends BubbleController {
    // eslint-disable-next-line class-methods-use-this
    parsePrimitiveData() {
        throw new Error("format not supported");
    }

    // eslint-disable-next-line class-methods-use-this
    parseArrayData() {
        throw new Error("format not supported");
    }

    /**
     * Parse array of objects
     * @protected
     */
    parseObjectData(meta, data, start, count) {
        const parsed = super.parseObjectData(meta, data, start, count);
        for (let i = 0; i < parsed.length; i += 1) {
            const item = data[start + i];
            parsed[i].x2 = valueOrDefault(item && item.x2 && +item.x2, this.resolveDataElementOptions(i + start).x2);
            parsed[i].backgroundColor = item.backgroundColor;
            parsed[i].hoverBackgroundColor = item.hoverBackgroundColor;
        }
        return parsed;
    }

    // eslint-disable-next-line class-methods-use-this
    getMaxOverflow() {
        return true;
    }

    /**
     * @protected
     */
    getLabelAndValue(index) {
        const meta = this._cachedMeta;
        const parsed = this.getParsed(index);

        return {
            label: meta.label,
            value: `(${parsed.x} - ${parsed.x2}, ${parsed.y})`
        };
    }

    // eslint-disable-next-line complexity
    updateElements(points, start, count, mode) {
        const reset = mode === "reset";
        const { iScale, vScale } = this._cachedMeta;
        const firstOpts = this.resolveDataElementOptions(start, mode);
        const sharedOptions = this.getSharedOptions(firstOpts);
        const includeOptions = this.includeOptions(mode, sharedOptions);
        const iAxis = iScale.axis;
        const vAxis = vScale.axis;

        for (let i = start; i < start + count; i += 1) {
            const point = points[i];
            const parsed = !reset && this.getParsed(i);
            const properties = {};
            // eslint-disable-next-line no-multi-assign
            const iPixel = (properties[iAxis] = reset
                ? iScale.getPixelForDecimal(0.5)
                : iScale.getPixelForValue(parsed[iAxis]));
            // eslint-disable-next-line no-multi-assign
            const vPixel = (properties[vAxis] = reset ? vScale.getBasePixel() : vScale.getPixelForValue(parsed[vAxis]));
            const data = this.chart.data.datasets[this.index].data[i];

            properties.skip = Number.isNaN(iPixel) || Number.isNaN(vPixel);

            if (includeOptions) {
                properties.options = this.resolveDataElementOptions(i, point.active ? "active" : mode);
                properties.options.backgroundColor = data.backgroundColor || properties.options.backgroundColor;
                properties.options.hoverBackgroundColor =
                    data.hoverBackgroundColor || properties.options.hoverBackgroundColor;

                if (reset) {
                    properties.options.x2 = properties.x;
                }
            }

            const x = iScale.getPixelForValue(data.x);
            const x2 = iScale.getPixelForValue(data.x2);
            const y = vScale.getPixelForValue(data.y);
            const props = {
                ...properties,
                x,
                x2,
                y,
                width: x2 - x
            };

            this.updateElement(point, i, props, mode);
        }

        this.updateSharedOptions(sharedOptions, mode, firstOpts);
    }

    /**
     * @param {number} index
     * @param {string} [mode]
     * @protected
     */
    resolveDataElementOptions(index, mode) {
        let values = super.resolveDataElementOptions(index, mode);

        // In case values were cached (and thus frozen), we need to clone the values
        if (values.$shared) {
            values = { ...values, $shared: false };
        }

        // overwriting default bubble chart behaviour
        if (mode === "active") {
            values.radius = barHoverRadius;
        }

        return values;
    }

    getMinMax(scale) {
        const { min: superMin, max: superMax } = super.getMinMax(scale);
        const meta = this._cachedMeta;
        const { _parsed } = meta;

        if (_parsed.length < 2) {
            return { min: 0, max: 1 };
        }

        if (scale === meta.vScale) {
            return { min: superMin + 1, max: superMax - 8 };
        }

        let min = Number.POSITIVE_INFINITY;
        let max = Number.NEGATIVE_INFINITY;
        for (let i = 0; i < _parsed.length; i += 1) {
            const data = _parsed[i];
            min = Math.min(min, data.x);
            max = Math.max(max, data.x2);
        }
        return { min, max };
    }
}

Chart.register(RoundedRect, GanttController);

GanttController.id = "Gantt";
GanttController.defaults = {
    datasetElementType: false,
    dataElementType: RoundedRect.id,
    hoverRadius: 8,
    radius: 8,
    animations: {
        numbers: {
            type: "number",
            properties: ["x", "y"]
        }
    }
};

GanttController.overrides = {
    scales: {
        x: {
            type: LinearScale.id
        },
        y: {
            type: CategoryScale.id
        }
    }
};
