<template>
    <div id="app" :class="themeClassName">
        <hox-loading-bar />
        <Layout>
            <Header v-if="isAuthorizedContentVisible && isClientSelected" class="hoxton-header">
                <navigation />
            </Header>
            <Layout>
                <Sider
                    :collapsible="true"
                    class="sidebarWrapper"
                    :value="!isSidebarOpen"
                    :collapsed-width="sidebarWidthCollapsed"
                    :width="sidebarWidthOpen"
                    :hide-trigger="true"
                >
                    <portal-target name="main-sidebar" slim></portal-target>
                </Sider>
                <Content class="main-content">
                    <router-view :key="$route.fullPath" class="hox-full-width router-view" />
                </Content>
            </Layout>
        </Layout>
        <div v-if="isAuthorizedContentVisible" class="hoxton-footer">
            <footer-nav theme="light" />
        </div>
    </div>
</template>
<script>
import Navigation from "@/components/Navigation";
import HoxLoadingBar from "@/HoxLoadingBar";
import themeMixin from "@/mixins/themeMixin";
import FooterNav from "@/FooterNav";

export default {
    name: "ThemeDefault",
    components: {
        FooterNav,
        HoxLoadingBar,
        Navigation
    },
    mixins: [themeMixin],

    data() {
        return {
            sidebarWidth: 300
        };
    },

    computed: {
        isClientSelected() {
            return "clientId" in this.$route.params;
        }
    }
};
</script>

<style lang="scss">
@import "../sass/variables";

.theme-default {
    .ivu-layout-sider {
        left: auto;
    }
}

.hoxton-footer {
    position: sticky;
    left: 0;
    bottom: 0;
    padding-left: $spacing-small;
    width: $campaign-vertical-nav-width;
    z-index: $zindex-footer;
}
</style>
