<template>
    <div :class="classes">
        <div @click.stop>
            <template v-if="isIe">
                <h3 class="qa-annotation-input-form__header">Add annotation</h3>
                <at :members="members" :value="internalContent" name-key="value">
                    <template #item="s">
                        <span v-text="s.item.label"></span>
                    </template>

                    <div
                        ref="editor"
                        class="qa-annotation-input-form__input"
                        contenteditable
                        @textinput="onIeInput"
                    ></div>
                </at>
            </template>

            <hox-mentionable
                v-else
                name-key="value"
                :value="internalContent"
                :members="members"
                :poptip-width="poptipWidth"
                @insert="onInsert"
            >
                <template #item="s">
                    <span v-if="s.item.alias">
                        <strong>{{ s.item.label }}</strong>
                    </span>
                    <span v-else v-text="s.item.label"></span>
                </template>
                <template #embeddedItem="s">
                    <span>
                        <span class="mentionable-tag" :title="s.current.label">@{{ s.current.value }}</span>
                    </span>
                </template>

                <div ref="editor" class="qa-annotation-input-form__input" contenteditable @input="onDivInput"></div>
            </hox-mentionable>

            <Tooltip v-if="mentioned.length" placement="bottom-start">
                <span class="link-like">{{ mentionedCountText }}</span>
                will be notified
                <template #content>
                    <p v-for="user in mentioned" :key="user">{{ user }}</p>
                </template>
            </Tooltip>
            <p v-else>
                Add
                <span class="mentionable-tag">@</span>
                to mention someone
            </p>
        </div>
        <div class="qa-annotation-input-form__buttons-container">
            <Button class="qa-annotation-input-form__button" :disabled="isSaving" type="default" @click.stop="cancel">
                <slot name="cancelButtonContent">Cancel</slot>
            </Button>
            <Button
                class="qa-annotation-input-form__button"
                :disabled="hasValidationErrors"
                :loading="isSaving"
                type="primary"
                @click.stop="save"
            >
                <slot name="saveButtonContent">Send</slot>
            </Button>
        </div>
    </div>
</template>

<script>
import At from "vue-at";
import HoxMentionable from "@/components/common/Mentionable";
import mentionableMixin from "@/mixins/mentionableMixin";
import { isIe } from "@/utils";

export default {
    components: { HoxMentionable, At },
    mixins: [mentionableMixin],
    props: {
        content: {
            type: String,
            default: ""
        },
        isSaving: {
            type: Boolean
        },
        marginBottom: {
            type: String,
            default: "small",
            validator(value) {
                return ["none", "small"].includes(value);
            }
        },
        poptipWidth: {
            type: String,
            default: "normal",
            validator(value) {
                return ["normal", "wide"].includes(value);
            }
        }
    },
    data() {
        return {
            internalContent: "",
            isIe: isIe(),
            mentioned: []
        };
    },
    computed: {
        classes() {
            const classes = ["qa-annotation-input-form"];
            if (this.marginBottom === "small") {
                classes.push("qa-annotation-input-form--margin-bottom-small");
            }
            return classes;
        },

        hasValidationErrors() {
            return !this.internalContent;
        },

        mentionedCountText() {
            return `${this.mentioned.length} ${this.mentioned.length === 1 ? "person" : "people"}`;
        },

        mentionedUserIds() {
            return this.members.filter(({ value }) => this.mentioned.includes(`@${value}`)).map(({ label }) => label);
        }
    },
    watch: {
        content: {
            handler() {
                this.internalContent = this.processTextForEdit(this.content);
            },
            immediate: true
        }
    },
    mounted() {
        if (this.isIe) {
            this.$refs.editor.innerHTML = this.internalContent;
        }
        this.focus();
    },
    methods: {
        cancel() {
            this.$emit("cancel");
        },
        focus() {
            if (!this.isIe) {
                this.$refs.editor.focus();
            }
        },

        onDivInput(e) {
            this.internalContent = e.target.innerHTML;
            this.updateMentioned(e.target.innerHTML);
        },

        onIeInput() {
            this.internalContent = this.$refs.editor.innerHTML;
            this.updateMentioned(this.$refs.editor.innerHTML);
        },

        onInsert() {
            this.updateMentioned(this.$refs.editor.innerHTML);
        },

        save() {
            let html = this.processTextForSave(this.$refs.editor.innerHTML);

            // We need to add additional spaces around the mentions tag
            html = html.replace(/<span class="mentionable-tag" [^>]*>(@[\w.\-@]+)<\/span>/, match => ` ${match} `);

            const div = document.createElement("div");
            div.innerHTML = html;
            const text = div.textContent || div.innerText || "";

            this.$emit("save", {
                content: text.replace("  ", " "),
                mentions: this.mentionedUserIds
            });
        },

        processTextForEdit(text) {
            let value = text;
            value = value.replace(/\n+\s+\n+/g, "\n\n");
            value = value.replace(/\n\n+/g, "\n\n");
            value = value.replace(/\n/g, "<br>");
            value = value.replace(/\s+/g, " ");

            return value;
        },

        processTextForSave(html) {
            // https://gist.github.com/nathansmith/86b5d4b23ed968a92fd4
            let value = html;
            // Convert `&amp;` to `&`.
            value = value.replace(/&amp;/gi, "&");

            // Replace spaces.
            value = value.replace(/&nbsp;/gi, " ");

            // Tighten up "<" and ">".
            value = value.replace(/>\s+/g, ">");
            value = value.replace(/\s+</g, "<");

            // Replace "<div><br></div>" - new line in chrome
            value = value.replace(/<div><br><\/div>/gi, "\n");

            // Replace "<br>".
            value = value.replace(/<br>/gi, "\n");

            // Replace "<div>" (from Chrome).
            value = value.replace(/<div>/gi, "\n");
            value = value.replace(/<\/div>/gi, "");

            // Replace "<p>" (from IE).
            value = value.replace(/<p>/gi, "\n");
            value = value.replace(/<\/p>/gi, "");

            // No more than 2x newline, per "paragraph".
            value = value.replace(/\n\n+/g, "\n\n");

            return value;
        },

        getAllMatches(regExp, str) {
            const matches = [];

            // eslint-disable-next-line no-constant-condition
            while (true) {
                const match = regExp.exec(str);
                if (match === null) {
                    break;
                }
                matches.push(match[3]);
            }
            return matches;
        },

        updateMentioned(text) {
            let parsed = this.getAllMatches(/((^|\s|>|;))(@[\w.\-@]+)/g, text);

            if (parsed.includes("@campaign")) {
                parsed = [].concat(
                    parsed.filter(i => i !== "@campaign"),
                    this.members.map(({ value }) => `@${value}`)
                );
            }
            const unique = [...new Set(parsed)];

            this.mentioned = unique.filter(mention => this.validMentions.includes(mention));
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.qa-annotation-input-form {
    padding: $spacing 2px 0 2px;

    &--margin-bottom-small {
        margin: 0 0 $spacing-small;
    }

    .atwho-view {
        max-height: 86px;
    }
}

.qa-annotation-input-form__header {
    font-size: 18px;
    line-height: 1em;
    margin: 0 32px 16px 0;
    padding: 16px 0;
}

.qa-annotation-input-form__input {
    min-height: 6em;
    width: 100%;
    border: 1px solid $grey3;
    white-space: pre-wrap;
    margin-bottom: $spacing-small;
    padding: 6px;
    outline-color: white;

    &:focus {
        border: 1px solid $grey5;
    }
}

.qa-annotation-input-form .qa-annotation-input-form__button {
    margin: 0 0 0 $spacing-small;

    .ivu-icon-ios-loading {
        position: absolute;
        right: calc(50% - 10px);
        top: calc(50% - 10px);
    }

    &.ivu-btn > .ivu-icon + span {
        margin-left: 0;
    }
}

.qa-annotation-input-form__buttons-container {
    display: flex;
    justify-content: flex-end;
    padding: $spacing-small 0 0 0;
}

.mentionable-usage-description__icon {
    color: $grey5;
    margin-bottom: $spacing;
}

.mentionable-tag {
    color: $blue;
}
</style>
