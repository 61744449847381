<template>
    <div :class="classes" @click="selectAnnotation(annotation)">
        <qa-confirmation-modal
            v-if="confirmAnnotationDeletionModalIsVisible"
            :is-doing-action="isDeletingAnnotation"
            @cancel="toggleConfirmAnnotationDeletionModal"
            @confirm="deleteAnnotation"
        >
            <template #title>Are you sure you want to delete the annotation?</template>
            <template #content>
                <p>
                    Deleting an annotation is permanent: it can not be recovered. Any comments made on the annotation
                    will also be deleted.
                </p>
            </template>
            <template #confirmButtonContent>Delete annotation</template>
        </qa-confirmation-modal>
        <div class="qa-single-annotation__header">
            <div class="qa-single-annotation__header-metadata">
                <div class="qa-single-annotation__annotation-dot-wrapper">
                    <qa-annotation-dot :is-resolved="isResolved" size="small" :type="annotation.type">
                        {{ annotation.displayIndex }}
                    </qa-annotation-dot>
                </div>
                <div class="qa-single-annotation__info">
                    <p class="qa-single-annotation__user-name" :title="annotation.author">
                        {{ annotation.author }}
                    </p>
                    <p class="qa-single-annotation__date">
                        {{ formattedTimestamp }}
                    </p>
                </div>
            </div>
            <div
                v-if="(isLoggedInUsersAnnotation || userCanManageOtherUsersAnnotations) && !isResolved"
                class="qa-single-annotation__action-items"
            >
                <square-button
                    v-if="isLoggedInUsersAnnotation"
                    :has-inverse-hover="!isEditing"
                    :is-solid="isEditing"
                    size="small"
                    @click.stop="toggleIsEditing"
                >
                    <Icon type="md-create" />
                </square-button>
                <square-button
                    v-if="isLoggedInUsersAnnotation || userCanManageOtherUsersAnnotations"
                    has-inverse-hover
                    size="small"
                    @click.stop="toggleConfirmAnnotationDeletionModal"
                >
                    <Icon type="md-trash" />
                </square-button>
            </div>
        </div>
        <qa-annotation-input-form
            v-if="isEditing"
            :content="sanitizedAnnotationDescription"
            :is-saving="isUpdatingAnnotation"
            @cancel="toggleIsEditing"
            @save="updateAnnotation"
        />
        <!--
      The content in the following element is whitespace sensitive in order to
      display the annotation as intended by the user.
    -->
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-else class="qa-single-annotation__body" v-html="descriptionPreview"></div>
        <div v-if="userCanSeeComments" class="qa-single-annotation__comments-wrapper">
            <qa-annotation-comments
                :annotation-is-resolved="isResolved"
                :comments="annotation.comments"
                :comments-are-visible="commentsAreVisible"
                @commentDeleted="removeComment"
                @commentUpdated="replaceComment"
                @setCommentsAreVisible="setCommentsAreVisible"
            />
        </div>
        <div v-if="isAddingComment" class="qa-single-annotation__add-comment-container">
            <qa-annotation-input-form :is-saving="isSavingComment" @cancel="toggleIsAddingComment" @save="addComment">
                <template #saveButtonContent>Comment</template>
            </qa-annotation-input-form>
        </div>
        <div :class="footerClasses">
            <div v-if="!isResolved && userCanAddComment">
                <a href="#" @click.stop.prevent="toggleIsAddingComment">Add Comment</a>
            </div>
            <div @click.stop>
                <labelled-switch
                    v-if="isLoggedInUsersAnnotation || userCanManageOtherUsersAnnotations"
                    :loading="isSettingAnnotationStatus"
                    size="small"
                    type="info"
                    :value="isResolved"
                    @change="setAnnotationStatus"
                >
                    Resolved
                </labelled-switch>
                <template v-else-if="isResolved">
                    <span class="qa-single-annotation__resolution-text qa-single-annotation__resolution-text--resolved">
                        Resolved
                    </span>
                </template>
                <template v-else>
                    <span
                        class="qa-single-annotation__resolution-text qa-single-annotation__resolution-text--unresolved"
                    >
                        Unresolved
                    </span>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import LabelledSwitch from "@/components/Campaign/LabelledSwitch";
import SquareButton from "@/components/common/SquareButton";
import QaAnnotationComments from "@/components/Qa/QaAnnotationComments";
import QaAnnotationDot from "@/components/Qa/QaAnnotationDot";
import QaAnnotationInputForm from "@/components/Qa/QaAnnotationInputForm";
import QaConfirmationModal from "@/components/Qa/QaConfirmationModal";
import { AnnotationStatus, AnnotationType } from "@/enums/annotations";
import { addCommentToAnnotation, deleteAnnotation, setAnnotationStatus, updateAnnotation } from "@/services/Qa";
import { formatTimestamp, sanitizeHTML } from "@/utils";
import mentionableMixin from "@/mixins/mentionableMixin";

export default {
    components: {
        LabelledSwitch,
        QaAnnotationComments,
        QaAnnotationDot,
        QaAnnotationInputForm,
        QaConfirmationModal,
        SquareButton
    },
    mixins: [mentionableMixin],
    props: {
        annotation: {
            required: true,
            type: Object
        },
        isSelected: {
            type: Boolean
        }
    },
    data() {
        return {
            commentsAreVisible: false,
            confirmAnnotationDeletionModalIsVisible: false,
            isAddingComment: false,
            isDeletingAnnotation: false,
            isEditing: false,
            isSavingComment: false,
            isSettingAnnotationStatus: false,
            isUpdatingAnnotation: false
        };
    },
    computed: {
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        classes() {
            const classes = ["qa-single-annotation"];
            if (this.isSelected) {
                if (this.annotation.type === AnnotationType.Internal) {
                    classes.push("qa-single-annotation--internal-selected");
                } else if (this.annotation.type === AnnotationType.External) {
                    classes.push("qa-single-annotation--external-selected");
                }
            }
            return classes;
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        descriptionPreview() {
            const regexMentions = [...this.validMentions].sort().reverse().join("|");
            const regex = new RegExp(`\\B(${regexMentions})(\\s|$)`, "g");
            return this.sanitizedAnnotationDescription.replace(
                regex,
                match => `<span class="mentionable-tag">${match}</span>`
            );
        },

        footerClasses() {
            const classes = ["qa-single-annotation__footer"];
            if (this.isResolved || !this.userCanAddComment) {
                classes.push("qa-single-annotation__footer--align-content-right");
            }
            if (!this.userCanSeeComments) {
                classes.push("qa-single-annotation__footer--border-top");
            }
            return classes;
        },
        formattedTimestamp() {
            return formatTimestamp(this.annotation.created);
        },
        hasComments() {
            return this.annotation.comments.length > 0;
        },
        isInternal() {
            return this.annotation.type === AnnotationType.Internal;
        },
        isLoggedInUsersAnnotation() {
            return this.$store.state.auth.me.id === this.annotation.author;
        },

        isResolved() {
            return this.annotation.status === AnnotationStatus.Resolved;
        },

        sanitizedAnnotationDescription() {
            return sanitizeHTML(this.annotation.description);
        },

        userCanAddComment() {
            if (this.isInternal) {
                return this.$auth.userCan(this.$auth.Actions.CanManageInternalComments, {
                    clientId: this.clientId,
                    campaignId: this.campaignId
                });
            }
            return this.$auth.userCan(this.$auth.Actions.CanManageExternalComments, {
                clientId: this.clientId,
                campaignId: this.campaignId
            });
        },

        userCanManageOtherUsersAnnotations() {
            return this.$auth.userCan(this.$auth.Actions.CanManageOtherUsersAnnotations);
        },

        userCanSeeComments() {
            if (this.isInternal) {
                return this.$auth.userCan(this.$auth.Actions.CanListInternalComments);
            }
            return this.$auth.userCan(this.$auth.Actions.CanListExternalComments);
        }
    },
    watch: {
        isSelected() {
            if (this.isSelected) {
                const positionFromTopOfDocument = this.$el.getBoundingClientRect().top;
                this.$emit("positionFromTopOfDocument", positionFromTopOfDocument);
            }
        }
    },
    methods: {
        async addComment({ content, mentions }) {
            this.isSavingComment = true;
            try {
                const comment = await addCommentToAnnotation(this.annotation._id, content, mentions);
                this.$emit("annotationUpdated", {
                    ...this.annotation,
                    comments: [...this.annotation.comments, comment]
                });
                /*
                    We want to show the just added comment, so we make sure that the
                    comments are visible.
                */
                this.commentsAreVisible = true;
                this.toggleIsAddingComment();
                this.$snackbar.success("Comment added.");
            } catch (err) {
                this.$snackbar.error(
                    "There was an unexpected error and the comment was unable to be saved. Please try again in a few moments."
                );
            }
            this.isSavingComment = false;
        },
        async deleteAnnotation() {
            this.isDeletingAnnotation = true;
            try {
                await deleteAnnotation(this.annotation._id);
                this.$emit("annotationDeleted", this.annotation);
                this.$snackbar.success("Annotation deleted.");
            } catch (err) {
                this.$snackbar.error(
                    "There was an unexpected error and the annotation was unable to be deleted. Please try again in a few moments."
                );
            }
            this.isDeletingAnnotation = false;
        },
        removeComment(commentToRemove) {
            const comments = this.annotation.comments.filter(comment => comment._id !== commentToRemove._id);
            this.$emit("annotationUpdated", {
                ...this.annotation,
                comments
            });
        },
        replaceComment(commentToReplace) {
            const comments = this.annotation.comments.map(comment => {
                if (comment._id === commentToReplace._id) {
                    return commentToReplace;
                }
                return comment;
            });
            this.$emit("annotationUpdated", {
                ...this.annotation,
                comments
            });
        },
        selectAnnotation(annotation) {
            this.$emit("annotationSelected", annotation);
        },
        async setAnnotationStatus(isResolved) {
            this.isSettingAnnotationStatus = true;
            const status = isResolved ? AnnotationStatus.Resolved : AnnotationStatus.Unresolved;
            try {
                const updatedAnnotation = await setAnnotationStatus(this.annotation._id, status);
                this.$emit("annotationUpdated", updatedAnnotation);
                if (isResolved) {
                    this.$snackbar.success("Annotation set as resolved.");
                } else {
                    this.$snackbar.success("Annotation set as unresolved.");
                }
            } catch (err) {
                this.$snackbar.error(
                    "There was an unexpected error and the annotation status was unable to be set. Please try again in a few moments."
                );
            }
            this.isSettingAnnotationStatus = false;
        },
        setCommentsAreVisible(commentsAreVisible) {
            this.commentsAreVisible = commentsAreVisible;
        },
        toggleConfirmAnnotationDeletionModal() {
            this.confirmAnnotationDeletionModalIsVisible = !this.confirmAnnotationDeletionModalIsVisible;
        },
        toggleIsAddingComment() {
            this.isAddingComment = !this.isAddingComment;
        },
        toggleIsEditing() {
            this.isEditing = !this.isEditing;
        },
        async updateAnnotation({ content, mentions }) {
            this.isUpdatingAnnotation = true;
            try {
                const updatedAnnotation = await updateAnnotation(
                    this.annotation._id,
                    content,
                    this.annotation.timeline,
                    this.annotation.xpos,
                    this.annotation.ypos,
                    mentions
                );
                this.toggleIsEditing();
                this.$emit("annotationUpdated", updatedAnnotation);
                this.$snackbar.success("Annotation updated.");
            } catch (err) {
                this.$snackbar.error(
                    "There was an unexpected error and the annotation was unable to be updated. Please try again in a few moments."
                );
            }
            this.isUpdatingAnnotation = false;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

$selected-box-shadow-size: 3px;

.qa-single-annotation {
    background: $white;
    border: 1px solid $grey3;
    cursor: pointer;
    margin: 0 0 $spacing-small;
    padding: $spacing;
    transition:
        border-color 0.2s linear,
        box-shadow 0.2s linear;

    &:hover {
        border-color: $darkgrey2;
        box-shadow: 3px 5px 8px 3px lighten($darkgrey2, 15%);
    }
}

.qa-single-annotation--internal-selected {
    border-color: $darkgrey1;
    box-shadow: 3px 5px 8px 3px lighten($darkgrey1, 15%);
    cursor: default;

    &:hover {
        border-color: $darkgrey2;
        box-shadow: 3px 5px 8px 3px lighten($darkgrey2, 15%);
    }
}

.qa-single-annotation--external-selected {
    border-color: $external-annotation-color;
    box-shadow: 0 0 0 $selected-box-shadow-size $external-annotation-color;
    cursor: default;

    &:hover {
        border-color: $external-annotation-color;
        box-shadow: 0 0 0 $selected-box-shadow-size $external-annotation-color;
    }
}

.qa-single-annotation__action-items {
    display: flex;
}

.qa-single-annotation__add-comment-container {
    border-bottom: 1px solid $grey3;
    margin: 0 (0 - $spacing) 0 0;
    padding: $spacing-small $spacing 0 0;
}

.qa-single-annotation__annotation-dot-wrapper {
    align-self: flex-start;
}

.qa-single-annotation__body {
    font-size: $font-size-small;
    margin: 0 0 $spacing;
    white-space: pre-wrap;
}

.qa-single-annotation__comments-wrapper {
    border-top: 1px solid $grey3;
    margin: 0 (0 - $spacing) 0 0;

    .ivu-collapse-content > .ivu-collapse-content-box {
        border-bottom: 1px solid $grey3;
    }
}

.qa-single-annotation__date {
    color: $grey5;
    font-size: $font-size-small;
}

.qa-single-annotation__footer {
    display: flex;
    justify-content: space-between;
    margin: 0 (0 - $spacing) 0 0;
    padding: $spacing-small $spacing 0 0;
}

.qa-single-annotation__footer--align-content-right {
    justify-content: flex-end;
}

.qa-single-annotation__footer--border-top {
    border-top: 1px solid $grey3;
}

.qa-single-annotation__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 0 $spacing-small;
}

.qa-single-annotation__header-metadata {
    align-items: center;
    display: flex;
    flex: 1;
    overflow: hidden;
}

.qa-single-annotation__info {
    padding: 0 $spacing-smaller 0 $spacing-small;
    overflow: hidden;
}

.qa-single-annotation__resolution-text {
    font-weight: bold;
}

.qa-single-annotation__resolution-text--resolved {
    color: $success-color;
}

.qa-single-annotation__resolution-text--unresolved {
    color: $grey5;
}

.qa-single-annotation__user-name {
    font-size: $font-size-small;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
