import { deepClone } from "@/utils";

/**
 * Exports URLManager used process the state and sync the url
 * @type {class}
 */
export default class URLManager {
    /**
     * URLManager constructor
     * @param  {VueRouter} router The VueRouter object
     * @param  {Object} opts      Options to manage the URL
     * @return {void}
     */
    constructor(router, opts = {}) {
        this.$router = router;
        this.URLHistoryTimer = Date.now();
        this.setOptions(opts);
    }

    /**
     * Returns the default options
     * @return {String}
     */
    static get defaults() {
        return {
            defaults: [],
            tracked: [],
            threshold: 700
        };
    }

    /**
     * Returns the query object
     * @return {Object}
     */
    get query() {
        return this.$router.currentRoute.query;
    }

    /**
     * Returns the query string from the url
     * @return {String}
     */
    get queryString() {
        // Encode param
        const mapParams = (key, values) =>
            values.map(value => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        // Loop query object to build new string
        return Object.keys(this.query)
            .map(key => {
                // Map single value if not Array
                if (!Array.isArray(this.query[key])) {
                    return mapParams(key, [this.query[key]]);
                }
                // Map entire Array
                return mapParams(key, this.query[key]).join("&");
            })
            .join("&");
    }

    /**
     * Returns the first portion of the filter name as some have colons i.e. gv:location
     * @param  {String} key The filter name to be split
     * @return {String}
     */
    static filterKey(key) {
        return key.split(":")[0];
    }

    /**
     * Validates and sets the options
     * @param  {Object} opts The options for the URLManager
     * @return {void}
     */
    setOptions(opts) {
        if (!opts || typeof opts !== "object") {
            throw new Error("URLManager expects an object to be passed");
        }

        const validateDefaults = () => {
            if (typeof opts.defaults !== "undefined") {
                if (!Array.isArray(opts.defaults)) {
                    throw new Error("URLManager expects defaults to be an Array");
                } else {
                    const valid = opts.defaults.every(
                        defaultFilter =>
                            defaultFilter &&
                            typeof defaultFilter === "object" &&
                            defaultFilter.key &&
                            defaultFilter.value
                    );

                    if (!valid) {
                        throw new Error(
                            "URLManager expects defaults to contain only objects with key and value properties"
                        );
                    }
                }
            }
        };

        const validateTracked = () => {
            if (typeof opts.tracked !== "undefined") {
                if (!Array.isArray(opts.tracked)) {
                    throw new Error("URLManager expects tracked to be an Array");
                } else {
                    const valid = opts.tracked.every(trackedFilter => typeof trackedFilter === "string");

                    if (!valid) {
                        throw new Error("URLManager expects tracked to contain only strings");
                    }
                }
            }
        };

        const validateThreshold = () => {
            if (typeof opts.threshold !== "undefined") {
                const threshold = parseInt(opts.threshold, 10);
                const valid = !Number.isNaN(threshold) && threshold >= 0;

                if (!valid) {
                    throw new Error("URLManager expects threshold to be a number greater than or equal to 0");
                }
            }
        };

        validateDefaults();
        validateTracked();
        validateThreshold();

        this.opts = { ...URLManager.defaults, ...opts };
    }

    /**
     * Returns the array of tracked parameters
     * @param  {Array} filters The new state filters
     * @return {Array}
     */
    trackedFilters(filters = []) {
        // Reduce new array of tracked params
        return filters.reduce((tracked, filter) => {
            // Push to tracked array is filter is supposed to be tracked
            if (this.opts.tracked.includes(URLManager.filterKey(filter.key))) {
                tracked.push(filter.key);
            }
            // Return the tracked array
            return tracked;
        }, []);
    }

    /**
     * Returns a default filter if the param value is the default value
     * @param  {Array} filters The new state filters
     * @return {Object}
     */
    isDefaultValue(filter) {
        const filterValue = value => JSON.stringify([...value].sort());
        return this.opts.defaults.find(
            defaultFilter =>
                defaultFilter.key === URLManager.filterKey(filter.key) &&
                filterValue(filter.value) === filterValue(defaultFilter.value)
        );
    }

    /**
     * Returns a the query string of the new state
     * @param  {Array} params The new state params
     * @return {String}
     */
    getStateAsQueryString(params) {
        const filters = deepClone(params);
        const valid = filters.every(filter => filter && typeof filter === "object" && filter.key && filter.value);
        // Make sure passed params are a valid structure
        if (!valid) {
            throw new Error("URLManager expects filters to contain only objects with key and value properties");
        }
        // Get tracked filters
        const trackedFilters = this.trackedFilters(filters);
        const filterNotTracked = key => !trackedFilters.includes(key);
        // Loop filters and update state
        filters.forEach(filter => {
            // Filter not tracked so ignore
            if (filterNotTracked(filter.key)) {
                return;
            }
            // Delete filter if there are no values or the default value
            if (filter.value.length === 0 || this.isDefaultValue(filter)) {
                delete this.query[filter.key];
            } else if (filter.value.length === 1) {
                this.query[filter.key] = filter.value.pop();
            } else {
                this.query[filter.key] = filter.value;
            }
        });
        // Return new query string
        return this.queryString;
    }

    /**
     * Updates the URL with the new params from the changed state
     * @param  {Array} params The new state params
     * @param  {Boolean} replace Flag used to force replace state on initial load
     * @return {void}
     */
    setURLParams(params, replace = false) {
        // Store current query string for comparison
        const currentQueryString = this.queryString;
        // If the query string is not different then return
        if (currentQueryString === this.getStateAsQueryString(params)) {
            return;
        }
        // Get current timestamp to only update history when time is greater than threshold
        const now = Date.now();
        // Build new state for history
        let state = this.queryString + window.location.hash;
        state = (state === "" ? document.location.pathname : "?") + state;
        // Test whether the history of the browser should be updated or replaced
        if (this.URLHistoryTimer > now || replace) {
            window.history.replaceState(null, "", state);
        } else {
            window.history.pushState(null, "", state);
        }
        // Update timer for next change
        this.URLHistoryTimer = now + this.opts.threshold;
    }
}
