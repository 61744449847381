import path from "path";
import { slugify, slugifyFileName } from "../utils";

export default filePath => {
    const name = slugifyFileName(path.basename(filePath));
    const dirnameParts = path
        .dirname(filePath)
        .split(path.sep)
        .map(dirPart => slugify(dirPart));
    return path.join(...dirnameParts, name);
};
