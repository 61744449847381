<template>
    <hox-modal data-testid="share-links__modal" @close="close">
        <template #header>Share Preview Links</template>
        <div class="share-preview-modal__title-row">
            <h4 class="share-preview-modal__title">{{ deliverablesTitle }}</h4>
            <Button
                v-if="!showPreviewUrls"
                :loading="isGettingPreviewUrls"
                ghost
                type="primary"
                @click="getPreviewUrls"
            >
                <template v-if="isGettingPreviewUrls">Getting shareable links</template>
                <template v-else>Get shareable links</template>
            </Button>
            <Tooltip v-else-if="computedDeliverables.length > 1" :disabled="!showAllCopiedTooltip" placement="left">
                <template #content>Copied to clipboard</template>
                <Button
                    ghost
                    type="primary"
                    @click="copyAllPreviewUrlsToClipboard"
                    @mouseleave.native="disableAllCopiedTooltip"
                >
                    Copy all
                </Button>
            </Tooltip>
        </div>
        <Alert v-if="showPreviewUrls" banner type="warning">
            Preview links are temporary
            <template #desc>They are only valid for 30 days. After this time they will no longer work.</template>
        </Alert>
        <hox-alert v-if="hasErrorGettingPreviewUrls" type="danger">
            <template #title>There was an error while trying to get the shareable links</template>
            <template #content>
                <p>Hopefully it was only a temporary issue and should work if you try again in a couple of moments.</p>
            </template>
            <template #actionItems>
                <Button ghost type="error" @click="getPreviewUrls">Retry getting shareable links</Button>
            </template>
        </hox-alert>
        <div :class="deliverablesContainerClasses">
            <div v-for="deliverable of visibleDeliverables" :key="deliverable.idHash">
                <p :title="deliverable.reportingLabel" class="share-preview-modal__deliverable-label">
                    {{ deliverable.reportingLabel }}
                </p>
                <div v-if="showPreviewUrls" class="share-preview-modal__preview-url-row">
                    <div class="share-preview-modal__preview-url-input-wrapper">
                        <Input :value="deliverable.previewUrl" readonly />
                    </div>
                    <Tooltip :disabled="!showCopiedTooltipByIdHash[deliverable.idHash]" placement="left">
                        <template #content>Copied to clipboard</template>
                        <Button
                            ghost
                            type="primary"
                            @click="copyPreviewUrlToClipboard(deliverable.idHash, deliverable.previewUrl)"
                            @mouseleave.native="disableCopiedTooltip(deliverable.idHash)"
                        >
                            Copy
                        </Button>
                    </Tooltip>
                </div>
            </div>
            <div
                v-if="!showAllDeliverables && visibleDeliverables.length < computedDeliverables.length"
                class="share-preview-modal__show-all-deliverables-container"
            >
                <Button size="small" @click="toggleShowAllDeliverables">
                    Show all {{ deliverables.length }} deliverables
                </Button>
            </div>
        </div>
        <h4 class="share-preview-modal__title">Recipient Email Addresses</h4>
        <multi-input
            v-model="emailAddresses"
            :error="validationErrors.emailAddresses"
            :errors-by-index="validationErrors.emailAddressesByIndex"
            :show-errors="showValidationErrors"
            new-value-placeholder="Type here to add an email address"
            data-testid="share-links__modal-email"
            @inputRemoved="onInputRemoved"
            @inputBlur="onInputBlur"
        />
        <Alert v-if="hasErrorSendingPreviewUrls" banner type="error">
            <template #desc>
                There was an unexpected error and the preview links were not shared. Hopefully it was a temporary issue
                and should work if you try again in a few moments.
            </template>
        </Alert>
        <template #footer>
            <div class="share-preview-modal__footer-button-wrapper">
                <Button data-testid="share-links__modal-cancel-button" @click="close">Cancel</Button>
            </div>
            <div class="share-preview-modal__footer-button-wrapper">
                <Button
                    :disabled="hasValidationErrors && showValidationErrors"
                    :loading="isSendingPreviewUrls"
                    type="primary"
                    data-testid="share-links__modal-send-button"
                    @click="sendPreviewLinks"
                >
                    <template v-if="isSendingPreviewUrls">Sending Preview Links</template>
                    <template v-else>Send Preview Links</template>
                </Button>
            </div>
        </template>
    </hox-modal>
</template>

<script>
import Vue from "vue";
// eslint-disable-next-line import/no-extraneous-dependencies
import validateEmail from "shared-utils/validateEmail";
import computeDeliverablePreview from "@/apollo/mutations/v2/ComputeDeliverablePreview.gql";
import HoxAlert from "@/components/common/Alert";
import MultiInput from "@/components/common/MultiInput";
import HoxModal from "@/components/Modal/Modal/Modal";
import DeliverablesService from "@/services/Deliverables";

const maxVisibleDeliverables = 5;

export default {
    components: {
        HoxAlert,
        HoxModal,
        MultiInput
    },
    props: {
        campaignId: {
            required: true,
            type: String
        },
        deliverables: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            blurredInputs: [],
            emailAddresses: [],
            hasErrorGettingPreviewUrls: false,
            hasErrorSendingPreviewUrls: false,
            isGettingPreviewUrls: false,
            isSendingPreviewUrls: false,
            previewUrlsByIdHash: {},
            showAllCopiedTooltip: false,
            showAllDeliverables: false,
            showCopiedTooltipByIdHash: {},
            showPreviewUrls: false,
            showValidationErrors: false
        };
    },
    computed: {
        canShareDeliverable() {
            return this.$auth.userCan(this.$auth.Actions.CanShareDeliverable, {
                clientId: this.clientId,
                campaignId: this.campaignId
            });
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        computedDeliverableIdentifiers() {
            return DeliverablesService.getComputedDeliverableIdentifiers(this.deliverables);
        },
        computedDeliverables() {
            return this.deliverables.map(deliverable => {
                const previewUrl = this.previewUrlsByIdHash[deliverable.idHash];
                return {
                    idHash: deliverable.idHash,
                    previewUrl,
                    reportingLabel: deliverable.reportingLabel
                };
            });
        },
        deliverablesContainerClasses() {
            const classes = ["share-preview-modal__deliverables-container"];
            if (this.showAllDeliverables) {
                classes.push("share-preview-modal__deliverables-container--scrollable");
            }
            return classes;
        },
        deliverablesTitle() {
            return this.deliverables.length > 1
                ? `${this.deliverables.length} Deliverables`
                : `${this.deliverables.length} Deliverable`;
        },
        hasValidationErrors() {
            return Object.keys(this.validationErrors).length > 0;
        },
        reportingLabels() {
            return this.computedDeliverables.reduce((acc, { idHash, reportingLabel }) => {
                acc[idHash] = reportingLabel;
                return acc;
            }, {});
        },
        validationErrors() {
            const errors = {};
            const toValidate = this.emailAddresses.filter((v, i) => !!this.blurredInputs[i]);

            if (toValidate.length === 0) {
                errors.emailAddresses = ["At least 1 email address must be provided in order to share preview links."];
            } else {
                const emailAddressErrorsByIndex = {};
                toValidate.forEach((emailAddress, index) => {
                    const isValid = validateEmail(emailAddress);
                    if (!isValid) {
                        emailAddressErrorsByIndex[index] = [
                            `'${emailAddress}' doesn’t look like an email address. Please double-check it or try to include an “@”.`
                        ];
                    }
                });
                if (Object.keys(emailAddressErrorsByIndex).length > 0) {
                    errors.emailAddressesByIndex = emailAddressErrorsByIndex;
                }
            }
            return errors;
        },
        visibleDeliverables() {
            if (!this.showAllDeliverables && this.computedDeliverables.length > maxVisibleDeliverables) {
                return this.computedDeliverables.slice(0, maxVisibleDeliverables - 1);
            }
            return this.computedDeliverables;
        }
    },
    watch: {
        canShareDeliverable() {
            if (!this.canShareDeliverable) {
                this.$snackbar.warning("You do not have access to share deliverables");
                this.close();
            }
        }
    },
    methods: {
        close() {
            this.$emit("close");
        },
        copyAllPreviewUrlsToClipboard() {
            this.copyTextToClipboard(
                this.computedDeliverables.reduce((acc, deliverable) => {
                    acc.push(deliverable.reportingLabel, deliverable.previewUrl);
                    return acc;
                }, [])
            );
            this.showAllCopiedTooltip = true;
        },
        copyPreviewUrlToClipboard(idHash, previewUrl) {
            this.copyTextToClipboard([previewUrl]);
            this.$set(this.showCopiedTooltipByIdHash, idHash, true);
        },
        copyTextToClipboard(lines) {
            const el = document.createElement("textarea");
            el.value = lines.join("\n");
            el.setAttribute("readonly", "");
            el.style = { display: "none" };
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
        },
        disableAllCopiedTooltip() {
            this.showAllCopiedTooltip = false;
        },
        disableCopiedTooltip(idHash) {
            this.$delete(this.showCopiedTooltipByIdHash, idHash);
        },
        async getPreviewUrls() {
            this.hasErrorGettingPreviewUrls = false;
            this.isGettingPreviewUrls = true;
            try {
                const res = await this.$apollo.mutate({
                    mutation: computeDeliverablePreview,
                    variables: {
                        campaignId: this.campaignId,
                        computedDeliverableIdentifiers: this.computedDeliverableIdentifiers
                    }
                });
                this.previewUrlsByIdHash = res.data.computeDeliverablePreview.reduce((acc, { idHash, url }) => {
                    acc[idHash] = url;
                    return acc;
                }, {});
                this.showPreviewUrls = true;
            } catch (err) {
                this.hasErrorGettingPreviewUrls = true;
            }
            this.isGettingPreviewUrls = false;
        },

        onInputBlur(index) {
            Vue.set(this.blurredInputs, index, true);
        },

        onInputRemoved(index) {
            this.blurredInputs.splice(index, 1);
        },

        async sendPreviewLinks() {
            this.showValidationErrors = true;
            if (!this.hasValidationErrors && !this.isSendingPreviewUrls) {
                this.isSendingPreviewUrls = true;
                this.hasErrorSendingPreviewUrls = false;
                try {
                    await this.$apollo.mutate({
                        mutation: computeDeliverablePreview,
                        variables: {
                            campaignId: this.campaignId,
                            computedDeliverableIdentifiers: this.computedDeliverableIdentifiers,
                            emailAddresses: this.emailAddresses,
                            reportingLabels: this.reportingLabels
                        }
                    });
                } catch (err) {
                    this.hasErrorSendingPreviewUrls = true;
                    this.isSendingPreviewUrls = false;
                    return;
                }
                this.$snackbar.success("Preview links shared");
                this.isSendingPreviewUrls = false;
                this.close();
            }
        },
        toggleShowAllDeliverables() {
            this.showAllDeliverables = !this.showAllDeliverables;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.share-preview-modal__deliverable-label {
    font-size: $font-size-base;
    margin: 0 0 $spacing-small;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.share-preview-modal__deliverables-container {
    margin: 0 0 $spacing;
    max-height: 300px;
    overflow: hidden;
    position: relative;
}

.share-preview-modal__deliverables-container--scrollable {
    overflow-y: scroll;
}

.share-preview-modal__footer-button-wrapper {
    display: inline-block;
    margin: 0 0 0 $spacing-small;
}

.share-preview-modal__preview-url-input-wrapper {
    flex: 1;
    margin: 0 $spacing-small 0 0;
}

.share-preview-modal__preview-url-row {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: (0 - $spacing-smaller) 0 $spacing;
}

.share-preview-modal__show-all-deliverables-container {
    background: $white;
    border-top: 1px solid $grey3;
    bottom: 0;
    box-shadow: 0 -10px 20px $white;
    left: -20px;
    padding: $spacing-small 0 0;
    position: absolute;
    right: -20px;
    text-align: center;
}

.share-preview-modal__title {
    font-size: $font-size-large;
    margin: 0 0 $spacing;
    padding: $spacing 0 0;
}

.share-preview-modal__title-row {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
</style>
