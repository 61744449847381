<template>
    <div>
        <prism-editor
            ref="editor"
            class="css-editor"
            :value="value"
            :highlight="highlighter"
            :placeholder="placeholder"
            line-numbers
            @input="onInput"
        ></prism-editor>
    </div>
</template>
<script>
import { PrismEditor } from "vue-prism-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-css";

export default {
    name: "CssEditor",
    components: {
        PrismEditor
    },

    props: {
        value: {
            type: String
        }
    },

    data() {
        return {
            placeholder: "// put your css here"
        };
    },

    mounted() {
        this.$refs.editor.$refs.textarea.focus();
    },

    methods: {
        highlighter(code) {
            return highlight(code, languages.css, "css");
        },

        onInput(input) {
            this.$emit("input", input);
        }
    }
};
</script>

<style lang="scss">
@import "../../../../sass/_variables.scss";
@import "~prism-editor";
@import "~prism-theme-tomorrow";

/* required class */
.css-editor {
    color: $cmp-dark-font-color;
    background-color: $cmp-dark-input-bg-color;
    border-color: $darktheme20;

    font-family:
        Fira code,
        Fira Mono,
        Consolas,
        Menlo,
        Courier,
        monospace;
    font-size: $font-size-base;
    line-height: $line-height-base;
    padding: $spacing-smaller;
    min-height: 4em;
}

/* optional class for removing the outline */
.prism-editor__textarea {
    outline: none;
}
</style>
