<template>
    <div class="dashboard-card">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "DashboardCard"
};
</script>

<style lang="scss">
@import "sass/variables";
.dashboard-card {
    background-color: $white;
    border-radius: $border-radius-larger;
    border: $border-width-base solid $grey3;
    padding: $spacing;
    width: 100%;
}
</style>
