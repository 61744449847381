<template>
    <div class="media-library__asset-tag-list">
        <div v-if="!tags" class="no-tags">
            <in-progress-img class="icon" />
            <p>Image analysis in progress...</p>
        </div>
        <div v-else>
            <h3>Asset Tags</h3>
            <ul>
                <li v-for="tag in tags" :key="tag.label + tag.type">
                    <div class="media-library__asset-tag-list-text">
                        <span>{{ getTagLabel(tag) }}</span>
                        <span>{{ getTagConfidence(tag) }}%</span>
                    </div>
                    <Progress :percent="getTagConfidence(tag)" hide-info></Progress>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import InProgressSVG from "@/assets/image-processing-in-progress.svg";
import mediaItemTagsQuery from "@/apollo/queries/MediaItemTags.gql";
import bus from "@/bus";
import { ImageTagTypes } from "@/enums/media";

const tagPrefixes = {
    [ImageTagTypes.AGE]: "Age",
    [ImageTagTypes.DOMINANT_COLOUR]: "Dominant Colour",
    [ImageTagTypes.GENDER]: "Gender",
    [ImageTagTypes.FACIAL_FEATURE]: "Facial Feature",
    [ImageTagTypes.EMOTION]: "Emotion",
    [ImageTagTypes.LOGO]: "Logo",
    [ImageTagTypes.LANDMARK]: "Landmark"
};

export default {
    name: "MediaLibraryAssetTags",

    components: {
        "in-progress-img": InProgressSVG
    },

    props: {
        asset: {
            type: Object,
            required: true
        }
    },

    data: () => {
        return {
            mediaItem: null
        };
    },

    computed: {
        tags() {
            // Get the tags from the passed in asset, if they are not there then fetch
            // the asset from the API in case they have been added
            return (this.mediaItem && this.mediaItem.info.tags) || null;
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        }
    },

    watch: {
        asset: {
            immediate: true,
            handler() {
                this.mediaItem = this.asset;
            }
        }
    },

    methods: {
        getTagConfidence(tag) {
            return Number(tag.confidence.toFixed(3));
        },

        getTagLabel(tag) {
            if (tagPrefixes[tag.type]) {
                return `${tagPrefixes[tag.type]}: ${tag.label}`;
            }

            return tag.label;
        }
    },

    apollo: {
        mediaItem: {
            query: mediaItemTagsQuery,

            fetchPolicy: "no-cache",

            pollInterval: 3000,

            variables() {
                return {
                    mediaItemId: this.asset.id
                };
            },

            skip() {
                return this.tags;
            },

            result({ data: { mediaItem } }) {
                this.mediaItem = mediaItem;

                if (this.tags) {
                    this.$apollo.queries.mediaItem.stopPolling();
                }
            },

            error(e) {
                bus.$emit("apolloErrorEvent", e);
            }
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/../sass/_variables.scss";

.media-library__asset-tag-list {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 99%;
    background: white;
    overflow: hidden scroll;
    padding-right: $spacing-large;
    box-shadow: 3px 0px 3px -2px rgba(140, 140, 140, 0.4);

    ul {
        list-style: none;
        li {
            margin-top: $spacing-base;

            .media-library__asset-tag-list-text {
                display: flex;
                justify-content: space-between;

                span {
                    max-width: 50%;
                    display: block;

                    & + span {
                        text-align: right;
                    }
                }
            }
        }
    }

    .no-tags {
        margin-top: 70%;
        text-align: center;

        .icon {
            margin-bottom: $spacing-base;
        }
    }
}
</style>
