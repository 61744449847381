<template>
    <div class="bar-chart creative-insights-chart">
        <div class="creative-insights-chart__chart-wrapper">
            <canvas ref="chart" class="horizontal-bar-chart__chart" />
            <div ref="popperAnchor" class="creative-insights-chart__tooltip" :style="tooltipStyle"></div>
        </div>
        <chart-tooltip ref="tooltip" :point-labels="tooltipLines" :image-urls="tooltipImageUrls" />
    </div>
</template>

<script>
import { Chart, BarElement, BarController, CategoryScale, LinearScale, Title, Tooltip } from "chart.js";
import { CreativeInsightsAction, CreativeInsightsGetters } from "@/store/modules/creativeInsights";
import { getMetricStat } from "@/utils/creativeInteligence";
import { numberToShortKM } from "@/utils";
import { getMetricsLabel } from "@/enums/creativeInteligence";
import chartTooltipMixin from "@/mixins/chartTooltipMixin";
import ChartTooltip from "@/components/CreativeIntelligence/ChartTooltip";

Chart.register(BarElement, BarController, CategoryScale, LinearScale, Title, Tooltip);

const Color = {
    kpiMetric: "#58AFFF",
    confidenceMetric: "#8E66DD",
    kpiMetricHover: "#58AFFFBB",
    confidenceMetricHover: "#8E66DDBB"
};

export default {
    name: "BarChart",
    components: { ChartTooltip },
    mixins: [chartTooltipMixin],

    props: {
        data: {
            type: Object
        },
        labelMap: {
            type: Object,
            default() {
                return {};
            }
        },
        title: {
            type: String
        }
    },

    data() {
        return {
            chart: undefined
        };
    },

    computed: {
        chartData() {
            return Object.keys(this.data).reduce((acc, group) => {
                if (this.data[group].summaryStats) {
                    acc[group] = this.data[group];
                }

                return acc;
            }, {});
        },

        confidenceMetricMax() {
            // 5% more than max or threshold
            return (
                (this.confidenceMetrixOverallMax < this.confidenceMetricValue
                    ? this.confidenceMetricValue
                    : this.confidenceMetrixOverallMax) * 1.05
            );
        },

        confidenceMetricMin() {
            return 0;
        },

        confidenceMetricsName() {
            return this.$store.getters[CreativeInsightsGetters.ConfidenceMetricName];
        },

        confidenceMetricsValue() {
            return this.$store.getters[CreativeInsightsGetters.ConfidenceMetricValue];
        },

        confidenceMetrixOverallMax() {
            const stat = getMetricStat(this.confidenceMetricsName);

            return Math.max(...this.labels.map(label => this.chartData[label].summaryStats.confidenceMetric[stat]));
        },

        datasets() {
            return ["kpiMetric", "confidenceMetric"].map(metric => {
                const { backgroundColors, hoverBackgroundColors, values, creatives } =
                    this.generateBarChartData(metric);

                return {
                    label: getMetricsLabel(this[`${metric}sName`]),
                    creatives,
                    data: values,
                    backgroundColor: backgroundColors,
                    hoverBackgroundColor: hoverBackgroundColors,
                    yAxisID: `${metric}`
                };
            });
        },

        kpiMetricMax() {
            // 5% more than max or threshold
            return (
                (this.kpiMetrixOverallMax < this.kpiMetricValue ? this.kpiMetricValue : this.kpiMetrixOverallMax) * 1.05
            );
        },

        kpiMetricMin() {
            return 0;
        },

        kpiMetricsName() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricName];
        },

        kpiMetricsValue() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricValue];
        },

        kpiMetrixOverallMax() {
            const stat = getMetricStat(this.kpiMetricsName);

            return Math.max(...this.labels.map(label => this.chartData[label].summaryStats.kpiMetric[stat]));
        },

        plugins() {
            return [
                {
                    beforeDraw: chart => {
                        const {
                            ctx,
                            chartArea: { left, right },
                            scales: { kpiMetric: kpiMetricScale, confidenceMetric: confidenceMetricScale }
                        } = chart;

                        const kpiPosition = kpiMetricScale.getPixelForValue(this.kpiMetricsValue);
                        ctx.strokeStyle = Color.kpiMetric;
                        ctx.setLineDash([5, 3]); /* dashes are 5px and spaces are 3px */
                        ctx.beginPath();
                        ctx.moveTo(left, kpiPosition);
                        ctx.lineTo(right, kpiPosition);
                        ctx.stroke();

                        const confidencePosition = confidenceMetricScale.getPixelForValue(this.confidenceMetricsValue);
                        ctx.strokeStyle = Color.confidenceMetric;
                        ctx.setLineDash([5, 3]); /* dashes are 5px and spaces are 3px */
                        ctx.beginPath();
                        ctx.moveTo(left, confidencePosition);
                        ctx.lineTo(right, confidencePosition);
                        ctx.stroke();

                        ctx.setLineDash([]);
                    }
                }
            ];
        },

        labels() {
            return Object.keys(this.chartData);
        },

        displayLabels() {
            return this.labels.map(l => this.labelMap[l] || l);
        },

        scales() {
            return {
                kpiMetric: {
                    id: "kpiMetric",
                    type: "linear",
                    min: this.kpiMetricMin,
                    max: this.kpiMetricMax,
                    ticks: {
                        display: true
                    },
                    grid: {
                        drawOnChartArea: false // only want the grid lines for one axis to show up
                    }
                },
                confidenceMetric: {
                    id: "confidenceMetric",
                    position: "right",
                    type: "linear",
                    min: this.confidenceMetricMin,
                    max: this.confidenceMetricMax,
                    ticks: {
                        display: true,
                        callback: value => {
                            return this.getTickString(value);
                        }
                    },
                    grid: {
                        drawOnChartArea: false // only want the grid lines for one axis to show up
                    }
                }
            };
        }
    },

    watch: {
        data() {
            this.chart.data.datasets = this.datasets;
            this.chart.data.labels = this.displayLabels;
            this.chart.options.scales = this.scales;
            this.chart.update();
        }
    },

    mounted() {
        this.initChart();
    },

    methods: {
        generateBarChartData(metric = "kpiMetric") {
            const items = Object.keys(this.chartData);

            return items.reduce(
                (acc, label) => {
                    const backgroundColor = Color[metric];
                    const hoverBackgroundColor = Color[`${metric}Hover`];
                    const stat = getMetricStat(this[`${metric}sName`]);
                    const { deliverables, summaryStats } = this.chartData[label];

                    const value = summaryStats[metric][stat];

                    return {
                        backgroundColors: [...acc.backgroundColors, backgroundColor],
                        hoverBackgroundColors: [...acc.hoverBackgroundColors, hoverBackgroundColor],
                        values: [...acc.values, value],
                        creatives: [...acc.creatives, deliverables]
                    };
                },
                {
                    backgroundColors: [],
                    hoverBackgroundColors: [],
                    values: [],
                    creatives: []
                }
            );
        },

        getTickString(v, mprecision = 0, kprecision = 0) {
            return numberToShortKM(v, mprecision, kprecision);
        },

        initChart() {
            this.chart = new Chart(this.$refs.chart, {
                data: {
                    labels: this.displayLabels,
                    datasets: this.datasets
                },
                options: {
                    scales: this.scales,
                    onClick: ({ chart }, [{ index, datasetIndex }]) => {
                        const creative = chart.data.datasets[datasetIndex].creatives[index][0];
                        if (creative) {
                            this.$store.dispatch(CreativeInsightsAction.SetDrawerCreatives, [creative]);
                        }
                    },
                    plugins: {
                        legend: {
                            display: true,
                            align: "end",
                            labels: {
                                usePointStyle: true,
                                boxWidth: 4
                            }
                        },
                        title: {
                            display: true,
                            align: "start",
                            text: this.title
                        },
                        tooltip: {
                            // Disable the on-canvas tooltip
                            enabled: false,
                            external: this.customTooltip.bind(this),
                            callbacks: {
                                label: ({ label }) => label
                            }
                        }
                    }
                },
                plugins: this.plugins,
                type: "bar"
            });
        },

        setTooltipText(tooltipModel) {
            let textLines = [];

            if (tooltipModel.dataPoints) {
                // todo: clean up
                const bodyLines = tooltipModel.dataPoints.reduce((acc, { label }) => {
                    acc.add(label);
                    return acc;
                }, new Set());

                textLines = [...bodyLines].sort().map(line => {
                    const stats = this.data[line].summaryStats;
                    const deliverableAttributes = this.data[line].deliverables[0].attributes;
                    const attr = deliverableAttributes.find(({ attribute }) => attribute === line);

                    let attributeConfidence;
                    if (attr) {
                        attributeConfidence = attr.metadata[0].confidence;
                    }

                    return {
                        label: line,
                        stats,
                        attributeConfidence
                    };
                });
            }
            this.tooltipLines = textLines;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.bar-chart {
    width: 100%;
}
</style>
