<template>
    <resizable-campaign-banner
        :class="classes"
        :master-template="deliverable.masterTemplate"
        :max-height="maxHeight"
        :max-width="maxWidth"
        :scaling-factor="scalingFactor"
        class-name="qa-banner"
        @click.native="selectBanner"
        @scaling-is-enabled="onScalingEnabledChange"
    >
        <template #bannerContainer="{ slotProps }">
            <div class="qa-banner__banner-container">
                <div class="qa-banner__select-layer" />
                <div
                    v-if="isSelected && userCanAddAnnotations"
                    class="qa-banner__add-annotation-layer"
                    @mouseover="isHovered = true"
                    @mouseleave="isHovered = false"
                    @click="showAddNewAnnotation($event, slotProps.internalScalingFactor)"
                >
                    <qa-banner-add-annotation
                        v-if="newAnnotationIsVisible"
                        :deliverable="deliverable"
                        :position="newAnnotationMetadata.position"
                        :scaling-factor="slotProps.internalScalingFactor"
                        :timeline="newAnnotationMetadata.timeline"
                        @annotationAdded="annotationAdded"
                        @close="closeAddNewAnnotation"
                    />
                    <qa-banner-cursor-info
                        v-if="!newAnnotationIsVisible && isHovered"
                        :init-mouse-event="initMouseEvent"
                        :has-header="hasResizeHeader"
                    />
                </div>
                <qa-banner-annotations
                    v-if="isSelected && annotations.length > 0"
                    :annotations="annotations"
                    :selected-annotation="selectedAnnotation"
                    :scaling-factor="slotProps.internalScalingFactor"
                    :show-resolved="showResolved"
                    @annotationSelected="selectAnnotation"
                />
                <banner-preview
                    :banner="previewBannerAdapter(deliverable)"
                    :group="BannerGroup.Qa"
                    :iframe-prefix="BannerGroup.Qa"
                    :preview-values="previewValues(deliverable)"
                    :ad-type="deliverable.masterTemplate.adType"
                    :scaling-factor="slotProps.internalScalingFactor"
                    :is-resizable="slotProps.isResizable"
                />
            </div>
        </template>

        <template #footer>
            <div class="qa-banner__footer">
                <div class="qa-banner__footer-items">
                    <set-banner-status-dropdown
                        v-if="$auth.userCan($auth.Actions.CanChangeDeliverableQAStatus, { clientId, campaignId })"
                        :deliverable="deliverable"
                        @statusHasBeenUpdated="$emit('statusHasBeenUpdated')"
                    />
                    <banner-status-indicator v-else :deliverable="deliverable" />
                </div>
                <div class="qa-banner__title">
                    <banner-info-poptip :deliverable="deliverable" />
                </div>
                <div class="qa-banner__approval-buttons-container">
                    <Tooltip
                        class="qa-banner__approval-buttons-tooltip"
                        content="Approve this banner"
                        placement="bottom"
                    >
                        <square-button
                            :is-loading="isApprovingBanner"
                            :is-solid="usersDeliverableStatus === BannerQaStatus.Approved"
                            type="success"
                            @click="setDeliverableUserStatusToApproved"
                        >
                            <Icon :size="approvalIconSize" type="ios-checkmark" />
                        </square-button>
                    </Tooltip>
                    <Tooltip
                        class="qa-banner__approval-buttons-tooltip"
                        content="Reject this banner"
                        placement="bottom"
                    >
                        <square-button
                            :is-loading="isRejectingBanner"
                            :is-solid="usersDeliverableStatus === BannerQaStatus.Rejected"
                            type="danger"
                            @click="setDeliverableUserStatusToRejected"
                        >
                            <Icon :size="approvalIconSize" type="ios-close" />
                        </square-button>
                    </Tooltip>
                </div>
            </div>
        </template>
    </resizable-campaign-banner>
</template>

<script>
import bus from "@/bus";
import BannerPreview from "@/components/Previews/BannerPreview";
import SquareButton from "@/components/common/SquareButton";
import BannerInfoPoptip from "@/components/Campaign/BannerInfoPoptip";
import BannerStatusIndicator from "@/components/Campaign/BannerStatusIndicator";
import SetBannerStatusDropdown from "@/components/Campaign/SetBannerStatusDropdown";
import QaBannerAddAnnotation from "@/components/Qa/QaBannerAddAnnotation";
import QaBannerAnnotations from "@/components/Qa/QaBannerAnnotations";
import { BannerActionEvents, BannerGroup, BannerQaStatus, BannerState } from "@/enums/banners";
import deliverableMethodsMixin from "@/mixins/deliverableMethodsMixin";
import { setLoggedInUsersDeliverablesStatus } from "@/services/Qa";
import { DeliverableLibraryAction, DeliverableLibraryGetters } from "@/store/modules/deliverableLibrary";
import QaBannerCursorInfo from "@/components/Qa/QaBannerCursorInfo";
import ResizableCampaignBanner from "@/components/Campaign/ResizableCampaignBanner";
import scalingBannerMixin from "@/mixins/scalingBannerMixin";

export default {
    components: {
        ResizableCampaignBanner,
        QaBannerCursorInfo,
        BannerInfoPoptip,
        BannerPreview,
        BannerStatusIndicator,
        QaBannerAddAnnotation,
        QaBannerAnnotations,
        SetBannerStatusDropdown,
        SquareButton
    },
    mixins: [deliverableMethodsMixin, scalingBannerMixin],
    props: {
        showResolved: {
            type: Boolean,
            default: true
        },

        deliverable: {
            type: Object,
            required: true
        },
        externalAnnotations: {
            type: Array
        },
        internalAnnotations: {
            type: Array
        },
        isSelected: {
            type: Boolean
        },

        selectedAnnotation: {
            type: Object,
            validator(value) {
                return value === null || value._id;
            }
        }
    },
    data() {
        return {
            approvalIconSize: 38,
            bannerWasPlayingBeforeAnnotation: false,
            iconSize: 24,
            initMouseEvent: null,
            isApprovingBanner: false,
            isHovered: false,
            isRejectingBanner: false,
            newAnnotationMetadata: {
                timeline: null,
                position: {
                    x: null,
                    y: null
                }
            },
            newAnnotationIsVisible: false
        };
    },
    computed: {
        annotations() {
            return [...this.externalAnnotations, ...this.internalAnnotations];
        },
        bannerStyle() {
            if (this.deliverable !== null) {
                const { height, width } = this.deliverable.masterTemplate;
                return {
                    height: `${height}px`,
                    width: `${width}px`
                };
            }
            return {};
        },
        bannerIframeId() {
            return this.iframeId(this.deliverable, BannerGroup.Qa);
        },
        bannerIsPlaying() {
            return (
                this.bannerState &&
                (this.bannerState.state === BannerState.Play || this.bannerState.state === BannerState.Loaded)
            );
        },
        bannerIsCompleted() {
            return this.bannerState && this.bannerState.state === BannerState.Completed;
        },
        bannerState() {
            return this.$store.state.banners.instanceStates[this.bannerIframeId];
        },
        bannerTime() {
            if (this.bannerState && this.bannerState.currentTime) {
                return this.bannerState.currentTime;
            }
            return 0;
        },

        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        classes() {
            const classes = ["qa-banner"];
            if (this.isSelected) {
                classes.push("qa-banner--selected");
            }
            return classes;
        },

        masterTemplate() {
            return this.deliverable.masterTemplate;
        },

        userCanAddAnnotations() {
            return (
                this.$auth.userCan(this.$auth.Actions.CanManageExternalAnnotations, {
                    clientId: this.clientId,
                    campaignId: this.campaignId
                }) ||
                this.$auth.userCan(this.$auth.Actions.CanManageInternalAnnotations, {
                    clientId: this.clientId,
                    campaignId: this.campaignId
                })
            );
        },
        usersDeliverableStatus() {
            return this.$store.getters[DeliverableLibraryGetters.getLoggedInUserStatusForIdHash](
                this.deliverable.idHash
            );
        }
    },
    watch: {
        bannerTime() {
            this.$emit("bannerTimeUpdated", this.bannerTime);
        },
        isSelected() {
            if (!this.isSelected) {
                this.closeAddNewAnnotation();
            }
        }
    },
    created() {
        this.BannerGroup = BannerGroup;
        this.BannerQaStatus = BannerQaStatus;
    },
    methods: {
        annotationAdded(annotation) {
            this.$emit("annotationAdded", annotation);
            this.closeAddNewAnnotation();
        },
        async showAddNewAnnotation(evt, scalingFactor) {
            const bounds = this.$el.getBoundingClientRect();
            const manualOffset = {
                x: 2,
                y: 38
            };
            this.newAnnotationMetadata.position.x = Math.floor(
                (evt.clientX - bounds.left - manualOffset.x) / scalingFactor
            );
            this.newAnnotationMetadata.position.y = Math.floor(
                (evt.clientY - bounds.top - manualOffset.y) / scalingFactor
            );

            this.newAnnotationMetadata.timeline = this.getBannerTime();
            this.newAnnotationIsVisible = true;
            this.bannerWasPlayingBeforeAnnotation = this.bannerIsPlaying;
            if (!this.bannerIsCompleted) {
                bus.$emit(BannerActionEvents.AllBannersPause, BannerGroup.Qa);
            }
        },
        getBannerTime() {
            if (this.bannerIframeId in this.$store.state.banners.instanceStates) {
                return this.$store.state.banners.instanceStates[this.bannerIframeId].currentTime;
            }
            return 0;
        },
        closeAddNewAnnotation() {
            this.newAnnotationIsVisible = false;
            this.newAnnotationMetadata = {
                timeline: null,
                position: {
                    x: null,
                    y: null
                }
            };
            if (!this.bannerIsCompleted && this.bannerWasPlayingBeforeAnnotation) {
                bus.$emit(BannerActionEvents.AllBannersPlay, BannerGroup.Qa);
            }
        },

        onScalingEnabledChange(isEnabled) {
            if (!isEnabled && this.newAnnotationIsVisible) {
                this.newAnnotationIsVisible = false;
            }
        },

        selectAnnotation(annotation) {
            this.$emit("annotationSelected", annotation);
        },
        selectBanner($event) {
            this.initMouseEvent = $event;
            this.$emit("select", this.deliverable);
        },
        async setDeliverableUserStatus(status) {
            let statusToSet = status;
            if (status === this.usersDeliverableStatus) {
                /*
                    If status is equal to what we have for the user and deliverable
                    then we want to unset the users approval/rejection: we do this
                    by giving it the draft state.
                */
                statusToSet = BannerQaStatus.Draft;
            }
            const deliverables = await setLoggedInUsersDeliverablesStatus(
                this.campaignId,
                [this.deliverable],
                statusToSet
            );
            this.$store.dispatch(DeliverableLibraryAction.SetDeliverableStatusByIdHash, deliverables);
        },
        async setDeliverableUserStatusToApproved() {
            this.isApprovingBanner = true;
            try {
                await this.setDeliverableUserStatus(BannerQaStatus.Approved);
                if (this.usersDeliverableStatus === BannerQaStatus.Approved) {
                    this.$snackbar.success("Deliverable approved by you.");
                } else {
                    this.$snackbar.success("The deliverable is no longer approved by you.");
                }

                this.$emit("userQaStatusHasBeenUpdated");
            } catch (err) {
                this.$snackbar.error("Sorry, We could not change your ads’ status this time. Try again in a moment.");
            }
            this.isApprovingBanner = false;
        },
        async setDeliverableUserStatusToRejected() {
            this.isRejectingBanner = true;
            try {
                await this.setDeliverableUserStatus(BannerQaStatus.Rejected);
                if (this.usersDeliverableStatus === BannerQaStatus.Rejected) {
                    this.$snackbar.success("Deliverable rejected by you.");
                } else {
                    this.$snackbar.success("The deliverable is no longer rejected by you.");
                }

                this.$emit("userQaStatusHasBeenUpdated");
            } catch (err) {
                this.$snackbar.error("Sorry, We could not change your ads’ status this time. Try again in a moment.");
            }
            this.isRejectingBanner = false;
        }
    }
};
</script>
<style lang="scss">
@import "@/../sass/_variables.scss";

.qa-banner {
    border: 1px solid $grey3;
    box-sizing: content-box;
    display: inline-block;
    transition:
        border-color 0.2s linear,
        box-shadow 0.2s linear;

    .ivu-poptip-body {
        padding: $spacing;
    }

    .ivu-poptip-popper {
        margin: 2px 0 0 -11px;
    }
}

.qa-banner--selected {
    border-color: $darkgrey1;
    box-shadow: 3px 5px 8px 3px lighten($darkgrey1, 15%);

    .qa-banner__select-layer {
        cursor: default;
    }
}

.qa-banner__approval-buttons-tooltip {
    height: 40px;
}

.qa-banner__approval-buttons-container {
    display: flex;
}

.qa-banner__footer {
    align-items: center;
    background: $white;
    display: flex;
    justify-content: space-between;
    max-width: 100%;
}

.qa-banner__footer-items {
    display: flex;
}

.qa-banner__add-annotation-layer {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    &:hover .qa-banner-cursor-info {
        display: block;
    }
}

.qa-banner__select-layer {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.qa-banner__banner-container {
    position: relative;
}

.qa-banner__title {
    color: $darktheme20;
    flex: 1 0;
    font-size: $font-size-small;
    min-width: 0;
}
</style>
