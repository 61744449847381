<template>
    <card class="font-card">
        <div class="font-card__thumbnail" :style="thumbnailStyle">
            <p v-if="!thumbnailAvailable">Font preview unavailable</p>
        </div>
        <template #title>
            <Tooltip v-if="!font.postScriptName" class="font-card__postscript-warning">
                <Icon type="md-warning" />
                <template #content>
                    This font has no PostScript name
                    <br />
                    so will not work for PSD templates
                </template>
            </Tooltip>
            {{ font.name }}
        </template>
        <template #actionItems>
            <Dropdown transfer trigger="click">
                <card-footer-button>
                    <Icon type="ios-more" class="font-card-option--dots" />
                </card-footer-button>
                <DropdownMenu slot="list">
                    <DropdownItem v-if="userCanManageFonts" @click.native="toggleEditFontModalIsVisible">
                        Edit
                    </DropdownItem>
                    <DropdownItem v-if="userCanManageFonts" @click.native="toggleDeleteFontModalIsVisible">
                        Delete
                    </DropdownItem>
                    <DropdownItem @click.native="download">Download</DropdownItem>
                    <!-- <DropdownItem v-if="userCanEditCampaign" @click.native="setSection">
                            Move to Section
                        </DropdownItem> -->
                </DropdownMenu>
            </Dropdown>
        </template>
        <edit-font-modal
            v-if="editFontModalIsVisible"
            :font="font"
            :upload-config="uploadConfig"
            @close="toggleEditFontModalIsVisible"
            @fontUpdated="edit"
        />
        <delete-font-modal
            v-if="deleteFontModalIsVisible"
            :id="font._id"
            :name="font.name"
            @close="toggleDeleteFontModalIsVisible"
            @fontDeleted="deleted"
        />
    </card>
</template>

<script>
import Card from "@/components/Card/Card";
import CardFooterButton from "@/components/Card/CardFooterButton";
import EditFontModal from "@/components/EditFontModal";
import DeleteFontModal from "@/components/DeleteFontModal";
import downloadFile from "@/utils/downloadFile";

export default {
    components: {
        Card,
        CardFooterButton,
        EditFontModal,
        DeleteFontModal
    },
    props: {
        font: {
            required: true,
            type: Object
        },
        clientId: {
            required: true,
            type: String
        },
        uploadConfig: {
            required: true,
            type: Object
        }
    },
    data() {
        return {
            editFontModalIsVisible: false,
            deleteFontModalIsVisible: false
        };
    },
    computed: {
        thumbnailAvailable() {
            return this.font.thumbnail != null;
        },
        thumbnailStyle() {
            if (!this.thumbnailAvailable) {
                return {};
            }
            return {
                backgroundImage: `url(${this.font.thumbnail})`
            };
        },
        userCanManageFonts() {
            return this.$auth.userCan(this.$auth.Actions.CanManageFonts, {
                clientId: this.clientId
            });
        }
    },
    methods: {
        edit() {
            this.$emit("edit");
        },
        deleted() {
            this.$emit("deleted");
        },
        download() {
            const u = new URL(this.font.url, window.location.origin);
            const ext = u.pathname.split(".").pop();
            downloadFile(this.font.url, `${this.font.postScriptName}.${ext}`);
        },
        toggleEditFontModalIsVisible() {
            this.editFontModalIsVisible = !this.editFontModalIsVisible;
        },
        toggleDeleteFontModalIsVisible() {
            this.deleteFontModalIsVisible = !this.deleteFontModalIsVisible;
        }
    }
};
</script>

<style lang="scss">
@import "../../sass/_variables.scss";

$thumbnail-height: 200px;

.font-card {
    &__thumbnail {
        align-items: center;
        background: no-repeat center;
        background-size: cover;
        color: $grey5;
        display: flex;
        height: $thumbnail-height;
        justify-content: center;
        width: 100%;
    }

    &__postscript-warning {
        color: $warning-color;
        margin-right: $spacing-small;
    }

    .card__footer-title {
        line-height: 24px;
    }
}
</style>
