// eslint-disable-next-line import/no-extraneous-dependencies
import getTime from "shared-utils/getTime";
import {
    persistData,
    getMasterTemplateByPath,
    getMasterTemplateById,
    setMasterTemplate,
    newId,
    getManifest
} from "./state";

/**
 * Get a list of templates from a manifest.
 * There could be more than one if a user has made new sizes in the editor.
 *
 * @param {Object} manifest
 * @param {String} serverUri
 */
export const getTemplatesFromManifest = (manifest, serverUri) => {
    const time = getTime();
    // Use the path that the manifest is from to obtain the template information
    const mt = getMasterTemplateByPath(manifest.path);
    // Create a template object from the manifest data
    const template = {
        _id: mt._id,
        adType: manifest.adType,
        name: manifest.name,
        persistentKey: manifest.path,
        preview: `${serverUri}/${manifest.path}`,
        // `adSize` key in the template gets assigned to `size` during parsing, see: parseHoxtonManifest.js
        height: manifest.size.height,
        width: manifest.size.width,
        collapsed: manifest.size.collapsed,
        created: time,
        modified: time,
        backupQuality: manifest.backupQuality || null,
        reportingLabel: manifest.reportingLabel || null,
        disableLegacyCDN: manifest.disableLegacyCDN || false,
        __typename: "MasterTemplate"
    };

    const templates = [template];
    // If the template has new sizes created by the user then we need to return those as well
    if (mt.sizes) {
        mt.sizes.forEach(size => {
            templates.push({
                ...template,
                // _id, width and height are the only properties that should be different between the templates
                _id: size._id,
                width: size.width,
                height: size.height
            });
        });
    }

    return templates;
};

/**
 * Creates a new template size from one that already exists
 *
 * @param {Object} operation the GraphQL operation
 * @param {String} serverUri the uri of the Hoxton CLI server
 */
export const resizeMasterTemplate = async (operation, serverUri) => {
    const { variables } = operation;
    // Get the template that needs to be copied and resized
    const template = getMasterTemplateById(variables.masterTemplateId);
    if (!template.sizes) {
        template.sizes = [];
    }
    // Create the new size and set it back into the local state
    const _id = newId();
    template.sizes.push({ _id, width: variables.width, height: variables.height });
    setMasterTemplate(template);

    // Persist the new size data to the working directory
    await persistData(serverUri);

    // Now that we have a new size we need to get a Master Template object to return from it
    const manifest = getManifest(template.path);
    const templates = getTemplatesFromManifest(manifest, serverUri);

    return {
        // Return the template that was just created
        resizeMasterTemplate: templates.find(t => t._id === _id)
    };
};
