// eslint-disable-next-line import/no-extraneous-dependencies
import nl2br from "shared-utils/nl2br";
// eslint-disable-next-line import/no-extraneous-dependencies
import { TemplateType } from "shared-utils/enums/masterTemplate";
import { CampaignGetters } from "@/store/modules/campaign";
import { EditorAction, EditorGetters } from "@/store/modules/editor";

import { EditableEvent, EditableScope, EditableType } from "@/enums/editables";
import { OverwriteScope, TemplateOverwriteScopes } from "@/enums/overwrites";

import editableUtils from "@/utils/editables";
import { deepClone } from "@/utils";

export default {
    computed: {
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        editableTemplates() {
            return this.editable.defaultValues.map(defaultValue => defaultValue.masterTemplateId);
        },

        masterTemplateLabels() {
            return this.$store.getters[CampaignGetters.masterTemplateLabels];
        },

        masterTemplates() {
            return this.$store.state.campaign.normalized.masterTemplates;
        },

        selectedGroupValues() {
            return this.$store.state.editor.selectedGroupValues;
        },

        selectedGroupValuesIds() {
            return this.$store.getters[EditorGetters.selectedGroupValuesIds];
        },

        selectedLanguage() {
            return this.$store.state.editor.selectedLanguage;
        },

        templateLabels() {
            const labels = deepClone(this.masterTemplateLabels);
            Object.keys(labels).forEach(masterTemplateId => {
                if (!this.editableTemplates.includes(masterTemplateId)) {
                    delete labels[masterTemplateId];
                }
            });

            return labels;
        }
    },

    methods: {
        computeEditableValue(editable, masterTemplateId, overwriteValueObject) {
            return editableUtils.computeEditableValue(
                editable,
                masterTemplateId,
                overwriteValueObject,
                this.masterTemplates
            );
        },

        getAffectingGroups(editable, masterTemplateId) {
            if (masterTemplateId) {
                const storeEditable = this.$store.state.campaign.normalized.editables[editable._id];
                const groups = storeEditable.defaultValues.filter(def => def.masterTemplateId === masterTemplateId);
                if (groups.length) {
                    return groups[0].editableGroups;
                }

                return [];
            }
            const map = this.$store.getters[EditorGetters.groupsForSelectedEditable];
            if (editable._id in map) {
                return map[editable._id];
            }

            return [];
        },

        getAffectingGroupValueIds(groupsAffectingEditable) {
            if (!groupsAffectingEditable) {
                return [];
            }

            return groupsAffectingEditable.reduce((acc, groupName) => {
                if (groupName in this.selectedGroupValues) {
                    acc.push(this.selectedGroupValues[groupName].groupValueId);
                }

                return acc;
            }, []);
        },

        getDefaultValue(editable, masterTemplateId) {
            return editableUtils.getDefaultValue(editable, masterTemplateId);
        },

        getEditableValue(editable, groupsAffectingEditable, masterTemplateId) {
            const overwriteValueObject = this.getOverwrite(editable, groupsAffectingEditable, masterTemplateId);
            return this.computeEditableValue(editable, masterTemplateId, overwriteValueObject) || "";
        },

        getEditableSavedValue(editable, masterTemplateId) {
            const overwriteValueObject = this.getSavedOverwrite(editable._id, masterTemplateId);

            return this.computeEditableValue(editable, masterTemplateId, overwriteValueObject);
        },

        getEditablePreviewValue(editable, groupsAffectingEditable, { _id, adType }) {
            const value = this.getEditableValue(editable, groupsAffectingEditable, _id);

            if (editable.type === EditableType.Folder) {
                const mediaItem = this.getOverwriteMediaItem(editable, groupsAffectingEditable, _id);
                const newValue = `${value.replace(/^\/|\/$/g, "")}/`;

                if (mediaItem) {
                    return `/${newValue}`;
                }

                return newValue;
            }

            if (editable.type === EditableType.Textarea && adType !== TemplateType.PSD) {
                return nl2br(value);
            }

            return value;
        },

        getOverwriteMediaItem(editable, groupsAffectingEditable, templateId) {
            return this.getOverwrite(editable, groupsAffectingEditable, templateId).mediaItem;
        },

        getOverwrite(editable, groupsAffectingEditable, templateId) {
            const relevantSelectedGroups = this.getAffectingGroupValueIds(groupsAffectingEditable);
            return this.$store.getters[EditorGetters.editableOverwriteValue](
                editable._id,
                templateId,
                relevantSelectedGroups
            );
        },

        getSavedOverwrite(
            editableId,
            templateId,
            language = this.selectedLanguage,
            selectedGroupsIds = this.selectedGroupValuesIds
        ) {
            return this.$store.getters[CampaignGetters.editableOverwriteValue](
                editableId,
                templateId,
                language,
                selectedGroupsIds
            );
        },

        computeEditableSettings(editable, masterTemplateId, groupOverwrite, templateOverwrite) {
            const defaultSettings = editableUtils.getDefaultSettings(editable, masterTemplateId);
            const settingsFromGroup = editableUtils.getEditableSettings(groupOverwrite);
            const settingsFromTemplate = editableUtils.getEditableSettings(templateOverwrite);
            return { ...defaultSettings, ...settingsFromGroup, ...settingsFromTemplate };
        },

        getEditableSettings(editable, groupsAffectingEditable, masterTemplateId) {
            const groupOverwrite = this.getOverwrite(editable, groupsAffectingEditable, null);
            const templateOverwrite = this.getOverwrite(editable, groupsAffectingEditable, masterTemplateId);
            return this.computeEditableSettings(editable, masterTemplateId, groupOverwrite, templateOverwrite) || "";
        },

        shouldRemoveOverwrite(editable, masterTemplateId, currentScope, { value, mediaItem, scope, settings }) {
            if (!settings) {
                return false;
            }
            // Overwrite should be removed if there is no value and every setting matches the default value
            const defaultSettings = editableUtils.getDefaultSettings(editable, masterTemplateId);
            return (
                scope === currentScope &&
                value !== "" &&
                !value &&
                !mediaItem &&
                Object.keys(settings).every(
                    key => JSON.stringify(settings[key]) === JSON.stringify(defaultSettings[key])
                )
            );
        },

        getForKey(editable, templateId) {
            return `${editable._id}_${templateId}`;
        },

        getLabelColor(editable, groupsAffectingEditable, templateId) {
            const overwrite = this.getOverwrite(editable, groupsAffectingEditable, templateId);
            return overwrite.value || overwrite.value === "" || overwrite.mediaItem ? "primary" : "default";
        },

        isTemplateOverwrite(editable, groupsAffectingEditable, templateId) {
            const overwrite = this.getOverwrite(editable, groupsAffectingEditable, templateId);
            return overwrite && TemplateOverwriteScopes.includes(overwrite.scope);
        },

        removeOverwrite(overwriteId) {
            this.$store.dispatch(EditorAction.RemoveOverwrite, overwriteId);
        },

        computeGroupScope() {
            // TODO decide if we should remove all the campaign and template level overwrite references & endpoints
            return !this.editable.scope || this.editable.scope === EditableScope.Group
                ? OverwriteScope.EditableGroup
                : OverwriteScope.Campaign;
        },

        computeTemplateScope() {
            return !this.editable.scope || this.editable.scope === EditableScope.Group
                ? OverwriteScope.EditableGroupTemplate
                : OverwriteScope.Template;
        },

        onUpdateSettings(templateId, settings, payload) {
            this.updateSettings(settings, payload, this.groupsAffectingEditable, templateId);
        },

        restore(updateData) {
            const updatePayload = {
                scope: this.computeGroupScope(),
                editable: this.editable,
                ...updateData
            };

            if (updateData.templateId) {
                updatePayload.scope = this.computeTemplateScope();
                updatePayload.masterTemplateId = updateData.templateId;
            }

            this.$emit(EditableEvent.Remove, updatePayload);
        },

        update(updateData, templateId = null) {
            const updatePayload = {
                scope: this.computeGroupScope(),
                editable: this.editable,
                groupsAffectingEditable: this.groupsAffectingEditable,
                ...updateData
            };

            if (templateId) {
                updatePayload.scope = this.computeTemplateScope();
                updatePayload.masterTemplateId = templateId;
            }

            this.$emit(EditableEvent.Update, updatePayload);
        },

        updateSettings(settings, updateData, groupsAffectingEditable, templateId = null) {
            const updatedSettings = { ...settings, ...updateData };
            let settingsToApply = updatedSettings;
            if (templateId) {
                const overwrite = this.getOverwrite(this.editable, groupsAffectingEditable, templateId);
                settingsToApply = Object.keys(updatedSettings).reduce((acc, propName) => {
                    if (
                        updatedSettings[propName] !== undefined ||
                        !overwrite.settingsFromScope ||
                        (overwrite.settingsFromScope &&
                            overwrite.settingsFromScope[OverwriteScope.EditableGroup] &&
                            overwrite.settingsFromScope[OverwriteScope.EditableGroup][propName] &&
                            overwrite.settingsFromScope[OverwriteScope.EditableGroup][propName] !==
                                updatedSettings[propName])
                    ) {
                        return Object.assign(acc, { [propName]: updatedSettings[propName] });
                    }

                    return acc;
                }, {});
            }

            const updatePayload = {
                scope: this.computeGroupScope(),
                editable: this.editable,
                settings: settingsToApply,
                groupsAffectingEditable
            };

            if (templateId) {
                updatePayload.scope = this.computeTemplateScope();
                updatePayload.masterTemplateId = templateId;
            }

            this.$emit(EditableEvent.Update, updatePayload);
        },

        updateFileEditable(updateData) {
            this.update(
                {
                    ...updateData,
                    ...(updateData.mediaItem && { value: updateData.mediaItem.id })
                },
                updateData.templateId
            );
        }
    }
};
