// @ts-check
// eslint-disable-next-line no-unused-vars, import/no-extraneous-dependencies
import * as Types from "shared-utils/types";
// eslint-disable-next-line import/no-extraneous-dependencies
import metadataToInputMetadata from "shared-utils/metadataToInputMetadata";

import computedValuesToComputedOverwrites from "@/utils/computedValuesToComputedOverwrites";

export default {
    methods: {
        /**
         * @param {Types.Deliverable} deliverable
         * @param {string} [iframePrefix]
         * @returns {string}
         */
        iframeId(deliverable, iframePrefix = "") {
            return `${iframePrefix}iframe-${deliverable.idHash}`;
        },

        /**
         * @param {Types.Deliverable} deliverable
         * @returns {string}
         */
        previewValues(deliverable) {
            const computedOverwrites = computedValuesToComputedOverwrites(deliverable.computedValues);

            const metadata = [
                {
                    fieldName: "language",
                    value: deliverable.language
                },
                ...metadataToInputMetadata(deliverable.metadata || [])
            ];

            return JSON.stringify({
                computedOverwrites,
                metadata,
                masterTemplate: {
                    _id: deliverable.masterTemplate._id,
                    persistentKey: deliverable.masterTemplate.persistentKey,
                    width: deliverable.masterTemplate.width,
                    height: deliverable.masterTemplate.height,
                    aeInfo: {
                        // @ts-ignore
                        completed: deliverable.masterTemplate?.aeInfo?.completed,
                        // @ts-ignore
                        plainlyProjectId: deliverable.masterTemplate?.aeInfo?.plainlyProjectId,
                        // @ts-ignore
                        plainlyTemplateId: deliverable.masterTemplate?.aeInfo?.plainlyTemplateId
                    },
                    reportingLabel: deliverable.masterTemplate.reportingLabel
                },
                previewUrl: deliverable.masterTemplate.preview,
                // @ts-ignore
                campaignId: this.campaignId,
                reportingLabel: deliverable.reportingLabel
            });
        },

        /**
         * @param {Types.Deliverable} deliverable
         * @returns {Types.MasterTemplate & { _id: string, id: string }}
         */
        previewBannerAdapter(deliverable) {
            return {
                ...deliverable.masterTemplate,
                id: this.iframeId(deliverable),
                _id: deliverable.idHash
            };
        }
    }
};
