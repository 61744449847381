var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['multi-input', _vm.error && _vm.showErrors ? 'multi-input--has-errors' : '']},[_vm._l((_vm.internalValues),function(value,index){return _c('div',{key:index},[_c('div',{class:[
                'multi-input__value-wrapper',
                _vm.errorsByIndex[index] && _vm.showErrors ? 'multi-input__value-wrapper--has-errors' : ''
            ]},[_c('Input',{ref:index,refInFor:true,attrs:{"placeholder":_vm.isLastValue(index) ? _vm.newValuePlaceholder : ''},on:{"on-blur":function($event){return _vm.removeValueIAtIndexfEmpty(index)},"on-keydown":function($event){return _vm.keyupHandler($event, index, _vm.internalValues[index])}},model:{value:(_vm.internalValues[index]),callback:function ($$v) {_vm.$set(_vm.internalValues, index, $$v)},expression:"internalValues[index]"}}),(_vm.internalValues.length > 1)?_c('div',{class:[
                    'multi-input__remove-button-wrapper',
                    _vm.isLastValue(index) ? 'multi-input__remove-button-wrapper--invisible' : ''
                ]},[_c('Button',{attrs:{"icon":"ios-close","shape":"circle","size":"small","tabindex":"-1"},on:{"click":function($event){return _vm.removeValueAtIndex(index)}}})],1):_vm._e()],1),(_vm.errorsByIndex[index] && _vm.showErrors)?_c('div',_vm._l((_vm.errorsByIndex[index]),function(errorMessage){return _c('p',{key:errorMessage,staticClass:"multi-input__error-message"},[_vm._v(" "+_vm._s(errorMessage)+" ")])}),0):_vm._e()])}),(_vm.error && _vm.showErrors)?_c('div',_vm._l((_vm.error),function(errorMessage){return _c('p',{key:errorMessage,staticClass:"multi-input__error-message"},[_vm._v(" "+_vm._s(errorMessage)+" ")])}),0):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }