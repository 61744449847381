<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
    <div class="login-wrapper">
        <hox-loading-layer v-if="isLoggingIn" theme="dark" size="large" />
        <div class="login-form-wrapper">
            <hogarth-ic-logo class="logo" />
            <Form direction="vertical" class="login-form" @submit.native.prevent>
                <FormItem>
                    <Input
                        id="login-username"
                        v-model="form.username"
                        prefix="ios-contact-outline"
                        type="text"
                        placeholder="Username / Email Address"
                        size="large"
                        data-testid="login-username"
                        @keydown.native.stop
                        @keyup.native.enter="login"
                    ></Input>
                </FormItem>
                <FormItem v-if="form.showPassword">
                    <Input
                        id="login-password"
                        v-model="form.password"
                        prefix="ios-lock-outline"
                        type="password"
                        placeholder="Password"
                        size="large"
                        data-testid="login-password"
                        @keydown.native.stop
                        @keyup.native.enter="login"
                    ></Input>
                    <a class="forgot-password" @click.prevent="forgotPassword">Forgot Password?</a>
                </FormItem>
                <FormItem class="sign-in-btn-wrapper">
                    <Button
                        type="primary"
                        size="large"
                        class="primary-green sign-in-btn"
                        data-testid="login-button"
                        @click="login"
                        v-text="form.showPassword ? 'Sign In' : 'Next'"
                    ></Button>
                </FormItem>
            </Form>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { getProvider } from "@/utils";
import bus from "@/bus";
import HogarthIcLogo from "@/components/HogarthIcLogo";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Login",
    components: {
        HogarthIcLogo
    },

    data() {
        return {
            form: {
                username: "",
                password: "",
                showPassword: false
            },
            isLoggingIn: false
        };
    },

    computed: {
        ...mapState("auth", ["authConfig"])
    },

    async mounted() {
        bus.$emit("percentLoadEvent", 0);
        this.$apollo.provider.defaultClient.resetStore();
        await this.autocompleteAndSubmitForm();
    },

    methods: {
        ...mapMutations("auth", ["setUser", "setIsAuthorized"]),
        forgotPassword() {
            if (this.form.username.length) {
                this.$Modal.confirm({
                    title: "Forgot Password",
                    content: `Request a new password for ${this.form.username}?`,
                    okText: "Yes",
                    cancelText: "No",
                    onOk: async () => {
                        try {
                            await this.$auth.forgotPassword(this.form.username);
                            this.$router.push({
                                name: "Reset Password",
                                params: { username: this.form.username }
                            });
                        } catch (e) {
                            this.$snackbar.authentication(e);
                        }
                    }
                });
            } else {
                this.$snackbar.fieldRequired("Username must not be empty");
            }
        },
        isProviderLogin() {
            if (this.form.username.includes("@")) {
                const provider = getProvider(this.form.username);

                if (provider) {
                    this.$auth.providerLogin(provider, this.authConfig);
                    return true;
                }
            }
            return false;
        },
        validateLogin() {
            if (this.form.username.length === 0) {
                this.$snackbar.fieldRequired("Username must not be empty");
                return false;
            }
            if (this.isProviderLogin()) {
                return false;
            }
            if (!this.form.showPassword) {
                this.form.showPassword = true;
                return false;
            }
            if (this.form.password.length === 0) {
                this.$snackbar.fieldRequired("Password must not be empty");
                return false;
            }
            return true;
        },
        async login() {
            if (!this.validateLogin()) {
                return;
            }
            this.isLoggingIn = true;
            try {
                const user = await this.$auth.login(this.form.username, this.form.password);
                this.setUser({ user });

                if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                    this.setIsAuthorized({ authorized: false });
                    this.setUser({ user });
                    this.$router.push({ name: "Reset Password" });
                } else {
                    this.setIsAuthorized({ authorized: true });
                    this.$router.push(this.$router.getLandingRoute(this.$route));
                }
            } catch (e) {
                this.$snackbar.error(
                    "Login Error",
                    e.code === "UserNotFoundException" ? "Incorrect username or password." : e.message
                );
            }
            this.isLoggingIn = false;
        },
        async autocompleteAndSubmitForm() {
            const { query } = this.$route;
            if (query?.oneTimeLogin) {
                try {
                    const { username, code } = JSON.parse(query.oneTimeLogin);
                    this.form.showPassword = true;
                    this.form.username = username;
                    this.form.password = code;
                    await this.login();
                } catch (err) {
                    // ignore the error and just display the regular login
                    this.form.showPassword = false;
                    this.form.username = "";
                    this.form.password = "";
                }
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../sass/variables";

.login {
    &-wrapper {
        background: transparent;
        text-align: center;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 360px;
        margin: 0 auto;
        flex-direction: column;
        color: #ffffff;

        .forgot-password {
            float: right;
            color: $white;
        }

        .sign-in-btn {
            height: auto;
            margin-top: 25px;
            padding: 10px 50px;
            width: 100%;

            &-wrapper {
                margin-top: 24px;
            }
        }
    }

    &-form-wrapper {
        width: 100%;
    }

    &-form {
        width: 100%;
    }
}
</style>
