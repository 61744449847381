<template>
    <div>
        <div class="advanced-model-options__input">
            <label>Image size</label>
            <Select :value="savedCustomInputs.width + 'x' + savedCustomInputs.height" @input="onUpdateImageSize">
                <Option v-for="size in customOptions.size[model].options" :key="size" :value="size">
                    {{ size }}
                </Option>
            </Select>
        </div>
        <advanced-model-options-slider
            label="Number of denoising steps"
            :value="savedCustomInputs.num_inference_steps"
            :settings="customOptions.num_inference_steps"
            @input="onUpdateDenoise"
        />

        <advanced-model-options-slider
            label="Scale for classifier-free guidance"
            :value="savedCustomInputs.guidance_scale"
            :settings="customOptions.guidance_scale"
            @input="onUpdateGuidance"
        />

        <div class="advanced-model-options__input">
            <label>Random seed. Leave blank to randomize the seed</label>
            <Input :value="savedCustomInputs.seed" @input="onUpdateRandomSeed"></Input>
        </div>
        <template v-if="model === ImagineModel.StableDiffusion">
            <div class="advanced-model-options__input">
                <label>Choose a scheduler.</label>
                <Select :value="savedCustomInputs.scheduler" @input="onUpdateScheduler">
                    <Option v-for="scheduler in customOptions.scheduler.options" :key="scheduler" :value="scheduler">
                        {{ scheduler }}
                    </Option>
                </Select>
            </div>
            <div class="advanced-model-options__input">
                <label>Specify things to not see in the output</label>
                <Input :value="savedCustomInputs.negative_prompt" @input="onUpdateNegativePrompt"></Input>
            </div>
        </template>

        <div v-if="initImage" class="advanced-model-options__input">
            <label>
                Prompt strength when using init image. 1.0 corresponds to full destruction of information in init image.
            </label>
            <div class="advanced-model-options__input-slider">
                <span>{{ savedCustomInputs.prompt_strength }}</span>
                <Slider
                    :min="customOptions.prompt_strength.minimum"
                    :max="customOptions.prompt_strength.maximum"
                    :step="customOptions.prompt_strength.step"
                    :value="customOptions.prompt_strength.defaultValue"
                    @on-input="onUpdatePromptStrength"
                ></Slider>
            </div>
        </div>
        <div>
            <Button type="primary" @click="onSaveUpdates">Save</Button>
        </div>
    </div>
</template>
<script>
import { ModelDefaultValues, ImagineModel } from "@/enums/imagine";
import { ImagineAction } from "@/store/modules/imagine";
import AdvancedModelOptionsSlider from "@/components/Imagine/AdvancedModelOptions/AdvancedModelOptionsSlider";

const customOptions = {
    size: {
        [ImagineModel.StableDiffusion]: {
            type: "select",
            width: ModelDefaultValues[ImagineModel.StableDiffusion].width,
            height: ModelDefaultValues[ImagineModel.StableDiffusion].height,
            options: ["128x128", "256x256", "384x384", "448x448", "512x512", "768x768"]
        },
        [ImagineModel.MaterialStableDiffusion]: {
            type: "select",
            width: ModelDefaultValues[ImagineModel.MaterialStableDiffusion].width,
            height: ModelDefaultValues[ImagineModel.MaterialStableDiffusion].height,
            options: ["128x128", "256x256", "512x512", "768x768"]
        }
    },
    scheduler: {
        type: "select",
        defaultValue: "",
        options: ["DDIM", "K_EULER", "DPMSolverMultistep", "K_EULER_ANCESTRAL", "PNDM", "KLMS"]
    },
    num_inference_steps: {
        type: "slider",
        defaultValue: 50,
        minimum: 1,
        maximum: 500,
        step: 1
    },
    guidance_scale: {
        type: "slider",
        defaultValue: 7.5,
        minimum: 1,
        maximum: 20,
        step: 0.5
    },
    prompt_strength: {
        type: "slider",
        defaultValue: 0.8,
        minimum: 0,
        maximum: 1,
        step: 0.1
    },
    seed: {
        type: "text",
        defaultValue: ""
    },
    negative_prompt: {
        type: "text",
        defaultValue: ""
    }
};
export default {
    name: "AdvancedStableDiffusionOptions",
    components: { AdvancedModelOptionsSlider },
    props: {
        initImage: {
            type: String
        },

        model: {
            type: String
        }
    },
    data() {
        return {
            customInputs: {},
            savedCustomInputs: {},
            defaultValues: {
                num_inference_steps: customOptions.num_inference_steps.defaultValue,
                guidance_scale: customOptions.guidance_scale.defaultValue,
                scheduler: customOptions.scheduler.defaultValue,
                seed: customOptions.seed.defaultValue,
                negative_prompt: customOptions.negative_prompt.defaultValue,
                prompt_strength: customOptions.prompt_strength.defaultValue,
                width: customOptions.size[this.model].width,
                height: customOptions.size[this.model].height
            }
        };
    },

    computed: {
        savedAdvancedOptions() {
            return this.$store.state.imagine.advancedOptions[this.model];
        }
    },

    watch: {
        model: {
            immediate: true,
            handler() {
                this.resetDefaultValues();
                this.savedCustomInputs = this.defaultValues;
                this.customInputs = {};

                if (this.savedAdvancedOptions) {
                    this.savedCustomInputs = { ...this.defaultValues, ...this.savedAdvancedOptions };
                    this.customInputs = this.savedAdvancedOptions;
                }
            }
        }
    },

    created() {
        this.ImagineModel = ImagineModel;
        this.customOptions = customOptions;
    },

    methods: {
        resetDefaultValues() {
            // todo clean this up
            const defaultValues = {};
            const properties = [
                "num_inference_steps",
                "guidance_scale",
                "scheduler",
                "seed",
                "negative_prompt",
                "prompt_strength"
            ];
            properties.map(property => {
                defaultValues[property] = customOptions[property].defaultValue;
                return defaultValues;
            });

            this.defaultValues = defaultValues;
            this.defaultValues.width = customOptions.size[this.model].width;
            this.defaultValues.height = customOptions.size[this.model].height;
        },

        onUpdateImageSize(val) {
            const imageSizes = val.split("x");
            this.savedCustomInputs.width = imageSizes[0];
            this.savedCustomInputs.height = imageSizes[1];
            this.customInputs.width = imageSizes[0];
            this.customInputs.height = imageSizes[1];
        },

        onUpdateDenoise(val) {
            this.savedCustomInputs.num_inference_steps = val;
            this.customInputs.num_inference_steps = this.savedCustomInputs.num_inference_steps;
        },

        onUpdatePromptStrength(val) {
            this.savedCustomInputs.prompt_strength = val;
            this.customInputs.prompt_strength = this.savedCustomInputs.prompt_strength;
        },

        onUpdateGuidance(val) {
            this.savedCustomInputs.guidance_scale = val;
            this.customInputs.guidance_scale = this.savedCustomInputs.guidance_scale;
        },

        onUpdateRandomSeed(val) {
            this.savedCustomInputs.seed = val;
            this.customInputs.seed = this.savedCustomInputs.seed;
        },

        onUpdateNegativePrompt(val) {
            this.savedCustomInputs.negative_prompt = val;
            this.customInputs.negative_prompt = this.savedCustomInputs.negative_prompt;
        },

        onUpdateScheduler(val) {
            this.savedCustomInputs.scheduler = val;
            this.customInputs.scheduler = this.savedCustomInputs.scheduler;
        },

        onSaveUpdates() {
            this.$emit("updatedCustomInputs", this.customInputs);
            this.$store.dispatch(ImagineAction.SetAdvancedOptions, {
                ...this.$store.state.imagine.advancedOptions,
                [this.model]: {
                    ...this.customInputs
                }
            });
        }
    }
};
</script>
