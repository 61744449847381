// BE constants
export const AdMetadataInsights = {
    AD_ID: "ad-id",
    ADSET_NAME: "adset-name",
    CAMPAIGN_ID: "campaign-id",
    COMPAIGN_NAME: "campaign-name",
    OBJECTIVE: "objective",
    AD_TYPE: "ad-type",
    DURATION: "duration",
    ASPECT_RATIO: "aspect-ratio",
    POST_TYPE: "post-type",
    ATTACHMENT_TYPE: "attachment-type"
};

export const InsightsMetadata = {
    POSITION: "position",
    AREA: "size",
    WORD_COUNT: "word-count",
    ATTENTION_SCORE: "attention-score",
    KEY_PHRASES: "key-phrases",
    KEYWORDS: "keywords",
    WORD_COUNT_GROUP: "word-count-group"
};

export const InsightsMetadataWithGroups = {
    AREA: InsightsMetadata.AREA,
    WORD_COUNT: InsightsMetadata.WORD_COUNT,
    ATTENTION_SCORE: InsightsMetadata.ATTENTION_SCORE
};

// end of BE constants
export const InsightType = {
    AppearanceDurationAnalysis: "AppearanceDurationAnalysis",
    AppearancePositionAnalysis: "AppearancePositionAnalysis",
    AttributeMetadata: "AttributeMetadata",
    AttributeAdMetadata: "AttributeAdMetadata",
    AttributeValuesAnalysis: "AttributeValuesAnalysis",
    BucketValues: "BucketValues",
    CampaignInfo: "CampaignInfo",
    ColorAnalysis: "ColorAnalysis",
    ContextAnalysis: "ContextAnalysis",
    KeyTakeaway: "KeyTakeaway",
    KeyTakeawayNotMatching: "KeyTakeawayNotMatching",
    Keywords: "Keywords",
    Matrix: "Matrix",
    PositionAnalysis: "PositionAnalysis",
    SizeAnalysis: "SizeAnalysis"
};

export const InsightTypeToServiceName = {
    [InsightType.AppearanceDurationAnalysis]: "segment-length",
    [InsightType.AppearancePositionAnalysis]: "segment-position",
    [InsightType.AttributeMetadata]: "attribute-metadata",
    [InsightType.AttributeAdMetadata]: "attribute-ad-metadata",
    [InsightType.ContextAnalysis]: "context",
    [InsightType.KeyTakeaway]: "key-takeaway",
    [InsightType.KeyTakeawayNotMatching]: "key-takeaway",
    [InsightType.Matrix]: "matrix",
    [InsightType.PositionAnalysis]: "position",
    [InsightType.SizeAnalysis]: "size",
    [InsightType.AttributeValuesAnalysis]: "attribute-values",
    [InsightType.BucketValues]: "bucket-values",
    [InsightType.CampaignInfo]: "campaign-info",
    [InsightType.Keywords]: "attribute-metadata"
};

export const InsightTypeDefaultServiceOptions = {
    [InsightType.AttributeMetadata]: {
        type: InsightsMetadata.KEYWORDS
    },
    [InsightType.AttributeAdMetadata]: {
        type: AdMetadataInsights.AD_TYPE
    },
    [InsightType.Keywords]: {
        type: InsightsMetadata.KEYWORDS
    },
    [InsightType.SizeAnalysis]: {
        groups: [
            { name: "Small", maxValue: "0.1" },
            { name: "Medium", maxValue: "0.4" },
            { name: "Large", maxValue: "0.6" }
        ]
    },
    [InsightType.AppearanceDurationAnalysis]: {
        groups: [
            { name: "0-2", maxValue: "2" },
            { name: "0-5", maxValue: "5" },
            { name: "0-200", maxValue: "200" }
        ]
    },
    [InsightType.AppearancePositionAnalysis]: {
        groups: [
            { name: "0-2", maxValue: "2" },
            { name: "0-5", maxValue: "5" },
            { name: "0-200", maxValue: "200" }
        ]
    }
};

export const InsightTypeSubTypes = {
    [InsightType.AttributeMetadata]: [
        "position",
        "size",
        "word-count",
        "attention-score",
        "key-phrases",
        "keywords",
        "word-count-group"
    ],
    [InsightType.AttributeAdMetadata]: [
        "ad-id",
        "adset-name",
        "campaign-id",
        "campaign-name",
        "objective",
        "ad-type",
        "duration",
        "aspect-ratio",
        "post-type",
        "attachment-type"
    ]
};

export const PositionAnalysisPosition = {
    TopLeft: "TOP_LEFT",
    TopCenter: "TOP_CENTER",
    TopRight: "TOP_RIGHT",
    CenterLeft: "CENTER_LEFT",
    Center: "CENTER",
    CenterRight: "CENTER_RIGHT",
    BottomLeft: "BOTTOM_LEFT",
    BottomCenter: "BOTTOM_CENTER",
    BottomRight: "BOTTOM_RIGHT"
};

export const PositionAnalysisPositionLabels = {
    [PositionAnalysisPosition.TopLeft]: "Top Left",
    [PositionAnalysisPosition.TopCenter]: "Top Center",
    [PositionAnalysisPosition.TopRight]: "Top Right",
    [PositionAnalysisPosition.CenterLeft]: "Center Left",
    [PositionAnalysisPosition.Center]: "Center",
    [PositionAnalysisPosition.CenterRight]: "Center Right",
    [PositionAnalysisPosition.BottomLeft]: "Bottom Left",
    [PositionAnalysisPosition.BottomCenter]: "Bottom Center",
    [PositionAnalysisPosition.BottomRight]: "Bottom Right"
};

export const ColorAnalysisColor = {
    Blue: "Blue",
    Green: "Green",
    Purple: "Purple",
    Red: "Red",
    Yellow: "Yellow"
};

export const BreakdownGroup = {
    All: "all",
    Demographic: "demographic",
    Device: "device",
    Location: "location",
    Other: "other",
    Platform: "platform"
};

// to be removed
export const BreakdownsByGroups = {
    all: [
        "13-17",
        "18-24",
        "25-34",
        "35-44",
        "45-54",
        "Andaman and Nicobar Islands",
        "Andhra Pradesh",
        "android_smartphone",
        "android_tablet",
        "Arunachal Pradesh",
        "Assam",
        "audience_network",
        "audience_network__an_classic",
        "audience_network__rewarded_video",
        "Bihar",
        "Chandigarh",
        "Chhattisgarh",
        "Dadra and Nagar Haveli",
        "Delhi",
        "desktop",
        "facebook",
        "facebook__facebook_stories",
        "facebook__feed",
        "facebook__instant_article",
        "facebook__instream_video",
        "facebook__marketplace",
        "facebook__search",
        "facebook__video_feeds",
        "female",
        "female__13-17",
        "female__18-24",
        "female__25-34",
        "female__35-44",
        "female__45-54",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "IN",
        "instagram",
        "instagram__feed",
        "instagram__instagram_explore",
        "instagram__instagram_stories",
        "ipad",
        "iphone",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "male",
        "male__13-17",
        "male__18-24",
        "male__25-34",
        "male__35-44",
        "male__45-54",
        "Manipur",
        "Meghalaya",
        "messenger",
        "messenger__messenger_stories",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "other",
        "Puducherry",
        "Punjab region",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "unknown",
        "unknown__13-17",
        "unknown__18-24",
        "unknown__25-34",
        "unknown__35-44",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal"
    ],
    demographic: [
        "13-17",
        "18-24",
        "25-34",
        "35-44",
        "45-54",
        "female",
        "female__13-17",
        "female__18-24",
        "female__25-34",
        "female__35-44",
        "female__45-54",
        "male",
        "male__13-17",
        "male__18-24",
        "male__25-34",
        "male__35-44",
        "male__45-54",
        "unknown__13-17",
        "unknown__18-24",
        "unknown__25-34",
        "unknown__35-44"
    ],
    device: ["android_smartphone", "android_tablet", "desktop", "ipad", "iphone"],
    location: [
        "IN",
        "Andaman and Nicobar Islands",
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chandigarh",
        "Chhattisgarh",
        "Dadra and Nagar Haveli",
        "Delhi",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Puducherry",
        "Punjab region",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal"
    ],
    other: ["audience_network", "audience_network__an_classic", "audience_network__rewarded_video", "other", "unknown"],
    platform: [
        "facebook",
        "facebook__facebook_stories",
        "facebook__feed",
        "facebook__instant_article",
        "facebook__instream_video",
        "facebook__marketplace",
        "facebook__search",
        "facebook__video_feeds",
        "instagram",
        "instagram__feed",
        "instagram__instagram_explore",
        "instagram__instagram_stories",
        "messenger",
        "messenger__messenger_stories"
    ]
};

export const NoScalePadding = {
    left: 8,
    right: 24,
    top: 0,
    bottom: 0
};

export const ChartLabelsStyle = {
    font: {
        family: "WPP Regular",
        weight: "bold",
        size: 14
    }
};
