<template>
    <form @submit.prevent="submit">
        <hox-input
            ref="sectionName"
            v-model="internalFormData.name"
            is-required
            placeholder="E.g. Branding Q1"
            data-testid="add-section-modal__name-input"
            :show-error="showValidationErrors && validationErrors.name !== undefined"
        >
            <template #label>Section name</template>
            <template #error>
                <p v-for="error in validationErrors.name" :key="error">
                    {{ error }}
                </p>
            </template>
        </hox-input>
    </form>
</template>

<script>
export default {
    props: {
        externalErrors: {
            required: true,
            type: Object
        },

        initialFormData: {
            type: Object
        },

        showValidationErrors: {
            type: Boolean
        }
    },

    data() {
        return {
            internalFormData: { ...this.initialFormData }
        };
    },
    computed: {
        hasValidationErrors() {
            return Object.keys(this.validationErrors).length > 0;
        },

        validationErrors() {
            const errors = {};
            if (this.externalErrors.name) {
                errors.name = this.externalErrors.name;
            } else if (!this.internalFormData.name || this.internalFormData.name.trim() === "") {
                errors.name = ["A section requires a name"];
            }
            return errors;
        }
    },

    watch: {
        hasValidationErrors: {
            handler() {
                this.$emit("hasValidationErrorsUpdate", this.hasValidationErrors);
            },
            immediate: true
        },

        internalFormData: {
            deep: true,
            handler() {
                this.$emit("formDataUpdate", { ...this.internalFormData });
            },
            immediate: true
        }
    },

    async mounted() {
        await this.$nextTick();
        this.$refs.sectionName.focus();
    },

    methods: {
        submit() {
            this.$emit("submit", { ...this.internalFormData });
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
</style>
