<template>
    <div id="app" :class="themeClassName">
        <hox-loading-bar />
        <Layout class="campaign-layout">
            <template v-if="isAuthorizedContentVisible">
                <campaign-header />
            </template>
            <Sider
                :collapsible="true"
                :value="!isSidebarOpen"
                :collapsed-width="sidebarWidthCollapsed"
                :width="sidebarWidthOpen"
                :hide-trigger="true"
            >
                <portal-target name="main-sidebar" slim></portal-target>
            </Sider>
            <Layout class="campaign-content">
                <Content class="main-content">
                    <router-view :key="$route.fullPath" class="hox-full-height hox-full-width router-view" />
                </Content>
            </Layout>
        </Layout>
    </div>
</template>

<script>
import HoxLoadingBar from "@/HoxLoadingBar";
import CampaignHeader from "@/CampaignHeader";
import themeMixin from "@/mixins/themeMixin";

export default {
    name: "ThemeCampaignLight",
    components: {
        CampaignHeader,
        HoxLoadingBar
    },
    mixins: [themeMixin],

    data() {
        return {
            sidebarWidth: 300
        };
    }
};
</script>

<style lang="scss">
@import "../sass/variables";

.theme-campaign-light {
    .campaign {
        &-header {
            background-color: $cmp-light-bg-color;
            border-bottom: 1px solid $cmp-light-border-color;

            &__breadcrumb {
                span.inactive-link {
                    color: $cmp-light-secondary-font-color;
                }

                a {
                    color: $cmp-light-secondary-font-color;

                    &:hover {
                        color: $cmp-light-secondary-font-color;
                    }
                }
            }
        }

        &-banner {
            border: 1px solid $cmp-light-border-color;
            box-sizing: content-box;

            &-footer {
                color: $cmp-light-font-color;
            }
        }
    }

    .ivu-layout-sider {
        background-color: $white;

        .sidebar-body {
            width: $campaign-library-pane-width;
            top: 0;
            position: fixed !important;
            overflow: auto !important;
            height: 100vh;
        }
    }

    .main-content {
        background-color: $cmp-light-bg-color;
    }
}

.campaign-light__sidebar {
    &-heading {
        background: $primary;
        color: $white;
        font-size: $font-size-large;
        margin: -1px 0 0;
        padding: $spacing 20px $spacing;
    }

    &-nav {
        height: $campaign-nav-height;
        display: flex;
        justify-content: stretch;
        border-bottom: 1px solid $grey3;

        .navigation-tabs {
            display: flex;
            width: 100%;
        }

        .navigation-tab {
            flex: 1 0 50%;
            width: 50%;
            padding: 22px 20px 19px 20px;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: bold;
            text-align: center;
        }
    }

    .collapsible-widget .ivu-checkbox-wrapper:hover {
        background-color: $cmp-light-filters-hover-color;
    }

    .ivu-collapse > .ivu-collapse-item {
        border: 1px solid transparent;

        .ivu-collapse-header {
            border: none;
            background: $white;

            &:hover {
                background: $cmp-light-filters-hover-color;
            }
        }

        &.ivu-collapse-item-active {
            border-bottom: 1px solid $cmp-light-filters-bg-color;

            .ivu-collapse-header {
                background: $cmp-light-filters-bg-color;
                border-bottom: $cmp-light-filters-bg-color;

                &:hover {
                    background: $cmp-light-filters-hover-color;
                }
            }
        }
    }
}

.campaign-light__content-header {
    border-bottom: 1px solid $grey3;
    height: $campaign-nav-height;
    margin: (0 - 30px) 0 0; // 30px being the padding on .ivu-layout-content/.main-content.
    overflow-x: auto;
    padding: 0 20px;

    &-item {
        flex: 0 0 auto;
        max-width: 100%;
        padding: 0 0 0 $spacing-smaller;
        width: auto;
    }

    &-items {
        align-items: center;
        display: flex;
        flex: 1;
        height: 100%;
        justify-content: space-between;
    }

    &-group {
        align-items: center;
        display: flex;
        border-right: 1px solid $grey3;
        height: 100%;
        margin-right: 16px;
        padding-right: 20px;
    }
}
</style>
