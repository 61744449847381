<template>
    <multiview-editable-widget
        class="editable-image-widget"
        :is-opened="opened"
        :read-only="readOnly"
        @input="toggleOpen"
    >
        <template #name>{{ editable.label }}</template>
        <template #controls-prefix>
            <editable-restriction-control v-if="showRestrictionControl" :editable="editable" />
        </template>
        <template #controls>
            <Icon
                v-if="$auth.userCan($auth.Actions.CanManageMediaItems)"
                type="md-folder"
                size="18"
                @click.stop="smartPlacement"
            ></Icon>
        </template>

        <editable-file-list-view
            slot="list-view"
            :editable="editable"
            :groups-affecting-editable="groupsAffectingEditable"
            :is-opened="opened"
            @update="updateFileEditable"
        ></editable-file-list-view>
        <editable-file-grid-view
            slot="grid-view"
            :editable="editable"
            :groups-affecting-editable="groupsAffectingEditable"
            :is-opened="opened"
            @update="updateFileEditable"
        ></editable-file-grid-view>
    </multiview-editable-widget>
</template>

<script>
import EditableFileGridView from "@/components/Campaign/EditableFileGridView";
import EditableFileListView from "@/components/Campaign/EditableFileListView";
import { MediaLibraryAction } from "@/store/modules/mediaLibrary";
import MultiviewEditableWidget from "@/components/Campaign/widgets/MultiviewEditableWidget";

import editableItemMixin from "@/mixins/editableItemMixin";
import editableMethodsMixin from "@/mixins/editableMethodsMixin";
import EditableRestrictionControl from "@/components/Campaign/EditableRestrictionControl";

export default {
    name: "EditableImageWidget",

    components: {
        EditableRestrictionControl,
        MultiviewEditableWidget,
        EditableFileListView,
        EditableFileGridView
    },

    mixins: [editableItemMixin, editableMethodsMixin],

    computed: {
        masterTemplateIds() {
            return this.$store.state.campaign.normalized.masterTemplateIds;
        },

        masterTemplates() {
            return this.$store.state.campaign.normalized.masterTemplates;
        },

        masterTemplateSizes() {
            return this.masterTemplateIds.reduce(
                (acc, cur) =>
                    Object.assign(acc, {
                        [cur]: `${this.masterTemplates[cur].width}x${this.masterTemplates[cur].height}`
                    }),
                {}
            );
        }
    },

    methods: {
        async smartPlacement() {
            // 1. get file selection
            const selectedFiles = await this.$store.dispatch(MediaLibraryAction.SelectFiles, {});

            if (!selectedFiles) {
                return;
            }

            // 2. check if selection contains a file named default.xxx.
            let defaultImage = Object.values(selectedFiles).filter(
                file => file.name.replace(/\.(.+)$/, "") === "default"
            );

            let defaultUpdate = null;
            // 3. If It does use the file values to preset update values
            if (defaultImage.length) {
                defaultImage = defaultImage.pop();
                defaultUpdate = {
                    value: defaultImage.id,
                    mediaItem: defaultImage
                };
            }

            // 4. go through the selected files, if we find a file with a name that is matching this template's sizes
            // ( ${ cur.width }x${ cur.height } pattern - let's use this file for update.
            Object.keys(this.masterTemplateSizes).forEach(templateId => {
                let deliverableUpdate = {};

                if (defaultUpdate) {
                    deliverableUpdate = { ...defaultUpdate, ...deliverableUpdate };
                }

                const matchingSize = Object.values(selectedFiles).find(
                    file => file.name.toLowerCase().indexOf(this.masterTemplateSizes[templateId]) > -1
                );

                if (matchingSize) {
                    deliverableUpdate.value = matchingSize.id;
                    deliverableUpdate.mediaItem = matchingSize;
                }

                if (deliverableUpdate.value) {
                    this.update(deliverableUpdate, templateId);
                }
            });
        }
    }
};
</script>

<style lang="scss">
@import "../../../../sass/variables";

.editable-image-item .widget-mode {
    color: $cmp-dark-disabled-color;

    &--active {
        color: $cmp-dark-font-color;
    }
}
</style>
