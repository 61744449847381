/**
 * Meant as a boilerplate to simplify writing the pass through components
 * https://zendev.com/2018/05/31/transparent-wrapper-components-in-vue.html
 * @Usage:
 *   <i-table v-on="listeners" v-bind="attrs"></i-table>
 *
 */
export default {
    inheritAttrs: false,
    computed: {
        listeners() {
            // override with { input, ...listeners } to add local listeners handling
            const { ...listeners } = this.$listeners;
            return listeners || [];
        },

        attrs() {
            // override with { rows, ...attrs } where rows is the attr to add the local login
            const { ...attrs } = this.$attrs;
            return attrs;
        }
    }
};
