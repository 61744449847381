<template>
    <div :class="wrapperClasses" @click="$emit('click')">
        <Tooltip :delay="0" :disabled="!showTooltip" placement="bottom-end" trigger="hover">
            <div :class="classes">
                <div class="main-nav-button__background" />
                <div :class="iconContainerClasses">
                    <slot />
                </div>
                <div v-if="badgeCount" class="main-nav-button__badge-container">
                    <Badge :count="badgeCount" overflow-count="10" />
                </div>
            </div>
            <template #content>
                <slot name="tooltipContent" />
            </template>
        </Tooltip>
    </div>
</template>

<script>
export default {
    props: {
        badgeCount: {
            type: Number
        },

        hasCircleBorder: {
            type: Boolean
        },

        isActive: {
            type: Boolean
        },

        marginBottom: {
            default: "base",
            validator(value) {
                return ["none", "small", "base"].includes(value);
            }
        },

        showTooltip: {
            default: true,
            type: Boolean
        },

        theme: {
            default: "light",
            validator(value) {
                return ["dark", "light"].includes(value);
            }
        }
    },
    computed: {
        classes() {
            const classes = ["main-nav-button", `main-nav-button--theme-${this.theme}`];
            if (this.isActive) {
                classes.push("main-nav-button--active");
            }
            return classes;
        },

        iconContainerClasses() {
            const classes = ["main-nav-button__icon-container"];
            if (this.hasCircleBorder) {
                classes.push("main-nav-button__icon-container--circle-border");
            }
            return classes;
        },

        wrapperClasses() {
            return [`main-nav-button__wrapper--margin-bottom-${this.marginBottom}`];
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

$icon-container-size: 34px;
$icon-size: 24px;

.main-nav-button {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: $input-height-large;
    justify-content: center;
    position: relative;
    transition: color $transition-time-base $transition-function-base;
    width: $input-height-large;

    &--theme-light {
        color: $cmp-light-font-color;

        .main-nav-button__icon-container--circle-border {
            border-color: $cmp-light-font-color;
        }
    }

    &--theme-dark {
        color: $cmp-dark-font-color;

        .main-nav-button__icon-container--circle-border {
            border-color: $cmp-dark-font-color;
        }
    }

    &--active,
    &:hover {
        color: $white;

        .main-nav-button__background {
            opacity: 1;
        }

        .main-nav-button__icon-container--circle-border {
            border-color: $white;
        }
    }

    &:hover {
        .main-nav-button__background {
            background-image: linear-gradient(90deg, lighten(#1300ee, 5%) 0%, lighten(#3dc1ff, 5%) 100%);
        }
    }
}

.main-nav-button__background {
    border-radius: 10px;
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-image: linear-gradient(90deg, #1300ee 0%, #3dc1ff 100%);
    transition: opacity $transition-time-base $transition-function-base;
}

.main-nav-button__badge-container {
    position: absolute;
    top: $spacing-smallest;
    right: $spacing-smallest;

    .ivu-badge-count {
        background: $error-color;
        border-radius: 100%;
        box-shadow: none;
        font-family: inherit;
        font-size: $font-size-smaller;
        height: 20px;
        padding: 0;
        width: 20px;
    }
}

.main-nav-button__icon-container {
    align-items: center;
    display: flex;
    font-size: $icon-size;
    height: $icon-container-size;
    justify-content: center;
    position: relative;
    width: $icon-container-size;

    .ivu-icon {
        font-size: $icon-size;
    }

    &--circle-border {
        border-radius: $icon-container-size;
        border-style: solid;
        border-width: 2px;
        font-size: 18px;
        transition: border-color $transition-time-base $transition-function-base;

        .ivu-icon {
            font-size: 20px;
        }
    }

    > svg {
        display: inline-block;
        fill: currentColor;
        height: 1em;
    }
}

.main-nav-button__wrapper {
    &--margin-bottom-base {
        margin-bottom: $spacing-base;
    }

    &--margin-bottom-small {
        margin-bottom: $spacing-small;
    }

    &--margin-bottom-none {
        margin-bottom: 0;
    }
}
</style>
