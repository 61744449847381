import VueApollo from "vue-apollo";
import { ApolloClient } from "apollo-client";
import { BatchHttpLink } from "apollo-link-batch-http";
import { HttpLink } from "apollo-link-http";
import { defaultDataIdFromObject, InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink, split } from "apollo-link";
import { onError } from "apollo-link-error";
import { RetryLink } from "apollo-link-retry";
import { asyncAuthLink } from "@/services/Auth";
import { getObjectValue } from "@/utils";
import bus from "@/bus";
import config from "@/config";
import { LocalDevLink } from "./local-dev";

let loading = 0;

// eslint-disable-next-line complexity
const errorLink = onError(({ operation, response, graphQLErrors, networkError }) => {
    if (getObjectValue(operation, "query.definitions.length") && operation.query.definitions[0].operation === "query") {
        if (getObjectValue(graphQLErrors, "length")) {
            // eslint-disable-next-line no-param-reassign
            response.errors = null;
            bus.$emit("apolloLoadEvent", 0);
            bus.$emit("apolloErrorEvent", {
                graphQLErrors,
                message: `graphQLErrors: ${graphQLErrors[0].message}`
            });
            bus.$emit(`${operation.operationName}ErrorEvent`, graphQLErrors, networkError);
        }
    }

    /*
       We do not want the user to be redirected away from the page they are on if
       we have an error for some operations, preferring to handle the error at
       the component level.
    */
    const operationsToIgnore = [
        "PublishVariationsXmlFeed",
        "UnpublishVariationsXmlFeed",
        "DownloadDeliverable",
        "GenerateCreativeInsightsReport"
    ];
    if (networkError && !operationsToIgnore.includes(operation.operationName)) {
        if (networkError.statusCode === 409) {
            bus.$emit("userNotFound");
            return;
        }
        if (operation.getContext().useLocalDevLink) {
            bus.$emit("localDevNetworkError");
        } else {
            bus.$emit("networkError");
        }
    }
});

// You should use an absolute URL here e.g. 'https://d1vups0kf4xobi.cloudfront.net/graphql'
const batchLink = new BatchHttpLink({ uri: "/graphql" });
const httpLink = new HttpLink({ uri: "/graphql" });
const baseLink = split(operation => operation.operationName === "UpdateResizedTemplate", httpLink, batchLink);

const retryLink = new RetryLink({
    delay: count => count * 1000 * Math.random(),
    attempts: {
        max: 5,
        retryIf: error => {
            // If the error is a forbidden one then refresh the token and retry
            if ([401, 403].includes(error.statusCode)) {
                bus.$emit("refreshToken", error);
                return true;
            }
            // Error not due to forbidden so do not retry
            return false;
        }
    }
});

const httpWithAuthLink = asyncAuthLink.concat(baseLink);
const localDevLink = new LocalDevLink({ uri: config.appConfig.HOXTON_CLI_URI });

const splitLink = retryLink.split(
    operation => {
        return operation.getContext().useLocalDevLink;
    },
    localDevLink,
    httpWithAuthLink
);

const cache = new InMemoryCache({
    dataIdFromObject: object => {
        const typename = "__typename";
        switch (object[typename]) {
            case "MediaItem": {
                /*
                This is required otherwise an image mediaItem that is used twice in
                a campaign will only have the resizeSettings from the first instance
                of that mediaItem.
            */
                let cacheKey = `${object[typename]}:${object.id}`;
                if (object.overwriteId) {
                    cacheKey += `:${object.overwriteId}`;
                }
                return cacheKey;
            }
            case "Editable": {
                // Because defaultValues depend on campaign we need to take them into account when creating a cache key
                return `${object[typename]}:${object._id}:${JSON.stringify(object.defaultValues)}`;
            }
            default:
                return defaultDataIdFromObject(object);
        }
    }
});

const apolloLink = ApolloLink.from([errorLink, splitLink]);

const apolloClient = new ApolloClient({
    cache,
    link: apolloLink,
    connectToDevTools: true
});

const apolloProvider = new VueApollo({
    queryDeduplication: true,
    defaultClient: apolloClient,
    defaultOptions: {
        $query: {
            // fetchPolicy: 'cache-and-network',
        },
        $loadingKey: "loading"
    },
    watchLoading(state, mod) {
        bus.$emit("apolloLoadEvent", loading);
        loading = state !== undefined ? loading + mod : loading;
        return loading;
    },
    errorHandler(error) {
        bus.$emit("apolloLoadEvent", 0);
        bus.$emit("apolloErrorEvent", error);
    }
});

export { apolloProvider, apolloClient };
