export default (value = "") => {
    return (
        value
            // Remove any newlines around break tags
            .replace(/(\r?\n)?<br(\s+)?\/?>(\r?\n)?/gim, "<br />")
            // replace any newline characters which are left with break tags
            .replace(/\r?\n/gim, "\n<br />\n")
            // normalise <br /> tags so the front end can consistently process them
            .replace(/<br(\s+)?\/?>/gim, "<br />")
            // add whitespace about around the break tags
            .replace(/<br \/>/gim, "\n<br />\n")
            // remove consecutive new lines
            .replace(/(\r?\n)+/gim, "\n")
            // put consecutive br tags on same line
            .replace(/<br \/>\n(?=<br \/>)/gim, "<br />")
            // Remove any preleading and trailing whitespace that we may have produced
            .trim()
    );
};
