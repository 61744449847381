<template>
    <div class="key-takeaway-panel">
        <insights-data-provider
            v-if="type"
            :advanced-search="advancedSearch"
            :insights-type="type"
            :next-page-token="nextPageToken"
            :request-options="requestOptions"
            :sort-by="sortBy"
            @data="onData"
            @next="onNextData"
            @error="onError"
            @loading="onLoading"
            @loadingNext="onLoadingNext"
        />
        <!--        <div class="debug">
            <pre>{{ nextPageToken }}</pre>
            {{ page }}/ {{ totalPages }}
            <pre>{{ pagination }}</pre>
        </div>-->
        <div v-if="isLoading" class="insight-preview-body">
            <hox-loading-spinner v-if="isLoading" :size="40" />
        </div>
        <Collapse v-else v-model="opened" class="key-takeaway" simple>
            <Panel name="matching" class="key-takeaway__panel">
                <strong>{{ header }}&nbsp;</strong>
                <template #content>
                    <div class="key-takeaway__carousel-wrapper">
                        <div v-if="hasPrevious" class="key-takeaway__next" @click="switchPage(-1)">
                            <Icon type="ios-arrow-back" size="24" />
                        </div>

                        <div class="key-takeaway__creatives">
                            <div v-if="isLoadingNext" class="insight-preview-body">
                                <hox-loading-spinner v-if="isLoadingNext" :size="40" />
                            </div>
                            <template v-else>
                                <insights-creative-preview
                                    v-for="(creative, i) in pagedItems"
                                    :key="creative.reportId + i"
                                    class="creative-preview-insights key-takeaway__creative"
                                    :creative="creative"
                                    :stats="creative.summaryStats"
                                    :seek-to-attribute="true"
                                    @click="onClick"
                                />
                            </template>
                        </div>
                        <div
                            class="key-takeaway__next"
                            :class="{ 'key-takeaway__next--disabled': !hasNext }"
                            @click="switchPage(1)"
                        >
                            <Icon v-if="hasNext" type="ios-arrow-forward" size="24" />
                        </div>
                    </div>
                </template>
            </Panel>
        </Collapse>
    </div>
</template>
<script>
import { CreativeInsightsAction, CreativeInsightsGetters } from "@/store/modules/creativeInsights";
import paginationMixin from "@/mixins/paginationMixin";
import { InsightType } from "@/components/CreativeIntelligence/constants";
import InsightsDataProvider from "@/components/CreativeIntelligence/InsightsDataProvider";
import InsightsCreativePreview from "@/components/CreativeIntelligence/InsightPreviews/InsightsCreativePreview";

const itemsPerPage = 4;

export default {
    name: "KeyTakeawayPanel",
    components: { InsightsCreativePreview, InsightsDataProvider },
    mixins: [paginationMixin],
    props: {
        matching: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            opened: this.matching ? ["matching"] : [],
            trackInUrl: false,
            stats: {},
            deliverables: [],
            nextPageToken: "",
            isLoading: true,
            isLoadingNext: false,
            itemsPerPage: 4,
            pageToToken: [null, ""],
            dataPages: [null],
            pagination: {}
        };
    },

    computed: {
        advancedSearch() {
            return this.$store.getters[CreativeInsightsGetters.AdvancedSearchValue];
        },

        avgKpiValue() {
            if (!this.stats || !this.stats.kpiMetric) {
                return "";
            }

            return this.stats.kpiMetric.avg;
        },

        avgKpiValueDisplay() {
            if (!this.avgKpiValue) {
                return null;
            }

            return this.avgKpiValue.toFixed(3);
        },

        breakdownText() {
            // todo temp
            return "";
            /* if (this.$store.state.creativeInsights.selectedBreakdown === Breakdown.NoBreakdowns) {
                return "all";
            }

            return getBreakdownLabel(this.$store.state.creativeInsights.selectedBreakdown); */
        },

        delta() {
            return this.kpiSettingsValue - this.avgKpiValue;
        },

        deltaPercent() {
            return Math.ceil((Math.abs(this.delta) / this.kpiSettingsValue) * 100);
        },

        deltaText() {
            return this.delta > 0 ? "below" : "above";
        },

        hasNext() {
            return this.totalPages && this.page !== this.totalPages;
        },

        hasPrevious() {
            return this.totalPages && this.page !== 1;
        },

        header() {
            if (!this.stats.count) {
                return "";
            }
            /* curr: There are 65 ads containing Emotion out of 1123 ads. 69% of these ads are above your benchmark of 0.005 */
            // 65 out of 1123 ads [do not] contain Emotion, averaging 0.11 on your selected KPI. 69% of these ads are above your benchmark of 0.005
            let beginning = "";
            if (this.matching) {
                if (!this.selectedTagIds.length) {
                    beginning = `There are ${this.stats.count} out of ${this.stats.totalCount} ads matching the query`;
                } else {
                    beginning = `${this.stats.count} out of ${this.stats.totalCount} ads contain ${
                        this.tagsById[this.selectedTagIds[0]].name
                    }`;
                }
            } else if (!this.selectedTagIds.length) {
                beginning = `There are ${this.stats.count} out of ${this.stats.totalCount} ads not matching the query`;
            } else {
                beginning = `${this.stats.count} out of ${this.stats.totalCount} ads do not contain ${
                    this.tagsById[this.selectedTagIds[0]].name
                }`;
            }
            const above = `${this.percentAbove}% of these ads are above your benchmark of ${this.kpiSettingsValue}`;
            const avg = this.avgKpiValueDisplay ? `, averaging ${this.avgKpiValueDisplay}.` : ".";

            return `${beginning}${avg} ${above}`;
        },

        itemsToPage() {
            return this.deliverables;
        },

        kpiSettingsValue() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricValue];
        },

        parentTitle() {
            if (this.showsDiversityTemplate) {
                return `People appear on ${this.utilisationPercent}% of ${this.breakdownText} ads, and performs ${this.deltaPercent}% ${this.deltaText} your benchmark of ${this.kpiSettingsValue}`;
            }

            return `${this.resultSource} appears on ${this.utilisationPercent}% of ${this.breakdownText} ads, and performs ${this.deltaPercent}% ${this.deltaText} your benchmark of ${this.kpiSettingsValue}`;
        },

        percentAbove() {
            return Math.round((this.stats.adsAboveKPI / this.stats.count) * 100);
        },

        requestOptions() {
            return {
                topDeliverablesNumber: 1,
                topUniqueCreativesNumber: itemsPerPage
            };
        },

        resultSource() {
            return this.selectedTagIds.length
                ? `${this.tagsById[this.selectedTagIds[0]].name}`
                : "Ads matching the query";
        },

        selectedTagIds() {
            return this.$store.state.ciTags.selectedTagIds;
        },

        sortBy() {
            return this.$store.state.creativeInsights.sortBy;
        },

        tagsById() {
            return this.$store.state.ciTags.tagsById;
        },

        totalPages() {
            if (!this.pagination) {
                return 1;
            }

            return Math.ceil(this.pagination.total / itemsPerPage);
        },

        type() {
            return this.matching ? InsightType.KeyTakeaway : InsightType.KeyTakeawayNotMatching;
        },

        utilisationPercent() {
            if (!this.stats.totalCount) {
                return 0;
            }
            if (this.stats.utilisation > 1) {
                return Math.round(this.stats.utilisation);
            }

            return Math.round(this.stats.utilisation * 100);
        }
    },

    watch: {
        advancedSearch() {
            this.resetPagination();
        },

        page() {
            if (!this.dataPages[this.page]) {
                this.nextPageToken = this.pagination.nextPageToken;
            }
        },

        parentTitle() {
            this.$emit("parentTitle", this.parentTitle);
        }
    },

    created() {
        this.pageSize = itemsPerPage;
    },

    methods: {
        getDeliverablesFromDisplayableGroups({ data }) {
            const { uniqueCreativesGroups } = data;
            return uniqueCreativesGroups.reduce((acc, { deliverables: delivs, summaryStats }) => {
                if (delivs.length) {
                    acc.push({ ...delivs[0], summaryStats });
                }

                return acc;
            }, []);
        },

        onClick(creative) {
            this.$store.dispatch(CreativeInsightsAction.SetDrawerCreatives, [creative]);
        },

        onData(resp) {
            if (resp) {
                this.page = 1;
                this.deliverables = this.getDeliverablesFromDisplayableGroups(resp);
                this.stats = resp.data.summaryStats;
                this.pagination = resp.pagination;
                this.dataPages[this.page] = resp;
            }
        },

        onNextData(resp) {
            if (resp) {
                this.deliverables.push(...this.getDeliverablesFromDisplayableGroups(resp));
                this.pagination = resp.pagination;
                this.dataPages[this.page] = resp;
            }
        },

        onError() {
            this.resetData();
        },

        onLoading(loading) {
            if (loading) {
                this.resetPagination();
            }
            this.isLoading = loading;
            this.$emit("loading", loading);
        },

        onLoadingNext(loading) {
            this.isLoadingNext = loading;
            this.$emit("loadingNext", loading);
        },

        resetData() {
            this.deliverables = [];
            this.stats = {};
        },

        resetPagination() {
            this.page = 1;
            this.nextPageToken = "";
            this.pageToToken = [null, ""];
            this.dataPages = [null];
        },

        setPageNumber(number) {
            this.$emit("input", number);
        },

        switchPage(dir) {
            if (this.page + dir < 1) {
                this.page = 1;
                return;
            }

            if (this.page + dir > this.totalPages) {
                return;
            }

            this.page = this.page + dir;
        }
    }
};
</script>
<style lang="scss">
@import "../../../../sass/_variables.scss";

$bgColor: transparent !important;

.key-takeaway__carousel-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow: hidden;
    min-height: 352px;
    margin-bottom: $spacing;
}

.key-takeaway__creatives {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
}
.key-takeaway__creative {
    flex: 0 1;
}

.key-takeaway__next {
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 342px;
    border: 0 none;
    cursor: pointer;

    &--disabled {
        cursor: default;
    }
}
</style>
