<template>
    <div :id="member.id" class="teamMember">
        <div class="teamMemberEmail">{{ member.id }}</div>
        <Tag
            v-if="entityType !== 'client' && member.accessViaScope === 'client'"
            class="teamMemberScope"
            type="border"
        >
            Client Team
        </Tag>
        <div v-if="canRemove(member.id)" class="revoke" @click="remove">
            <Icon type="ios-close"></Icon>
        </div>
        <div class="clear"></div>
        <div class="teamMemberAccess">{{ member.role.name }}</div>
    </div>
</template>

<script>
export default {
    name: "TeamMember",
    props: {
        member: {
            type: Object,
            required: true
        },
        entityType: {
            type: String,
            required: true
        }
    },

    computed: {
        userId() {
            return this.$store.state.auth.me && this.$store.state.auth.me.id;
        }
    },

    methods: {
        canRemove(memberId) {
            // allowed to remove users
            return (
                this.$auth.userCan(this.$auth.Actions.CanManageUsers) &&
                // can't remove self
                this.userId !== memberId &&
                // can't remove client level users from a campaign
                (this.entityType !== "campaign" || this.member.accessViaScope === "campaign")
            );
        },

        remove() {
            this.$emit("remove");
        }
    }
};
</script>

<style lang="scss">
@import "../../sass/variables";

.teamMember {
    margin-bottom: 0.5rem;

    &Email {
        font-size: 1rem;
        font-weight: bold;
        float: left;
    }

    &Access {
        text-transform: capitalize;
    }

    &Scope {
        float: right;

        .ivu-tag-text {
            color: $info-color !important;
        }

        &.ivu-tag-border {
            border-color: $info-color;
        }
    }

    .revoke {
        float: right;
        font-size: 0.8rem;
        padding-top: 5px;
        cursor: pointer;
    }

    .clear {
        clear: both;
    }
}
</style>
