<template>
    <empty-message type="bug" class="network-error">
        <template #title>It's not you, it's the network error</template>
        <template #content>
            <p v-if="!hasRecovered">We are experiencing some technical difficulties connecting to the server.</p>
            <p v-if="!hasRecovered && retry > 5">
                We are still trying to connect to the server, however You can try to manually refresh this page.
            </p>
            <p v-if="hasRecovered && !returnRoute">
                <router-link :to="{ name: 'Home' }">Click here to go to the homepage</router-link>
            </p>
        </template>
    </empty-message>
</template>

<script>
import meQuery from "@/apollo/queries/Me.gql";
import EmptyMessage from "@/components/common/EmptyMessage";

export default {
    name: "NetworkError",
    components: { EmptyMessage },
    data() {
        return {
            retry: 0,
            hasRecovered: false
        };
    },
    apollo: {
        me: {
            query: meQuery,
            pollInterval: 5000,
            fetchPolicy: "no-cache",
            result({ data, error }) {
                if (!error && data.me) {
                    this.hasRecovered = true;

                    if (this.returnRoute) {
                        this.$router.replace(this.returnRoute);
                        this.$store.commit("ui/setNetworkErrorReturnRoute", null);
                    }
                } else {
                    this.retry += 1;
                }
            }
        }
    },

    computed: {
        returnRoute() {
            return this.$store.state.ui.networkErrorReturnRoute;
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.network-error {
    margin: 50px auto;
    text-align: center;

    p {
        margin: 10px 0;
    }
}
</style>
