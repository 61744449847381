<template>
    <tr>
        <slot />
    </tr>
</template>

<script>
export default {
    props: {
        isSticky: {
            type: Boolean
        },
        isSubHeader: {
            type: Boolean
        }
    },
    data() {
        return {
            stickyOffsetPixels: undefined
        };
    },
    mounted() {
        if (this.isSticky) {
            this.setStickyOffsetPixels();
        }
    },
    methods: {
        setStickyOffsetPixels() {
            this.stickyOffsetPixels = this.$el.offsetTop;
        }
    },
    provide() {
        return {
            isStickyTop: () => this.isSticky,
            isSubHeader: () => this.isSubHeader,
            /*
                In Chrome <thead> nor <tr> can have `position: sticky` applied to
                them, so we need to apply it to the <th> elements, so we calculate
                the offset once and pass it down to all children.
            */
            stickyTopOffsetPixels: () => this.stickyOffsetPixels
        };
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
</style>
