export const JOB_STATUSES = {
    PENDING: "PENDING",
    IN_PROGRESS: "IN_PROGRESS",
    COMPLETE: "COMPLETE",
    ABORTED: "ABORTED",
    FAILED: "FAILED"
};

export default {
    JOB_STATUSES
};
