<template>
    <div class="labelled-select">
        <deliverable-label v-if="label" :color="labelColor" :label="label" />
        <Select :value="value" class="hox-select-dark" size="small" :placeholder="placeholder" @input="onChange">
            <Option v-for="option in options" :key="option.value" :value="option.value">{{ option.label }}</Option>
        </Select>
    </div>
</template>
<script>
import DeliverableLabel from "@/components/Campaign/DeliverableLabel";
import { EditableEvent } from "@/enums/editables";

export default {
    name: "LabelledSelect",
    components: { DeliverableLabel },
    props: {
        label: { type: String, default: "" },
        labelColor: {
            type: String,
            default: "default"
        },

        options: {
            type: Array,
            required: true
        },
        placeholder: {
            type: String,
            default: "Select"
        },

        // eslint-disable-next-line vue/require-prop-types
        value: {
            required: true
        }
    },

    methods: {
        onChange(value) {
            this.$emit(EditableEvent.Update, value);
        }
    }
};
</script>

<style lang="scss">
.labelled-select {
    display: flex;
    flex: 1 0;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    &__tag {
        flex: 0 0 80px;
    }

    &__text {
        flex: 1 0;
        display: flex;
        overflow: hidden;
        padding: 0 5px;
        align-items: center;
    }
}
</style>
