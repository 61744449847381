<template>
    <div>
        <DropdownItem
            :divided="isDivided"
            :selected="selectedStatus === BannerQaStatus.Draft"
            data-testid="production-status--draft"
            @click.native="emitStatus(BannerQaStatus.Draft)"
        >
            <qa-status-dot :status="BannerQaStatus.Draft" />
            {{ BannerQaStatusLabel[BannerQaStatus.Draft] }}
        </DropdownItem>
        <DropdownItem
            :selected="selectedStatus === BannerQaStatus.InReview"
            data-testid="production-status--in-review"
            @click.native="emitStatus(BannerQaStatus.InReview)"
        >
            <qa-status-dot :status="BannerQaStatus.InReview" />
            {{ BannerQaStatusLabel[BannerQaStatus.InReview] }}
        </DropdownItem>
        <DropdownItem
            :selected="selectedStatus === BannerQaStatus.Approved"
            data-testid="production-status--approved"
            @click.native="emitStatus(BannerQaStatus.Approved)"
        >
            <qa-status-dot :status="BannerQaStatus.Approved" />
            {{ BannerQaStatusLabel[BannerQaStatus.Approved] }}
        </DropdownItem>
        <DropdownItem
            :selected="selectedStatus === BannerQaStatus.Rejected"
            data-testid="production-status--rejected"
            @click.native="emitStatus(BannerQaStatus.Rejected)"
        >
            <qa-status-dot :status="BannerQaStatus.Rejected" />
            {{ BannerQaStatusLabel[BannerQaStatus.Rejected] }}
        </DropdownItem>
    </div>
</template>

<script>
import QaStatusDot from "@/components/Qa/QaStatusDot";
import { BannerQaStatus, BannerQaStatusLabel } from "@/enums/banners";

export default {
    components: {
        QaStatusDot
    },
    props: {
        isDivided: {
            type: Boolean
        },
        selectedStatus: {
            type: String
        }
    },
    created() {
        this.BannerQaStatus = BannerQaStatus;
        this.BannerQaStatusLabel = BannerQaStatusLabel;
    },
    methods: {
        emitStatus(status) {
            this.$emit("statusSelected", status);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
</style>
