<template>
    <div class="editable-group-batch-upload">
        <upload-button :format="mimetype" :before-upload="onBeforeUpload" :loading="isLoading">
            <template #button>
                <slot></slot>
            </template>
        </upload-button>

        <div v-if="uploadedFile.name" class="editable-group-batch-upload__description">
            <span class="editable-group-batch-upload__name">{{ uploadedFile.name }}</span>
            <span class="editable-group-batch-upload__size">{{ bytesToMegabytes(uploadedFile.size) }}</span>
            <Icon type="md-close" @click="resetUploadedFile" />
        </div>
    </div>
</template>
<script>
import { parseStringPromise } from "xml2js";
import mimetype from "@/enums/mimetype";
import UploadButton from "@/components/Campaign/UploadButton";

export default {
    name: "FeedUploadButton",
    components: { UploadButton },
    data() {
        return {
            isLoading: false,
            uploadedFile: {}
        };
    },
    created() {
        this.mimetype = [mimetype.XML, mimetype.JSON];
    },

    methods: {
        onBeforeUpload(file) {
            this.uploadedFile = file;
            if (file.type === mimetype.XML) {
                this.readXml(file);
                return false;
            }

            if (file.type === mimetype.JSON) {
                console.log("JSON");

                this.readJson(file);
                return false;
            }

            this.$emit("error", "The file needs to be either XML or JSON.");
            return false;
        },

        async readXml(file) {
            try {
                this.isLoading = true;
                const str = await file.text();
                const importData = await parseStringPromise(str);
                this.$emit("xmlLoad", importData);
            } catch (e) {
                this.$snackbar.error(e);
            }
            this.isLoading = false;
        },

        async readJson(file) {
            try {
                this.isLoading = true;
                const str = await file.text();
                const importData = await JSON.parse(str);
                this.$emit("jsonLoad", importData);
            } catch (e) {
                this.$snackbar.error(e);
            }
            this.isLoading = false;
        },

        resetUploadedFile() {
            this.uploadedFile = {};
            this.$emit("resetUploadedFile");
        },

        bytesToMegabytes(bytes) {
            const megabytes = bytes / 1048576;
            return `${megabytes.toFixed(2)} MB`;
        }
    }
};
</script>
