<template>
    <loading-bar
        class="loading-bar"
        :on-error-done="onErrorDone"
        :on-progress-done="onProgressDone"
        :progress="progress"
        direction="right"
        :error="error"
    ></loading-bar>
</template>
<script>
import LoadingBar from "vue2-loading-bar";
import bus from "@/bus";

export default {
    name: "HoxLoadingBar",
    components: { LoadingBar },

    data() {
        return {
            callback: null,
            error: false,
            load: 0,
            progress: 0
        };
    },

    created() {
        bus.$on("percentLoadEvent", (load, callback) => {
            this.load = load;
            this.progress = load;
            this.callback = callback;
        });

        bus.$on("apolloLoadEvent", load => {
            this.load = load;
            this.progress = load === 0 ? 100 : 100 / load;
        });
        bus.$on("apolloErrorEvent", err => {
            this.setToError(true);
            // Process global errors
            if ([401, 403].includes(err.statusCode)) {
                this.$snackbar.unauthorized(err);
            } else if (Array.isArray(err.graphQLErrors)) {
                this.$snackbar.graphQLErrors(err.graphQLErrors);
            }
        });
    },

    methods: {
        onErrorDone() {
            this.error = false;
        },

        onProgressDone() {
            this.progress = 0;

            if (this.callback && typeof this.callback === "function") {
                this.callback();
                this.callback = null;
            }
        },

        setToError(bol) {
            this.error = bol;
        }
    }
};
</script>
