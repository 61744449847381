<template>
    <hox-modal @close="cancel">
        <div class="qa-confirmation-modal__alert-wrapper">
            <hox-alert type="danger">
                <template #title>
                    <slot name="title" />
                </template>
                <template #content>
                    <slot name="content" />
                </template>
            </hox-alert>
        </div>
        <template #footer>
            <Button class="qa-confirmation-modal__button" :disabled="isDoingAction" @click="cancel">
                <slot name="cancelButtonContent">Cancel</slot>
            </Button>
            <Button class="qa-confirmation-modal__button" :loading="isDoingAction" type="error" @click="confirm">
                <slot name="confirmButtonContent">Confirm</slot>
            </Button>
        </template>
    </hox-modal>
</template>

<script>
import HoxAlert from "@/components/common/Alert";
import HoxModal from "@/components/Modal/Modal/Modal";

export default {
    components: {
        HoxAlert,
        HoxModal
    },
    props: {
        isDoingAction: {
            type: Boolean
        }
    },
    methods: {
        cancel() {
            if (!this.isDoingAction) {
                this.$emit("cancel");
            }
        },
        confirm() {
            this.$emit("confirm");
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.qa-confirmation-modal__alert-wrapper {
    padding: $spacing 0 0;
    margin: 0 0 (0 - $spacing);
}

.qa-confirmation-modal__button {
    margin: 0 0 0 $spacing-small;

    .ivu-icon-ios-loading {
        position: absolute;
        right: calc(50% - 10px);
        top: calc(50% - 10px);
    }

    &.ivu-btn > .ivu-icon + span {
        margin-left: 0;
    }
}
</style>
