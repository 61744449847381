<template>
    <multiview-editable-widget
        class="editable-folder-widget"
        :is-opened="opened"
        :read-only="readOnly"
        @input="toggleOpen"
    >
        <template #name>{{ editable.label }}</template>
        <template #controls-prefix>
            <editable-restriction-control v-if="showRestrictionControl" :editable="editable" />
        </template>

        <template #controls></template>
        <editable-file-list-view
            slot="list-view"
            :editable="editable"
            :groups-affecting-editable="groupsAffectingEditable"
            :is-opened="opened"
            :type="editable.type"
            @update="updateFileEditable"
        ></editable-file-list-view>
        <editable-file-grid-view
            slot="grid-view"
            :editable="editable"
            :groups-affecting-editable="groupsAffectingEditable"
            :is-opened="opened"
            :type="editable.type"
            @update="updateFileEditable"
        ></editable-file-grid-view>
    </multiview-editable-widget>
</template>

<script>
import MultiviewEditableWidget from "@/components/Campaign/widgets/MultiviewEditableWidget";
import EditableFileGridView from "@/components/Campaign/EditableFileGridView";
import EditableFileListView from "@/components/Campaign/EditableFileListView";

import editableItemMixin from "@/mixins/editableItemMixin";
import editableMethodsMixin from "@/mixins/editableMethodsMixin";
import EditableRestrictionControl from "@/components/Campaign/EditableRestrictionControl";

export default {
    name: "EditableVideoWidget",

    components: {
        EditableRestrictionControl,
        MultiviewEditableWidget,
        EditableFileListView,
        EditableFileGridView
    },

    mixins: [editableItemMixin, editableMethodsMixin]
};
</script>

<style lang="scss">
@import "../../../../sass/variables";

.editable-folder-item .widget-mode {
    color: $cmp-dark-disabled-color;

    &--active {
        color: $cmp-dark-font-color;
    }
}
</style>
