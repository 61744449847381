<template>
    <empty-message type="bug" class="page-not-found">
        <template #title>It's not you, it's us...</template>
        <template #content>
            <p>Sorry we can't find the page you're looking for.</p>
            <p>
                <router-link :to="{ name: 'Home' }">Click here to go to the homepage</router-link>
            </p>
        </template>
    </empty-message>
</template>

<script>
import EmptyMessage from "@/components/common/EmptyMessage";

export default {
    name: "PageNotFound",
    components: { EmptyMessage }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.page-not-found {
    margin: 50px auto;
    text-align: center;

    p {
        margin: 10px 0;
    }
}
</style>
