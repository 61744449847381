<template>
    <div class="library-deliverable-table-status-cell">
        <qa-status-label display="block" :is-clickable="true" :status="status" @click="$emit('click')" />
    </div>
</template>

<script>
import QaStatusLabel from "@/components/Qa/QaStatusLabel";

export default {
    components: {
        QaStatusLabel
    },
    props: {
        status: {
            required: true,
            type: String
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
</style>
