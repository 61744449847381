<template>
    <div class="dashboard-filters">
        <Tag v-for="filter in tagFilters" :key="filter.key" closable @on-close="removeFilter(filter)">
            {{ filter.filterValue }}
        </Tag>
    </div>
</template>
<script>
import { CreativeInsightsAction } from "@/store/modules/creativeInsights";

export default {
    name: "DashboardFilters",
    computed: {
        selectedDynamicBreakdowns() {
            return this.$store.state.creativeInsights.selectedDynamicBreakdowns;
        },

        tagFilters() {
            return Object.keys(this.selectedDynamicBreakdowns).reduce((acc, filterGroup) => {
                this.selectedDynamicBreakdowns[filterGroup].forEach(filterValue => {
                    acc.push({
                        key: `${filterGroup}:${filterValue}`,
                        filterValue,
                        filterGroup
                    });
                });

                return acc;
            }, []);
        }
    },

    methods: {
        removeFilter({ filterValue, filterGroup }) {
            const updatedBranch = this.selectedDynamicBreakdowns[filterGroup].filter(value => value !== filterValue);

            if (updatedBranch.length) {
                this.$store.dispatch(CreativeInsightsAction.SetSelectedDynamicBreakdowns, {
                    ...this.selectedDynamicBreakdowns,
                    [filterGroup]: updatedBranch
                });

                return;
            }

            const updatedFilters = { ...this.selectedDynamicBreakdowns };
            delete updatedFilters[filterGroup];
            this.$store.dispatch(CreativeInsightsAction.SetSelectedDynamicBreakdowns, updatedFilters);
        }
    }
};
</script>
