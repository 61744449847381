<template>
    <div class="qa-annotations">
        <collapsible-widget content-padding="none" :is-opened="isExpanded" @input="setIsExpanded">
            <span slot="name" class="qa-annotations__title">
                <slot name="titleContent">Annotations ({{ annotations.length }})</slot>
            </span>
            <template #controls>
                <qa-user-status-indicators :users="usersWithStatus" />
            </template>
            <div class="qa-annotations__annotations">
                <div v-if="!hasAnnotations" class="qa-annotations__no-annotations-message">
                    <slot name="emptyMessageContent">
                        <p>There are currently no internal annotations.</p>
                    </slot>
                </div>
                <qa-single-annotation
                    v-for="annotation of filteredAnnotations"
                    v-else
                    :key="annotation._id"
                    :annotation="annotation"
                    :is-selected="checkIfAnnotationIsSelected(annotation)"
                    @annotationDeleted="removeAnnotation"
                    @annotationSelected="selectAnnotation"
                    @annotationUpdated="replaceAnnotation"
                    @positionFromTopOfDocument="emitAnnotationsPositionFromTopOfDocument"
                />
            </div>
        </collapsible-widget>
    </div>
</template>

<script>
import QaUserStatusIndicators from "@/components/Qa/QaUserStatusIndicators";
import QaSingleAnnotation from "@/components/Qa/QaSingleAnnotation";
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";
import { AnnotationStatus } from "@/enums/annotations";

export default {
    components: {
        CollapsibleWidget,
        QaSingleAnnotation,
        QaUserStatusIndicators
    },
    props: {
        annotationType: {
            type: String,
            required: true
        },
        annotations: {
            required: true,
            type: Array
        },
        selectedAnnotation: {
            validator(value) {
                return value === null || value._id;
            }
        },

        showResolved: {
            type: Boolean,
            default: false
        },

        usersWithStatus: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            isExpanded: true
        };
    },
    computed: {
        filteredAnnotations() {
            return this.showResolved
                ? this.annotations
                : this.annotations.filter(annotation => annotation.status === AnnotationStatus.Unresolved);
        },

        hasAnnotations() {
            return this.filteredAnnotations.length > 0;
        }
    },
    watch: {
        annotations() {
            this.setIsExpanded(true);
        },
        selectedAnnotation() {
            if (this.selectedAnnotation && this.selectedAnnotation.type === this.annotationType) {
                this.setIsExpanded(true);
            }
        }
    },
    methods: {
        checkIfAnnotationIsSelected(annotation) {
            return this.selectedAnnotation !== null && annotation._id === this.selectedAnnotation._id;
        },
        emitAnnotationsPositionFromTopOfDocument(annotationsPositionFromTopOfDocument) {
            this.$emit("annotationsPositionFromTopOfDocument", annotationsPositionFromTopOfDocument);
        },
        removeAnnotation(annotation) {
            this.$emit("annotationDeleted", annotation);
        },
        replaceAnnotation(annotation) {
            this.$emit("annotationUpdated", annotation);
        },
        selectAnnotation(annotation) {
            this.$emit("annotationSelected", annotation);
        },
        setIsExpanded(isExpanded) {
            this.isExpanded = isExpanded;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_mixins.scss";
@import "@/../sass/_variables.scss";

.qa-annotations {
    .ivu-collapse > .ivu-collapse-item {
        border: none;
        border-bottom-color: transparent;

        &.ivu-collapse-item-active {
            border-bottom-color: $grey3;
        }

        > .ivu-collapse-header {
            border-bottom: 1px solid $grey3;
        }
    }

    .collapsible-widget.ivu-collapse .ivu-collapse-content > .ivu-collapse-content-box {
        border-bottom: 1px solid $grey3;
    }
}

.qa-annotations__annotations {
    background: $cmp-light-bg-color;
    padding: $spacing-small $spacing-small 0;

    @include clearfix();
}

.qa-annotations__title {
    font-size: $font-size-base;
}

.qa-annotations__no-annotations-message {
    background: $white;
    border: 1px dashed $grey3;
    color: $grey5;
    margin: 0 0 $spacing-small;
    padding: $spacing $spacing $spacing-small;

    > p {
        font-size: $font-size-small;
        margin-bottom: $spacing-small;
    }
}
</style>
