import { fetcher, postFetcher } from "./utils";

const ENDPOINT = "enriched-resources";

export const getResourcesByGroupIds = async commaSeparatedResourceGroupIds => {
    const resp = await fetcher(ENDPOINT, { resourceGroupIds: encodeURIComponent(commaSeparatedResourceGroupIds) });
    return resp;
};

export const addResources = async resources => {
    return postFetcher(ENDPOINT, { resources });
};
