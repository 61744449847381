<template>
    <Poptip class="banner-info-poptip" padding="0" placement="bottom-start" transfer trigger="hover">
        <span class="banner-info-poptip__content">
            <slot>{{ reportingLabel }}</slot>
        </span>
        <template #content>
            <div class="banner-info-poptip__info">
                <h3 class="banner-info-poptip__info-title" :title="reportingLabel">{{ reportingLabel }}</h3>
                <ul class="banner-info-poptip__info-list">
                    <li v-if="showVisibleToRoles">
                        <span class="banner-info-poptip__info-list-title">
                            Visible to {{ visibleToRoles.length }} {{ rolesLabel }}
                        </span>
                        <p v-for="role in visibleToRoles" :key="role._id">
                            {{ role.name }}
                        </p>
                    </li>
                    <li>
                        <span class="banner-info-poptip__info-list-title">Published on DoubleClick</span>
                        {{ publishedOnDoubleClickLabel }}
                    </li>
                    <li>
                        <span class="banner-info-poptip__info-list-title">Published on Sizmek</span>
                        {{ publishedOnSizmekLabel }}
                    </li>
                    <li>
                        <span class="banner-info-poptip__info-list-title">Published on OpenDC</span>
                        {{ publishedOnOpenDCLabel }}
                    </li>
                    <li>
                        <span class="banner-info-poptip__info-list-title">Language</span>
                        {{ languageLabel }}
                    </li>
                    <li>
                        <span class="banner-info-poptip__info-list-title">Template</span>
                        {{ deliverable.masterTemplate.name }}
                    </li>
                    <li>
                        <span class="banner-info-poptip__info-list-title">Size</span>
                        {{ deliverable.masterTemplate.width }}x{{ deliverable.masterTemplate.height }}
                    </li>
                    <li v-for="(groupValue, groupName) in summaryGroupValues" :key="groupName">
                        <span class="banner-info-poptip__info-list-title">
                            {{ groupName | formatGroupName }}
                        </span>
                        {{ groupValue }}
                    </li>
                </ul>
            </div>
        </template>
    </Poptip>
</template>

<script>
import { DeliverableLibraryGetters } from "@/store/modules/deliverableLibrary";
import { RolesGetter } from "@/store/modules/roles";
import { formatGroupName } from "@/utils";
import languageUtils from "@/utils/languages";

export default {
    filters: {
        formatGroupName
    },
    props: {
        deliverable: {
            type: Object,
            required: true
        }
    },
    computed: {
        languageLabel() {
            return languageUtils.localeToFriendlyName(this.deliverable.language);
        },

        publishedOnDoubleClickLabel() {
            const isPublishedOnDoubleClick = this.$store.getters[
                DeliverableLibraryGetters.computedDeliverableIsPublishedToDoubleClick
            ](this.deliverable.idHash);
            return isPublishedOnDoubleClick ? "Yes" : "No";
        },

        publishedOnSizmekLabel() {
            const isPublishedOnSizmek = this.$store.getters[
                DeliverableLibraryGetters.computedDeliverableIsPublishedToSizmek
            ](this.deliverable.idHash);
            return isPublishedOnSizmek ? "Yes" : "No";
        },

        publishedOnOpenDCLabel() {
            const isPublishedOnODC = this.$store.getters[DeliverableLibraryGetters.computedDeliverableIsPublishedToODC](
                this.deliverable.idHash
            );
            return isPublishedOnODC ? "Yes" : "No";
        },

        reportingLabel() {
            return this.deliverable.reportingLabel;
        },
        roles() {
            return this.$store.state.roles.roles;
        },
        rolesLabel() {
            return this.visibleToRoles.length > 1 ? "roles" : "role";
        },
        rolesThatCanViewAllDeliverables() {
            return this.$store.getters[RolesGetter.getRolesThatHaveAction](
                this.$auth.Actions.CanChangeDeliverableVisibility
            );
        },
        showVisibleToRoles() {
            return this.$auth.userCan(this.$auth.Actions.CanListRoles) && this.visibleToRoles.length > 0;
        },
        summaryGroupValues() {
            const allGroups = this.$store.state.campaign.normalized.editableGroupIds.reduce(
                (acc, cur) => Object.assign(acc, { [cur]: "None" }),
                {}
            );
            let combinationGroups = {};
            if (this.deliverable.combination) {
                let { combination } = this.deliverable;
                if (!Array.isArray(combination)) {
                    combination = [combination];
                }
                const reductor = (acc, combinationGroup) => {
                    const groupName = Object.keys(combinationGroup).pop();
                    return Object.assign(acc, {
                        [groupName]:
                            this.$store.state.campaign.editableGroupValues[combinationGroup[groupName].groupValueId]
                                .value
                    });
                };
                combinationGroups = combination.reduce(reductor.bind(this), {});
            }
            return { ...allGroups, ...combinationGroups };
        },
        visibleToRoles() {
            /*
                To avoid confusion for the user, we're always going to list roles that can
                view all deliverables.
            */
            const roles = [...this.rolesThatCanViewAllDeliverables];
            const deliverableStatus =
                this.$store.state.deliverableLibrary.deliverableStatusByIdHash[this.deliverable.idHash];
            if (deliverableStatus) {
                roles.push(...this.roles.filter(role => deliverableStatus.visibleTo.includes(role._id)));
            }
            return roles;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.banner-info-poptip {
    &.ivu-poptip {
        max-width: 100%;
    }

    .ivu-poptip-rel {
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: top;
        white-space: nowrap;
        width: 100%;
    }
}

.banner-info-poptip__content {
    overflow: hidden;
    padding: 0 $spacing-smaller;
}

.banner-info-poptip__info {
    max-height: 300px;
    max-width: 200px;
    overflow-y: auto;
    padding: $spacing;
}

.banner-info-poptip__info-list {
    > li {
        border-bottom: 1px solid $grey3;
        display: flex;
        flex-wrap: wrap;
        font-size: $font-size-small;
        font-weight: bold;
        justify-content: space-between;
        padding: 0 0 $spacing-smaller;
        margin: 0 0 $spacing-smaller;

        p {
            width: 100%;
        }

        &:last-child {
            border-bottom: none;
            margin: 0;
            padding: 0;
        }
    }
}

.banner-info-poptip__info-list-title {
    font-weight: normal;
}

.banner-info-poptip__info-title {
    font-size: $font-size-base;
    font-weight: bold;
    margin: 0 0 $spacing;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
