<template>
    <div />
</template>

<script>
import { fetcher } from "@/components/Reporting/data/utils";

const queryEndpoint = "metadata/report-kpis";

export default {
    name: "ReportMetricsDataProvider",
    data() {
        return {
            isLoading: false,
            error: []
        };
    },

    computed: {
        baseOptions() {
            return {
                workerId: this.workerId,
                withStats: true
            };
        },

        workerId() {
            return this.$store.state.creativeInsights.workerId;
        }
    },

    watch: {
        workerId() {
            this.fetch();
        }
    },

    mounted() {
        this.fetch();
    },

    methods: {
        async fetch() {
            if (!this.workerId) {
                return;
            }
            try {
                this.isLoading = true;
                this.$emit("loading", this.isLoading);
                const response = await fetcher(queryEndpoint, this.baseOptions);
                const { data, message } = await response.json();
                if (response.status === 200) {
                    this.$emit("data", data);
                } else {
                    this.$emit("error", message);
                }
            } catch (e) {
                this.$emit("error", e);
            } finally {
                this.isLoading = false;
                this.$emit("loading", this.isLoading);
            }
        }
    }
};
</script>

<style scoped></style>
