<template>
    <div class="deliverable-preview-drawer">
        <div class="deliverable-preview-drawer__container">
            <div class="deliverable-preview-drawer__header">
                <navigation-tabs v-if="isPublished">
                    <navigation-tabs-item :is-active="activeTab === 'preview'" @click="activeTab = 'preview'">
                        Production
                    </navigation-tabs-item>
                    <navigation-tabs-item
                        v-if="isPublishedToDoubleClick"
                        :is-active="activeTab === Platform.DoubleClick"
                        @click="activeTab = Platform.DoubleClick"
                    >
                        {{ PlatformLabel[Platform.DoubleClick] }}
                    </navigation-tabs-item>
                    <navigation-tabs-item
                        v-if="isPublishedToSizmek"
                        :is-active="activeTab === Platform.Sizmek"
                        @click="activeTab = Platform.Sizmek"
                    >
                        {{ PlatformLabel[Platform.Sizmek] }}
                    </navigation-tabs-item>
                    <navigation-tabs-item
                        v-if="isPublishedToOpenDC"
                        :is-active="activeTab === Platform.OpenDC"
                        @click="activeTab = Platform.OpenDC"
                    >
                        {{ PlatformLabel[Platform.OpenDC] }}
                    </navigation-tabs-item>
                </navigation-tabs>
            </div>

            <div class="deliverable-preview-drawer__body">
                <template v-if="deliverable">
                    <hox-alert
                        v-if="activeTab !== 'preview'"
                        class="deliverable-preview-drawer__feed-wrapper"
                        size="small"
                        :show-icon="false"
                        align-text="center"
                    >
                        <template #content>
                            <div class="deliverable-preview-drawer__feed">
                                <a
                                    ref="feedUrl"
                                    class="deliverable-preview-drawer__feed-link"
                                    :href="publishedStatus.xmlFeedUrl"
                                    target="_blank"
                                >
                                    {{ publishedStatus.xmlFeedUrl }}
                                </a>
                                <Button
                                    class="deliverable-preview-drawer__feed-copy"
                                    size="small"
                                    @click="copyFeedUrl"
                                >
                                    {{ copyBtnText }}
                                </Button>
                            </div>
                        </template>
                    </hox-alert>

                    <div v-if="!isAE" class="deliverable-preview-drawer__qr-wrapper">
                        <preview-url-qr-code
                            class="deliverable-preview-drawer__qr-code"
                            :deliverable="deliverable"
                        ></preview-url-qr-code>
                        <div class="deliverable-preview-drawer__qr-copy">Scan to view on mobile</div>
                    </div>
                    <div class="deliverable-preview-drawer__preview-wrapper">
                        <banner-preview
                            :key="activeTab"
                            :banner="previewBannerAdapter(deliverable)"
                            :content-clickable="true"
                            :group="BannerGroup.Preview"
                            :iframe-prefix="BannerGroup.Preview"
                            :preview-values="bannerPreviewValues"
                            :style="templateSize"
                            :ad-type="deliverable.masterTemplate.adType"
                        />
                    </div>
                </template>
            </div>
            <div class="deliverable-preview-drawer__footer">
                <banner-scrubbar :value="bannerPlaybackTime" @change="setBannerPlaybackTime" />
                <square-button has-inverse-hover @click="playPauseReplayBanners">
                    <Icon :type="playPauseReplayIcon" />
                </square-button>
            </div>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { TemplateType } from "shared-utils/enums/masterTemplate";

import BannerPreview from "@/components/Previews/BannerPreview";
import BannerScrubbar from "@/components/Previews/BannerScrubbar";
import NavigationTabs from "@/components/common/NavigationTabs/Container";
import NavigationTabsItem from "@/components/common/NavigationTabs/Tab";
import SquareButton from "@/components/common/SquareButton";
import HoxAlert from "@/components/common/Alert";

import { BannerGroup } from "@/enums/banners";
import { Platform, PlatformLabel } from "@/enums/platforms";

import bannerTimelineMixin from "@/mixins/bannerTimelineMixin";
import deliverableMethodsMixin from "@/mixins/deliverableMethodsMixin";

import URLManager from "@/services/URLManager";

import { DeliverableLibraryAction, DeliverableLibraryGetters } from "@/store/modules/deliverableLibrary";
import PreviewUrlQrCode from "@/components/Campaign/PreviewUrlQrCode";

const QueryParams = {
    ActiveTab: "ddp-at"
};

export default {
    name: "DeliverablePreviewDrawerTab",
    components: {
        PreviewUrlQrCode,
        HoxAlert,
        BannerPreview,
        BannerScrubbar,
        NavigationTabs,
        NavigationTabsItem,
        SquareButton
    },
    mixins: [deliverableMethodsMixin, bannerTimelineMixin],
    props: {
        deliverable: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            bannerGroup: BannerGroup.Preview,
            copyBtnText: "Copy URL"
        };
    },

    computed: {
        activeTab: {
            get() {
                return this.$store.state.deliverableLibrary.previewDrawerTab.activeTab;
            },
            set(value) {
                this.$store.dispatch(DeliverableLibraryAction.SetPreviewDrawerActiveTab, value);
            }
        },

        bannerPreviewValues() {
            let { deliverable } = this;

            if (this.activeTab !== "preview") {
                deliverable = {
                    computedValues: this.publishedEditables,
                    metadata: this.publishedMetadata,
                    masterTemplate: this.deliverable.masterTemplate
                };
            }

            return this.previewValues(deliverable);
        },

        bannerTime() {
            if (this.bannerState && this.bannerState.currentTime) {
                return this.bannerState.currentTime;
            }

            return 0;
        },

        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        deliverableStatus() {
            return this.$store.getters[DeliverableLibraryGetters.computedDeliverableStatus](this.deliverable.idHash);
        },

        deliverableReportingLabel() {
            if (this.deliverable) {
                return this.deliverable.reportingLabel || "";
            }

            return "";
        },

        isAE() {
            return this.deliverable.masterTemplate.adType === TemplateType.AE;
        },

        isPublished() {
            return this.isPublishedToDoubleClick || this.isPublishedToSizmek || this.isPublishedToOpenDC;
        },

        isPublishedToDoubleClick() {
            if (this.deliverable) {
                return this.$store.getters[DeliverableLibraryGetters.computedDeliverableIsPublishedToDoubleClick](
                    this.deliverable.idHash
                );
            }

            return false;
        },

        isPublishedToSizmek() {
            if (this.deliverable) {
                return this.$store.getters[DeliverableLibraryGetters.computedDeliverableIsPublishedToSizmek](
                    this.deliverable.idHash
                );
            }

            return false;
        },

        isPublishedToOpenDC() {
            if (this.deliverable) {
                return this.$store.getters[DeliverableLibraryGetters.computedDeliverableIsPublishedToODC](
                    this.deliverable.idHash
                );
            }

            return false;
        },

        publishedEditables() {
            return this.publishedStatus ? this.publishedStatus.computedEditables : [];
        },

        publishedMetadata() {
            return this.publishedStatus ? this.publishedStatus.metadata : [];
        },

        publishedStatus() {
            return this.$store.state.deliverableLibrary.deliverableStatusByIdHash[this.deliverable.idHash]
                .publishedStatus[this.activeTab];
        },

        templateSize() {
            const { height, width } = this.deliverable.masterTemplate;
            return {
                height: `${height}px`,
                width: `${width}px`
            };
        }
    },
    watch: {
        activeTab() {
            this.bannerPlaybackTime = 0;
            this.setActiveTabQueryParams();
        },
        bannerTime() {
            this.setBannerPlaybackTimeFromBanner(this.bannerTime);
        }
    },

    beforeDestroy() {
        this.unsetAllQueryParams();
    },

    created() {
        this.urlManager = new URLManager(this.$router, {
            defaults: [
                {
                    key: QueryParams.ActiveTab,
                    value: ["preview"]
                }
            ],
            tracked: Object.values(QueryParams)
        });
        this.BannerGroup = BannerGroup;
        this.Platform = Platform;
        this.PlatformLabel = PlatformLabel;
        this.initActiveTab();
        this.setActiveTabQueryParams();
    },
    methods: {
        copyFeedUrl() {
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(this.$refs.feedUrl);
            selection.removeAllRanges();
            selection.addRange(range);

            try {
                document.execCommand("copy");
                selection.removeAllRanges();

                const original = this.copyBtnText;
                this.copyBtnText = "Copied!";

                setTimeout(() => {
                    this.copyBtnText = original;
                }, 1200);
            } catch (e) {
                this.$snackbar.error(e);
            }
        },

        // eslint-disable-next-line complexity
        initActiveTab() {
            if (!this.activeTab) {
                const queryParamActiveTab = this.$route.query[QueryParams.ActiveTab];
                if (
                    (queryParamActiveTab === Platform.DoubleClick && this.isPublishedToDoubleClick) ||
                    (queryParamActiveTab === Platform.Sizmek && this.isPublishedToSizmek)
                ) {
                    this.activeTab = queryParamActiveTab;
                } else {
                    this.activeTab = "preview";
                }
            }
        },

        setActiveTabQueryParams() {
            this.urlManager.setURLParams(
                [
                    {
                        key: QueryParams.ActiveTab,
                        value: [this.activeTab]
                    }
                ],
                true
            );
        },

        unsetAllQueryParams() {
            this.urlManager.setURLParams(
                Object.values(QueryParams).map(key => ({
                    key,
                    value: []
                })),
                true
            );
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.deliverable-preview-drawer {
    .ivu-drawer-body {
        flex: 1;
        height: auto;
        overflow: hidden;
        padding: 0;
        position: relative;
        width: 100%;
    }

    .ivu-drawer-content {
        display: flex;
        flex-direction: column;
    }

    .timeline-slider {
        align-items: center;
        display: flex;
        flex: 1 0;
        width: 100%;
    }

    .ivu-slider-wrap {
        margin: 9px ($spacing + 9) 9px 9px;
    }

    .play-pause-all {
        margin: 0;
        color: $grey5;
        transition: color 0.2s linear;

        &:hover {
            color: $darktheme100;
        }
    }
}

.deliverable-preview-drawer__body {
    background: $cmp-light-bg-color;
    display: flex;
    flex: 1;
    overflow: auto;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.deliverable-preview-drawer__container {
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.deliverable-preview-drawer__qr {
    &-wrapper {
        flex: 0 0;
        margin: $spacing-large 0 0 $spacing-large;
        padding: $spacing $spacing $spacing-small $spacing;
        border: 1px solid $grey2;
        background-color: $white;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }

    &-code {
        margin-bottom: $spacing-small;
    }

    &-copy {
        white-space: nowrap;
    }
}

.deliverable-preview-drawer__header {
    background-color: white;
    border-bottom: 1px solid $grey2;
    padding-left: $spacing-large;

    .navigation-tab {
        padding: 24px $spacing-large 20px;
    }
}

.deliverable-preview-drawer__footer {
    border-top: 1px solid $grey3;
    display: flex;
    padding: $spacing-small $spacing-large;
}

.deliverable-preview-drawer__preview-wrapper {
    flex: 0 0;
    padding: $spacing-large;
    margin: 0 auto;
}

.deliverable-preview-drawer__status-container {
    padding: 0 0 $spacing-large;
}

.deliverable-preview-drawer__feed {
    position: relative;
    width: 100%;
    min-height: 30px;
    display: flex;
    align-items: center;

    &-wrapper {
        flex: 1 0 calc(100% - #{$spacing-large * 2});
        width: calc(100% - #{$spacing-large * 2});
        margin: $spacing $spacing-large 0 $spacing-large;
    }

    &-link {
        color: $darkgrey2;
        padding: 0 20px;
    }

    &-copy {
        margin-left: auto;
        margin-right: 12px;

        &.ivu-btn {
            font-size: 11px;
            background-color: $grey2;
            border: none;

            &:hover {
                background-color: $grey3;
            }
        }
    }
}
</style>
