<template>
    <collapsible-widget :is-opened="true">
        <template slot="name">Sizes</template>
        <template slot="controls">
            <Badge v-if="selectedValues.length" :count="selectedValues.length" class="filter-badge-count"></Badge>
        </template>
        <library-checkbox-group v-model="selectedValues" :options="options" />
    </collapsible-widget>
</template>
<script>
import { DeliverableLibraryAction, DeliverableLibraryGetters } from "@/store/modules/deliverableLibrary";
import LibraryCheckboxGroup from "@/components/Campaign/LibraryCheckboxGroup";
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";

export default {
    name: "LibrarySizeFilter",
    components: {
        CollapsibleWidget,
        LibraryCheckboxGroup
    },

    computed: {
        options() {
            return this.$store.state.deliverableLibrary.filterOptions.masterTemplates;
        },

        selectedValues: {
            get() {
                return this.$store.getters[DeliverableLibraryGetters.sizeFilters];
            },
            set(newValue) {
                this.$store.dispatch(DeliverableLibraryAction.UpdateSizeFilter, {
                    values: newValue
                });
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
</style>
