<template>
    <video v-bind="attrs" v-on="listeners">
        <slot></slot>
    </video>
</template>
<script>
import passThroughMixin from "@/mixins/passThroughMixin";

export default {
    name: "VideoWrapper",
    mixins: [passThroughMixin],
    data() {
        return {
            stateMonitoringInterval: null,
            lastCurrentTime: 0
        };
    },

    mounted() {
        this.bindEvents();
        this.startMonitoringTime();
    },

    beforeDestroy() {
        this.stopMonitoringTime();
        if (this.$el.remove) {
            this.$el.remove();
        }

        if (this.$el.srcObject) {
            this.$el.srcObject = null;
        }
    },

    methods: {
        bindEvents() {
            this.$el.addEventListener("error", () => this.emit("error", this.$el.error));
            this.$el.addEventListener("playing", () => this.emit("play"));
            this.$el.addEventListener("play", () => this.emit("play"));
            this.$el.addEventListener("pause", () => this.emit("pause"));
            this.$el.addEventListener("canplay", () => this.emit("ready"));
            this.$el.addEventListener("waiting", () => this.emit("wait"));
            this.$el.addEventListener("loadstart", () => this.emit("wait"));
            this.$el.addEventListener("loadeddata", () => this.emit("loadeddata"));
            this.$el.addEventListener("volumechange", () =>
                this.emit("volumechange", { muted: this.$el.muted, volume: this.$el.volume })
            );
            this.$el.addEventListener("durationchange", () => this.emit("duration", this.$el.duration));
            this.$el.addEventListener("resize", () =>
                this.emit("resize", { width: this.$el.videoWidth, height: this.$el.videoHeight })
            );
            this.$el.addEventListener("seeking", () => this.emit("seek"));
            this.$el.addEventListener("seeked", () => this.emit("seeked"));
            this.$el.addEventListener("ended", () => this.emit("ended"));
        },

        emit(event, payload) {
            this.$emit(event, payload);
        },

        startMonitoringTime() {
            this.stateMonitoringInterval = setInterval(() => this.updateCurrentTime(), 200);
        },

        updateCurrentTime() {
            if (this.$el.currentTime === this.lastCurrentTime) {
                return;
            }

            this.lastCurrentTime = this.$el.currentTime;

            this.$emit("update", {
                currentTime: this.lastCurrentTime
            });
        },

        stopMonitoringTime() {
            clearInterval(this.stateMonitoringInterval);
        }
    }
};
</script>
