<template>
    <hox-modal @close="close">
        <hox-loading-layer v-if="isUpdatingFont" />
        <template #header>Edit custom font</template>
        <font-form
            :upload-config="uploadConfig"
            :initial-form-data="initialFormData"
            :font-preview-url="font.url"
            @validationErrorsUpdate="setValidationErrors"
            @formDataUpdate="setFormData"
            @formTabSwitched="onTabChange"
            @submit="updateFont"
        />
        <Alert banner type="warning">
            Licensing
            <template #desc>Only upload fonts if the appropriate font license agreement has been obtained</template>
        </Alert>
        <div v-if="error" class="edit-font-modal__unexpected-error-wrapper">
            <Alert banner type="error">
                <template #desc>{{ error }}</template>
            </Alert>
        </div>
        <template #footer>
            <Button :disabled="isUpdatingFont" type="primary" @click="updateFont">Update</Button>
        </template>
    </hox-modal>
</template>

<script>
import updateFontMutation from "@/apollo/mutations/UpdateFont.gql";
import FontForm from "@/components/FontForm";

export default {
    name: "EditFontModal",

    components: {
        FontForm
    },

    props: {
        // sections: {
        //     required: true,
        //     type: Array
        // },
        font: {
            required: true,
            type: Object
        },

        uploadConfig: {
            required: true,
            type: Object
        }
    },

    data() {
        return {
            error: "",
            isUpdatingFont: false,
            formData: {},
            validationErrors: []
        };
    },

    computed: {
        clientId() {
            return this.$store.state.route.params.clientId;
        },
        initialFormData() {
            // Here we only want to set the url as formData if it was originally an external font because otherwise
            // The uploaded persistent s3 url will be added to the form
            return {
                name: this.font.name,
                ...(this.font.key && { key: this.font.key }),
                ...(!this.font.key && { url: this.font.url })
                // sectionId: font.section ? font.section._id : "none"
            };
        },
        updateMutationVariables() {
            return {
                fontId: this.font._id,
                font: {
                    name: this.formData.name,
                    ...(this.formData.key && this.font.key !== this.formData.key && { key: this.formData.key }),
                    ...(this.formData.url && this.font.url !== this.formData.url && { url: this.formData.url })
                    // sectionId: this.formData.sectionId === "none" ? null : this.formData.sectionId
                }
            };
        }
    },

    methods: {
        async updateFont() {
            if (!this.isUpdatingFont) {
                // Return if there are validation errors
                if (this.validationErrors.length) {
                    const [error] = this.validationErrors;
                    this.error = error;
                    return;
                }
                // Lock and reset errors
                this.isUpdatingFont = true;
                this.error = "";

                try {
                    await this.$apollo.mutate({
                        mutation: updateFontMutation,
                        variables: this.updateMutationVariables
                    });

                    this.$snackbar.success("Font updated");
                    this.$emit("fontUpdated");
                    this.close();
                } catch ({ graphQLErrors }) {
                    const duplicateKey = graphQLErrors.find(
                        ({ extensions }) => extensions && extensions.code === "DUPLICATE_KEY"
                    );

                    this.error = duplicateKey
                        ? duplicateKey.message
                        : "There was an unexpected error and the font could not be updated. Please try again in a few moments.";
                } finally {
                    this.isUpdatingFont = false;
                }
            }
        },

        close() {
            this.$emit("close");
        },

        onTabChange() {
            this.error = "";
        },

        setFormData(formData) {
            this.formData = formData;
        },

        setValidationErrors(validationErrors) {
            this.validationErrors = validationErrors;

            if (!this.validationErrors.length) {
                this.error = "";
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.edit-font-modal__unexpected-error-wrapper {
    padding: $spacing 0 0 0;
}
</style>
