<template>
    <div class="client-select">
        <Select class="client-select-inner" data-testid="clients-list__select" v-bind="attrs" v-on="listeners">
            <Option
                v-for="client in options"
                :key="client._id"
                class="client-select-option"
                :value="client._id"
                :title="client.name"
            >
                {{ client.name }}
            </Option>
        </Select>
    </div>
</template>

<script>
import passThroughMixin from "@/mixins/passThroughMixin";
import bus from "@/bus";

import ClientsQuery from "@/apollo/queries/Clients.gql";

export default {
    name: "ClientSelect",
    mixins: [passThroughMixin],

    props: {
        excludedIds: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            clients: [],
            isLoading: true
        };
    },

    computed: {
        options() {
            return this.clients.filter(
                client => client._id === this.attrs.value || !this.excludedIds.includes(client._id)
            );
        }
    },

    apollo: {
        clients: {
            query: ClientsQuery,
            error(e) {
                bus.$emit("apolloErrorEvent", e);
            },
            result({ data: { clients } }) {
                this.isLoading = false;
                this.clients = clients;
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.client-select {
    &-inner {
        .ivu-select-selection {
            right: 0;
        }

        &.ivu-select-visible {
            .ivu-select-selection {
                box-shadow: none;
            }
        }

        .ivu-select-selection {
            border: none;
            background-color: inherit;
            color: inherit;

            .ivu-select-placeholder {
                color: inherit;
            }
        }
    }
}
</style>
