import { ApiKey, BaseUrl } from "@/components/Reporting/data/constants";

export const buildOptions = paramsObject => {
    const paramsArray = Object.entries(paramsObject).reduce((acc, [key, value]) => {
        acc.push(`${key}=${value}`);

        return acc;
    }, []);

    return paramsArray.join("&");
};

export const baseRequestOptions = {
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
        "x-api-key": ApiKey
    },
    credentials: "same-origin", // include, *same-origin, omit
    referrerPolicy: "no-referrer" // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
};

export const fetcher = (queryEndpoint, options, requestOptions = {}) => {
    return fetch(`${BaseUrl}/${queryEndpoint}?${buildOptions(options)}`, {
        ...baseRequestOptions,
        method: "GET",
        ...requestOptions
    });
};

export const postFetcher = (queryEndpoint, body, options = {}) => {
    return fetch(`${BaseUrl}/${queryEndpoint}`, {
        method: "POST",
        ...baseRequestOptions,
        headers: {
            ...baseRequestOptions.headers,
            ...(body && { "Content-Type": "application/json" })
        },
        ...(body && { body: JSON.stringify(body) }),
        ...options
    });
};

export const fetchRequest = ({
    method = "GET",
    endpoint,
    body,
    headers = {},
    options = {},
    apiKey = ApiKey,
    contentType = "application/json"
}) => {
    const requestOptions = {
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        referrerPolicy: "no-referrer",
        headers: {
            "x-api-key": apiKey,
            "Content-Type": contentType,
            ...headers
        },
        method,
        ...options
    };

    requestOptions.headers["Content-Type"] = contentType;
    if (body) {
        requestOptions.body = body;
    }

    return fetch(endpoint, requestOptions);
};
