<template>
    <div class="library-deliverable-table-empty-cell"><slot></slot></div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

$line-width: 2px;

.library-deliverable-table-empty-cell {
    align-items: center;
    background: repeating-linear-gradient(
        45deg,
        $white,
        $white $line-width,
        $grey1 $line-width,
        $grey1 ($line-width * 2)
    );
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
</style>
