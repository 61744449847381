<template>
    <div class="creative-intelligence-settings-button" @click="showSettingsModal">
        <creative-insights-settings-modal />

        <div class="creative-intelligence-settings-button__header">BENCHMARK</div>
        <div v-if="kpi" class="creative-intelligence-settings-button__value">{{ getLabel(kpi) }}</div>
    </div>
</template>
<script>
import { CreativeInsightsAction, CreativeInsightsGetters } from "@/store/modules/creativeInsights";
import CreativeInsightsSettingsModal from "@/components/CreativeIntelligence/CreativeInsightsSettingsModal";
import { getMetricsLabel } from "@/enums/creativeInteligence";

export default {
    name: "CreativeInsightsHeaderSettingsButton",
    components: { CreativeInsightsSettingsModal },

    computed: {
        kpi() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricName];
        }
    },

    methods: {
        getLabel(metric) {
            return getMetricsLabel(metric);
        },

        showSettingsModal() {
            this.$store.dispatch(CreativeInsightsAction.ToggleSettingsModal);
        }
    }
};
</script>

<style lang="scss">
.creative-intelligence-settings-button {
    &__header {
        font-size: 10px;
        line-height: 12px;
    }

    &__value {
        white-space: nowrap;
        line-height: 18px;
    }
}
</style>
