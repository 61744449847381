<template>
    <div class="user-not-found">
        <div class="user-not-found__wrapper">
            <!-- prettier-ignore -->
            <h1>Oops!</h1>
            <p>
                It appears that you do not have sufficient permissions to log in to this platform.
                <br />
                Please click
                <a href="https://forms.office.com/e/iW434UrcAf"><strong>here</strong></a>
                to request access.
            </p>
        </div>
    </div>
</template>

<script>
import meQuery from "@/apollo/queries/Me.gql";
import { DefaultUser } from "@/enums/users";

export default {
    name: "UserNotFound",
    data() {
        return {
            hasData: true,
            userName: ""
        };
    },

    computed: {
        isDefaultUser() {
            return this.userName === DefaultUser;
        }
    },

    async mounted() {
        this.userName = await this.$auth.userDisplayName(true);
    },

    apollo: {
        me: {
            query: meQuery,
            pollInterval: 5000,
            fetchPolicy: "no-cache",
            result({ data, error }) {
                if (!error && data.me) {
                    this.hasData = true;
                    this.$router.replace({ name: "Home" });
                }
            }
        }
    }
};
</script>

<style lang="scss">
.user-not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    text-align: center;

    &__wrapper {
        margin: 50px auto;
        text-align: center;
    }

    h1 {
        font-size: 32px;
        margin-bottom: 25px;
    }

    p {
        font-size: 14px;
        margin: 10px 0;
    }
}
</style>
