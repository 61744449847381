<template>
    <hox-modal data-testid="add-section-modal" @close="close">
        <hox-loading-layer v-if="isAddingSection" />
        <template #header>Add section</template>
        <campaign-section-form
            :external-errors="mutationErrors"
            :show-validation-errors="showValidationErrors"
            @hasValidationErrorsUpdate="setHasValidationErrors"
            @formDataUpdate="setFormData"
            @submit="addSection"
        />
        <div v-if="hasErrorAddingSection" class="add-campaign-section-modal__unexpected-error-wrapper">
            <Alert banner type="error">
                <template #desc>
                    There was an unexpected error and the section could not be added. Please try again in a few moments.
                </template>
            </Alert>
        </div>
        <template #footer>
            <Button
                :disabled="showValidationErrors && hasValidationErrors"
                type="primary"
                data-testid="add-section-modal__add-button"
                @click="addSection"
            >
                Add
            </Button>
        </template>
    </hox-modal>
</template>

<script>
import CampaignSectionForm from "@/components/CampaignSectionForm";
import addCampaignSectionMutation from "@/apollo/mutations/AddCampaignSection.gql";
import { groupErrorsByField } from "@/utils";

export default {
    components: {
        CampaignSectionForm
    },

    props: {
        clientId: {
            required: true,
            type: String
        }
    },

    data() {
        return {
            hasErrorAddingSection: false,
            hasValidationErrors: false,
            isAddingSection: false,
            formData: {},
            mutationErrors: {},
            showValidationErrors: false
        };
    },

    watch: {
        // eslint-disable-next-line func-names
        "formData.name": function () {
            this.$delete(this.mutationErrors, "name");
        }
    },

    methods: {
        async addSection() {
            this.showValidationErrors = true;
            if (!this.hasValidationErrors) {
                this.hasErrorAddingSection = false;
                this.isAddingSection = true;
                try {
                    await this.$apollo.mutate({
                        mutation: addCampaignSectionMutation,
                        variables: {
                            clientId: this.clientId,
                            name: this.formData.name
                        }
                    });
                } catch ({ graphQLErrors }) {
                    this.mutationErrors = groupErrorsByField(graphQLErrors);
                    this.hasErrorAddingSection = Object.keys(this.mutationErrors).length === 0;
                    return;
                } finally {
                    this.isAddingSection = false;
                }
                this.$emit("sectionAdded");
                this.close();
            }
        },

        close() {
            this.$emit("close");
        },

        setFormData(formData) {
            this.formData = formData;
        },

        setHasValidationErrors(hasValidationErrors) {
            this.hasValidationErrors = hasValidationErrors;
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/../sass/_variables.scss";

.add-campaign-section-modal__unexpected-error-wrapper {
    padding: $spacing 0 0 0;
}
</style>
