<template>
    <Collapse v-model="opened" :class="classes">
        <Panel name="1" class="collapsible-widget__panel">
            <span class="collapsible-widget__panel-name">
                <span class="collapsible-widget__panel-name--inner">
                    <slot name="name"></slot>
                </span>
            </span>
            <span class="collapsible-widget__panel-controls">
                <slot name="controls"></slot>
            </span>
            <template v-if="scroll">
                <div slot="content" class="collapsible-widget__body">
                    <div class="collapsible-widget__body-scroll">
                        <slot></slot>
                    </div>
                </div>
            </template>
            <template v-else>
                <div slot="content" class="collapsible-widget__body">
                    <slot></slot>
                </div>
            </template>
        </Panel>
    </Collapse>
</template>

<script>
export default {
    name: "CollapsibleWidget",
    props: {
        collapsedHeight: {
            type: [String, Number]
        },
        isOpened: {
            type: Boolean,
            default: true
        },
        contentPadding: {
            default: "default",
            validator(value) {
                return ["default", "none"].includes(value);
            }
        },
        scroll: {
            type: Boolean,
            default: true
        },
        size: {
            validator(value) {
                return value === undefined || "small";
            }
        }
    },

    data() {
        return {
            opened: ""
        };
    },

    computed: {
        classes() {
            const classes = ["collapsible-widget"];
            if (this.size === "small") {
                classes.push("collapsible-widget--small");
            }

            if (this.contentPadding === "none") {
                classes.push("collapsible-widget--content-padding-none");
            }

            if (this.collapsedHeight) {
                classes.push(
                    `collapsible-widget--collapse-to-min collapsible-widget--collapse-to-min-${this.collapsedHeight}`
                );
            }

            return classes;
        }
    },

    watch: {
        isOpened: {
            handler(isOpened) {
                this.opened = isOpened ? "1" : "";
            },
            immediate: true
        },
        opened() {
            this.$emit("input", this.opened.length > 0);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

/*
  Only some of the styles relevant to this component are here, with other classes
  and overwrites elsewhere: we should consolidate those here, but it felt fraught
  with danger.
*/

$small-height: 34px;

.collapsible-widget {
    &.ivu-collapse {
        border: none;
        border-radius: 0;

        .ivu-collapse-content > .ivu-collapse-content-box {
            padding: 6px 0;
        }
    }

    .ivu-checkbox-wrapper {
        margin: 0;
        padding: 0 0 0 50px;
        display: block;
    }

    &__panel {
        .ivu-collapse-header {
            display: flex;
            width: 100%;
            flex: 1 0 100%;
            justify-content: flex-start;
            align-items: center;

            & > .ivu-icon {
                display: flex;
            }
        }

        &-name {
            flex: 1 0;
            overflow: hidden;

            &--inner {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &-controls {
            display: flex;
            flex: 0 0;
            align-items: center;
            align-self: flex-end;
            margin: 12px 5px;
        }
    }
}

.collapsible-widget--content-padding-none {
    &.ivu-collapse > .ivu-collapse-item > .ivu-collapse-content > .ivu-collapse-content-box {
        padding: 0;
    }
}

.collapsible-widget--small {
    &.ivu-collapse > .ivu-collapse-item {
        > .ivu-collapse-header {
            background: $white;
            font-size: $font-size-small;
            height: $small-height;
            line-height: $small-height;
            padding: 0 0 0 $spacing-small;

            > i {
                font-size: $font-size-small;
                margin-right: $spacing-small;
            }
        }

        &.ivu-collapse-item-active .ivu-collapse-header {
            background: $white;
        }
    }

    .collapsible-widget__panel-controls {
        align-self: auto;
        margin-bottom: 0;
        margin-top: 0;
    }
}

.collapsible-widget--collapse-to-min {
    .ivu-collapse-content {
        display: block !important;
    }

    &-47 {
        .ivu-collapse-content {
            min-height: 47px !important;
        }
    }

    &-139 {
        .ivu-collapse-content {
            min-height: 139px !important;
        }
    }
}
</style>
