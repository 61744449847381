<template>
    <div class="library-qa-status-filter">
        <collapsible-widget :is-opened="true">
            <template #name>Review Status</template>
            <CheckboxGroup :value="qaUserStatusFilterSelection">
                <Checkbox
                    :label="BannerQaUserStatusFilter.All"
                    @click.native.stop.prevent="setQaUserStatusFilter(FilterGroup.All, BannerQaUserStatusFilter.All)"
                >
                    {{ BannerQaUserStatusFilterLabel[BannerQaUserStatusFilter.All] }}
                </Checkbox>
                <Checkbox
                    :label="FilterGroup.Approved"
                    @click.native.stop.prevent="
                        setQaUserStatusFilter(FilterGroup.Approved, BannerQaUserStatusFilter.IApproved, true)
                    "
                >
                    Approved
                </Checkbox>
                <div v-show="isApprovedSubmenuVisible" class="library-published-status-filter__sub-group">
                    <Checkbox
                        :label="BannerQaUserStatusFilter.IApproved"
                        @click.native.stop.prevent="
                            setQaUserStatusFilter(FilterGroup.Approved, BannerQaUserStatusFilter.IApproved)
                        "
                    >
                        {{ BannerQaUserStatusFilterLabel[BannerQaUserStatusFilter.IApproved] }}
                    </Checkbox>
                    <Checkbox
                        :label="BannerQaUserStatusFilter.Approved"
                        @click.native.stop.prevent="
                            setQaUserStatusFilter(FilterGroup.Approved, BannerQaUserStatusFilter.Approved)
                        "
                    >
                        {{ BannerQaUserStatusFilterLabel[BannerQaUserStatusFilter.Approved] }}
                    </Checkbox>
                </div>

                <Checkbox
                    :label="FilterGroup.Rejected"
                    @click.native.stop.prevent="
                        setQaUserStatusFilter(FilterGroup.Rejected, BannerQaUserStatusFilter.IRejected, true)
                    "
                >
                    Rejected
                </Checkbox>
                <div v-show="isRejectedSubmenuVisible" class="library-published-status-filter__sub-group">
                    <Checkbox
                        :label="BannerQaUserStatusFilter.IRejected"
                        @click.native.stop.prevent="
                            setQaUserStatusFilter(FilterGroup.Rejected, BannerQaUserStatusFilter.IRejected)
                        "
                    >
                        {{ BannerQaUserStatusFilterLabel[BannerQaUserStatusFilter.IRejected] }}
                    </Checkbox>
                    <Checkbox
                        :label="BannerQaUserStatusFilter.Rejected"
                        @click.native.stop.prevent="
                            setQaUserStatusFilter(FilterGroup.Rejected, BannerQaUserStatusFilter.Rejected)
                        "
                    >
                        {{ BannerQaUserStatusFilterLabel[BannerQaUserStatusFilter.Rejected] }}
                    </Checkbox>
                </div>

                <Checkbox
                    :label="FilterGroup.NotReviewed"
                    @click.native.stop.prevent="
                        setQaUserStatusFilter(FilterGroup.NotReviewed, BannerQaUserStatusFilter.INone, true)
                    "
                >
                    Not reviewed
                </Checkbox>
                <div v-show="isNotReviewedSubmenuVisible" class="library-published-status-filter__sub-group">
                    <Checkbox
                        :label="BannerQaUserStatusFilter.INone"
                        @click.native.stop.prevent="
                            setQaUserStatusFilter(FilterGroup.NotReviewed, BannerQaUserStatusFilter.INone)
                        "
                    >
                        {{ BannerQaUserStatusFilterLabel[BannerQaUserStatusFilter.INone] }}
                    </Checkbox>
                    <Checkbox
                        :label="BannerQaUserStatusFilter.None"
                        @click.native.stop.prevent="
                            setQaUserStatusFilter(FilterGroup.NotReviewed, BannerQaUserStatusFilter.None)
                        "
                    >
                        {{ BannerQaUserStatusFilterLabel[BannerQaUserStatusFilter.None] }}
                    </Checkbox>
                </div>
            </CheckboxGroup>
        </collapsible-widget>
    </div>
</template>

<script>
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";
import { BannerQaUserStatusFilter, BannerQaUserStatusFilterLabel } from "@/enums/banners";
import { DeliverableLibraryAction, DeliverableLibraryGetters } from "@/store/modules/deliverableLibrary";

const FilterGroup = {
    All: "All",
    Approved: "Approved",
    NotReviewed: "NotReviewed",
    Rejected: "Rejected"
};
const defaultSelection = {
    [FilterGroup.All]: BannerQaUserStatusFilter.All,
    [FilterGroup.Approved]: null,
    [FilterGroup.Rejected]: null,
    [FilterGroup.NotReviewed]: null
};

export default {
    components: {
        CollapsibleWidget
    },

    computed: {
        isApprovedSubmenuVisible() {
            return !!this.qaUserStatusFilterMap[FilterGroup.Approved];
        },

        isNotReviewedSubmenuVisible() {
            return !!this.qaUserStatusFilterMap[FilterGroup.NotReviewed];
        },

        isRejectedSubmenuVisible() {
            return !!this.qaUserStatusFilterMap[FilterGroup.Rejected];
        },

        qaUserStatusFilterMap: {
            get() {
                const selectedFilters = this.$store.getters[DeliverableLibraryGetters.userStatusFilter];
                if (selectedFilters === BannerQaUserStatusFilter.All) {
                    return { ...defaultSelection };
                }

                return selectedFilters.reduce(
                    (acc, filter) => {
                        if (this.isApprovedValue(filter)) {
                            return {
                                ...acc,
                                [this.FilterGroup.Approved]: filter,
                                [this.FilterGroup.All]: null
                            };
                        }

                        if (this.isRejectedValue(filter)) {
                            return {
                                ...acc,
                                [this.FilterGroup.Rejected]: filter,
                                [this.FilterGroup.All]: null
                            };
                        }

                        if (this.isNotReviewedValue(filter)) {
                            return {
                                ...acc,
                                [this.FilterGroup.NotReviewed]: filter,
                                [this.FilterGroup.All]: null
                            };
                        }

                        return acc;
                    },
                    { ...defaultSelection }
                );
            },
            set(value) {
                this.$store.dispatch(
                    DeliverableLibraryAction.UpdateUserStatusFilter,
                    Object.values(value).filter(Boolean)
                );
            }
        },

        qaUserStatusFilterSelection() {
            return [
                ...Object.keys(this.qaUserStatusFilterMap).filter(key => !!this.qaUserStatusFilterMap[key]),
                ...Object.values(this.qaUserStatusFilterMap).filter(Boolean)
            ];
        }
    },
    created() {
        this.BannerQaUserStatusFilter = BannerQaUserStatusFilter;
        this.BannerQaUserStatusFilterLabel = BannerQaUserStatusFilterLabel;
        this.FilterGroup = FilterGroup;

        this.approvedFilterStateses = [
            BannerQaUserStatusFilter.Approved,
            BannerQaUserStatusFilter.IApproved,
            this.FilterGroup.Approved
        ];
        this.rejectedFilterStateses = [
            BannerQaUserStatusFilter.Rejected,
            BannerQaUserStatusFilter.IRejected,
            this.FilterGroup.Rejected
        ];
        this.notReviewedFilterStateses = [
            BannerQaUserStatusFilter.None,
            BannerQaUserStatusFilter.INone,
            this.FilterGroup.NotReviewed
        ];

        this.validFilterValues = Object.values(BannerQaUserStatusFilter);
    },
    methods: {
        isApprovedValue(val) {
            if (Array.isArray(val)) {
                return val.some(v => this.approvedFilterStateses.includes(v));
            }

            return this.approvedFilterStateses.includes(val);
        },

        isNotReviewedValue(val) {
            if (Array.isArray(val)) {
                return val.some(v => this.notReviewedFilterStateses.includes(v));
            }

            return this.notReviewedFilterStateses.includes(val);
        },

        isRejectedValue(val) {
            if (Array.isArray(val)) {
                return val.some(v => this.rejectedFilterStateses.includes(v));
            }

            return this.rejectedFilterStateses.includes(val);
        },

        setQaUserStatusFilter(group, qaStatus, entireGroup = false) {
            if (group === FilterGroup.All) {
                this.qaUserStatusFilterMap = { ...defaultSelection, [group]: qaStatus };
                return;
            }

            if (entireGroup && !!this.qaUserStatusFilterMap[group]) {
                this.qaUserStatusFilterMap = { ...this.qaUserStatusFilterMap, [group]: null };
                return;
            }

            if (this.qaUserStatusFilterMap[group] === qaStatus) {
                this.qaUserStatusFilterMap = { ...this.qaUserStatusFilterMap, [group]: null };
                return;
            }

            this.qaUserStatusFilterMap = { ...this.qaUserStatusFilterMap, [group]: qaStatus, [FilterGroup.All]: null };
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.library-qa-status-filter {
    .ivu-radio {
        margin: 0;
    }

    .ivu-radio-group {
        display: block;
    }

    .ivu-radio-wrapper {
        margin: 0;
        padding: 0 0 0 50px;
        display: block;
    }
}

.library-qa-status-filter__status-dot-wrapper {
    display: inline-block;
    padding: 0 0 0 $spacing-smaller;
}
</style>
