<template>
    <div class="campaign-cell">
        <Dropdown
            trigger="custom"
            class="campaign-cell-dropdown no-wrap"
            placement="bottom"
            data-testid="campaigns-list__select"
            :visible="isPoptipOpen"
            transfer
            @on-visible-change="onVisibleChange"
        >
            <div class="campaign-cell-poptip" @click="isPoptipOpen = !isPoptipOpen">
                <span v-if="hadClientAccess" class="campaign-cell-poptip-text link-like">All campaigns</span>
                <span v-else class="campaign-cell-poptip-text link-like">
                    {{ value.length }} campaign{{ value.length === 1 ? "" : "s" }}
                </span>
                <Icon class="campaign-cell-poptip-arrow" type="ios-arrow-down"></Icon>
            </div>
            <template #list>
                <div v-click-outside="clickOutside" class="campaign-cell-content">
                    <Spin v-if="isLoading" fixed></Spin>
                    <table-checkbox-group
                        v-else
                        class="campaign-cell-table"
                        type="ghost"
                        :columns="columns"
                        :checkbox-width="30"
                        :data="tableData"
                        :show-header="false"
                        :value="selected"
                        @on-selection-change="onSelect"
                    ></table-checkbox-group>

                    <div v-if="disabled && !preview" class="campaign-cell-buttons">
                        <Button @click="isPoptipOpen = false">Close</Button>
                    </div>

                    <div v-if="!disabled" class="campaign-cell-buttons">
                        <Button
                            class="campaign-cell-button--cancel"
                            type="text"
                            data-testid="campaigns-list__cancel-button"
                            @click="isPoptipOpen = false"
                        >
                            Cancel
                        </Button>
                        <Button
                            class="campaign-cell-button--save"
                            type="primary"
                            data-testid="campaigns-list__save-button"
                            @click="saveScopes"
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </template>
        </Dropdown>
    </div>
</template>

<script>
import campaignsQuery from "@/apollo/queries/CampaignsOnlyByClient.gql";

import TableCheckboxGroup from "@/components/TableCheckboxGroup";
import bus from "@/bus";
import ClickOutsideCapture from "@/directives/ClickOutsideCapture";

export default {
    name: "CampaignSelect",
    components: { TableCheckboxGroup },
    directives: {
        // code was based on this: https://jsfiddle.net/Linusborg/Lx49LaL8/
        // Had to add useCapture to the event listener as , I suspect, one of the parents stops click propagation
        "click-outside": ClickOutsideCapture
    },

    props: {
        clientId: {
            type: String,
            required: true
        },

        disabled: {
            type: Boolean,
            default: false
        },

        preview: {
            type: Boolean,
            default: false
        },

        value: {
            type: Array,
            default() {
                return [];
            }
        }
    },

    data() {
        return {
            clientCampaigns: [],
            columns: [
                {
                    title: "select all",
                    key: "name",
                    renderHeader: this.renderHeader.bind(this),
                    render: (h, params) =>
                        h("span", {
                            class: "campaign-cell-option",
                            attrs: {
                                title: params.row.name
                            },
                            // DOM properties
                            domProps: {
                                innerHTML: params.row.name
                            }
                        })
                }
            ],
            isLoading: true,
            isPoptipOpen: false,
            selected: []
        };
    },

    computed: {
        campaignOptions() {
            const selectedCampaignIds = this.selected.map(cmp => cmp._id);

            return this.clientCampaigns.map(cmp => ({
                ...cmp,
                ...(this.disabled && { _disabled: true }),
                ...(this.hasClientAccess && { _disabled: true }),
                ...(!this.hasClientAccess && selectedCampaignIds.includes(cmp._id) && { _checked: true })
            }));
        },

        hadClientAccess() {
            return this.value && this.value.indexOf("all") > -1;
        },

        hasClientAccess() {
            return this.selected.indexOf("all") > -1;
        },

        tableData() {
            const base = [];

            if (this.$auth.hasScope({ clientId: this.clientId })) {
                base.push({
                    name: "All Campaigns",
                    clientScope: true,
                    ...(this.disabled && { _disabled: true }),
                    ...(this.hasClientAccess && { _checked: true })
                });
            }

            return base.concat(this.campaignOptions);
        }
    },

    created() {
        this.selected = [...this.value];
    },

    methods: {
        clickOutside() {
            if (this.isPoptipOpen) {
                this.isPoptipOpen = false;
            }
        },

        onSelect(selection) {
            const addsClientScope = selection.find(s => s.clientScope);

            // mark all relevant as selected
            this.selected = selection.map(s => ({
                ...s,
                _checked: true
            }));

            // correctly mark "All Campaigns"
            if (addsClientScope) {
                this.selected.push("all");
            } else {
                const allIndex = this.selected.indexOf("all");

                if (allIndex > -1) {
                    this.selected.splice(allIndex, 1);
                }
            }
        },

        onVisibleChange(vis) {
            this.isPoptipOpen = vis;
        },

        renderHeader(h) {
            return h("span", {
                domProps: {
                    innerHTML: "Select All"
                },
                on: {
                    click: this.selectAll.bind(this)
                }
            });
        },

        // eslint-disable-next-line complexity
        saveScopes() {
            this.isPoptipOpen = false;
            const userScopeData = {
                clientId: this.clientId,
                removeUserScope: false,
                addUserScope: false,
                campaignIdsToAdd: [],
                campaignIdsToRemove: []
            };

            // console.log( "saveScopes", this.hadClientAccess, this.hasClientAccess );

            if (this.hadClientAccess && !this.hasClientAccess) {
                userScopeData.removeUserScope = true;
            }

            if (!this.hadClientAccess && this.hasClientAccess) {
                userScopeData.addUserScope = true;
            } else if (this.hadClientAccess && this.hasClientAccess) {
                return;
            } else {
                const dbCampaignIds = this.value.map(v => v._id);
                const selectedIds = this.selected.map(s => s._id);

                // console.log( "dbCampaignIds", dbCampaignIds, selectedIds );

                const addedCampaigns = this.selected
                    .filter(cmp => !dbCampaignIds.includes(cmp._id))
                    .map(cmp => cmp._id);

                const removedCampaigns = dbCampaignIds.filter(cmpId => !selectedIds.includes(cmpId));

                userScopeData.campaignIdsToAdd = addedCampaigns.filter(Boolean);
                userScopeData.campaignIdsToRemove = removedCampaigns.filter(Boolean);
            }

            this.$emit("UserScopeUpdate", userScopeData);
            this.$emit("input", this.selected);
        }
    },

    apollo: {
        campaigns: {
            query: campaignsQuery,
            variables() {
                return {
                    clientId: this.clientId
                };
            },
            skip() {
                return !this.isPoptipOpen;
            },
            error(e) {
                bus.$emit("apolloErrorEvent", e);
            },
            result({ data: { campaigns } }) {
                this.isLoading = false;
                this.clientCampaigns = campaigns;
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.campaign-cell {
    width: 100%;

    &-dropdown {
        width: 293px;
        padding-left: 6px;
    }

    &-poptip {
        display: flex;
        justify-content: space-between;
        width: 287px;

        &-arrow.ivu-icon {
            font-size: 14px;
            padding-top: 3px;
            padding-right: 3px;
        }
    }

    &-content {
        max-height: 250px;
        width: 300px;
    }

    &-buttons {
        padding: 8px;
        display: flex;
        justify-content: flex-end;
    }

    &-table {
        max-height: 200px;
        overflow: hidden auto;
        padding: 8px 0;

        th {
            font-weight: normal;
            color: $blue;
        }

        .ivu-table-cell {
            padding: 0;
        }
    }

    &-poptip-text {
        width: 100%;
    }
}
</style>
