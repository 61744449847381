<template>
    <div class="insights-creative-preview-carousel">
        <insights-data-provider
            v-if="nextPageToken"
            :advanced-search="advancedSearch"
            :insights-type="type"
            :next-page-token="nextPageToken"
            :request-options="bucketValuesRequestOptions"
            @data="onNextData"
            @next="onNextData"
            @error="onError"
            @loading="onLoading"
            @loadingNext="onLoading"
        />
        <div v-if="showControls" class="creative-carousel-arrow left" @click="switchPage(-1)">
            <Icon type="ios-arrow-back" size="24"></Icon>
        </div>

        <div class="insights-creative-preview-carousel__item">
            <hox-loading-layer v-if="isLoading" :is-full-screen="false" />
            <insights-creative-preview
                :creative="creative"
                :stats="stats"
                :seek-to-attribute="seekToAttribute"
                :bounding-boxes="boundingBoxes"
            >
                <template #name><slot name="name"></slot></template>
                <template #paging>{{ page }} / {{ totalPages }}</template>
            </insights-creative-preview>
        </div>

        <div v-if="showControls" class="creative-carousel-arrow right" @click="switchPage(1)">
            <Icon type="ios-arrow-forward" size="24"></Icon>
        </div>

        <div v-if="showControls" class="creative-carousel-numbers">{{ page }} of {{ totalPages }}</div>
    </div>
</template>
<script>
import InsightsCreativePreview from "@/components/CreativeIntelligence/InsightPreviews/InsightsCreativePreview";
import InsightsDataProvider from "@/components/CreativeIntelligence/InsightsDataProvider";
import { InsightType } from "@/components/CreativeIntelligence/constants";
import paginationMixin from "@/mixins/paginationMixin";
import { CreativeInsightsGetters } from "@/store/modules/creativeInsights";

export default {
    name: "InsightsCreativePreviewCarousel",
    components: { InsightsDataProvider, InsightsCreativePreview },
    mixins: [paginationMixin],
    props: {
        boundingBoxes: {
            type: Boolean,
            default: true
        },

        initCreatives: {
            type: Array,
            required: true
        },

        bucketConfig: {
            type: Object
        },

        requestOptions: {
            type: Object,
            default() {
                return {};
            }
        },

        seekToAttribute: {
            type: Boolean,
            default: false
        },

        stats: {
            type: Object,
            required: true
        },

        video: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            creative: { ...this.initCreatives[0] },
            creativeIndex: 1,
            creatives: [...this.initCreatives],
            dataPages: [null, true],
            isLoading: false,
            nextPageToken: "",
            page: 1,
            pageToToken: [null, ""],
            pagination: this.bucketConfig,
            type: InsightType.BucketValues
        };
    },

    computed: {
        advancedSearch() {
            return this.$store.getters[CreativeInsightsGetters.AdvancedSearchValue];
        },

        bucketValuesRequestOptions() {
            return {
                ...this.requestOptions,
                bucketFilters: this.bucketConfig.bucketFilters,
                topUniqueCreativesNumber: 20
            };
        },

        classes() {
            const classes = ["creative-carousel"];

            if (this.padding) {
                classes.push(`padding-${this.padding}`);
            }
            return classes;
        },

        hasNext() {
            return this.page !== this.totalPages;
        },

        hasPrevious() {
            return this.page !== 1;
        },

        itemsToPage() {
            return this.creatives;
        },

        showControls() {
            return this.totalPages > 1;
        },

        totalPages() {
            return this.bucketConfig.total;
        }
    },

    watch: {
        page() {
            if (!this.dataPages[this.page]) {
                this.nextPageToken = this.pagination.nextPageToken;
            } else {
                this.creativeIndex = this.page;
                this.creative = this.creatives[this.creativeIndex - 1];
            }
        }
    },

    methods: {
        getDeliverablesFromDisplayableGroups({ data }) {
            const { uniqueCreativesGroups } = data;
            return uniqueCreativesGroups.reduce((acc, { deliverables: delivs, summaryStats }) => {
                if (delivs.length) {
                    acc.push({
                        ...delivs[0],
                        summaryStats
                    });
                }

                return acc;
            }, []);
        },

        onNextData(response) {
            if (response.data && response.data.uniqueCreativesGroups && response.data.uniqueCreativesGroups.length) {
                const newCreatives = [...this.getDeliverablesFromDisplayableGroups(response)];
                this.creatives.push(...newCreatives);
                newCreatives.forEach((result, i) => {
                    this.dataPages[this.page + i] = result;
                });
            }
            this.pagination = response.data.pagination;

            if (this.creativeIndex !== this.page) {
                this.creativeIndex = this.page;
                this.creative = this.creatives[this.creativeIndex - 1];
            }
        },

        onError() {
            this.resetData();
        },

        onLoading(loading) {
            this.isLoading = loading;
        },

        resetData() {
            this.creatives = [];
        },

        setPageNumber(page) {
            this.page = page;
        },

        switchPage(dir) {
            if (this.page + dir < 1) {
                this.page = this.totalPages;
                return;
            }

            if (this.page + dir > this.totalPages) {
                this.page = 1;
                return;
            }

            this.page = this.page + dir;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.insights-creative-preview-carousel {
    position: relative;

    &:hover .creative-carousel-arrow,
    &:hover .creative-carousel-numbers {
        visibility: visible;
    }

    &__item {
        position: relative;

        .loading-layer--position-absolute {
            z-index: 4;
            pointer-events: none;
        }
    }
}

.creative-carousel-arrow {
    visibility: hidden;

    border: none;
    outline: none;

    padding: 0;
    margin: 0;

    width: 20px;
    height: 20px;

    cursor: pointer;

    position: absolute;
    top: 50%;
    z-index: 10;
    transform: translateY(-50%);

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: transparent;
    color: $cmp-light-font-color;

    &:focus {
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15);
    }

    text-align: center;
    font-size: 14px;

    &.left {
        left: 14px;
    }

    &.right {
        right: 14px;
    }
}

.creative-carousel-numbers {
    visibility: hidden;
    display: block;
    position: absolute;
    bottom: 30px;
    font-size: 11px;
    left: 50%;
    transform: translate(-50%, -50%);

    li button {
        transition: none;
    }
}
</style>
