<template>
    <hox-modal @close="close">
        <hox-loading-layer v-if="isDeletingFont" />
        <div class="delete-font-modal__alert-wrapper">
            <hox-alert type="danger" :margin-bottom="hasErrorDeletingFont ? 'base' : 'none'">
                <template #title>
                    Are you sure you want to delete the "
                    <em>{{ name }}</em>
                    " font?
                </template>
                <template #content>
                    <p>Deleting a font is permanent: it can not be recovered.</p>
                </template>
            </hox-alert>
        </div>
        <Alert v-if="hasErrorDeletingFont" banner type="error">
            <template #desc>Something went wrong and the font was not deleted. Try again in a few moments.</template>
        </Alert>
        <template #footer>
            <Button class="delete-font-modal__button" @click="close">Cancel</Button>
            <Button class="delete-font-modal__button" type="error" @click="deleteFont">Delete</Button>
        </template>
    </hox-modal>
</template>

<script>
import RemoveFontMutation from "@/apollo/mutations/RemoveFont.gql";

export default {
    props: {
        id: {
            type: String,
            required: true
        },

        name: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            hasErrorDeletingFont: false,
            isDeletingFont: false
        };
    },

    methods: {
        close() {
            this.$emit("close");
        },

        async deleteFont() {
            this.hasErrorDeletingFont = false;
            if (!this.isDeletingFont) {
                this.isDeletingFont = true;
                try {
                    await this.$apollo.mutate({
                        mutation: RemoveFontMutation,
                        variables: {
                            fontId: this.id
                        }
                    });
                    this.$snackbar.success(`The font "${this.name}" has been deleted`);
                    this.$emit("fontDeleted");
                    this.close();
                } catch (err) {
                    this.hasErrorDeletingFont = true;
                    return;
                } finally {
                    this.isDeletingFont = false;
                }
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.delete-font-modal {
    &__alert-wrapper {
        padding: $spacing 0 0;
    }

    &__button {
        margin: 0 0 0 $spacing-small;
    }
}
</style>
