<template>
    <div class="content-wrapper">
        <h1>Maintenance Mode</h1>
        <p>We're currently working on some updates to improve your experience and will be back online very soon.</p>
        <p>Apologies for any inconvience.</p>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Maintenance"
};
</script>

<style scoped>
.content-wrapper {
    margin: 50px auto;
    text-align: center;
}

p {
    margin: 10px 0;
}
</style>
