<template>
    <hox-modal @close="close">
        <hox-loading-layer v-if="isDeletingSection" />
        <div class="delete-section-modal__alert-wrapper">
            <hox-alert type="danger" :margin-bottom="hasErrorDeletingSection ? 'base' : 'none'">
                <template #title>
                    Are you sure you want to delete the "
                    <em>{{ sectionName }}</em>
                    " section?
                </template>
                <template #content>
                    <p>
                        Campaigns in this section will no longer be grouped together and will appear at the top of the
                        section listings.
                    </p>
                </template>
            </hox-alert>
        </div>
        <Alert v-if="hasErrorDeletingSection" banner type="error">
            <template #desc>Something went wrong and the section was not deleted. Try again in a few moments.</template>
        </Alert>
        <template #footer>
            <Button class="delete-section-modal__button" @click="close">Cancel</Button>
            <Button class="delete-section-modal__button" type="error" @click="deleteSection">Delete</Button>
        </template>
    </hox-modal>
</template>

<script>
import removeCampaignSectionMutation from "@/apollo/mutations/RemoveCampaignSection.gql";

export default {
    props: {
        sectionId: {
            type: String,
            required: true
        },

        sectionName: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            hasErrorDeletingSection: false,
            isDeletingSection: false
        };
    },

    methods: {
        close() {
            this.$emit("close");
        },

        async deleteSection() {
            this.hasErrorDeletingSection = false;
            if (!this.isDeletingSection) {
                this.isDeletingSection = true;
                try {
                    await this.$apollo.mutate({
                        mutation: removeCampaignSectionMutation,
                        variables: {
                            sectionId: this.sectionId
                        }
                    });
                    this.$snackbar.success(`The section "${this.sectionName}" has been deleted`);
                    this.$emit("sectionDeleted");
                    this.close();
                } catch (err) {
                    this.hasErrorDeletingSection = true;
                    return;
                } finally {
                    this.isDeletingSection = false;
                }
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.delete-section-modal__alert-wrapper {
    padding: $spacing 0 0;
}

.delete-section-modal__button {
    margin: 0 0 0 $spacing-small;
}
</style>
