<template>
    <div
        class="banner-preview"
        :class="{
            'banner-preview--click-disabled': !contentClickable,
            'banner-preview--resizable': isResizable
        }"
    >
        <lazy-load-banner :lazy-load-enabled="lazyLoad" :load-in-view="true">
            <a-e-banner
                v-if="isAE"
                :id="iframeId"
                class="banner-preview__banner"
                :width="banner.width"
                :height="banner.height"
                :preview-values="previewValues"
                :editor-values="editorValues"
                :scaling-factor="scalingFactor"
                :is-resizable="isResizable"
            />
            <banner
                v-else
                :id="iframeId"
                :key="key"
                class="banner-preview__banner"
                :class="{ 'banner-preview__banner--resizable': isResizable }"
                :debug="debug"
                :width="banner.width"
                :height="banner.height"
                :preview-values="previewValues"
                :scaling-factor="scalingFactor"
                :ad-type="adType"
                :is-resizable="isResizable"
                @BannerIFrameLoaded="onBannerIframeLoaded"
            />
        </lazy-load-banner>
    </div>
</template>

<script>
import throttle from "lodash.throttle";

// eslint-disable-next-line import/no-extraneous-dependencies
import { TemplateType } from "shared-utils/enums/masterTemplate";

import bus from "@/bus";
import {
    BannerVideoActions,
    BannerVideoEvents,
    BannerActionEvents,
    BannerDefaultDuration,
    BannerEvents,
    BannerState
} from "@/enums/banners";
import { BannersAction } from "@/store/modules/banners.store";
import Banner from "@/components/Previews/Banner";
import AEBanner from "@/components/Previews/AEBanner";
import LazyLoadBanner from "@/components/Previews/LazyLoadBanner";

export default {
    name: "BannerPreview",
    components: {
        LazyLoadBanner,
        Banner,
        AEBanner
    },
    props: {
        previewValues: {
            type: String,
            required: true
        },

        editorValues: {
            type: Object,
            default: () => ({})
        },

        banner: {
            type: Object,
            required: true
        },

        group: {
            type: String
        },

        iframePrefix: {
            type: String,
            default: ""
        },

        contentClickable: {
            type: Boolean,
            default: false
        },

        lazyLoad: {
            type: Boolean,
            default: false
        },

        debug: {
            type: Boolean,
            default: false
        },

        scalingFactor: {
            type: Number
        },

        adType: {
            type: String,
            required: true
        },

        isResizable: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            isPlaybackCompleted: false,
            isPlaying: true,
            isLoading: true,
            duration: BannerDefaultDuration,
            currentTime: 0,
            iframe: null,
            key: 0
        };
    },

    computed: {
        /* todo restore when we can reliably obtain timeline duration
        isPlaybackCompleted() {
            return this.currentTime === this.duration;
        }, */

        isBannerStatic() {
            return this.duration === 0;
        },

        id() {
            return this.banner.id;
        },

        iframeId() {
            return `${this.iframePrefix}${this.banner.id}`;
        },

        isAE() {
            return this.adType === TemplateType.AE;
        }
    },

    created() {
        // this var needs to be declared this way to prevent vue's reactivity being added to the banner timeline object
        // as for some reason it breaks some ot the functions.
        this.timeline = null;
    },

    mounted() {
        // bacause bus uses different instance of vue we need to bind this in order to access local value
        bus.$on(BannerActionEvents.BannerPause, this.pauseThisBanner.bind(this));
        bus.$on(BannerActionEvents.BannerPlay, this.playThisBanner.bind(this));
        bus.$on(BannerActionEvents.BannerRefresh, this.refreshThisBanner.bind(this));
        bus.$on(BannerActionEvents.BannerReplay, this.replayThisBanner.bind(this));
        bus.$on(BannerActionEvents.BannerSeek, this.seekThisBanner.bind(this));
        bus.$on(BannerActionEvents.AllBannersPlay, this.play.bind(this));
        bus.$on(BannerActionEvents.AllBannersPause, this.pause.bind(this));
        bus.$on(BannerActionEvents.AllBannersReplay, this.replay.bind(this));
        bus.$on(BannerActionEvents.AllBannersSeek, this.seek.bind(this));

        bus.$on(BannerEvents.AEVideoReady, this.onAEVideoReady.bind(this));
    },

    methods: {
        onAEVideoReady({ id, duration }) {
            if (id !== this.iframeId) {
                return;
            }

            this.$store.dispatch(BannersAction.Update, {
                instance: this.iframeId,
                duration,
                state: BannerState.Loaded
            });

            this.isLoading = false;
            // todo fix - we need to find the longest duration
            this.$emit(BannerEvents.BannerLoaded, this.duration);

            bus.$emit(BannerEvents.BannerLoaded, {
                bannerId: this.banner.id,
                duration: this.duration
            });

            bus.$on(BannerVideoEvents.Ended, videoBannerId => {
                if (videoBannerId !== this.iframeId) {
                    return;
                }

                this.onComplete();
            });

            bus.$on(BannerVideoEvents.TimeUpdate, ({ id: videoBannerId, currentTime }) => {
                if (videoBannerId !== this.iframeId) {
                    return;
                }

                this.updateCurrentTime(currentTime);
            });

            this.play();
        },

        onBannerIframeLoaded(evt) {
            this.iframe = evt.currentTarget.contentWindow;
            this.timeline = (this.iframe.Creative && this.iframe.Creative.tl) || this.iframe.TL;

            if (this.timeline) {
                // had to disable the duration as it turns out the way the templates are created we have no reliable
                // way to obtain the total duration.
                // this.duration = this.timeline.totalDuration();

                this.timeline.eventCallback(
                    "onUpdate",
                    throttle(() => {
                        this.updateCurrentTime(this.timeline.time());
                    }, 100)
                );

                this.timeline.eventCallback("onComplete", () => this.onComplete());

                this.play();
            }

            this.$store.dispatch(BannersAction.Update, {
                instance: this.iframeId,
                duration: BannerDefaultDuration,
                state: BannerState.Loaded
            });

            this.isLoading = false;
            // todo fix - we need to find the longest duration
            this.$emit(BannerEvents.BannerLoaded, this.duration);

            bus.$emit(BannerEvents.BannerLoaded, {
                bannerId: this.banner.id,
                duration: this.duration
            });
        },

        play(group) {
            if (!group || this.group === group) {
                if (!this.timeline && !this.isAE) {
                    return;
                }

                if (this.isPlaybackCompleted) {
                    return;
                }

                if (this.timeline) {
                    this.timeline.play();
                }

                if (this.isAE) {
                    bus.$emit(BannerVideoActions.Play, this.iframeId);
                }

                this.isPlaying = true;

                this.$store.dispatch(BannersAction.SetState, {
                    instance: this.iframeId,
                    state: BannerState.Play
                });
            }
        },

        replay(group) {
            if (!group || this.group === group) {
                if (!this.timeline && !this.isAE) {
                    this.key += 1;
                    return;
                }

                this.isPlaybackCompleted = false;

                if (this.timeline) {
                    this.timeline.restart();
                }

                if (this.isAE) {
                    bus.$emit(BannerVideoActions.Reset, this.iframeId);
                }

                this.isPlaying = true;

                this.$store.dispatch(BannersAction.SetState, {
                    instance: this.iframeId,
                    state: BannerState.Play
                });
            }
        },

        pause(group) {
            if (!group || this.group === group) {
                if (!this.timeline && !this.isAE) {
                    return;
                }

                if (this.timeline) {
                    this.timeline.pause();
                }

                if (this.isAE) {
                    bus.$emit(BannerVideoActions.Pause, this.iframeId);
                }

                this.isPlaying = false;

                this.$store.dispatch(BannersAction.SetState, {
                    instance: this.iframeId,
                    state: BannerState.Pause
                });
            }
        },

        seek(time, group) {
            if (!group || this.group === group) {
                this.isPlaybackCompleted = false;

                if (this.isPlaying) {
                    this.pause();
                }

                this.setCurrentTime(time);

                this.$store.dispatch(BannersAction.SetState, {
                    instance: this.iframeId,
                    state: BannerState.Seek
                });
            }
        },

        setCurrentTime(time) {
            this.currentTime = time;

            if (this.timeline) {
                this.timeline.seek(this.currentTime, false);

                /* None of the components uses this value so disable at the moment
                this.$store.dispatch( BannersAction.SetCurrentTime, {
                    instance: this.iframeId,
                    currentTime: this.currentTime
                } ); */
            }
        },

        /**
         * @param {Number} [videoTime]
         */
        updateCurrentTime(videoTime) {
            if (!this.timeline && !this.isAE) {
                return;
            }

            const currentTime = videoTime;

            this.currentTime = currentTime;
            this.$store.dispatch(BannersAction.SetCurrentTime, {
                instance: this.iframeId,
                currentTime
            });
        },

        onComplete() {
            if (this.isAE) {
                this.isPlaybackCompleted = true;

                this.$store.dispatch(BannersAction.SetState, {
                    instance: this.iframeId,
                    state: BannerState.Completed
                });

                return;
            }

            // on hsbc banners onCompete is fired twice
            if (this.timeline.progress() === 1) {
                this.isPlaybackCompleted = true;
                this.pause();

                this.$store.dispatch(BannersAction.SetState, {
                    instance: this.iframeId,
                    state: BannerState.Completed
                });
            }
        },

        pauseThisBanner(bannerId) {
            if (bannerId === this.banner._id) {
                this.pause(this.group);
            }
        },

        playThisBanner(bannerId) {
            if (bannerId === this.banner._id) {
                this.play(this.group);
            }
        },

        refreshThisBanner(bannerId) {
            if (bannerId === this.banner._id) {
                this.key += 1;
            }
        },

        replayThisBanner(bannerId) {
            if (bannerId === this.banner._id) {
                this.replay(this.group);
            }
        },

        seekThisBanner(bannerId, time) {
            if (bannerId === this.banner._id) {
                this.setCurrentTime(time, this.group);
            }
        }
    }
};
</script>

<style lang="scss">
.banner-preview--click-disabled {
    pointer-events: none;
}
.banner-preview--resizable,
.banner-preview__banner--resizable {
    width: 100%;
    height: 100%;
}
</style>
