<template>
    <div class="selectall-notice">
        <p>You have selected {{ selectedCount }} out of {{ totalCount }} items</p>
        <div class="selectall-notice__button-wrapper">
            <Button size="small" type="primary" @click="selectAll">Select all {{ totalCount }} items</Button>
        </div>
        <div class="selectall-notice__button-wrapper">
            <Button size="small" @click="dismiss">Dismiss</Button>
        </div>
    </div>
</template>
<script>
export default {
    name: "SelectAllNotice",
    props: {
        allSelectedMode: {
            type: Boolean,
            default: false
        },

        selected: {
            type: Number,
            default: 0
        },

        totalCount: {
            type: Number,
            default: 0
        }
    },

    computed: {
        selectedCount() {
            if (this.allSelectedMode) {
                return this.totalCount;
            }

            return this.selected;
        }
    },

    methods: {
        dismiss() {
            this.$emit("dismiss");
        },

        selectAll() {
            this.$emit("selectAll");
        }
    }
};
</script>
<style lang="scss">
@import "../../../sass/variables";

.selectall-notice {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: $spacing-small $spacing;
    text-align: center;
    width: 100%;
    background: $white;
    margin-bottom: $spacing;
}

.selectall-notice__button-wrapper {
    display: inline-block;
    padding: 0 0 0 $spacing-small;
}
</style>
