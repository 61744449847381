<template>
    <div class="quadrant-text white-space-nowrap">
        <span :class="classes">
            {{ QuadrantNames[quadrantValue] }}
        </span>
    </div>
</template>
<script>
import { CreativeInsightsGetters } from "@/store/modules/creativeInsights";

const QuadrantNames = {
    "00": "Too Early",
    "01": "Low Performer",
    10: "One To Watch",
    11: "High Performer"
};

export default {
    name: "QuadrantText",
    props: {
        stats: {
            type: Object,
            required: true
        }
    },

    computed: {
        classes() {
            return `quadrant-text--style-${this.quadrantValue}`;
        },

        confidenceValue() {
            return this.stats.confidenceMetric[this.$store.getters[CreativeInsightsGetters.ConfidenceMetricStat]];
        },

        kpiValue() {
            return this.stats.kpiMetric[this.$store.getters[CreativeInsightsGetters.KpiMetricStat]];
        },

        quadrantValue() {
            return this.$store.getters[CreativeInsightsGetters.QuadrantValue](this.kpiValue, this.confidenceValue);
        }
    },

    created() {
        this.QuadrantNames = QuadrantNames;
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.quadrant-text {
    font-size: 10px;
    color: $ci-font-color-secondary;
    text-transform: uppercase;
}

.quadrant-text--style {
    &-00 {
        color: $ci-font-color-secondary;
    }

    &-01 {
        color: $ci-quadrant-red;
    }

    &-10 {
        color: $ci-quadrant-orange;
    }

    &-11 {
        color: $ci-quadrant-green;
    }
}
</style>
