<template>
    <div class="insights-creative-preview">
        <div class="insights-creative-preview__header">
            <div class="insights-creative-preview__header-name"><slot name="name"></slot></div>
            <quadrant-text :stats="stats" />
        </div>

        <card class="dashboard-card" :show-footer="false" @click.native="onClick">
            <div class="insights-creative-preview__content">
                <div
                    v-if="creative.attributes.length"
                    class="insights-creative-preview__content-overlays"
                    :style="overlayStyle"
                >
                    <div
                        v-for="attribute in creative.attributes"
                        :key="attribute.attributeId"
                        :data-key="attribute.attributeId"
                        class="insights-creative-preview__content-overlay-wrapper"
                    >
                        <div
                            v-if="showBoundingBox(attribute)"
                            class="insights-creative-preview__content-overlay"
                            :style="getBoxStyle(attribute)"
                        >
                            <div
                                class="insights-creative-preview__content-overlay-header"
                                :title="attribute.attribute + ' ' + getAttributeConfidence(attribute)"
                            >
                                {{ attribute.attribute }}
                                <template v-if="getAttributeConfidence(attribute)">
                                    ({{ getAttributeConfidence(attribute) }})
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="hasPreviewMedia" class="insights-creative-preview__media">
                    <video-wrapper
                        v-if="video && videoUrl"
                        ref="video"
                        class="insights-creative-preview__content-base insights-creative-preview__video"
                        muted
                        @update="onUpdate"
                        @play="setPlaybackState('playing')"
                        @pause="setPlaybackState('paused')"
                        @ended="setPlaybackState('ended')"
                        @error="onError"
                        @wait="isLoading = true"
                        @ready="onVideoReady"
                        @duration="setDuration"
                    >
                        <source v-if="videoUrl" :src="videoUrl" type="video/mp4" />
                    </video-wrapper>
                    <img
                        v-else-if="imageUrl"
                        ref="image"
                        :src="imageUrl"
                        class="insights-creative-preview__content-base insights-creative-preview__image"
                        @load="onImageLoaded"
                    />
                    <resize-observer class="" @notify="handleResize"></resize-observer>
                </div>
                <div
                    v-else-if="creative.attributes.length"
                    class="insights-creative-preview__attribute-text insights-creative-preview__content-base"
                >
                    <h4 v-if="creative.attributes[0].element">{{ creative.attributes[0].element }}</h4>
                    <h2 v-if="creative.attributes[0].attribute">{{ creative.attributes[0].attribute }}</h2>
                </div>
                <!--                frame: {{ frameNumber }} / seekToAndPause {{ seekToAndPause }}-->
            </div>
        </card>

        <div v-if="creative" class="insights-creative-preview__footer">
            <div class="insights-creative-preview__footer-item">
                <Icon class="insights-creative-preview__footer-item-icon" type="ios-pie-outline" />
                <span class="insights-creative-preview__footer-item-value">{{ utilisationDisplay }}</span>
            </div>

            <div class="insights-creative-preview__footer-item">
                <Icon class="insights-creative-preview__footer-item-icon" type="ios-trending-up" />
                <span class="insights-creative-preview__footer-item-value">{{ kpiAvgDisplayValue }}</span>
            </div>

            <div class="insights-creative-preview__footer-item">
                <Icon class="insights-creative-preview__footer-item-icon" type="ios-eye-outline" />
                <span class="insights-creative-preview__footer-item-value">{{ impressionsDisplay }}</span>
            </div>

            <div v-if="debugMode" class="insights-creative-preview__footer-item">
                <Poptip trigger="hover" :title="creative.reportingLabel">
                    <Icon class="insights-creative-preview__footer-item-icon" type="ios-bug-outline" />
                    <template #content>
                        <slot name="paging"></slot>
                        <ul v-if="seekToAttribute">
                            <li v-for="(attr, i) in creative.attributes" :key="i + attr.attributeId">
                                {{ attr.attribute }}
                                <span v-for="(seg, i) in attr.timeline" :key="i">({{ seg.start }}-{{ seg.end }})</span>
                                <template v-if="attr.metadata && attr.metadata[longestSegmentIndex]">
                                    <template v-if="attr.metadata[longestSegmentIndex].position">
                                        | position: {{ attr.metadata[longestSegmentIndex].position }}
                                    </template>
                                    <template v-if="attr.metadata[longestSegmentIndex].area">
                                        | area: {{ toCssPercent(attr.metadata[longestSegmentIndex].area) }} | box:
                                        (w,h,t,l)
                                    </template>
                                    <template v-if="attr.metadata[longestSegmentIndex].bounds">
                                        {{ toCssPercent(attr.metadata[longestSegmentIndex].bounds.width) }},
                                        {{ toCssPercent(attr.metadata[longestSegmentIndex].bounds.height) }},
                                        {{ toCssPercent(attr.metadata[longestSegmentIndex].bounds.top) }},
                                        {{ toCssPercent(attr.metadata[longestSegmentIndex].bounds.left) }},
                                    </template>
                                </template>
                                <template v-else>NO METADATA</template>
                            </li>
                        </ul>
                    </template>
                </Poptip>
            </div>
        </div>
    </div>
</template>
<script>
import { ResizeObserver } from "vue-resize";
import VideoWrapper from "@/views/Campaign/VideoWrapper";
import Card from "@/components/Card/Card";
import { numberToShortKM } from "@/utils";
import { CreativeInsightsAction, CreativeInsightsGetters } from "@/store/modules/creativeInsights";
import QuadrantText from "@/components/CreativeIntelligence/InsightPreviews/QuadrantText";
import { getMetricStat, kpiDisplayFormatter, utilisationDisplayFormatter } from "@/utils/creativeInteligence";

const toCssPercent = val => {
    return `${Math.floor(Number.parseFloat(val) * 100)}%`;
};

const FramesPerSecond = 2;

export default {
    name: "InsightsCreativePreview",
    components: { QuadrantText, VideoWrapper, Card, "resize-observer": ResizeObserver },
    props: {
        creative: {
            type: Object,
            required: true
        },

        stats: {
            type: Object,
            required: true
        },

        seekToAttribute: {
            type: Boolean,
            default: false
        },

        boundingBoxes: {
            type: Boolean,
            default: true
        },

        video: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            currentTime: 0,
            duration: 0,
            isFirstLoad: true,
            isLoading: true,
            playbackState: "loading",
            timeUpdateFromUser: false,
            timeUpdateFromVideo: true,
            loadedImageWidth: null
        };
    },

    computed: {
        confidenceMetricName() {
            return this.$store.getters[CreativeInsightsGetters.ConfidenceMetricName];
        },

        debugMode() {
            return !!localStorage.getItem("reportingDebug");
        },

        hasPreviewMedia() {
            return !!(this.imageUrl || this.videoUrl);
        },

        imageUrl() {
            if (this.showHeatmap) {
                return this.creative.signedHeatMapPreviewUrl;
            }

            return this.creative.signedPreviewUrl;
        },

        impressionsDisplay() {
            // todo remove where impressions are always comming in
            if (this.confidenceMetricName === "impressions") {
                return numberToShortKM(this.stats.confidenceMetric.sum);
            }

            return numberToShortKM(this.creative.metrics.impressions);
        },

        kpi() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricName];
        },

        kpiAvgDisplayValue() {
            if (this.kpiAvgValue !== null) {
                return kpiDisplayFormatter(this.kpiAvgValue);
            }
            return null;
        },

        kpiAvgValue() {
            const stat = getMetricStat(this.kpiMetricName);
            return this.stats.kpiMetric[stat];
        },

        kpiDisplayValue() {
            if (this.kpiValue !== null) {
                return kpiDisplayFormatter(this.kpiValue);
            }
            return null;
        },

        kpiMetricName() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricName];
        },

        kpiValue() {
            return this.creative.metrics[this.kpi];
        },

        longestSegment() {
            const matchingSegments = this.creative.attributes.reduce((acc, { timeline }) => {
                if (!timeline) {
                    return acc;
                }

                acc.push(
                    ...timeline.map((segment, segmentIndex) => {
                        return {
                            ...segment,
                            segmentIndex
                        };
                    })
                );

                return acc;
            }, []);

            if (!matchingSegments.length) {
                return null;
            }

            return matchingSegments.sort((a, b) => b.end - b.start - (a.end - a.start))[0];
        },

        longestSegmentIndex() {
            if (!this.longestSegment) {
                return -1;
            }

            return this.longestSegment.segmentIndex;
        },

        overlayStyle() {
            if (!this.loadedImageWidth) {
                return { width: "100%" };
            }

            return {
                width: `${this.loadedImageWidth}px`
            };
        },

        previewFrameNumber() {
            if (!this.imageUrl) {
                return "?";
            }
            const [base] = this.imageUrl.split("?");
            const [, frame] = base.split("-frame-");
            if (!frame) {
                return 0;
            }

            const frameNumber = frame.replace(".png", "").replace("-heatmap.jpeg", "");
            if (Number.isInteger(+frameNumber)) {
                return +frameNumber;
            }
            return 0;
        },

        previewFrameTime() {
            if (this.video) {
                return this.seekToAndPause;
            }
            return this.previewFrameNumber / FramesPerSecond;
        },

        seekToAndPause() {
            if (!this.seekToAttribute || !this.longestSegment) {
                return 0;
            }

            return this.longestSegment.start + (this.longestSegment.end - this.longestSegment.start) / 2;
        },

        showHeatmap() {
            return this.$store.state.creativeInsights.showHeatmap;
        },

        utilisation() {
            return this.stats.utilisation;
        },

        utilisationDisplay() {
            return utilisationDisplayFormatter(this.utilisation);
        },

        videoEl() {
            return this.$refs.video.$el;
        },

        videoUrl() {
            return this.creative.signedVideoUrl;
        }
    },

    watch: {
        seekToAndPause(time) {
            this.seek(time);
        },

        imageUrl() {
            this.loadedImageWidth = null;
        }
    },

    created() {
        this.toCssPercent = toCssPercent;
    },

    methods: {
        getAttributeConfidence({ metadata }) {
            if (!metadata || !metadata.length) {
                return null;
            }

            if (metadata[0].confidence > 1) {
                return `${Math.round(metadata[0].confidence)}%`;
            }

            return `${Math.round(metadata[0].confidence * 100)}%`;
        },
        getBoxStyle({ timeline, metadata }) {
            let longestSegmentIndex = 0;
            if (timeline && timeline.length) {
                longestSegmentIndex = [...timeline]
                    .map((segment, segmentIndex) => ({
                        ...segment,
                        segmentIndex
                    }))
                    .sort((a, b) => b.end - b.start - (a.end - a.start))[0].segmentIndex;
            }
            if (
                !metadata ||
                longestSegmentIndex === undefined ||
                !metadata[longestSegmentIndex] ||
                !metadata[longestSegmentIndex].bounds ||
                !metadata[longestSegmentIndex].bounds.width
            ) {
                return {
                    width: 0,
                    height: 0,
                    top: 0,
                    left: 0
                };
            }
            const {
                bounds: { width = 0, height = 0, top = 0, left = 0 }
            } = metadata[longestSegmentIndex];

            return {
                width: toCssPercent(width),
                height: toCssPercent(height),
                top: toCssPercent(top),
                left: toCssPercent(left)
            };
        },

        handleResize() {
            if (this.$refs.image && this.$refs.image.clientWidth) {
                this.loadedImageWidth = this.$refs.image.clientWidth;
            }
        },

        onClick() {
            if (this.videoUrl) {
                this.$store.dispatch(CreativeInsightsAction.SetDrawerCreatives, [this.creative]);
            }
        },

        onError(err) {
            console.error(err);
            this.setPlaybackState("error");
        },

        async onImageLoaded() {
            if (this.$refs.image && this.$refs.image.clientWidth) {
                this.loadedImageWidth = this.$refs.image.clientWidth;
            }
        },

        onUpdate({ currentTime }) {
            this.$emit("update", currentTime);
            this.setTimeFromVideo(currentTime);
        },

        onVideoReady() {
            if (this.seekToAndPause && this.isFirstLoad) {
                this.timeUpdateFromVideo = false;
                this.seek(this.seekToAndPause);
                this.isFirstLoad = false;
            }
            this.isLoading = false;
        },

        async pause() {
            await this.videoEl.pause();
        },

        async play() {
            await this.videoEl.play();
        },

        setPlaybackState(playbackState) {
            this.playbackState = playbackState;
        },

        seek(time) {
            if (this.currentTime === time && time > this.duration) {
                return;
            }

            this.setTimeManually(time);
        },

        setDuration(duration) {
            this.duration = duration;
            this.$emit("duration", duration);
        },

        setTimeFromVideo(currentTime) {
            this.timeUpdateFromVideo = true;
            this.timeUpdateFromUser = false;
            this.currentTime = currentTime;
        },

        setTimeManually(currentTime) {
            if (!this.timeUpdateFromVideo && currentTime < this.duration) {
                this.currentTime = currentTime;
                this.timeUpdateFromUser = true;
                if (typeof this.$refs.video !== "undefined" && this.$refs.video.$el) {
                    this.$refs.video.$el.currentTime = currentTime;
                    this.$refs.video.$el.pause();
                }
            }

            this.timeUpdateFromVideo = false;
        },

        showBoundingBox({ metadata, timeline }) {
            if (this.loadedImageWidth === null) {
                return false;
            }
            const hasBoxes = this.boundingBoxes && metadata && metadata[0] && metadata[0].bounds;
            if (!hasBoxes) {
                return false;
            }

            if (!timeline) {
                return true;
            }

            return timeline.some(
                segment => segment.start <= this.previewFrameTime && segment.end >= this.previewFrameTime
            );
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.insights-creative-preview {
    margin: $spacing;
    width: 300px;
    height: 315px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;

    & .dashboard-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .card__footer {
        padding-left: 0;
    }

    &__rank {
        .kpi-performance__results-kpi {
            border: 0;
            //margin-left: -$spacing;
        }
    }

    &__video,
    &__image {
        display: block;
        max-height: 224px;
        max-width: 268px; // 300 -2* 16 padding
        object-fit: contain;
    }

    &__image {
        image-rendering: -webkit-optimize-contrast;
    }

    &__attribute-text {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 250px;
        padding: $spacing;

        h4 {
            align-self: flex-start;
            text-transform: uppercase;
        }

        h2 {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4; /* number of lines to show */
            line-clamp: 4;
            -webkit-box-orient: vertical;
        }
    }

    .insights-creative-preview__content {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &--no-data {
            width: 250px;
            height: 250px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: $ci-font-color-secondary;
        }

        &-base {
            position: relative;
            z-index: 1;
        }
        &-overlays {
            position: absolute;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            z-index: 2;
        }

        &-overlay {
            position: absolute;
            background: transparent;
            border: 1px #b1aef4 solid;

            &-header {
                background-color: #b1aef4;
                max-width: 90px;
                font-size: 8px;
                color: white;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                height: 12px;
                right: 0;
                position: absolute;
                margin-top: -12px;
            }
        }
    }

    .insights-creative-preview__footer {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $ci-font-color-secondary;

        &--no-data {
            height: 22px;
        }

        &-item {
            padding: 0 $spacing-small;

            &-value {
                padding: 0 $spacing-small;
            }
        }
    }

    .insights-creative-preview__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-name {
            max-width: 240px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-right: $spacing;
        }

        &-quadrant {
            white-space: nowrap;
        }
    }
}
</style>
