<template>
    <collapsible-widget :is-opened="true">
        <template slot="name">Sizes</template>
        <template slot="controls">
            <Badge v-if="selectedValues.length" :count="selectedValues.length" class="filter-badge-count"></Badge>
        </template>
        <library-checkbox-group v-model="selectedValues" :options="options" @input="updateSelectedSizes" />
    </collapsible-widget>
</template>
<script>
import LibraryCheckboxGroup from "@/components/Campaign/LibraryCheckboxGroup";
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";

export default {
    name: "LibrarySizeFilter",
    components: {
        CollapsibleWidget,
        LibraryCheckboxGroup
    },
    props: {
        selectedSizeFilters: {
            type: Array
        }
    },

    data() {
        return {
            selectedValues: []
        };
    },

    computed: {
        options() {
            return this.$store.state.deliverableLibrary.filterOptions.masterTemplates;
        }
    },

    watch: {
        selectedSizeFilters: {
            handler(newVal) {
                this.selectedValues = newVal;
            },
            immediate: true
        }
    },

    methods: {
        updateSelectedSizes(sizes) {
            this.$emit("updateSelectedSizeFilters", sizes);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
</style>
