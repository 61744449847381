<template>
    <div class="insights-creative-preview">
        <div class="insights-creative-preview__header">
            <div class="insights-creative-preview__header-name"><slot name="name"></slot></div>
        </div>

        <card class="dashboard-card" :show-footer="false">
            <div class="insights-creative-preview__content insights-creative-preview__content--no-data">
                <no-data />
                No data
            </div>
        </card>

        <div class="insights-creative-preview__footer insights-creative-preview__footer--no-data"></div>
    </div>
</template>
<script>
import Card from "@/components/Card/Card";
import NoData from "@/assets/NoData.svg";

export default {
    name: "InsightsCreativeNoDataPreview",
    components: { Card, NoData },

    data() {
        return {};
    }
};
</script>
