<template>
    <div class="expandable-settings-pane">
        <font-style
            v-if="isFontStyleVisible"
            :key="`${key}-font`"
            :computed-style="computedStyle"
            :value="settings"
            :parent-settings="parentSettings"
            :is-psd="isMasterTemplatePSD"
            @input="onSettingsChange"
            @clear="clearSettings"
        />

        <element-position
            v-if="editedEditable"
            :key="`${key}-position`"
            :computed-style="computedStyle"
            :value="settings"
            :parent-settings="parentSettings"
            :master-template="editedMasterTemplate"
            :bounding-client-rect="boundingClientRect"
            :is-psd="isMasterTemplatePSD"
            :editable-type="editable.type"
            @input="onSettingsChange"
            @clear="clearSettings"
        />
    </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { TemplateType } from "shared-utils/enums/masterTemplate";
import { EditorAction, EditorGetters } from "@/store/modules/editor";
import FontStyle from "@/components/Campaign/FontStyle";
import editableMethodsMixin from "@/mixins/editableMethodsMixin";
import { OverwriteScope } from "@/enums/overwrites";
import ElementPosition from "@/components/Campaign/ElementPosition";
import { EditableType } from "@/enums/editables";

export default {
    name: "EditableSettingsPane",
    components: {
        ElementPosition,
        FontStyle
    },
    mixins: [editableMethodsMixin],

    data() {
        return {
            computedStyle: {},
            boundingClientRect: {}
        };
    },
    computed: {
        editedEditable() {
            return this.$store.state.editor.editedEditable;
        },

        editedMasterTemplate() {
            if (this.editedEditable && this.editedEditable.masterTemplateId) {
                return this.$store.state.campaign.normalized.masterTemplates[this.editedEditable.masterTemplateId];
            }

            return null;
        },

        editableOverwrites() {
            return this.$store.getters[EditorGetters.editableOverwrites];
        },

        editables() {
            return this.$store.state.campaign.normalized.editables;
        },

        editable() {
            return this.editables[this.editedEditable.editable._id];
        },

        isFontStyleVisible() {
            return (
                !!this.editedEditable &&
                [EditableType.Text, EditableType.Textarea].includes(
                    this.editables[this.editedEditable.editable._id].type
                )
            );
        },

        isMasterTemplatePSD() {
            return this.editedMasterTemplate && this.editedMasterTemplate.adType === TemplateType.PSD;
        },

        overwrite() {
            return this.getOverwrite(
                this.editedEditable.editable,
                this.editedEditable.groupsAffectingEditable,
                this.editedEditable.masterTemplateId
            );
        },

        parentSettings() {
            if (this.scopeLevel === OverwriteScope.EditableGroupTemplate) {
                return this.settingsFromLevel(OverwriteScope.EditableGroup);
            }

            return {};
        },

        scopeLevel() {
            return this.editedEditable.masterTemplateId
                ? OverwriteScope.EditableGroupTemplate
                : OverwriteScope.EditableGroup;
        },

        settings() {
            return this.settingsFromLevel(this.scopeLevel);
        },

        selectedGroupValueIdsKey() {
            return this.$store.state.editor.selectedGroupValueIds.join("-");
        },

        editedEditablesSettings: {
            get() {
                return this.$store.state.editor.editedEditablesSettings;
            },

            set(settings) {
                this.$store.dispatch(EditorAction.SetEditedEditablesSettings, settings);
            }
        },

        key() {
            return `${this.selectedGroupValueIdsKey}-${this.editedEditable.editable._id}-${this.editedEditable.masterTemplateId}`;
        }
    },

    watch: {
        editedEditable: {
            immediate: true,
            handler() {
                this.initSettings();
            }
        }
    },

    created() {
        this.unsubscribeActionListener = this.$store.subscribeAction({
            after: action => {
                if (this.editedEditable && action.type === EditorAction.ClearLocalChanges) {
                    this.initSettings();
                }
            }
        });
    },

    beforeDestory() {
        this.unsubscribeActionListener();
    },

    methods: {
        clearSettings(styleProps) {
            const overwriteValueObject = this.getOverwrite(
                this.editedEditable.editable,
                this.editedEditable.groupsAffectingEditable,
                this.editedEditable.masterTemplateId
            );

            if (
                overwriteValueObject.scope === null ||
                (overwriteValueObject.scope === OverwriteScope.EditableGroup && this.editedEditable.masterTemplateId)
            ) {
                console.log("noting to remove - styles coming from parent");
                return;
            }

            const style =
                this.computeEditableSettings(
                    this.editables[this.editedEditable.editable._id],
                    this.editedEditable.masterTemplateId,
                    overwriteValueObject
                ) || "";

            const propNames = Object.keys(styleProps);
            const settingsDelta = { ...style };
            propNames.forEach(prop => delete settingsDelta[prop]);

            if (!Object.keys(settingsDelta).length) {
                if (overwriteValueObject.scope !== overwriteValueObject.valueFromScope) {
                    this.$store.dispatch(EditorAction.RemoveOverwrite, overwriteValueObject._id);
                }

                this.editedEditablesSettings = {};
            } else {
                this.editedEditablesSettings = settingsDelta;
            }
            this.updateSettings(
                {},
                this.editedEditablesSettings,
                this.editedEditable.groupsAffectingEditable,
                this.editedEditable.masterTemplateId
            );
        },

        getOverwrite(editable, groupsAffectingEditable, masterTemplateId) {
            const relevantSelectedGroups = this.getAffectingGroupValueIds(groupsAffectingEditable);
            return this.$store.getters[EditorGetters.editableOverwriteValue](
                editable._id,
                masterTemplateId,
                relevantSelectedGroups
            );
        },

        onSettingsChange(settings) {
            this.editedEditablesSettings = settings;
            this.updateSettings(
                {},
                this.editedEditablesSettings,
                this.editedEditable.groupsAffectingEditable,
                this.editedEditable.masterTemplateId
            );
        },

        settingsFromLevel(level) {
            if (this.overwrite && this.overwrite.settingsFromScope && this.overwrite.settingsFromScope[level]) {
                return this.overwrite.settingsFromScope[level] || {};
            }

            return {};
        },

        initSettings() {
            if (!this.editedEditable || !this.editedEditable.editable) {
                this.computedStyle = {};
                this.editedEditablesSettings = null;
                return;
            }

            const overwriteValueObject = this.getOverwrite(
                this.editedEditable.editable,
                this.editedEditable.groupsAffectingEditable,
                this.editedEditable.masterTemplateId
            );
            this.editedEditablesSettings = {
                ...this.computeEditableSettings(
                    this.editedEditable.editable,
                    this.editedEditable.masterTemplateId,
                    overwriteValueObject
                ),
                initial: true
            };

            if (!this.editedEditable.masterTemplateId || this.isMasterTemplatePSD) {
                this.computedStyle = {};
                this.boundingClientRect = {};

                return;
            }

            try {
                const selector = `#iframe-${this.editedEditable.masterTemplateId}`;
                const iframeEl = document.querySelector(selector);
                const editableEl = iframeEl.contentWindow.document.querySelector(`#${this.editable.name}`);
                this.boundingClientRect = editableEl.getBoundingClientRect();
                this.computedStyle = iframeEl.contentWindow.getComputedStyle(editableEl);
            } catch (e) {
                this.computedStyle = {};
                this.boundingClientRect = {};
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.expandable-settings-pane {
    height: 100%;
    overflow: hidden auto;

    svg {
        fill: $cmp-dark-font-color;
    }

    .ivu-btn {
        background-color: $cmp-dark-input-bg-color;
        border-color: #dcdee2;
        margin: 2px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $grey5;
    }
    input::-moz-placeholder {
        /* Firefox 19+ */
        color: $grey5;
    }
    input:-ms-input-placeholder {
        /* IE 10+ */
        color: $grey5;
    }
    input:-moz-placeholder {
        /* Firefox 18- */
        color: $grey5;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }
}

.editable-settings {
    &-item {
        width: 40px;
        height: 33px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__input {
        flex: 1 1 43px;
        width: 43px;

        &-label {
            padding-right: $spacing-small;
            width: 20px;
            flex: 0 0 20px;
            &--second {
                margin-left: 10px;
            }
        }

        .ivu-input-group-append {
            font-size: 10px;
            background-color: $cmp-dark-input-bg-color;
            border-color: $darktheme20;
            padding: 0;
            line-height: normal;
            height: 24px;
        }

        &--with-suffix {
            .ivu-input {
                padding-right: 2px;
            }

            .ivu-input-group-append {
                padding-left: 2px;
                padding-right: 7px;
            }
        }

        .ivu-input {
            border-right: 0;
            text-align: right;
            height: 24px;
        }

        .ivu-input:focus {
            box-shadow: none;
        }

        &-wrapper {
            flex: 1 1 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
}
</style>
