<template>
    <collapsible-widget :is-opened="opened" collapsed-height="47" @input="toggleOpen">
        <template #name>{{ editable.label }}</template>
        <template #controls>
            <editable-restriction-control v-if="showRestrictionControl" :editable="editable" />
        </template>

        <div class="deliverable-values editable-widget__body">
            <div v-if="readOnly" class="editable-widget__body--readonly" title="Read Only" @click.stop.prevent></div>
            <expandable-text-input
                class="deliverable-value-item"
                :label="EditableGroupScopeLabel"
                :value="editableValue"
                :overwrite="getOverwrite(editable, groupsAffectingEditable, null)"
                :scope="OverwriteScope.EditableGroup"
                placeholder="Enter value"
                :lockable="false"
                @input="onAllChange($event)"
                @restore="onGroupValueRestore($event)"
                @updateSettings="onUpdateSettings(null, ...arguments)"
            >
                <template v-if="editableValue" #icons>
                    <a target="_blank" :href="editableValue" @click.stop>
                        <Icon class="editable-url-widget__link-icon" type="ios-link" />
                    </a>
                </template>
            </expandable-text-input>

            <expandable-text-input
                v-for="template in templatesWithValues"
                v-show="opened"
                :key="template.id"
                class="deliverable-value-item"
                :label="template.label"
                :label-color="template.labelColor"
                :scope="OverwriteScope.EditableGroupTemplate"
                :value="template.editableValue"
                :overwrite="template.overwrite"
                placeholder="Enter value"
                @input="onValueChange($event, template.id)"
                @removeOverwrite="onRemoveOverwrite"
                @restore="onValueRestore($event, template.id)"
                @updateSettings="onUpdateSettings(template.id, ...arguments)"
            >
                <template v-if="template.editableValue" #icons>
                    <a target="_blank" :href="template.editableValue" @click.stop>
                        <Icon class="editable-url-widget__link-icon" type="ios-link" />
                    </a>
                </template>
            </expandable-text-input>
        </div>
    </collapsible-widget>
</template>

<script>
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";
import ExpandableTextInput from "@/components/Campaign/ExpandableTextInput";

import editableItemMixin from "@/mixins/editableItemMixin";
import editableMethodsMixin from "@/mixins/editableMethodsMixin";
import EditableRestrictionControl from "@/components/Campaign/EditableRestrictionControl";
import { OverwriteScope } from "@/enums/overwrites";

export default {
    name: "EditableTextWidget",
    components: {
        EditableRestrictionControl,
        ExpandableTextInput,
        CollapsibleWidget
    },

    mixins: [editableItemMixin, editableMethodsMixin],

    computed: {
        editableValue() {
            return this.getEditableValue(this.editable, this.groupsAffectingEditable, null);
        },
        language() {
            return this.$store.state.editor.selectedLanguage;
        },
        templatesWithValues() {
            return Object.keys(this.templateLabels).map(templateId => ({
                editableValue: this.getEditableValue(this.editable, this.groupsAffectingEditable, templateId),
                id: templateId,
                label: this.templateLabels[templateId],
                labelColor: this.getLabelColor(this.editable, this.groupsAffectingEditable, templateId),
                overwrite: this.getOverwrite(this.editable, this.groupsAffectingEditable, templateId)
            }));
        }
    },

    created() {
        this.OverwriteScope = OverwriteScope;
    },

    methods: {
        onAllChange(value) {
            this.update({
                value
            });
        },

        onValueChange(value, templateId) {
            this.update(
                {
                    value
                },
                templateId
            );
        },

        onGroupValueRestore(value) {
            this.restore({
                value,
                language: this.language
            });
        },

        onRemoveOverwrite(overwriteId) {
            this.removeOverwrite(overwriteId);
        },

        onValueRestore(value, templateId) {
            this.restore({
                value,
                templateId,
                language: this.language
            });
        }
    }
};
</script>

<style lang="scss">
@import "../../../../sass/variables";

.editable-url-widget__link-icon {
    color: $cmp-dark-font-color;

    &:hover {
        color: $cmp-dark-font-color;
    }
}
</style>
