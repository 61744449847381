<template>
    <div class="read-text">
        <!-- eslint-disable-next-line vue/no-useless-template-attributes -->
        <template v-if="isLoading" class="gpt3-preview__audio-loading">
            <loading-icon class="loading-spinner__icon" />
        </template>
        <template v-else-if="errorMessage">
            <Icon type="md-volume-off" />
        </template>
        <template v-else>
            <Icon type="md-volume-up" :class="{ 'read-text__ready-icon': ready }" @click.stop="loadAndPlay" />
            <audio v-if="src" ref="audioPlayer" autoplay :src="src"></audio>
        </template>
        <imagine-data-provider
            v-if="load"
            :key="text"
            :options="requestAudioOptions"
            @data="onData"
            @loading="onLoading"
            @error="onError"
        />
    </div>
</template>

<script>
import { getPlayHTRandomVoice, ImagineModel, ModelConfig } from "@/enums/imagine";
import ImagineDataProvider from "@/components/Imagine/ImagineDataProvider";
import LoadingIcon from "@/assets/Loading.svg";

export default {
    name: "ReadText",
    components: { ImagineDataProvider, LoadingIcon },

    props: {
        text: {
            type: String
        }
    },
    data() {
        return {
            isLoading: false,
            ready: false,
            load: false,
            errorMessage: "",
            data: null
        };
    },

    computed: {
        src() {
            if (!this.data) {
                return "";
            }

            return this.data.output.success[0];
        },

        requestAudioOptions() {
            const requestOptions = ModelConfig[ImagineModel.PlayHT].fieldsToRequest(this.searchOptions);
            requestOptions.input.prompt = [this.text];
            requestOptions.input.voice = getPlayHTRandomVoice();

            return requestOptions;
        }
    },

    watch: {
        text() {
            this.reset();
        }
    },

    methods: {
        async onData(data) {
            this.data = data;
            if (data.output.fail) {
                this.errorMessage = data.output.fail;
            }

            if (data.output.success && data.output.success[0]) {
                this.ready = true;
                await this.$nextTick();
                this.play();
            }
            this.isLoading = false;
        },

        onError({ message }) {
            this.errorMessage = message.errorMessage;
            if (typeof message === "string") {
                this.errorMessage = message;
            }
            this.isLoading = false;
            this.$emit("complete");
        },

        onLoading(loading) {
            this.isLoading = loading;
        },

        reset() {
            this.load = false;
            this.ready = false;
            this.errorMessage = "";
            this.data = null;
        },

        play() {
            if (this.ready) {
                this.$refs.audioPlayer.play();
            }
        },

        loadAndPlay() {
            if (this.ready) {
                this.play();
            }
            this.load = true;
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";
.read-text {
    position: relative;
    width: 20px;
    height: 20px;
    .loading-spinner__icon {
        display: block;
        height: 20px;
        width: 20px;
    }
    &__ready-icon {
        color: $purple;
    }
}
</style>
