<template>
    <div v-if="hasHeatmaps" class="heatmap-switch">
        <labelled-switch v-model="showHeatmap"><slot name="label">Attention Score</slot></labelled-switch>
    </div>
</template>
<script>
import LabelledSwitch from "@/components/Campaign/LabelledSwitch";
import { CreativeInsightsAction, CreativeInsightsGetters } from "@/store/modules/creativeInsights";

export default {
    name: "HeatmapSwitch",
    components: { LabelledSwitch },
    computed: {
        hasHeatmaps() {
            return this.$store.getters[CreativeInsightsGetters.HasHeatmaps];
        },

        showHeatmap: {
            get() {
                return this.$store.state.creativeInsights.showHeatmap;
            },
            set(val) {
                this.$store.dispatch(CreativeInsightsAction.SetShowHeatmap, val);
            }
        }
    }
};
</script>
<style lang="scss">
@import "../../../sass/_variables.scss";

.heatmap-switch {
    margin-left: auto;
    margin-right: $spacing;
}
</style>
