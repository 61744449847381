<template>
    <div>
        <Button type="primary" disabled="disabled" class="insights-download">Download{{ tagName }} Report</Button>
    </div>
</template>
<script>
export default {
    name: "ReportDownload",
    computed: {
        tagName() {
            return this.selectedTagIds.length ? ` ${this.tagsById[this.selectedTagIds[0]].name}` : " ";
        },

        selectedTagIds() {
            return this.$store.state.ciTags.selectedTagIds;
        },

        tagsById() {
            return this.$store.state.ciTags.tagsById;
        }
    }
};
</script>
