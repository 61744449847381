<template>
    <div class="master-template-banner-options">
        <master-template-upload-button
            ref="replace"
            class="hidden"
            :master-template-id="masterTemplate._id"
            @familyUpdated="familyUpdated"
            @updated="update"
        ></master-template-upload-button>
        <ul>
            <li
                v-if="isHTML5"
                class="master-template-banner-option"
                data-testid="banner-options__resize-button"
                @click="onResize"
            >
                <Icon type="md-expand" />
                <span v-if="isResizable">Exit Resize Mode</span>
                <span v-else>Resize Mode</span>
            </li>
            <li
                v-if="!isAE"
                class="master-template-banner-option"
                data-testid="banner-options__download-button"
                @click="onDownload"
            >
                <Icon type="md-cloud-download" />
                <span>Download Template</span>
            </li>
            <li
                v-if="!isAE"
                class="master-template-banner-option"
                data-testid="banner-options__replace-button"
                @click="onReplace"
            >
                <Icon type="md-cloud-upload" />
                <span>Replace Template</span>
            </li>
            <li class="master-template-banner-option" data-testid="banner-options__delete-button" @click="remove">
                <Icon type="md-trash" />
                <span>Delete</span>
            </li>
        </ul>
    </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { TemplateType } from "shared-utils/enums/masterTemplate";

import MasterTemplateUploadButton from "@/components/Campaign/MasterTemplates/MasterTemplateUploadButton";
import QueryMasterTemplateDownloadUrl from "@/apollo/queries/MasterTemplateDownloadUrl.gql";
import downloadFile from "@/utils/downloadFile";

export default {
    name: "MasterTemplateBannerOptions",
    components: { MasterTemplateUploadButton },
    props: {
        isResizable: {
            type: Boolean,
            default: false
        },

        masterTemplate: {
            type: Object,
            required: true
        }
    },
    computed: {
        isAE() {
            return this.masterTemplate.adType === TemplateType.AE;
        },

        isHTML5() {
            return this.masterTemplate.adType === TemplateType.HTML5;
        }
    },
    methods: {
        async onDownload() {
            const { data } = await this.$apollo.query({
                query: QueryMasterTemplateDownloadUrl,
                variables: {
                    masterTemplateId: this.masterTemplate._id
                }
            });
            downloadFile(data.masterTemplateDownloadUrl, `${this.masterTemplate.name}.zip`);
            this.hide();
        },

        async onReplace() {
            this.hide();
            this.$refs.replace.remoteClick();
        },

        familyUpdated(members) {
            this.$emit("familyUpdated", members);
            this.hide();
        },

        hide() {
            this.$emit("hide");
        },

        onResize() {
            this.$emit("resizeMode");
            this.hide();
        },

        remove() {
            this.$emit("remove");
            this.hide();
        },

        update() {
            this.$emit("update");
            this.hide();
        }
    }
};
</script>
<style lang="scss">
@import "../../../../sass/variables";

.master-template-banner-option {
    text-align: left;
    height: 33px;
    width: 100%;
    padding: 0 11px;
    line-height: 33px;
    cursor: pointer;
    color: $cmp-light-font-color;

    display: flex;
    gap: 6px;
    align-items: center;

    &:hover {
        background-color: $grey2;
    }
}
</style>
