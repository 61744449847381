// eslint-disable-next-line import/no-extraneous-dependencies
import { UserActions as Actions, UserActionsToAuthorizeByScope } from "shared-utils/enums/user";
// TODO update this to use @apollo/client/link/context
import { setContext } from "apollo-link-context";
import Amplify from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import store from "@/store";
import { AuthGetters } from "@/store/modules/auth";
import { DefaultUser } from "@/enums/users";

const OAUTH_STATE = "oauthState";

const getAuthConfig = async () => {
    const response = await fetch(`${window.location.origin}/oauth-config`);

    if (response.status !== 200) {
        const error = await response.json();
        throw new Error(error.message || "Error retrieving Auth Config");
    }

    return response.json();
};

const setAuthConfig = async authConfig => {
    if (sessionStorage.getItem(OAUTH_STATE) === null) {
        // Create a state value which will be used to avoid CSRF. We persisit
        // the value because the page will reload as part of the login process.
        // https://developers.google.com/identity/protocols/oauth2/openid-connect?hl=fr#createxsrftoken
        sessionStorage.setItem(OAUTH_STATE, Math.floor(Math.random() * 10000000000));
    }

    // Set config on Amplify
    Amplify.configure({
        Auth: authConfig
    });
};

const logout = async authConfig => {
    // Remove oauthSession from storage is logging out from a provider otherwise use Amplify

    if (localStorage.getItem("oauthSession")) {
        localStorage.removeItem("oauthSession");
        window.location = `${authConfig.ssoURL}/logout?client_id=${authConfig.userPoolWebClientId}&logout_uri=https://${document.subdomain}/login`;
        return false;
    }
    // Returns true to confirm the user was logged out
    await Auth.signOut();
    return true;
};

const login = async (username, password) => Auth.signIn(username, password);

const providerLogin = async (provider, authConfig) => {
    const state = sessionStorage.getItem(OAUTH_STATE);
    // Logging in with a provider to redirect to the cognito authorize endpoint
    const url = `${authConfig.ssoURL}/oauth2/authorize?identity_provider=${provider}&redirect_uri=https://${document.subdomain}/&response_type=code&client_id=${authConfig.userPoolWebClientId}&scope=openid&state=${state}`;
    window.location = url;
};

const requestToken = async (params, authConfig) => {
    // Convert object params to a query string
    const body = Object.keys(params)
        .reduce((a, k) => {
            a.push(`${k}=${encodeURIComponent(params[k])}`);
            return a;
        }, [])
        .join("&");
    // Fetch relevant token from token endpoint
    const response = await fetch(`${authConfig.ssoURL}/oauth2/token`, {
        body: `${body}&client_id=${authConfig.userPoolWebClientId}&redirect_uri=https://${document.subdomain}/`,
        headers: { "content-type": "application/x-www-form-urlencoded" },
        method: "POST"
    });
    // Get response JSON
    const data = await response.json();
    // Throw if error found
    if (data.error) {
        throw new Error(data.error);
    }
    // Store tokens
    if (data.id_token) {
        // Get current oauth session
        const session = JSON.parse(localStorage.getItem("oauthSession")) || {};
        // Set new idToken
        session.idToken = {
            jwtToken: data.id_token,
            payload: JSON.parse(atob(data.id_token.split(".")[1]))
        };
        // Set refresh token if found
        if (data.refresh_token) {
            session.refreshToken = data.refresh_token;
        }
        // Store new session
        localStorage.setItem("oauthSession", JSON.stringify(session));
    } else {
        throw new Error(data);
    }
};

const processCode = async (code, state, authConfig) => {
    // We compare the state value to avoid CSRF
    if (state !== sessionStorage.getItem(OAUTH_STATE)) {
        // Something went wrong teturn user to the homepage without the code.
        window.location = window.location.origin;
        return false;
    }
    // Process OAuth auth code
    await requestToken(
        {
            code,
            grant_type: "authorization_code"
        },
        authConfig
    );
    return true;
};

const refreshToken = authConfig =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
        // Get current oauth session
        let session = JSON.parse(localStorage.getItem("oauthSession"));
        // Request token if oauth found otherwise refresh with Amplify
        if (session) {
            try {
                await requestToken(
                    {
                        refresh_token: session.refreshToken,
                        grant_type: "refresh_token"
                    },
                    authConfig
                );
                resolve();
            } catch (e) {
                reject(e);
            }
        } else {
            // Grab current user and session
            const user = await Auth.currentAuthenticatedUser();
            session = await Auth.currentSession();
            // Refresh session
            user.refreshSession(session.refreshToken, (err, data) => {
                if (err) {
                    reject(err);
                }
                resolve(data);
            });
        }
    });

const getName = (user, firstNameOnly) => {
    if (firstNameOnly) {
        return user.attributes.given_name;
    }
    if (user.attributes.given_name && user.attributes.family_name) {
        return `${user.attributes.given_name} ${user.attributes.family_name}`;
    }
    if (user.username) {
        return user.username;
    }
    return null;
};

const userDisplayName = async (firstNameOnly = false) => {
    // Get current oauth session and user
    const session = JSON.parse(localStorage.getItem("oauthSession"));
    const user = session ? { attributes: session.idToken.payload } : await Auth.currentAuthenticatedUser();
    // Return default display name if user name not found
    return getName(user, firstNameOnly) || DefaultUser;
};

const userEmail = async () => {
    // Get current oauth session and user
    const session = JSON.parse(localStorage.getItem("oauthSession"));
    const user = session ? { attributes: session.idToken.payload } : await Auth.currentAuthenticatedUser();
    // Return default display name if user name not found
    return user.attributes.email;
};

const getPayload = async () => {
    // Get current oauth session
    const session = JSON.parse(localStorage.getItem("oauthSession"));
    // If session is not found then get Amplify current authenticated user
    if (!session) {
        try {
            const user = await Auth.currentAuthenticatedUser();
            return user.signInUserSession.idToken.payload;
        } catch (e) {
            if (e !== "not authenticated") {
                throw e;
            }
            // Authenticated user not found so return empty payload
            return {};
        }
    }
    // Return current session
    return session.idToken.payload;
};

const getIdToken = async () => {
    // Get current oauth session
    let session = JSON.parse(localStorage.getItem("oauthSession"));
    // If session is not found then get Amplify session
    if (!session) {
        try {
            session = await Auth.currentSession();
        } catch (e) {
            if (e !== "No current user") {
                throw e;
            }
            // Token not found so return null
            return null;
        }
    }
    // Return current session
    return session.idToken.jwtToken;
};

const isAuthorized = async authConfig => {
    setAuthConfig(authConfig);

    return !!(await getIdToken());
};

const forgotPassword = async username => Auth.forgotPassword(username);

const forgotPasswordSubmit = async (username, code, password) => Auth.forgotPasswordSubmit(username, code, password);

const completeNewPassword = async (user, password) => Auth.completeNewPassword(user, password);

const hasScope = (scope, exact = true) => store.getters[AuthGetters.hasScope](scope, exact);

// eslint-disable-next-line complexity
const userCan = (action, scope = null, exact = true) => {
    const isScopeRequired = UserActionsToAuthorizeByScope.includes(action);

    if (isScopeRequired && !scope) {
        throw new Error(`AuthService: Scope required for action: ${action}`);
    }

    return isScopeRequired
        ? store.getters[AuthGetters.userCan](action) && scope && hasScope(scope, exact)
        : store.getters[AuthGetters.userCan](action);
};

export const asyncAuthLink = setContext(async () => ({
    // Add ID Token as auth header for API calls
    headers: { Authorization: await getIdToken() }
}));

export default {
    refreshToken,
    login,
    logout,
    providerLogin,
    forgotPassword,
    forgotPasswordSubmit,
    completeNewPassword,
    processCode,
    userDisplayName,
    userEmail,
    getAuthConfig,
    isAuthorized,
    getPayload,
    userCan,
    hasScope,
    Actions
};
