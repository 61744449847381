<template>
    <collapsible-widget :is-opened="true" size="small">
        <template slot="name">
            <span class="capitalize-text">{{ label }}</span>
        </template>
        <template slot="controls">
            <Badge
                v-if="selectedValues.length"
                :count="selectedValues.length"
                class="editable-group-checkbox__count-badge filter-badge-count"
            ></Badge>
        </template>
        <library-checkbox-group v-model="selectedValues" :options="options" />
    </collapsible-widget>
</template>
<script>
import { DeliverableLibraryAction, DeliverableLibraryGetters } from "@/store/modules/deliverableLibrary";
import LibraryCheckboxGroup from "@/components/Campaign/LibraryCheckboxGroup";
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";

export default {
    name: "EditableGroupCheckbox",
    components: {
        CollapsibleWidget,
        LibraryCheckboxGroup
    },

    props: {
        groupName: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        options: {
            type: Array,
            required: true
        }
    },

    computed: {
        selectedValues: {
            get() {
                return this.$store.getters[DeliverableLibraryGetters.groupValueFilters](this.groupName);
            },
            set(newValue) {
                this.$store.dispatch(DeliverableLibraryAction.UpdateGroupValueFilter, {
                    groupName: this.groupName,
                    groupValues: newValue
                });
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
</style>
