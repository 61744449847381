<template>
    <div class="imagine__tip-box">
        <Collapse v-model="opened" simple @input="onOpen">
            <Panel name="1" class="hox-collapsible__panel">
                <span class="hox-collapsible__panel-name">
                    <span class="hox-collapsible__panel-name--inner">
                        <slot name="name"></slot>
                    </span>
                </span>
                <span class="hox-collapsible__panel-controls">
                    <slot name="controls"></slot>
                </span>
                <div slot="content" class="hox-collapsible__body">
                    <slot></slot>
                </div>
            </Panel>
        </Collapse>
    </div>
</template>
<script>
export default {
    name: "CollapsibleTip",
    props: {
        isOpen: {
            type: Boolean,
            default: false
        },
        forceCollapse: {
            type: Number
        }
    },
    data() {
        return {
            opened: ""
        };
    },

    watch: {
        isOpen: {
            immediate: true,
            handler() {
                this.opened = this.isOpen ? ["1"] : [];
            }
        },

        forceCollapse() {
            this.opened = [];
        }
    },

    methods: {
        onOpen(v) {
            this.$emit("opened", v);
        }
    }
};
</script>
