import assert from "assert";
import { decodeManifest } from "hi-dcp-dyce";
import { EditableTypes } from "../../gql/Editable/constants";
import { AdTypes, EditableMediaTypes } from "../../gql/MasterTemplate/constants";
import { isAbsoluteUrl, isMediaType } from "../utils";
import { ValidationError, UnexpectedError } from "../errors";

const editableGroups = [];
const editableNames = [];
const manifestProperties = [
    "name",
    "platform",
    "adType",
    "adSize",
    "reportingLabel",
    "backupQuality",
    "metadata",
    "hoxtonSettings",
    "disableLegacyCDN"
];

const isValidReportingLabelPlaceholder = value => {
    const groups = Array.from(new Set(editableGroups));
    const others = ["language", "platform", "name", "adType", "width", "height"];

    return groups.includes(value) || others.includes(value) || editableNames.includes(value);
};

const sanitiseReportingLabel = reportingLabel =>
    reportingLabel.replace(/\{(.*?)\}/g, (match, $1) => {
        const lowered = $1.toLowerCase();
        const value = isValidReportingLabelPlaceholder(lowered) ? lowered : $1;
        return `{${value}}`;
    });

const sanitiseHoxtonData = hoxtonData => {
    const sanitised = { ...hoxtonData };
    sanitised.adSize.width = parseInt(sanitised.adSize.width, 10);
    sanitised.adSize.height = parseInt(sanitised.adSize.height, 10);
    sanitised.backupQuality = parseInt(sanitised.backupQuality, 10) || null;
    return sanitised;
};

const validateHoxtonData = hoxtonData => {
    assert(
        hoxtonData.name,
        new ValidationError(
            "name",
            hoxtonData.name,
            "Invalid JSON provided in Hoxton tags. A name must be passed in the hoxton JSON object."
        )
    );
    assert(
        typeof hoxtonData.adSize === "object",
        new ValidationError(
            "adSize",
            hoxtonData.adSize,
            "Invalid JSON provided in Hoxton tags. The adSize object must be passed in the hoxton JSON object."
        )
    );
    assert(
        hoxtonData.adSize.width,
        new ValidationError(
            "adSize.width",
            hoxtonData.adSize.width,
            "Invalid JSON provided in Hoxton tags. The adSize must be passed in the hoxton JSON object and must have a width."
        )
    );
    assert(
        hoxtonData.adSize.height,
        new ValidationError(
            "adSize.height",
            hoxtonData.adSize.height,
            "Invalid JSON provided in Hoxton tags. The adSize must be passed in the hoxton JSON object and must have a height."
        )
    );
    assert(
        AdTypes[hoxtonData.adType],
        new ValidationError(
            "adType",
            hoxtonData.adType,
            `Invalid JSON provided in Hoxton tags. Invalid adType provided. The "${hoxtonData.adType}" adType is not currently accepted.`
        )
    );

    assert(
        Object.keys(hoxtonData).every(fieldName => {
            const entry = hoxtonData[fieldName];

            if (typeof entry === "object" && EditableMediaTypes.includes(entry.type)) {
                return !!entry.value;
            }

            return true;
        }),
        new ValidationError(
            "Media File",
            hoxtonData.metadata,
            "Invalid JSON provided in Hoxton tags. All Media type editables (image, file etc) need to have initial value."
        )
    );

    if (hoxtonData.metadata) {
        assert(
            typeof hoxtonData.metadata === "object",
            new ValidationError(
                "metadata",
                hoxtonData.metadata,
                "Invalid JSON provided in Hoxton tags. The metadata property must be an object."
            )
        );
        assert(
            Object.keys(hoxtonData.metadata).every(fieldName => typeof hoxtonData.metadata[fieldName] === "string"),
            new ValidationError(
                "metadata",
                hoxtonData.metadata,
                "Invalid JSON provided in Hoxton tags. Metadata properties can only be strings."
            )
        );
    }
    return hoxtonData;
};

const validateReportingLabel = reportingLabel => {
    if (!reportingLabel) {
        return false;
    }

    const sanitisedLabel = sanitiseReportingLabel(reportingLabel);
    const matches = sanitisedLabel.match(/{(.*?)}/g);

    if (!matches) {
        return true;
    }

    return matches.every(match => {
        const value = match.replace(/^{|}$/g, "");
        assert(
            isValidReportingLabelPlaceholder(value),
            new ValidationError(
                "reportingLabel",
                value,
                `Invalid JSON provided in Hoxton tags. Invalid reportingLabel provided. The tag "{${value}}" is not known.`
            )
        );
        return true;
    });
};

const validateEditableName = editableName => {
    assert(
        !editableName.match(/\s|^[A-Z]/g),
        new ValidationError(
            editableName,
            undefined,
            `Invalid JSON provided in Hoxton tags. Invalid editable name provided: "${editableName}" - must be camelCase i.e. editableName`
        )
    );
};

const validateEditableType = editable => {
    const type = editable && editable.type ? editable.type : undefined;
    assert(
        EditableTypes[type],
        new ValidationError(
            editable.name,
            type,
            `Invalid editable type provided: "${type}" for editable "${editable.name}"`
        )
    );
    assert(
        !(isMediaType(editable.type) && isAbsoluteUrl(editable.defaultValue)),
        new ValidationError(
            `${editable.name}.type`,
            editable.type,
            `Invalid JSON provided in Hoxton tags. Invalid editable type provided: "${editable.type}" for editable "${editable.name}" can not be an external URL`
        )
    );
};

const populateEditable = (manifest, key) => {
    const update = {};
    if (manifest[key].keyframe) {
        update.keyframe = manifest[key].keyframe;
    }

    if (manifest[key].editableGroups) {
        update.editableGroups = manifest[key].editableGroups.map(editableGroupName => editableGroupName.toLowerCase());
    } else {
        update.editableGroups = ["other fields"];
    }

    if (manifest[key].keywords) {
        update.keywords = manifest[key].keywords;
    }
    return update;
};

const manifestParse = manifest =>
    Object.keys(manifest).reduce((accumulator, key) => {
        if (!manifestProperties.includes(key)) {
            validateEditableName(key);

            const friendlyName = key
                .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
                .replace(/([a-z])([0-9])/, "$1 $2")
                .replace(/^./, str => str.toUpperCase());

            assert(
                manifest[key] && typeof manifest[key] === "object",
                new ValidationError(
                    key,
                    undefined,
                    `Invalid JSON provided in Hoxton tags. "${key}" is not a known property.`
                )
            );

            const editable = {
                name: key,
                friendlyName,
                defaultValue: manifest[key].value,
                type: manifest[key].type
            };

            validateEditableType(editable);
            Object.assign(editable, populateEditable(manifest, key));
            editableGroups.push(...editable.editableGroups);
            editableNames.push(key);
            accumulator.push(editable);
        }

        return accumulator;
    }, []);

export default hoxtonTagContents => {
    // Handle both encoded and decoded manifest
    const parsedJSON = decodeManifest(hoxtonTagContents);
    if (!parsedJSON) {
        throw new UnexpectedError("Invalid JSON provided in Hoxton manifest.");
    }

    const reply = {
        data: {}
    };

    const hoxtonData = sanitiseHoxtonData(validateHoxtonData(parsedJSON));

    reply.data.name = hoxtonData.name;
    reply.data.size = hoxtonData.adSize;
    reply.data.editables = manifestParse(hoxtonData);

    if (hoxtonData.adType) {
        reply.data.adType = hoxtonData.adType;
    }

    if (hoxtonData.backupQuality) {
        reply.data.backupQuality = hoxtonData.backupQuality;
    }

    if (validateReportingLabel(hoxtonData.reportingLabel)) {
        reply.data.reportingLabel = sanitiseReportingLabel(hoxtonData.reportingLabel);
    }

    reply.success = true;
    reply.messages = ["Parsing successful."];

    return reply;
};
