<template>
    <collapsible-widget
        class="editable-array-widget editable-select-widget"
        :is-opened="opened"
        collapsed-height="47"
        @input="toggleOpen"
    >
        <template #name>{{ editable.label }}</template>
        <template #controls>
            <editable-restriction-control v-if="showRestrictionControl" :editable="editable" />
        </template>

        <div class="editable-widget__body">
            <div v-if="readOnly" class="editable-widget__body--readonly" title="Read Only" @click.stop.prevent></div>
            <div class="deliverable-value-item">
                <labelled-select
                    :label="EditableGroupScopeLabel"
                    :options="allOptions"
                    :placeholder="groupPlaceholder"
                    :value="getSelected(getEditableValue(editable, groupsAffectingEditable, null))"
                    @update="onAllChange($event)"
                />
            </div>

            <lockable-row
                v-for="(label, templateId) in templateLabels"
                v-show="opened"
                :key="getForKey(editable, templateId)"
                :locked="isTemplateOverwrite(editable, groupsAffectingEditable, templateId)"
                :tracked-clicks="['locked']"
                @unlock="onUnlock(editable, groupsAffectingEditable, templateId)"
            >
                <labelled-select
                    :label="label"
                    :label-color="getLabelColor(editable, groupsAffectingEditable, templateId)"
                    :options="getEditableValue(editable, groupsAffectingEditable, templateId)"
                    :value="getSelected(getEditableValue(editable, groupsAffectingEditable, templateId))"
                    @update="onUpdate($event, groupsAffectingEditable, templateId)"
                />
            </lockable-row>
        </div>
    </collapsible-widget>
</template>

<script>
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";
import LabelledSelect from "@/components/Campaign/LabelledSelect";
import LockableRow from "@/components/Campaign/LockableRow";
import editableItemMixin from "@/mixins/editableItemMixin";
import editableMethodsMixin from "@/mixins/editableMethodsMixin";
import EditableRestrictionControl from "@/components/Campaign/EditableRestrictionControl";

export default {
    name: "EditableArrayWidget",

    components: {
        EditableRestrictionControl,
        LockableRow,
        LabelledSelect,
        CollapsibleWidget
    },

    mixins: [editableItemMixin, editableMethodsMixin],

    computed: {
        allOptions() {
            const allOptions = [];
            const allLabels = [];

            Object.keys(this.templateLabels).forEach(templateId => {
                const options = this.getEditableValue(this.editable, this.groupsAffectingEditable, templateId);

                if (Array.isArray(options)) {
                    options.forEach(option => {
                        if (!allLabels.includes(option.label)) {
                            allOptions.push(option);
                            allLabels.push(option.label);
                        }
                    });
                }
            });

            return allOptions;
        },

        groupPlaceholder() {
            const masterTemplateIds = Object.keys(this.templateLabels);
            const sample = this.getSelectedLabel(this.getDefaultValue(this.editable, masterTemplateIds[0]));
            const sameDefaults = !masterTemplateIds.some(masterTemplateId => {
                const val = this.getSelectedLabel(this.getDefaultValue(this.editable, masterTemplateId));
                return val !== sample;
            });

            return sameDefaults ? sample : "Select";
        }
    },

    methods: {
        onAllChange(newValue) {
            this.onUpdate(newValue, null);
        },

        onUnlock(editable, groupsAffectingEditable, templateId) {
            const overwrite = this.getOverwrite(editable, groupsAffectingEditable, templateId);
            this.removeOverwrite(overwrite._id);
        },

        onUpdate(newValue, groupsAffectingEditable, templateId) {
            let editableValue = this.getEditableValue(this.editable, groupsAffectingEditable, templateId);

            if (!editableValue) {
                editableValue = this.allOptions;
            }

            const options = editableValue.map(({ label, value }) => {
                const option = {
                    label,
                    value
                };

                if (option.value === newValue) {
                    option.selected = true;
                }

                return option;
            });

            this.update({ value: options }, templateId);
        },

        getSelected(options) {
            if (!Array.isArray(options)) {
                return null;
            }

            const selected = options.find(option => option.selected);
            return selected ? selected.value : null;
        },

        getSelectedLabel(options) {
            if (!Array.isArray(options)) {
                return null;
            }

            const selected = options.find(option => option.selected);
            return selected ? selected.label : null;
        }
    }
};
</script>
