const storeNamespace = "imagine";

export const ImagineAction = {
    SetUseAdvancedOptions: `${storeNamespace}/setUseAdvancedOptions`,
    SetAdvancedOptions: `${storeNamespace}/setAdvancedOptions`
};
export const ImagineMutation = {
    SetUseAdvancedOptions: "setUseAdvancedOptions",
    SetAdvancedOptions: "setAdvancedOptions"
};

export default {
    namespaced: true,
    state: {
        useAdvancedOptions: true,
        advancedOptions: {}
    },

    mutations: {
        setUseAdvancedOptions(state, payload) {
            state.useAdvancedOptions = payload;
        },

        setAdvancedOptions(state, payload) {
            state.advancedOptions = payload;
        }
    },

    actions: {
        setUseAdvancedOptions({ commit }, value) {
            localStorage.setItem("showAdvancedOptions", value);
            commit(ImagineMutation.SetUseAdvancedOptions, value);
        },

        setAdvancedOptions({ commit }, value) {
            commit(ImagineMutation.SetAdvancedOptions, value);
        }
    }
};
