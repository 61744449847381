<template>
    <div
        ref="wrap"
        class="mentionable-wrap"
        @compositionstart="handleCompositionStart"
        @compositionend="handleCompositionEnd"
        @input="handleInput(false)"
        @keydown.capture="handleKeyDown"
    >
        <Poptip
            v-if="atwho"
            class="mentionable-popup-wrapper--anchor"
            popper-class="mentionable-popup-wrapper"
            :value="true"
            :transfer="true"
        >
            <template #content>
                <div class="mentionable-popup" :class="poptipClass">
                    <template v-if="showUsageDescription && chunk.length === 0">
                        <slot name="usageDescription">
                            <div class="mentionable-usage-description">
                                Start typing or select from the list provided
                            </div>
                        </slot>
                    </template>
                    <template v-else-if="isIe">
                        <slot name="list-footer">
                            <div class="mentionable-list-footer">Mentions are not available in your browser.</div>
                        </slot>
                    </template>
                    <template v-else>
                        <slot name="list-header"></slot>

                        <ul v-if="atwho.list.length" class="mentionable-list">
                            <li
                                v-for="(item, index) in atwho.list"
                                :key="index"
                                :ref="isCur(index) && 'cur'"
                                class="mentionable-list-item"
                                :class="{ 'mentionable-list-item--current': isCur(index) }"
                                :data-index="index"
                                @mouseenter="handleItemHover"
                                @click="handleItemClick"
                            >
                                <slot name="item" :item="item">
                                    <span v-if="item" v-text="itemName(item)"></span>
                                </slot>
                            </li>
                        </ul>
                        <template v-else slot="no-matches"></template>
                        <slot name="list-footer">
                            <div class="mentionable-list-footer">
                                <strong class="link-like" @click="insertAtCampaign">@campaign</strong>
                                will notify everyone collaborating on this campaign
                            </div>
                        </slot>
                    </template>
                </div>
            </template>
            <div class="mentionable-popup-ref"></div>
        </Poptip>
        <span v-show="false" ref="embeddedItem">
            <slot name="embeddedItem" :current="currentItem"></slot>
        </span>
        <span v-show="false" ref="embeddedAtCampaign">
            <slot name="embeddedAtCampaign">
                <span><span class="mentionable-tag">@campaign</span></span>
            </slot>
        </span>
        <slot></slot>
    </div>
</template>

<script>
/*
 * This component is a modified version of At component from vue-at library.
 * Due to the template overwriting limitations these has been ported and used directly
 * https://github.com/fritx/vue-at
 * https://fritx.github.io/vue-at/#/en/quickstart
 */

// bug report: https://github.com/vuejs/awesome-vue/pull/1028
// https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoViewIfNeeded

/* eslint-disable */
import { isIe } from "@/utils";

export function scrollIntoView( el, scrollParent ) {
    if ( el.scrollIntoViewIfNeeded ) {
        el.scrollIntoViewIfNeeded( false ); // alignToCenter=false
    } else {
        // should not use `el.scrollIntoView(false)` // alignToTop=false
        // bug report: https://stackoverflow.com/questions/11039885/scrollintoview-causing-the-whole-page-to-move
        const diff = el.offsetTop - scrollParent.scrollTop;
        if ( diff < 0 || diff > scrollParent.offsetHeight - el.offsetHeight ) {
            scrollParent = scrollParent || el.parentElement;
            scrollParent.scrollTop = el.offsetTop;
        }
    }
}

export function applyRange( range ) {
    const selection = window.getSelection();
    if ( selection ) { // 容错
        selection.removeAllRanges();
        selection.addRange( range );
    }
}

export function getRange() {
    const selection = window.getSelection();
    if ( selection && selection.rangeCount > 0 ) {
        return selection.getRangeAt( 0 );
    }
}

export function getAtAndIndex( text, ats ) {
    return ats.map( at => ( {
        at,
        index: text.lastIndexOf( at )
    } ) ).reduce( ( a, b ) => ( a.index > b.index ? a : b ) );
}

// http://stackoverflow.com/questions/26747240/plain-javascript-replication-to-offset-and-position
export function getOffset( element, target ) {
    // var element = document.getElementById(element),
    //     target  = target ? document.getElementById(target) : window;
    target = target || window;
    var offset = {
            top: element.offsetTop,
            left: element.offsetLeft
        },
        parent = element.offsetParent;
    while ( parent != null && parent != target ) {
        offset.left += parent.offsetLeft;
        offset.top += parent.offsetTop;
        parent = parent.offsetParent;
    }
    return offset;
}

// http://stackoverflow.com/questions/3972014/get-caret-position-in-contenteditable-div
export function closest( el, predicate ) {
    /* eslint-disable */
    do {
        if ( predicate( el ) ) return el;
    } while ( el = el && el.parentNode );
}

// http://stackoverflow.com/questions/15157435/get-last-character-before-caret-position-in-javascript
export function getPrecedingRange() {
    const r = getRange();
    if ( r ) {
        const range = r.cloneRange();
        range.collapse( true );
        // var el = closest(range.endContainer, d => d.contentEditable)
        // range.setStart(el, 0)
        range.setStart( range.endContainer, 0 );
        return range;
    }
}

export default {
    name: "HoxMentionable",

    props: {
        value: {
            type: String, // value not required
            default: null
        },
        at: {
            type: String,
            default: null
        },
        ats: {
            type: Array,
            default: () => [ "@" ]
        },
        suffix: {
            type: String,
            default: " "
        },
        loop: { // allow options looping
            type: Boolean,
            default: true
        },
        allowSpaces: {
            type: Boolean,
            default: false
        },
        tabSelect: {
            type: Boolean,
            default: false
        },
        avoidEmail: {
            type: Boolean,
            default: true
        },
        showUnique: {
            type: Boolean,
            default: true
        },
        hoverSelect: {
            type: Boolean,
            default: true
        },
        members: {
            type: Array,
            default: () => []
        },
        nameKey: {
            type: String,
            default: ""
        },
        filterMatch: {
            type: Function,
            default: ( name, chunk, at ) =>
                // match at lower-case
                name.toLowerCase()
                    .indexOf( chunk.toLowerCase() ) > -1

        },
        deleteMatch: {
            type: Function,
            default: ( name, chunk, suffix ) => chunk === name + suffix
        },
        poptipWidth: {
            default: "normal",
            validator( value ) {
                return [ "normal", "wide" ].includes( value );
            }
        }
    },

    data() {
        return {
            // at[v-model] mode should be on only when
            // initial :value/v-model is present (not nil)
            bindsValue: this.value != null,
            chunk: "",
            customsEmbedded: false,
            isIe: isIe(),
            poptipClass: "",
            showUsageDescription: false,
            showNoMatch: false,
            hasComposition: false,
            atwho: null
        };
    },
    computed: {
        atItems() {
            return this.at ? [ this.at ] : this.ats;
        },

        currentItem() {
            if ( this.atwho && this.atwho.list.length && !( this.showUsageDescription && this.chunk.length === 0 ) ) {
                return this.atwho.list[ this.atwho.cur ];
            }
            return "";
        }
    },
    watch: {
        "atwho.cur": function ( index ) {
            if ( index != null ) { // cur index exists
                this.$nextTick( () => {
                    this.scrollToCur();
                } );
            }
        },
        members() {
            this.handleInput( true );
        },
        value( value, oldValue ) {
            if ( this.bindsValue ) {
                this.handleValueUpdate( value );
            }
        }
    },
    mounted() {
        if ( this.$scopedSlots.embeddedItem ) {
            this.customsEmbedded = true;
        }

        if ( this.$scopedSlots.usageDescription ) {
            this.showUsageDescription = true;
        }

        if ( this.bindsValue ) {
            this.handleValueUpdate( this.value );
        }

        this.poptipClass = `mentionable-popup--${ this.poptipWidth }`;
    },

    methods: {
        itemName( v ) {
            const { nameKey } = this;
            return nameKey ? v[ nameKey ] : v;
        },
        isCur( index ) {
            return index === this.atwho.cur;
        },
        handleValueUpdate( value ) {
            const el = this.$el.querySelector( "[contenteditable]" );
            if ( value !== el.innerHTML ) { // avoid range reset
                el.innerHTML = value;
                this.dispatchInput();
            }
        },
        dispatchInput() {
            const el = this.$el.querySelector( "[contenteditable]" );
            const ev = new Event( "input", { bubbles: true } );
            el.dispatchEvent( ev );
        },

        handleItemHover( e ) {
            if ( this.hoverSelect ) {
                this.selectByMouse( e );
            }
        },
        handleItemClick( e ) {
            this.selectByMouse( e );
            this.insertItem();
        },
        handleDelete( e ) {
            const range = getPrecedingRange();
            if ( range ) {
                if ( this.customsEmbedded && range.endOffset >= 1 ) {
                    let a = range.endContainer.childNodes[ range.endOffset ]
                        || range.endContainer.childNodes[ range.endOffset - 1 ];
                    if ( !a || a.nodeType === Node.TEXT_NODE && !/^\s?$/.test( a.data ) ) {
                        return;
                    }
                    if ( a.nodeType === Node.TEXT_NODE ) {
                        if ( a.previousSibling ) a = a.previousSibling;
                    } else if ( a.previousElementSibling ) a = a.previousElementSibling;
                    let ch = [].slice.call( a.childNodes );
                    ch = [].reverse.call( ch );
                    ch.unshift( a );
                    let last;
                    [].some.call( ch, ( c ) => {
                        if ( c.getAttribute && c.getAttribute( "data-at-embedded" ) != null ) {
                            last = c;
                            return true;
                        }
                    } );
                    if ( last ) {
                        e.preventDefault();
                        e.stopPropagation();
                        const r = getRange();
                        if ( r ) {
                            r.setStartBefore( last );
                            r.deleteContents();
                            applyRange( r );
                            this.handleInput();
                        }
                    }
                    return;
                }

                const { atItems, members, suffix, deleteMatch, itemName } = this;
                const text = range.toString();
                const { at, index } = getAtAndIndex( text, atItems );

                if ( index > -1 ) {
                    const chunk = text.slice( index + at.length );
                    const has = members.some( ( v ) => {
                        const name = itemName( v );
                        return deleteMatch( name, chunk, suffix );
                    } );
                    if ( has ) {
                        e.preventDefault();
                        e.stopPropagation();
                        const r = getRange();
                        if ( r ) {
                            r.setStart( r.endContainer, index );
                            r.deleteContents();
                            applyRange( r );
                            this.handleInput();
                        }
                    }
                }
            }
        },
        handleKeyDown( e ) {
            const { atwho } = this;
            if ( atwho ) {
                if ( e.keyCode === 38 || e.keyCode === 40 ) { // ↑/↓
                    if ( !( e.metaKey || e.ctrlKey ) ) {
                        e.preventDefault();
                        e.stopPropagation();
                        this.selectByKeyboard( e );
                    }
                    return;
                }
                if ( e.keyCode === 13 || ( this.tabSelect && e.keyCode === 9 ) ) { // enter or tab
                    this.insertItem();
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                }
                if ( e.keyCode === 27 ) { // esc
                    this.closePanel();
                    return;
                }
            }

            // 为了兼容ie ie9~11 editable无input事件 只能靠keydown触发 textarea正常
            // 另 ie9 textarea的delete不触发input
            const isValid = e.keyCode >= 48 && e.keyCode <= 90 || e.keyCode === 8;
            if ( isValid ) {
                setTimeout( () => {
                    this.handleInput();
                }, 50 );
            }

            if ( e.keyCode === 8 ) {
                this.handleDelete( e );
            }
        },

        // compositionStart -> input -> compositionEnd
        handleCompositionStart() {
            this.hasComposition = true;
        },
        handleCompositionEnd() {
            this.hasComposition = false;
            this.handleInput();
        },
        handleInput( keep ) {
            if ( this.hasComposition ) return;
            const el = this.$el.querySelector( "[contenteditable]" );
            this.$emit( "input", el.innerHTML );

            const range = getPrecedingRange();

            if ( range ) {
                const { atItems, avoidEmail, allowSpaces, showUnique } = this;

                let show = true;
                const text = range.toString();

                const { at, index } = getAtAndIndex( text, atItems );

                if ( index < 0 ) show = false;
                const prev = text[ index - 1 ];

                const chunk = text.slice( index + at.length, text.length );
                this.chunk = chunk;

                if ( avoidEmail ) {
                    // 上一个字符不能为字母数字 避免与邮箱冲突
                    // 微信则是避免 所有字母数字及半角符号
                    if ( /^[a-z0-9]$/i.test( prev ) ) show = false;
                }

                if ( !allowSpaces && /\s/.test( chunk ) ) {
                    show = false;
                }

                // chunk以空白字符开头不匹配 避免`@ `也匹配
                if ( /^\s/.test( chunk ) ) show = false;

                if ( !show ) {
                    this.closePanel();
                } else {
                    const { members, filterMatch, itemName } = this;
                    if ( !keep && chunk ) {
                        this.$emit( "at", chunk );
                    }
                    const matched = members.filter( ( v ) => {
                        const name = itemName( v );
                        return filterMatch( name, chunk, at );
                    } );

                    show = false;
                    if ( matched.length ) {
                        show = true;
                        if ( !showUnique ) {
                            const item = matched[ 0 ];
                            if ( chunk === itemName( item ) ) {
                                show = false;
                            }
                        }
                    } else if ( this.showNoMatch && /[\w\.\-@]+/.test( chunk ) ) {
                        show = true;
                    }

                    if ( show ) {
                        this.openPanel( matched, range, index, at );
                    } else {
                        this.closePanel();
                    }
                }
            }
        },

        closePanel() {
            if ( this.atwho ) {
                this.atwho = null;
            }
        },
        openPanel( list, range, offset, at ) {
            const fn = () => {
                const r = range.cloneRange();
                r.setStart( r.endContainer, offset + at.length );
                const rect = r.getClientRects()[ 0 ];
                this.atwho = {
                    range,
                    offset,
                    list,
                    x: rect.left,
                    y: rect.top - 4,
                    cur: 0
                };
            };
            if ( this.atwho ) {
                fn();
            } else {
                setTimeout( fn, 10 );
            }
        },

        scrollToCur() {
            if ( !this.$refs.cur ) {
                return;
            }
            const curEl = this.$refs.cur[ 0 ];
            if ( !curEl ) {
                return;
            }

            const scrollParent = curEl.parentElement.parentElement; // .mentionable-popup
            scrollIntoView( curEl, scrollParent );
        },
        selectByMouse( e ) {
            const el = closest( e.target, d => d.getAttribute( "data-index" ) );
            const cur = +el.getAttribute( "data-index" );
            this.atwho = {
                ...this.atwho,
                cur
            };
        },
        selectByKeyboard( e ) {
            const offset = e.keyCode === 38 ? -1 : 1;
            const { cur, list } = this.atwho;
            const nextCur = this.loop
                ? ( cur + offset + list.length ) % list.length
                : Math.max( 0, Math.min( cur + offset, list.length - 1 ) );
            this.atwho = {
                ...this.atwho,
                cur: nextCur
            };
        },

        insertText( text, r ) {
            r.deleteContents();
            const node = r.endContainer;
            if ( node.nodeType === Node.TEXT_NODE ) {
                const cut = r.endOffset;
                node.data = node.data.slice( 0, cut )
                    + text + node.data.slice( cut );
                r.setEnd( node, cut + text.length );
            } else {
                const t = document.createTextNode( text );
                r.insertNode( t );
                r.setEndAfter( t );
            }
            r.collapse( false ); // 参数在IE下必传
            applyRange( r );
            this.dispatchInput();
        },

        insertHtml( html, r ) {
            r.deleteContents();
            const node = r.endContainer;
            const newElement = document.createElement( "span" );

            // Seems `contentediable=false` should includes spaces,
            // otherwise, caret can't be placed well across them
            newElement.appendChild( document.createTextNode( " " ) );
            newElement.appendChild( this.htmlToElement( html ) );
            newElement.appendChild( document.createTextNode( " " ) );
            newElement.setAttribute( "data-at-embedded", "" );
            newElement.setAttribute( "contenteditable", false );

            if ( node.nodeType === Node.TEXT_NODE ) {
                const cut = r.endOffset;
                const secondPart = node.splitText( cut );
                node.parentNode.insertBefore( newElement, secondPart );
                r.setEndBefore( secondPart );
            } else {
                const t = document.createTextNode( suffix );
                r.insertNode( newElement );
                r.setEndAfter( newElement );
                r.insertNode( t );
                r.setEndAfter( t );
            }
            r.collapse( false ); // 参数在IE下必传
            applyRange( r );
        },

        insertItem() {
            const { range, offset, list, cur } = this.atwho;
            const { suffix, atItems, itemName, customsEmbedded } = this;
            const r = range.cloneRange();
            const text = range.toString();
            const { at, index } = getAtAndIndex( text, atItems );

            const curItem = list[ cur ];

            if ( !curItem ) {
                this.closePanel();
                return;
            }

            if ( !curItem ) {
                this.closePanel();
                return;
            }

            // Leading `@` is automatically dropped as `customsEmbedded=true`
            // You can fully custom the output inside the embedded slot
            const start = customsEmbedded ? index : index + at.length;
            r.setStart( r.endContainer, start );

            // hack: 连续两次 可以确保click后 focus回来 range真正生效
            applyRange( r );
            applyRange( r );

            if ( customsEmbedded ) {
                // `suffix` is ignored as `customsEmbedded=true` has to be
                // wrapped around by spaces
                const html = this.$refs.embeddedItem.firstChild.innerHTML;
                this.insertHtml( html, r );
            } else {
                const t = itemName( curItem ) + suffix;
                this.insertText( t, r );
            }

            this.$emit( "insert", curItem );

            this.handleInput();
        },

        insertAtCampaign() {
            const { range } = this.atwho;
            const { atItems } = this;
            const r = range.cloneRange();
            const text = range.toString();
            const { index } = getAtAndIndex( text, atItems );

            r.setStart( r.endContainer, index );

            applyRange( r );
            applyRange( r );

            const html = this.$refs.embeddedAtCampaign.firstChild.innerHTML;
            this.insertHtml( html, r );

            this.$emit( "insert", "campaign" );

            this.handleInput();
        },

        htmlToElement( html ) {
            const template = document.createElement( "template" );
            html = html.trim(); // Never return a text node of whitespace as the result
            template.innerHTML = html;
            return template.content.firstChild;
        }
    }
};
/* eslint-enable */
</script>

<style lang="scss">
@import "../../../sass/variables";

.mentionable {
    &-wrap {
        position: relative;
    }

    &-panel {
        position: absolute;
        font-size: $font-size-small;
    }

    &-inner {
        position: relative;
    }
}

.mentionable-list {
    list-style: none;
    max-height: 200px;
    overflow-y: auto;
    padding: 0;
    margin: 0;

    &-header {
        border-bottom: 1px solid $grey3;
        padding: $spacing-small 20px $spacing-small;
    }

    &-footer {
        border-top: 1px solid $grey3;
        padding: $spacing-small 20px $spacing-small;
    }
}

.mentionable-list-item {
    box-sizing: border-box;
    padding: 8px 20px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $grey1;

    span {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &--current {
        background: $grey1;
    }
}

.mentionable-popup {
    z-index: 11110 !important; //todo: check
    border-radius: 6px;
    box-shadow: 0 0 10px 0 rgba(101, 111, 122, 0.5);
    position: absolute;
    bottom: 0;
    left: -0.8em;
    cursor: default;
    background-color: $white;
    max-height: 300px;
    width: 335px;
    overflow-y: auto;
    white-space: normal;
    margin-right: 20px;

    &--wide {
        width: 450px;
    }

    &::-webkit-scrollbar {
        width: 11px;
        height: 11px;
    }

    &::-webkit-scrollbar-track {
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
        min-height: 36px;
        border: 2px solid transparent;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        background-clip: padding-box;
        border-radius: 7px;
        background-color: #c4c4c4;
    }
}

.mentionable-usage-description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px $spacing-large;
    text-align: center;
}

.mentionable-popup-wrapper {
    .ivu-poptip-body {
        padding: 0;
    }

    &--anchor {
        height: 0;
        padding: 0;
        margin: 0;
    }
}
</style>
