<template>
    <all-clear-svg class="all-clear" :style="style" />
</template>
<script>
import AllClearSvg from "@/assets/AllClear.svg";

export default {
    name: "AllClear",
    components: { AllClearSvg },
    props: {
        width: {
            default: 500,
            type: Number
        }
    },
    computed: {
        style() {
            return {
                width: this.width
            };
        }
    }
};
</script>

<style lang="scss">
.all-clear {
    .cls-1 {
        fill: #e8e8e8;
    }

    .cls-2 {
        opacity: 0.31;
    }

    .cls-3 {
        fill: #606060;
    }

    .cls-4 {
        fill: #dddcff;
    }

    .cls-5 {
        fill: #351ea2;
    }

    .cls-6 {
        fill: #fff;
    }

    .cls-7 {
        fill: #f2f3f4;
    }

    .cls-8 {
        fill: #9b34bf;
    }

    .cls-9 {
        fill: #201537;
    }

    .cls-10 {
        fill: #b1aef4;
    }

    .cls-11 {
        fill: #1e1084;
    }

    .cls-12 {
        fill: #c334bf;
    }

    .cls-13 {
        fill: #4a3ea3;
    }

    .cls-14 {
        fill: #27f5c7;
    }

    .cls-15 {
        fill: #f9444e;
    }
}
</style>
