<template>
    <div class="insights-dashboard-tag-component">
        <insights-data-provider
            :insights-type="dataProviderEndpoint"
            :advanced-search="tag.advancedSearch"
            :request-options="requestOptions"
            :refresh-counter="refreshCounter"
            :fetch-all="fetchAll"
            :sort-by="sortBy"
            @data="onData"
            @next="onNextData"
            @loading="setLoading"
            @fetchAllComplete="onFetchAllComplete"
            @error="onError"
        />

        <insights-dashboard-tag-layout
            :tag="tag"
            :is-loading="isLoading"
            :has-error="hasError"
            :error-message="errorMessage"
            @saveSettings="saveSettings"
            @retry="onRetry"
        >
            <template #contentBody>
                <component
                    :is="componentName"
                    v-if="normalisedData"
                    :data="normalisedData"
                    :max-height="maxChartHeight"
                    :tag="tag"
                    :type="preview"
                    :display-value="displayValue"
                />
            </template>
            <template #filters>
                <tag-component-filter-select @input="setLocalFilter" />
            </template>
            <template #settingsBody>
                <div class="local-chart-settings">
                    <label class="local-chart-settings__label">Chart type</label>
                    <Dropdown>
                        <a href="javascript:void(0)">
                            <span class="advanced-search__type-value link-like">{{ ChartTypeLabel[preview] }}</span>
                            <Icon type="ios-arrow-down"></Icon>
                        </a>
                        <DropdownMenu slot="list">
                            <DropdownItem @click.native="setLocalPreview(ChartType.BarChart)">
                                {{ ChartTypeLabel[ChartType.BarChart] }}
                            </DropdownItem>
                            <DropdownItem @click.native="setLocalPreview(ChartType.HorizontalBarChart)">
                                {{ ChartTypeLabel[ChartType.HorizontalBarChart] }}
                            </DropdownItem>
                            <DropdownItem @click.native="setLocalPreview(ChartType.DoughnutChart)">
                                {{ ChartTypeLabel[ChartType.DoughnutChart] }}
                            </DropdownItem>
                            <DropdownItem @click.native="setLocalPreview(ChartType.ScatterChart)">
                                {{ ChartTypeLabel[ChartType.ScatterChart] }}
                            </DropdownItem>
                            <DropdownItem @click.native="setLocalPreview(ChartType.WordCloudChart)">
                                {{ ChartTypeLabel[ChartType.WordCloudChart] }}
                            </DropdownItem>
                            <DropdownItem @click.native="setLocalPreview(ChartType.TreemapChart)">
                                {{ ChartTypeLabel[ChartType.TreemapChart] }}
                            </DropdownItem>
                            <DropdownItem @click.native="setLocalPreview(ChartType.PolarChart)">
                                {{ ChartTypeLabel[ChartType.PolarChart] }}
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <div class="local-chart-settings">
                    <label class="local-chart-settings__label">Endpoint</label>
                    <Dropdown>
                        <a href="javascript:void(0)">
                            <span class="advanced-search__type-value link-like">{{ localDataProviderEndpoint }}</span>
                            <Icon type="ios-arrow-down"></Icon>
                        </a>
                        <DropdownMenu slot="list">
                            <DropdownItem
                                v-for="endpoint in endpointSelectOptions"
                                :key="endpoint.value"
                                @click.native="setLocalEndpoint(endpoint)"
                            >
                                {{ endpoint.label }}
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <collapsible-tip v-if="localDataProviderEndpointOptions" class="local-chart-settings__advanced">
                    <template #name>Advanced options</template>
                    <label class="local-chart-settings__label">Endpoint options</label>
                    <Input
                        type="textarea"
                        :value="localDataProviderEndpointOptionsString"
                        :autosize="{ minRows: 3, maxRows: 11 }"
                        @input="setLocalEndpointOptions"
                    ></Input>
                </collapsible-tip>
            </template>
        </insights-dashboard-tag-layout>
    </div>
</template>
<script>
import InsightsDataProvider from "@/components/CreativeIntelligence/InsightsDataProvider";
import InsightsDashboardTagLayout from "@/views/CreativeInteligence/InsightsDashboardTagLayout";
import { InsightType, InsightTypeDefaultServiceOptions } from "@/components/CreativeIntelligence/constants";
import { deliverablesFromVideoGroups } from "@/utils/creativeInteligence";
import ChartPreview from "@/components/CreativeIntelligence/TagPreview/ChartPreview";
import TreemapChart from "@/components/CreativeIntelligence/TagPreview/TreemapChart";
import ScatterChart from "@/components/CreativeIntelligence/ScatterChart";
import TagComponentFilterSelect from "@/views/CreativeInteligence/TagComponentFilterSelect";
import { CreativeInsightsDashboardContent, getResourcePath } from "@/enums/creativeInteligence";
import { ChartDisplayValue, ChartType, ChartTypeLabel } from "@/utils/chart";
import CollapsibleTip from "@/components/Imagine/CollapsibleTip";

// make sure all components are correctly imported
const previewComponents = {
    [ChartType.BarChart]: "chartPreview",
    [ChartType.DoughnutChart]: "chartPreview",
    [ChartType.HorizontalBarChart]: "chartPreview",
    [ChartType.PolarChart]: "chartPreview",
    [ChartType.ScatterChart]: "scatterChart",
    [ChartType.TreemapChart]: "treemapChart",
    [ChartType.WordCloudChart]: "chartPreview"
};
const previewComponentsOptions = Object.keys(previewComponents);
const defaultPreview = ChartType.BarChart;

const LocalStorageTagSettingsKey = "creativeIntelligenceTagSettings";

export default {
    name: "DefaultInsightsDashboardTagComponent",
    components: {
        CollapsibleTip,
        ChartPreview,
        InsightsDashboardTagLayout,
        InsightsDataProvider,
        ScatterChart,
        TagComponentFilterSelect,
        TreemapChart
    },
    props: {
        dashboardContent: {
            type: String
        },

        tag: {
            type: Object
        }
    },

    data() {
        return {
            data: null,
            endpointSelectOptions: [
                { value: InsightType.AppearanceDurationAnalysis, label: InsightType.AppearanceDurationAnalysis },
                { value: InsightType.AppearancePositionAnalysis, label: InsightType.AppearancePositionAnalysis },
                { value: InsightType.AttributeMetadata, label: InsightType.AttributeMetadata },
                { value: InsightType.AttributeAdMetadata, label: InsightType.AttributeAdMetadata },
                { value: InsightType.AttributeValuesAnalysis, label: InsightType.AttributeValuesAnalysis },
                /* { value: InsightType.ColorAnalysis, label: InsightType.ColorAnalysis },
                { value: InsightType.KeyTakeaway, label: InsightType.KeyTakeaway },
                { value: InsightType.KeyTakeawayNotMatching, label: InsightType.KeyTakeawayNotMatching }, */
                { value: InsightType.PositionAnalysis, label: InsightType.PositionAnalysis },
                { value: InsightType.SizeAnalysis, label: InsightType.SizeAnalysis },
                { value: InsightType.Keywords, label: InsightType.Keywords }
            ],
            maxChartHeight: 380,
            refreshCounter: 0,
            normalisedData: null,
            fetchAll: false,
            hasError: false,
            errorMessage: "",
            isLoading: true,
            localEndpoint: null,
            localEndpointOptions: null,
            localFilter: null,
            localPreview: null,
            savedLocalEndpoint: null,
            savedLocalEndpointOptions: null,
            savedLocalFilter: null,
            savedLocalPreview: null
        };
    },

    computed: {
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        componentName() {
            if (previewComponentsOptions.includes(this.preview)) {
                return previewComponents[this.preview];
            }

            return previewComponents[defaultPreview];
        },

        dataProviderEndpoint() {
            return this.savedLocalEndpoint || this.tagEndpoint || InsightType.AttributeValuesAnalysis;
        },

        dataProviderEndpointOptions() {
            if (this.savedLocalEndpointOptions) {
                return this.savedLocalEndpointOptions;
            }

            if (this.tagEndpoint === this.dataProviderEndpoint && this.tagEndpointOptions) {
                return this.tagEndpointOptions;
            }

            return InsightTypeDefaultServiceOptions[this.dataProviderEndpoint];
        },

        displayValue() {
            // todo - enums
            return this.dashboardContent === CreativeInsightsDashboardContent.Utilisation
                ? ChartDisplayValue.Utilisation
                : ChartDisplayValue.KpiStat;
        },

        filterNames() {
            return Object.values(this.selectedBreakdowns);
        },

        localDataProviderEndpoint() {
            return this.localEndpoint || this.tagEndpoint || InsightType.AttributeValuesAnalysis;
        },

        localDataProviderEndpointOptions() {
            if (this.localEndpointOptions) {
                return this.localEndpointOptions;
            }

            if (this.tagEndpoint === this.localEndpoint && this.tagEndpointOptions) {
                return this.tagEndpointOptions;
            }

            return InsightTypeDefaultServiceOptions[this.localDataProviderEndpoint];
        },

        localDataProviderEndpointOptionsString() {
            if (!this.localDataProviderEndpointOptions) {
                return "";
            }

            return JSON.stringify(this.localDataProviderEndpointOptions, null, 2);
        },

        preview() {
            // todo add support for value change & local storage
            return this.localPreview || (this.tag.tagOptions && this.tag.tagOptions.defaultPreview) || defaultPreview;
        },

        requestOptions() {
            let topAttributesValuesNumber = localStorage.getItem("creativeIntelligenceTagResults") || 10;
            if (this.dashboardContent !== CreativeInsightsDashboardContent.Utilisation) {
                topAttributesValuesNumber = Math.ceil(topAttributesValuesNumber / 2);
            }

            const base = {
                topDeliverablesNumber: 1,
                topUniqueCreativesNumber: 3,
                topAttributesValuesNumber,
                ...(this.dataProviderEndpointOptions && { ...this.dataProviderEndpointOptions })
            };

            if (this.localFilter) {
                return {
                    ...base,
                    dynamicBreakdowns: this.localFilter
                };
            }
            return base;
        },

        resourcePath() {
            return getResourcePath(this.dataProviderEndpoint);
        },

        selectedBreakdowns() {
            return this.$store.state.creativeInsights.selectedDynamicBreakdowns;
        },

        sortBy() {
            const storeSortBy = this.$store.state.creativeInsights.sortBy;
            if (this.dashboardContent === CreativeInsightsDashboardContent.Utilisation) {
                return {
                    field: "utilisation",
                    order: "desc"
                };
            }
            if (this.dashboardContent === CreativeInsightsDashboardContent.Top) {
                return {
                    ...storeSortBy,
                    order: "desc"
                };
            }

            if (this.dashboardContent === CreativeInsightsDashboardContent.Bottom) {
                return {
                    ...storeSortBy,
                    order: "asc"
                };
            }

            return storeSortBy;
        },

        tagEndpoint() {
            return this.tag.tagOptions && this.tag.tagOptions.endpoint;
        },

        tagEndpointOptions() {
            return this.tag.tagOptions && this.tag.tagOptions.endpointOptions;
        }
    },

    created() {
        this.restoreSettingsFromLocalStorage();
        this.ChartType = ChartType;
        this.ChartTypeLabel = ChartTypeLabel;
        this.InsightTypeDefaultServiceOptions = InsightTypeDefaultServiceOptions;
    },

    methods: {
        getAttributesFromData(attributes) {
            return Object.keys(attributes).reduce((acc, group) => {
                acc[group] = {
                    ...attributes[group]
                };
                if (!attributes[group].deliverables && attributes[group].uniqueCreativesGroups) {
                    acc[group].deliverables = deliverablesFromVideoGroups(attributes[group].uniqueCreativesGroups);
                }

                return acc;
            }, {});
        },

        onData({ data }) {
            this.hasError = false;
            if (!data) {
                return;
            }
            this.data = data;
        },

        onError() {
            this.hasError = true;
            this.isLoading = false;
        },

        onFetchAllComplete() {
            this.isLoading = false;
            this.normalisedData = this.getAttributesFromData(this.data[this.resourcePath]);
            if (Object.keys(this.normalisedData).length === 0) {
                this.hasError = true;
                this.errorMessage = "This query has no data.";
            }
        },

        onNextData(response) {
            if (response) {
                this.data = {
                    [this.resourcePath]: {
                        ...this.data[this.resourcePath],
                        ...response.data[this.resourcePath]
                    }
                };
            }
        },

        onRetry() {
            this.refreshCounter += 1;
            this.hasError = false;
        },

        restoreSettingsFromLocalStorage() {
            const savedTagSettings = localStorage.getItem(LocalStorageTagSettingsKey) || "";
            let settings = {};

            if (savedTagSettings) {
                settings = JSON.parse(savedTagSettings);
            }

            if (settings[this.campaignId] && settings[this.campaignId][this.tag.tagId]) {
                this.localEndpoint = settings[this.campaignId][this.tag.tagId].localEndpoint;
                this.localPreview = settings[this.campaignId][this.tag.tagId].localPreview;
                // this.localFilter = settings[this.campaignId][this.tag.tagId].localFilter;
                this.localEndpointOptions = settings[this.campaignId][this.tag.tagId].localEndpointOptions;
                // this.savedLocalFilter = this.localFilter;
                this.savedLocalPreview = this.localPreview;
                this.savedLocalEndpoint = this.localEndpoint;
                this.savedLocalEndpointOptions = this.localEndpointOptions;
            }
        },

        saveSettings() {
            this.savedLocalFilter = this.localFilter;
            this.savedLocalPreview = this.localPreview;
            this.savedLocalEndpoint = this.localEndpoint;
            this.savedLocalEndpointOptions = this.localEndpointOptions;
            this.saveSettingsInLocalStorage();
        },

        saveSettingsInLocalStorage() {
            const savedTagSettings = localStorage.getItem(LocalStorageTagSettingsKey) || "";
            let settings = {};

            if (savedTagSettings) {
                settings = JSON.parse(savedTagSettings);
            }

            localStorage.setItem(
                LocalStorageTagSettingsKey,
                JSON.stringify({
                    ...settings,
                    [this.campaignId]: {
                        ...settings[this.campaignId],
                        [this.tag.tagId]: {
                            // localFilter: this.savedLocalFilter,
                            localPreview: this.savedLocalPreview,
                            localEndpoint: this.savedLocalEndpoint,
                            localEndpointOptions: this.savedLocalEndpointOptions
                        }
                    }
                })
            );
        },

        setLoading(val) {
            // we only care about loading start - the loading end is handled by onFetchAllComplete
            if (val) {
                this.isLoading = val;
            }
        },

        setLocalEndpoint({ value }) {
            this.localEndpointOptions = null;
            this.localEndpoint = value;
        },

        setLocalEndpointOptions(value) {
            try {
                const val = JSON.parse(value);
                this.localEndpointOptions = val;
            } catch (e) {
                console.log("Error when parsing options");
            }
        },

        setLocalFilter({ filter }) {
            this.localFilter = filter;
        },

        setLocalPreview(val) {
            this.localPreview = val;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.local-chart-settings {
    padding: 5px 0;

    &__label {
        margin-right: $spacing-small;
    }

    &__advanced {
        .ivu-collapse > .ivu-collapse-item > .ivu-collapse-header {
            line-height: 24px;
            height: 24px;
            padding-left: 0;
            font-weight: normal;
        }

        .ivu-collapse,
        .ivu-collapse > .ivu-collapse-item {
            border: 0 none;
        }

        .ivu-collapse > .ivu-collapse-item.ivu-collapse-item-active .ivu-collapse-header {
            background-color: transparent;
        }

        .ivu-collapse > .ivu-collapse-item > .ivu-collapse-header > i {
            margin-right: 0;
        }
    }
}
</style>
