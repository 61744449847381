<template>
    <div :class="classes" />
</template>

<script>
import { BannerQaStatus, BannerQaUserStatus } from "@/enums/banners";

export default {
    props: {
        status: {
            default: BannerQaStatus.Draft,
            required: true,
            validator(value) {
                return (
                    Object.values(BannerQaStatus).includes(value) || Object.values(BannerQaUserStatus).includes(value)
                );
            }
        }
    },
    computed: {
        classes() {
            const classes = ["qa-status-dot"];
            if (this.status === BannerQaStatus.Approved || this.status === BannerQaUserStatus.IApproved) {
                classes.push("qa-status-dot--approved");
            } else if (this.status === BannerQaStatus.InReview) {
                classes.push("qa-status-dot--in-review");
            } else if (this.status === BannerQaStatus.Rejected || this.status === BannerQaUserStatus.IRejected) {
                classes.push("qa-status-dot--rejected");
            } else {
                classes.push("qa-status-dot--draft");
            }
            return classes;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

$status-dot-size: 10px;

.qa-status-dot {
    display: inline-block;
    border-radius: 100%;
    height: $status-dot-size;
    width: $status-dot-size;
}

.qa-status-dot--approved {
    background: $success-color;
}

.qa-status-dot--in-review {
    background: $warning-color;
}

.qa-status-dot--rejected {
    background: $error-color;
}

.qa-status-dot--draft {
    background: $white;
    border: 1px solid $grey5;
}
</style>
