<template>
    <div class="chatgpt-preview imagine-preview">
        <imagine-data-provider
            v-if="startSearch"
            :key="searchOptions.searchId + '-' + refreshCounter"
            :options="requestOptions"
            :refresh-counter="refreshCounter"
            @data="onData"
            @loading="onLoading"
            @error="onError"
        />

        <hox-alert v-if="errorMessage" type="danger" size="small" margin-top="none" margin-bottom="none">
            <template #title>
                <span :title="errorMessage">
                    Sorry, the generated content was rejected by our automated content moderation system.
                </span>
            </template>
            <template #content></template>
            <template #actionItems>
                <Button type="primary" @click="forceRefetch">Try again</Button>
            </template>
        </hox-alert>
        <div v-else>
            <div v-if="showLeftArrow" class="imagine-carousel-arrow left" @click="switchPage(-1)">
                <Icon type="ios-arrow-back" size="24"></Icon>
            </div>
            <div v-if="isLoading" class="imagine-preview__result-loading" @click="onResultClick">
                <hox-loading-spinner :size="70" icon="wish-icon" />
            </div>
            <div v-else class="imagine-preview__result" @click="onResultClick">
                <div class="imagine-preview__overlay">
                    <div class="imagine-preview__headline imagine-preview__result-element">
                        <div class="chatgpt-preview__chat-response">
                            {{ chatResponse }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="showRightArrow" class="imagine-carousel-arrow right" @click="switchPage(1)">
                <Icon type="ios-arrow-forward" size="24"></Icon>
            </div>
        </div>
    </div>
</template>
<script>
import { ModelConfig, ImagineModel, sanitizeGPTParams } from "@/enums/imagine";
import ImagineDataProvider from "@/components/Imagine/ImagineDataProvider";
import { v4 as uuidv4 } from "uuid";

export default {
    name: "ChatgptPreview",
    components: { ImagineDataProvider },
    props: {
        searchOptions: {
            type: Object
        },

        startSearch: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            carouselImages: [],
            clicker: 0,
            data: null,
            errorMessage: "",
            imageCarouselItems: [],
            isLoading: true,
            page: 1,
            refreshCounter: 0,
            savedCreativeAiOptions: {},
            searchDuration: 0,
            searchEnd: null,
            searchStart: null,
            startImageSearch: true,
            useAdvancedOptions: true
        };
    },
    computed: {
        chatResponse() {
            return this.data?.output?.success?.choices[0]?.text;
        },

        requestOptions() {
            const requestOptions = ModelConfig[this.searchOptions.model].fieldsToRequest(
                this.searchOptions,
                this.refreshCounter
            );
            if (this.useAdvancedOptions && this.savedCreativeAiOptions) {
                requestOptions.input = { ...requestOptions.input, ...this.savedCreativeAiOptions };
            }

            requestOptions.input.prompt = `${this.searchOptions.prompt} ${
                this.searchOptions.row ? this.searchOptions.row : ""
            } ${this.searchOptions.column ? this.searchOptions.column : ""}`.replace("  ", " ");

            requestOptions.input = sanitizeGPTParams(requestOptions.input);

            return requestOptions;
        },

        promptText() {
            if (this.useAdvancedOptions && this.savedCreativeAiOptions) {
                return this.savedCreativeAiOptions.text;
            }
            return this.searchOptions.text;
        },

        showLeftArrow() {
            if (this.isLoading) {
                return false;
            }

            if (this.errorMessage !== "") {
                return false;
            }

            return this.page > 1;
        },

        showRightArrow() {
            if (this.isLoading) {
                return false;
            }

            if (this.errorMessage !== "") {
                return false;
            }

            return true;
        }
    },

    created() {
        this.savedCreativeAiOptions = { ...this.$store.state.imagine.advancedOptions[ImagineModel.ChatGPT] };
        this.useAdvancedOptions = this.$store.state.imagine.useAdvancedOptions;
    },

    methods: {
        onData(response) {
            // todo rename this and gpt3 to represent what id does + refresh counter is also updated on error
            if (!this.imageCarouselItems[this.refreshCounter]) {
                this.imageCarouselItems[this.refreshCounter] = response;
            }
            if (response.output.fail) {
                this.errorMessage = response.output.fail;
                return;
            }
            this.data = response;
            this.$emit("complete");
        },

        onError({ message }) {
            this.errorMessage = message.errorMessage;
            if (typeof message === "string") {
                this.errorMessage = message;
            }
            this.isLoading = false;
            this.$emit("complete");
        },

        onLoading(loading) {
            this.isLoading = loading;
        },

        onResultClick() {
            this.clicker += 1;
        },

        forceRefetch() {
            this.errorMessage = "";
            this.refreshCounter += 1;
        },

        switchPage(dir) {
            if (this.page + dir < 1) {
                return;
            }
            this.page = this.page + dir;
            this.startImageSearch = true;
            if (this.carouselImages[this.page - 1]) {
                this.startImageSearch = false;
            }

            if (dir === 1) {
                if (this.imageCarouselItems[this.page - 1]) {
                    this.data = this.imageCarouselItems[this.page - 1];
                    return;
                }

                this.refreshCounter += 1;
                this.startImageSearch = true;
                this.requestOptions.input.uuid = uuidv4();
                return;
            }
            this.data = this.imageCarouselItems[this.page - 1];
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
.chatgpt-preview {
    &__chat-response {
        padding: $spacing;
        width: 100%;
        height: 100%;
        overflow: auto;
        white-space: pre-line;
    }
    .imagine-preview__overlay {
        .imagine-preview__result-element {
            padding: 0;
            text-align: left;
        }
    }
}
</style>
