<template>
    <div class="notifications-drawer" data-testid="notifications-drawer">
        <Drawer placement="left" :transfer="false" :value="isVisible" width="400px" @on-close="close">
            <div class="notifications-drawer__header">
                <hox-tabs color="tertiary" size="small">
                    <hox-tabs-item is-active>Notifications</hox-tabs-item>
                </hox-tabs>
            </div>
            <div class="notifications-drawer__content">
                <hox-alert v-if="hasErrorLoadingMyJobsByStatus" margin-bottom="none" size="small" type="danger">
                    <template #title>
                        There was an unexpected error while attempting to get your background jobs.
                    </template>
                    <template #content>
                        <p>Hopefully it is just a temporary issue and it should work if you retry in a few moments.</p>
                    </template>
                    <template #actionItems>
                        <Button :loading="isLoadingMyJobsByStatus > 0" type="primary" @click="runMyJobsByStatusQuery">
                            Retry
                        </Button>
                    </template>
                </hox-alert>
                <div v-else-if="notifications.length === 0" class="notifications-drawer__empty-message-wrapper">
                    <hox-empty-message type="all-clear">
                        <template #title>There are no notifications</template>
                    </hox-empty-message>
                </div>
                <div v-for="notification of notifications" :key="notification.id">
                    <flashtalking-download-job-notification-item
                        v-if="
                            notification.type === NotificationTypes.FlashtalkingDownloadExcel ||
                            notification.type === NotificationTypes.FlashtalkingDownloadAssets
                        "
                        :notification="notification"
                    />
                    <download-notification-item
                        v-if="notification.type === NotificationTypes.Download"
                        :notification="notification"
                    />
                    <download-job-notification-item
                        v-else-if="notification.type === NotificationTypes.DownloadJob"
                        :notification="notification"
                    />
                    <publish-notification-item
                        v-else-if="
                            notification.type === NotificationTypes.Publish ||
                            notification.type === NotificationTypes.Unpublish
                        "
                        :notification="notification"
                    />
                    <generate-report-notification-item
                        v-else-if="notification.type === NotificationTypes.GenerateReport"
                        :notification="notification"
                    />

                    <background-update-notification-item
                        v-else-if="notification.type === NotificationTypes.BackgroundUpdate"
                        :notification="notification"
                    />
                </div>
            </div>
            <div
                v-if="notifications.length > 0"
                class="notifications-drawer__footer"
                @click="clearItemsNotPendingOrInProgress"
            >
                Clear Completed
            </div>
        </Drawer>
    </div>
</template>

<script>
import jobsByIdQuery from "@/apollo/queries/v2/JobsById.gql";
import myJobsByStatusQuery from "@/apollo/queries/v2/MyJobsByStatus.gql";
import DownloadNotificationItem from "@/components/Notifications/DownloadNotificationItem";
import DownloadJobNotificationItem from "@/components/Notifications/DownloadJobNotificationItem";
import PublishNotificationItem from "@/components/Notifications/PublishNotificationItem";
import GenerateReportNotificationItem from "@/components/Notifications/GenerateReportNotificationItem";
import BackgroundUpdateNotificationItem from "@/components/Notifications/BackgroundUpdateNotificationItem";
import FlashtalkingDownloadJobNotificationItem from "@/components/Notifications/FlashtalkingDownloadJobNotificationItem";
import { JobStatus, JobStatusToNotificationStatus, JobNames } from "@/enums/jobs";
import { NotificationTypes } from "@/enums/notifications";
import { JobsAction } from "@/store/modules/jobs";
import { NotificationsAction } from "@/store/modules/notifications";

export default {
    components: {
        BackgroundUpdateNotificationItem,
        DownloadNotificationItem,
        DownloadJobNotificationItem,
        GenerateReportNotificationItem,
        PublishNotificationItem,
        FlashtalkingDownloadJobNotificationItem
    },

    data() {
        return {
            hasErrorLoadingMyJobsByStatus: false,
            isLoadingMyJobsByStatus: 0
        };
    },

    computed: {
        isAuthorized() {
            return this.$store.state.auth.isAuthorized;
        },

        isVisible() {
            return this.$store.state.notifications.drawerIsVisible;
        },

        jobIdsToQuery() {
            return Object.keys(this.$store.state.jobs.notificationIdsByActiveJobId);
        },

        notifications() {
            return this.$store.state.notifications.items;
        }
    },

    watch: {
        $route() {
            this.close();
        }
    },

    created() {
        this.NotificationTypes = NotificationTypes;
    },

    methods: {
        clearItemsNotPendingOrInProgress() {
            this.$store.dispatch(NotificationsAction.ClearItemsNotPendingOrInProgress);
        },

        close() {
            this.$store.dispatch(NotificationsAction.SetDrawerIsVisible, false);
        },

        runMyJobsByStatusQuery() {
            this.$apollo.queries.myJobsByStatus.refetch();
        },

        getSnackBarMessageByJob(job) {
            const jobResultMessages = {
                [JobNames.UnpublishXmlFeed]: {
                    success:
                        "The ads has been removed from feeds. You can find the updated feeds in your notifications",
                    error: job.message
                },
                [JobNames.PublishXmlFeed]: {
                    success:
                        "The ads has been published to feeds. You can find the updated feeds in your notifications",
                    error: job.message
                },
                [JobNames.AddMetadataValue]: {
                    success: "The ads has been updated",
                    error: job.message
                },
                [JobNames.RemoveMetadataValue]: {
                    success: "The ads has been updated",
                    error: job.message
                }
            };
            if (jobResultMessages[job.name]) {
                return jobResultMessages[job.name];
            }
            return {
                success: "Your ads are ready to be downloaded. You can find a download link in your notifications",
                error: "There was an unexpected error while preparing ads for download"
            };
        },
        getNotificationTypeByJob(job) {
            const jobNotificationMap = {
                [JobNames.UnpublishXmlFeed]: NotificationTypes.Unpublish,
                [JobNames.PublishXmlFeed]: NotificationTypes.Publish,
                [JobNames.AddMetadataValue]: NotificationTypes.BackgroundUpdate,
                [JobNames.RemoveMetadataValue]: NotificationTypes.BackgroundUpdate,
                [JobNames.PUBFT_DOWNLOAD_EXCEL]: NotificationTypes.FlashtalkingDownloadExcel,
                [JobNames.PUBFT_DOWNLOAD_ASSETS]: NotificationTypes.FlashtalkingDownloadAssets
            };
            return jobNotificationMap[job.name] || NotificationTypes.DownloadJob;
        }
    },

    apollo: {
        myJobsByStatus: {
            query: myJobsByStatusQuery,
            fetchPolicy: "no-cache",
            loadingKey: "isLoadingMyJobsByStatus",
            skip() {
                return !this.isAuthorized;
            },
            variables() {
                return {
                    status: [JobStatus.PENDING, JobStatus.IN_PROGRESS]
                };
            },
            async result({ data, error }) {
                if (error) {
                    this.hasErrorLoadingMyJobsByStatus = true;
                    return;
                }
                this.hasErrorLoadingMyJobsByStatus = false;
                const notificationIdsWithJobIds = await Promise.all(
                    data.myJobsByStatus.map(async job => {
                        /*
                            In theory we should never have a notification for any job at this point.
                            However, vue-apollo is an interesting beast that occasionally does unexpected
                            things so we're going to err on the side of caution and do a check so that we
                            do not end up with multiple notifications per job.
                        */
                        let notificationId = this.$store.state.jobs.notificationIdsByActiveJobId[job._id];
                        if (notificationId === undefined) {
                            notificationId = await this.$store.dispatch(NotificationsAction.Add, {
                                message: job.message,
                                status: JobStatusToNotificationStatus[job.status],
                                type: this.getNotificationTypeByJob(job),
                                job
                            });
                        }
                        return {
                            jobId: job._id,
                            notificationId
                        };
                    })
                );
                notificationIdsWithJobIds.forEach(notificationIdWithJobId => {
                    this.$store.dispatch(JobsAction.SetNotificationIdByActiveJobId, notificationIdWithJobId);
                });
            }
        },

        jobs: {
            query: jobsByIdQuery,
            fetchPolicy: "no-cache",
            pollInterval: 5000,
            skip() {
                return this.jobIdsToQuery.length === 0 || !this.isAuthorized;
            },
            variables() {
                return {
                    jobIds: this.jobIdsToQuery
                };
            },
            result({ data, error }) {
                if (error) {
                    /*
                        We don't need to do any error handling here as the request should retry
                        again in a short amount of time due to the pollInterval.
                    */
                    return;
                }
                data.jobs.forEach(job => {
                    const notificationId = this.$store.state.jobs.notificationIdsByActiveJobId[job._id];
                    this.$store.dispatch(NotificationsAction.Update, {
                        downloadLink: job.payload.zipUrl,
                        id: notificationId,
                        message: job.message,
                        status: JobStatusToNotificationStatus[job.status],
                        job
                    });
                    if (job.status !== JobStatus.PENDING && job.status !== JobStatus.IN_PROGRESS) {
                        const message = this.getSnackBarMessageByJob(job);
                        if (job.status === JobStatus.FAILED || job.status === JobStatus.ABORTED) {
                            this.$snackbar.error(message.error);
                        } else {
                            this.$snackbar.success(message.success);
                        }
                        /*
                            If the job is no longer in progress or pending then we no longer want
                            to poll it for an updated status, so we remove the job reference from the
                            object that we're using to track active jobs.
                        */
                        this.$store.dispatch(JobsAction.DeleteNotificationIdByActiveJobId, job);
                    }
                });
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.notifications-drawer {
    .ivu-drawer-mask,
    .ivu-drawer-wrap {
        z-index: $zindex-notifications-drawer;
    }

    .ivu-drawer-body {
        display: flex;
        flex-direction: column;
        padding: 0;
        height: 100%;
    }

    .ivu-drawer-content {
        box-sizing: content-box;
        border-left: $campaign-vertical-nav-width solid $cmp-light-bg-color;
    }
}

.notifications-drawer__content {
    border-bottom: 1px solid $cmp-light-border-color;
    flex: 1;
    overflow: auto;
}

.notifications-drawer__empty-message-wrapper {
    padding: $spacing-large;
}

.notifications-drawer__footer {
    background: $white;
    border-bottom: $border-width-thicker solid $primary-color;
    cursor: pointer;
    flex: 0;
    font-size: $font-size-base;
    font-weight: bold;
    padding: $spacing-semi-small 0;
    transition: background 0.2s ease-in-out;
    text-align: center;
    text-transform: uppercase;

    &:hover {
        background: $grey2;
    }
}

.notifications-drawer__header {
    border-bottom: $border-width-base solid $cmp-light-border-color;
    padding: $spacing-large 0 0 0;
}
</style>
