import { BarElement } from "chart.js";
import { addRoundedRectPath } from "chart.js/helpers";

export class OffsetBar extends BarElement {
    draw(ctx) {
        const {
            options: { backgroundColor }
        } = this;

        ctx.save();

        ctx.beginPath();
        addRoundedRectPath(ctx, {
            x: this.x,
            y: this.y,
            w: this.width,
            h: this.height,
            radius: {
                topLeft: 0,
                topRight: 0,
                bottomLeft: 0,
                bottomRight: 0
            }
        });
        ctx.fillStyle = backgroundColor;
        ctx.fill();

        ctx.restore();
    }
}
OffsetBar.id = "OffsetBar";
