export const Platform = {
    DoubleClick: "doubleclick",
    DoubleClickSV: "doubleclick_sv",
    Flashtalking: "flashtalking",
    FlashtalkingExcel: "flashtalking_excel",
    FlashtalkingAssets: "flashtalking_assets",
    Sizmek: "sizmek",
    SizmekSV: "sizmek_sv",
    TikTok: "tiktok",
    Facebook: "facebook",
    OpenDC: "opendc"
};

export const PlatformLabel = {
    [Platform.DoubleClick]: "Google",
    [Platform.DoubleClickSV]: "Google Studio Dynamic",
    [Platform.Flashtalking]: "Flashtalking",
    [Platform.FlashtalkingExcel]: "Flashtalking Excel",
    [Platform.FlashtalkingAssets]: "Flashtalking Assets",
    [Platform.Sizmek]: "Sizmek",
    [Platform.SizmekSV]: "Sizmek Dynamic",
    [Platform.TikTok]: "TikTok Ads Manager",
    [Platform.Facebook]: "Meta Ads Manager",
    [Platform.OpenDC]: "Choreograph"
};

export const PlatformPublishedStatusFilter = {
    All: "all",
    NotPublished: "n",
    NotPublishedToDoubleClick: "npdc",
    NotPublishedToFlashtalking: "npf",
    NotPublishedToSizmek: "nps",
    NotPublishedToOpenDC: "npodc",
    Published: "p",
    PublishedToDoubleClick: "pdc",
    PublishedToFlashtalking: "pf",
    PublishedToSizmek: "ps",
    PublishedToOpenDC: "podc"
};

export const PsdDownloads = {
    Image: "image",
    ImageNoScale: "image_no_scale",
    ImageJpeg: "image_jpeg",
    ImageJpegNoScale: "image_jpeg_no_scale",
    Psd: "psd"
};

export const BatchDownloads = {
    ...PsdDownloads,
    ...Platform,
    Generic: "generic",
    ImageJpeg: "image_jpeg",
    ImageJpegNoScale: "image_jpeg_no_scale",
    Video: "video"
};

export const ZipDownloads = {
    ...Platform,
    Generic: "generic"
};

export const PlatformPublishedStatusFilterLabel = {
    [PlatformPublishedStatusFilter.All]: "All",
    [PlatformPublishedStatusFilter.NotPublished]: "Not published",
    [PlatformPublishedStatusFilter.NotPublishedToDoubleClick]: "Not published on DoubleClick",
    [PlatformPublishedStatusFilter.NotPublishedToFlashtalking]: "Not published on Flashtalking",
    [PlatformPublishedStatusFilter.NotPublishedToSizmek]: "Not published on Sizmek",
    [PlatformPublishedStatusFilter.NotPublishedToOpenDC]: "Not published on OpenDC",
    [PlatformPublishedStatusFilter.Published]: "Published",
    [PlatformPublishedStatusFilter.PublishedToDoubleClick]: "Published on DoubleClick",
    [PlatformPublishedStatusFilter.PublishedToFlashtalking]: "Published on Flashtalking",
    [PlatformPublishedStatusFilter.PublishedToSizmek]: "Published on Sizmek",
    [PlatformPublishedStatusFilter.PublishedToOpenDC]: "Published on OpenDC"
};

export const PublishedStatusFilterPlatform = {
    All: "ALL",
    DoubleClick: "DOUBLECLICK",
    OpenDC: "OPENDC",
    Flashtalking: "FLASHTALKING",
    Sizmek: "SIZMEK"
};
