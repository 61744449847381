import { camelToUpperCase } from "@/utils";
import { InsightType } from "@/components/CreativeIntelligence/constants";

const BreakdownBreakdownValueSeparator = "#$#";
const BreakdownsSeparator = "__$$__";

const CreativeInsightsMode = {
    BarReport: "BarReport",
    InsightsReport: "InsightsReport",
    QuadrantReport: "QuadrantReport"
};

const CreativeInsightsModeLabel = {
    [CreativeInsightsMode.BarReport]: "Bar Report",
    [CreativeInsightsMode.InsightsReport]: "Insights Report",
    [CreativeInsightsMode.QuadrantReport]: "Quadrant Report"
};

const CreativeInsightsContentFilter = {
    Everything: "Everything",
    Content: "Content",
    Context: "Context",
    Position: "Position"
};

const CreativeInsightsDashboardContent = {
    Utilisation: "utilisation",
    Top: "top",
    Bottom: "bottom",
    BestPractice: "bestPractice",
    DataExplorer: "dataExplorer"
};

const CreativeIntelligenceMetric = {
    AddToCartValue: "add_to_cart_value",
    AddToCart: "add_to_cart",
    AddToCartRate: "add_to_cart_rate",
    AddToCartValueRate: "add_to_cart_value_rate",
    ClickRate: "clicks_rate",
    Clicks: "clicks",
    ConversionRate: "conversion_rate",
    Impressions: "impressions",
    Purchase: "purchase",
    PurchaseRate: "purchase_rate",
    PurchaseValue: "purchase_value",
    PurchaseValueRate: "purchase_value_rate",
    VideoP100: "video_p100",
    VideoP100Rate: "video_p100_rate",
    VideoP25: "video_p25",
    VideoP25Rate: "video_p25_rate",
    VideoP50: "video_p50",
    VideoP50Rate: "video_p50_rate",
    VideoP75: "video_p75",
    VideoP75Rate: "video_p75_rate",
    VideoP95: "video_p95",
    VideoP95Rate: "video_p95_rate",
    VideoStarted: "video_started",
    VideoStartedRate: "video_started_rate"
};

const CreativeIntelligenceMetricLabels = {
    [CreativeIntelligenceMetric.ClickRate]: "Clickthrough Rate",
    [CreativeIntelligenceMetric.ConversionRate]: "Conversion Rate",
    [CreativeIntelligenceMetric.AddToCartRate]: "Add to cart rate",
    [CreativeIntelligenceMetric.AddToCartValueRate]: "Add to cart value rate",
    [CreativeIntelligenceMetric.PurchaseRate]: "Purchase Rate",
    [CreativeIntelligenceMetric.PurchaseValueRate]: "Purchase Value Rate",
    [CreativeIntelligenceMetric.VideoP100Rate]: "100% Viewthrough Rate",
    [CreativeIntelligenceMetric.VideoP25Rate]: "25% Viewthrough Rate",
    [CreativeIntelligenceMetric.VideoP50Rate]: "50% Viewthrough Rate",
    [CreativeIntelligenceMetric.VideoP75Rate]: "75% Viewthrough Rate",
    [CreativeIntelligenceMetric.VideoP95Rate]: "95% Viewthrough Rate",
    [CreativeIntelligenceMetric.VideoStartedRate]: "View Rate",
    [CreativeIntelligenceMetric.Impressions]: "Impressions",
    [CreativeIntelligenceMetric.Clicks]: "Clicks",
    [CreativeIntelligenceMetric.VideoStarted]: "Views",
    [CreativeIntelligenceMetric.VideoP25]: "25% Viewthrough",
    [CreativeIntelligenceMetric.VideoP50]: "50% Viewthrough",
    [CreativeIntelligenceMetric.VideoP75]: "75% Viewthrough",
    [CreativeIntelligenceMetric.VideoP95]: "95% Viewthrough",
    [CreativeIntelligenceMetric.VideoP100]: "100% Viewthrough",
    [CreativeIntelligenceMetric.AddToCart]: "Add to Cart",
    [CreativeIntelligenceMetric.AddToCartValue]: "Add to Cart Value",
    [CreativeIntelligenceMetric.Purchase]: "Purchase",
    [CreativeIntelligenceMetric.PurchaseValue]: "Purchase Value"
};

const getMetricsLabel = metric => {
    if (CreativeIntelligenceMetricLabels[metric]) {
        return CreativeIntelligenceMetricLabels[metric];
    }

    const result = metric.replace(/([A-Z])/g, " $1");
    const label = result.charAt(0).toUpperCase() + result.slice(1);

    return label.replace("_rate", " Rate");
};

const Breakdown = {
    NoBreakdowns: "no-breakdowns"
};

const BreakdownFriendlyNames = {
    [Breakdown.NoBreakdowns]: "Overall",
    facebook__feed: "Facebook Feed",
    facebook__instream_video: "Facebook Instream Video",
    facebook__facebook_stories: "Facebook Story",
    instagram__feed: "Instagram Feed",
    instagram__instagram_stories: "Instagram Story",
    instagram__instagram_explore: "Instagram Explore",
    overallKpiAverage: "Overall",
    unknown__unknown: "Unknown",
    ipad: "iPad",
    iphone: "iPhone"
};

const getBreakdownLabel = breakdown => {
    const autoName = breakdown
        .split(BreakdownsSeparator)
        .map(word => {
            const [, value] = word.split(BreakdownBreakdownValueSeparator);
            return value;
        })
        .join(" ");
    return BreakdownFriendlyNames[breakdown] ? BreakdownFriendlyNames[breakdown] : autoName;
};

const getBreakdownTitle = breakdownName =>
    camelToUpperCase(breakdownName.replace("adMetadata.", "").replace("breakdowns.", ""));

const getResourcePath = insightsType => {
    const groupEndpoints = [
        InsightType.Keywords,
        InsightType.SizeAnalysis,
        InsightType.AttributeMetadata,
        InsightType.AttributeAdMetadata,
        InsightType.AppearanceDurationAnalysis,
        InsightType.AppearancePositionAnalysis
    ];

    if (groupEndpoints.includes(insightsType)) {
        return "groups";
    }

    if (insightsType === InsightType.PositionAnalysis) {
        return "positions";
    }

    return "attributes";
};
const ChartColors = [
    "#58AFFF",
    "#0074D9",
    "#7FDBFF",
    "#39CCCC",
    "#3D9970",
    "#2ECC40",
    "#01FF70",
    "#FFDC00",
    "#FF851B",
    "#FF4136",
    "#F012BE",
    "#B10DC9",
    "#85144B",
    "#BBBBBB",
    "#AAAAAA",
    "#333333"
];

export {
    Breakdown,
    BreakdownBreakdownValueSeparator,
    BreakdownFriendlyNames,
    BreakdownsSeparator,
    ChartColors,
    CreativeInsightsContentFilter,
    CreativeInsightsDashboardContent,
    CreativeInsightsMode,
    CreativeInsightsModeLabel,
    CreativeIntelligenceMetric,
    CreativeIntelligenceMetricLabels,
    getBreakdownLabel,
    getBreakdownTitle,
    getMetricsLabel,
    getResourcePath
};
