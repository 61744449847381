<template>
    <div class="qa-drawer-sidebar" :class="{ 'qa-drawer-sidebar__no-deliverable': !deliverable }">
        <div class="qa-drawer-sidebar__header">
            <h3 v-if="userCanListInternalAnnotations" class="annotations-count">
                {{ internalAnnotations.length + externalAnnotations.length }} Annotations
            </h3>
            <div class="show-resolved-control">
                <labelled-switch :value="showResolved" size="small" type="info" @change="onChange">
                    <span class="link-like">Show Resolved</span>
                </labelled-switch>
            </div>
        </div>
        <div v-if="deliverable" class="qa-drawer-sidebar__content">
            <hox-alert v-if="hasErrorLoadingSelectedDeliverableData" size="small" type="danger">
                <template #title>
                    "Sorry, We could not load your annotations this time. Try again in a moment."
                </template>
                <template #actionItems>
                    <Button type="primary" @click="loadSelectedDeliverableData">Load annotations</Button>
                </template>
            </hox-alert>
            <template v-else-if="isLoadingSelectedDeliverableData && isInitialLoadOfSelectedDeliverableData">
                <Spin fix />
            </template>
            <template v-else>
                <qa-annotations
                    v-if="userCanListInternalAnnotations"
                    :annotations="internalAnnotations"
                    :annotation-type="AnnotationType.Internal"
                    :selected-annotation="selectedAnnotation"
                    :show-resolved="showResolved"
                    :users-with-status="userWithStatusByUserType.internal"
                    @annotationDeleted="removeAnnotation"
                    @annotationSelected="selectAnnotation"
                    @annotationUpdated="replaceAnnotation"
                    @annotationsPositionFromTopOfDocument="scrollAnnotationIntoView"
                >
                    <template #titleContent>Internal ({{ internalAnnotations.length }})</template>
                    <template #emptyMessageContent>
                        <p v-if="!internalAnnotations.length">There are currently no internal annotations.</p>
                        <p v-if="internalAnnotations.length && !showResolved">
                            There are no unresolved internal annotations.
                        </p>
                        <p v-if="$auth.userCan($auth.Actions.CanManageInternalAnnotations, { clientId, campaignId })">
                            Click anywhere on the selected banner to add one.
                        </p>
                    </template>
                </qa-annotations>
                <qa-annotations
                    v-if="userCanListExternalAnnotations"
                    :annotations="externalAnnotations"
                    :annotation-type="AnnotationType.External"
                    :selected-annotation="selectedAnnotation"
                    :show-resolved="showResolved"
                    :users-with-status="userWithStatusByUserType.external"
                    @annotationDeleted="removeAnnotation"
                    @annotationSelected="selectAnnotation"
                    @annotationUpdated="replaceAnnotation"
                    @annotationsPositionFromTopOfDocument="scrollAnnotationIntoView"
                >
                    <template #titleContent>{{ externalAnnotationsLabel }} ({{ externalAnnotations.length }})</template>
                    <template #emptyMessageContent>
                        <p>{{ externalAnnotationsEmptyMessage }}</p>
                        <p
                            v-if="
                                !$auth.userCan($auth.Actions.CanManageInternalAnnotations, { clientId, campaignId }) &&
                                $auth.userCan($auth.Actions.CanManageExternalAnnotations, { clientId, campaignId })
                            "
                        >
                            Click anywhere on the selected banner to add one.
                        </p>
                    </template>
                </qa-annotations>
            </template>
        </div>
        <div v-else class="qa-drawer-sidebar__content">
            <div class="qa-annotations__no-annotations-message">
                <p>Select an ad to show annotations</p>
            </div>
        </div>
        <transition name="fade">
            <div
                v-if="isLoadingSelectedDeliverableData && !isInitialLoadOfSelectedDeliverableData"
                class="qa-drawer-sidebar__loading-message"
            >
                <Spin />
                Fetching updated annotations&hellip;
            </div>
        </transition>
    </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { SuperAdminRole } from "shared-utils/enums/user";
import QaAnnotations from "@/components/Qa/QaAnnotations";
import HoxAlert from "@/components/common/Alert";
import { AnnotationType } from "@/enums/annotations";
import LabelledSwitch from "@/components/Campaign/LabelledSwitch";
import { DeliverableLibraryGetters } from "@/store/modules/deliverableLibrary";

export default {
    components: {
        LabelledSwitch,
        HoxAlert,
        QaAnnotations
    },
    props: {
        showResolved: {
            type: Boolean,
            default: false
        },

        // eslint-disable-next-line vue/require-prop-types
        deliverable: {
            required: true
        },
        externalAnnotations: {
            type: Array
        },
        hasErrorLoadingSelectedDeliverableData: {
            type: Boolean
        },
        internalAnnotations: {
            type: Array
        },
        isLoadingSelectedDeliverableData: {
            type: Boolean
        },
        selectedAnnotation: {
            validator(value) {
                return value === null || value._id;
            }
        }
    },

    data() {
        return {
            isInitialLoadOfSelectedDeliverableData: true
        };
    },

    computed: {
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        deliverableReportingLabel() {
            return this.deliverable.reportingLabel;
        },
        deliverableStatus() {
            return this.$store.getters[DeliverableLibraryGetters.computedDeliverableStatus](this.deliverable.idHash);
        },
        deliverableUserStatus() {
            const deliverableStatus =
                this.$store.state.deliverableLibrary.deliverableStatusByIdHash[this.deliverable.idHash];
            if (deliverableStatus && deliverableStatus.users) {
                return deliverableStatus.users;
            }
            return [];
        },
        externalAnnotationsEmptyMessage() {
            return this.userCanListExternalAnnotations && !this.userCanListInternalAnnotations
                ? "No annotations."
                : "No external annotations.";
        },
        externalAnnotationsLabel() {
            return this.userCanListExternalAnnotations && !this.userCanListInternalAnnotations
                ? "Annotations"
                : "External";
        },
        userCanListExternalAnnotations() {
            return this.$auth.userCan(this.$auth.Actions.CanListExternalAnnotations);
        },
        userCanListInternalAnnotations() {
            return this.$auth.userCan(this.$auth.Actions.CanListInternalAnnotations);
        },
        userWithStatusByUserType() {
            return this.deliverableUserStatus.reduce(
                (userWithStatusByUserType, userStatus) => {
                    let userType = "external";
                    if (
                        userStatus.user.role.name === SuperAdminRole ||
                        userStatus.user.role.actions.includes(this.$auth.Actions.CanManageInternalAnnotations)
                    ) {
                        userType = "internal";
                    }
                    userWithStatusByUserType[userType].push(userStatus);
                    return userWithStatusByUserType;
                },
                {
                    external: [],
                    internal: []
                }
            );
        }
    },
    watch: {
        deliverable() {
            this.isInitialLoadOfSelectedDeliverableData = true;
        },
        isLoadingSelectedDeliverableData() {
            if (this.hasErrorLoadingSelectedDeliverableData) {
                this.isInitialLoadOfSelectedDeliverableData = true;
            } else if (!this.isLoadingSelectedDeliverableData) {
                this.isInitialLoadOfSelectedDeliverableData = false;
            }
        }
    },

    created() {
        this.AnnotationType = AnnotationType;
    },

    methods: {
        animatedScrollTo(scrollingElement, to, duration) {
            /*
              Taken from: https://gist.github.com/andjosh/6764939
            */
            const start = scrollingElement.scrollTop;
            const change = to - start;
            const startDate = +new Date();
            const easeInOutQuad = (t, b, c, d) => {
                let time = t / d / 2;
                if (time < 1) {
                    return (c / 2) * time * time + b;
                }
                time -= 1;
                return (-c / 2) * (time * (time - 2) - 1) + b;
            };
            const animateScroll = () => {
                const currentDate = +new Date();
                const currentTime = currentDate - startDate;
                // eslint-disable-next-line no-param-reassign
                scrollingElement.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration), 10);
                if (currentTime < duration) {
                    window.requestAnimationFrame(animateScroll);
                } else {
                    // eslint-disable-next-line no-param-reassign
                    scrollingElement.scrollTop = to;
                }
            };
            animateScroll();
        },
        emitAnnotationsPositionFromTopOfDocument(annotationsPositionFromTopOfDocument) {
            this.$emit("annotationsPositionFromTopOfDocument", annotationsPositionFromTopOfDocument);
        },
        loadSelectedDeliverableData() {
            this.$emit("loadSelectedDeliverableData");
        },

        onChange(val) {
            this.$emit("resolvedShown", val);
        },

        removeAnnotation(annotation) {
            this.$emit("annotationDeleted", annotation);
        },
        replaceAnnotation(annotation) {
            this.$emit("annotationUpdated", annotation);
        },
        scrollAnnotationIntoView(annotationsPositionFromTopOfDocument) {
            const offsetFromTopOfAnnotation = 8;
            const annotationPositionFromTopOfSidebar =
                annotationsPositionFromTopOfDocument - this.$el.getBoundingClientRect().top;
            let scrollTop;
            if (annotationPositionFromTopOfSidebar < 0) {
                scrollTop = this.$el.scrollTop + annotationPositionFromTopOfSidebar;
            } else if (annotationPositionFromTopOfSidebar > 0) {
                scrollTop = annotationPositionFromTopOfSidebar + this.$el.scrollTop;
            }
            this.animatedScrollTo(this.$el, scrollTop - offsetFromTopOfAnnotation, 300);
        },
        selectAnnotation(annotation) {
            this.$emit("annotationSelected", annotation);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.qa-drawer-sidebar {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: $spacing 0 0 0;
    height: 100%;
    width: 100%;
}

.qa-drawer-sidebar__no-deliverable {
    .qa-annotations__no-annotations-message {
        margin: 8px;
    }
}

.qa-drawer-sidebar__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 10px;

    .ivu-switch {
        margin-left: 5px;
    }

    .show-resolved-control {
        margin-left: auto;
    }
}

.qa-drawer-sidebar__content {
    flex: 1;
    position: relative;
}

.qa-drawer-sidebar__loading-message {
    background: $white;
    bottom: $spacing-small;
    box-shadow: 0 1px 16px rgba(0, 0, 0, 0.2);
    font-size: $font-size-small;
    left: $spacing-small;
    padding: $spacing;
    position: absolute;
    right: $spacing-small;

    .ivu-spin {
        display: inline-block;
    }
}

.qa-drawer-sidebar__status-container {
    border-bottom: 1px solid $grey3;
    padding: 0 $spacing $spacing;
}

.qa-drawer-sidebar__title {
    font-size: $font-size-larger;
    margin: 0 $spacing $spacing-smaller;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
