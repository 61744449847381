<template>
    <hox-modal v-if="isVisible" wrapper-class="account-signal__modal" @close="close">
        <template #header>
            <div class="account-signal__header">
                {{ modalTitle }}
                <span>{{ actionText }}</span>
            </div>
        </template>
        <div class="account-signal__modal-wrap">
            <h3>I want a signal based on</h3>
            <div class="account-signal__list">
                <basic-account-signal-form
                    v-for="(signal, signalIndex) in editedData.signals"
                    :key="signalIndex + '-' + deleteCounter"
                    :signal-index="signalIndex"
                    :rule-index="ruleIndex"
                    :signal-type="signal.signalType"
                    :account-index="accountIndex"
                    :signal-action="signalAction"
                    :form-data="signal"
                    @accountSignalAction="onHandleUpdateSignal"
                    @disableSaveButton="isSaveButtonActive = false"
                    @deleteSignal="onDeleteSignal"
                />
            </div>
            <input type="button" class="account-signal__add-new" value="+ Add condition" @click="addNewSignal" />
        </div>
        <Button
            type="primary"
            class="account-signal__save-button"
            data-testid="account-signal__save-button"
            :disabled="!isSaveButtonActive"
            @click="onSubmitAccountSignal"
        >
            {{ actionLabel }}
        </Button>
    </hox-modal>
</template>
<script>
import BasicAccountSignalForm from "@/components/IntelligentDelivery/BasicAccountSignalForm";

export default {
    name: "AccountSignalFormModal",
    components: {
        BasicAccountSignalForm
    },
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        signalAction: {
            type: String,
            default: "add"
        },
        accountIndex: {
            type: Number,
            default: 0
        },
        ruleIndex: {
            type: Number,
            default: 0
        },
        signalIndex: {
            type: Number,
            default: 0
        },
        formData: {
            type: Object
        }
    },
    data() {
        return {
            deleteCounter: 0,
            isSaveButtonActive: true,
            currentSignalType: this.formData.signalType,
            editedData: JSON.parse(JSON.stringify(this.formData))
        };
    },
    computed: {
        actionText() {
            if (this.signalAction === "edit") {
                return "Update your signal details.";
            }
            return "Create a signal to define how and when your campaign should be activated.";
        },
        modalTitle() {
            if (this.signalAction === "edit") {
                return "Edit your signals";
            }
            return "Add new signal";
        },
        actionLabel() {
            if (this.signalAction === "edit") {
                return "Save";
            }
            return "Add signal";
        },
        signalTypeComponent() {
            if (this.formData.signalType === "WEATHER") {
                return "WeatherSignalForm";
            }
            return "DatetimeSignalForm";
        }
    },

    methods: {
        close() {
            this.$emit("close");
        },

        onHandleAddSignal(data) {
            this.editedData = data;
        },

        onHandleUpdateSignal(data) {
            this.isSaveButtonActive = true;
            this.editedData.accountIndex = this.accountIndex;
            this.editedData.ruleIndex = this.ruleIndex;
            this.editedData.signalAction = this.signalAction;
            this.editedData.signals[data.signalIndex].signalType = data.formData.signalType;
            this.editedData.signals[data.signalIndex].value = data.formData.value;
            this.editedData.signals[data.signalIndex].locations = data.formData.locations;
            if (!data.formData.order) {
                this.editedData.signals[data.signalIndex].order = data.signalIndex;
            }
        },

        onSubmitAccountSignal() {
            if (this.editedData) {
                if (this.editedData.signals.length === 1 && this.editedData.signals[0].signalType === undefined) {
                    this.editedData.signals = [];
                }
                this.editedData.signals = this.editedData.signals.filter(item => item.signalType);
                this.editedData.accountIndex = this.accountIndex;
                this.editedData.ruleIndex = this.ruleIndex;
                this.editedData.signalAction = this.signalAction;
                this.$emit("accountSignalAction", this.editedData);
            }
        },

        addNewSignal() {
            this.editedData.signals.push({});
        },

        onDeleteSignal(index) {
            this.deleteCounter += 1;
            this.isSaveButtonActive = true;
            this.editedData.signals.splice(index, 1);
            if (this.editedData.signals.length === 0) {
                this.editedData.signals.push({});
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.account-signal {
    &__modal {
        &-wrap {
            padding: 20px;
            border-radius: 6px;
            margin-bottom: 10px;
            background: var(--wpp-grey-color-100);
        }
        h3 {
            margin-bottom: 12px;
        }
        .hox-modal {
            width: 820px;
            &__body {
                margin: 0 20px;
                padding: 0 0 20px;
            }
        }
    }
    &__header {
        span {
            display: block;
            font-size: 13px;
            font-weight: normal;
            margin-top: 10px;
        }
        .ivu-date-picker {
            width: 48%;
            margin-right: 4%;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    &__wrap {
        display: flex;
    }

    &__tabs {
        border-bottom: 2px solid var(--wpp-grey-color-400);
        margin-bottom: 20px;
        span {
            display: inline-block;
            padding: 5px 10px;
            margin-bottom: -2px;
            border-bottom: 2px solid var(--wpp-grey-color-400);
            cursor: pointer;
            &.active {
                border-bottom: 2px solid $primary-color;
            }
        }
    }

    &__add-new {
        color: var(--wpp-primary-color-500);
        border: none;
        background: none;
        font-weight: 600;
        margin-top: 20px;
        cursor: pointer;
        position: relative;
    }

    &__save-button {
        margin-left: auto;
    }

    &__tabs {
        border-bottom: 2px solid var(--wpp-grey-color-400);
        margin-bottom: 20px;
        span {
            display: inline-block;
            padding: 5px 10px;
            margin-bottom: -2px;
            border-bottom: 2px solid var(--wpp-grey-color-400);
            cursor: pointer;
            &.active {
                border-bottom: 2px solid $primary-color;
            }
        }
    }

    &__add-new {
        color: var(--wpp-primary-color-500);
        border: none;
        background: none;
        font-weight: 600;
        margin-top: 20px;
        cursor: pointer;
        position: relative;
    }

    .ivu-form-item {
        display: flex;
    }
}
</style>
