const languagesList = [
    {
        value: "af",
        label: "Afrikaans"
    },
    {
        value: "sq",
        label: "Albanian"
    },
    {
        value: "ar-dz",
        label: "Arabic (Algeria)"
    },
    {
        value: "ar-bh",
        label: "Arabic (Bahrain)"
    },
    {
        value: "ar-eg",
        label: "Arabic (Egypt)"
    },
    {
        value: "ar-iq",
        label: "Arabic (Iraq)"
    },
    {
        value: "ar-jo",
        label: "Arabic (Jordan)"
    },
    {
        value: "ar-kw",
        label: "Arabic (Kuwait)"
    },
    {
        value: "ar-lb",
        label: "Arabic (Lebanon)"
    },
    {
        value: "ar-ly",
        label: "Arabic (Libya)"
    },
    {
        value: "ar-ma",
        label: "Arabic (Morocco)"
    },
    {
        value: "ar-om",
        label: "Arabic (Oman)"
    },
    {
        value: "ar-qa",
        label: "Arabic (Qatar)"
    },
    {
        value: "ar-sa",
        label: "Arabic (Saudi Arabia)"
    },
    {
        value: "ar-sy",
        label: "Arabic (Syria)"
    },
    {
        value: "ar-tn",
        label: "Arabic (Tunisia)"
    },
    {
        value: "ar-ae",
        label: "Arabic (U.A.E.)"
    },
    {
        value: "ar-ye",
        label: "Arabic (Yemen)"
    },
    {
        value: "eu",
        label: "Basque"
    },
    {
        value: "be",
        label: "Belarusian"
    },
    {
        value: "bg",
        label: "Bulgarian"
    },
    {
        value: "ca",
        label: "Catalan"
    },
    {
        value: "zh-hk",
        label: "Chinese (Hong Kong)"
    },
    {
        value: "zh-cn",
        label: "Chinese (PRC)"
    },
    {
        value: "zh-sg",
        label: "Chinese (Singapore)"
    },
    {
        value: "zh-tw",
        label: "Chinese (Taiwan)"
    },
    {
        value: "hr",
        label: "Croatian"
    },
    {
        value: "cs",
        label: "Czech"
    },
    {
        value: "da",
        label: "Danish"
    },
    {
        value: "nl-be",
        label: "Dutch (Belgium)"
    },
    {
        value: "nl",
        label: "Dutch (Standard)"
    },
    {
        value: "en",
        label: "English"
    },
    {
        value: "en-au",
        label: "English (Australia)"
    },
    {
        value: "en-bz",
        label: "English (Belize)"
    },
    {
        value: "en-ca",
        label: "English (Canada)"
    },
    {
        value: "en-cn",
        label: "English (China)"
    },
    {
        value: "en-hk",
        label: "English (Hong Kong)"
    },
    {
        value: "en-ie",
        label: "English (Ireland)"
    },
    {
        value: "en-jm",
        label: "English (Jamaica)"
    },
    {
        value: "en-kr",
        label: "English (Korea)"
    },
    {
        value: "en-my",
        label: "English (Malaysia)"
    },
    {
        value: "en-nz",
        label: "English (New Zealand)"
    },
    {
        value: "en-ph",
        label: "English (Philippines)"
    },
    {
        value: "en-sg",
        label: "English (Singapore)"
    },
    {
        value: "en-za",
        label: "English (South Africa)"
    },
    {
        value: "en-tw",
        label: "English (Taiwan)"
    },
    {
        value: "en-th",
        label: "English (Thailand)"
    },
    {
        value: "en-tt",
        label: "English (Trinidad)"
    },
    {
        value: "en-ae",
        label: "English (U.A.E.)"
    },
    {
        value: "en-gb",
        label: "English (United Kingdom)"
    },
    {
        value: "en-us",
        label: "English (United States)"
    },
    {
        value: "et",
        label: "Estonian"
    },
    {
        value: "fo",
        label: "Faeroese"
    },
    {
        value: "fa",
        label: "Farsi"
    },
    {
        value: "fi",
        label: "Finnish"
    },
    {
        value: "fr-be",
        label: "French (Belgium)"
    },
    {
        value: "fr-ca",
        label: "French (Canada)"
    },
    {
        value: "fr-lu",
        label: "French (Luxembourg)"
    },
    {
        value: "fr",
        label: "French (Standard)"
    },
    {
        value: "fr-ch",
        label: "French (Switzerland)"
    },
    {
        value: "gd",
        label: "Gaelic (Scotland)"
    },
    {
        value: "de-at",
        label: "German (Austria)"
    },
    {
        value: "de-li",
        label: "German (Liechtenstein)"
    },
    {
        value: "de-lu",
        label: "German (Luxembourg)"
    },
    {
        value: "de",
        label: "German (Standard)"
    },
    {
        value: "de-ch",
        label: "German (Switzerland)"
    },
    {
        value: "el",
        label: "Greek"
    },
    {
        value: "he",
        label: "Hebrew"
    },
    {
        value: "hi",
        label: "Hindi"
    },
    {
        value: "hu",
        label: "Hungarian"
    },
    {
        value: "is",
        label: "Icelandic"
    },
    {
        value: "id",
        label: "Indonesian"
    },
    {
        value: "ga",
        label: "Irish"
    },
    {
        value: "it",
        label: "Italian (Standard)"
    },
    {
        value: "it-ch",
        label: "Italian (Switzerland)"
    },
    {
        value: "ja",
        label: "Japanese"
    },
    {
        value: "ko",
        label: "Korean"
    },
    {
        value: "ko(J)",
        label: "Korean (Johab)"
    },
    {
        value: "ku",
        label: "Kurdish"
    },
    {
        value: "kz",
        label: "Kazakhstan"
    },
    {
        value: "lv",
        label: "Latvian"
    },
    {
        value: "lt",
        label: "Lithuanian"
    },
    {
        value: "me",
        label: "Montenegrin"
    },
    {
        value: "mk",
        label: "Macedonian (FYROM)"
    },
    {
        value: "ml",
        label: "Malayalam"
    },
    {
        value: "ms",
        label: "Malaysian"
    },
    {
        value: "mt",
        label: "Maltese"
    },
    {
        value: "no",
        label: "Norwegian"
    },
    {
        value: "nb",
        label: "Norwegian (Bokmål)"
    },
    {
        value: "nn",
        label: "Norwegian (Nynorsk)"
    },
    {
        value: "pl",
        label: "Polish"
    },
    {
        value: "pt-br",
        label: "Portuguese (Brazil)"
    },
    {
        value: "pt",
        label: "Portuguese (Portugal)"
    },
    {
        value: "pa",
        label: "Punjabi"
    },
    {
        value: "rm",
        label: "Rhaeto-Romanic"
    },
    {
        value: "ro",
        label: "Romanian"
    },
    {
        value: "ro-md",
        label: "Romanian (Republic of Moldova)"
    },
    {
        value: "ru",
        label: "Russian"
    },
    {
        value: "ru-md",
        label: "Russian (Republic of Moldova)"
    },
    {
        value: "sr",
        label: "Serbian"
    },
    {
        value: "sk",
        label: "Slovak"
    },
    {
        value: "sl",
        label: "Slovenian"
    },
    {
        value: "sb",
        label: "Sorbian"
    },
    {
        value: "es-ar",
        label: "Spanish (Argentina)"
    },
    {
        value: "es-bo",
        label: "Spanish (Bolivia)"
    },
    {
        value: "es-cl",
        label: "Spanish (Chile)"
    },
    {
        value: "es-co",
        label: "Spanish (Colombia)"
    },
    {
        value: "es-cr",
        label: "Spanish (Costa Rica)"
    },
    {
        value: "es-do",
        label: "Spanish (Dominican Republic)"
    },
    {
        value: "es-ec",
        label: "Spanish (Ecuador)"
    },
    {
        value: "es-sv",
        label: "Spanish (El Salvador)"
    },
    {
        value: "es-gt",
        label: "Spanish (Guatemala)"
    },
    {
        value: "es-hn",
        label: "Spanish (Honduras)"
    },
    {
        value: "es-mx",
        label: "Spanish (Mexico)"
    },
    {
        value: "es-ni",
        label: "Spanish (Nicaragua)"
    },
    {
        value: "es-pa",
        label: "Spanish (Panama)"
    },
    {
        value: "es-py",
        label: "Spanish (Paraguay)"
    },
    {
        value: "es-pe",
        label: "Spanish (Peru)"
    },
    {
        value: "es-pr",
        label: "Spanish (Puerto Rico)"
    },
    {
        value: "es",
        label: "Spanish (Spain)"
    },
    {
        value: "es-uy",
        label: "Spanish (Uruguay)"
    },
    {
        value: "es-ve",
        label: "Spanish (Venezuela)"
    },
    {
        value: "sv",
        label: "Swedish"
    },
    {
        value: "sv-fi",
        label: "Swedish (Finland)"
    },
    {
        value: "ta",
        label: "Tamil"
    },
    {
        value: "th",
        label: "Thai"
    },
    {
        value: "ts",
        label: "Tsonga"
    },
    {
        value: "tn",
        label: "Tswana"
    },
    {
        value: "tr",
        label: "Turkish"
    },
    {
        value: "uk",
        label: "Ukrainian"
    },
    {
        value: "ur",
        label: "Urdu"
    },
    {
        value: "uz",
        label: "Uzbek"
    },
    {
        value: "ve",
        label: "Venda"
    },
    {
        value: "vi",
        label: "Vietnamese"
    },
    {
        value: "cy",
        label: "Welsh"
    },
    {
        value: "xh",
        label: "Xhosa"
    },
    {
        value: "ji",
        label: "Yiddish"
    },
    {
        value: "zu",
        label: "Zulu"
    }
];

export default languagesList;
