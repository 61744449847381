<template>
    <hox-modal class="marketplace-modal" wrapper-class="insights-marketplace" @close="onClose">
        <template #header>Insights Marketplace</template>
        <template #tabs>
            <div class="marketplace__navigation">
                <Select v-model="activeTab" class="marketplace__navigation-select">
                    <Option v-for="type in marketplaceTypes" :key="type" :value="type">{{ type }} Insights</Option>
                </Select>
            </div>
        </template>
        <tags-stats-data-provider :tags="items" @data="onData" />
        <hox-loading-layer v-if="isSaving" :is-full-screen="false" />
        <div class="marketplace-modal__body">
            <div v-for="category in categoryItems" :key="category.name" class="marketplace-category--wrap">
                <div class="marketplace-category">{{ category.name }}</div>
                <div
                    v-for="item in category.items"
                    :key="item.tagId"
                    class="marketplace-item"
                    :class="{ disabled: isTagDisabled[item.tagId] }"
                >
                    <div v-if="item.new" class="marketplace-new-badge"><img src="/img/badge-new.png" /></div>
                    <div class="marketplace-item__header">
                        <h4>
                            {{ item.name }}
                        </h4>
                        <kpi-stats v-if="tagStats[item.tagId]" :stats="tagStats[item.tagId]" />
                    </div>
                    <p>{{ item.marketplaceDescription }}</p>
                    <div class="marketplace-item__footer">
                        <div
                            v-if="inClientTags(item)"
                            class="marketplace-item__footer-action marketplace-item__footer-action--added"
                        >
                            <Icon type="md-add-circle" />
                            Added
                        </div>
                        <div
                            v-else
                            class="marketplace-item__footer-action marketplace-item__footer-action--add"
                            @click="copyTag(item)"
                        >
                            <Icon type="md-add-circle" />
                            Add Insight
                        </div>

                        <div v-if="item.marketplaceIcon" class="marketplace-item__provider">
                            <img :src="item.marketplaceIcon" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </hox-modal>
</template>
<script>
import HoxModal from "@/components/Modal/Modal/Modal";
import { marketplaceItems } from "@/components/CreativeIntelligence/marketplaceItems";
import { TagsAction } from "@/store/modules/ciTags";
import TagsStatsDataProvider from "@/components/CreativeIntelligence/TagsStatsDataProvider";
import KpiStats from "@/components/CreativeIntelligence/KpiStats";

const Tab = {
    Annotations: "annotations",
    Predictions: "predictions"
};

export default {
    name: "InsightsMarketplaceModal",
    components: { KpiStats, TagsStatsDataProvider, HoxModal },
    props: {
        isOpen: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            modalWidth: 1000,
            activeTab: Tab.Annotations,
            items: marketplaceItems,
            tagStats: {},
            isTagDisabled: {}
        };
    },

    computed: {
        sourceTagIds() {
            return this.tags.map(({ sourceTag }) => sourceTag);
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        isSaving() {
            return this.$store.state.ciTags.isCreating;
        },

        marketplaceItemsByType() {
            return this.items.reduce((acc, tag) => {
                acc[tag.marketplaceType] = acc[tag.marketplaceType] || [];
                acc[tag.marketplaceType].push(tag);

                return acc;
            }, {});
        },

        marketplaceTypes() {
            return Object.keys(this.marketplaceItemsByType);
        },

        tags() {
            return this.$store.state.ciTags.tags;
        },

        categoryItems() {
            const categoryItems = {};
            this.marketplaceItemsByType[this.activeTab].forEach(item => {
                const { category } = item;
                categoryItems[category] = categoryItems[category] ?? { name: category, items: [] };
                categoryItems[category].items.push(item);
            });

            const itemsArray = Object.values(categoryItems).sort((a, b) => a.name.localeCompare(b.name));
            return itemsArray;
        }
    },

    created() {
        this.Tab = Tab;
    },

    methods: {
        async copyTag(tag) {
            try {
                await this.$store.dispatch(TagsAction.CreateTag, {
                    clientId: this.clientId,
                    ...(tag.displayTemplates && { displayTemplates: tag.displayTemplates }),
                    ...(tag.tagOptions && { tagOptions: tag.tagOptions }),
                    name: tag.name,
                    query: tag.advancedSearch,
                    sourceTag: tag.tagId,
                    category: tag.category || ""
                });

                this.$snackbar.success("Marketplace tag has been successfully added");
                await this.$store.dispatch(TagsAction.GetTags, this.clientId);
            } catch (e) {
                console.error("Marketplace copy error", e);

                this.validationErrors = [e.message];
                this.hasError = true;
            }
        },

        inClientTags({ tagId }) {
            return this.sourceTagIds.includes(tagId);
        },

        onClose() {
            this.$emit("update:isOpen", false);
        },

        onData({ data }) {
            this.tagStats = data;
            Object.keys(this.tagStats).forEach(key => {
                this.isTagDisabled[key] = this.tagStats[key].confidenceMetricStats.count === 0;
            });
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";
.marketplace__navigation {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-select {
        margin-right: $spacing;
        width: 170px;

        .ivu-select-item,
        .ivu-select-selected-value {
            text-transform: capitalize;
        }
    }
}

.marketplace-modal__body {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.marketplace-new-badge {
    position: absolute;
    right: -10px;
    height: 32px;
    top: -10px;

    img {
        width: 100%;
        height: 100%;
        image-rendering: -webkit-optimize-contrast;
    }
}

.marketplace-item {
    padding: $spacing-semi-large;
    width: 33.33333333%;
    flex: 0 0 33.33333333%;
    height: 160px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border-bottom: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;
    position: relative;

    &.disabled {
        pointer-events: none;
        .marketplace-item__header,
        .marketplace-item__footer,
        p {
            opacity: 0.3;
        }
    }

    &:last-child(3n) {
        border-right: 0 none;
    }

    &__header {
        display: flex;
        height: 24px;
        line-height: 24px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    h4 {
        color: $ci-font-color;
        flex: 1 1;
        font-size: 14px;
        line-height: 24px;
        //white-space: nowrap;
    }

    p {
        flex: 1 1;
        padding: $spacing-small 0;
    }

    &__footer-action {
        &--added {
            color: #2ed23e;
        }

        &--add {
            color: $ci-blue;
            cursor: pointer;
        }
    }

    &__provider {
        position: absolute;
        bottom: $spacing-small;
        right: $spacing-small;
        max-width: 100px;

        img {
            width: 100%;
            height: 100%;
            image-rendering: -webkit-optimize-contrast;
        }
    }
}
.marketplace-category {
    width: 100%;
    flex: auto;
    text-transform: uppercase;
    font-size: 12px;
    padding: $spacing-small;
    background: $grey1;
    &--wrap {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
    }
}
.insights-marketplace .hox-modal {
    width: 1000px;
    height: 90vh;

    .hox-modal__header {
        margin-bottom: 0;
    }

    .hox-modal__body {
        padding: 0;
        height: calc(90vh - 67px);
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .hox-modal__header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: $spacing-larger;
        padding-left: $spacing-semi-large;
    }
}
</style>
