<template>
    <collapsible-widget
        content-padding="none"
        :is-opened="commentsAreVisible"
        size="small"
        @input="setCommentsAreVisible"
    >
        <span slot="name">Comments ({{ comments.length }})</span>
        <div v-if="!hasComments" class="qa-annotation-comments__no-comments-wrapper">
            <!--
        Ideally we wouldn't have the wrapper, but there seems
        to be a grey block that gets rendered if we have a top
        margin on `qa-annotation-comments__no-comments-message`.
      -->
            <div class="qa-annotation-comments__no-comments-message">
                <p>There are currently no comments on this annotation.</p>
            </div>
        </div>
        <div v-else class="qa-annotation-comments__comments">
            <qa-single-annotation-comment
                v-for="comment of comments"
                :key="comment._id"
                :annotation-is-resolved="annotationIsResolved"
                :comment="comment"
                @commentDeleted="removeComment"
                @commentUpdated="replaceComment"
            />
        </div>
    </collapsible-widget>
</template>

<script>
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";
import QaSingleAnnotationComment from "@/components/Qa/QaSingleAnnotationComment";

export default {
    components: {
        CollapsibleWidget,
        QaSingleAnnotationComment
    },
    props: {
        annotationIsResolved: {
            required: true,
            type: Boolean
        },
        comments: {
            required: true,
            type: Array
        },
        commentsAreVisible: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        hasComments() {
            return this.comments.length > 0;
        }
    },
    methods: {
        removeComment(comment) {
            this.$emit("commentDeleted", comment);
        },
        replaceComment(comment) {
            this.$emit("commentUpdated", comment);
        },
        setCommentsAreVisible(commentsAreVisible) {
            this.$emit("setCommentsAreVisible", commentsAreVisible);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.qa-annotation-comments__comments {
    padding: 0 $spacing 0 0;
}

.qa-annotation-comments__no-comments-message {
    border: 1px dashed $grey3;
    color: $grey5;
    margin: 0 $spacing $spacing-small 0;
    padding: $spacing;

    > p {
        font-size: $font-size-small;
    }
}

.qa-annotation-comments__no-comments-wrapper {
    padding: $spacing-small 0 0;
}
</style>
