<template>
    <div id="user-menu" class="user-menu">
        <hox-loading-layer v-if="isLoggingOut" is-full-screen :theme="loggingOutLoaderTheme" />
        <Poptip v-model="isPoptipActive" placement="left" class="user-menu__avatar-poptip">
            <hox-main-nav-button
                :is-active="isPoptipActive"
                margin-bottom="none"
                :show-tooltip="false"
                data-testid="top-bar__user-menu-button"
            >
                <div class="user-menu__avatar">
                    <div class="user-menu__avatar-icon">{{ userInitials }}</div>
                </div>
                <template #tooltipContent>Your profile and settings</template>
            </hox-main-nav-button>
            <template #content>
                <slot name="poptipContent">
                    <div class="user-menu__actions" data-testid="top-bar__settings-button" @click="openSettingsModal">
                        <Icon type="md-cog" />
                        <span class="user-menu-option--settings">Settings</span>
                    </div>
                    <div class="user-menu__actions" data-testid="top-bar__theme-button" @click="setCurrentTheme">
                        <Icon :type="currentTheme.icon" />
                        <span class="user-menu-option--theme-change">{{ currentTheme.label }}</span>
                    </div>
                    <div class="user-menu__actions" data-testid="top-bar__logout-button" @click="logout">
                        <Icon type="md-log-out" />
                        <span class="user-menu-option--logout">Logout</span>
                    </div>
                </slot>
            </template>
        </Poptip>

        <manage-user-modal v-if="isSettingsModalOpen" @close="isSettingsModalOpen = false" />
    </div>
</template>
<script>
import ManageUserModal from "@/components/ManageUsers/ManageUserModal";
import { UiAction } from "@/store/modules/ui";
import { DefaultUser } from "@/enums/users";

export default {
    name: "UserMenu",
    components: { ManageUserModal },
    data() {
        return {
            isLoggingOut: false,
            isPoptipActive: false,
            isSettingsModalOpen: false,
            userName: DefaultUser
        };
    },

    computed: {
        loggingOutLoaderTheme() {
            return this.$store.state.ui.theme === "campaign-dark" ? "dark" : "light";
        },

        userInitials() {
            return this.userName
                .split(" ")
                .map(name => name[0].toUpperCase())
                .join("");
        },

        currentTheme: {
            get() {
                return this.$store.state.ui.themeMode;
            },

            set(val) {
                this.$store.dispatch(UiAction.SetThemeMode, val);
            }
        }
    },

    async created() {
        this.userName = await this.$auth.userDisplayName();
        const savedTheme = JSON.parse(localStorage.getItem("currentTheme"));
        if (savedTheme) {
            this.currentTheme = savedTheme;
        }
    },

    methods: {
        async logout() {
            this.isLoggingOut = true;
            this.isPoptipActive = false;
            try {
                /*
                    If false the window will redirect to coginito so a manual redirect
                    is not required to flush application state.
                */
                if (await this.$auth.logout(this.$store.state.auth.authConfig)) {
                    /*
                        We hard redirect (refresh the page) on logout to ensure that
                        all application state is cleared.

                        Why? So that we completely avoid any issues that could be
                        caused by state persisting between logged in users.

                        We don't use this.$router.go() as we don't want to redirect
                        the user back to the current page when logging in: this could
                        result in a torrent of errors if the newly logged in user
                        does not have access to the same scopes.
                    */
                    window.location = "/login";
                }
            } catch (err) {
                this.isLoggingOut = false;
                this.$snackbar.error(
                    "There was an unexpected error while logging out. Please try again in a few moments"
                );
            }
            /*
                We don't set `this.isLoggingOut = false` in a finally block as it causes
                a moment where the loading layer is not displayed before the user is
                redirected, which looks bad: as they are being redirected the
                loading layer will not persist anyway.
            */
        },

        openSettingsModal() {
            this.isPoptipActive = false;
            this.isSettingsModalOpen = true;
        },

        setCurrentTheme() {
            if (this.currentTheme.theme === "light") {
                this.currentTheme = {
                    theme: "dark",
                    icon: "md-sunny",
                    label: "Light mode"
                };
            } else {
                this.currentTheme = {
                    theme: "light",
                    icon: "md-moon",
                    label: "Dark mode"
                };
            }
        }
    }
};
</script>

<style lang="scss">
@import "../sass/variables";

.user-menu {
    margin: 0 0 $spacing-base;

    &__avatar {
        flex: 1 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        white-space: nowrap;
        color: $cmp-light-font-color;

        &-icon {
            text-align: center;
            border-radius: 50%;
            width: 30px;
            color: $darkgrey3;
            height: 30px;
            line-height: 26px;
            font-size: 12px;
            vertical-align: center;
            background-image: linear-gradient(90deg, #00fff0 0%, #00d1ff 100%);
            border: 2px solid $white;
        }

        &-poptip .ivu-poptip-body {
            color: $cmp-light-font-color;
        }
    }

    &__actions {
        cursor: pointer;
        padding: 10px 5px;

        &:hover {
            color: $cmp-light-active;
        }

        > i {
            padding-right: 5px;
        }
    }
}
</style>
