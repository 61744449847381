<template>
    <div class="deliverable-value-item lockable-row" :class="{ 'lockable-row--locked': isLocked }">
        <div class="lockable-row__padlock">
            <Icon v-if="isLocked" class="lockable-row__padlock--click-tracked" type="ios-lock" @click="unlock" />
            <Icon v-if="!isLocked" type="ios-unlock-outline" />
        </div>
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "LockableRow",
    props: {
        locked: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            isLocked: false
        };
    },

    watch: {
        locked() {
            this.updateLock(this.locked);
        }
    },

    created() {
        this.updateLock(this.locked);
    },

    methods: {
        unlock() {
            this.$emit("unlock");
        },

        updateLock(lock) {
            this.isLocked = lock;
            this.$emit("on-lock", this.isLocked);
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.lockable-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex: 1 0;
    border-bottom: none;

    &--locked {
        color: $cmp-dark-disabled-color;
    }

    &__padlock {
        text-align: center;
        color: $cmp-dark-font-color;
        border-right: 1px solid $cmp-dark-bg-color;
        padding: 0 10px;

        &--click-tracked {
            cursor: pointer;
        }
    }
}
</style>
