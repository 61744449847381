<template>
    <div class="library-banner__scaling-controls" :class="{ 'library-banner__scaling-controls--dark': isThemeDark }">
        <p>{{ userVisibleScalingPercentage }}%</p>
        <Tooltip placement="left" :transfer="true">
            <hox-square-button @click="toggleScalingIsEnabled">
                <Icon size="24" :type="scalingButtonIcon" />
            </hox-square-button>
            <template #content>
                <template v-if="scalingIsEnabled">Show original size</template>
                <template v-else>Scale to {{ userVisibleScalingPercentage }}%</template>
            </template>
        </Tooltip>
    </div>
</template>
<script>
export default {
    name: "ScalingHeader",

    props: {
        internalScalingFactor: {
            type: [Number, String]
        },
        isResizable: {
            type: Boolean,
            default: false
        },
        scalingIsEnabled: {
            type: Boolean
        },
        theme: {
            type: String,
            default: "light"
        }
    },

    computed: {
        isThemeDark() {
            return this.theme === "dark";
        },
        scalingButtonIcon() {
            return this.scalingIsEnabled ? "ios-expand" : "ios-contract";
        },
        userVisibleScalingPercentage() {
            return Math.round(this.internalScalingFactor * 100);
        }
    },

    methods: {
        toggleScalingIsEnabled() {
            this.$emit("scaling-is-enabled", !this.scalingIsEnabled);
        }
    }
};
</script>
<style lang="scss">
@import "@/../sass/_variables.scss";

.library-banner__scaling-controls {
    align-self: flex-end;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    background: $white;
    color: $darktheme20;
    display: flex;
    font-size: $font-size-small;

    &--dark {
        background-color: $cmp-dark-secondary-bg-color;
        color: $grey1;
    }

    p {
        margin: 0 $spacing-smaller 0 0;
    }
}
</style>
