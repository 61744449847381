import isMediaType from "../utils/isMediaType";
import objectIdsMatch from "../utils/objectIdsMatch";
import getDimensionModifiers from "../utils/getDimensionModifiers";
import { constructImageBossUrl, generateCoverValue } from "../utils/imageBoss";
import { EditableTypes } from "../../gql/Editable/constants";
import {
    OverwritingScope,
    OverwritingScopeOrder,
    OverwritingLanguageOrder
} from "../../gql/EditableOverwrite/modules/OverwriteEditables/OverwritingScope";

// TODO: move to campaign and store in DB
const campaignDefaultLanguage = "en";

const isCampaignScope = overwrite => overwrite.scope === OverwritingScope.EDITABLE_GROUP;

const isGroupScope = overwrite => overwrite.scope === OverwritingScope.EDITABLE_GROUP;

const isTemplateScope = overwrite => overwrite.scope === OverwritingScope.MASTER_TEMPLATE;

const isGroupTemplateScope = overwrite => overwrite.scope === OverwritingScope.EDITABLE_GROUP_AND_MASTER_TEMPLATE;

const isValidLanguage = (overwrite, language) =>
    overwrite.language === language || overwrite.language === campaignDefaultLanguage;

const isValidScope = (overwrite, groupValuesIds = [], masterTemplateId) => {
    const hasSelectedTemplate = objectIdsMatch(overwrite.masterTemplateId, masterTemplateId);
    const hasSelectedGroups = overwrite.editableGroupValueIds.every(id => {
        const groupValueId = typeof id === "object" ? id.toString() : id;
        return groupValuesIds.includes(groupValueId);
    });

    if (isTemplateScope(overwrite)) {
        return hasSelectedTemplate;
    }

    if (isGroupScope(overwrite)) {
        return hasSelectedGroups;
    }

    if (isGroupTemplateScope(overwrite)) {
        return hasSelectedTemplate && hasSelectedGroups;
    }

    return isCampaignScope(overwrite);
};

export const getDefaultValue = (editable, masterTemplateId) => {
    const masterTemplateDefaultValue = editable.defaultValues.find(defaultValue =>
        objectIdsMatch(defaultValue.masterTemplateId, masterTemplateId)
    );
    return masterTemplateDefaultValue ? masterTemplateDefaultValue.value : null;
};

export const getOverwrite = (overwrites, groupValuesIds, masterTemplateId, language) => {
    const validOverwrites = overwrites
        .filter(overwrite => {
            return isValidLanguage(overwrite, language) && isValidScope(overwrite, groupValuesIds, masterTemplateId);
        })
        .sort((a, b) => {
            return (
                OverwritingLanguageOrder(b.language, language, campaignDefaultLanguage) -
                    OverwritingLanguageOrder(a.language, language, campaignDefaultLanguage) ||
                OverwritingScopeOrder[b.scope] - OverwritingScopeOrder[a.scope]
            );
        });

    return validOverwrites.reduce(
        (computedOverwrite, overwrite) => Object.assign({}, overwrite, computedOverwrite),
        {}
    );
};

const getSettingsByMasterTemplateId = (resizeSettings, masterTemplateId) => {
    const { resizeSettingsByTemplateId } = resizeSettings;
    return resizeSettingsByTemplateId && resizeSettingsByTemplateId[masterTemplateId]
        ? resizeSettingsByTemplateId[masterTemplateId]
        : resizeSettings;
};

export const getMediaItemResizedUrl = ({ defaultValues }, { resizeSettings, url }, masterTemplate = {}) => {
    const defaultValue = defaultValues.find(value => objectIdsMatch(value.masterTemplateId, masterTemplate._id));
    const hasResizeSettings = () => defaultValue && resizeSettings;

    if (!hasResizeSettings()) {
        return url;
    }

    const { dimensions } = defaultValue;
    const { cover, horizontalAlign, verticalAlign, manualSettings, persistentKey } = getSettingsByMasterTemplateId(
        resizeSettings,
        masterTemplate._id
    );

    // Make sure we are on BE when we use this. On the FE in the editor we can load from the image service
    const hasResizedPersistentKey = () => typeof window === "undefined" && persistentKey;
    if (hasResizedPersistentKey()) {
        return persistentKey;
    }

    if (!dimensions || (!cover && !manualSettings)) {
        return url;
    }

    const { widthMultiplier, heightMultiplier } = getDimensionModifiers(masterTemplate);

    return constructImageBossUrl(
        url,
        generateCoverValue(cover, horizontalAlign, verticalAlign),
        Math.round(dimensions.width * widthMultiplier),
        Math.round(dimensions.height * heightMultiplier),
        manualSettings
    );
};

export const getEditableValue = (editable, overwrite, masterTemplate, preview = false) => {
    const isValidMediaItem = () => isMediaType(editable.type) && overwrite.mediaItem;

    if (isValidMediaItem(editable.type)) {
        if (editable.type === EditableTypes.folder) {
            const value = `${overwrite.mediaItem.persistentKey.replace(/^\/|\/$/g, "")}/`;
            return preview ? `/${value}` : value;
        }

        if (overwrite.mediaItem.resizeSettings) {
            return getMediaItemResizedUrl(editable, overwrite.mediaItem, masterTemplate);
        }

        return overwrite.mediaItem.url;
    }

    return overwrite.value;
};

const getOverwriteValue = (computedOverwrite, { value: overwriteValue }) =>
    computedOverwrite.value || computedOverwrite.value === "" ? computedOverwrite.value : overwriteValue;

const mergeInGroupScope = (groupLevelOverwrite, templateLevelOverwrite) => {
    const overwrite = { ...templateLevelOverwrite };

    if (groupLevelOverwrite.settings) {
        Object.assign(overwrite, { settings: { ...groupLevelOverwrite.settings, ...templateLevelOverwrite.settings } });
    }

    if (templateLevelOverwrite) {
        Object.assign(overwrite, {
            value: getOverwriteValue(templateLevelOverwrite, groupLevelOverwrite),
            mediaUseId: templateLevelOverwrite.mediaUseId || groupLevelOverwrite.mediaUseId
        });
    }

    return overwrite;
};

export const getEditableValueObject = (
    editableAndOverwrites,
    groupValuesIds,
    masterTemplateId,
    language = campaignDefaultLanguage
) => {
    const { editable, overwrites } = editableAndOverwrites;
    const value = getDefaultValue(editable, masterTemplateId);
    const defaultValue = { value, defaultValue: value };

    if (!overwrites.length) {
        return defaultValue;
    }

    let overwrite = getOverwrite(overwrites, groupValuesIds, masterTemplateId, language);

    if (masterTemplateId) {
        const groupLevelOverwrite = getOverwrite(overwrites, groupValuesIds, null, language);

        overwrite = mergeInGroupScope(groupLevelOverwrite, overwrite);
    }

    if (!overwrite.mediaItem && overwrite.value == null) {
        overwrite.value = defaultValue.value;
        overwrite.defaultValue = defaultValue.defaultValue;
    }

    return overwrite;
};

export const prepareValueForPreview = (value, type) => {
    if (type === EditableTypes.folder) {
        const newValue = `${value.replace(/^\/|\/$/g, "")}/`;

        if (newValue.startsWith("media-library/")) {
            return `/${newValue}`;
        }

        return newValue;
    }

    return value;
};

export default {
    getMediaItemResizedUrl,
    getEditableValue,
    getEditableValueObject,
    prepareValueForPreview
};
