import assert from "assert";
import { ValidationError } from "../errors";
// Export the error so it can be used / imported on the front end
export { ValidationError };

export default name => {
    // field name cannot start wiht - or a number
    assert(!name.match(/^(\d|-)/), new ValidationError("name", name, 'Column name cannot start with "-" or a number'));
    // field name cannot contain spaces
    assert(!name.includes(" "), new ValidationError("name", name, "Column name cannot contain a space"));
    // field name can only contain alpha numeric hyphens and underscores
    assert(
        name.match(/^[a-zA-Z0-9-_]+$/),
        new ValidationError(
            "name",
            name,
            "Column name contains invalid characters. You can only use letters, numbers, hyphens and underscores"
        )
    );
    // field name reservation is case-insensitive so we have a list of lower case strings
    const reservedNames = [
        "targeting",
        "version_classification",
        "unique_id",
        "reporting_label",
        "qastatus",
        "row",
        "rows",
        "_id",
        "language",
        "published",
        "publishedstatus"
    ];
    assert(
        !reservedNames.includes(name.toLowerCase()),
        new ValidationError(
            "name",
            name,
            `"${name}" is a reserved term and can't be used as a Column name. Other reserved terms are: ${reservedNames.join(
                ", "
            )}`
        )
    );
};
