<template>
    <td :class="classes" :style="style">
        <div :class="contentClasses">
            <slot />
        </div>
    </td>
</template>

<script>
export default {
    props: {
        hasStrongBorderLeft: {
            type: Boolean
        },
        isStickyLeft: {
            type: Boolean
        },
        isStickyRight: {
            type: Boolean
        },
        maxWidthPixels: {
            type: Number
        },
        minWidthPixels: {
            type: Number
        },
        overflowHidden: {
            default: true,
            type: Boolean
        },
        stickyOffsetPixels: {
            type: Number
        },
        widthPixels: {
            type: Number
        }
    },
    data() {
        return {
            autoStickyOffsetPixels: undefined
        };
    },
    computed: {
        classes() {
            const classes = ["table-row-column"];
            if (this.isStickyLeft) {
                classes.push("table-row-column--sticky-left");
            } else if (this.isStickyRight) {
                classes.push("table-row-column--sticky-right");
            }
            if (this.hasStrongBorderLeft) {
                classes.push("table-row-column--strong-border-left");
            }
            return classes;
        },
        contentClasses() {
            const classes = ["table-row-column__content"];
            if (this.overflowHidden) {
                classes.push("table-row-column__content--overflow-hidden");
            }
            return classes;
        },
        // eslint-disable-next-line complexity
        style() {
            const style = {};
            if (this.widthPixels) {
                style.maxWidth = `${this.widthPixels}px`;
                style.minWidth = `${this.widthPixels}px`;
                style.width = `${this.widthPixels}px`;
            } else {
                if (this.maxWidthPixels) {
                    style.maxWidth = `${this.maxWidthPixels}px`;
                }
                if (this.minWidthPixels) {
                    style.minWidth = `${this.minWidthPixels}px`;
                }
            }
            if (this.stickyOffsetPixels) {
                if (this.isStickyLeft) {
                    style.left = `${this.stickyOffsetPixels}px`;
                } else if (this.isStickyRight) {
                    style.right = `${this.stickyOffsetPixels}px`;
                }
            } else if (this.autoStickyOffsetPixels && this.isStickyLeft) {
                style.left = `${this.autoStickyOffsetPixels}px`;
            }
            return style;
        }
    },
    watch: {
        isStickyLeft() {
            if (this.isStickyLeft && !this.stickyOffsetPixels) {
                this.setAutoStickyOffsetPixels();
            }
        }
    },
    mounted() {
        if (this.isStickyLeft && this.stickyOffsetPixels === undefined) {
            this.setAutoStickyOffsetPixels();
        }
    },
    methods: {
        setAutoStickyOffsetPixels() {
            /*
                The reason we do not get a value for offsetRight, for sticky right columns,
                is that it would require more logic, likely having to use the scroll and/or total
                width of the table to calculate correctly. As having multiple sticky columns on the
                right is far less likely, we're not going to implement that functionality yet.
            */
            this.autoStickyOffsetPixels = this.$el.offsetLeft;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.table-row-column {
    position: relative;
    white-space: nowrap;
    width: auto;

    &--sticky-left {
        left: 0;
        position: sticky;
        z-index: 2;

        &:after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            height: 100%;
            border-right: 1px solid $grey3;
        }
    }

    &--sticky-right {
        position: sticky;
        right: 0;
        z-index: 2;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            height: 100%;
            border-left: 1px solid $grey3;
        }
    }

    &--strong-border-left:after {
        content: "";
        position: absolute;
        left: -1px;
        bottom: 0;
        height: 100%;
        border-left: 3px double $grey3;
    }
}

.table-row-column__content {
    padding: $spacing-small $spacing-semi-small;
    max-width: 100%;

    &--overflow-hidden {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>
