<template>
    <collapsible-widget :is-opened="opened" collapsed-height="47" @input="toggleOpen">
        <template #name>{{ editable.label }}</template>
        <template #controls>
            <editable-restriction-control v-if="showRestrictionControl" :editable="editable" />
        </template>

        <div class="deliverable-values editable-widget__body">
            <div v-if="readOnly" class="editable-widget__body--readonly" title="Read Only" @click.stop.prevent></div>
            <expandable-text-input
                class="deliverable-value-item"
                :label="EditableGroupScopeLabel"
                :can-change-visibility="false"
                :is-expanded="isExpanded(null)"
                :value="getEditableValue(editable, groupsAffectingEditable, null)"
                :settings="getEditableSettings(editable, groupsAffectingEditable, null)"
                :scope="OverwriteScope.EditableGroup"
                :overwrite="getOverwrite(editable, groupsAffectingEditable, null)"
                placeholder="Enter value"
                :lockable="false"
                @collapse="onCollapse(null)"
                @input="throttledValueChange($event)"
                @expand="onExpand(null)"
                @restore="onGroupValueRestore($event)"
                @updateSettings="onUpdateSettings(null, ...arguments)"
            >
                <template #editor="editorProps">
                    <css-editor :value="editorProps.value" @input="editorProps.onChange" />
                </template>
            </expandable-text-input>
            <expandable-text-input
                v-for="(label, templateId) in templateLabels"
                v-show="opened"
                :key="templateId"
                class="deliverable-value-item"
                :can-change-visibility="false"
                :is-expanded="isExpanded(templateId)"
                :label="label"
                :label-color="getLabelColor(editable, groupsAffectingEditable, templateId)"
                :value="getEditableValue(editable, groupsAffectingEditable, templateId)"
                :settings="getEditableSettings(editable, groupsAffectingEditable, templateId)"
                :scope="OverwriteScope.EditableGroupTemplate"
                :overwrite="getOverwrite(editable, groupsAffectingEditable, templateId)"
                placeholder="Enter value"
                @collapse="onCollapse(templateId)"
                @input="throttledValueChange($event, templateId)"
                @expand="onExpand(templateId)"
                @removeOverwrite="onRemoveOverwrite"
                @restore="onValueRestore($event, templateId)"
                @updateSettings="onUpdateSettings(templateId, ...arguments)"
            >
                <template #editor="editorProps">
                    <css-editor :value="editorProps.value" @input="editorProps.onChange" />
                </template>
            </expandable-text-input>
        </div>
    </collapsible-widget>
</template>

<script>
import throttle from "lodash.throttle";
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";
import ExpandableTextInput from "@/components/Campaign/ExpandableTextInput";

import editableItemMixin from "@/mixins/editableItemMixin";
import editableMethodsMixin from "@/mixins/editableMethodsMixin";
import EditableRestrictionControl from "@/components/Campaign/EditableRestrictionControl";
import { OverwriteScope } from "@/enums/overwrites";
import CssEditor from "@/components/Campaign/widgets/CssEditor";

export default {
    name: "EditableCssWidget",
    components: {
        CssEditor,
        EditableRestrictionControl,
        ExpandableTextInput,
        CollapsibleWidget
    },

    mixins: [editableItemMixin, editableMethodsMixin],

    data() {
        return {
            expandedTempalateId: undefined
        };
    },

    computed: {
        editables() {
            return this.$store.state.campaign.normalized.editables;
        },

        isEdited() {
            return this.expandedTempalateId !== undefined;
        },

        language() {
            return this.$store.state.editor.selectedLanguage;
        }
    },

    created() {
        this.OverwriteScope = OverwriteScope;
        this.throttledValueChange = throttle((value, templateId) => {
            this.onValueChange(value, templateId);
        }, 100);
    },

    methods: {
        isExpanded(templateId) {
            return this.expandedTempalateId === templateId;
        },

        onAllChange(value) {
            this.update({
                value
            });
        },

        onCollapse(templateId) {
            if (this.isExpanded(templateId)) {
                this.expandedTempalateId = undefined;
            }
        },

        onExpand(templateId) {
            this.expandedTempalateId = templateId;
        },

        onValueChange(value, templateId) {
            this.update(
                {
                    value
                },
                templateId
            );
        },

        onGroupValueRestore(value) {
            this.restore({
                value,
                language: this.language
            });
        },

        onRemoveOverwrite(overwriteId) {
            this.removeOverwrite(overwriteId);
        },

        onValueRestore(value, templateId) {
            this.restore({
                value,
                templateId,
                language: this.language
            });
        }
    }
};
</script>
