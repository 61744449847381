<template>
    <div class="grid-view">
        <resize-settings-modal
            v-if="resizeSettingsModalIsVisible"
            :editable="editable"
            :resize-settings="resizeSettingsToEdit"
            :is-group-resize-settings="isEditingGroupResizeSettings"
            :is-single-template-resize-settings="isEditingSingleTemplateResizeSettings"
            :preview-image-url="imageToResize"
            :template-ids="templateIdsToEditResizeSettingsOf"
            @setResizeSettings="setResizeSettings"
            @close="toggleResizeSettingsModal"
        />
        <component
            :is="componentName"
            :filetype="editable.type"
            :label="EditableGroupScopeLabel"
            :media-item="getOverwriteMediaItem(editable, groupsAffectingEditable, null)"
            :value="getMediaItemUrl(editable, groupsAffectingEditable, null)"
            @click="fileSelected(editable, groupsAffectingEditable, null)"
            @icon-click="fileSelected(editable, groupsAffectingEditable, null)"
            @openResizeSettings="showResizeSettingsModal(templatesUsingGroupMediaItem, true, false)"
            @openEditableSettings="onOpenEditableSettings(editable, groupsAffectingEditable, null)"
        />
        <component
            :is="componentName"
            v-for="(label, templateId) in templateLabels"
            v-show="opened"
            :key="getForKey(editable, templateId)"
            :editable="editable"
            :filetype="editable.type"
            :label="label"
            :label-color="getLabelColor(editable, groupsAffectingEditable, templateId)"
            :media-item="getOverwriteMediaItem(editable, groupsAffectingEditable, templateId)"
            :template-id="templateId"
            :value="getMediaItemUrl(editable, groupsAffectingEditable, templateId)"
            @click="fileSelected(editable, groupsAffectingEditable, templateId)"
            @icon-click="fileSelected(editable, groupsAffectingEditable, templateId)"
            @openResizeSettings="
                showResizeSettingsModal(
                    [templateId],
                    !isTemplateOverwrite(editable, groupsAffectingEditable, templateId),
                    true
                )
            "
            @openEditableSettings="onOpenEditableSettings(editable, groupsAffectingEditable, templateId)"
        />
    </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { TemplateType } from "shared-utils/enums/masterTemplate";
// eslint-disable-next-line import/no-extraneous-dependencies
import { getMediaItemResizedUrl } from "shared-utils/editables";
import EditableImageThumbnail from "@/components/Campaign/EditableImageThumbnail";
import EditableVideoThumbnail from "@/components/Campaign/EditableVideoThumbnail";
import { EditableType } from "@/enums/editables";
import editableFileViewMixin from "@/mixins/editableFileViewMixin";
import { CampaignGetters } from "@/store/modules/campaign";
import { EditorAction } from "@/store/modules/editor";
import { EditableGroupScopeLabel } from "@/enums/overwrites";

export default {
    name: "EditableFileGridView",
    components: {
        EditableVideoThumbnail,
        EditableImageThumbnail
    },
    mixins: [editableFileViewMixin],
    computed: {
        componentName() {
            switch (this.type) {
                case EditableType.Video:
                    return "editable-video-thumbnail";

                case EditableType.BackgroundImage:
                case EditableType.Image:
                case EditableType.Folder:
                default:
                    return "editable-image-thumbnail";
            }
        },

        isEditablesPaneVisible() {
            return this.$store.state.editor.isEditablesPaneVisible;
        },

        masterTemplates() {
            return this.$store.state.campaign.normalized.masterTemplates;
        }
    },
    created() {
        this.EditableGroupScopeLabel = EditableGroupScopeLabel;
    },
    methods: {
        getMediaItemUrl(editable, groupsAffectingEditable, templateId) {
            const mediaItem = this.getOverwriteMediaItem(editable, groupsAffectingEditable, templateId);
            if (this.type === EditableType.Folder && !mediaItem) {
                const template = this.$store.getters[CampaignGetters.templateById](templateId);
                if (template) {
                    const defaultValue = this.getDefaultValue(editable, templateId).replace(/^\/|\/$/g, "");
                    // In sandbox mode the preview has index.html in it so that the local server can load the template
                    //  we do not want index.html in the path so we replace it out here
                    return `${template.preview.replace(/index.html?$/, "")}/${defaultValue}/static.png`;
                }
            }

            if (mediaItem) {
                return getMediaItemResizedUrl(editable, mediaItem, this.masterTemplates[templateId]);
            }

            return this.getDefaultValue(editable, templateId);
        },
        onOpenEditableSettings(editable, groupsAffectingEditable, templateId) {
            this.$store.dispatch(EditorAction.SetEditedEditable, {
                editable,
                groupsAffectingEditable,
                masterTemplateId: templateId
            });

            // If the editable belongs to any AE templates, do not show the settings pane
            if (templateId) {
                if (this.$store.state.campaign.normalized.masterTemplates[templateId].adType === TemplateType.AE) {
                    return;
                }
            } else if (
                editable?.defaultValues.find(
                    ({ masterTemplateId }) =>
                        this.$store.state.campaign.normalized.masterTemplates[masterTemplateId].adType ===
                        TemplateType.AE
                )
            ) {
                return;
            }

            this.$store.dispatch(EditorAction.SetSettingsPaneVisibility, true);
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.grid-view {
    background-color: $cmp-dark-secondary-sidebar-color;
    display: flex !important;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    flex: 1 0 100%;

    .editable-image-thumbnail {
        display: flex;
        margin: 5px 1px;
    }
}
</style>
