<template>
    <insight-preview-wrapper
        :type="InsightType.PositionAnalysis"
        :filters="matchingContentFilters"
        :response-path="responsePath"
        @opened="onOpen"
        @data="onData"
    >
        <template #name>{{ title }}</template>
        <template #headline>{{ headerText }}</template>

        <div v-if="data && !hasNoDeliverables" class="position-analysis">
            <div v-for="position in positions" :key="position" class="position-analysis__item">
                <insights-creative-preview-carousel
                    v-if="hasBucketData(PositionAnalysisPosition[position])"
                    :bounding-boxes="true"
                    :bucket-config="getBucketConfig(position)"
                    :init-creatives="getDeliverables(position)"
                    :seek-to-attribute="true"
                    :stats="getStats(position)"
                >
                    <template #name>{{ PositionAnalysisPositionDisplay[position] }}</template>
                </insights-creative-preview-carousel>
                <insights-creative-no-data-preview v-else>
                    <template #name>{{ PositionAnalysisPositionDisplay[position] }}</template>
                </insights-creative-no-data-preview>
            </div>
        </div>

        <template #chartPreview>
            <insights-chart-preview
                :data="data"
                :title="title"
                :label-map="PositionAnalysisPositionLabels"
            ></insights-chart-preview>
        </template>
    </insight-preview-wrapper>
</template>
<script>
import {
    InsightType,
    InsightTypeToServiceName,
    PositionAnalysisPosition,
    PositionAnalysisPositionLabels
} from "@/components/CreativeIntelligence/constants";
import InsightPreviewWrapper from "@/components/CreativeIntelligence/InsightPreviews/InsightPreviewWrapper";
import InsightsCreativeNoDataPreview from "@/components/CreativeIntelligence/InsightPreviews/InsightsCreativeNoDataPreview";
import InsightsChartPreview from "@/components/CreativeIntelligence/InsightPreviews/InsightsChartPreview";
import { CreativeInsightsContentFilter } from "@/enums/creativeInteligence";
import InsightsCreativePreviewCarousel from "@/components/CreativeIntelligence/InsightPreviews/InsightsCreativePreviewCarousel";
import { CreativeInsightsGetters } from "@/store/modules/creativeInsights";
import { getMetricStat } from "@/utils/creativeInteligence";

export const PositionAnalysisPositionDisplay = {
    BottomCenter: "Bottom Center",
    BottomLeft: "Bottom Left",
    BottomRight: "Bottom Right",
    Center: "Center",
    CenterLeft: "Left",
    CenterRight: "Right",
    TopCenter: "Top Center",
    TopLeft: "Top Left",
    TopRight: "Top Right"
};

export default {
    name: "PositionAnalysis",
    components: {
        InsightsCreativePreviewCarousel,
        InsightsChartPreview,
        InsightsCreativeNoDataPreview,
        InsightPreviewWrapper
    },

    data() {
        return {
            data: null,
            responsePath: "positions",
            title: "Position Analysis",
            opened: false
        };
    },

    computed: {
        positions() {
            return Object.keys(PositionAnalysisPosition);
        },

        best() {
            if (!this.data) {
                return null;
            }

            const labelsSortedByKpi = [...this.positions].sort((labelA, labelB) => {
                const objA = this.data[PositionAnalysisPosition[labelA]].summaryStats;
                const objB = this.data[PositionAnalysisPosition[labelB]].summaryStats;
                const kpiA = objA ? objA.kpiMetric[this.kpiMetricStatName] : 0;
                const kpiB = objB ? objB.kpiMetric[this.kpiMetricStatName] : 0;

                return kpiB - kpiA;
            });

            return PositionAnalysisPositionDisplay[labelsSortedByKpi[0]];
        },

        kpiMetricName() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricName];
        },

        kpiMetricStatName() {
            return getMetricStat(this.kpiMetricName);
        },

        hasNoDeliverables() {
            return this.data && !Object.values(this.data).some(({ deliverables }) => deliverables.length);
        },

        displayTemplates() {
            if (!this.selectedTagIds.length) {
                return null;
            }

            return this.tagsById[this.selectedTagIds[0]].displayTemplates;
        },

        showsDiversityTemplate() {
            return this.displayTemplates && this.displayTemplates.includes("diversity");
        },

        headerText() {
            if (this.showsDiversityTemplate) {
                return `You're showing People mostly in the ${this.best}.`;
            }

            return `${this.resultSource} works best when it appears in the ${this.best}`;
        },

        resultSource() {
            return this.selectedTagIds.length ? `${this.tagsById[this.selectedTagIds[0]].name}` : "The search query";
        },

        selectedTagIds() {
            return this.$store.state.ciTags.selectedTagIds;
        },

        tagsById() {
            return this.$store.state.ciTags.tagsById;
        }
    },

    created() {
        this.matchingContentFilters = [CreativeInsightsContentFilter.Position];
        this.InsightType = InsightType;
        this.PositionAnalysisPosition = PositionAnalysisPosition;
        this.PositionAnalysisPositionDisplay = PositionAnalysisPositionDisplay;
        this.PositionAnalysisPositionLabels = PositionAnalysisPositionLabels;
    },

    methods: {
        getBucketConfig(position) {
            return {
                ...this.data[PositionAnalysisPosition[position]].pagination,
                bucketFilters: {
                    value: PositionAnalysisPosition[position],
                    type: InsightTypeToServiceName[InsightType.PositionAnalysis]
                }
            };
        },
        getDeliverable(position) {
            if (!this.data || !this.data[PositionAnalysisPosition[position]]) {
                return null;
            }

            return this.data[PositionAnalysisPosition[position]].deliverables[0] || null;
        },

        getDeliverables(position) {
            if (!this.data || !this.data[PositionAnalysisPosition[position]]) {
                return [];
            }

            return this.data[PositionAnalysisPosition[position]].deliverables || [];
        },

        getStats(position) {
            return this.data[PositionAnalysisPosition[position]].summaryStats;
        },

        hasBucketData(bucket) {
            return this.data[bucket] && this.data[bucket].deliverables.length;
        },

        onData(response) {
            this.data = response;
        },

        async onOpen(open) {
            if (!this.opened && open) {
                await this.$nextTick();
                this.opened = true;
            }
        }
    }
};
</script>
<style lang="scss">
@import "../../../../sass/_variables.scss";

.position-analysis {
    max-width: 1100px;

    display: grid;
    gap: 1px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;

    &__item {
        padding: 10px;
    }
}
</style>
