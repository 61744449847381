<template>
    <hox-modal data-testid="create-campaign__modal" @close="close">
        <hox-loading-layer v-if="isCreatingCampaign" />
        <template #header>Create campaign</template>
        <campaign-form
            :external-errors="mutationErrors"
            :sections="sections"
            :show-validation-errors="showValidationErrors"
            @formDataUpdate="setFormData"
            @hasValidationErrorsUpdate="setHasValidationErrors"
            @submit="addCampaign"
        />
        <div v-if="hasUnexpectedErrorCreatingCampaign" class="create-campaign-modal__unexpected-error-wrapper">
            <Alert banner type="error">
                <template #desc>
                    There was an unexpected error and the campaign could not be created. Please try again in a few
                    moments.
                </template>
            </Alert>
        </div>
        <template #footer>
            <Button
                :disabled="showValidationErrors && hasValidationErrors"
                type="primary"
                data-testid="create-campaign__submit"
                @click="addCampaign"
            >
                Create
            </Button>
        </template>
    </hox-modal>
</template>

<script>
import addCampaignMutation from "@/apollo/mutations/AddCampaign.gql";
import CampaignForm from "@/components/CampaignForm";
import { campaignDefaultLanguage } from "@/store/modules/campaign";
import { groupErrorsByField } from "@/utils";

export default {
    name: "CreateCampaignModal",

    components: {
        CampaignForm
    },

    props: {
        sections: {
            required: true,
            type: Array
        }
    },

    data() {
        return {
            formData: {},
            hasUnexpectedErrorCreatingCampaign: false,
            hasValidationErrors: false,
            isCreatingCampaign: false,
            mutationErrors: {},
            showValidationErrors: false
        };
    },

    computed: {
        clientId() {
            return this.$route.params.clientId;
        }
    },

    watch: {
        // eslint-disable-next-line func-names
        "formData.name": function () {
            this.$delete(this.mutationErrors, "name");
        }
    },

    methods: {
        async addCampaign() {
            this.showValidationErrors = true;
            this.hasUnexpectedErrorCreatingCampaign = false;
            if (!this.isCreatingCampaign && !this.hasValidationErrors) {
                this.isCreatingCampaign = true;
                try {
                    await this.$apollo.mutate({
                        mutation: addCampaignMutation,
                        variables: {
                            clientId: this.clientId,
                            campaign: {
                                name: this.formData.name,
                                jiraTicketUrl: this.formData.jiraTicketUrl,
                                resourceGroupIds: this.formData.resourceGroupIds,
                                languages: [campaignDefaultLanguage],
                                sectionId: this.formData.sectionId === "none" ? null : this.formData.sectionId,
                                toneOfVoice: this.formData.toneOfVoice
                            }
                        }
                    });
                    this.$snackbar.success("Campaign created");
                    this.$emit("campaignCreated");
                    this.close();
                } catch ({ graphQLErrors }) {
                    this.mutationErrors = groupErrorsByField(graphQLErrors);
                    this.hasUnexpectedErrorCreatingCampaign = Object.keys(this.mutationErrors).length === 0;
                } finally {
                    this.isCreatingCampaign = false;
                }
            }
        },

        close() {
            this.$emit("close");
        },

        setFormData(formData) {
            this.formData = formData;
        },

        setHasValidationErrors(hasValidationErrors) {
            this.hasValidationErrors = hasValidationErrors;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.create-campaign-modal__unexpected-error-wrapper {
    padding: $spacing 0 0 0;
}
</style>
