<template>
    <collapsible-widget
        class="editable-boolean-widget editable-select-widget"
        :is-opened="opened"
        collapsed-height="47"
        @input="toggleOpen"
    >
        <template #name>{{ editable.label }}</template>
        <template #controls>
            <editable-restriction-control v-if="showRestrictionControl" :editable="editable" />
        </template>

        <div class="editable-widget__body">
            <div v-if="readOnly" class="editable-widget__body--readonly" title="Read Only" @click.stop.prevent></div>
            <div class="deliverable-value-item">
                <labelled-select
                    :options="options"
                    :placeholder="groupPlaceholder"
                    :value="getEditableValue(editable, groupsAffectingEditable, null)"
                    :label="EditableGroupScopeLabel"
                    @update="onAllChange($event)"
                />
            </div>

            <lockable-row
                v-for="(label, templateId) in templateLabels"
                v-show="opened"
                :key="getForKey(editable._id, templateId)"
                :locked="isTemplateOverwrite(editable, groupsAffectingEditable, templateId)"
                @unlock="onUnlock(editable, groupsAffectingEditable, templateId)"
            >
                <labelled-select
                    :label="label"
                    :label-color="getLabelColor(editable, groupsAffectingEditable, templateId)"
                    :options="options"
                    :value="getEditableValue(editable, groupsAffectingEditable, templateId)"
                    class="list-view__item"
                    @update="onUpdate($event, groupsAffectingEditable, templateId)"
                />
            </lockable-row>
        </div>
    </collapsible-widget>
</template>

<script>
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";
import LabelledSelect from "@/components/Campaign/LabelledSelect";
import LockableRow from "@/components/Campaign/LockableRow";

import editableItemMixin from "@/mixins/editableItemMixin";
import editableMethodsMixin from "@/mixins/editableMethodsMixin";
import EditableRestrictionControl from "@/components/Campaign/EditableRestrictionControl";

export default {
    name: "EditableBooleanWidget",
    components: {
        EditableRestrictionControl,
        LockableRow,
        LabelledSelect,
        CollapsibleWidget
    },
    mixins: [editableItemMixin, editableMethodsMixin],

    data() {
        return {
            options: [
                {
                    label: "true",
                    value: "true"
                },

                {
                    label: "false",
                    value: "false"
                }
            ]
        };
    },

    computed: {
        groupPlaceholder() {
            const masterTemplateIds = Object.keys(this.templateLabels);
            const sample = this.getDefaultValue(this.editable, masterTemplateIds[0]);
            const sameDefaults = !masterTemplateIds.some(masterTemplateId => {
                const val = this.getDefaultValue(this.editable, masterTemplateId);
                return val !== sample;
            });

            return sameDefaults ? sample : "Select";
        }
    },

    methods: {
        onAllChange(value) {
            this.update({
                value
            });
        },

        onUnlock(editable, groupsAffectingEditable, templateId) {
            const overwrite = this.getOverwrite(editable, groupsAffectingEditable, templateId);
            this.removeOverwrite(overwrite._id);
        },

        onUpdate(value, groupsAffectingEditable, templateId) {
            this.update(
                {
                    value
                },
                templateId
            );
        }
    }
};
</script>
