<template>
    <hox-modal v-if="isVisible" wrapper-class="add-account-modal" @close="close">
        <template #header>
            {{ actionLabel }}
        </template>
        <div>
            <p>Please enter all of your details below to create your Meta account.</p>
        </div>
        <div class="delete-campaign-modal__alert-wrapper">
            <Alert show-icon>
                <template slot="icon">
                    <icon type="md-information-circle" size="20" color="#8B919A" />
                </template>
                <p>
                    <strong>Facebook Account ID,&nbsp;</strong>
                    <strong>Page ID</strong>
                    and
                    <strong>Instagram Account ID</strong>
                    need be added as a partner to Hogarth's Business
                    <br />
                    Account:
                    <span class="link-type">
                        <strong>3704782469638109</strong>
                    </span>
                </p>
            </Alert>
            <Alert show-icon>
                <template slot="icon">
                    <icon type="md-information-circle" size="20" color="#8B919A" />
                </template>
                <p>
                    <strong>Origin Ad Set ID</strong>
                    is configured in your Facebook ad account and can be used to set audience targeting.
                </p>
            </Alert>
        </div>
        <div class="add-account-modal__wrap">
            <div class="add-account-modal__form">
                <component
                    :is="formComponent"
                    :form-data="formData"
                    :action-label="actionLabel"
                    @submitFormData="onSubmitFormData"
                    @changeAccountType="onChangeAccountType"
                />
            </div>
            <div v-if="isGoogleAccount" class="add-account-modal__info">
                <Alert banner type="warning">
                    <template #desc>
                        <strong>DC Cmapaign Profile ID</strong>
                        configured in your Google ad account and can be used to set audience targeting.
                    </template>
                </Alert>
            </div>
        </div>
    </hox-modal>
</template>
<script>
import { AccountTypesList } from "@/enums/activate";
import BasicAccountForm from "@/components/IntelligentDelivery/BasicAccountForm";
import FacebookAccountForm from "@/components/IntelligentDelivery/FacebookAccountForm";
import GoogleAccountForm from "@/components/IntelligentDelivery/GoogleAccountForm";

export default {
    name: "PlatformAccountFormModal",
    components: {
        BasicAccountForm,
        FacebookAccountForm,
        GoogleAccountForm
    },
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        accountAction: {
            type: String,
            default: "add"
        },
        accountIndex: {
            type: Number,
            default: 0
        },
        formData: {
            type: Object
        }
    },
    data() {
        return {
            AccountTypesList,
            currentAccountType: this.formData.accountType
        };
    },
    computed: {
        actionLabel() {
            if (this.accountAction === "edit") {
                return "Edit Meta Account";
            }
            return "Add Meta Account";
        },
        formComponent() {
            switch (this.currentAccountType) {
                case this.AccountTypesList.Facebook.id:
                    return "FacebookAccountForm";
                case this.AccountTypesList.Google.id:
                    return "GoogleAccountForm";
                default:
                    return "BasicAccountForm";
            }
        },
        isFacebookAccount() {
            return this.currentAccountType === this.AccountTypesList.Facebook.id;
        },
        isGoogleAccount() {
            return this.currentAccountType === this.AccountTypesList.Google.id;
        }
    },
    watch: {
        formData(data) {
            if (data && data.accountType) {
                this.currentAccountType = data.accountType;
            }
        }
    },
    methods: {
        close() {
            this.$emit("close");
        },
        onChangeAccountType(type) {
            this.currentAccountType = type;
        },

        onSubmitFormData() {
            this.$snackbar.info(`Platform account ${this.accountAction === "add" ? "added" : "edited"}`);
            this.$emit("addPlatformAccount", {
                formData: this.formData,
                accountIndex: this.accountIndex,
                accountAction: this.accountAction
            });
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.add-account-modal {
    &--extended {
        .hox-modal {
            width: 900px;
        }
        &__form {
            width: auto;
            padding-right: 10px;
        }
    }
    &__form {
        width: 100%;
        padding-right: 0;
        .ivu-form-item {
            margin-bottom: 10px;
            &.ivu-form-item-error {
                margin-bottom: 20px;
            }
        }
        .ivu-form-item-label {
            font-weight: bold;
        }
        .ivu-btn-primary {
            margin-top: 30px;
        }
        .ivu-icon {
            font-size: 12px;
        }
    }
    &__wrap {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 10px;
    }

    &__info {
        display: flex;
        align-items: center;
    }
    .hox-modal {
        width: 554px;
        &__header {
            margin-bottom: 0;
        }
        &__header-container {
            padding: 30px 30px 0;
        }
        &__body {
            padding: 2px 30px 30px;
        }
        p {
            color: #121619;
        }
    }
    .ivu-alert {
        border: none;
        background-color: #e7eaee;
        padding: 5px 8px 5px 32px;
        line-height: 22px;
        &-icon {
            left: 8px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
</style>
