<template>
    <div class="top-nav">
        <div class="client-logo">
            <Dropdown v-if="currentClient" @on-click="changeClient">
                <div class="dropdown-selection" href="javascript:void(0)">
                    <router-link v-if="currentClient.logoUrl" :to="{ name: 'Home' }">
                        <img :src="currentClient.logoUrl" class="logo-image" />
                    </router-link>
                    <template v-else>
                        <router-link v-if="currentClient.name" class="dropdown-selection__link" :to="{ name: 'Home' }">
                            <div class="dropdown-selection__client-name">{{ currentClient.name }}</div>
                        </router-link>
                    </template>
                    <Icon v-if="clients.length > 1" class="dropdown-selection-icon" type="ios-arrow-down"></Icon>
                </div>
                <DropdownMenu slot="list">
                    <DropdownItem
                        v-for="cli in clients"
                        :key="cli.name"
                        :name="cli._id"
                        :class="{ active: cli.id === currentClient.id }"
                    >
                        {{ cli.name }}
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
        <section-nav />
        <ul class="hox-menu ivu-menu ivu-menu-horizontal ivu-menu-light">
            <li
                v-if="isItemVisible('CampaignsDashboard')"
                key="CampaignsDashboard"
                class="hox-menu-item hox-menu-item--campaigns ivu-menu-item"
                data-testid="horizontal-menu__campaigns-button"
                :class="{ 'ivu-menu-item-selected': isItemSelected('CampaignsDashboard') }"
                @click="onSelect('CampaignsDashboard')"
            >
                Campaigns
            </li>
            <li
                v-if="isItemVisible('ClientTemplates')"
                key="ClientTemplates"
                class="hox-menu-item hox-menu-item--templates ivu-menu-item"
                data-testid="horizontal-menu__master-templates-button"
                :class="{ 'ivu-menu-item-selected': isItemSelected('ClientTemplates') }"
                @click="onSelect('ClientTemplates')"
            >
                Master Templates
            </li>
            <li
                v-if="isItemVisible('Assets')"
                key="Assets"
                class="hox-menu-item hox-menu-item--assets ivu-menu-item"
                data-testid="horizontal-menu__content-library-button"
                @click="onSelect('Assets')"
            >
                Content Library
            </li>
            <li
                v-if="isItemVisible('Fonts')"
                key="Fonts"
                class="hox-menu-item hox-menu-item--fonts ivu-menu-item"
                :class="{ 'ivu-menu-item-selected': isItemSelected('Fonts') }"
                data-testid="horizontal-menu__fonts-button"
                @click="onSelect('Fonts')"
            >
                Fonts
            </li>
        </ul>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import navigationQuery from "@/apollo/queries/Navigation.gql";
import { UiAction } from "@/store/modules/ui";
import { getDomain } from "@/utils";
import SectionNav from "@/components/SectionNav";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Navigation",
    components: { SectionNav },
    data() {
        return {
            clients: [],
            collapsed: false,
            userDisplayName: ""
        };
    },

    computed: {
        ...mapState("auth", ["authConfig"]),

        clientId() {
            return "clientId" in this.$route.params ? this.$route.params.clientId : null;
        },

        currentClient() {
            return this.$store.state.ui.currentClient;
        },

        currentRoute() {
            return this.$route.params;
        }
    },

    async mounted() {
        this.userDisplayName = await this.$auth.userDisplayName();
    },

    methods: {
        ...mapMutations("auth", ["setIsAuthorized"]),
        ...mapMutations("mediaLibrary", {
            setMediaLibraryOpen: "setOpen"
        }),

        changeClient(clientId) {
            const client = this.clients.filter(c => c._id === clientId).pop();
            // Must push route before setting the new current client so that we can detect the change
            this.$router
                .push({
                    name: "CampaignsDashboard",
                    params: {
                        clientId: client._id
                    }
                })
                .catch(err => {
                    if (err.name !== "NavigationDuplicated") {
                        throw err;
                    }
                });
            this.setCurrentClient(client);
        },

        getRouterPath(name) {
            return {
                name,
                params: {
                    clientId: this.clientId
                }
            };
        },

        isItemSelected(name) {
            return this.$route.name === name;
        },

        // eslint-disable-next-line complexity
        isItemVisible(item) {
            switch (item) {
                case "CampaignsDashboard":
                    return this.clientId;

                case "ClientTemplates":
                    return (
                        this.clientId &&
                        this.$auth.userCan(this.$auth.Actions.CanListMasterTemplates, { clientId: this.clientId }) &&
                        getDomain() === "other"
                    );

                case "Assets":
                    return (
                        this.clientId &&
                        this.$auth.userCan(this.$auth.Actions.CanListMediaItems, { clientId: this.clientId }) &&
                        getDomain() === "other"
                    );

                case "Fonts":
                    return (
                        this.clientId &&
                        this.$auth.userCan(this.$auth.Actions.CanListFonts, { clientId: this.clientId }) &&
                        getDomain() === "other"
                    );

                default:
                    return true;
            }
        },

        async logout() {
            // If false the window will redirect to coginito so push is not required
            if (await this.$auth.logout(this.authConfig)) {
                this.setIsAuthorized({ authorized: false });
                this.$router.push({ name: "Login" });
            }
        },

        onSelect(name) {
            if (name === "Assets") {
                this.openMediaLibrary();
            } else {
                this.$router.push(this.getRouterPath(name)).catch(err => {
                    if (err.name !== "NavigationDuplicated") {
                        throw err;
                    }
                });
            }
        },

        openMediaLibrary() {
            this.setMediaLibraryOpen({ open: true });
        },

        setCurrentClient(client) {
            this.$store.dispatch(UiAction.SetCurrentClient, client);
        }
    },

    apollo: {
        navigation: {
            query: navigationQuery,
            fetchPolicy: "no-cache",
            skip() {
                return !this.clientId;
            },
            manual: true,
            result(result) {
                if (result.data.clients.length) {
                    // Make sure the list is updated if new clients have been added
                    const currentClient = result.data.clients.find(client => client._id === this.clientId);
                    if (!currentClient) {
                        this.$apollo.queries.navigation.refetch();
                        return;
                    }

                    this.clients = result.data.clients.map(client => {
                        if (client._id === this.clientId) {
                            this.setCurrentClient(client);
                        }

                        return {
                            ...client,
                            content: client.name
                        };
                    });
                }
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../sass/variables";

$nav-bg-color: $white;
$nav-border-color: $grey2;
$nav-border-color-selected: #50e3c2; //old
$nav-border-color-selected: $primary-color;
$nav-font-color: #515a6e;

.top-nav {
    border-bottom: 2px solid $nav-border-color;
    background-color: $nav-bg-color;
    display: flex;
    flex: 1 0 100%;
    width: 100%;
    height: 60px;
    justify-content: space-between;
    align-items: center;

    .hox-menu {
        flex: 1 0 360px;
        z-index: 0;

        .ivu-menu-item {
            font-size: 16px;
            text-transform: none;
            color: $nav-font-color;

            &:hover,
            &-selected {
                color: $nav-font-color;
                border-bottom: 4px $nav-border-color-selected solid;
            }
        }
    }

    .client-logo {
        width: 130px;
        flex: 0 0 130px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 20px;
        padding-right: 20px;
        position: relative;

        .logo-image {
            max-height: 45px;
            max-width: 70px;
            padding: 0;
            margin: 0;
        }

        .ivu-dropdown {
            width: 110px;

            &-item {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        .dropdown-selection {
            flex: 0;
            padding-right: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            overflow: hidden;

            &__client-name {
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}

.top-nav__right {
    display: flex;
}
</style>
