<template>
    <div class="data-filter">
        <Form :label-width="labelWidth">
            <FormItem :label="label">
                <Select
                    v-model="selected"
                    :size="size"
                    class="data-filter__select"
                    :placeholder="placeholder"
                    @on-change="onChange"
                >
                    <Option
                        v-if="showResetFilter"
                        :value="resetFilter.value"
                        class="data-filter__option reset-filter"
                    >
                        {{ resetFilter.label }}
                    </Option>
                    <slot name="options" :options="options">
                        <Option
                            v-for="option in options"
                            :key="option.value"
                            :value="option.value"
                            class="data-filter__option"
                        >
                            {{ getOptionValue(option) }}
                        </Option>
                    </slot>
                </Select>
            </FormItem>
        </Form>
    </div>
</template>

<script>
export default {
    name: "DataFilter",

    props: {
        options: {
            type: Array,
            required: true
        },

        label: {
            type: String,
            required: true
        },

        resetFilter: {
            type: Object,
            default() {
                return {
                    value: "all",
                    label: "All"
                };
            }
        },

        showResetFilter: {
            type: Boolean,
            default: true
        },

        placeholder: {
            type: String,
            default: "Select"
        },

        size: {
            type: String
        },

        labelWidth: {
            type: Number,
            default: 60
        },

        value: {
            type: String
        }
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-computed-properties-in-data
            selected: this.selectedDefault
        };
    },

    computed: {
        selectedDefault() {
            return (this.showResetFilter && this.resetFilter.value) || null;
        }
    },

    watch: {
        value: {
            immediate: true,
            handler() {
                this.selected = typeof this.value === "undefined" ? this.selectedDefault : this.value;
            }
        }
    },

    methods: {
        getOptionValue(option) {
            return option.label || option.value;
        },

        onChange(value) {
            this.$emit("input", value);
        }
    }
};
</script>
