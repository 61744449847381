<template>
    <div v-if="visible" class="multiview-select-all">
        <Checkbox
            :disabled="!isEnabled"
            :indeterminate="indeterminate"
            :value="allChecked"
            data-testid="select-all__checkbox"
            @click.prevent.native="onCheckAll"
        >
            Select all
        </Checkbox>
        <span class="multiview-select-all__selected-count">{{ selected }} Selected</span>
    </div>
</template>
<script>
import bus from "@/bus";
import { MultiviewEvent } from "@/enums/multiview";

export default {
    name: "MultiviewSelectallControl",

    props: {
        instanceName: {
            type: String,
            default: "multiview"
        },
        showEmpty: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        allChecked() {
            return (
                !!(this.selected && !this.indeterminate) || (this.selected === this.totalCount && this.selected !== 0)
            );
        },

        indeterminate() {
            return !!(this.selected && this.selected !== this.pageCount && this.selected !== this.totalCount);
        },

        isEnabled() {
            return this.totalCount > 0;
        },

        pageCount() {
            return this.state ? this.state.pageCount : 0;
        },

        selected() {
            return this.state ? this.state.selected : 0;
        },

        state() {
            return this.$store.state.ui.multiviewComponents[this.instanceName];
        },

        totalCount() {
            return this.state ? this.state.totalCount : 0;
        },

        visible() {
            return this.totalCount || this.showEmpty;
        }
    },

    methods: {
        onCheckAll() {
            if (this.isEnabled) {
                bus.$emit(MultiviewEvent.SelectAllOnPage, {
                    value: !this.allChecked,
                    instance: this.instanceName
                });
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.multiview-select-all {
    display: inline-block;
    white-space: nowrap;
}

.multiview-select-all__selected-count {
    border-left: 1px solid $grey3;
    font-size: $font-size-base;
    padding: 0 0 0 $spacing-small;
}
</style>
