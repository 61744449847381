<template>
    <div class="account-signal__inner">
        <div class="account-signal__item" :class="{ 'account-signal__item--empty': !currentSignalType }">
            <div class="account-signal__select">
                <Select v-model="currentSignalType" label="Select trigger">
                    <Option value="DATETIME">Date and time</Option>
                    <Option value="WEATHER">Weather</Option>
                </Select>
            </div>
            <component
                :is="signalTypeComponent"
                v-if="currentSignalType"
                ref="signalForm"
                :signal-action="signalAction"
                :form-data="localData"
                :account-index="accountIndex"
                :rule-index="ruleIndex"
                :signal-index="signalIndex"
                @update="handleUpdateSignal"
                @disableSaveButton="$emit('disableSaveButton', false)"
            />
            <div
                v-if="currentSignalType"
                class="account-signal__delete"
                :class="{ 'account-signal__delete--disabled': signalAction === 'add' }"
            >
                <Icon type="md-trash" @click="deleteSignal" />
            </div>
        </div>
    </div>
</template>
<script>
import DatetimeSignalForm from "@/components/IntelligentDelivery/DatetimeSignalForm";
import WeatherSignalForm from "@/components/IntelligentDelivery/WeatherSignalForm";
import { SignalTypesList } from "@/enums/activate";

export default {
    name: "BasicAccountSignalForm",
    components: {
        DatetimeSignalForm,
        WeatherSignalForm
    },
    props: {
        signalAction: {
            type: String,
            default: "add"
        },
        accountIndex: {
            type: Number,
            default: 0
        },
        ruleIndex: {
            type: Number,
            default: 0
        },
        signalIndex: {
            type: Number,
            default: 0
        },
        formData: {
            type: Object
        }
    },
    data() {
        return {
            currentSignalType: "",
            localData: this.formData,
            weatherSignalTypes: {
                DATETIME: "DATETIME",
                WEATHER: [SignalTypesList.Temperature.id, SignalTypesList.Rain.id, SignalTypesList.Snow.id]
            }
        };
    },
    computed: {
        actionText() {
            if (this.signalAction === "edit") {
                return "Edit your signal";
            }
            return "Create a signal to define how and when your campaign should be activated.";
        },
        actionLabel() {
            if (this.signalAction === "edit") {
                return "Edit signal";
            }
            return "Add signal";
        },
        signalTypeComponent() {
            if (this.currentSignalType === "DATETIME") {
                return "datetime-signal-form";
            }

            return "weather-signal-form";
        },
        getWeatherSignaltype() {
            const signal = this.formData.signalType;
            const weatherSignals = this.weatherSignalTypes.WEATHER;
            if (weatherSignals.includes(signal)) {
                return "WEATHER";
            }
            if (this.formData.signalType === "DATETIME") {
                return "DATETIME";
            }
            return "";
        }
    },
    watch: {
        currentSignalType: {
            deep: true,
            handler() {
                this.localData = {};
            }
        }
    },
    created() {
        this.currentSignalType = this.getWeatherSignaltype;
    },
    methods: {
        close() {
            this.$emit("close");
        },

        handleUpdateSignal(data) {
            this.$emit("accountSignalAction", data);
        },

        deleteSignal() {
            this.$emit("deleteSignal", this.signalIndex);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.account-signal {
    &__form {
        padding: 4px 14px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .ivu-form-item {
            margin-bottom: 0;
            &.ivu-form-item-error {
                margin-bottom: 0;
                .ivu-select,
                .ivu-input {
                    border-color: #f9444e;
                }
            }
        }
        .ivu-form-item-label {
            font-weight: bold;
            text-align: left;
            width: 100%;
        }
        .ivu-form-item-content {
            width: 100%;
        }
        .ivu-btn-primary {
            margin-top: 30px;
        }
        .ivu-icon {
            font-size: 12px;
        }
        .ivu-date-picker {
            margin: 10px 0;
            width: 165px;
            &:last-child {
                margin-right: 0;
            }
            &.account-signal__time-picker {
                width: 110px;
            }
        }
        .ivu-select {
            .ivu-select-selection {
                padding: 9px 10px;
            }
            &.account-signal__operator {
                margin-right: 10px;
            }
            padding: 0;
            margin: 10px 0;
            height: 34px;
            width: auto;
        }
        .ivu-dropdown {
            margin: 10px 0;
        }
        .ivu-input {
            line-height: 19px;
        }
        .editable-group__values {
            display: flex;
            flex-direction: column;
            label {
                display: block;
                margin: 5px 0;
            }
        }
    }
    .hox-modal {
        width: 820px;
        &__body {
            background: var(--wpp-grey-color-100);
            margin: 0 20px;
        }
    }
    &__inner {
        position: relative;
        margin-bottom: 52px;
        &::before {
            content: "";
            position: absolute;
            top: 100%;
            left: 30px;
            width: 1px;
            height: 52px;
            background: var(--wpp-grey-color-400);
        }
        &::after {
            content: "AND";
            position: absolute;
            font-size: 12px;
            border-radius: 20px;
            color: #fff;
            background: var(--wpp-primary-color-500);
            padding: 5px;
            line-height: 12px;
            font-weight: bold;
            top: calc(100% + 13px);
            left: 11px;
            border: 1px solid var(--wpp-grey-color-400);
        }
        &:last-child {
            margin-bottom: 0;
            &::before,
            &::after {
                display: none;
            }
        }
    }
    &__item {
        display: flex;
        position: relative;
        border-radius: 8px;
        border: 1px solid var(--wpp-grey-color-400);
        background: var(--Grey-000, #fff);

        &--empty {
            height: 68px;
        }
    }
    &__select {
        width: 150px;
        padding: 0;
        position: absolute;
        left: 14px;
        top: 14px;
        .ivu-select {
            padding: 0;
        }
        .ivu-select-selection {
            padding: 9px 10px;
        }
    }
    &__wrap {
        display: flex;
        background: var(--wpp-grey-color-100);
        padding: 20px;
        margin-bottom: 10px;
        border-radius: 6px;
    }
    &__delete {
        display: flex;
        padding: 20px 10px;
        align-items: center;
        border-left: 1px solid var(--wpp-grey-color-400);
        cursor: pointer;
        &--disabled {
            cursor: not-allowed;
            i {
                opacity: 0.25;
            }
        }
    }
    &__text-input {
        margin: 10px 0;
        width: auto;
    }
    &__location {
        position: relative;
        width: auto;
        .account-signal__text-input {
            z-index: 2;
        }
        .account-signal__location-list {
            position: absolute;
            list-style: none;
            top: 34px;
            left: 0;
            width: 100%;
            background: #fff;
            border: 1px solid var(--wpp-grey-color-400);
            border-top: none;
            border-radius: 0 0 6px 6px;
            z-index: 1;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            padding: 10px 0;
            li {
                padding: 5px 10px;
                cursor: pointer;
                &:hover {
                    background: var(--wpp-grey-color-100);
                }
            }
        }
    }

    .ivu-select {
        width: auto;
    }
    &__header {
        span {
            display: block;
            font-size: 13px;
            font-weight: normal;
            margin-top: 10px;
        }
    }
    &__info {
        padding: 0 10px;
        display: flex;
        align-items: center;
        margin: 10px 0;

        &--first {
            padding-left: 160px;
        }
    }

    &__tabs {
        border-bottom: 2px solid var(--wpp-grey-color-400);
        margin-bottom: 20px;
        span {
            display: inline-block;
            padding: 5px 10px;
            margin-bottom: -2px;
            border-bottom: 2px solid var(--wpp-grey-color-400);
            cursor: pointer;
            &.active {
                border-bottom: 2px solid $primary-color;
            }
        }
    }
    &__weekdays-dropdown {
        .ivu-btn {
            span {
                color: var(--wpp-grey-color-900);
                font-weight: 400;
            }
        }
    }

    .ivu-form-item {
        display: flex;
    }
}
</style>
