<template>
    <div
        class="insights-tags"
        :class="{ 'insights-tags--closed': !isOpen, 'insights-tags--drawer-open': isDrawerOpen }"
    >
        <insights-marketplace-modal v-if="isMarketplaceOpen" :is-open.sync="isMarketplaceOpen" />
        <div class="insights-tags__list">
            <div class="insights-tags__marketplace">
                <InsightMarketplaceIconSvg />
                <h3>
                    Insights
                    <span>Marketplace</span>
                </h3>
                <Button @click="showMarketplace">Explore Now</Button>
            </div>
            <reporting-tags-list></reporting-tags-list>
        </div>
        <div class="pane-handle" :class="{ 'pane-handle--open': isOpen }" @click="toggle">
            <Icon v-if="isOpen" type="ios-arrow-back" />
            <Icon v-else type="ios-arrow-forward" />
        </div>
    </div>
</template>

<script>
import ReportingTagsList from "@/components/CreativeIntelligence/ReportingTagsList";
import { UiAction } from "@/store/modules/ui";
import InsightsMarketplaceModal from "@/components/CreativeIntelligence/InsightsMarketplaceModal";
import InsightMarketplaceIconSvg from "@/assets/insight-marketplace-icon.svg";

export default {
    name: "TagsDrawer",
    components: { InsightsMarketplaceModal, ReportingTagsList, InsightMarketplaceIconSvg },

    data() {
        return {
            isMarketplaceOpen: false
        };
    },

    computed: {
        isDrawerOpen() {
            return this.$store.state.creativeInsights.drawerCreatives.length;
        },

        isOpen() {
            return this.$store.state.ui.isSidebarOpen;
        }
    },

    methods: {
        showMarketplace() {
            this.isMarketplaceOpen = true;
        },

        toggle() {
            const action = this.isOpen ? UiAction.CloseSidebar : UiAction.OpenSidebar;
            this.$store.dispatch(action);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.creative-insights__tags-slider {
    .ivu-layout-sider-children {
        background-color: $white;

        &:has(> .insights-tags--closed) {
            background-color: transparent;
        }
    }
}

.insights-tags {
    width: 250px;
    flex: 0 1 250px;
    height: 100%;
    display: block;
    overflow: hidden;
    position: relative;
    background-color: $white;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 6;

    &--drawer-open {
        z-index: 4;
    }

    &--closed {
        width: 0;
        flex: 0 1 0;
        padding-left: 0;
        background-color: transparent;
    }

    &__list {
        position: absolute;
        top: 0;
        margin-top: -30px;
        flex: 1 1 100%;
        width: 100%;
        height: 100vh;
        transition: width 4s ease-in-out;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__marketplace {
        margin: $spacing-large 0 $spacing-largest 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;

        h3 {
            margin: $spacing-small 0 $spacing;
            font-size: 14px;

            span {
                color: $ci-blue;
            }
        }

        .ivu-btn {
            background-color: $ci-blue;
            color: white;
            border: 0 transparent;
        }
    }

    .pane-handle {
        &--open {
            left: 310px;
            transition: left 0.2s ease-in-out;
        }

        left: 60px;

        position: fixed;
        top: calc(50vh - 40px);

        height: 80px;
        background-color: $white;
        color: $ci-blue;
        width: 12px;
        flex: 0 0 12px;
        text-align: center;
        vertical-align: center;
        line-height: 80px;
        box-shadow: 5px 1px 8px rgba(0, 0, 0, 0.15);
        clip-path: inset(-5px -15px -5px 0);
        border-radius: 0 5px 5px 0;
        border: 0px none;
        border-left: 0;

        .ivu-icon {
            margin-left: -5px;
            font-size: 14px;
        }
    }
}
</style>
