<template>
    <div class="search-results">
        <h3 class="search-results__header">
            <span class="search-results__title">
                {{ styledPrompt }}
            </span>
            <span class="search-results__model white-space-nowrap">
                {{ ImagineModelToLabel[searchOptions.model] }}
            </span>
            <div
                v-if="searchOptions.model === ImagineModel.GPT3 && completeCounter > 0"
                class="search-results__view-change"
                @click="onChangeGPTField"
            >
                <span>Showing:</span>
                {{ gptField }}
            </div>
            <span class="link-like search-results__load-more" @click="loadMore">Re-roll</span>
            <span v-if="completeCounter < numberOfResults" class="search-results__duration white-space-nowrap">
                Processing: {{ searchDuration }}s
            </span>
            <span v-else class="search-results__duration">Took: {{ searchDuration }}s</span>
        </h3>
        <div class="search-results-previews">
            <component
                :is="componentName"
                v-for="resultNumber in numberOfResults"
                :key="searchOptions.model + searchOptions.prompt + resultNumber"
                :search-options="searchOptions"
                show-image-button
                class="search-results-preview"
                :hover-options="hoverOptions"
                :field-index="gptFieldIndex"
                @complete="onComplete"
                @baseImage="setBaseImage"
                @editImage="onEditImage"
                @updateCta="onUpdateCta"
            />
        </div>
    </div>
</template>
<script>
import SearchPreview from "@/components/Imagine/ModelPreview/SearchPreview";
import {
    GPT3FieldsSequence,
    ModelConfig,
    promptToStyledPrompt,
    ImagineModel,
    ImagineModelToLabel
} from "@/enums/imagine";
import Gpt3Preview from "@/components/Imagine/ModelPreview/Gpt3Preview";
import ChatgptPreview from "@/components/Imagine/ModelPreview/ChatgptPreview";

export default {
    name: "SearchResults",
    components: { Gpt3Preview, SearchPreview, ChatgptPreview },
    props: {
        searchOptions: {
            type: Object
        },
        hoverOptions: {
            type: Object,
            default() {
                return {
                    newWindow: true,
                    showArrows: true,
                    showEdit: true,
                    setAsBase: true,
                    showAddToLibrary: false
                };
            }
        }
    },

    data() {
        return {
            completeCounter: 0,
            gptFieldIndex: 0,
            reRollCounter: 0,
            searchDuration: 0
        };
    },

    computed: {
        componentName() {
            if (this.searchOptions.model === ImagineModel.GPT3) {
                return "gpt3-preview";
            }

            if (this.searchOptions.model === ImagineModel.ChatGPT) {
                return "chatgpt-preview";
            }

            return "search-preview";
        },

        gptField() {
            return GPT3FieldsSequence[this.gptFieldIndex % GPT3FieldsSequence.length];
        },

        numberOfResults() {
            return ModelConfig[this.searchOptions.model].numberOfResults + this.reRollCounter * this.numberOfReRolls;
        },

        numberOfReRolls() {
            return ModelConfig[this.searchOptions.model].numberOfReRolls;
        },

        styledPrompt() {
            return promptToStyledPrompt(this.searchOptions);
        }
    },

    watch: {
        completeCounter() {
            if (this.completeCounter >= this.numberOfResults) {
                clearInterval(this.updateInterval);
            }
        }
    },

    created() {
        this.ImagineModel = ImagineModel;
        this.ImagineModelToLabel = ImagineModelToLabel;
    },

    mounted() {
        this.searchStart = new Date();
        this.updateInterval = setInterval(this.updateDuration, 1000);
    },

    methods: {
        setBaseImage(url) {
            this.$emit("baseImage", url);
        },

        loadMore() {
            this.reRollCounter += 1;
        },

        onComplete() {
            this.completeCounter += 1;
        },

        onChangeGPTField() {
            this.gptFieldIndex += 1;
        },

        onEditImage(props) {
            this.$emit("editImage", props);
        },

        updateDuration() {
            this.searchDuration = Math.floor((new Date() - this.searchStart) / 1000);
        },

        onUpdateCta(cta) {
            this.$emit("updateCta", cta);
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/_variables.scss";

.search-results {
    margin-bottom: $spacing;
    display: flex;
    flex: 1 1 100%;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        width: 100%;
        margin-bottom: $spacing;
        line-height: 1;
    }

    &__model {
        font-weight: normal;
        margin-right: $spacing;
    }

    &__duration {
        margin-right: $spacing;
        font-weight: normal;
        font-size: 12px;
        white-space: nowrap;
    }

    &__title {
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: $spacing-larger;
    }

    &-previews {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 1fr;
        width: 100%;
        flex-wrap: wrap;
    }

    &-preview {
        flex: 1 1 22%;
        margin: 5px;
        position: relative;
        background: #ffffff;
    }

    &__load-more {
        font-size: 14px;
        margin: 0 $spacing 0 auto;
    }

    &__view-change {
        font-weight: normal;
        padding: 0 $spacing;
        margin-right: auto;
        cursor: pointer;

        span {
            font-weight: bold;
        }
    }
}
</style>
