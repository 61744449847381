<template>
    <hox-modal class="publish-modal" @close="close">
        <template #header>
            <Icon type="logo-facebook" size="43" />
            <span>{{ title }}</span>
        </template>
        <template>
            <facebook-creative-manager-settings
                v-if="isFacebook"
                :deliverables="deliverables"
                :library-filters-for-query="libraryFiltersForQuery"
                :action="selectedAction"
                @publishHandlerReady="onPublishHandlerReady"
            />
        </template>

        <template #footer>
            <Button class="publish-modal__button" :disabled="loading" @click="close">Cancel</Button>
            <Button
                class="publish-modal__button"
                :disabled="!readyToSubmit"
                :loading="loading"
                type="primary"
                @click="submit"
            >
                {{ actionText }}
            </Button>
        </template>
    </hox-modal>
</template>

<script>
import HoxModal from "@/components/Modal/Modal/Modal";
import { Platform, PlatformLabel } from "@/enums/platforms";
import FacebookCreativeManagerSettings from "@/components/Campaign/Publish/Facebook/FacebookCreativeManagerSettings";

export default {
    components: {
        FacebookCreativeManagerSettings,
        HoxModal
    },
    props: {
        action: {
            required: true,
            type: String
        },

        deliverables: {
            required: true,
            type: Array
        },

        libraryFiltersForQuery: {
            default: () => {},
            required: false,
            type: Object
        },

        platform: {
            required: true,
            type: String
        }
    },

    data() {
        return {
            selectedAction: this.action,
            publishHandler: null,
            loading: false
        };
    },

    computed: {
        actionText() {
            if (this.selectedAction === "unpublish") {
                return "Unpublish";
            }
            if (this.selectedAction === "rebuild") {
                return "Reset";
            }
            return "Publish";
        },

        canPublishDeliverable() {
            return this.$auth.userCan(this.$auth.Actions.CanPublishDeliverableToAdServer, {
                clientId: this.clientId,
                campaignId: this.campaignId
            });
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        isFacebook() {
            return this.platform === Platform.Facebook;
        },

        readyToSubmit() {
            return typeof this.publishHandler === "function";
        },

        title() {
            const platformLabel = PlatformLabel[this.platform];

            if (this.selectedAction === "publish") {
                return `${this.actionText} to ${platformLabel}`;
            }
            if (this.selectedAction === "unpublish") {
                return `${this.actionText} from ${platformLabel}`;
            }
            if (this.selectedAction === "rebuild") {
                return `${this.actionText} ${platformLabel}`;
            }

            return this.selectedAction;
        }
    },

    watch: {
        canPublishDeliverable() {
            if (!this.canPublishDeliverable) {
                this.$snackbar.warning("You do not have access to publish deliverables");
                this.close();
            }
        }
    },

    created() {
        this.Platform = Platform;
        this.PlatformLabel = PlatformLabel;
    },

    methods: {
        close() {
            this.$emit("close");
        },

        onLoadingStateChanged({ loading }) {
            this.loading = loading;
        },

        onPublishHandlerReady(publishHandler) {
            this.publishHandler = publishHandler;
        },

        async submit() {
            this.loading = true;
            if (this.readyToSubmit) {
                await this.publishHandler();
                this.loading = false;
                this.close();
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.publish-modal__button {
    margin: 0 0 0 $spacing-small;
}
</style>
