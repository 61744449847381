<template>
    <div :class="classes">
        <Drawer :transfer="false" :value="true" width="90" @on-close="close">
            <hox-loading-layer v-if="isLoadingDeliverable" :is-full-screen="false" />
            <template #header>
                <!--
          This empty div is used to trick the drawer component into not hiding the
          header slot if we are loading a deliverable.

          If we didn't do this then the header would not be shown when isLoadingDeliverable
          is switched back to false.
        -->
                <div>
                    <div v-if="drawerHeaderIsVisible" class="deliverable-drawer-header hox-drawer-header">
                        <ButtonGroup
                            class="deliverable-drawer-nav hox-drawer-nav"
                            :class="{ 'deliverable-drawer-nav--bulk': isBulk }"
                        >
                            <Button
                                v-if="!isBulk"
                                class="deliverable-drawer-nav-item deliverable-drawer-nav-item--preview hox-drawer-item hox-drawer-item--preview"
                                :type="activeTab === 'preview' ? 'primary' : 'default'"
                                @click="activeTab = 'preview'"
                            >
                                Preview
                            </Button>
                            <Button
                                class="deliverable-drawer-nav-item deliverable-drawer-nav-item--qa hox-drawer-nav-item hox-drawer-nav-item--qa"
                                :type="activeTab === 'qa' ? 'primary' : 'default'"
                                @click="activeTab = 'qa'"
                            >
                                Quality Assurance
                            </Button>
                        </ButtonGroup>
                        <div v-if="headerDeliverable" class="deliverable-drawer-header-info hox-drawer-header-info">
                            <h3
                                class="deliverable-drawer-header-info__label hox-drawer-header-info__label"
                                :title="deliverableReportingLabel"
                            >
                                <div
                                    class="deliverable-drawer-header-info__label-text hox-drawer-header-info__label-tex"
                                >
                                    {{ deliverableReportingLabel }}
                                </div>
                            </h3>
                            <qa-status-label :status="deliverableStatus" />
                        </div>
                    </div>
                </div>
            </template>
            <template #close>
                <div
                    class="deliverable-drawer__close-icon-container hox-drawer__close-icon-container"
                    data-testid="qa-drawer__close"
                >
                    <Icon size="44" type="ios-close" @click="close" />
                </div>
            </template>

            <div v-if="!isLoadingDeliverable" class="deliverable-drawer__container hox-drawer__container">
                <div class="deliverable-drawer__body hox-drawer__body">
                    <div
                        v-if="hasErrorLoadingDeliverable || deliverableDoesNotExist"
                        class="deliverable-drawer__error-message-wrapper"
                    >
                        <hox-alert v-if="deliverableDoesNotExist" type="info">
                            <template #title>You do not have access to this ad</template>
                            <template #content>
                                <p>
                                    Check with your administrator that the ad you’re looking for is still live or
                                    request access.
                                </p>
                            </template>
                        </hox-alert>
                        <hox-alert v-else type="danger">
                            <template #title>Uh oh!</template>
                            <template #content>
                                <p>Sorry! We could not get your ads this time. Try again a moment.</p>
                            </template>
                            <template #actionItems>
                                <Button type="primary" @click="getDeliverable">Retry</Button>
                            </template>
                        </hox-alert>
                    </div>
                    <deliverable-preview-drawer-tab
                        v-else-if="activeTab === 'preview'"
                        :deliverable="internalPreviewDeliverable"
                    />

                    <qa-drawer-tab
                        v-else-if="activeTab === 'qa'"
                        :all-selected-mode="isFilterBulk"
                        :deliverables="internalQaDeliverables"
                        :library-filters-for-query="libraryFiltersForQuery"
                        @selectedDeliverableUpdate="onSelectedDeliverableUpdate"
                        @statusHasBeenUpdated="$emit('statusHasBeenUpdated')"
                        @userStatusHasBeenUpdated="$emit('userStatusHasBeenUpdated')"
                        @annotationsHasBeenUpdated="$emit('annotationsHasBeenUpdated')"
                    />
                </div>
            </div>
        </Drawer>
    </div>
</template>

<script>
import DeliverablesService from "@/services/Deliverables";
import URLManager from "@/services/URLManager";
import { CampaignGetters } from "@/store/modules/campaign";
import { DeliverableLibraryAction, DeliverableLibraryGetters } from "@/store/modules/deliverableLibrary";
import QaStatusLabel from "@/components/Qa/QaStatusLabel";
import DeliverablePreviewDrawerTab from "@/components/Campaign/DeliverablePreviewDrawer";
import QaDrawerTab from "@/components/Qa/DrawerTab/QaDrawerTab";

const QueryParams = {
    ActiveTab: "dd-at",
    EditableGroupValueIds: "dd-gv",
    MastertTemplateId: "dd-mt",
    Language: "dd-lng",
    BulkFilterQa: "dd-bfqa"
};

export default {
    components: {
        QaDrawerTab,
        DeliverablePreviewDrawerTab,
        QaStatusLabel
    },
    props: {
        allSelectedMode: {
            type: Boolean,
            default: false
        },

        deliverables: {
            type: Array,
            required: true
        },

        libraryFiltersForQuery: {
            required: false,
            type: Object
        }
    },

    data() {
        return {
            deliverableDoesNotExist: false,
            fetchedDeliverables: undefined,
            hasErrorLoadingDeliverable: false,
            isLoadingDeliverable: false,
            selectedDeliverable: null
        };
    },

    computed: {
        activeTab: {
            get() {
                return this.$store.state.deliverableLibrary.drawer.activeTab;
            },
            set(value) {
                this.$store.dispatch(DeliverableLibraryAction.SetDrawerActiveTab, value);
            }
        },

        campaignId() {
            return this.$route.params.campaignId;
        },

        classes() {
            const classes = ["deliverable-drawer", "hox-drawer"];
            /*
               As we can't simply hide/show the header slot, we are going to
               control the headers visibility using CSS.
            */
            if (!this.drawerHeaderIsVisible) {
                classes.push("deliverable-drawer--hide-header");
            }
            return classes;
        },

        deliverableStatus() {
            return this.$store.getters[DeliverableLibraryGetters.computedDeliverableStatus](
                this.headerDeliverable.idHash
            );
        },

        deliverableReportingLabel() {
            return this.headerDeliverable.reportingLabel;
        },

        drawerHeaderIsVisible() {
            return !this.isLoadingDeliverable && !this.hasErrorLoadingDeliverable && !this.deliverableDoesNotExist;
        },

        headerDeliverable() {
            if (this.isFilterBulk) {
                return this.selectedDeliverable;
            }

            return this.selectedDeliverable || this.internalDeliverables[0];
        },

        internalDeliverables() {
            return this.deliverables.length ? this.deliverables : this.fetchedDeliverables;
        },

        internalPreviewDeliverable() {
            return this.internalDeliverables[0];
        },

        internalQaDeliverables() {
            if (this.isFilterBulk) {
                // We just need to pass the filters, the child component will figure out the content based on filters.
                return [];
            }

            return this.internalDeliverables;
        },

        isArrayBulk() {
            return Array.isArray(this.deliverables) && this.deliverables.length > 1;
        },

        isBulk() {
            return this.isArrayBulk || this.isFilterBulk;
        },

        isFilterBulk() {
            return (this.allSelectedMode && !this.isSingleDeliverable) || !!this.$route.query[QueryParams.BulkFilterQa];
        },

        isSingleDeliverable() {
            return Array.isArray(this.deliverables) && this.deliverables.length === 1;
        }
    },

    watch: {
        activeTab() {
            this.setActiveTabQueryParams();
        }
    },

    beforeDestroy() {
        this.unsetAllQueryParams();
    },
    // eslint-disable-next-line complexity
    created() {
        this.deliverablesService = new DeliverablesService(this.$apollo, this.$store);
        this.urlManager = new URLManager(this.$router, {
            defaults: [
                {
                    key: QueryParams.ActiveTab,
                    value: ["qa"]
                }
            ],
            tracked: Object.values(QueryParams)
        });

        if (this.deliverables && this.deliverables.length) {
            if (this.isSingleDeliverable) {
                this.setDeliverableQueryParams();
            }
            // TODO: add query param settings for the bulk QA
        } else if (!this.isBulk) {
            this.getDeliverableFromQueryParams();
        }

        if (!this.activeTab) {
            this.activeTab = this.$route.query[QueryParams.ActiveTab] || "qa";
        }

        this.setActiveTabQueryParams();
        this.setBulkFilterQaQueryParam();
    },

    methods: {
        campaignHasAllEditableGroupValueIds(editableGroupValueIds) {
            return editableGroupValueIds.every(
                groupValueId => this.$store.state.campaign.editableGroupValues[groupValueId]
            );
        },

        campaignHasLanguage(language) {
            return !!this.$store.state.campaign.languages.includes(language);
        },

        campaignHasMasterTemplateId(masterTemplateId) {
            return !!this.$store.state.campaign.normalized.masterTemplates[masterTemplateId];
        },

        close() {
            this.$emit("closed");
        },

        // eslint-disable-next-line complexity
        async getDeliverableFromQueryParams() {
            this.hasErrorLoadingDeliverable = false;
            this.isLoadingDeliverable = true;
            const editableGroupValueIdsQueryParams = this.$route.query[QueryParams.EditableGroupValueIds];
            const editableGroupValueIds = Array.isArray(editableGroupValueIdsQueryParams)
                ? editableGroupValueIdsQueryParams
                : [editableGroupValueIdsQueryParams];
            const masterTemplateId = this.$route.query[QueryParams.MastertTemplateId];
            const language = this.$route.query[QueryParams.Language];
            if (
                !this.campaignHasAllEditableGroupValueIds(editableGroupValueIds) ||
                !this.campaignHasMasterTemplateId(masterTemplateId) ||
                !this.campaignHasLanguage(language)
            ) {
                this.deliverableDoesNotExist = true;
                this.isLoadingDeliverable = false;
                this.close();
                return;
            }
            const groupValuesForQuery = editableGroupValueIds.map(groupValueId => {
                const groupName = this.$store.getters[CampaignGetters.groupByGroupValueId][groupValueId];
                return {
                    name: groupName,
                    valueIds: [groupValueId]
                };
            });
            try {
                const { deliverables } = await this.deliverablesService.getPaginatedDeliverables(
                    this.campaignId,
                    {
                        groupValueFilters: groupValuesForQuery,
                        qaStatusFilters: null,
                        publishedStatusFilters: null,
                        sizeFilters: [masterTemplateId],
                        languageFilters: [language]
                    },
                    1,
                    1
                );
                const [deliverable] = deliverables;
                if (!deliverable) {
                    this.deliverableDoesNotExist = true;
                    this.isLoadingDeliverable = false;
                    return;
                }
                this.$store.dispatch(DeliverableLibraryAction.SetDeliverableStatusByIdHash, [deliverable]);
                this.fetchedDeliverables = [deliverable];
            } catch (err) {
                this.hasErrorLoadingDeliverable = true;
            }
            this.isLoadingDeliverable = false;
        },

        onSelectedDeliverableUpdate(deliverable) {
            if (deliverable) {
                this.selectedDeliverable = deliverable;
            }
        },

        setActiveTabQueryParams() {
            this.urlManager.setURLParams(
                [
                    {
                        key: QueryParams.ActiveTab,
                        value: [this.activeTab]
                    }
                ],
                true
            );
        },

        setBulkFilterQaQueryParam() {
            this.urlManager.setURLParams(
                [
                    {
                        key: QueryParams.BulkFilterQa,
                        value: this.isFilterBulk ? [1] : []
                    }
                ],
                true
            );
        },

        setDeliverableQueryParams() {
            this.urlManager.setURLParams(
                [
                    {
                        key: QueryParams.EditableGroupValueIds,
                        value: this.internalDeliverables[0].editableGroupValueIds
                    },
                    {
                        key: QueryParams.MastertTemplateId,
                        value: [this.internalDeliverables[0].masterTemplate._id]
                    },
                    {
                        key: QueryParams.Language,
                        value: [this.internalDeliverables[0].language]
                    }
                ],
                true
            );
        },

        unsetAllQueryParams() {
            this.urlManager.setURLParams(
                Object.values(QueryParams).map(key => ({
                    key,
                    value: []
                })),
                true
            );
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.deliverable-drawer {
}
</style>
