<template>
    <Card class="insights-dashboard-tag-component__layout">
        <delete-tag-confirmation-modal
            v-if="isConfirmationModalShown"
            @confirm="deleteTag"
            @cancel="hideConfirmationModal"
        />

        <hox-loading-layer v-if="isLoading" :is-full-screen="false" />
        <div v-if="showContent" class="insights-dashboard-tag-component__layout-content">
            <!-- eslint-disable-next-line vue/valid-v-show  -->
            <template v-show="!isLoading && !hasError">
                <div class="insights-dashboard-tag-component__layout-header">
                    <h3 class="insights-dashboard-tag-component__layout-header-name" @click="goToTagInsights">
                        <slot name="contentTitle">
                            <Icon type="ios-arrow-dropright"></Icon>
                            {{ tag.name }}
                        </slot>
                    </h3>
                    <slot name="filters"></slot>
                    <div class="insights-dashboard-tag-component__layout-header-actions">
                        <Icon type="md-cog" @click="setShowContent(false)"></Icon>
                        <Icon type="ios-move" class="insights-dashboard-tag-component__drag-handle"></Icon>
                    </div>
                </div>

                <div v-if="hasError" class="insights-dashboard-tag-component__layout-body">
                    <hox-empty-message class="insights-dashboard-tag-component__layout-error-message">
                        <template #title>
                            <span v-if="errorMessage">{{ errorMessage }}</span>
                            <span v-else>Request failed, please check the query and retry</span>
                        </template>
                        <template #actionItems>
                            <Button v-if="!errorMessage" type="primary" @click="retry">Retry</Button>
                        </template>
                    </hox-empty-message>
                </div>
                <div v-else class="insights-dashboard-tag-component__layout-body">
                    <slot name="contentBody"></slot>
                </div>
            </template>
        </div>
        <div v-else class="insights-dashboard-tag-component__layout insights-dashboard-tag-component_settings">
            <div class="insights-dashboard-tag-component__layout-header">
                <h3>
                    <slot name="settingsTitle">{{ tag.name }} settings</slot>
                </h3>
                <Icon type="md-close" @click="setShowContent(true)"></Icon>
            </div>
            <div class="insights-dashboard-tag-component__layout-body">
                <slot name="settingsBody"></slot>
            </div>
            <div class="insights-dashboard-tag-component__layout-footer">
                <slot name="settingsFooter">
                    <Button
                        type="error"
                        class="insights-dashboard-tag-component_settings__footer-button insights-dashboard-tag-component_settings__footer-button--delete"
                        @click="showConfirmationModal"
                    >
                        Delete
                    </Button>

                    <Button
                        class="insights-dashboard-tag-component_settings__footer-button"
                        @click="setShowContent(true)"
                    >
                        Close
                    </Button>
                    <Button
                        class="insights-dashboard-tag-component_settings__footer-button insights-dashboard-tag-component_settings__footer-button--save"
                        type="primary"
                        @click="save"
                    >
                        Save
                    </Button>
                </slot>
            </div>
        </div>
    </Card>
</template>
<script>
import { TagsAction } from "@/store/modules/ciTags";
import DeleteTagConfirmationModal from "@/components/CreativeIntelligence/DeleteTagConfirmationModal";

export default {
    name: "InsightsDashboardTagLayout",
    components: { DeleteTagConfirmationModal },
    props: {
        tag: {
            type: Object,
            default() {
                return {
                    name: ""
                };
            }
        },

        isLoading: {
            type: Boolean,
            default: false
        },

        hasError: {
            type: Boolean,
            default: false
        },

        errorMessage: {
            type: String,
            default: ""
        }
    },

    data() {
        return {
            showContent: true,
            isConfirmationModalShown: false
        };
    },

    computed: {
        isDeleting() {
            return this.$store.state.ciTags.isDeleting;
        }
    },

    methods: {
        async deleteTag() {
            try {
                await this.$store.dispatch(TagsAction.DeleteTag, this.tag);
                this.hideConfirmationModal();
            } catch (e) {
                console.log(e);
            }
        },

        goToTagInsights() {
            this.$store.dispatch(TagsAction.SelectTags, [this.tag.tagId]);
            this.$router.push({ name: "CITagInsights", params: { tagId: this.tag.tagId } });
        },

        hideConfirmationModal() {
            this.isConfirmationModalShown = false;
        },

        retry() {
            this.$emit("retry");
        },

        save() {
            this.$emit("saveSettings");
            this.setShowContent(true);
        },

        setShowContent(val) {
            this.showContent = val;
            this.$emit("showContent", this.showContent);
        },

        showConfirmationModal() {
            this.isConfirmationModalShown = true;
        }
    }
};
</script>
<style lang="scss">
@import "@/../sass/_variables.scss";

.insights-dashboard-tag-component__layout {
    width: 100%;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;

    &-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: $spacing-small;

        &-name {
            cursor: pointer;
            margin-right: auto;
        }
    }

    &-body {
        width: 100%;
        height: 380px;
    }

    &-footer {
        width: 100%;
        display: flex;
    }

    &-error-message {
        margin-top: $spacing-larger;
    }

    .ivu-card-body {
        width: 100%;
        height: 100%;
    }
}
.insights-dashboard-tag-component_settings {
    .insights-dashboard-tag-component__layout {
        &-body {
            width: 260px;
            align-self: center;
            height: 350px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &__footer-button {
        &--delete {
            margin-right: auto;
        }

        &--save {
            margin-left: $spacing-semi-small;
        }
    }
}
</style>
