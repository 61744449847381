<template>
    <hox-notification-item
        :show-button="notification.downloadLink !== undefined"
        :show-clear-button="showClearButton"
        :show-error-message="hasFailed"
        :status="notification.status"
        @clear="removeNotification"
    >
        <template #title>
            {{ title }}
        </template>
        <template #subtitle>
            {{ statusText }}
        </template>
        <template #content>
            <template v-if="isCompleted">
                <p>Here is the link to download. We have also sent you a link via email.</p>
                <template v-if="failedLabels.length">
                    <p>The following ads could not be generated:</p>
                    <ul>
                        <li v-for="label in failedLabels" :key="label">{{ label }}</li>
                    </ul>
                </template>
            </template>
            <template v-else-if="!hasFailed">
                <p v-if="isTikTokJob">
                    Your video(s) are processing, and will be pushed directly to TikTok Ads Manager.
                </p>
                <p v-else-if="isFacebookJob">
                    Your video(s) are processing, and will be pushed directly to Facebook Ads Manager.
                </p>
                <p v-else>
                    Once the ads are ready to download, we will send you a download link via email. You will also find
                    the link in this tab.
                </p>
                <div v-if="subProgress" class="download-job-notification-item__sub-progress">
                    <div v-for="(deliverable, hash) in subProgress" :key="hash">
                        <div class="download-job-notification-item__sub-progress-label">
                            <p>{{ deliverable.label }}</p>
                            <p>{{ deliverable.percent }}%</p>
                        </div>
                        <hox-progress-bar
                            :is-animated="true"
                            :progress-percentage="deliverable.percent"
                            type="in-progress"
                        />
                    </div>
                    <p v-if="invisibleProgressCount > 0">Plus {{ invisibleProgressCount }} more in progress...</p>
                </div>
            </template>
        </template>
        <template #errorMessage>
            <p>There was an unexpected issue while preparing ads for download.</p>
            <template v-if="failedLabels.length">
                <p>The following ads could not be generated:</p>
                <ul>
                    <li v-for="label in failedLabels" :key="label">{{ label }}</li>
                </ul>
            </template>
        </template>
        <template #button>
            <Button size="small" target="_blank" :to="notification.downloadLink">Download</Button>
        </template>
    </hox-notification-item>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import nl2br from "shared-utils/nl2br";
import { NotificationStatus } from "@/enums/notifications";
import { NotificationsAction } from "@/store/modules/notifications";

export default {
    props: {
        notification: {
            required: true,
            type: Object
        }
    },

    data() {
        return {
            hasHadCompletedAndTotalValues: false,
            subProgressShowCount: 5,
            serviceTitles: {
                PsdDownloader: "PSD",
                ScreenshotDownloader: "image",
                VideoDownloader: "video"
            }
        };
    },
    computed: {
        hasFailed() {
            return this.notification.status === NotificationStatus.Failed;
        },

        failedLabels() {
            const {
                payload: { failedTasks }
            } = this.notification.job;

            if (!failedTasks) {
                return [];
            }

            return Object.values(failedTasks).map(({ label }) => label);
        },

        invisibleProgressCount() {
            const { payload } = this.notification.job;
            if (!payload.progress) {
                return 0;
            }

            return (
                Object.values(payload.progress).filter(prog => prog.percent < 100).length - this.subProgressShowCount
            );
        },

        isCompleted() {
            return this.notification.status === NotificationStatus.Completed;
        },

        notificationMessage() {
            return nl2br(this.notification.message);
        },

        showClearButton() {
            return this.hasFailed || this.isCompleted;
        },

        statusText() {
            if (this.hasFailed) {
                return "Failed";
            }
            return this.notificationMessage;
        },

        subProgress() {
            const { payload } = this.notification.job;
            if (!payload.progress) {
                return null;
            }
            // We could sort here to put them in order of highest progress however this means that jobs
            // running at different speeds tend to jostle for the top 5 position and so the displayed
            // items switch around on every update
            return Object.values(payload.progress)
                .filter(prog => prog.percent < 100)
                .slice(0, this.subProgressShowCount);
        },

        isTikTokJob() {
            const {
                payload: { tikTokData }
            } = this.notification.job;
            return tikTokData != null;
        },

        isFacebookJob() {
            const {
                payload: { facebookData }
            } = this.notification.job;
            return facebookData != null;
        },

        title() {
            if (this.isTikTokJob) {
                return "Publish to TikTok Ads Manager";
            }
            if (this.isFacebookJob) {
                return "Publish to Facebook Ads Manager";
            }
            return `Download ${this.serviceTitles[this.notification.job.name] || "your"} ads`;
        }
    },

    methods: {
        removeNotification() {
            this.$store.dispatch(NotificationsAction.RemoveItem, this.notification.id);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.download-job-notification-item__sub-progress {
    margin-top: $spacing-small;
    padding-left: $spacing-base;
    border-left: 1px solid $cmp-light-border-color;

    &-label {
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        margin-bottom: $spacing-smaller;

        > :first-child {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
</style>
