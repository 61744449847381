<template>
    <div class="editable-group-checkbox-list editable-groups-filters">
        <template v-if="editableGroupsFilterOptions.length">
            <collapsible-widget content-padding="none" :is-open="true">
                <template #name>Content</template>
                <template #controls>
                    <Badge
                        v-if="countOfSelectedGroupValues > 0"
                        :count="countOfSelectedGroupValues"
                        class="filter-badge-count"
                    />
                </template>
                <div v-for="editableGroup in editableGroupsFilterOptions" :key="editableGroup.name">
                    <editable-group-checkbox
                        :group-name="editableGroup.name"
                        :label="editableGroup.label"
                        :options="editableGroup.options"
                    />
                </div>
            </collapsible-widget>
        </template>
    </div>
</template>

<script>
import EditableGroupCheckbox from "@/components/Campaign/EditableGroupCheckbox";
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";
import { DeliverableLibraryGetters } from "@/store/modules/deliverableLibrary";

export default {
    name: "EditableGroupCheckboxList",
    components: {
        CollapsibleWidget,
        EditableGroupCheckbox
    },

    computed: {
        countOfSelectedGroupValues() {
            const selectedValues = this.$store.getters[DeliverableLibraryGetters.groupValueFilters]();
            return selectedValues.reduce((count, { groupValues = [] }) => count + groupValues.length, 0);
        },

        editableGroupsFilterOptions() {
            return this.$store.state.deliverableLibrary.filterOptions.editableGroups;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.editable-group-checkbox-list {
    & > .ivu-collapse > .ivu-collapse-item-active .ivu-collapse-header {
        border-bottom: 1px solid $cmp-light-filters-border-color;
    }

    .collapsible-widget--small {
        &.ivu-collapse > .ivu-collapse-item {
            > .ivu-collapse-header {
                padding: 0 0 0 $spacing-large;
                border: none;

                &:hover {
                    background: $cmp-light-filters-hover-color;
                }
            }
        }
    }
}
</style>
