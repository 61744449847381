<template>
    <div class="editable-video-thumbnail" :class="{ 'editable-video-thumbnail--locked': !canEdit }">
        <deliverable-label
            v-if="label"
            class="editable-video-thumbnail__label"
            :color="labelColor"
            :label="label"
        ></deliverable-label>
        <video
            v-if="value"
            ref="video"
            width="100"
            height="82"
            class="editable-video-thumbnail__thumbnail editable-video-thumbnail__video"
            @mouseenter="playVideo"
            @mouseleave="stopVideo"
            @click="onClick('')"
        >
            <source :src="value" type="video/mp4" />
        </video>
        <div v-else class="editable-video-thumbnail__icon editable-video-thumbnail__thumbnail" @click="onClick('')">
            <Icon :type="iconType"></Icon>
        </div>
        <div class="editable-video-thumbnail__footer">
            <div class="editable-video-thumbnail__footer--name" :title="fileName">
                {{ fileName }}
            </div>
            <div class="editable-video-thumbnail__footer--icon">
                <Icon type="ios-search" @click="onClick('icon')" />
            </div>
        </div>
    </div>
</template>
<script>
import DeliverableLabel from "@/components/Campaign/DeliverableLabel";
import { EditableType } from "@/enums/editables";

export default {
    name: "EditableVideoThumbnail",
    components: { DeliverableLabel },
    props: {
        value: {
            type: String,
            default: ""
        },

        mediaItem: {
            type: Object,
            default: null
        },

        label: {
            type: String
        },

        labelColor: {
            type: String,
            default: "default"
        },

        canEdit: {
            type: Boolean,
            default: true
        },

        filetype: {
            type: String,
            default: EditableType.Video
        }
    },

    computed: {
        fileName() {
            // todo update default value
            return this.mediaItem ? this.mediaItem.name : "Default";
        },

        iconType() {
            return "md-videocam";
        }
    },

    methods: {
        onClick(target) {
            this.$emit(target ? `${target}-click` : "click");
        },

        playVideo() {
            if (this.$refs.video) {
                this.$refs.video.play();
            }
        },

        stopVideo() {
            if (this.$refs.video) {
                this.$refs.video.pause();
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_mixins.scss";
@import "../../../sass/variables";

$preview-width: 100px;

.editable-video-thumbnail {
    @include make-checked-background(#808080, 5px);
    display: flex;
    flex: 0 0 $preview-width;
    width: $preview-width;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid $cmp-dark-bg-color;
    border-radius: 0 0 2px 2px;
    position: relative;

    &__label {
        position: absolute;
        top: 5px;
        left: 0;
        z-index: 2;
    }

    &__video {
        width: 100%;
        height: 82px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &__thumbnail {
        cursor: pointer;
    }

    &__icon .ivu-icon {
        font-size: 84px;
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 31px;
        line-height: 31px;
        overflow: hidden;
        padding: 0 5px;
        background-color: $cmp-dark-secondary-sidebar-color;

        &--icon {
            display: none;
            cursor: pointer;
        }

        &--name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &:hover {
        .editable-video-thumbnail__footer--icon {
            display: flex;
        }
    }

    &--locked:hover {
        .editable-video-thumbnail__footer--icon {
            display: none;
        }

        .editable-video-thumbnail__video {
            cursor: default;
        }
    }
}
</style>
