<template>
    <div class="library-deliverable-table-image-cell">
        <div class="library-deliverable-table-image-cell__image-container">
            <div
                v-if="!showFolderIcon"
                class="library-deliverable-table-image-cell__image"
                :style="{
                    backgroundImage: `url( ${src} )`
                }"
            />
            <img
                v-else
                class="library-deliverable-table-image-cell__fallback-image"
                src="/img/open-folder-outline.svg"
            />
        </div>
        <span class="library-deliverable-table-image-cell__text">
            <a :title="filename" :href="linkToAsset" target="_blank">
                {{ filename }}
            </a>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        fallbackToFolderIcon: {
            type: Boolean
        },
        linkUrl: {
            type: String
        },
        src: {
            required: true,
            type: String
        },
        title: {
            type: String
        }
    },
    data() {
        return {
            showFolderIcon: false
        };
    },
    computed: {
        filename() {
            try {
                const url = new URL(this.src);
                return url.pathname.split("/").pop();
            } catch (err) {
                return this.title;
            }
        },
        linkToAsset() {
            return this.linkUrl || this.src;
        }
    },
    watch: {
        src: {
            handler() {
                if (this.fallbackToFolderIcon) {
                    const image = new Image();
                    image.onerror = () => {
                        this.showFolderIcon = true;
                    };
                    image.src = this.src;
                } else {
                    this.showFolderIcon = false;
                }
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_mixins.scss";
@import "@/../sass/_variables.scss";

$check-color: $grey3;
$image-width: 80px;

.library-deliverable-table-image-cell {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    padding: $spacing-smaller 0;
    margin: (0 - $spacing-small) 0;
}

.library-deliverable-table-image-cell__fallback-image {
    filter: invert(40%);
    height: 90%;
}

.library-deliverable-table-image-cell__image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.library-deliverable-table-image-cell__image-container {
    @include make-checked-background($grey3, $spacing-small, $white);
    align-items: center;
    border: 1px solid $grey3;
    display: flex;
    height: 50px;
    justify-content: center;
    margin: 0 $spacing-small 0 0;
    min-width: $image-width;
    position: relative;
    width: $image-width;
}

.library-deliverable-table-image-cell__text {
    color: $grey5;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
