import { EditableType } from "@/enums/editables";
import { deepClone } from "@/utils";
import { defaultMediaLibrarySortBy } from "@/components/SortMediaLibrary";

const storeNamespace = "mediaLibrary";

export const MediaLibraryAction = {
    Open: `${storeNamespace}/open`,
    Close: `${storeNamespace}/close`,
    SelectFile: `${storeNamespace}/selectFile`,
    SelectFiles: `${storeNamespace}/selectFiles`,
    SelectFolder: `${storeNamespace}/selectFolder`,
    SetActiveFile: `${storeNamespace}/setActiveFile`,
    SetActiveFolder: `${storeNamespace}/setActiveFolder`,
    SetSandboxSelectedClient: `${storeNamespace}/setSandboxSelectedClient`,
    SetSortBy: `${storeNamespace}/setSortBy`,
    SetUseAutoResize: `${storeNamespace}/setUseAutoResize`,
    ResetState: `${storeNamespace}/resetState`
};

export const MediaLibraryMutation = {
    SetActiveFile: "setActiveFile",
    SetActiveFolder: "setActiveFolder",
    SetActiveInstance: "setActiveInstance",
    SetCloseCallback: "setCloseCallback",
    SetOpen: "setOpen",
    SetSandboxSelectedClient: "setSandboxSelectedClient",
    SetShowGridView: "setShowGridView",
    SetSortBy: "setSortBy",
    SetUseAutoResize: "setUseAutoResize",
    ResetState: "resetState"
};

export const MediaLibraryTab = {
    Library: "library",
    Link: "link"
};

const getDefaultState = () => ({
    activeFile: null,
    activeFolder: null,
    activeInstance: null,
    closeCallback: null,
    open: false,
    sandboxSelectedClient: null,
    showGridView: true,
    sortBy: defaultMediaLibrarySortBy,
    useAutoResize: false
});

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        activeFolderName: state =>
            state.activeFolder
                ? state.activeFolder
                      .split("/")
                      .filter(folder => folder.trim() !== "")
                      .pop()
                : ""
    },
    mutations: {
        setActiveFile(state, fileId) {
            state.activeFile = fileId;
        },

        setActiveFolder(state, payload) {
            state.activeFolder = payload.folder;
        },

        setActiveInstance(state, payload) {
            state.activeInstance = payload.instance;
        },

        setCloseCallback(state, payload) {
            state.closeCallback = payload.callback;
        },

        setOpen(state, payload) {
            state.open = payload.open;
        },
        setShowGridView(state, payload) {
            state.showGridView = payload.show;
        },

        setSortBy(state, sortBy) {
            state.sortBy = sortBy;
        },

        setSandboxSelectedClient(state, client) {
            state.sandboxSelectedClient = client;
        },

        setUseAutoResize(state, useAutoResize) {
            state.useAutoResize = useAutoResize;
        },

        resetState(state) {
            Object.assign(state, getDefaultState());
        }
    },
    actions: {
        close({ commit, state }, selectedAssets = null) {
            if (typeof state.closeCallback === "function") {
                state.closeCallback(selectedAssets);
            }

            commit(MediaLibraryMutation.SetActiveInstance, { instance: null });
            commit(MediaLibraryMutation.SetCloseCallback, { callback: null });
            commit(MediaLibraryMutation.SetOpen, { open: false });
        },

        open({ commit }, payload) {
            commit(MediaLibraryMutation.SetActiveInstance, { instance: payload.instance ? payload.instance : null });
            commit(MediaLibraryMutation.SetCloseCallback, { callback: payload.closeCallback });
            commit(MediaLibraryMutation.SetOpen, { open: true });
        },

        selectFile({ commit, dispatch }, payload) {
            return new Promise(resolve => {
                const openPayload = deepClone(payload);

                openPayload.instance = openPayload.instance || {
                    type: EditableType.Image,
                    multiple: false
                };

                if ("folderId" in payload) {
                    commit("setActiveFolder", { folder: payload.folderId });
                }

                commit("setActiveFile", payload.fileId);

                if (!payload.closeCallback) {
                    openPayload.closeCallback = data => resolve(data);
                }

                dispatch("open", openPayload);
            });
        },

        selectFiles({ commit, dispatch }, payload) {
            return new Promise(resolve => {
                const openPayload = deepClone(payload);

                openPayload.instance = openPayload.instance || {
                    type: EditableType.Image,
                    multiple: true
                };

                if ("folderId" in payload) {
                    commit("setActiveFolder", { folder: payload.folderId });
                }

                if (!payload.closeCallback) {
                    openPayload.closeCallback = data => resolve(data);
                }

                dispatch("open", openPayload);
            });
        },

        selectFolder({ commit, dispatch }, payload) {
            return new Promise(resolve => {
                const openPayload = deepClone(payload);

                openPayload.instance = openPayload.instance || {
                    type: "folder",
                    multiple: false
                };

                if ("folderId" in payload) {
                    commit("setActiveFolder", { folder: payload.folderId });
                }

                if (!payload.closeCallback) {
                    openPayload.closeCallback = data => resolve(data);
                }

                dispatch("open", openPayload);
            });
        },

        setActiveFile({ commit }, fileId) {
            commit(MediaLibraryMutation.SetActiveFile, fileId);
        },

        setActiveFolder({ commit }, folderId) {
            commit(MediaLibraryMutation.SetActiveFolder, { folder: folderId });
        },

        setSortBy({ commit }, sortBy) {
            commit(MediaLibraryMutation.SetSortBy, sortBy);
        },

        setSandboxSelectedClient({ commit }, client) {
            commit(MediaLibraryMutation.SetSandboxSelectedClient, client);
        },

        setUseAutoResize({ commit }, useAutoResize) {
            commit(MediaLibraryMutation.SetUseAutoResize, useAutoResize);
        },

        resetState({ commit }) {
            commit(MediaLibraryMutation.ResetState);
        }
    }
};
