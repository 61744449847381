<template>
    <div class="library-qa-status-filter">
        <collapsible-widget :is-opened="true">
            <template #name>Other Filters</template>
            <CheckboxGroup v-model="miscFilters">
                <Checkbox :label="FilterGroup.HideDuplicates">Hide Duplicates</Checkbox>
            </CheckboxGroup>
        </collapsible-widget>
    </div>
</template>

<script>
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";
import { BannerQaUserStatusFilter, BannerQaUserStatusFilterLabel } from "@/enums/banners";
import { DeliverableLibraryAction, DeliverableLibraryGetters } from "@/store/modules/deliverableLibrary";

export default {
    name: "LibraryMiscFilter",
    components: {
        CollapsibleWidget
    },

    computed: {
        miscFilters: {
            get() {
                return this.$store.getters[DeliverableLibraryGetters.miscFilters];
            },
            set(value) {
                this.$store.dispatch(DeliverableLibraryAction.UpdateMiscFilter, value);
            }
        }
    },
    created() {
        this.BannerQaUserStatusFilter = BannerQaUserStatusFilter;
        this.BannerQaUserStatusFilterLabel = BannerQaUserStatusFilterLabel;

        this.FilterGroup = {
            HideDuplicates: "dedup"
        };
    },
    methods: {
        setMiscFilter(qaStatus) {
            this.miscFilters = qaStatus;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.library-qa-status-filter {
    .ivu-radio {
        margin: 0;
    }

    .ivu-radio-group {
        display: block;
    }

    .ivu-radio-wrapper {
        margin: 0;
        padding: 0 0 0 50px;
        display: block;
    }
}

.library-qa-status-filter__status-dot-wrapper {
    display: inline-block;
    padding: 0 0 0 $spacing-smaller;
}
</style>
