export const BannerVideoActions = {
    Play: "play",
    Pause: "pause",
    Reset: "reset"
};

export const BannerVideoEvents = {
    Ended: "ended",
    TimeUpdate: "timeupdate"
};

export const BannerScrubbarEvents = {
    Scrubbing: "scrubbing",
    ScrubUpdateValue: "scrubUpdateValue"
};

export const BannerActionEvents = {
    AllBannersPause: "AllBannersPause",
    AllBannersPlay: "AllBannersPlay",
    AllBannersReplay: "AllBannersReplay",
    AllBannersSeek: "AllBannersSeek",
    BannerPause: "BannerPause",
    BannerPlay: "BannerPlay",
    BannerRefresh: "BannerRefresh",
    BannerReplay: "BannerReplay",
    BannerSeek: "BannerSeek"
};

export const BannerState = {
    Loading: "BannerStateLoading",
    Loaded: "BannerStateLoaded",
    Play: "BannerStatePlay",
    Pause: "BannerStatePause",
    Completed: "BannerStateCompleted",
    Seek: "BannerStateSeek"
};

export const BannerDefaultDuration = 30;

export const BannerEvents = {
    BannerIFrameLoaded: "BannerIFrameLoaded",
    BannerLoaded: "BannerLoaded",
    AEVideoReady: "AEVideoReady"
};

export const BannerQaCommentedOnBy = {
    Me: "ME",
    Any: "ANY",
    None: "NONE"
};

export const BannerQaStatus = {
    Draft: "IN_DRAFT",
    InReview: "IN_QA",
    Approved: "QA_APPROVED",
    Rejected: "QA_REJECTED"
};

export const BannerQaUserStatus = {
    None: "NONE",
    Approved: "QA_APPROVED",
    Rejected: "QA_REJECTED",
    INone: "INONE",
    IApproved: "IQA_APPROVED",
    IRejected: "IQA_REJECTED"
};

export const BannerQaCommentedOnByLabel = {
    [BannerQaCommentedOnBy.Me]: "Me",
    [BannerQaCommentedOnBy.Any]: "Everybody",
    [BannerQaCommentedOnBy.None]: "None"
};

export const BannerQaStatusLabel = {
    [BannerQaStatus.Draft]: "Draft",
    [BannerQaStatus.InReview]: "In review",
    [BannerQaStatus.Approved]: "Fully reviewed",
    [BannerQaStatus.Rejected]: "Changes required"
};

export const BannerQaUserStatusLabel = {
    [BannerQaUserStatus.INone]: "Me",
    [BannerQaUserStatus.IApproved]: "Me",
    [BannerQaUserStatus.IRejected]: "Me",
    [BannerQaUserStatus.IRejected]: "Me",
    [BannerQaUserStatus.None]: "Everybody",
    [BannerQaUserStatus.Approved]: "Everybody",
    [BannerQaUserStatus.Rejected]: "Everybody"
};

export const UserStatusUser = {
    Me: "ME",
    Any: "ANY"
};

export const BannerQaUserStatusLabelFilterMap = {
    [BannerQaUserStatus.INone]: { user: UserStatusUser.Me, status: BannerQaUserStatus.None },
    [BannerQaUserStatus.IApproved]: { user: UserStatusUser.Me, status: BannerQaUserStatus.Approved },
    [BannerQaUserStatus.IRejected]: { user: UserStatusUser.Me, status: BannerQaUserStatus.Rejected },
    [BannerQaUserStatus.None]: { user: UserStatusUser.Any, status: BannerQaUserStatus.None },
    [BannerQaUserStatus.Approved]: { user: UserStatusUser.Any, status: BannerQaUserStatus.Approved },
    [BannerQaUserStatus.Rejected]: { user: UserStatusUser.Any, status: BannerQaUserStatus.Rejected }
};

export const BannerQaStatusFilter = {
    All: "all",
    ...BannerQaStatus
};

export const BannerQaCommentedOnByFilter = {
    All: "all",
    ...BannerQaCommentedOnBy
};

export const BannerQaUserStatusFilter = {
    All: "all",
    ...BannerQaUserStatus
};

export const BannerQaCommentedOnByFilterLabel = {
    [BannerQaCommentedOnByFilter.All]: "All",
    ...BannerQaCommentedOnByLabel
};

export const BannerQaStatusFilterLabel = {
    [BannerQaStatusFilter.All]: "All",
    ...BannerQaStatusLabel
};

export const BannerQaUserStatusFilterLabel = {
    [BannerQaUserStatusFilter.All]: "All",
    ...BannerQaUserStatusLabel
};

export const BannerGroup = {
    Preview: "preview",
    Qa: "qa"
};
