<template>
    <nav class="footer-nav" :class="classes">
        <div v-if="isNavHome" class="home-logo">
            <router-link :to="{ name: 'Home' }" data-testid="tob-bar__logo">
                <wpp-logo class="logo" />
            </router-link>
        </div>
        <user-feedback-menu />
        <hox-main-nav-button
            has-circle-border
            :badge-count="notificationsCount"
            :is-active="notiticationsDrawerIsVisible"
            :theme="theme"
            :show-tooltip="false"
            data-testid="tob-bar__notifications-button"
            @click.native="toggleNotificationsDrawerIsVisible"
        >
            <Icon type="md-notifications" />
            <template #tooltipContent>Notifications</template>
        </hox-main-nav-button>
        <user-menu />
    </nav>
</template>
<script>
import WppLogo from "@/assets/ic_logo.svg";
import { NotificationsAction } from "@/store/modules/notifications";

import { getDomain } from "@/utils";
import UserMenu from "@/UserMenu";
import UserFeedbackMenu from "@/UserFeedbackMenu";

export default {
    name: "FooterNav",
    components: {
        UserMenu,
        UserFeedbackMenu,
        WppLogo
    },

    props: {
        theme: {
            default: "light",
            validator(value) {
                return ["light", "dark"].includes(value);
            }
        }
    },

    computed: {
        isNavHome() {
            const theDomain = getDomain();
            const defaultRoutes = ["Home", "Sandbox", "LocalDevNetworkError"];
            if (defaultRoutes.includes(this.$route.name) && theDomain !== "imagine") {
                return true;
            }
            return false;
        },
        classes() {
            return `footer-nav--${this.theme}`;
        },

        isAuthorized() {
            return this.$store.state.auth.isAuthorized;
        },

        notificationsCount() {
            return this.$store.state.notifications.items.length;
        },

        notiticationsDrawerIsVisible: {
            get() {
                return this.$store.state.notifications.drawerIsVisible;
            },

            set(value) {
                this.$store.dispatch(NotificationsAction.SetDrawerIsVisible, value);
            }
        }
    },

    methods: {
        toggleNotificationsDrawerIsVisible() {
            this.notiticationsDrawerIsVisible = !this.notiticationsDrawerIsVisible;
        }
    }
};
</script>
<style lang="scss">
@import "../sass/variables";

.footer-nav {
    width: 44px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;

    svg.logo {
        height: 26px;
        width: 20px;
        margin-bottom: $spacing;
        margin-top: 68px;
    }

    &--dark {
        svg.logo {
            path {
                fill: $hogarth-H-light-fill;
            }
        }
    }
}
</style>
