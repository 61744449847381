<template>
    <div class="dashboard-content-dropdown">
        <Dropdown>
            <a href="javascript:void(0)">
                <span class="dashboard-content-dropdown__type-value link-like">{{ options[option].title }}</span>
                <Icon type="ios-arrow-down"></Icon>
            </a>
            <DropdownMenu slot="list">
                <DropdownItem v-for="optionKey in optionKeys" :key="optionKey" @click.native="setOption(optionKey)">
                    {{ options[optionKey].title }}
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
        <br />
        {{ options[option].description }}
    </div>
</template>
<script>
import { CreativeInsightsDashboardContent } from "@/enums/creativeInteligence";

const options = {
    [CreativeInsightsDashboardContent.Utilisation]: {
        title: "What my content looks like",
        description: "Discover which creative elements you're using the most"
    },
    [CreativeInsightsDashboardContent.Top]: {
        title: "What's working",
        description: "Here’s your best performing creative elements"
    },
    [CreativeInsightsDashboardContent.Bottom]: {
        title: "What's not working",
        description: "These creative elements are not driving performance"
    },
    [CreativeInsightsDashboardContent.DataExplorer]: {
        title: "Data Explorer",
        description: "Explore all the data points in a single chart"
    }
    /* ,
[CreativeInsightsDashboardContent.BestPractice]: {
    title: "Channel best practice",
    description: "Check your results against industry best practice"
} */
};

export default {
    name: "DashboardContentDropdown",
    data() {
        return {
            option: CreativeInsightsDashboardContent.Utilisation,
            options,
            optionKeys: Object.keys(options)
        };
    },

    watch: {
        option: {
            immediate: true,
            handler() {
                this.$emit("input", this.option);
            }
        }
    },

    created() {
        this.CreativeInsightsDashboardContent = CreativeInsightsDashboardContent;
    },

    methods: {
        setOption(option) {
            this.option = option;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.dashboard-content-dropdown {
    margin: $spacing $spacing-smaller;

    &__type-value {
        font-size: 20px;
    }
}
</style>
