<template>
    <div class="facebook-creative-manager-settings">
        <label class="facebook-creative-manager-settings__row">
            <span class="facebook-creative-manager-settings__label">Page:</span>
            <Select v-model="page" class="facebook-creative-manager-settings__select">
                <Option v-for="pageItem in pageOptions" :key="pageItem.value" :value="pageItem.value">
                    {{ pageItem.label }}
                </Option>
            </Select>
        </label>
        <label class="facebook-creative-manager-settings__row">
            <span class="facebook-creative-manager-settings__label">Campaign:</span>
            <Select v-model="campaign" class="facebook-creative-manager-settings__select">
                <Option v-for="campaignItem in campaignOptions" :key="campaignItem.value" :value="campaignItem.value">
                    {{ campaignItem.label }}
                </Option>
            </Select>
        </label>
        <label class="facebook-creative-manager-settings__row">
            <span class="facebook-creative-manager-settings__label">Ad Set:</span>
            <Select v-model="adSet" class="facebook-creative-manager-settings__select">
                <Option v-for="adSetItem in adSetOptions" :key="adSetItem.value" :value="adSetItem.value">
                    {{ adSetItem.label }}
                </Option>
            </Select>
        </label>
    </div>
</template>

<script>
import { BatchDownloads } from "@/enums/platforms";
import { NotificationsAction } from "@/store/modules/notifications";
import { JobStatusToNotificationStatus } from "@/enums/jobs";
import { NotificationTypes } from "@/enums/notifications";
import { JobsAction } from "@/store/modules/jobs";
import facebookPublisherParamsQuery from "@/apollo/queries/v2/FacebookPublisherParams.gql";
import DeliverablesService from "@/services/Deliverables";
import bus from "@/bus";

const fbResultToOptions = ({ id, name }) => ({ value: id, label: name });

export default {
    name: "FacebookCreativeManagerSettings",

    props: {
        action: {
            required: true,
            type: String
        },

        deliverables: {
            required: true,
            type: Array
        },

        libraryFiltersForQuery: {
            required: false,
            type: Object
        }
    },

    data() {
        return {
            facebookPublisherParams: {
                businessPages: [],
                campaigns: [],
                adSets: []
            },
            adSet: null,
            campaign: null,
            page: null
        };
    },

    computed: {
        canPublish() {
            return !!(this.page && this.adSet);
        },

        pageOptions() {
            return this.facebookPublisherParams.businessPages.map(fbResultToOptions);
        },
        campaignOptions() {
            return this.facebookPublisherParams.campaigns.map(fbResultToOptions);
        },
        adSetOptions() {
            // if no campaign selected show all the ad sets
            return this.facebookPublisherParams.adSets
                .filter(adSet => !this.campaign || adSet.campaignId === this.campaign)
                .map(fbResultToOptions);
        },
        campaignId() {
            return this.$store.state.route.params.campaignId;
        }
    },

    watch: {
        canPublish: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.$emit("publishHandlerReady", this.publishToFacebookCallback);
                    return;
                }
                this.$emit("publishHandlerReady", null);
            }
        }
    },

    created() {
        this.deliverablesService = new DeliverablesService(this.$apollo);
    },

    methods: {
        async publishToFacebookCallback() {
            try {
                const job = await this.deliverablesService.downloadJob(
                    this.campaignId,
                    BatchDownloads.Facebook,
                    this.deliverables,
                    [],
                    this.libraryFiltersForQuery,
                    { fbVideoAdOptions: { pageId: this.page, adSetId: this.adSet } }
                );
                const notificationId = await this.$store.dispatch(NotificationsAction.Add, {
                    message: job.message,
                    status: JobStatusToNotificationStatus[job.status],
                    type: NotificationTypes.DownloadJob,
                    job
                });
                this.$store.dispatch(JobsAction.SetNotificationIdByActiveJobId, {
                    jobId: job._id,
                    notificationId
                });
                this.$snackbar.info(
                    "Your ads are being prepared for publish to Facebook Ads Manager. Take a look at your notifications to check on progress"
                );
            } catch (err) {
                console.error(err);
                this.$snackbar.error(
                    "There was an unexpected error while preparing your ads for publish to Facebook Ads Manager. Please try again in a few moments"
                );
            }
        }
    },

    apollo: {
        facebookPublisherParams: {
            query: facebookPublisherParamsQuery,
            variables() {
                return {
                    campaignId: this.campaignId
                };
            },
            error(e) {
                bus.$emit("apolloErrorEvent", e);
            },
            result({ data: { facebookPublisherParams } }) {
                this.facebookPublisherParams = facebookPublisherParams;
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.facebook-creative-manager-settings {
    &__row {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: $spacing-smaller;
    }

    &__label {
        width: 90px;
    }
}
</style>
