<template>
    <div v-observe-visibility="{ callback: visibilityChanged, once: true }" class="lazy-banner">
        <div v-if="!isVisible" class="lazy-banner__placeholder" @click="onClick">
            <slot name="placeholder"></slot>
        </div>
        <slot v-else></slot>
    </div>
</template>

<script>
import { ObserveVisibility } from "vue-observe-visibility";

export default {
    name: "LazyLoadBanner",
    directives: { ObserveVisibility },

    props: {
        lazyLoadEnabled: {
            type: Boolean,
            default: true
        },
        loadInView: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            isVisible: !this.lazyLoadEnabled
        };
    },

    methods: {
        visibilityChanged(isVisible) {
            if (!this.loadInView) {
                return;
            }

            if (isVisible) {
                this.isVisible = true;
            }
        },

        onClick() {
            this.isVisible = true;
        }
    }
};
</script>
