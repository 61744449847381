<template>
    <div>
        <div id="user-feedback-menu" class="user-feedback-menu">
            <hox-loading-layer v-if="isLoggingOut" is-full-screen :theme="loggingOutLoaderTheme" />
            <Poptip v-model="isPoptipActive" placement="left" class="user-menu__avatar-poptip">
                <hox-main-nav-button
                    has-circle-border
                    :show-tooltip="false"
                    data-testid="top-bar__user-feedback-menu-button"
                >
                    <Icon type="md-help" />
                    <template #tooltipContent>User feedback menu</template>
                </hox-main-nav-button>
                <template #content>
                    <slot name="poptipContent">
                        <div
                            class="user-menu__actions"
                            data-testid="top-bar__report-bug-button"
                            @click="openReportBugDrawer"
                        >
                            <Icon type="md-bug" />
                            <span>Report a bug</span>
                        </div>
                        <div
                            class="user-menu__actions"
                            data-testid="top-bar__feature-request-button"
                            @click="openRequestFeatureDrawer"
                        >
                            <Icon type="ios-bulb" />
                            <span>Feature request</span>
                        </div>
                        <div class="user-menu__actions" data-testid="top-bar__knwoledge-button">
                            <Icon type="md-book" />
                            <div class="user-menu-option--knowledge">
                                Knowledge hub
                                <span>coming soon</span>
                            </div>
                        </div>
                    </slot>
                </template>
            </Poptip>
        </div>
        <user-feedback-drawer
            :form-type="formType"
            :is-open="isUserFeedbackDrawerVisible"
            @close="onCloseUserFeedbackDrawer"
        />
    </div>
</template>
<script>
import UserFeedbackDrawer from "./components/UserFeedbackDrawer";

export default {
    name: "UserFeedbackMenu",
    components: { UserFeedbackDrawer },
    data() {
        return {
            isLoggingOut: false,
            isPoptipActive: false,
            isUserFeedbackDrawerVisible: false,
            formType: "bug-report"
        };
    },

    methods: {
        onCloseUserFeedbackDrawer() {
            this.isUserFeedbackDrawerVisible = false;
        },

        openReportBugDrawer() {
            this.isPoptipActive = false;
            this.formType = "bug-report";
            this.isUserFeedbackDrawerVisible = true;
        },

        openRequestFeatureDrawer() {
            this.isPoptipActive = false;
            this.formType = "feedback-form";
            this.isUserFeedbackDrawerVisible = true;
        }
    }
};
</script>

<style lang="scss">
@import "../sass/variables";

.user-feedback-menu {
    .user-menu__actions {
        display: flex;
    }
    .user-menu-option {
        display: flex;
        &--knowledge {
            display: flex;
            flex-direction: column;
            span {
                font-size: 9px;
                margin-top: -3px;
            }
        }
    }
}
</style>
