import { AdMetadataInsights, InsightsMetadata, InsightType } from "@/components/CreativeIntelligence/constants";

export const marketplaceAnnotations = [
    {
        advancedSearch: "advancedQuery:attributes.type=ImageTag-Emotion NESTED BY attributes",
        name: "Emotion",
        marketplaceIcon: "",
        marketplaceDescription: "Discover how face emotions are being used",
        tagGroup: "marketplace-annotations",
        category: "People",
        marketplaceType: "annotations",
        tagId: "marketplace-22g",
        tagOptions: {
            endpoint: InsightType.AttributeValuesAnalysis,
            defaultPreview: "treemapChart"
        }
    },
    {
        advancedSearch: "advancedQuery:attributes.type=ImageTag-Age  NESTED BY attributes",
        name: "Age appearance",
        marketplaceIcon: "",
        marketplaceDescription: "Use of Age representation",
        tagGroup: "marketplace-annotations",
        category: "People",
        marketplaceType: "annotations",
        tagId: "marketplace-26b",
        tagOptions: {
            endpoint: InsightType.AttributeValuesAnalysis,
            defaultPreview: "doughnutChart"
        }
    },
    {
        advancedSearch: "advancedQuery:attributes.type=ImageTag-Gender NESTED BY attributes",
        name: "Gender appearance",
        marketplaceIcon: "",
        marketplaceDescription: "Use of Gender representation",
        tagGroup: "marketplace-annotations",
        category: "People",
        marketplaceType: "annotations",
        tagId: "marketplace-27",
        tagOptions: {
            endpoint: InsightType.AttributeValuesAnalysis,
            defaultPreview: "doughnutChart"
        }
    },
    {
        advancedSearch:
            "advancedQuery:(attributes.type=ImageTag-Object AND attributes.metadata.area > 0.1 AND attributes.metadata.attentionScore > 80) NESTED BY attributes",
        name: "Objects with High Visual Attention",
        marketplaceIcon: "/img/mediacom.png",
        marketplaceDescription: "Discover which objects are the most eye-catching in your creative",
        tagGroup: "marketplace-annotations",
        category: "Objects & Products",
        marketplaceType: "annotations",
        tagId: "marketplace-35a",
        tagOptions: {
            endpoint: InsightType.AttributeValuesAnalysis,
            defaultPreview: "doughnutChart"
        }
    },
    {
        advancedSearch: "contains=text",
        name: "Words with High Visual Attention",
        marketplaceIcon: "/img/mediacom.png",
        marketplaceDescription: "Discover which words are the most eye-catching in your creative",
        tagGroup: "marketplace-annotations",
        category: "Words & Phrases",
        marketplaceType: "annotations",
        tagId: "marketplace-35b",
        tagOptions: {
            endpoint: InsightType.AttributeMetadata,
            endpointOptions: {
                type: "keywords"
            },
            defaultPreview: "wordCloudChart"
        }
    },
    {
        advancedSearch: "advancedQuery:attributes.type=ImageTag-Multicultural NESTED BY attributes",
        name: "Multicultural appearance",
        marketplaceDescription: "Discover representation of ethnicity appearance within your creative",
        marketplaceIcon: "/img/wunderman-thompson.png",
        tagGroup: "marketplace-annotations",
        category: "People",
        marketplaceType: "annotations",
        displayTemplates: ["diversity"],
        tagId: "marketplace-4",
        new: true
    },
    {
        advancedSearch:
            "advancedQuery:(attributes.type=TextValue AND attributes.metadata.area > 0.1 AND attributes.element=video) NESTED BY attributes",
        name: "Headlines",
        marketplaceDescription: "Detect and analyse prominent text",
        marketplaceIcon: "",
        tagGroup: "marketplace-annotations",
        category: "Words & Phrases",
        marketplaceType: "annotations",
        tagId: "marketplace-21b",
        tagOptions: {
            endpoint: InsightType.AttributeValuesAnalysis,
            defaultPreview: "horizontalBarChart"
        }
    },
    {
        advancedSearch: "text=save",
        name: "Offers",
        marketplaceDescription: "Creatives that contain a currency or % based message",
        marketplaceIcon: "",
        marketplaceType: "annotations",
        category: "Words & Phrases",
        tagGroup: "marketplace-annotations",
        tagId: "marketplace-20a",
        tagOptions: {
            endpoint: InsightType.AttributeValuesAnalysis,
            defaultPreview: "scatterChart"
        }
    },
    {
        advancedSearch: "advancedQuery:attributes.type=ImageTag-Logo NESTED BY attributes",
        name: "Logo",
        marketplaceDescription: "Identify and understand use of logos and logotypes",
        marketplaceIcon: "",
        tagGroup: "marketplace-annotations",
        category: "Branding & Design",
        marketplaceType: "annotations",
        tagId: "marketplace-23a",
        tagOptions: {
            endpoint: InsightType.AttributeValuesAnalysis,
            defaultPreview: "scatterChart"
        }
    },
    {
        advancedSearch: "text=soon OR text=limited OR text=now OR text=ends",
        name: "Urgency Messaging",
        marketplaceDescription: "Creatives that contain a time based message",
        marketplaceIcon: "",
        tagGroup: "marketplace-annotations",
        category: "Words & Phrases",
        marketplaceType: "annotations",
        tagId: "marketplace-24"
    },
    {
        advancedSearch:
            "advancedQuery:(attributes.attribute=person OR attributes.type=ImageTag-Gender OR attributes.type=ImageTag-Age OR attributes.type=ImageTag-Emotion) NESTED BY attributes",
        name: "People",
        marketplaceIcon: "",
        marketplaceDescription: "Representation of People",
        tagGroup: "marketplace-annotations",
        category: "People",
        marketplaceType: "annotations",
        tagId: "marketplace-25"
    },
    {
        advancedSearch: "contains=object",
        name: "Objects",
        marketplaceIcon: "",
        marketplaceDescription: "A wider analysis of objects",
        tagGroup: "marketplace-annotations",
        category: "Objects & Products",
        marketplaceType: "annotations",
        tagId: "marketplace-28"
    },
    {
        advancedSearch: "advancedQuery:(attributes.type=Celebrity AND attributes.element=video) NESTED BY attributes",
        name: "Celebrity",
        marketplaceDescription: "Identify Celebrities across Sport, Film, Television, and Music",
        marketplaceIcon: "",
        category: "People",
        tagGroup: "marketplace-annotations",
        marketplaceType: "annotations",
        tagId: "marketplace-30"
    },
    {
        advancedSearch: "advancedQuery:attributes.type=ColorTheory-PantoneColorGroup NESTED BY attributes",
        name: "Dominant Colour",
        marketplaceIcon: "",
        marketplaceDescription: "How eye catching colour is used across your creatives",
        tagGroup: "marketplace-annotations",
        category: "Branding & Design",
        marketplaceType: "annotations",
        tagId: "marketplace-29",
        tagOptions: {
            endpoint: InsightType.AttributeValuesAnalysis,
            defaultPreview: "treemapChart"
        }
    },
    {
        advancedSearch: "advancedQuery:attributes.type=ColorTheory-TemperatureGroup NESTED BY attributes",
        name: "Color Temperature",
        marketplaceDescription: "Kelvin scores of color within creative frames",
        marketplaceIcon: "",
        category: "Branding & Design",
        tagGroup: "marketplace-annotations",
        marketplaceType: "annotations",
        tagId: "marketplace-2"
    },
    {
        advancedSearch:
            "advancedQuery:(attributes.type=ColorTheory-PantoneColorGroup AND attributes.metadata.area > 0.4) NESTED BY attributes",
        name: "Background Color",
        marketplaceDescription: "Cluster creatives by the most used colour by area",
        marketplaceIcon: "",
        category: "Branding & Design",
        tagGroup: "marketplace-annotations",
        marketplaceType: "annotations",
        tagId: "marketplace-2a"
    },
    {
        advancedSearch:
            "advancedQuery:(attributes.type=TextValue AND (attributes.element=video OR attributes.element=thumbnail) AND attributes.metadata.wordCount < 10) NESTED BY attributes",
        name: "Short-form Copy",
        marketplaceDescription: "Use of punchy, short sentences.",
        marketplaceIcon: "",
        category: "Words & Phrases",
        tagGroup: "marketplace-annotations",
        marketplaceType: "annotations",
        tagId: "marketplace-31"
    },
    {
        advancedSearch:
            "advancedQuery:(attributes.type=TextValue AND (attributes.element=video OR attributes.element=thumbnail) AND attributes.metadata.wordCount > 10 AND attributes.metadata.wordCount < 20) NESTED BY attributes",
        name: "Medium-form Copy",
        marketplaceDescription: "Longer, more descriptive uses of copy",
        marketplaceIcon: "",
        category: "Words & Phrases",
        tagGroup: "marketplace-annotations",
        marketplaceType: "annotations",
        tagId: "marketplace-32"
    },
    {
        advancedSearch:
            "advancedQuery:(attributes.type=TextValue AND (attributes.element=video OR attributes.element=thumbnail) AND attributes.metadata.wordCount > 20) NESTED BY attributes",
        name: "Long-form Copy",
        marketplaceDescription: "Long form copy such as Terms & Conditions",
        marketplaceIcon: "",
        category: "Words & Phrases",
        tagGroup: "marketplace-annotations",
        marketplaceType: "annotations",
        tagId: "marketplace-33"
    },
    {
        advancedSearch: "contains=text",
        name: "Key Words",
        marketplaceIcon: "",
        marketplaceDescription: "Discover the keywords in your creative",
        tagGroup: "marketplace-annotations",
        marketplaceType: "annotations",
        category: "Words & Phrases",
        tagId: "marketplace-35c",
        tagOptions: {
            endpoint: InsightType.AttributeMetadata,
            endpointOptions: {
                type: InsightsMetadata.KEYWORDS
            },
            defaultPreview: "wordCloudChart"
        }
    },
    {
        advancedSearch: "contains=text",
        name: "Key Phrases",
        marketplaceIcon: "",
        marketplaceDescription: "Key Phrases found within your creative",
        tagGroup: "marketplace-annotations",
        marketplaceType: "annotations",
        category: "Words & Phrases",
        tagId: "marketplace-36",
        tagOptions: {
            endpoint: InsightType.AttributeMetadata,
            endpointOptions: {
                type: InsightsMetadata.KEY_PHRASES
            },
            defaultPreview: "wordCloudChart"
        }
    },
    {
        advancedSearch: "contains=text",
        name: "Text Sentiment",
        marketplaceIcon: "",
        marketplaceDescription: "Basic Text Sentiment analysis, clustering into Negative, Neutral, or Positive",
        tagGroup: "marketplace-annotations",
        marketplaceType: "annotations",
        category: "Words & Phrases",
        tagId: "marketplace-37",
        tagOptions: {
            endpoint: InsightType.AttributeMetadata,
            endpointOptions: {
                // todo
                type: "sentiment"
            },
            defaultPreview: "horizontalBarChart"
        }
    },
    {
        advancedSearch: "",
        name: "Formats",
        marketplaceIcon: "",
        marketplaceDescription: "Compare static, display & video formats",
        tagGroup: "marketplace-annotations",
        category: "Placements & Formats",
        marketplaceType: "annotations",
        tagId: "marketplace-38",
        tagOptions: {
            endpoint: InsightType.AttributeAdMetadata,
            endpointOptions: {
                type: AdMetadataInsights.AD_TYPE
            },
            defaultPreview: "polarChart"
        }
    },
    {
        advancedSearch: "",
        name: "Duration",
        marketplaceIcon: "",
        category: "Placements & Formats",
        marketplaceDescription: "Compare video length",
        tagGroup: "marketplace-annotations",
        marketplaceType: "annotations",
        tagId: "marketplace-39",
        tagOptions: {
            endpoint: InsightType.AttributeAdMetadata,
            endpointOptions: {
                type: AdMetadataInsights.DURATION
            },
            defaultPreview: "polarChart"
        }
    },
    {
        advancedSearch: "",
        name: "Placement Sizes",
        marketplaceIcon: "",
        marketplaceDescription: "Compare formats like 1:1, 16:9 and 9:16",
        tagGroup: "marketplace-annotations",
        marketplaceType: "annotations",
        category: "Placements & Formats",
        tagId: "marketplace-40",
        tagOptions: {
            endpoint: InsightType.AttributeAdMetadata,
            endpointOptions: {
                type: AdMetadataInsights.ASPECT_RATIO
            },
            defaultPreview: "polarChart"
        }
    }
];

export const marketplacePredictions = [];

export const marketplaceItems = [...marketplaceAnnotations, ...marketplacePredictions];
