<template>
    <div>
        <Select class="platform-select" v-bind="attrs" v-on="listeners">
            <Option
                v-for="option in downloadOptionsList"
                :key="option"
                class="platform-select__option"
                :value="option"
                :disabled="isDisabled(option)"
                :class="{ 'platform-select__option--divider': isDividing(option) }"
            >
                {{ DownloadOptionLabels[option] }}
            </Option>
        </Select>
    </div>
</template>

<script>
import { Platform, PlatformLabel, PsdDownloads, BatchDownloads } from "@/enums/platforms";
import passThroughMixin from "@/mixins/passThroughMixin";

export default {
    mixins: [passThroughMixin],

    props: {
        showHtmlOptions: {
            required: true,
            type: Boolean
        },
        showPsdOptions: {
            required: true,
            type: Boolean
        },
        showAeOptions: {
            required: true,
            type: Boolean
        }
    },

    data() {
        return {
            downloadOptionsList: [],
            isSettingPublishedStatus: false
        };
    },

    computed: {
        clientId() {
            return this.$route.params.clientId;
        },

        campaignId() {
            return this.$route.params.campaignId;
        },

        editableGroupValues() {
            return this.$store.state.campaign.editableGroupValues;
        }
    },

    created() {
        this.Platform = Platform;
        this.PlatformLabel = PlatformLabel;
        this.DownloadOptions = {
            ...Platform,
            ...BatchDownloads
        };

        this.downloadOptionsList = [
            this.DownloadOptions.Sizmek,
            this.DownloadOptions.SizmekSV,
            this.DownloadOptions.DoubleClick,
            this.DownloadOptions.DoubleClickSV,
            this.DownloadOptions.OpenDC,
            this.DownloadOptions.Flashtalking,
            this.DownloadOptions.FlashtalkingExcel,
            this.DownloadOptions.FlashtalkingAssets,
            this.DownloadOptions.Generic,
            this.DownloadOptions.ImageNoScale,
            this.DownloadOptions.Image,
            this.DownloadOptions.ImageJpegNoScale,
            this.DownloadOptions.ImageJpeg,
            this.DownloadOptions.Psd
        ];

        this.DownloadOptionLabels = {
            [this.DownloadOptions.Sizmek]: `${PlatformLabel[Platform.Sizmek]} HTML`,
            [this.DownloadOptions.SizmekSV]: `${PlatformLabel[Platform.SizmekSV]} HTML`,
            [this.DownloadOptions.DoubleClick]: `${PlatformLabel[Platform.DoubleClick]} Studio HTML`,
            [this.DownloadOptions.DoubleClickSV]: `${PlatformLabel[Platform.DoubleClickSV]} HTML`,
            [this.DownloadOptions.OpenDC]: `${PlatformLabel[Platform.OpenDC]} HTML`,
            [this.DownloadOptions.Flashtalking]: `${PlatformLabel[Platform.Flashtalking]} HTML`,
            [this.DownloadOptions.FlashtalkingExcel]: PlatformLabel[Platform.FlashtalkingExcel],
            [this.DownloadOptions.FlashtalkingAssets]: PlatformLabel[Platform.FlashtalkingAssets],
            [this.DownloadOptions.Generic]: "Generic HTML",
            [this.DownloadOptions.ImageNoScale]: "Image (PNG)",
            [this.DownloadOptions.Image]: "Image@x2 (PNG)",
            [this.DownloadOptions.ImageJpegNoScale]: "Image (JPG)",
            [this.DownloadOptions.ImageJpeg]: "Image@x2 (JPG)",
            [this.DownloadOptions.Psd]: "PSD"
        };

        this.dividingOptions = [
            this.DownloadOptions.DoubleClick,
            this.DownloadOptions.OpenDC,
            this.DownloadOptions.Flashtalking,
            this.DownloadOptions.Generic,
            this.DownloadOptions.ImageNoScale,
            this.DownloadOptions.Psd
        ];

        if (
            this.$auth.userCan(this.$auth.Actions.CanDownloadVideo, {
                clientId: this.clientId,
                campaignId: this.campaignId
            })
        ) {
            this.DownloadOptions.Video = "video";
            this.downloadOptionsList.push(this.DownloadOptions.Video);
            this.DownloadOptionLabels[this.DownloadOptions.Video] = "Video (MP4)";
            this.dividingOptions.push(this.DownloadOptions.Video);
        }
    },

    methods: {
        isDisabled(option) {
            // After Effects banners can be only downloaded as video for now
            if (this.showAeOptions && option !== this.DownloadOptions.Video) {
                return true;
            }

            // HTML banners cannot export a PSD file
            if (this.showHtmlOptions && option === PsdDownloads.Psd) {
                return true;
            }

            if (this.showPsdOptions && !Object.values(PsdDownloads).includes(option)) {
                return true;
            }

            return false;
        },
        isDividing(option) {
            return this.dividingOptions.includes(option);
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.platform-select {
    &__option {
        border-top: 1px solid transparent;

        &--divider {
            border-top: 1px solid $grey3;
            margin-top: 5px;
            padding-top: 7px;

            &:before {
                content: "";
                height: 5px;
                display: block;
                background-color: $white;
                width: calc(100% + 2 * 16px);
                margin-left: -$spacing;
                position: relative;
                top: -7px;
            }
        }
    }
}
</style>
