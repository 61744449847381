<template>
    <div
        class="campaign-banner"
        :class="{ 'campaign-banner--resizable': isResizable, 'campaign-banner-dark-theme': theme === 'dark' }"
    >
        <div class="campaign-banner__preview">
            <slot></slot>
            <resize-observer class="campaign-banner__resize-observer" @notify="handleResize"></resize-observer>
        </div>
        <div class="campaign-banner__footer">
            <slot name="footer">
                <campaign-banner-footer>
                    <template slot="name">
                        <slot name="footerName">
                            <Poptip
                                v-model="infoVisible"
                                placement="bottom-start"
                                class="campaign-edit__poptip hide-title"
                                trigger="hover"
                            >
                                <span class="banner-name">
                                    {{ bannerName }}
                                </span>
                                <div slot="content" class="campaign-edit__poptip-templateName">
                                    <p>{{ bannerName }}</p>
                                </div>
                            </Poptip>
                        </slot>
                    </template>
                    <template slot="footerControls">
                        <slot name="footerControls"></slot>
                    </template>
                    <template slot="footerOptions">
                        <slot name="footerOptions"></slot>
                    </template>
                </campaign-banner-footer>
            </slot>
        </div>
    </div>
</template>

<script>
import { ResizeObserver } from "vue-resize";
import CampaignBannerFooter from "@/components/Campaign/CampaignBannerFooter";

export default {
    name: "CampaignBanner",
    components: {
        CampaignBannerFooter,
        "resize-observer": ResizeObserver
    },

    props: {
        bannerName: {
            type: String
        },

        isResizable: {
            type: Boolean,
            default: false
        },

        theme: {
            type: String,
            default: "light"
        }
    },
    data() {
        return {
            infoVisible: false
        };
    },

    methods: {
        handleResize(data) {
            if (this.isResizable) {
                this.$emit("resized", data);
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";
@import "~resize-css";

.campaign-banner {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;

    &--resizable {
        resize: both;
        background-color: $white;

        &.campaign-banner-dark-theme {
            background-color: $cmp-dark-secondary-bg-color;
        }

        .campaign-banner-footer {
            background-color: transparent;
        }
    }

    &__preview {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    &__footer {
        flex: 0 0 40px;
    }
}

.banner-name {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
