import Vue from "vue";
import { dedup, deepClone, formatEditableName } from "@/utils";
import { EditableScope } from "@/enums/editables";
import editableUtils from "@/utils/editables";
import { campaignDefaultLanguage, CampaignGetters } from "@/store/modules/campaign";
import { MediaLibraryAssetType } from "@/components/MediaLibrary";

const storeNamespace = "editor";

export const EditorAction = {
    ClearGroupValueData: `${storeNamespace}/clearGroupValueData`,
    ClearLanguageData: `${storeNamespace}/clearLanguageData`,
    ClearLocalAssetChanges: `${storeNamespace}/clearAssetData`,
    ClearLocalChanges: `${storeNamespace}/clearLocalChanges`,
    ClearSelectedGroupValues: `${storeNamespace}/clearSelectedGroupValues`,
    RemoveOverwrite: `${storeNamespace}/removeOverwrite`,
    ResetEditor: `${storeNamespace}/resetEditor`,
    SetEditedEditable: `${storeNamespace}/setEditedEditable`,
    SetEditedEditablesSettings: `${storeNamespace}/setEditedEditablesSettings`,
    SetFonts: `${storeNamespace}/setFonts`,
    SetLocalOverwriteTmpId: `${storeNamespace}/setLocalOverwriteTmpId`,
    SelectGroupValue: `${storeNamespace}/selectGroupValue`,
    SelectLanguage: `${storeNamespace}/selectLanguage`,
    SetLocalEditableOverwrites: `${storeNamespace}/setLocalEditableOverwrites`,
    SetEditablesPaneVisibility: `${storeNamespace}/setEditablesPaneVisibility`,
    SetSettingsPaneVisibility: `${storeNamespace}/setSettingsPaneVisibility`,
    SetShowEditablesPaneInModal: `${storeNamespace}/setShowEditablesPaneInModal`,
    SetShowSettingsPaneInModal: `${storeNamespace}/setShowSettingsPaneInModal`
};

export const EditorGetters = {
    editableOverwrites: `${storeNamespace}/editableOverwrites`,
    editableOverwriteValue: `${storeNamespace}/editableOverwriteValue`,
    fontIds: `${storeNamespace}/fontIds`,
    fontsByName: `${storeNamespace}/fontsByName`,
    groupsForSelectedEditable: `${storeNamespace}/groupsForSelectedEditable`,
    selectedEditables: `${storeNamespace}/selectedEditables`,
    selectedGroupValuesIds: `${storeNamespace}/selectedGroupValuesIds`,
    editingGroupValue: `${storeNamespace}/editingGroupValue`
};

export const EditorMutation = {
    AddGroupSelection: "addGroupSelection",
    ClearEditingGroupName: "clearEditingGroupName",
    ClearLocalOverwritesForAsset: "clearLocalOverwritesForAsset",
    ClearLocalOverwritesForGroupValue: "clearLocalOverwritesForGroupValue",
    ClearLocalOverwritesForLanguage: "clearLocalOverwritesForLanguage",
    ClearSelectedGroupValues: "clearSelectedGroupValues",
    ClearRemovedOverwrites: "clearRemovedOverwrites",
    RemoveOverwrite: "removeOverwrite",
    SetEditedEditable: "setEditedEditable",
    SetEditedEditablesSettings: "setEditedEditablesSettings",
    SetFonts: "setFonts",
    SetLocalOverwriteTmpId: "setLocalOverwriteTmpId",
    SelectLanguage: "selectLanguage",
    SetEditablesPaneVisibility: "setEditablesPaneVisibility",
    SetSettingsPaneVisibility: "setSettingsPaneVisibility",
    SetShowEditablesPaneInModal: "setShowEditablesPaneInModal",
    SetShowSettingsPaneInModal: "setShowSettingsPaneInModal",
    SetLocalEditableOverwrites: "setLocalEditableOverwrites",
    UpdateSelectedGroupValues: "updateSelectedGroupValues",
    UpdateBeforeUnloadListener: "updateBeforeUnloadListener"
};

const beforeUnload = e => {
    e.preventDefault();
    e.returnValue = "You will lose all content edits made since your last save.";
};

export default {
    namespaced: true,
    state: {
        allowMultiGroupValues: true,
        editingGroupName: null,
        editedEditable: null,
        editedEditablesSettings: null,
        hasBeforeUnloadListener: false,
        isSettingsPaneVisible: false,
        isEditablesPaneVisible: false,
        localEditableOverwrites: [],
        localOverwrites: [],
        localOverwriteTmpId: 1,
        removedOverwriteIds: [],
        selectedGroupValues: {
            /* [groupName: string]: {groupName: string, groupValueId: string} */
        },
        selectedGroupValueIds: [
            /* groupValueId: string */
        ],
        selectedLanguage: campaignDefaultLanguage,
        showSettingsPaneInModal: false,
        showEditablesPaneInModal: false,
        fonts: {}
    },

    getters: {
        editableOverwrites: (state, getters, rootState) => {
            if (!state.removedOverwriteIds.length) {
                return editableUtils.mergeEditables(
                    rootState.campaign.editableOverwrites,
                    state.localEditableOverwrites
                );
            }

            const dbEditableOverwrites = rootState.campaign.editableOverwrites
                // exclude removed overwrites
                .reduce((acc, editableOverwrite) => {
                    const overwrites = editableOverwrite.overwrites.filter(
                        overwrite => !state.removedOverwriteIds.includes(overwrite._id)
                    );

                    if (overwrites.length) {
                        acc.push({
                            ...editableOverwrite,
                            overwrites
                        });
                    }
                    return acc;
                }, []);

            return editableUtils.mergeEditables(dbEditableOverwrites, state.localEditableOverwrites);
        },

        editableOverwriteValue:
            (state, getters, rootState, rootGetters) =>
            (editableId, masterTemplateId, groupValuesIds = getters.selectedGroupValuesIds) =>
                rootGetters[CampaignGetters.editableOverwriteValue](
                    editableId,
                    masterTemplateId,
                    state.selectedLanguage,
                    groupValuesIds,
                    getters.editableOverwrites
                ),

        fontsByName(state) {
            return Object.values(state.fonts).reduce((acc, font) => {
                acc[font.name] = font;
                return acc;
            }, {});
        },

        fontIds(state) {
            return Object.keys(state.fonts);
        },

        // this could be wrong for multi-group
        groupsForSelectedEditable: (state, getters) => {
            const selectedGroup = state.editingGroupName;
            return getters.selectedEditables.reduce((acc, editable) => {
                const defaultValues = editable.defaultValues.filter(({ editableGroups }) =>
                    editableGroups.includes(selectedGroup)
                );
                if (defaultValues.length) {
                    acc[editable._id] = defaultValues[0].editableGroups;
                }

                return acc;
            }, {});
        },

        /**
         * returns editables for currently selected group
         * @param state
         * @param getters
         * @param rootState
         * @returns {*}
         */
        selectedEditables: (state, getters, rootState) => {
            const selectedGroup = rootState.campaign.normalized.editableGroups[state.editingGroupName];
            const selectedGroupEditableIds =
                selectedGroup && selectedGroup.editables ? deepClone(selectedGroup.editables) : [];
            const selectedEditables = selectedGroupEditableIds.map(selectedGroupEditableId => ({
                ...rootState.campaign.normalized.editables[selectedGroupEditableId],
                label: formatEditableName(rootState.campaign.normalized.editables[selectedGroupEditableId].name),
                scope: EditableScope.Group
            }));
            const uniqueEditables = dedup(selectedEditables, "_id");
            return uniqueEditables;
        },

        selectedGroupValuesIds: state => state.selectedGroupValueIds, // todo: to be removed

        editingGroupValue: state => state.selectedGroupValues[state.editingGroupName]
    },

    mutations: {
        addGroupSelection(state, groupValue) {
            // line below left as a reference - if we need to disallow multigroup selection again this is the way to do it
            // const val = state.allowMultiGroupValues ? deepClone( state.selectedGroupValues ) : {};

            Vue.set(state.selectedGroupValues, groupValue.groupName, groupValue);

            state.editingGroupName = groupValue.groupName;
            state.selectedGroupValueIds = state.selectedGroupValueIds.concat(groupValue.groupValueId);
        },

        clearEditingGroupName(state) {
            state.editingGroupName = null;
        },

        clearLocalOverwritesForAsset(state, removedAsset) {
            state.localEditableOverwrites = state.localEditableOverwrites
                // remove local overwrites in the removed language
                .map(editableOverwrite => ({
                    ...editableOverwrite,
                    overwrites: editableOverwrite.overwrites.filter(overwrite => {
                        const isIdMatched = overwrite.mediaItem && overwrite.mediaItem.id === removedAsset.id;

                        if (removedAsset.type === MediaLibraryAssetType.Folder) {
                            return (
                                !isIdMatched &&
                                overwrite.mediaItem.persistentKey.indexOf(removedAsset.persistentKey) === -1
                            );
                        }

                        return !isIdMatched;
                    })
                }));
        },

        clearLocalOverwritesForGroupValue(state, removedGroupValueId) {
            state.localEditableOverwrites = state.localEditableOverwrites
                // remove local overwrites in the removed language
                .map(editableOverwrite => ({
                    ...editableOverwrite,
                    overwrites: editableOverwrite.overwrites.filter(
                        overwrite => !overwrite.editableGroupValueIds.includes(removedGroupValueId)
                    )
                }))
                // remove editableOverwrite if we removed all the overwrites in the previous step
                .filter(editableOverwrite => editableOverwrite.overwrites.length);
        },

        clearLocalOverwritesForLanguage(state, language) {
            state.localEditableOverwrites = state.localEditableOverwrites
                // remove local overwrites in the removed language
                .map(editableOverwrite => ({
                    ...editableOverwrite,
                    overwrites: editableOverwrite.overwrites.filter(overwrite => overwrite.language !== language)
                }))
                // remove editableOverwrite if we removed all the overwrites in the previous step
                .filter(editableOverwrite => editableOverwrite.overwrites.length);
        },

        clearSelectedGroupValues(state) {
            state.selectedGroupValues = {};
        },

        clearRemovedOverwrites(state) {
            state.removedOverwriteIds = [];
        },

        removeOverwrite(state, overwriteId) {
            if (overwriteId.indexOf("tmpId-") > -1) {
                // Remove local overwrite
                state.localEditableOverwrites = state.localEditableOverwrites
                    .map(editableOverwrite => ({
                        ...editableOverwrite,
                        overwrites: editableOverwrite.overwrites.filter(overwrite => overwrite._id !== overwriteId)
                    }))
                    // remove editableOverwrite if we removed all the overwrites in the previous step
                    .filter(editableOverwrite => editableOverwrite.overwrites.length);
            } else {
                state.removedOverwriteIds = state.removedOverwriteIds.concat(overwriteId);
            }
        },

        setLocalEditableOverwrites(state, editableOverwrites) {
            state.localEditableOverwrites = editableOverwrites;
        },

        setEditedEditable(state, data) {
            state.editedEditable = data;
        },

        setEditedEditablesSettings(state, data) {
            state.editedEditablesSettings = data;
        },

        setFonts(state, fonts) {
            state.fonts = {};
            fonts.forEach(font => Vue.set(state.fonts, font._id, font));
        },

        setLocalOverwriteTmpId(state, tmpId) {
            state.localOverwriteTmpId = tmpId;
        },

        selectLanguage(state, language) {
            state.selectedLanguage = language;
        },

        setEditablesPaneVisibility(state, isVisible) {
            state.isEditablesPaneVisible = isVisible;
        },

        setSettingsPaneVisibility(state, isVisible) {
            state.isSettingsPaneVisible = isVisible;
        },

        setShowEditablesPaneInModal(state, showEditablesPaneInModal) {
            state.showEditablesPaneInModal = showEditablesPaneInModal;
        },
        setShowSettingsPaneInModal(state, showSettingsPaneInModal) {
            state.showSettingsPaneInModal = showSettingsPaneInModal;
        },

        updateBeforeUnloadListener(state) {
            if (
                !state.hasBeforeUnloadListener &&
                (state.removedOverwriteIds.length || state.localEditableOverwrites.length)
            ) {
                window.addEventListener("beforeunload", beforeUnload);
                state.hasBeforeUnloadListener = true;
            }

            if (
                state.hasBeforeUnloadListener &&
                !state.removedOverwriteIds.length &&
                !state.localEditableOverwrites.length
            ) {
                window.removeEventListener("beforeunload", beforeUnload);
                state.hasBeforeUnloadListener = true;
            }
        },

        updateSelectedGroupValues(state, groupValue) {
            const previousEditableGroupValue = state.selectedGroupValues[groupValue.groupName];

            Vue.set(state.selectedGroupValues, groupValue.groupName, groupValue);
            state.editedEditable = null;
            state.editedEditablesSettings = {};

            if (previousEditableGroupValue) {
                const previousEditableGroupValueId = previousEditableGroupValue.groupValueId;
                const previousEditableGroupValueIdIndex =
                    state.selectedGroupValueIds.indexOf(previousEditableGroupValueId);
                const index =
                    previousEditableGroupValueIdIndex > -1
                        ? previousEditableGroupValueIdIndex
                        : state.selectedGroupValueIds.length;

                Vue.set(state.selectedGroupValueIds, index, groupValue.groupValueId);
            }

            state.editingGroupName = groupValue.groupName;
        }
    },

    actions: {
        clearAssetData({ commit }, removedAsset) {
            commit(EditorMutation.ClearLocalOverwritesForAsset, removedAsset);
        },

        clearGroupValueData({ commit, state }, removedGroupValueId) {
            commit(EditorMutation.ClearLocalOverwritesForGroupValue, removedGroupValueId);

            if (state.editingGroupName) {
                const selectedGroup = state.selectedGroupValues[state.editingGroupName];

                // !selectedGroup.groupValueId cause at this stage the value has already been removed.
                if (selectedGroup && !selectedGroup.groupValueId) {
                    commit(EditorMutation.ClearEditingGroupName);
                }
            }
        },

        clearLanguageData({ commit, state }, removedLanguage) {
            commit(EditorMutation.ClearLocalOverwritesForLanguage, removedLanguage);

            if (state.selectedLanguage === removedLanguage) {
                commit(EditorMutation.SelectLanguage, campaignDefaultLanguage);
            }
        },

        clearLocalChanges({ commit }) {
            commit(EditorMutation.SetLocalEditableOverwrites, []);
            commit(EditorMutation.ClearRemovedOverwrites);
            commit(EditorMutation.UpdateBeforeUnloadListener);
        },

        clearSelectedGroupValues({ commit }) {
            commit(EditorMutation.ClearSelectedGroupValues);
        },

        removeOverwrite({ commit }, payload) {
            commit(EditorMutation.RemoveOverwrite, payload);
            commit(EditorMutation.UpdateBeforeUnloadListener);
        },

        resetEditor({ commit }) {
            commit(EditorMutation.ClearSelectedGroupValues);
            commit(EditorMutation.ClearEditingGroupName);
        },

        setLocalEditableOverwrites({ commit }, editableOverwrites) {
            commit(EditorMutation.SetLocalEditableOverwrites, editableOverwrites);
            commit(EditorMutation.UpdateBeforeUnloadListener);
        },

        selectGroupValue({ commit, state }, payload) {
            let mutation = EditorMutation.AddGroupSelection;

            if (payload.groupName in state.selectedGroupValues) {
                mutation = EditorMutation.UpdateSelectedGroupValues;
            }

            commit(mutation, payload);
        },

        setEditedEditable({ commit }, payload) {
            commit(EditorMutation.SetEditedEditable, payload);

            if (!payload) {
                commit(EditorMutation.SetSettingsPaneVisibility, false);
            }
        },

        setEditedEditablesSettings({ commit }, payload) {
            commit(EditorMutation.SetEditedEditablesSettings, payload);
        },

        setFonts({ commit }, payload) {
            commit(EditorMutation.SetFonts, payload);
        },

        setLocalOverwriteTmpId({ commit }, payload) {
            commit(EditorMutation.SetLocalOverwriteTmpId, payload);
        },

        selectLanguage({ commit }, payload) {
            commit(EditorMutation.SelectLanguage, payload);
        },

        setSettingsPaneVisibility({ commit }, isVisible) {
            commit(EditorMutation.SetSettingsPaneVisibility, isVisible);
        },

        setEditablesPaneVisibility({ commit }, isVisible) {
            commit(EditorMutation.SetEditablesPaneVisibility, isVisible);
        },

        setShowEditablesPaneInModal({ commit }, showEditablesPaneInModal) {
            commit(EditorMutation.SetShowEditablesPaneInModal, showEditablesPaneInModal);
        },

        setShowSettingsPaneInModal({ commit }, showSettingsPaneInModal) {
            commit(EditorMutation.SetShowSettingsPaneInModal, showSettingsPaneInModal);
        }
    }
};
