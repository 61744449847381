// eslint-disable-next-line no-unused-vars, import/no-extraneous-dependencies
import * as Types from "shared-utils/types";

import addMasterTemplateMutation from "@/apollo/mutations/v2/AddMasterTemplate.gql";
import associateMasterTemplateMutation from "@/apollo/mutations/v2/AssociateMasterTemplateWithCampaign.gql";
import disassociateMasterTemplateMutation from "@/apollo/mutations/v2/UnassociateMasterTemplateFromCampaign.gql";
import removeMasterTemplateMutation from "@/apollo/mutations/v2/RemoveMasterTemplate.gql";
import clientMasterTemplatesQuery from "@/apollo/queries/v2/ClientMasterTemplates.gql";
import updateTemplateMutation from "@/apollo/mutations/v2/UpdateMasterTemplate.gql";
import updateResizeTemplateMutation from "@/apollo/mutations/v2/UpdateResizedMasterTemplate.gql";
import completeAEMasterTemplates from "@/apollo/mutations/v2/CompleteAEMasterTemplate.gql";

/**
 * @typedef {Object} MasterTemplateAddResponse
 * @property {string} _id
 * @property {Types.TemplateType} adType
 * @property {string} persistentKey
 */

export default class MasterTemplates {
    /**
     * Creates new instance of MasterTemplate
     * @param {VueApollo} apollo
     * @param {string} clientId
     * @param {ApolloQuery} refreshQuery
     */
    constructor(apollo, clientId, refreshQuery = null) {
        this.$apollo = apollo;
        this.refreshQuery = refreshQuery;

        this.defaults = {
            variables: {
                clientId
            }
        };

        if (refreshQuery) {
            this.defaults.refetchQueries = [this.refreshQuery];
        } else {
            this.defaults.refetchQueries = [
                {
                    query: clientMasterTemplatesQuery,
                    variables: {
                        clientId: this.defaults.variables.clientId
                    }
                }
            ];
        }
    }

    /**
     * Uploads new master template
     * @param {string} zipUrl
     * @returns {Promise<{ data: { addMasterTemplate: MasterTemplateAddResponse }>}
     */
    async add(zipUrl) {
        return this.$apollo.mutate({
            mutation: addMasterTemplateMutation,
            variables: {
                zipUrl,
                clientId: this.defaults.variables.clientId
            },
            refetchQueries: this.defaults.refetchQueries
        });
    }

    /**
     * Updates HTML5 master templates
     * @param {string} masterTemplateId
     * @param {string} zipUrl
     * @param {Types.TemplateType} type
     * @param {boolean} force
     */
    async update(masterTemplateId, zipUrl, type, force = false) {
        return this.$apollo.mutate({
            mutation: updateTemplateMutation,
            variables: {
                masterTemplateId,
                zipUrl,
                clientId: this.defaults.variables.clientId,
                type,
                force
            },
            ...(force && {
                awaitRefetchQueries: true, // wait for the refetch before resolving the promise
                refetchQueries: this.defaults.refetchQueries
            })
        });
    }

    /**
     * Updates AE master templates with Plaily project data
     * @param {string} masterTemplateId
     * @param {string} plainlyProjectId
     * @param {Types.InputAEComposition[]} compositions
     * @returns {Promise<MasterTemplateAddResponse>}
     */
    async completeAE(masterTemplateId, plainlyProjectId, compositions) {
        return this.$apollo.mutate({
            mutation: completeAEMasterTemplates,
            variables: {
                initialTemplateId: masterTemplateId,
                plainlyProjectId,
                compositions,
                clientId: this.defaults.variables.clientId
            },
            refetchQueries: this.defaults.refetchQueries
        });
    }

    async updateResize(masterTemplateId, parentId) {
        return this.$apollo.mutate({
            mutation: updateResizeTemplateMutation,
            variables: {
                masterTemplateId,
                parentId
            }
        });
    }

    async delete(masterTemplateId) {
        return this.$apollo.mutate({
            mutation: removeMasterTemplateMutation,
            variables: {
                masterTemplateId
            },
            refetchQueries: this.defaults.refetchQueries
        });
    }

    async associate(masterTemplateId, campaignId) {
        return this.$apollo.mutate({
            mutation: associateMasterTemplateMutation,
            variables: {
                masterTemplateId,
                campaignId
            }
        });
    }

    async disassociate(masterTemplateId, campaignId) {
        return this.$apollo.mutate({
            mutation: disassociateMasterTemplateMutation,
            variables: {
                masterTemplateId,
                campaignId
            }
        });
    }
}
