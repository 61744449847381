<template>
    <div class="library-visible-to-filter">
        <collapsible-widget :is-opened="true">
            <template #name>Visible To</template>

            <RadioGroup :value="selectedFilters">
                <Radio :label="null" @click.native.stop.prevent="setSelectedFilters(null)">Me</Radio>
                <Radio
                    v-for="{ label, value } in options"
                    :key="value"
                    :label="value"
                    @click.native.stop.prevent="setSelectedFilters(value)"
                >
                    {{ label }}
                </Radio>
            </RadioGroup>
        </collapsible-widget>
    </div>
</template>

<script>
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";
import { DeliverableLibraryAction, DeliverableLibraryGetters } from "@/store/modules/deliverableLibrary";
import { RolesGetter } from "@/store/modules/roles";

export default {
    name: "LibraryVisibleToFilter",
    components: {
        CollapsibleWidget
    },

    data() {
        return {
            selectedFilters: null
        };
    },

    computed: {
        options() {
            return this.rolesThatCanOnlyViewDeliverablesVisibleToRole.map(role => ({
                label: role.name,
                value: role._id
            }));
        },

        visibleToFilter: {
            get() {
                return this.$store.getters[DeliverableLibraryGetters.VisibleToFilter];
            },
            set(value) {
                this.$store.dispatch(DeliverableLibraryAction.UpdateVisibleToFilter, value);
            }
        },

        rolesThatCanOnlyViewDeliverablesVisibleToRole() {
            return this.$store.getters[RolesGetter.getRolesThatDoNotHaveAction](
                this.$auth.Actions.CanChangeDeliverableVisibility
            );
        }
    },

    watch: {
        visibleToFilter: {
            immediate: true,
            handler(newValue) {
                if (!Array.isArray(newValue)) {
                    this.selectedFilters = newValue;
                }
            }
        }
    },

    methods: {
        setSelectedFilters(selectedFilters) {
            this.selectedFilters = selectedFilters;
            this.visibleToFilter = selectedFilters ? [selectedFilters] : [];
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.library-visible-to-filter {
    .ivu-radio {
        margin: 0;
    }

    .ivu-radio-group {
        display: block;
    }

    .ivu-radio-wrapper {
        margin: 0;
        padding: 0 0 0 50px;
        display: block;
    }
}
</style>
