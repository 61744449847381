<template>
    <div class="insights-display-settings">
        <div class="insights-display-settings__dropdown">
            <span>Show me:&nbsp;</span>

            <Dropdown>
                <a href="javascript:void(0)">
                    <span class="link-like">{{ modeDisplay }}</span>
                    <Icon type="ios-arrow-down"></Icon>
                </a>
                <DropdownMenu slot="list">
                    <DropdownItem @click.native="setMode(CreativeInsightsMode.InsightsReport)">
                        {{ CreativeInsightsModeLabel[CreativeInsightsMode.InsightsReport] }}
                    </DropdownItem>
                    <DropdownItem @click.native="setMode(CreativeInsightsMode.QuadrantReport)">
                        {{ CreativeInsightsModeLabel[CreativeInsightsMode.QuadrantReport] }}
                    </DropdownItem>
                    <DropdownItem @click.native="setMode(CreativeInsightsMode.BarReport)">
                        {{ CreativeInsightsModeLabel[CreativeInsightsMode.BarReport] }}
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>

        <div v-if="false" class="insights-display-settings__dropdown">
            <span>Tell me about:&nbsp;</span>

            <Dropdown>
                <a href="javascript:void(0)">
                    <span class="link-like">{{ contentFilter }}</span>
                    <Icon type="ios-arrow-down"></Icon>
                </a>
                <DropdownMenu slot="list">
                    <DropdownItem @click.native="setContentFilter(CreativeInsightsContentFilter.Everything)">
                        Everything
                    </DropdownItem>
                    <DropdownItem divided @click.native="setContentFilter(CreativeInsightsContentFilter.Content)">
                        Content
                    </DropdownItem>
                    <DropdownItem @click.native="setContentFilter(CreativeInsightsContentFilter.Position)">
                        Position
                    </DropdownItem>
                    <DropdownItem
                        v-if="showContextItem"
                        @click.native="setContentFilter(CreativeInsightsContentFilter.Context)"
                    >
                        Context
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    </div>
</template>
<script>
import { CreativeInsightsAction } from "@/store/modules/creativeInsights";
import {
    CreativeInsightsContentFilter,
    CreativeInsightsMode,
    CreativeInsightsModeLabel
} from "@/enums/creativeInteligence";

export default {
    name: "InsightsDisplaySettings",

    computed: {
        mode: {
            set(mode) {
                this.$store.dispatch(CreativeInsightsAction.SetDisplayMode, mode);
            },

            get() {
                return this.$store.state.creativeInsights.displayMode;
            }
        },

        modeDisplay() {
            return CreativeInsightsModeLabel[this.mode];
        },

        contentFilter: {
            set(filter) {
                this.$store.dispatch(CreativeInsightsAction.SetContentFilter, filter);
            },

            get() {
                return this.$store.state.creativeInsights.contentFilter;
            }
        },

        showContextItem() {
            return this.mode !== CreativeInsightsMode.InsightsReport;
        }
    },

    created() {
        this.CreativeInsightsMode = CreativeInsightsMode;
        this.CreativeInsightsModeLabel = CreativeInsightsModeLabel;
        this.CreativeInsightsContentFilter = CreativeInsightsContentFilter;
    },

    methods: {
        setMode(mode) {
            this.mode = mode;
            if (
                mode === CreativeInsightsMode.InsightsReport &&
                this.contentFilter === CreativeInsightsContentFilter.Context
            ) {
                this.contentFilter = CreativeInsightsContentFilter.Everything;
            }
        },

        setContentFilter(contentFilter) {
            this.contentFilter = contentFilter;
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/_variables.scss";

.insights-display-settings {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &__dropdown {
        margin-right: $spacing-semi-large;
    }
}
</style>
