<template>
    <router-link class="client-card" :to="link">
        <card is-clickable>
            <div class="client-card__logo-container">
                <img v-if="logo" class="client-card__logo" :src="logo" />
                <Icon v-else type="ios-image-outline" class="client-card__missing-logo" />
            </div>
            <template #title>
                {{ name }}
            </template>
            <template v-if="showMenu && $auth.hasScope({ clientId: id })" #actionItems>
                <Dropdown trigger="click" class="client-card__options">
                    <card-footer-button>
                        <Icon type="ios-more" class="client-card-option--dots" data-testid="client_more-options" />
                    </card-footer-button>
                    <DropdownMenu slot="list">
                        <DropdownItem
                            v-if="$auth.userCan($auth.Actions.CanEditClient, { clientId: id })"
                            class="client-card-option--edit"
                            data-testid="client_edit-button"
                            @click.native="edit"
                        >
                            Edit
                        </DropdownItem>
                        <DropdownItem
                            class="client-card-option--manage"
                            data-testid="client_team-button"
                            @click.native="manageTeam"
                        >
                            Manage Team
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </template>
        </card>
    </router-link>
</template>

<script>
import Card from "@/components/Card/Card";
import CardFooterButton from "@/components/Card/CardFooterButton";

export default {
    components: {
        Card,
        CardFooterButton
    },

    props: {
        id: {
            type: String,
            required: true
        },
        logo: {
            type: String
        },
        name: {
            required: true,
            type: String
        },
        showMenu: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        link() {
            return {
                name: "CampaignsDashboard",
                params: { clientId: this.id }
            };
        }
    },

    methods: {
        edit() {
            this.$emit("edit");
        },

        manageTeam() {
            this.$emit("manage-team");
        }
    }
};
</script>

<style lang="scss">
@import "../../sass/_variables.scss";

$logo-height: 200px;

.client-card__logo {
    max-height: 75%;
    max-width: 75%;
    flex: 0 0 auto;
}

.client-card__logo-container {
    align-items: center;
    color: $grey5;
    display: flex;
    height: $logo-height;
    justify-content: center;
}

.client-card__missing-logo {
    align-items: center;
    display: flex;
    height: $logo-height;
    font-size: 80px;
    justify-content: center;
}
</style>
