<template>
    <Form
        ref="addAccountForm"
        class="add-platform-account"
        :rules="validationRules"
        :model="formData"
        @submit.native.prevent="onSubmitFormAccount"
    >
        <FormItem ref="accountName" label="Account name" prop="accountName">
            <Input
                v-model="formData.accountName"
                auto-complete="off"
                data-testid="add-platform-account__name"
            ></Input>
        </FormItem>
        <account-type-select :account-type="formData.accountType" @changeAccountType="onChangeAccountType" />
        <FormItem ref="campaignProfileId" label="Google Studio Feed" prop="campaignProfileId">
            <Input
                v-model="formData.campaignProfileId"
                auto-complete="off"
                data-testid="add-platform-account__id"
            ></Input>
        </FormItem>
        <Button type="primary" data-testid="new-platform-account__modal-button" @click="onSubmitFormAccount">
            {{ actionLabel }}
        </Button>
    </Form>
</template>
<script>
import activateAccountFormsMixin from "@/mixins/activateAccountFormsMixin";
import { checkOnlyNumbers } from "@/utils";
import AccountTypeSelect from "@/components/IntelligentDelivery/AccountTypeSelect";

export default {
    name: "BasicAccountForm",
    components: {
        AccountTypeSelect
    },
    mixins: [activateAccountFormsMixin],

    computed: {
        validationRules() {
            return {
                ...this.baseValidationRules,
                campaignProfileId: [
                    {
                        required: true,
                        message: "Please enter a Campaign Profile Id.",
                        trigger: "blur"
                    },
                    {
                        message: "Campaign Profile Id must contain only numbers",
                        validator: (rule, value) => checkOnlyNumbers(value),
                        trigger: "blur"
                    }
                ]
            };
        }
    }
};
</script>
