<template>
    <div class="preview-url-qa-code__wrapper">
        <hox-loading-spinner v-if="isLoading" :size="40" />
        <template v-else>
            <qr-code v-if="previewUrl" class="preview-url-qa-code" :value="previewUrl" :size="qrSize"></qr-code>
            <hox-alert v-else type="danger" size="small" show-icon="false" align-text="center">
                <template #title>Uh oh!</template>
                <template #content>
                    <p>Sorry! We could not generate preview link. Try again a moment.</p>
                </template>
                <template #actionItems>
                    <Button type="primary" @click="getUrl">Retry</Button>
                </template>
            </hox-alert>
        </template>
    </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import computeDeliverablePreview from "@/apollo/mutations/v2/ComputeDeliverablePreview.gql";
import HoxAlert from "@/components/common/Alert";
import DeliverablesService from "@/services/Deliverables";

export default {
    name: "PreviewUrlQrCode",
    components: {
        HoxAlert,
        "qr-code": QrcodeVue
    },

    props: {
        deliverable: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            hasError: false,
            isLoading: true,
            previewUrl: null,
            qrSize: 100
        };
    },

    computed: {
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        computedDeliverableIdentifiers() {
            return DeliverablesService.getComputedDeliverableIdentifiers([this.deliverable]);
        }
    },

    created() {
        this.getUrl();
    },

    methods: {
        async getUrl() {
            this.isLoading = true;
            this.hasError = false;

            try {
                const res = await this.$apollo.mutate({
                    mutation: computeDeliverablePreview,
                    variables: {
                        campaignId: this.campaignId,
                        computedDeliverableIdentifiers: this.computedDeliverableIdentifiers
                    }
                });

                if (res.data.computeDeliverablePreview) {
                    this.previewUrl = res.data.computeDeliverablePreview[0].url;
                } else {
                    this.hasError = true;
                }
            } catch (e) {
                this.hasError = true;
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
