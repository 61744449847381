<template>
    <div :class="containerClasses">
        <div v-if="showIcon" :class="iconContainerClasses">
            <Icon :size="iconSize" :type="icon" />
        </div>
        <div class="alert__content" :class="alignClass">
            <h3 v-if="hasTitle">
                <slot name="title" />
            </h3>
            <slot name="content" />
            <div v-if="hasActionItems" class="alert__action-items">
                <slot name="actionItems" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HoxAlert",
    props: {
        marginBottom: {
            default: "base",
            validator(value) {
                return ["base", "large", "none"].includes(value);
            }
        },

        marginTop: {
            default: "none",
            validator(value) {
                return ["base", "large", "none"].includes(value);
            }
        },

        type: {
            default: "info",
            type: String,
            validator(value) {
                return ["danger", "info", "warning", "success"].includes(value);
            }
        },

        showIcon: {
            type: Boolean,
            default: true
        },

        size: {
            type: String,
            default: "default",
            validator(value) {
                return ["small", "default"].includes(value);
            }
        },

        alignText: {
            type: String,
            default: "",
            validator(value) {
                return ["", "center", "end", "start"].includes(value);
            }
        },
        theme: {
            type: String,
            default: "light"
        }
    },
    data() {
        return {
            hasActionItems: undefined,
            hasTitle: undefined
        };
    },
    computed: {
        alignClass() {
            return this.alignText ? `alert__content-align alert__content-align--${this.alignText}` : "";
        },

        containerClasses() {
            const classes = [
                "alert",
                `alert--${this.size}`,
                `alert--margin-bottom-${this.marginBottom}`,
                `alert--margin-top-${this.marginTop}`,
                `alert--type-${this.type}`
            ];

            if (this.theme === "dark") {
                classes.push("alert--dark");
            }

            if (this.alignText) {
                classes.push(`alert__align--${this.alignText}`);
            }

            return classes;
        },

        icon() {
            if (this.type === "danger" || this.type === "warning") {
                return "md-alert";
            }
            if (this.type === "success") {
                return "md-checkmark-circle";
            }

            return "md-information-circle";
        },
        iconContainerClasses() {
            return ["alert__icon-container", `alert__icon-container--${this.type}`];
        },
        iconSize() {
            return this.size === "default" ? 64 : 32;
        }
    },
    mounted() {
        this.hasActionItems = this.$slots.actionItems !== undefined;
        this.hasTitle = this.$slots.title !== undefined;
    }
};
</script>

<style lang="scss">
@import "../../../sass/_variables.scss";

.alert {
    background-color: $grey1;
    display: flex;
    padding: $spacing-large $spacing ($spacing-large - $spacing-small);
    width: 100%;
}
.alert--dark {
    background-color: $cmp-dark-bg-color;

    &.alert--type-info {
        background-color: $info-color;

        .alert__icon-container {
            color: $white;
        }
    }
}

.alert--small.alert__align--center {
    .alert__icon-container {
        padding: 0 $spacing-small 0 0;
    }
}

.alert--small {
    padding: $spacing-small;
}

.alert--margin-bottom-base {
    margin-bottom: $spacing;
}

.alert--margin-bottom-large {
    margin-bottom: $spacing-large;
}

.alert--margin-bottom-none {
    margin-bottom: 0;
}

.alert--margin-top-base {
    margin-top: $spacing;
}

.alert--margin-top-large {
    margin-top: $spacing-large;
}

.alert--margin-top-none {
    margin-top: 0;
}

.alert__action-items {
    margin: 0 0 $spacing-small;
    padding: $spacing-small 0 0;
}

.alert__content {
    flex: 1 0 auto;
    flex-basis: 0;

    > h3 {
        font-size: $font-size-large;
        margin: 0 0 $spacing;
    }

    > p {
        font-size: $font-size-base;
        margin: 0 0 $spacing-small;
    }

    &-align {
        display: flex;

        p {
            flex: 1;
            margin: 0;
        }

        &--start {
            align-items: flex-start;
        }

        &--end {
            align-items: flex-end;
        }

        &--center {
            align-items: center;
        }
    }
}

.alert__icon-container {
    flex: 0 1 auto;
    flex-basis: 0;
    padding: 0 $spacing $spacing-small 0;
}

.alert__icon-container--danger {
    color: $error-color;
}

.alert__icon-container--info {
    color: $info-color;
}

.alert__icon-container--warning {
    color: $warning-color;
}

.alert__icon-container--success {
    color: $success-color;
}
</style>
