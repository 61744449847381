<template>
    <div class="library-qa-status-filter">
        <collapsible-widget :is-opened="true">
            <template #name>Production Status</template>
            <RadioGroup :value="qaStatusFilter">
                <Radio
                    v-for="filter in BannerQaStatusFilter"
                    :key="filter"
                    :label="filter"
                    @click.native.stop.prevent="setQaStatusFilter(filter)"
                >
                    <div
                        v-if="filter !== BannerQaStatusFilter.All"
                        class="library-qa-status-filter__status-dot-wrapper"
                    >
                        <qa-status-dot :status="filter" />
                    </div>
                    {{ BannerQaStatusFilterLabel[filter] }}
                </Radio>
            </RadioGroup>
        </collapsible-widget>
    </div>
</template>

<script>
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";
import QaStatusDot from "@/components/Qa/QaStatusDot";
import { BannerQaStatusFilter, BannerQaStatusFilterLabel } from "@/enums/banners";
import { DeliverableLibraryAction, DeliverableLibraryGetters } from "@/store/modules/deliverableLibrary";

export default {
    components: {
        CollapsibleWidget,
        QaStatusDot
    },
    computed: {
        qaStatusFilter: {
            get() {
                return this.$store.getters[DeliverableLibraryGetters.statusFilter];
            },
            set(value) {
                this.$store.dispatch(DeliverableLibraryAction.UpdateStatusFilter, value);
            }
        }
    },
    created() {
        this.BannerQaStatusFilter = BannerQaStatusFilter;
        this.BannerQaStatusFilterLabel = BannerQaStatusFilterLabel;
    },
    methods: {
        setQaStatusFilter(qaStatus) {
            this.qaStatusFilter = qaStatus;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.library-qa-status-filter {
    .ivu-radio {
        margin: 0;
    }

    .ivu-radio-group {
        display: block;
    }

    .ivu-radio-wrapper {
        margin: 0;
        padding: 0 0 0 50px;
        display: block;
    }
}

.library-qa-status-filter__status-dot-wrapper {
    display: inline-block;
    padding: 0 0 0 $spacing-smaller;
}
</style>
