<template>
    <hox-modal @close="close">
        <hox-loading-layer v-if="isSettingMetadataValues" />
        <template #header>Set "{{ metadataField.name }}" values</template>

        <form @submit.prevent="setMetadataValue">
            <hox-input ref="valueInput" v-model="metadataValue">
                <template #label>Column value</template>
            </hox-input>
        </form>
        <hox-alert size="small">
            <template #content>
                <p v-if="hasSelectedDeliverables">
                    This value will be set on the column "{{ metadataField.name }}" for
                    {{ deliverablesToSetMetadataValueOnCount }} selected line {{ itemOrItems }} on
                    <template v-if="allSelectedMode">all the pages.</template>
                    <template v-else>the current page.</template>
                </p>
                <template v-else>
                    <p>
                        This value will be set on the column "{{ metadataField.name }}" for all line items on the
                        current page.
                    </p>
                    <p>
                        You can select specific rows if you wish to limit the line items that this value is applied to.
                    </p>
                </template>
            </template>
        </hox-alert>
        <Alert v-if="hasErrorSettingMetadataValues" banner type="error">
            <template #desc>
                There was an unexpected error and no changes were saved. Hopefully it was a temporary issue and should
                work if you try again in a few moments.
            </template>
        </Alert>
        <campaign-locked-notice :no-polling="true"></campaign-locked-notice>
        <template #footer>
            <Button type="primary" :disabled="isCampaignLocked" @click="setMetadataValue">Save</Button>
        </template>
    </hox-modal>
</template>

<script>
import Deliverables from "@/services/Deliverables";
import {
    addComputedDeliverablesMetadataValue,
    addComputedDeliverablesMetadataValueUsingFilter,
    removeComputedDeliverablesMetadataValueByFieldId,
    removeComputedDeliverablesMetadataValueUsingFilter
} from "@/services/Metadata";
import { NotificationsAction } from "@/store/modules/notifications";
import { JobStatusToNotificationStatus } from "@/enums/jobs";
import { NotificationTypes } from "@/enums/notifications";
import { JobsAction } from "@/store/modules/jobs";
import CampaignLockedNotice from "@/views/Campaign/CampaignLockedNotice";

export default {
    components: { CampaignLockedNotice },
    props: {
        allSelectedMode: {
            type: Boolean,
            default: false
        },

        deliverables: {
            required: true,
            type: Array
        },

        libraryFiltersForQuery: {
            required: true,
            type: Object
        },

        metadataFieldId: {
            required: true,
            type: String
        },

        selectedDeliverables: {
            required: true,
            type: Array
        },

        totalItems: {
            required: true,
            type: Number
        }
    },

    data() {
        return {
            hasErrorSettingMetadataValues: false,
            isSettingMetadataValues: false,
            metadataValue: ""
        };
    },
    computed: {
        deliverablesToSetMetadataValueOn() {
            return this.hasSelectedDeliverables ? this.selectedDeliverables : this.deliverables;
        },

        deliverablesToSetMetadataValueOnCount() {
            if (this.allSelectedMode) {
                return this.totalItems;
            }

            return this.deliverablesToSetMetadataValueOn.length;
        },

        hasSelectedDeliverables() {
            return this.selectedDeliverables.length > 0 || this.allSelectedMode;
        },

        isCampaignLocked() {
            return this.$store.state.campaign.isLocked;
        },

        itemOrItems() {
            return this.deliverablesToSetMetadataValueOnCount === 1 ? "item" : "items";
        },

        metadataField() {
            return this.$store.state.deliverableLibrary.metadataFieldsByFieldId[this.metadataFieldId];
        }
    },

    async mounted() {
        await this.$nextTick();
        this.$refs.valueInput.focus();
    },

    methods: {
        close() {
            this.$emit("close");
        },

        setMetadataValue() {
            if (this.allSelectedMode) {
                this.setMetadataValueUsingFilter();
                return;
            }

            this.setMetadataValueOnDeliverables();
        },

        async setMetadataValueUsingFilter() {
            this.isSettingMetadataValues = true;
            this.hasErrorSettingMetadataValues = false;

            try {
                if (this.metadataValue) {
                    const job = await addComputedDeliverablesMetadataValueUsingFilter(
                        this.libraryFiltersForQuery,
                        this.metadataFieldId,
                        this.metadataValue
                    );

                    const notificationId = await this.$store.dispatch(NotificationsAction.Add, {
                        message: job.message,
                        status: JobStatusToNotificationStatus[job.status],
                        type: NotificationTypes.BackgroundUpdate,
                        job
                    });

                    this.$store.dispatch(JobsAction.SetNotificationIdByActiveJobId, {
                        jobId: job._id,
                        notificationId
                    });

                    this.$snackbar.info(
                        "Your task is now being processed. Some features in this campaign will be disabled until this is completed."
                    );
                } else {
                    const job = await removeComputedDeliverablesMetadataValueUsingFilter(
                        this.libraryFiltersForQuery,
                        this.metadataFieldId
                    );

                    const notificationId = await this.$store.dispatch(NotificationsAction.Add, {
                        message: job.message,
                        status: JobStatusToNotificationStatus[job.status],
                        type: NotificationTypes.BackgroundUpdate,
                        job
                    });

                    this.$store.dispatch(JobsAction.SetNotificationIdByActiveJobId, {
                        jobId: job._id,
                        notificationId
                    });

                    this.$snackbar.info(
                        "Your task is now being processed. Some features in this campaign will be disabled until this is completed."
                    );
                }
            } catch (err) {
                this.hasErrorSettingMetadataValues = true;
                return;
            } finally {
                this.isSettingMetadataValues = false;
            }
            this.close();
        },

        async setMetadataValueOnDeliverables() {
            this.isSettingMetadataValues = true;
            this.hasErrorSettingMetadataValues = false;
            const deliverableIdentifiers = Deliverables.getComputedDeliverableIdentifiers(
                this.deliverablesToSetMetadataValueOn
            );
            const idHashes = this.deliverablesToSetMetadataValueOn.map(({ idHash }) => idHash);
            try {
                if (this.metadataValue) {
                    const metadataValue = await addComputedDeliverablesMetadataValue(
                        deliverableIdentifiers,
                        this.metadataFieldId,
                        this.metadataValue
                    );
                    this.$emit("metadataValueUpdatedOnDeliverables", { idHashes, metadataValue });
                } else {
                    await removeComputedDeliverablesMetadataValueByFieldId(
                        deliverableIdentifiers,
                        this.metadataFieldId
                    );
                    this.$emit("metadataValueUpdatedOnDeliverables", {
                        idHashes,
                        metadataValue: {
                            field: {
                                _id: this.metadataFieldId
                            },
                            value: undefined
                        }
                    });
                }
            } catch (err) {
                this.hasErrorSettingMetadataValues = true;
                return;
            } finally {
                this.isSettingMetadataValues = false;
            }
            this.close();
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
</style>
