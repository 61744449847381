import { ChartDisplayValue, drawQuadrants } from "@/utils/chart";
import { CreativeInsightsGetters } from "@/store/modules/creativeInsights";
import { getMetricStat, utilisationToValue } from "@/utils/creativeInteligence";
import { numberToShortKM } from "@/utils";

const Color = {
    kpiMetric: "#58AFFF",
    confidenceMetric: "#8E66DD",
    kpiMetricHover: "#58AFFFBB",
    confidenceMetricHover: "#8E66DDBB"
};
export default {
    props: {
        data: {
            type: Object
        },

        displayValue: {
            type: String,
            default: ChartDisplayValue.KpiStat
        },

        labelMap: {
            type: Object,
            default() {
                return {};
            }
        },

        maxHeight: {
            type: Number
        },

        title: {
            type: String
        }
    },

    data() {
        return {
            chart: undefined,
            type: "scatter"
        };
    },

    computed: {
        canvasStyle() {
            if (!this.maxHeight) {
                return {};
            }

            return {
                maxHeight: `${this.maxHeight}px`
            };
        },

        chartData() {
            return Object.keys(this.data).reduce((acc, group) => {
                if (this.data[group].summaryStats) {
                    acc[group] = this.data[group];
                }

                return acc;
            }, {});
        },

        confidenceMetricMax() {
            return Math.max(Number.parseFloat(this.confidenceMetricValue) * 2, this.confidenceMetricOverallMax * 1.05);
        },

        confidenceMetricMin() {
            return 0;
        },

        confidenceMetricsName() {
            return this.$store.getters[CreativeInsightsGetters.ConfidenceMetricName];
        },

        confidenceMetricValue() {
            return this.$store.getters[CreativeInsightsGetters.ConfidenceMetricValue];
        },

        confidenceMetricOverallMax() {
            const stat = getMetricStat(this.confidenceMetricsName);

            return Math.max(...this.labels.map(label => this.chartData[label].summaryStats.confidenceMetric[stat]));
        },

        datasets() {
            const { backgroundColors, hoverBackgroundColors, values, labels, creatives } =
                this.generateScatterChartData(this.chartData);

            return [
                {
                    labels,
                    creatives,
                    data: values,
                    backgroundColor: backgroundColors,
                    hoverBackgroundColor: hoverBackgroundColors
                }
            ];
        },

        kpiMetricMax() {
            return Math.max(Number.parseFloat(this.kpiMetricValue) * 2, this.kpiMetricOverallMax * 1.05);
        },

        kpiMetricMin() {
            return 0;
        },

        kpiMetricsName() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricName];
        },

        kpiMetricValue() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricValue];
        },

        kpiMetricOverallMax() {
            if (this.displayValue === ChartDisplayValue.Utilisation) {
                return Math.max(
                    ...this.labels.map(label => utilisationToValue(this.chartData[label].summaryStats.utilisation))
                );
            }
            const stat = getMetricStat(this.kpiMetricsName);

            return Math.max(...this.labels.map(label => this.chartData[label].summaryStats.kpiMetric[stat]));
        },

        plugins() {
            return [
                {
                    beforeDraw: chart => {
                        drawQuadrants(chart, this.kpiMetricValue, this.confidenceMetricValue);
                    }
                }
            ];
        },

        labels() {
            return Object.keys(this.chartData);
        },

        scales() {
            return {
                y: {
                    beginAtZero: true,
                    min: this.kpiMetricMin,
                    max: this.kpiMetricMax,
                    ticks: {
                        display: true,
                        callback: value => {
                            if (this.displayValue === ChartDisplayValue.Utilisation) {
                                return value > 100 ? "" : `${value}%`;
                            }

                            return value;
                        }
                    },
                    grid: {
                        drawOnChartArea: false // only want the grid lines for one axis to show up
                    }
                },
                x: {
                    beginAtZero: true,
                    min: this.confidenceMetricMin,
                    max: this.confidenceMetricMax,
                    ticks: {
                        display: true,
                        callback: value => {
                            return this.getTickString(value);
                        }
                    },
                    grid: {
                        drawOnChartArea: false // only want the grid lines for one axis to show up
                    }
                }
            };
        }
    },

    methods: {
        generateScatterChartData(chartData) {
            const items = Object.keys(chartData);
            return items.reduce(
                (acc, label) => {
                    const { backgroundColor, hoverBackgroundColor } = this.getPointColors(label);
                    const { deliverables, summaryStats } = chartData[label];

                    if (!summaryStats || !deliverables) {
                        return acc;
                    }

                    return {
                        backgroundColors: [...acc.backgroundColors, backgroundColor],
                        hoverBackgroundColors: [...acc.hoverBackgroundColors, hoverBackgroundColor],
                        creatives: [...acc.creatives, deliverables],
                        labels: [...acc.labels, this.labelMap[label] || label],
                        values: [...acc.values, this.getPointValue(summaryStats)]
                    };
                },
                {
                    backgroundColors: [],
                    hoverBackgroundColors: [],
                    labels: [],
                    values: [],
                    creatives: []
                }
            );
        },

        getPointColors(label) {
            const labelWithHexRegEx = /^.*-#?([0-9a-f]{6})$/i;
            const [, matchedLabelColor] = label.match(labelWithHexRegEx) || [];

            if (matchedLabelColor) {
                return {
                    backgroundColor: `#${matchedLabelColor}`,
                    hoverBackgroundColor: `#${matchedLabelColor}AA`
                };
            }

            return {
                backgroundColor: Color.kpiMetric,
                hoverBackgroundColor: Color.kpiMetricHover
            };
        },

        getPointValue(summaryStats) {
            const kpiStat = getMetricStat(this.kpiMetricsName);
            const confidenceStat = getMetricStat(this.confidenceMetricsName);
            const y = summaryStats.kpiMetric[kpiStat];
            const x = summaryStats.confidenceMetric[confidenceStat];

            if (this.displayValue === ChartDisplayValue.Utilisation) {
                return {
                    x,
                    y: utilisationToValue(summaryStats.utilisation)
                };
            }

            return {
                x,
                y
            };
        },

        getTickString(v, mprecision = 0, kprecision = 0) {
            return numberToShortKM(v, mprecision, kprecision);
        },

        setTooltipText(tooltipModel) {
            let textLines = [];
            if (tooltipModel.body) {
                const bodyLines = tooltipModel.body.reduce((acc, { lines }) => {
                    lines.forEach(line => acc.add(line));
                    return acc;
                }, new Set());

                textLines = [...bodyLines].sort().map(line => {
                    const stats = this.data[line].summaryStats;
                    const deliverableAttributes = this.data[line].deliverables[0].attributes;
                    const attr = deliverableAttributes.find(({ attribute }) => attribute === line);

                    let attributeConfidence;
                    if (attr) {
                        attributeConfidence = attr.metadata[0].confidence;
                    }

                    return {
                        label: line,
                        stats,
                        attributeConfidence
                    };
                });
            }

            this.tooltipLines = textLines;
        }
    }
};
