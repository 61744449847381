import { apolloClient } from "@/apollo";
import AddMetadatFieldMutation from "@/apollo/mutations/v2/AddMetadataField.gql";
import RemoveMetadatFieldMutation from "@/apollo/mutations/v2/RemoveMetadataField.gql";
import AddComputedDeliverableMetadataValueMutation from "@/apollo/mutations/v2/AddComputedDeliverableMetadataValue.gql";
import AddComputedDeliverableMetadataValueUsingFiltersMutation from "@/apollo/mutations/v2/AddComputedDeliverableMetadataValueUsingFilters.gql";
import RemoveMetadataMutation from "@/apollo/mutations/v2/RemoveMetadata.gql";
import RemoveMetadataUsingFilterMutation from "@/apollo/mutations/v2/RemoveMetadataUsingFilter.gql";
import UpdateMetadatFieldMutation from "@/apollo/mutations/v2/UpdateMetadataField.gql";
import MetadataFieldsQuery from "@/apollo/queries/v2/MetadataFields.gql";
import { constructLibraryFiltersQueryVariables } from "@/utils";

export async function addMetadataField(campaignId, name) {
    const { data } = await apolloClient.mutate({
        mutation: AddMetadatFieldMutation,
        fetchPolicy: "no-cache",
        variables: {
            campaignId,
            name
        }
    });
    const metadataField = data.addMetadataField;
    return metadataField;
}

export async function getMetadataFieldsOfCampaign(campaignId) {
    const { data } = await apolloClient.query({
        fetchPolicy: "network-only",
        query: MetadataFieldsQuery,
        variables: {
            campaignId
        }
    });
    const { metadataFields } = data;
    return metadataFields;
}

export async function removeMetadataField(fieldId) {
    await apolloClient.mutate({
        mutation: RemoveMetadatFieldMutation,
        variables: {
            fieldId
        }
    });
    return fieldId;
}

export async function addComputedDeliverablesMetadataValue(computedDeliverableIdentifiers, fieldId, value) {
    const { data } = await apolloClient.mutate({
        mutation: AddComputedDeliverableMetadataValueMutation,
        fetchPolicy: "no-cache",
        variables: {
            computedDeliverableIdentifiers,
            fieldId,
            value
        }
    });
    return data.addMetadataValue;
}

export async function addComputedDeliverablesMetadataValueUsingFilter(libraryFilters, fieldId, value) {
    const libraryFiltersVars = constructLibraryFiltersQueryVariables(libraryFilters);
    const { data } = await apolloClient.mutate({
        mutation: AddComputedDeliverableMetadataValueUsingFiltersMutation,
        fetchPolicy: "no-cache",
        variables: {
            libraryFilters: libraryFiltersVars,
            fieldId,
            value
        }
    });
    return data.addMetadataValue;
}

export async function removeComputedDeliverablesMetadataValueByFieldId(computedDeliverableIdentifiers, fieldId) {
    await apolloClient.mutate({
        mutation: RemoveMetadataMutation,
        variables: {
            computedDeliverableIdentifiers,
            fieldId
        }
    });
}

export async function removeComputedDeliverablesMetadataValueUsingFilter(libraryFilters, fieldId) {
    const libraryFiltersVars = constructLibraryFiltersQueryVariables(libraryFilters);
    const { data } = await apolloClient.mutate({
        mutation: RemoveMetadataUsingFilterMutation,
        variables: {
            libraryFilters: libraryFiltersVars,
            fieldId
        }
    });

    return data.removeMetadata;
}

export async function updateMetadataField(fieldId, name) {
    const { data } = await apolloClient.mutate({
        mutation: UpdateMetadatFieldMutation,
        variables: {
            fieldId,
            name
        }
    });
    const metadataField = data.updateMetadataField;
    return metadataField;
}
