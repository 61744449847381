<template>
    <hox-notification-item
        :progress-percentage="progressPercentage"
        :show-clear-button="isCompleted"
        :show-error-message="showErrorMessage"
        :status="status"
        @clear="removeNotification"
    >
        <template #title>
            {{ title }}
        </template>
        <template #subtitle>
            {{ statusText }}
        </template>
        <template #errorMessage>
            <p>We could not download some ads.</p>
            <p>Please try again in a few moments.</p>
        </template>
        <template #content>
            <template v-if="notification.deliverablesThatFailedToDownload.length > 0">
                <p><strong>We could not download these ads:</strong></p>
                <ul>
                    <li
                        v-for="deliverable in notification.deliverablesThatFailedToDownload"
                        :key="deliverable.idHash"
                        class="download-notification-item__list-item"
                        :title="deliverable.reportingLabel"
                    >
                        {{ deliverable.reportingLabel }}
                    </li>
                </ul>
            </template>
            <template v-if="notification.deliverablesWithDownloadLink.length > 0">
                <p><strong>Success! Click on the links to download the ads.</strong></p>
                <ul>
                    <li
                        v-for="deliverable in notification.deliverablesWithDownloadLink"
                        :key="deliverable.idHash"
                        class="download-notification-item__list-item"
                        :title="deliverable.reportingLabel"
                    >
                        <a :href="deliverable.url">
                            {{ deliverable.reportingLabel }}
                        </a>
                    </li>
                </ul>
            </template>
            <template v-if="deliverablesStillToBeProcessed.length > 0">
                <p><strong>Hoxton is preparing these ads:</strong></p>
                <ul>
                    <li
                        v-for="deliverable in deliverablesStillToBeProcessed"
                        :key="deliverable.idHash"
                        class="download-notification-item__list-item"
                        :title="deliverable.reportingLabel"
                    >
                        {{ deliverable.reportingLabel }}
                    </li>
                </ul>
            </template>
        </template>
    </hox-notification-item>
</template>

<script>
import { NotificationStatus } from "@/enums/notifications";
import { PlatformLabel } from "@/enums/platforms";
import { NotificationsAction } from "@/store/modules/notifications";

export default {
    props: {
        notification: {
            required: true,
            type: Object
        }
    },
    computed: {
        deliverablesStillToBeProcessed() {
            return this.notification.deliverables.filter(
                deliverable =>
                    this.notification.deliverablesWithDownloadLink.every(
                        deliverableWithDownloadLink => deliverableWithDownloadLink.idHash !== deliverable.idHash
                    ) &&
                    this.notification.deliverablesThatFailedToDownload.every(
                        deliverableThatFailedToDownload => deliverableThatFailedToDownload.idHash !== deliverable.idHash
                    )
            );
        },

        downloadOrDownloads() {
            return this.notification.deliverables.length === 1 ? "download" : "downloads";
        },

        failedOrCompletedDownloadsCount() {
            return (
                this.notification.deliverablesWithDownloadLink.length +
                this.notification.deliverablesThatFailedToDownload.length
            );
        },

        hasDownloadErrors() {
            return this.notification.deliverablesThatFailedToDownload.length > 0;
        },

        isCompleted() {
            return this.notification.status === NotificationStatus.Completed;
        },

        isCompletedStatusText() {
            if (this.hasDownloadErrors) {
                const errorOrErrors =
                    this.notification.deliverablesThatFailedToDownload.length === 1 ? "error" : "errors";
                return `Completed with ${this.notification.deliverablesThatFailedToDownload.length} ${errorOrErrors}`;
            }
            return `${this.notification.deliverables.length} ${this.downloadOrDownloads} completed`;
        },

        isInProgress() {
            return this.notification.status === NotificationStatus.InProgress;
        },

        isInProgressStatusText() {
            if (this.failedOrCompletedDownloadsCount === 0) {
                return `Preparing ${this.notification.deliverables.length} ${this.downloadOrDownloads}`;
            }
            return `In progress (${this.failedOrCompletedDownloadsCount} of ${this.notification.deliverables.length} completed)`;
        },

        progressPercentage() {
            if (this.isCompleted || this.failedOrCompletedDownloadsCount === 0) {
                return undefined;
            }
            const progressPercentage =
                (this.failedOrCompletedDownloadsCount / this.notification.deliverables.length) * 100;
            return progressPercentage;
        },

        showErrorMessage() {
            return this.isCompleted && this.hasDownloadErrors;
        },

        status() {
            /*
                Because we're not explicitly marking a download notification
                as failed (because it can complete with some errors) we detect
                if we're showing an error message to decide whether the notification
                has the status of failed.
            */
            if (this.showErrorMessage) {
                return NotificationStatus.Failed;
            }
            return this.notification.status;
        },

        statusText() {
            if (this.isInProgress) {
                return this.isInProgressStatusText;
            }
            if (this.isCompleted) {
                return this.isCompletedStatusText;
            }
            return undefined;
        },

        title() {
            const platformLabel = PlatformLabel[this.notification.platform];
            if (platformLabel) {
                return `Download ${platformLabel} ads`;
            }
            return "Download ads";
        }
    },

    methods: {
        removeNotification() {
            this.$store.dispatch(NotificationsAction.RemoveItem, this.notification.id);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.download-notification-item__list-item {
    list-style-position: inside;
    margin-left: 0 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
