import { render, staticRenderFns } from "./DetachablePanel.vue?vue&type=template&id=c16ddbd4"
import script from "./DetachablePanel.vue?vue&type=script&lang=js"
export * from "./DetachablePanel.vue?vue&type=script&lang=js"
import style0 from "./DetachablePanel.vue?vue&type=style&index=0&id=c16ddbd4&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports