/**
 * eslint erroneously flags this as missing an import, but it's just an alias for local files,
 * same in all the other files
 */
// eslint-disable-next-line import/no-extraneous-dependencies
import parseHoxtonManifest from "shared-utils/parseHoxtonManifest";
// eslint-disable-next-line import/no-extraneous-dependencies
import isMediaType from "shared-utils/isMediaType";
import { apolloClient } from "@/apollo";
import mediaItemQuery from "@/apollo/queries/MediaItem.gql";
import { EditableType } from "@/enums/editables";
import { snackbar } from "@/utils";
import {
    getMasterTemplateByPath,
    newId,
    getEditableGroupValues,
    getEditableId,
    getOverwrites,
    setState,
    persistenceKey,
    setManifest,
    setOverwrite
} from "./state";
import { HoxtonCliClient } from "../HoxtonCliClient";
import { getTemplatesFromManifest } from "./masterTemplates";
import { GraphQLError } from "../GraphQLError";

// Setup some mock data for our 'development campaign'
const clientId = newId();
const localDevelopmentCampaign = {
    name: "LOCAL DEVELOPMENT",
    _id: newId(),
    id: "LOCAL-DEVELOPMENT",
    dcProfileId: null,
    jiraTicketUrl: null,
    resourceGroupIds: null,
    languages: ["en"],
    client: {
        _id: clientId,
        id: "1234567890",
        name: "Hoxton CLI",
        imageProcessingEnabled: false,
        connectModuleEnabled: false,
        __typename: "Client"
    },
    mastheadImageUrl: null,
    mastheadUploadConfig: null,
    __typename: "Campaign"
};

/**
 * Takes the URI for a template and returns a Hoxton manifest parsed from its HTML
 * @param {String} templateUri
 */
const getManifestFromTemplate = async templateUri => {
    const response = await fetch(templateUri);
    if (!response.ok) {
        throw new Error(`Unable to fetch uri: ${templateUri}`);
    }
    const html = await response.text();
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const hoxton = doc.querySelector("hoxton");
    if (!hoxton) {
        throw new Error(`Unable to find hoxton tag on ${templateUri}`);
    }
    const parsed = parseHoxtonManifest(hoxton.getAttribute("data"));
    if (!parsed || !parsed.success) {
        throw new Error(`Unable to parse hoxton tag on ${templateUri}`);
    }
    return parsed;
};

const getMasterTemplatesByCampaign = (manifests, serverUri) => {
    return manifests.reduce((acc, manifest) => {
        // Store the manifest against the path in our localState
        // This allows us to reuse the manifest without re-fetching it between reloads
        setManifest(manifest.path, manifest);
        return acc.concat(...getTemplatesFromManifest(manifest, serverUri));
    }, []);
};

// [{editableGroupValues:[], editables:[], label: "", name:""}]
const getEditableGroupsByCampaign = (manifests, serverUri) => {
    // In the manifest the groups are listed as properties of the editables so
    //  we need to loop the editables to find the groups
    const editablesAndGroupById = manifests.reduce((acc, { path, editables = [] }) => {
        editables.forEach(editable => {
            const { editableGroups = [] } = editable;
            // If an editable does not have editableGroups set then it needs to be put in the 'other groups' group
            if (editableGroups.length === 0) {
                editableGroups.push("other fields");
            }
            // An editable can have more than one group so we need to loop again
            editableGroups.forEach(label => {
                // Set the group in our accumulator if we have not encountered it yet
                const groupName = label.toLowerCase();
                if (!acc[groupName]) {
                    acc[groupName] = {
                        name: groupName,
                        label,
                        editableGroupValues: [
                            // Get the editable group values from our local state
                            // This ensures the groups do not disappear between each
                            //  GQL refetch and also each Editor reload
                            ...getEditableGroupValues(groupName)
                        ],
                        editables: [],
                        __typename: "EditableGroup"
                    };
                }

                // Prefix the values with the server address
                //  which allows images etc to be visible in the sidebar
                const basePath = path.replace(/index.html$/, "");
                const value =
                    isMediaType(editable.type) && editable.type !== EditableType.Folder
                        ? `${serverUri}/${basePath}${editable.defaultValue}`
                        : editable.defaultValue;

                // Get the master template id and other details we need from local state
                const template = getMasterTemplateByPath(path);
                const templateIds = [template._id];
                // If the user has created other sizes for this path / manifest combo then we need
                //  to create defaultValue entries for those as well
                if (template.sizes && template.sizes.length) {
                    templateIds.push(...template.sizes.map(size => size._id));
                }
                // Loop the template ids and set the editables for the group
                templateIds.forEach(masterTemplateId => {
                    const defaultValue = {
                        masterTemplateId,
                        value,
                        editableGroups: editable.editableGroups,
                        dimensions: null
                    };
                    // If the editable has already been added to the group then we can just push a
                    //  defaultValue in for the template size we are working with
                    const existingEditable = acc[groupName].editables.find(e => e.name === editable.name);
                    if (existingEditable) {
                        existingEditable.defaultValues.push(defaultValue);
                    } else {
                        // Add the editable to the group
                        acc[groupName].editables.push({
                            defaultValues: [defaultValue],
                            label: editable.friendlyName || editable.name,
                            name: editable.name,
                            type: editable.type,
                            restricted: false,
                            _id: getEditableId(editable.name),
                            __typename: "Editable"
                        });
                    }
                });
            });
        });

        return acc;
    }, {});

    // Convert the group-name keyed object to an array
    return Object.values(editablesAndGroupById);
};

/**
 * A list of media item ids to fetch from the GQL server
 *
 * @param {Array} mediaItemIds
 */
const getMediaItems = async mediaItemIds => {
    const mediaItems = await Promise.all(
        mediaItemIds.map(mediaItemId => {
            return apolloClient.query({
                query: mediaItemQuery,
                variables: {
                    mediaItemId
                },
                fetchPolicy: "no-cache"
            });
        })
    );
    // Return a keyed object so that media items are easy to find
    return mediaItems.reduce((acc, { data }) => {
        const { mediaItem } = data;
        if (mediaItem) {
            acc[mediaItem.id] = mediaItem;
        }
        return acc;
    }, {});
};

/**
 * Check the media item URLs that we have loaded
 *
 * @param {Array} overwrites the overwrites to check
 */
const ensureSignedUrlsAreNotExpired = async overwrites => {
    // Find all of the expired URLs
    const expiredMediaItemIds = Array.from(
        overwrites.reduce((acc, overwrite) => {
            const { mediaItem } = overwrite;
            if (!mediaItem) {
                return acc;
            }

            const url = new URL(mediaItem.url);
            const expires = url.searchParams.get("Expires");
            if (expires * 1000 > Date.now()) {
                return acc;
            }

            acc.add(mediaItem.id);

            return acc;
        }, new Set())
    );

    // If we have none which are expired then we can just return the overwrites
    if (!expiredMediaItemIds.length) {
        return overwrites;
    }

    // Process the expired urls
    const updatedMediaItems = await getMediaItems(expiredMediaItemIds);

    // Return an updated set of overwrites
    const updatedOverwrites = overwrites.map(overwrite => {
        const { mediaItem } = overwrite;
        if (!mediaItem || !updatedMediaItems[mediaItem.id]) {
            return overwrite;
        }

        return {
            ...overwrite,
            mediaItem: {
                ...mediaItem,
                url: updatedMediaItems[mediaItem.id].url
            }
        };
    });

    // Put the updated URLs back into the state so that they are persisted
    updatedOverwrites.forEach(overwrite => {
        setOverwrite(overwrite);
    });

    return overwrites;
};

const getEditablesWithOverwritesByCampaign = async editableGroups => {
    // Retrieve the created overwrites from our local state
    const overwrites = getOverwrites();

    // Check the signed URLs for images are still valid
    const overwritesWithSignedUrls = await ensureSignedUrlsAreNotExpired(overwrites);

    return editableGroups.reduce((acc, group) => {
        const editableAndOverwrites = group.editables.map(editable => ({
            editable,
            overwrites: overwritesWithSignedUrls.filter(overwrite => {
                return overwrite.editableId === editable._id;
            }),
            __typename: "EditableWithOverwrites"
        }));
        return acc.concat(editableAndOverwrites);
    }, []);
};

export default async (operation, serverUri) => {
    // Connect to the CLI server and grab the template list
    const cliClient = new HoxtonCliClient(serverUri);
    const [templateResponse, persistedState] = await Promise.all([
        // Get list of template on the file system
        cliClient.getTemplateList(),
        // Get any persisted state saved in this project directory
        cliClient.getData(persistenceKey)
    ]);
    // If state was returned then we can set our localState to equal it
    if (persistedState) {
        setState(persistedState);
    }
    // Loop each of the template files and retrieve their manifests
    const manifests = await Promise.all(
        templateResponse.templates.map(async template => {
            const { path } = template;
            try {
                // Get manifest from template
                const manifest = await getManifestFromTemplate(`${serverUri}/${path}`);
                return { ...manifest.data, path };
            } catch (err) {
                snackbar.error(`Unable to parse manifest for path: ${path}.`, err.message, {
                    duration: 10,
                    closable: true
                });
                return null;
            }
        })
    );
    try {
        // Remove any null manifests that may exist due to errors
        const usableManifests = manifests.filter(Boolean);
        const editableGroups = getEditableGroupsByCampaign(usableManifests, serverUri);
        // Return a response equivalent to the one you would get if the request went to the GQL server
        return {
            campaign: localDevelopmentCampaign,
            editableGroupsByCampaign: editableGroups,
            editablesWithOverwritesByCampaign: await getEditablesWithOverwritesByCampaign(editableGroups),
            masterTemplatesByCampaign: getMasterTemplatesByCampaign(usableManifests, serverUri)
        };
    } catch (err) {
        throw new GraphQLError(err.message);
    }
};
