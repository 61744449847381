import HoxtonError from "./HoxtonError";

// TODO: account for the case where only a message is provided so that we do not need to pass undefined, undefined as params
export default class ValidationError extends HoxtonError {
    constructor(field, value, message) {
        // If message is not provided then we can default the message
        super(message || `"${value}" is not a valid value for "${field}"`, "INVALID");
        // Assign extra debug data to the extensions object
        Object.assign(this.extensions, { field, value });
    }
}
