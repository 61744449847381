<template>
    <Modal
        :value="value"
        draggable
        footer-hide
        scrollable
        transfer
        :width="width"
        :closable="closable"
        :class-name="classes"
        class="my-content"
        :z-index="zIndex"
        @input="onClose"
    >
        <template #header>
            <slot name="header">
                <div class="detachable-panel__header" @dblclick="onDoubleClick">
                    <span><slot name="title">Settings</slot></span>

                    <span v-if="attachable" class="detachable-panel__header-icon">
                        <Icon type="md-swap" @click.capture.native.stop.prevent="onAttach" />
                    </span>
                </div>
            </slot>
        </template>
        <div class="detachable-panel__content">
            <slot name="content"></slot>
        </div>
    </Modal>
</template>
<script>
export default {
    name: "DetachablePanel",
    props: {
        value: {
            type: Boolean,
            required: true
        },

        className: {
            type: String
        },

        attachable: {
            type: Boolean,
            default: true
        },

        closable: {
            type: Boolean,
            default: true
        },
        width: {
            type: Number,
            default: 250
        }
    },

    data() {
        return {
            collapsedMinHeight: "49px",
            expandedMinHeight: "300px",
            expandedHeight: "300px",
            isDetached: true,
            zIndex: 500
        };
    },

    computed: {
        classes() {
            let closableClass = "";
            if (this.closable) {
                closableClass = "detachable-panel--closable";
            }
            return `theme-campaign-dark dark-scrollbar detachable-panel detachable-panel__modal ${this.className} ${closableClass}`;
        }
    },

    methods: {
        onAttach() {
            this.$emit("attach");
        },

        onClose(evt) {
            this.$emit("close", evt);
        },

        onDoubleClick() {
            const contentEl = this.$el.querySelector(".ivu-modal-content");

            if (contentEl.style.minHeight === this.collapsedMinHeight) {
                contentEl.style.height = this.expandedHeight;
                contentEl.style.minHeight = this.expandedMinHeight;
            } else {
                this.expandedHeight = contentEl.style.height;
                contentEl.style.height = this.collapsedMinHeight;
                contentEl.style.minHeight = this.collapsedMinHeight;
            }
        }
    }
};
</script>
<style lang="scss">
@import "../../../sass/variables";

.detachable-panel {
    color: $cmp-dark-font-color;

    & .ivu-collapse-header {
        font-size: $font-size-base;
    }

    &__modal {
        overflow: hidden;
    }

    &__content {
        min-width: 200px;
        width: 100%;
        height: 100%;
    }

    &--closable &__header {
        padding-right: 24px;
    }

    .ivu-modal-content {
        box-shadow: 1px 1px 5px 0 #000000;
        background-color: $cmp-dark-bg-color;
        border-radius: $border-radius-base;
        resize: both;
        overflow: hidden;
        min-width: 200px;
        min-height: 300px;
        padding-bottom: 45px;
        height: 100%;
    }

    .ivu-modal {
        margin: 0 0 0 auto;
    }

    .ivu-modal-header {
        border-color: $cmp-dark-border-color;

        p,
        &-inner {
            color: $cmp-dark-font-color;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 20px;

        &-icon {
            cursor: pointer;
            color: #999;

            &:hover {
                color: #444;
            }
        }
    }

    .ivu-modal-body {
        padding: 0;
        height: 100%;
    }
}
</style>
