<template>
    <div id="app" :class="themeClassName">
        <hox-loading-bar />
        <Layout class="campaign-layout">
            <creative-insights-nav v-if="showTopNav" />
            <Sider
                class="creative-insights__tags-slider"
                :collapsible="true"
                :value="!isSidebarOpen"
                :collapsed-width="sidebarWidthCollapsed"
                :width="sidebarWidthOpen"
                :hide-trigger="true"
            >
                <portal-target name="main-sidebar" slim><tags-drawer /></portal-target>
            </Sider>

            <Layout class="campaign-content">
                <template v-if="isAuthorizedContentVisible">
                    <portal-target name="content-header" slim><creative-insights-header /></portal-target>
                </template>
                <Content class="creative-insights-content">
                    <router-view :key="$route.fullPath" class="hox-full-height hox-full-width router-view" />
                </Content>
            </Layout>
        </Layout>
    </div>
</template>

<script>
import HoxLoadingBar from "@/HoxLoadingBar";
import themeMixin from "@/mixins/themeMixin";
import CreativeInsightsNav from "@/components/CreativeIntelligence/CreativeInsightsNav";
import CreativeInsightsHeader from "@/components/CreativeIntelligence/CreativeInsightsHeader";
import TagsDrawer from "@/components/Reporting/TagsDrawer";

export default {
    name: "ThemeCreativeInsights",
    components: {
        TagsDrawer,
        CreativeInsightsHeader,
        CreativeInsightsNav,
        HoxLoadingBar
    },
    mixins: [themeMixin],

    data() {
        return {
            sidebarWidth: 250,
            sidebarCollapsedWidth: 12
        };
    },

    computed: {
        showTopNav() {
            try {
                if (localStorage.getItem("hoxDomain") === "imagine") {
                    return window.self === window.top;
                }
                return true;
            } catch (e) {
                return false;
            }
        }
    }
};
</script>

<style lang="scss">
@import "../sass/variables";

@font-face {
    font-family: "WPP Light";
    src:
        url(https://open.wpp.com/wp-content/uploads/2020/05/wpp-light.woff) format("woff"),
        url(https://open.wpp.com/wp-content/uploads/2020/05/wpp-light.otf) format("truetype");
}
@font-face {
    font-family: "WPP Regular";
    src:
        url(https://open.wpp.com/wp-content/uploads/2020/05/wpp-regular.woff) format("woff"),
        url(https://open.wpp.com/wp-content/uploads/2020/05/wpp-regular.otf) format("truetype");
}
@font-face {
    font-family: "WPP Medium";
    src:
        url(https://open.wpp.com/wp-content/uploads/2020/05/wpp-medium.woff) format("woff"),
        url(https://open.wpp.com/wp-content/uploads/2020/05/wpp-medium.otf) format("truetype");
}
@font-face {
    font-family: "WPP Bold";
    src:
        url(https://open.wpp.com/wp-content/uploads/2020/05/wpp-bold.woff) format("woff"),
        url(https://open.wpp.com/wp-content/uploads/2020/05/wpp-bold.otf) format("truetype");
}

html {
    overflow-y: scroll;
}

.ivu-btn-primary {
    color: #fff;
    background-color: $ci-blue;
    border-color: $ci-blue;
}

.theme-creative-insights,
.hox-modal__wrapper--creative-insights {
    font-family: "WPP Regular", sans-serif;
}

.main-content {
    background-color: transparent;
}

.ivu-layout-sider {
    background-color: transparent;
}
</style>
