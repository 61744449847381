<template>
    <div class="reporting-tags-list">
        <tags-stats-data-provider :key="campaignId" :tags="tags" @data="onData" @loading="onStatsLoading" />
        <hox-loading-layer v-if="isLoading" :is-full-screen="false" />
        <template v-else-if="tags.length">
            <template v-if="showCategories">
                <section v-for="category in tagCategories" :key="category">
                    <h4>{{ category }}</h4>
                    <reporting-tag
                        v-for="tag in tagsByCategories(category)"
                        :key="tag.tagId"
                        :tag="tag"
                        :stats="tagStats[tag.tagId] || {}"
                    />
                </section>
            </template>
            <template v-else>
                <reporting-tag v-for="tag in tags" :key="tag.tagId" :tag="tag" :stats="tagStats[tag.tagId] || {}" />
            </template>
        </template>
    </div>
</template>

<script>
import ReportingTag from "@/components/CreativeIntelligence/ReportingTag";
import { TagsAction } from "@/store/modules/ciTags";
import TagsStatsDataProvider from "@/components/CreativeIntelligence/TagsStatsDataProvider";

const sortTagsByName = ({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB);

export default {
    name: "ReportingTagsList",
    components: { TagsStatsDataProvider, ReportingTag },

    data() {
        return {
            tagStats: {}
        };
    },

    computed: {
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        isLoading() {
            return this.$store.state.ciTags.isLoading;
        },

        tagCategories() {
            return this.$store.state.ciTags.tagCategories;
        },

        tags() {
            return [...this.$store.state.ciTags.tags].sort(sortTagsByName);
        },

        showCategories() {
            return this.tagCategories.length > 1;
        }
    },

    created() {
        this.$store.dispatch(TagsAction.GetTags, this.clientId);
    },

    methods: {
        onData({ data }) {
            this.tagStats = data;
        },

        onStatsLoading(val) {
            if (val) {
                this.tagStats = {};
            }
        },

        tagsByCategories(category) {
            if (!this.$store.state.ciTags.tagsByCategory || !this.$store.state.ciTags.tagsByCategory[category]) {
                return {};
            }

            return this.$store.state.ciTags.tagsByCategory[category]
                .map(tagId => this.$store.state.ciTags.tagsById[tagId])
                .filter(Boolean)
                .sort(sortTagsByName);
        }
    }
};
</script>
<style lang="scss">
@import "@/../sass/_variables.scss";

.reporting-tags-list {
    width: 100%;
    overflow: auto;
    height: 100%;

    section {
        margin-bottom: $spacing;
    }

    h4 {
        padding-left: $spacing;
        text-transform: capitalize;
    }
}
</style>
