<template>
    <div class="library-deliverable-table-metadata-search-header-cell">
        <Input v-model="searchString" icon="ios-search" size="small" />
    </div>
</template>

<script>
import { DeliverableLibraryAction } from "@/store/modules/deliverableLibrary";

export default {
    props: {
        fieldId: {
            type: String,
            required: true
        }
    },

    computed: {
        searchString: {
            get() {
                return this.$store.state.deliverableLibrary.metadataSearchFiltersByFieldId[this.fieldId];
            },
            set(searchString) {
                if (searchString) {
                    this.$store.dispatch(DeliverableLibraryAction.SetMetadataSearchFiltersByFieldId, {
                        fieldId: this.fieldId,
                        searchString
                    });
                } else {
                    this.$store.dispatch(DeliverableLibraryAction.UnsetMetadataSearchFiltersByFieldId, this.fieldId);
                }
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
.library-deliverable-table-metadata-search-header-cell {
    width: 100%;
}
</style>
