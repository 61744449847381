import { apolloClient } from "@/apollo";
import AddQAAnnotationCommentOnComputedDeliverableMutation from "@/apollo/mutations/v2/annotations/AddQAAnnotationCommentOnComputedDeliverable.gql";
import AddQAAnnotationOnComputedDeliverableMutation from "@/apollo/mutations/v2/annotations/AddQAAnnotationOnComputedDeliverable.gql";
import ChangeQAAnnotationStatusOnComputedDeliverableMutation from "@/apollo/mutations/v2/annotations/ChangeQAAnnotationStatusOnComputedDeliverable.gql";
import RemoveQAAnnotationCommentOnComputedDeliverableMutation from "@/apollo/mutations/v2/annotations/RemoveQAAnnotationCommentOnComputedDeliverable.gql";
import RemoveQAAnnotationOnComputedDeliverableMutation from "@/apollo/mutations/v2/annotations/RemoveQAAnnotationFromComputedDeliverable.gql";
import UpdateQAAnnotationOnComputedDeliverableMutation from "@/apollo/mutations/v2/annotations/UpdateQAAnnotationOnComputedDeliverable.gql";
import UpdateQAAnnotationCommentOnComputedDeliverableMutation from "@/apollo/mutations/v2/annotations/UpdateQAAnnotationCommentOnComputedDeliverable.gql";
import SetComputedDeliverableStatusMutation from "@/apollo/mutations/v2/SetComputedDeliverableStatus.gql";
import SetComputedDeliverableVisibleToMutation from "@/apollo/mutations/v2/SetComputedDeliverableVisibleTo.gql";
import ComputedDeliverablesStatusQuery from "@/apollo/queries/v2/ComputedDeliverablesStatus.gql";
import ComputedDeliverablesAnnotationsQuery from "@/apollo/queries/v2/ComputedDeliverablesAnnotations.gql";
import DeliverablesService from "@/services/Deliverables";
import { constructLibraryFiltersQueryVariables, isEmptyObject } from "../utils";

export async function addAnnotationToDeliverable(campaignId, deliverable, content, time, posX, posY, mentions) {
    const [computedDeliverableIdentifier] = DeliverablesService.getComputedDeliverableIdentifiers([deliverable]);
    const { data } = await apolloClient.mutate({
        mutation: AddQAAnnotationOnComputedDeliverableMutation,
        variables: {
            campaignId,
            computedDeliverableIdentifier,
            description: content,
            timeline: time,
            xpos: posX,
            ypos: posY,
            mentions
        }
    });
    const savedAnnotation = data.addQAAnnotationOnComputedDeliverable;
    return savedAnnotation;
}

export async function addCommentToAnnotation(annotationId, content, mentions) {
    const { data } = await apolloClient.mutate({
        mutation: AddQAAnnotationCommentOnComputedDeliverableMutation,
        variables: {
            annotationId,
            content,
            mentions
        }
    });
    const comment = data.addQAAnnotationCommentOnComputedDeliverable;
    return comment;
}

export async function deleteAnnotation(annotationId) {
    await apolloClient.mutate({
        mutation: RemoveQAAnnotationOnComputedDeliverableMutation,
        variables: {
            annotationId
        }
    });
}

export async function deleteAnnotationComment(commentId) {
    await apolloClient.mutate({
        mutation: RemoveQAAnnotationCommentOnComputedDeliverableMutation,
        variables: {
            commentId
        }
    });
}

export async function getAnnotationsOfDeliverable(campaignId, deliverable, annotationType) {
    const computedDeliverableIdentifiers = DeliverablesService.getComputedDeliverableIdentifiers([deliverable]);
    const { data } = await apolloClient.query({
        fetchPolicy: "network-only",
        query: ComputedDeliverablesAnnotationsQuery,
        variables: {
            annotationType,
            campaignId,
            computedDeliverableIdentifiers
        }
    });
    const { annotations } = data.computedDeliverables[0];
    return annotations;
}

export async function getDeliverables(campaignId, deliverables) {
    const computedDeliverableIdentifiers = DeliverablesService.getComputedDeliverableIdentifiers(deliverables);
    const { data } = await apolloClient.query({
        fetchPolicy: "network-only",
        query: ComputedDeliverablesStatusQuery,
        variables: {
            campaignId,
            computedDeliverableIdentifiers
        }
    });
    const fetchedDeliverables = data.computedDeliverables;
    return fetchedDeliverables;
}

export async function setAnnotationStatus(annotationId, status) {
    const { data } = await apolloClient.mutate({
        mutation: ChangeQAAnnotationStatusOnComputedDeliverableMutation,
        variables: {
            annotationId,
            annotationStatus: status
        }
    });
    const updatedAnnotation = data.changeQAAnnotationStatusOnComputedDeliverable;
    return updatedAnnotation;
}

export async function setDeliverablesGlobalStatus(campaignId, deliverables, status, libraryFilters = {}) {
    const variables = {
        campaignId,
        globalStatus: status
    };
    if (!isEmptyObject(libraryFilters)) {
        // all selected mode
        const libraryFiltersVars = constructLibraryFiltersQueryVariables(libraryFilters);
        variables.libraryFilters = libraryFiltersVars;
    } else {
        const computedDeliverableIdentifiers = DeliverablesService.getComputedDeliverableIdentifiers(deliverables);
        variables.computedDeliverableIdentifiers = computedDeliverableIdentifiers;
    }
    const { data } = await apolloClient.mutate({
        mutation: SetComputedDeliverableStatusMutation,
        variables
    });
    const updatedDeliverables = data.setComputedDeliverablesQAStatus;
    return updatedDeliverables;
}

export async function setDeliverablesVisibleToRoles(campaignId, deliverables, visibleToRoleIds, libraryFilters = {}) {
    const variables = {
        campaignId,
        visibleTo: visibleToRoleIds
    };

    if (!isEmptyObject(libraryFilters)) {
        // all selected mode
        const libraryFiltersVars = constructLibraryFiltersQueryVariables(libraryFilters);
        variables.libraryFilters = libraryFiltersVars;
    } else {
        const computedDeliverableIdentifiers = DeliverablesService.getComputedDeliverableIdentifiers(deliverables);
        variables.computedDeliverableIdentifiers = computedDeliverableIdentifiers;
    }
    const { data } = await apolloClient.mutate({
        mutation: SetComputedDeliverableVisibleToMutation,
        variables
    });
    const updatedDeliverables = data.setComputedDeliverablesVisibility;
    return updatedDeliverables;
}

export async function setLoggedInUsersDeliverablesStatus(campaignId, deliverables, status, libraryFilters = {}) {
    const variables = {
        campaignId,
        userStatus: status
    };

    if (!isEmptyObject(libraryFilters)) {
        // all selected mode
        const libraryFiltersVars = constructLibraryFiltersQueryVariables(libraryFilters);
        variables.libraryFilters = libraryFiltersVars;
    } else {
        const computedDeliverableIdentifiers = DeliverablesService.getComputedDeliverableIdentifiers(deliverables);
        variables.computedDeliverableIdentifiers = computedDeliverableIdentifiers;
    }
    const { data } = await apolloClient.mutate({
        mutation: SetComputedDeliverableStatusMutation,
        variables
    });
    const updatedDeliverables = data.setComputedDeliverablesQAStatus;
    return updatedDeliverables;
}

export async function updateAnnotation(annotationId, content, time, posX, posY, mentions) {
    const { data } = await apolloClient.mutate({
        mutation: UpdateQAAnnotationOnComputedDeliverableMutation,
        variables: {
            annotationId,
            description: content,
            timeline: time,
            xpos: posX,
            ypos: posY,
            mentions
        }
    });
    const updatedAnnotation = data.updateQAAnnotationOnComputedDeliverable;
    return updatedAnnotation;
}

export async function updateAnnotationComment(commentId, content, mentions) {
    const { data } = await apolloClient.mutate({
        mutation: UpdateQAAnnotationCommentOnComputedDeliverableMutation,
        variables: {
            commentId,
            content,
            mentions
        }
    });
    const updatedComment = data.updateQAAnnotationCommentOnComputedDeliverable;
    return updatedComment;
}
