<template>
    <div :class="containerClasses">
        <slot />
        <div v-if="showFooter" class="card__footer">
            <div class="card__footer-title" data-testid="client_title">
                <slot name="title" />
            </div>
            <div class="card__footer-action-items" @click.stop.prevent>
                <slot name="actionItems" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Card",
    props: {
        isClickable: {
            type: Boolean
        },

        showFooter: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        containerClasses() {
            const classes = ["card__container"];
            if (this.isClickable) {
                classes.push("card__container--clickable");
            }
            return classes;
        }
    }
};
</script>

<style scoped lang="scss">
@import "../../../sass/_variables.scss";

.card__container {
    border: 1px solid $grey3;
    margin: 0 0 15px 0;
}

.card__container--clickable {
    transition: box-shadow 0.15s;
    &:hover {
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15);
    }
}

.card__footer {
    align-items: center;
    border-top: 1px solid $grey3;
    display: flex;
    height: 40px;
    justify-content: space-between;
    padding: 0 0 0 12px;
}

.card__footer-title {
    color: $darktheme20;
    flex: 1 0;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.card__footer-action-items {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    height: 100%;
    padding: 0 0 0 12px;
}
</style>
