<template>
    <collapsible-widget v-if="options.length > 0">
        <template slot="name">Master Template Families</template>
        <template slot="controls"></template>
        <CheckboxGroup v-model="selectedValues" class="editable-group__values" :vertical="true">
            <Checkbox v-for="option in options" :key="option.value" :label="option.value">
                {{ option.label }}
            </Checkbox>
        </CheckboxGroup>
    </collapsible-widget>
</template>
<script>
import {
    FilterType,
    MasterTemplateLibraryAction,
    MasterTemplateLibraryGetters
} from "@/store/modules/masterTemplateLibrary";
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";
import { deepClone } from "@/utils";

export default {
    name: "MasterTemplateFamilyFilter",
    components: { CollapsibleWidget },

    props: {
        campaignMasterTemplateIds: {
            type: Array,
            default() {
                return [];
            }
        }
    },

    computed: {
        filterValues() {
            return this.$store.state.masterTemplateLibrary.filters.filter(filter => filter.type === FilterType.Family);
        },

        masterTemplates() {
            return this.$store.state.masterTemplateLibrary.masterTemplates;
        },

        // Templates that belong to the client but has not been added to the campaign yet.
        masterTemplateIds() {
            return this.$store.state.masterTemplateLibrary.masterTemplateIds.filter(
                templateId => !this.campaignMasterTemplateIds.includes(templateId)
            );
        },

        options() {
            const families = this.$store.getters[MasterTemplateLibraryGetters.templateFamiliesByParentId];
            return Object.values(families)
                .filter(({ parentId, children }) => parentId && children.length)
                .map(({ parentId }) => ({ value: parentId, label: this.masterTemplates[parentId].name }));
        },

        selectedValues: {
            get() {
                if (this.filterValues.length) {
                    const fval = deepClone(this.filterValues);

                    return fval.pop().values;
                }

                return [];
            },
            set(newValue) {
                this.$store.dispatch(MasterTemplateLibraryAction.UpdateFamilyFilter, {
                    values: newValue
                });
            }
        }
    },

    beforeDestroy() {
        this.selectedValues = [];
    }
};
</script>

<style lang="scss">
.editable-group {
    &__add {
        width: 100%;
    }

    &__values {
        width: 100%;
    }

    .show-form {
        &--showing {
            display: none;
        }
    }
}
</style>
