<template>
    <Poptip placement="bottom-start" transfer trigger="hover">
        <div class="banner-status-indicator">
            <qa-status-dot :status="status" />
        </div>
        <template #content>
            <qa-status-dot :status="status" />
            {{ statusLabel }}
        </template>
    </Poptip>
</template>

<script>
import QaStatusDot from "@/components/Qa/QaStatusDot";
import { BannerQaStatusLabel } from "@/enums/banners";
import { DeliverableLibraryGetters } from "@/store/modules/deliverableLibrary";

export default {
    components: {
        QaStatusDot
    },
    props: {
        deliverable: {
            required: true,
            type: Object
        }
    },
    computed: {
        statusLabel() {
            return BannerQaStatusLabel[this.status];
        },
        status() {
            return this.$store.getters[DeliverableLibraryGetters.computedDeliverableStatus](this.deliverable.idHash);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

$size: 40px;

.banner-status-indicator {
    align-items: center;
    display: flex;
    height: $size;
    justify-content: center;
    position: relative;
    width: $size;
}
</style>
