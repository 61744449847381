<template>
    <div>
        <Form @submit.native.prevent="submitForm">
            <FormItem
                :class="{
                    'ivu-form-item-error': showValidationErrors && validationErrors.columnName
                }"
                label="Column name"
                required
            >
                <Input
                    ref="columnNameInput"
                    v-model="internalFormData.columnName"
                    placeholder="e.g. audienceId"
                    @keydown.native.stop
                />
                <p
                    v-if="showValidationErrors && validationErrors.columnName"
                    class="metadata-field-form__form-error-message"
                >
                    {{ validationErrors.columnName }}
                </p>
                <p class="metadata-field-form__instructions">The column name can't contain spaces</p>
                <p class="metadata-field-form__instructions">Use only letters and numbers</p>
            </FormItem>
        </Form>
        <div
            v-if="!hasValidationErrors && hasErrorSubmittingForm"
            :class="{
                'metadata-field-form__error-message-wrapper--top-spacing': !hasValidationErrors
            }"
        >
            <Alert banner type="error">
                <template #desc>
                    There was an unexpected error and no changes were saved. Hopefully it was a temporary issue and
                    should work if you try again in a few moments.
                </template>
            </Alert>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import isValidMetadataFieldName, { ValidationError } from "shared-utils/isValidMetadataFieldName";

export default {
    props: {
        hasErrorSubmittingForm: {
            type: Boolean
        },

        initialFormData: {
            default: () => ({}),
            type: Object
        },

        showValidationErrors: {
            type: Boolean
        },

        error: {
            default: () => null,
            type: Object
        }
    },

    data() {
        return {
            internalFormData: {
                columnName: this.initialFormData.columnName
            }
        };
    },
    computed: {
        hasValidationErrors() {
            return Object.keys(this.validationErrors).length > 0;
        },

        validationErrors() {
            const validationErrors = {};

            if (this.error) {
                const processedErrorMessage = this.processErrorMessage(this.error);
                if (processedErrorMessage) {
                    validationErrors.columnName = processedErrorMessage;
                    return validationErrors;
                }
            }

            if (!this.internalFormData.columnName) {
                validationErrors.columnName = "A column name is required";
            } else {
                try {
                    isValidMetadataFieldName(this.internalFormData.columnName);
                } catch (err) {
                    // The assert module on the front-end is not throwing the custom error but instead throws an AssertionError with the custom error set as the message property
                    // which is why we pass down the message property here and not the err variable. This is likely a symptom of jest overriding the assert module
                    // Refs: https://github.com/facebook/jest/issues/7547, https://github.com/vuejs/vue-cli/issues/1131
                    validationErrors.columnName = this.processErrorMessage(err.message);
                }
            }

            return validationErrors;
        }
    },

    watch: {
        internalFormData: {
            deep: true,
            handler() {
                this.$emit("formDataUpdate", { ...this.internalFormData });
            },
            immediate: true
        },

        hasValidationErrors: {
            handler() {
                this.$emit("hasValidationErrorsUpdate", this.hasValidationErrors);
            },
            immediate: true
        }
    },

    async mounted() {
        await this.$nextTick();
        this.$refs.columnNameInput.focus();
    },

    methods: {
        processErrorMessage(err) {
            if (!err.message) {
                return "";
            }
            // Handle the error coming from the API or being thrown locally. The code running locally mangles the class name so we need to check its type instead.
            if (err instanceof ValidationError || (err.extensions && err.extensions.type === "ValidationError")) {
                return err.message.replace("GraphQL error: ", "");
            }

            return "";
        },

        submitForm() {
            this.$emit("submit", { ...this.internalFormData });
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.metadata-field-form__error-message-wrapper--top-spacing {
    padding: $spacing 0 0;
}

.metadata-field-form__form-error-message {
    color: $error-color;
    font-size: $font-size-small;
    margin: $spacing-small 0 $spacing-small;
    line-height: $font-size-large;
}

.metadata-field-form__instructions {
    color: $grey5;
    font-size: $font-size-small;
    margin: $spacing-small 0 0;
    line-height: $font-size-small;
    clear: both;
}
</style>
