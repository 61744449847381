<template>
    <div class="alternative-headlines">
        <h4 class="alternative-headlines__header">
            <span class="alternative-headlines__title">AI Generated Options</span>
        </h4>
        <div class="alternative-headlines-previews">
            <gpt3-cta-preview
                v-for="resultNumber in numberOfResults"
                :key="searchOptions.model + searchOptions.prompt + resultNumber"
                :search-options="searchOptions"
                @complete="onComplete"
                @selected="onSelect"
            />
        </div>
    </div>
</template>
<script>
import { ModelConfig, ImagineModel, ImagineModelToLabel } from "@/enums/imagine";
import Gpt3CtaPreview from "@/components/Campaign/ModelPreview/Gpt3CtaPreview";

export default {
    name: "AlternativeHeadlinesResults",
    components: { Gpt3CtaPreview },
    props: {
        searchOptions: {
            type: Object
        }
    },

    data() {
        return {
            completeCounter: 0,
            searchDuration: 0
        };
    },

    computed: {
        numberOfResults() {
            return ModelConfig[this.searchOptions.model].numberOfResults;
        }
    },

    watch: {
        completeCounter() {
            if (this.completeCounter >= this.numberOfResults) {
                clearInterval(this.updateInterval);
            }
        }
    },

    created() {
        this.ImagineModel = ImagineModel;
        this.ImagineModelToLabel = ImagineModelToLabel;
    },

    mounted() {
        this.searchStart = new Date();
        this.updateInterval = setInterval(this.updateDuration, 1000);
    },

    methods: {
        onComplete() {
            this.completeCounter += 1;
        },

        updateDuration() {
            this.searchDuration = Math.floor((new Date() - this.searchStart) / 1000);
        },

        onSelect(cta) {
            this.$emit("selected", cta);
            this.$emit("close");
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/_variables.scss";

.alternative-headlines {
    margin-bottom: $spacing;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        width: 100%;
        margin-bottom: $spacing;
        line-height: 1;
    }

    &__model {
        font-weight: normal;
        margin-right: $spacing;
    }

    &__duration {
        margin-right: $spacing;
        font-weight: normal;
        font-size: 12px;
        white-space: nowrap;
    }

    &__title {
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: $spacing-larger;
    }

    &-previews {
        width: 100%;
    }

    &-preview {
        margin: auto 0;
        position: relative;
        background: #ffffff;
    }

    &__load-more {
        font-size: 14px;
        margin: 0 $spacing 0 auto;
    }

    &__view-change {
        font-weight: normal;
        padding: 0 $spacing;
        margin-right: auto;
        cursor: pointer;

        span {
            font-weight: bold;
        }
    }
}
</style>
