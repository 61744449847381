import HoxtonError from "./HoxtonError";

/*
 * Note: we can't put the console.error in this file as the constructor is run even when the error is not thrown.
 * For example: assert( true, new UnexpectedError(...) ) would put the error in the logs even if it isn't thrown
 */
export default class UnexpectedError extends HoxtonError {
    /**
     * err can be the original error that was caught - or a string for a custom error
     * codeOrMessage should be a custom error message for a caught error or the error code for a custom error
     * @param {String|Error} err
     * @param {String} codeOrMessage - optional
     */
    constructor(err, codeOrMessage) {
        if (err instanceof Error) {
            super(codeOrMessage || err.message, "UNEXPECTED");
            this.originalError = err;
        } else {
            super(err, codeOrMessage || "UNEXPECTED");
        }
    }
}
