<template>
    <select-all-notice
        v-if="isNoticeShown"
        :selected="selected"
        :total-count="totalCount"
        @selectAll="onSelectAll"
        @dismiss="hideNotice"
    />
</template>
<script>
import { MultiviewEvent } from "@/enums/multiview";
import bus from "@/bus";
import SelectAllNotice from "@/components/Campaign/SelectAllNotice";

export default {
    name: "MultiviewSelectallNotice",
    components: { SelectAllNotice },
    props: {
        instanceName: {
            type: String
        }
    },

    data() {
        return {
            showNotice: false
        };
    },

    computed: {
        pageCount() {
            return this.state ? this.state.totalCount : 0;
        },

        selected() {
            return this.state ? this.state.selected : 0;
        },

        isNoticeShown() {
            return this.showNotice && this.selected !== this.totalCount && this.selected;
        },

        state() {
            return this.$store.state.ui.multiviewComponents[this.instanceName];
        },

        totalCount() {
            return this.state ? this.state.totalCount : 0;
        }
    },

    created() {
        bus.$on(MultiviewEvent.SelectAllOnPage, this.onSelectAllOnPage.bind(this));
    },

    methods: {
        hideNotice() {
            this.showNotice = false;
        },

        onSelectAll() {
            bus.$emit(MultiviewEvent.SelectAll, {
                instance: this.instanceName
            });
        },

        onSelectAllOnPage(selectData) {
            if (selectData.instance !== this.instanceName) {
                return;
            }

            if (selectData.value && this.selected < this.totalCount) {
                this.showNotice = true;
            } else {
                this.showNotice = false;
            }
        }
    }
};
</script>
