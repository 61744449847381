import addGroupValueMutation from "@/apollo/mutations/v2/AddEditableGroupValue.gql";
import updateGroupValuesMutation from "@/apollo/mutations/v2/UpdateEditableGroupValues.gql";
import removeGroupValuesMutation from "@/apollo/mutations/v2/RemoveEditableGroupValues.gql";
import removeOverwritesMutation from "@/apollo/mutations/RemoveOverwrites.gql";
import overwriteEditablesWithEditableGroupAndMasterTemplateScope from "@/apollo/mutations/v2/OverwriteEditablesWithEditableGroupAndMasterTemplateScope.gql";
import overwriteEditablesWithEditableGroupScope from "@/apollo/mutations/v2/OverwriteEditablesWithEditableGroupScope.gql";
import overwriteEditablesWithMasterTemplateScope from "@/apollo/mutations/v2/OverwriteEditablesWithMasterTemplateScope.gql";
import overwriteEditablesWithCampaignScope from "@/apollo/mutations/v2/OverwriteEditablesWithCampaignScope.gql";

import { OverwriteScope } from "@/enums/overwrites";
import { EditableImageTypes, EditableMediaTypes } from "@/enums/editables";

const stripId = ({ _id, ...props }) => props;

export default class Editables {
    /**
     * Creates new instance of Editables Services
     * @param {VueApollo} apollo
     * @param {string} campaignId
     * @param {object} refreshQuery
     */
    constructor(apollo, campaignId, { refreshQuery = null, context = null } = {}) {
        this.$apollo = apollo;
        this.refreshQuery = refreshQuery;
        this.context = context;

        this.defaults = {
            variables: {
                campaignId
            }
        };

        if (refreshQuery) {
            this.defaults.refetchQueries = [this.refreshQuery];
        }
    }

    getContext() {
        return this.context ? { context: this.context } : {};
    }

    async addEditableGroupValue(editableGroupName, value) {
        return this.$apollo.mutate({
            mutation: addGroupValueMutation,
            variables: {
                editableGroupName,
                value,
                campaignId: this.defaults.variables.campaignId
            },
            ...this.getContext()
        });
    }

    async removeEditableGroupValues(editableGroupValueIds) {
        return this.$apollo.mutate({
            mutation: removeGroupValuesMutation,
            variables: { editableGroupValueIds },
            ...this.getContext()
        });
    }

    async updateEditableGroupValues(editableGroupValues) {
        return this.$apollo.mutate({
            mutation: updateGroupValuesMutation,
            variables: { editableGroupValues },
            ...this.getContext()
        });
    }

    async removeOverwrites(overwriteIds) {
        if (!overwriteIds.length) {
            return null;
        }

        return this.$apollo.mutate({
            mutation: removeOverwritesMutation,
            variables: {
                campaignId: this.defaults.variables.campaignId,
                overwriteIds
            },
            ...this.getContext()
        });
    }

    async addOverwrites(editableOverwrites) {
        const overwritesByScope = {};

        editableOverwrites.forEach(editableOverwrite => {
            (editableOverwrite.overwrites || []).reduce((acc, cur) => {
                acc[cur.scope] = acc[cur.scope] || [];
                acc[cur.scope].push({
                    editable: editableOverwrite.editable,
                    overwrite: stripId(cur)
                });
                return acc;
            }, overwritesByScope);
        });

        const mutations = Object.keys(overwritesByScope).map(scope =>
            this.$apollo.mutate({
                mutation: Editables.getOverwriteMutation(scope),
                variables: {
                    campaignId: this.defaults.variables.campaignId,
                    overwrites: Editables.getOverwritesToSave(overwritesByScope[scope])
                },
                ...this.getContext()
            })
        );

        return Promise.all(mutations);
    }

    static getOverwriteMutation(scope) {
        switch (scope) {
            case OverwriteScope.Campaign:
                return overwriteEditablesWithCampaignScope;

            case OverwriteScope.Template:
                return overwriteEditablesWithMasterTemplateScope;

            case OverwriteScope.EditableGroup:
                return overwriteEditablesWithEditableGroupScope;

            case OverwriteScope.EditableGroupTemplate:
                return overwriteEditablesWithEditableGroupAndMasterTemplateScope;
            default:
                return overwriteEditablesWithEditableGroupAndMasterTemplateScope;
        }
    }

    /* eslint-disable complexity */
    static getOverwritesToSave(overwrites) {
        return overwrites.map(({ editable, overwrite }) => {
            const overwriteToSave = {
                editableId: editable._id,
                language: overwrite.language
            };

            /* masterTemplateId: overwrite.masterTemplateId,
                editableGroupValueIds: overwrite.editableGroupValueIds,
            }; */

            switch (overwrite.scope) {
                /* These levels are not available at the moment
                    case OverwriteScope.Campaign:
                        // no need to add any more fields for this scope
                        break;
                    case OverwriteScope.Template:
                        overwriteToSave.masterTemplateId = overwrite.masterTemplateId;
                        break; */
                case OverwriteScope.EditableGroup:
                    overwriteToSave.editableGroupValueIds = overwrite.editableGroupValueIds;

                    break;
                case OverwriteScope.EditableGroupTemplate:
                    overwriteToSave.masterTemplateId = overwrite.masterTemplateId;
                    overwriteToSave.editableGroupValueIds = overwrite.editableGroupValueIds;
                    break;
                default:
                    break;
            }

            if (EditableImageTypes.includes(editable.type) && overwrite.mediaItem) {
                overwriteToSave.resizeSettings = overwrite.mediaItem.resizeSettings || null;
            }

            if (EditableMediaTypes.includes(editable.type)) {
                overwriteToSave.mediaId = overwrite.value;
            } else {
                overwriteToSave.value = overwrite.value;
            }

            if (overwrite.settings) {
                overwriteToSave.settings = { ...overwrite.settings };

                if (overwriteToSave.settings.fontFamily) {
                    overwriteToSave.settings.fontFamily = overwriteToSave.settings.fontFamily._id;
                }
            }

            return overwriteToSave;
        });
    }
}
