<template>
    <div class="editable-settings__input-wrapper" @keydown.capture="onWrapperKeyDown">
        <slot name="label"></slot>
        <Input
            ref="inputEl"
            :value="value"
            type="number"
            class="editable-settings__input"
            size="small"
            :class="{ 'editable-settings__input--with-suffix': showSuffix }"
            :placeholder="placeholder"
            @input="onInput"
        >
            <template slot="append">
                <span v-show="showSuffix">{{ suffix }}</span>
            </template>
        </Input>
    </div>
</template>
<script>
export default {
    name: "EditableSettingsNumberInput",
    props: {
        value: {
            type: Number,
            default: undefined
        },
        propName: {
            type: String,
            required: true
        },
        placeholder: {
            type: String
        },
        suffix: {
            type: String,
            default: "px"
        },
        showSuffix: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        focus() {
            this.$refs.inputEl.focus();
        },

        onInput($event) {
            this.$emit("input", $event);
        },
        onWrapperKeyDown($event) {
            this.$emit("wrapperKeyDown", $event);
        }
    }
};
</script>
