<template>
    <data-sort-filter :default-value="defaultValue" :label="label" :options="options" v-on="$listeners" />
</template>

<script>
import DataSortFilter from "@/components/DataSortFilter";

export const defaultCampaignSortBy = "name_ASC";

export default {
    name: "SortCampaigns",
    components: { DataSortFilter },
    props: {
        value: {
            type: String,
            default: defaultCampaignSortBy
        }
    },
    data() {
        return {
            options: [
                {
                    field: "Name",
                    ascValue: "name_ASC",
                    ascOptionDirection: "A to Z",
                    descValue: "name_DESC",
                    descOptionDirection: "Z to A"
                }
            ],
            label: "Sort by:",
            defaultValue: defaultCampaignSortBy
        };
    }
};
</script>
