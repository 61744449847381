<template>
    <div class="library-deliverable-table-reporting-label-cell">
        <div :class="contentClasses">
            <span
                class="library-deliverable-table-reporting-label-cell__value"
                :title="reportingLabel"
                @dblclick.self="toggleInputIsVisible"
            >
                {{ reportingLabel }}
            </span>
            <div
                v-if="userCanChangeDeliverableReportingLabel"
                class="library-deliverable-table-reporting-label-cell__edit-button-wrapper"
            >
                <hox-square-button
                    has-inverse-hover
                    :is-active="inputIsVisible"
                    size="small"
                    @click="toggleInputIsVisible"
                >
                    <Icon type="md-create" />
                </hox-square-button>
            </div>
        </div>
        <transition name="fade">
            <div v-if="inputIsVisible" class="library-deliverable-table-reporting-label-cell__input-container">
                <form class="library-deliverable-table-reporting-label-cell__form" @submit.prevent="setReportingLabel">
                    <Input
                        ref="input"
                        v-model="value"
                        @enter.prevent="setReportingLabel"
                        @on-keyup.esc.prevent="toggleInputIsVisible"
                    />
                </form>
                <div>
                    <hox-square-button has-inverse-hover size="small" @click="setReportingLabel">
                        <Icon type="md-checkmark" />
                    </hox-square-button>
                    <hox-square-button has-inverse-hover size="small" @click="toggleInputIsVisible">
                        <Icon type="md-close" />
                    </hox-square-button>
                </div>
            </div>
        </transition>
        <hox-loading-layer v-if="isSettingReportingLabel" :is-full-screen="false" is-transparent size="smaller" />
    </div>
</template>

<script>
import Deliverables from "@/services/Deliverables";

export default {
    props: {
        deliverable: {
            required: true,
            type: Object
        },

        inputIsVisible: {
            default: false,
            type: Boolean
        }
    },

    data() {
        return {
            isSettingReportingLabel: false,
            value: this.deliverable.reportingLabel || ""
        };
    },

    computed: {
        campaignId() {
            return this.$route.params.campaignId;
        },

        clientId() {
            return this.$route.params.clientId;
        },

        contentClasses() {
            const classes = ["library-deliverable-table-reporting-label-cell__content"];
            if (this.isSettingReportingLabel) {
                classes.push("library-deliverable-table-reporting-label-cell__content--faded");
            }
            return classes;
        },

        reportingLabel() {
            return this.deliverable.reportingLabel;
        },

        userCanChangeDeliverableReportingLabel() {
            return this.$auth.userCan(this.$auth.Actions.CanChangeDeliverableReportingLabel, {
                clientId: this.clientId,
                campaignId: this.campaignId
            });
        }
    },

    watch: {
        async inputIsVisible() {
            if (this.inputIsVisible) {
                this.value = this.reportingLabel;
                await this.$nextTick();
                this.$refs.input.focus();
            }
        }
    },

    created() {
        this.deliverablesService = new Deliverables(this.$apollo, this.$store);
    },

    methods: {
        async setReportingLabel() {
            this.toggleInputIsVisible();
            this.isSettingReportingLabel = true;
            try {
                let reportingLabel;
                if (this.value) {
                    reportingLabel = await this.deliverablesService.setComputedDeliverablesReportingLabel(
                        this.campaignId,
                        this.deliverable,
                        this.value
                    );
                } else {
                    reportingLabel = await this.deliverablesService.removeComputedDeliverablesReportingLabel(
                        this.campaignId,
                        this.deliverable
                    );
                }
                this.$emit("reportingLabelUpdated", reportingLabel);
            } catch (err) {
                console.error(err);
                this.$snackbar.error(
                    "The reporting label was not set because there was an unexpected error. Please try again in a few moments."
                );
            }
            this.isSettingReportingLabel = false;
        },

        toggleInputIsVisible() {
            /*
                The reason that the visibility of the input is handled via an external prop
                (and event), as opposed to internal data, is that we do not want to show multiple
                inputs in a single table at the same time and this is only possible when an
                external entity controls things.
            */
            if (this.userCanChangeDeliverableReportingLabel) {
                this.$emit("toggleInputIsVisible");
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.library-deliverable-table-reporting-label-cell {
    position: relative;
    max-width: 100%;

    &:hover {
        .library-deliverable-table-reporting-label-cell__edit-button-wrapper {
            opacity: 1;
        }
    }
}

.library-deliverable-table-reporting-label-cell__content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    transition:
        filter 0.2s,
        opacity 0.2s;

    &--faded {
        filter: blur(2px);
        opacity: 0.5;
    }
}

.library-deliverable-table-reporting-label-cell__form {
    flex: 1;
    margin: 0 $spacing-smaller 0 0;
}

.library-deliverable-table-reporting-label-cell__edit-button-wrapper {
    opacity: 0;
    transition: opacity 0.2s;
}

.library-deliverable-table-reporting-label-cell__input-container {
    align-items: center;
    background: $white;
    box-shadow: $box-shadow-base;
    display: flex;
    left: 100%;
    min-width: 240px;
    padding: $spacing-small;
    position: absolute;
    top: 50%;
    transform: translate(-100%, -50%);
    width: 100%;
    z-index: 110;

    .ivu-input {
        height: 28px;
    }
}

.library-deliverable-table-reporting-label-cell__value {
    cursor: text;
    flex: 1;
    margin: 0 $spacing-small 0 0;
    overflow: hidden;
    text-overflow: ellipsis;

    &--muted {
        color: $grey5;
    }
}
</style>
