import "./polyfills";

// eslint-disable-next-line import/extensions
import "hoxton-iview-theme";
import Vue from "vue";
import iView from "iview";
import iViewLocale from "iview/dist/locale/en-US";
import VueApollo from "vue-apollo";
import PortalVue from "portal-vue";
import Vuebar from "vuebar";

import { sync } from "vuex-router-sync";

import App from "@/App";

import "@/components/common";
import router from "@/router";
import store from "@/store";
import filters from "@/filters";
import auth from "@/services/Auth";
import { getDomain, getProvider, snackbar } from "@/utils";
import { apolloProvider } from "@/apollo";
import { UiAction } from "@/store/modules/ui";
import { BaseUrl } from "@/components/Reporting/data/constants";

const setDomain = domain => {
    store.dispatch(UiAction.SetDomain, domain);
};

document.subdomain = document.domain;
document.domain = document.domain.includes("hogarth.ai") ? "hogarth.ai" : "hoxton.co";
setDomain(getDomain());

console.log(`STACK SETTINGS:
provider: ${getProvider("test@hogarth.com")}
domain: ${getDomain()},
backend: ${BaseUrl},
document.domain: ${document.domain},
reportingDebug: ${!!localStorage.getItem("reportingDebug")}
`);

const helpers = {
    install() {
        Vue.prototype.$auth = auth;
        Vue.prototype.$snackbar = snackbar;
    }
};

sync(store, router);

Vue.use(iView, { locale: iViewLocale });
Vue.use(VueApollo);
Vue.use(PortalVue);
Vue.use(Vuebar);
Vue.use(helpers);
Vue.use(filters);

Vue.config.productionTip = false;
// Vue.config.performance = true;
/* eslint-disable no-new */
new Vue({
    el: "#app",
    router,
    store,
    apolloProvider,
    components: { App },
    template: "<App/>"
});
