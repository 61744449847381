<template>
    <div class="library-published-status-filter">
        <collapsible-widget :is-opened="true">
            <template #name>Publishing Status</template>
            <CheckboxGroup :value="[publishedStatusFilter]">
                <Checkbox
                    :label="PlatformPublishedStatusFilter.All"
                    @click.native.stop.prevent="setPublishedStatusFilter(PlatformPublishedStatusFilter.All)"
                >
                    All
                </Checkbox>
                <Checkbox
                    :label="PlatformPublishedStatusFilter.Published"
                    @click.native.stop.prevent="setPublishedStatusFilter(PlatformPublishedStatusFilter.Published)"
                >
                    Published
                </Checkbox>

                <div v-if="isPublishedSubmenuVisible" class="library-published-status-filter__sub-group">
                    <Checkbox
                        :label="PlatformPublishedStatusFilter.PublishedToDoubleClick"
                        @click.native.stop.prevent="
                            setPublishedStatusFilter(PlatformPublishedStatusFilter.PublishedToDoubleClick)
                        "
                    >
                        {{ PlatformLabel[Platform.DoubleClick] }}
                    </Checkbox>
                    <Checkbox
                        :label="PlatformPublishedStatusFilter.PublishedToSizmek"
                        @click.native.stop.prevent="
                            setPublishedStatusFilter(PlatformPublishedStatusFilter.PublishedToSizmek)
                        "
                    >
                        {{ PlatformLabel[Platform.Sizmek] }}
                    </Checkbox>
                    <Checkbox
                        :label="PlatformPublishedStatusFilter.PublishedToOpenDC"
                        @click.native.stop.prevent="
                            setPublishedStatusFilter(PlatformPublishedStatusFilter.PublishedToOpenDC)
                        "
                    >
                        {{ PlatformLabel[Platform.OpenDC] }}
                    </Checkbox>
                </div>

                <Checkbox
                    :label="PlatformPublishedStatusFilter.NotPublished"
                    @click.native.stop.prevent="setPublishedStatusFilter(PlatformPublishedStatusFilter.NotPublished)"
                >
                    Not published
                </Checkbox>
                <div v-if="isNotPublishedSubmenuVisible" class="library-published-status-filter__sub-group">
                    <Checkbox
                        :label="PlatformPublishedStatusFilter.NotPublishedToDoubleClick"
                        @click.native.stop.prevent="
                            setPublishedStatusFilter(PlatformPublishedStatusFilter.NotPublishedToDoubleClick)
                        "
                    >
                        {{ PlatformLabel[Platform.DoubleClick] }}
                    </Checkbox>
                    <Checkbox
                        :label="PlatformPublishedStatusFilter.NotPublishedToSizmek"
                        @click.native.stop.prevent="
                            setPublishedStatusFilter(PlatformPublishedStatusFilter.NotPublishedToSizmek)
                        "
                    >
                        {{ PlatformLabel[Platform.Sizmek] }}
                    </Checkbox>
                </div>
            </CheckboxGroup>
        </collapsible-widget>
    </div>
</template>

<script>
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";
import { Platform, PlatformLabel, PlatformPublishedStatusFilter } from "@/enums/platforms";
import { DeliverableLibraryAction, DeliverableLibraryGetters } from "@/store/modules/deliverableLibrary";

export default {
    components: {
        CollapsibleWidget
    },
    computed: {
        isPublishedSubmenuVisible() {
            return this.publishedFilterStateses.includes(this.publishedStatusFilter);
        },

        isNotPublishedSubmenuVisible() {
            return this.notPublishedFilterStateses.includes(this.publishedStatusFilter);
        },

        publishedStatusFilter: {
            get() {
                return this.$store.getters[DeliverableLibraryGetters.publishedStatusFilter];
            },
            set(value) {
                this.$store.dispatch(DeliverableLibraryAction.UpdatePublishedStatusFilter, value);
            }
        }
    },
    created() {
        this.Platform = Platform;
        this.PlatformLabel = PlatformLabel;
        this.PlatformPublishedStatusFilter = PlatformPublishedStatusFilter;
        this.publishedFilterStateses = [
            PlatformPublishedStatusFilter.Published,
            PlatformPublishedStatusFilter.PublishedToDoubleClick,
            PlatformPublishedStatusFilter.PublishedToSizmek,
            PlatformPublishedStatusFilter.PublishedToOpenDC
        ];
        this.notPublishedFilterStateses = [
            PlatformPublishedStatusFilter.NotPublished,
            PlatformPublishedStatusFilter.NotPublishedToDoubleClick,
            PlatformPublishedStatusFilter.NotPublishedToSizmek,
            PlatformPublishedStatusFilter.NotPublishedToOpenDC
        ];
    },

    methods: {
        setPublishedStatusFilter(publishedStatus) {
            this.publishedStatusFilter = publishedStatus;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.library-published-status-filter__sub-group {
    margin: 0 0 $spacing-smaller $spacing;
    padding: $spacing-smaller 0;
}
</style>
