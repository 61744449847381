// eslint-disable-next-line no-unused-vars, import/no-extraneous-dependencies
import * as Types from "shared-utils/types";

import { NotificationStatus } from "@/enums/notifications";

const storeNamespace = "notifications";

export const NotificationsAction = {
    Add: `${storeNamespace}/addItem`,
    AddDownloadLinkToNotification: `${storeNamespace}/addDownloadLinkToNotification`,
    ClearItemsNotPendingOrInProgress: `${storeNamespace}/clearItemsNotPendingOrInProgress`,
    RemoveItem: `${storeNamespace}/removeItem`,
    SetDrawerIsVisible: `${storeNamespace}/setDrawerIsVisible`,
    Update: `${storeNamespace}/updateItem`
};

export const NotificationsGetter = {
    GetItemById: `${storeNamespace}/getItemById`
};

export const NotificationsMutation = {
    Add: "addItem",
    AddDownloadLinkToNotification: "addDownloadLinkToNotification",
    ClearItemsNotPendingOrInProgress: "clearItemsNotPendingOrInProgress",
    IncrementIdCounter: "incrementIdCounter",
    RemoveItem: "removeItem",
    SetDrawerIsVisible: "setDrawerIsVisible",
    Update: "updateItem"
};

/**
 * @typedef {Object} Notification
 * @property {"completed" | "failed" | "in-progress" | "pending"} status
 * @property {"background-update" | "download" | "download-job" | "generate-report" | "publish" | "unpublish"} type
 * @property {Types.Job} job
 * @property {string} [message]
 * @property {number} [deliverablesCount]
 * @property {string} [platform]
 */

/**
 * @typedef {Object} NotificationsId
 * @property {number} id
 *
 * @typedef {Notification & NotificationsId} NotificationWithId
 */

/**
 * @typedef {Object} NotificationsState
 * @property {boolean} drawerIsVisible
 * @property {number} idCounter
 * @property {NotificationWithId[]} items
 */

/**
 * @type {import("vuex").Module<NotificationsState, any>}
 */
export default {
    namespaced: true,

    state: {
        drawerIsVisible: false,
        idCounter: 0,
        items: []
    },

    getters: {
        getItemById(state) {
            return itemId => state.items.find(item => item.id === itemId);
        }
    },

    mutations: {
        /**
         * @param {NotificationsState} state
         * @param {NotificationWithId} payload
         */
        addItem: (state, payload) => {
            state.items.unshift(payload);
        },

        /**
         * @param {NotificationsState} state
         */
        clearItemsNotPendingOrInProgress(state) {
            state.items = state.items.filter(
                item => item.status === NotificationStatus.Pending || item.status === NotificationStatus.InProgress
            );
        },

        /**
         * @param {NotificationsState} state
         */
        incrementIdCounter(state) {
            state.idCounter += 1;
        },

        /**
         * @param {NotificationsState} state
         * @param {number} itemId
         */
        removeItem(state, itemId) {
            state.items = state.items.filter(item => item.id !== itemId);
        },

        /**
         * @param {NotificationsState} state
         * @param {boolean} drawerIsVisible
         */
        setDrawerIsVisible(state, drawerIsVisible) {
            state.drawerIsVisible = drawerIsVisible;
        },

        /**
         * @param {NotificationsState} state
         * @param {Partial<NotificationWithId>} updatedNotification
         */
        updateItem(state, updatedNotification) {
            state.items = state.items.map(item => {
                if (item.id === updatedNotification.id) {
                    return {
                        ...item,
                        ...updatedNotification
                    };
                }

                return item;
            });
        }
    },

    actions: {
        addDownloadLinkToNotification({ commit }, downloadLink) {
            commit(NotificationsMutation.AddDownloadLinkToNotification, downloadLink);
        },

        /**
         * @param {Object} param0
         * @param {NotificationsState} param0.state
         * @param {Notification} notification
         */
        addItem({ commit, state }, notification) {
            commit(NotificationsMutation.IncrementIdCounter);
            commit(NotificationsMutation.Add, {
                ...notification,
                id: state.idCounter
            });

            return state.idCounter;
        },

        clearItemsNotPendingOrInProgress({ commit }) {
            commit(NotificationsMutation.ClearItemsNotPendingOrInProgress);
        },

        removeItem({ commit }, itemId) {
            commit(NotificationsMutation.RemoveItem, itemId);
        },

        setDrawerIsVisible({ commit }, drawerIsVisible) {
            commit(NotificationsMutation.SetDrawerIsVisible, drawerIsVisible);
        },

        updateItem({ commit }, updatedNotification) {
            commit(NotificationsMutation.Update, updatedNotification);
        }
    }
};
