<template>
    <container>
        <hox-loading-layer v-if="isLoading && !isInitialLoad" size="large" />
        <add-campaign-section-modal
            v-if="addSectionModalIsVisible"
            :client-id="clientId"
            @close="toggleAddSectionModalIsVisible"
            @sectionAdded="reloadCampaignsAndSections"
        />
        <create-campaign-modal
            v-if="createCampaignModalIsVisible"
            :sections="sections"
            @campaignCreated="reloadCampaignsAndSections"
            @close="toggleCreateCampaignModalIsVisible"
        />
        <duplicate-campaign-modal
            v-if="duplicateCampaignModalIsVisible"
            :campaign-id="campaignIdToDuplicate"
            :sections="sections"
            @campaignDuplicated="reloadCampaignsAndSections"
            @close="toggleDuplicateCampaignModalIsVisible"
        />
        <edit-campaign-modal
            v-if="editCampaignModalIsVisible"
            :campaign-id="campaignIdToEdit"
            :sections="sections"
            @campaignEdited="reloadCampaignsAndSections"
            @close="toggleEditCampaignModalIsVisible"
            @deleted="reloadCampaignsAndSections"
        />
        <edit-campaign-section-modal
            v-if="editCampaignSectionModalIsVisible"
            :section-id="sectionToEdit._id"
            :section-name="sectionToEdit.name"
            @close="toggleEditCampaignSectionModalIsVisible"
            @deleted="reloadCampaignsAndSections"
            @sectionUpdated="reloadCampaignsAndSections"
        />
        <set-campaign-section-modal
            v-if="setCampaignSectionModalIsVisible"
            :campaign-id="campaignIdToEdit"
            :sections="sections"
            @close="toggleSetCampaignSectionModalIsVisible"
            @campaignSectionSet="reloadCampaignsAndSections"
        />
        <team
            v-if="teamModalIsVisible"
            :campaign-id="teamCampaignId"
            :client-id="clientId"
            entity-type="campaign"
            @close="toggleTeamModalIsVisible"
        />
        <div v-if="isLoading && isInitialLoad" class="campaigns-dashboard__loading-wrapper">
            <hox-loading-layer size="large" />
        </div>
        <div v-else-if="hasCampaigns" class="campaigns">
            <div class="campaigns__action-items">
                <sort-campaigns v-model="sortCampaignsBy" class="campaigns__action-item"></sort-campaigns>
                <Button
                    v-if="userCanManageCampaignSections"
                    class="campaigns__action-item"
                    size="large"
                    data-testid="add-section__button"
                    @click="toggleAddSectionModalIsVisible"
                >
                    Add new section
                </Button>
                <Button
                    v-if="userCanAddCampaign"
                    class="campaigns__action-item"
                    size="large"
                    type="primary"
                    data-testid="create-campaign__button"
                    @click="toggleCreateCampaignModalIsVisible"
                >
                    Create new campaign
                </Button>
            </div>
            <Row :gutter="15">
                <Col
                    v-for="campaign of campaignsBySectionId.noSection"
                    :key="campaign.id"
                    :xs="24"
                    :sm="12"
                    :md="8"
                    :lg="6"
                >
                    <campaign-card
                        :id="campaign._id"
                        :key="campaign.id"
                        :client-id="clientId"
                        :logo="campaign.mastheadImageUrl"
                        :name="campaign.name"
                        :title="campaign.name"
                        :has-jira="!!campaign.jiraTicketUrl"
                        @duplicate="showDuplicateCampaignModal(campaign._id)"
                        @edit="showEditCampaignModal(campaign._id)"
                        @manage-team="showTeam(campaign._id)"
                        @setSection="showSetCampaignSectionModal(campaign._id)"
                    />
                </Col>
            </Row>
            <draggable
                v-model="orderedSections"
                class="insights-dashboard-tag-components__draggable-wrapper"
                handle=".campaigns__section-drag-handle"
            >
                <Collapse v-for="sectionId in orderedSections" :key="sectionId" simple class="hox-collapsible">
                    <Panel name="1" class="hox-collapsible__panel">
                        <span data-testid="campaign-section__name">{{ sectionsById[sectionId].name }}</span>
                        <span v-if="userCanManageCampaignSections" class="campaigns__section-action-items">
                            <hox-square-button
                                size="small"
                                data-testid="campaign-section__button--edit"
                                @click="showEditCampaignSectionModal(sectionId)"
                            >
                                <Icon type="md-create" />
                            </hox-square-button>
                        </span>
                        <Icon type="ios-move" class="campaigns__section-drag-handle"></Icon>

                        <div slot="content" class="hox-collapsible__body">
                            <div v-if="!campaignsBySectionId[sectionId]" class="campaigns__section-empty-message">
                                <p>There are no campaigns in this section.</p>
                                <p>
                                    You can add a campaign to this section by editing an existing campaign or creating a
                                    new campaign and selecting this section.
                                </p>
                            </div>
                            <Row v-else :gutter="15">
                                <Col
                                    v-for="campaign of campaignsBySectionId[sectionId]"
                                    :key="campaign._id"
                                    :xs="24"
                                    :sm="12"
                                    :md="8"
                                    :lg="6"
                                >
                                    <campaign-card
                                        :id="campaign._id"
                                        :key="campaign._id"
                                        :client-id="clientId"
                                        :logo="campaign.mastheadImageUrl"
                                        :name="campaign.name"
                                        :title="campaign.name"
                                        :has-jira="!!campaign.jiraTicketUrl"
                                        @duplicate="showDuplicateCampaignModal(campaign._id)"
                                        @edit="showEditCampaignModal(campaign._id)"
                                        @manage-team="showTeam(campaign._id)"
                                        @setSection="showSetCampaignSectionModal(campaign._id)"
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Panel>
                </Collapse>
            </draggable>
        </div>
        <div v-else class="campaigns-dashboard__empty-message-wrapper">
            <template v-if="userCanAddCampaign">
                <hox-empty-message>
                    <template #title>No campaigns exist for this client</template>
                    <template #actionItems>
                        <Button
                            v-if="userCanAddCampaign"
                            data-testid="create-campaign__button"
                            type="primary"
                            @click="toggleCreateCampaignModalIsVisible"
                        >
                            Create new campaign
                        </Button>
                    </template>
                </hox-empty-message>
            </template>
            <template v-else>
                <hox-empty-message>
                    <template #title>You don't have access to any campaigns for this client</template>
                    <template #actionItems>
                        <Button v-if="userCanAddCampaign" type="primary" @click="toggleCreateCampaignModalIsVisible">
                            Create new campaign
                        </Button>
                    </template>
                </hox-empty-message>
            </template>
        </div>
    </container>
</template>

<script>
import campaignsQuery from "@/apollo/queries/CampaignsByClient.gql";
import campaignSectionsQuery from "@/apollo/queries/CampaignSections.gql";
import AddCampaignSectionModal from "@/components/AddCampaignSectionModal";
import CampaignCard from "@/components/Campaign/CampaignCard";
import Container from "@/components/Container";
import Team from "@/components/Team";
import CreateCampaignModal from "@/components/CreateCampaignModal";
import DuplicateCampaignModal from "@/components/DuplicateCampaignModal";
import EditCampaignModal from "@/components/EditCampaignModal";
import EditCampaignSectionModal from "@/components/EditCampaignSectionModal";
import SetCampaignSectionModal from "@/components/SetCampaignSectionModal";
import SortCampaigns, { defaultCampaignSortBy } from "@/components/SortCampaign";
import draggable from "vuedraggable";

const LocalStorageOrderItem = "hoxSectionsOrder";

export default {
    name: "CampaignsDashboard",
    components: {
        AddCampaignSectionModal,
        SortCampaigns,
        CampaignCard,
        Container,
        CreateCampaignModal,
        DuplicateCampaignModal,
        EditCampaignModal,
        EditCampaignSectionModal,
        SetCampaignSectionModal,
        Team,
        draggable
    },

    data() {
        return {
            addSectionModalIsVisible: false,
            campaigns: [],
            campaignIdToDuplicate: undefined,
            campaignIdToEdit: undefined,
            createCampaignModalIsVisible: false,
            duplicateCampaignModalIsVisible: false,
            editCampaignModalIsVisible: false,
            editCampaignSectionModalIsVisible: false,
            hasErrorLoadingCampaigns: false,
            hasErrorLoadingSections: false,
            hasLoadedCampaigns: false,
            hasLoadedSections: false,
            isLoadingCampaigns: 0,
            isLoadingSections: 0,
            localSortOrder: [],
            oldClientId: null,
            sectionIdToEdit: undefined,
            sections: [],
            setCampaignSectionModalIsVisible: false,
            sortCampaignsBy: defaultCampaignSortBy,
            teamCampaignId: undefined,
            teamModalIsVisible: false
        };
    },

    computed: {
        campaignsBySectionId() {
            const campaignsBySectionId = {
                noSection: []
            };
            this.campaigns.forEach(campaign => {
                if (!campaign.section || !campaign.section._id) {
                    campaignsBySectionId.noSection.push(campaign);
                    return;
                }
                if (campaignsBySectionId[campaign.section._id] === undefined) {
                    campaignsBySectionId[campaign.section._id] = [];
                }
                campaignsBySectionId[campaign.section._id].push(campaign);
            });
            return campaignsBySectionId;
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        hasCampaigns() {
            return this.campaigns.length > 0;
        },

        isInitialLoad() {
            return !this.hasLoadedCampaigns || !this.hasLoadedSections;
        },

        isLoading() {
            return this.isLoadingCampaigns || this.isLoadingSections;
        },

        isPermissionGranted() {
            return this.userHasScope;
        },

        sectionToEdit() {
            return this.sections.find(section => {
                return section._id === this.sectionIdToEdit;
            });
        },

        sectionsById() {
            return this.visibleSections.reduce((acc, section) => {
                acc[section._id] = section;
                return acc;
            }, {});
        },

        userCanAddCampaign() {
            return this.$auth.userCan(this.$auth.Actions.CanAddCampaign, { clientId: this.clientId }, false);
        },

        userCanManageCampaignSections() {
            return this.$auth.userCan(this.$auth.Actions.CanManageCampaignSections, { clientId: this.clientId }, false);
        },

        userHasScope() {
            return this.$auth.hasScope({ clientId: this.clientId }, false);
        },

        userScope() {
            return this.$store.state.auth.me && this.$store.state.auth.me.scope;
        },

        visibleSections() {
            if (this.userCanManageCampaignSections) {
                return this.sections;
            }

            return this.sections.filter(section => {
                return this.campaignsBySectionId[section._id] !== undefined;
            });
        },

        orderedSections: {
            get() {
                const dbSectionIds = this.visibleSections.map(({ _id }) => _id);
                const localStorageTagByIndex = this.localSortOrder.reduce((acc, sectionId, index) => {
                    acc[sectionId] = index + 1;
                    return acc;
                }, {});

                const { newTags, existingTags } = dbSectionIds.reduce(
                    (acc, sectionId) => {
                        if (this.localSortOrder.includes(sectionId)) {
                            acc.existingTags.push(sectionId);
                        } else {
                            acc.newTags.push(sectionId);
                        }

                        return acc;
                    },
                    { newTags: [], existingTags: [] }
                );

                const res = [
                    ...existingTags.sort((tagA, tagB) => localStorageTagByIndex[tagA] - localStorageTagByIndex[tagB]),
                    ...newTags
                ];

                return res;
            },
            set(val) {
                this.localSortOrder = val;
            }
        },

        localStorageSectionOrder() {
            const orders = JSON.parse(localStorage.getItem(LocalStorageOrderItem));

            if (!orders || !orders[this.clientId]) {
                return [];
            }

            return orders[this.clientId];
        }
    },

    watch: {
        localSortOrder(clientOrder) {
            const orders = JSON.parse(localStorage.getItem(LocalStorageOrderItem));
            localStorage.setItem(LocalStorageOrderItem, JSON.stringify({ ...orders, [this.clientId]: clientOrder }));
        },

        userHasScope: {
            immediate: true,
            handler() {
                if (!this.userHasScope) {
                    this.$snackbar.warning("You do not have access to this client");
                    this.$router.push({ name: "Home" }).catch(err => {
                        if (err.name !== "NavigationDuplicated") {
                            throw err;
                        }
                    });
                }
            }
        },

        userScope: {
            handler() {
                if (
                    this.campaigns.some(
                        campaign =>
                            !this.$auth.hasScope({
                                clientId: this.clientId,
                                campaignId: campaign._id
                            })
                    )
                ) {
                    this.$apollo.queries.campaigns.refetch();
                }
            }
        }
    },

    created() {
        this.localSortOrder = this.localStorageSectionOrder;
    },

    methods: {
        campaignCreateSuccess() {
            this.$apollo.queries.campaigns.refetch();
        },
        campaignDeleted() {
            this.$apollo.queries.campaigns.refetch();
        },
        reloadCampaignsAndSections() {
            this.$apollo.queries.campaigns.refetch();
            this.$apollo.queries.campaignSections.refetch();
        },
        showDuplicateCampaignModal(campaignId) {
            this.campaignIdToDuplicate = campaignId;
            this.toggleDuplicateCampaignModalIsVisible();
        },
        showEditCampaignModal(campaignId) {
            this.campaignIdToEdit = campaignId;
            this.toggleEditCampaignModalIsVisible();
        },
        showEditCampaignSectionModal(sectionId) {
            this.sectionIdToEdit = sectionId;
            this.toggleEditCampaignSectionModalIsVisible();
        },
        showSetCampaignSectionModal(campaignId) {
            this.campaignIdToEdit = campaignId;
            this.toggleSetCampaignSectionModalIsVisible();
        },
        showTeam(campaignId) {
            this.teamCampaignId = campaignId;
            this.toggleTeamModalIsVisible();
        },
        toggleAddSectionModalIsVisible() {
            this.addSectionModalIsVisible = !this.addSectionModalIsVisible;
        },
        toggleCreateCampaignModalIsVisible() {
            this.createCampaignModalIsVisible = !this.createCampaignModalIsVisible;
        },
        toggleDuplicateCampaignModalIsVisible() {
            this.duplicateCampaignModalIsVisible = !this.duplicateCampaignModalIsVisible;
        },
        toggleEditCampaignModalIsVisible() {
            this.editCampaignModalIsVisible = !this.editCampaignModalIsVisible;
        },
        toggleEditCampaignSectionModalIsVisible() {
            this.editCampaignSectionModalIsVisible = !this.editCampaignSectionModalIsVisible;
        },
        toggleSetCampaignSectionModalIsVisible() {
            this.setCampaignSectionModalIsVisible = !this.setCampaignSectionModalIsVisible;
        },
        toggleTeamModalIsVisible() {
            this.teamModalIsVisible = !this.teamModalIsVisible;
        }
    },

    apollo: {
        campaigns: {
            query: campaignsQuery,
            fetchPolicy: "network-only",
            loadingKey: "isLoadingCampaigns",
            variables() {
                return {
                    clientId: this.clientId,
                    orderBy: this.sortCampaignsBy
                };
            },

            skip() {
                return !this.isPermissionGranted;
            },

            result({ data, error }) {
                if (error) {
                    this.hasErrorLoadingCampaigns = true;
                }
                this.hasLoadedCampaigns = true;
                this.hasErrorLoadingCampaigns = false;
                if (!data.campaigns.length && !data.client) {
                    this.$snackbar.warning("You no longer have access to campaigns under this client");
                    this.$router.push({ name: "Home" }).catch(err => {
                        if (err.name !== "NavigationDuplicated") {
                            throw err;
                        }
                    });
                } else {
                    // TEMPORARY UNTIL OLD IDS ARE REMOVED AND DB DATA MIGRATED
                    this.oldClientId = data.client && data.client.id;
                }
            }
        },

        campaignSections: {
            query: campaignSectionsQuery,
            fetchPolicy: "network-only",
            loadingKey: "isLoadingSections",

            variables() {
                return {
                    clientId: this.clientId,
                    orderBy: this.sortCampaignsBy
                };
            },

            skip() {
                return !this.isPermissionGranted;
            },

            result({ data, error }) {
                if (error) {
                    this.hasErrorLoadingSections = true;
                    return;
                }
                this.hasLoadedSections = true;
                this.hasErrorLoadingSections = false;
                this.sections = data.campaignSections;
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../sass/_variables.scss";

.campaigns {
    &__action {
        &-items {
            display: flex;
            justify-content: flex-end;
            padding: 15px 0;
        }

        &-item {
            flex: 0 0;
            display: flex;
            align-items: center;
            margin-left: 20px;
        }
    }

    &__list--no-action-items {
        padding: 15px 0 0 0;
    }

    .hox-collapsible {
        width: 100%;

        > .ivu-collapse-item.ivu-collapse-item-active {
            border-bottom: 1px transparent;
        }

        &__body {
            margin-top: $spacing;
        }
    }

    .ivu-collapse {
        border-top: 1px transparent;
    }

    .square-button--default:hover {
        background-color: transparent;
    }

    &__section-drag-handle {
        float: right;
        margin-top: 16px;
    }

    &__section-divider {
        border: none;
        border-top: $border-width-base solid $cmp-light-border-color;
        flex: 1;
    }

    &__section-empty-message {
        color: $cmp-light-font-color;
        font-size: $font-size-base;
        margin: (0 - $spacing-small) 0 $spacing;

        p {
            margin: 0 0 $spacing-small;

            &:first-child {
                font-weight: bold;
            }
        }
    }

    &__section-header {
        align-items: center;
        display: flex;
        margin: 0 0 $spacing;
        padding: $spacing 0 0;
    }

    &__section-title {
        font-size: $font-size-large;
        font-weight: bold;
        padding: 0 $spacing-small 0 0;
    }
}

.campaigns-dashboard__empty-message-wrapper {
    padding: $spacing-large 0 0 0;
}

.campaigns-dashboard__loading-wrapper {
    height: 120px;
    position: relative;
}
</style>
