<template>
    <hox-modal :show-footer="!isLoadingCampaign || !hasErrorLoadingCampaign" @close="close">
        <hox-loading-layer v-if="isSettingCampaignSection" />
        <template #header>Move campaign to section</template>
        <div v-if="isLoadingCampaign" class="set-campaign-section-modal__loading-container">
            <hox-loading-layer :is-full-screen="false" />
        </div>
        <hox-alert v-else-if="hasErrorLoadingCampaign" margin-bottom="none" type="danger">
            <template #title>Uh oh!</template>
            <template #content>
                <p>Something went wrong while getting some data. Try again in a few moments.</p>
            </template>
            <template #actionItems>
                <Button type="primary" @click="refetchCampaign">Retry</Button>
            </template>
        </hox-alert>
        <template v-else>
            <sections-select v-model="formData.sectionId" :sections="sections" />
            <div
                v-if="hasUnexpectedErrorSettingCampaignSection"
                class="set-campaign-section-modal__unexpected-error-wrapper"
            >
                <Alert banner type="error">
                    <template #desc>
                        There was an unexpected error and the campaign was unable to be moved to the section. Please try
                        again in a few moments.
                    </template>
                </Alert>
            </div>
            <template slot="footer">
                <Button type="primary" :disabled="isSettingCampaignSection" @click="setCampaignSection">
                    Save
                </Button>
            </template>
        </template>
    </hox-modal>
</template>

<script>
import updateCampaignMutation from "@/apollo/mutations/UpdateCampaign.gql";
import campaignQuery from "@/apollo/queries/v2/Campaign.gql";
import SectionsSelect from "@/components/SectionsSelect";

export default {
    components: {
        SectionsSelect
    },

    props: {
        campaignId: {
            required: true,
            type: String
        },

        sections: {
            required: true,
            type: Array
        }
    },

    data() {
        return {
            campaign: undefined,
            formData: {
                sectionId: undefined
            },
            hasErrorLoadingCampaign: false,
            hasUnexpectedErrorSettingCampaignSection: false,
            isLoadingCampaign: 0,
            isSettingCampaignSection: false
        };
    },

    computed: {
        canEditCampaign() {
            return this.$auth.userCan(this.$auth.Actions.CanEditCampaign, {
                clientId: this.clientId,
                campaignId: this.campaignId
            });
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        }
    },

    watch: {
        canEditCampaign() {
            if (!this.canEditCampaign) {
                this.$snackbar.warning("You do not have access to edit campaigns");
                this.close();
            }
        }
    },

    methods: {
        close() {
            this.$emit("close");
        },

        refetchCampaign() {
            this.$apollo.queries.campaign.refetch();
        },

        async setCampaignSection() {
            this.isSettingCampaignSection = true;
            try {
                await this.$apollo.mutate({
                    mutation: updateCampaignMutation,
                    variables: {
                        campaignId: this.campaignId,
                        campaign: {
                            sectionId: this.formData.sectionId === "none" ? null : this.formData.sectionId
                        }
                    }
                });
                this.$snackbar.success("Campaign moved to section");
                this.$emit("campaignSectionSet");
                this.close();
            } catch (err) {
                this.hasUnexpectedErrorSettingCampaignSection = true;
            } finally {
                this.isSettingCampaignSection = false;
            }
        }
    },

    apollo: {
        campaign: {
            query: campaignQuery,
            fetchPolicy: "network-only",
            loadingKey: "isLoadingCampaign",
            variables() {
                return {
                    campaignId: this.campaignId
                };
            },
            result({ data: { campaign }, error }) {
                if (error) {
                    this.hasErrorLoadingCampaign = true;
                    return;
                }
                this.campaign = campaign;
                this.hasErrorLoadingCampaign = false;
                this.formData = {
                    sectionId: campaign.section ? campaign.section._id : "none"
                };
            }
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/../sass/_variables.scss";

.set-campaign-section-modal {
    &__label {
        display: block;
        margin: 0 0 $spacing-small;
    }

    &__loading-container {
        position: relative;
        height: 300px;
    }

    &__section-select-wrapper {
        margin: 0 0 $spacing;
    }

    &__unexpected-error-wrapper {
        padding: $spacing 0 0 0;
    }
}
</style>
