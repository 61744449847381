<template>
    <div class="list-view">
        <resize-settings-modal
            v-if="resizeSettingsModalIsVisible"
            :editable="editable"
            :resize-settings="resizeSettingsToEdit"
            :is-group-resize-settings="isEditingGroupResizeSettings"
            :is-single-template-resize-settings="isEditingSingleTemplateResizeSettings"
            :preview-image-url="imageToResize"
            :template-ids="templateIdsToEditResizeSettingsOf"
            @setResizeSettings="setResizeSettings"
            @close="toggleResizeSettingsModal"
        />
        <visible-row
            :visible="isVisible(editable, groupsAffectingEditable, null)"
            @updateSettings="onSettingsUpdate(editable, groupsAffectingEditable, null, ...arguments)"
        >
            <labelled-value
                :label="EditableGroupScopeLabel"
                class="list-view__item"
                icon="md-search"
                :value="getFileName(editable, groupsAffectingEditable, null)"
                @click="fileSelected(editable, groupsAffectingEditable, null)"
            >
                <template #icons>
                    <Icon
                        v-if="!isDefault(editable, groupsAffectingEditable, null) && isImage"
                        type="md-crop"
                        @click.stop="showResizeSettingsModal(templatesUsingGroupMediaItem, true, false)"
                    />
                    <Icon type="md-create" @click.prevent="fileSelected(editable, groupsAffectingEditable, null)" />
                </template>
            </labelled-value>
        </visible-row>
        <visible-row
            v-for="(label, templateId) in templateLabels"
            v-show="opened"
            :key="getForKey(editable, templateId)"
            :visible="isVisible(editable, groupsAffectingEditable, templateId)"
            @updateSettings="onSettingsUpdate(editable, groupsAffectingEditable, templateId, ...arguments)"
        >
            <lockable-row
                :locked="isTemplateOverwrite(editable, groupsAffectingEditable, templateId)"
                @unlock="onUnlock(editable, groupsAffectingEditable, templateId)"
            />
            <labelled-value
                :label="label"
                class="list-view__item"
                :label-color="getLabelColor(editable, groupsAffectingEditable, templateId)"
                icon="md-search"
                :value="getFileName(editable, groupsAffectingEditable, templateId)"
                @click="fileSelected(editable, groupsAffectingEditable, templateId)"
            >
                <template #icons>
                    <Icon
                        v-if="!isDefault(editable, groupsAffectingEditable, templateId) && isImage"
                        type="md-crop"
                        @click.stop="
                            showResizeSettingsModal(
                                [templateId],
                                !isTemplateOverwrite(editable, groupsAffectingEditable, templateId),
                                true
                            )
                        "
                    />
                    <Icon
                        type="md-create"
                        @click.prevent="fileSelected(editable, groupsAffectingEditable, templateId)"
                    />
                </template>
            </labelled-value>
        </visible-row>
    </div>
</template>

<script>
import LabelledValue from "@/components/Campaign/LabelledValue";
import LockableRow from "@/components/Campaign/LockableRow";
import VisibleRow from "@/components/Campaign/VisibleRow";
import { EditableImageTypes } from "@/enums/editables";
import editableFileViewMixin from "@/mixins/editableFileViewMixin";
import { EditableGroupScopeLabel } from "@/enums/overwrites";

export default {
    name: "EditableFileListView",
    components: {
        LockableRow,
        VisibleRow,
        LabelledValue
    },
    mixins: [editableFileViewMixin],
    computed: {
        isImage() {
            return EditableImageTypes.includes(this.type);
        }
    },
    created() {
        this.EditableGroupScopeLabel = EditableGroupScopeLabel;
    },
    methods: {
        getFileName(editable, groupsAffectingEditable, templateId) {
            // todo decide on how to obtain default value
            const mediaItem = this.getOverwriteMediaItem(editable, groupsAffectingEditable, templateId);

            return mediaItem ? mediaItem.name : "Default";
        },

        isDefault(editable, groupsAffectingEditable, templateId) {
            return !this.getOverwriteMediaItem(editable, groupsAffectingEditable, templateId);
        },

        isVisible(editable, groupsAffectingEditable, templateId) {
            const settings = this.getEditableSettings(editable, groupsAffectingEditable, templateId);

            if (!settings) {
                return true;
            }

            return settings.visible !== false;
        },

        onUnlock(editable, groupsAffectingEditable, templateId) {
            const overwrite = this.getOverwrite(editable, groupsAffectingEditable, templateId);
            this.removeOverwrite(overwrite._id);
        },

        onSettingsUpdate(editable, groupsAffectingEditable, templateId, payload) {
            const settings = this.getEditableSettings(editable, groupsAffectingEditable, templateId);
            this.updateSettings(settings, payload, groupsAffectingEditable, templateId);
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.list-view {
    background-color: $cmp-dark-secondary-sidebar-color;
    font-size: 12px;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex: 1 0 100%;

    &.labelled-value {
        height: 34px;
    }

    &__item {
        line-height: 34px;
        padding-left: 10px;
        height: 34px;
    }
}
</style>
