<template>
    <div class="filter-tree-select">
        <Dropdown
            transfer-class-name="filter-tree-select__dropdown"
            transfer
            trigger="custom"
            :visible="visible"
            @on-clickoutside="clickoutside"
        >
            <div @click="visible = !visible">
                <slot name="dropdown-trigger">
                    <Button>
                        <Icon type="ios-options-outline"></Icon>
                        Filters
                    </Button>
                </slot>
            </div>
            <template #list>
                <div class="filter-tree-select__tree-wrapper-input" @click.stop>
                    <Input
                        v-model="textFilter"
                        prefix="ios-search"
                        clearable
                        placeholder="Search"
                        size="small"
                        @click.stop.prevent
                    />
                </div>

                <div class="filter-tree-select__tree-wrapper" @click.stop>
                    <Tree
                        class="filter-tree-select__tree"
                        empty-text="No Results"
                        :data="options"
                        check-directly
                        show-checkbox
                        multiple
                        @on-check-change="onCheckChange"
                        @on-toggle-expand="onExpand"
                    />
                </div>
            </template>
        </Dropdown>
    </div>
</template>
<script>
import { CreativeInsightsGetters } from "@/store/modules/creativeInsights";
import { getBreakdownTitle } from "@/enums/creativeInteligence";

const defaultSelectionValue = false;

export default {
    name: "TreeSelectBreakdownFilter",

    props: {
        availableFilters: {
            type: Object
        }
    },

    data() {
        return {
            selected: {},
            expanded: {},
            visible: false,
            textFilter: ""
        };
    },

    computed: {
        breakdownValuesByBreakdown() {
            // we want to have things sorted
            const bbName = this.$store.getters[CreativeInsightsGetters.DynamicBreakdownValuesByBreakdown];

            return Object.keys(bbName)
                .sort()
                .reduce((acc, breakdown) => {
                    acc[breakdown] = bbName[breakdown].sort();

                    return acc;
                }, {});
        },

        hasTextFilter() {
            return this.textFilter !== "";
        },

        options() {
            return Object.keys(this.breakdownValuesByBreakdown)
                .map(breakdown => this.getTree(breakdown, this.breakdownValuesByBreakdown[breakdown]))
                .filter(Boolean);
        },

        selectedDynamicBreakdowns() {
            return this.$store.state.creativeInsights.selectedDynamicBreakdowns;
        }
    },

    watch: {
        breakdownValuesByBreakdown: {
            immediate: true,
            handler() {
                // value has change, so we need to update the this.selected to reflect current status
                this.selected = Object.keys(this.breakdownValuesByBreakdown).reduce((acc, breakdown) => {
                    acc[breakdown] = this.breakdownValuesByBreakdown[breakdown].reduce((accumulator, value) => {
                        let selectionValue = defaultSelectionValue;
                        if (
                            this.selectedDynamicBreakdowns[breakdown] &&
                            this.selectedDynamicBreakdowns[breakdown].includes(value)
                        ) {
                            selectionValue = true;
                        }

                        accumulator[value] = selectionValue;
                        return accumulator;
                    }, {});

                    return acc;
                }, {});

                this.expanded = Object.keys(this.breakdownValuesByBreakdown).reduce((acc, breakdown) => {
                    acc[breakdown] = false;

                    return acc;
                }, {});
            }
        },

        selectedDynamicBreakdowns: {
            handler() {
                // value has change, so we need to update the this.selected to reflect current status
                this.selected = Object.keys(this.breakdownValuesByBreakdown).reduce((acc, breakdown) => {
                    acc[breakdown] = this.breakdownValuesByBreakdown[breakdown].reduce((accumulator, value) => {
                        let selectionValue = defaultSelectionValue;
                        if (
                            this.selectedDynamicBreakdowns[breakdown] &&
                            this.selectedDynamicBreakdowns[breakdown].includes(value)
                        ) {
                            selectionValue = true;
                        }

                        accumulator[value] = selectionValue;
                        return accumulator;
                    }, {});

                    return acc;
                }, {});
            }
        },

        selected: {
            handler() {
                this.$emit("selected", this.selected);
            }
        }
    },

    methods: {
        getTree(breakdown, breakdownValues) {
            const children = breakdownValues
                .map(value => ({
                    title: value,
                    breakdown,
                    value,
                    checked: this.selected[breakdown][value]
                }))
                .filter(({ title }) => title.toLowerCase().indexOf(this.textFilter.toLowerCase()) !== -1);

            if (!children.length) {
                return null;
            }

            return {
                title: getBreakdownTitle(breakdown),
                breakdown,
                expand: this.hasTextFilter || this.expanded[breakdown],
                children
            };
        },

        onCheckChange(curentlyChecked, params) {
            const { breakdown, value, checked, children } = params;
            let updatedBreakdownValues = [];

            // breakdown checkbox clicked
            if (!children) {
                this.selected[breakdown][value] = !this.selected[breakdown][value];
                updatedBreakdownValues = (this.selectedDynamicBreakdowns[breakdown] || []).filter(v => v !== value);
                if (checked) {
                    updatedBreakdownValues.push(value);
                }
            } else {
                this.selected[breakdown] = this.breakdownValuesByBreakdown[breakdown].reduce((acc, v) => {
                    acc[v] = checked;

                    return acc;
                }, {});
                if (checked) {
                    updatedBreakdownValues = [...this.breakdownValuesByBreakdown[breakdown]];
                }
            }

            this.$emit("selectedBreakdowns", {
                ...this.selectedDynamicBreakdowns,
                [breakdown]: updatedBreakdownValues
            });

            this.$emit("selected", this.selected);
        },

        clickoutside() {
            this.visible = false;
        },

        onExpand({ breakdown }) {
            this.expanded[breakdown] = !this.expanded[breakdown];
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.filter-tree-select__tree-wrapper {
    min-width: 250px;
    max-width: 400px;
    padding: 0 $spacing-small;

    &-input {
        margin: 0 $spacing-small 5px $spacing-small;
    }
}
.filter-tree-select__dropdown {
    max-height: 80vh;
}

.filter-tree-select__tree {
}
</style>
