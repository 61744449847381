import { apolloClient } from "@/apollo/index";
import publishVariationsXmlFeedMutation from "@/apollo/mutations/PublishVariationsXmlFeed.gql";
import unpublishVariationsXmlFeedMutation from "@/apollo/mutations/UnpublishVariationsXmlFeed.gql";
import addDownloadComputedDeliverablesJobMutation from "@/apollo/mutations/AddDownloadComputedDeliverablesJob.gql";
import setReportingLabelMutation from "@/apollo/mutations/v2/SetReportingLabel.gql";
import removeReportingLabelMutation from "@/apollo/mutations/v2/RemoveReportingLabel.gql";
import paginatedDeliverablesQuery from "@/apollo/queries/v2/PaginatedDeliverables.gql";
import { CampaignGetters } from "@/store/modules/campaign";
import { BatchDownloads } from "@/enums/platforms";
import { constructLibraryFiltersQueryVariables, isEmptyObject } from "../utils";

export default class Deliverables {
    static getComputedDeliverableIdentifiers(deliverablesData) {
        return deliverablesData.map(deliverable => {
            let editableGroupValueIds = [];
            const groupValues = deliverable.combination && Object.values(deliverable.combination);

            if (Array.isArray(groupValues)) {
                editableGroupValueIds = groupValues.map(groupValue => {
                    if ("groupValueId" in groupValue) {
                        return groupValue.groupValueId;
                    }

                    return Object.values(groupValue).pop().groupValueId;
                });
            }

            return {
                editableGroupValueIds,
                language: deliverable.language,
                masterTemplateId: deliverable.masterTemplate._id
            };
        });
    }

    /**
     * Creates new instance of Deliverables Services
     * @param {VueApollo} apollo
     * @param {Vuex} [store]
     */
    constructor(apollo, store) {
        this.$apollo = apollo;
        this.$store = store;
    }

    constructDeliverable(deliverable) {
        /*
            We're temporarily (hopefully) going to tweak the deliverable
            data here to get it into a state where it's functional as
            a deliverable in the system as it was pre-BE generated deliverables.

            Why? So that we only need to change the minimal amount of code to
            get it working with the BE generated deliverables.
        */
        /*
            Default values are required for the current download implementation to
            work.

            It should be removed once we move to a batch download mechanism,
            because batch will fix everything.
        */
        const defaultValuesByEditableId = deliverable.computedValues.reduce((acc, computedValue) => {
            const editable = this.$store.state.campaign.normalized.editables[computedValue.editableId];
            const defaultValue = editable.defaultValues.find(
                ({ masterTemplateId }) => masterTemplateId === deliverable.masterTemplate._id
            );
            if (defaultValue) {
                acc[computedValue.editableId] = defaultValue.value;
            }
            return acc;
        }, {});
        /*
            `combination` is used in the QA drawer, in the library banner info poptip,
            to generate the reporting label and to construct the deliverable identifier.
        */
        const combination = deliverable.editableGroupValueIds.reduce((acc, groupValueId) => {
            const group = this.$store.getters[CampaignGetters.groupByGroupValueId][groupValueId];
            if (group) {
                return [
                    ...acc,
                    {
                        [group]: {
                            groupValueId
                        }
                    }
                ];
            }
            return acc;
        }, []);
        return {
            ...deliverable,
            combination,
            defaultValuesByEditableId,
            idHash: deliverable.hash,
            masterTemplate: this.$store.state.campaign.normalized.masterTemplates[deliverable.masterTemplate._id]
        };
    }

    async getPaginatedDeliverables(campaignId, libraryFilters, page, deliverablesPerPage) {
        const libraryFiltersVars = constructLibraryFiltersQueryVariables(libraryFilters);
        const { data } = await this.$apollo.query({
            fetchPolicy: "no-cache",
            query: paginatedDeliverablesQuery,
            variables: {
                campaignId,
                libraryFilters: libraryFiltersVars,
                page,
                perPage: deliverablesPerPage
            }
        });
        const deliverables = data.computeDeliverables.deliverables.map(deliverable =>
            this.constructDeliverable(deliverable)
        );
        return {
            deliverables,
            pagination: data.computeDeliverables.pagination
        };
    }

    getBatchMutationVariables(
        campaignId,
        platform,
        deliverables,
        deliverableIdentifiers,
        libraryFilters,
        platformOpts
    ) {
        const variables = {
            campaignId,
            platform,
            platformOpts
        };

        if (!isEmptyObject(libraryFilters)) {
            // all selected mode
            const libraryFiltersVars = constructLibraryFiltersQueryVariables(libraryFilters);
            variables.libraryFilters = libraryFiltersVars;
        } else if (deliverableIdentifiers.length) {
            variables.computedDeliverableIdentifiers = deliverableIdentifiers;
        } else {
            const computedDeliverableIdentifiers = this.constructor.getComputedDeliverableIdentifiers(deliverables);
            variables.computedDeliverableIdentifiers = computedDeliverableIdentifiers;
        }

        return variables;
    }

    async publish(campaignId, platform, deliverables, libraryFilters = {}, rebuild = false) {
        const variables = this.getBatchMutationVariables(campaignId, platform, deliverables, [], libraryFilters);
        const { data } = await apolloClient.mutate({
            mutation: publishVariationsXmlFeedMutation,
            variables: { ...variables, rebuild }
        });
        return data.publishVariationsXmlFeed;
    }

    async unpublish(campaignId, platform, deliverables, libraryFilters = {}) {
        const variables = this.getBatchMutationVariables(campaignId, platform, deliverables, [], libraryFilters);
        const { data } = await apolloClient.mutate({
            mutation: unpublishVariationsXmlFeedMutation,
            variables
        });
        return data.unpublishVariationsXmlFeed;
    }

    async downloadJob(
        campaignId,
        platform,
        deliverables,
        deliverableIdentifiers = [],
        libraryFilters = {},
        platformOpts = {}
    ) {
        const variables = this.getBatchMutationVariables(
            campaignId,
            platform,
            deliverables,
            deliverableIdentifiers,
            libraryFilters,
            platformOpts
        );
        // Process image_no_scale
        if (platform === BatchDownloads.ImageNoScale) {
            variables.platform = "image";
            variables.deviceScaleFactor = 1;
        } else if ([BatchDownloads.ImageJpegNoScale, BatchDownloads.ImageJpeg].includes(platform)) {
            variables.platform = "image";
            variables.platformOpts.format = "jpeg";

            if (platform === BatchDownloads.ImageJpegNoScale) {
                variables.deviceScaleFactor = 1;
            }
        }

        if ([BatchDownloads.Image, BatchDownloads.ImageJpeg].includes(platform)) {
            variables.deviceScaleFactor = 2;
        }
        // Call mutation
        const { data } = await apolloClient.mutate({
            mutation: addDownloadComputedDeliverablesJobMutation,
            variables
        });
        return data.addDownloadComputedDeliverablesJob;
    }

    async setComputedDeliverablesReportingLabel(campaignId, deliverable, reportingLabel) {
        const [computedDeliverableIdentifier] = Deliverables.getComputedDeliverableIdentifiers([deliverable]);

        // Call mutation
        const { data, errors = [] } = await this.$apollo.mutate({
            mutation: setReportingLabelMutation,
            variables: {
                campaignId,
                computedDeliverableIdentifier,
                reportingLabel
            }
        });
        if (errors.length) {
            throw new Error(errors[0]);
        }
        return data.setComputedDeliverableCustomReportingLabel.reportingLabel;
    }

    async removeComputedDeliverablesReportingLabel(campaignId, deliverable) {
        const [computedDeliverableIdentifier] = Deliverables.getComputedDeliverableIdentifiers([deliverable]);

        // Call mutation
        const { data, errors = [] } = await this.$apollo.mutate({
            mutation: removeReportingLabelMutation,
            variables: {
                campaignId,
                computedDeliverableIdentifier
            }
        });
        if (errors.length) {
            throw new Error(errors[0]);
        }
        return data.removeComputedDeliverableCustomReportingLabel.reportingLabel;
    }
}
