<template>
    <div class="user-feedback-drawer">
        <Drawer :transfer="false" :value="isOpen" width="600px" @on-close="close">
            <template #close>
                <div class="hox-drawer__close-icon-container">
                    <Icon size="30" type="ios-close" @click="close" />
                </div>
            </template>
            <div :key="formType" class="user-feedback-drawer__iframe-wrapper" :class="iframeWrapperClass">
                <template v-if="formType === 'bug-report'">
                    <script
                        async
                        type="application/javascript"
                        src="https://secure.aha.io/assets/idea_portals/embedded/application.js"
                        data-portal-url="https://bug-report-hogarth.ideas.aha.io/ideas/new"
                    ></script>
                </template>
                <!-- eslint-disable-next-line vue/no-useless-template-attributes -->
                <template v-else class="user-feedback-drawer__iframe-feature-request">
                    <script
                        async
                        type="application/javascript"
                        src="https://secure.aha.io/assets/idea_portals/embedded/application.js"
                        data-portal-url="https://feature-request-hogarth.ideas.aha.io/ideas/new"
                    ></script>
                </template>
            </div>
        </Drawer>
    </div>
</template>

<script>
// https://gist.github.com/jwilson8767/db379026efcbd932f64382db4b02853e
export function elementReady(selector) {
    return new Promise(resolve => {
        const el = document.querySelector(selector);
        if (el) {
            resolve(el);
            return;
        }
        new MutationObserver((mutationRecords, observer) => {
            // Query for elements matching the specified selector
            Array.from(document.querySelectorAll(selector)).forEach(element => {
                resolve(element);
                observer.disconnect();
            });
        }).observe(document.documentElement, {
            childList: true,
            subtree: true
        });
    });
}

export default {
    name: "UserFeedbackDrawer",

    props: {
        formType: {
            type: String,
            default: "bug-report"
        },

        isOpen: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        iframeWrapperClass() {
            return `user-feedback-drawer__iframe-${this.formType}`;
        }
    },

    watch: {
        formType: {
            immediate: true,
            handler(value) {
                this.enableScroll(value);
            }
        }
    },

    methods: {
        enableScroll() {
            elementReady(`.${this.iframeWrapperClass} iframe`).then(iframe => {
                // eslint-disable-next-line no-param-reassign
                iframe.scrolling = "yes";
            });
        },
        close() {
            this.$emit("close");
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
.user-feedback-drawer {
    &__iframe-wrapper {
        width: 600px;
        height: 100%;
    }

    iframe {
        height: calc(100% - 17px) !important;
        padding-top: 16px;
    }
    &__body {
        height: 100%;
        flex: 1;
        border: 1px solid #0d3349;
    }

    &__container {
        transform: translateZ(0);
        overflow: hidden;
    }

    &__header {
        padding: $spacing $spacing-large;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-select {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 300px;
        }

        &-breakdown {
            margin-right: $spacing;
        }
    }
    .ivu-drawer-right {
        .ivu-drawer-close {
            top: 5px;
            z-index: 2;
            color: #000;
        }
    }
}
</style>
