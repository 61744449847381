import Vue from "vue";
import { deepClone } from "@/utils";
import { MultiviewMode } from "@/enums/multiview";

const storeNamespace = "ui";

export const UiAction = {
    CloseSidebar: `${storeNamespace}/closeSidebar`,
    MultiviewDestroy: `${storeNamespace}/multiviewDestroy`,
    MultiviewInit: `${storeNamespace}/multiviewInit`,
    MultiviewUpdate: `${storeNamespace}/multiviewUpdate`,
    OpenSidebar: `${storeNamespace}/openSidebar`,
    SetCurrentClient: `${storeNamespace}/setCurrentClient`,
    SetDomain: `${storeNamespace}/setDomain`,
    SetSidebarWidthCollapsed: `${storeNamespace}/setSidebarWidthCollapsed`,
    SetSidebarWidthOpen: `${storeNamespace}/setSidebarWidthOpen`,
    SetThemeMode: `${storeNamespace}/setThemeMode`
};

const UiInternalAction = {
    MultiviewUpdate: "multiviewUpdate"
};

export const UiMutation = {
    MultiviewDestroy: "multiviewDestroy",
    MultiviewInit: "multiviewInit",
    MultiviewSetPageCount: "multiviewSetPageCount",
    MultiviewSetSelected: "multiviewSetSelected",
    MultiviewSetTotalCount: "multiviewSetTotalCount",
    MultiviewSwitchView: "multiviewSwitchView",
    SetCurrentClient: "setCurrentClient",
    SetDomain: "setDomain",
    SetSidebarState: "setSidebarState",
    SetSidebarWidthCollapsed: "setSidebarWidthCollapsed",
    SetSidebarWidthOpen: "setSidebarWidthOpen",
    UpdateLanguageFilter: "updateLanguageFilter",
    UpdateSizeFilter: "updateSizeFilter",
    SetThemeMode: "setThemeMode"
};

const instanceState = {
    pageCount: 0,
    selected: 0,
    totalCount: 0,
    view: MultiviewMode.Grid
};

export default {
    namespaced: true,
    state: {
        defaultTheme: "default",
        domain: "other",
        currentClient: null,
        isSidebarOpen: false,
        loginReturnRoute: null,
        networkErrorReturnRoute: null,
        sidebarWidthOpen: 300,
        sidebarWidthCollapsed: 0,
        theme: "default",
        themeMode: {
            theme: "light",
            icon: "md-moon",
            label: "Dark mode"
        },
        multiviewComponents: {
            /* [instanceName: string]: InstanceState */
        }
    },

    mutations: {
        multiviewDestroy(state, instance) {
            Vue.delete(state.multiviewComponents, instance);
        },

        multiviewInit(state, instance) {
            if (!(instance in state.multiviewComponents)) {
                Vue.set(state.multiviewComponents, instance, deepClone(instanceState));
            }
        },

        multiviewSetPageCount(state, { instance, pageCount }) {
            Vue.set(state.multiviewComponents, instance, {
                ...state.multiviewComponents[instance],
                pageCount
            });
        },

        multiviewSetSelected(state, { instance, selected }) {
            Vue.set(state.multiviewComponents, instance, {
                ...state.multiviewComponents[instance],
                selected
            });
        },

        multiviewSetTotalCount(state, { instance, totalCount }) {
            Vue.set(state.multiviewComponents, instance, {
                ...state.multiviewComponents[instance],
                totalCount
            });
        },

        multiviewSwitchView(state, { instance, view }) {
            Vue.set(state.multiviewComponents, instance, {
                ...state.multiviewComponents[instance],
                view
            });
        },

        setCurrentClient(state, client) {
            state.currentClient = client;
        },

        setDomain(state, payload) {
            state.domain = payload;
        },

        setLoginReturnRoute(state, route) {
            state.loginReturnRoute = route;
        },

        setNetworkErrorReturnRoute(state, route) {
            state.networkErrorReturnRoute = route;
        },

        setSidebarState(state, payload) {
            state.isSidebarOpen = payload;
        },

        setSidebarWidthOpen(state, width) {
            state.sidebarWidthOpen = width;
        },

        setSidebarWidthCollapsed(state, width) {
            state.sidebarWidthCollapsed = width;
        },

        setTheme(state, theme) {
            state.theme = theme;
        },

        setThemeMode(state, payload) {
            state.themeMode = payload;
        }
    },

    actions: {
        closeSidebar({ commit }) {
            commit(UiMutation.SetSidebarState, false);
        },

        multiviewDestroy({ commit }, instance) {
            commit(UiMutation.MultiviewDestroy, instance);
        },

        multiviewInit({ commit, dispatch }, initData) {
            if (typeof initData === "object") {
                commit(UiMutation.MultiviewInit, initData.instance);
                dispatch(UiInternalAction.MultiviewUpdate, initData);
            } else {
                commit(UiMutation.MultiviewInit, initData);
            }
        },

        /* eslint-disable complexity */
        multiviewUpdate({ commit }, update) {
            if (!("instance" in update)) {
                throw Error("Instance name required");
            }

            if ("pageCount" in update) {
                commit(UiMutation.MultiviewSetPageCount, update);
            }

            if ("selected" in update) {
                commit(UiMutation.MultiviewSetSelected, update);
            }

            if ("totalCount" in update) {
                commit(UiMutation.MultiviewSetTotalCount, update);
            }

            if ("view" in update) {
                commit(UiMutation.MultiviewSwitchView, update);
            }
        },

        openSidebar({ commit }) {
            commit(UiMutation.SetSidebarState, true);
        },

        setCurrentClient({ commit }, client) {
            commit(UiMutation.SetCurrentClient, client);
        },

        setDomain({ commit }, data) {
            commit(UiMutation.SetDomain, data);
        },

        setSidebarWidthCollapsed({ commit }, width) {
            commit(UiMutation.SetSidebarWidthCollapsed, width);
        },

        setSidebarWidthOpen({ commit }, width) {
            commit(UiMutation.SetSidebarWidthOpen, width);
        },

        setTheme({ commit }, theme) {
            commit("setTheme", theme);
        },

        setThemeMode({ commit }, value) {
            localStorage.setItem("currentTheme", JSON.stringify(value));
            commit(UiMutation.SetThemeMode, value);
        }
    }
};
