<template>
    <div class="bar-chart creative-insights-chart">
        <div ref="boundaryElement" class="creative-insights-chart__chart-wrapper">
            <canvas ref="chart" class="horizontal-bar-chart__chart" :style="canvasStyle" />
            <div ref="popperAnchor" class="creative-insights-chart__tooltip" :style="tooltipStyle"></div>
        </div>
        <chart-tooltip ref="tooltip" :point-labels="tooltipLines" :image-urls="tooltipImageUrls" />
    </div>
</template>

<script>
import { Chart, LinearScale, Title, Tooltip } from "chart.js";
import { TreemapController, TreemapElement } from "chartjs-chart-treemap";
import { CreativeInsightsGetters } from "@/store/modules/creativeInsights";
import { getMetricStat, utilisationToValue } from "@/utils/creativeInteligence";
import { numberToShortKM } from "@/utils";
import { ChartDisplayValue, getCustomTooltips, getPointColors } from "@/utils/chart";
import { ChartColors } from "@/enums/creativeInteligence";
import chartTooltipMixin from "@/mixins/chartTooltipMixin";
import ChartTooltip from "@/components/CreativeIntelligence/ChartTooltip";

Chart.register(LinearScale, Title, Tooltip, TreemapController, TreemapElement);

export default {
    name: "TreemapChart",
    components: { ChartTooltip },
    mixins: [chartTooltipMixin],
    props: {
        data: {
            type: Object
        },

        displayValue: {
            type: String,
            default: ChartDisplayValue.KpiStat
        },

        labelMap: {
            type: Object,
            default() {
                return {};
            }
        },

        maxHeight: {
            type: Number,
            default: 380
        },

        tag: {
            type: Object
        },

        type: {
            type: String
        }
    },

    data() {
        return {
            chart: undefined
        };
    },

    computed: {
        canvasStyle() {
            return {
                maxHeight: `${this.maxHeight}px`
            };
        },

        chartData() {
            return Object.keys(this.data).reduce((acc, group) => {
                if (this.data[group].summaryStats) {
                    acc[group] = this.data[group];
                }

                return acc;
            }, {});
        },

        datasets() {
            return ["kpiMetric"].map(metric => {
                const { values, creatives } = this.generateTreemapChartData(metric);

                return {
                    // label: getMetricsLabel(this[`${metric}sName`]),
                    creatives,
                    tree: values,
                    key: "value",
                    backgroundColor: ctx => {
                        return ctx.raw?._data?.backgroundColor || ChartColors[0];
                    },
                    hoverBackgroundColor: ctx => {
                        return ctx.raw?._data?.hoverBackgroundColor || `${ChartColors[0]}AA`;
                    }
                };
            });
        },

        kpiMetricMax() {
            // 5% more than max or threshold
            return (
                (this.kpiMetrixOverallMax < this.kpiMetricValue ? this.kpiMetricValue : this.kpiMetrixOverallMax) * 1.05
            );
        },

        kpiMetricMin() {
            return 0;
        },

        kpiMetricsName() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricName];
        },

        kpiMetricsValue() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricValue];
        },

        kpiMetrixOverallMax() {
            const stat = getMetricStat(this.kpiMetricsName);

            return Math.max(...this.labels.map(label => this.chartData[label].summaryStats.kpiMetric[stat]));
        },

        labels() {
            return Object.keys(this.chartData);
        },

        displayLabels() {
            return this.labels.map(l => this.labelMap[l] || l).sort();
        },

        scales() {
            return {
                kpiMetric: {
                    id: "kpiMetric",
                    type: "linear",
                    min: this.kpiMetricMin,
                    max: this.kpiMetricMax,
                    display: false,
                    ticks: {
                        display: false
                    },
                    grid: {
                        drawOnChartArea: false // only want the grid lines for one axis to show up
                    }
                }
            };
        }
    },

    watch: {
        data() {
            this.chart.data.datasets = this.datasets;
            this.chart.data.labels = this.displayLabels;
            this.chart.options.scales = this.scales;
            this.chart.update();
        },

        type() {
            this.chart.data.datasets = this.datasets;
            this.chart.data.labels = this.displayLabels;
            this.chart.options.scales = this.scales;
            this.chart.update();
        }
    },

    mounted() {
        this.initChart();
    },

    methods: {
        customTooltip(chartJsTooltip) {
            try {
                getCustomTooltips(this, chartJsTooltip, this.setTooltipUrls);
            } catch (e) {
                console.log(e);
            }
        },

        setTooltipUrls(_, tooltipModel) {
            // because with the treemap chart we don't have the situation where we get more then one datapoint we can address it directly
            const dataPointLabel = tooltipModel.dataPoints[0].raw._data.label;
            console.log(dataPointLabel);
            const { deliverables } = this.data[dataPointLabel];
            const frameUrls = deliverables.map(({ signedPreviewUrl }) => signedPreviewUrl).filter(Boolean);

            if (frameUrls.length) {
                const uniqueImages = [...new Set(frameUrls)];
                if (JSON.stringify(this.tooltipImageUrls) !== JSON.stringify(uniqueImages)) {
                    this.tooltipImageUrls = uniqueImages;
                } else {
                    this.$refs.tooltip.startTooltipCarousel();
                }
            } else {
                this.tooltipImageUrls = [];
            }
        },

        generateTreemapChartData(metric = "kpiMetric") {
            return this.displayLabels.reduce(
                (acc, label, currentIndex) => {
                    const { backgroundColor, hoverBackgroundColor } = this.getPointColors(label, currentIndex);
                    const { deliverables, summaryStats } = this.chartData[label];

                    // since treemap operates on area rather than the value
                    // we need to pass it as an object in order to be able to show correct tooltip value
                    const value = {
                        value: this.getPointValue(metric, summaryStats),
                        label,
                        backgroundColor,
                        hoverBackgroundColor
                    };

                    return {
                        backgroundColors: [...acc.backgroundColors, backgroundColor],
                        hoverBackgroundColors: [...acc.hoverBackgroundColors, hoverBackgroundColor],
                        values: [...acc.values, value],
                        creatives: [...acc.creatives, deliverables]
                    };
                },
                {
                    backgroundColors: [],
                    hoverBackgroundColors: [],
                    values: [],
                    creatives: []
                }
            );
        },

        getPointColors(label, index) {
            return getPointColors(label, index);
        },

        getPointValue(metric, summaryStats) {
            if (this.displayValue === ChartDisplayValue.Utilisation) {
                return utilisationToValue(summaryStats.utilisation);
            }

            const stat = getMetricStat(this[`${metric}sName`]);
            return summaryStats[metric][stat];
        },

        getTickString(v, mprecision = 0, kprecision = 0) {
            return numberToShortKM(v, mprecision, kprecision);
        },

        initChart() {
            this.chart = new Chart(this.$refs.chart, {
                data: {
                    labels: this.displayLabels,
                    datasets: this.datasets
                },
                options: {
                    scales: this.scales,
                    plugins: {
                        legend: {
                            display: this.type === "doughnutChart",
                            align: "center",
                            position: "right",
                            labels: {
                                usePointStyle: true,
                                boxWidth: 4
                            }
                        },
                        title: {
                            display: false,
                            align: "start",
                            text: this.title
                        },
                        tooltip: {
                            // Disable the on-canvas tooltip
                            enabled: false,
                            // todo uncomment to add custom tooltips
                            external: this.customTooltip.bind(this),
                            callbacks: {
                                label: ({ label }) => label
                            }
                        }
                    },
                    elements: {
                        word: {
                            maxRotation: 0
                        },
                        treemap: {
                            labels: {
                                display: true,
                                formatter: ctx => ctx.raw._data.label
                            }
                        }
                    },

                    maintainAspectRatio: false,
                    responsive: true,
                    ...this.chartOptionsByType
                },
                plugins: this.plugins,
                type: "treemap"
            });
        },

        setTooltipText(tooltipModel) {
            let textLines = [];

            if (tooltipModel.dataPoints) {
                if (tooltipModel.dataPoints[0]?.raw._data) {
                    const { label } = tooltipModel.dataPoints[0].raw._data;

                    textLines = [`${label}`];
                }
            }

            this.tooltipLines = textLines.map(line => {
                const stats = this.data[line].summaryStats;
                const deliverableAttributes = this.data[line].deliverables[0].attributes;
                const attr = deliverableAttributes.find(({ attribute }) => attribute === line);

                let attributeConfidence;
                if (attr) {
                    attributeConfidence = attr.metadata[0].confidence;
                }

                return {
                    label: line,
                    stats,
                    attributeConfidence
                };
            });
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.bar-chart {
    width: 100%;
    height: 100%;
}

.creative-insights-chart__chart-wrapper {
    &__chart-wrapper {
        height: 380px;
    }
}

.horizontal-bar-chart__chart {
    height: 380px;
}
</style>
