<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
    <div class="editables-controls">
        <Button
            v-if="isSettingsPaneVisible && !showSettingsPaneInModal"
            class="campaign-edit__header-action-btn"
            size="small"
            icon="md-arrow-back"
            @click="hideSettingsPane"
        >
            Back
        </Button>
        <Button
            class="campaign-edit__header-action-btn"
            size="small"
            @click="toggleExpand"
            v-text="allExpanded ? 'Collapse All' : 'Expand All'"
        ></Button>
        <Button
            v-if="showDetachBtn"
            class="campaign-edit__header-action-btn detach-btn"
            size="small"
            icon="md-swap"
            @click="detach"
        ></Button>
        <Input
            class="campaign-edit__header-action-btn editables-controls__search"
            :value="nameFilter"
            size="small"
            search
            placeholder="Search"
            clearable
            @input="setNameFilter"
        />
    </div>
</template>
<script>
import { EditorAction, EditorGetters } from "@/store/modules/editor";

export default {
    name: "EditablesListGontrols",
    props: {
        allExpanded: {
            type: Boolean
        }
    },

    data() {
        return {
            nameFilter: ""
        };
    },

    computed: {
        isSettingsPaneVisible() {
            return this.$store.state.editor.isSettingsPaneVisible;
        },

        selectedEditables() {
            return this.$store.getters[EditorGetters.selectedEditables];
        },

        showDetachBtn() {
            return !this.showEditablesPaneInModal;
        },

        showEditablesPaneInModal() {
            return this.$store.state.editor.showEditablesPaneInModal;
        },

        showSettingsPaneInModal() {
            return this.$store.state.editor.showSettingsPaneInModal;
        }
    },

    watch: {
        selectedEditables() {
            this.setNameFilter("");
        }
    },

    methods: {
        detach() {
            this.$emit("detach");
        },

        hideSettingsPane() {
            this.$store.dispatch(EditorAction.SetEditedEditable, null);
        },

        setNameFilter(value) {
            this.nameFilter = value;
            this.$emit("updateFilter", value);
        },

        toggleExpand() {
            this.$emit("toggleExpand", !this.allExpanded);
        }
    }
};
</script>
<style lang="scss">
@import "../../../sass/variables";

.editables-controls {
    position: relative;
    top: 0;
    left: 0;
    height: 63px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    border-bottom: $cmp-dark-border-width solid $cmp-dark-border-color;

    &__search {
        background-color: transparent;
        border: none;

        &:hover {
            background-color: transparent;
            border: none;
        }
    }

    .campaign-edit__header-action-btn {
        margin-left: $spacing-small;
        margin-bottom: $spacing-small;
        font-size: 12px;

        > span {
            display: inline;
        }

        &.detach-btn {
            justify-self: flex-end;
        }
    }
}
</style>
