<template>
    <div v-if="canListMasterTemplates" class="hox-full-height master-templates">
        <hox-loading-layer v-if="isLoading" is-opaque />
        <master-template-library />
        <sidebar :scroll="false">
            <div class="master-templates__filters">
                <master-template-size-filter />
                <master-template-type-filter />
                <master-template-resized-filter />
                <master-template-family-filter />
            </div>
        </sidebar>
    </div>
</template>

<script>
import bus from "@/bus";
import { UiAction } from "@/store/modules/ui";

import clientMasterTemplatesQuery from "@/apollo/queries/v2/ClientMasterTemplates.gql";

import Sidebar from "@/components/Sidebar";
import MasterTemplateLibrary from "@/components/Campaign/MasterTemplateLibrary";
import MasterTemplateSizeFilter from "@/components/Campaign/MasterTemplates/MasterTemplateSizeFilter";
import MasterTemplateTypeFilter from "@/components/Campaign/MasterTemplates/MasterTemplateTypeFilter";
import MasterTemplateResizedFilter from "@/components/Campaign/MasterTemplates/MasterTemplateResizeFilter";
import MasterTemplateFamilyFilter from "@/components/Campaign/MasterTemplates/MasterTemplateFamilyFilter";

export default {
    name: "CampaignTemplates",
    components: {
        MasterTemplateFamilyFilter,
        MasterTemplateResizedFilter,
        MasterTemplateTypeFilter,
        MasterTemplateSizeFilter,
        Sidebar,
        MasterTemplateLibrary
    },
    data() {
        return {
            uploadConfig: null,
            masterTemplates: [],
            isSetNameEdited: false,
            isNameInputDisabled: false,
            newSetName: "",
            currentSegment: 0,
            currentTemplate: null,
            formData: {},
            refreshIds: {},
            isLoading: true,
            uploadType: "load"
        };
    },

    computed: {
        canListMasterTemplates() {
            return this.$auth.userCan(this.$auth.Actions.CanListMasterTemplates, { clientId: this.clientId });
        },

        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        isPermissionGranted() {
            return this.canListMasterTemplates;
        }
    },

    watch: {
        canListMasterTemplates: {
            immediate: true,
            handler() {
                if (!this.canListMasterTemplates) {
                    this.$snackbar.warning("You do not have access to list master templates");
                    this.$router.push({ name: "Home" });
                }
            }
        }
    },

    created() {
        if (this.canListMasterTemplates) {
            this.$store.dispatch(UiAction.OpenSidebar);
            this.$apollo.queries.masterTemplates.refetch();
        }
    },

    apollo: {
        masterTemplates: {
            query: clientMasterTemplatesQuery,
            variables() {
                return {
                    clientId: this.clientId
                };
            },
            manual: true,
            skip() {
                return !this.isPermissionGranted;
            },
            result({ data }) {
                this.masterTemplates = data.masterTemplatesByClient;

                this.isLoading = false;
            },
            error(e) {
                bus.$emit("apolloErrorEvent", e);
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.master-templates {
    height: 100%;
    position: relative;
    width: 100%;

    &__filters {
        padding-top: 60px;
    }

    .campaign-banner {
        border: 1px solid $cmp-light-border-color;
        box-sizing: content-box;
    }

    .master-template-library {
        bottom: 0;
        display: flex;
        flex-direction: column;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}
</style>
