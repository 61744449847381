import md5 from "md5";
// eslint-disable-next-line import/no-extraneous-dependencies
import rgbToHex from "shared-utils/rgbToHex";
// eslint-disable-next-line import/no-extraneous-dependencies
import { TemplateType } from "shared-utils/enums/masterTemplate";
// eslint-disable-next-line import/no-extraneous-dependencies
import { getMediaItemResizedUrl } from "shared-utils/editables";
import { deepClone, getImageDimensionsFromUrl, getObjectValue } from "@/utils/index";
import { OverwriteScope } from "@/enums/overwrites";
import { EditableImageTypes, EditableMediaTypes, EditableType } from "@/enums/editables";

function getUrlWithoutQueryParams(url) {
    return url.split("?")[0];
}

export const getOverwriteScopeId = overwrite => {
    switch (overwrite.scope) {
        /* these levels are not currently in use but leaving in case we need to reuse it
    case OverwriteScope.Campaign:
        return `${ overwrite.language }__${ overwrite.scope }`;

    case OverwriteScope.Template:
        return `${ overwrite.language }__${ overwrite.scope }__${ overwrite.masterTemplateId }`; */

        case OverwriteScope.EditableGroup:
            return `${overwrite.language}__${overwrite.scope}__${overwrite.editableGroupValueIds.sort().join("_")}`;

        case OverwriteScope.EditableGroupTemplate:
            return `${overwrite.language}__${overwrite.scope}__${overwrite.editableGroupValueIds.sort().join("_")}__${
                overwrite.masterTemplateId
            }`;

        default:
            return `${overwrite.language}__${overwrite.scope}`;
    }
};

const mergeOverwrites = (baseOverwrites, extensionOverwrites) => {
    const existingOverwritesMap = baseOverwrites.reduce((acc, cur) => {
        acc[getOverwriteScopeId(cur)] = cur;
        return acc;
    }, {});

    const mergedOverwrites = extensionOverwrites.reduce((acc, newOverwrite) => {
        acc[getOverwriteScopeId(newOverwrite)] = newOverwrite;
        return acc;
    }, existingOverwritesMap);

    return Object.values(mergedOverwrites);
};

export default {
    computeEditableValue(editable, masterTemplateId, overwriteValueObject, masterTemplates = {}) {
        const defaultValue = this.getDefaultValue(editable, masterTemplateId);
        const overwriteValue = this.getEditableValue(editable, overwriteValueObject, masterTemplateId, masterTemplates);

        if (typeof overwriteValue !== "undefined" && overwriteValue !== null) {
            return overwriteValue;
        }

        return defaultValue;
    },

    extractOverwriteValue(overwriteValue) {
        if (overwriteValue.mediaItem === null && overwriteValue.value === undefined) {
            return false;
        }

        return overwriteValue;
    },

    findMissingFonts(editable, availableFontNames) {
        if (!editable.layers) {
            return [];
        }

        const missingFonts = editable.layers.reduce((acc, { value }) => {
            if (value.text) {
                value.text.characterStyles.forEach(({ fontName, fontAvailable }) => {
                    if (!fontAvailable && !availableFontNames.includes(fontName)) {
                        acc.add(fontName);
                    }
                });
            }
            return acc;
        }, new Set());

        return Array.from(missingFonts);
    },

    hasPsdTemplate(editable, masterTemplates) {
        return editable.defaultValues.some(
            ({ masterTemplateId }) =>
                masterTemplates[masterTemplateId] && masterTemplates[masterTemplateId].adType === TemplateType.PSD
        );
    },

    getDefaultValue(editable, masterTemplateId) {
        const templateDefaultValue = editable.defaultValues.find(
            defaultValue => defaultValue.masterTemplateId === masterTemplateId
        );

        if (templateDefaultValue) {
            return templateDefaultValue.value;
        }

        return null;
    },

    getGroupPlaceholder(editable, masterTemplateIds, defaultLabel = "") {
        if (editable.type !== EditableType.Text) {
            return defaultLabel;
        }

        const sample = this.getDefaultValue(editable, masterTemplateIds[0]);
        const sameDefaults = !masterTemplateIds.some(masterTemplateId => {
            const val = this.getDefaultValue(editable, masterTemplateId);
            return val !== sample;
        });

        return sameDefaults ? sample : defaultLabel;
    },

    getEditableValue(editable, overwriteValueObject, templateId, masterTemplates = {}) {
        const isFolderMediaItem = () => editable.type === EditableType.Folder && overwriteValueObject.mediaItem;

        if (EditableMediaTypes.includes(editable.type)) {
            if (isFolderMediaItem()) {
                return `/${overwriteValueObject.mediaItem.persistentKey}/`;
            }

            if (getObjectValue(overwriteValueObject, "mediaItem.resizeSettings")) {
                return getMediaItemResizedUrl(editable, overwriteValueObject.mediaItem, masterTemplates[templateId]);
            }

            return getObjectValue(overwriteValueObject, "mediaItem.url");
        }

        return getObjectValue(overwriteValueObject, "value");
    },

    getDefaultSettings(editable, templateId) {
        const layers = editable.layers || [];
        const templateDefaultLayer = layers.find(({ masterTemplateId }) => masterTemplateId === templateId);
        const hasLayer = templateDefaultLayer && templateDefaultLayer.value;
        const isTextLayer = hasLayer && templateDefaultLayer.value.type === "textLayer";

        const setVisibility = () => {
            if (!hasLayer) {
                return {};
            }
            // Set visibility
            return { visible: templateDefaultLayer.value.visible !== false };
        };

        const setFontStyles = () => {
            if (!isTextLayer) {
                return {};
            }
            // For now we're only using the first styles object found
            const [characterStyles] = templateDefaultLayer.value.text.characterStyles;
            // Return if not found
            if (!characterStyles) {
                return {};
            }
            // Extract values
            let hexFontColor = "";
            const { fontColor, fontSize, fontFamily } = characterStyles;
            // Convert default rgb to hex
            if (fontColor && fontColor.rgb) {
                const { red, green, blue } = fontColor.rgb;
                hexFontColor = rgbToHex(red, green, blue, true);
            }
            // Set default values
            return {
                ...(fontFamily && { fontFamily }),
                ...(fontSize && { fontSize }),
                ...(hexFontColor && { fontColor: hexFontColor })
            };
        };

        const setPosition = () => {
            if (!hasLayer || !templateDefaultLayer.value.bounds) {
                return {};
            }

            return deepClone(templateDefaultLayer.value.bounds);
        };

        return {
            ...setVisibility(),
            ...setFontStyles(),
            ...setPosition()
        };
    },

    getEditableSettings(overwriteValueObject) {
        return getObjectValue(overwriteValueObject, "settings");
    },

    getEditableOverwriteHash(editableOverwrite) {
        return md5(JSON.stringify(editableOverwrite));
    },

    async getImageDimensionsByDefaultValueFromEditablesWithOverwrites(editablesWithOverwritesByCampaign) {
        const imageDefaultValues = editablesWithOverwritesByCampaign.reduce((acc, editablesWithOverwrites) => {
            const { type, defaultValues } = editablesWithOverwrites.editable;
            if (EditableImageTypes.includes(type)) {
                if (defaultValues.dimensions && defaultValues.dimensions.width && defaultValues.dimensions.height) {
                    return acc;
                }

                return [...acc, ...defaultValues.map(({ value }) => value)];
            }
            return acc;
        }, []);
        const imageDefaultValuesWithDimensions = await Promise.all(
            imageDefaultValues.map(async imageDefaultValue => {
                try {
                    const { height, width } = await getImageDimensionsFromUrl(imageDefaultValue);
                    return {
                        defaultValue: imageDefaultValue,
                        dimensions: {
                            height,
                            width
                        }
                    };
                } catch (err) {
                    return null;
                }
            })
        );
        const imageDimensionsByDefaultValue = imageDefaultValuesWithDimensions.reduce(
            (acc, imageDefaultValueWithDimensions) => {
                if (imageDefaultValueWithDimensions) {
                    const { defaultValue, dimensions } = imageDefaultValueWithDimensions;
                    /*
                    We use the URL without query params as the query params for an
                    image can change over time (specifically the access keys),
                    while a user is using the editor which would lead to images
                    appearing to not be resized due to dimensions not existing
                    for the image.
                */
                    acc[getUrlWithoutQueryParams(defaultValue)] = dimensions;
                }
                return acc;
            },
            {}
        );
        return imageDimensionsByDefaultValue;
    },

    getEditablesWithOverwritesByCampaignWithDefaultImageDimensions(
        editablesWithOverwritesByCampaign,
        imageDimensionsByDefaultValue
    ) {
        return editablesWithOverwritesByCampaign.map(editablesWithOverwrites => {
            if (EditableImageTypes.includes(editablesWithOverwrites.editable.type)) {
                const defaultValuesWithImageDimensions = editablesWithOverwrites.editable.defaultValues.map(
                    defaultValue => {
                        if (defaultValue.dimensions) {
                            return defaultValue;
                        }

                        const dimensions = imageDimensionsByDefaultValue[getUrlWithoutQueryParams(defaultValue.value)];

                        if (dimensions) {
                            return {
                                ...defaultValue,
                                dimensions
                            };
                        }

                        return defaultValue;
                    }
                );
                return {
                    ...editablesWithOverwrites,
                    editable: {
                        ...editablesWithOverwrites.editable,
                        defaultValues: defaultValuesWithImageDimensions
                    }
                };
            }
            return editablesWithOverwrites;
        });
    },

    getEditableGroupsByCampaignWithDefaultImageDimensions(editableGroupsByCampaign, imageDimensionsByDefaultValue) {
        return editableGroupsByCampaign.map(editableGroup => {
            const editablesWithDefaultImageDimenions = editableGroup.editables.map(editable => {
                if (EditableImageTypes.includes(editable.type)) {
                    const defaultValuesWithImageDimensions = editable.defaultValues.map(defaultValue => {
                        if (defaultValue.dimensions) {
                            return defaultValue;
                        }

                        const dimensions = imageDimensionsByDefaultValue[getUrlWithoutQueryParams(defaultValue.value)];

                        if (dimensions) {
                            return {
                                ...defaultValue,
                                dimensions
                            };
                        }

                        return defaultValue;
                    });
                    return {
                        ...editable,
                        defaultValues: defaultValuesWithImageDimensions
                    };
                }
                return editable;
            });
            return {
                ...editableGroup,
                editables: editablesWithDefaultImageDimenions
            };
        });
    },

    mergeEditables(base, extension) {
        const baseEditableMap = base.reduce((acc, cur) => {
            // We must make a new array of overwrites here so the merge does not end up in the base
            acc[cur.editable._id] = { ...cur, overwrites: [...cur.overwrites] };
            return acc;
        }, {});

        const merged = extension.reduce((acc, extensionEditable) => {
            const baseEditable = acc[extensionEditable.editable._id];
            if (!baseEditable) {
                acc[extensionEditable.editable._id] = {
                    editable: extensionEditable.editable,
                    overwrites: extensionEditable.overwrites
                };
                return acc;
            }

            acc[extensionEditable.editable._id].overwrites = mergeOverwrites(
                baseEditable.overwrites,
                extensionEditable.overwrites
            );
            return acc;
        }, baseEditableMap);

        return Object.values(merged);
    },

    setScopeValues(scope, updatedEditable) {
        const scopedEditable = deepClone(updatedEditable);
        // Remove properties not required based on scopre
        if (scope === OverwriteScope.Campaign) {
            delete scopedEditable.editableGroupValueIds;
            delete scopedEditable.masterTemplateId;
        } else if (scope === OverwriteScope.Template) {
            delete scopedEditable.editableGroupValueIds;
        } else if (scope === OverwriteScope.EditableGroup) {
            delete scopedEditable.masterTemplateId;
        }
        // Get hash of scope, must be done before added values otherwise it will always be a different hash
        scopedEditable.hash = this.getEditableOverwriteHash(scopedEditable);
        // Return scoped editable
        return scopedEditable;
    }
};
