<template>
    <div class="creative-insights-chart scatter-chart">
        <div ref="boundaryElement" class="creative-insights-chart__chart-wrapper">
            <canvas ref="chart" class="horizontal-bar-chart__chart" :style="canvasStyle" />
            <div ref="popperAnchor" class="creative-insights-chart__tooltip" :style="tooltipStyle"></div>
        </div>
        <chart-tooltip ref="tooltip" :point-labels="tooltipLines" :image-urls="tooltipImageUrls" />
    </div>
</template>

<script>
import {
    Chart,
    PointElement,
    BarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    Legend,
    Title,
    Tooltip,
    SubTitle
} from "chart.js";

import scatterChartMixin from "@/components/CreativeIntelligence/ScatterChart.mixin";
import chartTooltipMixin from "@/mixins/chartTooltipMixin";
import ChartTooltip from "@/components/CreativeIntelligence/ChartTooltip";

Chart.register(
    BarController,
    CategoryScale,
    Legend,
    LinearScale,
    PointElement,
    ScatterController,
    SubTitle,
    Title,
    Tooltip
);

export default {
    name: "ScatterChart",
    components: { ChartTooltip },
    mixins: [scatterChartMixin, chartTooltipMixin],

    data() {
        return {
            chart: undefined
        };
    },

    computed: {},

    watch: {
        data() {
            this.chart.data.datasets = this.datasets;
            this.chart.data.labels = this.labels;
            this.chart.options.scales = this.scales;
            this.chart.update();
        }
    },

    mounted() {
        this.initChart();
    },

    methods: {
        initChart() {
            Chart.defaults.elements.point.radius = 6;
            Chart.defaults.elements.point.hoverRadius = 7;

            this.chart = new Chart(this.$refs.chart, {
                data: {
                    labels: this.labels,
                    datasets: this.datasets
                },

                options: {
                    scales: this.scales,
                    plugins: {
                        legend: {
                            display: false,
                            align: "end"
                        },
                        title: {
                            display: true,
                            align: "start",
                            text: this.title
                        },
                        tooltip: {
                            // Disable the on-canvas tooltip
                            enabled: false,
                            external: this.customTooltip.bind(this),
                            callbacks: {
                                label: ({ dataset, dataIndex }) => {
                                    return dataset.labels[dataIndex];
                                }
                            }
                        }
                    }
                },
                plugins: this.plugins,
                type: "scatter"
            });
        }
    }
};
</script>
