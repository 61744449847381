import hexToRgb from "./hexToRgb";

const setVisible = (settings, layer) => {
    if (settings.visible !== undefined) {
        Object.assign(layer, { visible: settings.visible });
    }
};

const setCharacterStyles = (settings, layer) => {
    if (!layer.text) {
        return;
    }
    // The characterStyles property for text layer is an array, for now we're just applying the
    // styles to all objects in the array if there are more than one until we have an example to test with
    const characterStyles = layer.text.characterStyles.map(style => {
        return {
            ...style,
            ...(settings.fontSize && { fontSize: settings.fontSize }),
            ...(settings.fontColor && {
                fontColor: { rgb: hexToRgb(settings.fontColor, true) }
            }),
            ...(settings.fontFamily && {
                fontFamily: {
                    url: settings.fontFamily.signedUrl,
                    ...(settings.fontFamily.postScriptName && { name: settings.fontFamily.postScriptName })
                }
            })
        };
    });

    Object.assign(layer.text.characterStyles, { ...characterStyles });
};

const setBounds = (settings, layer) => {
    Object.assign(layer.bounds, {
        ...(settings.top !== undefined && { top: settings.top }),
        ...(settings.left !== undefined && { left: settings.left }),
        ...(settings.width !== undefined && { width: settings.width }),
        ...(settings.height !== undefined && { height: settings.height })
    });
};

export default (settings, layer) => {
    if (!settings || !layer) {
        return;
    }

    setVisible(settings, layer);
    setCharacterStyles(settings, layer);
    setBounds(settings, layer);
};
