<template>
    <text-stats-chart
        :duration="duration"
        :current-time="currentTime"
        :attributes="textOverlayAttributes"
        :scale-x="scaleX"
        :color="color"
        :ticks-callback="ticksCallback"
        :show-x="false"
    ></text-stats-chart>
</template>

<script>
import TextStatsChart from "@/components/CreativeIntelligence/TextStatsChart";
import { ColorsHEX } from "@/enums/reporting";

// todo extract a common component
// todo disable animations

export default {
    name: "WordCountChart",
    components: { TextStatsChart },
    props: {
        currentTime: {
            type: Number,
            default: 0
        },

        duration: {
            type: [Number, String],
            default: 10
        },

        attributes: {
            type: Array,
            required: true,
            default() {
                return [];
            }
        },

        scaleX: {
            type: Object
        }
    },

    data() {
        return {
            color: ColorsHEX.deviationDown,
            ticksCallback: v => `${v}`
        };
    },

    computed: {
        textOverlayAttributes() {
            return this.attributes.filter(({ type }) => type === "WordCount");
        }
    }
};
</script>
