<template>
    <div class="gpt3cta-preview">
        <campaign-data-provider
            v-if="startSearch"
            :key="searchOptions.searchId + '-' + refreshCounter"
            :options="requestOptions"
            @data="onData"
            @loading="onLoading"
            @error="onError"
        />

        <hox-alert v-if="errorMessage" type="danger" size="small" margin-top="none" margin-bottom="none">
            <template #title>
                <span :title="errorMessage">
                    Sorry, the generated content was rejected by our automated content moderation system.
                </span>
            </template>
            <template #content></template>
            <template #actionItems>
                <Button type="primary" @click="forceRefetch">Try again</Button>
            </template>
        </hox-alert>
        <div v-else>
            <div v-if="isLoading" class="gpt3cta-preview__result-loading">
                <hox-loading-spinner :size="70" icon="wish-icon" />
            </div>
            <div v-else class="gpt3cta-preview__result">
                <div v-for="cta in ctas" :key="cta" class="gpt3cta-preview__overlay">
                    <div class="gpt3cta-preview__cta gpt3cta-preview__result-element">
                        <h4>
                            {{ cta }}
                        </h4>
                        <Button type="primary" @click="onSelect(cta)">Select</Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ModelConfig, ImagineModelToLabel } from "@/enums/imagine";
import CampaignDataProvider from "@/components/Campaign/CampaignDataProvider";

export default {
    name: "Gpt3CtaPreview",
    components: { CampaignDataProvider },
    props: {
        searchOptions: {
            type: Object
        },

        startSearch: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            ctas: undefined,
            data: null,
            errorMessage: "",
            isLoading: true,
            refreshCounter: 0
        };
    },
    computed: {
        requestOptions() {
            const requestOptions = ModelConfig[this.searchOptions.model].fieldsToRequest(this.searchOptions);
            return requestOptions;
        }
    },

    created() {
        this.modelToLabel = ImagineModelToLabel;
    },

    methods: {
        onData(search) {
            this.data = search;
            this.parseResponseText();
            this.$emit("complete");
        },

        onError({ message }) {
            this.errorMessage = message.errorMessage;
            if (typeof message === "string") {
                this.errorMessage = message;
            }
            this.isLoading = false;
            this.$emit("complete");
        },

        onLoading(loading) {
            this.isLoading = loading;
        },

        parseResponseText() {
            const response = this.data.output.success.choices[0].message.content.trim();
            const regex = /"callToAction":\s*"([^"]+)"/g;
            let match;
            const callToActions = [];

            do {
                match = regex.exec(response);
                if (match) {
                    callToActions.push(match[1]);
                }
            } while (match);

            this.ctas = callToActions;
        },

        forceRefetch() {
            this.errorMessage = "";
            this.refreshCounter += 1;
        },

        onSelect(cta) {
            this.$emit("selected", cta);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.gpt3-preview {
    &__image-prompt {
        background: rgba($color: #000000, $alpha: 0.5);
        color: $white;
        opacity: 0;

        &:hover {
            opacity: 1;
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
}
.gpt3cta-preview__cta {
    display: flex;
    align-items: baseline;
    h4 {
        font-weight: 400;
    }
    button {
        margin: 0 0 10px auto;
    }
}
</style>
