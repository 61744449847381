<template>
    <div class="editor-banner-options">
        <master-template-upload-button
            ref="replace"
            :master-template-id="masterTemplate._id"
            class="hidden"
            @updated="update"
            @updateStarted="onUpdateStarted"
            @familyUpdated="familyUpdated"
        ></master-template-upload-button>
        <ul>
            <li v-if="showResize" class="editor-banner-option" @click="onResize">
                <Icon type="md-expand" />
                <span v-if="isResizable">Exit Resize Mode</span>
                <span v-else>Resize Mode</span>
            </li>

            <li v-if="showNewSize" class="editor-banner-option" @click="onNewSize">
                <Icon type="ios-flash" />
                <span>New size...</span>
            </li>

            <li v-if="showReplace" class="editor-banner-option" @click="onReplace">
                <Icon type="md-cloud-upload" />
                <span>Replace Template</span>
            </li>

            <li v-if="showRemove" class="editor-banner-option" @click="onRemove">
                <Icon type="md-trash" />
                <span>Remove from campaign</span>
            </li>
        </ul>
    </div>
</template>
<script>
import MasterTemplateUploadButton from "@/components/Campaign/MasterTemplates/MasterTemplateUploadButton";

export default {
    name: "EditorBannerOptions",
    components: { MasterTemplateUploadButton },

    props: {
        isPsd: {
            default: false,
            type: Boolean
        },

        isResizable: {
            type: Boolean,
            default: false
        },

        masterTemplate: {
            type: Object,
            required: true
        },

        showResize: {
            type: Boolean,
            default: true
        },

        showNewSize: {
            type: Boolean,
            default: true
        },

        showReplace: {
            type: Boolean,
            default: true
        },

        showRemove: {
            type: Boolean,
            default: true
        }
    },

    methods: {
        async onReplace() {
            this.hide();
            this.$refs.replace.remoteClick();
        },

        familyUpdated(members) {
            this.$emit("familyUpdated", members);
            this.hide();
        },

        hide() {
            this.$emit("hide");
        },

        onNewSize() {
            this.hide();
            this.$emit("newSize");
        },

        onRemove() {
            this.hide();
            this.$Modal.confirm({
                title: "Are you sure?",
                content:
                    "Removing this template from this campaign will delete all ads produced with it. This is permanent and can not be undone.",
                okText: "Remove template",
                onOk: () => this.remove(),
                cancelText: "Cancel",
                width: 300
            });
        },

        onResize() {
            this.$emit("resizeMode");
            this.hide();
        },

        onUpdateStarted() {
            this.$emit("updateStarted");
        },

        remove() {
            this.$emit("remove");
        },

        update() {
            this.$emit("update");
            this.hide();
        }
    }
};
</script>
<style lang="scss">
@import "../../../sass/variables";

.editor-banner-option {
    text-align: left;
    height: 33px;
    width: 100%;
    padding: 0 11px;
    line-height: 33px;
    cursor: pointer;
    color: $cmp-dark-font-color;

    span {
        padding-left: $spacing-small;
    }

    &:hover {
        background-color: $cmp-dark-secondary-bg-color;
    }

    .ivu-poptip-body {
        padding: 0;
        border-radius: 0;
    }
}
</style>
