<template>
    <div class="campaign-header__nav">
        <div v-if="clientLogo" class="creative-insights-header__logo">
            <router-link :to="clientLink">
                <img :src="clientLogo" class="creative-insights-header__logo-image" />
            </router-link>
        </div>
        <Header class="campaign-header hoxton-header">
            <Breadcrumb separator="/" class="campaign-header__breadcrumbs">
                <BreadcrumbItem
                    v-for="breadcrumb in breadcrumbs"
                    :key="breadcrumb.label"
                    :class="['campaign-header__breadcrumb', breadcrumb.extraClass]"
                >
                    <template v-if="!breadcrumb.path">
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span class="inactive-link" v-html="breadcrumb.label"></span>
                    </template>
                    <router-link v-else :to="breadcrumb">
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span v-html="breadcrumb.label"></span>
                    </router-link>
                </BreadcrumbItem>
            </Breadcrumb>
        </Header>

        <div class="hoxton-footer">
            <footer-nav theme="light" />
        </div>
    </div>
</template>

<script>
import clientsQuery from "@/apollo/queries/Clients.gql";
import bus from "@/bus";
import { UiAction } from "@/store/modules/ui";
import { getDomain } from "@/utils";
import FooterNav from "@/FooterNav";

export default {
    name: "CampaignHeader",

    components: {
        FooterNav
    },

    data() {
        return {
            userActions: false
        };
    },

    computed: {
        breadcrumbs() {
            return (
                this.$route.matched
                    // eslint-disable-next-line complexity
                    .map(({ meta, path }) => {
                        let label = meta.breadcrumb;
                        let link = path;
                        let extraClass = "";

                        if (label === ":client") {
                            if (this.client._id !== this.clientId) {
                                label = "Client";
                            } else {
                                label = `Client: <strong>${this.client.name}</strong>`;
                            }
                        } else if (label === ":campaign") {
                            if (this.campaign._id !== this.campaignId) {
                                label = "Campaign";
                            } else {
                                label = `Campaign: <strong>${this.campaign.name}</strong>`;
                            }
                            if (getDomain() === "analytics") {
                                extraClass = "campaign-header__breadcrumb--disabled";
                            }
                        }

                        link = link.replace(":clientId", this.clientId);
                        link = link.replace(":campaignId", this.campaignId);

                        if (meta.permission && meta.permission.action) {
                            link = this.$auth.userCan(meta.permission.action, {
                                ...(meta.permission.scope.clientId && { clientId: this.clientId }),
                                ...(meta.permission.scope.campaignId && { campaignId: this.campaignId })
                            })
                                ? link
                                : null;
                        }

                        return {
                            path: link,
                            label,
                            extraClass
                        };
                    })
                    .filter(breadcrumb => breadcrumb.label)
            );
        },
        campaign() {
            return this.$store.state.campaign;
        },
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },
        client() {
            return this.$store.state.campaign.client;
        },
        clientId() {
            return "clientId" in this.$route.params ? this.$route.params.clientId : null;
        },
        clientLink() {
            return {
                name: "CampaignsDashboard",
                params: { clientId: this.clientId }
            };
        },
        clientLogo() {
            return this.$store.state.ui.currentClient && this.$store.state.ui.currentClient.logoUrl;
        }
    },
    methods: {
        setCurrentClient(client) {
            this.$store.dispatch(UiAction.SetCurrentClient, client);
        }
    },
    apollo: {
        clients: {
            query: clientsQuery,
            error(e) {
                bus.$emit("apolloErrorEvent", e);
            },
            result({ data }) {
                if (typeof data.clients === "undefined") {
                    this.$router.push({ name: "UserNotFound" });
                }

                // if user can't do anything in the client screen lets redirect it to the campaign dashboard
                const currentClient = data.clients.find(client => client._id === this.clientId);
                if (!currentClient) {
                    this.$apollo.queries.navigation.refetch();
                    return;
                }

                this.clients = data.clients.map(client => {
                    if (client._id === this.clientId) {
                        this.setCurrentClient(client);
                    }

                    return {
                        ...client,
                        content: client.name
                    };
                });
            }
        }
    }
};
</script>
