<template>
    <div class="resize-settings-template-section">
        <div v-if="showTemplateSpecificSettingsSwitch || showLabel" class="resize-settings-template-section__title-row">
            <h3 v-if="showLabel" class="resize-settings-template-section__label">
                {{ label }}
            </h3>
            <div v-if="showTemplateSpecificSettingsSwitch">
                <labelled-switch :value="hasOwnResizeSettings" @change="value => (hasOwnResizeSettings = value)">
                    Template specific settings
                </labelled-switch>
            </div>
        </div>

        <resize-settings
            v-if="hasOwnResizeSettings"
            :cover.sync="internalResizeSettings.cover"
            :horizontal-align.sync="internalResizeSettings.horizontalAlign"
            :resize-type.sync="internalResizeType"
            :vertical-align.sync="internalResizeSettings.verticalAlign"
            :resize-dimensions="internalResizeDimensions"
            @dimensionChange="onInputChange"
        />

        <div class="resize-settings-template-section__preview-container">
            <image-cropper
                v-if="showManualResizeComponent"
                :image-crop-coordinates="resizeSettings.manualSettings"
                :image-url="previewImageUrl"
                :require-click-to-enable-zoom="internalRequireClickToEnableImageCroppingZoom"
                :viewport-height-pixels="internalResizeDimensions.height"
                :viewport-width-pixels="internalResizeDimensions.width"
                @cropCoordinatesUpdated="setManualSettings"
            />
            <asset-preview v-else :asset-url="resizedPreviewImageUrl" />
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import {
    CoverType,
    HorizontalAlign,
    ResizeType,
    VerticalAlign,
    constructImageBossUrl,
    generateCoverValue
} from "shared-utils/imageBoss";
import LabelledSwitch from "@/components/Campaign/LabelledSwitch";
import ResizeSettings from "@/components/ResizeSettings";
import ImageCropper from "@/components/common/ImageCropper";
import AssetPreview from "@/components/common/AssetPreview";

export default {
    components: {
        ImageCropper,
        AssetPreview,
        LabelledSwitch,
        ResizeSettings
    },
    props: {
        initialHasOwnResizeSettings: {
            type: Boolean
        },
        groupResizeType: {
            validator(value) {
                return Object.values(ResizeType).includes(value);
            }
        },
        groupResizeSettings: {
            type: Object
        },
        label: {
            required: true,
            type: String
        },
        previewImageUrl: {
            required: true,
            type: String
        },
        requireClickToEnableImageCroppingZoom: {
            type: Boolean
        },
        resizeDimensions: {
            required: true,
            validator(value) {
                return value.height !== undefined && value.width !== undefined;
            }
        },
        showLabel: {
            default: true,
            type: Boolean
        },
        showTemplateSpecificSettingsSwitch: {
            default: true,
            type: Boolean
        },
        templateId: {
            required: true,
            type: String
        },
        templateResizeSettings: {
            type: Object
        },
        manualDimensions: {
            type: Object
        }
    },
    data() {
        /*
            The primary reason why we have internalResizeSettings and internalResizeType
            is so that the state persists if the user toggles hasOwnResizeSettings
            off and on again.
        */
        return {
            beforeManualResizeSettings: {},
            hasOwnResizeSettings: this.templateResizeSettings !== undefined,
            hasSetOwnResizeType: false,
            internalResizeDimensions: {},
            internalResizeSettings: this.templateResizeSettings || {},
            internalResizeType: ResizeType.Off,
            previousAutoResizeCoverValue: undefined,
            shouldTakeGroupResizeType: this.templateResizeSettings === undefined
        };
    },
    computed: {
        showManualResizeComponent() {
            return (
                (this.internalResizeType === ResizeType.Manual && this.hasOwnResizeSettings) ||
                this.groupResizeType === ResizeType.Manual
            );
        },
        internalRequireClickToEnableImageCroppingZoom() {
            return this.requireClickToEnableImageCroppingZoom && !this.hasSetOwnResizeType;
        },
        resizedPreviewImageUrl() {
            if (
                this.internalResizeDimensions &&
                this.resizeIsEnabled &&
                (this.resizeSettings.cover || this.resizeSettings.manualSettings)
            ) {
                const cover = generateCoverValue(
                    this.resizeSettings.cover,
                    this.resizeSettings.horizontalAlign,
                    this.resizeSettings.verticalAlign
                );
                return constructImageBossUrl(
                    this.previewImageUrl,
                    cover,
                    this.internalResizeDimensions.width,
                    this.internalResizeDimensions.height,
                    this.resizeSettings.manualSettings
                );
            }
            return this.previewImageUrl;
        },
        resizeIsEnabled() {
            return this.resizeIsEnabledWithGroupSettings || this.resizeIsEnabledWithInternalSettings;
        },
        resizeIsEnabledWithGroupSettings() {
            return this.groupResizeType !== ResizeType.Off && !this.hasOwnResizeSettings;
        },
        resizeIsEnabledWithInternalSettings() {
            return this.internalResizeType !== ResizeType.Off && this.hasOwnResizeSettings;
        },
        resizeSettings() {
            return this.templateResizeSettings || this.groupResizeSettings;
        },

        masterTemplate() {
            return this.$store.state.campaign.normalized.masterTemplates[this.templateId];
        }
    },
    watch: {
        // eslint-disable-next-line complexity
        hasOwnResizeSettings() {
            if (!this.hasOwnResizeSettings) {
                this.$emit("removeTemplateResizeSettings");
            } else {
                /*
                    this.shouldTakeGroupResizeType will be true if this
                    component was initialised without its own template resize
                    settings.

                    So that the image preview doesn't instantly change we want
                    to take the resize type from the group so that the settings
                    we show for the template are the same as the settings we
                    have for the group.
                */
                if (this.shouldTakeGroupResizeType) {
                    this.shouldTakeGroupResizeType = false;
                    this.internalResizeType = this.groupResizeType;
                }
                if (this.internalResizeType === ResizeType.Off) {
                    /*
                        While this should normally be handled by the internalResizeType
                        watcher it's possible that it would never get triggered
                        if internalResizeType is set to the same value it previously
                        was.
                    */
                    this.$emit("setTemplateResizeSettings", {});
                } else {
                    /*
                        This is to ensure that values that the user may have previously
                        set for this template are kept if they toggle hasOwnResizeSettings
                        off and on, falling back to the current groupResizeSettings if no
                        settings have previously been set for the template.
                    */
                    this.internalResizeSettings = {
                        cover: this.internalResizeSettings.cover || this.groupResizeSettings.cover || CoverType.Smart,
                        horizontalAlign:
                            this.internalResizeSettings.horizontalAlign ||
                            this.groupResizeSettings.horizontalAlign ||
                            HorizontalAlign.Center,
                        manualSettings:
                            this.internalResizeSettings.manualSettings ||
                            this.groupResizeSettings.manualSettings ||
                            null,
                        verticalAlign:
                            this.internalResizeSettings.verticalAlign ||
                            this.groupResizeSettings.verticalAlign ||
                            VerticalAlign.Center
                    };
                }
            }
        },
        // eslint-disable-next-line complexity
        internalResizeType(newValue, previousValue) {
            this.hasSetOwnResizeType = true;
            /*
                We want the previous CoverType that was set when internalResizeType was
                ResizeType.Auto to apply when setting the internalResizeType back to
                ResizeType.Auto if we have had another internalResizeType set.
            */
            if (previousValue === ResizeType.Auto) {
                this.previousAutoResizeCoverValue = this.internalResizeSettings.cover;
            }
            if (this.internalResizeType === ResizeType.Off) {
                this.$emit("setTemplateResizeSettings", {});
            } else if (this.internalResizeType === ResizeType.Manual) {
                this.internalResizeSettings = {
                    ...this.internalResizeSettings,
                    cover: CoverType.Manual
                };
            } else {
                this.internalResizeSettings = {
                    ...this.internalResizeSettings,
                    cover: this.previousAutoResizeCoverValue || this.groupResizeSettings.cover || CoverType.Smart
                };
            }
        },
        internalResizeSettings: {
            deep: true,
            handler() {
                this.$emit("setTemplateResizeSettings", { ...this.internalResizeSettings });
            }
        },

        manualDimensions: {
            deep: true,
            handler(val) {
                this.internalResizeDimensions = val;
            }
        }
    },
    // eslint-disable-next-line complexity
    created() {
        this.internalResizeDimensions = this.resizeDimensions;
        if (this.templateResizeSettings !== undefined && this.templateResizeSettings.cover !== undefined) {
            if (this.templateResizeSettings.cover === CoverType.Manual) {
                this.internalResizeType = ResizeType.Manual;
                if (this.resizeSettings.manualSettings) {
                    this.internalResizeDimensions = {
                        width: this.resizeSettings.manualSettings.width,
                        height: this.resizeSettings.manualSettings.height
                    };
                }
            } else {
                this.internalResizeType = ResizeType.Auto;
                this.previousAutoResizeCoverValue = this.templateResizeSettings.cover;
            }
        }
        if (this.initialHasOwnResizeSettings && this.showTemplateSpecificSettingsSwitch) {
            /*
                If we are only showing resize settings for a single template then
                we want to default to showing the resize settings rather requiring
                the user to toggle "has own resize settings".
            */
            this.hasOwnResizeSettings = true;
        }
    },
    methods: {
        onInputChange(manualDimensions) {
            this.internalResizeDimensions = manualDimensions;
        },

        setManualSettings(imageCropCoordinates) {
            const path = new URL(this.previewImageUrl).pathname.replace(/^\//, "");
            this.internalResizeSettings = {
                ...this.internalResizeSettings,
                manualSettings: { path, ...this.internalResizeDimensions, ...imageCropCoordinates }
            };
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.resize-settings-template-section {
    border-bottom: 1px solid $grey3;
    margin: 0 0 $spacing;
    padding: 0 0 $spacing;

    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }

    .new-size-modal__form {
        display: flex;
        justify-content: flex-end;
    }
}

.resize-settings-template-section__label {
    font-size: $font-size-large;
    font-weight: bold;
}

.resize-settings-template-section__preview-container {
    height: 240px;
}

.resize-settings-template-section__title-row {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 0 $spacing;
}
</style>
