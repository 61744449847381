<template>
    <div />
</template>

<script>
import { postFetcher } from "@/components/Reporting/data/utils";
import { Bucket } from "@/components/Reporting/data/constants";
import { InsightType, InsightTypeToServiceName } from "@/components/CreativeIntelligence/constants";
import { CreativeInsightsGetters } from "@/store/modules/creativeInsights";
import Vue from "vue";

export default {
    name: "InsightsDataProvider",

    props: {
        advancedSearch: {
            type: String,
            default: ""
        },

        fetchAll: {
            type: Boolean,
            default: false
        },

        insightsType: {
            type: String
        },

        nextPageToken: {
            type: String,
            default: ""
        },

        refreshCounter: {
            type: Number,
            default: 0
        },

        requestOptions: {
            type: Object,
            default() {
                return {};
            }
        },

        sortBy: {
            type: Object
        }
    },

    data() {
        return {
            isLoading: false,
            abortController: new AbortController(),
            activeRequests: {}
        };
    },

    computed: {
        apiDiscardThresholds() {
            return this.$store.getters[CreativeInsightsGetters.ApiDiscardThresholds];
        },

        baseOptions() {
            return {
                workerIds: this.workerId.split(",")
            };
        },

        confidenceMetric() {
            return this.$store.getters[CreativeInsightsGetters.ConfidenceMetricName];
        },

        endpointParams() {
            return {
                ...this.getRequestOptionsByType(this.insightsType),
                ...(this.nextPageToken && { nextPageToken: this.nextPageToken })
            };
        },

        fetchNextEndpointParams() {
            return {
                ...this.getRequestOptionsByType(this.insightsType),
                nextPageToken: this.nextPageToken
            };
        },

        kpi() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricName];
        },

        kpiMetricsValue() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricValue];
        },

        queryEndpoint() {
            const serviceName = InsightTypeToServiceName[this.insightsType];

            if (!serviceName) {
                return "insights/key-takeaway";
            }

            return `insights/${serviceName}`;
        },

        selectedDynamicBreakdowns() {
            return this.$store.state.creativeInsights.selectedDynamicBreakdowns;
        },

        dynamicBreakdownsFilter() {
            return this.$store.getters[CreativeInsightsGetters.DynamicBreakdownsFilter];
        },

        workerId() {
            return this.$store.state.creativeInsights.workerId;
        }
    },

    watch: {
        advancedSearch() {
            this.fetch();
        },

        apiDiscardThresholds() {
            this.fetch();
        },

        selectedDynamicBreakdowns() {
            if (this.insightsType !== InsightType.CampaignInfo) {
                this.fetch();
            }
        },

        confidenceMetric() {
            this.fetch();
        },

        insightsType: {
            immediate: true,
            handler() {
                this.createAbortController();
                this.fetch();
            }
        },

        kpi() {
            this.fetch();
        },

        sortBy() {
            this.fetch();
        },

        nextPageToken() {
            this.fetchNext();
        },

        refreshCounter() {
            this.fetch();
        },

        requestOptions: {
            handler() {
                this.fetch();
            },
            deep: true
        },

        workerId() {
            this.fetch();
        }
    },

    created() {
        this.instance = Math.random().toString(36).slice(2);
    },

    mounted() {
        this.fetch();
    },

    methods: {
        abortPreviousRequests() {
            if (Object.keys(this.activeRequests).length) {
                // if there are older requests just abort them
                Object.keys(this.activeRequests).forEach(key => {
                    this.activeRequests[key].abort();
                    this.$emit("aborted");
                    Vue.delete(this.activeRequests, key);
                });
            }

            if (this.abortController.signal.aborted) {
                // in order to be able to make another request we need a new abortController.
                this.createAbortController();
            }
        },

        createAbortController() {
            this.abortController = new AbortController();
        },

        getRequestOptionsByType(insightType) {
            const baseOptions = {
                kpiMetric: this.kpi,
                kpiBenchmarkValue: +this.kpiMetricsValue,
                confidenceMetric: this.confidenceMetric,
                sortBy: this.sortBy,
                storageBucket: Bucket,
                topDeliverablesNumber: 1,
                topUniqueCreativesNumber: 1,
                ...this.baseOptions,
                ...(this.advancedSearch && { advancedSearch: this.advancedSearch }),
                ...(this.apiDiscardThresholds.length && { discardThresholds: this.apiDiscardThresholds }),
                dynamicBreakdowns: this.dynamicBreakdownsFilter,
                ...this.requestOptions
            };

            switch (insightType) {
                case InsightType.ContextAnalysis:
                    return {
                        ...baseOptions,
                        sampleSizePercentage: 10
                    };

                case InsightType.KeyTakeawayNotMatching:
                    return {
                        ...baseOptions,
                        notMatchQuery: true
                    };

                default:
                    return baseOptions;
            }
        },

        async fetch() {
            if (!this.workerId) {
                return;
            }
            try {
                this.abortPreviousRequests();
                this.isLoading = true;
                this.$emit("loading", this.isLoading);

                const { signal } = this.abortController;
                Vue.set(this.activeRequests, this.endpointParams, this.abortController);
                const response = await postFetcher(this.queryEndpoint, this.endpointParams, { signal });

                if (response.ok) {
                    const responseJson = await response.json();
                    this.$emit("data", {
                        ...responseJson,
                        requestType: this.insightsType,
                        currentPageToken: this.nextPageToken
                    });

                    this.doFetchAll(responseJson);
                } else {
                    this.$emit("error", { response });
                }
                this.isLoading = false;
                this.$emit("loading", this.isLoading);
            } catch (e) {
                if (e.name === "AbortError") {
                    return;
                }
                this.$emit("error", e);
                this.isLoading = false;
                this.$emit("loading", this.isLoading);
                throw e;
            }
        },

        async fetchNext(requestParams) {
            const options = requestParams || this.fetchNextEndpointParams;

            try {
                this.isLoading = true;
                this.$emit("loadingNext", this.isLoading);
                const { signal } = this.abortController;

                const response = await postFetcher(this.queryEndpoint, options, {
                    signal
                });
                const responseJson = await response.json();

                this.$emit("next", {
                    ...responseJson,
                    requestType: this.insightsType,
                    currentPageToken: this.nextPageToken
                });
                await this.doFetchAll(responseJson);
            } catch (e) {
                this.$emit("error", e);
            } finally {
                this.isLoading = false;
                this.$emit("loadingNext", this.isLoading);
            }
        },

        async doFetchAll(responseJson) {
            // this will have to be better incorporated with the loading state once we move to react
            if (this.fetchAll && responseJson.pagination) {
                if (!responseJson.pagination.nextPageToken) {
                    this.$emit("fetchAllComplete");
                    return;
                }

                await this.fetchNext({
                    ...this.fetchNextEndpointParams,
                    topAttributesValuesNumber: 100,
                    nextPageToken: responseJson.pagination.nextPageToken
                });
            } else {
                this.$emit("fetchAllComplete");
            }
        }
    }
};
</script>
