<template>
    <div class="media-library__client-select">
        <div class="media-library__client-select-title">
            <h3>Which client would you like to see the assets for?</h3>
        </div>
        <div class="media-library__client-select-grid">
            <a
                v-for="client in clients"
                :key="client._id"
                class="media-library__client-select-grid-card"
                @click.capture.stop.prevent="selectClient(client)"
            >
                <client-card
                    :id="client._id"
                    :logo="client.logoUrl"
                    :name="client.name"
                    :title="client.name"
                    :show-menu="false"
                />
            </a>
        </div>
    </div>
</template>

<script>
import clientsQuery from "@/apollo/queries/Clients.gql";
import ClientCard from "@/components/ClientCard";
import { defaultClientSortBy } from "@/components/SortClients";

export default {
    components: {
        ClientCard
    },

    data() {
        return {
            clients: []
        };
    },

    methods: {
        selectClient(client) {
            this.$emit("client-selected", client);
        }
    },

    apollo: {
        clients: {
            query: clientsQuery,

            variables() {
                return {
                    orderBy: defaultClientSortBy
                };
            },

            result({ data: { clients } }) {
                this.clients = clients;
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../sass/_variables";

.media-library__client-select {
    position: relative;

    &-title {
        margin-bottom: $spacing-base;
    }

    &-grid {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        &-card {
            width: 25%;
            padding: 0 $spacing-base;

            &:nth-child(1n) {
                padding-left: 0;
            }

            &:nth-child(4n) {
                padding-right: 0;
            }
        }
    }
}
</style>
