<template>
    <Form
        ref="signalForm"
        class="account-signal__form"
        :model="localData"
        @submit.native.prevent="onSubmitAccountSignal"
    >
        <span class="account-signal__info account-signal__info--first">which triggers on</span>
        <DatePicker
            v-model="localData.dtstart"
            type="date"
            placeholder="Start date"
            data-testid="signal-modal__start-date"
            @on-change="handleStartDateChange"
        ></DatePicker>
        <span class="account-signal__info">at</span>
        <TimePicker
            v-model="selectedTime.startTime"
            format="HH:mm"
            placeholder="Start time"
            data-testid="signal-modal__start-time"
            class="account-signal__time-picker"
            @on-change="handleTimeChange($event, 'startTime')"
        ></TimePicker>
        <span class="account-signal__info">and ends on</span>
        <DatePicker
            v-model="localData.until"
            type="date"
            placeholder="End date"
            data-testid="signal-modal__end-date"
            @on-change="handleEndDateChange"
        ></DatePicker>
        <span class="account-signal__info">at</span>
        <TimePicker
            v-model="selectedTime.endTime"
            format="HH:mm"
            placeholder="End time"
            data-testid="signal-modal__end-time"
            class="account-signal__time-picker"
            @on-change="handleTimeChange($event, 'endTime')"
        ></TimePicker>
        <span class="account-signal__info">accross these</span>
        <Dropdown
            placement="bottom-start"
            trigger="custom"
            :visible="dropdownIsVisible"
            data-testid="signal-modal__weekdays-dropdown"
            class="account-signal__weekdays-dropdown"
            @on-clickoutside="toggleDropdown"
        >
            <hox-dropdown-button
                :is-active="dropdownIsVisible"
                data-testid="library__visibility-select"
                @click="toggleDropdown"
            >
                <slot>Select days</slot>
            </hox-dropdown-button>
            <template #list>
                <CheckboxGroup v-model="localData.byweekday" class="editable-group__values" :vertical="true">
                    <Checkbox v-for="weekDay in weekDays" :key="weekDay.value" :label="weekDay.value">
                        {{ weekDay.label }}
                    </Checkbox>
                </CheckboxGroup>
            </template>
        </Dropdown>
        <span class="account-signal__info">in this</span>
        <Select
            ref="tzid"
            v-model="localData.tzid"
            label="Select Timezone"
            prop="tzid"
            data-testid="signal-modal__tzid"
        >
            <Option v-for="timezone in timezones" :key="timezone.label" :value="timezone.value">
                {{ timezone.label }}
            </Option>
        </Select>
    </Form>
</template>
<script>
import { RRule, rrulestr } from "rrule";
import { weekDays, months, timezones } from "@/enums/activate";

export default {
    name: "DatetimeSignalForm",
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        signalAction: {
            type: String,
            default: "add"
        },
        accountIndex: {
            type: Number,
            default: 0
        },
        ruleIndex: {
            type: Number,
            default: 0
        },
        signalIndex: {
            type: Number,
            default: 0
        },
        formData: {
            type: Object
        }
    },
    data() {
        return {
            weekDays,
            months,
            timezones,
            localData: {},
            dropdownIsVisible: false,
            selectedTime: {
                startDate: "",
                startTime: "00:00",
                endDate: "",
                endTime: "00:00"
            }
        };
    },
    computed: {
        actionLabel() {
            if (this.signalAction === "edit") {
                return "Edit signal";
            }
            return "Add signal";
        }
    },
    watch: {
        formData: {
            deep: true,
            handler(newVal) {
                if (!newVal || !newVal.value) {
                    return;
                }
                const editedRule = rrulestr(newVal.value);
                this.localData = {
                    ...this.localData,
                    ...newVal,
                    ...editedRule.options,
                    byweekday: editedRule.options.byweekday ?? [],
                    dtstart: this.updateDateToUtc(editedRule.options.dtstart, "decode")
                };

                if (this.localData.until) {
                    this.localData.until = this.updateDateToUtc(editedRule.options.until, "decode");
                    const endDateComponents = {
                        year: this.localData.until.getFullYear(),
                        month: String(this.localData.until.getMonth() + 1).padStart(2, "0"),
                        day: String(this.localData.until.getDate()).padStart(2, "0"),
                        hours: String(this.localData.until.getHours()).padStart(2, "0"),
                        minutes: String(this.localData.until.getMinutes()).padStart(2, "0")
                    };

                    this.selectedTime.endDate = `${endDateComponents.year}-${endDateComponents.month}-${endDateComponents.day}`;
                    this.selectedTime.endTime = `${endDateComponents.hours}:${endDateComponents.minutes}`;
                }

                const startDateComponents = {
                    year: this.localData.dtstart.getFullYear(),
                    month: String(this.localData.dtstart.getMonth() + 1).padStart(2, "0"),
                    day: String(this.localData.dtstart.getDate()).padStart(2, "0"),
                    hours: String(this.localData.dtstart.getHours()).padStart(2, "0"),
                    minutes: String(this.localData.dtstart.getMinutes()).padStart(2, "0")
                };

                this.selectedTime.startDate = `${startDateComponents.year}-${startDateComponents.month}-${startDateComponents.day}`;
                this.selectedTime.startTime = `${startDateComponents.hours}:${startDateComponents.minutes}`;
            },
            immediate: true
        },
        localData: {
            deep: true,
            handler() {
                this.onSubmitAccountSignal();
            }
        },
        selectedTime: {
            deep: true,
            handler(selectedTime) {
                const startDateTime = new Date(`${selectedTime.startDate}T${selectedTime.startTime}:00`);
                const endDateTime = new Date(`${selectedTime.endDate}T${selectedTime.endTime}:00`);
                if (endDateTime < startDateTime) {
                    this.$snackbar.info("The start date is after the end date. Please check the dates and times.");
                }

                this.onSubmitAccountSignal();
            }
        }
    },
    mounted() {
        if (!this.localData.value) {
            this.localData.byweekday = [0, 1, 2, 3, 4, 5, 6];
        }
    },
    methods: {
        close() {
            this.$emit("close");
        },

        updateDateToUtc(date, type) {
            const userTimezoneOffset = date.getTimezoneOffset() * 60000;
            if (type === "encode") {
                return new Date(date.getTime() - userTimezoneOffset);
            }
            return new Date(date.getTime() + userTimezoneOffset);
        },

        getTodayDate(type) {
            if (type === "date") {
                const today = new Date();

                const year = today.getFullYear();
                const month = (today.getMonth() + 1).toString().padStart(2, "0");
                const day = today.getDate().toString().padStart(2, "0");

                const formattedDate = `${year}-${month}-${day}`;

                return formattedDate;
            }
            return new Date();
        },

        getStartDate() {
            let startDate;
            const { startTime, startDate: selectedDate } = this.selectedTime;

            if (startTime) {
                const date = selectedDate || this.getTodayDate("date");
                startDate = this.updateDateToUtc(new Date(`${date} ${startTime}`), "encode");
            } else {
                const date = selectedDate ? `${selectedDate} 00:00` : new Date();
                startDate = this.updateDateToUtc(new Date(date), "encode");
            }

            return startDate;
        },

        getEndDate() {
            const { endDate: selectedEndDate, endTime } = this.selectedTime;

            if (selectedEndDate) {
                const time = endTime || "00:00";
                const endDate = this.updateDateToUtc(new Date(`${selectedEndDate} ${time}`), "encode");
                return endDate;
            }

            return null;
        },

        generateRule() {
            const { localData } = this;
            const startDate = this.getStartDate();
            const endDate = this.getEndDate();

            const ruleOptions = {
                freq: 3,
                byweekday: localData.byweekday ?? [],
                tzid: localData.tzid,
                dtstart: startDate
            };

            if (endDate) {
                ruleOptions.until = endDate;
            }

            return new RRule(ruleOptions);
        },

        handleStartDateChange(date) {
            this.selectedTime.startDate = date;
        },

        handleEndDateChange(date) {
            this.selectedTime.endDate = date;
        },

        handleTimeChange(time, type) {
            if (type === "startTime") {
                this.selectedTime.startTime = time;
            } else {
                this.selectedTime.endTime = time;
            }
        },

        onSubmitAccountSignal() {
            const rule = this.generateRule();
            this.localData.value = rule.toString();
            this.localData.signalType = "DATETIME";

            this.$emit("update", {
                formData: this.localData,
                accountIndex: this.accountIndex,
                ruleIndex: this.ruleIndex,
                signalIndex: this.signalIndex,
                signalAction: this.signalAction
            });
        },

        toggleDropdown() {
            this.dropdownIsVisible = !this.dropdownIsVisible;
        }
    }
};
</script>
