<template>
    <div class="user-role-list-name">
        <strong>{{ user.id }}</strong>
    </div>
</template>

<script>
export default {
    name: "UserRoleListName",
    props: {
        user: {
            type: Object,
            required: true
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.user-role-list-name {
    &__icon {
        display: inline-block;
        padding: 0 8px 0 0;
    }
}
</style>
