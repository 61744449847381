<template>
    <div :class="classes">
        <div :class="iconContainerClasses">
            <Icon type="ios-download-outline" />
        </div>
        <div class="notification-item__content-container">
            <div :class="headerClasses">
                <div class="notification-item__titles-container">
                    <h3 :class="titleClasses" :title="trimmedTitle">
                        <slot name="title" />
                    </h3>
                    <h4 class="notification-item__subtitle" :title="trimmedSubtitle">
                        <slot name="subtitle" />
                    </h4>
                </div>
                <div class="notification-item__action-items">
                    <div class="notification-item__clear-button-container">
                        <Button size="small" @click="emitClear">Clear</Button>
                    </div>
                    <div v-if="internalShowButton" class="notification-item__button-container">
                        <slot name="button" />
                    </div>
                    <div v-if="showProgressPercentage" class="notification-item__percentage-container">
                        {{ internalProgressPercentage }}%
                    </div>
                    <div v-if="showExpandIcon" :class="expandIconClasses" @click="toggleShowContent">
                        <Icon type="ios-arrow-down" />
                    </div>
                </div>
            </div>
            <hox-progress-bar
                v-if="showProgressBar"
                :is-animated="isInProgress"
                :margin-bottom="progressBarMarginBottom"
                :progress-percentage="internalProgressPercentage"
                :type="progressBarType"
            />
            <div v-if="internalShowErrorMessage" class="notification-item__error-message">
                <slot name="errorMessage" />
            </div>
            <div v-if="showContent" class="notification-item__content">
                <slot name="content" />
            </div>
        </div>
    </div>
</template>

<script>
import { NotificationStatus } from "@/enums/notifications";
import { getObjectValue } from "@/utils";

export default {
    props: {
        showButton: {
            default: undefined,
            type: Boolean
        },

        hasContent: {
            default: undefined,
            type: Boolean
        },

        icon: {
            default: "download",
            validator(value) {
                return ["download", "upload"].includes(value);
            }
        },

        progressPercentage: {
            type: Number
        },

        showClearButton: {
            type: Boolean
        },

        showErrorMessage: {
            default: undefined,
            type: Boolean
        },

        showProgressBar: {
            default: true,
            type: Boolean
        },

        status: {
            default: NotificationStatus.InProgress,
            validator(value) {
                return Object.values(NotificationStatus).includes(value);
            }
        }
    },

    data() {
        return {
            showContent: false
        };
    },
    computed: {
        classes() {
            const classes = ["notification-item"];
            if (this.internalShowErrorMessage || this.showContent) {
                classes.push("notification-item--padding-bottom-small");
            }
            if (this.showClearButton) {
                classes.push("notification-item--show-clear-button");
            }
            return classes;
        },

        expandIconClasses() {
            const classes = ["notification-item__expand-icon-container"];
            if (this.showContent) {
                classes.push("notification-item__expand-icon-container--active");
            }
            return classes;
        },

        hasCompleted() {
            return this.status === NotificationStatus.Completed;
        },

        hasFailed() {
            return this.status === NotificationStatus.Failed;
        },

        hasProgressPercentage() {
            return this.progressPercentage !== undefined;
        },

        headerClasses() {
            const classes = ["notification-item__header"];
            if (!this.showProgressBar) {
                if (this.showContent || this.internalShowErrorMessage) {
                    classes.push("notification-item__header--margin-bottom-smaller");
                } else {
                    classes.push("notification-item__header--margin-bottom-none");
                }
            }
            return classes;
        },

        iconContainerClasses() {
            const classes = ["notification-item__icon-container"];
            if (this.icon === "upload") {
                classes.push("notification-item__icon-container--flipped");
            }
            if (this.hasFailed) {
                classes.push("notification-item__icon-container--color-error");
            } else if (this.hasCompleted) {
                classes.push("notification-item__icon-container--color-success");
            } else if (this.isInProgress) {
                classes.push("notification-item__icon-container--color-primary");
            }
            return classes;
        },

        internalProgressPercentage() {
            if (this.hasProgressPercentage) {
                return Math.round(this.progressPercentage * 100) / 100;
            }
            if (this.isInProgress || this.hasFailed || this.hasCompleted) {
                /*
                    To save us from having to pass in a progressPercentage of 100
                    when the process has completed, failed or is indeterminately in
                    progress we default to having the progress bar be full.
                */
                return 100;
            }
            return undefined;
        },

        isInProgress() {
            return this.status === NotificationStatus.InProgress;
        },

        progressBarMarginBottom() {
            if (this.internalShowErrorMessage || this.showContent) {
                return "small";
            }
            return "none";
        },

        progressBarType() {
            if (this.hasFailed) {
                return "error";
            }
            if (this.hasCompleted) {
                return "success";
            }
            return "in-progress";
        },

        internalShowButton() {
            return this.showButton === false ? false : this.showButton || this.$slots.button !== undefined;
        },

        internalShowErrorMessage() {
            return this.showErrorMessage === false
                ? false
                : this.showErrorMessage || this.$slots.errorMessage !== undefined;
        },

        showExpandIcon() {
            return this.hasContent === false ? false : this.hasContent || this.$slots.content !== undefined;
        },

        showProgressPercentage() {
            return this.hasProgressPercentage;
        },

        titleClasses() {
            const classes = ["notification-item__title"];
            if (this.isInProgress) {
                classes.push("notification-item__title--color-primary");
            } else if (this.hasFailed) {
                classes.push("notification-item__title--color-error");
            } else if (this.hasCompleted) {
                classes.push("notification-item__title--color-success");
            }
            return classes;
        },

        trimmedSubtitle() {
            const subtitleText = getObjectValue(this.$slots, "subtitle.0.text");
            if (subtitleText) {
                return subtitleText.trim();
            }
            return undefined;
        },

        trimmedTitle() {
            const titleText = getObjectValue(this.$slots, "title.0.text");
            if (titleText) {
                return titleText.trim();
            }
            return undefined;
        }
    },

    methods: {
        emitClear() {
            this.$emit("clear");
        },

        toggleShowContent() {
            this.showContent = !this.showContent;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.notification-item {
    display: flex;
    padding: $spacing;
    transition: box-shadow $transition-time-base $transition-function-base;

    .notification-item__clear-button-container {
        display: none;
        margin: 0 0 0 $spacing-small;
    }

    &:hover {
        box-shadow: $box-shadow-base;
    }

    &--padding-bottom-small {
        padding-bottom: $spacing-small;
    }

    &--show-clear-button {
        &:hover {
            .notification-item__clear-button-container {
                display: block;
            }
        }
    }
}

.notification-item__action-items {
    align-items: flex-end;
    display: flex;
    justify-self: flex-end;
}

.notification-item__button-container {
    margin: 0 0 0 $spacing-small;
}

.notification-item__content {
    > p {
        font-size: $font-size-small;
        line-height: $line-height-base;
        padding: 0 0 $spacing-smaller;
    }

    > ul {
        padding: 0 0 0 $spacing-smaller;

        > li {
            font-size: $font-size-small;
            margin: 0 0 0 $spacing-base;
            padding: 0 0 $spacing-smaller;
        }
    }
}

.notification-item__content-container {
    flex: 1;
    overflow: hidden;
    align-self: center;
}

.notification-item__error-message {
    > p,
    li {
        color: $error-color;
        font-size: $font-size-small;
        line-height: $line-height-base;
        padding: 0 0 $spacing-smaller;
    }
}

.notification-item__expand-icon-container {
    align-items: center;
    border: $border-width-base solid $cmp-light-font-color;
    border-radius: 100%;
    color: $cmp-light-font-color;
    cursor: pointer;
    display: flex;
    height: $input-height-small;
    justify-content: center;
    margin: 0 0 0 $spacing-small;
    transition: color, border-color, transform;
    transition-duration: $transition-time-base;
    transition-timing-function: $transition-function-base;
    width: $input-height-small;

    &:hover {
        border-color: $link-hover-color;
        color: $link-hover-color;
    }

    &--active {
        border-color: $link-color;
        color: $link-color;
        transform: rotate(180deg);
    }
}

.notification-item__icon-container {
    margin: 0 $spacing-small 0 0;

    .ivu-icon {
        font-size: 40px;
    }

    &--flipped {
        .ivu-icon {
            transform: rotate(180deg);
        }
    }

    &--color-error {
        color: $error-color;
    }

    &--color-primary {
        color: $primary-color;
    }

    &--color-success {
        color: $success-color;
    }
}

.notification-item__header {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin: 0 0 $spacing-small;

    &--margin-bottom-none {
        margin-bottom: 0;
    }

    &--margin-bottom-smaller {
        margin-bottom: $spacing-smaller;
    }
}

.notification-item__percentage-container {
    color: $cmp-light-font-color;
    font-size: $font-size-small;
    margin: 0 0 0 $spacing-small;
}

.notification-item__subtitle {
    color: $cmp-light-font-color;
    font-size: $font-size-small;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.notification-item__titles-container {
    font-size: $font-size-base;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.notification-item__title {
    font-size: $font-size-base;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--color-primary {
        color: $primary-color;
    }

    &--color-error {
        color: $error-color;
    }

    &--color-success {
        color: $success-color;
    }
}
</style>
