import { imageBossBase } from "../utils/cdn";
import config from "../../../client/src/config";

export const ResizeType = {
    Auto: "auto",
    Manual: "manual",
    Off: "off"
};

export const CoverType = {
    Attention: "cover:attention",
    Directional: "directional",
    Entropy: "cover:entropy",
    Face: "cover:face",
    Manual: "cover",
    Smart: "cover:smart"
};

export const HorizontalAlign = {
    Center: "center",
    East: "east",
    West: "west"
};

export const VerticalAlign = {
    Center: "center",
    North: "north",
    South: "south"
};

export const constructImageBossUrl = (imageUrlToResize, cover, width, height, manualSettings) => {
    let internalHeight = height;
    let internalWidth = width;
    if (manualSettings) {
        internalHeight = height === manualSettings.height ? height : manualSettings.height;
        internalWidth = width === manualSettings.width ? width : manualSettings.width;

        const { bottomRightCoordinates, topLeftCoordinates } = manualSettings;
        const args = JSON.stringify({
            convertArgs: [
                "-crop",
                `${bottomRightCoordinates.x - topLeftCoordinates.x}x${bottomRightCoordinates.y -
                    topLeftCoordinates.y}+${topLeftCoordinates.x}+${topLeftCoordinates.y}`,
                "+repage",
                "-resize",
                `x${internalHeight}`,
                "+repage"
            ],
            imageUrl: imageUrlToResize
        });
        const data = typeof btoa === "undefined" ? Buffer.from(args).toString("base64") : btoa(args);
        const getImageServiceDomain = () =>
            (process && process.env && process.env.RENDERER_SERVICE) || config.appConfig.RENDERER_SERVICE;
        return `${getImageServiceDomain()}/convert?data=${data}`;
    }

    const { host, pathname, search } = new URL(imageUrlToResize);
    const [bucket] = host.split(".");

    return `${imageBossBase}${bucket}/${cover}/${internalWidth}x${internalHeight}${pathname}${search}`;
};

export const generateCoverValue = (cover, horizontalAlign, verticalAlign) => {
    if (cover === CoverType.Directional) {
        if (horizontalAlign === HorizontalAlign.Center && verticalAlign === VerticalAlign.Center) {
            return "cover:center";
        }
        if (horizontalAlign === HorizontalAlign.Center) {
            return `cover:${verticalAlign}`;
        }
        if (verticalAlign === VerticalAlign.Center) {
            return `cover:${horizontalAlign}`;
        }
        return `cover:${verticalAlign}${horizontalAlign}`;
    }
    return cover;
};
