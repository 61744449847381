<template>
    <div class="sections-select">
        <label class="sections-select__label">Section</label>
        <Select :value="value" data-testid="section-select" @on-change="updatedValue => $emit('input', updatedValue)">
            <Option v-for="option in sectionOptions" :key="option.value" :value="option.value">
                {{ option.label }}
            </Option>
        </Select>
    </div>
</template>

<script>
export default {
    props: {
        sections: {
            required: true,
            type: Array
        },

        value: {
            type: [Number, String]
        }
    },
    computed: {
        sectionOptions() {
            return [
                {
                    label: "None",
                    /*
                        Ideally this value would be `null` or `undefined` but the iView
                        select component will not select it as an initial value if we
                        use those values.
                    */
                    value: "none"
                },
                ...this.sections.map(section => ({
                    label: section.name,
                    value: section._id
                }))
            ];
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.sections-select {
    margin: 0 0 $spacing;
}

.sections-select__label {
    display: block;
    margin: 0 0 $spacing-small;
}
</style>
