import assert from "assert";
import { InvalidArgumentError } from "../errors";
import generateKeyValueObject from "./generateKeyValueObject";

const ENTITY_LABELS = {
    ANNOTATION: "Annotation",
    CAMPAIGN: "Campaign",
    CAMPAIGN_SECTION: "Section",
    CLIENT: "Client",
    CLIENT_MEDIA_ITEMS: "Client media items",
    COMMENT: "Comment",
    COMPUTED_DELIVERABLE: "Computed Deliverable",
    REPORT: "Report",
    EDITABLE: "Editable",
    EDITABLE_GROUP_VALUE: "Editable Group Value",
    EDITABLE_OVERWRITE: "Editable Overwrite",
    FONT: "Font",
    JOB: "Job",
    MASTER_TEMPLATE: "Master Template",
    MEDIA_FOLDER: "Folder",
    MEDIA_ITEM: "Media Item",
    METADATA_FIELD: "Metadata field",
    METADATA_VALUE: "Metadata value",
    OTHER_USERS_ANNOTATION: "another user's Annotation",
    OTHER_USERS_COMMENT: "another user's Comment",
    ROLE: "Role",
    SCOPE: "Scope",
    USER: "User"
};

export const getEntity = type => {
    const label = ENTITY_LABELS[type];
    assert(label, new InvalidArgumentError(`${type} is not a valid entity type`));
    return { type, label };
};

export default generateKeyValueObject(ENTITY_LABELS);
