<template>
    <hox-modal
        :show-footer="!isLoadingCampaign || !hasErrorLoadingCampaign"
        data-testid="edit-campaign-modal"
        @close="close"
    >
        <hox-loading-layer v-if="isUpdatingCampaign" />
        <delete-campaign-modal
            v-if="deleteCampaignModalIsVisible"
            :campaign-id="campaign._id"
            :campaign-name="campaign.name"
            @close="toggleDeleteCampaignModalIsVisible"
            @campaignDeleted="deleted"
        />
        <template #header>Edit campaign</template>
        <div v-if="isLoadingCampaign" class="edit-campaign-modal__loading-container">
            <hox-loading-layer :is-full-screen="false" />
        </div>
        <hox-alert v-else-if="hasErrorLoadingCampaign" margin-bottom="none" type="danger">
            <template #title>Uh oh!</template>
            <template #content>
                <p>Something went wrong while getting some data. Try again in a few moments.</p>
            </template>
            <template #actionItems>
                <Button type="primary" @click="refetchCampaign">Retry</Button>
            </template>
        </hox-alert>
        <template v-else>
            <campaign-form
                :external-errors="mutationErrors"
                :initial-form-data="formData"
                :sections="sections"
                show-validation-errors
                :upload-config="uploadConfig"
                @formDataUpdate="setFormData"
                @hasValidationErrorsUpdate="setHasValidationErrors"
                @submit="updateCampaign"
            />
            <div v-if="hasUnexpectedErrorUpdatingCampaign" class="edit-campaign-modal__unexpected-error-wrapper">
                <Alert banner type="error">
                    <template #desc>
                        There was an unexpected error and the campaign was not updated. Please try again in a few
                        moments.
                    </template>
                </Alert>
            </div>
            <template slot="footer">
                <div class="edit-campaign-modal__buttons-container">
                    <Button
                        v-if="userCanDeleteCampaign"
                        ghost
                        type="error"
                        data-testid="edit-campaign-modal__delete-button"
                        @click="toggleDeleteCampaignModalIsVisible"
                    >
                        Delete
                    </Button>
                    <Button
                        type="primary"
                        :disabled="isUpdatingCampaign || hasValidationErrors"
                        data-testid="edit-campaign-modal__save-button"
                        @click="updateCampaign"
                    >
                        Save
                    </Button>
                </div>
            </template>
        </template>
    </hox-modal>
</template>

<script>
import campaignQuery from "@/apollo/queries/v2/Campaign.gql";
import updateCampaignMutation from "@/apollo/mutations/UpdateCampaign.gql";
import CampaignForm from "@/components/CampaignForm";
import DeleteCampaignModal from "@/components/DeleteCampaignModal";
import { groupErrorsByField } from "@/utils";

export default {
    name: "EditCampaignModal",

    components: {
        CampaignForm,
        DeleteCampaignModal
    },

    props: {
        campaignId: {
            required: true,
            type: String
        },

        sections: {
            required: true,
            type: Array
        }
    },

    data() {
        return {
            campaign: undefined,
            deleteCampaignModalIsVisible: false,
            formData: {},
            hasErrorLoadingCampaign: false,
            hasUnexpectedErrorUpdatingCampaign: false,
            hasValidationErrors: false,
            isLoadingCampaign: 0,
            isUpdatingCampaign: false,
            mutationErrors: {},
            uploadConfig: undefined
        };
    },

    computed: {
        canEditCampaign() {
            return this.$auth.userCan(this.$auth.Actions.CanEditCampaign, {
                clientId: this.clientId,
                campaignId: this.campaignId
            });
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        userCanDeleteCampaign() {
            return this.$auth.userCan(this.$auth.Actions.CanRemoveCampaign, {
                campaignId: this.campaignId,
                clientId: this.clientId
            });
        }
    },

    watch: {
        canEditCampaign() {
            if (!this.canEditCampaign) {
                this.$snackbar.warning("You do not have access to edit campaigns");
                this.close();
            }
        },
        // eslint-disable-next-line func-names
        "formData.name": function () {
            this.$delete(this.mutationErrors, "name");
        }
    },

    methods: {
        close() {
            this.$emit("close");
        },

        deleted() {
            this.$emit("deleted");
            this.close();
        },

        refetchCampaign() {
            this.$apollo.queries.campaign.refetch();
        },

        setFormData(formData) {
            this.formData = formData;
        },

        setHasValidationErrors(hasValidationErrors) {
            this.hasValidationErrors = hasValidationErrors;
        },

        toggleDeleteCampaignModalIsVisible() {
            this.deleteCampaignModalIsVisible = !this.deleteCampaignModalIsVisible;
        },

        async updateCampaign() {
            this.isUpdatingCampaign = true;
            try {
                await this.$apollo.mutate({
                    mutation: updateCampaignMutation,
                    variables: {
                        campaignId: this.campaignId,
                        campaign: {
                            name: this.formData.name,
                            jiraTicketUrl: this.formData.jiraTicketUrl,
                            resourceGroupIds: this.formData.resourceGroupIds,
                            masthead: this.formData.content,
                            sectionId: this.formData.sectionId === "none" ? null : this.formData.sectionId,
                            toneOfVoice: this.formData.toneOfVoice,
                            defaultTimelineLength: +this.formData.defaultTimelineLength
                        }
                    }
                });
                this.$snackbar.success("Campaign updated");
                this.$emit("campaignEdited");
                this.close();
            } catch ({ graphQLErrors }) {
                this.mutationErrors = groupErrorsByField(graphQLErrors);
                this.hasUnexpectedErrorUpdatingCampaign = Object.keys(this.mutationErrors).length === 0;
            } finally {
                this.isUpdatingCampaign = false;
            }
        }
    },

    apollo: {
        campaign: {
            query: campaignQuery,
            fetchPolicy: "network-only",
            loadingKey: "isLoadingCampaign",
            variables() {
                return {
                    campaignId: this.campaignId
                };
            },
            result({ data: { campaign }, error }) {
                if (error) {
                    this.hasErrorLoadingCampaign = true;
                    return;
                }
                this.campaign = campaign;
                this.hasErrorLoadingCampaign = false;
                this.uploadConfig = campaign.mastheadUploadConfig;
                console.log("campaign", campaign);

                this.formData = {
                    id: campaign._id,
                    mastheadImageUrl: campaign.mastheadImageUrl,
                    name: campaign.name,
                    jiraTicketUrl: campaign.jiraTicketUrl,
                    resourceGroupIds: campaign.resourceGroupIds,
                    sectionId: campaign.section ? campaign.section._id : "none",
                    toneOfVoice: campaign.toneOfVoice,
                    defaultTimelineLength: campaign.defaultTimelineLength
                };
            }
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/../sass/_variables.scss";

.edit-campaign-modal {
    &__loading-container {
        position: relative;
        height: 300px;
    }

    &__buttons-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__unexpected-error-wrapper {
        padding: $spacing 0 0 0;
    }
}
</style>
