<template>
    <hox-modal :show-footer="campaignCanBeDuplicated" @close="close">
        <hox-loading-layer v-if="isDuplicatingCampaign" />
        <template #header>Duplicate campaign</template>
        <div v-if="isLoadingOriginalCampaign" class="duplicate-campaign-modal__loading-container">
            <hox-loading-layer :is-full-screen="false" />
        </div>
        <hox-alert v-else-if="hasErrorLoadingOriginalCampaign" margin-bottom="none" type="danger">
            <template #title>Uh oh!</template>
            <template #content>
                <p>Something went wrong while getting some data. Try again in a few moments.</p>
            </template>
            <template #actionItems>
                <Button type="primary" @click="$apollo.queries.campaign.refetch">Retry</Button>
            </template>
        </hox-alert>
        <hox-alert v-else-if="masterTemplateOptions.length === 0" margin-top="base" type="warning">
            <template #content>
                <p>The campaign you have selected cannot be duplicated as it has no master templates.</p>
            </template>
        </hox-alert>
        <template v-else>
            <campaign-form
                :external-errors="mutationErrors"
                :initial-form-data="formData"
                :sections="sections"
                show-validation-errors
                @formDataUpdate="setFormData"
                @hasValidationErrorsUpdate="setHasFormValidationErrors"
                @submit="duplicateCampaign"
            />
            <div class="duplicate-campaign-modal__checkbox-group-wrapper">
                <label class="duplicate-campaign-modal__checkbox-group-label">Master Templates</label>
                <CheckboxGroup v-model="selectedMasterTemplates">
                    <Checkbox v-for="option in masterTemplateOptions" :key="option.value" :label="option.value">
                        {{ option.label }}
                    </Checkbox>
                </CheckboxGroup>
                <p
                    v-for="error in validationErrors.selectedMasterTemplates"
                    :key="error"
                    class="duplicate-campaign-modal__checkbox-group-error"
                >
                    {{ error }}
                </p>
            </div>
            <div class="duplicate-campaign-modal__checkbox-group-wrapper">
                <label class="duplicate-campaign-modal__checkbox-group-label">Languages</label>
                <CheckboxGroup v-model="selectedLanguages">
                    <Checkbox
                        v-for="option in languageOptions"
                        :key="option.value"
                        :label="option.value"
                        :disabled="option.value === 'en'"
                    >
                        {{ option.label }}
                        <span v-if="option.value === 'en'">(Required)</span>
                    </Checkbox>
                </CheckboxGroup>
                <p
                    v-for="error in validationErrors.selectedLanguages"
                    :key="error"
                    class="duplicate-campaign-modal__checkbox-group-error"
                >
                    {{ error }}
                </p>
            </div>
            <hox-alert margin-bottom="none" margin-top="large" type="info">
                <template #content>
                    <p>
                        Duplicating a campaign will create a new campaign with the selected master templates and
                        languages, along with all creative content belonging to them.
                    </p>
                    <p>
                        Teams members, annotations, QA status and published status will not be brought over to the new
                        campaign.
                    </p>
                </template>
            </hox-alert>
            <div
                v-if="hasUnexpectedErrorDuplicatingCampaign"
                class="duplicate-campaign-modal__unexpected-error-wrapper"
            >
                <Alert banner type="error">
                    <template #desc>
                        There was an unexpected error and the campaign was not duplicated. Please try again in a few
                        moments.
                    </template>
                </Alert>
            </div>
        </template>
        <template slot="footer">
            <Button
                type="primary"
                :disabled="isDuplicatingCampaign || hasValidationErrors"
                data-testid="duplicate-campaign-modall__button--duplicate"
                @click="duplicateCampaign"
            >
                Duplicate
            </Button>
        </template>
    </hox-modal>
</template>

<script>
import campaignWithLanguagesAndMasterTemplatesQuery from "@/apollo/queries/v2/CampaignWithLanguagesAndMasterTemplates.gql";
import copyCampaignMutation from "@/apollo/mutations/v2/CopyCampaign.gql";
import CampaignForm from "@/components/CampaignForm";
import { generateMasterTemplateFilterOptions, groupErrorsByField, languages } from "@/utils";

export default {
    components: {
        CampaignForm
    },

    props: {
        campaignId: {
            required: true,
            type: String
        },

        sections: {
            required: true,
            type: Array
        }
    },

    data() {
        return {
            formData: {},
            hasErrorLoadingOriginalCampaign: false,
            hasFormValidationErrors: false,
            hasUnexpectedErrorDuplicatingCampaign: false,
            isDuplicatingCampaign: false,
            isLoadingOriginalCampaign: 0,
            languageOptions: [],
            masterTemplateOptions: [],
            mutationErrors: {},
            selectedLanguages: [],
            selectedMasterTemplates: []
        };
    },

    computed: {
        campaignCanBeDuplicated() {
            return this.masterTemplateOptions.length > 0;
        },

        hasValidationErrors() {
            return this.hasFormValidationErrors || Object.keys(this.validationErrors).length > 0;
        },

        validationErrors() {
            const errors = {};
            if (this.selectedLanguages.length === 0) {
                errors.selectedLanguages = ["At least 1 language needs to be selected."];
            }
            if (this.selectedMasterTemplates.length === 0) {
                errors.selectedMasterTemplates = ["At least 1 master template needs to be selected."];
            }
            return errors;
        }
    },

    methods: {
        close() {
            this.$emit("close");
        },

        async duplicateCampaign() {
            if (!this.hasValidationErrors && !this.isDuplicatingCampaign) {
                this.isDuplicatingCampaign = true;
                try {
                    await this.$apollo.mutate({
                        mutation: copyCampaignMutation,
                        variables: {
                            campaignId: this.campaignId,
                            languages: this.selectedLanguages,
                            masterTemplateIds: this.selectedMasterTemplates,
                            name: this.formData.name,
                            jiraTicketUrl: this.formData.jiraTicketUrl,
                            resourceGroupIds: this.formData.resourceGroupIds,
                            sectionId: this.formData.sectionId === "none" ? null : this.formData.sectionId
                        }
                    });
                    this.$snackbar.success("Campaign duplicated");
                    this.$emit("campaignDuplicated");
                    this.close();
                } catch (err) {
                    console.log(err);
                    this.mutationErrors = groupErrorsByField(err.graphQLErrors);
                    this.hasUnexpectedErrorDuplicatingCampaign = Object.keys(this.mutationErrors).length === 0;
                } finally {
                    this.isDuplicatingCampaign = false;
                }
            }
        },

        setFormData(formData) {
            this.formData = formData;
        },

        setHasFormValidationErrors(hasFormValidationErrors) {
            this.hasFormValidationErrors = hasFormValidationErrors;
        }
    },

    apollo: {
        campaign: {
            query: campaignWithLanguagesAndMasterTemplatesQuery,
            fetchPolicy: "network-only",
            loadingKey: "isLoadingOriginalCampaign",
            variables() {
                return {
                    campaignId: this.campaignId
                };
            },
            result({ data: { campaign }, error }) {
                if (error) {
                    this.hasErrorLoadingOriginalCampaign = true;
                    return;
                }
                this.hasErrorLoadingOriginalCampaign = false;
                this.formData = {
                    name: `Copy of ${campaign.name}`,
                    jiraTicketUrl: campaign.jiraTicketUrl,
                    resourceGroupIds: campaign.resourceGroupIds,
                    sectionId: campaign.section ? campaign.section._id : "none"
                };
                this.languageOptions = campaign.languages.map(language => ({
                    label: languages.localeToFriendlyName(language),
                    value: language
                }));
                this.selectedLanguages = [...campaign.languages];
                this.masterTemplateOptions = generateMasterTemplateFilterOptions(campaign.masterTemplates);
                this.selectedMasterTemplates = this.masterTemplateOptions.map(({ value }) => value);
            }
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/../sass/_variables.scss";

.duplicate-campaign-modal {
    &__checkbox-group-error {
        color: $error-color;
        font-size: $font-size-small;
        padding: $spacing-small 0 0;
    }

    &__checkbox-group-wrapper {
        margin: 0 0 $spacing;

        .ivu-checkbox-group-item {
            display: block;
        }

        .ivu-checkbox-wrapper:hover {
            background-color: $cmp-light-filters-hover-color;
        }
    }

    &__checkbox-group-label {
        display: block;
        font-size: $font-size-base;
        padding: 0 0 $spacing-small;
    }

    &__loading-container {
        position: relative;
        height: 300px;
    }

    &__unexpected-error-wrapper {
        padding: $spacing 0 0 0;

        .ivu-alert {
            margin: 0;
        }
    }
}
</style>
