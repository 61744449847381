<template>
    <div class="master-template-select-view-control multiselect-view-control">
        <Button :class="{ active: isActive(MultiviewMode.Grid) }" @click="switchView(MultiviewMode.Grid)">
            <Icon type="md-apps" />
        </Button>
        <Button :class="{ active: isActive(MultiviewMode.List) }" @click="switchView(MultiviewMode.List)">
            <Icon type="md-list" />
        </Button>
    </div>
</template>
<script>
import { MultiviewMode } from "@/enums/multiview";
import { MasterTemplateLibraryAction } from "@/store/modules/masterTemplateLibrary";

export default {
    name: "MasterTemplateSelectViewControl",

    computed: {
        view() {
            return this.$store.state.masterTemplateLibrary.selectViewMode;
        }
    },

    created() {
        this.MultiviewMode = MultiviewMode;
    },

    methods: {
        isActive(view) {
            return view === this.view;
        },

        switchView(view) {
            this.$store.dispatch(MasterTemplateLibraryAction.SetSelectViewMode, view);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.multiselect-view-control {
    display: flex;
    flex-wrap: nowrap;

    .active {
        z-index: 1;
    }

    .ivu-btn:first-child {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        margin-right: -1px;
    }

    .ivu-btn:last-child {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
}

.master-template-select-view-control {
    margin-right: $spacing;
}
</style>
