<template>
    <div>
        <delete-tag-confirmation-modal
            v-if="isConfirmationModalShown"
            @confirm="deleteTag"
            @cancel="hideConfirmationModal"
        />

        <hox-modal v-if="isModalVisible" class="create-tag-modal" @close="closeModal">
            <template #header>
                <template v-if="editMode">Edit search tag</template>
                <template v-else>Save search tag</template>
            </template>
            <div class="create-tag-modal__body">
                <div v-if="editMode" class="create-tag-modal__form">
                    Name
                    <Input ref="name" v-model="tagName" @on-enter="save" />

                    <br />
                    <br />
                    Query
                    <Input v-model="tagAdvancedSearch" @on-enter="save" />
                </div>
                <div v-else class="create-tag-modal__form">
                    Name
                    <Input ref="newTagName" v-model="newTagName" @on-enter="save" />
                </div>
                <div class="create-tag-modal__form-errors">
                    <ul>
                        <li v-for="error in validationErrors" :key="error">{{ error }}</li>
                    </ul>
                </div>
                <div v-if="hasError && !isSaving" class="create-tag-modal__error">
                    <hox-alert type="danger" size="small" align-text="center">
                        <template #content>
                            <p>There was an error when trying to create new size, please try again.</p>
                        </template>
                    </hox-alert>
                </div>
            </div>
            <template #footer>
                <Button
                    v-if="editMode"
                    type="error"
                    class="create-tag-modal__footer-button create-tag-modal__footer-button--delete"
                    @click="showConfirmationModal"
                >
                    Delete
                </Button>

                <Button class="create-tag-modal__footer-button" @click="closeModal">Close</Button>
                <Button
                    class="create-tag-modal__footer-button create-tag-modal__footer-button--save"
                    type="primary"
                    :loading="isSaving"
                    :disabled="isSaving || !!validationErrors.length"
                    @click="save"
                >
                    {{ isSaving ? "Saving..." : "Save" }}
                </Button>
            </template>
        </hox-modal>
    </div>
</template>
<script>
import { TagsAction } from "@/store/modules/ciTags";
import DeleteTagConfirmationModal from "@/components/CreativeIntelligence/DeleteTagConfirmationModal";

export default {
    name: "ManageTagModal",
    components: { DeleteTagConfirmationModal },
    props: {
        isModalVisible: {
            type: Boolean
        },

        query: {
            type: String
        },

        tag: {
            type: Object
        }
    },
    data() {
        return {
            hasError: false,
            isConfirmationModalShown: false,
            newTagName: "",
            tagAdvancedSearch: "",
            tagName: "",
            validationErrors: []
        };
    },

    computed: {
        editMode() {
            return this.tag !== undefined;
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        isSaving() {
            return this.editMode ? this.$store.state.ciTags.isUpdating : this.$store.state.ciTags.isCreating;
        }
    },

    watch: {
        tag: {
            immediate: true,
            deep: true,
            handler(val) {
                if (val) {
                    this.tagName = val.name;
                    this.tagAdvancedSearch = val.advancedSearch;
                }
            }
        }
    },

    mounted() {
        this.focus();
    },

    methods: {
        async createTag() {
            try {
                await this.$store.dispatch(TagsAction.CreateTag, {
                    clientId: this.clientId,
                    name: this.newTagName,
                    query: this.query
                });
                this.closeModal();
                this.$snackbar.success("Search query has been saved");
                this.newTagName = "";
            } catch (e) {
                this.validationErrors = [e.message];
                this.hasError = true;
            }
        },

        async deleteTag() {
            try {
                await this.$store.dispatch(TagsAction.DeleteTag, this.tag);
                this.hideConfirmationModal();
                this.closeModal();
            } catch (e) {
                console.log(e);
            }
        },

        async focus() {
            await this.$nextTick();
            const element = this.editMode ? "name" : "newTagName";
            if (this.$refs[element]) {
                this.$refs[element].focus();
            }
        },

        async updateTag() {
            try {
                await this.$store.dispatch(TagsAction.UpdateTag, {
                    ...this.tag,
                    name: this.tagName,
                    advancedSearch: this.tagAdvancedSearch
                });
                this.closeModal();
                this.$snackbar.success("Search query has been updated");
            } catch (e) {
                this.validationErrors = [e.message];
                this.hasError = true;
            }
        },

        closeModal() {
            this.$emit("update:isModalVisible", false);
        },

        hideConfirmationModal() {
            this.isConfirmationModalShown = false;
        },

        save() {
            if (this.editMode) {
                this.updateTag();
            } else {
                this.createTag();
            }
        },

        showConfirmationModal() {
            this.isConfirmationModalShown = true;
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.create-tag-modal__footer-button {
    &--delete {
        margin-right: auto;
    }

    &--save {
        margin-left: $spacing-semi-small;
    }
}
</style>
