<template>
    <div class="deliverable-value-item visible-row" :class="{ 'visible-row--invisible': !visible }">
        <div v-if="canChangeVisibility" class="visible-row__eye">
            <Icon v-if="visible" type="ios-eye" @click="toggleVisibility" />
            <Icon v-else type="ios-eye-off" @click="toggleVisibility" />
        </div>
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "VisibleRow",
    props: {
        canChangeVisibility: {
            type: Boolean,
            default: true
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        toggleVisibility() {
            this.$emit("updateSettings", { visible: !this.visible });
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.visible-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex: 1 0;

    &--invisible {
        color: $cmp-dark-disabled-color;
    }

    &__eye {
        cursor: pointer;
        text-align: center;
        color: $cmp-dark-font-color;
        border-right: 1px solid $cmp-dark-bg-color;
        padding: 0 10px;
    }
}
</style>
