<template>
    <div :key="creative.reportId" class="hox-drawer-tab timeline-analysis">
        <!--        todo: this need to be updated to the new component-->
        <creative-insights-data-provider
            :report-id="creative.reportId"
            @loading="setLoadingState"
            @data="onApiResponse"
        />

        <hox-loading-layer v-if="isLoadingCreative" :is-full-screen="false" />
        <div v-else class="timeline-analysis__body">
            <div class="timeline-analysis__preview">
                <card class="dashboard-card timeline-analysis__video-card">
                    <video-wrapper
                        ref="video"
                        class="timeline-analysis__video"
                        controls
                        muted
                        @update="onUpdate"
                        @play="setState('playing')"
                        @pause="setState('paused')"
                        @ended="setState('ended')"
                        @canplay.once="onCanPlay"
                        @duration="setDuration"
                    >
                        <source :src="videoUrl" type="video/mp4" />
                    </video-wrapper>
                    <template #title>
                        <heatmap-switch><template #label>&nbsp;</template></heatmap-switch>
                    </template>
                    <template #actionItems>
                        <div class="kpi-performance__results">
                            <div v-if="kpiValue !== null" class="kpi-performance__results-kpi">
                                <div class="kpi-performance__results-kpi-perf">
                                    {{ kpiDisplayValue }}
                                </div>
                                <div class="kpi-performance__results-kpi-name">KPI</div>
                            </div>
                            <div v-if="kpiAvgValue !== null" class="kpi-performance__results-kpi">
                                <div class="kpi-performance__results-kpi-perf">
                                    {{ kpiAvgDisplayValue }}
                                </div>
                                <div class="kpi-performance__results-kpi-name">AVG KPI</div>
                            </div>
                        </div>
                    </template>
                </card>
                <dashboard-card>
                    <hox-loading-layer v-if="!hasAttributes" :is-full-screen="false" />
                    <div v-else class="library-checkbox-group">
                        <CheckboxGroup v-model="filters" class="timeline-analysis__filters">
                            <Checkbox v-for="option in filterOptions" :key="option.value" :label="option.value">
                                {{ AttributeTypeLabels[option.label] || option.label }}
                            </Checkbox>
                        </CheckboxGroup>
                    </div>
                </dashboard-card>
            </div>

            <dashboard-card class="timeline-analysis__chart-card">
                <div class="timeline-analysis__overlays">
                    <div class="timeline-analysis__overlays-playhead" :style="playheadStyle"></div>
                </div>
                <dropoff-chart
                    v-if="!isLoading"
                    :video-metrics="videoMetrics"
                    :duration="duration"
                    :current-time="currentTime"
                    :attributes="attributes"
                    :scale-x="scaleX"
                    @hasMetrics="hasDropoffMetrics = $event"
                />
                <div class="timeline-analysis__scroll-wrapper" :style="scrollWrapperStyle">
                    <text-overlay-chat
                        v-if="!isLoading"
                        :duration="duration"
                        :current-time="currentTime"
                        :attributes="attributes"
                        :scale-x="scaleX"
                        class="timeline-analysis__text-stats-chart"
                    />
                    <word-count-chat
                        v-if="!isLoading"
                        :duration="duration"
                        :current-time="currentTime"
                        :attributes="attributes"
                        :scale-x="scaleX"
                        class="timeline-analysis__text-stats-chart"
                    />
                    <timeline-analysis-chart
                        v-if="hasAttributes"
                        ref="timelineChart"
                        class="timeline-analysis__chart"
                        :attributes="chartAttributes"
                        :duration="duration"
                        :current-time="currentTime"
                        :scale-x="scaleX"
                    />
                </div>
            </dashboard-card>
        </div>

        <div class="timeline-analysis__footer">
            <square-button has-inverse-hover @click="playPauseVideo">
                <Icon :type="playPauseReplayIcon" />
            </square-button>
            <div class="timeline-analysis__scrubbar-container">
                <banner-scrubbar :value="currentTime" :duration="duration" @change="seek" @dragging="onDragging" />
            </div>
        </div>
    </div>
</template>
<script>
import TimelineAnalysisChart from "@/components/CreativeIntelligence/TimelineAnalysisChart";
import VideoWrapper from "@/views/Campaign/VideoWrapper";
import Card from "@/components/Card/Card";
import SquareButton from "@/components/common/SquareButton";
import BannerScrubbar from "@/components/Previews/BannerScrubbar";
import DashboardCard from "@/components/Reporting/DashboardCard";
import { AttributeTypeLabels, AttributeTypes, ReportKPI } from "@/enums/reporting";
import DropoffChart from "@/components/CreativeIntelligence/DropoffChart";
import CreativeInsightsDataProvider from "@/components/Reporting/data/CreativeInsightsDataProvider";
import TextOverlayChat from "@/components/CreativeIntelligence/TextOverlayChart";
import WordCountChat from "@/components/CreativeIntelligence/WordCountChart";
import "./SegmentBarChart";
import { CreativeInsightsGetters } from "@/store/modules/creativeInsights";
import HeatmapSwitch from "@/views/CreativeInteligence/HeatmapSwitch";

const excludedFromTimelineChart = [
    AttributeTypes.WORD_COUNT,
    AttributeTypes.WORD_COUNT_GROUP,
    AttributeTypes.TEXT_OVERLAY,
    AttributeTypes.COLOR_THEORY_TEMPERATURE,
    AttributeTypes.COLOR_THEORY_TEMPERATURE_GROUP
];

export default {
    name: "TimelineAnalysis",
    components: {
        HeatmapSwitch,
        WordCountChat,
        TextOverlayChat,
        CreativeInsightsDataProvider,
        DropoffChart,
        DashboardCard,
        BannerScrubbar,
        SquareButton,
        VideoWrapper,
        TimelineAnalysisChart,
        Card
    },
    props: {
        creative: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            apiResponse: null,
            currentTime: 0,
            duration: 0,
            filters: [],
            hasDropoffMetrics: false,
            isLoadingAttributes: true,
            isLoadingCreative: true,
            playheadStyle: { left: 8 },
            state: undefined,
            timeUpdateFromUser: false,
            timeUpdateFromVideo: true,
            wasPlayingBeforeDrag: false
        };
    },

    computed: {
        attributes() {
            if (!this.creativeData || !this.creativeData.attributes) {
                return [];
            }

            return this.creativeData.attributes;
        },

        attributesWithTimeline() {
            return this.attributes.filter(attribute => attribute.timeline && attribute.timeline.length);
        },
        chartAttributesWithTimeline() {
            return this.attributesWithTimeline.filter(({ type }) => !excludedFromTimelineChart.includes(type));
        },

        isLoading() {
            return this.isLoadingCreative;
        },

        chartAttributes() {
            if (!this.filters.length) {
                return this.chartAttributesWithTimeline;
            }

            return this.chartAttributesWithTimeline.filter(({ type }) => this.filters.includes(type));
        },

        creativeData() {
            return (!!this.apiResponse && this.apiResponse.data && this.apiResponse.data[0]) || null;
        },

        datasetTypes() {
            return [...this.chartAttributesWithTimeline.reduce((acc, curr) => acc.add(curr.type), new Set())];
        },

        filterOptions() {
            return this.datasetTypes.map(value => ({ value, label: value }));
        },

        hasAttributes() {
            return !!this.chartAttributes.length;
        },

        isVideoCompleted() {
            return this.state === "ended";
        },

        isVideoPlaying() {
            return this.state === "playing";
        },

        kpi() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricName];
        },

        kpiAvgValue() {
            return (this.creativeData && this.creativeData.avgKpiForSelectedKpi) || null;
        },

        kpiAvgDisplayValue() {
            if (this.kpiAvgValue !== null) {
                return this.kpiAvgValue.toFixed(3);
            }
            return null;
        },

        kpiValue() {
            if (this.creativeData && this.creativeData.metrics) {
                return this.creativeData.metrics[this.kpi];
            }
            return null;
        },

        kpiDisplayValue() {
            if (this.kpiValue !== null) {
                return this.kpiValue.toFixed(3);
            }
            return null;
        },

        playPauseReplayIcon() {
            if (this.videoShouldReplay || this.isVideoCompleted) {
                return "md-refresh";
            }

            if (this.isVideoPlaying) {
                return "md-pause";
            }

            return "md-play";
        },

        scaleX() {
            return {
                type: "linear",
                position: "top",
                min: 0,
                suggestedMax: this.duration,
                step: 1,
                ticks: {
                    callback: this.timeDuration
                },
                id: "timeline",
                grid: {
                    drawBorder: false,
                    display: false
                }
            };
        },

        scrollWrapperStyle() {
            return this.hasDropoffMetrics ? { height: "calc(100% - 221px)" } : { height: "calc(100% - 41px)" };
        },

        showHeatmap() {
            return this.$store.state.creativeInsights.showHeatmap;
        },

        videoUrl() {
            if (this.showHeatmap) {
                return (this.creativeData && this.creativeData.signedVideoHeatMapUrl) || null;
            }

            return (this.creativeData && this.creativeData.signedVideoUrl) || null;
        },

        videoMetrics() {
            return (this.creativeData && this.creativeData.metrics) || {};
        }
    },

    watch: {
        isLoading(val) {
            // todo switch loading to true when switching the tab
            if (!val && this.$refs.video && this.$refs.video.$el) {
                this.$refs.video.$el.play();
            }
        },

        videoUrl() {
            if (this.$refs.video && this.$refs.video.$el) {
                this.$refs.video.$el.load();
                this.$refs.video.$el.play();
            }
        }
    },

    created() {
        this.ReportKPI = ReportKPI;
        this.AttributeTypeLabels = AttributeTypeLabels;
    },

    methods: {
        timeDuration(value) {
            const date = new Date(0);
            date.setSeconds(value); // specify value for SECONDS here

            return date.toISOString().substr(15, 4);
        },

        onApiResponse(response) {
            this.apiResponse = response;
        },

        onCanPlay() {
            const video = this.$refs.video.$el;
            video.muted = true;
            this.playVideo();
        },

        onDragging(dragging) {
            if (dragging) {
                this.wasPlayingBeforeDrag = this.isVideoPlaying;
                this.pauseVideo();
            } else if (this.wasPlayingBeforeDrag) {
                this.playVideo();
            }
        },

        onUpdate({ currentTime }) {
            this.setTimeFromVideo(currentTime);
            this.updatePlayhead();
        },

        playVideo() {
            const video = this.$refs.video.$el;

            video.play();
        },

        pauseVideo() {
            const video = this.$refs.video.$el;

            video.pause();
        },

        playPauseVideo() {
            const video = this.$refs.video.$el;
            if (this.isVideoPlaying) {
                video.pause();
                return;
            }

            if (this.isVideoCompleted || this.videoShouldReplay) {
                this.videoShouldReplay = false;

                video.play();
                return;
            }

            video.play();
        },

        seek(time) {
            if (this.currentTime === time) {
                return;
            }

            this.setTimeManually(time);
        },

        setDuration(duration) {
            this.duration = duration;
        },

        setLoadingState(state) {
            this.isLoadingCreative = state;
        },

        setState(state) {
            this.state = state;
        },

        setTimeFromVideo(currentTime) {
            this.timeUpdateFromVideo = true;
            this.timeUpdateFromUser = false;
            this.currentTime = currentTime;
        },

        setTimeManually(currentTime) {
            if (!this.timeUpdateFromVideo) {
                this.currentTime = currentTime;
                this.timeUpdateFromUser = true;
                this.$refs.video.$el.currentTime = currentTime;
                this.$refs.video.$el.pause();
            }
            this.timeUpdateFromVideo = false;
        },

        updatePlayhead() {
            if (!this.$refs.timelineChart || !this.$refs.timelineChart.chart) {
                this.playheadStyle = { left: 0 };
                return;
            }

            const timelineScale = this.$refs.timelineChart.chart.scales.x;
            const position = timelineScale.getPixelForValue(this.currentTime);
            this.playheadStyle = { left: `${Math.round(position)}px` };
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.timeline-analysis {
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: $spacing-small;
    pointer-events: visible;

    &__body {
        flex: 1 1 auto;
        display: flex;
        height: auto;
        overflow: auto;
        padding: 0 $spacing 0 80px;
    }

    &__preview {
        width: 300px;
        margin-right: $spacing;
        flex: 0 0 300px;
    }

    &-wrapper {
        height: 100%;
        width: 100%;
    }

    &__overlays {
        position: absolute;
        top: 30px;
        left: $spacing;
        z-index: 0;
        width: 100%;
        height: calc(100% - 30px);
        pointer-events: none;

        &-playhead {
            position: absolute;
            height: 100%;
            width: 2px;
            background-color: $primary;
            left: 14px;
        }
    }

    &__scroll-wrapper {
        padding-top: $spacing-large;
        overflow: hidden scroll;
        width: 100%;
        height: 100%;
        flex: 1;
        min-height: 80px;
        position: relative;
        padding-right: $spacing;
        padding-bottom: $spacing;
    }

    &__chart {
        height: calc(100% - 120px);
        //width: 100% !important;
        flex: 1 1;

        &-card {
            position: relative;
            padding-right: $spacing-smaller;
            margin-bottom: $spacing-small;
        }
    }

    &__video {
        max-width: 300px;
        height: 100%;
        object-fit: contain;
        max-height: 90vh;
        min-height: 200px;
        display: block;
        margin: 0 auto;
    }

    &__footer {
        align-items: center;
        border-top: 1px solid $grey3;
        display: flex;
        padding: $spacing-small $spacing-large;
        flex: 0 0 auto;

        .timeline-slider {
            align-items: center;
            display: flex;
            flex: 1 0;
            width: 100%;
        }

        .ivu-slider-wrap {
            margin: 9px ($spacing + 9) 9px 9px;
        }
    }

    &__scrubbar-container {
        flex: 1;
        position: relative;
    }

    &__video-card {
        padding: 0;
        margin: $spacing 0;
    }

    .kpi-performance__results {
        justify-content: space-between;
        &-kpi {
            width: 69px;
            display: flex;
            align-items: center;
            text-align: center;
            flex: 1 1;
        }
    }

    &__filters {
        label {
            width: 100%;
        }
    }
}
</style>
