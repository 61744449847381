<template>
    <hox-alert v-if="isVisible" type="warning" size="small" theme="dark" align-text="center">
        <template #content>
            <div class="missing-font-info">
                <div class="missing-font-info__message">
                    <strong>Some text fields are disabled due to missing fonts:</strong>
                    {{ missingFontNames.join(", ") }}
                </div>
                <div class="missing-font-info__close" @click="closedByUser = true">
                    <Icon type="md-close" />
                </div>
            </div>
        </template>
    </hox-alert>
</template>
<script>
import editableUtils from "@/utils/editables";
import HoxAlert from "@/components/common/Alert";
import { EditorGetters } from "@/store/modules/editor";

export default {
    name: "MissingFontInfo",
    components: { HoxAlert },
    data() {
        return {
            closedByUser: false
        };
    },
    computed: {
        availableFontNames() {
            if (!this.fontsByName) {
                return [];
            }

            return Object.keys(this.fontsByName);
        },

        editableIds() {
            return this.$store.state.campaign.normalized.editableIds;
        },

        editables() {
            return this.$store.state.campaign.normalized.editables;
        },

        editablesForPSD() {
            return this.editableIds.reduce((acc, editableId) => {
                if (editableUtils.hasPsdTemplate(this.editables[editableId], this.masterTemplates)) {
                    acc.push(this.editables[editableId]);
                }

                return acc;
            }, []);
        },

        fontsByName() {
            return this.$store.getters[EditorGetters.fontsByName];
        },

        hasMissingFonts() {
            return !!this.missingFontNames.length;
        },

        isVisible() {
            return this.hasMissingFonts && !this.closedByUser;
        },

        masterTemplates() {
            return this.$store.state.campaign.normalized.masterTemplates;
        },

        missingFontNames() {
            const missingSet = this.editablesForPSD.reduce((acc, editable) => {
                const missing = editableUtils.findMissingFonts(editable, this.availableFontNames);

                missing.forEach(m => acc.add(m));

                return acc;
            }, new Set());

            return Array.from(missingSet);
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";
.missing-font-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &__message {
        font-size: 12px;
        vertical-align: center;
    }

    &__close {
        cursor: pointer;
    }
}
</style>
