<template>
    <hox-modal @close="close">
        <hox-loading-layer v-if="isAddingMetadataField" />
        <template #header>Add column</template>
        <metadata-field-form
            :has-error-submitting-form="hasErrorAddingMetadataField"
            :show-validation-errors="showValidationErrors"
            :error="formError"
            @formDataUpdate="setFormData"
            @hasValidationErrorsUpdate="setHasValidationErrors"
            @submit="addMetadataField"
        />
        <hox-alert size="small" type="info" margin-top="base">
            <template #content>
                <p>
                    Columns can be used to assign custom values to deliverables, such as targeting IDs or tracking
                    codes.
                </p>
                <p>These values will included as part of the published feeds.</p>
            </template>
        </hox-alert>
        <template #footer>
            <Button :disabled="showValidationErrors && hasValidationErrors" type="primary" @click="addMetadataField">
                Add column
            </Button>
        </template>
    </hox-modal>
</template>

<script>
import MetadataFieldForm from "@/components/Metadata/MetadataFieldForm";
import { addMetadataField } from "@/services/Metadata";
import { DeliverableLibraryAction } from "@/store/modules/deliverableLibrary";

export default {
    components: {
        MetadataFieldForm
    },

    data() {
        return {
            formData: {},
            hasErrorAddingMetadataField: false,
            hasValidationErrors: false,
            isAddingMetadataField: false,
            showValidationErrors: false,
            formError: null
        };
    },
    computed: {
        campaignId() {
            return this.$route.params.campaignId;
        }
    },

    methods: {
        async addMetadataField() {
            this.showValidationErrors = true;
            if (this.hasValidationErrors) {
                return;
            }

            this.hasErrorAddingMetadataField = false;
            this.isAddingMetadataField = true;
            try {
                const createdMetadataField = await addMetadataField(this.campaignId, this.formData.columnName);

                this.showSuccessMessage(createdMetadataField);
                this.storeMetadataField(createdMetadataField);
            } catch (err) {
                this.formError = err;
                this.hasErrorAddingMetadataField = true;
                return;
            } finally {
                this.isAddingMetadataField = false;
            }
            this.close();
        },

        close() {
            this.$emit("close");
        },

        setFormData(formData) {
            this.formData = formData;
            this.formError = null;
            this.hasErrorAddingMetadataField = false;
        },

        setHasValidationErrors(hasValidationErrors) {
            this.hasValidationErrors = hasValidationErrors;
        },

        showSuccessMessage(field) {
            if (this.$store.state.deliverableLibrary.metadataFieldsByFieldId[field._id]) {
                this.$snackbar.success(`The column "${field.name}" already exists`);
            } else {
                this.$snackbar.success(`The column "${field.name}" has been added to the table`);
            }
        },

        storeMetadataField(field) {
            // update or set the field we have in the store
            this.$store.dispatch(DeliverableLibraryAction.SetMetadataFieldsByFieldId, field);
            let visibilityUpdated = false;
            /*
                    So that the new metadata field is immediately visible in
                    the table we add it to the visible and sorted column keys
                    in the store.

                    But only if it is not already there
                */
            if (!this.$store.state.deliverableLibrary.table.visibleColumnKeys.find(vck => vck === field._id)) {
                this.$store.dispatch(DeliverableLibraryAction.SetTableVisibleColumnKeys, [
                    ...this.$store.state.deliverableLibrary.table.visibleColumnKeys,
                    field._id
                ]);
                visibilityUpdated = true;
            }
            if (!this.$store.state.deliverableLibrary.table.sortedColumnKeys.find(sck => sck === field._id)) {
                this.$store.dispatch(DeliverableLibraryAction.SetTableSortedColumnKeys, [
                    ...this.$store.state.deliverableLibrary.table.sortedColumnKeys,
                    field._id
                ]);

                visibilityUpdated = true;
            }

            if (visibilityUpdated) {
                this.$emit("columnVisibilityUpdated");
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
</style>
