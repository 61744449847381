<template>
    <transition appear name="fade">
        <div class="annotationScrubbar">
            <div
                v-for="annotation in filteredAnnotations"
                :key="annotation.id"
                :class="generateItemClasses(annotation)"
                :style="calculatePosition(annotation)"
                @click="selectAnnotation(annotation)"
            />
        </div>
    </transition>
</template>

<script>
import { AnnotationStatus, AnnotationType } from "@/enums/annotations";

export default {
    name: "AnnotationScrubbar",
    props: {
        annotations: {
            type: Array,
            required: true
        },

        duration: {
            type: Number,
            required: true
        },

        selectedAnnotation: {
            validator(value) {
                return value === null || value._id;
            }
        },

        showResolved: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        filteredAnnotations() {
            return this.showResolved
                ? this.annotations
                : this.annotations.filter(annotation => annotation.status === AnnotationStatus.Unresolved);
        }
    },

    methods: {
        selectAnnotation(annotation) {
            this.$emit("annotationSelected", annotation);
        },

        calculatePosition(annotation) {
            if (!this.duration || !annotation.timeline) {
                return 0;
            }

            return {
                left: `${(annotation.timeline / this.duration) * 100}%`
            };
        },

        generateItemClasses(annotation) {
            const classes = ["annotationScrubbar__item"];
            if (annotation.type === AnnotationType.Internal) {
                classes.push("annotationScrubbar__item--internal-annotation");
            } else if (annotation.type === AnnotationType.External) {
                classes.push("annotationScrubbar__item--external-annotation");
            }
            const annotationIsSelected =
                this.selectedAnnotation !== null && annotation._id === this.selectedAnnotation._id;
            if (annotationIsSelected) {
                classes.push("annotationScrubbar__item--selected");
            }
            return classes;
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

$annotation-size: 10px;
$point-size: 8px;
$selected-annotation-height: ($annotation-size * 2);
$selected-annotation-width: ($selected-annotation-height * 0.75);
$selected-point-size: ($point-size * 1.5);

@keyframes annotation-scrubbar__bounce {
    0% {
        transform: translateY(0);
    }
    70% {
        transform: translateY((0 - calc($selected-annotation-height / 4)));
    }
    100% {
        transform: translateY(0);
    }
}

.annotationScrubbar {
    /*
      This style/positioning is very specific to the implementation in the QA
      drawer. It would likely break if used elsewhere.
    */
    height: 1px;
    left: 9px;
    position: absolute;
    right: 25px;
    top: -9px;

    &__item {
        cursor: pointer;
        position: absolute;
        width: $annotation-size;
        height: $annotation-size;
        padding: 0px;
        background: $yellow;
        border-radius: 0px;
        margin-left: (0 - calc($annotation-size / 2));
        transition: transform 0.2s linear;

        &:after {
            content: "";
            position: absolute;
            border-style: solid;
            border-width: $point-size calc($annotation-size / 2) 0;
            border-color: $yellow transparent;
            display: block;
            width: 0;
            z-index: 1;
            bottom: (0 - $point-size);
            left: 0;
        }

        &:hover {
            transform: translateY(-3px);
        }

        &--external-annotation {
            background: $external-annotation-color;

            &:after {
                border-color: $external-annotation-color transparent;
            }
        }

        &--internal-annotation {
            background: $internal-annotation-color;

            &:after {
                border-color: $internal-annotation-color transparent;
            }
        }

        &--selected {
            animation: annotation-scrubbar__bounce 1s infinite;
            height: $selected-annotation-height;
            width: $selected-annotation-width;
            margin-left: (0 - calc($selected-annotation-width / 2));
            margin-top: (0 - $selected-point-size);
            z-index: 2;

            &:after {
                border-width: $selected-point-size calc($selected-annotation-width / 2) 0;
                bottom: (0 - $selected-point-size);
                z-index: 2;
            }

            &:hover {
                transform: none;
            }
        }
    }
}
</style>
