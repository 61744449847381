<template>
    <hox-modal @close="close">
        <hox-loading-layer v-if="isUpdatingSection" />
        <delete-campaign-section-modal
            v-if="deleteCampaignSectionModalIsVisible"
            :section-id="sectionId"
            :section-name="sectionName"
            @sectionDeleted="deleted"
            @close="toggleDeleteCampaignSectionModalIsVisible"
        />
        <template #header>Edit section</template>
        <campaign-section-form
            :external-errors="mutationErrors"
            :initial-form-data="formData"
            :show-validation-errors="true"
            @hasValidationErrorsUpdate="setHasValidationErrors"
            @formDataUpdate="setFormData"
            @submit="updateSection"
        />
        <div v-if="hasErrorUpdatingSection" class="edit-campaign-section-modal__unexpected-error-wrapper">
            <Alert banner type="error">
                <template #desc>
                    There was an unexpected error and the section could not be updated. Please try again in a few
                    moments.
                </template>
            </Alert>
        </div>
        <template #footer>
            <div class="edit-campaign-section-modal__buttons-container">
                <Button
                    ghost
                    type="error"
                    data-testid="duplicate-section-modal__button--delete"
                    @click="toggleDeleteCampaignSectionModalIsVisible"
                >
                    Delete
                </Button>
                <Button
                    type="primary"
                    data-testid="duplicate-section-modal__button--save"
                    :disabled="hasValidationErrors"
                    @click="updateSection"
                >
                    Save
                </Button>
            </div>
        </template>
    </hox-modal>
</template>

<script>
import updateCampaignSectionMutation from "@/apollo/mutations/UpdateCampaignSection.gql";
import CampaignSectionForm from "@/components/CampaignSectionForm";
import DeleteCampaignSectionModal from "@/components/DeleteCampaignSectionModal";
import { groupErrorsByField } from "@/utils";

export default {
    components: {
        CampaignSectionForm,
        DeleteCampaignSectionModal
    },

    props: {
        sectionId: {
            type: String,
            required: true
        },
        sectionName: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            deleteCampaignSectionModalIsVisible: false,
            formData: {
                name: this.sectionName
            },
            hasErrorUpdatingSection: false,
            hasValidationErrors: false,
            isUpdatingSection: false,
            mutationErrors: {},
            section: undefined
        };
    },

    watch: {
        // eslint-disable-next-line func-names
        "formData.name": function () {
            this.$delete(this.mutationErrors, "name");
        }
    },

    methods: {
        async updateSection() {
            this.showValidationErrors = true;
            if (!this.hasValidationErrors) {
                this.hasErrorUpdatingSection = false;
                this.isUpdatingSection = true;
                try {
                    await this.$apollo.mutate({
                        mutation: updateCampaignSectionMutation,
                        variables: {
                            sectionId: this.sectionId,
                            name: this.formData.name
                        }
                    });
                } catch ({ graphQLErrors }) {
                    this.mutationErrors = groupErrorsByField(graphQLErrors);
                    this.hasErrorUpdatingSection = Object.keys(this.mutationErrors).length === 0;
                    return;
                } finally {
                    this.isUpdatingSection = false;
                }
                this.$emit("sectionUpdated");
                this.close();
            }
        },

        close() {
            this.$emit("close");
        },

        deleted() {
            this.$emit("deleted");
            this.close();
        },

        setFormData(formData) {
            this.formData = formData;
        },

        setHasValidationErrors(hasValidationErrors) {
            this.hasValidationErrors = hasValidationErrors;
        },

        toggleDeleteCampaignSectionModalIsVisible() {
            this.deleteCampaignSectionModalIsVisible = !this.deleteCampaignSectionModalIsVisible;
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/../sass/_variables.scss";

.edit-campaign-section-modal {
    &__loading-container {
        position: relative;
        height: 300px;
    }

    &__buttons-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__unexpected-error-wrapper {
        padding: $spacing 0 0 0;
    }
}
</style>
