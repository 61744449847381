<template>
    <div class="insights-dashboard-wrapper">
        <reporting-drawer :is-open="drawerCreatives.length" :creatives="drawerCreatives" @closed="closeDrawer" />
        <insights-marketplace-modal v-if="isMarketplaceOpen" :is-open.sync="isMarketplaceOpen" />
        <hox-loading-layer v-if="!isQueryReady" size="large" />
        <div v-else>
            <empty-message v-if="!hasWorkerId" type="no-content" class="insights-dashboard-no-kpis">
                <template #title>This campaign has no Resource Group ID set.</template>
            </empty-message>
            <div v-else>
                <empty-message v-if="!isIndexed" type="no-content" class="insights-dashboard-no-kpis">
                    <template #title>Processing data ({{ indexedPercentage }}%)</template>
                </empty-message>
                <div
                    v-else-if="benchmarksSet"
                    class="insights-dashboard"
                    :class="{ 'insights-dashboard--drawer-is-open': drawerCreatives.length }"
                >
                    <insights-data-provider
                        :insights-type="InsightType.CampaignInfo"
                        :refresh-counter="refreshCounter"
                        @loading="setLoading"
                        @data="onData"
                        @error="onError"
                    />

                    <dashboard-content-dropdown @input="setDashboardContent" />

                    <dashboard-filters />

                    <div v-if="orderedTags.length" class="insights-dashboard-tag-components">
                        <template v-if="dashboardContent === CreativeInsightsDashboardContent.DataExplorer">
                            <creative-insights-data-explorer />
                        </template>
                        <template v-else>
                            <draggable
                                v-model="orderedTags"
                                class="insights-dashboard-tag-components__draggable-wrapper"
                                handle=".insights-dashboard-tag-component__drag-handle"
                                ghost-class="insights-dashboard-tag-component--dragged"
                            >
                                <insights-dashboard-tag-component
                                    v-for="tagId in orderedTags"
                                    :key="tagId"
                                    :tag-id="tagId"
                                    :dashboard-content="dashboardContent"
                                    class="insights-dashboard-tag-component"
                                />
                            </draggable>
                        </template>
                    </div>

                    <card class="insights-dashboard__marketplace">
                        <InsightMarketplaceIconSvg />
                        <h3>
                            Insights
                            <span>Marketplace</span>
                        </h3>
                        <Button @click="showMarketplace">Explore Now</Button>
                    </card>

                    <hox-empty-message v-if="showNoResults" class="insights-dashboard-no-data">
                        <template #title>
                            Select another insight, try a different search or adjust your Discard Metrics settings
                        </template>
                    </hox-empty-message>
                    <hox-alert v-else-if="hasError && !isLoading" margin-top="base" type="danger">
                        <template #title>Uh oh!</template>
                        <template #content>
                            <p>
                                There was an unexpected issue while loading the data. Hopefully it was a temporary issue
                                and should be fixed if you try again in a few moments.
                            </p>
                        </template>
                    </hox-alert>
                </div>
                <empty-message v-else type="no-content" class="insights-dashboard-no-kpis">
                    <template #title>To get started, you'll need to define your benchmarks</template>
                    <template #actionItems>
                        <Button type="primary" @click="showSettingsModal">Set Benchmarks</Button>
                    </template>
                </empty-message>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import CampaignQuery from "@/apollo/queries/v2/Campaign.gql";
import {
    CreativeInsightsAction as ReportingAction,
    CreativeInsightsAction,
    CreativeInsightsGetters
} from "@/store/modules/creativeInsights";
import ReportingDrawer from "@/components/CreativeIntelligence/CreativeInteligenceDeliverableDrawer";
import { UiAction } from "@/store/modules/ui";

import EmptyMessage from "@/components/common/EmptyMessage";
import { InsightType } from "@/components/CreativeIntelligence/constants";
import InsightsDataProvider from "@/components/CreativeIntelligence/InsightsDataProvider";
import InsightsDashboardTagComponent from "@/views/CreativeInteligence/InsightsDashboardTagComponent";
import DashboardContentDropdown from "@/views/CreativeInteligence/DashboardContentDropdown";
import DashboardFilters from "@/views/CreativeInteligence/DashboardFilters";
import InsightMarketplaceIconSvg from "@/assets/insight-marketplace-icon.svg";
import InsightsMarketplaceModal from "@/components/CreativeIntelligence/InsightsMarketplaceModal";
import { CreativeInsightsDashboardContent } from "@/enums/creativeInteligence";
import CreativeInsightsDataExplorer from "@/views/CreativeInteligence/CreativeInsightsDataExplorer";

const LocalStorageOrderItem = "creativeIntelligenceTagOrder";

export default {
    name: "InsightsDashboard",
    components: {
        CreativeInsightsDataExplorer,
        InsightsMarketplaceModal,
        DashboardContentDropdown,
        DashboardFilters,
        EmptyMessage,
        InsightMarketplaceIconSvg,
        InsightsDashboardTagComponent,
        InsightsDataProvider,
        ReportingDrawer,
        draggable
    },

    data() {
        return {
            dashboardContent: null,
            data: null,
            hasError: false,
            isLoading: false,
            isMarketplaceOpen: false,
            localSortOrder: [],
            refreshCounter: 0,
            hasWorkerId: false,
            isQueryReady: false
        };
    },

    computed: {
        advancedSearch() {
            return this.$store.state.creativeInsights.advancedSearch;
        },

        benchmarksSet() {
            return (
                this.$store.getters[CreativeInsightsGetters.KpiMetricName] !== undefined &&
                this.$store.getters[CreativeInsightsGetters.ConfidenceMetricName] !== undefined
            );
        },

        breakdowns() {
            return this.$store.state.creativeInsights.breakdowns;
        },

        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        displayTemplates() {
            if (!this.selectedTagIds.length) {
                return null;
            }

            return this.tagsById[this.selectedTagIds[0]].displayTemplates;
        },

        drawerCreatives: {
            set(mode) {
                return this.$store.dispatch(CreativeInsightsAction.SetDrawerCreatives, mode);
            },
            get() {
                return this.$store.state.creativeInsights.drawerCreatives;
            }
        },

        hasData() {
            return this.data && this.breakdowns.length;
        },

        indexedPercentage() {
            return this.$store.state.creativeInsights.indexedPercentage;
        },

        isIndexed() {
            return this.$store.state.creativeInsights.isIndexed;
        },

        indexingError() {
            return this.$store.state.creativeInsights.indexingError;
        },

        localStorageTagOrder() {
            return JSON.parse(localStorage.getItem(LocalStorageOrderItem)) || [];
        },

        orderedTags: {
            get() {
                const dbTagsIds = Object.keys(this.tagsById);
                const localStorageTagByIndex = this.localSortOrder.reduce((acc, tagId, index) => {
                    acc[tagId] = index + 1;
                    return acc;
                }, {});
                const { newTags, existingTags } = dbTagsIds.reduce(
                    (acc, tagId) => {
                        if (this.localSortOrder.includes(tagId)) {
                            acc.existingTags.push(tagId);
                        } else {
                            acc.newTags.push(tagId);
                        }

                        return acc;
                    },
                    { newTags: [], existingTags: [] }
                );

                const res = [
                    ...existingTags.sort((tagA, tagB) => localStorageTagByIndex[tagA] - localStorageTagByIndex[tagB]),
                    ...newTags
                ];

                return res;
            },
            set(val) {
                this.localSortOrder = val;
            }
        },

        showsDiversityTemplate() {
            return this.displayTemplates && this.displayTemplates.includes("diversity");
        },

        showNoResults() {
            return (
                !this.isLoading &&
                this.data &&
                this.data.overallKpiMetricStats &&
                this.data.overallKpiMetricStats &&
                !this.data.overallKpiMetricStats.count
            );
        },

        selectedTagIds() {
            return this.$store.state.ciTags.selectedTagIds;
        },

        tagsById() {
            return this.$store.state.ciTags.tagsById;
        }
    },

    watch: {
        advancedSearch(val) {
            if (val && this.dashboardContent !== CreativeInsightsDashboardContent.DataExplorer) {
                // navigate to tag view.
                this.$router.push({ name: "CITagInsights" });
            }
        },

        drawerCreatives: {
            immediate: true,
            handler(val) {
                if (val) {
                    if (val.length) {
                        document.body.classList.add("insights--no-scroll");
                    } else {
                        document.body.classList.remove("insights--no-scroll");
                    }
                }
            }
        },

        localSortOrder(order) {
            localStorage.setItem(LocalStorageOrderItem, JSON.stringify(order));
        }
    },

    created() {
        this.InsightType = InsightType;
        this.CreativeInsightsDashboardContent = CreativeInsightsDashboardContent;

        this.$store.dispatch(CreativeInsightsAction.Init);
        this.$store.dispatch(UiAction.CloseSidebar);
        this.localSortOrder = this.localStorageTagOrder;
    },

    methods: {
        closeDrawer() {
            this.drawerCreatives = [];
        },

        // eslint-disable-next-line complexity
        onData({ data }) {
            if (!data) {
                return;
            }

            if (data) {
                this.data = data;
                this.hasError = false;
                if (typeof data.percentage !== "undefined") {
                    this.$store.dispatch(CreativeInsightsAction.SetIsIndexed, false);
                    this.$store.dispatch(CreativeInsightsAction.SetIndexedPercentage, data.percentage);
                }
            }

            if (data.filter) {
                this.$store.dispatch(CreativeInsightsAction.SetBreakdowns, data.filter);
            }

            if (data.metadata) {
                this.$store.dispatch(CreativeInsightsAction.SetMetadata, {
                    ...data.metadata,
                    maxDate: data.dateRange.maxDate ? data.dateRange.maxDate : "",
                    minDate: data.dateRange.minDate ? data.dateRange.minDate : ""
                });
            }

            // todo check this code
            if (!this.isIndexed) {
                const incrementRefreshCounter = () => {
                    this.refreshCounter += 1;
                };
                setTimeout(incrementRefreshCounter.bind(this), 5000);
            }
        },

        onError() {
            this.hasError = true;
        },

        setDashboardContent(content) {
            this.dashboardContent = content;
        },

        setLoading(isLoading) {
            this.isLoading = isLoading;
        },

        showMarketplace() {
            this.isMarketplaceOpen = true;
        },

        showSettingsModal() {
            this.$store.dispatch(CreativeInsightsAction.ToggleSettingsModal);
        }
    },

    apollo: {
        campaign: {
            query: CampaignQuery,
            loadingKey: "isLoading",
            fetchPolicy: "no-cache",
            variables() {
                return {
                    campaignId: this.campaignId
                };
            },

            result({ data, error }) {
                this.$store.dispatch(UiAction.SetCurrentClient, data.campaign.client);
                if (error) {
                    console.error(error);
                    return;
                }
                this.isQueryReady = true;

                if (!data.campaign.resourceGroupIds) {
                    return;
                }

                this.hasWorkerId = true;
                this.$store.dispatch(ReportingAction.SetWorkerId, data.campaign.resourceGroupIds);
                if (!this.benchmarksSet) {
                    this.showSettingsModal();
                }
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
@import "@/../sass/creative-insights-charts.scss";
.insights-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.insights-dashboard {
    flex: 1 1 100%;
    height: 100%;
    width: calc(100% - 84px);
    margin: 0 42px;
    image-rendering: -webkit-optimize-contrast;

    &--drawer-is-open {
        overflow: hidden;
    }

    &-no-data {
        margin: $spacing-largest auto auto;
    }

    &-no-kpis {
        margin: 20vh auto auto;
    }

    &__marketplace {
        margin: $spacing-smaller $spacing-smaller $spacing;
        height: 175px;

        .ivu-card-body {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
        }

        h3 {
            margin: $spacing-small 0 $spacing;
            font-size: 14px;

            span {
                color: $ci-blue;
            }
        }

        .ivu-btn {
            background-color: $ci-blue;
            color: white;
            border: 0 transparent;
        }
    }
}

.insights--no-scroll {
    overflow: hidden;
    height: 100vh;
}

.diversity-warning .alert__icon-container {
    padding-top: $spacing-small;
}

.insights-dashboard-tag-component {
    width: 50%;
    flex: 0 1 50%;
    padding: $spacing-smaller;

    &--dragged {
        opacity: 0.5;
    }
}

.insights-dashboard-tag-components {
    display: flex; // swap to grid
    flex-wrap: wrap;
    justify-content: space-between;

    &__draggable-wrapper {
        width: 100%;
        display: flex; // swap to grid
        flex-wrap: wrap;
        justify-content: space-between;

        .insights-dashboard-tag-component__drag-handle {
            cursor: move;
        }
    }
}

.dashboard-filters {
    margin: 0 $spacing-smaller;
}
</style>
