<template>
    <form class="font-form" @submit.prevent="submit">
        <hox-input
            v-if="internalFormData.name !== undefined"
            data-testid="add-font-modal__name-input"
            :value="internalFormData.name"
            is-required
            @input="onFontNameChange"
        >
            <template #label>Font name</template>
        </hox-input>
        <!-- <sections-select v-model="internalFormData.sectionId" :sections="sections" /> -->
        <hox-tabs color="tertiary" size="small" data-testid="add-font-modal__tabs">
            <hox-tabs-item
                v-for="tab of tabs"
                :key="tab.value"
                :is-active="tab.value === activeTabValue"
                :data-testid="tab.testId"
                @click="setActiveTabValue(tab.value)"
            >
                {{ tab.label }}
            </hox-tabs-item>
        </hox-tabs>
        <div class="font-form__tabbed-content">
            <div v-if="activeTabValue === 'upload'">
                <font-upload
                    v-if="uploadConfig"
                    :config="uploadConfig"
                    @fontPreviewUpdate="onFontPreviewUpdate"
                    @fontUploaded="onFontUploaded"
                    @fontUploadFailed="onFontUploadFailed"
                />
            </div>
            <div v-if="activeTabValue === 'external'">
                <hox-input
                    ref="fontUrl"
                    data-testid="add-font-modal__url-input"
                    :value="internalFormData.url"
                    @input="onFontUrlChange"
                >
                    <template #label>Font url</template>
                </hox-input>
            </div>
            <div v-if="showFontPreview" class="font-form__preview">
                <label class="font-form__preview-label">Font Preview</label>
                <div class="font-form__preview-text">
                    <hox-loading-layer v-if="isLoadingFont" :is-full-screen="false" size="small" />
                    <span v-else>{{ previewText }}</span>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import FontUpload from "@/components/FontUpload";
// import SectionsSelect from "@/components/SectionsSelect";
import { debounce } from "@/utils";

export default {
    name: "FontForm",

    components: {
        FontUpload
        // SectionsSelect
    },

    props: {
        uploadConfig: {
            required: true,
            type: Object
        },

        // sections: {
        //     required: true,
        //     type: Array,
        //     validator(value) {
        //         return value.every(item => item._id !== undefined && item.name !== undefined);
        //     }
        // },

        initialFormData: {
            type: Object
        },

        fontPreviewUrl: {
            type: String
        }
    },

    data() {
        const tabs = [
            { label: "Upload font", value: "upload", testId: "upload-font__tab" },
            { label: "External font", value: "external", testId: "external-font__tab" }
        ];

        return {
            tabs,
            activeTabValue: tabs[0].value,
            isLoadingFont: false,
            previewText: "The Quick Brown Fox Jumps Over The Lazy Dog",
            showFontPreview: false,
            internalFormData: {
                ...this.initialFormData,
                sectionId: (this.initialFormData && this.initialFormData.sectionId) || "none"
            }
        };
    },

    computed: {
        keyValue() {
            return (this.internalFormData.key || "").trim();
        },

        nameValue() {
            return (this.internalFormData.name || "").trim();
        },

        urlValue() {
            return (this.internalFormData.url || "").trim();
        },

        validationErrors() {
            const errors = [];

            if ((!this.keyValue && !this.urlValue) || !this.showFontPreview) {
                errors.push("A valid font to upload was not found");
            }

            if (!this.nameValue) {
                errors.push("A font name is required");
            }

            return errors;
        }
    },

    watch: {
        validationErrors: {
            deep: true,
            immediate: true,
            handler() {
                this.$emit("validationErrorsUpdate", [...this.validationErrors]);
            }
        },

        internalFormData: {
            deep: true,
            immediate: true,
            handler() {
                this.$emit("formDataUpdate", { ...this.internalFormData });
            }
        }
    },

    async mounted() {
        if (this.initialFormData && this.initialFormData.url) {
            this.activeTabValue = "external";
        }
        if (this.fontPreviewUrl) {
            await this.setFontPreview(this.fontPreviewUrl);
        }
    },

    methods: {
        onFontPreviewUpdate(fontUrl) {
            this.setFontPreview(fontUrl);
        },

        onFontUploaded(fontUrl) {
            this.$set(this.internalFormData, "key", fontUrl);
            this.$set(this.internalFormData, "url", "");
            this.setName(fontUrl);
        },

        onFontUploadFailed() {
            this.$snackbar.error(
                "Font Error",
                "There was an unexpected error uploading the font. Please try again in a few moments."
            );
        },

        onFontNameChange(fontName) {
            const name = fontName.trim();
            this.$set(this.internalFormData, "name", name);
        },

        onFontUrlChange: debounce(function handler(fontUrl) {
            const url = fontUrl.trim();
            this.setFontPreview(url);
            this.$set(this.internalFormData, "url", url);
            this.$set(this.internalFormData, "key", "");
            this.setName(url);
        }, 500),

        async setFontPreview(fontUrl) {
            if (!fontUrl) {
                this.showFontPreview = false;
                return;
            }

            try {
                this.isLoadingFont = true;
                this.showFontPreview = true;
                const previewFont = new FontFace("CustomFontPreview", `url("${fontUrl}")`);
                const fontFace = await previewFont.load();
                document.fonts.add(fontFace);
            } catch (err) {
                this.showFontPreview = false;
                this.$snackbar.error("Font Not Found", err.message);
            } finally {
                this.isLoadingFont = false;
            }
        },

        async setActiveTabValue(tabValue) {
            this.activeTabValue = tabValue;
            // Only empty if not in edit mode
            if (!this.initialFormData) {
                this.showFontPreview = false;
                this.$set(this.internalFormData, "key", "");
                this.$set(this.internalFormData, "url", "");
                // We set this to undefined to hide the field when we switch tabs
                this.$set(this.internalFormData, "name", undefined);
            }
            // Emit switch event
            this.$emit("formTabSwitched");
            // Focus on input if we've switched to the external tab
            await this.$nextTick();
            if (this.$refs.fontUrl) {
                this.$refs.fontUrl.focus();
            }
        },

        setName(fontUrl) {
            if (!this.nameValue) {
                const matches = fontUrl.match(/.*\/(.+?)\./);
                const name = matches.length > 1 ? matches[1] : "";
                this.$set(this.internalFormData, "name", name);
            }
        },

        submit() {
            this.$emit("submit");
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.font-form {
    &__preview {
        &-label {
            display: inline-block;
            margin: 0 0 8px;
        }

        &-text {
            display: block;
            border: 1px solid $cmp-light-border-color;
            font-family: "CustomFontPreview";
            font-size: 32px;
            min-height: 100px;
            padding: $spacing-small;
            position: relative;
            margin: 0 0 $spacing-small;
            text-align: center;
        }
    }

    &__tabbed-content {
        padding: $spacing 0;
    }
}
</style>
