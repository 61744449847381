import {
    newId,
    getEditableGroupValue,
    setEditableGroupValue,
    removeEditableGroupValue as removeGroupValue,
    removeOverwritesByGroupValueId,
    persistData
} from "./state";

// [{"data":{"addEditableGroupValue":{"_id":"5f0f11476974860009f56efc","__typename":"EditableGroupValue"}}}]
export const addEditableGroupValue = async (operation, serverUri) => {
    const { variables } = operation;
    // Create a new editable group value in the local state
    const _id = newId();
    const data = setEditableGroupValue(_id, {
        _id,
        value: variables.value,
        editableGroupName: variables.editableGroupName,
        __typename: "EditableGroupValue"
    });

    // Save the group to the working directory - but don't save any overwrites
    await persistData(serverUri);

    return {
        addEditableGroupValue: data
    };
};

// [{"data":{"addEditableGroupValues":[{"_id":"5f0f11476974860009f56efc","__typename":"EditableGroupValue"}]}}]
export const addEditableGroupValues = async (operation, serverUri) => {
    const { variables } = operation;

    const data = variables.values.map(value => {
        const _id = newId();
        return setEditableGroupValue(_id, {
            _id,
            value,
            editableGroupName: variables.editableGroupName,
            __typename: "EditableGroupValue"
        });
    });

    // Save the group to the working directory - but don't save any overwrites
    await persistData(serverUri);

    return {
        addEditableGroupValues: data
    };
};

// [{"data":{"updateEditableGroupValues":[{"_id":"5f0f11476974860009f56efc","value":"123456","__typename":"EditableGroupValue"}]}}]
export const updateEditableGroupValue = async (operation, serverUri) => {
    const { variables } = operation;

    // Updates can be sent as a batch so we need to loop the inputs
    const data = variables.editableGroupValues.map(egvInput => {
        // Find the group in the local state
        const egv = getEditableGroupValue(egvInput.editableGroupValueId);
        // Set it back to local state with the new name (value)
        return setEditableGroupValue(egvInput.editableGroupValueId, {
            ...egv,
            value: egvInput.value
        });
    });

    // Persist the changes to disk
    await persistData(serverUri);

    return {
        updateEditableGroupValues: data
    };
};

// [{"data":{"removeEditableGroupValues":{"removeEditableGroupValuesNumber":1,"removeComputedDeliverablesReport":{"removedComputedDeliverablesNumber":0,"removedAnnotations":0,"removedComments":0},"foundOverwritesNumber":0,"removedOverwritesNumber":0}}}]
export const removeEditableGroupValue = async (operation, serverUri) => {
    const { variables } = operation;
    let count = 0;
    // Loop the input ids and remove each group from the local state
    variables.editableGroupValueIds.forEach(egvId => {
        removeGroupValue(egvId);
        // Increase the count by the number of overwrites that were attached to this group and subsequently deleted
        count += removeOverwritesByGroupValueId(egvId).length;
    });

    // Persist the changes to disk
    await persistData(serverUri);

    return {
        removeEditableGroupValues: {
            removeEditableGroupValuesNumber: variables.editableGroupValueIds.length,
            // Not relevant for sandbox mode so 0 is an accurate representation
            removeComputedDeliverablesReport: {
                removedComputedDeliverablesNumber: 0,
                removedAnnotations: 0,
                removedComments: 0
            },
            foundOverwritesNumber: count,
            removedOverwritesNumber: count
        }
    };
};
