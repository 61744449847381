<template>
    <div class="report-kpi-selector">
        <Select :value="innerValue" :loading="loading" :disabled="disabled" size="small" transfer @input="onInput">
            <Option v-for="opt in options" :key="opt" :label="getLabel(opt)" :value="opt">
                {{ getLabel(opt) }}
            </Option>
        </Select>
        <hox-loading-spinner v-if="loading" :size="20" />
    </div>
</template>

<script>
import { getMetricsLabel } from "@/enums/creativeInteligence";

export default {
    name: "MetricSelector",
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            default() {
                return [];
            }
        },
        value: {
            type: String
        }
    },

    data() {
        return {
            innerValue: "value"
        };
    },

    watch: {
        value: {
            immediate: true,
            handler() {
                this.innerValue = this.value;
            }
        }
    },

    methods: {
        getLabel(opt) {
            return getMetricsLabel(opt);
        },

        onInput(val) {
            this.innerValue = val;
            this.$emit("input", val);
        }
    }
};
</script>
<style lang="scss">
@import "../../../sass/variables";

.report-kpi-selector {
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__label {
        margin-right: $spacing-small;
    }
}
</style>
