<template>
    <nav class="creative-insights-nav">
        <div>
            <div class="creative-insights-nav__home-button-wrapper">
                <router-link :to="{ name: 'Home' }">
                    <div v-if="clientLogo" class="creative-insights-header__logo">
                        <router-link :to="clientLink">
                            <img :src="clientLogo" class="creative-insights-header__logo-image" />
                        </router-link>
                    </div>
                    <hox-main-nav-button v-else theme="dark">
                        <wpp-logo />
                        <template #tooltipContent>Home</template>
                    </hox-main-nav-button>
                </router-link>
            </div>
        </div>
        <Header class="campaign-header hoxton-header">
            <Breadcrumb separator="/" class="campaign-header__breadcrumbs">
                <BreadcrumbItem
                    v-for="(breadcrumb, index) in breadcrumbs"
                    :key="`breadcrumb-${index}`"
                    :class="['campaign-header__breadcrumb', breadcrumb.extraClass]"
                >
                    <template v-if="!breadcrumb.path">
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span class="inactive-link" v-html="breadcrumb.label"></span>
                    </template>
                    <router-link v-else :to="breadcrumb">
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span v-html="breadcrumb.label"></span>
                    </router-link>
                </BreadcrumbItem>
            </Breadcrumb>
        </Header>

        <footer-nav theme="dark"></footer-nav>
    </nav>
</template>
<script>
import WppLogo from "@/assets/wpp-logo-short.svg";
import { getDomain } from "@/utils";
import FooterNav from "@/FooterNav";

export default {
    name: "CreativeInsightsNav",

    components: {
        WppLogo,
        FooterNav
    },

    props: {
        hasCampaign: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        breadcrumbs() {
            return (
                this.$route.matched
                    // eslint-disable-next-line complexity
                    .map(({ meta, path }) => {
                        let label = meta.breadcrumb;
                        let link = path;
                        let extraClass = "";

                        if (label === ":client") {
                            if (this.client._id !== this.clientId) {
                                label = "Client";
                            } else {
                                label = `Client: <strong>${this.client.name}</strong>`;
                            }
                        } else if (label === ":campaign") {
                            if (this.campaign._id !== this.campaignId) {
                                label = "Campaign";
                            } else {
                                label = `Campaign: <strong>${this.campaign.name}</strong>`;
                            }
                            if (getDomain() === "analytics") {
                                extraClass = "campaign-header__breadcrumb--disabled";
                            }
                        }

                        link = link.replace(":clientId", this.clientId);
                        link = link.replace(":campaignId", this.campaignId);

                        if (meta.permission && meta.permission.action) {
                            link = this.$auth.userCan(meta.permission.action, {
                                ...(meta.permission.scope.clientId && { clientId: this.clientId }),
                                ...(meta.permission.scope.campaignId && { campaignId: this.campaignId })
                            })
                                ? link
                                : null;
                        }

                        return {
                            path: link,
                            label,
                            extraClass
                        };
                    })
                    .filter(breadcrumb => breadcrumb.label)
            );
        },
        campaign() {
            return this.$store.state.campaign;
        },
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },
        client() {
            return this.$store.state.campaign.client;
        },
        clientId() {
            return this.$store.state.route.params.clientId;
        },

        clientLink() {
            return {
                name: "CampaignsDashboard",
                params: { clientId: this.clientId }
            };
        },

        clientLogo() {
            return this.$store.state.ui.currentClient && this.$store.state.ui.currentClient.logoUrl;
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/_variables.scss";

.creative-insights-nav {
    align-items: center;
    background-color: $creative-insights-nav-bg;
    display: flex;
    flex-direction: column;
    flex: 0 0 $creative-insights-vertical-nav-width;
    height: 100vh;
    justify-content: space-between;
    left: 0;
    padding: $spacing-small 0 0 0;
    position: fixed;
    top: 0;
    width: $creative-insights-vertical-nav-width;
    z-index: $zindex-creative-insights-nav;
}

.creative-insights-nav__home-button-wrapper {
    margin-bottom: 90px;
}
</style>
