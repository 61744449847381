<template>
    <div class="master-template-text-filter">
        <div class="master-templates-sort-by__label">Search:</div>
        <Input v-model="value" search clearable />
    </div>
</template>
<script>
import { FilterType, MasterTemplateLibraryAction } from "@/store/modules/masterTemplateLibrary";
import { deepClone } from "@/utils";

export default {
    name: "MasterTemplateTextFilter",

    props: {
        campaignMasterTemplateIds: {
            type: Array,
            default() {
                return [];
            }
        }
    },

    computed: {
        filterValues() {
            return this.$store.state.masterTemplateLibrary.filters.filter(filter => filter.type === FilterType.Text);
        },

        masterTemplates() {
            return this.$store.state.masterTemplateLibrary.masterTemplates;
        },

        // Templates that belong to the client but has not been added to the campaign yet.
        masterTemplateIds() {
            return this.$store.state.masterTemplateLibrary.masterTemplateIds.filter(
                templateId => !this.campaignMasterTemplateIds.includes(templateId)
            );
        },

        value: {
            get() {
                if (this.filterValues.length) {
                    const fval = deepClone(this.filterValues);

                    return fval.pop().values;
                }

                return "";
            },

            set(newValue) {
                this.$store.dispatch(MasterTemplateLibraryAction.UpdateTextFilter, {
                    values: newValue
                });
            }
        }
    },

    beforeDestroy() {
        this.selectedValues = [];
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.master-template-text-filter {
    display: flex;
    margin-right: $spacing;
    justify-content: space-between;
    align-items: center;
    width: 155px;
    flex: 0 0 155px;
    margin-right: $spacing;

    &__label {
        white-space: nowrap;
        display: flex;
        margin-right: $spacing-smaller;
    }
}
</style>
