import { render, staticRenderFns } from "./CampaignDataProvider.vue?vue&type=template&id=93f83f62"
import script from "./CampaignDataProvider.vue?vue&type=script&lang=js"
export * from "./CampaignDataProvider.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports