<template>
    <div class="user-permission-modal-table-wrapper">
        <Button
            v-if="!disabled"
            class="user-permission-modal-add-client"
            type="text"
            icon="md-person-add"
            data-testid="add-brand__button"
            @click="addNewClientRow"
        >
            Add Brand
        </Button>
        <Table
            :row-class-name="rowClassName"
            :columns="columns"
            :data="tableData"
            :show-header="!preview"
            class="user-permission-modal-table user-permission-modal-table--ghost"
        ></Table>
    </div>
</template>
<script>
import Vue from "vue";
import ClientSelect from "@/components/ManageUsers/ClientSelect";
import CampaignSelect from "@/components/ManageUsers/CampaignSelect";
import ClientsQuery from "@/apollo/queries/Clients.gql";

import { deepClone } from "@/utils";
import bus from "@/bus";

export default {
    name: "UserScopeSelect",
    props: {
        clients: {
            type: Object,
            required: true
        },
        clientCampaigns: {
            type: Object,
            required: true
        },

        data: {
            type: Array,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        preview: {
            type: Boolean,
            default: false
        },

        showEmptyRow: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            allClients: [],
            columns: [
                {
                    title: "Brand",
                    render: this.renderClientCell.bind(this),
                    className: "bordered-column client-name",
                    width: 120,
                    ellipsis: true
                },

                {
                    title: "Campaigns",
                    render: this.renderCampaignCell.bind(this),
                    className: "bordered-column client-campaigns",
                    width: 300,
                    ellipsis: true
                },
                {
                    title: " ",
                    align: "right",
                    className: "client-campaigns",
                    render: this.renderActionCell.bind(this),
                    width: 33
                }
            ],
            newRowData: {
                newRow: true,
                clientId: null,
                scopes: []
            },
            newRows: [],
            scope: {},
            userScope: []
        };
    },

    computed: {
        allClientsShown() {
            return Object.keys(this.clients).length + this.newRows.length >= this.allClients.length;
        },

        scopesToSave() {
            return Object.keys(this.scope).reduce((acc, clientId) => acc.concat(this.scope[clientId]), []);
        },

        tableData() {
            return this.newRows.concat(this.data);
        },

        userId() {
            return this.$store.state.auth.me && this.$store.state.auth.me.id;
        }
    },

    watch: {
        clients: {
            handler(clients) {
                const ids = Object.keys(clients);
                this.newRows = this.newRows.filter(r => !ids.includes(r.clientId));
            }
        }
    },

    created() {
        this.$apollo.queries.clients.refetch();

        if (!this.data.length && this.showEmptyRow) {
            this.newRows.push(deepClone(this.newRowData));
        }
    },

    methods: {
        addNewClientRow() {
            if (this.allClientsShown) {
                this.$snackbar.info("This user has access to all campaigns");
            } else {
                this.newRows.push(deepClone(this.newRowData));
            }
        },

        isClientDisabled(clientId) {
            return this.disabled || !this.$auth.hasScope({ clientId });
        },

        newRowToValue(scopes) {
            if (!scopes) {
                return [];
            }

            return scopes.map(scope => {
                if (typeof scope.campaignId === "undefined") {
                    return "all";
                }

                return {
                    clientId: scope.clientId,
                    _id: scope.campaignId
                };
            });
        },

        onUserScopeUpdate(data) {
            this.$emit("user-scope-update", data);
        },

        onNewRowClientChange(rowIndex, selectedClient) {
            this.newRows[rowIndex].clientId = selectedClient;
        },

        onScopeChange(clientId, newScope) {
            Vue.set(this.scope, clientId, this.rowToScope(clientId, newScope));

            this.$emit("input", this.scopesToSave);
        },

        removeClientScope(clientId, isNewRow) {
            if (isNewRow) {
                this.newRows = this.newRows.filter(r => r.clientId !== clientId);
                return;
            }

            this.$emit("remove-client-scope", clientId);
        },

        removeNewClientRow(rowIndex) {
            const removed = this.newRows.splice(rowIndex, 1);

            if (removed[0].clientId) {
                Vue.delete(this.scope, removed[0].clientId);
            }

            this.$emit("input", this.scopesToSave);
        },

        renderClientCell(h, params) {
            if (params.row.newRow) {
                return h(ClientSelect, {
                    props: {
                        excludedIds: Object.keys(this.clients).concat(this.newRows.map(r => r.clientId).filter(Boolean))
                    },
                    attrs: {
                        value: params.row.clientId,
                        transfer: true,
                        size: "small"
                    },
                    on: {
                        input: this.onNewRowClientChange.bind(this, params.index)
                    }
                });
            }

            return h("span", params.row.name);
        },

        renderCampaignCell(h, params) {
            if (params.row.newRow) {
                if (params.row.clientId) {
                    return h(CampaignSelect, {
                        props: {
                            clientId: params.row.clientId,
                            value: this.newRowToValue(this.scope[params.row.clientId])
                        },
                        on: {
                            input: this.onScopeChange.bind(this, params.row.clientId),
                            UserScopeUpdate: this.onUserScopeUpdate.bind(this)
                        }
                    });
                }

                return h("span", {
                    class: "select-client-notice",
                    domProps: {
                        innerHTML: "Please select the brand"
                    }
                });
            }

            return h(CampaignSelect, {
                attrs: {
                    title: params.row.name,
                    transfer: true
                },
                props: {
                    preview: this.preview,
                    clientId: params.row._id,
                    disabled: this.disabled,
                    value: this.clientCampaigns[params.row._id]
                },
                on: {
                    UserScopeUpdate: this.onUserScopeUpdate.bind(this)
                }
            });
        },

        renderActionCell(h, params) {
            if (this.isClientDisabled(params.row._id)) {
                return null;
            }

            return h("Icon", {
                class: "remove-client-scope",
                attrs: {
                    title: params.row.name,
                    "data-testid": "remove-client-scope__button"
                },
                props: {
                    type: "md-close"
                },
                on: {
                    click: params.row.newRow
                        ? this.removeNewClientRow.bind(this, params.index)
                        : this.removeClientScope.bind(this, params.row._id, params.row.newRow)
                }
            });
        },

        rowClassName(row) {
            if (row.newRow) {
                return "new-entry-row";
            }

            return "";
        },

        rowToScope(clientId, row) {
            return row
                .map(r => {
                    if (typeof r === "object") {
                        return {
                            clientId,
                            ...(!r.clientScope && { campaignId: r._id })
                        };
                    }

                    return null;
                })
                .filter(Boolean);
        }
    },

    apollo: {
        clients: {
            query: ClientsQuery,
            error(e) {
                bus.$emit("apolloErrorEvent", e);
            },
            result({ data: { clients } }) {
                this.isLoading = false;
                this.allClients = clients;
            }
        }
    }
};
</script>
