export default {
    bind(el, binding) {
        // Define Handler and cache it on the element
        const { bubble } = binding.modifiers;
        const handler = e => {
            if (bubble || (!el.contains(e.target) && el !== e.target)) {
                binding.value(e);
            }
        };
        // eslint-disable-next-line no-param-reassign
        el.__vueClickOutside__ = handler;

        // add Event Listeners
        document.addEventListener("click", handler, true);
    },

    unbind(el) {
        // Remove Event Listeners
        document.removeEventListener("click", el.__vueClickOutside__, true);
        // eslint-disable-next-line no-param-reassign
        el.__vueClickOutside__ = null;
    }
};
