<template>
    <div class="dropoff-chart" :class="classes">
        <report-metrics-data-provider v-if="!hasStats" @data="setMetricNames" />

        <div class="dropoff-chart-wrapper">
            <canvas ref="dropoff" class="dropoff-chart__chart" />
        </div>
    </div>
</template>

<script>
import { Chart } from "chart.js";
import { ColorsHEX } from "@/enums/reporting";
import { CreativeInsightsAction, CreativeInsightsGetters } from "@/store/modules/creativeInsights";
import ReportMetricsDataProvider from "@/components/Reporting/data/ReportMetricsDataProvider";

const metricNameToIndex = {
    video_started: 0,
    video_p25: 1,
    video_p50: 2,
    video_p75: 3,
    video_p95: 4,
    video_p100: 5,
    "video_play_actions|video_view": 0,
    "video_p25_watched_actions|video_view": 1,
    "video_p50_watched_actions|video_view": 2,
    "video_p75_watched_actions|video_view": 3,
    "video_p95_watched_actions|video_view": 4,
    "video_p100_watched_actions|video_view": 5,
    "video_play_actions|video_view_time": 0,
    activeViewPercentAudibleAndVisibleAtStart: 0,
    activeViewPercentAudibleAndVisibleAtFirstQuartile: 1,
    activeViewPercentAudibleAndVisibleAtMidpoint: 2,
    activeViewPercentAudibleAndVisibleAtThirdQuartile: 3,
    activeViewPercentAudibleAndVisibleAtCompletion: 4
};

const SCREENSHOT_INTERVAL = 0.5;

export default {
    name: "DropoffChart",
    components: { ReportMetricsDataProvider },
    props: {
        currentTime: {
            type: Number,
            default: 0
        },

        duration: {
            type: [Number, String],
            default: 10
        },

        videoMetrics: {
            type: Object,
            required: true,
            default() {
                return {};
            }
        },

        attributes: {
            type: Array,
            required: true,
            default() {
                return [];
            }
        },

        scaleX: {
            type: Object
        }
    },

    data() {
        return {
            chart: undefined
        };
    },

    computed: {
        timelineTicks() {
            const duration = Number.parseFloat(this.duration);
            return [...Array(Math.ceil(duration * (1 / SCREENSHOT_INTERVAL)))].map((v, i) => i * SCREENSHOT_INTERVAL);
        },

        classes() {
            return this.hasDropoffMetrics ? "" : "dropoff-chart--no-metrics";
        },

        dropoffData() {
            if (!this.videoMetrics) {
                return [];
            }

            return Object.keys(metricNameToIndex).reduce((acc, metric) => {
                acc[metricNameToIndex[metric]] = this.videoMetrics[metric];
                return acc;
            }, []);
        },

        dropoffChartData() {
            if (!this.dropoffData.length) {
                return [];
            }

            return this.getDropoffPoints(this.dropoffData);
        },

        dropoffAvgChartData() {
            const data = Object.keys(metricNameToIndex).reduce((acc, metric) => {
                if (this.metricStats[metric]) {
                    acc.push(this.metricStats[metric].avg);
                }

                return acc;
            }, []);

            if (!data.length) {
                return [];
            }

            return this.getDropoffPoints(data);
        },

        datasets() {
            if (!this.duration) {
                return [];
            }

            return [
                {
                    label: "View-through rate",
                    backgroundColor: ColorsHEX.red,
                    borderColor: ColorsHEX.red,
                    data: this.dropoffChartData,
                    fill: false,
                    pointRadius: 0,
                    type: "line",
                    borderWidth: 2,
                    // borderDash: [10, 10],
                    tension: 0.2,
                    order: 0
                },
                {
                    label: "Average View-through rate",
                    backgroundColor: ColorsHEX.red,
                    borderColor: `${ColorsHEX.red}aa`,
                    data: this.dropoffAvgChartData,
                    fill: false,
                    pointRadius: 0,
                    borderDash: [8, 2],
                    borderWidth: 1,
                    type: "line",
                    order: 1,
                    tension: 0.2
                }
            ];
        },

        hasAvgDropoffData() {
            return this.dropoffAvgChartData && this.dropoffAvgChartData.some(({ y }) => !Number.isNaN(y) && y);
        },

        hasDropoffData() {
            return this.dropoffData && this.dropoffData.some(val => val);
        },

        hasDropoffMetrics() {
            return this.hasDropoffData || this.hasAvgDropoffData;
        },

        hasStats() {
            return !!Object.keys(this.metricStats).length;
        },

        kpi() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricName];
        },

        metricStats() {
            return this.$store.state.creativeInsights.reportMetricStats;
        },

        options() {
            return {
                plugins: {
                    tooltips: {
                        enabled: true
                    },
                    hover: {
                        animationDuration: 0
                    },
                    legend: {
                        display: this.hasDropoffData
                    }
                },
                layout: {
                    padding: { left: 7, right: 48 }
                },
                maintainAspectRatio: false,
                responsive: true,
                scales: this.scales
            };
        },

        scales() {
            return {
                x: {
                    ...this.scaleX,
                    grid: {
                        ...this.scaleX.grid,
                        display: true
                    },
                    id: "timeline",
                    position: "bottom"
                },
                y: {
                    id: "dropoff",
                    grid: {
                        display: false
                    },
                    min: 0,
                    max: 1.1,
                    scaleLabel: {
                        display: false
                    },
                    ticks: {
                        display: false,
                        mirror: true,
                        callback: value => {
                            return value > 1 ? "" : `${value * 100}%`;
                        }
                    }
                }
            };
        }
    },

    watch: {
        attributes() {
            this.chart.options.scales = this.scales;
            this.chart.data.datasets = this.datasets;
            this.chart.update();
        },

        dropoffAvgChartData() {
            this.chart.options.scales = this.scales;
            this.chart.data.datasets = this.datasets;
            this.chart.update();
        },

        duration() {
            this.chart.options.scales = this.scales;
            this.chart.data.datasets = this.datasets;
            this.chart.update();
        },

        has() {
            this.chart.options.plugins.legend = this.hasDropoffData;
            this.chart.update();
        },

        kpi() {
            this.chart.options.scales = this.scales;
            this.chart.data.datasets = this.datasets;
            this.chart.update();
        },

        videoMetrics() {
            this.chart.options.scales = this.scales;
            this.chart.data.datasets = this.datasets;
            this.chart.update();
        }
    },

    mounted() {
        this.chart = new Chart(this.$refs.dropoff, {
            type: "line",
            options: this.options,
            data: {
                datasets: this.datasets
            }
        });
    },

    methods: {
        getDropoffPoints(data) {
            const duration = Number.parseFloat(this.duration);

            if (this.dropoffData.length === 5) {
                return [
                    { y: data[0] / data[0], x: duration * 0 },
                    { y: data[1] / data[0], x: duration * 0.25 },
                    { y: data[2] / data[0], x: duration * 0.5 },
                    { y: data[3] / data[0], x: duration * 0.75 },
                    { y: data[4] / data[0], x: duration }
                ];
            }

            return [
                { y: data[0] / data[0], x: duration * 0 },
                { y: data[1] / data[0], x: duration * 0.25 },
                { y: data[2] / data[0], x: duration * 0.5 },
                { y: data[3] / data[0], x: duration * 0.75 },
                { y: data[4] / data[0], x: duration * 0.95 },
                { y: data[5] / data[0], x: duration }
            ];
        },

        setMetricNames({ metrics, metricStats }) {
            this.$store.dispatch(CreativeInsightsAction.SetReportMetricNames, metrics);
            this.$store.dispatch(CreativeInsightsAction.SetReportMetricStats, metricStats);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
.dropoff-chart {
    height: 215px;
    padding: $spacing-small 0 $spacing-semi-small 0;
    border: 1px solid $grey3;
    width: calc(100% - 10px);

    &--no-metrics {
        height: 45px;

        .dropoff-chart-wrapper {
            height: 45px;
        }
    }

    &-chart {
        width: calc(100% - 10px);
    }

    &-wrapper {
        height: 205px;
    }
}
</style>
