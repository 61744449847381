const delay = ms =>
    new Promise(res => {
        setTimeout(res, ms);
    });
export default {
    data() {
        return {
            bodyDimensionsPixels: {
                height: undefined,
                width: undefined
            },
            scalingFactor: "scale",
            bodyHeightOffset: 0,
            bodyWidthOffset: -50
        };
    },

    computed: {},

    created() {
        window.addEventListener("resize", this.setBodyDimensionsPixels, true);
    },

    mounted() {
        this.setBodyDimensionsPixels();
        this.waitAndRecalculate();
    },

    beforeDestory() {
        window.removeEventListener("resize", this.setBodyDimensionsPixels, true);
    },

    methods: {
        async setBodyDimensionsPixels() {
            const el = this.$refs.body;

            if (el) {
                const bodyRect = el.getBoundingClientRect();
                if (
                    this.bodyDimensionsPixels.height !== bodyRect.height ||
                    this.bodyDimensionsPixels.width !== bodyRect.width
                ) {
                    // -40 because we need to discount box's padding
                    this.bodyDimensionsPixels = {
                        height: bodyRect.height + this.bodyHeightOffset,
                        width: bodyRect.width + this.bodyWidthOffset
                    };
                }

                if (bodyRect.height === 0 && bodyRect.width === 0) {
                    setTimeout(() => {
                        this.setBodyDimensionsPixels();
                    }, 100);
                }
            }
        },

        async waitAndRecalculate() {
            await delay(2000);
            await this.setBodyDimensionsPixels();
        }
    }
};
