import { ObjectID } from "bson";
import { deepClone, snackbar } from "@/utils";
import { GraphQLError } from "../GraphQLError";
import { HoxtonCliClient } from "../HoxtonCliClient";

export const newId = () => new ObjectID().toHexString();

export const schemaVersion = "v1";
export const persistenceKey = "editorState.json";

// State to store the local copy of data created by usign the sandbox
let localState;
const defaultState = {
    schemaVersion,
    masterTemplates: {},
    editableGroupValues: {},
    editables: {},
    overwrites: []
};
// Store the manifests separately so they never get persisted to disk
const manifests = {};

export const resetState = () => {
    // Clone the defaultState object so we get structure but don't accidentally change it during runtime
    localState = deepClone(defaultState);
};

// Setup our empty local state
resetState();

export const getState = () => {
    return localState;
};

export const setState = state => {
    // When setting the state we need to check the schema version is what we expect so we can ensure that things operate correctly.
    if (state.schemaVersion !== schemaVersion) {
        snackbar.warning("Invalid state data. Wrong schema version.");
        return;
    }
    // Ensure that the properties on the default state exist even if they are overwritten
    localState = { ...defaultState, ...deepClone(state) };
};

export const getManifest = path => {
    return manifests[path];
};

export const setManifest = (path, manifest) => {
    manifests[path] = manifest;
    return manifests[path];
};

export const persistData = async (serverUri, includeOverwrites = false) => {
    try {
        // If we are inclusing the overwrites then we can send the whole state object to the CLI API
        const client = new HoxtonCliClient(serverUri);
        if (includeOverwrites) {
            await client.setData(persistenceKey, getState());
            return;
        }
        // Otherwise we need to get the currently persisted overwrites and add them to our state object so they are not lost on write
        const currentState = (await client.getData(persistenceKey)) || defaultState;
        // Remove the in-memory overwrites
        const { overwrites, ...rest } = getState();
        await client.setData(persistenceKey, {
            // Make a new state from the overwrites in the persisted file
            overwrites: currentState.overwrites,
            // And everything else from our local state
            ...rest
        });
    } catch (err) {
        // Make sure our errors appear as snackbar errors
        throw new GraphQLError(`Unable to persist data to working directory. ${err.message}`);
    }
};

/**
 * Gets the master template we created when we processed a path from the CLI
 * If a Master Template does not exist for that path then create one
 * @param {String} path A path provided by the CLI
 */
export const getMasterTemplateByPath = path => {
    if (!localState.masterTemplates[path]) {
        localState.masterTemplates[path] = {
            _id: newId(),
            path
        };
    }

    return localState.masterTemplates[path];
};

/**
 * Find the master template in the local state with the provided id.
 * Useful for processing GQL requests that provide a specific id.
 * @param {String} mtId The id of the master template
 */
export const getMasterTemplateById = mtId => {
    const template = Object.values(localState.masterTemplates).find(mt => {
        // Check if the provided id matches a template that corresponds to a file
        if (mt._id === mtId) {
            return true;
        }

        // Otherwise check for the ids we created when making a new size as the id
        //  could be for a virtual template
        if (mt.sizes) {
            return mt.sizes.some(size => size._id === mtId);
        }

        return false;
    });
    if (!template) {
        // Make sure the error is displayed in the UI by using a GraphQLError
        throw new GraphQLError(`Template with id ${mtId} cannot be found`);
    }
    return template;
};

/**
 * Takes a template object and sets it in local state. This is not a Master Template
 * as used in the FE but rather a reference to the path, _id and sizes that have been
 * created for a template returned by the CLI
 * @param {Object} template
 */
export const setMasterTemplate = template => {
    if (!template.path) {
        throw new GraphQLError("Template has no set path");
    }
    localState.masterTemplates[template.path] = {
        ...localState.masterTemplates[template.path],
        ...template
    };
    return localState.masterTemplates[template.path];
};

/**
 * Gets the id of an editable
 * If the editable does not exist in the local state then it is created
 *
 * @param {*} groupName Name of the group the editable is in
 * @param {*} name The name of the editable
 */
export const getEditableId = name => {
    const key = `${name}`;
    if (!localState.editables[key]) {
        localState.editables[key] = {
            _id: newId()
        };
    }

    return localState.editables[key]._id;
};

export const getEditableGroupValue = id => {
    return localState.editableGroupValues[id];
};

/**
 * Get all of the group values in the provided group
 * @param {String} groupName
 */
export const getEditableGroupValues = groupName => {
    return Object.values(localState.editableGroupValues).filter(egv => egv.editableGroupName === groupName);
};

/**
 * Sets a group value object in the local state
 * @param {String} id The id of the editable group value
 * @param {Object} egv The object to set
 */
export const setEditableGroupValue = (id, egv) => {
    localState.editableGroupValues[id] = egv;
    return egv;
};

/**
 * Removes a group value object in the local state
 * @param {String} id The id of the editable group value
 */
export const removeEditableGroupValue = id => {
    delete localState.editableGroupValues[id];
};

/**
 * Utility to compare two overwrites for equivalency.
 * Used to find them in the localState array
 * @param {Object} o1 The first overwrite
 * @param {Object} o2 The other overwrite to compare it to
 */
const compareOverwrites = (o1, o2) => {
    if (o1.editableId !== o2.editableId) {
        return false;
    }
    if (o1.scope !== o2.scope) {
        return false;
    }
    if (o1.language !== o2.language) {
        return false;
    }
    if (o1.masterTemplateId !== o2.masterTemplateId) {
        return false;
    }

    const egv1 = o1.editableGroupValueIds;
    const egv2 = o2.editableGroupValueIds;
    // If the two lists have different lengths then they cannot be the same
    if (egv1.length !== egv2.length) {
        return false;
    }

    // Now that we know the two lists are the same length, if all the items in the
    //  first list are in the second then the lists must be equal
    const intersection = egv1.filter(egvId => egv2.includes(egvId));
    if (egv1.length !== intersection.length) {
        return false;
    }

    // If all of the compared properties are the same then the overwrites are equivalent
    return true;
};

/**
 * Stores an overwrite in the local state
 * @param {Object} overwrite
 */
export const setOverwrite = overwrite => {
    // Overwrites are stored in an array, and we do not always have their id passed in so we need to find them by comparison
    const index = localState.overwrites.findIndex(ovr => compareOverwrites(overwrite, ovr));
    // If the index can be found then we update it with the passed in value
    if (index > -1) {
        localState.overwrites[index] = {
            ...localState.overwrites[index],
            ...overwrite
        };
        return localState.overwrites[index];
    }

    // Otherwise we create a new one, add it to our array and then return it
    const newOverwrite = {
        ...overwrite,
        _id: overwrite._id || newId(),
        __typename: "Overwrite"
    };
    localState.overwrites.push(newOverwrite);
    return newOverwrite;
};

/**
 * Removes an overwrite from local state by id
 * @param {String} id
 */
export const removeOverwrite = id => {
    const index = localState.overwrites.findIndex(ovr => ovr._id === id);
    if (index > -1) {
        return localState.overwrites.splice(index, 1);
    }
    return undefined;
};

/**
 * Removes overwrites for the specified group id.
 * If the overwrite is used in more than one group then it will not be removed.
 * @param {String} egvId
 */
export const removeOverwritesByGroupValueId = egvId => {
    const overwrites = localState.overwrites.filter(overwrite => overwrite.editableGroupValueIds.includes(egvId));
    return overwrites.reduce((acc, overwrite) => {
        const o = { ...overwrite };
        o.editableGroupValueIds = o.editableGroupValueIds.filter(id => id !== egvId);
        const removed = removeOverwrite(o._id);
        if (!o.editableGroupValueIds.length) {
            acc.push(removed);
        } else {
            // Set it with new groupValueIds
            setOverwrite(o);
        }
        return acc;
    }, []);
};

/**
 * Return all of the overwrites stored in the local state
 */
export const getOverwrites = () => {
    const editableGroupValueIds = Object.keys(localState.editableGroupValues);
    // Remove any overwrites which do not have valid group values
    const remove = localState.overwrites.reduce((acc, overwrite) => {
        if (!overwrite.editableGroupValueIds.some(egvId => editableGroupValueIds.includes(egvId))) {
            acc.push(overwrite._id);
        }
        return acc;
    }, []);
    remove.forEach(id => {
        removeOverwrite(id);
    });
    return localState.overwrites;
};
