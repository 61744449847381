<template>
    <div />
</template>

<script>
import { AuthGetters } from "@/store/modules/auth";

const ResourceFetchStatus = {
    inProgress: "IN_PROGRESS",
    available: "AVAILABLE",
    failed: "FAILED"
};

export default {
    name: "CampaignDataProvider",

    props: {
        options: {
            type: Object
        },

        refreshCounter: {
            type: Number,
            default: 0
        },

        requestOptions: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    data() {
        return {
            isLoading: false,
            retry: false,
            fetchCount: 0
        };
    },
    computed: {
        baseRequestOptions() {
            return {
                mode: "cors",
                cache: "no-cache",
                headers: {
                    "x-api-key": this.apiInfo.apiKey,
                    "Content-Type": "application/json"
                },
                credentials: "same-origin",
                referrerPolicy: "no-referrer"
            };
        },

        apiInfo() {
            return this.$store.getters[AuthGetters.contentGenerationApiInfo];
        },

        userId() {
            return this.$store.state.auth.me.reference;
        },

        requestBody() {
            return this.options;
        },

        requestEndpoint() {
            // if changing the domain for dev purposes make sure to change the api key as well
            return `${this.apiInfo.url}/v1/resource/request/${this.userId}`;
        },

        mergedRequestOptions() {
            return {
                ...this.baseRequestOptions,
                method: "POST",
                body: JSON.stringify(this.requestBody),
                redirect: "follow",
                ...this.requestOptions
            };
        }
    },

    watch: {
        options: {
            immediate: true,
            deep: true,
            handler() {
                this.fetch();
            }
        },

        refreshCounter() {
            this.fetch();
        }
    },

    methods: {
        async fetch() {
            this.fetchCount += 1;

            try {
                this.isLoading = true;
                this.$emit("loading", this.isLoading);

                const response = await fetch(this.requestEndpoint, this.mergedRequestOptions);
                if (response.ok) {
                    const responseJson = await response.json();
                    if (responseJson.data && responseJson.data.status === ResourceFetchStatus.inProgress) {
                        setTimeout(() => {
                            console.log("resource not ready yet, repeating");

                            this.fetch();
                        }, 2500);
                        return;
                    }
                    if (responseJson.data && responseJson.data.status === ResourceFetchStatus.failed) {
                        try {
                            this.$emit("error", { message: JSON.parse(responseJson.data.output.fail) });
                        } catch (e) {
                            console.log(e);
                            this.$emit("error", { message: responseJson.data.output.fail });
                        }
                        return;
                    }

                    this.$emit("data", { ...responseJson.data, searchOptions: this.searchOptions });
                } else {
                    this.$emit("error", { response });
                }
                this.isLoading = false;
                this.$emit("loading", this.isLoading);
            } catch (e) {
                this.$emit("error", e);
                this.isLoading = false;
                this.$emit("loading", this.isLoading);
                throw e;
            }
        }
    }
};
</script>
