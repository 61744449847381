<template>
    <div :class="classes">
        <template v-if="isPublished">
            <Tag
                v-if="isPublishedToDoubleClick"
                color="success"
                type="border"
                @click.native="showPlatformPreview(Platform.DoubleClick)"
            >
                {{ PlatformLabel[Platform.DoubleClick] }}
            </Tag>
            <Tag
                v-if="isPublishedToSizmek"
                color="success"
                type="border"
                @click.native="showPlatformPreview(Platform.Sizmek)"
            >
                {{ PlatformLabel[Platform.Sizmek] }}
            </Tag>
            <Tag
                v-if="isPublishedToOpenDC"
                color="success"
                type="border"
                @click.native="showPlatformPreview(Platform.OpenDC)"
            >
                {{ PlatformLabel[Platform.OpenDC] }}
            </Tag>
        </template>
        <template v-else>
            <Tag class="library-deliverable-table-published-status-cell__tag--disabled" color="default" type="border">
                Not Published
            </Tag>
        </template>
    </div>
</template>

<script>
import { Platform, PlatformLabel } from "@/enums/platforms";

export default {
    props: {
        isPublishedToDoubleClick: {
            type: Boolean
        },
        isPublishedToSizmek: {
            type: Boolean
        },
        isPublishedToOpenDC: {
            type: Boolean
        }
    },
    computed: {
        classes() {
            const classes = ["library-deliverable-table-published-status-cell"];
            const singleTag =
                [this.isPublishedToDoubleClick, this.isPublishedToSizmek, this.isPublishedToOpenDC].filter(v => v)
                    .length < 2;
            if (singleTag) {
                classes.push("library-deliverable-table-published-status-cell--full-width-tags");
            }
            return classes;
        },
        isPublished() {
            return this.isPublishedToDoubleClick || this.isPublishedToSizmek || this.isPublishedToOpenDC;
        }
    },
    created() {
        this.Platform = Platform;
        this.PlatformLabel = PlatformLabel;
    },
    methods: {
        getTagColor(isPublished) {
            return isPublished ? "success" : "default";
        },
        showPlatformPreview(platform) {
            this.$emit("showPlatformPreview", platform);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.library-deliverable-table-published-status-cell {
    overflow: hidden;
}

.library-deliverable-table-published-status-cell .ivu-tag:last-child {
    margin-right: 0;
}

.library-deliverable-table-published-status-cell--full-width-tags {
    .ivu-tag {
        text-align: center;
        width: 100%;
    }
}

.library-deliverable-table-published-status-cell__tag--disabled {
    color: $grey5;
    opacity: 0.9;
    pointer-events: none;

    .ivu-tag-text {
        color: $grey5;
    }
}
</style>
