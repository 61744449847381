import { getCustomTooltips } from "@/utils/chart";

export default {
    data() {
        return {
            tooltipLines: [],
            tooltipImageUrls: [],
            tooltipStyle: {},
            tooltipCarouselTimeout: undefined
        };
    },

    methods: {
        customTooltip(chartJsTooltip) {
            try {
                getCustomTooltips(this, chartJsTooltip);
            } catch (e) {
                console.log(e);
            }
        }
    }
};
