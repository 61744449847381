import { checkEmptySpaces } from "@/utils";

export default {
    props: {
        formData: {
            type: Object
        },
        actionLabel: {
            type: String
        }
    },

    data() {
        return {
            baseValidationRules: {
                accountName: [
                    {
                        required: true,
                        message: "Please enter an account name",
                        trigger: "blur"
                    },
                    {
                        message: "Account name must not start with space",
                        validator: (rule, value) => checkEmptySpaces(value),
                        trigger: "blur"
                    }
                ],
                accountType: [
                    {
                        required: true,
                        message: "Please select an account type.",
                        trigger: "change"
                    }
                ]
            }
        };
    },

    methods: {
        onChangeAccountType(accountType) {
            this.formData.accountType = accountType;
            this.$emit("changeAccountType", accountType);
        },

        onSubmitFormAccount() {
            this.$refs.addAccountForm.validate(valid => {
                if (!valid) {
                    this.$snackbar.error("Please correctly fill in the required fields");
                    return;
                }

                this.$emit("submitFormData", {
                    formData: this.formData
                });
            });
        }
    }
};
