<template>
    <hox-modal class="importExportModal" @close="close">
        <template #header>Import/Export</template>

        <template>
            <hox-alert type="info" class="importExportModal__howto">
                <template #title>To upload content</template>
                <template #content>
                    <ol class="importExportModal__howto-list">
                        <li>
                            <span class="link-like" @click="downloadTemplate">Download template</span>
                        </li>
                        <li>Fill in the content in the the file</li>
                        <li>Upload the content XSLX file</li>
                    </ol>
                </template>
            </hox-alert>

            <div class="importExportModal__upload-controls">
                <xls-upload-button
                    @click.native.stop="valuesUploadCompleted = false"
                    @load="onXlsValuesLoad"
                    @error="onValuesImportError"
                >
                    <Button type="primary" class="">Upload Selection</Button>
                </xls-upload-button>
            </div>
            <div class="importExportModal__upload-results">
                <hox-alert v-if="hasValuesError" type="danger" class="importExportModal__upload-result">
                    <template #title>There were problems reading this sheet</template>

                    <template #content>
                        <ul v-if="valuesErrorDetails" class="importExportModal__upload-results-error-list">
                            <li v-for="detail in valuesErrorDetails" :key="detail">{{ detail }}</li>
                        </ul>
                    </template>
                </hox-alert>
            </div>
        </template>

        <template #footer>
            <div class="share-preview-modal__footer-button-wrapper">
                <Button @click="close">Close</Button>
            </div>
        </template>
    </hox-modal>
</template>

<script>
import HoxModal from "@/components/Modal/Modal/Modal";
import editableMethodsMixin from "@/mixins/editableMethodsMixin";
import { CampaignGetters } from "@/store/modules/campaign";
import ImportExport from "@/services/ImportExport";
import XlsUploadButton from "@/components/Campaign/XlsUploadButton";

export default {
    name: "ImportExportLibraryModal",
    components: {
        XlsUploadButton,
        HoxModal
    },
    mixins: [editableMethodsMixin],

    data() {
        return {
            hasValuesError: false,
            validRows: [],
            valuesErrorDetails: [],
            valuesUploadCompleted: false
        };
    },

    computed: {
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        campaignLanguages() {
            return this.$store.state.campaign.languages;
        },

        editableGroupValues() {
            return this.$store.state.campaign.editableGroupValues;
        },

        groupValueNamesByGroup() {
            return this.$store.getters[CampaignGetters.groupValueNamesByGroup];
        },

        masterTemplateNameMap() {
            return this.$store.getters[CampaignGetters.masterTemplatesBySize];
        },

        validGroupValuesNamesByGroupValue() {
            return this.$store.getters[CampaignGetters.groupValueNamesByGroup];
        },

        validLanguages() {
            return this.campaignLanguages;
        },

        validMasterTemplateLabel() {
            return Object.keys(this.masterTemplateNameMap);
        }
    },

    created() {
        this.importExportService = new ImportExport(this.$apollo, this.$store);
    },

    methods: {
        async onXlsValuesLoad(importedData) {
            this.valuesUploadCompleted = false;
            this.hasValuesError = false;
            this.valuesErrorDetails = [];

            if (!importedData.sheets.length) {
                this.valuesErrorDetails = [
                    "The file is either empty or is incorrectly formatted. Please check your data and try again."
                ];
                this.hasValuesError = true;
                return;
            }

            await this.$nextTick();

            const processingErrors = [];
            const inputSheet = importedData.Input;
            await this.processInputSelect(inputSheet.sheetData);

            if (processingErrors.length) {
                this.hasValuesError = true;
                this.valuesErrorDetails = processingErrors.flatMap(({ details }) => details);
            }

            this.valuesUploadCompleted = true;
        },

        async processInputSelect(dataArray) {
            this.validRows = [];
            this.hasValuesError = false;
            this.valuesErrorDetails = [];
            // empty value represents templateName header
            const [, , ...groupValueNames] = dataArray.shift();

            const { validRows, sameValueIndexes } = dataArray.reduce(
                (acc, row, i) => {
                    const rowIndex = i + 3;
                    const validationError = this.getValidationError(row, groupValueNames, rowIndex);
                    const valueString = JSON.stringify(row);
                    if (acc.sameValueIndexes[valueString]) {
                        acc.sameValueIndexes[valueString].push(rowIndex);
                    } else {
                        acc.sameValueIndexes[valueString] = [rowIndex];
                    }

                    if (validationError.length) {
                        this.hasValuesError = true;
                        this.valuesErrorDetails.push(...validationError);
                        return acc;
                    }

                    acc.validRows.push(this.processRow(row, groupValueNames));
                    return acc;
                },
                { validRows: [], sameValueIndexes: {} }
            );

            Object.entries(sameValueIndexes).forEach(([values, indexes]) => {
                if (indexes.length > 1) {
                    this.hasValuesError = true;
                    this.valuesErrorDetails.push(
                        `Rows ${indexes.join(", ")} have duplicated values (${JSON.parse(values).join(", ")})`
                    );
                }
            });

            if (this.hasValuesError) {
                this.validRows = validRows;
                return;
            }

            this.$emit("selectedDeliverablesChanged", validRows);
        },

        close() {
            this.$emit("close");
        },

        downloadTemplate() {
            this.$emit("exportTemplate");
        },

        getValidationError(row, groupNames, rowNumber) {
            const [size, language, ...values] = row;
            const errors = [];
            if (!this.validMasterTemplateLabel.includes(size)) {
                errors.push(`Invalid size at row ${rowNumber}`);
            }

            if (!this.validLanguages.includes(language)) {
                errors.push(`Invalid language at row ${rowNumber}`);
            }

            values.forEach((value, i) => {
                if (!this.validGroupValuesNamesByGroupValue[groupNames[i]].editableGroupValuesNames.includes(value)) {
                    errors.push(`Invalid value "${value}" for group "${groupNames[i]}" at row ${rowNumber}`);
                }
            });

            return errors;
        },

        onValuesImportError(e) {
            this.hasValuesError = true;
            this.valuesErrorDetails = [e.message];
        },

        processRow(row, groupNames) {
            const [size, language, ...values] = row;
            const editableGroupValueIds = values.map(
                (value, i) => this.validGroupValuesNamesByGroupValue[groupNames[i]].editableGroupValuesNamesMap[value]
            );
            // todo: what to do with duplicated labels?
            const masterTemplateId = this.masterTemplateNameMap[size][0];

            return {
                language,
                masterTemplateId,
                editableGroupValueIds
            };
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.importExportModal {
    &__navigation {
        margin-bottom: $spacing;
    }

    &__howto {
        &-list {
            padding-left: $spacing;
        }
    }

    &__files {
        padding-left: $spacing;
        margin-bottom: $spacing;
    }

    &__upload-result {
        margin-top: $spacing;
    }

    &__upload-results-error-list {
        padding-left: $spacing;
        word-break: break-word;
    }

    &__upload-controls {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .labelled-switch {
            flex: 0;

            &__label {
                white-space: nowrap;
            }
        }
    }
}
</style>
