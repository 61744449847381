<template>
    <div>
        <!--
            The user has selected at least 1 size, language and group value
            but has no deliverables due to the QA status and/or published status
        -->
        <hox-empty-message v-if="isFilteringByMetadataSearch">
            <template #title>No deliverables with the selected filters and column search terms could be found</template>
        </hox-empty-message>
        <!--
            The user has selected at least 1 size, language and group value
            but has no deliverables due to the QA status and/or published status
        -->
        <hox-empty-message v-else-if="isFilteringByStatus && isFilteringByPublishedStatus">
            <template #title>
                No deliverables with the selected languages, sizes and group values have the QA status "
                <em>{{ statusFilterLabel }}</em>
                " and the published status "
                <em>{{ publishedStatusFilterLabel }}</em>
                "
            </template>
        </hox-empty-message>
        <!--
            The user has selected at least 1 size, language and group value
            but has no deliverables due to the published status.
        -->
        <hox-empty-message v-else-if="isFilteringByPublishedStatus">
            <template #title>
                No deliverables with the selected languages, sizes and group values have the published status "
                <em>{{ publishedStatusFilterLabel }}</em>
                "
            </template>
        </hox-empty-message>
        <!--
            The user has selected at least 1 size, language and group value
            but has no deliverables due to the visibleTo Filter.
        -->
        <hox-empty-message v-else-if="isFilteringByVisibleTo">
            <template #title>
                No deliverables with the selected languages, sizes and group values are visible to selected role
            </template>
        </hox-empty-message>
        <!--
            The user has selected at least 1 size, language and group value
            but has no deliverables due to the QA status.
        -->
        <hox-empty-message v-else-if="isFilteringByStatus">
            <template #title>
                No deliverables with the selected languages, sizes and group values have the QA status "
                <em>{{ statusFilterLabel }}</em>
                "
            </template>
        </hox-empty-message>

        <hox-empty-message v-else-if="isFilteringByCommentedOnBy">
            <template #title>
                No deliverables with the selected languages, sizes and group values that have "
                <em>{{ commentedOnByFilterLabel }}</em>
                " comments
            </template>
        </hox-empty-message>

        <hox-empty-message v-else-if="isFilteringByUserStatus">
            <template #title>
                No deliverables with the selected languages, sizes and group values that match your selection in Review
                Status filter
            </template>
        </hox-empty-message>

        <!--
            The user has selected at least 1 size, language and group value but
            can not view any deliverables, most likely due to their role.
        -->
        <hox-empty-message v-else>
            <template #title>There are no deliverables with the selected languages, sizes and group values</template>
        </hox-empty-message>
    </div>
</template>

<script>
export default {
    props: {
        isFilteringByCommentedOnBy: {
            type: Boolean
        },

        isFilteringByMetadataSearch: {
            type: Boolean
        },

        isFilteringByPublishedStatus: {
            type: Boolean
        },

        isFilteringByStatus: {
            type: Boolean
        },

        isFilteringByUserStatus: {
            type: Boolean
        },

        isFilteringByVisibleTo: {
            type: Boolean
        },

        publishedStatusFilterLabel: {
            type: String
        },

        statusFilterLabel: {
            type: String
        },

        userStatusFilterLabel: {
            type: String
        },

        commentedOnByFilterLabel: {
            type: String
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
</style>
