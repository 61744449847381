<template>
    <div :class="{ 'qa-creative__has-selection': selectedDeliverable }" class="qa-creative__body">
        <portal to="creativeSizeFilter">
            <div v-if="hasDeliverables">
                <Dropdown
                    placement="bottom-start"
                    trigger="custom"
                    :visible="sizeFilterIsVisible"
                    data-testid="qa-drawer__select--sizes"
                    @on-clickoutside="toggleDropdown"
                >
                    <hox-dropdown-button @click="toggleDropdown">
                        <slot>Ad Sizes</slot>
                    </hox-dropdown-button>
                    <template #list>
                        <qa-drawer-tab-select-templates
                            :visible-master-template-ids="visibleMasterTemplateIds"
                            @select="setVisibleMasterTemplateIds"
                        />
                    </template>
                </Dropdown>
            </div>
        </portal>

        <div ref="body" class="qa-creative__body-content" @click.self="unsetSelectedDeliverable">
            <div v-if="hasErrorLoadingDeliverables" class="qa-creative__alert-wrapper">
                <hox-alert type="danger">
                    <template #title>Uh oh!</template>
                    <template #content>
                        <p>Sorry! We could not get your ads this time. Try again a moment.</p>
                    </template>
                    <template #actionItems>
                        <Button type="primary" @click="getDeliverablesForCreative">
                            Retry getting deliverables
                        </Button>
                    </template>
                </hox-alert>
            </div>
            <template v-else-if="hasDeliverables">
                <template v-if="hasSizesSelected">
                    <div
                        v-for="constructedDeliverable of deliverablesSortedBySize"
                        :key="constructedDeliverable.idHash"
                        class="qa-creative__banner-wrapper"
                        :class="{
                            'qa-creative__banner-wrapper--visible': isVisible(constructedDeliverable),
                            'qa-creative__banner-wrapper--single-visible': isTheOnlyVisible(constructedDeliverable)
                        }"
                        @click.self="unsetSelectedDeliverable"
                    >
                        <qa-banner
                            v-show="isVisible(constructedDeliverable)"
                            :max-height="bodyDimensionsPixels.height"
                            :max-width="bodyDimensionsPixels.width"
                            :scaling-factor="scalingFactor"
                            :deliverable="constructedDeliverable"
                            :external-annotations="externalAnnotations"
                            :internal-annotations="internalAnnotations"
                            :is-selected="
                                selectedDeliverable && selectedDeliverable.idHash === constructedDeliverable.idHash
                            "
                            :selected-annotation="selectedAnnotation"
                            :show-resolved="showResolved"
                            @annotationAdded="addAnnotation"
                            @annotationSelected="setSelectedAnnotation"
                            @select="setSelectedDeliverable"
                            @bannerTimeUpdated="onBannerTimeUpdate($event, constructedDeliverable)"
                            @statusHasBeenUpdated="$emit('statusHasBeenUpdated')"
                            @userQaStatusHasBeenUpdated="$emit('userQaStatusHasBeenUpdated', $event)"
                        />
                    </div>
                </template>

                <!--
          When there no sizes selected (and as a result no deliverables to show).
        -->
                <hox-empty-message v-else class="qa-creative__empty-message">
                    <template #title>No sizes selected</template>
                    <template #content>
                        <p>Select at least one size to QA your ads.</p>
                    </template>
                </hox-empty-message>
            </template>

            <!--
        When there are sizes selected, but no deliverables to show (due to role
        view restrictions).
      -->
            <hox-empty-message v-else-if="!isLoadingDeliverables" class="qa-creative__empty-message">
                <template #title>No ads to QA</template>
                <template #content>
                    <p>No ads for your selected sizes.</p>
                </template>
            </hox-empty-message>
        </div>
    </div>
</template>

<script>
import HoxEmptyMessage from "@/components/common/EmptyMessage";
import QaBanner from "@/components/Qa/QaBanner";
import { AnnotationType } from "@/enums/annotations";
import { BannerGroup } from "@/enums/banners";
import DeliverablesService from "@/services/Deliverables";
import deliverableMethodsMixin from "@/mixins/deliverableMethodsMixin";
import URLManager from "@/services/URLManager";
import { DeliverableLibraryAction } from "@/store/modules/deliverableLibrary";
import QaDrawerTabSelectTemplates from "@/components/Qa/DrawerTab/QaDrawerTabSelectTemplates";
import scalingBannerWrapperMixin from "@/mixins/scalingBannerWrapperMixin";

const QueryParams = {
    SelectedDeliverableId: "qad-sd",
    VisibleMasterTemplateIds: "qad-vmt"
};

const sortByWidth = (a, b) => {
    const widthA = a.masterTemplate.width;
    const widthB = b.masterTemplate.width;

    if (widthA !== widthB) {
        return widthA < widthB ? -1 : 1;
    }

    const heightA = a.masterTemplate.height;
    const heightB = b.masterTemplate.height;

    if (heightA === heightB) {
        return 0;
    }

    return heightA < heightB ? 1 : -1;
};

export default {
    name: "QaCreativePreview",

    components: {
        QaDrawerTabSelectTemplates,
        HoxEmptyMessage,
        QaBanner
    },
    mixins: [deliverableMethodsMixin, scalingBannerWrapperMixin],
    props: {
        deliverable: {
            type: Object,
            required: true
        },
        externalAnnotations: {
            type: Array
        },
        internalAnnotations: {
            type: Array
        },
        selectedAnnotation: {
            type: Object,
            validator(value) {
                return value === null || value._id;
            }
        },
        showResolved: {
            type: Boolean,
            default: false
        },
        showFullCreative: {
            type: Boolean,
            default: false
        },
        showMasterTemplateIds: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            bannerGroup: BannerGroup.Qa,
            bodyWidthOffset: -76,
            deliverables: [],
            deliverablesRequestCounter: 0,
            existingDeliverableStatusIdHashes: new Set(),
            hasErrorLoadingDeliverables: false,
            hasErrorLoadingSelectedDeliverableStatus: false,
            isFirstDeliverablesLoad: true,
            isLoadingDeliverables: false,
            isLoadingSelectedDeliverableStatus: false,
            sizeFilterIsVisible: false,
            selectedDeliverable: null,
            visibleMasterTemplateIds: []
        };
    },

    computed: {
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        masterTemplateIds() {
            return this.$store.state.campaign.normalized.masterTemplateIds;
        },

        deliverablesSortedBySize() {
            const sorted = [...this.deliverables].sort(sortByWidth);

            return sorted.reduce((acc, curr) => {
                if (curr.masterTemplate.width === 300 && curr.masterTemplate.height === 250) {
                    return [curr].concat(acc);
                }
                return acc.concat(curr);
            }, []);
        },
        hasDeliverables() {
            return this.deliverables.length !== 0;
        },

        hasSizesSelected() {
            return this.visibleMasterTemplateIds.length;
        },

        timeUpdateSource() {
            if (this.selectedDeliverable) {
                return this.selectedDeliverable;
            }

            return this.deliverablesSortedBySize.filter(d => this.isVisible(d)).pop();
        }
    },
    watch: {
        deliverable: {
            handler(deliverable) {
                if (this.deliverable !== null) {
                    /*
                        At the moment we're clearing any templates that are selected
                        and only showing the template of the deliverable.

                        We may want to persist templates through different deliverables/drawer
                        open and closes, or alternatively always show all sizes for a deliverable.
                    */
                    // this.visibleMasterTemplateIds = [ ...this.masterTemplateIds ];
                    this.getDeliverablesForCreative();
                    /*
                        In a single banner mode limit the view to the deliverable and mark it as selected
                    */
                    if (!this.showFullCreative) {
                        if (!this.showMasterTemplateIds.length) {
                            this.visibleMasterTemplateIds = [deliverable.masterTemplate._id];
                            this.setSelectedDeliverable(deliverable);
                        } else {
                            this.visibleMasterTemplateIds = this.showMasterTemplateIds;
                        }
                    }
                }
            },
            immediate: false
        },

        isLoadingDeliverables(val) {
            this.$emit(val ? "loadingStarted" : "loadingFinished");
        },

        visibleMasterTemplateIds(val, oldVal) {
            const newVisibleMasterTemplateIds = val.filter(v => !oldVal.includes(v));
            // if we get a new visible template id we need to pull data
            if (newVisibleMasterTemplateIds.length) {
                this.getDeliverablesForCreative();
            }

            if (this.selectedDeliverable && !val.includes(this.selectedDeliverable.masterTemplate._id)) {
                this.unsetSelectedDeliverable();
            }
            this.setVisibleMasterTemplateIdsQueryParams();
            this.$emit("visibleMasterTemplateIds", val);
        }
    },

    beforeDestroy() {
        this.cleanDeliverableStatusByIdHash();
        this.unsetAllQueryParams();
    },
    created() {
        this.urlManager = new URLManager(this.$router, {
            tracked: Object.values(QueryParams)
        });
        /*
            We store a set of existing deliverable ID hashes so that we can
            clear out data created by this component without removing data that
            is being used in elsewhere.
        */
        this.existingDeliverableStatusIdHashes = new Set(
            Object.keys(this.$store.state.deliverableLibrary.deliverableStatusByIdHash)
        );
        this.AnnotationType = AnnotationType;
        this.deliverablesService = new DeliverablesService(this.$apollo, this.$store);
        const queryParamVisibleMasterTemplateIds = this.$route.query[QueryParams.VisibleMasterTemplateIds];
        if (queryParamVisibleMasterTemplateIds) {
            const visibleMasterTemplateIds = Array.isArray(queryParamVisibleMasterTemplateIds)
                ? queryParamVisibleMasterTemplateIds
                : [queryParamVisibleMasterTemplateIds];
            /*
                It's possible that some visibleMasterTemplateIds query params may no
                longer exist for a campaign. Instead of causing a hard error we
                proactively filter the data.
            */
            this.setVisibleMasterTemplateIds(
                visibleMasterTemplateIds.filter(
                    masterTemplateId => this.$store.state.campaign.normalized.masterTemplates[masterTemplateId]
                )
            );
        } else {
            this.setVisibleMasterTemplateIds([...this.masterTemplateIds]);
        }
        /*
          In a single banner mode limit the view to the deliverable and mark it as selected
        */
        if (!this.showFullCreative) {
            if (!this.showMasterTemplateIds.length) {
                this.visibleMasterTemplateIds = [this.deliverable.masterTemplate._id];
                this.setSelectedDeliverable(this.deliverable);
            } else {
                this.visibleMasterTemplateIds = this.showMasterTemplateIds;
            }
        }
        this.setVisibleMasterTemplateIdsQueryParams();
        this.getDeliverablesForCreative();
    },
    methods: {
        addAnnotation(annotation) {
            this.$emit("annotationAdded", annotation);
        },

        cleanDeliverableStatusByIdHash() {
            /*
                To ensure that `deliverableStatusByIdHash` in the store does not
                infinitely grow we need to clear out data that is no longer being used.

                To make sure that we do not clear out data that is being used elsewhere
                we filter out any deliverables that existed in `deliverableStatusByIdHash`
                before this component was created.
            */
            const deliverablesToUnset = this.deliverables.filter(
                deliverable => !this.existingDeliverableStatusIdHashes.has(deliverable.idHash)
            );
            this.$store.dispatch(DeliverableLibraryAction.UnsetDeliverableStatusByIdHash, deliverablesToUnset);
        },

        setVisibleMasterTemplateIds(visibleMasterTemplateIds) {
            this.visibleMasterTemplateIds = visibleMasterTemplateIds;
            if (
                this.selectedDeliverable &&
                !this.visibleMasterTemplateIds.includes(this.selectedDeliverable.masterTemplate._id)
            ) {
                this.unsetSelectedDeliverable();
            }
        },

        // eslint-disable-next-line complexity
        async getDeliverablesForCreative() {
            this.hasErrorLoadingDeliverables = false;
            const isMostRecentRequest = requestCount => this.deliverablesRequestCounter === requestCount;
            this.deliverablesRequestCounter += 1;
            this.cleanDeliverableStatusByIdHash();
            if (this.deliverable !== null && this.masterTemplateIds.length > 0) {
                const requestCount = this.deliverablesRequestCounter;
                this.isLoadingDeliverables = true;
                try {
                    const groupValuesForQuery = this.deliverable.combination.map(groupValue => {
                        const groupName = Object.keys(groupValue)[0];
                        return {
                            name: groupName,
                            valueIds: [groupValue[groupName].groupValueId]
                        };
                    });

                    // if we don't have the visible template then skip the call
                    if (!this.visibleMasterTemplateIds.length) {
                        return;
                    }

                    const libraryFiltersForQuery = {
                        groupValueFilters: groupValuesForQuery,
                        qaStatusFilters: null,
                        publishedStatusFilters: null,
                        sizeFilters: this.visibleMasterTemplateIds,
                        languageFilters: [this.deliverable.language]
                    };
                    const { deliverables } = await this.deliverablesService.getPaginatedDeliverables(
                        this.campaignId,
                        libraryFiltersForQuery,
                        1,
                        this.masterTemplateIds.length
                    );

                    if (!isMostRecentRequest(requestCount)) {
                        return;
                    }
                    this.$store.dispatch(DeliverableLibraryAction.SetDeliverableStatusByIdHash, deliverables);
                    this.deliverables = deliverables;
                    this.$emit("load", deliverables);
                } catch (err) {
                    if (isMostRecentRequest(requestCount)) {
                        this.hasErrorLoadingDeliverables = true;
                    }
                } finally {
                    if (isMostRecentRequest(requestCount)) {
                        this.isLoadingDeliverables = false;
                    }
                }
            } else {
                this.isLoadingDeliverables = false;
                this.deliverables = [];
            }
        },

        isTheOnlyVisible(computedDeliverable) {
            return (
                this.visibleMasterTemplateIds.includes(computedDeliverable.masterTemplate._id) &&
                this.visibleMasterTemplateIds.length === 1
            );
        },

        isVisible(computedDeliverable) {
            return this.visibleMasterTemplateIds.includes(computedDeliverable.masterTemplate._id);
        },

        onBannerTimeUpdate(val, deliverable) {
            if (this.timeUpdateSource.idHash === deliverable.idHash) {
                this.setBannerPlaybackTimeFromBanner(val);
            }
        },

        setBannerPlaybackTimeFromBanner(time) {
            this.$emit("selectedBannerTimeUpdated", time);
        },

        setSelectedAnnotation(annotation) {
            this.$emit("annotationSelected", annotation);
        },

        setSelectedDeliverable(deliverable) {
            if (
                (!this.selectedDeliverable || this.selectedDeliverable.idHash !== deliverable.idHash) &&
                this.visibleMasterTemplateIds.includes(deliverable.masterTemplate._id)
            ) {
                this.selectedDeliverable = deliverable;
                this.$emit("select", this.selectedDeliverable);
            }
        },

        setVisibleMasterTemplateIdsQueryParams() {
            this.urlManager.setURLParams(
                [
                    {
                        key: QueryParams.VisibleMasterTemplateIds,
                        value: [this.visibleMasterTemplateIds]
                    }
                ],
                true
            );
        },

        toggleDropdown() {
            this.sizeFilterIsVisible = !this.sizeFilterIsVisible;
        },

        unsetAllQueryParams() {
            this.urlManager.setURLParams(
                Object.values(QueryParams).map(key => ({
                    key,
                    value: []
                })),
                true
            );
        },
        unsetSelectedDeliverable() {
            this.selectedDeliverable = null;
            this.$emit("select", null);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.qa-creative__body {
    background: $cmp-light-bg-color;
    display: flex;
    flex: 1;

    height: auto;
    position: relative;
    overflow: auto;
}

.qa-creative__body-content {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: flex-start;
    overflow: auto;
    padding: $spacing-large $spacing-large $spacing;
    width: 100%;
}

.qa-creative__alert-wrapper {
    width: 100%;
}

.qa-creative__banner-wrapper {
    display: inline-block;
    vertical-align: top;

    &--visible {
        margin: 0 $spacing $spacing 0;

        &.qa-creative__banner-wrapper--single-visible {
            margin: 0 auto $spacing auto;
        }
    }
}

.qa-creative__empty-message {
    flex: 1;
    margin: auto;
}

.qa-creative__has-selection {
    .qa-banner {
        opacity: 0.75;

        &--selected {
            opacity: 1;
        }
    }
}
</style>
