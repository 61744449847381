<template>
    <div class="image-cropper">
        <div ref="container" class="image-cropper__container">
            <img
                v-if="showImage"
                ref="image"
                class="image-cropper__image"
                :src="imageUrl"
                :style="imageStyle"
                @error="imageLoadError"
                @load="imageLoaded"
            />
            <Spin v-if="isLoadingImage" fix />
            <hox-alert v-else-if="hasErrorLoadingImage" margin-bottom="none" size="small" type="danger">
                <template #title>Uh oh!</template>
                <template #content>
                    <p>There was an error when trying to load the image.</p>
                </template>
                <template #actionItems>
                    <Button ghost type="error" @click="retryLoadingImage">Retry loading image</Button>
                </template>
            </hox-alert>
            <template v-else>
                <div class="image-cropper__viewport" :style="viewportStyle" />
                <div
                    class="image-cropper__draggable"
                    @mousedown.prevent="startDrag"
                    @wheel="setScalingFactorByScroll"
                />
                <transition name="fade">
                    <div
                        v-if="showEnableZoomMessage && !scalingIsDisabled"
                        class="image-cropper__enable-zoom-message"
                        @mousedown.prevent="startDrag"
                        @wheel="setScalingFactorByScroll"
                    >
                        Click to enable zoom by scrolling
                    </div>
                </transition>
            </template>
        </div>
        <div class="image-cropper__slider-container">
            <square-button
                :is-disabled="scalingIsDisabled"
                has-inverse-hover
                size="small"
                @click="decreaseScalingFactor"
            >
                <Icon type="md-remove" />
            </square-button>
            <div class="image-cropper__slider" @click="sliderClicked">
                <Slider
                    :disabled="scalingIsDisabled"
                    :min="minScalingFactor"
                    :max="maxScalingFactor"
                    :show-tip="scalingIsDisabled ? 'never' : 'hover'"
                    :step="sliderStep"
                    :tip-format="value => `${Math.round(value * 100)}%`"
                    :value="sliderScalingFactor"
                    @on-input="setScalingFactorBySlider"
                />
            </div>
            <square-button
                :is-disabled="scalingIsDisabled"
                has-inverse-hover
                size="small"
                @click="increaseScalingFactor"
            >
                <Icon type="md-add" />
            </square-button>
        </div>
    </div>
</template>

<script>
import HoxAlert from "@/components/common/Alert";
import SquareButton from "@/components/common/SquareButton";

export default {
    components: {
        HoxAlert,
        SquareButton
    },
    props: {
        imageCropCoordinates: {
            type: Object,
            // eslint-disable-next-line complexity
            validator(value) {
                return (
                    !value ||
                    (value.topLeftCoordinates !== undefined &&
                        value.topLeftCoordinates.x !== undefined &&
                        value.topLeftCoordinates.y !== undefined &&
                        value.bottomRightCoordinates !== undefined &&
                        value.bottomRightCoordinates.x !== undefined &&
                        value.bottomRightCoordinates.y !== undefined)
                );
            }
        },
        imageUrl: {
            required: true,
            type: String
        },
        requireClickToEnableZoom: {
            default: false,
            type: Boolean
        },
        viewportHeightPixels: {
            required: true,
            type: Number
        },
        viewportWidthPixels: {
            required: true,
            type: Number
        }
    },
    data() {
        return {
            container: {
                height: null,
                width: null
            },
            enableZoomTimeout: undefined,
            hasErrorLoadingImage: false,
            image: {
                height: null,
                width: null
            },
            isLoadingImage: true,
            maxScalingFactor: 2,
            minScalingFactor: 0,
            offset: {
                x: 0,
                y: 0
            },
            previousMouseCoordinates: {
                x: null,
                y: null
            },
            scalingFactor: 1, // 1 = 100%, 2 = 200%, 0.5 = 50% and so on.
            showEnableZoomMessage: false,
            showImage: true,
            sliderStep: 0.01,
            zoomIsEnabled: !this.requireClickToEnableZoom
        };
    },
    computed: {
        imageStyle() {
            return {
                height: `${this.image.height}px`,
                left: `${(this.image.width / 2 - this.container.width / 2) * -1}px`,
                top: `${(this.image.height / 2 - this.container.height / 2) * -1}px`,
                transform: `scale(${this.scalingFactor}) translate(${this.scale(this.offset.x)}px, ${this.scale(
                    this.offset.y
                )}px)`,
                width: `${this.image.width}px`
            };
        },
        scaledViewportHeight() {
            return this.viewportHeightPixels * this.viewportScalingFactor;
        },
        scaledViewportWidth() {
            return this.viewportWidthPixels * this.viewportScalingFactor;
        },
        scalingIsDisabled() {
            return (
                this.isLoadingImage ||
                this.hasErrorLoadingImage ||
                this.minScalingFactor === this.maxScalingFactor ||
                this.scalingFactor > this.maxScalingFactor * this.viewportScalingFactor
            );
        },
        sliderScalingFactor() {
            /*
                Because the viewport may have a scaling factor applied to it, the numbers shown
                on the slider need to be scaled by the viewport scaling factor to show the
                correct value for the level of zoom that is being shown to the user.
            */
            return this.scalingFactor / this.viewportScalingFactor;
        },
        viewportStyle() {
            return {
                height: `${this.scaledViewportHeight}px`,
                width: `${this.scaledViewportWidth}px`
            };
        },
        viewportScalingFactor() {
            /*
                Because the viewport may be bigger than the component container we will need to scale
                things down to fit.
            */
            if (this.viewportHeightPixels > this.container.height || this.viewportWidthPixels > this.container.width) {
                const minPaddingBetweenViewportAndContainerEdges = 10;
                const heightScalingFactor =
                    (this.container.height - minPaddingBetweenViewportAndContainerEdges * 2) /
                    this.viewportHeightPixels;
                const widthScalingFactor =
                    (this.container.width - minPaddingBetweenViewportAndContainerEdges * 2) / this.viewportWidthPixels;
                return Math.min(heightScalingFactor, widthScalingFactor);
            }
            return 1;
        }
    },
    watch: {
        offset() {
            const imageCropCoordinates = this.calculateImageCropCoordinates();
            this.$emit("cropCoordinatesUpdated", imageCropCoordinates);
        },
        scalingFactor() {
            const imageCropCoordinates = this.calculateImageCropCoordinates();
            this.$emit("cropCoordinatesUpdated", imageCropCoordinates);
        },

        viewportHeightPixels() {
            this.imageLoaded();
        },

        viewportWidthPixels() {
            this.imageLoaded();
        }
    },
    mounted() {
        /*
            There is a "bug" that manifests when this component is used in a component
            that transitions in using scale. We use the container dimensions to calculate
            *things* and it is smaller than it should be if the element is mid transition
            when it is created/mounted.

            The temporary workaround is to add an optional flag to the modal component
            so that it only transitions using opacity.
        */
        this.setContainerDimensions();
    },
    methods: {
        calculateImageCropCoordinates(offsetX = this.offset.x, offsetY = this.offset.y) {
            const topLeftCoordinates = {
                x: this.image.width / 2 - this.scale(this.scaledViewportWidth) / 2 - this.scale(offsetX),
                y: this.image.height / 2 - this.scale(this.scaledViewportHeight) / 2 - this.scale(offsetY)
            };
            const bottomRightCoordinates = {
                x: topLeftCoordinates.x + this.scale(this.scaledViewportWidth),
                y: topLeftCoordinates.y + this.scale(this.scaledViewportHeight)
            };
            return { bottomRightCoordinates, topLeftCoordinates };
        },
        calculateMinimumScalingFactor() {
            const minHeightScalingFactor = this.viewportHeightPixels / this.image.height;
            const minWidthScalingFactor = this.viewportWidthPixels / this.image.width;
            this.minScalingFactor =
                Math.ceil(Math.max(minHeightScalingFactor, minWidthScalingFactor) * (1 / this.sliderStep)) /
                (1 / this.sliderStep);
        },
        decreaseScalingFactor() {
            this.zoomIsEnabled = true;
            this.setScalingFactor(this.scalingFactor - this.sliderStep);
        },
        // eslint-disable-next-line complexity
        getCorrectedOffsetsToKeepImageEdgesOutsideOfViewport(offsetX = this.offset.x, offsetY = this.offset.y) {
            const newImageCropCoordinates = this.calculateImageCropCoordinates(offsetX, offsetY);
            const newOffset = {
                x: offsetX,
                y: offsetY
            };
            let hasCorrectedOffset = false;
            if (newImageCropCoordinates.topLeftCoordinates.x < 0) {
                hasCorrectedOffset = true;
                newOffset.x -= this.reverseScale(newImageCropCoordinates.topLeftCoordinates.x);
            } else if (newImageCropCoordinates.bottomRightCoordinates.x > this.image.width) {
                hasCorrectedOffset = true;
                newOffset.x -= this.reverseScale(newImageCropCoordinates.bottomRightCoordinates.x - this.image.width);
            }
            if (newImageCropCoordinates.topLeftCoordinates.y < 0) {
                hasCorrectedOffset = true;
                newOffset.y -= this.reverseScale(newImageCropCoordinates.topLeftCoordinates.y);
            } else if (newImageCropCoordinates.bottomRightCoordinates.y > this.image.height) {
                hasCorrectedOffset = true;
                newOffset.y -= this.reverseScale(newImageCropCoordinates.bottomRightCoordinates.y - this.image.height);
            }
            if (hasCorrectedOffset) {
                return newOffset;
            }
            return null;
        },
        endDrag() {
            window.removeEventListener("mousemove", this.setMouseCoordinates, true);
            window.removeEventListener("mouseup", this.endDrag, true);
            this.previousMouseCoordinates = {
                x: null,
                y: null
            };
        },
        // eslint-disable-next-line complexity
        imageLoaded() {
            this.setImageDimensions();
            if (this.image.height !== null && this.image.width !== null) {
                this.calculateMinimumScalingFactor();
                if (this.imageCropCoordinates) {
                    this.setOffsetAndScalingFactorFromImageCropCoordinates();
                } else {
                    this.setScalingFactorBasedOnImageDimensions();
                }
            }
            this.isLoadingImage = false;
            const imageCropCoordinates = this.calculateImageCropCoordinates();
            this.$emit("cropCoordinatesUpdated", imageCropCoordinates);
        },
        imageLoadError() {
            this.isLoadingImage = false;
            this.hasErrorLoadingImage = true;
            this.showImage = false;
        },
        increaseScalingFactor() {
            this.zoomIsEnabled = true;
            this.setScalingFactor(this.scalingFactor + this.sliderStep);
        },
        retryLoadingImage() {
            this.isLoadingImage = true;
            this.hasErrorLoadingImage = false;
            this.showImage = true;
        },
        reverseScale(value) {
            return (value / (1 / this.scalingFactor)) * -1;
        },
        scale(value) {
            return value * (1 / this.scalingFactor);
        },
        setContainerDimensions() {
            const el = this.$refs.container.getBoundingClientRect();
            this.container.height = el.height;
            this.container.width = el.width;
        },
        setImageDimensions() {
            const img = this.$refs.image;
            this.image = {
                height: img.naturalHeight,
                width: img.naturalWidth
            };
        },
        setMouseCoordinates(evt) {
            const currentMouseCoordinates = {
                x: evt.clientX,
                y: evt.clientY
            };
            if (this.previousMouseCoordinates.x !== null) {
                const newOffset = {
                    x: this.offset.x - (this.previousMouseCoordinates.x - currentMouseCoordinates.x),
                    y: this.offset.y - (this.previousMouseCoordinates.y - currentMouseCoordinates.y)
                };

                this.setOffset(newOffset);
            }
            this.previousMouseCoordinates = currentMouseCoordinates;
        },
        setOffsetAndScalingFactorFromImageCropCoordinates() {
            const cropWidth =
                this.imageCropCoordinates.bottomRightCoordinates.x - this.imageCropCoordinates.topLeftCoordinates.x;
            const calculatedScalingFactor = this.scaledViewportWidth / cropWidth;
            this.setScalingFactor(calculatedScalingFactor < 0 ? calculatedScalingFactor * -1 : calculatedScalingFactor);
            const calculatedOffset = {
                x: this.reverseScale(
                    this.imageCropCoordinates.topLeftCoordinates.x +
                        (this.scale(this.scaledViewportWidth) / 2 - this.image.width / 2)
                ),
                y: this.reverseScale(
                    this.imageCropCoordinates.topLeftCoordinates.y +
                        (this.scale(this.scaledViewportHeight) / 2 - this.image.height / 2)
                )
            };
            this.setOffset(calculatedOffset);
        },
        setOffset(offset) {
            const correctedOffsets = this.getCorrectedOffsetsToKeepImageEdgesOutsideOfViewport(offset.x, offset.y);
            this.offset = correctedOffsets || offset;
        },
        setScalingFactor(scalingFactor) {
            if (this.image.height !== null && this.image.width !== null) {
                this.scalingFactor = Math.max(
                    Math.min(scalingFactor, this.maxScalingFactor * this.viewportScalingFactor),
                    this.minScalingFactor * this.viewportScalingFactor
                );
                /*
                    Changing the scaling factor will alter where the image is so
                    we need to ensure that the new scaling factor will not
                    cause any edge of the image to be within the bounds of
                    the viewport, so we do a check and get corrected offsets
                    if it is the case.
                */
                const correctedOffsets = this.getCorrectedOffsetsToKeepImageEdgesOutsideOfViewport();
                if (correctedOffsets) {
                    this.offset = correctedOffsets;
                }
            }
        },
        setScalingFactorBasedOnImageDimensions() {
            if (this.image.width < this.viewportWidthPixels || this.image.height < this.viewportHeightPixels) {
                /*
                    If the image is smaller than the viewport then we will set the scaling
                    factor so that none of the image edges are within the viewport.
                */
                const minPaddingBetweenViewportAndImageEdges = 10;
                const heightScalingFactor =
                    (this.scaledViewportHeight + minPaddingBetweenViewportAndImageEdges) / this.image.height;
                const widthScalingFactor =
                    (this.scaledViewportWidth + minPaddingBetweenViewportAndImageEdges) / this.image.width;
                this.setScalingFactor(Math.max(heightScalingFactor, widthScalingFactor));
            } else if (this.image.width > this.container.width || this.image.height > this.container.height) {
                /*
                    If the image is larger than the container then we want to scale it down so to a sensible size
                    so that it's easy to get started working with it.
                */
                const paddingBetweenImageAndComponentEdge = 20;
                const heightScalingFactor =
                    this.container.height / (this.image.height + paddingBetweenImageAndComponentEdge);
                const widthScalingFactor =
                    this.container.width / (this.image.width + paddingBetweenImageAndComponentEdge);
                this.setScalingFactor(Math.min(heightScalingFactor, widthScalingFactor));
            }
        },
        setScalingFactorByScroll(evt) {
            if (this.zoomIsEnabled) {
                if (!this.scalingIsDisabled) {
                    evt.preventDefault();
                }
                this.setScalingFactor(this.scalingFactor + evt.deltaY * -0.0025);
            } else {
                this.showEnableZoomMessage = true;
                clearTimeout(this.enableZoomTimeout);
                this.enableZoomTimeout = setTimeout(() => {
                    this.showEnableZoomMessage = false;
                }, 1000);
            }
        },
        setScalingFactorBySlider(value) {
            this.setScalingFactor(value * this.viewportScalingFactor);
        },
        sliderClicked() {
            /*
                The reason why we have a sliderClicked function to set zoomIsEnabled
                instead of using setScalingFactorBySlider is that setScalingFactorBySlider
                is triggered when setting the initial value.
            */
            this.zoomIsEnabled = true;
        },
        startDrag(evt) {
            this.zoomIsEnabled = true;
            this.showEnableZoomMessage = false;
            clearTimeout(this.enableZoomTimeout);
            this.setMouseCoordinates(evt);
            window.addEventListener("mousemove", this.setMouseCoordinates, true);
            window.addEventListener("mouseup", this.endDrag, true);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_mixins.scss";
@import "@/../sass/_variables.scss";

.image-cropper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.image-cropper__container {
    @include make-checked-background($grey3, $spacing-small);
    align-items: center;
    border: 1px solid $grey3;
    display: flex;
    flex: 1;
    justify-content: center;
    overflow: hidden;
    padding: 0 $spacing;
    position: relative;
    width: 100%;
}

.image-cropper__draggable {
    bottom: 0;
    cursor: move;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.image-cropper__enable-zoom-message {
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    color: $white;
    display: flex;
    font-size: $font-size-larger;
    font-weight: bold;
    justify-content: center;
    left: 0;
    padding: 0 $spacing;
    position: absolute;
    right: 0;
    top: 0;
}

.image-cropper__image {
    position: absolute;
}

.image-cropper__slider {
    flex: 1;
    padding: 0 $spacing;
}

.image-cropper__slider-container {
    align-items: center;
    border: 1px solid $grey3;
    border-top: none;
    color: $grey5;
    display: flex;
    padding: 0 $spacing-small;
}

.image-cropper__viewport {
    border: 2px dashed $white;
    box-shadow: 0 0 200vw 200vh rgba(0, 0, 0, 0.65);
    display: block;
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
}
</style>
