<template>
    <div :class="classes">
        <slot />
    </div>
</template>

<script>
import { AnnotationType } from "@/enums/annotations";

export default {
    props: {
        isHighlighted: {
            type: Boolean
        },
        isSelected: {
            type: Boolean
        },
        size: {
            validator(value) {
                return ["small"].includes(value);
            }
        },
        type: {
            default: AnnotationType.Internal,
            validator(value) {
                return [AnnotationType.External, AnnotationType.Internal].includes(value);
            }
        }
    },
    computed: {
        // eslint-disable-next-line complexity
        classes() {
            const classes = ["qa-annotation-dot"];
            if (this.type === AnnotationType.Internal) {
                classes.push("qa-annotation-dot--internal");
            } else if (this.type === AnnotationType.External) {
                classes.push("qa-annotation-dot--external");
            }
            if (this.size === "small") {
                classes.push("qa-annotation-dot--small");
            }
            if (this.isHighlighted) {
                classes.push("qa-annotation-dot--highlighted");
            }
            if (this.isSelected) {
                classes.push("qa-annotation-dot--selected");
            }
            return classes;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

$annotation-dot-size: 30px;
$small-annotation-dot-size: 20px;

@mixin make-pulse-animation($type, $color) {
    @keyframes qa-annotation-dot__#{$type}-pulse {
        0% {
            box-shadow: 0 0 0 0 rgba($color, 1);
        }
        70% {
            box-shadow: 0 0 0 10px rgba($color, 0);
        }
        100% {
            box-shadow: 0 0 0 0 rgba($color, 0);
        }
    }
}

@include make-pulse-animation("external", $external-annotation-color);
@include make-pulse-animation("internal", $internal-annotation-color);

.qa-annotation-dot {
    align-items: center;
    border-radius: 100%;
    color: $white;
    display: flex;
    font-size: $font-size-base;
    height: $annotation-dot-size;
    justify-content: center;
    width: $annotation-dot-size;
}

.qa-annotation-dot--external {
    background: $external-annotation-color;

    &.qa-annotation-dot--selected {
        animation: qa-annotation-dot__external-pulse 1s infinite;
    }
}

.qa-annotation-dot--highlighted {
    border: 2px solid $white;
}

.qa-annotation-dot--internal {
    background: $blue;

    &.qa-annotation-dot--selected {
        animation: qa-annotation-dot__internal-pulse 1s infinite;
    }
}

.qa-annotation-dot--selected {
    transform: scale(1.5);
}

.qa-annotation-dot--small {
    border: 1px solid $white;
    font-size: $font-size-small;
    height: $small-annotation-dot-size;
    width: $small-annotation-dot-size;

    .qa-annotation-dot--highlighted {
        border: 1px solid $white;
    }
}
</style>
