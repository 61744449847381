// eslint-disable-next-line import/no-extraneous-dependencies
import { JOB_STATUSES } from "shared-utils/enums/job";
import { NotificationStatus } from "@/enums/notifications";

export const JobStatus = JOB_STATUSES;

export const JobStatusToNotificationStatus = {
    [JobStatus.ABORTED]: NotificationStatus.Failed,
    [JobStatus.COMPLETE]: NotificationStatus.Completed,
    [JobStatus.FAILED]: NotificationStatus.Failed,
    [JobStatus.IN_PROGRESS]: NotificationStatus.InProgress,
    [JobStatus.PENDING]: NotificationStatus.Pending
};

export const JobNames = {
    AddMetadataValue: "AddMetadataValue",
    RemoveMetadataValue: "RemoveMetadataValue",
    PublishXmlFeed: "PublishXmlFeed",
    UnpublishXmlFeed: "UnpublishXmlFeed",
    PUBFT_DOWNLOAD_EXCEL: "PUBFT_DOWNLOAD_EXCEL",
    PUBFT_DOWNLOAD_ASSETS: "PUBFT_DOWNLOAD_ASSETS"
};
