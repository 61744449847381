<template>
    <Tooltip
        v-if="$auth.userCan($auth.Actions.CanManageRestrictedEditables, { clientId, campaignId })"
        class="editable-restriction-control"
        content="Allows team to make updates"
        placement="bottom-end"
        :delay="tooltipDelay"
    >
        <i-switch
            :loading="isSettingEditableRestriction"
            size="small"
            :value="!editable.restricted"
            @click.native.stop
            @input="toggleEditableIsRestricted"
        />
    </Tooltip>
</template>
<script>
import AddEditableDerestriction from "@/apollo/mutations/v2/AddEditableDerestriction.gql";
import RemoveEditableDerestriction from "@/apollo/mutations/v2/RemoveEditableDerestriction.gql";
import { CampaignAction } from "@/store/modules/campaign";
import EditableRestrictionMessage from "@/components/Campaign/EditableRestrictionMessage";

export default {
    name: "EditableRestrictionControl",
    props: {
        editable: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            isSettingEditableRestriction: false,
            tooltipDelay: 1000
        };
    },

    computed: {
        campaignId() {
            return this.$route.params.campaignId;
        },

        clientId() {
            return this.$route.params.clientId;
        }
    },

    methods: {
        async toggleEditableIsRestricted() {
            if (!this.isSettingEditableRestriction) {
                this.isSettingEditableRestriction = true;
                try {
                    const variables = {
                        campaignId: this.campaignId,
                        editableId: this.editable._id
                    };

                    if (this.editable.restricted) {
                        await this.$apollo.mutate({
                            mutation: AddEditableDerestriction,
                            variables
                        });

                        this.$snackbar.render("none", {
                            duration: 6,
                            render: h =>
                                h(EditableRestrictionMessage, {
                                    props: {
                                        label: this.editable.label,
                                        roleName: this.$store.state.auth.me.role.name
                                    }
                                })
                        });
                    } else {
                        await this.$apollo.mutate({
                            mutation: RemoveEditableDerestriction,
                            variables
                        });

                        this.$snackbar.success(`Updates disabled for ${this.editable.label}`);
                    }

                    this.$store.dispatch(CampaignAction.SetEditableRestriction, {
                        editableId: this.editable._id,
                        isRestricted: !this.editable.restricted
                    });
                } catch (e) {
                    this.$snackbar.error(`Restrictions update failed for ${this.editable.label}`);
                }
                this.isSettingEditableRestriction = false;
            }
        }
    }
};
</script>

<style lang="scss">
.editable-restriction-control {
    line-height: 18px;
}
</style>
