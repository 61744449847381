import Vue from "vue";
import { JobStatus } from "@/enums/jobs";

const storeNamespace = "jobs";

export const JobsAction = {
    DeleteNotificationIdByActiveJobId: `${storeNamespace}/deleteNotificationIdByActiveJobId`,
    SetNotificationIdByActiveJobId: `${storeNamespace}/setNotificationIdByActiveJobId`
};

export const JobsMutation = {
    DeleteNotificationIdByActiveJobId: "deleteNotificationIdByActiveJobId",
    AddCompletedJob: "addCompletedJob",
    SetNotificationIdByActiveJobId: "setNotificationIdByActiveJobId"
};

export default {
    namespaced: true,

    state: {
        completedJobs: [],
        notificationIdsByActiveJobId: {}
    },

    mutations: {
        deleteNotificationIdByActiveJobId(state, jobId) {
            Vue.delete(state.notificationIdsByActiveJobId, jobId);
        },

        setNotificationIdByActiveJobId(state, { notificationId, jobId }) {
            Vue.set(state.notificationIdsByActiveJobId, jobId, notificationId);
        },

        addCompletedJob(state, job) {
            state.completedJobs = [...state.completedJobs, job];
        }
    },

    actions: {
        deleteNotificationIdByActiveJobId({ commit }, job) {
            if (job.status === JobStatus.COMPLETE) {
                commit(JobsMutation.AddCompletedJob, job);
            }
            commit(JobsMutation.DeleteNotificationIdByActiveJobId, job._id);
        },

        setNotificationIdByActiveJobId({ commit }, { notificationId, jobId }) {
            commit(JobsMutation.SetNotificationIdByActiveJobId, { notificationId, jobId });
        }
    }
};
