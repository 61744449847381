// @ts-check
/**
 * @typedef {Object} CiAPIHeaders
 * @property {string} Content-Type
 * @property {string} x-api-key
 */

/**
 * @param {string} apiKey
 * @returns {CiAPIHeaders}
 */
export function getDefaultHeaders(apiKey) {
    return {
        "Content-Type": "application/json",
        "x-api-key": apiKey
    };
}

/** @type {Partial<RequestInit>} */
const BASE_REQUEST_OPTIONS = {
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    referrerPolicy: "no-referrer" // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
};

/**
 * @template B
 * @template T
 * @param {string} url
 * @param {CiAPIHeaders} headers
 * @param {"GET" | "POST" | "PUT" | "DELETE"} method
 * @param {B | undefined} body
 * @returns {Promise<T>}
 */
export async function request(url, headers, method, body) {
    /** @type {RequestInit} */
    const params = {
        method,
        headers,
        ...BASE_REQUEST_OPTIONS,
        ...(body ? { body: JSON.stringify(body) } : {})
    };

    const resp = await fetch(url, params);

    if (!resp.ok) {
        const { message } = await resp.json();

        if (message) {
            throw new Error(message);
        }

        throw new Error("Something went wrong");
    }

    return resp.json();
}

export const RESOURCE_MANAGER_STATUS = {
    /**
     * @returns {"AVAILABLE"}
     */
    get AVAILABLE() {
        return "AVAILABLE";
    },
    /**
     * @returns {"IN_PROGRESS"}
     */
    get IN_PROGRESS() {
        return "IN_PROGRESS";
    },
    /**
     * @returns {"FAILED"}
     */
    get FAILED() {
        return "FAILED";
    }
};

export const RESOURCE_MANAGER_TYPES = {
    /**
     * @returns {"PLAINLY_CREATE_PROJECT"}
     */
    get PLAINLY_CREATE_PROJECT() {
        return "PLAINLY_CREATE_PROJECT";
    },
    /**
     * @returns {"PLAINLY_RENDER"}
     */
    get PLAINLY_RENDER() {
        return "PLAINLY_RENDER";
    },
    /**
     * @returns {"PLAINLY_BATCH_RENDER"}
     */
    get PLAINLY_BATCH_RENDER() {
        return "PLAINLY_BATCH_RENDER";
    }
};

/**
 * @typedef {Object} ResourceManagerInProgressResponse
 * @property {string} resourceId
 * @property {"IN_PROGRESS"} status
 * @property {number} percentage
 * @property {Object} [output]
 * @property {unknown} [output.inProgress]
 */

/**
 * @typedef {Object} ResourceManagerFailedResponse
 * @property {string} resourceId
 * @property {"FAILED"} status
 * @property {number} percentage
 * @property {Object} [output]
 * @property {(Error | string)} [output.fail]
 */

/**
 * @template T
 * @typedef {Object} ResourceManagerSuccesResponse
 * @property {string} resourceId
 * @property {"AVAILABLE"} status
 * @property {number} percentage
 * @property {Object} output
 * @property {T} output.success
 */

/**
 * @template T
 * @typedef {ResourceManagerInProgressResponse | ResourceManagerFailedResponse | ResourceManagerSuccesResponse<T>} ResourceManagerResponse
 */

/**
 * Resource Manager request
 * @template T
 * @param {Object} params
 * @param {string} params.baseUrl
 * @param {string} params.apiKey
 * @param {string} params.id
 * @param {Object} params.body
 * @returns {Promise<{ data: ResourceManagerResponse<T> }>}
 */
export async function resourceManagerRequest({ baseUrl, apiKey, id, body }) {
    const headers = getDefaultHeaders(apiKey);
    const url = `${baseUrl}/v1/resource/request/${id}`;

    return request(url, headers, "POST", body);
}

export * from "./plainly";
