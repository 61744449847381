<template>
    <!--
    The reason that has-scale-transition is set to false is due to an interation
    that may cause the ImageCropper component to calculate incorrect dimensions
    as the modal is actively being resized as it is being shown.
  -->
    <hox-modal :has-scale-transition="false" @close="close">
        <template #header>Resize Settings</template>
        <div
            v-if="!isSingleTemplateResizeSettings || isSingleTemplateWithOwnMediaItem"
            :class="settingsContainerClasses"
        >
            <resize-settings
                :cover.sync="internalResizeSettings.cover"
                :horizontal-align.sync="internalResizeSettings.horizontalAlign"
                :resize-type.sync="resizeType"
                :show-manual-resize-option="isSingleTemplateWithOwnMediaItem"
                :vertical-align.sync="internalResizeSettings.verticalAlign"
                :resize-dimensions="resizeDimensionsByTemplateId[templateIds[0]]"
                @dimensionChange="onInputChange"
            />
        </div>
        <hox-alert v-if="templateIds.length === 0" type="info">
            <template #title>This image is not used by any templates</template>
            <template #content>
                <p>As such it is not possible to show any preview of how the image will be resized.</p>
            </template>
        </hox-alert>
        <resize-settings-template-section
            v-for="templateId of templateIds"
            :key="templateId"
            :initial-has-own-resize-settings="isSingleTemplateResizeSettings"
            :group-resize-type="resizeType"
            :group-resize-settings="internalResizeSettings"
            :label="templateLabels[templateId]"
            :preview-image-url="previewImageUrl"
            :require-click-to-enable-image-cropping-zoom="
                !isSingleTemplateResizeSettings && !isSingleTemplateWithOwnMediaItem
            "
            :resize-dimensions="resizeDimensionsByTemplateId[templateId]"
            :show-label="isGroupResizeSettings"
            :show-template-specific-settings-switch="isGroupResizeSettings"
            :template-id="templateId"
            :template-resize-settings="resizeSettingsByTemplateId[templateId]"
            :manual-dimensions="manualDimensions"
            @removeTemplateResizeSettings="removeTemplateResizeSettings(templateId)"
            @setTemplateResizeSettings="setTemplateResizeSettings(templateId, $event)"
        />
        <template #footer>
            <Button class="resize-settings-modal--button-spacing" @click="close">Cancel</Button>
            <Button class="resize-settings-modal--button-spacing" type="primary" @click="emitResizeSettings">
                Set Resize Settings
            </Button>
        </template>
    </hox-modal>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { CoverType, HorizontalAlign, ResizeType, VerticalAlign } from "shared-utils/imageBoss";
// eslint-disable-next-line import/no-extraneous-dependencies
import getDimensionsModifiers from "shared-utils/getDimensionModifiers";
import HoxAlert from "@/components/common/Alert";
import HoxModal from "@/components/Modal/Modal/Modal";
import ResizeSettings from "@/components/ResizeSettings";
import ResizeSettingsTemplateSection from "@/components/ResizeSettingsTemplateSection";
import { CampaignGetters } from "@/store/modules/campaign";
import { deepClone } from "@/utils";

export default {
    components: {
        ResizeSettings,
        ResizeSettingsTemplateSection,
        HoxAlert,
        HoxModal
    },
    props: {
        editable: {
            required: true,
            validator: value => value.defaultValues !== undefined
        },
        templateIds: {
            required: true,
            type: Array
        },
        isGroupResizeSettings: {
            type: Boolean
        },
        isSingleTemplateResizeSettings: {
            type: Boolean
        },
        previewImageUrl: {
            required: true,
            type: String
        },
        resizeSettings: {
            required: true,
            type: Object
        }
    },
    data() {
        return {
            internalResizeSettings: {},
            manualDimensions: {},
            previousAutoResizeCoverValue: undefined,
            resizeType: ResizeType.Off
        };
    },
    computed: {
        isSingleTemplateWithOwnMediaItem() {
            return !this.isGroupResizeSettings && this.isSingleTemplateResizeSettings;
        },

        masterTemplates() {
            return this.$store.state.campaign.normalized.masterTemplates;
        },

        resizeDimensionsByTemplateId() {
            return this.templateIds.reduce((acc, templateId) => {
                const { dimensions } = this.editable.defaultValues.find(
                    defaultValue => defaultValue.masterTemplateId === templateId
                );

                const { widthMultiplier, heightMultiplier } = getDimensionsModifiers(this.masterTemplates[templateId]);

                acc[templateId] = {
                    width: Math.round(dimensions.width * widthMultiplier),
                    height: Math.round(dimensions.height * heightMultiplier)
                };

                return acc;
            }, {});
        },
        resizeSettingsByTemplateId() {
            return this.internalResizeSettings.resizeSettingsByTemplateId || {};
        },
        settingsContainerClasses() {
            const classes = ["resize-settings-modal__settings-container"];
            if (!this.isGroupResizeSettings && this.isSingleTemplateResizeSettings) {
                classes.push("resize-settings-modal__settings-container--no-bottom-border");
            }
            return classes;
        },
        templateLabels() {
            return this.$store.getters[CampaignGetters.masterTemplateLabels];
        }
    },
    watch: {
        resizeType(newValue, previousValue) {
            if (previousValue === ResizeType.Auto) {
                this.previousAutoResizeCoverValue = this.internalResizeSettings.cover;
            }
            const cover =
                newValue === ResizeType.Manual
                    ? CoverType.Manual
                    : this.previousAutoResizeCoverValue || CoverType.Smart;
            this.internalResizeSettings = {
                ...this.internalResizeSettings,
                cover
            };
        }
    },
    created() {
        if (Object.keys(this.resizeSettings).length > 0) {
            this.internalResizeSettings = {
                horizontalAlign: HorizontalAlign.Center,
                manualSettings: null,
                verticalAlign: VerticalAlign.Center,
                ...deepClone(this.resizeSettings)
            };
            if (this.resizeSettings.cover !== undefined) {
                if (this.resizeSettings.cover === CoverType.Manual) {
                    this.resizeType = ResizeType.Manual;
                } else {
                    this.resizeType = ResizeType.Auto;
                    this.previousAutoResizeCoverValue = this.internalResizeSettings.cover;
                }
            }
        }
    },
    methods: {
        sanitiseResizeSettings(resizeSettings) {
            /*
                We strip away any bits of data that are not relevant to the current
                resize settings to ensure consistent and predicatable behaviour
                when the user comes back to the resize settings after saving.
            */
            const sanitisedResizeSettings = deepClone(resizeSettings);
            if (resizeSettings.cover !== CoverType.Manual) {
                delete sanitisedResizeSettings.manualSettings;
            }
            if (resizeSettings.cover !== CoverType.Directional) {
                delete sanitisedResizeSettings.horizontalAlign;
                delete sanitisedResizeSettings.verticalAlign;
            }
            if (resizeSettings.resizeSettingsByTemplateId) {
                if (Object.keys(resizeSettings.resizeSettingsByTemplateId).length < 0) {
                    delete sanitisedResizeSettings.resizeSettingsByTemplateId;
                } else {
                    sanitisedResizeSettings.resizeSettingsByTemplateId = Object.keys(
                        sanitisedResizeSettings.resizeSettingsByTemplateId
                    ).reduce((acc, templateId) => {
                        acc[templateId] = this.sanitiseResizeSettings(
                            sanitisedResizeSettings.resizeSettingsByTemplateId[templateId]
                        );
                        return acc;
                    }, {});
                }
            }
            return sanitisedResizeSettings;
        },
        emitResizeSettings() {
            const hasTemplateSpecificResizeSettings =
                this.internalResizeSettings.resizeSettingsByTemplateId &&
                Object.keys(this.internalResizeSettings.resizeSettingsByTemplateId).length > 0;
            if (this.resizeType !== ResizeType.Off) {
                const sanitisedResizeSettings = this.sanitiseResizeSettings(this.internalResizeSettings);
                this.$emit("setResizeSettings", sanitisedResizeSettings);
            } else if (hasTemplateSpecificResizeSettings) {
                const sanitisedResizeSettings = this.sanitiseResizeSettings({
                    resizeSettingsByTemplateId: this.internalResizeSettings.resizeSettingsByTemplateId
                });
                this.$emit("setResizeSettings", sanitisedResizeSettings);
            } else {
                this.$emit("setResizeSettings", undefined);
            }
            this.close();
        },
        close() {
            this.$emit("close");
        },
        removeTemplateResizeSettings(templateId) {
            if (this.internalResizeSettings.resizeSettingsByTemplateId) {
                this.$delete(this.internalResizeSettings.resizeSettingsByTemplateId, templateId);
            }
        },
        setTemplateResizeSettings(templateId, templateResizeSettings) {
            if (this.isSingleTemplateWithOwnMediaItem) {
                /*
                    If this is a single template with its own media item then
                    we want to set the resize settings at the top level, not
                    as part of resizeSettingsByTemplateId.
                */
                this.internalResizeSettings = {
                    ...this.internalResizeSettings,
                    ...templateResizeSettings
                };
            } else {
                if (!this.internalResizeSettings.resizeSettingsByTemplateId) {
                    this.$set(this.internalResizeSettings, "resizeSettingsByTemplateId", {});
                }
                this.$set(this.internalResizeSettings.resizeSettingsByTemplateId, templateId, templateResizeSettings);
            }
        },
        onInputChange(val) {
            this.manualDimensions = val;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.resize-settings-modal__settings-container {
    border-bottom: 1px solid $grey3;
    margin: 0 0 $spacing;
    padding: 0 0 $spacing-small;
}

.resize-settings-modal__settings-container--no-bottom-border {
    border-bottom: none;
    margin: 0;
    padding: 0;
}

.resize-settings-modal--button-spacing {
    margin: 0 0 0 $spacing-small;
}
</style>
