<!-- eslint-disable vue/no-mutating-props -->
<template>
    <hox-modal is-on-top-of-everything @close="close">
        <template #header>Add Asset from Link</template>
        <div class="media-library-add-asset-from-url">
            <div class="media-library-add-asset-from-url__input-wrapper">
                <Input
                    ref="urlInput"
                    v-model="assetUrl"
                    placeholder="E.g. https://cdn.hoxton.co/assets/logo.png"
                    prefix="ios-link"
                    data-testid="add-asset-url-modal__input"
                />
            </div>
            <asset-preview
                data-testid="add-asset-url-modal__preview-image"
                :show-loading-state="displayAssetUrl !== assetUrl"
                :asset-url="displayAssetUrl"
                @error="assetLoadError = true"
                @load="assetLoadError = false"
            >
                <template #errorMessage>
                    <hox-alert type="danger">
                        <template #title>
                            <p>There was an error when trying to display the link as an asset</p>
                        </template>
                        <template #content>
                            <p>
                                This may be because it does not exist, is not an asset or is missing the
                                <code>https://</code>
                                prefix.
                            </p>
                            <p>
                                If you believe that the link is correct then the next step to take is to manually check
                                if it exists in your browser.
                            </p>
                        </template>
                        <template #actionItems>
                            <Button ghost target="_blank" :to="displayAssetUrl" type="error">
                                Manually check link
                            </Button>
                        </template>
                    </hox-alert>
                </template>
            </asset-preview>
        </div>
        <template #footer>
            <div>
                <Button
                    class="media-library-add-asset-from-url__button"
                    data-testid="add-asset-url-modal__cancel-button"
                    @click="close"
                >
                    Cancel
                </Button>
                <Button
                    class="media-library-add-asset-from-url__button"
                    data-testid="add-asset-url-modal__add-button"
                    :disabled="!assetUrl || assetLoadError"
                    type="primary"
                    @click="addImageToLibrary"
                >
                    Add Asset To Library
                </Button>
            </div>
        </template>
    </hox-modal>
</template>

<script>
import updateMediaFilesMutation from "@/apollo/mutations/UpdateMediaFiles.gql";
import uploadMediaFilesMutation from "@/apollo/mutations/UploadMediaFiles.gql";
import bus from "@/bus";
import HoxAlert from "@/components/common/Alert";
import AssetPreview from "@/components/common/AssetPreview";
import HoxModal from "@/components/Modal/Modal/Modal";
import { getFileNameFromUrl, splitFileNameAndExtension } from "@/utils";

export default {
    components: {
        HoxAlert,
        HoxModal,
        AssetPreview
    },
    props: {
        folderId: {
            type: String,
            required: true
        },
        mediaItems: {
            required: true,
            type: Array
        },
        assetUrl: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            /*
          `assetUrl` and `displayAssetUrl` are distinct values because we
          do not instantly update the asset src with assetUrl as the user types
          to prevent trying to load many broken assets in very quick succession.

          Once the user stops typing for N amount of time, `displayAssetUrl` is
          set to the value of `assetUrl`.
      */
            displayAssetUrl: this.assetUrl,
            assetLoadingTimeout: undefined,
            isAddingAssetToLibrary: false,
            assetLoadError: false
        };
    },
    computed: {
        clientId() {
            return this.$route.params.clientId;
        }
    },
    watch: {
        assetUrl() {
            clearTimeout(this.assetLoadingTimeout);
            if (this.assetUrl === "" || this.assetUrl === this.displayAssetUrl) {
                this.displayAssetUrl = this.assetUrl;
            } else {
                this.assetLoadingTimeout = setTimeout(() => {
                    this.displayAssetUrl = this.assetUrl;
                }, 500);
            }
        }
    },
    async mounted() {
        await this.$nextTick();
        this.$refs.urlInput.focus();
    },
    methods: {
        close() {
            this.$emit("close");
        },
        async addImageToLibrary() {
            if (!this.isAddingAssetToLibrary) {
                this.isAddingAssetToLibrary = true;
                bus.$emit("percentLoadEvent", 50);
                const fileName = getFileNameFromUrl(this.assetUrl);
                const { name: fileNameWithoutExtension } = splitFileNameAndExtension(getFileNameFromUrl(this.assetUrl));
                const existingMediaItem = this.mediaItems.find(item => item.type === "FILE" && item.name === fileName);
                let createdOrUpdatedfileId;
                try {
                    if (existingMediaItem) {
                        const res = await this.$apollo.mutate({
                            mutation: updateMediaFilesMutation,
                            variables: {
                                clientId: this.clientId,
                                files: [
                                    {
                                        fileId: existingMediaItem.id,
                                        name: fileNameWithoutExtension,
                                        url: this.assetUrl
                                    }
                                ],
                                folderId: this.folderId
                            }
                        });
                        createdOrUpdatedfileId = res.data.updateMediaFiles[0].id;
                    } else {
                        const res = await this.$apollo.mutate({
                            mutation: uploadMediaFilesMutation,
                            variables: {
                                clientId: this.clientId,
                                files: [
                                    {
                                        name: fileNameWithoutExtension,
                                        url: this.assetUrl
                                    }
                                ],
                                folderId: this.folderId
                            }
                        });
                        createdOrUpdatedfileId = res.data.uploadMediaFiles[0].id;
                    }
                } catch (err) {
                    const errorMessage = existingMediaItem
                        ? "There was an unexpected error while trying to update the asset"
                        : "There was an unexpected error while attempting to add the asset to the Content Library";

                    this.$snackbar.error(errorMessage);
                    return;
                } finally {
                    this.isAddingAssetToLibrary = false;
                    bus.$emit("percentLoadEvent", 100);
                }
                this.$emit("assetAdded", createdOrUpdatedfileId);
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

$check-color: $grey3;

.media-library-add-asset-from-url {
    display: flex;
    flex-direction: column;
    height: 50vh;
    justify-content: flex-start;
    align-content: center;
}

.media-library-add-asset-from-url__button {
    margin: 0 0 0 $spacing-small;
}

.media-library-add-asset-from-url__input-wrapper {
    margin: 0 0 $spacing;
}
</style>
