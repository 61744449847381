<template>
    <empty-message type="bug" class="network-error">
        <template #title>We cannot connect to the CLI!</template>
        <template #content>
            <p>
                Please ensure you have the CLI installed. Once you do you can run the following in a terminal to get
                sandbox mode up and running:
            </p>
            <pre>
                <code>cd /path/to/banner</code>
                <code>hoxton</code>
            </pre>
            <p><router-link :to="{ name: 'Sandbox' }">Click here to try again</router-link></p>
        </template>
    </empty-message>
</template>

<script>
import EmptyMessage from "@/components/common/EmptyMessage";
import config from "@/config";

export default {
    name: "LocalDevNetworkError",

    components: { EmptyMessage },

    created() {
        this.checkServerConnection();
    },

    methods: {
        async checkServerConnection() {
            setTimeout(async () => {
                try {
                    // This will only error if the server is unreachable
                    await fetch(`${config.appConfig.HOXTON_CLI_URI}`);
                    // If there is no error then we can switch back to the sandbox
                    this.$router.push({ name: "Sandbox" });
                } catch (err) {
                    this.checkServerConnection();
                }
            }, 5000);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/../sass/_variables";

.network-error {
    margin: 50px auto;
    text-align: center;

    p {
        margin: 10px 0;
    }
}

pre {
    white-space: normal;
    background: rgba($color: #000000, $alpha: 0.8);
    padding: $spacing-base;
    display: inline-block;
    border-radius: $border-radius-larger;
    box-shadow: $box-shadow-base;

    code {
        display: block;
        text-align: left;
        color: white;
        border-radius: 5px;
        white-space: normal;

        &:before {
            content: "$ ";
        }
    }
}
</style>
