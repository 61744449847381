<template>
    <div class="set-deliverables-visible-to-dropdown">
        <Dropdown
            placement="bottom-start"
            trigger="custom"
            :visible="dropdownIsVisible"
            @on-clickoutside="toggleDropdown"
        >
            <hox-dropdown-button
                :is-active="dropdownIsVisible"
                :is-disabled="deliverables.length === 0 || isDisabled"
                :is-loading="isSettingVisibleTo"
                data-testid="library__visibility-select"
                @click="toggleDropdown"
            >
                <slot>Visibility</slot>
            </hox-dropdown-button>
            <template #list>
                <CheckboxGroup v-model="visibleToRoleIds">
                    <DropdownItem v-for="{ label, value } in options" :key="value">
                        <Checkbox :label="value" :data-testid="'library__visibility-select--' + value">
                            {{ label }}
                        </Checkbox>
                    </DropdownItem>
                </CheckboxGroup>
                <div class="set-deliverables-visible-to-dropdown__dropdown-footer">
                    <Button
                        class="set-deliverables-visible-to-dropdown__dropdown-button"
                        data-testid="library__visibility-button--cancel"
                        @click="toggleDropdown"
                    >
                        Cancel
                    </Button>
                    <Button
                        class="set-deliverables-visible-to-dropdown__dropdown-button"
                        type="primary"
                        data-testid="library__visibility-button--save"
                        @click="saveDeliverableVisibleTo"
                    >
                        Save
                    </Button>
                </div>
            </template>
        </Dropdown>
    </div>
</template>

<script>
import HoxDropdownButton from "@/components/common/DropdownButton";
import { setDeliverablesVisibleToRoles } from "@/services/Qa";
import { DeliverableLibraryAction } from "@/store/modules/deliverableLibrary";
import { RolesGetter } from "@/store/modules/roles";

export default {
    components: {
        HoxDropdownButton
    },
    props: {
        deliverables: {
            required: true,
            type: Array
        },
        isDisabled: {
            type: Boolean
        },
        libraryFiltersForQuery: {
            required: false,
            type: Object
        },
        itemToChangeNumber: {
            required: false,
            type: Number
        }
    },
    data() {
        return {
            dropdownIsVisible: false,
            isSettingVisibleTo: false,
            visibleToRoleIds: []
        };
    },
    computed: {
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },
        options() {
            return this.rolesThatCanOnlyViewDeliverablesVisibleToRole.map(role => ({
                label: role.name,
                value: role._id
            }));
        },
        rolesThatCanOnlyViewDeliverablesVisibleToRole() {
            return this.$store.getters[RolesGetter.getRolesThatDoNotHaveAction](
                this.$auth.Actions.CanChangeDeliverableVisibility
            );
        }
    },
    methods: {
        // eslint-disable-next-line complexity
        async saveDeliverableVisibleTo() {
            /*
                We make a copy of the current visibleToRoleIds because when we
                toggle the dropdown we reset the component visibleToRoleIds data.
            */
            const visibleToRoleIds = [...this.visibleToRoleIds];
            this.toggleDropdown();
            this.isSettingVisibleTo = true;
            const deliverablesCount = this.itemToChangeNumber || this.deliverables.length;
            try {
                const deliverables = await setDeliverablesVisibleToRoles(
                    this.campaignId,
                    this.deliverables,
                    visibleToRoleIds,
                    this.libraryFiltersForQuery
                );
                this.$store.dispatch(DeliverableLibraryAction.SetDeliverableStatusByIdHash, deliverables);
                if (visibleToRoleIds.length > 0) {
                    const roleNamesNowVisibleTo = visibleToRoleIds.map(roleId => {
                        const { label } = this.options.find(option => option.value === roleId);
                        return `${label}s`;
                    });
                    const joinedRoleNamesNowVisibleTo = roleNamesNowVisibleTo.join(", ");
                    if (deliverablesCount === 1) {
                        this.$snackbar.success(`1 deliverable is now visible to ${joinedRoleNamesNowVisibleTo}.`);
                    } else {
                        this.$snackbar.success(
                            `${deliverablesCount} deliverables are now visible to ${joinedRoleNamesNowVisibleTo}.`
                        );
                    }
                } else if (deliverablesCount === 1) {
                    this.$snackbar.success(
                        "1 deliverable is now only visible to users who can always see all deliverables."
                    );
                } else {
                    this.$snackbar.success(
                        `${deliverablesCount} deliverables are now only visible to users who can always see all deliverables.`
                    );
                }
            } catch (err) {
                this.$snackbar.error(
                    "There was an unexpected error and the deliverable visibility was unable to be set. Please try again in a few moments."
                );
            }
            this.isSettingVisibleTo = false;
        },
        toggleDropdown() {
            this.dropdownIsVisible = !this.dropdownIsVisible;
            if (!this.dropdownIsVisible) {
                this.visibleToRoleIds = [];
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.set-deliverables-visible-to-dropdown {
    .ivu-checkbox-wrapper {
        display: block;
        margin: 0;
        padding: $spacing-small $spacing $spacing-small $spacing-small;
    }

    .ivu-dropdown-item {
        padding: 0;
    }
}

.set-deliverables-visible-to-dropdown__dropdown-button {
    margin: 0 0 0 $spacing-small;
}

.set-deliverables-visible-to-dropdown__dropdown-footer {
    display: flex;
    justify-content: flex-end;
    padding: $spacing $spacing-small ($spacing-small - 5px) $spacing-large;
}
</style>
