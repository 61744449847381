<template>
    <div class="editable-restriction-message ivu-message-success">
        <span><Icon type="ios-checkmark-circle" /></span>
        <span>
            Your team can now make changes to {{ label }}.
            <a @click="showModal">Tell me more</a>
        </span>
    </div>
</template>

<script>
import EditableRestrictionMessageContent from "@/components/Campaign/EditableRestrictionMessageContent";

export default {
    name: "EditableRestrictionMessage",
    props: {
        label: {
            type: String
        },

        roleName: {
            type: String
        }
    },

    methods: {
        showModal() {
            this.$Modal.info({
                render: h =>
                    h(EditableRestrictionMessageContent, {
                        props: {
                            roleName: this.roleName
                        }
                    }),
                okText: "Got it"
            });
        }
    }
};
</script>
