export default {
    props: {
        visible: {}
    },

    methods: {
        onVisibleChange(evt) {
            this.$emit("update:visible", evt);
        }
    }
};
