import Vue from "vue";
import { BannerQaCommentedOnByFilter, BannerQaStatus, BannerQaStatusFilter } from "@/enums/banners";
import { Platform, PlatformPublishedStatusFilter } from "@/enums/platforms";
import { deepClone, formatGroupName, getObjectValue } from "@/utils";
import { generateFilterForCollection, getActiveValuesOfCollectionFromQueryParams } from "@/utils/urls";

const storeNamespace = "deliverableLibrary";
const DeliverableLibraryInternalAction = {
    SetInitialState: "setInitialState",
    SetMetadataSearchFiltersByFieldId: "setMetadataSearchFiltersByFieldId",
    SyncFilters: "syncFilters",
    UpdateGroupValueFilter: "updateGroupValueFilter",
    UpdateLanguageFilter: "updateLanguageFilter",
    UpdatePublishedStatusFilter: "updatePublishedStatusFilter",
    UpdateSizeFilter: "updateSizeFilter",
    UpdateStatusFilter: "updateStatusFilter",
    UpdateUserStatusFilter: "updateUserStatusFilter",
    UpdateCommentedOnByFilter: "updateCommentedOnByFilter",
    UpdateVisibleToFilter: "updateVisibleToFilter",
    UpdateMiscFilter: "updateMiscFilter"
};

export const DeliverableLibraryAction = {
    SetDeliverableStatusByIdHash: `${storeNamespace}/setDeliverableStatusByIdHash`,
    UnsetDeliverableStatusByIdHash: `${storeNamespace}/unsetDeliverableStatusByIdHash`,
    SetInitialState: `${storeNamespace}/${DeliverableLibraryInternalAction.SetInitialState}`,
    SyncFilters: `${storeNamespace}/${DeliverableLibraryInternalAction.SyncFilters}`,
    UpdateGroupValueFilter: `${storeNamespace}/${DeliverableLibraryInternalAction.UpdateGroupValueFilter}`,
    UpdateLanguageFilter: `${storeNamespace}/${DeliverableLibraryInternalAction.UpdateLanguageFilter}`,
    UpdateSizeFilter: `${storeNamespace}/${DeliverableLibraryInternalAction.UpdateSizeFilter}`,
    UpdatePublishedStatusFilter: `${storeNamespace}/updatePublishedStatusFilter`,
    UpdateStatusFilter: `${storeNamespace}/updateStatusFilter`,
    UpdateUserStatusFilter: `${storeNamespace}/updateUserStatusFilter`,
    UpdateCommentedOnByFilter: `${storeNamespace}/updateCommentedOnByFilter`,
    UpdateVisibleToFilter: `${storeNamespace}/updateVisibleToFilter`,
    UpdateMiscFilter: `${storeNamespace}/updateMiscFilter`,
    SetCampaignId: `${storeNamespace}/setCampaignId`,
    SetTableSortedColumnKeys: `${storeNamespace}/setTableSortedColumnKeys`,
    SetTableVisibleColumnKeys: `${storeNamespace}/setTableVisibleColumnKeys`,
    SetCreativeValuesAreVisible: `${storeNamespace}/setCreativeValuesAreVisible`,
    ClearMetadataFieldsByFieldId: `${storeNamespace}/clearMetadataFieldsByFieldId`,
    SetMetadataFieldsByFieldId: `${storeNamespace}/setMetadataFieldsByFieldId`,
    UnsetMetadataFieldsByFieldId: `${storeNamespace}/unsetMetadataFieldsByFieldId`,
    SetPagination: `${storeNamespace}/setPagination`,
    SetPreviewDrawerActiveTab: `${storeNamespace}/setPreviewDrawerActiveTab`,
    SetDrawerActiveTab: `${storeNamespace}/setDrawerActiveTab`,
    SetDrawerDeliverables: `${storeNamespace}/setDrawerDeliverables`,
    SetDrawerIsVisible: `${storeNamespace}/setDrawerIsVisible`,
    SetEditableGroupFilterOptions: `${storeNamespace}/setEditableGroupFilterOptions`,
    SetLanguageFilterOptions: `${storeNamespace}/setLanguageFilterOptions`,
    SetMasterTemplateFilterOptions: `${storeNamespace}/setMasterTemplateFilterOptions`,
    SetMetadataSearchFiltersByFieldId: `${storeNamespace}/setMetadataSearchFiltersByFieldId`,
    UnsetMetadataSearchFiltersByFieldId: `${storeNamespace}/unsetMetadataSearchFiltersByFieldId`,
    ClearMetadataSearchFiltersByFieldId: `${storeNamespace}/clearMetadataSearchFiltersByFieldId`,
    SetViewMode: `${storeNamespace}/setViewMode`
};

export const DeliverableLibraryGetters = {
    allDeliverablesValues: `${storeNamespace}/allDeliverablesValues`,
    commentedOnByFilter: `${storeNamespace}/commentedOnByFilter`,
    computedDeliverableStatus: `${storeNamespace}/computedDeliverableStatus`,
    computedDeliverableIsPublishedToPlatform: `${storeNamespace}/computedDeliverableIsPublishedToPlatform`,
    computedDeliverableIsPublishedToDoubleClick: `${storeNamespace}/computedDeliverableIsPublishedToDoubleClick`,
    computedDeliverableIsPublishedToSizmek: `${storeNamespace}/computedDeliverableIsPublishedToSizmek`,
    computedDeliverableIsPublishedToODC: `${storeNamespace}/computedDeliverableIsPublishedToODC`,
    groupValueFilters: `${storeNamespace}/groupValueFilters`,
    languageFilters: `${storeNamespace}/languageFilters`,
    sizeFilters: `${storeNamespace}/sizeFilters`,
    publishedStatusFilter: `${storeNamespace}/publishedStatusFilter`,
    statusFilter: `${storeNamespace}/statusFilter`,
    userStatusFilter: `${storeNamespace}/userStatusFilter`,
    urlFilterParams: `${storeNamespace}/urlFilterParams`,
    tableColumnLabelsByColumnKey: `${storeNamespace}/tableColumnLabelsByColumnKey`,
    getLoggedInUserStatusForIdHash: `${storeNamespace}/getLoggedInUserStatusForIdHash`,
    VisibleToFilter: `${storeNamespace}/visibleToFilter`,
    miscFilters: `${storeNamespace}/miscFilters`
};

export const DeliverableLibraryInternalGetters = {
    computedDeliverableStatus: "computedDeliverableStatus"
};

export const DeliverableLibraryMutation = {
    UpdateFilter: "updateFilter",
    ResetState: "resetState",
    SetCampaignId: "setCampaignId",
    SetCreativeValuesAreVisible: "setCreativeValuesAreVisible",
    UnsetDeliverableStatusByIdHash: "unsetDeliverableStatusByIdHash",
    SetDeliverableStatusByIdHash: "setDeliverableStatusByIdHash",
    ClearMetadataFieldsByFieldId: "clearMetadataFieldsByFieldId",
    SetMetadataFieldsByFieldId: "setMetadataFieldsByFieldId",
    unsetMetadataFieldsByFieldId: "unsetMetadataFieldsByFieldId",
    SetPagination: "setPagination",
    SetTableSortedColumnKeys: "setTableSortedColumnKeys",
    SetTableVisibleColumnKeys: "setTableVisibleColumnKeys",
    SetPreviewDrawerActiveTab: "setPreviewDrawerActiveTab",
    SetDrawerActiveTab: "setDrawerActiveTab",
    SetDrawerDeliverables: "setDrawerDeliverables",
    SetDrawerIsVisible: "setDrawerIsVisible",
    SetEditableGroupFilterOptions: "setEditableGroupFilterOptions",
    SetLanguageFilterOptions: "setLanguageFilterOptions",
    SetMasterTemplateFilterOptions: "setMasterTemplateFilterOptions",
    SetMetadataSearchFiltersByFieldId: "setMetadataSearchFiltersByFieldId",
    UnsetMetadataSearchFiltersByFieldId: "unsetMetadataSearchFiltersByFieldId",
    ClearMetadataSearchFiltersByFieldId: "clearMetadataSearchFiltersByFieldId",
    SetViewMode: "setViewMode"
};

export const FilterType = {
    CommentedOnBy: "CommentedOnBy",
    GroupValue: "GroupValue",
    PublishedStatus: "PublishedStatus",
    Size: "Size",
    Status: "Status",
    UserStatus: "UserStatus",
    Text: "Text",
    Language: "Language",
    VisibleTo: "VisibleTo",
    Misc: "Misc"
};

export const UrlFilterParams = {
    CommentedOnBy: "cob",
    DeliverableDrawerIsVisible: "dd",
    GroupValue: "gv",
    MetadataSearch: "mds",
    Page: "p",
    PageSize: "ps",
    PublishedStatus: "pst",
    Size: "sz",
    Status: "st",
    Text: "q",
    UserStatus: "ust",
    Language: "lng",
    ViewMode: "vm",
    VisibleTo: "vt",
    Misc: "msc"
};

export const ViewMode = {
    Grid: "grid",
    Table: "table"
};

export const ViewModeDefaultPageSize = {
    [ViewMode.Grid]: 10,
    [ViewMode.Table]: 25
};

const generatePaginationDefaults = () => ({
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
    totalItems: 0
});

const viewModeDefault = ViewMode.Grid;
const qaStatusDefault = BannerQaStatusFilter.All;
const qaUserStatusDefault = BannerQaStatusFilter.All;
const qaCommentedOnByDefault = BannerQaCommentedOnByFilter.All;
const publishedStatusDefault = PlatformPublishedStatusFilter.All;
const visibleToDefault = [];
const miscDefault = [];

export default {
    namespaced: true,
    state: {
        campaignId: null,
        deliverableStatusByIdHash: {},
        drawer: {
            activeTab: null,
            deliverables: [],
            isVisible: false
        },
        metadataSearchFiltersByFieldId: {},
        metadataFieldsByFieldId: {},
        pagination: generatePaginationDefaults(),
        previewDrawerTab: {
            activeTab: null
        },
        table: {
            creativeValuesAreVisible: false,
            sortedColumnKeys: null,
            visibleColumnKeys: null
        },
        viewMode: viewModeDefault,
        filters: [],
        filterOptions: {
            editableGroups: [],
            languages: [],
            masterTemplates: []
        }
    },

    getters: {
        computedDeliverableIsPublishedToPlatform: state => (idHash, platform) => {
            const platformXmlFeed = getObjectValue(
                state.deliverableStatusByIdHash,
                `${idHash}.publishedStatus.${platform}.xmlFeedUrl`
            );
            return platformXmlFeed !== undefined && platformXmlFeed !== null;
        },

        computedDeliverableIsPublishedToDoubleClick: (state, getters) => idHash =>
            getters.computedDeliverableIsPublishedToPlatform(idHash, Platform.DoubleClick),

        computedDeliverableIsPublishedToSizmek: (state, getters) => idHash =>
            getters.computedDeliverableIsPublishedToPlatform(idHash, Platform.Sizmek),

        computedDeliverableIsPublishedToODC: (state, getters) => idHash =>
            getters.computedDeliverableIsPublishedToPlatform(idHash, Platform.OpenDC),

        computedDeliverableStatus: state => idHash => {
            const deliverable = state.deliverableStatusByIdHash[idHash];
            if (deliverable && deliverable.status) {
                return state.deliverableStatusByIdHash[idHash].status;
            }
            return BannerQaStatus.Draft;
        },

        getLoggedInUserStatusForIdHash(state, getters, rootState) {
            return idHash => {
                const userId = rootState.auth.me.id;
                const deliverable = state.deliverableStatusByIdHash[idHash];
                if (!deliverable) {
                    return null;
                }
                const usersStatuses = deliverable.users;
                if (usersStatuses) {
                    const userStatus = usersStatuses.find(status => status && status.user.id === userId);
                    if (userStatus) {
                        return userStatus.status;
                    }
                }
                return null;
            };
        },

        publishedStatusFilter: state => {
            const publishedStatusFilter = state.filters.filter(filter => filter.type === FilterType.PublishedStatus);
            if (publishedStatusFilter.length === 0) {
                return publishedStatusDefault;
            }
            return publishedStatusFilter[0].values[0];
        },

        commentedOnByFilter: state => {
            const commentedOnByFilter = state.filters.filter(filter => filter.type === FilterType.CommentedOnBy);
            if (commentedOnByFilter.length === 0) {
                return qaCommentedOnByDefault;
            }
            return commentedOnByFilter[0].values[0];
        },

        statusFilter: state => {
            const statusFilter = state.filters.filter(filter => filter.type === FilterType.Status);
            if (statusFilter.length === 0) {
                return qaStatusDefault;
            }
            return statusFilter[0].values[0];
        },

        userStatusFilter: state => {
            const userStatusFilter = state.filters.filter(filter => filter.type === FilterType.UserStatus);
            if (userStatusFilter.length === 0) {
                return qaUserStatusDefault;
            }
            return userStatusFilter[0].values;
        },

        groupValueFilters:
            state =>
            (groupName = undefined) => {
                const groupValueFilters = state.filters.filter(filter => filter.type === FilterType.GroupValue);

                if (groupName) {
                    const groupValueFilter = groupValueFilters.filter(filter => filter.groupName === groupName);
                    return groupValueFilter.length === 0 ? [] : [...groupValueFilter[0].groupValues];
                }

                return groupValueFilters;
            },

        sizeFilters: (state, getters, rootState) => {
            const { masterTemplates } = rootState.campaign.normalized;
            const sizeFilters = state.filters.filter(filter => filter.type === FilterType.Size);
            const sizes = sizeFilters.length === 0 ? [] : [...sizeFilters[0].values];
            // if the template is not in the list then we set a high number so that it will come last
            const getTemplateWidth = id => (masterTemplates[id] && masterTemplates[id].width) || 100000;
            const sortedSizeFilters = sizes.sort((a, b) => {
                const widthA = getTemplateWidth(a);
                const widthB = getTemplateWidth(b);
                if (widthA === widthB) {
                    return 0;
                }
                return widthA < widthB ? -1 : 1;
            });

            return sortedSizeFilters.reduce((acc, curr) => {
                if (
                    masterTemplates[curr] &&
                    masterTemplates[curr].width === 300 &&
                    masterTemplates[curr].height === 250
                ) {
                    return [curr].concat(acc);
                }
                return acc.concat(curr);
            }, []);
        },

        languageFilters: state => {
            const languageFilters = state.filters.filter(filter => filter.type === FilterType.Language);
            return languageFilters.length === 0 ? [] : [...languageFilters[0].values];
        },

        urlFilterParams: (state, getters) => {
            /*
                If we dont have a campaign ID then we will assume that the library
                is not yet initialised.

                As such we don't bother with attempting to generate any filter params.
            */
            if (!state.campaignId) {
                return [];
            }
            const urlFilterParams = [
                generateFilterForCollection(
                    UrlFilterParams.Language,
                    getters.languageFilters,
                    state.filterOptions.languages.map(({ value }) => value)
                ),
                generateFilterForCollection(
                    UrlFilterParams.Size,
                    getters.sizeFilters,
                    state.filterOptions.masterTemplates.map(({ value }) => value)
                ),
                {
                    key: UrlFilterParams.PublishedStatus,
                    value:
                        getters.publishedStatusFilter === publishedStatusDefault ? [] : [getters.publishedStatusFilter]
                },
                {
                    key: UrlFilterParams.Status,
                    value: getters.statusFilter === qaStatusDefault ? [] : [getters.statusFilter]
                },
                {
                    key: UrlFilterParams.UserStatus,
                    value: getters.userStatusFilter === qaUserStatusDefault ? [] : [getters.userStatusFilter]
                },
                {
                    key: UrlFilterParams.CommentedOnBy,
                    value: getters.commentedOnByFilter === qaCommentedOnByDefault ? [] : [getters.commentedOnByFilter]
                },

                {
                    key: UrlFilterParams.VisibleTo,
                    value: getters.visibleToFilter
                },
                {
                    key: UrlFilterParams.Misc,
                    value: getters.miscFilters
                },

                ...state.filterOptions.editableGroups.map(editableGroup =>
                    generateFilterForCollection(
                        `${UrlFilterParams.GroupValue}:${editableGroup.name}`,
                        getters.groupValueFilters(editableGroup.name),
                        editableGroup.options.map(({ value }) => value)
                    )
                )
            ];
            return urlFilterParams;
        },

        tableColumnLabelsByColumnKey(state, getters, rootState) {
            const groupLabelsByGroupName = rootState.campaign.normalized.editableGroupIds.reduce((acc, groupName) => {
                acc[groupName] = formatGroupName(groupName);
                return acc;
            }, {});
            const metadataFieldLabelsByFieldId = Object.keys(state.metadataFieldsByFieldId).reduce((acc, fieldId) => {
                acc[fieldId] = state.metadataFieldsByFieldId[fieldId].name;
                return acc;
            }, {});
            /*
                This object defines the default sort order.
            */
            return {
                status: "QA Status",
                publishedStatus: "Published",
                templateSize: "Ad Size",
                language: "Language",
                ...groupLabelsByGroupName,
                ...metadataFieldLabelsByFieldId
            };
        },

        visibleToFilter: state => {
            const visibleToFilter = state.filters.filter(filter => filter.type === FilterType.VisibleTo);
            if (visibleToFilter.length === 0) {
                return [];
            }
            return visibleToFilter[0].values[0];
        },

        miscFilters: state => {
            const miscFilter = state.filters.filter(filter => filter.type === FilterType.Misc);

            if (miscFilter.length === 0) {
                return [];
            }
            return miscFilter[0].values[0];
        }
    },

    mutations: {
        resetState(state) {
            state.filters = [];
            state.metadataSearchFiltersByFieldId = {};
            state.pagination = generatePaginationDefaults();
            state.viewMode = viewModeDefault;
            state.table.sortedColumnKeys = null;
            state.table.visibleColumnKeys = null;
        },

        updateFilter(state, filter) {
            const filters = deepClone(state.filters);
            const filterIndex = filters.findIndex(existingFilter => {
                if (filter.type === FilterType.GroupValue) {
                    return existingFilter.groupName === filter.groupName;
                }
                return existingFilter.type === filter.type;
            });
            const filterValues = filter.values || filter.groupValues;
            if (filterValues.length > 0) {
                if (filterIndex === -1) {
                    // add new filter
                    filters.push(filter);
                } else {
                    // update filter
                    filters.splice(filterIndex, 1, filter);
                }
            } else if (filterIndex !== -1) {
                filters.splice(filterIndex, 1);
            }
            state.filters = filters;
        },

        setCampaignId(state, campaignId) {
            state.campaignId = campaignId;
        },

        setPagination(state, pagination) {
            state.pagination = pagination;
        },

        setViewMode(state, viewMode) {
            state.viewMode = viewMode;
        },

        setDrawerActiveTab(state, activeTab) {
            state.drawer.activeTab = activeTab;
        },

        setDrawerDeliverables(state, deliverables) {
            state.drawer.deliverables = deliverables;
        },

        setDrawerIsVisible(state, isVisible) {
            state.drawer.isVisible = isVisible;
        },

        setPreviewDrawerActiveTab(state, activeTab) {
            state.previewDrawerTab.activeTab = activeTab;
        },

        setTableSortedColumnKeys(state, sortedColumnKeys) {
            state.table.sortedColumnKeys = [...sortedColumnKeys];
        },

        setTableVisibleColumnKeys(state, visibleColumnKeys) {
            state.table.visibleColumnKeys = [...visibleColumnKeys];
        },

        setCreativeValuesAreVisible(state, creativeValuesAreVisible) {
            state.table.creativeValuesAreVisible = creativeValuesAreVisible;
        },

        setDeliverableStatusByIdHash(state, deliverables) {
            // eslint-disable-next-line complexity
            deliverables.forEach(deliverable => {
                const idHash = deliverable.idHash || deliverable.hash;
                const existingDeliverableStatus = state.deliverableStatusByIdHash[idHash] || {};
                if (idHash) {
                    /*
                        As the deliverable data can sometimes come back incomplete from the BE (publishing
                        mutations), or for situations where we only want to set or update a single field,
                        we fall back to any current data we have for the deliverable and then finally
                        to defaults.
                    */
                    /*
                        Because publishedStatus could intentionally be null we do an explicit
                        check for it before allowing us to fall back to previous or default
                        values.
                    */
                    const publishedStatus =
                        deliverable.publishedStatus === null
                            ? deliverable.publishedStatus
                            : deliverable.publishedStatus || existingDeliverableStatus.publishedStatus || null;
                    Vue.set(state.deliverableStatusByIdHash, idHash, {
                        status: deliverable.status || existingDeliverableStatus.status || BannerQaStatus.Draft,
                        publishedStatus,
                        users: deliverable.users || existingDeliverableStatus.users || [],
                        visibleTo: deliverable.visibleTo || existingDeliverableStatus.visibleTo || []
                    });
                }
            });
        },

        unsetDeliverableStatusByIdHash(state, deliverables) {
            deliverables.forEach(deliverable => {
                Vue.delete(state.deliverableStatusByIdHash, deliverable.idHash);
            });
        },

        clearMetadataFieldsByFieldId(state) {
            state.metadataFieldsByFieldId = {};
        },

        setMetadataFieldsByFieldId(state, metadataField) {
            Vue.set(state.metadataFieldsByFieldId, metadataField._id, metadataField);
        },

        unsetMetadataFieldsByFieldId(state, metadataFieldId) {
            Vue.delete(state.metadataFieldsByFieldId, metadataFieldId);
        },

        setEditableGroupFilterOptions(state, options) {
            state.filterOptions.editableGroups = options;
        },

        setLanguageFilterOptions(state, options) {
            state.filterOptions.languages = options;
        },

        setMasterTemplateFilterOptions(state, options) {
            state.filterOptions.masterTemplates = options;
        },

        setMetadataSearchFiltersByFieldId(state, { fieldId, searchString }) {
            state.metadataSearchFiltersByFieldId = {
                ...state.metadataSearchFiltersByFieldId,
                [fieldId]: searchString
            };
        },

        unsetMetadataSearchFiltersByFieldId(state, fieldId) {
            Vue.delete(state.metadataSearchFiltersByFieldId, fieldId);
        },

        clearMetadataSearchFiltersByFieldId(state) {
            state.metadataSearchFiltersByFieldId = {};
        }
    },

    actions: {
        setDeliverableStatusByIdHash({ commit }, deliverables) {
            commit(DeliverableLibraryMutation.SetDeliverableStatusByIdHash, deliverables);
        },

        unsetDeliverableStatusByIdHash({ commit }, deliverables) {
            commit(DeliverableLibraryMutation.UnsetDeliverableStatusByIdHash, deliverables);
        },

        setInitialState({ commit, dispatch, state, rootState }, query) {
            const setPageAndViewModeState = () => {
                if (query[UrlFilterParams.Page]) {
                    commit(DeliverableLibraryMutation.SetPagination, {
                        ...state.pagination,
                        currentPage: Number(query[UrlFilterParams.Page])
                    });
                }
                if (query[UrlFilterParams.ViewMode]) {
                    commit(DeliverableLibraryMutation.SetViewMode, query[UrlFilterParams.ViewMode]);
                }
                if (state.viewMode === ViewMode.Grid) {
                    /*
                        We only allow a single page size in grid view, so we'll hard code it.
                    */
                    commit(DeliverableLibraryMutation.SetPagination, {
                        ...state.pagination,
                        pageSize: ViewModeDefaultPageSize[ViewMode.Grid]
                    });
                } else if (query[UrlFilterParams.PageSize]) {
                    commit(DeliverableLibraryMutation.SetPagination, {
                        ...state.pagination,
                        pageSize: Number(query[UrlFilterParams.PageSize])
                    });
                } else {
                    /*
                        If we're not in grid mode and we have no page size set
                        in the query param then let's assume that we're in table
                        mode, so we'll set the default table page size.
                    */
                    commit(DeliverableLibraryMutation.SetPagination, {
                        ...state.pagination,
                        pageSize: ViewModeDefaultPageSize[ViewMode.Table]
                    });
                }
            };
            const setFilterState = () => {
                state.filterOptions.editableGroups.forEach(editableGroup => {
                    dispatch(DeliverableLibraryInternalAction.UpdateGroupValueFilter, {
                        groupName: editableGroup.name,
                        groupValues: getActiveValuesOfCollectionFromQueryParams(
                            `${UrlFilterParams.GroupValue}:${editableGroup.name}`,
                            editableGroup.options.map(({ value }) => value),
                            query
                        )
                    });
                });
                dispatch(DeliverableLibraryInternalAction.UpdateSizeFilter, {
                    values: getActiveValuesOfCollectionFromQueryParams(
                        UrlFilterParams.Size,
                        state.filterOptions.masterTemplates.map(({ value }) => value),
                        query
                    )
                });
                dispatch(DeliverableLibraryInternalAction.UpdateLanguageFilter, {
                    values: getActiveValuesOfCollectionFromQueryParams(
                        UrlFilterParams.Language,
                        state.filterOptions.languages.map(({ value }) => value),
                        query
                    )
                });
                dispatch(
                    DeliverableLibraryInternalAction.UpdatePublishedStatusFilter,
                    query[UrlFilterParams.PublishedStatus] || publishedStatusDefault
                );
                dispatch(
                    DeliverableLibraryInternalAction.UpdateStatusFilter,
                    query[UrlFilterParams.Status] || qaStatusDefault
                );
                // todo split values
                dispatch(
                    DeliverableLibraryInternalAction.UpdateUserStatusFilter,
                    query[UrlFilterParams.UserStatus] || qaUserStatusDefault
                );
                dispatch(
                    DeliverableLibraryInternalAction.UpdateCommentedOnByFilter,
                    query[UrlFilterParams.CommentedOnBy] || qaCommentedOnByDefault
                );

                dispatch(
                    DeliverableLibraryInternalAction.UpdateVisibleToFilter,
                    query[UrlFilterParams.VisibleTo] || visibleToDefault
                );

                dispatch(DeliverableLibraryInternalAction.UpdateMiscFilter, query[UrlFilterParams.Misc] || miscDefault);

                Object.keys(state.metadataFieldsByFieldId).forEach(fieldId => {
                    const searchString = query[`${UrlFilterParams.MetadataSearch}:${fieldId}`];
                    if (searchString) {
                        dispatch(DeliverableLibraryInternalAction.SetMetadataSearchFiltersByFieldId, {
                            fieldId,
                            searchString
                        });
                    }
                });
            };
            commit(DeliverableLibraryMutation.ResetState);
            commit(DeliverableLibraryMutation.SetCampaignId, rootState.campaign._id);
            if (query[UrlFilterParams.DeliverableDrawerIsVisible]) {
                commit(DeliverableLibraryMutation.SetDrawerIsVisible, true);
            }
            setPageAndViewModeState();
            setFilterState();
        },

        // this should be a mutation but we need access to the rootState...
        /* eslint-disable complexity */
        syncFilters({ commit, state, rootState }) {
            /*
                The purpose of this function is to sanitize our filters by removing values that no
                longer exist in the campaign. Why? So that we don't get into a situation where
                we attempt to get data for an item that existed in a filter but is no longer part of
                the campaign, probably causing the app to crash or making unusual things happen.
            */
            state.filters.forEach(filter => {
                switch (filter.type) {
                    case FilterType.GroupValue:
                        if (
                            !filter.groupValues.every(filterGroupValue =>
                                rootState.campaign.editableGroupValueIds.includes(filterGroupValue)
                            )
                        ) {
                            const updated = {
                                ...filter,
                                groupValues: filter.groupValues.filter(filterGroupValue =>
                                    rootState.campaign.editableGroupValueIds.includes(filterGroupValue)
                                )
                            };

                            commit(DeliverableLibraryMutation.UpdateFilter, updated);
                        }

                        break;

                    case FilterType.Language:
                        if (
                            !filter.values.every(filterLanguage =>
                                rootState.campaign.languages.includes(filterLanguage)
                            )
                        ) {
                            const updated = {
                                ...filter,
                                values: filter.values.filter(filterLanguage =>
                                    rootState.campaign.languages.includes(filterLanguage)
                                )
                            };

                            commit(DeliverableLibraryMutation.UpdateFilter, updated);
                        }

                        break;

                    case FilterType.Size: {
                        const { masterTemplateIds } = rootState.campaign.normalized;
                        const filterMasterTemplateIdsWhichStillExistInCampaign = filter.values.filter(
                            filterMasterTemplateId => masterTemplateIds.includes(filterMasterTemplateId)
                        );
                        if (filterMasterTemplateIdsWhichStillExistInCampaign.length !== filter.values.length) {
                            const updated = {
                                ...filter,
                                values: filterMasterTemplateIdsWhichStillExistInCampaign
                            };
                            commit(DeliverableLibraryMutation.UpdateFilter, updated);
                        }
                        break;
                    }

                    default:
                }
            });
        },

        updateGroupValueFilter({ commit }, filter) {
            commit(DeliverableLibraryMutation.UpdateFilter, {
                ...filter,
                type: FilterType.GroupValue
            });
        },

        updateSizeFilter({ commit }, filter) {
            commit(DeliverableLibraryMutation.UpdateFilter, {
                ...filter,
                type: FilterType.Size
            });
        },

        updatePublishedStatusFilter({ commit }, status) {
            commit(DeliverableLibraryMutation.UpdateFilter, {
                type: FilterType.PublishedStatus,
                values: [status]
            });
        },

        updateStatusFilter({ commit }, status) {
            commit(DeliverableLibraryMutation.UpdateFilter, {
                type: FilterType.Status,
                values: [status]
            });
        },

        updateUserStatusFilter({ commit }, status) {
            commit(DeliverableLibraryMutation.UpdateFilter, {
                type: FilterType.UserStatus,
                values: status
            });
        },

        updateCommentedOnByFilter({ commit }, status) {
            commit(DeliverableLibraryMutation.UpdateFilter, {
                type: FilterType.CommentedOnBy,
                values: [status]
            });
        },

        updateLanguageFilter({ commit }, filter) {
            commit(DeliverableLibraryMutation.UpdateFilter, {
                ...filter,
                type: FilterType.Language
            });
        },

        updateVisibleToFilter({ commit }, status) {
            commit(DeliverableLibraryMutation.UpdateFilter, {
                type: FilterType.VisibleTo,
                values: [status]
            });
        },

        updateMiscFilter({ commit }, status) {
            const stat = Array.isArray(status) ? status : [status];
            commit(DeliverableLibraryMutation.UpdateFilter, {
                type: FilterType.Misc,
                values: [stat]
            });
        },

        setCampaignId({ commit }, campaignId) {
            commit(DeliverableLibraryMutation.SetCampaignId, campaignId);
        },

        setCreativeValuesAreVisible({ commit }, creativeValuesAreVisible) {
            commit(DeliverableLibraryMutation.SetCreativeValuesAreVisible, creativeValuesAreVisible);
        },

        clearMetadataFieldsByFieldId({ commit }) {
            commit(DeliverableLibraryMutation.ClearMetadataFieldsByFieldId);
        },

        setMetadataFieldsByFieldId({ commit }, metadataField) {
            commit(DeliverableLibraryMutation.SetMetadataFieldsByFieldId, metadataField);
        },

        unsetMetadataFieldsByFieldId({ commit }, metadataFieldId) {
            commit(DeliverableLibraryMutation.unsetMetadataFieldsByFieldId, metadataFieldId);
        },

        setTableSortedColumnKeys({ commit }, sortedColumnKeys) {
            commit(DeliverableLibraryMutation.SetTableSortedColumnKeys, sortedColumnKeys);
        },

        setTableVisibleColumnKeys({ commit }, visibleColumnKeys) {
            commit(DeliverableLibraryMutation.SetTableVisibleColumnKeys, visibleColumnKeys);
        },

        setPagination({ commit }, pagination) {
            commit(DeliverableLibraryMutation.SetPagination, pagination);
        },

        setPreviewDrawerActiveTab({ commit }, activeTab) {
            commit(DeliverableLibraryMutation.SetPreviewDrawerActiveTab, activeTab);
        },

        setViewMode({ commit }, viewMode) {
            commit(DeliverableLibraryMutation.SetViewMode, viewMode);
        },

        setDrawerActiveTab({ commit }, activeTab) {
            commit(DeliverableLibraryMutation.SetDrawerActiveTab, activeTab);
        },

        setDrawerDeliverables({ commit }, deliverables) {
            commit(DeliverableLibraryMutation.SetDrawerDeliverables, deliverables);
        },

        setDrawerIsVisible({ commit }, isVisible) {
            commit(DeliverableLibraryMutation.SetDrawerIsVisible, isVisible);
        },

        setEditableGroupFilterOptions({ commit }, options) {
            commit(DeliverableLibraryMutation.SetEditableGroupFilterOptions, options);
        },

        setLanguageFilterOptions({ commit }, options) {
            commit(DeliverableLibraryMutation.SetLanguageFilterOptions, options);
        },

        setMasterTemplateFilterOptions({ commit }, options) {
            commit(DeliverableLibraryMutation.SetMasterTemplateFilterOptions, options);
        },

        setMetadataSearchFiltersByFieldId({ commit }, { fieldId, searchString }) {
            commit(DeliverableLibraryMutation.SetMetadataSearchFiltersByFieldId, { fieldId, searchString });
        },

        unsetMetadataSearchFiltersByFieldId({ commit }, fieldId) {
            commit(DeliverableLibraryMutation.UnsetMetadataSearchFiltersByFieldId, fieldId);
        },

        clearMetadataSearchFiltersByFieldId({ commit }) {
            commit(DeliverableLibraryMutation.ClearMetadataSearchFiltersByFieldId);
        }
    }
};
