<template>
    <div class="timeline-analysis-chart">
        <div class="timeline-analysis-chart__wrapper" :style="chartStyle">
            <canvas ref="timingChart" class="timeline-analysis-chart__chart" />
        </div>
    </div>
</template>

<script>
import { Chart } from "chart.js";
import "./GanttChart";
import { AttributeTypes, ColorsHEX, sortByAttributeLabelOrder, TimelineAnalysisType } from "@/enums/reporting";
import { ChartLabelsStyle, NoScalePadding } from "@/components/CreativeIntelligence/constants";
import { CreativeInsightsGetters } from "@/store/modules/creativeInsights";

const SCREENSHOT_INTERVAL = 0.5;

const barHeight = 18;
const rowHeight = 25;
const barRadius = 10;

export default {
    props: {
        attributes: {
            type: Array
        },

        currentTime: {
            type: Number,
            default: 0
        },

        duration: {
            type: [Number, String]
        },

        scaleX: {
            type: Object
        }
    },

    data() {
        return {
            chart: undefined,
            filters: [],
            firstLoadWidth: "100%"
        };
    },

    computed: {
        attributeTypes() {
            return [...new Set(this.sortedAttributes.map(({ type }) => type))];
        },

        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        chartHeight() {
            // we assume "rowHeight" per row, 2 rows per entry + 40px to accommodate scales
            return this.yLabels.length * 2 * rowHeight + 23;
        },

        chartStyle() {
            return { height: `${this.chartHeight}px` };
        },

        datasets() {
            return [...this.timelineDatasets];
        },

        filterOptions() {
            return [...new Set(this.sortedAttributes.map(({ type }) => type))].map(({ attribute }) => ({
                value: attribute,
                label: attribute
            }));
        },

        kpi() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricName];
        },

        chartOptions() {
            return {
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: true,
                        callbacks: {
                            label: ({ label, raw: { y } }) => {
                                return [`${label}: ${y}`];
                            }
                        },
                        xAlign: "center",
                        yAlign: "bottom"
                    }
                },
                layout: {
                    padding: {
                        left: 2,
                        right: NoScalePadding.right
                    }
                },

                maintainAspectRatio: false,
                responsive: true,
                scales: this.scales,
                animation: false,
                active: {
                    mode: null
                }
            };
        },

        scaleAttributes() {
            return {
                offset: true,
                ticks: {
                    mirror: true,
                    labelOffset: -20,
                    ...ChartLabelsStyle
                },
                grid: {
                    display: false
                }
            };
        },

        scales() {
            return {
                x: {
                    ...this.scaleX,
                    position: "bottom",
                    ticks: {
                        display: false
                    }
                },
                y: {
                    ...this.scaleAttributes,
                    stackWeight: 2,
                    stack: "chartStack",
                    grid: {
                        drawBorder: false,
                        display: false
                    }
                }
            };
        },

        sortedAttributes() {
            return [...this.timelineAttributes].sort((a, b) => {
                const labelOrderCmp = sortByAttributeLabelOrder(a, b);

                if (labelOrderCmp !== 0) {
                    return labelOrderCmp;
                }

                const strA = a.attribute.toLowerCase();
                const strB = b.attribute.toLowerCase();

                return strA > strB ? 1 : strA < strB ? -1 : 0;
            });
        },

        textOverlayAttributes() {
            return this.attributes.filter(({ type }) => type === "TextOverlay");
        },

        textOverlayData() {
            return Object.values(this.textOverlayDataMap).sort((a, b) => b.x - a.x);
        },

        textOverlayDataMap() {
            return this.getChartDataMap(this.textOverlayAttributes);
        },

        textOverlayMaxValue() {
            return Math.max(...this.textOverlayData.map(({ y }) => y));
        },

        ticksCount() {
            if (!this.duration) {
                return 0;
            }

            const duration = Number.parseFloat(this.duration);
            return Math.ceil(duration * (1 / SCREENSHOT_INTERVAL)) + 1;
        },

        timelineAttributes() {
            return this.attributes;
        },

        timelineDatasets() {
            return this.attributeTypes.reduce((acc, analysisType) => {
                const data = this.sortedAttributes.filter(({ type }) => type === analysisType);

                if (!data.length) {
                    return acc;
                }

                const { backgroundColors, labels, values } = this.generateChartData(data);

                acc.push({
                    analysisType,
                    backgroundColor: backgroundColors,
                    borderColor: ColorsHEX.transparent,
                    data: values,
                    height: barHeight,
                    radius: barRadius,
                    label: analysisType,
                    labels
                });
                return acc;
            }, []);
        },

        yLabels() {
            return [...new Set(this.sortedAttributes.map(({ attribute }) => attribute))];
        }
    },

    watch: {
        async chartStyle(newStyle) {
            this.$refs.timingChart.setAttribute("height", this.chartHeight);
            this.$refs.timingChart.style.height = newStyle.height;

            this.chart.resize(this.$refs.timingChart.width, this.chartHeight);
            await this.$nextTick();
            this.chart.options.scales = this.scales;
            this.chart.data.labels = this.yLabels;
            this.chart.data.datasets = this.datasets;
            this.chart.update();
        },

        breakdownsIdentifier() {
            this.chart.update();
        },

        attributes: {
            handler() {
                this.chart.options.scales = this.scales;
                this.chart.data.labels = this.yLabels;
                this.chart.data.datasets = this.datasets;
                this.chart.update();
            }
        },

        duration() {
            this.chart.options.scales = this.scales;
            this.chart.update();
        },

        kpi() {
            this.chart.options.scales = this.scales;
            this.chart.update();
        }
    },

    async mounted() {
        this.createChart();
        await this.$nextTick();
        this.firstLoadWidth = Math.floor(this.$refs.timingChart.style.width.replace("px", ""));
    },

    methods: {
        createChart() {
            this.chart = new Chart(this.$refs.timingChart, {
                type: "Gantt",
                options: this.chartOptions,
                data: {
                    labels: this.yLabels,
                    datasets: this.datasets
                }
            });
        },

        generateChartData(attributes) {
            return attributes.reduce(
                (acc, attribute) => {
                    const { backgroundColor, hoverBackgroundColor } = this.getTypeColor(attribute.type);
                    const y = attribute.attribute;
                    if (!attribute.timeline) {
                        return acc;
                    }

                    const useIndividualPointColors = [
                        AttributeTypes.COLOR_THEORY_COLOR,
                        AttributeTypes.COLOR_THEORY_COLOR_GROUP,
                        AttributeTypes.COLOR_THEORY_PANTONE_COLOR_GROUP,
                        AttributeTypes.COLOR_THEORY_PRIMARY_COLOR_GROUP
                    ].includes(attribute.type);

                    let pointColor = backgroundColor;
                    if (useIndividualPointColors) {
                        const colorHex = attribute.attribute.split("-");
                        pointColor = colorHex.length > 1 ? colorHex[1] : colorHex[0];
                    }

                    const values = attribute.timeline.map(segment => {
                        if (segment.start === segment.end) {
                            return {
                                x: segment.start,
                                x2: segment.end,
                                y,
                                backgroundColor: useIndividualPointColors ? pointColor : backgroundColor,
                                hoverBackgroundColor: useIndividualPointColors ? pointColor : hoverBackgroundColor,
                                attribute
                            };
                        }

                        return {
                            x: segment.start,
                            x2: segment.end,
                            y,
                            backgroundColor: useIndividualPointColors ? pointColor : backgroundColor,
                            hoverBackgroundColor: useIndividualPointColors ? pointColor : hoverBackgroundColor,
                            attribute
                        };
                    });

                    return {
                        backgroundColors: [
                            ...acc.backgroundColors,
                            useIndividualPointColors ? pointColor : backgroundColor
                        ],
                        hoverBackgroundColors: [
                            ...acc.hoverBackgroundColors,
                            useIndividualPointColors ? pointColor : hoverBackgroundColor
                        ],
                        labels: [...acc.labels, y],
                        values: [...acc.values, ...values]
                    };
                },
                {
                    backgroundColors: [],
                    hoverBackgroundColors: [],
                    labels: [],
                    values: []
                }
            );
        },

        getChartDataMap(attributes) {
            if (!this.ticksCount) {
                return [];
            }
            const initObj = Array(this.ticksCount)
                .fill(null)
                .reduce((acc, curr, i) => {
                    acc[i * SCREENSHOT_INTERVAL] = { y: 0, x: i * SCREENSHOT_INTERVAL };
                    return acc;
                }, {});

            return attributes.reduce((acc, { timeline, attribute }) => {
                // ignore attributes without the timeline
                if (!timeline) {
                    return acc;
                }

                timeline.forEach(({ start, end }) => {
                    for (let i = start; i <= end; i += SCREENSHOT_INTERVAL) {
                        acc[i].y += Number.parseFloat(attribute);
                    }
                });

                return acc;
            }, initObj);
        },

        getTypeColor(type) {
            if (
                type === TimelineAnalysisType.Text ||
                type === TimelineAnalysisType.TextValue ||
                type === TimelineAnalysisType["ImageTag-Label"]
            ) {
                return {
                    backgroundColor: ColorsHEX.forAdam,
                    hoverBackgroundColor: ColorsHEX.forAdam
                };
            }

            if (type === TimelineAnalysisType.Logo || type === TimelineAnalysisType["ImageTag-Logo"]) {
                return {
                    backgroundColor: ColorsHEX.green,
                    hoverBackgroundColor: ColorsHEX.green
                };
            }

            return {
                backgroundColor: ColorsHEX.gray,
                hoverBackgroundColor: ColorsHEX.gray
            };
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
.timeline-analysis-chart {
    margin-top: -6px;
    &__wrapper {
        position: relative;
    }
    &__chart {
        position: relative;
        width: 100% !important;

        /* https://github.com/jtblin/angular-chart.js/issues/614 */
    }
}
</style>
