<template>
    <div class="advanced-model-options__input">
        <label>{{ label }}</label>
        <div class="advanced-model-options__input-slider">
            <span>{{ value }}</span>
            <Slider
                :min="settings.minimum"
                :max="settings.maximum"
                :step="settings.step"
                :value="value"
                @input="onUpdate"
            ></Slider>
        </div>
    </div>
</template>
<script>
export default {
    name: "AdvancedModelOptionsSlider",
    props: {
        settings: {
            type: Object,
            required: true
        },
        value: {
            type: [String, Number]
        },
        label: {
            type: String
        }
    },

    methods: {
        onUpdate(val) {
            this.$emit("input", val);
        }
    }
};
</script>
