<template>
    <hox-notification-item
        :show-button="isCompleted"
        :show-clear-button="showClearButton"
        :show-error-message="hasFailed"
        :status="notification.status"
        @clear="removeNotification"
    >
        <template #title>
            {{ title }}
        </template>
        <template #subtitle>
            {{ statusText }}
        </template>
        <template #content>
            <template v-if="isCompleted">
                <p>Here is the link to download. Please to press the button to download your deliverables.</p>
            </template>
        </template>
        <template #errorMessage>
            <p>There was an unexpected issue while preparing ads for download.</p>
        </template>
        <template #button>
            <Button size="small" target="_blank" @click="downloadHandler">Download</Button>
        </template>
    </hox-notification-item>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import nl2br from "shared-utils/nl2br";
import { NotificationStatus, NotificationTypes } from "@/enums/notifications";
import { NotificationsAction } from "@/store/modules/notifications";
import getIntelligentDeliveryApiInfoQuery from "@/apollo/queries/v2/GetIntelligentDeliveryApiInfo.gql";
import { fetchRequest } from "@/components/Reporting/data/utils";

export default {
    props: {
        notification: {
            required: true,
            type: Object
        }
    },

    data() {
        return {
            apiInfo: {}
        };
    },
    computed: {
        hasFailed() {
            return this.notification.status === NotificationStatus.Failed;
        },

        failedLabels() {
            const {
                payload: { failedTasks }
            } = this.notification.job;

            if (!failedTasks) {
                return [];
            }

            return Object.values(failedTasks).map(({ label }) => label);
        },

        isCompleted() {
            return this.notification.status === NotificationStatus.Completed;
        },

        notificationMessage() {
            return nl2br(this.notification.message);
        },

        showClearButton() {
            return this.hasFailed || this.isCompleted;
        },

        statusText() {
            if (this.hasFailed) {
                return "Failed";
            }
            return this.notificationMessage;
        },

        isExcelJob() {
            return this.notification.type === NotificationTypes.FlashtalkingDownloadExcel;
        },

        isAssetsJob() {
            return this.notification.type === NotificationTypes.FlashtalkingDownloadAssets;
        },

        title() {
            if (this.isExcelJob) {
                return "Download your Excel";
            }
            if (this.isAssetsJob) {
                return "Download your Assets";
            }
            return "";
        }
    },

    methods: {
        removeNotification() {
            this.$store.dispatch(NotificationsAction.RemoveItem, this.notification.id);
        },

        async downloadHandler() {
            try {
                const singEndpoint = `${this.apiInfo.url}/v1/resource/request/${this.notification.job._id}`;
                const body = {
                    type: this.notification.type,
                    input: this.notification.job.payload
                };
                const response = await fetchRequest({
                    endpoint: singEndpoint,
                    method: "POST",
                    apiKey: this.apiInfo.apiKey,
                    body: JSON.stringify(body)
                });
                if (response.ok) {
                    const responseValidateJson = await response.json();
                    if (responseValidateJson?.data?.output?.success?.excelFileLocation?.signedUrl) {
                        window.open(
                            responseValidateJson?.data?.output?.success?.excelFileLocation?.signedUrl,
                            "_blank"
                        );
                    } else if (responseValidateJson?.data?.output?.success?.assetsFileLocation?.signedUrl) {
                        window.open(
                            responseValidateJson?.data?.output?.success?.assetsFileLocation?.signedUrl,
                            "_blank"
                        );
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }
    },

    apollo: {
        intelligentDeliveryApiInfo: {
            query: getIntelligentDeliveryApiInfoQuery,
            result({ data }) {
                this.apiInfo = data.intelligentDeliveryApiInfo;
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.download-job-notification-item__sub-progress {
    margin-top: $spacing-small;
    padding-left: $spacing-base;
    border-left: 1px solid $cmp-light-border-color;

    &-label {
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        margin-bottom: $spacing-smaller;

        > :first-child {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
</style>
