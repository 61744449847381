<template>
    <div class="master-template-select">
        <div v-show="viewMode === ViewMode.Grid" ref="body" class="master-template-select__wrapper">
            <CheckboxGroup v-model="selectedItems">
                <template v-if="gridItems.length && !groupByFamily">
                    <div
                        v-for="masterTemplateId in gridItems"
                        :key="masterTemplateId"
                        class="campaign-edit__deliverable"
                        :class="{
                            'campaign-edit__deliverable--resizable': resizedBanners.includes(masterTemplateId)
                        }"
                    >
                        <master-template-preview
                            v-if="!!masterTemplates[masterTemplateId].adType"
                            :is-selectable="isSelectable(masterTemplateId)"
                            :max-height="bodyDimensionsPixels.height"
                            :max-width="bodyDimensionsPixels.width"
                            :scaling-factor="scalingFactor"
                            :master-template="masterTemplates[masterTemplateId]"
                            @remove="onRemove(masterTemplateId)"
                            @resized="onResize(masterTemplateId, $event)"
                        />
                    </div>
                </template>
                <template v-else-if="gridItems.length && groupByFamily">
                    <div v-for="{ parentId, children } in gridItemFamilies" :key="parentId" class="template-family">
                        <div v-if="parentId" class="template-family__parent">
                            <h4>Parent Template</h4>
                            <div
                                class="campaign-edit__deliverable"
                                :class="{
                                    'campaign-edit__deliverable--resizable': resizedBanners.includes(parentId)
                                }"
                            >
                                <master-template-preview
                                    :is-selectable="isSelectable(parentId)"
                                    :max-height="bodyDimensionsPixels.height"
                                    :max-width="bodyDimensionsPixels.width"
                                    :scaling-factor="scalingFactor"
                                    :master-template="masterTemplates[parentId]"
                                    @remove="onRemove(parentId)"
                                    @resized="onResize(parentId, $event)"
                                />
                            </div>
                        </div>
                        <div v-if="children.length" class="template-family__children">
                            <h4>Resized Templates</h4>
                            <div
                                v-for="masterTemplateId in children"
                                :key="masterTemplateId"
                                class="campaign-edit__deliverable"
                                :class="{
                                    'campaign-edit__deliverable--resizable': resizedBanners.includes(masterTemplateId)
                                }"
                            >
                                <master-template-preview
                                    :is-selectable="isSelectable(masterTemplateId)"
                                    :max-height="bodyDimensionsPixels.height"
                                    :max-width="bodyDimensionsPixels.width"
                                    :scaling-factor="scalingFactor"
                                    :master-template="masterTemplates[masterTemplateId]"
                                    @remove="onRemove(masterTemplateId)"
                                    @resized="onResize(masterTemplateId, $event)"
                                />
                            </div>
                        </div>
                    </div>
                </template>
                <hox-empty-message v-else>
                    <template #title>
                        <template v-if="hasCampaignItems">There aren't any new templates in your library</template>
                        <template v-else>There are no templates in your library</template>
                    </template>
                    <template #actionItems>
                        <master-template-upload-button
                            v-if="$auth.userCan($auth.Actions.CanManageMasterTemplates, { clientId }, false)"
                        >
                            <Button data-testid="upload-template__button" type="primary">Upload a template</Button>
                        </master-template-upload-button>
                    </template>
                </hox-empty-message>
            </CheckboxGroup>
        </div>
        <div v-if="viewMode === ViewMode.List" class="master-template-table-select__wrapper">
            <master-template-table
                v-model="selectedItems"
                :items="gridItems"
                :is-selectable="hasCampaignItems"
                @remove="onRemove"
            ></master-template-table>
        </div>
        <div class="master-template-select__footer">
            <div class="master-templates-select__paging paging-controls">
                <Page
                    v-if="itemsToPage.length"
                    :current="page"
                    :total="itemsToPage.length"
                    :page-size="pageSize"
                    :page-size-opts="pageSizeOpts"
                    @on-change="updatePageNumber"
                ></Page>

                <Select v-model="pageSize" class="hidden">
                    <Option v-for="sizeOpt in pageSizeOpts" :key="sizeOpt.value" :value="sizeOpt.value">
                        {{ sizeOpt.label }}
                    </Option>
                </Select>
            </div>
        </div>
    </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { TemplatesSortBy, TemplateType } from "shared-utils/enums/masterTemplate";

import { deepClone } from "@/utils";
import MasterTemplates from "@/services/MasterTemplates";
import MasterTemplatePreview from "@/components/Campaign/MasterTemplatePreview";
import paginationMixin from "@/mixins/paginationMixin";
import MasterTemplateUploadButton from "@/components/Campaign/MasterTemplates/MasterTemplateUploadButton";
import HoxEmptyMessage from "@/components/common/EmptyMessage";

import { UiAction } from "@/store/modules/ui";
import multiviewPaginationMixin from "@/mixins/multiviewPaginationMixin";
import scalingBannerWrapperMixin from "@/mixins/scalingBannerWrapperMixin";
import { FilterType } from "@/store/modules/masterTemplateLibrary";
import MasterTemplateTable from "@/components/Campaign/MasterTemplates/MasterTemplatesTable";
import { MultiviewMode } from "@/enums/multiview";

export default {
    name: "MasterTemplateSelect",

    components: {
        MasterTemplateTable,
        MasterTemplateUploadButton,
        MasterTemplatePreview,
        HoxEmptyMessage
    },
    mixins: [paginationMixin, multiviewPaginationMixin, scalingBannerWrapperMixin],

    props: {
        campaignTemplateIds: {
            type: Array,
            default() {
                return null;
            }
        },

        instanceName: {
            type: String,
            default: "multiview"
        },

        items: {
            type: Array,
            required: true
        },

        sortBy: {
            type: Object,
            default() {
                return TemplatesSortBy.WIDTH_ASC;
            }
        }
    },

    data() {
        return {
            optionsVisible: false,
            resizedBanners: [],
            selected: []
        };
    },

    computed: {
        clientId() {
            return this.$store.state.route.params.clientId;
        },

        filteredItems() {
            const items = deepClone(this.items);

            if (this.campaignTemplateIds && this.campaignTemplateIds.length) {
                return items.filter(item => !this.campaignTemplateIds.includes(item));
            }

            return items;
        },

        gridItems() {
            return this.pagedItems;
        },

        gridItemFamilies() {
            const faimiliesByParentId = this.pagedItems.reduce((acc, templateId) => {
                const template = this.masterTemplates[templateId];
                const id = template.parentId || template._id;
                acc[id] = acc[id] || { parentId: null, children: [] };
                if (!template.parentId) {
                    acc[template._id].parentId = template._id;
                } else {
                    acc[template.parentId].children.push(template._id);
                }

                return acc;
            }, {});

            return Object.values(faimiliesByParentId);
        },

        groupByFamily() {
            return !!this.$store.state.masterTemplateLibrary.filters.filter(filter => filter.type === FilterType.Family)
                .length;
        },

        hasCampaignItems() {
            return this.campaignTemplateIds !== null;
        },

        itemsToPage() {
            return this.sortedItems;
        },

        masterTemplates() {
            return this.$store.state.masterTemplateLibrary.masterTemplates;
        },

        pageSizeOpts() {
            return this.pageSizes.map(size => ({
                value: size,
                label: `${size}/page`
            }));
        },

        pageSizes() {
            return [this.defaultPageSize];
        },

        selectedItems: {
            get() {
                return this.selected;
            },

            set(value) {
                this.selected = value;
                this.$emit("select", value);

                this.$store.dispatch(UiAction.MultiviewUpdate, {
                    instance: this.instanceName,
                    selected: this.selected.length,
                    pageCount: this.pagedItems.length,
                    totalCount: this.itemsToPage.length
                });
            }
        },

        sortedItems() {
            return [...this.filteredItems].sort((a, b) => {
                const masterTemplateA = this.masterTemplates[a];
                const masterTemplateB = this.masterTemplates[b];

                return this.sortElements(masterTemplateA, masterTemplateB, this.sortBy);
            });
        },

        viewMode() {
            return this.$store.state.masterTemplateLibrary.selectViewMode;
        }
    },

    watch: {
        items() {
            this.selectedItems = [];
            this.page = 1;
        },

        viewMode(mode) {
            if (mode === MultiviewMode.Grid) {
                this.setBodyDimensionsPixels();
            }
        }
    },

    created() {
        window.addEventListener("resize", this.setBodyDimensionsPixels, true);
        this.masterTemplateService = new MasterTemplates(this.$apollo, this.clientId);
        this.initMultiviewPagination();
        this.ViewMode = MultiviewMode;
    },

    mounted() {
        this.selectedItems = []; // forcing setting initial vuex state
        this.setBodyDimensionsPixels();
    },

    methods: {
        isSelectable(templateId) {
            // preview is selectable if we have campaignTemplateIds and
            // if it's an AE template, if the template is complete
            if (this.campaignTemplateIds === null) {
                return false;
            }

            if (this.masterTemplates[templateId]?.adType === TemplateType.AE) {
                return !!this.masterTemplates[templateId]?.aeInfo?.completed;
            }

            return true;
        },

        async onRemove(masterTemplateId) {
            try {
                await this.masterTemplateService.delete(masterTemplateId);
            } catch (e) {
                this.$snackbar.error("Upload Failed", e.message, {
                    duration: 0,
                    closable: true
                });
            }
        },

        onResize(masterTemplateId, isResized) {
            if (isResized) {
                this.resizedBanners.push(masterTemplateId);
            } else {
                this.resizedBanners = this.resizedBanners.filter(id => masterTemplateId !== id);
            }
        },

        sortElements(a, b, sortBy) {
            const directionMultiplier = sortBy.dir === "asc" ? -1 : 1;

            if (a[sortBy.field] < b[sortBy.field]) {
                return directionMultiplier;
            }

            if (a[sortBy.field] > b[sortBy.field]) {
                return directionMultiplier * -1;
            }

            return 0;
        },

        templateName(masterTemplate) {
            return `${masterTemplate.width} x  ${masterTemplate.height}`;
        },

        templateSize(masterTemplateId) {
            return {
                width: `${this.masterTemplates[masterTemplateId].width}px`,
                height: `${this.masterTemplates[masterTemplateId].height}px`
            };
        },

        updateSelected(value) {
            this.selected = value;
            this.$emit("select", value);

            this.$store.dispatch(UiAction.MultiviewUpdate, {
                instance: this.instanceName,
                selected: this.selected.length,
                pageCount: this.pagedItems.length,
                totalCount: this.itemsToPage.length
            });
        }
    }
};
</script>
<style lang="scss">
@import "@/../sass/_variables.scss";

.master-template-select {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .master-template-select__wrapper {
        overflow-x: auto;
        padding: $spacing-large 20px $spacing;
        flex: 1;
    }

    .master-template-select__footer {
        padding: $spacing 20px;
        border-top: 1px solid $grey3;
    }
}
</style>
