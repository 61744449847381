<template>
    <resizable-campaign-banner
        :key="campaignBannerKey"
        :banner-name="templateName"
        :master-template="masterTemplate"
        :max-height="maxHeight"
        :max-width="maxWidth"
        :show-resize-header="showResizeHeader"
        :scaling-factor="scalingFactor"
        :resizable="resizable"
        :input-height="inputHeight"
        :input-width="inputWidth"
        @resized="onResize"
    >
        <template #bannerContainer="{ slotProps }">
            <template v-if="isSelectable">
                <Checkbox
                    class="campaign-banner__banner-container-checkbox campaign-banner__checkbox"
                    :label="masterTemplate._id"
                >
                    <banner-preview
                        :banner="previewBannerAdapter"
                        :preview-values="previewValues"
                        :lazy-load="true"
                        :scaling-factor="slotProps.internalScalingFactor"
                        :ad-type="masterTemplate.adType"
                        :is-resizable="slotProps.isResizable"
                    />
                </Checkbox>
            </template>
            <template v-else>
                <banner-preview
                    :banner="previewBannerAdapter"
                    :preview-values="previewValues"
                    :lazy-load="true"
                    :scaling-factor="slotProps.internalScalingFactor"
                    :ad-type="masterTemplate.adType"
                    :is-resizable="slotProps.isResizable"
                />
            </template>
        </template>

        <template slot="footerControls">
            <campaign-banner-play :banner-id="masterTemplate._id"></campaign-banner-play>
        </template>

        <template v-if="canManageMasterTemplates" #footerOptions="{ slotProps, slotToggleResizeMode }">
            <Poptip
                v-model="optionsVisible"
                placement="bottom"
                class="hide-title"
                popper-class="campaign-edit__poptip campaign-edit__poptip--transferred"
                :transfer="true"
            >
                <icon type="ios-more" />
                <master-template-banner-options
                    slot="content"
                    :master-template="masterTemplate"
                    :is-resizable="slotProps.isResizable"
                    @resizeMode="slotToggleResizeMode"
                    @hide="onOptionsHide"
                    @update="onUpdate(masterTemplate._id)"
                    @familyUpdated="onFamilyUpdate"
                    @remove="deleteTemplatePrompt"
                ></master-template-banner-options>
            </Poptip>
        </template>
    </resizable-campaign-banner>
</template>
<script>
// eslint-disable-next-line no-unused-vars, import/no-extraneous-dependencies
import * as Types from "shared-utils/types";

import BannerPreview from "@/components/Previews/BannerPreview";
import CampaignBannerPlay from "@/components/Campaign/CampaignBannerPlay";
import MasterTemplateBannerOptions from "@/components/Campaign/MasterTemplates/MasterTemplateBannerOptions";
import scalingBannerMixin from "@/mixins/scalingBannerMixin";
import ResizableCampaignBanner from "@/components/Campaign/ResizableCampaignBanner";

export default {
    name: "MasterTemplatePreview",
    components: {
        ResizableCampaignBanner,
        BannerPreview,
        CampaignBannerPlay,
        MasterTemplateBannerOptions
    },

    mixins: [scalingBannerMixin],

    props: {
        /**
         * @type {Types.MasterTemplate}
         */
        masterTemplate: {
            type: Object,
            required: true
        },

        isSelectable: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            bannerUpdateCount: 0,
            optionsVisible: false
        };
    },

    computed: {
        adapterId() {
            if (this.isSelectable || this.resizable) {
                return this.masterTemplate._id;
            }
            return `iframe-${this.masterTemplate._id}`;
        },

        campaignBannerKey() {
            return `${this.masterTemplate._id}-${this.bannerUpdateCount}`;
        },

        canManageMasterTemplates() {
            return this.$auth.userCan(this.$auth.Actions.CanManageMasterTemplates, { clientId: this.clientId });
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        previewBannerAdapter() {
            return {
                ...this.masterTemplate,
                id: this.adapterId
            };
        },

        previewValues() {
            return JSON.stringify({
                computedOverwrites: [],
                metadata: [],
                masterTemplate: {
                    _id: this.masterTemplate._id,
                    persistentKey: this.masterTemplate.persistentKey,
                    width: this.masterTemplate.width,
                    height: this.masterTemplate.height,
                    aeInfo: {
                        completed: this.masterTemplate?.aeInfo?.completed,
                        plainlyProjectId: this.masterTemplate?.aeInfo?.plainlyProjectId,
                        plainlyTemplateId: this.masterTemplate?.aeInfo?.plainlyTemplateId
                    },
                    reportingLabel: this.masterTemplate.reportingLabel
                },
                previewUrl: this.masterTemplate.preview,
                psdThumbnail: this.masterTemplate.psdThumbnail
            });
        },

        templateName() {
            return `${this.masterTemplate.width} x ${this.masterTemplate.height} - ${this.masterTemplate.name}`;
        }
    },

    methods: {
        deleteTemplatePrompt() {
            this.$Modal.confirm({
                title: "Are you sure?",
                // content: "Warning: This template is used in <campaign name1, campaign name 2>. All ads using this template will be deleted. This is permanent and can not be undone.",
                content:
                    "This template may be used in other campaigns. All ads using this template will be deleted. This is permanent and can not be undone.",
                okText: "Delete",
                onOk: () => {
                    this.$emit("remove");
                },
                cancelText: "Cancel",
                width: 300
            });
        },

        onOptionsHide() {
            this.optionsVisible = false;
        },

        onResize(sizes) {
            this.$emit("resized", sizes);
        },

        onUpdate(masterTemplateId) {
            this.$emit("update", masterTemplateId);
            this.bannerUpdateCount += 1;
        },

        onFamilyUpdate(masterTemplateIds) {
            masterTemplateIds.forEach(masterTemplateId => this.$emit("update", masterTemplateId));
            this.bannerUpdateCount += 1;
        }
    }
};
</script>
