<template>
    <div class="library-deliverable-table-metadata-header-cell">
        <edit-metadata-field-modal
            v-if="editMetadataFieldModalIsVisible"
            :column-name="columnName"
            :field-id="fieldId"
            @close="toggleEditMetadataFieldModalIsVisible"
        />
        <set-metadata-values-modal
            v-if="setMetadataValuesModalIsVisible"
            :metadata-field-id="fieldId"
            :deliverables="deliverables"
            :selected-deliverables="selectedDeliverables"
            :library-filters-for-query="computedLibraryFiltersForQuery"
            :all-selected-mode="allSelectedMode"
            :total-items="totalItems"
            @close="toggleSetMetadataValuesModalIsVisible"
            @metadataValueUpdatedOnDeliverables="$emit('metadataValueUpdatedOnDeliverables', $event)"
        />
        <div class="library-deliverable-table-metadata-header-cell__content">
            <span class="library-deliverable-table-metadata-header-cell__title">
                <slot />
            </span>
            <div
                v-if="userCanManageMetadata"
                class="library-deliverable-table-metadata-header-cell__edit-button-wrapper"
            >
                <Dropdown
                    placement="bottom-start"
                    transfer
                    trigger="custom"
                    :visible="dropdownIsVisible"
                    @on-clickoutside="toggleDropdownIsVisible"
                >
                    <hox-square-button
                        has-inverse-hover
                        :is-active="dropdownIsVisible"
                        size="small"
                        type="primary"
                        @click.native="toggleDropdownIsVisible"
                    >
                        <Icon type="ios-more" />
                    </hox-square-button>
                    <template #list>
                        <DropdownItem @click.native="toggleEditMetadataFieldModalIsVisible">
                            Edit column
                        </DropdownItem>
                        <DropdownItem @click.native="toggleSetMetadataValuesModalIsVisible">
                            Set values
                        </DropdownItem>
                    </template>
                </Dropdown>
            </div>
        </div>
    </div>
</template>

<script>
import EditMetadataFieldModal from "@/components/Metadata/EditMetadataFieldModal";
import SetMetadataValuesModal from "@/components/Metadata/SetMetadataValuesModal";

export default {
    components: {
        EditMetadataFieldModal,
        SetMetadataValuesModal
    },

    props: {
        allSelectedMode: {
            type: Boolean,
            default: false
        },

        columnName: {
            required: true,
            type: String
        },

        deliverables: {
            required: true,
            type: Array
        },

        fieldId: {
            type: String,
            required: true
        },

        libraryFiltersForQuery: {
            required: true,
            type: Object
        },

        selectedDeliverables: {
            required: true,
            type: Array
        },

        totalItems: {
            required: true,
            type: Number
        }
    },

    data() {
        return {
            dropdownIsVisible: false,
            editMetadataFieldModalIsVisible: false,
            setMetadataValuesModalIsVisible: false
        };
    },

    computed: {
        campaignId() {
            return this.$route.params.campaignId;
        },

        clientId() {
            return this.$route.params.clientId;
        },

        computedLibraryFiltersForQuery() {
            return (this.allSelectedMode && this.libraryFiltersForQuery) || {};
        },

        userCanManageMetadata() {
            return this.$auth.userCan(this.$auth.Actions.CanManageMetadata, {
                clientId: this.clientId,
                campaignId: this.campaignId
            });
        }
    },

    methods: {
        toggleDropdownIsVisible() {
            this.dropdownIsVisible = !this.dropdownIsVisible;
        },

        toggleEditMetadataFieldModalIsVisible() {
            this.editMetadataFieldModalIsVisible = !this.editMetadataFieldModalIsVisible;
        },

        toggleSetMetadataValuesModalIsVisible() {
            this.setMetadataValuesModalIsVisible = !this.setMetadataValuesModalIsVisible;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
.library-deliverable-table-metadata-header-cell {
    font-weight: bold;
    width: 100%;
}

.library-deliverable-table-metadata-header-cell__content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.library-deliverable-table-metadata-header-cell__edit-button-wrapper {
    margin: (0 - $spacing-semi-small) 0 (0 - $spacing-semi-small) $spacing-smaller;
}

.library-deliverable-table-metadata-header-cell__title {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
