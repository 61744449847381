<template>
    <div class="text-stats-chart">
        <slot name="title" :attributes-max-value="attributesMaxValue">
            <h4>Text words per second (Max: {{ ticksCallback(attributesMaxValue) }})</h4>
        </slot>
        <div class="text-stats-chart__wrapper">
            <canvas ref="textOverlay" class="text-stats-chart__chart" />
        </div>
    </div>
</template>

<script>
import { Chart } from "chart.js";
import { ColorsHEX } from "@/enums/reporting";
import { CreativeInsightsGetters } from "@/store/modules/creativeInsights";

const SCREENSHOT_INTERVAL = 0.5;

// todo extract a common component
// todo disable animations

const getBgColor = (rgbColor, value, datasetMax) => {
    if (value === 0) {
        return `${rgbColor}00`;
    }

    const maxPercent = value / datasetMax;
    // + 10% to avoid being fully transparent
    const percent = maxPercent + 0.03 > 1 ? 1 : maxPercent + 0.03;
    const hexAlpha = Math.ceil(255 * percent).toString(16);

    return `${rgbColor}${hexAlpha}`;
};

export default {
    name: "TextStatsChart",
    props: {
        currentTime: {
            type: Number,
            default: 0
        },

        duration: {
            type: [Number, String],
            default: 10
        },

        attributes: {
            type: Array,
            required: true,
            default() {
                return [];
            }
        },

        scaleX: {
            type: Object
        },

        ticksCallback: {
            type: Function,
            default(value) {
                return `${value}`;
            }
        },

        color: {
            type: String,
            default: "#1890ff"
        },

        showX: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            chart: undefined
        };
    },

    computed: {
        timelineTicks() {
            return [...Array(this.ticksCount)].map((v, i) => i * SCREENSHOT_INTERVAL);
        },

        ticksCount() {
            if (!this.duration) {
                return 0;
            }

            const duration = Number.parseFloat(this.duration);
            return Math.ceil(duration * (1 / SCREENSHOT_INTERVAL)) + 1;
        },

        attributesDataMap() {
            return this.getChartDataMap(this.attributes);
        },

        attributesData() {
            return Object.values(this.attributesDataMap).sort((a, b) => b.x - a.x);
        },

        attributesMaxValue() {
            return Math.max(...this.attributesData.map(({ y }) => y));
        },

        datasets() {
            if (!this.duration) {
                return [];
            }

            return [
                {
                    backgroundColor: this.attributesData.map(({ y }) =>
                        getBgColor(this.color, y, this.attributesMaxValue)
                    ),
                    borderColor: ColorsHEX.transparent,
                    data: this.attributesData,
                    fill: true,
                    pointRadius: 4,
                    inflateAmount: 0,
                    type: "SegmentBar",
                    barPercentage: 1,
                    categoryPercentage: 1,
                    borderWidth: 0
                }
            ];
        },

        kpi() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricName];
        },

        options() {
            return {
                animation: false,
                plugins: {
                    legend: {
                        display: false,
                        maxHeight: 0
                    },
                    title: {
                        display: false,
                        padding: 0
                    },
                    subtitle: {
                        display: false,
                        padding: 0
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                ...(!this.showX && {
                    layout: {
                        padding: 0
                    }
                }),
                maintainAspectRatio: false,
                responsive: true,
                scales: this.scales
            };
        },

        scales() {
            return {
                x: {
                    ...this.scaleX,
                    ...(!this.showX && {
                        ticks: {
                            display: false
                        }
                    }),
                    offset: false,
                    grid: {
                        ...this.scaleX.grid,
                        zeroLineColor: "transparent",
                        // color: "transparent"
                        display: false,
                        offset: false
                    }
                },

                y: {
                    offset: false,
                    id: "textOverlay",
                    min: 0,
                    max: this.attributesMaxValue,
                    // suggestedMax: this.attributesMaxValue,
                    // drawOnChartArea: false,
                    grid: {
                        display: false,
                        drawBorder: false,
                        drawTicks: false,
                        tickLength: 1
                    },
                    scaleLabel: {
                        display: false
                    },
                    ticks: {
                        display: false,
                        mirror: false,
                        // labelOffset: 10,
                        callback: this.ticksCallback,
                        // startAtZero: true,
                        padding: 0,
                        backdropPadding: 0
                    }
                }
            };
        }
    },

    watch: {
        attributes() {
            this.chart.options.scales = this.scales;
            this.chart.data.datasets = this.datasets;
            this.chart.update();
        },

        attributesReportDataAvailable() {
            this.chart.options.scales = this.scales;
            this.chart.data.datasets = this.datasets;
            this.chart.update();
        },

        duration() {
            this.chart.options.scales = this.scales;
            this.chart.data.datasets = this.datasets;
            this.chart.update();
        },

        kpi() {
            this.chart.options.scales = this.scales;
            this.chart.data.datasets = this.datasets;
            this.chart.update();
        },

        videoMetrics() {
            this.chart.options.scales = this.scales;
            this.chart.data.datasets = this.datasets;
            this.chart.update();
        }
    },

    mounted() {
        this.chart = new Chart(this.$refs.textOverlay, {
            options: this.options,
            type: "line",
            data: {
                datasets: this.datasets
            }
        });
    },

    methods: {
        getChartDataMap(attributes) {
            if (!this.ticksCount) {
                return [];
            }
            const initObj = Array(this.ticksCount)
                .fill(null)
                .reduce((acc, curr, i) => {
                    acc[i * SCREENSHOT_INTERVAL] = { y: 0, x: i * SCREENSHOT_INTERVAL };
                    return acc;
                }, {});

            return attributes.reduce((acc, { timeline, attribute }) => {
                // ignore attributes without the timeline
                if (!timeline) {
                    return acc;
                }

                timeline.forEach(({ start, end }) => {
                    for (let i = start; i < end; i += SCREENSHOT_INTERVAL) {
                        acc[i].y += Number.parseFloat(attribute);
                    }
                });

                return acc;
            }, initObj);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
.text-stats-chart {
    position: relative;
    height: 48px;
    padding: 0;
    margin: 0;

    h4 {
        padding-left: 18px;
        font-size: 14px;
        top: 4px;
    }

    &__chart {
        width: 100%;
    }

    &__wrapper {
        height: 28px;
        padding: 0;
        margin: 0;
    }
}
</style>
