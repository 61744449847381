import URLManager from "@/services/URLManager";

/**
 * Implementation:
 * requires the implementing component to define
 * itemsToPage data/computed value that will provide source data for the pagination
 *
 * Example paging element:
 * @example
 * <i-page v-if="itemsToPage.length"
 class="paging-control paging-control-pages"
 :current="page"
 :total="itemsToPage.length"
 :pageSize="pageSize"
 @on-change="updatePageNumber"></i-page>
 */

export default {
    data() {
        return {
            initPage: 1,
            defaultPageSize: 10,
            trackInUrl: true,
            page: 1,
            pageSize: 10,
            tracked: {
                page: { key: "p", value: [1] },
                pageSize: { key: "ps", value: [10] }
            }
        };
    },

    created() {
        this.initPagnation();
        if (this.trackInUrl) {
            this.urlManager = new URLManager(this.$router, {
                defaults: [this.tracked.page, this.tracked.pageSize],
                tracked: [this.tracked.page.key, this.tracked.pageSize.key]
            });
        }
    },

    computed: {
        dataByPage() {
            const byPage = [];

            if (!this.itemsToPage.length) {
                return byPage;
            }

            for (let i = 0; i < this.itemsToPage.length; i += this.pageSize) {
                byPage.push(this.itemsToPage.slice(i, i + this.pageSize));
            }

            return byPage;
        },

        itemsToPage() {
            throw new Error("In order to use this mixin you need to implement itemsToPage function");
        },

        pagedItems() {
            return this.dataByPage[this.page - 1] || [];
        }
    },

    methods: {
        initPagnation() {
            const page = parseInt(this.$store.state.route.query.p, 10);
            const pageSize = parseInt(this.$store.state.route.query.ps, 10);

            this.initPage = !Number.isNaN(page) && page > 1 ? page : 1;
            this.pageSize = !Number.isNaN(pageSize) && pageSize !== 10 ? pageSize : 10;
        },

        updatePageNumber(page) {
            this.page = page;
        }
    },

    watch: {
        page(val) {
            if (this.trackInUrl) {
                this.urlManager.setURLParams([{ ...this.tracked.page, value: [val] }]);
            }
        },

        pageSize(val) {
            if (this.trackInUrl) {
                this.urlManager.setURLParams([
                    {
                        ...this.tracked.pageSize,
                        value: [val]
                    }
                ]);
            }
        }
    }
};
