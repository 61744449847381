// @ts-check
// eslint-disable-next-line no-unused-vars, import/no-extraneous-dependencies
import * as Types from "shared-utils/types";

/**
 * @param {Types.ComputedValues[]} computedValues
 * @returns {Types.ComputedOverwrite[]}
 */
function computedValuesToComputedOverwrites(computedValues) {
    return computedValues.map(({ value, name, type, layer, publisherPlatform, settings }) => ({
        newValue: value,
        name,
        type,
        layer,
        isPublisherPlatform: !!publisherPlatform,
        settings,
        htmlTag: "manifest"
    }));
}

export default computedValuesToComputedOverwrites;
