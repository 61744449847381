<template>
    <li class="family-update-progress-row">
        <!-- prettier-ignore -->
        <slot></slot>
        &nbsp;
        <template v-if="isMaster">(Master)&nbsp;</template>
        <hox-loading-spinner v-if="isLoading" class="family-update-progress-row__spinner"></hox-loading-spinner>
        <template v-else-if="hasFailed"><Icon :size="16" type="md-close" /></template>
        <template v-else><Icon type="md-checkmark" :size="16" /></template>
    </li>
</template>
<script>
export default {
    name: "FamilyUpdateProgressRow",
    props: {
        isMaster: {
            type: Boolean,
            default: false
        },

        isLoading: {
            type: Boolean,
            default: true
        },

        hasFailed: {
            type: Boolean,
            default: false
        }
    }
};
</script>
<style lang="scss">
@import "@/../sass/_variables.scss";

.family-update-progress-row {
    &__spinner {
        display: inline-block;
        top: $spacing-smallest;
    }
}
</style>
