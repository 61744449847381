// eslint-disable-next-line import/no-extraneous-dependencies
import hexToRgb from "shared-utils/hexToRgb";
// eslint-disable-next-line import/no-extraneous-dependencies
import {
    REPORT_DATA_FILE_TYPES as ReportDataFileTypes,
    ATTRIBUTE_TYPES as BackendAttributeTypes,
    TEXT_ENTITIES_TYPES as TextEntityTypes,
    COLOR_THEORY_TYPES
} from "@hoxton/lambda/gql/Reporting/modules/creativeInsights/constants";
// eslint-disable-next-line import/no-extraneous-dependencies
import { IMAGE_TAG_TYPES as ImageTypeAttributeSubtype } from "@hoxton/lambda/gql/MediaLibrary/modules/constants";

const ReportKPI = {
    AddToCartRate: "add_to_cart_rate",
    AddToCartValueRate: "add_to_cart_value_rate",
    ClickRate: "clicks_rate",
    ConversionRate: "conversion_rate",
    PurchaseRate: "purchase_rate",
    PurchaseValueRate: "purchase_value_rate",
    VideoP100Rate: "video_p100_rate",
    VideoP25Rate: "video_p25_rate",
    VideoP50Rate: "video_p50_rate",
    VideoP75Rate: "video_p75_rate",
    VideoP95Rate: "video_p95_rate",
    VideoStartedRate: "video_started_rate",
    ReactionsTotalRate: "reactions_total_rate"
};

const ReportKpiByMetric = Object.entries(ReportKPI).reduce((acc, entry) => {
    const [key, value] = entry;
    acc[value] = key;
    return acc;
}, {});

const ReportKPILabels = {
    [ReportKPI.ClickRate]: "Click-through Rate",
    [ReportKPI.ConversionRate]: "Conversion Rate",
    [ReportKPI.AddToCartRate]: "Add to cart rate",
    [ReportKPI.AddToCartValueRate]: "Add to cart value rate",
    [ReportKPI.PurchaseRate]: "Purchase rate",
    [ReportKPI.PurchaseValueRate]: "Purchase value rate",
    [ReportKPI.VideoP100Rate]: "Video p 100 rate",
    [ReportKPI.VideoP25Rate]: "Video p 25 rate",
    [ReportKPI.VideoP50Rate]: "Video p 50 rate",
    [ReportKPI.VideoP75Rate]: "Video p 75 rate",
    [ReportKPI.VideoP95Rate]: "Video p 95 rate",
    [ReportKPI.VideoStartedRate]: "Video started rate",
    impressions: "Impressions"
};

const AttributeTypes = {
    ...BackendAttributeTypes,
    COLOR_THEORY_COLOR: `${BackendAttributeTypes.COLOR_THEORY}-${COLOR_THEORY_TYPES.COLOR}`,
    COLOR_THEORY_COLOR_GROUP: `${BackendAttributeTypes.COLOR_THEORY}-${COLOR_THEORY_TYPES.COLOR_GROUP}`,
    COLOR_THEORY_PANTONE_COLOR_GROUP: `${BackendAttributeTypes.COLOR_THEORY}-${COLOR_THEORY_TYPES.PANTONE_COLOR_GROUP}`,
    COLOR_THEORY_PRIMARY_COLOR_GROUP: `${BackendAttributeTypes.COLOR_THEORY}-${COLOR_THEORY_TYPES.PRIMARY_COLOR_GROUP}`,
    COLOR_THEORY_TEMPERATURE: `${BackendAttributeTypes.COLOR_THEORY}-${COLOR_THEORY_TYPES.TEMPERATURE}`,
    COLOR_THEORY_TEMPERATURE_GROUP: `${BackendAttributeTypes.COLOR_THEORY}-${COLOR_THEORY_TYPES.TEMPERATURE_GROUP}`,
    ENTITY_COMMERCIAL_ITEM: `${BackendAttributeTypes.ENTITY}-${TextEntityTypes.COMMERCIAL_ITEM}`,
    ENTITY_DATE: `${BackendAttributeTypes.ENTITY}-${TextEntityTypes.DATE}`,
    ENTITY_LOCATION: `${BackendAttributeTypes.ENTITY}-${TextEntityTypes.LOCATION}`,
    ENTITY_ORGANIZATION: `${BackendAttributeTypes.ENTITY}-${TextEntityTypes.ORGANIZATION}`,
    ENTITY_OTHER: `${BackendAttributeTypes.ENTITY}-${TextEntityTypes.OTHER}`,
    ENTITY_PERSON: `${BackendAttributeTypes.ENTITY}-${TextEntityTypes.PERSON}`,
    ENTITY_QUANTITY: `${BackendAttributeTypes.ENTITY}-${TextEntityTypes.QUANTITY}`,
    ENTITY_TITLE: `${BackendAttributeTypes.ENTITY}-${TextEntityTypes.TITLE}`,
    ENTITY_EVENT: `${BackendAttributeTypes.ENTITY}-${TextEntityTypes.EVENT}`,
    IMAGE_TAG_AGE: `${BackendAttributeTypes.IMAGE_TAG}-${ImageTypeAttributeSubtype.AGE}`,
    IMAGE_TAG_EMOTION: `${BackendAttributeTypes.IMAGE_TAG}-${ImageTypeAttributeSubtype.EMOTION}`,
    IMAGE_TAG_FACIAL_FEATURE: `${BackendAttributeTypes.IMAGE_TAG}-${ImageTypeAttributeSubtype.FACIAL_FEATURE}`,
    IMAGE_TAG_GENDER: `${BackendAttributeTypes.IMAGE_TAG}-${ImageTypeAttributeSubtype.GENDER}`,
    IMAGE_TAG_LABEL: `${BackendAttributeTypes.IMAGE_TAG}-${ImageTypeAttributeSubtype.LABEL}`,
    IMAGE_TAG_OBJECT: `${BackendAttributeTypes.IMAGE_TAG}-${ImageTypeAttributeSubtype.OBJECT}`,
    IMAGE_TAG_LOGO: `${BackendAttributeTypes.IMAGE_TAG}-${ImageTypeAttributeSubtype.LOGO}`,
    IMAGE_TAG_DOMINANT_COLOR: `${BackendAttributeTypes.IMAGE_TAG}-${ImageTypeAttributeSubtype.DOMINANT_COLOUR}`,
    REPORTING_LABEL: "ReportingLabel",
    TEXT_OVERLAY: "TextOverlay",
    WORD_COUNT_GROUP: "WordCountGroup"
};

const AttributeTypeLabels = {
    // [AttributeTypes.EDITABLE_VALUE]: "Placeholder Value",
    [AttributeTypes.IMAGE_TAG]: "Image Tag",
    [AttributeTypes.IMAGE_VALUE]: "Images",
    [AttributeTypes.LANGUAGE]: "Language",
    // [AttributeTypes.METADATA]: "Metadata",
    [AttributeTypes.TEMPLATE_SIZE]: "Ad Size",
    [AttributeTypes.TEXT_SENTIMENT]: "Text Sentiment",
    [AttributeTypes.TEXT_VALUE]: "Text",
    [AttributeTypes.VIDEO_VALUE]: "Video",
    [AttributeTypes.IMAGE_TAG_AGE]: "Age",
    [AttributeTypes.IMAGE_TAG_EMOTION]: "Facial Expression",
    [AttributeTypes.IMAGE_TAG_FACIAL_FEATURE]: "Facial Features",
    [AttributeTypes.IMAGE_TAG_GENDER]: "Gender",
    [AttributeTypes.IMAGE_TAG_LABEL]: "Other",
    [AttributeTypes.IMAGE_TAG_OBJECT]: "Objects",
    [AttributeTypes.IMAGE_TAG_LOGO]: "Logos",
    [AttributeTypes.REPORTING_LABEL]: "Reporting Label",
    [AttributeTypes.KEYWORD]: "Key Word",
    [AttributeTypes.WORD_COUNT]: "Word Count",
    [AttributeTypes.WORD_COUNT_BRACKET]: "Word Count Groups",
    [AttributeTypes.KEY_PHRASE]: "Key Phrase",
    [AttributeTypes.ENTITY]: "Text Entity",
    [AttributeTypes.ENTITY_COMMERCIAL_ITEM]: "Commercial Item",
    [AttributeTypes.ENTITY_DATE]: "Date",
    [AttributeTypes.ENTITY_LOCATION]: "Location",
    [AttributeTypes.ENTITY_ORGANIZATION]: "Brand",
    [AttributeTypes.ENTITY_OTHER]: "Other",
    [AttributeTypes.ENTITY_PERSON]: "Celebrity",
    [AttributeTypes.ENTITY_QUANTITY]: "Price & Offers",
    [AttributeTypes.ENTITY_TITLE]: "Title",
    [AttributeTypes.COLOR_THEORY_COLOR]: "Color",
    [AttributeTypes.COLOR_THEORY_COLOR_GROUP]: "Color Group",
    [AttributeTypes.COLOR_THEORY_PANTONE_COLOR_GROUP]: "Pantone Color Group",
    [AttributeTypes.COLOR_THEORY_PRIMARY_COLOR_GROUP]: "Primary Color Group",
    [AttributeTypes.COLOR_THEORY_TEMPERATURE]: "Color Temperature",
    [AttributeTypes.COLOR_THEORY_TEMPERATURE_GROUP]: "Color Temperature Group",
    [AttributeTypes.IMAGE_TAG_DOMINANT_COLOR]: "Image Tag Dominant Color",
    [AttributeTypes.ENTITY_EVENT]: "Event"
};

const AttributeTypeTootipLabels = {
    ...AttributeTypeLabels,
    [AttributeTypes.IMAGE_VALUE]: "Image",
    [AttributeTypes.IMAGE_TAG_FACIAL_FEATURE]: "Facial Feature",
    [AttributeTypes.IMAGE_TAG_LABEL]: "Image Label",
    [AttributeTypes.IMAGE_TAG_OBJECT]: "Image Object",
    [AttributeTypes.IMAGE_TAG_LOGO]: "Image Logo",
    [AttributeTypes.WORD_COUNT_BRACKET]: "Word Count Group"
};

const AttributeTypeColors = {
    [AttributeTypes.IMAGE_VALUE]: "blue",
    [AttributeTypes.LANGUAGE]: "blue",
    [AttributeTypes.TEMPLATE_SIZE]: "pink",
    [AttributeTypes.TEXT_VALUE]: "blue",
    [AttributeTypes.VIDEO_VALUE]: "blue",
    [AttributeTypes.IMAGE_TAG_AGE]: "orange",
    [AttributeTypes.IMAGE_TAG_EMOTION]: "orange",
    [AttributeTypes.IMAGE_TAG_FACIAL_FEATURE]: "orange",
    [AttributeTypes.IMAGE_TAG_GENDER]: "orange",
    [AttributeTypes.IMAGE_TAG_LABEL]: "orange",
    [AttributeTypes.IMAGE_TAG_OBJECT]: "orange",
    [AttributeTypes.IMAGE_TAG_LOGO]: "orange",
    [AttributeTypes.REPORTING_LABEL]: "pink",
    [AttributeTypes.TEXT_SENTIMENT]: "green",
    [AttributeTypes.KEYWORD]: "blue",
    [AttributeTypes.WORD_COUNT]: "blue",
    [AttributeTypes.WORD_COUNT_BRACKET]: "blue",
    [AttributeTypes.KEY_PHRASE]: "blue",
    [AttributeTypes.ENTITY_COMMERCIAL_ITEM]: "green",
    [AttributeTypes.ENTITY_DATE]: "green",
    [AttributeTypes.ENTITY_LOCATION]: "green",
    [AttributeTypes.ENTITY_ORGANIZATION]: "green",
    [AttributeTypes.ENTITY_OTHER]: "green",
    [AttributeTypes.ENTITY_PERSON]: "green",
    [AttributeTypes.ENTITY_QUANTITY]: "green",
    [AttributeTypes.ENTITY_TITLE]: "green",
    [AttributeTypes.COLOR_THEORY]: "purple",
    [AttributeTypes.COLOR_THEORY_COLOR]: "purple",
    [AttributeTypes.COLOR_THEORY_COLOR_GROUP]: "purple",
    [AttributeTypes.COLOR_THEORY_PANTONE_COLOR_GROUP]: "purple",
    [AttributeTypes.COLOR_THEORY_PRIMARY_COLOR_GROUP]: "purple",
    [AttributeTypes.COLOR_THEORY_TEMPERATURE]: "purple",
    [AttributeTypes.COLOR_THEORY_TEMPERATURE_GROUP]: "purple"
};

const ColorsHEX = {
    deviationUp: "#41b883",
    deviationDown: "#f9444e",
    orange: "#ee825e",
    blue: "#477ef6",
    black: "#000000",
    cyan: "#5ad8a6",
    forAdam: "#faa804",
    gray: "#5d7092",
    green: "#1e9493",
    lightgray: "#cccccc",
    pink: "#dc5a83",
    purple: "#945fb9",
    red: "#e86452",
    transparent: "#FFFFFF00",
    yellow: "#f6bd16"
};

const hexToDotColor = hex => {
    const { red, green, blue } = hexToRgb(hex);

    return `rgba(${red}, ${green}, ${blue}, 0.7)`;
};

const Colors = Object.entries(ColorsHEX).reduce((acc, [key, value]) => {
    acc[key] = hexToDotColor(value);

    return acc;
}, {});

const TimelineAnalysisType = {
    "ImageTag-Label": "ImageTag-Label",
    "ImageTag-Logo": "ImageTag-Logo",
    TextValue: "TextValue",
    Label: "Label",
    Logo: "Logo",
    Text: "Text"
};

const timelineAttributeLabelOrder = {
    "ImageTag-Label": 3,
    "ImageTag-Logo": 4,
    Label: 3,
    Logo: 4,
    Text: 2,
    TextValue: 2
};

const getTimelineAttributeLabelOrder = attributeType => timelineAttributeLabelOrder[attributeType] || 0;

const sortByAttributeLabelOrder = (a, b) =>
    getTimelineAttributeLabelOrder(b.type) - getTimelineAttributeLabelOrder(a.type);

export {
    AttributeTypeColors,
    AttributeTypeLabels,
    AttributeTypes,
    AttributeTypeTootipLabels,
    Colors,
    ColorsHEX,
    hexToDotColor,
    ReportDataFileTypes,
    ReportKPI,
    ReportKpiByMetric,
    ReportKPILabels,
    sortByAttributeLabelOrder,
    TimelineAnalysisType,
    timelineAttributeLabelOrder
};
