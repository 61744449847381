<template>
    <div class="library-commented-on-by-filter">
        <collapsible-widget :is-opened="true">
            <template #name>Comments</template>
            <RadioGroup :value="commentedOnByFilter">
                <Radio
                    v-for="filter in BannerQaCommentedOnByFilter"
                    :key="filter"
                    :label="filter"
                    @click.native.stop.prevent="setCommentedOnByFilter(filter)"
                >
                    {{ BannerQaCommentedOnByFilterLabel[filter] }}
                </Radio>
            </RadioGroup>
        </collapsible-widget>
    </div>
</template>

<script>
import CollapsibleWidget from "@/components/Campaign/widgets/CollapsibleWidget";
import { BannerQaCommentedOnByFilter, BannerQaCommentedOnByFilterLabel } from "@/enums/banners";
import { DeliverableLibraryAction, DeliverableLibraryGetters } from "@/store/modules/deliverableLibrary";

export default {
    components: {
        CollapsibleWidget
    },
    computed: {
        commentedOnByFilter: {
            get() {
                return this.$store.getters[DeliverableLibraryGetters.commentedOnByFilter];
            },
            set(value) {
                this.$store.dispatch(DeliverableLibraryAction.UpdateCommentedOnByFilter, value);
            }
        }
    },
    created() {
        this.BannerQaCommentedOnByFilter = BannerQaCommentedOnByFilter;
        this.BannerQaCommentedOnByFilterLabel = BannerQaCommentedOnByFilterLabel;
    },
    methods: {
        setCommentedOnByFilter(commentedOnBy) {
            this.commentedOnByFilter = commentedOnBy;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.library-commented-on-by-filter {
    .ivu-radio {
        margin: 0;
    }

    .ivu-radio-group {
        display: block;
    }

    .ivu-radio-wrapper {
        margin: 0;
        padding: 0 0 0 50px;
        display: block;
    }
}
</style>
