<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <ul class="navigation-tabs">
        <slot />
    </ul>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            validator(value) {
                return ["primary", "secondary", "tertiary"].includes(value);
            }
        },
        size: {
            type: String,
            validator(value) {
                return ["small"].includes(value);
            }
        }
    },
    provide() {
        return {
            color: () => this.color,
            size: () => this.size
        };
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.navigation-tabs {
    display: block;
    font-size: 0;
    list-style: none;
}
</style>
