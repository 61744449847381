const jsonFetcher = async uri => {
    const response = await fetch(uri);
    if (!response.ok) {
        throw new Error(`Unable to fetch uri: ${uri}`);
    }
    return response.json();
};

const jsonPoster = async (uri, data = {}) => {
    const response = await fetch(uri, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error(`Unable to post data to uri: ${uri}`);
    }
    return response.json();
};

export class HoxtonCliClient {
    cliServerUri;

    constructor(serverUri) {
        this.cliServerUri = serverUri;
    }

    async getData(key) {
        if (!key) {
            return null;
        }
        const dataUri = `${this.cliServerUri}/api/data/${key}`;
        try {
            // If there is a 404 here it will show in the console and there is
            //  nothing we can do about that right now: https://bugs.chromium.org/p/chromium/issues/detail?id=124534
            const response = await jsonFetcher(dataUri);
            return response.data;
        } catch (err) {
            return null;
        }
    }

    async getTemplateList() {
        const templateUri = `${this.cliServerUri}/api/master-templates`;
        const response = await jsonFetcher(templateUri);
        if (!response.data) {
            throw new Error("Unable to get templates from Hoxton CLI");
        }
        return response.data;
    }

    async setData(key, data) {
        if (!key || !data) {
            throw new Error(`Cannot set data. Key: ${key}, Data: ${data}`);
        }
        const dataUri = `${this.cliServerUri}/api/data/${key}`;
        await jsonPoster(dataUri, data);
    }
}
