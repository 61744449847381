<template>
    <container>
        <hox-loading-layer v-if="isLoading && !isInitialLoad" />
        <!-- <add-campaign-section-modal
            v-if="addSectionModalIsVisible"
            :client-id="clientId"
            @close="toggleAddSectionModalIsVisible"
            @sectionAdded="reloadCampaignsAndSections"
        />
        <edit-campaign-section-modal
            v-if="editCampaignSectionModalIsVisible"
            :section-id="sectionToEdit._id"
            :section-name="sectionToEdit.name"
            @close="toggleEditCampaignSectionModalIsVisible"
            @deleted="reloadCampaignsAndSections"
            @sectionUpdated="reloadCampaignsAndSections"
        />
        <set-campaign-section-modal
            v-if="setCampaignSectionModalIsVisible"
            :campaign-id="campaignIdToEdit"
            :sections="sections"
            @close="toggleSetCampaignSectionModalIsVisible"
            @campaignSectionSet="reloadCampaignsAndSections"
        /> -->
        <add-font-modal
            v-if="addFontModalIsVisible && client.mastheadUploadConfig"
            :upload-config="client.mastheadUploadConfig"
            @fontAdded="reloadFonts"
            @close="toggleAddFontModalIsVisible"
        />
        <div v-if="isLoading && isInitialLoad" class="fonts-dashboard__loading-wrapper">
            <hox-loading-layer :is-full-screen="false" />
        </div>
        <div v-else-if="hasFonts" class="fonts">
            <div class="fonts__action-items">
                <sort-fonts v-model="sortFontsBy" class="fonts__action-item"></sort-fonts>
                <!-- <Button
                    v-if="userCanManageCampaignSections"
                    class="fonts__action-item"
                    size="large"
                    @click="toggleAddSectionModalIsVisible"
                >
                    Add new section
                </Button> -->
                <Button
                    v-if="userCanManageFonts"
                    class="fonts__action-item"
                    size="large"
                    type="primary"
                    data-testid="add-mew-font__button"
                    @click="toggleAddFontModalIsVisible"
                >
                    Add new font
                </Button>
            </div>
            <Row :gutter="15">
                <Col v-for="font of fontsBySectionId.noSection" :key="font._id" :xs="24" :sm="12" :md="8" :lg="6">
                    <font-card
                        :font="font"
                        :client-id="clientId"
                        :upload-config="client.mastheadUploadConfig"
                        @edit="reloadFonts"
                        @deleted="reloadFonts"
                    />
                </Col>
            </Row>
            <!-- <div v-for="section in visibleSections" :key="section._id">
                <div class="fonts__section-header">
                    <h3 class="fonts__section-title">{{ section.name }}</h3>
                    <div v-if="userCanManageCampaignSections" class="fonts__section-action-items">
                        <hox-square-button size="small" @click="showEditCampaignSectionModal(section._id)">
                            <Icon type="md-create" />
                        </hox-square-button>
                    </div>
                    <hr class="fonts__section-divider" />
                </div>
                <div v-if="!fontsBySectionId[section._id]" class="fonts__section-empty-message">
                    <p>There are no campaigns in this section.</p>
                    <p>
                        You can add a campaign to this section by editing an existing campaign or creating a new
                        campaign and selecting this section.
                    </p>
                </div>
                <Row v-else :gutter="15">
                    <Col
                        v-for="campaign of fontsBySectionId[section._id]"
                        :key="campaign._id"
                        :xs="24"
                        :sm="12"
                        :md="8"
                        :lg="6"
                    >
                        <campaign-card
                            :id="campaign._id"
                            :key="campaign._id"
                            :client-id="clientId"
                            :logo="campaign.mastheadImageUrl"
                            :name="campaign.name"
                            :title="campaign.name"
                            @edit="showEditCampaignModal(campaign._id)"
                            @setSection="showSetCampaignSectionModal(campaign._id)"
                        />
                    </Col>
                </Row>
            </div> -->
        </div>
        <div v-else class="fonts-dashboard__empty-message-wrapper">
            <template v-if="userCanManageFonts">
                <hox-empty-message>
                    <template #title>No custom fonts exist for this client</template>
                    <template #actionItems>
                        <Button
                            v-if="userCanManageFonts"
                            data-testid="new-font__button"
                            type="primary"
                            @click="toggleAddFontModalIsVisible"
                        >
                            Add new font
                        </Button>
                    </template>
                </hox-empty-message>
            </template>
            <template v-else>
                <hox-empty-message>
                    <template #title>You don't have access to any custom fonts for this client</template>
                </hox-empty-message>
            </template>
        </div>
    </container>
</template>

<script>
import fontsQuery from "@/apollo/queries/FontsByClient.gql";
// import campaignSectionsQuery from "@/apollo/queries/CampaignSections.gql";
import clientUploadConfigQuery from "@/apollo/queries/ClientUploadConfig.gql";
import FontCard from "@/components/FontCard";
import Container from "@/components/Container";
import AddFontModal from "@/components/AddFontModal";
import SortFonts, { defaultFontsSortBy } from "@/components/SortFonts";

export default {
    name: "FontsDashboard",
    components: {
        AddFontModal,
        SortFonts,
        FontCard,
        Container
    },

    data() {
        return {
            addSectionModalIsVisible: false,
            fonts: [],
            campaignIdToEdit: undefined,
            addFontModalIsVisible: false,
            hasLoadedFonts: false,
            isLoadingFonts: 0,
            sortFontsBy: defaultFontsSortBy
            // sections: [],
            // editCampaignSectionModalIsVisible: false,
            // hasErrorLoadingSections: false,
            // hasLoadedSections: false,
            // isLoadingSections: 0,
            // sectionIdToEdit: undefined,
            // setCampaignSectionModalIsVisible: false
        };
    },

    computed: {
        fontsBySectionId() {
            const fontsBySectionId = {
                noSection: []
            };
            this.fonts.forEach(font => {
                if (!font.section || !font.section._id) {
                    fontsBySectionId.noSection.push(font);
                    return;
                }
                if (fontsBySectionId[font.section._id] === undefined) {
                    fontsBySectionId[font.section._id] = [];
                }
                fontsBySectionId[font.section._id].push(font);
            });
            return fontsBySectionId;
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        hasFonts() {
            return this.fonts.length > 0;
        },

        isInitialLoad() {
            return !this.hasLoadedFonts; // || !this.hasLoadedSections;
        },

        isLoading() {
            return this.isLoadingFonts; // || this.isLoadingSections;
        },

        isPermissionGranted() {
            return this.userHasScope;
        },

        // sectionToEdit() {
        //     return this.sections.find(section => {
        //         return section._id === this.sectionIdToEdit;
        //     });
        // },

        userCanManageFonts() {
            return this.$auth.userCan(this.$auth.Actions.CanManageFonts, { clientId: this.clientId }, false);
        },

        // userCanManageCampaignSections() {
        //     return this.$auth.userCan(this.$auth.Actions.CanManageCampaignSections, { clientId: this.clientId }, false);
        // },

        userHasScope() {
            return this.$auth.hasScope({ clientId: this.clientId }, false);
        }

        // visibleSections() {
        //     if (this.userCanManageCampaignSections) {
        //         return this.sections;
        //     }
        //     return this.sections.filter(section => {
        //         return this.fontsBySectionId[section._id] !== undefined;
        //     });
        // }
    },

    methods: {
        reloadFonts() {
            this.$apollo.queries.fonts.refetch();
            // this.$apollo.queries.campaignSections.refetch();
        },
        // showEditCampaignSectionModal(sectionId) {
        //     this.sectionIdToEdit = sectionId;
        //     this.toggleEditCampaignSectionModalIsVisible();
        // },
        // showSetCampaignSectionModal(campaignId) {
        //     this.campaignIdToEdit = campaignId;
        //     this.toggleSetCampaignSectionModalIsVisible();
        // },
        // toggleAddSectionModalIsVisible() {
        //     this.addSectionModalIsVisible = !this.addSectionModalIsVisible;
        // },
        toggleAddFontModalIsVisible() {
            this.addFontModalIsVisible = !this.addFontModalIsVisible;
        }
        // toggleEditCampaignSectionModalIsVisible() {
        //     this.editCampaignSectionModalIsVisible = !this.editCampaignSectionModalIsVisible;
        // }
        // toggleSetCampaignSectionModalIsVisible() {
        //     this.setCampaignSectionModalIsVisible = !this.setCampaignSectionModalIsVisible;
        // }
    },

    apollo: {
        client: {
            query: clientUploadConfigQuery,
            variables() {
                return {
                    clientId: this.clientId
                };
            },
            skip() {
                return !this.isPermissionGranted;
            }
        },

        fonts: {
            query: fontsQuery,
            fetchPolicy: "network-only",
            loadingKey: "isLoadingFonts",
            variables() {
                return {
                    clientId: this.clientId,
                    orderBy: this.sortFontsBy
                };
            },

            skip() {
                return !this.isPermissionGranted;
            },
            result() {
                this.hasLoadedFonts = true;
            }
        }

        // campaignSections: {
        //     query: campaignSectionsQuery,
        //     fetchPolicy: "network-only",
        //     loadingKey: "isLoadingSections",

        //     variables() {
        //         return {
        //             clientId: this.clientId,
        //             orderBy: this.sortFontsBy
        //         };
        //     },

        //     skip() {
        //         return !this.isPermissionGranted;
        //     },

        //     result({ data, error }) {
        //         if (error) {
        //             this.hasErrorLoadingSections = true;
        //             return;
        //         }
        //         this.hasLoadedSections = true;
        //         this.hasErrorLoadingSections = false;
        //         this.sections = data.campaignSections;
        //     }
        // }
    }
};
</script>

<style lang="scss">
@import "../../sass/_variables.scss";

.fonts {
    margin-top: 70px;
    &__action {
        &-items {
            display: flex;
            justify-content: flex-end;
            padding: 15px 0;
        }

        &-item {
            flex: 0 0;
            display: flex;
            align-items: center;
            margin-left: 20px;
        }
    }

    &__list--no-action-items {
        padding: 15px 0 0 0;
    }

    &__section-divider {
        border: none;
        border-top: $border-width-base solid $cmp-light-border-color;
        flex: 1;
    }

    &__section-empty-message {
        color: $cmp-light-font-color;
        font-size: $font-size-base;
        margin: (0 - $spacing-small) 0 $spacing;

        p {
            margin: 0 0 $spacing-small;

            &:first-child {
                font-weight: bold;
            }
        }
    }

    &__section-header {
        align-items: center;
        display: flex;
        margin: 0 0 $spacing;
        padding: $spacing 0 0;
    }

    &__section-title {
        font-size: $font-size-large;
        font-weight: bold;
        padding: 0 $spacing-small 0 0;
    }

    .fonts__action-items {
        align-items: center;
        .data-filter__select {
            line-height: 30px;
        }
        button {
            &.fonts__action-item {
                padding: 6px 20px;
                height: 30px;
                line-height: 32px;
            }
        }
    }
}

.fonts-dashboard__empty-message-wrapper {
    padding: $spacing-large 0 0 0;

    button {
        margin: $spacing-small 0;
    }
}

.fonts-dashboard__loading-wrapper {
    height: 120px;
    position: relative;
}
</style>
