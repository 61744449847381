export const SuperAdminRole = "Super Admin";
export const CollaboratorRole = "Collaborator";

export const UserActions = {
    CanRemoveUser: "canRemoveUser",
    CanManageUsers: "canManageUsers",
    CanListRoles: "canListRoles",
    CanManageRoles: "canManageRoles",
    CanListAllClients: "canListAllClients",
    CanAddClient: "canAddClient",
    CanEditClient: "canEditClient",
    CanRemoveClient: "canRemoveClient",
    CanListMediaItems: "canListMediaItems",
    CanManageMediaItems: "canManageMediaItems",
    CanListMasterTemplates: "canListMasterTemplates",
    CanManageMasterTemplates: "canManageMasterTemplates",
    CanAddCampaign: "canAddCampaign",
    CanEditCampaign: "canEditCampaign",
    CanRemoveCampaign: "canRemoveCampaign",
    CanListGroupValue: "canListGroupValue",
    CanManageGroupValue: "canManageGroupValue",
    CanListOverwrites: "canListOverwrites",
    CanManageOverwrites: "canManageOverwrites",
    CanManageRestrictedEditables: "canManageRestrictedEditables",
    CanPreviewDeliverable: "canPreviewDeliverable",
    CanShareDeliverable: "canShareDeliverable",
    CanPublishDeliverableToAdServer: "canPublishDeliverableToAdServer",
    CanDownloadDeliverable: "canDownloadDeliverable",
    CanChangeDeliverableVisibility: "canChangeDeliverableVisibility",
    CanChangeDeliverableReportingLabel: "canChangeDeliverableReportingLabel",
    CanChangeDeliverableQAStatus: "canChangeDeliverableQAStatus",
    CanChangeDeliverablePublishedStatus: "canChangeDeliverablePublishedStatus",
    CanListInternalAnnotations: "canListInternalAnnotations",
    CanManageInternalAnnotations: "canManageInternalAnnotations",
    CanListInternalComments: "canListInternalComments",
    CanManageInternalComments: "canManageInternalComments",
    CanListExternalAnnotations: "canListExternalAnnotations",
    CanManageExternalAnnotations: "canManageExternalAnnotations",
    CanListExternalComments: "canListExternalComments",
    CanManageExternalComments: "canManageExternalComments",
    CanManageMetadata: "canManageMetadata",
    CanManageOtherUsersAnnotations: "canManageOtherUsersAnnotations",
    CanManageOtherUsersComments: "canManageOtherUsersComments",
    CanManageJobs: "canManageJobs",
    CanListJobs: "canListJobs",
    CanReceiveAllNotifications: "canReceiveAllNotifications",
    CanDownloadVideo: "canDownloadVideo",
    CanRegisterUserEvents: "canRegisterUserEvents",
    CanRetrieveReports: "canRetrieveReports",
    CanManageCampaignSections: "canManageCampaignSections",
    CanListFonts: "canListFonts",
    CanManageFonts: "canManageFonts",
    CanEnableImageProcessing: "canEnableImageProcessing",
    CanUseSandbox: "canUseSandbox",
    CanUseCreativeIntelligence: "canUseCreativeIntelligence",
    CanEnableConnectModule: "canEnableConnectModule"
};

export const UserActionsToAuthorizeByScope = [
    UserActions.CanEditClient,
    UserActions.CanRemoveClient,
    UserActions.CanAddCampaign,
    UserActions.CanEditCampaign,
    UserActions.CanRemoveCampaign,
    UserActions.CanListMasterTemplates,
    UserActions.CanManageMasterTemplates,
    UserActions.CanListGroupValue,
    UserActions.CanManageGroupValue,
    UserActions.CanListOverwrites,
    UserActions.CanManageOverwrites,
    UserActions.CanManageRestrictedEditables,
    UserActions.CanPreviewDeliverable,
    UserActions.CanShareDeliverable,
    UserActions.CanPublishDeliverableToAdServer,
    UserActions.CanDownloadDeliverable,
    UserActions.CanDownloadVideo,
    UserActions.CanChangeDeliverableVisibility,
    UserActions.CanChangeDeliverableReportingLabel,
    UserActions.CanChangeDeliverableQAStatus,
    UserActions.CanManageInternalAnnotations,
    UserActions.CanManageExternalAnnotations,
    UserActions.CanManageInternalComments,
    UserActions.CanManageExternalComments,
    UserActions.CanManageMetadata,
    UserActions.CanManageCampaignSections,
    UserActions.CanListFonts,
    UserActions.CanManageFonts,
    UserActions.CanRetrieveReports
];

export default {
    SuperAdminRole,
    CollaboratorRole,
    UserActions,
    UserActionsToAuthorizeByScope
};
