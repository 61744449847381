export const QueryParamPrefix = {
    NoValues: "n",
    InactiveValues: "i"
};

export function generateFilterForCollection(key, activeValues, allValues) {
    if (activeValues.length === 0) {
        return {
            key: `${QueryParamPrefix.NoValues}-${key}`,
            value: [1]
        };
    }
    if (activeValues.length === allValues.length) {
        return {
            key,
            value: []
        };
    }
    if (activeValues.length > allValues.length / 2) {
        return {
            key: `${QueryParamPrefix.InactiveValues}-${key}`,
            value: allValues.filter(value => !activeValues.includes(value))
        };
    }
    return {
        key,
        value: [...activeValues]
    };
}

// eslint-disable-next-line complexity
export function getActiveValuesOfCollectionFromQueryParams(key, allValues, queryParams) {
    /*
        If we have the key with the NoValues prefix in the query params
        then we return no values as being active.
    */
    if (queryParams[`${QueryParamPrefix.NoValues}-${key}`]) {
        return [];
    }
    /*
        If we have the key with the InactiveValues prefix in the query params
        then we only want values from allValues that are not specified in the
        query param values.
    */
    if (queryParams[`${QueryParamPrefix.InactiveValues}-${key}`]) {
        const queryParamValue = queryParams[`${QueryParamPrefix.InactiveValues}-${key}`];
        const queryParamValueArray = Array.isArray(queryParamValue) ? queryParamValue : [queryParamValue];
        return allValues.filter(value => !queryParamValueArray.includes(value));
    }
    /*
        If we have no query param value for the key, then we interpret that as having
        all values in the collection as being active.
    */
    if (queryParams[key] === undefined) {
        return allValues;
    }
    const queryParamValueArray = Array.isArray(queryParams[key]) ? queryParams[key] : [queryParams[key]];
    /*
        We filter the values to ensure that values that no longer exist in the
        collection make it through (which could cause issues).
    */
    return queryParamValueArray.filter(value => allValues.includes(value));
}

export function cleanAWSTokens(value) {
    return value.replace(/(\?|&)(Expires|Signature|x-amz-security-token|AWSAccessKeyId)(=[^&"]*)/g, "");
}
