<template>
    <Form
        ref="signalForm"
        class="account-signal__form"
        :model="localData"
        :rules="baseValidationRules"
        @submit.native.prevent="onSubmitAccountSignal"
    >
        <span class="account-signal__info account-signal__info--first">which triggers when</span>
        <Select v-model="localData.signalType" class="account-signal__type">
            <Option v-for="signalType in weatherSignalTypes" :key="signalType.id" :value="signalType.id">
                {{ signalType.name }}
            </Option>
        </Select>
        <span class="account-signal__info">is</span>
        <FormItem
            v-if="localData.signalType === SignalTypesList.Temperature.id"
            ref="signalOperator"
            prop="signalOperator"
        >
            <Select v-model="localData.signalOperator" label="Select" class="account-signal__operator">
                <Option v-for="operator in TemperatureOperatorTypesList" :key="operator.id" :value="operator.sign">
                    {{ operator.name }}
                </Option>
            </Select>
        </FormItem>
        <FormItem
            v-if="localData.signalType === SignalTypesList.Temperature.id"
            ref="signalValue"
            prop="signalValue"
        >
            <Input
                v-model="localData.signalValue"
                class="account-signal__text-input"
                placeholder="Enter amount °c"
            ></Input>
        </FormItem>
        <FormItem
            v-if="localData.signalType !== SignalTypesList.Temperature.id"
            ref="signalValue"
            prop="signalValue"
        >
            <Select v-model="localData.signalValue" class="signal-modal__type">
                <Option v-for="prec in PrecipitationAmountTypesList" :key="prec.id" :value="prec.id">
                    {{ prec.name }}
                </Option>
            </Select>
        </FormItem>
        <span class="account-signal__info">in the location</span>
        <div class="account-signal__location">
            <Input
                v-model="localData.location"
                type="text"
                auto-complete="off"
                data-testid="signal-modal__location"
                class="account-signal__text-input"
                placeholder="Enter your location"
                @input="handleInput"
            ></Input>
            <ul v-if="suggestions.length" class="account-signal__location-list">
                <li
                    v-for="suggestion in suggestions"
                    :key="suggestion.id"
                    class="account-signal__location-item"
                    @click="selectSuggestion(suggestion)"
                >
                    {{ suggestion.name }},{{ suggestion.country }}
                </li>
            </ul>
        </div>
    </Form>
</template>
<script>
import { fetchRequest } from "@/components/Reporting/data/utils";
import { SignalTypesList, PrecipitationAmountTypesList, TemperatureOperatorTypesList } from "@/enums/activate";
import { parseTemperatureValueString } from "@/utils/activate";
import getIntelligentDeliveryApiInfoQuery from "@/apollo/queries/v2/GetIntelligentDeliveryApiInfo.gql";

export default {
    name: "WeatherSignalForm",
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        signalAction: {
            type: String,
            default: "add"
        },
        accountIndex: {
            type: Number,
            default: 0
        },
        ruleIndex: {
            type: Number,
            default: 0
        },
        signalIndex: {
            type: Number,
            default: 0
        },
        formData: {
            type: Object
        }
    },
    data() {
        return {
            localData: {
                signalType: null,
                signalOperator: null,
                signalValue: null,
                location: "",
                locationName: ""
            },
            suggestions: [],
            SignalTypesList,
            PrecipitationAmountTypesList,
            TemperatureOperatorTypesList,
            updatedSignalType: "",
            baseValidationRules: {
                signalOperator: [
                    {
                        required: true,
                        message: " ",
                        trigger: "blur"
                    }
                ],
                signalValue: [
                    {
                        required: true,
                        message: " ",
                        trigger: "blur"
                    }
                ]
            }
        };
    },
    computed: {
        weatherSignalTypes() {
            const filteredData = {};
            Object.keys(this.SignalTypesList).forEach(key => {
                if (this.SignalTypesList[key].id.includes("OPEN_WEATHER")) {
                    filteredData[key] = this.SignalTypesList[key];
                }
            });
            return filteredData;
        }
    },
    watch: {
        formData: {
            deep: true,
            handler(newVal) {
                if (!newVal || !newVal.value) {
                    return;
                }
                this.localData.signalType = newVal.signalType;
                if (newVal.signalType === SignalTypesList.Temperature.id) {
                    const signalValue = parseTemperatureValueString(newVal.value);
                    this.localData.signalOperator = signalValue.operator;
                    this.localData.signalValue = signalValue.value;
                } else {
                    this.localData.signalValue = newVal.value;
                }
                this.localData.signalType = newVal.signalType;

                if (newVal.locations && newVal.locations.length) {
                    this.localData.location = newVal.locations[0].locationRef.split(":q:").slice(-1)[0];
                    this.localData.locationName = newVal.locations[0].displayName;
                }
            },
            immediate: true
        },
        localData: {
            deep: true,
            handler() {
                if (this.localData.signalValue) {
                    this.localData.signalValue = `${this.localData.signalValue}`;
                }
                this.onSubmitAccountSignal();
            }
        },
        "localData.signalType": {
            handler(newVal, oldVal) {
                if (!oldVal || oldVal !== newVal) {
                    this.$nextTick(() => {
                        this.onSubmitAccountSignal();
                    });

                    this.localData.signalValue = null;
                    this.localData.signalOperator = null;
                }
            },
            deep: true,
            immediate: false
        }
    },
    methods: {
        close() {
            this.$emit("close");
        },

        onSubmitAccountSignal() {
            this.$refs.signalForm.validate(valid => {
                if (!valid) {
                    this.$emit("disableSaveButton");
                    return;
                }
                if (this.localData.signalType === SignalTypesList.Temperature.id) {
                    this.localData.value = `${this.localData.signalOperator ? this.localData.signalOperator : ""}${
                        this.localData.signalValue
                    }`;
                    // X represents a placeholder for the comparison operator
                    this.localData.value = `X${this.localData.value}`;
                } else {
                    this.localData.value = `${this.localData.signalValue}`;
                }
                this.localData.locations = [];
                if (this.localData.location) {
                    this.localData.locations = [
                        {
                            displayName: this.localData.locationName,
                            locationRef: `openweather:2.5/weather:q:${this.localData.location}`
                        }
                    ];
                }
                this.$emit("update", {
                    formData: this.localData,
                    accountIndex: this.accountIndex,
                    ruleIndex: this.ruleIndex,
                    signalIndex: this.signalIndex,
                    signalAction: this.signalAction
                });
            });
        },

        async getSuggestions() {
            try {
                const singEndpoint = `${this.apiInfo.url}/v1/openweather/search-locations?key=${this.localData.location}/`;
                const response = await fetchRequest({
                    endpoint: singEndpoint,
                    method: "GET",
                    apiKey: this.apiInfo.apiKey
                });
                if (response.ok) {
                    const responseValidateJson = await response.json();
                    if (responseValidateJson.data.length) {
                        this.suggestions = responseValidateJson.data;
                    }
                } else {
                    this.handleErrorResponse(response);
                }
            } catch (e) {
                console.log(e);
            }
        },
        async handleInput() {
            if (this.localData.location.length >= 4) {
                await this.getSuggestions();
            } else {
                this.suggestions = [];
            }
        },

        selectSuggestion(suggestion) {
            this.localData.location = `${suggestion.name},${suggestion.country}`;
            this.localData.locationName = suggestion.name;
            this.suggestions = [];
        }
    },

    apollo: {
        intelligentDeliveryApiInfo: {
            query: getIntelligentDeliveryApiInfoQuery,
            result({ data }) {
                this.apiInfo = data.intelligentDeliveryApiInfo;
            }
        }
    }
};
</script>
