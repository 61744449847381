<template>
    <div class="labelled-value">
        <deliverable-label v-if="label" :color="labelColor" :label="label" />
        <div class="labelled-value__text" @click="onClick('')">
            <span
                class="labelled-value__text--value"
                :class="{ 'labelled-value__text--value-whitespace': isValueWhitespace }"
            >
                {{ value }}
            </span>
            <div class="labelled-value__icons-container">
                <slot name="icons" />
            </div>
        </div>
    </div>
</template>
<script>
import DeliverableLabel from "@/components/Campaign/DeliverableLabel";

export default {
    name: "LabelledValue",
    components: { DeliverableLabel },
    props: {
        label: {
            type: String
        },
        labelColor: {
            type: String,
            default: "default"
        },
        value: {
            type: String,
            required: true
        }
    },

    methods: {
        isValueWhitespace() {
            return /^\s$/.match(this.value);
        },

        onClick(target) {
            this.$emit(target ? `${target}-click` : "click");
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.labelled-value {
    display: flex;
    align-items: center;
    min-width: 0;
    padding: 0 0 0 10px;
    width: 100%;

    &__icons-container {
        display: none;
    }

    &__text {
        flex: 1 0;
        display: flex;
        overflow: hidden;
        padding: 0 5px;
        align-items: center;
        cursor: pointer;

        &--value {
            flex: 1 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &-whitespace:before,
            &:empty:before {
                content: "\200b";
            }
        }

        &:hover {
            .labelled-value__icons-container {
                color: $cmp-dark-font-color;
                display: block;
            }
        }
    }
}
</style>
