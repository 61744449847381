import { EditableTypes } from "../../gql/Editable/constants";

export default type =>
    [
        EditableTypes.image,
        EditableTypes.subtitles,
        EditableTypes.video,
        EditableTypes.folder,
        EditableTypes.backgroundImage
    ].includes(type);
