<template>
    <div class="campaign-edit-language-modal">
        <Modal
            cancel-text="Cancel"
            class-name="add-templates-modal language-modal-wrap"
            ok-text="Update languages"
            data-testid="languages-modal"
            :value="visible"
            @on-ok="onOk"
            @input="onVisibleChange"
        >
            <div class="language-modal">
                <div class="language-modal__tags">
                    <Tag>{{ campaignDefaultLanguage.label }}</Tag>
                    <tag-list
                        class="language-modal__tag-list"
                        :tags="campaignLanguagesForTags"
                        @tagClose="onRemoveLanguage"
                    ></tag-list>
                </div>
                <Input
                    prefix="ios-search"
                    placeholder="Start typing to search your language"
                    data-testid="languages-modal__search-input"
                    :clearable="true"
                    :value="remoteQuery"
                    @input="remoteFilter"
                ></Input>

                <div class="language-modal__body">
                    <div class="language-modal__body-content">
                        <table-checkbox-group
                            v-model="campaignLanguagesForTable"
                            class="language-modal__table-checkbox"
                            data-testid="languages-modal__checkobox-list"
                            :columns="tableColumns"
                            :data="tableData"
                            :disable-row-click="true"
                        ></table-checkbox-group>
                    </div>
                </div>

                <Alert show-icon class="hox-alert language-modal__aid">
                    <template slot="icon">
                        <icon type="md-information-circle" size="40" />
                    </template>
                    Your selected languages will be added to this campaign. Once saved, they will show in the "language"
                    dropdown of your Assemble.
                </Alert>
            </div>

            <template slot="header">
                <h3>Language</h3>
            </template>
        </Modal>
    </div>
</template>
<script>
import modalComponentMixin from "@/mixins/modalComponentMixin";
import TableCheckboxGroup from "@/components/TableCheckboxGroup";
import TagList from "@/components/Campaign/TagList";
import { languagesList } from "@/assets/languages";

import { campaignDefaultLanguage } from "@/store/modules/campaign";

export default {
    name: "CampaignLanguagesModal",
    components: {
        TagList,
        TableCheckboxGroup
    },
    mixins: [modalComponentMixin],

    data() {
        return {
            addedLanguages: [],
            existingLanguages: [],
            remoteQuery: "",
            tableColumns: [
                {
                    title: "Languages",
                    key: "label"
                },
                {
                    title: "Code",
                    key: "value",
                    width: 85
                }
            ]
        };
    },

    computed: {
        campaignDefaultLanguage() {
            return languagesList.find(opt => opt.value === campaignDefaultLanguage);
        },

        campaignLanguagesForTags() {
            return this.selectedLanguages
                .filter(lang => lang !== campaignDefaultLanguage)
                .map(lang => languagesList.find(opt => opt.value === lang).label);
        },

        campaignLanguagesForTable: {
            get() {
                return this.addedLanguages.map(lang => languagesList.filter(language => language.value === lang).pop());
            },

            set(opts) {
                opts.forEach(opt => this.addedLanguages.push(opt.value));
                this.remoteQuery = "";
            }
        },

        selectedLanguages() {
            return [].concat(this.existingLanguages, this.addedLanguages);
        },

        tableData() {
            return this.options
                .filter(option => option.label.toLowerCase().indexOf(this.remoteQuery) > -1)
                .filter(option => !this.selectedLanguages.includes(option.value));
        }
    },

    created() {
        this.options = languagesList;
        this.existingLanguages = this.$store.state.campaign.languages;
    },

    methods: {
        onOk() {
            this.$emit("update", this.selectedLanguages);
        },

        onRemoveLanguage(langLabel) {
            const toRemove = languagesList.find(opt => opt.label === langLabel);

            this.addedLanguages = this.addedLanguages.filter(lang => lang !== toRemove.value);

            if (this.existingLanguages.includes(toRemove.value)) {
                this.$Modal.confirm({
                    title: `Remove language: ${toRemove.label}`,
                    content:
                        "Removing this language from this campaign will delete all translations.<br><br> This is permanent and can not be undone.",
                    onOk: () => {
                        this.existingLanguages = this.existingLanguages.filter(lang => lang !== toRemove.value);
                    }
                });
            }
        },

        remoteFilter(query) {
            this.remoteQuery = query.toLowerCase();
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.language-modal {
    &__tagged-input {
        .ivu-select-dropdown {
            display: none;
        }
    }

    &__tags {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 7px;
    }

    &__table-checkbox {
        .ivu-table-header .ivu-table-cell-with-selection {
            display: none;
        }
    }

    &__body {
        margin-top: 20px;
        max-height: 300px;
        overflow-x: auto;
    }

    &__aid {
        margin-top: 7px;
        background-color: $grey1;
        border: none;

        &.ivu-alert.ivu-alert-with-icon {
            padding: 19px 12px 22px 82px;
        }

        .ivu-alert-icon {
            padding: 15px 23px 0 11px;
        }
    }
}
</style>
