<template>
    <div class="image-upload">
        <label v-if="showLabel" class="image-upload__label">
            <slot name="label" />
        </label>
        <img v-if="image" class="image-upload__image" :src="image" />
        <div class="image-upload__upload-wrapper">
            <hox-loading-layer v-if="isUploading" :is-full-screen="false" size="small" />
            <Upload
                v-if="Object.keys(uploadConfig).length"
                :show-upload-list="false"
                :action="uploadConfig.action"
                :data="uploadConfig.data"
                :multiple="false"
                :before-upload="beforeUpload"
                :on-success="onSuccess"
                :on-error="onError"
                type="drag"
            >
                <div class="image-upload__uploader">
                    <Icon size="38" type="ios-cloud-upload-outline" />
                    <p>Drag and drop (or click) to upload an image</p>
                </div>
            </Upload>
        </div>
        <p v-if="hasErrorUploading" class="image-upload__error-message">
            There was an error while uploading the image. Please try again in a few moments.
        </p>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: String },
        config: { type: Object }
    },
    data() {
        return {
            image: "",
            hasErrorUploading: false,
            isUploading: false,
            file: null,
            uploadConfig: {}
        };
    },
    computed: {
        showLabel() {
            return this.$slots.label !== undefined;
        }
    },
    watch: {
        value(v) {
            this.image = v;
        },
        config(c) {
            this.processConfig(c);
        }
    },
    mounted() {
        this.image = this.value;
        this.processConfig(this.config);
    },
    methods: {
        processConfig(config) {
            this.uploadConfig = { ...config };
            this.uploadConfig.data = JSON.parse(this.uploadConfig.postData);
            this.uploadConfig.data.key = this.uploadConfig.filePrefix;
        },
        beforeUpload(file) {
            this.file = null;
            this.uploadConfig.data.key += `/${file.name}`;

            const isAcceptedFileType = ["image/jpeg", "image/png"].includes(file.type);
            if (!isAcceptedFileType) {
                this.$snackbar.image({ message: "The image must be either a PNG or JPEG" });
                return false;
            }

            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$snackbar.image({ message: "Image size can not exceed 2MB" });
                return false;
            }

            this.file = URL.createObjectURL(file);
            this.isUploading = true;

            return true;
        },
        onSuccess() {
            this.isUploading = false;
            this.image = this.file;
            this.$emit("imageUploaded", this.uploadConfig.data.key);
        },
        onError() {
            this.isUploading = false;
            this.hasErrorUploading = true;
            this.$emit("imageUploadFailed");
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.image-upload {
    margin: 0 0 $spacing;

    .ivu-upload-drag {
        color: $link-color;
        transition: background, border-color, color;
        transition-timing-function: $transition-function-base;
        transition-duration: $transition-time-base;

        &:hover {
            color: $link-hover-color;
            border-color: $link-hover-color;
        }
    }

    .ivu-upload-dragOver {
        background: rgba($link-color, 0.15);
        border: 1px dashed $link-hover-color;
        color: $link-hover-color;
    }

    &__error-message {
        color: $error-color;
        font-size: $font-size-small;
        margin: 0 0 $spacing;
    }

    &__image {
        max-width: 100%;
        max-height: 150px;
        display: block;
        border: 1px solid $cmp-light-border-color;
        padding: $spacing-smallest;
        margin: 0 0 $spacing-small;
    }

    &__label {
        color: $cmp-light-secondary-font-color;
        display: inline-block;
        font-size: $font-size-base;
        margin: 0 0 $spacing-small;
    }

    &__upload-wrapper {
        position: relative;
        margin: 0 0 $spacing-small;
    }

    &__uploader {
        font-size: $font-size-base;
        width: 100%;
        text-align: center;
        cursor: pointer;
        padding: $spacing-large $spacing;

        .ivu-icon {
            margin: 0 0 $spacing-small;
        }
    }
}
</style>
