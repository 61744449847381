<template>
    <div class="library-banner__scaling-controls" :class="{ 'library-banner__scaling-controls--dark': isThemeDark }">
        <p>
            Resize mode
            <span v-if="resizeDimensions">{{ resizeDimensions }}</span>
        </p>
        <Tooltip placement="left" :transfer="true">
            <hox-square-button @click="disableResize">
                <Icon size="24" :type="scalingButtonIcon" />
            </hox-square-button>
            <template #content>Exit resize mode</template>
        </Tooltip>
    </div>
</template>
<script>
export default {
    name: "ResizeHeader",

    props: {
        resizeDimensions: {
            type: String,
            default: ""
        },
        internalScalingFactor: {
            type: [Number, String]
        },
        isResizable: {
            type: Boolean,
            default: false
        },
        scalingIsEnabled: {
            type: Boolean
        },
        theme: {
            type: String,
            default: "light"
        }
    },

    computed: {
        isThemeDark() {
            return this.theme === "dark";
        },
        scalingButtonIcon() {
            return "ios-expand";
        },
        userVisibleScalingPercentage() {
            return Math.round(this.internalScalingFactor * 100);
        }
    },

    methods: {
        disableResize() {
            this.$emit("disableResize");
        }
    }
};
</script>
