<template>
    <div :class="classes">
        <manage-tag-modal
            v-if="isModalVisible"
            :is-modal-visible.sync="isModalVisible"
            :query="fullQuery"
            :tag="tagForModal"
        />
        <Icon type="ios-search" class="advanced-search__icon" />
        <span class="advanced-search__type">
            <Dropdown>
                <a href="javascript:void(0)">
                    <span class="advanced-search__type-value link-like">{{ searchMode }}</span>
                    <Icon type="ios-arrow-down"></Icon>
                </a>
                <DropdownMenu slot="list">
                    <DropdownItem @click.native="setMode(SearchType.Everything)">
                        {{ SearchType.Everything }}
                    </DropdownItem>
                    <DropdownItem @click.native="setMode(SearchType.Text)">
                        {{ SearchType.Text }}
                    </DropdownItem>
                    <DropdownItem @click.native="setMode(SearchType.Objects)">
                        {{ SearchType.Objects }}
                    </DropdownItem>
                    <!--                    <DropdownItem @click.native="setMode(SearchType.Labels)">
                        {{ SearchType.Labels }}
                    </DropdownItem>-->
                    <DropdownItem @click.native="setMode(SearchType.Colors)">
                        {{ SearchType.Colors }}
                    </DropdownItem>
                    <DropdownItem @click.native="setMode(SearchType.Advanced)">
                        {{ SearchType.Advanced }}
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </span>

        <input
            ref="searchInput"
            class="reporting-dashboard-creative-explorer__query"
            :value="query"
            :placeholder="placeholder"
            @focus="focusChangedin"
            @blur="focusChangedout"
            @keydown.enter="search"
            @input="updateValue"
            @dblclick="onDblClick"
        />

        <Button v-show="isInputActive" class="advanced-search__button" size="small" @click="search">Search</Button>
        <Button v-show="!isInputActive && !tagMode" class="advanced-search__button" size="small" @click="showModal">
            Save
        </Button>
        <Button v-show="!isInputActive && tagMode" class="advanced-search__button" size="small" @click="showModal">
            Edit
        </Button>
    </div>
</template>
<script>
import ManageTagModal from "@/components/CreativeIntelligence/ManageTagModal";
import { CreativeInsightsAction } from "@/store/modules/creativeInsights";
import { TagsAction } from "@/store/modules/ciTags";

const SearchType = {
    Advanced: "Advanced",
    Colors: "Colors",
    Everything: "Everything",
    Labels: "Labels",
    Objects: "Objects",
    Text: "Text"
};

export default {
    name: "AdvancedSearchInput",
    components: { ManageTagModal },
    data() {
        return {
            query: "",
            isModalVisible: false,
            hasError: false,
            validationErrors: [],
            searchMode: SearchType.Everything,
            isInputActive: false,
            activeElement: null
        };
    },

    computed: {
        clientId() {
            return this.$store.state.route.params.clientId;
        },

        classes() {
            const classes = ["advanced-search"];

            if (this.tagMode) {
                classes.push("advanced-search--tag-mode");
            }

            return classes.join(" ");
        },

        isSaving() {
            return this.$store.state.ciTags.isCreating;
        },

        placeholder() {
            if (this.isInputActive) {
                return "";
            }

            return this.tagMode && this.selectedTag.name ? this.selectedTag.name : "Search";
        },

        queryPrefix() {
            switch (this.searchMode) {
                case SearchType.Advanced: {
                    return "";
                }

                case SearchType.Colors: {
                    return "color=";
                }

                case SearchType.Everything: {
                    return "all=";
                }

                case SearchType.Labels: {
                    return "all=";
                }

                case SearchType.Objects: {
                    return "object=";
                }

                case SearchType.Text: {
                    return "text=";
                }

                default: {
                    return "all=";
                }
            }
        },

        fullQuery() {
            return this.query ? `${this.queryPrefix}${this.query}` : "";
        },

        selectedTag() {
            if (!this.selectedTagIds.length) {
                return undefined;
            }

            return this.tagsById[this.selectedTagIds[0]];
        },

        tagForModal() {
            if (!this.tagMode) {
                return undefined;
            }

            return this.tagsById[this.selectedTagIds[0]];
        },

        selectedTagIds() {
            return this.$store.state.ciTags.selectedTagIds;
        },

        tagMode() {
            return this.selectedTagIds.length && this.query === "";
        },

        tagsById() {
            return this.$store.state.ciTags.tagsById;
        }
    },

    watch: {
        selectedTagIds(v) {
            if (v.length) {
                this.query = "";
            }
        },
        searchMode() {
            if (this.query) {
                this.$store.dispatch(CreativeInsightsAction.SetAdvancedSearch, this.fullQuery);
            }
        }
    },

    created() {
        this.SearchType = SearchType;
    },

    methods: {
        focusChangedin(event) {
            if (event.target === this.$refs.searchInput) {
                this.isInputActive = true;
            }
        },

        async focusChangedout(event) {
            if (event.target === this.$refs.searchInput) {
                // we need some time to handle button click that is otherwise affected by this variable
                setTimeout(() => {
                    this.isInputActive = false;
                }, 100);
            }
        },

        onDblClick() {
            if (this.tagForModal) {
                this.showModal();
            }
        },

        resetFocus() {
            document.body.focus();
        },

        search() {
            this.$store.dispatch(CreativeInsightsAction.SetAdvancedSearch, this.fullQuery);
            if (this.selectedTagIds.length) {
                this.selectedTagIds.forEach(tagId => this.$store.dispatch(TagsAction.ToggleTagSelection, tagId));
            }
            this.resetFocus();
            document.activeElement.blur();
        },

        setMode(mode) {
            this.searchMode = mode;
        },

        showModal() {
            this.isModalVisible = true;
        },

        updateValue(event) {
            this.query = event.target.value;
            this.$emit("input", this.query);
        }
    }
};
</script>
<style lang="scss">
@import "../../../sass/variables";

.advanced-search {
    border-bottom: 1px solid #d1d1d1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    &--tag-mode {
        ::placeholder {
            font-size: 24px;
            color: #000000;
        }
    }

    &__icon {
        margin-top: -5px;
        color: $ci-blue;
    }

    &__type {
        font-size: 14px;
        padding: 0 $spacing-small;
        flex: 0 0;
        margin-top: 1px;
        white-space: nowrap;
    }

    &__button {
        border: 0 none;
        flex: 0 0;
        margin-top: 1px;
    }

    input {
        font-size: 24px;
        line-height: 22px;
        margin-top: -3px;
        border: 0 none;
        background-color: transparent;
        flex: 1 1;
        color: #666666;

        &:focus-visible {
            outline-color: transparent;
        }
    }
}
</style>
