<template>
    <div
        class="qa-drawer-header__action-items-container"
        :class="{ 'qa-drawer-header__action-items-container--bulk': isBulk }"
    >
        <div class="qa-drawer-header__action-buttons">
            <div class="qa-drawer-header__action-item">
                <Button
                    :disabled="buttonsDisabled"
                    ghost
                    :loading="isApprovingAllDeliverables"
                    type="success"
                    data-testid="qa-drawer__button--approve"
                    @click="approveAllDeliverables"
                >
                    Approve All
                </Button>
            </div>
            <div class="qa-drawer-header__action-item">
                <Button
                    ghost
                    :disabled="buttonsDisabled"
                    :loading="isRejectingAllDeliverables"
                    type="error"
                    data-testid="qa-drawer__button--reject"
                    @click="rejectAllDeliverables"
                >
                    Reject All
                </Button>
            </div>
            <div
                v-if="$auth.userCan($auth.Actions.CanChangeDeliverableQAStatus, { clientId, campaignId })"
                class="qa-drawer-header__action-item"
            >
                <set-deliverables-status-dropdown
                    :deliverables="visibleDeliverables"
                    :is-disabled="buttonsDisabled"
                    data-testid="qa-drawer__select--production-status"
                    @statusHasBeenSet="$emit('statusHasBeenUpdated')"
                >
                    Production status
                </set-deliverables-status-dropdown>
            </div>
            <div
                v-if="$auth.userCan($auth.Actions.CanChangeDeliverableVisibility, { clientId, campaignId })"
                class="qa-drawer-header__action-item"
            >
                <set-deliverables-visible-to-dropdown
                    :deliverables="visibleDeliverables"
                    :is-disabled="buttonsDisabled"
                    data-testid="qa-drawer__select--visibility"
                >
                    Visibility
                </set-deliverables-visible-to-dropdown>
            </div>
            <div class="qa-drawer-header__action-item">
                <portal-target name="creativeSizeFilter" slim></portal-target>
            </div>
        </div>
        <div v-if="isBulk" class="qa-drawer-header__bulk-page">
            <Page :current="bulkCurrent" :page-size="1" :total="bulkTotal" @on-change="onPageChange"></Page>
        </div>
    </div>
</template>

<script>
import { BannerQaStatus } from "@/enums/banners";
import SetDeliverablesStatusDropdown from "@/components/Campaign/SetDeliverablesStatusDropdown";
import SetDeliverablesVisibleToDropdown from "@/components/Campaign/SetDeliverablesVisibleToDropdown";
import { setLoggedInUsersDeliverablesStatus } from "@/services/Qa";
import { DeliverableLibraryAction } from "@/store/modules/deliverableLibrary";

export default {
    components: {
        SetDeliverablesStatusDropdown,
        SetDeliverablesVisibleToDropdown
    },
    props: {
        bulkCurrent: {
            type: Number,
            default: 1
        },
        bulkTotal: {
            type: Number,
            default: 1
        },
        buttonsDisabled: {
            type: Boolean
        },
        deliverables: {
            type: Array,
            required: true
        },
        isBulk: {
            type: Boolean,
            default: false
        },
        visibleMasterTemplateIds: {
            type: Array
        }
    },
    data() {
        return {
            isApprovingAllDeliverables: false,
            isRejectingAllDeliverables: false
        };
    },
    computed: {
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        visibleDeliverables() {
            if (!this.visibleMasterTemplateIds.length) {
                return this.deliverables;
            }

            return this.deliverables.filter(({ masterTemplate }) =>
                this.visibleMasterTemplateIds.includes(masterTemplate._id)
            );
        },

        hasDeliverables() {
            return this.deliverables && this.deliverables.length !== 0;
        }
    },
    methods: {
        async approveAllDeliverables() {
            this.isApprovingAllDeliverables = true;
            const deliverablesCount = this.visibleDeliverables.length;
            try {
                await this.setDeliverablesUserStatus(this.visibleDeliverables, BannerQaStatus.Approved);
                if (deliverablesCount === 1) {
                    this.$snackbar.success("You have approved the deliverable.");
                } else {
                    this.$snackbar.success(`You have approved ${deliverablesCount} deliverables.`);
                }
                this.$emit("userQaStatusHasBeenUpdated");
            } catch (err) {
                this.$snackbar.error("Sorry, We could not approve your ads this time. Try again in a moment.");
            }
            this.isApprovingAllDeliverables = false;
        },
        onPageChange(page) {
            this.$emit("bulkIndexChange", page);
        },
        async setDeliverablesUserStatus(deliverables, status) {
            const updatedDeliverables = await setLoggedInUsersDeliverablesStatus(this.campaignId, deliverables, status);
            this.$store.dispatch(DeliverableLibraryAction.SetDeliverableStatusByIdHash, updatedDeliverables);
        },
        async rejectAllDeliverables() {
            this.isRejectingAllDeliverables = true;
            const deliverablesCount = this.visibleDeliverables.length;
            try {
                await this.setDeliverablesUserStatus(this.visibleDeliverables, BannerQaStatus.Rejected);
                if (deliverablesCount === 1) {
                    this.$snackbar.success("Deliverable rejected.");
                } else {
                    this.$snackbar.success(`${deliverablesCount} deliverables rejected.`);
                }

                this.$emit("userQaStatusHasBeenUpdated");
            } catch (err) {
                this.$snackbar.error("Sorry, We could not reject your ads this time. Try again in a moment.");
            }
            this.isRejectingAllDeliverables = false;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.qa-drawer-header__action-items-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 0 $spacing;

    .qa-drawer-header__action-buttons {
        margin-bottom: 0;
        align-self: flex-start;
    }

    &--bulk {
        .qa-drawer-header__action-buttons {
            margin-bottom: $spacing;
        }
    }

    .ivu-icon-ios-loading {
        position: absolute;
        right: calc(50% - 10px);
        top: calc(50% - 10px);
    }

    .ivu-btn > .ivu-icon + span {
        margin-left: 0;
    }
}

.qa-drawer-header__action-item {
    display: inline-block;
    margin: 12px $spacing-small 0 0;
}

.qa-drawer-header__action-item--extra-spacing {
    display: inline-block;
    margin: 0 $spacing-large 0 0;
}

@media all and (min-width: 1600px) {
    .qa-drawer-header__action-items-container {
        flex-direction: row;

        .qa-drawer-header__action-buttons {
            margin-bottom: 0;
        }

        .qa-drawer-header__bulk-page {
            margin-top: 12px;
            margin-left: auto;
            margin-right: 0;
        }
    }
}
</style>
