<template>
    <div class="qa-user-status-indicators">
        <Poptip v-if="hasUsersThatHaveApproved" placement="left" transfer trigger="hover">
            <div class="qa-user-status-indicators__user-dot-group">
                <div
                    v-for="n in numberOfApprovedDotsToDisplay"
                    :key="n"
                    class="qa-user-status-indicators__user-dot qa-user-status-indicators__user-dot--approved"
                >
                    <template v-if="n === numberOfApprovedDotsToDisplay">
                        {{ userIdsByQaStatus[BannerQaStatus.Approved].length }}
                    </template>
                </div>
            </div>
            <template #title>
                <span class="qa-user-status-indicators__user-list-title">
                    Approved by {{ userIdsByQaStatus[BannerQaStatus.Approved].length }}
                </span>
            </template>
            <template #content>
                <ul>
                    <li
                        v-for="userId in userIdsByQaStatus[BannerQaStatus.Approved]"
                        :key="userId"
                        class="qa-user-status-indicators__user-list-item"
                    >
                        <Icon class="qa-user-status-indicators__approved-icon" type="ios-checkmark" />
                        {{ userId }}
                    </li>
                </ul>
            </template>
        </Poptip>
        <Poptip v-if="hasUsersThatHaveRejected" placement="left" transfer trigger="hover">
            <div class="qa-user-status-indicators__user-dot-group">
                <div
                    v-for="n in numberOfRejectedDotsToDisplay"
                    :key="n"
                    class="qa-user-status-indicators__user-dot qa-user-status-indicators__user-dot--rejected"
                >
                    <template v-if="n === numberOfRejectedDotsToDisplay">
                        {{ userIdsByQaStatus[BannerQaStatus.Rejected].length }}
                    </template>
                </div>
            </div>
            <template #title>
                <span class="qa-user-status-indicators__user-list-title">
                    Rejected by {{ userIdsByQaStatus[BannerQaStatus.Rejected].length }}
                </span>
            </template>
            <template #content>
                <ul>
                    <li
                        v-for="userId in userIdsByQaStatus[BannerQaStatus.Rejected]"
                        :key="userId"
                        class="qa-user-status-indicators__user-list-item"
                    >
                        <Icon class="qa-user-status-indicators__rejected-icon" type="ios-close" />
                        {{ userId }}
                    </li>
                </ul>
            </template>
        </Poptip>
    </div>
</template>

<script>
import { BannerQaStatus } from "@/enums/banners";

/*
    Because we don't have an infinite horizontal space to display the user dots
    we limit the maximum number that we display for each type.
*/
const maxDotsOfTypeToDisplay = 3;

export default {
    props: {
        users: {
            validator(values) {
                return values.every(
                    value => value.status !== undefined && value.user !== undefined && value.user.id !== undefined
                );
            }
        }
    },
    computed: {
        numberOfApprovedDotsToDisplay() {
            return this.userIdsByQaStatus[BannerQaStatus.Approved].length > maxDotsOfTypeToDisplay
                ? maxDotsOfTypeToDisplay
                : this.userIdsByQaStatus[BannerQaStatus.Approved].length;
        },
        numberOfRejectedDotsToDisplay() {
            return this.userIdsByQaStatus[BannerQaStatus.Rejected].length > maxDotsOfTypeToDisplay
                ? maxDotsOfTypeToDisplay
                : this.userIdsByQaStatus[BannerQaStatus.Rejected].length;
        },
        hasUsersThatHaveApproved() {
            return this.userIdsByQaStatus[BannerQaStatus.Approved].length > 0;
        },
        hasUsersThatHaveRejected() {
            return this.userIdsByQaStatus[BannerQaStatus.Rejected].length > 0;
        },
        userIdsByQaStatus() {
            return this.users.reduce(
                (userIdsByQaStatus, { status, user }) => {
                    if (status !== BannerQaStatus.Draft) {
                        userIdsByQaStatus[status].push(user.id);
                    }
                    return userIdsByQaStatus;
                },
                {
                    [BannerQaStatus.Approved]: [],
                    [BannerQaStatus.Rejected]: []
                }
            );
        }
    },
    created() {
        this.BannerQaStatus = BannerQaStatus;
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

$user-gap: 1px;
$user-size: 25px;
$user-overlap-size: calc($user-size / 3);

.qa-user-status-indicators {
    display: flex;

    .ivu-poptip {
        display: flex;
    }
}

.qa-user-status-indicators__approved-icon {
    color: $success-color;
}

.qa-user-status-indicators__rejected-icon {
    color: $error-color;
}

.qa-user-status-indicators__user-dot {
    align-items: center;
    border-radius: 100%;
    border: $user-gap solid $grey1;
    color: $white;
    display: flex;
    height: $user-size;
    justify-content: center;
    margin: 0 (0 - $user-overlap-size) 0 0;
    width: $user-size;
}

.qa-user-status-indicators__user-dot--approved {
    background: linear-gradient(45deg, mix($success-color, $link-color, 75%), $success-color);
}

.qa-user-status-indicators__user-dot--rejected {
    background: linear-gradient(45deg, darken($error-color, 22.5%), $error-color);
}

.qa-user-status-indicators__user-dot-inner-group {
    display: flex;
    flex-direction: row-reverse;
}

.qa-user-status-indicators__user-dot-group {
    display: flex;
    flex-direction: row-reverse;
    padding: 0 $user-overlap-size 0 0;
}

.qa-user-status-indicators__user-list-item {
    font-weight: normal;
}

.qa-user-status-indicators__user-list-title {
    font-weight: bold;
}
</style>
