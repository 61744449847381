<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div :class="classes">
        <label v-if="internalShowLabel" class="hox-input__label" :for="inputId">
            <span v-if="isRequired" class="hox-input__required-indicator">*</span>
            <slot name="label" />
        </label>
        <input
            :id="inputId"
            ref="input"
            :class="inputClasses"
            :placeholder="placeholder"
            :type="type"
            :value="value"
            @input="emitValue"
            @keyup.enter="emitValue($event, 'enter')"
        />
        <div v-if="internalShowError || internalShowHint" class="hox-input__error-and-hint-wrapper">
            <div v-if="internalShowError" class="hox-input__error-container">
                <slot name="error" />
            </div>
            <div v-if="internalShowHint" class="hox-input__hint-container">
                <slot name="hint" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isRequired: {
            type: Boolean
        },

        marginBottom: {
            default: "base",
            type: String,
            validator(value) {
                return ["base", "small", "large", "none"].includes(value);
            }
        },

        placeholder: {
            type: String
        },

        showError: {
            default: undefined,
            type: Boolean
        },

        showHint: {
            default: undefined,
            type: Boolean
        },

        showLabel: {
            default: undefined,
            type: Boolean
        },

        size: {
            default: "base",
            type: String,
            validator(value) {
                return ["base", "large", "small"].includes(value);
            }
        },

        type: {
            default: "text",
            validator(value) {
                return ["text", "number", "password"].includes(value);
            }
        },

        value: {
            type: [Number, String]
        }
    },
    computed: {
        classes() {
            const classes = ["hox-input", `hox-input--margin-bottom-${this.marginBottom}`];
            return classes;
        },

        inputClasses() {
            const classes = ["hox-input__input", `hox-input__input--size-${this.size}`];
            if (this.internalShowError || this.internalShowHint) {
                classes.push("hox-input__input--margin-bottom-small");
            }
            if (this.internalShowError) {
                classes.push("hox-input__input--error");
            }
            return classes;
        },

        inputId() {
            return `input-${this._uid}`;
        },

        internalShowError() {
            return this.showError === false ? false : this.showError || this.$slots.error !== undefined;
        },

        internalShowHint() {
            return this.showHint === false ? false : this.showHint || this.$slots.hint !== undefined;
        },

        internalShowLabel() {
            return this.showLabel === false ? false : this.showLabel || this.$slots.label !== undefined;
        }
    },

    methods: {
        emitValue(evt, type = "input") {
            this.$emit(type, evt.target.value);
        },

        focus() {
            this.$refs.input.focus();
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_mixins.scss";
@import "@/../sass/_variables.scss";

$error-and-hint-content-spacing: $spacing-smaller;

.hox-input {
    display: block;
    width: 100%;

    &--margin-bottom-base {
        margin-bottom: $spacing;
    }

    &--margin-bottom-small {
        margin-bottom: $spacing-small;
    }

    &--margin-bottom-large {
        margin-bottom: $spacing-large;
    }

    &--margin-bottom-none {
        margin-bottom: 0;
    }
}

.hox-input__error-and-hint-wrapper {
    margin: 0 0 (0 - $error-and-hint-content-spacing);

    @include clearfix();
}

.hox-input__error-container,
.hox-input__hint-container {
    color: $error-color;
    font-size: $font-size-small;
    line-height: $line-height-base;

    > p {
        margin: 0 0 $error-and-hint-content-spacing;
    }
}

.hox-input__hint-container {
    color: $cmp-light-font-color;
}

.hox-input__input {
    background: $white;
    border: 1px solid $cmp-light-border-color;
    color: $cmp-light-secondary-font-color;
    font-size: $font-size-small;
    margin: 0;
    padding: 0 $spacing-small;
    position: relative;
    transition:
        border-color $transition-time-base,
        box-shadow $transition-time-base;
    transition-timing-function: $transition-function-base;
    width: 100%;
    z-index: 1;

    &:hover {
        border-color: $primary-color;
    }

    &:focus {
        border-color: $primary-color;
        outline: none;
        @include box-shadow-solid($color: $primary-color);
    }

    &::placeholder {
        color: $cmp-light-tertiary-font-color;
    }

    &--margin-bottom-small {
        margin-bottom: $spacing-small;
    }

    &--error {
        border-color: $error-color;

        &:hover {
            border-color: $error-color;
        }

        &:focus {
            border-color: $error-color;
            outline: none;
            @include box-shadow-solid($color: $error-color);
        }
    }

    &--size-base {
        height: $input-height-base;
    }

    &--size-large {
        font-size: $font-size-base;
        height: $input-height-large;
    }

    &--size-small {
        height: $input-height-small;
    }
}

.hox-input__label {
    color: $cmp-light-secondary-font-color;
    display: inline-block;
    font-size: $font-size-base;
    margin: 0 0 $spacing-small;
}

.hox-input__required-indicator {
    color: $error-color;
}
</style>
