<template>
    <div class="deliverable-value-item editable-settings-control" :title="title">
        <slot name="row">
            <div class="editable-settings-control__button">
                <slot name="button"></slot>
            </div>
            <div class="editable-settings-control__value">
                <template v-if="value">
                    <span>{{ value }}</span>
                    <slot name="actions"></slot>
                </template>
                <template v-else>
                    <slot name="value"></slot>
                    <slot name="actions"></slot>
                </template>
            </div>
        </slot>
    </div>
</template>
<script>
export default {
    name: "EditableSettingsControl",
    props: {
        value: {
            type: String
        },
        title: {
            type: String,
            default: ""
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.editable-settings-control {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex: 1 0 100%;
    padding: 0;
    height: 35px;

    &__button {
        text-align: center;
        color: $cmp-dark-font-color;
        border-right: 1px solid $cmp-dark-bg-color;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .ivu-poptip-rel {
            flex: 0 0 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__value {
        padding: 0 10px;
        display: flex;
        width: 100%;
    }
}
</style>
