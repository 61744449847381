<template>
    <div class="library-deliverable-table" :class="{ 'library-deliverable-table--locked-campaign': isCampaignLocked }">
        <hox-table
            ref="table"
            :empty-message-is-visible="!deliverables.length && !isLoadingDeliverables"
            :has-border="false"
            :is-loading="isLoadingDeliverables"
            is-scrollable
        >
            <template #header>
                <hox-table-header :is-sticky="!isIe">
                    <hox-table-header-column
                        :rowspan="primaryHeaderRowspan"
                        :is-sticky-left="!isIe"
                        :width-pixels="checkboxColumnWidth"
                    />
                    <hox-table-header-column
                        :rowspan="primaryHeaderRowspan"
                        :is-sticky-left="!isIe"
                        :max-width-pixels="reportingLabelColumnWidth"
                    >
                        Reporting Label
                    </hox-table-header-column>
                    <hox-table-header-column
                        v-for="column in headers.primary"
                        :key="column.key"
                        :colspan="column.colspan"
                        :has-strong-border-left="column.hasStrongBorderLeft"
                        :is-group-header="column.isGroupHeader"
                        :min-width-pixels="column.minWidthPixels"
                        :max-width-pixels="column.maxWidthPixels"
                        :rowspan="column.rowspan"
                    >
                        <table-metadata-header-cell
                            v-if="column.type === ColumnType.Metadata"
                            :column-name="metadataFieldsByFieldId[column.key].name"
                            :deliverables="deliverables"
                            :field-id="column.key"
                            :selected-deliverables="selectedDeliverables"
                            :all-selected-mode="allSelectedMode"
                            :total-items="totalItems"
                            :library-filters-for-query="libraryFiltersForQuery"
                            @metadataValueUpdatedOnDeliverables="emitMetadataValueUpdatedOnDeliverables"
                        >
                            <span :title="column.title">
                                {{ column.title }}
                            </span>
                        </table-metadata-header-cell>
                        <span v-else :title="column.title">
                            {{ column.title }}
                        </span>
                    </hox-table-header-column>
                    <hox-table-header-column
                        :rowspan="primaryHeaderRowspan"
                        :is-sticky-right="!isIe"
                        :width-pixels="actionItemsColumnWidth"
                    >
                        <table-action-items-cell v-if="selectedDeliverableIds.length" @showPreview="showBulkApproval">
                            <template #content>Bulk QA</template>
                        </table-action-items-cell>
                    </hox-table-header-column>
                </hox-table-header>
                <hox-table-header v-if="headers.secondary.length > 0" :is-sticky="!isIe" is-sub-header>
                    <hox-table-header-column
                        v-for="column in headers.secondary"
                        :key="column.title"
                        :max-width-pixels="column.maxWidthPixels"
                    >
                        <table-metadata-search-header-cell
                            v-if="column.type === ColumnType.Metadata"
                            :field-id="column.key"
                        />
                        <span v-else :title="column.title">
                            {{ column.title }}
                        </span>
                    </hox-table-header-column>
                </hox-table-header>
            </template>
            <template #body>
                <hox-table-row v-for="row in rows" :key="row.idHash">
                    <hox-table-row-column :is-sticky-left="!isIe" :sticky-offset-pixels="0">
                        <table-checkbox-cell
                            :is-selected="selectedDeliverableIds.includes(row.idHash)"
                            @selectionChanged="toggleDeliverableSelection(row.idHash)"
                        />
                    </hox-table-row-column>
                    <hox-table-row-column
                        :is-sticky-left="!isIe"
                        :max-width-pixels="reportingLabelColumnWidth"
                        :sticky-offset-pixels="checkboxColumnWidth"
                        :overflow-hidden="false"
                    >
                        <table-reporting-label-cell
                            :title="row.reportingLabel"
                            :deliverable="deliverablesByIdHash[row.idHash]"
                            :input-is-visible="reportingLabelInputIsVisible(row.idHash)"
                            @toggleInputIsVisible="toggleReportingLabelInputIsVisible(row.idHash)"
                            @reportingLabelUpdated="
                                emitReportingLabelUpdatedOnDeliverable({ idHash: row.idHash, reportingLabel: $event })
                            "
                        />
                    </hox-table-row-column>
                    <hox-table-row-column
                        v-for="rowColumn in row.columns"
                        :key="`${row.idHash}:${rowColumn.key}`"
                        :has-strong-border-left="rowColumn.hasStrongBorderLeft"
                        :max-width-pixels="rowColumn.maxWidthPixels"
                        :overflow-hidden="rowColumn.type !== ColumnType.Metadata"
                    >
                        <table-image-cell
                            v-if="
                                rowColumn.type === EditableType.Image || rowColumn.type === EditableType.BackgroundImage
                            "
                            :fallback-to-folder-icon="rowColumn.isFolder"
                            :link-url="rowColumn.linkUrl"
                            :src="rowColumn.value"
                            :title="rowColumn.title"
                        />
                        <table-video-cell v-else-if="rowColumn.type === EditableType.Video" :src="rowColumn.value" />
                        <table-status-cell
                            v-else-if="rowColumn.type === ColumnType.Tag"
                            :status="rowColumn.value"
                            @click="showDeliverableQa(row.idHash)"
                        />
                        <table-published-status-cell
                            v-else-if="rowColumn.type === ColumnType.PublishedStatus"
                            :is-published-to-double-click="rowColumn.value.isPublishedToDoubleClick"
                            :is-published-to-sizmek="rowColumn.value.isPublishedToSizmek"
                            :is-published-to-open-d-c="rowColumn.value.isPublishedToOpenDC"
                            @showPlatformPreview="platform => showDeliverablePreview(row.idHash, platform)"
                        />
                        <table-metadata-cell
                            v-else-if="rowColumn.type === ColumnType.Metadata"
                            :deliverable="deliverablesByIdHash[row.idHash]"
                            :field-id="rowColumn.key"
                            :input-is-visible="metadataInputIsVisible(row.idHash, rowColumn.key)"
                            :metadata-value="rowColumn.value"
                            @metadataValueUpdated="
                                emitMetadataValueUpdatedOnDeliverables({
                                    idHashes: [row.idHash],
                                    metadataValue: $event
                                })
                            "
                            @toggleInputIsVisible="toggleMetadataInputIsVisible(row.idHash, rowColumn.key)"
                        />
                        <a
                            v-else-if="rowColumn.type === EditableType.Url"
                            :src="rowColumn.value"
                            target="_blank"
                            :title="rowColumn.value"
                        >
                            {{ rowColumn.value }}
                        </a>
                        <span v-else-if="rowColumn.value" :title="rowColumn.value">
                            {{ rowColumn.value }}
                        </span>
                        <span v-else-if="rowColumn.type === ColumnType.GroupValue">Default</span>
                        <table-empty-cell v-else />
                    </hox-table-row-column>
                    <hox-table-row-column :is-sticky-right="!isIe" :width-pixels="actionItemsColumnWidth">
                        <table-action-items-cell @showPreview="showDeliverablePreview(row.idHash)" />
                    </hox-table-row-column>
                </hox-table-row>
            </template>
            <template #emptyMessage>
                <slot name="emptyMessage" />
            </template>
        </hox-table>
    </div>
</template>

<script>
import TableActionItemsCell from "@/components/Campaign/LibraryDeliverableTable/LibraryDeliverableTableActionItemsCell";
import TableCheckboxCell from "@/components/Campaign/LibraryDeliverableTable/LibraryDeliverableTableCheckboxCell";
import TableEmptyCell from "@/components/Campaign/LibraryDeliverableTable/LibraryDeliverableTableEmptyCell";
import TableImageCell from "@/components/Campaign/LibraryDeliverableTable/LibraryDeliverableTableImageCell";
import TableMetadataCell from "@/components/Campaign/LibraryDeliverableTable/LibraryDeliverableTableMetadataCell";
import TableMetadataHeaderCell from "@/components/Campaign/LibraryDeliverableTable/LibraryDeliverableTableMetadataHeaderCell";
import TableMetadataSearchHeaderCell from "@/components/Campaign/LibraryDeliverableTable/LibraryDeliverableTableMetadataSearchHeaderCell";
import TablePublishedStatusCell from "@/components/Campaign/LibraryDeliverableTable/LibraryDeliverableTablePublishedStatusCell";
import TableReportingLabelCell from "@/components/Campaign/LibraryDeliverableTable/LibraryDeliverableTableReportingLabelCell";
import TableStatusCell from "@/components/Campaign/LibraryDeliverableTable/LibraryDeliverableTableStatusCell";
import TableVideoCell from "@/components/Campaign/LibraryDeliverableTable/LibraryDeliverableTableVideoCell";
import { EditableType } from "@/enums/editables";
import URLManager from "@/services/URLManager";
import { CampaignGetters } from "@/store/modules/campaign";
import { DeliverableLibraryAction, DeliverableLibraryGetters } from "@/store/modules/deliverableLibrary";
import { formatEditableName, isIe } from "@/utils";
import languageUtils from "@/utils/languages";

const ColumnType = {
    GroupValue: "groupValue",
    Metadata: "metadata",
    PublishedStatus: "publishedStatus",
    Tag: "tag"
};

const QueryParamKey = {
    CreativeValuesAreVisible: "tccv",
    SortedColumnKeys: "tsck",
    VisibleColumnKeys: "tvck"
};

export default {
    components: {
        TableActionItemsCell,
        TableCheckboxCell,
        TableEmptyCell,
        TableImageCell,
        TableMetadataCell,
        TableMetadataHeaderCell,
        TableMetadataSearchHeaderCell,
        TablePublishedStatusCell,
        TableReportingLabelCell,
        TableStatusCell,
        TableVideoCell
    },
    props: {
        allSelectedMode: {
            type: Boolean,
            default: false
        },

        deliverables: {
            required: true,
            type: Array
        },

        isLoadingDeliverables: {
            type: Boolean
        },

        libraryFiltersForQuery: {
            required: true,
            type: Object
        },

        selectedDeliverableIds: {
            required: true,
            type: Array
        },

        selectedDeliverables: {
            required: true,
            type: Array
        },

        totalItems: {
            required: true,
            type: Number
        }
    },
    data() {
        return {
            actionItemsColumnWidth: 50,
            checkboxColumnWidth: 40,
            columnWidthByType: {
                [EditableType.BackgroundImage]: 300,
                [EditableType.Folder]: 300,
                [EditableType.Image]: 300,
                [EditableType.Video]: 300
            },
            defaultColumnWidth: 203,
            isIe: isIe(),
            reportingLabelColumnWidth: 300,
            visibleMetadataInput: {
                idHash: undefined,
                fieldId: undefined
            },
            visibleReportingLabelInput: null
        };
    },
    computed: {
        campaignId() {
            return this.$route.params.campaignId;
        },
        columnLabelsByKey() {
            return this.$store.getters[DeliverableLibraryGetters.tableColumnLabelsByColumnKey];
        },
        deliverableDataByIdHash() {
            return this.deliverables.reduce((acc, deliverable) => {
                const language = languageUtils.localeToFriendlyName(deliverable.language);
                const groupValuesByGroupName = {};
                deliverable.combination.forEach(combination => {
                    Object.keys(combination).forEach(groupName => {
                        const { groupValueId } = combination[groupName];
                        const groupValueName = this.$store.state.campaign.editableGroupValues[groupValueId].value;
                        groupValuesByGroupName[groupName] = {
                            type: ColumnType.GroupValue,
                            value: groupValueName
                        };
                    });
                });
                const editablesByEditableId = {};
                deliverable.computedValues.forEach(computedValue => {
                    editablesByEditableId[computedValue.editableId] = this.constructEditableCellData(
                        computedValue,
                        deliverable.masterTemplate.preview
                    );
                });
                const metadataValuesByFieldId = {};
                deliverable.metadata.forEach(metadata => {
                    metadataValuesByFieldId[metadata.field._id] = {
                        type: ColumnType.Metadata,
                        value: metadata
                    };
                });
                const status = this.$store.getters[DeliverableLibraryGetters.computedDeliverableStatus](
                    deliverable.idHash
                );
                const isPublishedToSizmek = this.$store.getters[
                    DeliverableLibraryGetters.computedDeliverableIsPublishedToSizmek
                ](deliverable.idHash);
                const isPublishedToDoubleClick = this.$store.getters[
                    DeliverableLibraryGetters.computedDeliverableIsPublishedToDoubleClick
                ](deliverable.idHash);
                const isPublishedToOpenDC = this.$store.getters[
                    DeliverableLibraryGetters.computedDeliverableIsPublishedToODC
                ](deliverable.idHash);

                acc[deliverable.idHash] = {
                    ...groupValuesByGroupName,
                    ...editablesByEditableId,
                    idHash: deliverable.idHash,
                    language,
                    ...metadataValuesByFieldId,
                    publishedStatus: {
                        type: ColumnType.PublishedStatus,
                        value: {
                            isPublishedToDoubleClick,
                            isPublishedToSizmek,
                            isPublishedToOpenDC
                        }
                    },
                    reportingLabel: deliverable.reportingLabel,
                    status: {
                        type: ColumnType.Tag,
                        value: status
                    },
                    templateSize: `${deliverable.masterTemplate.width}x${deliverable.masterTemplate.height}`
                };
                return acc;
            }, {});
        },

        primaryHeaderRowspan() {
            return this.headers.secondary.length > 0 ? 2 : 1;
        },
        headers() {
            const headerColumns = {
                primary: [],
                secondary: []
            };
            const metadataColumnsAreVisible = this.sortedVisibleColumnKeys.some(key => {
                return this.keyIsForMetadata(key);
            });
            // eslint-disable-next-line complexity
            this.sortedVisibleColumnKeys.forEach(key => {
                if (this.creativeValuesAreVisible && this.keyIsForGroup(key)) {
                    const groupEditableIds = this.$store.state.campaign.normalized.editableGroups[key].editables;
                    headerColumns.primary.push(
                        {
                            key,
                            hasStrongBorderLeft: true,
                            rowspan: 2,
                            maxWidthPixels: this.getColumnWidth(key),
                            title: this.getColumnTitle(key)
                        },
                        {
                            key: `${key}-values`,
                            colspan: groupEditableIds.length,
                            isGroupHeader: true,
                            maxWidthPixels: this.getColumnWidth(key),
                            title: `${this.getColumnTitle(key)} values`
                        }
                    );
                    headerColumns.secondary.push(
                        ...groupEditableIds.map(editableId => {
                            const title = this.getColumnTitle(editableId);
                            return {
                                maxWidthPixels: this.getColumnWidth(editableId),
                                title
                            };
                        })
                    );
                } else {
                    const keyIsForMetadata = this.keyIsForMetadata(key);
                    headerColumns.primary.push({
                        isGroupHeader: keyIsForMetadata,
                        key,
                        minWidthPixels: keyIsForMetadata ? 150 : undefined,
                        maxWidthPixels: this.getColumnWidth(key),
                        rowspan:
                            !keyIsForMetadata && (metadataColumnsAreVisible || this.creativeValuesAreVisible) ? 2 : 1,
                        title: this.getColumnTitle(key),
                        type: keyIsForMetadata && ColumnType.Metadata
                    });
                    if (keyIsForMetadata) {
                        headerColumns.secondary.push({
                            key,
                            type: ColumnType.Metadata
                        });
                    }
                }
            });
            return headerColumns;
        },

        isCampaignLocked() {
            return this.$store.state.campaign.isLocked;
        },

        rows() {
            return this.deliverables.map(deliverable => {
                const rowData = this.deliverableDataByIdHash[deliverable.idHash];
                const row = {
                    columns: [],
                    idHash: rowData.idHash,
                    reportingLabel: rowData.reportingLabel
                };
                this.sortedVisibleColumnKeys.forEach(key => {
                    if (this.keyIsForGroup(key)) {
                        row.columns.push({
                            ...this.getCellData(rowData, key),
                            key,
                            hasStrongBorderLeft: this.creativeValuesAreVisible,
                            maxWidthPixels: this.getColumnWidth(key),
                            type: ColumnType.GroupValue
                        });
                        if (this.creativeValuesAreVisible) {
                            const groupEditableIds =
                                this.$store.state.campaign.normalized.editableGroups[key].editables;
                            groupEditableIds.forEach(editableId => {
                                const column = {
                                    ...this.getCellData(rowData, editableId),
                                    key: editableId,
                                    maxWidthPixels: this.getColumnWidth(editableId)
                                };
                                row.columns.push(column);
                            });
                        }
                    } else if (this.keyIsForMetadata(key)) {
                        row.columns.push({
                            ...this.getCellData(rowData, key),
                            key,
                            maxWidthPixels: this.getColumnWidth(key),
                            type: ColumnType.Metadata
                        });
                    } else {
                        row.columns.push({
                            ...this.getCellData(rowData, key),
                            key,
                            maxWidthPixels: this.getColumnWidth(key)
                        });
                    }
                });
                return row;
            });
        },
        columnTypesByKey() {
            /*
                Currently we only need to do this lookup for columns that are editables, so that's
                all we generate in the object.
            */
            return this.$store.getters[CampaignGetters.groupEditables].reduce((acc, editable) => {
                acc[editable._id] = editable.type;
                return acc;
            }, {});
        },
        creativeValuesAreVisible: {
            get() {
                return this.$store.state.deliverableLibrary.table.creativeValuesAreVisible;
            },
            set(value) {
                this.$store.dispatch(DeliverableLibraryAction.SetCreativeValuesAreVisible, value);
            }
        },
        deliverablesByIdHash() {
            return this.deliverables.reduce((acc, deliverable) => {
                acc[deliverable.idHash] = {
                    ...deliverable
                };
                return acc;
            }, {});
        },
        metadataFieldsByFieldId() {
            return this.$store.state.deliverableLibrary.metadataFieldsByFieldId;
        },
        sortedColumnKeys: {
            get() {
                return this.$store.state.deliverableLibrary.table.sortedColumnKeys;
            },
            set(sortedColumnKeys) {
                this.$store.dispatch(DeliverableLibraryAction.SetTableSortedColumnKeys, sortedColumnKeys);
            }
        },
        sortedVisibleColumnKeys() {
            return this.sortedColumnKeys.filter(key => this.visibleColumnKeys.includes(key));
        },
        visibleColumnKeys: {
            get() {
                return this.$store.state.deliverableLibrary.table.visibleColumnKeys;
            },
            set(visibleColumnKeys) {
                this.$store.dispatch(DeliverableLibraryAction.SetTableVisibleColumnKeys, visibleColumnKeys);
            }
        }
    },
    watch: {
        creativeValuesAreVisible() {
            this.setQueryParams();
        },
        deliverables() {
            /*
                We don't want open inputs to persist changes in page so
                when the deliverables change we hide them all.
            */
            this.hideMetadataInputs();
            this.hideReportingLabelInputs();
        },
        isLoadingDeliverables() {
            /*
                When we switch between pages or change filters it feels odd to be
                left at an arbitrary scroll point on the table, so we reset the
                tables vertical scroll.

                We don't reset the horizontal scroll as that would take the user
                away from the columns they were interested in/currently typing
                a search filter on.
            */
            this.$refs.table.resetVerticalScroll();
            if (this.isLoadingDeliverables) {
                this.hideMetadataInputs();
                this.hideReportingLabelInputs();
            }
        },
        sortedColumnKeys() {
            this.setQueryParams();
        },
        visibleColumnKeys() {
            this.setQueryParams();
        }
    },
    beforeDestroy() {
        this.resetQueryParams();
    },
    created() {
        this.EditableType = EditableType;
        this.ColumnType = ColumnType;
        this.urlManager = new URLManager(this.$router, {
            defaults: [],
            tracked: Object.values(QueryParamKey)
        });
        this.initCreativeValuesAreVisible();
        this.initSortColumnKeys();
        this.initVisibleColumnKeys();
    },
    methods: {
        emitMetadataValueUpdatedOnDeliverables({ idHashes, metadataValue }) {
            this.$emit("metadataValueUpdatedOnDeliverables", {
                idHashes,
                metadataValue
            });
        },
        emitReportingLabelUpdatedOnDeliverable({ idHash, reportingLabel }) {
            this.$emit("reportingLabelUpdatedOnDeliverable", {
                idHash,
                reportingLabel
            });
        },
        metadataInputIsVisible(idHash, fieldId) {
            return idHash === this.visibleMetadataInput.idHash && fieldId === this.visibleMetadataInput.fieldId;
        },
        reportingLabelInputIsVisible(idHash) {
            return idHash === this.visibleReportingLabelInput;
        },
        // eslint-disable-next-line complexity
        constructEditableCellData(editable, masterTemplatePreviewUrl) {
            const editableCellData = {
                type: editable.type,
                value: editable.value
            };
            /*
                Some editable types do not have a `value` that is what we want to
                display in the table, so we handle them on a case by case basis
                to get a value that we want to display from them.
            */
            if (editable.type === EditableType.Folder) {
                editableCellData.isFolder = true;
                editableCellData.type = EditableType.Image;
                if (editable.value.startsWith("/clients/") || editable.value.startsWith("/media-library/")) {
                    editableCellData.linkUrl = `/${editable.value.replace(/^\/|\/$/g, "")}/index.html`;
                    const splitValue = editable.value.split("/");
                    if (splitValue[splitValue.length - 1] === "") {
                        editableCellData.title = splitValue[splitValue.length - 2];
                    } else {
                        editableCellData.title = splitValue[splitValue.length - 1];
                    }
                    editableCellData.value = `/${editable.value.replace(/^\/|\/$/g, "")}/static.png`;
                } else {
                    editableCellData.linkUrl = `${masterTemplatePreviewUrl}/${editable.value.replace(
                        /^\/|\/$/g,
                        ""
                    )}/index.html`;
                    editableCellData.title = "Default";
                    editableCellData.value = `${masterTemplatePreviewUrl}/${editable.value.replace(
                        /^\/|\/$/g,
                        ""
                    )}/static.png`;
                }
            } else if (editable.type === EditableType.Array) {
                const selectedValue = editableCellData.value.find(option => option.selected) || {};
                editableCellData.value = selectedValue.label;
            } else if (editable.type === EditableType.Boolean) {
                editableCellData.value =
                    editableCellData.value === true || editableCellData.value === "true" ? "Yes" : "No";
            } else if (editable.type === EditableType.File) {
                editableCellData.value = editableCellData.title || "Default";
            }
            return editableCellData;
        },
        getColumnWidth(key) {
            return this.columnWidthByType[this.columnTypesByKey[key]] || this.defaultColumnWidth;
        },
        // eslint-disable-next-line complexity
        getCellData(row, key) {
            if (!row || !key) {
                return {};
            }
            if (row[key] && row[key].value !== undefined && row[key].value !== null) {
                return row[key];
            }
            return {
                value: row[key]
            };
        },
        getColumnTitle(key) {
            if (this.columnLabelsByKey[key]) {
                return this.columnLabelsByKey[key];
            }
            if (this.keyIsForEditable(key)) {
                return formatEditableName(this.$store.state.campaign.normalized.editables[key].name);
            }
            return " ";
        },
        hideMetadataInputs() {
            this.visibleMetadataInput = {};
        },
        hideReportingLabelInputs() {
            this.visibleReportingLabelInput = null;
        },
        initCreativeValuesAreVisible() {
            const { [QueryParamKey.CreativeValuesAreVisible]: queryCreativeValuesAreVisible } = this.$route.query;
            if (queryCreativeValuesAreVisible) {
                this.creativeValuesAreVisible = true;
            }
        },
        initSortColumnKeys() {
            const { [QueryParamKey.SortedColumnKeys]: querySortedColumKeys } = this.$route.query;
            if (querySortedColumKeys) {
                const newSortedColumnKeys =
                    typeof querySortedColumKeys === "string" ? [querySortedColumKeys] : querySortedColumKeys;
                this.sortedColumnKeys = newSortedColumnKeys;
            }
            if (this.sortedColumnKeys === null) {
                this.sortedColumnKeys = Object.keys(this.columnLabelsByKey);
            } else {
                /*
                    In order to handle circumstances where a template or
                    metadata field has been added or removed we need to do a
                    little bit of bookkeeping to keep things consistent.

                    We want to maintain the order of what we have stored
                    but remove any keys that do no exist and append any new keys.
                */
                const newSortedColumnKeys = Object.keys(this.columnLabelsByKey);
                const sortedColumnKeysToBeStored = [];
                /*
                    We iterate through sortedColumnKeys because that is the order
                    that we want to persist.
                */
                this.sortedColumnKeys.forEach(columnKey => {
                    const index = newSortedColumnKeys.indexOf(columnKey);
                    if (index !== -1) {
                        /*
                            If it exists in this.sortedColumnKeys and newSortedColumnKeys
                            then we add it to sortedColumnKeysToBeStored to maintain the
                            order of all existing keys.
                        */
                        sortedColumnKeysToBeStored.push(columnKey);
                        newSortedColumnKeys.splice(index, 1);
                    }
                    /*
                        If we can't find an index then we do nothing to let the value
                        disapear into the ether.
                    */
                });
                /*
                    Append what remains of newSortedColumnKeys so that
                    any new keys are at the end of the order.
                */
                sortedColumnKeysToBeStored.push(...newSortedColumnKeys);
                this.sortedColumnKeys = [...sortedColumnKeysToBeStored];
            }
        },
        initVisibleColumnKeys() {
            const { [QueryParamKey.VisibleColumnKeys]: queryVisibleColumKeys } = this.$route.query;
            if (queryVisibleColumKeys) {
                const newVisibleColumnKeys =
                    typeof queryVisibleColumKeys === "string" ? [queryVisibleColumKeys] : queryVisibleColumKeys;
                const filteredNewVisibleColumnKeys = newVisibleColumnKeys.filter(columnKey =>
                    Object.keys(this.columnLabelsByKey).includes(columnKey)
                );
                this.visibleColumnKeys = filteredNewVisibleColumnKeys;
            }
            if (this.visibleColumnKeys === null) {
                this.visibleColumnKeys = Object.keys(this.columnLabelsByKey);
            }
        },
        keyIsForEditable(key) {
            return this.$store.state.campaign.normalized.editables[key] !== undefined;
        },
        keyIsForGroup(key) {
            return this.$store.state.campaign.normalized.editableGroups[key] !== undefined;
        },
        keyIsForMetadata(key) {
            return this.metadataFieldsByFieldId[key] !== undefined;
        },
        resetQueryParams() {
            this.urlManager.setURLParams(
                Object.values(QueryParamKey).map(queryParamKey => ({
                    key: queryParamKey,
                    value: []
                })),
                true
            );
        },
        setQueryParams() {
            this.urlManager.setURLParams(
                [
                    {
                        key: QueryParamKey.CreativeValuesAreVisible,
                        value: this.creativeValuesAreVisible ? [this.creativeValuesAreVisible] : []
                    },
                    {
                        key: QueryParamKey.SortedColumnKeys,
                        value: this.sortedColumnKeys
                    },
                    {
                        key: QueryParamKey.VisibleColumnKeys,
                        value: this.visibleColumnKeys
                    }
                ],
                true
            );
        },

        showBulkApproval() {
            if (this.allSelectedMode) {
                this.$store.dispatch(DeliverableLibraryAction.SetDrawerDeliverables, null);
            } else {
                this.$store.dispatch(
                    DeliverableLibraryAction.SetDrawerDeliverables,
                    this.selectedDeliverableIds.map(idHash => this.deliverablesByIdHash[idHash])
                );
            }

            this.$store.dispatch(DeliverableLibraryAction.SetDrawerIsVisible, true);

            this.$store.dispatch(DeliverableLibraryAction.SetDrawerActiveTab, "qa");
        },

        showDeliverablePreview(idHash, platform) {
            this.$store.dispatch(DeliverableLibraryAction.SetDrawerDeliverables, [this.deliverablesByIdHash[idHash]]);
            this.$store.dispatch(DeliverableLibraryAction.SetDrawerIsVisible, true);
            if (platform) {
                this.$store.dispatch(DeliverableLibraryAction.SetDrawerActiveTab, "preview");
                this.$store.dispatch(DeliverableLibraryAction.SetPreviewDrawerActiveTab, platform);
            }
        },
        showDeliverableQa(idHash) {
            this.$store.dispatch(DeliverableLibraryAction.SetDrawerDeliverables, [this.deliverablesByIdHash[idHash]]);
            this.$store.dispatch(DeliverableLibraryAction.SetDrawerIsVisible, true);
            this.$store.dispatch(DeliverableLibraryAction.SetDrawerActiveTab, "qa");
        },
        toggleDeliverableSelection(idHash) {
            let updatedSelectedDeliverableIds;
            if (this.selectedDeliverableIds.includes(idHash)) {
                updatedSelectedDeliverableIds = this.selectedDeliverableIds.filter(
                    selectedIdHash => selectedIdHash !== idHash
                );
            } else {
                updatedSelectedDeliverableIds = [...this.selectedDeliverableIds, idHash];
            }
            this.$emit("selectedDeliverablesChanged", updatedSelectedDeliverableIds);
        },
        toggleMetadataInputIsVisible(idHash, fieldId) {
            if (this.metadataInputIsVisible(idHash, fieldId)) {
                this.hideMetadataInputs();
            } else {
                this.visibleMetadataInput = {
                    idHash,
                    fieldId
                };
            }
        },
        toggleReportingLabelInputIsVisible(idHash) {
            if (this.reportingLabelInputIsVisible(idHash)) {
                this.hideReportingLabelInputs();
            } else {
                this.visibleReportingLabelInput = idHash;
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.library-deliverable-table {
    height: 100%;

    &--locked-campaign {
        height: calc(100% - 64px);
    }
}
</style>
