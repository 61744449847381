<template>
    <div class="set-banner-status-dropdown">
        <Dropdown
            placement="bottom-start"
            transfer
            trigger="custom"
            :visible="dropdownIsVisible"
            @on-clickoutside="toggleDropdown"
        >
            <square-button
                :is-active="dropdownIsVisible"
                :is-loading="isSettingDeliverablesStatus"
                @click="toggleDropdown"
            >
                <div>
                    <qa-status-dot :status="status" />
                    <Icon :class="arrowClasses" type="ios-arrow-down" />
                </div>
            </square-button>
            <template #list>
                <qa-status-dropdown-items :selected-status="status" @statusSelected="setDeliverableStatus" />
            </template>
        </Dropdown>
    </div>
</template>

<script>
import QaStatusDropdownItems from "@/components/Campaign/QaStatusDropdownItems";
import SquareButton from "@/components/common/SquareButton";
import QaStatusDot from "@/components/Qa/QaStatusDot";
import { BannerQaStatusLabel } from "@/enums/banners";
import { setDeliverablesGlobalStatus } from "@/services/Qa";
import { DeliverableLibraryAction, DeliverableLibraryGetters } from "@/store/modules/deliverableLibrary";

export default {
    components: {
        QaStatusDot,
        QaStatusDropdownItems,
        SquareButton
    },
    props: {
        deliverable: {
            required: true,
            type: Object
        }
    },
    data() {
        return {
            dropdownIsVisible: false,
            isSettingDeliverablesStatus: false
        };
    },
    computed: {
        arrowClasses() {
            const classes = ["set-banner-status-dropdown__select-arrow"];
            if (this.dropdownIsVisible) {
                classes.push("set-banner-status-dropdown__select-arrow--active ");
            }
            return classes;
        },
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },
        status() {
            return this.$store.getters[DeliverableLibraryGetters.computedDeliverableStatus](this.deliverable.idHash);
        }
    },
    methods: {
        toggleDropdown() {
            this.dropdownIsVisible = !this.dropdownIsVisible;
        },
        async setDeliverableStatus(status) {
            this.toggleDropdown();
            this.isSettingDeliverablesStatus = true;
            try {
                const deliverables = await setDeliverablesGlobalStatus(this.campaignId, [this.deliverable], status);
                this.$store.dispatch(DeliverableLibraryAction.SetDeliverableStatusByIdHash, deliverables);
                this.$snackbar.success(`The deliverable has had its status set to "${BannerQaStatusLabel[status]}"`);
                this.$emit("statusHasBeenUpdated");
            } catch (err) {
                this.$snackbar.error(
                    "There was an unexpected error and the deliverable status was unable to be set. Please try again in a few moments."
                );
            }
            this.isSettingDeliverablesStatus = false;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.set-banner-status-dropdown {
    display: inline-block;
}

.set-banner-status-dropdown__select-arrow {
    font-size: $font-size-small;
    margin: 0 0 0 2px;
    transition: transform 0.2s linear;
}

.set-banner-status-dropdown__select-arrow--active {
    transform: rotate(180deg);
}
</style>
