<template>
    <div class="template-upload">
        <Upload
            ref="upload"
            :action="uploadAction"
            :data="formData"
            :show-upload-list="false"
            :on-success="onTemplateUploadSuccess"
            :on-error="onTemplateUploadError"
            :on-progress="showUploadPercentage"
            :multiple="uploadType === UploadType.Create"
            :accept="acceptedFileMimes"
        >
            <div class="template-upload__button" @click="updatePostData">
                <Button ref="remote" class="hidden">Add template</Button>
                <slot>
                    <Button type="primary">Add template</Button>
                </slot>
            </div>
        </Upload>
        <hox-modal v-if="showUpdateTemplatePrompt" @close="updateTemplatePromptCancel">
            <template #header>Are you sure?</template>
            <template>
                <div class="template-upload-modal">
                    <p class="small-margin">This template is used in the following campaigns:</p>

                    <ul>
                        <li v-for="campaign in templateUsedInCampaigns" :key="campaign._id">
                            {{ campaign.name }}
                        </li>
                    </ul>

                    <p class="small-margin">{{ updateTemplateModalAdsText }}</p>

                    <hox-alert v-if="hasGroupConflicts" margin-bottom="none" type="danger" size="small">
                        <template #title>The template you are uploading contains different groups.</template>
                        <template #content>
                            <p class="template-upload-modal__alert-text">
                                Any deliverables which are affected by these changes will no longer be accessible. You
                                will lose access to any comments, annotations and status set on these deliverables, and
                                you should rebuild any feeds that have been published.
                            </p>
                            <template v-if="editableGroupConflicts.added.length">
                                <span class="template-upload-modal__label">
                                    This template contains these new groups:
                                </span>
                                <ul>
                                    <li
                                        v-for="added in editableGroupConflicts.added"
                                        :key="added"
                                        class="capitalize-text"
                                    >
                                        {{ added }}
                                    </li>
                                </ul>
                            </template>
                            <div v-if="editableGroupConflicts.removed.length" class="template-upload-modal__removed">
                                <span class="template-upload-modal__label">
                                    This template has removed following groups:
                                </span>
                                <ul>
                                    <li
                                        v-for="removed in editableGroupConflicts.removed"
                                        :key="removed"
                                        class="capitalize-text"
                                    >
                                        {{ removed }}
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </hox-alert>

                    <hox-alert
                        v-if="templateFamily.length && templateFamilyMaster"
                        margin-bottom="none"
                        type="danger"
                        size="small"
                    >
                        <template #title>Template family detected</template>
                        <template #content>
                            <p v-if="familyUpdateInProgress" class="template-upload-modal__alert-text">
                                Updating family members:
                            </p>
                            <p v-else class="template-upload-modal__alert-text">
                                This template belongs to the "{{ templateFamilyMaster.name }}" family. The Master size
                                will be updated. Select which additional sizes to update below.
                            </p>

                            <ul v-if="familyUpdateInProgress">
                                <div v-for="tplId in familyMembersToUpdate" :key="tplId">
                                    <family-update-progress-row
                                        :is-master="!templateFamilyById[tplId].parentId"
                                        :is-loading="familyMembersUpdated[tplId] === undefined"
                                        :has-failed="familyMembersUpdated[tplId] === false"
                                    >
                                        {{ templateFamilyById[tplId].width }}x{{ templateFamilyById[tplId].height }}
                                    </family-update-progress-row>
                                </div>
                            </ul>
                            <div v-else>
                                <Checkbox :value="allMembersSelected" @on-change="toggleSelection">All</Checkbox>
                                <CheckboxGroup v-model="familyMembersToUpdate">
                                    <Checkbox :label="templateFamilyMaster._id" :disabled="forceSelectFamilyMaster">
                                        {{ templateFamilyMaster.width }}x{{ templateFamilyMaster.height }} (Master)
                                    </Checkbox>
                                    <div v-for="tpl in templateFamily" :key="tpl._id">
                                        <Checkbox v-if="tpl.parentId" :label="tpl._id">
                                            {{ tpl.width }}x{{ tpl.height }}
                                        </Checkbox>
                                    </div>
                                </CheckboxGroup>
                            </div>
                        </template>
                    </hox-alert>
                </div>
            </template>
            <template #footer>
                <Button
                    v-if="familyUpdateInProgress"
                    class="template-upload-modal__button"
                    @click="showUpdateTemplatePrompt = false"
                >
                    Close
                </Button>
                <template v-else>
                    <Button class="template-upload-modal__button" @click="updateTemplatePromptCancel">
                        Cancel
                    </Button>
                    <Button class="template-upload-modal__button" type="primary" @click="updateTemplatePromptOk">
                        <template v-if="familyMembersToUpdate.length > 1">Update family</template>
                        <template v-else>Go ahead</template>
                    </Button>
                </template>
            </template>
        </hox-modal>
    </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars, import/no-extraneous-dependencies
import * as Types from "shared-utils/types";
// eslint-disable-next-line import/no-extraneous-dependencies
import { TemplateType } from "shared-utils/enums/masterTemplate";
// eslint-disable-next-line import/no-extraneous-dependencies
import { PSD_MIME_TYPES, ZIP_MIME_TYPE } from "shared-utils/constants";

import bus from "@/bus";
import { uploadConfigExpired } from "@/utils";
import MasterTemplates from "@/services/MasterTemplates";
import clientMasterTemplatesUploadConfigQuery from "@/apollo/queries/v2/ClientMasterTemplatesUploadConfig.gql";
import getAfterEffectsApiInfoQuery from "@/apollo/queries/v2/GetAfterEffectsApiInfo.gql";
import { MasterTemplateLibraryAction } from "@/store/modules/masterTemplateLibrary";
import FamilyUpdateProgressRow from "@/components/Campaign/MasterTemplates/FamilyUpdateProgressRow";
import { createAEProject } from "@/utils/ae";

const UploadType = {
    Create: "create",
    Update: "update"
};

/**
 * @typedef {MasterTemplates} MasterTemplatesService
 */

/**
 * @typedef {Object} MasterTemplateUploadButton
 * PROPS
 * @property {string} masterTemplateId
 * DATA
 * @property {object} editableGroupConflicts
 * @property {string[]} editableGroupConflicts.added
 * @property {string[]} editableGroupConflicts.removed
 * @property {string[]} familyMembersToUpdate
 * @property {{ [key: string]: boolean }} familyMembersUpdated
 * @property {boolean} familyUpdateInProgress
 * @property {boolean} isAeUploading
 * @property {boolean} isLoading
 * @property {Object[]} templateFamily
 * @property {{ [key: string]: object }} templateFamilyById
 * @property {Function} templateUsedPromptResolve
 * @property {Object} templateUsedToProduceAds
 * @property {Object} UploadType
 * @property {Object} fileTypeMap
 * @property {Object} formData
 * @property {boolean} showUpdateTemplatePrompt
 * @property {Object} templateUsedInCampaigns
 * @property {Object | undefined} templateUsedProducedAds
 * COMPUTED
 * @property {string} acceptedFileMimes
 * @property {Types.AeApiInfo} afterEffectsApiInfo
 * @property {boolean} allMembersSelected
 * @property {boolean} canManageMasterTemplates
 * @property {string} clientId
 * @property {boolean} forceSelectFamilyMaster
 * @property {boolean} hasGroupConflicts
 * @property {boolean} isPermissionGranted
 * @property {string} persistentBucket
 * @property {Object} templateFamilyMaster
 * @property {() => string} updateTemplateModalAdsText
 * @property {string} uploadAction
 * @property {Object} uploadConfig
 * @property {Object} uploadConfigObject
 * @property {Object} uploadType
 * @property {Types.TemplateType | null} templateType
 * @property {boolean} userHasScope
 * METHODS
 * @property {createPlainlyProject} createPlainlyProject
 * @property {createTemplate} createTemplate
 * @property {updateTemplate} updateTemplate
 * @property {updateTemplateFamily} updateTemplateFamily
 * @property {updateResize} updateResize
 * @property {onTemplateUploadError} onTemplateUploadError
 * @property {onTemplateUploadSuccess} onTemplateUploadSuccess
 * @property {remoteClick} remoteClick
 * @property {showUploadPercentage} showUploadPercentage
 * @property {toggleSelection} toggleSelection
 * @property {updatePostData} updatePostData
 * @property {updateTemplatePrompt} updateTemplatePrompt
 * @property {updateTemplatePromptCancel} updateTemplatePromptCancel
 * @property {updateTemplatePromptOk} updateTemplatePromptOk
 * CREATED
 * @property {MasterTemplatesService} masterTemplateService
 * OTHER
 * @property {Object} $apollo
 * @property {Object} $auth
 * @property {Object} $store
 * @property {Object} $snackbar
 * @property {Object} $emit
 * @property {Object} $refs
 */

export default {
    name: "MasterTemplateUploadButton",
    components: { FamilyUpdateProgressRow },
    props: {
        masterTemplateId: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            isAeUploading: false,
            isLoading: false,
            editableGroupConflicts: { added: [], removed: [] },
            familyMembersToUpdate: [],
            familyMembersUpdated: {},
            familyUpdateInProgress: false,
            templateFamily: [],
            templateFamilyById: {},
            templateUsedPromptResolve: () => {},
            templateUsedToProduceAds: {},
            UploadType,
            fileTypeMap: {},
            formData: {},
            showUpdateTemplatePrompt: false,
            templateUsedInCampaigns: [],
            templateUsedProducedAds: undefined
        };
    },

    computed: {
        acceptedFileMimes() {
            return [ZIP_MIME_TYPE, ...PSD_MIME_TYPES].join(", ");
        },

        allMembersSelected() {
            return this.familyMembersToUpdate.length === this.templateFamily.length;
        },

        /** @this MasterTemplateUploadButton */
        canManageMasterTemplates() {
            return this.$auth.userCan(this.$auth.Actions.CanManageMasterTemplates, { clientId: this.clientId }, false);
        },

        clientId() {
            return this.$store.state.route.params.clientId;
        },

        forceSelectFamilyMaster() {
            return true;
        },

        hasGroupConflicts() {
            return this.editableGroupConflicts.added.length || this.editableGroupConflicts.removed.length;
        },

        /**
         * @this MasterTemplateUploadButton
         */
        isPermissionGranted() {
            return this.canManageMasterTemplates;
        },

        /**
         * @this MasterTemplateUploadButton
         */
        persistentBucket() {
            // Compute the persiste bucket name in this way for simplicity, based on the temporary bucket name
            // so we avoid the complexity of fetching it from the BE, at least for now. It's harmless and the chances
            // to break something in the feature are linked to the probability to rename the buckets, so extremely low.
            return this.uploadConfig.action
                .split("//")[1]
                .split(".s3-eu-west-1.amazonaws.com")[0]
                .replace("temporary", "persistent");
        },

        templateFamilyMaster() {
            return this.templateFamily.find(({ parentId }) => parentId === null);
        },

        updateTemplateModalAdsText() {
            const { published = 0, unpublished = 0 } = this.templateUsedProducedAds;
            const xAds = published + unpublished === 1 ? " ad" : " ads";
            const publishedText = published > 0 ? `${published} published` : "";
            const unpublishedText = unpublished > 0 ? `${unpublished} unpublished` : "";
            const adsText = [publishedText, unpublishedText].filter(text => text).join(" and ");

            return `This action will affect ${adsText} ${xAds}.`;
        },

        /**
         * @this MasterTemplateUploadButton
         */
        uploadAction() {
            return (this.uploadConfig && this.uploadConfig.action) || "";
        },

        /**
         * @this MasterTemplateUploadButton
         */
        uploadConfig() {
            return this.uploadConfigObject.config;
        },

        uploadConfigObject() {
            return this.$store.state.masterTemplateLibrary.uploadConfig;
        },

        uploadType() {
            return this.masterTemplateId ? UploadType.Update : UploadType.Create;
        },

        /**
         * @returns {Types.TemplateType | null}
         */
        templateType() {
            return this.masterTemplateId
                ? this.$store.state.masterTemplateLibrary.masterTemplates[this.masterTemplateId].adType
                : null;
        },

        /**
         * @this MasterTemplateUploadButton
         */
        userHasScope() {
            return this.$auth.hasScope({ clientId: this.clientId }, false);
        }
    },

    watch: {
        /** @this MasterTemplateUploadButton */
        templateFamilyMaster(val) {
            if (val && val._id && !this.familyMembersToUpdate.includes(val._id)) {
                this.familyMembersToUpdate.push(this.templateFamilyMaster._id);
            }
        }
    },

    /** @this MasterTemplateUploadButton */
    created() {
        /* Trigger the refetching of the upload config in case it has expired. The skip() check
           in the query definition will stop it from being executed if its not needed. */
        this.$apollo.queries.masterTemplateUploadConfig.refetch();
        this.$apollo.queries.afterEffectsApiInfo.refetch();

        this.masterTemplateService = new MasterTemplates(this.$apollo, this.clientId);
    },

    methods: {
        /**
         * @callback createPlainlyProject
         * @param {string} masterTemplateId
         * @param {string} key
         * @returns {Promise<void>}
         *
         * @this MasterTemplateUploadButton
         * @type {createPlainlyProject}
         */
        async createPlainlyProject(masterTemplateId, key) {
            try {
                this.isAeUploading = true;

                const { plainlyProjectId, compositions } = await createAEProject({
                    clientId: this.clientId,
                    persistentBucket: this.persistentBucket,
                    aeApiInfo: {
                        baseUrl: this.afterEffectsApiInfo.url,
                        apiKey: this.afterEffectsApiInfo.apiKey
                    },
                    key
                });

                await this.masterTemplateService.completeAE(masterTemplateId, plainlyProjectId, compositions);

                this.isAeUploading = false;
                this.$snackbar.success("Template project created.");
            } catch (e) {
                this.isAeUploading = false;
                this.$snackbar.error("Failed to create plainly project.", e.message);
                this.$emit("error", e);
            }
        },

        /**
         * @callback createTemplate
         * @param {string} zipUrl
         * @returns {Promise<void>}
         *
         * @this MasterTemplateUploadButton
         * @type {createTemplate}
         */
        async createTemplate(zipUrl) {
            try {
                const response = await this.masterTemplateService.add(zipUrl);
                const mt = response.data.addMasterTemplate;

                if (mt.adType === TemplateType.AE) {
                    this.$snackbar.success("File uploaded, creating AE project...");
                    this.createPlainlyProject(mt._id, mt.persistentKey);
                } else {
                    this.$emit("added");
                    this.$snackbar.templateAdded();
                }
            } catch (e) {
                this.$snackbar.error("Upload Failed", e.message, {
                    duration: 0,
                    closable: true
                });
            }
            bus.$emit("percentLoadEvent", 100);
        },

        /**
         * @callback updateTemplate
         * @param {string} zipUrl
         * @returns {Promise<void>}
         *
         * @this MasterTemplateUploadButton
         * @type {updateTemplate}
         */
        async updateTemplate(zipUrl) {
            this.familyMembersToUpdate = [this.masterTemplateId];

            try {
                const { data } = await this.masterTemplateService.update(
                    this.masterTemplateId,
                    zipUrl,
                    this.templateType,
                    false
                );

                const { updateMasterTemplate: { usedInCampaigns = false } = {} } = data;

                if (usedInCampaigns) {
                    const doUpdate = await this.updateTemplatePrompt(data);
                    if (doUpdate) {
                        this.$emit("updateStarted");
                        if (this.familyMembersToUpdate.length > 1) {
                            await this.updateTemplateFamily(zipUrl);
                            return;
                        }
                        // hide the modal
                        this.showUpdateTemplatePrompt = false;
                        await this.masterTemplateService.update(this.masterTemplateId, zipUrl, this.templateType, true);
                    } else {
                        bus.$emit("percentLoadEvent", 100);
                        return;
                    }
                }

                this.$emit("updated");
                this.$snackbar.success("Template Updated");
            } catch (e) {
                this.$snackbar.error("Upload Failed", e.message, {
                    duration: 0,
                    closable: true
                });
            }

            bus.$emit("percentLoadEvent", 100);
        },

        /**
         * @callback updateTemplateFamily
         * @param {string} zipUrl
         * @returns {Promise<void>}
         *
         * @this MasterTemplateUploadButton
         * @type {updateTemplateFamily}
         */
        async updateTemplateFamily(zipUrl) {
            this.familyMembersUpdated = {};
            this.familyUpdateInProgress = true;

            // first we need to update parent to use it as a resize source later
            try {
                await this.masterTemplateService.update(this.templateFamilyMaster._id, zipUrl, this.templateType, true);
                this.familyMembersUpdated = {
                    ...this.familyMembersUpdated,
                    [this.templateFamilyMaster._id]: true
                };
            } catch (e) {
                this.familyMembersUpdated = {
                    ...this.familyMembersUpdated,
                    [this.templateFamilyMaster._id]: false
                };
                this.$snackbar.error("Failed to update family's parent template.", e.message);
                return;
            }

            await Promise.all(
                this.familyMembersToUpdate
                    .filter(id => id !== this.templateFamilyMaster._id)
                    .map(id => this.updateResize(id, this.templateFamilyMaster._id))
            );

            this.$emit("familyUpdated", this.familyMembersToUpdate);
            this.$snackbar.success("Template family Updated");
            this.familyUpdateInProgress = false;
            this.showUpdateTemplatePrompt = false;
        },

        /**
         * @callback updateResize
         * @param {string} templateId
         * @param {string} parentId
         * @returns {Promise<void>}
         *
         * @this MasterTemplateUploadButton
         * @type {updateResize}
         */
        async updateResize(templateId, parentId) {
            try {
                const result = await this.masterTemplateService.updateResize(templateId, parentId);
                this.familyMembersUpdated = {
                    ...this.familyMembersUpdated,
                    [templateId]: true
                };
                return result;
            } catch (err) {
                this.familyMembersUpdated = {
                    ...this.familyMembersUpdated,
                    [templateId]: false
                };
                console.log(err);
            }
            return Promise.resolve();
        },

        /**
         * @callback onTemplateUploadError
         * @param {Error} err
         * @returns {void}
         *
         * @this MasterTemplateUploadButton
         * @type {onTemplateUploadError}
         */
        onTemplateUploadError(err) {
            bus.$emit("apolloErrorEvent", err);
        },

        /**
         * @callback onTemplateUploadSuccess
         * @param {Object} response
         * @param {File} file
         * @returns {void}
         *
         * @this MasterTemplateUploadButton
         * @type {onTemplateUploadSuccess}
         */
        onTemplateUploadSuccess(response, file) {
            const zipUrl = `${this.uploadConfig.filePrefix}/${file.name}`;

            if (this.uploadType === this.UploadType.Create) {
                this.createTemplate(zipUrl);
            } else if (this.uploadType === this.UploadType.Update) {
                this.updateTemplate(zipUrl);
            }
        },

        /**
         * @callback remoteClick
         * @returns {void}
         *
         * @this MasterTemplateUploadButton
         * @type {remoteClick}
         */
        remoteClick() {
            this.updatePostData();
            this.$refs.remote.$el.click();
        },

        /**
         * @callback showUploadPercentage
         * @param {Object} e
         * @returns {void}
         *
         * @this MasterTemplateUploadButton
         * @type {showUploadPercentage}
         */
        showUploadPercentage(e) {
            bus.$emit("percentLoadEvent", e.percent);
        },

        /**
         * @callback toggleSelection
         * @param {boolean} val
         * @returns {void}
         *
         * @this MasterTemplateUploadButton
         * @type {toggleSelection}
         */
        toggleSelection(val) {
            if (val) {
                this.familyMembersToUpdate = this.templateFamily.map(({ _id }) => _id);
            } else {
                this.familyMembersToUpdate = [this.templateFamilyMaster._id];
            }
        },

        /**
         * @callback updatePostData
         * @returns {void}
         *
         * @this MasterTemplateUploadButton
         * @type {updatePostData}
         */
        updatePostData() {
            // Required to manually add in the Key for some reason.
            if (!this.uploadConfig || !this.uploadConfig.postData) {
                return;
            }

            const data = JSON.parse(this.uploadConfig.postData);
            data.key = `${this.uploadConfig.filePrefix}/\${filename}`;
            this.formData = data;
        },

        /**
         * @callback updateTemplatePrompt
         * @param {Object} data
         * @returns {Promise<boolean>}
         *
         * @this MasterTemplateUploadButton
         * @type {updateTemplatePrompt}
         */
        updateTemplatePrompt(data) {
            const {
                updateMasterTemplate: {
                    usedInCampaigns = false,
                    producedAds,
                    editableGroupConflicts,
                    templateFamily
                } = {}
            } = data;
            this.templateUsedInCampaigns = usedInCampaigns;
            this.templateUsedProducedAds = producedAds;
            this.editableGroupConflicts = editableGroupConflicts;
            this.familyUpdateInProgress = false;
            this.templateFamily = templateFamily || [];
            this.templateFamilyById = this.templateFamily.reduce((acc, curr) => {
                acc[curr._id] = curr;
                return acc;
            }, {});
            this.showUpdateTemplatePrompt = true;

            return new Promise(resolve => {
                this.templateUsedPromptResolve = result => {
                    // reset back to base state
                    this.templateUsedPromptResolve = () => {};
                    this.templateUsedInCampaigns = [];
                    this.templateUsedProducedAds = {};
                    // resolve the promise with the passed in result
                    resolve(result);
                };
            });
        },

        /**
         * @callback updateTemplatePromptCancel
         * @returns {void}
         *
         * @this MasterTemplateUploadButton
         * @type {updateTemplatePromptCancel}
         */
        updateTemplatePromptCancel() {
            this.showUpdateTemplatePrompt = false;
            this.templateUsedPromptResolve(false);
        },

        /**
         * @callback updateTemplatePromptOk
         * @returns {void}
         *
         * @this MasterTemplateUploadButton
         * @type {updateTemplatePromptOk}
         */
        updateTemplatePromptOk() {
            if (this.familyMembersToUpdate.length > 1) {
                this.familyUpdateInProgress = true;
            }
            this.templateUsedPromptResolve(true);
        }
    },

    apollo: {
        afterEffectsApiInfo: {
            query: getAfterEffectsApiInfoQuery,

            result({ data }) {
                this.$store.dispatch(MasterTemplateLibraryAction.SetAfterEffectsApiInfo, data.afterEffectsApiInfo);
            }
        },

        masterTemplateUploadConfig: {
            query: clientMasterTemplatesUploadConfigQuery,

            /**
             * @this MasterTemplateUploadButton
             */
            variables() {
                return {
                    clientId: this.clientId
                };
            },

            /**
             * @this MasterTemplateUploadButton
             */
            skip() {
                if (!this.isPermissionGranted || !this.clientId) {
                    return true;
                }

                if (this.uploadConfigObject && this.uploadConfigObject.clientId === this.clientId) {
                    // Check if the one we have is expired or not
                    if (uploadConfigExpired(this.uploadConfigObject.config)) {
                        return false;
                    }

                    this.isLoading = false;

                    return true;
                }

                return false;
            },

            manual: true,

            /**
             * @this MasterTemplateUploadButton
             */
            result({ data }) {
                this.$store.dispatch(MasterTemplateLibraryAction.SetUploadConfig, {
                    clientId: this.clientId,
                    config: data.masterTemplateUploadConfig
                });

                this.isLoading = false;
            },

            error(e) {
                bus.$emit("apolloErrorEvent", e);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
@import "@/../sass/_variables.scss";
.template-upload-modal {
    p.small-margin {
        margin-bottom: $spacing-small;
    }
    &__button {
        margin-left: $spacing-small;
    }
    p.template-upload-modal__alert-text {
        font-size: $font-size-small;
    }

    &__label {
        font-size: $font-size-small;
    }

    ul {
        margin-bottom: $spacing-small;
    }

    li {
        font-size: $font-size-small;
        margin-left: $spacing-large;
    }

    &__removed {
        margin-top: $spacing-small;
    }
}
</style>
