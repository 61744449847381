@@ -0,0 +1,36 @@
<template>
    <hox-modal v-if="isVisible" @close="close">
        <template #header>Detailed Publish Status</template>
        <div>
            {{ publishDetails }}
        </div>
    </hox-modal>
</template>
<script>
export default {
    name: "PublishingDetailsModal",
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        publishDetails: {
            type: String,
            default: ""
        }
    },

    methods: {
        close() {
            this.$emit("close");
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
</style>
