<template>
    <insight-preview-wrapper
        v-if="advancedSearch"
        :filters="matchingContentFilters"
        :next-page-token="nextPageToken"
        :request-options="requestOptions"
        :type="InsightType.AttributeValuesAnalysis"
        :response-path="responsePath"
        @response="onData"
        @error="onError"
        @loadingNext="onLoadingNext"
        @next="onNextData"
    >
        <template #name>{{ title }}</template>
        <template #headline>{{ headerText }}</template>

        <div v-if="attributeNames.length" class="attribute-values-analysis">
            <insights-creative-preview-carousel
                v-for="attributeName in attributeNames"
                :key="attributeName"
                class="creative-preview-insights"
                :bounding-boxes="true"
                :bucket-config="getBucketConfig(attributeName)"
                :init-creatives="getDeliverables(attributeName)"
                :request-options="requestOptions"
                :seek-to-attribute="true"
                :stats="getStats(attributeName)"
            >
                <template #name>
                    <span :title="attributeName">{{ attributeName }}</span>
                </template>
            </insights-creative-preview-carousel>
            <div class="attribute-values-analysis__load-more">
                <hox-loading-spinner v-if="isLoadingNext" :size="40" />
                <Button v-if="hasNext && !isLoadingNext" :disabled="isLoadingNext" @click="onLoadNext">
                    Load More
                </Button>
            </div>
        </div>

        <template #chartPreview>
            <insights-chart-preview :data="attributes" :title="title" />
        </template>
    </insight-preview-wrapper>
</template>
<script>
import { InsightType, InsightTypeToServiceName } from "@/components/CreativeIntelligence/constants";
import InsightPreviewWrapper from "@/components/CreativeIntelligence/InsightPreviews/InsightPreviewWrapper";
import { CreativeInsightsGetters } from "@/store/modules/creativeInsights";
import InsightsChartPreview from "@/components/CreativeIntelligence/InsightPreviews/InsightsChartPreview";
import { CreativeInsightsContentFilter } from "@/enums/creativeInteligence";
import InsightsCreativePreviewCarousel from "@/components/CreativeIntelligence/InsightPreviews/InsightsCreativePreviewCarousel";
import { deliverablesFromVideoGroups, getMetricStat } from "@/utils/creativeInteligence";

const itemsPerPage = 10;

export default {
    name: "AttributeValuesAnalysis",
    components: {
        InsightsCreativePreviewCarousel,
        InsightsChartPreview,
        InsightPreviewWrapper
    },

    data() {
        return {
            attributes: null,
            attributeNames: [],
            isLoadingNext: false,
            nextPageToken: "",
            pagination: {},
            responsePath: "attributes",
            title: "Usage"
        };
    },

    computed: {
        advancedSearch() {
            return this.$store.getters[CreativeInsightsGetters.AdvancedSearchValue];
        },

        breakdownText() {
            // todo temp
            return "";
            /* if (this.$store.state.creativeInsights.selectedBreakdown === Breakdown.NoBreakdowns) {
                return "";
            }

            return ` on ${getBreakdownLabel(this.$store.state.creativeInsights.selectedBreakdown)}`; */
        },

        displayTemplates() {
            if (!this.selectedTagIds.length) {
                return null;
            }

            return this.tagsById[this.selectedTagIds[0]].displayTemplates;
        },

        hasNext() {
            return this.pagination.nextPageToken;
        },

        showsDiversityTemplate() {
            return this.displayTemplates && this.displayTemplates.includes("diversity");
        },

        headerText() {
            if (this.showsDiversityTemplate) {
                return "Here's a summary of the automated inclusivity & representation audit";
            }

            if (this.attributeNames[0]) {
                return `"${this.attributeNames[0]}" is performing best${this.breakdownText}`;
            }

            return "";
        },

        kpiMetricName() {
            return this.$store.getters[CreativeInsightsGetters.KpiMetricName];
        },

        kpiMetricStatName() {
            return getMetricStat(this.kpiMetricName);
        },

        requestOptions() {
            return {
                topDeliverablesNumber: 1,
                topUniqueCreativesNumber: 1,
                topAttributesValuesNumber: itemsPerPage
            };
        },

        resultSource() {
            return this.selectedTagIds.length ? `"${this.tagsById[this.selectedTagIds[0]].name}"` : "The search query";
        },

        selectedTagIds() {
            return this.$store.state.ciTags.selectedTagIds;
        },

        tagsById() {
            return this.$store.state.ciTags.tagsById;
        }
    },

    created() {
        this.matchingContentFilters = [CreativeInsightsContentFilter.Content];
        this.InsightType = InsightType;
    },

    methods: {
        getAttributesFromData(attributes) {
            return Object.keys(attributes).reduce((acc, group) => {
                acc[group] = {
                    ...attributes[group]
                };
                if (!attributes[group].deliverables && attributes[group].uniqueCreativesGroups) {
                    acc[group].deliverables = deliverablesFromVideoGroups(attributes[group].uniqueCreativesGroups);
                }

                return acc;
            }, {});
        },

        getBucketConfig(attributeName) {
            return {
                ...this.attributes[attributeName].pagination,
                bucketFilters: {
                    value: attributeName,
                    type: InsightTypeToServiceName[InsightType.AttributeValuesAnalysis]
                }
            };
        },

        getDeliverables(attributeName) {
            if (!this.attributes || !this.attributes[attributeName]) {
                return [];
            }

            return (
                this.attributes[attributeName].uniqueCreativesGroups.map(({ deliverables }) => deliverables[0]) || []
            );
        },

        getStats(attributeName) {
            return this.attributes[attributeName].summaryStats;
        },

        onData(response) {
            this.pagination = response.pagination;
            this.attributes = this.getAttributesFromData(response.data.attributes);
            this.attributeNames = Object.keys(this.attributes);
        },

        onError(error) {
            this.attributes = null;
            this.attributeNames = [];
            console.error(error);
        },

        onLoadingNext(loading) {
            this.isLoadingNext = loading;
            this.$emit("loadingNext", loading);
        },

        onLoadNext() {
            this.nextPageToken = this.pagination.nextPageToken;
        },

        onNextData(response) {
            if (response) {
                const newAttributes = this.getAttributesFromData(response.data.attributes);
                this.pagination = response.pagination;
                this.attributes = {
                    ...this.attributes,
                    ...newAttributes
                };
                this.attributeNames.push(...Object.keys(newAttributes));
            }
        }
    }
};
</script>
<style lang="scss">
@import "../../../../sass/_variables.scss";

.attribute-values-analysis {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;

    &__load-more {
        flex: 1 0 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .creative-carousel-dots {
        bottom: 57px;
    }
}
</style>
