<template>
    <div class="empty-message">
        <div class="empty-message__container" :style="containerStyle">
            <div class="empty-message__wrapper">
                <h3 v-if="hasTitle" class="empty-message__title">
                    <slot name="title" />
                </h3>
                <slot name="content" />
                <slot name="actionItems" />
            </div>
            <div class="empty-message__image-wrapper">
                <component :is="imageComponent" />
            </div>
        </div>
    </div>
</template>

<script>
import AllClear from "@/components/AllClear";
import NoContentSvg from "@/assets/NoContent.svg";
import NoContentIconSvg from "@/assets/NoContentIcon.svg";
import BugSvg from "@/assets/BugIllustration.svg";
import ChooseSvg from "@/assets/Choose.svg";

export const ImageType = {
    AllClear: "all-clear",
    Error: "bug",
    NoContent: "no-content",
    NoContentIcon: "no-content-icon",
    Choose: "choose"
};

export default {
    props: {
        imageWidthPixels: {
            default: 500,
            type: Number
        },
        type: {
            default: ImageType.NoContent,
            validator(value) {
                return Object.values(ImageType).includes(value);
            }
        },
        displayImageFirst: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            hasTitle: undefined
        };
    },
    computed: {
        containerStyle() {
            const style = {};
            if (this.imageWidthPixels) {
                style.width = `${this.imageWidthPixels}px`;
            }
            if (this.displayImageFirst) {
                style.flexDirection = "column-reverse";
            }
            return style;
        },
        imageComponent() {
            switch (this.type) {
                case ImageType.AllClear:
                    return AllClear;
                case ImageType.Choose:
                    return ChooseSvg;
                case ImageType.Error:
                    return BugSvg;
                case ImageType.NoContentIcon:
                    return NoContentIconSvg;
                default:
                    return NoContentSvg;
            }
        }
    },
    mounted() {
        this.hasTitle = this.$slots.title !== undefined;
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.empty-message {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 0 $spacing;
    width: 100%;
}

.empty-message__container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.empty-message__wrapper {
    max-width: 100%;

    > p {
        font-size: $font-size-large;
        margin: 0 0 $spacing;
    }
}

.empty-message__image-wrapper svg {
    max-width: 300px;
}

.empty-message__title {
    font-size: $font-size-largest;
    font-weight: normal;
    margin: 0 0 $spacing-small;
    text-align: center;
}
</style>
