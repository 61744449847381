import { Breakdown } from "@/enums/creativeInteligence";
import { numberToShortKM } from "@/utils/index";

export const getBreakdownLabel = breakdown => {
    if (!breakdown) {
        return "";
    }

    if (breakdown.breakdownId === Breakdown.NoBreakdowns) {
        return "All data";
    }

    return `${breakdown.parts.publisherPlatform} ${breakdown.parts.platformPosition}`;
};

export const isRateMetric = metric => metric.endsWith("_rate");

export const getMetricStat = metric => (isRateMetric(metric) ? "avg" : "sum");

export const kpiDisplayFormatter = value => {
    return value.toFixed(3);
};

export const confidenceDisplayFormatter = (confidenceMetricStats, stat) => {
    const statValue = confidenceMetricStats[stat];
    if (statValue < 1000) {
        return statValue.toFixed(2);
    }
    if (statValue < 1) {
        return statValue.toFixed(3);
    }
    return numberToShortKM(statValue);
};

export const metricValueFormatter = statValue => {
    if (statValue < 1) {
        return statValue.toFixed(3);
    }
    return numberToShortKM(statValue).replace("B", "000000000").replace("M", "000000").replace("K", "000");
};

export const utilisationToValue = utilisation => (utilisation * 100).toFixed(0);

export const utilisationDisplayFormatter = utilisation => {
    const value = utilisationToValue(utilisation);

    return `${value}%`;
};

// take a single example from each creativeVideoGroup
export const deliverablesFromVideoGroups = uniqueCreativesGroups => {
    return uniqueCreativesGroups.reduce((acc, { deliverables }) => {
        if (deliverables.length) {
            acc.push(deliverables[0]);
        }

        return acc;
    }, []);
};
