<template>
    <component :is="componentName" :tag="tag" :dashboard-content="dashboardContent" />
</template>
<script>
import DefaultInsightsTagComponent from "@/views/CreativeInteligence/DefaultInsightsDashboardTagComponent";

const customTagComponents = {};

export default {
    name: "InsightsDashboardTagComponent",
    components: { DefaultInsightsTagComponent },
    props: {
        dashboardContent: {
            type: String
        },

        tagId: {
            type: String
        }
    },

    computed: {
        componentName() {
            if (customTagComponents[this.tag.name]) {
                return customTagComponents[this.tag.name];
            }

            return "default-insights-tag-component";
        },

        tag() {
            return this.$store.state.ciTags.tagsById[this.tagId];
        }
    },

    methods: {}
};
</script>
