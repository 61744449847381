<template>
    <div class="asset-preview">
        <Spin v-if="(isLoadingImage && assetUrl) || showLoadingState" fix />
        <img
            v-if="!isVideo"
            v-show="showPreviewImage"
            class="asset-preview__image"
            :src="assetUrl"
            @load="assetLoaded"
            @error="assetError"
        />

        <video
            v-if="assetUrl && isVideo"
            v-show="showPreviewImage"
            :key="assetUrl"
            class="asset-preview__image asset-preview__video"
            controls
            autoplay
            loop
            muted
            @loadeddata="assetLoaded"
            @error="assetError"
        >
            <source :src="assetUrl" :type="videoContentType" />
        </video>

        <div v-if="hasErrorLoadingImage && assetUrl" class="asset-preview__alert-container">
            <slot name="errorMessage">
                <hox-alert type="danger">
                    <template #title></template>
                    <template #content>
                        <p>There was an error when trying to display the asset.</p>
                    </template>
                </hox-alert>
            </slot>
        </div>
    </div>
</template>

<script>
import HoxAlert from "@/components/common/Alert";

export const videoExtensionContentType = {
    ".mp4": "video/mp4",
    ".m4a": "video/mp4",
    ".m4p": "video/mp4",
    ".m4b": "video/mp4",
    ".m4r": "video/mp4",
    ".m4v": "video/mp4",
    ".webm": "video/webm",
    ".ogg": "video/ogg",
    ".ogv": "video/ogg",
    ".oga": "video/ogg",
    ".ogx": "video/ogg",
    ".ogm": "video/ogg",
    ".spx": "video/ogg",
    ".opus": "video/ogg",
    ".mov": "video/quicktime"
};

export const videoExtensions = Object.keys(videoExtensionContentType);

export default {
    components: {
        HoxAlert
    },
    props: {
        assetUrl: {
            type: String
        },

        showLoadingState: {
            type: Boolean
        }
    },
    data() {
        return {
            hasErrorLoadingImage: false,
            isLoadingImage: true,
            showPreviewImage: false
        };
    },
    computed: {
        isVideo() {
            return this.assetUrl && videoExtensions.some(ext => this.assetUrl.includes(ext));
        },

        videoContentType() {
            if (!this.assetUrl) {
                return "";
            }

            const ext = videoExtensions.find(ex => this.assetUrl.includes(ex));
            return videoExtensionContentType[ext] || "video/mp4";
        }
    },

    watch: {
        assetUrl() {
            this.isLoadingImage = true;
        }
    },
    methods: {
        assetError() {
            this.isLoadingImage = false;
            this.hasErrorLoadingImage = true;
            this.showPreviewImage = false;
            this.$emit("error");
        },
        assetLoaded() {
            this.isLoadingImage = false;
            this.hasErrorLoadingImage = false;
            this.showPreviewImage = true;
            this.$emit("load");
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_mixins.scss";
@import "@/../sass/_variables.scss";

.asset-preview {
    @include make-checked-background($grey3, $spacing-small);
    align-items: center;
    border: 1px solid $grey3;
    display: flex;
    flex: 1;
    height: calc(100% - 32px);
    justify-content: center;
    position: relative;
    width: 100%;
}

.asset-preview__alert-container {
    max-width: 578px;
    padding: $spacing;
    width: 100%;
}

.asset-preview__image {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.asset-preview__video {
    max-height: 100%;
    object-fit: scale-down;
}
</style>
