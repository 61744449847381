<template>
    <div class="creative-insights-chart data-explorer scatter-chart">
        <div ref="boundaryElement" class="creative-insights-chart__chart-wrapper">
            <canvas ref="chart" class="horizontal-bar-chart__chart" :style="canvasStyle" />
            <div ref="popperAnchor" class="creative-insights-chart__tooltip" :style="tooltipStyle"></div>
        </div>
        <chart-tooltip ref="tooltip" :point-labels="tooltipLines" :image-urls="tooltipImageUrls" />
        <div>
            <labelled-switch v-model="useLogScale" class="data-explorer__scale">Log scale</labelled-switch>
        </div>
    </div>
</template>

<script>
import {
    Chart,
    PointElement,
    BarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    Legend,
    Title,
    Tooltip,
    SubTitle,
    LogarithmicScale
} from "chart.js";

import scatterChartMixin from "@/components/CreativeIntelligence/ScatterChart.mixin";
import { ChartColors } from "@/enums/creativeInteligence";
import { getMetricStat } from "@/utils/creativeInteligence";
import chartTooltipMixin from "@/mixins/chartTooltipMixin";
import ChartTooltip from "@/components/CreativeIntelligence/ChartTooltip";
import { ChartDisplayValue, drawQuadrants } from "@/utils/chart";
import LabelledSwitch from "@/components/Campaign/LabelledSwitch";

Chart.register(
    BarController,
    CategoryScale,
    Legend,
    LinearScale,
    LogarithmicScale,
    PointElement,
    ScatterController,
    SubTitle,
    Title,
    Tooltip
);

export default {
    name: "DataExplorerScatterChart",
    components: { ChartTooltip, LabelledSwitch },
    mixins: [scatterChartMixin, chartTooltipMixin],

    data() {
        return {
            chart: undefined,
            useLogScale: true
        };
    },

    computed: {
        chartData() {
            return this.data;
        },

        confidenceMetricOverallMax() {
            const stat = getMetricStat(this.confidenceMetricsName);

            return Math.max(...this.flatDatasets.map(point => point.summaryStats.confidenceMetric[stat]));
        },

        dataString() {
            return JSON.stringify(this.data);
        },

        flatDatasets() {
            return Object.keys(this.data).reduce((acc, tagId) => {
                return acc.concat(...Object.values(this.data[tagId].data));
            }, []);
        },

        datasets() {
            return Object.keys(this.data).map((tagId, index) => {
                const { tag, data, initial } = this.data[tagId];
                const { values, labels, creatives } = this.generateScatterChartData(data);

                const backgroundColor = ChartColors[index % ChartColors.length];
                const hoverBackgroundColor = `${ChartColors[index % ChartColors.length]}AA`;

                return {
                    tagId,
                    label: tag.name,
                    labels,
                    creatives,
                    data: values,
                    backgroundColor,
                    hoverBackgroundColor,
                    ...(initial && { hidden: true })
                };
            });
        },

        confidenceMetricMax() {
            return undefined;
        },

        kpiMetricOverallMax() {
            return undefined;
        },

        scales() {
            return {
                y: {
                    beginAtZero: true,
                    min: this.kpiMetricMin,
                    max: this.kpiMetricMax,
                    ticks: {
                        display: true,
                        callback: value => {
                            if (this.displayValue === ChartDisplayValue.Utilisation) {
                                return value > 1 ? "" : `${Math.round(value * 100)}%`;
                            }

                            return value;
                        },
                        ...(this.useLogScale && { maxTicksLimit: 8 })
                    },
                    grid: {
                        drawOnChartArea: false // only want the grid lines for one axis to show up
                    },
                    ...(this.useLogScale && { type: "logarithmic" })
                },
                x: {
                    beginAtZero: true,
                    min: this.confidenceMetricMin,
                    max: this.confidenceMetricMax,
                    ticks: {
                        display: true,
                        callback: value => {
                            return this.getTickString(value);
                        },
                        ...(this.useLogScale && { maxTicksLimit: 8 })
                    },
                    grid: {
                        drawOnChartArea: false // only want the grid lines for one axis to show up
                    },
                    ...(this.useLogScale && { type: "logarithmic" })
                }
            };
        },

        plugins() {
            const beforeDraw = chart => {
                // todo: don't add quadrants for initial state
                if (this.datasets.every(({ initial }) => initial)) {
                    return;
                }
                drawQuadrants(chart, +this.kpiMetricValue, +this.confidenceMetricValue);
            };
            return [
                {
                    beforeDraw: beforeDraw.bind(this)
                }
            ];
        }
    },

    watch: {
        dataString: {
            handler(val, oldVal) {
                const newValue = JSON.parse(val);
                const oldValue = JSON.parse(oldVal);
                const tagIds = Object.keys(oldValue);
                const updatedTagIndex = tagIds.findIndex(tagId => !newValue[tagId].initial && oldValue[tagId].initial);
                const datasetToAdd = this.datasets.find(({ tagId }) => {
                    return tagId === tagIds[updatedTagIndex];
                });
                this.chart.data.datasets[updatedTagIndex] = datasetToAdd;
                this.chart.update();
            }
        },

        useLogScale() {
            this.chart.options.scales = this.scales;
            this.chart.update();
        },

        data() {
            this.chart.data.datasets = this.datasets;
            this.chart.options.scales = this.scales;
            this.chart.update();
        }
    },

    mounted() {
        this.initChart();
    },

    methods: {
        initChart() {
            Chart.defaults.elements.point.radius = 6;
            Chart.defaults.elements.point.hoverRadius = 7;

            this.chart = new Chart(this.$refs.chart, {
                data: {
                    labels: this.labels,
                    datasets: this.datasets
                },

                options: {
                    scales: this.scales,
                    plugins: {
                        legend: {
                            display: true,
                            align: "center",
                            position: "right"
                        },
                        title: {
                            display: true,
                            align: "start",
                            text: this.title
                        },
                        tooltip: {
                            // Disable the on-canvas tooltip
                            enabled: false,
                            external: this.customTooltip.bind(this),
                            callbacks: {
                                label: ({ dataset, dataIndex }) => {
                                    return dataset.labels[dataIndex];
                                }
                            }
                        }
                    },
                    animation: {
                        duration: 1000
                    }
                },
                plugins: this.plugins,
                type: "scatter"
            });
        },

        setTooltipText(tooltipModel) {
            let textLines = [];
            if (tooltipModel.dataPoints) {
                // todo: clean up
                const bodyLines = tooltipModel.dataPoints.reduce((acc, dataPoint) => {
                    const { tagId } = dataPoint.dataset;
                    const label = dataPoint.dataset.labels[dataPoint.dataIndex];

                    const stats = this.data[tagId].data[label].summaryStats;
                    const deliverableAttributes = this.data[tagId].data[label].deliverables[0]?.attributes || [];
                    const attr = deliverableAttributes.find(({ attribute }) => attribute === label);

                    let attributeConfidence;
                    if (attr) {
                        attributeConfidence = attr.metadata[0].confidence;
                    }

                    acc[label] = {
                        label,
                        stats,
                        attributeConfidence
                    };
                    return acc;
                }, {});

                textLines = Object.values(bodyLines);
            }

            this.tooltipLines = textLines;
        }
    }
};
</script>

<style lang="scss">
.data-explorer {
    &__controls {
        width: 100px;
        float: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: -30px;
    }

    &__scale {
        /*flex: 1;
        width: 100px;*/
    }
}
</style>
