<template>
    <div :class="['multi-input', error && showErrors ? 'multi-input--has-errors' : '']">
        <div v-for="(value, index) of internalValues" :key="index">
            <div
                :class="[
                    'multi-input__value-wrapper',
                    errorsByIndex[index] && showErrors ? 'multi-input__value-wrapper--has-errors' : ''
                ]"
            >
                <Input
                    :ref="index"
                    v-model="internalValues[index]"
                    :placeholder="isLastValue(index) ? newValuePlaceholder : ''"
                    @on-blur="removeValueIAtIndexfEmpty(index)"
                    @on-keydown="keyupHandler($event, index, internalValues[index])"
                />
                <div
                    v-if="internalValues.length > 1"
                    :class="[
                        'multi-input__remove-button-wrapper',
                        isLastValue(index) ? 'multi-input__remove-button-wrapper--invisible' : ''
                    ]"
                >
                    <Button
                        icon="ios-close"
                        shape="circle"
                        size="small"
                        tabindex="-1"
                        @click="removeValueAtIndex(index)"
                    />
                </div>
            </div>
            <div v-if="errorsByIndex[index] && showErrors">
                <p v-for="errorMessage in errorsByIndex[index]" :key="errorMessage" class="multi-input__error-message">
                    {{ errorMessage }}
                </p>
            </div>
        </div>
        <div v-if="error && showErrors">
            <p v-for="errorMessage in error" :key="errorMessage" class="multi-input__error-message">
                {{ errorMessage }}
            </p>
        </div>
    </div>
</template>

<script>
/*
    TODO:
    Try to rework this so that we no longer have internalValues.
      Use :value and @input instead of v-model on the input elements.

    We will likely still need to have an internalValue so that we have an
    empty item at the end. This could likely be a computed value.
*/

const tabKeyCode = 9;

export default {
    props: {
        error: {
            type: Array
        },
        errorsByIndex: {
            type: Object,
            default: () => ({})
        },
        newValuePlaceholder: {
            type: String,
            default: "Type here to add an item"
        },
        showErrors: {
            type: Boolean
        },
        value: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            inputToFocus: null,
            internalValues: [...this.value, undefined]
        };
    },
    watch: {
        internalValues() {
            if (this.internalValues[this.internalValues.length - 1] !== undefined) {
                this.internalValues.push(undefined);
            }
            const externalValues = [...this.internalValues];
            externalValues.pop();
            this.$emit("input", externalValues);
        }
    },
    updated() {
        if (this.inputToFocus !== null) {
            this.$refs[this.inputToFocus][0].focus();
            this.inputToFocus = null;
        }
    },
    methods: {
        isLastValue(index) {
            return this.internalValues.length - 1 === index;
        },
        async keyupHandler(evt, index, currentValue) {
            if (evt.keyCode === tabKeyCode && !evt.shiftKey && !this.isLastValue(index) && !currentValue) {
                /*
                    If we have tabbed with a value that is empty then we need
                    to do a little dance to make the next input be the input
                    that we are focused on.

                    Without doing this we will jump over the next input due to
                    how we are keying the values in the template.
                */
                this.inputToFocus = index;
            }
        },
        removeValueAtIndex(index) {
            this.internalValues.splice(index, 1);
            this.$emit("inputRemoved", index);
        },

        removeValueIAtIndexfEmpty(index) {
            const value = this.internalValues[index];
            if (this.internalValues.length > 1 && (value === null || value === undefined || value === "")) {
                this.removeValueAtIndex(index);
            }
            this.$emit("inputBlur", index);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.multi-input {
    margin: 0 0 $spacing;
    width: 100%;
}

.multi-input--has-errors {
    margin: 0;
}

.multi-input__error-message {
    color: $error-color;
    font-size: $font-size-small;
    margin: 0 0 $spacing-small;
    &:last-child {
        margin: 0 0 $spacing;
    }
}

.multi-input__input-container {
    width: 100%;
}

.multi-input__value-wrapper {
    align-items: center;
    display: flex;
    margin: 0 0 $spacing-small;
}

.multi-input__value-wrapper--has-errors {
    margin: 0 0 $spacing-smallest;
}

.multi-input__remove-button-wrapper {
    margin: 0 0 0 $spacing-small;
}

.multi-input__remove-button-wrapper--invisible {
    opacity: 0;
    pointer-events: none;
}
</style>
