import { RolesGetter } from "@/store/modules/roles";

export default {
    computed: {
        campaignUserIds() {
            return this.$store.state.campaign.normalized.userIds;
        },

        members() {
            if (!Array.isArray(this.campaignUserIds)) {
                return [];
            }

            const campaignUserIds = [...this.campaignUserIds].sort().map(id => {
                const parts = id.split("@");

                return {
                    alias: false,
                    label: id,
                    value: `${parts[0]}`
                };
            });

            return [...campaignUserIds];
        },

        roleIdsToList() {
            return this.$store.getters[RolesGetter.getRolesThatHaveAction](
                this.$auth.Actions.CanReceiveAllNotifications
            ).map(({ _id }) => _id);
        },

        validMentions() {
            return ["@campaign"].concat(this.members.map(({ value }) => `@${value}`));
        }
    }
};
