<template>
    <portal :to="portalTarget">
        <transition name="fade">
            <div
                :class="wrapperClasses"
                tabindex="0"
                @mousedown.self="enableClosingByClickingBackground"
                @mouseup.self="closeByClickingBackground"
                @keyup.esc="close"
            >
                <transition appear :name="scaleTransition">
                    <div :class="classes" :style="styles" @mouseup="disableClosingByClickingBackground">
                        <div
                            v-if="internalShowHeader || internalShowTabs"
                            class="hox-modal__close-icon-container"
                            data-testid="modal-button__close"
                        >
                            <Icon size="44" type="ios-close" @click="close" />
                        </div>
                        <div v-if="internalShowHeader || internalShowTabs" class="hox-modal__header-container">
                            <div class="hox-modal__header">
                                <h3 v-if="internalShowHeader">
                                    <slot name="header" />
                                </h3>
                                <p v-if="description" class="hox-modal__description">
                                    {{ description }}
                                </p>
                            </div>

                            <div v-if="internalShowTabs" class="hox-modal__tabs">
                                <slot name="tabs" />
                            </div>
                        </div>
                        <div class="hox-modal__body">
                            <slot />
                        </div>
                        <div v-if="internalShowFooter" class="hox-modal__footer">
                            <slot name="footer" />
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
    </portal>
</template>

<script>
import bus from "@/bus";

let instanceCounter = 0;
let instanceId = 0;

export default {
    name: "HoxModal",
    props: {
        fullscreen: {
            type: Boolean
        },
        /*
            Ideally this prop would not need to exist, but sometimes due to interactions
            with other components we need to make sure the modal is the top layer.
        */
        isOnTopOfEverything: {
            type: Boolean
        },
        hasScaleTransition: {
            default: true,
            type: Boolean
        },

        maxWidthPx: {
            type: Number
        },

        resizable: {
            type: Boolean
        },

        showFooter: {
            default: undefined,
            type: Boolean
        },
        showTabs: {
            default: undefined,
            type: Boolean
        },
        showHeader: {
            default: undefined,
            type: Boolean
        },

        wrapperClass: {
            type: String,
            default: ""
        },
        description: {
            type: String,
            default: undefined
        }
    },
    data() {
        return {
            /*
                canBeClosedByClickingBackground exists so that we do not close the
                modal if we begin dragging within the modal but release the mouse
                outside of the modal, on the modal background.

                The behaviour without this was particularly annoying when dragging
                an image or attempting to select text in a modal.
            */
            canBeClosedByClickingBackground: false,
            portalTarget: undefined
        };
    },
    computed: {
        classes() {
            const classes = ["hox-modal"];
            if (!this.internalShowHeader && this.internalShowTabs) {
                classes.push("hox-modal--tabs-no-header");
            }
            if (this.fullscreen) {
                classes.push("hox-modal--fullscreen");
            }
            if (this.resizable) {
                classes.push("hox-modal--resizable");
            }
            if (this.maxWidth) {
                classes.push("hox-modal--max-width");
            }
            return classes;
        },
        internalShowFooter() {
            return this.showFooter === false ? false : this.showFooter || this.$slots.footer !== undefined;
        },
        internalShowHeader() {
            return this.showHeader === false ? false : this.showHeader || this.$slots.header !== undefined;
        },
        internalShowTabs() {
            return this.showTabs === false ? false : this.showTabs || this.$slots.tabs !== undefined;
        },
        scaleTransition() {
            return this.hasScaleTransition ? "ease" : null;
        },
        showHeaderContainer() {
            return this.internalShowHeader || this.internalShowTabs;
        },

        styles() {
            if (this.maxWidthPx) {
                return {
                    "max-width": `${this.maxWidthPx}px`
                };
            }

            return {};
        },

        theme() {
            return this.$store.state.ui.theme || "unknown";
        },

        wrapperClasses() {
            const classes = ["hox-modal__wrapper", `hox-modal__wrapper--${this.theme}`];
            if (this.isOnTopOfEverything) {
                classes.push("hox-modal__wrapper--on-top-of-everything");
            }

            if (this.wrapperClass) {
                classes.push(this.wrapperClass);
            }

            return classes;
        }
    },
    beforeDestroy() {
        instanceCounter -= 1;
        if (instanceCounter === 0) {
            document.body.classList.remove("hox-modal--no-scroll");
        }
    },
    beforeMount() {
        /*
            The reason this counter exists is so that we do not
            remove the no-scroll class from the body until we should (when no modal
            is being shown) if we are hiding/showing multiple modal components
            at the same time/nested within eachother.
        */
        instanceCounter += 1;
    },
    created() {
        instanceId += 1;
        this.portalTarget = `modal-${instanceId}`;
        bus.$emit("addModalPortal", this.portalTarget);
        if (document.activeElement) {
            document.activeElement.blur();
        }
        document.addEventListener("keyup", this.onEsc);
    },
    destroyed() {
        bus.$emit("removeModalPortal", this.portalTarget);
        document.removeEventListener("keyup", this.onEsc);
    },
    mounted() {
        if (instanceCounter === 1) {
            document.body.classList.add("hox-modal--no-scroll");
        }
    },
    methods: {
        close() {
            this.$emit("close");
        },
        closeByClickingBackground() {
            if (this.canBeClosedByClickingBackground) {
                this.close();
            }
        },
        disableClosingByClickingBackground() {
            this.canBeClosedByClickingBackground = false;
        },
        enableClosingByClickingBackground() {
            this.canBeClosedByClickingBackground = true;
        },

        onEsc(event) {
            if (event.keyCode === 27) {
                this.close();
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

$full-screen-outer-spacing: $spacing-large;

.hox-modal {
    background: $white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    color: $cmp-light-secondary-font-color;
    margin: auto;
    max-width: 100%;
    position: relative;
    width: 520px;
}

.hox-modal--tabs-no-header {
    .hox-modal__close-icon-container {
        top: $spacing-large - 21px;
    }
}

.hox-modal__body {
    padding: $spacing $spacing-large;
    position: relative;
}

.hox-modal__close-icon-container {
    color: $grey5;
    cursor: pointer;
    position: absolute;
    right: $spacing-large - 14px;
    top: $spacing-large - 14px;
    transition: color 0.2s ease-in-out;

    &:hover {
        color: $darkgrey2;
    }
}

.hox-modal__footer {
    display: flex;
    justify-content: flex-end;
    padding: $spacing $spacing-large $spacing-large $spacing-large;
}

.hox-modal__header-container {
    border-bottom: 1px solid $grey2;
    padding: $spacing $spacing-large 0;
}

.hox-modal__header {
    font-size: $font-size-larger;
    line-height: 1em;
    margin: 0 $spacing-large $spacing 0;
    padding: $spacing 0;
}

.hox-modal--no-scroll {
    overflow: hidden;
}

.hox-modal--fullscreen {
    bottom: $full-screen-outer-spacing;
    display: flex;
    flex-direction: column;
    height: calc(100vh - #{$full-screen-outer-spacing * 2});
    left: $full-screen-outer-spacing;
    position: absolute;
    right: $full-screen-outer-spacing;
    top: $full-screen-outer-spacing;
    width: calc(100vw - #{$full-screen-outer-spacing * 2});

    .hox-modal__body {
        flex: 1;
        overflow: auto;
    }
}

.hox-modal--resizable {
    width: initial;
}

.hox-modal__wrapper {
    background: rgba(55, 55, 55, 0.6);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: $spacing-large $spacing;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;
}

.hox-modal__wrapper--on-top-of-everything {
    z-index: 9999;
}

.hox-modal__description {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}
</style>
