<template>
    <div>
        <div
            v-for="annotation of filteredAnnotations"
            :key="annotation._id"
            :class="generetateAnnotationDotContainerClasses(annotation)"
            :style="getAnnotationStyle(annotation)"
            @click="selectAnnotation(annotation)"
        >
            <transition appear name="fade">
                <qa-annotation-dot
                    is-highlighted
                    :is-selected="checkIfAnnotationIsSelected(annotation)"
                    :type="annotation.type"
                >
                    <template v-if="annotation.status === AnnotationStatus.Unresolved">
                        {{ annotation.displayIndex }}
                    </template>
                    <template v-else>
                        <Icon type="md-checkmark" />
                    </template>
                </qa-annotation-dot>
            </transition>
        </div>
    </div>
</template>

<script>
import QaAnnotationDot from "@/components/Qa/QaAnnotationDot";
import { AnnotationStatus } from "@/enums/annotations";

export default {
    components: {
        QaAnnotationDot
    },
    props: {
        annotations: {
            type: Array
        },

        selectedAnnotation: {
            validator(value) {
                return value === null || value._id;
            }
        },

        scalingFactor: {
            type: Number
        },

        showResolved: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        filteredAnnotations() {
            return this.showResolved
                ? this.annotations
                : this.annotations.filter(annotation => annotation.status === AnnotationStatus.Unresolved);
        }
    },

    created() {
        this.AnnotationStatus = AnnotationStatus;
    },

    methods: {
        checkIfAnnotationIsSelected(annotation) {
            return this.selectedAnnotation !== null && annotation._id === this.selectedAnnotation._id;
        },

        generetateAnnotationDotContainerClasses(annotation) {
            const classes = ["qa-banner-annotations__annotation-dot-container"];
            if (this.checkIfAnnotationIsSelected(annotation)) {
                classes.push("qa-banner-annotations__annotation-dot-container--selected");
            }
            return classes;
        },

        getAnnotationStyle(annotation) {
            return {
                left: `${annotation.xpos * this.scalingFactor}px`,
                top: `${annotation.ypos * this.scalingFactor}px`
            };
        },
        selectAnnotation(annotation) {
            this.$emit("annotationSelected", annotation);
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.qa-banner-annotations__annotation-dot-container {
    cursor: pointer;
    opacity: 0.8;
    position: absolute;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s linear;
    z-index: 1;

    &:hover {
        opacity: 1;
        z-index: 2;
    }
}

.qa-banner-annotations__annotation-dot-container--selected {
    opacity: 1;
    z-index: 1;
}
</style>
