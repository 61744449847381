<template>
    <hox-alert v-if="isCampaignLocked" size="small" align-text="center" type="warning" class="campaign-locked-notice">
        <template #content>
            <p>
                Some features are disabled due to a task being processed on this campaign. Check the notification drawer
                for more details.
            </p>
        </template>
    </hox-alert>
</template>
<script>
import isCampaignLockedQuery from "@/apollo/queries/v2/IsCampaignLocked.gql";
import { CampaignAction } from "@/store/modules/campaign";
import { JobsAction } from "@/store/modules/jobs";

export default {
    name: "CampaignLockedNotice",

    props: {
        noPolling: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            forceRefetch: false
        };
    },

    computed: {
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        isAuthorized() {
            return this.$store.state.auth.isAuthorized;
        },

        isCampaignLocked() {
            return this.$store.state.campaign.isLocked;
        },

        shouldSkipPolling() {
            if (this.noPolling) {
                return true;
            }

            if (this.forceRefetch) {
                return false;
            }

            return !this.isCampaignLocked || !this.isAuthorized;
        }
    },

    created() {
        const actionsToImmediatelyRefresh = [
            JobsAction.SetNotificationIdByActiveJobId,
            JobsAction.DeleteNotificationIdByActiveJobId
        ];
        const subscribeHadnler = ({ type }) => {
            if (actionsToImmediatelyRefresh.includes(type)) {
                this.forceRefetch = true;
                this.$apollo.queries.isLocked.refetch();
            }
        };

        this.unsubscribe = this.$store.subscribeAction({
            after: subscribeHadnler.bind(this)
        });
    },

    beforeDestroy() {
        this.unsubscribe();
    },

    apollo: {
        isLocked: {
            query: isCampaignLockedQuery,
            fetchPolicy: "no-cache",
            pollInterval: 30000,
            skip() {
                return this.shouldSkipPolling;
            },
            manual: true,
            variables() {
                return {
                    campaignId: this.campaignId
                };
            },
            result({ data, error }) {
                if (error) {
                    /*
                      We don't need to do any error handling here as the request should retry
                      again in a short amount of time due to the pollInterval.
                    */
                    return;
                }
                this.forceRefetch = false;

                if (data.campaign.isLocked !== this.isCampaignLocked) {
                    this.$store.dispatch(CampaignAction.SetIsLocked, data.campaign.isLocked);
                }
            }
        }
    }
};
</script>

<style lang="scss">
@import "../../../sass/variables";

.campaign-locked-notice {
    text-align: center;
    align-content: center;
    justify-content: center;
    align-self: center;
    margin: $spacing-small auto;

    &--table-view {
        width: 900px;
    }
}
</style>
