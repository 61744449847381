<template>
    <hox-modal @close="close">
        <hox-loading-layer v-if="isDeletingCampaign" />
        <div class="delete-campaign-modal__alert-wrapper">
            <hox-alert type="danger" :margin-bottom="hasErrorDeletingCampaign ? 'base' : 'none'">
                <template #title>
                    Are you sure you want to delete the "
                    <em>{{ campaignName }}</em>
                    " campaign?
                </template>
                <template #content>
                    <p>Deleting a campaign is permanent: it can not be recovered.</p>
                </template>
            </hox-alert>
        </div>
        <Alert v-if="hasErrorDeletingCampaign" banner type="error">
            <template #desc>
                Something went wrong and the campaign was not deleted. Try again in a few moments.
            </template>
        </Alert>
        <template #footer>
            <Button class="delete-campaign-modal__button" @click="close">Cancel</Button>
            <Button class="delete-campaign-modal__button" type="error" @click="deleteCampaign">Delete</Button>
        </template>
    </hox-modal>
</template>

<script>
import RemoveCampaignMutation from "@/apollo/mutations/RemoveCampaign.gql";

export default {
    props: {
        campaignId: {
            type: String,
            required: true
        },

        campaignName: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            hasErrorDeletingCampaign: false,
            isDeletingCampaign: false
        };
    },

    methods: {
        close() {
            this.$emit("close");
        },

        async deleteCampaign() {
            this.hasErrorDeletingCampaign = false;
            if (!this.isDeletingCampaign) {
                this.isDeletingCampaign = true;
                try {
                    await this.$apollo.mutate({
                        mutation: RemoveCampaignMutation,
                        variables: {
                            campaignId: this.campaignId
                        }
                    });
                    this.$snackbar.success(`The campaign "${this.campaignName}" has been deleted`);
                    this.$emit("campaignDeleted");
                    this.close();
                } catch (err) {
                    this.hasErrorDeletingCampaign = true;
                    return;
                } finally {
                    this.isDeletingCampaign = false;
                }
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.delete-campaign-modal__alert-wrapper {
    padding: $spacing 0 0;
}

.delete-campaign-modal__button {
    margin: 0 0 0 $spacing-small;
}
</style>
