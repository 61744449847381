export default masterTemplate => {
    const { width, height, parentWidth, parentHeight } = masterTemplate;

    if (!parentHeight || !parentWidth) {
        return {
            widthMultiplier: 1,
            heightMultiplier: 1
        };
    }

    return {
        widthMultiplier: width / parentWidth,
        heightMultiplier: height / parentHeight
    };
};
