import { ChartColors } from "@/enums/creativeInteligence";
import { ColorsHEX } from "@/enums/reporting";

export const getPointColors = (label, index) => {
    const labelWithHexRegEx = /^.*-#?([0-9a-f]{6})$/i;
    const [, matchedLabelColor] = label.match(labelWithHexRegEx) || [];

    if (matchedLabelColor) {
        return {
            backgroundColor: `#${matchedLabelColor}`,
            hoverBackgroundColor: `#${matchedLabelColor}AA`
        };
    }

    return {
        backgroundColor: ChartColors[index % ChartColors.length],
        hoverBackgroundColor: `${ChartColors[index % ChartColors.length]}AA`
    };
};

export const drawQuadrants = (chart, kpiMetricValue, confidenceMetricValue) => {
    const {
        ctx,
        chartArea: { left, right, top, bottom },
        scales: { x: xScale, y: yScale }
    } = chart;

    const midY = yScale.getPixelForValue(+kpiMetricValue);
    const midX = xScale.getPixelForValue(+confidenceMetricValue);

    const stdFont = 'normal 700 14px "Open Sans"';

    ctx.beginPath();
    ctx.strokeStyle = "gray";
    ctx.setLineDash([2, 6]); /* dashes are 5px and spaces are 3px */
    ctx.moveTo(midX, top);
    ctx.lineTo(midX, bottom);
    ctx.stroke();

    ctx.beginPath();
    ctx.strokeStyle = "gray";

    ctx.moveTo(left, midY);
    ctx.lineTo(right, midY);
    ctx.stroke();
    ctx.setLineDash([]);

    // Top right quadrant
    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.strokeStyle = ColorsHEX.deviationUp;
    ctx.moveTo(midX + 1, top);
    ctx.lineTo(right, top);
    ctx.lineTo(right, midY);
    ctx.stroke();

    ctx.font = stdFont;
    ctx.fillStyle = ColorsHEX.lightgray;
    ctx.fillText("HIGH PERFORMERS", right - 140, 55);

    // Top left quadrant
    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.strokeStyle = "#D8E1EA";
    ctx.moveTo(left, midY + 1);
    ctx.lineTo(left, top);
    ctx.lineTo(midX, top);
    ctx.stroke();

    ctx.font = stdFont;
    ctx.fillStyle = ColorsHEX.lightgray;
    ctx.fillText("EXPLORE", left + 10, 55);

    // Bottom left quadrant
    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.strokeStyle = "#F5F7F9";
    ctx.moveTo(left, midY);
    ctx.lineTo(left, bottom);
    ctx.lineTo(midX, bottom);
    ctx.stroke();

    ctx.font = stdFont;
    ctx.fillStyle = ColorsHEX.lightgray;
    ctx.fillText("GETTING STARTED", left + 10, bottom - 15);

    // Bottom right quadrant
    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.strokeStyle = ColorsHEX.deviationDown;
    ctx.moveTo(midX + 1, bottom);
    ctx.lineTo(right, bottom);
    ctx.lineTo(right, midY);
    ctx.stroke();

    ctx.font = stdFont;
    ctx.fillStyle = ColorsHEX.lightgray;
    ctx.fillText("LOW PERFORMERS", right - 140, bottom - 15);
};

export const ChartType = {
    BarChart: "barChart",
    DoughnutChart: "doughnutChart",
    HorizontalBarChart: "horizontalBarChart",
    PolarChart: "polarChart",
    ScatterChart: "scatterChart",
    TreemapChart: "treemapChart",
    WordCloudChart: "wordCloudChart"
};

export const ChartTypeLabel = {
    [ChartType.BarChart]: "Bar Chart",
    [ChartType.DoughnutChart]: "Doughnut Chart",
    [ChartType.HorizontalBarChart]: "Horizontal Bar Chart",
    [ChartType.PolarChart]: "Polar Chart",
    [ChartType.ScatterChart]: "Scatter Chart",
    [ChartType.TreemapChart]: "Treemap Chart",
    [ChartType.WordCloudChart]: "Word Cloud Chart"
};

export const ChartDisplayValue = {
    KpiStat: "kpiStat",
    Utilisation: "utilisation"
};
/* eslint-disable no-param-reassign */

export const setTooltipUrls = (vueChartComponent, tooltipModel) => {
    const creatives = tooltipModel.dataPoints.map(({ datasetIndex, dataIndex }) => {
        const dataset = vueChartComponent.datasets[datasetIndex];

        return dataset.creatives[dataIndex];
    });

    const frameUrls = creatives.reduce((acc, group) => {
        // eslint-disable-next-line consistent-return
        group.forEach(({ signedPreviewUrl }) => {
            if (!signedPreviewUrl) {
                // we don't have the segment but we can
                return acc;
            }

            acc.push(signedPreviewUrl);
        });

        return acc;
    }, []);

    if (frameUrls.length) {
        const uniqueImages = [...new Set(frameUrls)];
        if (JSON.stringify(vueChartComponent.tooltipImageUrls) !== JSON.stringify(uniqueImages)) {
            vueChartComponent.tooltipImageUrls = uniqueImages;
        } else {
            vueChartComponent.$refs.tooltip.startTooltipCarousel();
        }
    } else {
        vueChartComponent.tooltipImageUrls = [];
    }
};
export const getCustomTooltips = async (vueChartComponent, { tooltip: tooltipModel }, setUrlsFn = setTooltipUrls) => {
    // Hide if no tooltip
    const tooltipRef = vueChartComponent.$refs.tooltip;
    tooltipRef.resetTooltipAnimation();
    if (tooltipModel.opacity === 0) {
        vueChartComponent.tooltipStyle = { opacity: 0 };
        tooltipRef.resetPopper();
        return;
    }

    vueChartComponent.setTooltipText(tooltipModel);

    vueChartComponent.tooltipStyle = {
        opacity: 1,
        position: "absolute",
        left: `${tooltipModel.caretX}px`,
        top: `${tooltipModel.caretY}px`
    };
    tooltipRef.initPopper();

    setUrlsFn(vueChartComponent, tooltipModel);
};
/* eslint-enable no-param-reassign */
