import { CampaignGetters } from "@/store/modules/campaign";
import { deepClone } from "@/utils";
import { EditableGroupScopeLabel } from "@/enums/overwrites";

export default {
    props: {
        editable: {
            required: true
        },

        groupsAffectingEditable: {
            type: Array,
            required: true
        },

        isOpened: {
            type: Boolean,
            default: true
        },

        readOnly: {
            type: Boolean,
            default: false
        },

        showRestrictionControl: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            opened: false
        };
    },

    computed: {
        editableTemplates() {
            return this.editable.defaultValues.map(defaultValue => defaultValue.masterTemplateId);
        },

        isSettingsPaneVisible() {
            return this.$store.state.editor.isSettingsPaneVisible;
        },

        masterTemplateLabels() {
            return this.$store.getters[CampaignGetters.masterTemplateLabels];
        },

        templateLabels() {
            const labels = deepClone(this.masterTemplateLabels);
            Object.keys(labels).forEach(masterTemplateId => {
                if (!this.editableTemplates.includes(masterTemplateId)) {
                    delete labels[masterTemplateId];
                }
            });

            return labels;
        }
    },

    created() {
        this.EditableGroupScopeLabel = EditableGroupScopeLabel;
    },

    watch: {
        isOpened: {
            handler(isOpened) {
                this.opened = isOpened;
            },
            immediate: true
        }
    },

    methods: {
        toggleOpen(value) {
            this.opened = value;
        }
    }
};
