export const ATTRIBUTE_TYPES = {
    // This type should no longer be used. It has been split into more specific types
    EDITABLE_VALUE: "PlaceholderValue",
    IFRAME_VALUE: "IframeValue",
    IMAGE_VALUE: "ImageValue",
    IMAGE_TAG: "ImageTag",
    LANGUAGE: "Language",
    METADATA: "Metadata",
    TEMPLATE_SIZE: "Size",
    TEXT_SENTIMENT: "TextSentiment",
    TEXT_VALUE: "TextValue",
    KEYWORD: "Keyword",
    WORD_COUNT: "WordCount",
    WORD_COUNT_BRACKET: "WordCountBracket",
    KEY_PHRASE: "KeyPhrase",
    ENTITY: "TextEntity",
    VIDEO_VALUE: "VideoValue",
    COLOR_THEORY: "ColorTheory",
    COLOR_THEORY_COLOR: "ColorTheory-Color",
    COLOR_THEORY_COLOR_GROUP: "ColorTheory-ColorGroup",
    COLOR_THEORY_PRIMARY_COLOR_GROUP: "ColorTheory-PrimaryColorGroup",
    COLOR_THEORY_PANTONE_COLOR_GROUP: "ColorTheory-PantoneColorGroup",
    COLOR_THEORY_TEMPERATURE: "ColorTheory-Temperature",
    COLOR_THEORY_TEMPERATURE_GROUP: "ColorTheory-TemperatureGroup"
};

export const REPORT_DATA_FILE_TYPES = {
    ATTRIBUTES_SUMMARY: "ATTRIBUTES_SUMMARY",
    CREATIVES_SUMMARY: "CREATIVES_SUMMARY",
    DELIVERABLES: "DELIVERABLES",
    EXCEL: "EXCEL",
    FLASHTALKING_VERSIONS: "FLASHTALKING_VERSIONS",
    INPUT: "INPUT",
    METRICS_REPORT: "METRICS_REPORT",
    REPORT: "REPORT",
    // This type should no longer be used. The data it was storing is now split out into separate files
    REPORT_DELIVERABLES_ATTRIBUTES: "REPORT_DELIVERABLES_ATTRIBUTES",
    TIMELINE_REPORT: "TIMELINE_REPORT"
};

export const TEXT_ENTITIES_TYPES = {
    ORGANIZATION: "Organization",
    TITLE: "Title",
    LOCATION: "Location",
    DATE: "Date",
    PERSON: "Person",
    QUANTITY: "Quantity",
    OTHER: "Other",
    COMMERCIAL_ITEM: "Commercial_item",
    EVENT: "Event"
};

export const COLOR_THEORY_TYPES = {
    COLOR: "Color",
    COLOR_GROUP: "ColorGroup",
    PANTONE_COLOR_GROUP: "PantoneColorGroup",
    PRIMARY_COLOR_GROUP: "PrimaryColorGroup",
    TEMPERATURE: "ColorTemperature",
    TEMPERATURE_GROUP: "ColorTemperatureGroup"
};

export const WORD_COUNT_BRACKETS = [
    { low: 0, high: 5 },
    { low: 6, high: 9 },
    { low: 10, high: 19 },
    { low: 20, high: 29 },
    { low: 30, high: 39 },
    { low: 40, high: 49 },
    { low: 50, high: 69 },
    { low: 70, high: 99 },
    { low: 100, high: 149 },
    { low: 150 }
];

export const UNKNOWN_WORD_COUNT_BRACKET = "Unknown";

export const COMPREHEND_INTERNAL_SERVER_EXCEPTION = "InternalServerException";
