<template>
    <div class="insights-wrapper">
        <reporting-drawer :is-open="drawerCreatives.length" :creatives="drawerCreatives" @closed="closeDrawer" />

        <empty-message v-if="!hasWorkerId" type="no-content" class="insights-dashboard-no-kpis">
            <template #title>This campaign has no Resource Group ID set.</template>
        </empty-message>
        <div v-else>
            <empty-message v-if="!isIndexed" type="no-content" class="insights-no-kpis">
                <template v-if="indexingError" #title>Indexing Error: {{ indexingError }}</template>
                <template v-else #title>Processing data ({{ indexedPercentage }}%)</template>
            </empty-message>
            <div
                v-else-if="benchmarksSet"
                class="insights"
                :class="{ 'insights--drawer-is-open': drawerCreatives.length }"
            >
                <insights-data-provider
                    :insights-type="InsightType.CampaignInfo"
                    :refresh-counter="refreshCounter"
                    @loading="setLoading"
                    @data="onData"
                    @error="onError"
                />
                <kpi-matrix />
                <div v-if="hasData" class="insights-settings">
                    <insights-display-settings />
                    <heatmap-switch />
                    <report-download />
                </div>

                <hox-alert
                    v-if="!isLoading && showsDiversityTemplate && hasData"
                    type="warning"
                    size="small"
                    align-text="center"
                    class="diversity-warning"
                >
                    <template #content>
                        <p>
                            Please note, Diversity data is pre-alpha and we are restricting usage of this feature. By
                            enabling access to this data on your account, you understand that this pre-alpha data should
                            not be used to infer insights, and agree not to share or record the dashboard showing these
                            results.
                        </p>
                    </template>
                </hox-alert>

                <div v-show="hasData && !isLoading">
                    <key-takeaway />
                    <attribute-values-analysis />
                    <position-analysis />
                    <size-analysis />
                    <appearance-duration-analysis />
                    <appearance-position-analysis />
                    <breakdown-context />
                    <!--                <context-analysis />-->
                </div>
                <hox-empty-message v-if="showNoResults" class="insights-no-data">
                    <template #title>
                        Select another insight, try a different search or adjust your Discard Metrics settings
                    </template>
                </hox-empty-message>
                <hox-alert v-else-if="hasError && !isLoading" margin-top="base" type="danger">
                    <template #title>Uh oh!</template>
                    <template #content>
                        <p>
                            There was an unexpected issue while loading the data. Hopefully it was a temporary issue and
                            should be fixed if you try again in a few moments.
                        </p>
                    </template>
                </hox-alert>
            </div>
            <empty-message v-else type="no-content" class="insights-no-kpis">
                <template #title>To get started, you'll need to define your benchmarks</template>
                <template #actionItems>
                    <Button type="primary" @click="showSettingsModal">Set Benchmarks</Button>
                </template>
            </empty-message>
        </div>
    </div>
</template>

<script>
/*
 *
 * this component was duplicated inside of tagInsights and should be removed after all features are migrated
 *
 *
 *
 *
 * */
import CampaignQuery from "@/apollo/queries/v2/Campaign.gql";
import KeyTakeaway from "@/components/CreativeIntelligence/InsightPreviews/KeyTakeaway";
import KpiMatrix from "@/components/CreativeIntelligence/InsightPreviews/KPIMatrix";
import PositionAnalysis from "@/components/CreativeIntelligence/InsightPreviews/PositionAnalysis";
import AppearanceDurationAnalysis from "@/components/CreativeIntelligence/InsightPreviews/AppearanceDurationAnalysis";
import AppearancePositionAnalysis from "@/components/CreativeIntelligence/InsightPreviews/AppearancePositionAnalysis";
import SizeAnalysis from "@/components/CreativeIntelligence/InsightPreviews/SizeAnalysis";
import AttributeValuesAnalysis from "@/components/CreativeIntelligence/InsightPreviews/AttributeValuesAnalysis";
import {
    CreativeInsightsAction as ReportingAction,
    CreativeInsightsAction,
    CreativeInsightsGetters
} from "@/store/modules/creativeInsights";
import ReportingDrawer from "@/components/CreativeIntelligence/CreativeInteligenceDeliverableDrawer";
import { UiAction } from "@/store/modules/ui";
import InsightsDisplaySettings from "@/components/CreativeIntelligence/InsightsDisplaySettings";
import ReportDownload from "@/components/CreativeIntelligence/ReportDownload";
import BreakdownContext from "@/components/CreativeIntelligence/InsightPreviews/BreakdownContextCharts";
import EmptyMessage from "@/components/common/EmptyMessage";
import HeatmapSwitch from "@/views/CreativeInteligence/HeatmapSwitch";
import { InsightType } from "@/components/CreativeIntelligence/constants";
import InsightsDataProvider from "@/components/CreativeIntelligence/InsightsDataProvider";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Insights",
    components: {
        InsightsDataProvider,
        HeatmapSwitch,
        EmptyMessage,
        BreakdownContext,
        ReportDownload,
        InsightsDisplaySettings,
        ReportingDrawer,
        AttributeValuesAnalysis,
        SizeAnalysis,
        AppearancePositionAnalysis,
        AppearanceDurationAnalysis,
        PositionAnalysis,
        KpiMatrix,
        KeyTakeaway
    },

    data() {
        return {
            data: null,
            isLoading: true,
            hasError: false,
            refreshCounter: 0,
            hasWorkerId: false
        };
    },

    computed: {
        benchmarksSet() {
            return (
                this.$store.getters[CreativeInsightsGetters.KpiMetricName] !== undefined &&
                this.$store.getters[CreativeInsightsGetters.ConfidenceMetricName] !== undefined
            );
        },

        breakdowns() {
            return this.$store.state.creativeInsights.breakdowns;
        },

        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        displayTemplates() {
            if (!this.selectedTagIds.length) {
                return null;
            }

            return this.tagsById[this.selectedTagIds[0]].displayTemplates;
        },

        drawerCreatives: {
            set(mode) {
                return this.$store.dispatch(CreativeInsightsAction.SetDrawerCreatives, mode);
            },
            get() {
                return this.$store.state.creativeInsights.drawerCreatives;
            }
        },

        hasData() {
            return this.data && !this.data.percentage && this.breakdowns.length;
        },

        indexedPercentage() {
            return this.$store.state.creativeInsights.indexedPercentage;
        },

        isIndexed() {
            return this.$store.state.creativeInsights.isIndexed;
        },

        indexingError() {
            return this.$store.state.creativeInsights.indexingError;
        },

        showsDiversityTemplate() {
            return this.displayTemplates && this.displayTemplates.includes("diversity");
        },

        showNoResults() {
            return (
                !this.isLoading &&
                this.data &&
                this.data.overallKpiMetricStats &&
                this.data.overallKpiMetricStats &&
                !this.data.overallKpiMetricStats.count
            );
        },

        selectedTagIds() {
            return this.$store.state.ciTags.selectedTagIds;
        },

        tagsById() {
            return this.$store.state.ciTags.tagsById;
        }
    },

    watch: {
        drawerCreatives: {
            immediate: true,
            handler(val) {
                if (val) {
                    if (val.length) {
                        document.body.classList.add("insights--no-scroll");
                    } else {
                        document.body.classList.remove("insights--no-scroll");
                    }
                }
            }
        }
    },

    created() {
        this.InsightType = InsightType;

        this.$store.dispatch(CreativeInsightsAction.Init);
        this.$store.dispatch(UiAction.OpenSidebar);
    },

    methods: {
        closeDrawer() {
            this.drawerCreatives = [];
        },

        onData({ data }) {
            if (!data) {
                return;
            }

            this.data = data;
            this.hasError = false;
            this.setIndexedStatus(data);

            if (data.filter) {
                this.$store.dispatch(CreativeInsightsAction.SetBreakdowns, data.filter);
            }

            this.setMetadata(data);

            if (!this.isIndexed) {
                setTimeout(() => {
                    this.refreshCounter += 1;
                }, 5000);
            }
        },

        onError() {
            this.hasError = true;
        },

        setIndexedStatus(data) {
            if (typeof data.percentage !== "undefined") {
                this.$store.dispatch(CreativeInsightsAction.SetIsIndexed, false);
                this.$store.dispatch(CreativeInsightsAction.SetIndexedPercentage, data.percentage);
            }
        },

        setLoading(isLoading) {
            this.isLoading = isLoading;
        },

        setMetadata(data) {
            if (data.metadata) {
                this.$store.dispatch(CreativeInsightsAction.SetMetadata, {
                    ...data.metadata,
                    maxDate: data.dateRange.maxDate ? data.dateRange.maxDate : "",
                    minDate: data.dateRange.minDate ? data.dateRange.minDate : ""
                });
            }
        },

        showSettingsModal() {
            this.$store.dispatch(CreativeInsightsAction.ToggleSettingsModal);
        }
    },

    apollo: {
        campaign: {
            query: CampaignQuery,
            loadingKey: "isLoading",
            variables() {
                return {
                    campaignId: this.campaignId
                };
            },

            result({ data, error }) {
                this.$store.dispatch(UiAction.SetCurrentClient, data.campaign.client);
                if (error) {
                    console.error(error);
                    return;
                }

                if (!data.campaign.resourceGroupIds) {
                    return;
                }

                this.hasWorkerId = true;
                this.$store.dispatch(ReportingAction.SetWorkerId, data.campaign.resourceGroupIds);

                if (!this.benchmarksSet) {
                    this.showSettingsModal();
                }
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";
.insights-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.insights {
    flex: 1 1 100%;
    height: 100%;
    width: calc(100% - 84px);
    margin: 0 42px;
    image-rendering: -webkit-optimize-contrast;

    &--drawer-is-open {
        overflow: hidden;
    }

    &-no-data {
        margin: $spacing-largest auto auto;
    }

    &-no-kpis {
        margin: 20vh auto auto;
    }

    &-section {
        margin: $spacing $spacing-small;
    }

    &-settings {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 45px 0 $spacing 0;
    }

    .ivu-collapse > .ivu-collapse-item {
        border: 1px solid transparent;

        .ivu-collapse-header {
            border: none;
            background: $white;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
        }

        &.ivu-collapse-item-active {
            border-bottom: 1px solid $cmp-light-filters-border-color;

            .ivu-collapse-header {
                background: $white;
            }
        }
    }

    .ivu-collapse-content {
        background: rgba(255, 255, 255, 0.4);
    }
}

.insights--no-scroll {
    overflow: hidden;
    height: 100vh;
}

.diversity-warning .alert__icon-container {
    padding-top: $spacing-small;
}
</style>
