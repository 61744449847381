<template>
    <Slider
        ref="sliderComponent"
        class="banner-scrubbar timeline-slider"
        :min="0"
        :max="max"
        :step="0.01"
        :value="value"
        :tip-format="formatLabel"
        @on-input="onInput"
        @on-change="onChange"
    ></Slider>
</template>
<script>
import bus from "@/bus";
import { BannerScrubbarEvents, BannerDefaultDuration } from "@/enums/banners";

export default {
    name: "BannerScrubbar",
    props: {
        duration: {
            type: Number
        },

        value: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            dragging: false
        };
    },

    computed: {
        campaignDuration() {
            return this.$store.state.campaign.defaultTimelineLength;
        },

        max() {
            if (this.duration) {
                return this.duration;
            }

            return this.campaignDuration || BannerDefaultDuration;
        }
    },

    watch: {
        dragging() {
            this.$emit("dragging", this.dragging);
        }
    },

    mounted() {
        this.onPointerDragStart = this.$refs.sliderComponent.onPointerDragStart;
        this.onPointerDrag = this.$refs.sliderComponent.onPointerDrag;
        this.onPointerDragEnd = this.$refs.sliderComponent.onPointerDragEnd;
        // we need to hack that in because the component does not return the info for us nor we can watch internal prop
        this.$refs.sliderComponent.onPointerDragStart = event => {
            this.onPointerDragStart(event);
            this.$emit("drag-start");
            bus.$emit(BannerScrubbarEvents.Scrubbing);
            this.dragging = true;
        };

        this.$refs.sliderComponent.onPointerDragEnd = event => {
            this.onPointerDragEnd(event);
            this.$emit("drag-end");
            this.dragging = false;
        };
    },

    methods: {
        formatLabel(val) {
            return `${val} Sec`;
        },

        onChange(val) {
            bus.$emit(BannerScrubbarEvents.ScrubUpdateValue, val);
        },

        onInput(val) {
            this.$emit("change", val);

            if (this.dragging) {
                bus.$emit(BannerScrubbarEvents.ScrubUpdateValue, val);
            }
        }
    }
};
</script>

<style lang="scss">
.banner-scrubbar {
    .ivu-slider {
        &-wrap {
            margin: 26px 20px;
        }

        &-button {
            width: 18px;
            height: 18px;

            &-wrap {
                width: 40px;
                height: 40px;
                top: -6px;
            }
        }
    }
}
</style>
