<template>
    <div id="app" :class="themeClassName">
        <hox-loading-bar />
        <Layout>
            <Layout>
                <hogarth-footer-logo class="login-footer-logo"></hogarth-footer-logo>
                <Content class="main-content">
                    <router-view :key="$route.fullPath" class="hox-full-width router-view" />
                </Content>
                <div class="login-footer">
                    <span class="pull-right">© {{ year }} Hogarth Worldwide, All Rights Reserved</span>
                </div>
            </Layout>
        </Layout>
    </div>
</template>
<script>
import HoxLoadingBar from "@/HoxLoadingBar";
import themeMixin from "@/mixins/themeMixin";
import HogarthFooterLogo from "@/assets/Hogarth_Wordmark.svg";

export default {
    name: "ThemeLogin",
    components: {
        HoxLoadingBar,
        HogarthFooterLogo
    },
    mixins: [themeMixin],

    data() {
        return {
            sidebarWidth: 0
        };
    },
    computed: {
        year() {
            return new Date().getFullYear();
        }
    }
};
</script>

<style lang="scss">
@import "../sass/variables";

.theme-login {
    &:before {
        content: " ";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkyMCIgaGVpZ2h0PSIxMDgwIiB2aWV3Qm94PSIwIDAgMTkyMCAxMDgwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgPGNpcmNsZSBmaWxsPSJ0cmFuc3BhcmVudCIgc3Ryb2tlPSIjMjdjNzlhIiBjeD0iNjQwIiBjeT0iNjQ4IiByPSI2NzAiPjwvY2lyY2xlPgogICAgPGNpcmNsZSBmaWxsPSJ0cmFuc3BhcmVudCIgc3Ryb2tlPSIjNGY1MWM5IiBjeD0iMTI4MCIgY3k9IjY0OCIgcj0iNjcwIj48L2NpcmNsZT4KPC9zdmc+Cg==")
            no-repeat top;
        background-size: cover;
    }

    background-color: $hogarth-blue;
    text-align: center;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    color: #ffffff;

    .main-content,
    .ivu-layout,
    #app {
        background-color: transparent;
        width: 100%;
    }

    .ivu-form .ivu-form-item-label {
        color: $white;
    }

    .main-content {
        z-index: 1;
    }

    #client-logo {
        max-width: 200px;
        max-height: 150px;
        margin: 40px 0;
    }

    .login-footer {
        width: 100%;
        position: absolute;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.28);
        height: 36px;
        line-height: 36px;
        padding: 0 36px;
        color: $white;

        &-logo {
            position: absolute;
            width: 120px;
            right: 26px;
            bottom: 30px;
        }
    }
}
</style>
