<template>
    <div class="role-select" data-testid="user-role__select">
        <Select v-bind="attrs" v-on="listeners">
            <Option
                v-for="role in userRoles"
                :key="role._id"
                class="role-select-option"
                :value="role._id"
                :title="role.name"
            >
                {{ role.name }}
            </Option>
        </Select>
    </div>
</template>

<script>
import passThroughMixin from "@/mixins/passThroughMixin";

export default {
    name: "UserRoleSelect",
    mixins: [passThroughMixin],
    computed: {
        userRoles() {
            return this.$store.state.roles.roles;
        }
    }
};
</script>
