<template>
    <hox-modal class="importExportModal" @close="close">
        <template #header>Import/Export {{ headerTabName }}</template>
        <navigation-tabs size="small" class="importExportModal__navigation">
            <navigation-tabs-item :is-active="activeTab === 'content'" @click="activeTab = 'content'">
                Content
            </navigation-tabs-item>
            <navigation-tabs-item :is-active="activeTab === 'translations'" @click="activeTab = 'translations'">
                Translations
            </navigation-tabs-item>
        </navigation-tabs>

        <template v-if="activeTab === 'content'">
            <hox-alert type="info" class="importExportModal__howto">
                <template #title>To upload content</template>
                <template #content>
                    <ol class="importExportModal__howto-list">
                        <li>
                            Download template for
                            <span class="link-like" @click="downloadTemplate('global')">global</span>
                            or
                            <span class="link-like" @click="downloadTemplate('template')">template specific</span>
                            values
                        </li>
                        <li>
                            Fill in the content in the the file
                            <br />
                            Note: Import supports text and file based editables only
                        </li>
                        <li>Upload the content XSLX file</li>
                        <li>Review and save changes in the editor</li>
                    </ol>
                </template>
            </hox-alert>

            <div class="importExportModal__upload-controls">
                <xls-upload-button
                    @click.native.stop="valuesUploadCompleted = false"
                    @load="onXlsValuesLoad"
                    @error="onValuesImportError"
                >
                    <Button type="primary" class="">Upload Values</Button>
                </xls-upload-button>

                <labelled-switch :value="useAutoResize" @change="toggleAutoResize">Auto Resize</labelled-switch>
            </div>
            <div class="importExportModal__upload-results">
                <hox-alert v-if="hasValuesError" type="danger" class="importExportModal__upload-result">
                    <template #content>
                        <p>{{ valuesErrorMessage }}</p>
                        <ul v-if="valuesErrorDetails" class="importExportModal__upload-results-error-list">
                            <li v-for="(detail, i) in valuesErrorDetails" :key="detail.message + i">
                                {{ detail.message }}
                            </li>
                        </ul>
                    </template>
                </hox-alert>

                <hox-alert
                    v-if="valuesUploadCompleted && !hasValuesError"
                    type="success"
                    class="importExportModal__upload-result"
                >
                    <template #title>File uploaded successfully</template>
                    <template #content>
                        <p>Content has been imported. Please review and save the changes.</p>
                    </template>
                </hox-alert>
            </div>
        </template>
        <template v-if="activeTab === 'translations'">
            <template v-if="!languageUploadCompleted">
                <hox-alert type="info" class="importExportModal__howto">
                    <template #title>To create the translations</template>
                    <template #content>
                        <ol class="importExportModal__howto-list">
                            <li>Download the file for the language you want to populate</li>
                            <li>Fill in the translations in the file</li>
                            <li>Upload the translations XSLX file</li>
                            <li>Review and save changes in the editor</li>
                        </ol>
                    </template>
                </hox-alert>
                <div class="importExportModal__files">
                    <h3>Download translation file</h3>
                    <div v-for="lang in campaignLanguages" :key="lang" class="link-like" @click="onClick(lang)">
                        {{ localeToFriendlyName(lang) }}
                    </div>
                </div>
            </template>
            <div class="importExportModal__upload-results">
                <hox-alert v-if="hasTranslationsError" type="danger" class="importExportModal__upload-result">
                    <template #content>
                        <p>{{ translationsErrorMessage }}</p>
                    </template>
                </hox-alert>
                <hox-alert v-if="languageUploadCompleted" type="success" class="importExportModal__upload-result">
                    <template #title>File uploaded successfully</template>
                    <template #content>
                        <p>
                            {{ languageImportFeedback.message }}
                        </p>
                        <p v-if="languageImportFeedback.newLanguages">
                            {{ languageImportFeedback.newLanguages }}
                        </p>
                    </template>
                    <template v-if="languageImportFeedback.languages.length === 1" #actionItems>
                        <Button
                            v-if="onlyImportedLanguage !== selectedLanguage"
                            type="primary"
                            @click="switchAndClose(onlyImportedLanguage)"
                        >
                            Switch to {{ localeToFriendlyName(onlyImportedLanguage) }} and close
                        </Button>
                    </template>
                </hox-alert>
            </div>

            <xls-upload-button
                @click.native.stop="valuesUploadCompleted = false"
                @load="onXlsTranslationsLoad"
                @error="onTranslationsError"
            >
                <Button type="primary">Upload Translations</Button>
            </xls-upload-button>
        </template>

        <template #footer>
            <div class="share-preview-modal__footer-button-wrapper">
                <Button @click="close">Close</Button>
            </div>
        </template>
    </hox-modal>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { CoverType, ResizeType } from "shared-utils/imageBoss";
import editableUtils from "@/utils/editables";
import HoxModal from "@/components/Modal/Modal/Modal";
import { camelToUpperCase } from "@/utils";
import editableMethodsMixin from "@/mixins/editableMethodsMixin";
import { ExportGroupScopeLabel, OverwriteScope } from "@/enums/overwrites";
import langUtils from "@/utils/languages";
import { EditorAction } from "@/store/modules/editor";
import NavigationTabs from "@/components/common/NavigationTabs/Container";
import NavigationTabsItem from "@/components/common/NavigationTabs/Tab";
import { campaignDefaultLanguage, CampaignGetters } from "@/store/modules/campaign";
import { ExportableEditableTypes, EditableType } from "@/enums/editables";
import LabelledSwitch from "@/components/Campaign/LabelledSwitch";
import { languageCodes } from "@/assets/languages";
import ImportExport from "@/services/ImportExport";
import XlsUploadButton from "@/components/Campaign/XlsUploadButton";

const emptyOverwriteImportValue = "{empty}";
class GroupProcessingError extends Error {
    constructor(message, groupName, details) {
        super(message);
        this.name = "GroupProcessingError";
        this.groupName = groupName;
        this.details = details;
    }
}

export default {
    name: "ImportExportModal",
    components: {
        XlsUploadButton,
        LabelledSwitch,
        HoxModal,
        NavigationTabs,
        NavigationTabsItem
    },
    mixins: [editableMethodsMixin],
    props: {
        createNewGroupValues: {
            type: Function
        },
        languageImportComplete: {
            type: Boolean,
            default: false
        },
        languageImportFeedback: {
            type: Object
        }
    },
    data() {
        return {
            hasValuesError: false,
            hasTranslationsError: false,
            translationsErrorMessage: "",
            valuesErrorMessage: "",
            valuesErrorDetails: [],
            languageUploadCompleted: false,
            valuesUploadCompleted: false,
            uploadedLanguage: "en",
            activeTab: "content",
            resizeSettings: { cover: CoverType.Smart },
            useAutoResize: false
        };
    },

    computed: {
        campaignId() {
            return this.$store.state.route.params.campaignId;
        },

        campaignLanguages() {
            return this.$store.state.campaign.languages;
        },

        editableGroupIds() {
            return this.$store.state.campaign.normalized.editableGroupIds;
        },

        editableGroups() {
            return this.$store.state.campaign.normalized.editableGroups;
        },

        editableGroupValues() {
            return this.$store.state.campaign.editableGroupValues;
        },

        editableIds() {
            return this.$store.state.campaign.normalized.editableIds;
        },

        editables() {
            return this.$store.state.campaign.normalized.editables;
        },

        editablesGroupNames() {
            return this.$store.state.campaign.normalized.editableGroupIds;
        },

        editablesNameMap() {
            const map = {};
            this.editableIds.forEach(editableId => {
                if (ExportableEditableTypes.includes(this.editables[editableId].type)) {
                    map[this.editables[editableId].name] = editableId;
                }
            });

            return map;
        },

        groupValueNamesByGroup() {
            return this.$store.getters[CampaignGetters.groupValueNamesByGroup];
        },

        headerTabName() {
            return camelToUpperCase(this.activeTab);
        },

        masterTemplateNameMap() {
            return this.$store.getters[CampaignGetters.masterTemplatesBySize];
        },

        onlyImportedLanguage() {
            if (this.languageImportFeedback.languages && this.languageImportFeedback.languages.length === 1) {
                return this.languageImportFeedback.languages[0];
            }

            return null;
        },

        selectedLanguage() {
            return this.$store.state.editor.selectedLanguage;
        }
    },

    watch: {
        languageImportComplete(val) {
            this.languageUploadCompleted = val;
        }
    },

    created() {
        this.importExportService = new ImportExport(this.$apollo, this.$store);
    },

    methods: {
        async onXlsTranslationsLoad(importedData) {
            this.languageUploadCompleted = false;
            this.hasTranslationsError = false;

            if (!importedData.sheets.length) {
                this.valuesErrorMessage =
                    "The file is either empty or is incorrectly formatted. Please check your data and try again.";
                this.hasTranslationsError = true;
                return;
            }

            await this.$nextTick();

            const processingErrors = [];
            await Promise.all(
                importedData.sheets.map(async sheetName => {
                    try {
                        await this.processTranslationsSheet(importedData[sheetName].sheetData);
                    } catch (e) {
                        processingErrors.push(e);
                    }
                })
            );

            this.languageUploadCompleted = true;
        },

        processTranslationsSheet(sheetData) {
            if (!sheetData.length) {
                return;
            }

            const columnIndexes = {};
            const header = sheetData.shift();
            header.shift(); // take "en" out of the computations
            const translationsInit = {
                languages: []
            };
            const ignoredHeaders = [];
            header.forEach((langValue, index) => {
                const lang = langValue.toLowerCase();
                if (languageCodes.includes(lang)) {
                    translationsInit[lang] = {};
                    translationsInit.languages.push(lang);
                    columnIndexes[lang] = index;
                } else {
                    ignoredHeaders.push(langValue);
                }
            });

            if (ignoredHeaders.length) {
                this.$snackbar.info(
                    "Columns ignored",
                    `"${ignoredHeaders.join(", ")}" doesn't seem to be a valid language code`,
                    { duration: 10, closable: true }
                );
            }

            const languageData = sheetData.reduce((acc, curr) => {
                if (curr[0]) {
                    translationsInit.languages.forEach(lang => {
                        if (curr[columnIndexes[lang] + 1]) {
                            acc[lang][curr[0]] = curr[columnIndexes[lang] + 1];
                        }
                    });
                }

                return acc;
            }, translationsInit);

            this.$emit("languagesUpdated", {
                languageData
            });
        },

        async onXlsValuesLoad(importedData) {
            this.valuesUploadCompleted = false;
            this.hasValuesError = false;
            this.valuesErrorDetails = [];

            if (!importedData.sheets.length) {
                this.valuesErrorMessage =
                    "The file is either empty or is incorrectly formatted. Please check your data and try again.";
                this.hasValuesError = true;
                return;
            }

            await this.$nextTick();

            const processingErrors = [];
            await Promise.all(
                importedData.sheets.map(async sheetName => {
                    try {
                        await this.processGroupImport(importedData[sheetName].sheetData, sheetName);
                    } catch (e) {
                        processingErrors.push(e);
                    }
                })
            );

            if (processingErrors.length) {
                this.hasValuesError = true;
                const processingErrorsText = processingErrors.map(({ groupName }) => groupName).join(",");
                this.valuesErrorMessage = `Upload complete but some data could not be imported: ${processingErrorsText}`;
                this.valuesErrorDetails = processingErrors.flatMap(({ details }) => details);
            }

            this.valuesUploadCompleted = true;
        },

        async processGroupImport(dataArray, _groupName) {
            // empty value represents templateName header
            const [groupNameCell, templateHeader, ...editableNamesHeader] = dataArray.shift();
            let allSizesImport = false;
            if (templateHeader.toLowerCase() !== "template") {
                // if this column is not a template - we treat it as a value column and set the the value for All Sizes
                editableNamesHeader.unshift(templateHeader);
                allSizesImport = true;
            }
            const getGroupName = (paramName, cellName) => paramName || cellName.replace("*GROUP: ", "");
            const groupName = getGroupName(_groupName, groupNameCell);
            const firstEmptyHeaderCell = editableNamesHeader.indexOf("");
            const lastRelevantColumn = firstEmptyHeaderCell > -1 ? firstEmptyHeaderCell : editableNamesHeader.length;

            const editableNames = editableNamesHeader.slice(0, lastRelevantColumn);

            if (!this.editablesGroupNames.includes(groupName)) {
                this.valuesErrorMessage = `Uploaded group '${groupName}' is not a valid name for the master templates available in this campaign.
                Please check your data and try again.`;
                this.hasValuesError = true;

                return;
            }
            const invalidEditableNames = editableNames.filter(editableName => !this.editablesNameMap[editableName]);

            if (invalidEditableNames.length) {
                this.valuesErrorMessage = `Uploaded file contains an editable name that is not valid for the group "${groupName}".
                Please check your data and try again.
                Invalid names: ${invalidEditableNames.join(", ")}`;
                this.hasValuesError = true;

                return;
            }

            const groupValueRows = dataArray
                // filter out empty rows
                .filter(l => !!l[0])
                .map(curr => this.parseGroupValueImportRow(curr, groupName, lastRelevantColumn, allSizesImport))
                .filter(g => !!g);

            const groupValuesToCreateSet = groupValueRows.reduce((acc, grp) => {
                if (!grp.groupValueId && grp.groupValueName !== "") {
                    acc.add(grp.groupValueName);
                }

                return acc;
            }, new Set());

            if (groupValuesToCreateSet.size) {
                try {
                    const createdGroupValues = await this.createNewGroupValues(groupName, [...groupValuesToCreateSet]);
                    const createdGroupValuesIdMap = createdGroupValues
                        /* Filter out Boolean returns which indicate that there was no need to call a query */
                        .reduce((acc, curr) => {
                            return Object.assign(acc, {
                                [curr.value]: curr._id
                            });
                        }, {});
                    groupValueRows.forEach(g => {
                        if (!g.groupValueId) {
                            Object.assign(g, { groupValueId: createdGroupValuesIdMap[g.groupValueName] });
                        }
                    });
                } catch (e) {
                    this.valuesErrorMessage = `Failed to create group values for group ${groupName}`;
                    console.error(e);
                    this.hasValuesError = true;
                }
            }

            /* Because the Unsaved group scope overwrite can affect how we process the template scope overwrites we need
            to process group values first.
            Issue Example:
            For the boolean type when the default is set to false
            And we import a file which updates group to true and the template value to false
            The second value will not be applied as there is no change comparing to the saved state */

            const processingErrors = [];
            await Promise.all(
                groupValueRows
                    .filter(row => row.masterTemplateName === ExportGroupScopeLabel)
                    .flatMap(row =>
                        row.editableValues.map(async (editableValue, columnIndex) => {
                            try {
                                await this.processGroupValueImportRow(row, editableNames, editableValue, columnIndex);
                            } catch (e) {
                                processingErrors.push(e);
                            }
                        })
                    )
            );

            // going over template level overwrites
            await Promise.all(
                groupValueRows
                    .filter(row => row.masterTemplateName !== ExportGroupScopeLabel)
                    .flatMap(row =>
                        row.editableValues.map(async (editableValue, columnIndex) => {
                            try {
                                await this.processGroupValueImportRow(row, editableNames, editableValue, columnIndex);
                            } catch (e) {
                                processingErrors.push(e);
                            }
                        })
                    )
            );

            if (processingErrors.length) {
                throw new GroupProcessingError(
                    `Error when processing entries for ${groupName}`,
                    groupName,
                    processingErrors
                );
            }
        },

        close() {
            this.$emit("close");
        },

        downloadTemplate(type) {
            this.$emit("exportValuesTemplate", type);
        },

        getArrayValue(editable, groupValueIds, masterTemplateId, editableValue) {
            let defaultValue = this.getDefaultValue(editable, masterTemplateId);
            if (!defaultValue) {
                defaultValue = this.getDefaultValue(editable, editable.defaultValues[0].masterTemplateId);
            }

            return defaultValue.map(({ label, value }) => {
                const option = {
                    label,
                    value
                };

                if (option.label === editableValue) {
                    option.selected = true;
                }

                return option;
            });
        },

        localeToFriendlyName(lang) {
            return langUtils.localeToFriendlyName(lang);
        },

        onClick(lang) {
            this.$emit("exportLanguage", lang);
        },

        onTranslationsError(e) {
            this.hasTranslationsError = true;
            this.translationsErrorMessage = e.message;
        },

        onValuesImportError(e) {
            this.hasValuesError = true;
            this.valuesErrorMessage = e.message;
        },

        parseGroupValueImportRow(row, groupName, lastRelevantColumn, allSizesImport) {
            return this.importExportService.parseGroupValueImportRow(
                row,
                groupName,
                lastRelevantColumn,
                allSizesImport
            );
        },

        processGroupValueImportRow(row, editableNames, editableValue, columnIndex) {
            if (editableValue === "") {
                return Promise.resolve();
            }

            let value = editableValue;
            // this is the way we enable having empty strings in the csv
            if (editableValue === emptyOverwriteImportValue) {
                value = "";
            }

            const editableNameFromHeader = editableNames[columnIndex];
            const editable = this.editables[this.editablesNameMap[editableNameFromHeader]];
            const scope =
                row.masterTemplateName === ExportGroupScopeLabel
                    ? OverwriteScope.EditableGroup
                    : OverwriteScope.EditableGroupTemplate;

            if (scope === OverwriteScope.EditableGroup) {
                return this.setImportedValue(editable, [row.groupValueId], undefined, value, scope);
            }

            if (
                this.masterTemplateNameMap[row.masterTemplateName] &&
                this.masterTemplateNameMap[row.masterTemplateName].length
            ) {
                return Promise.all(
                    this.masterTemplateNameMap[row.masterTemplateName].map(masterTemplateId =>
                        this.setImportedValue(editable, [row.groupValueId], masterTemplateId, value, scope)
                    )
                );
            }

            this.$snackbar.warning(`This campaign does not contain a template called "${row.masterTemplateName}"`);
            return Promise.resolve();
        },

        processLine(fields, language) {
            const ids = fields[0].split("@");
            const [groupValueIds, editableId, masterTemplateId] = ids;
            const value = fields[2];

            const editable = this.editables[editableId];

            if (this.wasValueUpdated(groupValueIds, editableId, masterTemplateId, language, value)) {
                this.$emit("updateEditable", {
                    language,
                    value,
                    editableGroupValueIds: [groupValueIds],
                    editable,
                    scope: masterTemplateId ? OverwriteScope.EditableGroupTemplate : OverwriteScope.EditableGroup,
                    ...(masterTemplateId && { masterTemplateId })
                });

                return true;
            }

            return false;
        },

        switchAndClose(uploadedLanguage) {
            this.$store.dispatch(EditorAction.SelectLanguage, uploadedLanguage);
            this.close();
        },

        toggleAutoResize() {
            this.useAutoResize = !this.useAutoResize;
        },

        setImportedValue(editable, groupValueIds, masterTemplateId, importedValue, scope) {
            let value = importedValue;
            if (editable.type === EditableType.Array) {
                value = this.getArrayValue(editable, groupValueIds, masterTemplateId, value);
            }

            if (
                editable &&
                this.wasValueUpdated(groupValueIds, editable._id, masterTemplateId, campaignDefaultLanguage, value)
            ) {
                return this.importExportService.applyEditableUpdate({
                    language: campaignDefaultLanguage,
                    value,
                    editableGroupValueIds: groupValueIds,
                    editable,
                    scope,
                    resizeSettings: this.resizeSettings,
                    resizeType: this.useAutoResize ? ResizeType.Auto : ResizeType.Off,
                    ...(scope === OverwriteScope.EditableGroupTemplate && { masterTemplateId })
                });
            }

            return Promise.resolve();
        },

        wasValueUpdated(groupValueIds, editableId, masterTemplateId, language, value) {
            const { masterTemplateIds } = this.$store.state.campaign.normalized;
            let cmpValue = value;
            const editable = this.editables[editableId];
            const savedOverwrite = this.getSavedOverwrite(editableId, masterTemplateId, language, groupValueIds);
            const savedValue = ImportExport.getExportValue(editable, masterTemplateId, savedOverwrite);

            if (editable.type === EditableType.Array) {
                const selected = value.find(option => option.selected);

                cmpValue = selected ? selected.label : value;
            }

            if (masterTemplateId) {
                const hasMatchingLocalGroupScopeOverwrite = this.$store.state.editor.localEditableOverwrites.find(
                    editableOverwrites => {
                        return (
                            editableOverwrites.editable._id === editableId &&
                            editableOverwrites.overwrites.some(
                                overwrite =>
                                    overwrite.scope === OverwriteScope.EditableGroup &&
                                    overwrite.language === language &&
                                    JSON.stringify(overwrite.editableGroupValueIds) === JSON.stringify(groupValueIds) &&
                                    overwrite.value !== cmpValue
                            )
                        );
                    }
                );
                // If there is unsaved group scope overwrite that would impact this overwrite we need to push our value as well
                if (hasMatchingLocalGroupScopeOverwrite) {
                    return true;
                }
            }

            if (!savedValue) {
                const groupPlaceholder = editableUtils.getGroupPlaceholder(editable, masterTemplateIds, "");

                return groupPlaceholder !== cmpValue;
            }

            return savedValue !== cmpValue;
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

.importExportModal {
    &__navigation {
        margin-bottom: $spacing;
    }

    &__howto {
        &-list {
            padding-left: $spacing;
        }
    }

    &__files {
        padding-left: $spacing;
        margin-bottom: $spacing;
    }

    &__upload-result {
        margin-top: $spacing;
    }

    &__upload-results-error-list {
        padding-left: $spacing;
        word-break: break-word;
    }

    &__upload-controls {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .labelled-switch {
            flex: 0;

            &__label {
                white-space: nowrap;
            }
        }
    }
}
</style>
