const storeNamespace = "roles";

export const RolesAction = {
    SetRoles: `${storeNamespace}/setRoles`
};

export const RolesGetter = {
    getRolesThatHaveAction: `${storeNamespace}/getRolesThatHaveAction`,
    getRolesThatDoNotHaveAction: `${storeNamespace}/getRolesThatDoNotHaveAction`,
    getCollaboratorRole: `${storeNamespace}/getCollaboratorRole`
};

export const RolesMutation = {
    SetRoles: "setRoles"
};

export default {
    namespaced: true,

    state: {
        roles: []
    },

    getters: {
        getRolesThatHaveAction: state => action => state.roles.filter(role => role.actions.includes(action)),

        getRolesThatDoNotHaveAction: state => action => state.roles.filter(role => !role.actions.includes(action)),

        getCollaboratorRole: state => state.roles.find(role => role.name === "Collaborator")
    },

    mutations: {
        setRoles(state, roles) {
            state.roles = roles;
        }
    },

    actions: {
        setRoles({ commit }, roles) {
            commit(RolesMutation.SetRoles, roles);
        }
    }
};
