<template>
    <div class="loading-spinner" :style="style">
        <div v-if="icon" :class="iconClass"></div>
        <div v-else>
            <loading-icon class="loading-spinner__icon" />
        </div>
    </div>
</template>

<script>
/*
    This component will inherit the font-size and color from the CSS of it's parent
    if the props `size` or `color` are not defined.
*/
import LoadingIcon from "@/assets/Loading.svg";

export default {
    components: {
        LoadingIcon
    },
    props: {
        color: {
            type: String
        },
        icon: {
            type: String
        },
        size: {
            type: Number
        }
    },

    computed: {
        iconClass() {
            if (this.icon) {
                return this.icon;
            }

            return "";
        },

        style() {
            return {
                color: this.color,
                fontSize: this.size ? `${this.size}px` : undefined
            };
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_variables.scss";

@keyframes loading-spinner__spin {
    from {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    position: relative;
    height: 1em;
    width: 1em;
    line-height: 0;

    div,
    img {
        width: 100%;
        height: 100%;
    }
}

.loading-spinner__icon {
    animation: loading-spinner__spin 1s linear infinite;
    flex: 1;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;

    path {
        fill: currentColor;
    }
}

.loading-spinner__logo {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    path {
        fill: currentColor;
    }
}

.wish-icon {
    background: url("../../assets/wish.gif") no-repeat center;
    background-size: 100%;
}
</style>
