<template>
    <div class="library-deliverable-table-video-cell">
        <div class="library-deliverable-table-video-cell__video-container">
            <video
                ref="video"
                class="library-deliverable-table-video-cell__video"
                loop
                @mouseenter="playVideo"
                @mouseleave="stopVideo"
            >
                <source :src="src" type="video/mp4" />
            </video>
        </div>
        <span class="library-deliverable-table-video-cell__text">
            <a :title="filename" :href="src" target="_blank">
                {{ filename }}
            </a>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        src: {
            required: true,
            type: String
        }
    },
    computed: {
        filename() {
            const url = new URL(this.src);
            return url.pathname.split("/").pop();
        }
    },
    methods: {
        playVideo() {
            if (this.$refs.video) {
                this.$refs.video.play();
            }
        },
        stopVideo() {
            if (this.$refs.video) {
                this.$refs.video.pause();
            }
        }
    }
};
</script>

<style lang="scss">
@import "@/../sass/_mixins.scss";
@import "@/../sass/_variables.scss";

.library-deliverable-table-video-cell {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    padding: $spacing-smaller 0;
    margin: (0 - $spacing-small) 0;
}

.library-deliverable-table-video-cell__video-container {
    @include make-checked-background($grey3, $spacing-small, $white);
    align-items: center;
    border: 1px solid $grey3;
    display: flex;
    height: 50px;
    justify-content: center;
    margin: 0 $spacing-small 0 0;
    position: relative;
    width: 80px;
}

.library-deliverable-table-video-cell__video {
    max-height: 100%;
    max-width: 100%;
}

.library-deliverable-table-video-cell__text {
    color: $grey5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    > a {
        font-weight: bold;
    }
}
</style>
